import {UserLibraryUserProjectTypeWithCmsData} from 'features/types/userLibrarySlice';
import {Dispatch, SetStateAction} from 'react';
import {Modal} from 'ui/Modal';
import {TemplateInfo} from './TemplateInfo';

interface Props {
  template?: UserLibraryUserProjectTypeWithCmsData;
  setSelectedTemplate: Dispatch<
    SetStateAction<UserLibraryUserProjectTypeWithCmsData | undefined>
  >;
}

export const TemplateModal = ({template, setSelectedTemplate}: Props) => {
  if (!template) {
    return null;
  }

  return (
    <Modal
      title={''}
      size="xl"
      open={!!template}
      onClose={() => setSelectedTemplate(undefined)}
    >
      <TemplateInfo template={template} />
    </Modal>
  );
};
