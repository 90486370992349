import {CableMessagePayload} from './types';
import {announceDiscretelyUserToApi} from 'features/authSlice';
import {fetchUserClips} from 'features/userLibrarySlice';

export enum UploadMediaClipCableActions {
  Created = 'created',
  MuxDownloadReady = 'mux_download_ready',
}

const uploadMediaClipsCable = (
  dispatch: any,
  getAccessTokenSilently: any,
  message: CableMessagePayload,
  user: any,
) => {
  if (message.body.action === UploadMediaClipCableActions.Created) {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(fetchUserClips(token, false));
      } catch (e) {
        console.error(e);
      }
    })();
  }
  if (message.body.action === UploadMediaClipCableActions.MuxDownloadReady) {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(fetchUserClips(token, false));
      } catch (e) {
        console.error(e);
      }
    })();
  }
};

export default uploadMediaClipsCable;
