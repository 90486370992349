import {DraggableListWrap} from './DraggableListWrap';
import {LandingPageComponent} from 'services/landingPageAPI';
import {ListChapterCard} from './ListChapterCard';

export const DraggableChapterList = ({
  itemsList,
  landingPageId,
  scrollToIndex,
}: {
  landingPageId: string;
  itemsList: LandingPageComponent[];
  scrollToIndex: (index: number) => void;
}) => {
  return (
    <DraggableListWrap landingPageId={landingPageId} itemsList={itemsList}>
      {props => {
        return (
          <ListChapterCard
            item={props.item}
            isDragging={props.isDragging}
            onClick={() => {
              scrollToIndex(props.index);
            }}
          />
        );
      }}
    </DraggableListWrap>
  );
};
