import { AnimatePresence, motion } from 'framer-motion';
import { ArrowLeft, DownloadCloud, Pause, Play } from 'react-feather';
import {
  formatTime,
  usePlaybackPercentage,
} from 'features/EditorCanvas/components/CanvasTime/utils';
import {
  isAnonymousSelector,
  userMetadataSelector,
} from 'features/selectors/authSelectors';
import { useDocumentMouseUp, useEventListener } from 'features/Common/utils';
import { useEffect, useRef, useState } from 'react';

import { Button } from 'ui/Button';
import { ClipTimeProvider } from '../ClipStep/ClipStep';
import { EnterEmailModal } from '../EnterEmailModal';
import { FocusedFlowCanvasPreview } from '../CanvasPreview';
import { FocusedFlowToolbar } from '../Toolbar';
import { IconButton } from 'ui/IconButton';
import { LayoutCalculator } from '../LayoutCalculator';
import { StepHeading } from '../utils';
import { clamp } from 'lodash';
import classNames from 'classnames';
import { fadeProps } from 'features/EditorCanvas/Sidebar/Sidebar';
import { useDownloadProject } from '../DownloadStep/DownloadStep';
import { useDuration } from 'features/EditorCanvas/components/CanvasTime/useDuration';
import { usePlayback } from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import { useSelector } from 'react-redux';
import { useTimeSelector } from 'features/EditorCanvas/components/CanvasTime/useTimeSelector';
import { useWatchElementSize } from 'features/Common/useElementSize';
import { useWizardState } from '../State';

export const PreviewStep = () => {
  const { nextStep, previousStep } = useWizardState();

  const [containerRef, containerSize] = useWatchElementSize();

  const maxHeight = Math.max(containerSize.height, 250);

  const isAnonymous = useSelector(isAnonymousSelector);

  const { download, layoutCalculatorProps } = useDownloadProject();

  const [enterEmailModalOpen, setEnterEmailModalOpen] = useState(false);

  const userMetadata = useSelector(userMetadataSelector);

  return (
    <ClipTimeProvider endBehavior="stop">
      <div className="flex flex-1 flex-col space-y-6">
        <StepHeading
          title="Download your clip"
          subtitle="or, go back to make any changes"
          center
        />
        <div className="relative flex-1" ref={containerRef}>
          <div style={{ height: maxHeight }}>
            <div className="absolute inset-0">
              <FocusedFlowCanvasPreview
                maxHeight={maxHeight}
                overlay={<VideoControls />}
              />
            </div>
          </div>
        </div>
        <FocusedFlowToolbar>
          <Button variant="secondary" leftIcon={ArrowLeft} onClick={() => previousStep()}>
            Go back
          </Button>
          <Button
            variant="primary"
            leftIcon={DownloadCloud}
            onClick={async () => {
              await download();

              if (isAnonymous && !userMetadata?.notification_email) {
                setEnterEmailModalOpen(true);
                return;
              }

              nextStep();
            }}
          >
            Download video
          </Button>
        </FocusedFlowToolbar>
      </div>
      <EnterEmailModal
        title="Download started"
        open={enterEmailModalOpen}
        onSubmit={() => nextStep()}
        submitButton="Submit"
        optional={false}
      >
        {"On average, turnaround time is roughly 15-30% of the audio file's duration, with a minimum processing time of around 20-30 seconds"}
      </EnterEmailModal>
      <LayoutCalculator {...layoutCalculatorProps} />
    </ClipTimeProvider>
  );
};

const VideoControls = () => {
  const { isPlaying, toggle } = usePlayback();

  const playbackTime = useTimeSelector(({ currentTime }) => formatTime(currentTime), []);
  const duration = useDuration();

  const [gestureActive, setGestureActive] = useState(false);

  return (
    <div className="group absolute inset-0 cursor-pointer" onClick={() => toggle()}>
      <div
        className={classNames(
          'absolute inset-0 flex items-center justify-center opacity-0 transition',
          !isPlaying && 'opacity-100',
        )}
      >
        <div className="relative h-20 w-20 rounded-full bg-black bg-opacity-70 backdrop-blur backdrop-filter transition group-hover:scale-110">
          <Play
            size={38}
            className="absolute top-1/2 left-1/2 mt-[-18px] -ml-4 text-white"
          />
        </div>
      </div>
      <div
        className={classNames(
          'absolute inset-x-0 bottom-0 flex h-16 cursor-auto flex-col justify-end opacity-0 transition',
          gestureActive && 'opacity-100',
          isPlaying && 'group-hover:opacity-100',
        )}
        style={{
          backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6))',
        }}
        onClick={e => e.stopPropagation()}
      >
        <div className="flex items-center space-x-4 py-4 px-5 pr-5">
          <IconButton
            icon={isPlaying ? Pause : Play}
            variant="inline"
            label={isPlaying ? 'Pause' : 'Play'}
            size="small"
            onClick={() => toggle()}
            shortcut="space"
            _className="!text-white"
            preventDoubleClick
            negativeMargin
          />
          <Timeline onGestureActive={setGestureActive} />
          <div className="flex items-baseline whitespace-nowrap font-mono text-xs tracking-tighter text-white">
            <div>{playbackTime}</div>
            <div className="px-0.5">/</div>
            <div>{formatTime(duration)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Timeline = ({ onGestureActive }: { onGestureActive: (active: boolean) => void }) => {
  const playbackPercentage = usePlaybackPercentage();

  const [hover, setHover] = useState<number | null>(null);

  const container = useRef<HTMLDivElement>(null);

  const getMousePercentage = (event: MouseEvent) => {
    if (!container.current) return;

    const containerRect = container.current.getBoundingClientRect();
    const layerX = event.clientX - containerRect.left;

    const percentage = layerX / containerRect.width;
    return clamp(percentage, 0, 1);
  };

  const duration = useDuration();
  const { seekTo } = usePlayback();

  const [drag, setDrag] = useState<number | null>(null);
  const isMouseDown = useRef(false);

  useEffect(() => {
    onGestureActive(drag !== null);
  }, [drag, onGestureActive]);

  const onMouseDown = useDocumentMouseUp(() => {
    isMouseDown.current = false;
    setDrag(null);
  });

  const seekToPercentage = (percentage: number) => {
    seekTo({ timeMs: percentage * duration });
  };

  useEventListener('mousemove', event => {
    if (isMouseDown.current) {
      const percentage = getMousePercentage(event);
      if (percentage == null) return;

      seekToPercentage(percentage);
      setDrag(percentage);
    }
  });

  const mouseX = drag ?? hover;

  return (
    <div
      className="group w-full cursor-pointer select-none py-2"
      ref={container}
      onMouseDown={() => {
        isMouseDown.current = true;
        onMouseDown();
      }}
      onMouseMove={event => {
        const percentage = getMousePercentage(event.nativeEvent);
        if (percentage == null) return;

        setHover(percentage);
      }}
      onMouseLeave={() => {
        setHover(null);
      }}
      onClick={event => {
        const percentage = getMousePercentage(event.nativeEvent);
        if (percentage == null) return;

        seekToPercentage(percentage);
      }}
    >
      <div className="relative h-1 w-full rounded-full bg-white">
        {hover != null && (
          <motion.div
            className="absolute inset-y-0 left-0 rounded-full bg-blue-300"
            style={{ width: `${hover * 100}%` }}
            {...fadeProps}
          />
        )}
        <div
          className="absolute inset-y-0 left-0 rounded-full bg-blue-500"
          style={{ width: `${playbackPercentage}%` }}
        >
          <div className="absolute right-0 top-1/2 h-3 w-3 -translate-y-1/2 translate-x-1/2 rounded-full bg-blue-500 transition group-hover:scale-125" />
        </div>
        <AnimatePresence>
          {mouseX != null && (
            <motion.div
              className={classNames(
                'absolute bottom-3 origin-bottom -translate-x-1/2 rounded bg-gray-900 px-1 pt-0.5 pb-px text-xs text-white',
              )}
              style={{ left: `${mouseX * 100}%` }}
              {...fadeProps}
            >
              {formatTime(mouseX * duration, 0)}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
