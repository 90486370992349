import { PropsWithChildren, useContext } from 'react';

import { DimensionType } from 'features/types/canvasItemsSlice';
import { FrameContext } from 'pages/Canvas/FramePage';
import LoadingText from '../shared/LoadingText';
import { ViewTypes } from 'features/EditorCanvas/constants/ViewConstants';
import classNames from 'classnames';

const captionLayers = [ViewTypes.Caption, ViewTypes.CaptionClip];

export function LayerLoadingContainer({
  isLoading,
  dimension,
  children,
  viewType,
}: PropsWithChildren<{
  dimension: DimensionType;
  isLoading: boolean;
  viewType: ViewTypes;
}>) {
  const frameContext = useContext(FrameContext);

  if (frameContext) {
    return <>{children}</>;
  }

  return (
    <>
      {isLoading && (
        <div
          className="absolute z-10"
          style={{
            width: `${dimension.width}px`,
            height: `${dimension.height}px`,
          }}
        >
          {captionLayers.includes(viewType) ? (
            <div className="h-full w-full text-gray-200">
              <LoadingText />
            </div>
          ) : (
            <div
              className="h-full w-full animate-pulse bg-gray-100"
              role="alert"
              aria-live="polite"
              aria-busy="true"
            />
          )}
        </div>
      )}
      <div className={classNames({ hidden: isLoading })}>{children}</div>
    </>
  );
}
