import {BaseTranscriptChapter} from 'features/types/userLibrarySlice';
import {baseApi} from './baseAPI';

export type UserUploadTranscriptsSummary = {
  has_transcript: Boolean;
  has_chapters: Boolean;
  user_upload_transcripts_summary: BaseTranscriptChapter[];
};

export const userUploadTranscriptsSummaryAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['UserUploadTranscriptsSummary']})
  .injectEndpoints({
    endpoints: builder => ({
      userUploadTranscriptsSummary: builder.query<
        UserUploadTranscriptsSummary,
        {userUploadId: string}
      >({
        query: ({userUploadId}) => ({
          url: `user_upload_transcripts_summaries/${userUploadId}`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading upload media transcript chapters data',
        }),
        providesTags: (result, error, {userUploadId}) => [
          {type: 'UserUploadTranscriptsSummary', userUploadId},
        ],
      }),
    }),
  });

export const {useUserUploadTranscriptsSummaryQuery} = userUploadTranscriptsSummaryAPI;
