import {
  openProject,
  useCreateProjectFromTemplate,
} from 'features/Dashboard/useCreateProject';

import {Button} from 'ui/Button';
import {Edit} from 'react-feather';
import {UserLibraryUserProjectTypeWithCmsData} from 'features/types/userLibrarySlice';
import {accountDetailsSelector} from 'features/selectors/authSelectors';
import classNames from 'classnames';
import {format} from 'date-fns';
import {showSuccessNotification} from 'features/Common/utils';
import {useSelector} from 'react-redux';

interface Props {
  template: UserLibraryUserProjectTypeWithCmsData;
  isModal?: boolean;
}

export const TemplateInfo = ({template, isModal = false}: Props) => {
  const createFromTemplate = useCreateProjectFromTemplate();

  const {accountId} = useSelector(accountDetailsSelector);
  // console.log('template', template);

  const isOwnerOfTemplate = accountId == template.account_id;

  const editTemplate = ({
    template,
  }: {
    template: UserLibraryUserProjectTypeWithCmsData;
  }) => {
    openProject(template.slug);
  };

  return (
    <div className={classNames('mx-auto w-full max-w-3xl', isModal ? '' : 'mt-12')}>
      {template.latest_preview ? (
        <div
          className="aspect-w-16 aspect-h-8 rounded-lg bg-cover"
          style={{backgroundImage: `url("${template.latest_preview}")`}}
        />
      ) : null}
      <div className="mt-4">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-semibold text-gray-900">{template.name}</h2>
          <div className="space-x-3">
            {template.tags
              ? template.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                  >
                    {tag.title}
                  </span>
                ))
              : null}
          </div>
        </div>
        <div className="mt-1 text-sm text-gray-500">
          Updated {format(new Date(template.updated_at), 'MMM, d yyy')}
        </div>
        <p className="mt-3 max-w-xl text-gray-700">{template.description}</p>
      </div>
      <div className="mt-8 flex justify-between">
        <div className="flex justify-start space-x-3">
          {isOwnerOfTemplate && (
            <Button
              leftIcon={Edit}
              variant="tertiary"
              onClick={() => {
                return editTemplate({template});
              }}
            >
              Edit template
            </Button>
          )}
        </div>
        <div className="flex justify-end space-x-3">
          <Button
            variant="secondary"
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/dashboards/templates/${template.slug}`,
              );
              showSuccessNotification({
                title: 'Success',
                message: 'Link copied to clipboard.',
              });
            }}
          >
            Share
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              return createFromTemplate({template});
            }}
          >
            Create a project
          </Button>
        </div>
      </div>
    </div>
  );
};
