import {useAuthAlt} from 'features/Auth/useAuthAlt';
import ProjectNav from 'features/Dashboard/DashboardPage/ContentProjects/ProjectNav';
import {userLibraryUserProjectSelector} from 'features/selectors/userLibrarySelectors';
import {updateProjectName} from 'features/serverSyncSlice';
import {useEffect, useState} from 'react';
import {Edit2} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';
import {IconButton} from 'ui/IconButton';
import EditableTextField from '../shared/EditableTextField';

const DEFAULT_PROJECT_NAME = 'Untitled project';

export default function ProjectNameField({projectId}: {projectId: string}) {
  const project = useSelector(userLibraryUserProjectSelector)(projectId);
  const projectName = project && project.name;
  const dispatch = useDispatch();
  const {getAccessTokenSilently} = useAuthAlt();

  const currentProjectName =
    projectName === projectId
      ? DEFAULT_PROJECT_NAME
      : projectName
      ? projectName
      : DEFAULT_PROJECT_NAME;

  const [tempFieldValue, setTempFieldValue] = useState<string>(
    currentProjectName === DEFAULT_PROJECT_NAME ? '' : currentProjectName,
  );

  useEffect(() => {
    setTempFieldValue(
      currentProjectName === DEFAULT_PROJECT_NAME ? '' : currentProjectName,
    );
  }, [currentProjectName]);

  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitProjectName = () => {
    if (tempFieldValue !== '') {
      window.analytics.track('Submit new project name from workspace toolbar');
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          dispatch(updateProjectName(token, projectId, tempFieldValue));
        } catch (e) {
          console.error(e);
        }
      })();
    }
    setIsEditing(false);
  };

  if (!project) return null;

  return (
    <EditableTextField
      tempFieldValue={tempFieldValue}
      setTempFieldValue={setTempFieldValue}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      isSubmitting={isSubmitting}
      setIsSubmitting={setIsSubmitting}
      startValue={currentProjectName}
      onSubmit={submitProjectName}
    >
      <IconButton
        variant="inline"
        icon={Edit2}
        onClick={() => setIsEditing(true)}
        label="Edit project name"
        size="small"
      />
      <ProjectNav projectId={projectId} />
    </EditableTextField>
  );
}
