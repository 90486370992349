import {AccountWrapperAccountType} from 'features/types/authSlice';
import {baseApi} from './baseAPI';

export const recentUsageAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['Account']})
  .injectEndpoints({
    endpoints: builder => ({
      updateAccount: builder.mutation<
        void,
        {id: string} & Pick<AccountWrapperAccountType, 'name' | 'metadata'>
      >({
        query: ({id, ...account}) => ({
          url: `/accounts/${id}`,
          method: 'PATCH',
          v2: true,
          data: {account},
          errorTitle: 'Error updating account',
        }),
        invalidatesTags: ['Account'],
      }),
    }),
  });

export const {useUpdateAccountMutation} = recentUsageAPI;
