import {BASE_SCENE_FRAME_STATE} from 'features/sceneFrameSlice';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {PreviewContainer} from './PreviewContainer';

export const ClipPreview = ({userUpload}: {userUpload: UserUploadsType | undefined}) => {
  const dimensions = {...BASE_SCENE_FRAME_STATE.canvasDimensions};
  if (userUpload && userUpload.max_width != null && userUpload.max_height != null) {
    dimensions.width = userUpload.max_width;
    dimensions.height = userUpload.max_height;
  }

  const previewUrl = `https://image.mux.com/${userUpload?.mux_playback_id}/thumbnail.jpg`;

  return (
    <PreviewContainer loading={!userUpload} dimensions={dimensions}>
      <div
        className="absolute inset-0 bg-contain"
        style={{backgroundImage: `url('${previewUrl}')`}}
      />
    </PreviewContainer>
  );
};
