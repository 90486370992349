import {RadioGroup} from '@headlessui/react';
import classNames from 'classnames';
import {addNewScene, updateSceneOrders} from 'features/canvasItemsSlice';
import {AnimatePresence, motion} from 'framer-motion';
import produce from 'immer';
import {ReactNode, useLayoutEffect, useRef} from 'react';
import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  Droppable,
} from 'react-beautiful-dnd';
import {Pause, Play, PlusCircle} from 'react-feather';
import {useDispatch} from 'react-redux';
import {IconButton} from 'ui/IconButton';
import {v4} from 'uuid';
import {usePlayback} from '../CanvasTime/usePlayback';
import {useScenes} from '../CanvasTime/useScenes';
import {AccessibleProgress} from './AccessibleProgress';
import {canvasScenesZindex} from './canvasSceneConstants';
import {CanvasSceneDraggable} from './CanvasSceneDraggable';
import {CanvasScenesProvider} from './CanvasScenesContext';

function reorder<Item>(list: Item[], startIndex: number, endIndex: number) {
  return produce(list, draftList => {
    const [removed] = draftList.splice(startIndex, 1);
    draftList.splice(endIndex, 0, removed);
  });
}

function CanvasScenesWrap({children}: {children: ReactNode}) {
  return (
    <CanvasScenesProvider>
      <div className="relative w-full bg-white pb-0">{children}</div>
    </CanvasScenesProvider>
  );
}

export default function CanvasScenes({projectId}: {projectId: string}) {
  const {scenes, seekToScene, activeScene} = useScenes();
  const dispatch = useDispatch();

  const onDragEnd: DragDropContextProps['onDragEnd'] = result => {
    if (!result.destination) return;

    const newItemsRow = reorder(scenes, result.source.index, result.destination.index);

    const newScenes = {};
    newItemsRow.forEach((newItem, index) => {
      const {sceneId, ...newScene} = newItem;
      newScenes[sceneId] = {...newScene, order: index};
    });

    dispatch(updateSceneOrders({projectId, canvasScenes: newScenes}));

    window.analytics.track('scenes_reorder_scenes');
  };

  const seekToSceneRef = useRef(seekToScene);

  useLayoutEffect(() => {
    seekToSceneRef.current = seekToScene;
  }, [seekToScene]);

  const handleNewScene = (targetIndex?: number) => {
    const newSceneId = v4();
    if (targetIndex !== undefined) {
      dispatch(addNewScene({projectId, newSceneId, targetIndex}));
    } else {
      dispatch(addNewScene({projectId, newSceneId}));
    }
    setTimeout(() => {
      seekToSceneRef.current(newSceneId);
    });
  };

  const togglePlayback = () => {
    window.analytics.track('Handle video play');
    toggle({reset: false});
  };

  const {isPlaying, toggle} = usePlayback();

  return (
    <CanvasScenesWrap>
      <div className="flex flex-1 items-center space-x-4">
        <div
          className={classNames(
            'flex h-full items-center justify-center pl-4',
            `z-${canvasScenesZindex.PlayPauseToggle}`,
          )}
        >
          <IconButton
            icon={isPlaying ? Pause : Play}
            variant="inline"
            label={isPlaying ? 'Pause' : 'Play'}
            size="large"
            onClick={togglePlayback}
            _className="inline-flex bg-white p-3 h-16 w-16 rounded-full shadow-md border border-gray-300"
          />
        </div>
        <div className="relative inline-flex flex-1 flex-col overflow-x-auto">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable direction="horizontal" droppableId="scenes">
              {dropProvided => (
                <AnimatePresence initial={false}>
                  <div
                    className="inline-flex flex-nowrap p-3 pl-0 pt-0"
                    {...dropProvided.droppableProps}
                    ref={dropProvided.innerRef}
                  >
                    <RadioGroup
                      className="mr-3 flex space-x-3"
                      value={activeScene.id}
                      onChange={id => {
                        if (!id) return;
                        seekToScene(id);
                      }}
                    >
                      {scenes.map((scene, index) => {
                        return (
                          <RadioGroup.Option value={scene.id} key={scene.sceneId}>
                            {({active}) => (
                              <Draggable draggableId={scene.sceneId} index={index}>
                                {(dragProvided, dragSnapshot) => (
                                  <CanvasSceneDraggable
                                    handleNewScene={handleNewScene}
                                    index={index}
                                    scene={scene}
                                    active={active}
                                    dragProvided={dragProvided}
                                    dragSnapshot={dragSnapshot}
                                  />
                                )}
                              </Draggable>
                            )}
                          </RadioGroup.Option>
                        );
                      })}
                    </RadioGroup>

                    {dropProvided.placeholder}

                    <motion.div layout className="mt-3 h-[84px] min-w-[74px]">
                      <IconButton
                        variant="tertiary"
                        icon={PlusCircle}
                        _className="w-full h-full"
                        label="Add a Scene"
                        onClick={() => handleNewScene()}
                        trackClick={{
                          event: 'scenes_add_scene',
                          properties: {count: scenes.length + 1},
                        }}
                      />
                    </motion.div>

                    <div className="w-4 flex-shrink-0" />
                  </div>
                </AnimatePresence>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <AccessibleProgress />
    </CanvasScenesWrap>
  );
}
