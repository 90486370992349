import {THUMBNAIL_CONTAINER_HEIGHT} from './constants';
import {formatTime} from 'features/EditorCanvas/components/CanvasTime/utils';
import {useDuration} from 'features/EditorCanvas/components/CanvasTime/useDuration';

export default function TimelineHoverTimeMarker({percentage}: {percentage: number}) {
  const durationMs = useDuration();

  return (
    <div
      className="absolute bottom-0 z-[60] cursor-horizontal bg-white"
      style={{
        left: `${percentage * 100}%`,
        width: '2px',
        height: `${THUMBNAIL_CONTAINER_HEIGHT}px`,
      }}
    >
      <span className="top-0 h-4 select-none pl-2 text-xs text-gray-400">
        {formatTime(durationMs * percentage)}
      </span>
    </div>
  );
}
