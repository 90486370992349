import { DashParams, routePaths } from 'routes/routesHelper';
import {
  UploadButton,
  useUppy,
} from 'features/Dashboard/DashboardPage/ContentBrandKit/UploadButton';
import {
  UserUpload,
  useCreateUserUploadMutation,
  useListUserUploadsQuery,
} from 'services/userUploadAPI';
import {
  isImageMedia,
  isVideoMedia,
} from 'features/Dashboard/DashboardUploadDetails/utils';

import { Button } from 'ui/Button';
import { Divider } from 'features/EditorCanvas/Sidebar/SidebarMenu';
import { Link } from 'react-router-dom';
import { Upload } from 'react-feather';
import UploadIcon from 'features/Dashboard/shared/UploadIcon';
import { accountIdSelector } from 'features/selectors/authSelectors';
import cn from 'classnames';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

export const LatestUploads = () => {
  return (
    <div className="space-y-3">
      <UploadsListContentWithoutWrap />
    </div>
  );
};

export const UploadsListContentWithoutWrap = () => {
  const {
    data: flatResults,
    isLoading,

    isSuccess,
    isError,
  } = useListUserUploadsQuery(1);

  return (
    <div className="relative divide-y divide-gray-300">
      <div className="grid grid-cols-1">
        <div className="col-span-1 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-800 sm:pl-0">
          Recent uploads
        </div>
      </div>

      {isLoading && <LoadingUploads />}
      {isError && <div>Something went wrong</div>}
      {isSuccess &&
        (flatResults?.data.length === 0 ? (
          <EmptyUploads />
        ) : (
          <div className="space-y-3">
            <div className={cn('relative w-full divide-y divide-gray-200')}>
              {flatResults.data
                .slice(0, flatResults.data.length > 5 ? 5 : flatResults.data.length)
                .map((userUpload: UserUpload) => {
                  return (
                    <UploadsListContentMinRow
                      key={userUpload.id}
                      userUpload={userUpload}
                    />
                  );
                })}
            </div>
            <div>
              <Link to={`/${routePaths.dashboard}/${DashParams.UploadsParam}`}>
                <Button _className=" text-xs" variant="tertiary">
                  View all uploads
                </Button>
              </Link>
            </div>
          </div>
        ))}
    </div>
  );
};

const UploadsListContentMinRow = ({ userUpload }: { userUpload: UserUpload }) => {
  const previewImage = `https://image.mux.com/${userUpload.mux_playback_id}/thumbnail.png?width=100&height=100&fit_mode=smartcrop`;
  return (
    <div
      data-upload={userUpload.id}
      className={cn('group h-20 transition hover:bg-gray-50')}
      style={{
        width: '100%',
      }}
    >
      <Link
        className="flex h-20 items-center truncate text-ellipsis whitespace-nowrap py-0 pl-2 pr-3 text-sm sm:pl-3"
        to={`/${routePaths.dashboard}/${DashParams.UploadsParam}/${userUpload.id}`}
      >
        <div className="flex items-center truncate text-ellipsis">
          <div className="h-16 w-16 flex-shrink-0">
            {isVideoMedia(userUpload.file_type) &&
              (userUpload.mux_playback_id ? (
                <div className="w-full rounded-md bg-gray-50 p-2 group-hover:bg-gray-100 ">
                  <div className="aspect-w-4 aspect-h-4 flex flex-col items-end justify-end overflow-hidden">
                    <img
                      className="object-contain object-center"
                      src={previewImage}
                      alt={`Project preview`}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex h-16 w-16 items-center justify-center rounded-md bg-gray-50 group-hover:bg-gray-100">
                  <UploadIcon fileType={userUpload.file_type} />
                </div>
              ))}
            {isImageMedia(userUpload.file_type) && (
              <div className="w-full rounded-md bg-gray-50 p-2 group-hover:bg-gray-100 ">
                <div className="aspect-w-4 aspect-h-4 flex flex-col items-end justify-end overflow-hidden">
                  <img
                    className="object-contain object-center"
                    src={userUpload.upload_url}
                    alt={`Project preview`}
                  />
                </div>
              </div>
            )}
            {!isVideoMedia(userUpload.file_type) && !isImageMedia(userUpload.file_type) && (
              <div className="flex h-16 w-16 items-center justify-center rounded-md bg-gray-50 group-hover:bg-gray-100">
                <UploadIcon fileType={userUpload.file_type} />
              </div>
            )}
          </div>
          <div className="ml-4 flex h-12 flex-col items-start justify-center overflow-x-scroll">
            <div className="text-xs font-medium text-gray-900">
              {userUpload.file_name}
            </div>
            <div className="text-xs font-medium text-gray-400">
              Uploaded: {format(userUpload.created_at, 'P p')}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

const LoadingUploads = () => {
  return (
    <div className="flex h-full w-full flex-col space-y-1 border-b border-gray-200 px-0 py-4">
      <div className="h-10 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
      <Divider size="small" color="bg-gray-100" />
      <div className="h-10 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
      <Divider size="small" color="bg-gray-100" />
      <div className="h-10 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
      <Divider size="small" color="bg-gray-100" />
      <div className="h-10 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
    </div>
  );
};

export function EmptyUploads() {
  const [createUserUpload] = useCreateUserUploadMutation();
  const accountId = useSelector(accountIdSelector);

  const uppy = useUppy({
    postProcess: async userUpload => {
      if (!accountId) throw new Error('accountId missing');
      const result = await createUserUpload({ ...userUpload, account_id: accountId });
      if ('error' in result) return;
      // console.log('result', result);
    },
  });

  return (
    <div className="py-6">
      <UploadButton uppy={uppy}>
        {fileInput => (
          <button
            type="button"
            className="relative flex w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
          >
            {fileInput}
            <div className="bordre-2 flex h-20 w-20 items-center justify-center rounded-full border-2 border-gray-300">
              <Upload className="mx-auto h-12 w-8 text-gray-400" />
            </div>
            <span className="mt-2 block text-sm font-medium text-gray-400">
              Upload new file
            </span>
          </button>
        )}
      </UploadButton>
    </div>
  );
}
