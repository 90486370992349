import {useDispatch, useSelector} from 'react-redux';

import {NumberInput} from 'features/EditorCanvas/components/shared/common/NumberInput';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {updateItemStyle} from 'features/canvasItemsSlice';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

export function CornerRadiusPicker() {
  const dispatch = useDispatch();
  const projectId = useProjectId();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  if (!selected) return null;

  return (
    <div className="flex">
      <div className="flex w-full flex-col pr-6 text-sm leading-5 text-gray-700">
        <div className="flex flex-row">
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
            <div className="sm:col-span-3">
              <label className="mt-2 block transition">
                <div className="mb-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                  Corner Radius
                </div>
                <NumberInput
                  value={selected.item.style?.borderRadius ?? 0}
                  onChange={borderRadius => {
                    dispatch(
                      updateItemStyle({
                        projectId,
                        itemId: selected.id,
                        style: {borderRadius},
                      }),
                    );
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
