import {Check, Menu as MenuIcon, Move, Plus, Trash2} from 'react-feather';
import {DashParams, routePaths} from 'routes/routesHelper';
import {Fragment, MouseEvent} from 'react';
import {
  LandingPageComponent,
  useLandingPageQuery,
  useUpdateLandingPageMutation,
} from 'services/landingPageAPI';
import {Menu, Transition} from '@headlessui/react';
import {useNavigate, useParams} from 'react-router-dom';

import {BASE_SHARE_URL} from 'constants/environment';
import {Button} from 'ui/Button';
import {IconButton} from 'ui/IconButton';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export type LandingClipListResultActionsProps = {
  userClipId: string;
  userUploadId: string;
  setIsMenuOpen?: (param: string) => void;
  cardType: 'picker' | 'landingPage';
};

export default function LandingClipListResultActions({
  userClipId,
  userUploadId,
  setIsMenuOpen,
  cardType,
}: LandingClipListResultActionsProps) {
  const navigate = useNavigate();

  const {landingPageId} = useParams() as Record<string, string>; // TODO(lenny) make a landing page context
  const {data: landingPageData, isLoading} = useLandingPageQuery({id: landingPageId});
  const [updateLandingPage, {isLoading: isUpdating}] = useUpdateLandingPageMutation();
  const handlePlayClick = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    window.analytics.track('Click play clip on the clip index page');
    const url = `/${routePaths.dashboard}/${DashParams.UploadsParam}/media/${userUploadId}?clip_id=${userClipId}`;

    navigate(url);
  };

  const landingPageComponent = landingPageData?.landing_page_components.find(
    component => component.component_id === userClipId,
  );

  const handleDownloadClick = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    const url = `${BASE_SHARE_URL}/a/${userClipId}/download`;
    window.open(url, '_blank');
  };

  const handleAddClip = async (userClipId: string) => {
    window.analytics.track('Pg-Edit -- LdPgAdm -- Act-Crt -- Clip Component');
    await updateLandingPage({
      id: landingPageId,
      landing_page_components_attributes: [
        {component_id: userClipId, component_type: 'UploadMediaClip'},
      ],
    });
  };

  const handleRemoveClip = async (landingPageComponentId: string) => {
    await updateLandingPage({
      id: landingPageId,
      landing_page_components_attributes: [{id: landingPageComponentId, _destroy: true}],
    });
  };

  const items = [
    {name: 'Edit', onClick: handlePlayClick},
    {name: 'Download', onClick: handleDownloadClick},
  ];

  if (!landingPageData) {
    return null;
  }

  const isAdded = landingPageData.landing_page_components.some(
    (component: LandingPageComponent) => component.component_id === userClipId,
  );

  return (
    <div className="col-span-full flex items-center justify-between space-x-1">
      <div className="flex items-center">
        {cardType === 'picker' && (
          <>
            {isAdded ? (
              <Button variant="inline" size="small" leftIcon={Check} disabled>
                Added
              </Button>
            ) : (
              <Button
                variant="inline"
                size="small"
                leftIcon={Plus}
                onClick={() => handleAddClip(userClipId)}
              >
                Add clip
              </Button>
            )}
          </>
        )}
        {cardType === 'landingPage' && (
          <>
            <IconButton
              variant="inline"
              icon={Move}
              label="Drag to re-order"
              size="small"
              _className="!cursor-grab"
            />
            <Menu as="span" className="relative -ml-px block">
              {({open}) => {
                if (setIsMenuOpen) {
                  if (open) {
                    setIsMenuOpen(userClipId);
                  }
                }

                return (
                  <>
                    <Menu.Button>
                      <IconButton
                        icon={MenuIcon}
                        label="Options"
                        variant="inline"
                        size="small"
                      />
                    </Menu.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="absolute right-0 z-10 mt-2 -mr-1 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <div className="py-1">
                          {items.map(item => (
                            <Menu.Item key={item.name}>
                              {({active}) => (
                                <button
                                  onClick={item.onClick}
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block w-full px-4 py-2 text-left text-sm',
                                  )}
                                >
                                  {item.name}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                );
              }}
            </Menu>
          </>
        )}
      </div>
      {cardType === 'landingPage' && (
        <IconButton
          variant="inline"
          destructive
          icon={Trash2}
          onClick={() => {
            if (!landingPageComponent) return;
            handleRemoveClip(landingPageComponent.id);
          }}
          label="Delete"
          size="small"
        />
      )}
    </div>
  );
}
