import {DashParams, routePaths} from 'routes/routesHelper';

import {Link} from 'react-router-dom';
import {ReactElement} from 'react';
import cn from 'classnames';

const ActiveButton = ({
  children,
  menuPage,
  isFirst,
}: {
  children: ReactElement;
  menuPage: string;
  isFirst: boolean;
}) => {
  return (
    <Link
      to={`/${routePaths.dashboard}/${DashParams.SettingsParam}/${menuPage}`}
      className={cn(
        {
          'ml-8 ': !isFirst,
        },
        'whitespace-nowrap border-b-2 border-indigo-500 py-4 px-1 text-sm font-medium leading-5 text-indigo-600 focus:border-indigo-700 focus:text-indigo-800 focus:outline-none',
      )}
    >
      {children}
    </Link>
  );
};

const InactiveButton = ({
  children,
  menuPage,
  isFirst,
}: {
  children: ReactElement;
  menuPage: string;
  isFirst: boolean;
}) => {
  return (
    <Link
      to={`/${routePaths.dashboard}/${DashParams.SettingsParam}/${menuPage}`}
      className={cn(
        {
          'ml-8 ': !isFirst,
        },
        'whitespace-nowrap border-b-2 border-transparent py-4 px-1 text-sm font-medium leading-5 text-gray-500 hover:border-gray-300 hover:text-gray-700 focus:border-gray-300 focus:text-gray-700 focus:outline-none',
      )}
    >
      {children}
    </Link>
  );
};

export const TabNavItem = ({
  children,
  activePage,
  menuPage,
  isFirst,
}: {
  children: ReactElement;
  menuPage: string;
  isFirst: boolean;
  activePage: string;
}) => {
  return activePage === menuPage ? (
    <ActiveButton isFirst={isFirst} menuPage={menuPage}>
      {children}
    </ActiveButton>
  ) : (
    <InactiveButton isFirst={isFirst} menuPage={menuPage}>
      {children}
    </InactiveButton>
  );
};
