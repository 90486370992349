import {
  GhostCard,
  thumbnailURL,
} from 'features/Dashboard/DashboardPage/ContentUploads/TemplatePicker';
import {Pulse, StepButtons, StepHeader, useShouldPulse} from './utils';
import {useFetchCmsTemplatesQuery, useFetchTemplatesQuery} from 'services/templatesAPI';

import {FC} from 'react';
import ImageCard from 'ui/ImageCard';
import {UserLibraryUserProjectType} from 'features/types/userLibrarySlice';
import {WizardStepFC} from './NewProjectWizard';
import {convertSanityEntryToTemplate} from 'features/SanityCms/helpers';
import {emailSelector} from 'features/selectors/authSelectors';
import {initiateProject} from 'api/projectsAPI';
import {replaceProjectSource} from 'features/Dashboard/useCreateProject';
import {showErrorNotification} from 'features/Common/utils';
import {useAccountTemplatesQuery} from 'services/projectAPI';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useSelector} from 'react-redux';

export const ChooseTemplate: WizardStepFC = ({state, actions, source}) => {
  const {getAccessTokenSilently} = useAuthAlt();

  const {data: cmsData} = useFetchCmsTemplatesQuery();
  const {data: milkApiTemplates} = useFetchTemplatesQuery();

  const isCmsTemplatesActive = useFeatureFlag('cms-templates');
  const milkTemplates = isCmsTemplatesActive
    ? cmsData?.templates?.map(data => convertSanityEntryToTemplate(data))
    : milkApiTemplates;

  const {data: accountTemplates} = useAccountTemplatesQuery();
  const email = useSelector(emailSelector);

  const setTemplate = async (template: UserLibraryUserProjectType) => {
    window.analytics.track('TmpWzd -- Evt-Clk -- Template card');
    actions.setTemplateLoading(true);
    try {
      actions.setTemplateSlug(template.slug);
      const token = await getAccessTokenSilently();
      const {canvas_items, scene_frame} = await initiateProject(
        token,
        template.slug,
        email,
      );
      const items = await replaceProjectSource(canvas_items, source);
      actions.setTemplate({
        items,
        sceneFrame: scene_frame,
        templateSlug: template.slug,
      });
    } catch (error) {
      showErrorNotification({
        title: 'Something went wrong',
        message: (error as Error).message,
        error: error as Error,
      });
    }
    actions.setTemplateLoading(false);
  };

  const {shouldPulse} = useShouldPulse({
    after: 3000,
    onlyIf: !state.templateSlug,
  });

  return (
    <>
      <StepHeader heading="Choose a template" state={state} actions={actions}>
        <strong>Click on a template below</strong> to see what it looks like with your
        clip added to it.
      </StepHeader>
      <div className="space-y-4">
        {milkTemplates && accountTemplates ? (
          <>
            <TemplateGrid title="Your team templates">
              {accountTemplates.map(template => (
                <ImageCard
                  key={template.slug}
                  image={template.latest_preview}
                  label={template.name}
                  selected={template.slug === state.templateSlug}
                  onClick={() => setTemplate(template)}
                />
              ))}
            </TemplateGrid>
            <TemplateGrid title={accountTemplates.length > 0 && 'Milk templates'}>
              {milkTemplates.map((template, index) => (
                <Pulse
                  key={template.slug}
                  className="rounded-lg"
                  active={index === 0 && shouldPulse}
                >
                  <ImageCard
                    image={template.latest_preview ?? thumbnailURL(template.slug)}
                    label={template.name}
                    selected={template.slug === state.templateSlug}
                    onClick={() => setTemplate(template)}
                  />
                </Pulse>
              ))}
            </TemplateGrid>
          </>
        ) : (
          <TemplateGrid>
            <GhostCard />
            <GhostCard />
          </TemplateGrid>
        )}
      </div>
      <StepButtons
        state={state}
        actions={actions}
        overrideConfirm={{
          label: 'Next',
          disabled: !state.templateSlug || state.templateLoading,
          loading: state.templateLoading,
          onClick: async () => {
            if (!state.templateSlug) return;

            const allTemplates = [...(milkTemplates || []), ...(accountTemplates || [])];

            const template = allTemplates.find(t => t.slug === state.templateSlug);
            window.analytics.track('TmpWzd -- Lc-Fin -- Template step', {
              template: template?.name,
            });

            actions.nextStep();
          },
        }}
      />
    </>
  );
};

const TemplateGrid: FC<{title?: string | false; children: JSX.Element[]}> = ({
  title,
  children,
}) => {
  if (children.length === 0) return null;

  return (
    <div className="space-y-1">
      {title && <div className="text-sm font-semibold">{title}</div>}
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2">{children}</div>
    </div>
  );
};
