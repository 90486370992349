import {useDispatch, useSelector} from 'react-redux';

import FontSizePicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/FontSizePicker';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {updateItemAttribute} from 'features/canvasItemsSlice';

export default function TextSizePicker({projectId}: {projectId: string}) {
  const dispatch = useDispatch();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  if (!selected) return null;

  const updateActiveFontSize = (nextFontSize: number) => {
    dispatch(
      updateItemAttribute({
        projectId,
        id: selected.id,
        attribute: 'style',
        property: 'fontSize',
        newValue: nextFontSize,
      }),
    );
  };

  return (
    <div className="flex">
      <div className="flex w-full flex-col text-sm leading-5 text-gray-700">
        <FontSizePicker
          updateFontSize={updateActiveFontSize}
          activeFontSize={parseInt(selected.item.style.fontSize.toString())}
        />
      </div>
    </div>
  );
}
