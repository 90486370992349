import classNames from 'classnames';
import styled from 'styled-components';

const LayerView = styled.div.attrs(props => ({
  className: classNames('viewport-layer absolute z-10 ratio', {
    [`${props.viewType}-layer`]: true,
  }),
}))`
  z-index: ${props => props.order + 10};
  ${props => `width: ${props.width}px;`}
  ${props => `height: ${props.height}px;`}
  transform: ${props => `translate3d(${props.left}px, ${props.top}px, 0)`};
  webkit-transform: ${props => `translate3d(${props.left}px, ${props.top}px, 0)`};
`;

export default LayerView;
