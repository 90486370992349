import { ForwardedRef, forwardRef } from 'react';
import {
  ItemLayerCaption,
  ItemLayerCaptionClip,
  ItemLayerImage,
  ItemLayerSmartComponent,
  ItemLayerSquare,
  ItemLayerText,
} from 'features/EditorCanvas/components/CanvasItemLayers/CanvasItemLayer';
import { ItemLoading, isItemLoading, reconfirmItemHasStyle } from '../utils';
import { isFrameRecording, isLegacyRecording, isRecording } from 'constants/environment';

import { CanvasItem as CanvasItemType } from 'features/types/canvasItemsSlice';
import { ItemLayerVideoClip } from './CanvasItemLayers/VideoClip/ItemLayerVideoClip';
import { LayerLoadingContainer } from './CanvasItemLayers/LayerLoadingContainer';
import { MoveableLayer } from './CanvasItem/MoveableLayer';
import { ViewTypes } from 'features/EditorCanvas/constants/ViewConstants';
import { canvasItemsSelectedItemIdsSelector } from 'features/selectors/canvasStateSelectors';
import { useEditCrop } from './AppCanvas/Canvas/utils/useEditCrop';
import { useScenes } from 'features/EditorCanvas/components/CanvasTime/useScenes';
import { useSelector } from 'react-redux';
import { useSetFontReady } from 'features/Captions/Fonts';

export type CanvasItemProps = {
  projectId: string;
  itemId: string;
  onChangeSize?: () => void;
  item: CanvasItemType;
  itemLoading: ItemLoading;
  isPreview?: boolean;
};

function CanvasItem(
  { projectId, itemId, item, itemLoading, onChangeSize, isPreview = false }: CanvasItemProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  if (item.error) {
    if (isRecording()) {
      throw item.error;
    } else {
      console.error(`Item error (${itemId}):`, item.error.message);
    }
  }

  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);

  const { activeScene } = useScenes();

  const isSelected = selectedItemIds.includes(itemId);

  const editCrop = useEditCrop({ projectId, itemId, item });

  useSetFontReady({
    item: reconfirmItemHasStyle(item),
    itemId,
    projectId,
  });

  return (
    <MoveableLayer
      id={itemId}
      activeSceneId={activeScene.id}
      item={item}
      projectId={projectId}
      enabled={false}
      isSelected={isSelected}
      ref={ref}
      editCrop={editCrop}
      style={{ ...item.style, zIndex: 60 + item.order }}
      isPreview={isPreview}
    >
      <LayerLoadingContainer
        dimension={item.dimension}
        isLoading={isItemLoading(itemLoading)}
        viewType={item.viewType}
      >
        {item.viewType === ViewTypes.SmartComponent && item?.smartComponent && (
          <ItemLayerSmartComponent
            itemId={itemId}
            smartComponent={item.smartComponent}
            options={item.smartComponent.options}
            style={item.style}
            dimension={item.dimension}
          />
        )}
        {item.viewType === ViewTypes.Video && (
          <ItemLayerVideoClip
            itemId={itemId}
            itemSourceType={item.itemSource}
            itemSourceId={item.itemSourceId}
            isMuted={!!item.isMuted}
            projectId={projectId}
            playbackId={item.playbackId}
            timeOffsetSeconds={item.timeOffsetSeconds}
            playLengthSeconds={item.playLengthSeconds}
            sceneId={item.sceneId}
          />
        )}
        {item.viewType === ViewTypes.VideoClip && (
          <ItemLayerVideoClip
            itemId={itemId}
            itemSourceType={item.itemSource}
            itemSourceId={item.itemSourceId}
            isMuted={!!item.isMuted}
            projectId={projectId}
            playbackId={item.playbackId}
            timeOffsetSeconds={item.timeOffsetSeconds}
            playLengthSeconds={item.playLengthSeconds}
            sceneId={item.sceneId}
          />
        )}
        {item.viewType === ViewTypes.AudioClip && (
          <ItemLayerVideoClip
            itemId={itemId}
            itemSourceType={item.itemSource}
            itemSourceId={item.itemSourceId}
            isMuted={!!item.isMuted}
            projectId={projectId}
            playbackId={item.playbackId}
            timeOffsetSeconds={item.timeOffsetSeconds}
            playLengthSeconds={item.playLengthSeconds}
            sceneId={item.sceneId}
          />
        )}
        {item.viewType === ViewTypes.Square && (
          <ItemLayerSquare id={itemId} style={item.style} />
        )}
        {item.viewType === ViewTypes.Image && (
          <ItemLayerImage id={itemId} url={item.url} viewType={item.viewType} />
        )}
        {item.viewType === ViewTypes.Text && (
          <ItemLayerText
            id={itemId}
            style={item.style}
            text={item.text}
            onChangeSize={onChangeSize}
            isPreview={isPreview}
          />
        )}
        {item.viewType === ViewTypes.Caption && (
          <ItemLayerCaption
            projectId={projectId}
            id={itemId}
            style={item.style}
            dimension={item.dimension}
            item={item}
          />
        )}
        {item.viewType === ViewTypes.CaptionClip && (
          <ItemLayerCaptionClip
            projectId={projectId}
            id={itemId}
            style={item.style}
            dimension={item.dimension}
            item={item}
          />
        )}
      </LayerLoadingContainer>
    </MoveableLayer>
  );
}

const CanvasItemRef = forwardRef(CanvasItem);

export const CanvasItemContainer = forwardRef<HTMLDivElement, CanvasItemProps>((props, ref) => {
  const { activeScene, scenes } = useScenes();

  if (!props.item || Object.keys(props.item).length === 0) return null;

  const itemSceneId = props.item.sceneId;
  const itemScene = scenes.find(scene => scene.id === itemSceneId);

  if (!props.isPreview) {
    if (isFrameRecording() && itemSceneId !== activeScene.id) {
      return null;
    }

    if (!isLegacyRecording() && itemScene) {
      const activeSceneIndex = scenes.findIndex(scene => scene.id === activeScene.id);
      const itemSceneIndex = scenes.indexOf(itemScene);
      const distanceFromActiveScene = Math.abs(activeSceneIndex - itemSceneIndex);

      if (distanceFromActiveScene > 1) {
        return null;
      }
    }
  }

  return <CanvasItemRef {...props} ref={ref} />;
});
