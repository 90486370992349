import styled, {keyframes} from 'styled-components';

const rotate = keyframes`
  100%{
    transform: rotate(360deg);
  }
`;

const Container = styled.svg`
  animation: ${rotate} 1.67s linear infinite;
  position: relative;
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
`;

const Circle = styled.circle`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: ${dash} 1.25s ease-in-out infinite;
  stroke-linecap: round;
  stroke: currentColor;
`;

export const Spinner = ({
  size = 16,
  color,
}: {
  size?: number;
  color?: 'white' | 'black';
}) => {
  return (
    <Container
      style={{width: size, height: size, color}}
      className="text-indigo-500"
      viewBox="25 25 50 50"
    >
      <Circle
        className="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="5"
        strokeMiterlimit="10"
      />
    </Container>
  );
};
