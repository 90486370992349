import {ChevronRightIcon, ClockIcon} from '@heroicons/react/solid';
import {DashParams, routePaths} from 'routes/routesHelper';
import {MouseEvent, useRef, useState} from 'react';

import {AUDIO_PREVIEW_URL_LARGE} from 'features/Common/constants';
import {ClipLabels} from './ClipLabels';
import {ClipUploadMedia} from 'features/types/userLibrarySlice';
import {Link} from 'react-router-dom';
import {Play} from 'react-feather';
import ReactPlayer from 'vendor/react-player-milk';
import {Time} from 'features/Common/Time';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import classNames from 'classnames';
import {format} from 'date-fns';
import {removeListItem} from './utils';
import {useClipList} from './ClipListContext';
import {useUserUploadQuery} from 'services/userUploadAPI';

export function ClipListItem({
  userClip,
  userUploadId,
}: {
  userClip: UploadMediaClipType;
  userUploadId: string;
}) {
  const {
    data: userUpload,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  } = useUserUploadQuery({id: userUploadId});

  const {
    state: {selectedIdList},
    dispatch,
  } = useClipList();

  const isSelected = selectedIdList.includes(userClip.id);
  const [isPreview, setIsPreview] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const videoRef = useRef<any>(null);

  const setSelectedIdList = (newSelectedIdList: string[]) => {
    dispatch({
      type: 'update selected id list',
      payload: {
        newSelectedIdList,
      },
    });
  };

  const handleDirectCheckBoxClick = (evt: MouseEvent, clipId: string) => {
    evt.stopPropagation();
    // console.log('selectedIdList, clipId', selectedIdList, clipId);

    if (selectedIdList.includes(clipId)) {
      const newList = removeListItem(selectedIdList, clipId);
      if (newList.length === 0) {
        dispatch({
          type: 'update override checkbox clip value',
          payload: {
            newOverrideValue: false,
          },
        });
      }
      setSelectedIdList(newList);
    } else {
      setSelectedIdList([...selectedIdList, clipId]);
    }
  };

  const clipUrl = `/${routePaths.dashboard}/${DashParams.UploadsParam}/media/${userUploadId}?clip_id=${userClip.id}`;

  const handleLink = (evt: MouseEvent) => {
    window.analytics.track('Click clip play point from list', {
      user_clip_id: userClip.id,
    });
  };

  const handlePreview = () => {
    window.analytics.track(
      'Pg-Dsh-Clip-List -- Clip list item -- Evt-Clk -- Video preview',
    );
    if (!isPreview) {
      setIsPreview(true);
    }
  };

  return (
    <li>
      <div
        className={classNames(
          {
            'bg-gray-100 hover:bg-gray-200': isSelected,
            'hover:bg-gray-50': !isSelected,
          },
          'relative z-20 flex items-center space-x-4 px-2 py-4 sm:space-x-6 sm:px-4',
        )}
      >
        <div className="flex h-5 items-center">
          <input
            onChange={() => {}}
            checked={isSelected}
            onClick={evt => {
              handleDirectCheckBoxClick(evt, userClip.id);
            }}
            type="checkbox"
            className="h-4 w-4 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          />
        </div>

        <div className="block w-full">
          <div className="flex items-center">
            <div className="flex min-w-0 flex-1 items-center">
              <div className="flex-shrink-0">
                <div onClick={handlePreview} className="relative">
                  {isPreview ? (
                    <ReactPlayer
                      onReady={() => {
                        setIsVideoReady(true);
                        videoRef?.current?.player?.player?.play();
                      }}
                      ref={videoRef}
                      url={`https://stream.mux.com/${userClip.mux_playback_id}.m3u8`}
                      width="8rem"
                      height="5rem"
                      className={'h-full w-full select-none'}
                      playing={isPreview}
                      controls
                    />
                  ) : (
                    <div className="group relative">
                      <div className="trans absolute flex h-20 w-32 cursor-pointer items-center justify-center rounded-md bg-gray-800 bg-opacity-0 transition-opacity duration-75 group-hover:bg-opacity-30">
                        <Play className="flex h-8 w-8 text-white opacity-0 group-hover:opacity-100" />
                      </div>
                      {userClip.upload_media_type === ClipUploadMedia.Video && (
                        <img
                          className="h-20 w-32 rounded-md"
                          src={userClip.thumbnail_url}
                          alt=""
                        />
                      )}
                      {userClip.upload_media_type === ClipUploadMedia.Audio && (
                        <img
                          className="h-20 w-32 rounded-md"
                          src={AUDIO_PREVIEW_URL_LARGE}
                          alt="audio wave preview"
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 lg:grid-cols-3">
                <div className=" flex w-full flex-grow flex-col lg:col-span-2">
                  <Link onClick={handleLink} to={clipUrl}>
                    <p className="flex space-x-3 truncate text-sm font-medium text-indigo-600">
                      <span>{userClip.clip_name}</span>
                    </p>
                    <p className="flex space-x-3 truncate text-xs font-medium text-gray-500">
                      <span>Last updated: {format(userClip.updated_at, 'P')}</span>
                    </p>
                  </Link>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <span className="truncate-3-lines">{userClip.text_snippet}</span>
                  </p>
                </div>
                <div className="flex items-center justify-between md:space-x-4 md:pl-4">
                  <div className="hidden md:flex md:flex-row">
                    <div className="flex flex-col items-start justify-start space-y-2 text-sm text-gray-900">
                      <div className="flex items-center">
                        <ClockIcon
                          className="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="whitespace-nowrap text-sm text-gray-700">
                          {(
                            Number(userClip.end_time) - Number(userClip.start_time)
                          ).toFixed(1)}{' '}
                          sec
                        </span>
                      </div>

                      <div className="flex text-gray-400">
                        <span>
                          From: {`${new Time(Number(userClip.start_time), 's').pretty}`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <ClipLabels align={'end'} userClip={userClip} />
                    <Link onClick={handleLink} to={clipUrl}>
                      <ChevronRightIcon
                        className={classNames('h-5 w-5 flex-shrink-0', {
                          'text-indigo-500': isSelected,
                          'text-gray-400': !isSelected,
                        })}
                        aria-hidden="true"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
