import {CanvasItem, ParsedCrop} from 'features/types/canvasItemsSlice';
import {useCallback, useEffect, useLayoutEffect, useRef} from 'react';

export type MilkMessage =
  | {
      channel: 'preview-template';
      message: 'ready';
    }
  | {
      channel: 'preview-template';
      message: 'set-active-item';
      data: {itemId: string | null};
    }
  | {
      channel: 'preview-template-crop';
      message: 'start';
      data: {itemId: string};
    }
  | {
      channel: 'preview-template-crop';
      message: 'stop';
    }
  | {
      channel: 'preview-template-crop';
      message: 'scale';
      data: {direction: 'smaller' | 'larger'};
    }
  | {
      channel: 'preview-template-crop';
      message: 'on-change';
      data: {crop: ParsedCrop};
    }
  | {
      channel: 'preview-template';
      message: 'set-items';
      data: {items: Record<string, CanvasItem>};
    };

type Channel = MilkMessage['channel'];

export const useSendMessage = (destination: Window | undefined | null) => {
  const sendMessage = useCallback(
    (message: MilkMessage) => {
      if (!destination) {
        console.warn(
          'Attempted to call sendMessage without passing a destination window',
          message,
        );
        return;
      }

      destination.postMessage({milkMessage: message}, '*');
    },
    [destination],
  );

  return sendMessage;
};

function isMilkMessage(data: any): data is {milkMessage: MilkMessage} {
  return typeof data === 'object' && typeof data.milkMessage === 'object';
}

export const useOnMessage = (
  onMessage: (message: MilkMessage) => void,
  channel?: Channel,
) => {
  const onMessageRef = useRef(onMessage);
  useLayoutEffect(() => {
    onMessageRef.current = onMessage;
  }, [onMessage]);

  useEffect(() => {
    const handler = (event: MessageEvent<any>) => {
      if (event.origin !== window.location.origin) return;
      if (!isMilkMessage(event.data)) return;
      if (channel != null && event.data.milkMessage.channel !== channel) return;

      onMessageRef.current(event.data.milkMessage);
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [channel]);
};
