import {DashParams, routePaths} from 'routes/routesHelper';
import {Link, useNavigate} from 'react-router-dom';
import {
  useCreateLandingPageMutation,
  useLandingPagesQuery,
} from 'services/landingPageAPI';

import {Button} from 'ui/Button';
import {DashboardContentSkeleton} from 'features/Dashboard/common/DashboardContentSkeleton';
import {DashboardContentWrap} from 'features/Dashboard/common/DashboardContentWrap';
import {DashboardHead} from 'features/Dashboard/common/DashboardHead';
import LandingPageIndexEmptyState from './LandingPageIndexEmptyState';
import LandingPageResults from './LandingPageResults';
import cn from 'classnames';
import {useEffect} from 'react';
import {useProjectModal} from 'features/Dashboard/useProjectModal';

interface LandingPagesProps {
  isShowing: boolean;
}
export function LandingPages({isShowing}: LandingPagesProps) {
  const {data, isLoading} = useLandingPagesQuery();
  const [createLandingPage] = useCreateLandingPageMutation();
  const navigate = useNavigate();

  const {dispatch: newProjectDispatch} = useProjectModal();
  const handleNewProject = () => {
    newProjectDispatch({
      type: 'setIsModalOpen',
      payload: {
        isModalOpen: true,
      },
    });
  };

  useEffect(() => {
    window.analytics.track('Pg-Idx -- LdPgAdm -- Evt-Vwd -- Index Page');
  }, []);

  if (!data || isLoading) {
    return null;
  }

  const handleCreateLandingPage = async () => {
    window.analytics.track('Pg-Idx -- LdPgAdm -- Act-Crt -- Landing Page');
    const response = await createLandingPage({});
    if ('error' in response) return;
    navigate(
      `/${routePaths.dashboard}/${DashParams.LandingPagesParam}/${response.data.id}`,
    );
  };

  return (
    <DashboardContentSkeleton isShowing={isShowing} isLoading={false}>
      <DashboardHead title={'Landing Pages'}>
        {/* <DashboardSearchField
          placeholder={'Search Projects'}
          isSearching={isSearching}
          onSearchBlur={onSearchBlur}
          onSearchFocus={onSearchFocus}
          resultCount={filteredUserProjects.length}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        /> */}

        <span className="whitespace-nowrap">
          <Button variant="secondary" onClick={handleNewProject}>
            Start from scratch
          </Button>
        </span>
        <span className="whitespace-nowrap">
          <Button variant="primary" onClick={handleCreateLandingPage}>
            Create a Landing Page
          </Button>
        </span>
      </DashboardHead>
      <DashboardContentWrap
        className={cn(
          'flex h-full w-full min-w-full flex-grow items-start justify-center px-4 align-middle ',
        )}
      >
        {data && data.length > 0 ? (
          <LandingPageResults landingPages={data} />
        ) : (
          <LandingPageIndexEmptyState createHandler={handleCreateLandingPage} />
        )}
      </DashboardContentWrap>
    </DashboardContentSkeleton>
  );
}
