import {BaseItem, SmartComponentItem} from 'features/types/canvasItemsSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import {ColorInput} from 'features/Common/ColorInput';
import {Switch} from '@headlessui/react';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import classNames from 'classnames';
import {updateItemAttribute} from 'features/canvasItemsSlice';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

export function ProgressAnimationActiveColorPicker() {
  const projectId = useProjectId();
  const dispatch = useDispatch();
  const selectedItem = useSelector(canvasItemsSelectedItemSelector)(projectId);
  const [enabled, setEnabled] = useState(false);

  const item = selectedItem?.item as BaseItem & SmartComponentItem;
  const currentPassiveColor = item?.smartComponent?.options?.passiveColor;

  useEffect(() => {
    // console.log('running', currentPassiveColor);
    if (currentPassiveColor) {
      setEnabled(currentPassiveColor !== 'none');
    }
  }, [currentPassiveColor]);

  if (!selectedItem) return null;

  const handleChange = (
    changedValue: 'background' | 'passiveColor',
    newColor: string,
  ) => {
    dispatch(
      updateItemAttribute({
        projectId,
        id: selectedItem.id,
        attribute: 'smartComponent',
        newValue: {
          ...item.smartComponent,
          options: {
            ...item.smartComponent.options,
            [changedValue]: newColor,
          },
        },
      }),
    );
  };

  const handlePassiveSwitch = (newEnabledValue: boolean) => {
    // console.log('newEnabledValue', newEnabledValue);
    setEnabled(newEnabledValue);
    if (newEnabledValue === false) {
      handleChange('passiveColor', 'none');
    }
  };

  const backgroundColor = item.smartComponent.options.background;
  const passiveColor = item.smartComponent.options.passiveColor;

  return (
    <div className="mt-6 w-full space-y-3">
      <label className="block text-sm font-bold leading-5 text-gray-700">
        Progress bar colors
      </label>
      <div className="space-y-4">
        <div>
          <ColorInput
            label="Active color"
            color={backgroundColor}
            onChange={color => {
              handleChange('background', color.hex);
            }}
            type="shape_background"
          />
          <p className="mt-2 text-xs italic">Progress bar color</p>
        </div>
        <div className="flex flex-col space-y-3">
          <div>
            <label className="mb-3 mb-2 block text-sm font-medium leading-5 text-gray-700">
              Progress bar background
            </label>
            <Switch.Group as="div" className="flex items-center">
              <Switch
                checked={enabled}
                onChange={enabled => {
                  handlePassiveSwitch(enabled);
                }}
                className={classNames(
                  enabled ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3">
                <span className="text-sm font-normal text-gray-900">Show/hide</span>
              </Switch.Label>
            </Switch.Group>
          </div>
          {enabled && (
            <ColorInput
              label="Inactive section color"
              color={passiveColor}
              onChange={color => {
                handleChange('passiveColor', color.hex);
              }}
              type="shape_background"
            />
          )}
        </div>
      </div>
    </div>
  );
}
