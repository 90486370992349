import {RecordingFormSessionContext} from 'features/Dashboard/DashboardPage/ContentRecordingForms/RecordingFormSessionContext';
import {ReactElement} from 'react';

export const RecordingFormsContainerWrap = ({children}: {children: ReactElement}) => {
  return (
    <RecordingFormSessionContext.Provider>
      {children}
    </RecordingFormSessionContext.Provider>
  );
};
