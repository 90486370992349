import {BASE_SHARE_URL} from 'constants/environment';
import {replaceItemIds, useCreateProject} from 'features/Dashboard/useCreateProject';
import {SmartComponents} from 'features/EditorCanvas/constants/ItemToolbarMapping';
import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {
  emailSelector,
  isAnonymousSelector,
  userMetadataSelector,
} from 'features/selectors/authSelectors';
import {BaseItem, SmartComponentItem} from 'features/types/canvasItemsSlice';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {startExportProject} from 'features/userProjectDownloadSlice';
import produce from 'immer';
import {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAuthToken} from 'services/utils';
import {Spinner} from 'ui/Spinner';
import {LayoutCalculatorRef} from '../LayoutCalculator';
import {useDesign, useWizardState, useWizardStateId} from '../State';
import {StepHeading, TwoColumns, useProjectDownload, useUpload} from '../utils';

export const DownloadStep = () => {
  const {download: wizardDownload, clip} = useWizardState();
  const {download} = useProjectDownload(wizardDownload?.downloadId);

  const isAnonymous = useSelector(isAnonymousSelector);
  const email = useSelector(emailSelector);
  const metadata = useSelector(userMetadataSelector);

  const userEmail = isAnonymous ? metadata?.notification_email : email;

  if (!download || !clip) {
    return (
      <div className="flex justify-center py-9">
        <Spinner size={32} />
      </div>
    );
  }

  const downloadUrl = `${BASE_SHARE_URL}/a/${download.id}/download`;

  if (download.is_loading) {
    const overlay = (
      <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <Spinner size={42} color="white" />
      </div>
    );

    return (
      <TwoColumns preview={{overlay}}>
        <StepHeading title="Downloading..." />
        <div className="rounded-lg bg-blue-50 px-4 py-5">
          <h3 className="text-base font-medium leading-6 text-gray-900">
            Don&lsquo;t worry, you can close this page at any time.
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              We will email you{' '}
              {userEmail && <span className="font-medium">at {userEmail}</span>} when the
              download is finished.
            </p>
          </div>
        </div>
        <span className="focus:outline-none flex w-full cursor-wait items-center justify-center rounded-md border bg-gray-50 py-3 px-3 text-sm font-medium  text-gray-600 hover:bg-gray-100 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">
          Preparing download...
        </span>
      </TwoColumns>
    );
  }

  return (
    <TwoColumns>
      <StepHeading title="Your download is ready" />
      <div className="flex space-x-4">
        <a href={`${BASE_SHARE_URL}/a/${download.id}`} className="block flex-1">
          <span className="focus:outline-none flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">
            Share
          </span>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={downloadUrl}
          className="focus:outline-none flex flex-1 items-center justify-center rounded-md border border-transparent bg-indigo-50 py-3 px-8 text-base font-medium text-indigo-700 hover:bg-indigo-100 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
        >
          Download
        </a>
      </div>
    </TwoColumns>
  );
};

export const useCreateProjectFromWizard = (upload: UserUploadsType | undefined) => {
  const {title} = useDesign();
  const layoutCalculator = useRef<LayoutCalculatorRef>(null);
  const createProject = useCreateProject();

  const createProjectFromWizard = async () => {
    if (!upload) return;
    if (!layoutCalculator.current) return;

    const {items, sceneFrame} = await layoutCalculator.current.getLayout();

    const itemsWithNewIds = replaceItemIds(items);

    const audioWaveId = Object.keys(itemsWithNewIds).find(id => {
      const item = itemsWithNewIds[id];
      return (
        item.viewType === ViewTypes.SmartComponent &&
        item.smartComponent.id === SmartComponents.AudioWaveform
      );
    });

    if (audioWaveId) {
      const playableId = Object.keys(itemsWithNewIds).find(id => {
        return [ViewTypes.AudioClip, ViewTypes.Video, ViewTypes.VideoClip].includes(
          itemsWithNewIds[id].viewType,
        );
      });

      if (playableId) {
        const audioWaveItem = itemsWithNewIds[audioWaveId] as BaseItem &
          SmartComponentItem;

        itemsWithNewIds[audioWaveId] = produce(audioWaveItem, draftItem => {
          draftItem.smartComponent.options.targetPlayableMediaId = playableId;
        });
      }
    }

    const projectId = await createProject({
      name: title ?? 'Untitled project',
      items: itemsWithNewIds,
      sceneFrame,
      replaceIds: false,
    });

    return projectId;
  };

  return {createProjectFromWizard, layoutCalculator};
};

export const useDownloadProject = () => {
  const dispatch = useDispatch();

  const {setDownload, clip, design} = useWizardState();
  const {upload} = useUpload();
  const {createProjectFromWizard, layoutCalculator} = useCreateProjectFromWizard(upload);
  const [wizardStateId] = useWizardStateId();

  const download = async () => {
    const token = await getAuthToken();

    const projectId = await createProjectFromWizard();
    if (!projectId) return;

    const downloadId = await new Promise<string>((resolve, reject) => {
      dispatch(
        startExportProject(
          token,
          projectId,
          1,
          result => {
            if (typeof result === 'string') resolve(result);
            else reject(result);
          },
          {
            type: 'focused-flow',
            wizardStateId: wizardStateId!,
          },
        ),
      );
    });

    setDownload({projectId, downloadId});
  };

  return {
    download,
    layoutCalculatorProps: {
      ref: layoutCalculator,
      design: design,
      media: upload && {upload, clip},
      title: design.title,
    },
  };
};
