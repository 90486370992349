import {useFetchCmsTemplatesQuery, useFetchTemplatesQuery} from 'services/templatesAPI';

import {TemplateInfo} from './TemplateInfo';
import {convertSanityEntryToTemplate} from 'features/SanityCms/helpers';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useParams} from 'react-router-dom';

export function TemplatePage() {
  const {data: cmsData} = useFetchCmsTemplatesQuery();
  const {data: milkApiTemplates} = useFetchTemplatesQuery();

  const isCmsTemplatesActive = useFeatureFlag('cms-templates');

  const milkTemplates =
    (isCmsTemplatesActive
      ? cmsData?.templates?.map(data => convertSanityEntryToTemplate(data))
      : milkApiTemplates) ?? [];

  const {id} = useParams() as Record<string, string>;
  const template = milkTemplates.find(template => template.slug === id);

  return (
    <main className="flex-1 focus:outline-none" tabIndex={0}>
      <div className="relative mx-auto max-w-6xl md:px-8 xl:px-0">
        <div className="pt-10 pb-16">
          {template ? <TemplateInfo template={template} /> : null}
        </div>
      </div>
    </main>
  );
}
