// Refactor TODO (jacques): Get refetchOnFocus working

import {UserUpload} from 'features/Dashboard/DashboardPage/ContentBrandKit/UploadButton';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {baseApi} from './baseAPI';

export type AccountColor = {
  id: string;
  color_hex: string;
  created_at: number;
  updated_at: number;
  account_id: string;
  name?: string;
};

export type AccountBrand = {
  colors: AccountColor[];
  logos: UserUploadsType[];
};

const accountBrandAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['AccountColor', 'AccountLogo']})
  .injectEndpoints({
    endpoints: builder => ({
      fetchAccountBrand: builder.query<AccountBrand, void>({
        query: () => ({
          url: `account_brand`,
          method: 'GET',
          errorTitle: 'Error loading brand data',
        }),
        providesTags: ['AccountColor', 'AccountLogo'],
      }),

      createAccountColor: builder.mutation<void, {colorHex: string; name?: string}>({
        query: ({colorHex, name}) => ({
          url: `account_brand/colors`,
          method: 'POST',
          errorTitle: 'Error adding color',
          data: {color_hex: colorHex, name},
        }),
        invalidatesTags: ['AccountColor'],
      }),

      deleteAccountColor: builder.mutation<void, {id: string}>({
        query: ({id}) => ({
          url: `account_brand/colors/${id}`,
          method: 'DELETE',
          errorTitle: 'Error deleting color',
        }),
        invalidatesTags: ['AccountColor'],
      }),

      createAccountLogo: builder.mutation<
        UserUploadsType,
        {file: UserUpload; accountId: string; email: string}
      >({
        query: ({file, accountId, email}) => ({
          url: `user_uploads`,
          method: 'POST',
          errorTitle: 'Error adding logo',
          data: {
            user_upload: {
              ...file,
              account_id: accountId,
              account_logo: true,
              email,
            },
          },
        }),
        invalidatesTags: ['AccountLogo'],
      }),

      deleteAccountLogo: builder.mutation<void, {id: string}>({
        query: ({id}) => ({
          url: `user_uploads/delete`,
          method: 'POST',
          errorTitle: 'Error deleting logo',
          data: {
            user_upload: {
              id: id,
            },
          },
        }),
        invalidatesTags: ['AccountLogo'],
      }),
    }),
  });

export const {
  useFetchAccountBrandQuery,
  useCreateAccountColorMutation,
  useDeleteAccountColorMutation,
  useCreateAccountLogoMutation,
  useDeleteAccountLogoMutation,
} = accountBrandAPI;
