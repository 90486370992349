import {Card} from 'features/Common/Card';
import {CardHeader} from 'features/Common/CardHeader';
import {useEffect} from 'react';
import {SettingsColorsCard} from './SettingsColorsCard';

export default function SettingsVisualGovernance() {
  useEffect(() => {
    window.analytics.track('Loaded settings page - viewed visual governance page');
  }, []);

  return (
    <div className="mt-6 space-y-6 divide-y divide-gray-200">
      <SettingsColorsCard />
      {/* 
      <Card>
        <CardHeader subtitle="Coming soon" title="Fonts" />
        <div></div>
      </Card> */}
    </div>
  );
}
