import {CableMessagePayload} from './types';
import {UserType} from 'features/types/authSlice';
import {announceDiscretelyUserToApi} from 'features/authSlice';
import {updateUserProjects} from 'features/userLibrarySlice';

enum ProjectsCableActions {
  PreviewUrl = 'preview_url',
}

const projectsCable = (
  dispatch: any,
  getAccessTokenSilently: any,
  message: CableMessagePayload,
  user: UserType,
) => {
  if (message.body.action === ProjectsCableActions.PreviewUrl) {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        if (user) {
          dispatch(updateUserProjects(token));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }
};

export default projectsCable;
