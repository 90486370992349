import {Dialog, Transition} from '@headlessui/react';

import {Fragment} from 'react';
import {ModelContentListOption} from './ModelContentListOption';
import {ModelContentSearchColorLabels} from './ModelContentSearchColorLabels';
import {ModelContentSearchTextContent} from './ModelContentSearchTextContent';

export function ClipListFilterModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (param: boolean) => void;
}) {
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto" onClose={setOpen}>
          <div
            className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
            style={{fontSize: 0}}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden md:inline-block md:h-screen md:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-75"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-75"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <div className="flex w-full transform text-left text-base transition md:my-8 md:inline-block md:max-w-sm md:px-4 md:align-middle lg:max-w-sm">
                <ModelContentListOption open={open} setOpen={setOpen} />

                <ModelContentSearchTextContent open={open} setOpen={setOpen} />
                <ModelContentSearchColorLabels open={open} setOpen={setOpen} />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
