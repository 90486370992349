import {useTimeEffect} from './useTimeEffect';
import {useDuration} from './useDuration';
import {usePlayback} from './usePlayback';

export type EndBehavior = 'stop' | 'pause' | 'noop';

export const EndPlayback = ({behavior = 'stop'}: {behavior?: EndBehavior}) => {
  const durationMs = useDuration();
  const {stop, pause} = usePlayback();

  useTimeEffect(
    ({currentTime}) => {
      if (currentTime >= durationMs) {
        if (behavior === 'stop') {
          stop();
        } else if (behavior === 'pause') {
          pause();
        }
      }
    },
    [durationMs, stop, pause, behavior],
  );

  return null;
};
