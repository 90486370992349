import * as Sentry from '@sentry/react';

import {AppProviders} from 'AppProviders';
import {AppWrapStylesProvider} from 'AppWrap';
import {RoutesRoot} from 'RoutesRoot';

function App() {
  return (
    <AppWrapStylesProvider>
      <AppProviders>
        <RoutesRoot />
      </AppProviders>
    </AppWrapStylesProvider>
  );
}

export default Sentry.withProfiler(App);
