import {ApiAccountCheck, AuthenticationUserCheck} from 'RoutesRoot';
import {Route, Routes} from 'react-router-dom';

import {AcceptInvitePage} from 'pages/Onboarding/AcceptInvitePage';
import {CreateAccountPage} from 'features/NewOnboarding/CreateAccountPage';
import {NotificationWrap} from 'pages/AppEntrance/User/NotificationWrap';
import {PaymentPlanPick} from 'pages/AppEntrance/Account/PaymentPlanPick';
import {RequireLoggedOut} from 'features/Auth/Stytch';
import {Suspense} from 'react';
import {UserSignInPage} from 'pages/Auth/UserSignInPage';

/**
 * Routes under /auth/*
 */
export const RoutesUserRelated = () => (
  <Routes>
    <Route
      path="plan"
      element={
        <AuthenticationUserCheck>
          <ApiAccountCheck>
            <NotificationWrap>
              <PaymentPlanPick />
            </NotificationWrap>
          </ApiAccountCheck>
        </AuthenticationUserCheck>
      }
    />
    <Route path="join" element={<CreateAccountPage />} />
    <Route
      path={'accept-invite'}
      element={
        <AuthenticationUserCheck>
          <ApiAccountCheck>
            <NotificationWrap>
              <AcceptInvitePage />
            </NotificationWrap>
          </ApiAccountCheck>
        </AuthenticationUserCheck>
      }
    />
    <Route
      path="login"
      element={
        <RequireLoggedOut>
          <UserSignInPage />
        </RequireLoggedOut>
      }
    />
  </Routes>
);
