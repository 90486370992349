import './file-input.css';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import '@uppy/status-bar/dist/style.css';

import { DashParams, routePaths } from 'routes/routesHelper';
import { ProgressBar, StatusBar } from '@uppy/react';
import {
  UppyFile,
  getMetadata,
  handleError,
  handleRestrictionFailed,
  handleUploadError,
} from 'features/FileUploader/utils';
import { fetchUserClips, uploadUserFile } from 'features/userLibrarySlice';
import {
  isAudioMedia,
  isImageMedia,
  isVideoMedia,
} from 'features/Dashboard/DashboardUploadDetails/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';

import AwsS3 from '@uppy/aws-s3';
import { Button } from 'ui/Button';
import { GetRootState } from 'configureStore';
import { Link } from 'react-router-dom';
import { Loading } from './Loading';
import { NOTIFICATION_BASE } from 'features/Notifications/constants';
import NotificationError from 'features/Notifications/NotificationError';
import ReactTooltip from 'react-tooltip';
import SelectedUploadedItemDetail from '../../../components/AppCanvas/CanvasLeftSideNav/SelectedUploadedItemDetail';
import { SidebarAssetMenu } from './SidebarAssetMenu';
import { UPLOAD_COMPANION_URL } from 'constants/environment';
import UploadIcon from 'features/Dashboard/shared/UploadIcon';
import { UploadMediaClipType } from 'services/uploadMediaClipAPI';
import Uppy from '@uppy/core';
import Url from '@uppy/url';
import UserClipCard from '../../../components/AppCanvas/UserClipCard';
import { UserUploadsType } from 'features/types/userLibrarySlice';
import Zoom from '@uppy/zoom';
import { accessUserUpload } from 'features/Dashboard/DashboardPage/ContentBrandKit/UploadButton';
import { accountDetailsSelector } from 'features/selectors/authSelectors';
import { addImageCanvasItem } from 'features/canvasItemsSlice';
import { canvasStateSelector } from 'features/selectors/canvasStateSelectors';
import classNames from 'classnames';
import ms from 'ms';
import { selectCanvasProject } from 'features/selectors/canvasItemsSelectors';
import { store } from 'react-notifications-component';
import styled from 'styled-components';
import useAssetsList from './hooks/useAssetsList';
import { useAuthAlt } from 'features/Auth/useAuthAlt';
import { useInfiniteQuery } from 'services/useInfiniteQuery';
import { useProjectId } from '../../../useProjectId';
import { useScenes } from 'features/EditorCanvas/components/CanvasTime/useScenes';
import { useSidebarMenu } from '../../SidebarMenuContext';
import { userLibrarySelector } from 'features/selectors/userLibrarySelectors';
import { userUploadAPI } from 'services/userUploadAPI';
import { v4 as uuidv4 } from 'uuid';

const FileListItem = styled.div.attrs((props: { selected: boolean | null }) => ({
  className: classNames(
    'flex w-full cursor-pointer flex-row hover:bg-gray-100 text-sm font-semibold text-gray-500 rounded-md items-center justify-start text-left px-2 py-2',
    {
      'bg-gray-200': props.selected,
    },
  ),
})) <{ selected: boolean | null }>``;

export function Assets() {
  const countFilesAdded = useRef(0);
  const countFilesSuccess = useRef(0);
  const { dispatch: sidebarDispatch } = useSidebarMenu();

  const {
    hasNextPage,
    isFetching,
    fetchNextPage,
    resultPages: pages,
    isLoading,
    isSuccess,
    isError,
  } = useInfiniteQuery(userUploadAPI.endpoints.listUserUploads, {
    getNextPageParam: lastPage => lastPage.next ?? undefined,
  });

  const setUploadTypeView = (newActiveUploadMenu: string) => {
    sidebarDispatch({
      type: 'update upload type menu',
      activeUploadMenu: newActiveUploadMenu,
    });
  };

  const { getAccessTokenSilently } = useAuthAlt();
  const dispatch = useDispatch();
  const [menuMinimized, setMenuMinimized] = useState(false);
  const [selectedItem, setSelectedItem] = useState<null | UserUploadsType>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const assetsList = useAssetsList({ searchTerm });

  const projectId = useProjectId();

  const { isLoading: isLoadingProject } = useSelector(canvasStateSelector);

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const fileItemClicked = (item: UserUploadsType) => {
    window.analytics.track('Item file clicked');
    if (selectedItem && item.id === selectedItem.id) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  };

  const uppy = useMemo(() => {
    const onFileAdded = (file: UppyFile) => {
      const isContainingIllegalCharacter = [',', '?', '#', '%', '&'].some(character =>
        file.meta.name.includes(character),
      );

      if (isContainingIllegalCharacter) {
        uppy.removeFile(file.id);
        const message =
          'Uploaded file names can not contain illegal characters ?, #, %, and &. Please rename and reupload.';
        store.addNotification({
          ...NOTIFICATION_BASE,
          content: <NotificationError title={'Upload error'} message={message} />,
          type: 'warning',
          dismiss: {
            click: true,
            duration: 5000,
            onScreen: false,
          },
        });
      } else {
        // console.log('isContainingIllegalCharacter', isContainingIllegalCharacter);
        // console.log('const onFileAdded = (file) => {', file);
        countFilesAdded.current = countFilesAdded.current + 1;
        window.analytics.track('User added file to upload', {
          number_files_added: countFilesAdded.current,
          ...getMetadata(file),
        });
      }
    };
    const onUploadSuccess = (file: UppyFile) => {
      // console.log('const onUploadSuccess = (file) => {', file);
      countFilesSuccess.current = countFilesSuccess.current + 1;
      window.analytics.track('User file upload success', {
        number_files_added: countFilesSuccess.current,
        ...getMetadata(file),
      });
    };
    const onBeforeFileAdded = (currentFile: any, files: any) => {
      uppy.setFileMeta(currentFile.id, { originalFilename: currentFile.name });
      const modifiedFile = {
        ...currentFile,
        name: uuidv4(),
      };
      return modifiedFile;
    };
    const onFileRemove = (file: UppyFile) => {
      // console.log('const onFileRemove = (file) => {', file);
      window.analytics.track('User file removed', {
        ...getMetadata(file),
      });
    };
    return (
      Uppy({
        autoProceed: true,
        onBeforeFileAdded: onBeforeFileAdded,
        locale: {
          strings: {
            chooseFiles: 'Upload media',
            dropPasteImportFiles:
              'Drop any audio, video or caption file, %{browseFiles}, or import from:', // 'Drop files here, paste, %{browseFiles} or import from:',
            youCanOnlyUploadX: {
              0: 'You can only upload %{smart_count} file',
              1: 'You can only upload %{smart_count} files',
            },
            youHaveToAtLeastSelectX: {
              0: 'You have to select at least %{smart_count} file',
              1: 'You have to select at least %{smart_count} files',
            },
            // **NOTE**: This string is called `exceedsSize2` for backwards compatibility reasons.
            // See https://github.com/transloadit/uppy/pull/2077
            exceedsSize2: 'This file exceeds maximum allowed size of %{size}',
            youCanOnlyUserUploadsTypes:
              'Sorry! Currently you can only upload: %{types}. This will change in the future!',
            companionError: 'Connection with Companion failed',
          },
        },
      })
        .use(AwsS3, {
          // limit: 2,
          timeout: ms('1 minute'),
          companionUrl: UPLOAD_COMPANION_URL,
        })
        // or
        .use(Zoom, {
          companionUrl: UPLOAD_COMPANION_URL,
        })
        .use(Url, {
          companionUrl: UPLOAD_COMPANION_URL,
          locale: {},
        })
        .on('error', handleError)
        .on('upload-error', handleUploadError)
        .on('restriction-failed', handleRestrictionFailed)
        .on('file-added', onFileAdded)
        .on('file-remove', onFileRemove)
        .on('upload-success', onUploadSuccess)

        .on('complete', (result: any) => {
          result.successful.forEach((file: UppyFile) => {
            (async () => {
              try {
                const token = await getAccessTokenSilently();
                const userUpload = accessUserUpload(file);
                dispatch(uploadUserFile(token, userUpload));
              } catch (e) {
                console.error(e);
              }
            })();
          });

          if (result.successful.length === 1) {
            const file = result.successful[0];
            const extension = file.extension;
            const type = file.type;
            if (isAudioMedia(type)) {
              setUploadTypeView('Audio');
            }
            if (isImageMedia(type)) {
              setUploadTypeView('Images');
            }
            if (isVideoMedia(type)) {
              setUploadTypeView('Videos');
            }
            const bucketFileName = file.meta.key;
            const bucketKey = bucketFileName.replace(new RegExp(`.${extension}$`), '');
          }
          // navigate('/upload');
          // console.log('successful files:', result.successful);
          // console.log('failed files:', result.failed);

          // window.location.href = '/app/uploads/' + bucketKey
        })
    );
  }, [dispatch, getAccessTokenSilently]);

  const { email, accountId } = useSelector(accountDetailsSelector);

  const { loadingUploads, loadingClips } = useSelector(userLibrarySelector);

  useEffect(() => {
    if (!isLoadingProject) {
      window.analytics.track('Fetching user upload and clips in canvas');
      if (accountId && email) {
        (async () => {
          try {
            const token = await getAccessTokenSilently();

            dispatch(fetchUserClips(token, false));
          } catch (e) {
            console.error(e);
          }
        })();
      } else {
        (async () => {
          try {
            const token = await getAccessTokenSilently();

            dispatch(fetchUserClips(token, false));
          } catch (e) {
            console.error(e);
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingProject]);

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('change', (event: any) => {
        const files = Array.from(event.target.files);

        files.forEach((file: any) => {
          try {
            uppy.addFile({
              source: 'file input',
              name: file.name,
              type: file.type,
              data: file,
            });
          } catch (err) {
            // @ts-ignore
            if (err.isRestriction) {
              // handle restrictions
              console.log('Restriction error:', err);
            } else {
              // handle other errors
              console.error(err);
            }
          }
        });
      });

      // it’s probably a good idea to clear the `<input>`
      // after the upload or when the file was removed
      // (see https://github.com/transloadit/uppy/issues/2640#issuecomment-731034781)
      uppy.on('file-removed', () => {
        inputRef.current.value = null;
      });

      uppy.on('complete', () => {
        inputRef.current.value = null;
      });
    }
  }, [inputRef]);

  const FILE_NAME_SHORTENING_LENGTH = 20;

  const { activeScene } = useScenes();

  function startAndEnd(str: string) {
    if (!str) {
      return str;
    }
    if (str.length > FILE_NAME_SHORTENING_LENGTH) {
      return `${str.substr(0, 9)}...${str.substr(str.length - 8, str.length)}`;
    }
    return str;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="relative flex h-full w-full select-none flex-col">
      {!menuMinimized && (
        <>
          {(loadingUploads || loadingClips) && <Loading />}
          <div
            className={classNames('p-3 pb-0', {
              hidden: loadingUploads || loadingClips,
            })}
          >
            <Link to={`/${routePaths.dashboard}/${DashParams.UploadsParam}`}>
              <Button fullWidth>
                <span>Upload a file</span>
              </Button>
            </Link>
          </div>
          <div className="flex w-full flex-wrap">
            <SidebarAssetMenu />
          </div>
          <ProgressBar uppy={uppy} fixed hideAfterFinish />
          <div
            className={classNames('flex h-full flex-1 flex-col overflow-y-scroll p-3', {
              hidden: loadingUploads || loadingClips,
            })}
          >
            <div className="flex w-full flex-col justify-start space-y-2">
              <StatusBar
                uppy={uppy}
                hideUploadButton
                hideAfterFinish={false}
                showProgressDetails
              />
              {assetsList.map((passedItem, id) => {
                if (passedItem.type === 'clip') {
                  const item = passedItem.item as UploadMediaClipType;
                  return (
                    <UserClipCard
                      clip={item}
                      key={`user-clip-${id}`}
                      projectId={projectId}
                    />
                  );
                }
                if (passedItem.type === 'userUpload') {
                  const item = passedItem.item as UserUploadsType;
                  const {
                    file_type: fileType,
                    upload_url: uploadUrl,
                    file_name: fileName,
                    id: userUploadId,
                  } = item;
                  return (
                    <div key={`icon-file-${id}`}>
                      {isImageMedia(fileType) && (
                        <div className="group relative cursor-pointer overflow-hidden rounded-md">
                          <div className="relative flex w-full cursor-pointer flex-col items-start justify-start space-y-2 border bg-gray-50 p-3 text-left text-sm font-semibold text-gray-500 hover:bg-gray-100">
                            <img
                              src={uploadUrl}
                              className="w-full"
                              alt={`For ${fileName}`}
                            />
                            <span>{fileName && startAndEnd(fileName)}</span>
                          </div>
                          <div
                            className={classNames(
                              'absolute inset-0 flex items-center justify-center bg-gray-700 bg-opacity-80 text-center opacity-0 transition-opacity',
                              'group-hover:opacity-100',
                            )}
                          >
                            <div>
                              <div className="mb-2 font-bold text-white opacity-80">
                                Click to...
                              </div>
                              <Button
                                variant={'tertiary'}
                                size="large"
                                onClick={() => {
                                  dispatch(
                                    addImageCanvasItem({
                                      projectId,
                                      url: uploadUrl,
                                      fileName,
                                      sceneId: activeScene.id,
                                    }),
                                  );
                                }}
                              >
                                Insert image
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                      {!isImageMedia(fileType) && (
                        <FileListItem
                          selected={selectedItem && userUploadId === selectedItem.id}
                          onClick={() => {
                            fileItemClicked(item);
                          }}
                          data-tip-disable={
                            fileName && fileName.length < FILE_NAME_SHORTENING_LENGTH
                          }
                          data-tip={fileName}
                        >
                          <UploadIcon fileType={fileType} />
                          {fileName && startAndEnd(fileName)}
                        </FileListItem>
                      )}
                      {fileName && fileName.length > 20 && (
                        <ReactTooltip
                          className="bg-gray-600 font-semibold"
                          effect="solid"
                        />
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </>
      )}
      {selectedItem && selectedItem.id && (
        <SelectedUploadedItemDetail
          uploadId={selectedItem.id}
          projectId={projectId}
          userUpload={selectedItem}
        />
      )}
    </div>
  );
}
