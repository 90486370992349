import {OrientationType} from '../TemplateSelectionPopup';
import classNames from 'classnames';
import {OnChange} from 'features/Common/utils';

interface TemplateOrientationPickerProps {
  setActiveOrientation: OnChange<OrientationType>;
  activeOrientation: OrientationType;
}

const TemplateOrientationPicker = ({
  setActiveOrientation,
  activeOrientation,
}: TemplateOrientationPickerProps) => {
  return (
    <div className="flex select-none">
      <span className="relative z-0 inline-flex space-x-1">
        <button
          onClick={() => {
            setActiveOrientation(OrientationType.Landscape);
          }}
          type="button"
          style={{
            width: 90,
          }}
          className={classNames(
            {
              'hover:bg-indigo-200': activeOrientation === OrientationType.Landscape,
              'bg-indigo-100': activeOrientation === OrientationType.Landscape,
            },
            'focus:outline-none   relative -ml-px inline-flex flex-col items-center justify-end space-y-2 rounded-md rounded-r-md bg-white  px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10',
          )}
        >
          <svg
            width={28}
            height={20}
            viewBox="0 0 28 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x={1}
              y={1}
              width={26}
              height={18}
              rx={3}
              fill="white"
              stroke="#23313B"
              strokeWidth={2}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 10L13 12V8L16 10Z"
              fill="#00ADEF"
              stroke="#23313B"
              strokeWidth={2}
              strokeLinejoin="round"
            />
          </svg>
          <span className="pb-1 text-xs">Landscape</span>
        </button>

        {/* <button
          onClick={() => {
            setActiveOrientation(OrientationType.Portrait);
          }}
          type="button"
          style={{
            width: 90,
          }}
          className={classNames(
            {
              'hover:bg-indigo-200': activeOrientation === OrientationType.Portrait,
              'bg-indigo-100': activeOrientation === OrientationType.Portrait,
            },
            ' rounded-md flex-col justify-end space-y-2 relative inline-flex items-center px-2 py-2  bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none'
          )}
        >
          <svg
            width={20}
            height={28}
            viewBox="0 0 20 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x={1}
              y={1}
              width={18}
              height={26}
              rx={3}
              fill="white"
              stroke="#23313B"
              strokeWidth={2}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 14L9 16V12L12 14Z"
              fill="#00ADEF"
              stroke="#23313B"
              strokeWidth={2}
              strokeLinejoin="round"
            />
          </svg>
          <span className="pb-1 text-xs">Portrait</span>
        </button> */}
        <button
          onClick={() => {
            setActiveOrientation(OrientationType.Square);
          }}
          type="button"
          style={{
            width: 90,
          }}
          className={classNames(
            {
              'hover:bg-indigo-200': activeOrientation === OrientationType.Square,
              'bg-indigo-100': activeOrientation === OrientationType.Square,
            },
            ' focus:outline-none relative inline-flex flex-col items-center justify-end space-y-2 rounded-md rounded-l-md bg-white  px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10',
          )}
        >
          <span className="sr-only">Previous</span>
          {/* Heroicon name: solid/chevron-left */}
          <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x={1}
              y={1}
              width={18}
              height={18}
              rx={3}
              fill="white"
              stroke="#23313B"
              strokeWidth={2}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 10L9 12V8L12 10Z"
              fill="#00adef"
              stroke="#23313B"
              strokeWidth={2}
              strokeLinejoin="round"
            />
          </svg>
          <span className="pb-1 text-xs">Square</span>
        </button>
      </span>
    </div>
  );
};

export default TemplateOrientationPicker;
