import {DeleteButton, LargeDashedButton, ListContainer, ListSkeleton} from './utils';
import {Plus, UploadCloud} from 'react-feather';
import {UploadButton, useUppy} from './UploadButton';
import {
  useCreateAccountLogoMutation,
  useDeleteAccountLogoMutation,
  useFetchAccountBrandQuery,
} from 'services/accountBrandAPI';

import {Button} from 'ui/Button';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {accountDetailsSelector} from 'features/selectors/authSelectors';
import {useSelector} from 'react-redux';

const Logo = ({logo}: {logo: UserUploadsType}) => {
  const [deleteLogo] = useDeleteAccountLogoMutation();

  return (
    <li
      key={logo.id}
      className="group relative flex w-full items-center justify-between rounded-md border bg-gray-50 px-2 py-2"
    >
      <div
        className="h-10 w-full bg-contain bg-left bg-no-repeat"
        style={{backgroundImage: `url('${logo.upload_url}')`}}
        role="img"
        aria-label={logo.file_name}
      />
      <DeleteButton
        label={logo.file_name}
        onClickConfirm={() => deleteLogo({id: logo.id})}
      />
    </li>
  );
};

export const useUploadLogoUppy = (
  onUploadComplete?: (upload: UserUploadsType) => void,
) => {
  const [createAccountLogo] = useCreateAccountLogoMutation();
  const {accountId, email} = useSelector(accountDetailsSelector);

  return useUppy({
    postProcess: async file => {
      if (!accountId) throw new Error('accountId missing');
      if (!email) throw new Error('email missing');

      const result = await createAccountLogo({file, accountId, email});
      'data' in result && onUploadComplete?.(result.data);
    },
  });
};

export const Logos = () => {
  const {data, isLoading} = useFetchAccountBrandQuery();

  const uppy = useUploadLogoUppy();

  if (!data || isLoading) {
    return <ListSkeleton />;
  }
  return (
    <div className="">
      <div className="flex flex-col">
        <div className="my-3 flex px-6">
          <div className="flex w-full flex-row items-center justify-between">
            <span className="text-sm text-gray-500">
              Keep your brand or company logos in one place by uploading them to your
              Brand Kit.
            </span>
            <span className="pl-2">
              <UploadButton uppy={uppy}>
                {fileInput => (
                  <Button
                    _className="text-xs"
                    variant="primary"
                    fullWidth
                    leftIcon={Plus}
                  >
                    Add logo
                    {fileInput}
                  </Button>
                )}
              </UploadButton>
            </span>
          </div>
        </div>
      </div>
      {data.logos.length > 0 && (
        <div className="flex flex-col">
          <div className="my-3 flex flex-col space-y-1 px-6">
            <ul className="space-y-2 ">
              {data.logos.map(logo => (
                <Logo logo={logo} key={logo.id} />
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
