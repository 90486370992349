import {DimensionType, ItemWithSource} from 'features/types/canvasItemsSlice';
import WordFrame, {WordFrameOptions} from 'features/Captions/WordFrame';
import {WordWithDimensions, getWordsWithDimensions, useFontData} from './Fonts';
import {
  getTrimmedTranscript,
  transcriptRangeForItem,
} from 'features/TranscriptEditor/transcriptUtils';
import {useTranscript, useTranscriptIdAndLanguage} from './Transcripts';

import {useMemo} from 'react';
import {useSetDependencyReady} from 'features/EditorCanvas/utils';

/**
 * Returns wordFrames for a caption layer.
 */
export const useWordFrames = ({
  itemId,
  item,
  projectId,
}: {
  itemId: string;
  projectId: string;
  item: ItemWithSource;
}) => {
  const {width, height} = item.dimension;

  const font = useFontData(item.style.fontFamily, projectId);
  const {family: fontFamily, data: fontData} = font || {};

  const {transcriptId, language} = useTranscriptIdAndLanguage({projectId, itemId, item});
  const {transcript} = useTranscript(transcriptId, language);

  const wordFrames = useMemo(() => {
    if (!fontData || !transcript) return;

    const range = transcriptRangeForItem({item});
    const trimmedTranscript = getTrimmedTranscript(transcript, range, true).flat(1);

    const fontSizePx = parseInt(item.style.fontSize.toString());

    const words = getWordsWithDimensions({
      paragraph: trimmedTranscript,
      font: fontData,
      fontSizePx: parseInt(item.style.fontSize.toString()),
      lineHeight: item.style.lineHeight,
    });

    return getWordFrames({width, height}, words, language, fontSizePx);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fontData,
    transcript,
    width,
    height,
    // @ts-ignore
    item.playLengthSeconds,
    // @ts-ignore
    item.timeOffsetSeconds,
    item.style.fontSize,
    item.style.lineHeight,
    item.viewType,
  ]);

  useSetDependencyReady({
    projectId,
    itemId,
    dependency: 'wordFrames',
    ready: wordFrames != null,
  });

  return {wordFrames, fontFamily: fontFamily || item.style.fontFamily};
};

function getWordFrames(
  dimension: DimensionType,
  words: WordWithDimensions[],
  language: string,
  fontSizePx: number,
): WordFrame[] {
  const sceneFrames: WordFrame[] = [];

  let frameOptions = {
    box: {
      width: dimension.width,
      height: dimension.height,
    },
  };

  if (language !== 'en') {
    frameOptions = {
      box: {
        width: dimension.width,
        height: dimension.height - fontSizePx,
      },
    };
  }

  const lastFrame = (newSceneFrames: WordFrame[]): WordFrame =>
    newSceneFrames[newSceneFrames.length - 1];

  const createNewWordFrame = (
    newSceneFrames: WordFrame[],
    frameOptions: WordFrameOptions,
  ) => {
    newSceneFrames.push(new WordFrame(frameOptions));
  };

  words.forEach(wordWithDimensions => {
    // If scene is empty, create first frame
    if (!sceneFrames.length) {
      createNewWordFrame(sceneFrames, frameOptions);
    }

    const wasWordAdded = lastFrame(sceneFrames).addWord(wordWithDimensions);
    if (!wasWordAdded) {
      createNewWordFrame(sceneFrames, frameOptions);
      lastFrame(sceneFrames).addWord(wordWithDimensions);
    }
  });

  return sceneFrames;
}
