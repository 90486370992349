import {Minus, Plus, Search} from 'react-feather';
import {atom, useRecoilValue} from 'recoil';

import {CanvasButton} from '../CanvasButton';
import {HStack} from 'features/Common/Stack';

export const zoomState = atom({
  key: 'zoom',
  default: 1,
});

const ZoomIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    width="16px"
    height="16px"
    style={{marginRight: 4}}
  >
    <path
      fillRule="evenodd"
      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
      clipRule="evenodd"
    />
  </svg>
);

export const ZoomToolbar = ({
  setZoom,
  fitZoom,
}: {
  setZoom: (zoom: number) => void;
  fitZoom: number;
}) => {
  const zoom = useRecoilValue(zoomState);

  const zoomPercentage = Math.round(zoom * 100);
  const fitZoomPercentage = Math.round(fitZoom * 100);

  const fit = zoomPercentage === fitZoomPercentage;

  return (
    <HStack
      className="focus:outline-none absolute bottom-0 right-0 mb-2 mr-2 select-none text-sm"
      style={{zIndex: 250}}
      spacing={1}
    >
      <CanvasButton onClick={() => setZoom(zoom - zoom * 0.1)}>
        <Minus size={16} />
      </CanvasButton>
      <CanvasButton leftIcon={Search} onClick={() => setZoom(fitZoom)}>
        {fit ? 'Fit' : `${zoomPercentage}%`}
      </CanvasButton>
      <CanvasButton onClick={() => setZoom(zoom + zoom * 0.1)}>
        <Plus size={16} />
      </CanvasButton>
    </HStack>
  );
};
