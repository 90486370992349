import {TimeCode} from 'features/EditorCanvas/components/WorkspaceTopNavBar/TimeCode';
import {useEffect} from 'react';
import {useCorrectionModalOpen} from './TranscriptText/TranscriptCorrectionModal';

export default function DetailVideoPlayerControls() {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === ' ' && event.target === document.body) {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, []);

  const correctionModalOpen = useCorrectionModalOpen();

  return (
    <div className="w-full">
      <TimeCode skipControls enableShortcuts={!correctionModalOpen} />
    </div>
  );
}
