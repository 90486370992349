import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import classNames from 'classnames';
import {useAccountLabels} from '../ContentSettings/Labels/useAccountLabels';

export const ClipLabels = ({
  userClip,
  align = 'start',
  colorScheme = 'light',
}: {
  userClip: UploadMediaClipType;
  colorScheme?: 'light' | 'dark';
  align?: 'end' | 'start';
}) => {
  const {filteredLabels} = useAccountLabels();
  const activeLabelColors =
    filteredLabels.length > 0
      ? filteredLabels.filter(labelColor =>
          userClip?.labels && userClip?.labels.length > 0
            ? userClip.labels.find(label => label.label_value === labelColor.name)
            : false,
        )
      : [];

  return activeLabelColors.length > 0 ? (
    <div
      className={classNames(`justify-top flex flex-col space-y-1 px-1 pl-2 opacity-80`, {
        'items-start': align === 'start',
        'items-end': align === 'end',
      })}
    >
      {activeLabelColors.reverse().map((activeLabelColor, index) => (
        <div key={index} className="group relative">
          <span
            key={index}
            className={classNames(
              'absolute z-30 hidden items-center justify-center space-x-1 rounded-full border p-0.5 px-2 pl-4 group-hover:flex',
              {
                'left-0': align === 'start',
                'right-0': align === 'end',
                'bg-gray-50': colorScheme === 'light',
                '!bg-gray-900 !bg-opacity-80 font-medium backdrop-blur-sm backdrop-filter':
                  colorScheme === 'dark',
              },
            )}
          >
            <span
              className={classNames(
                'absolute left-2 h-2 w-2 rounded-full',
                activeLabelColor.bgColor,
              )}
            />
            <span className="whitespace-nowrap text-xs">{activeLabelColor.name}</span>
          </span>
          <span
            className={classNames(
              'relative flex items-center justify-center space-x-1 truncate rounded-full border p-0.5 px-2 pl-4',
              {
                'bg-gray-50': colorScheme === 'light',
                '!bg-gray-900 !bg-opacity-80 font-medium backdrop-blur-sm backdrop-filter':
                  colorScheme === 'dark',
              },
            )}
            style={{
              maxWidth: 110,
            }}
          >
            <span
              className={classNames(
                'absolute left-2 h-2 w-2 rounded-full',
                activeLabelColor.bgColor,
              )}
            />
            <span className="truncate whitespace-nowrap text-xs">
              {activeLabelColor.name}
            </span>
          </span>
        </div>
      ))}
    </div>
  ) : null;
};
