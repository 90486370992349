import {BaseItem, CanvasItem, CaptionItem} from 'features/types/canvasItemsSlice';

import {CSSProperties} from 'react';
import LayerCaptionsWrap from './LayerUploadCaptionsWrap';

const lineHeight = 1.25;

function ItemLayerCaption({
  projectId,
  style,
  id,
  dimension,
  item,
}: {
  projectId: string;
  style: CSSProperties;
  id: string;
  dimension: CanvasItem['dimension'];
  item: BaseItem & CaptionItem;
}) {
  const {fontSize} = style as {fontSize: string};
  const {height, width} = dimension;

  return (
    <LayerCaptionsWrap
      projectId={projectId}
      itemId={id}
      width={width}
      height={height}
      lineHeight={lineHeight}
      fontSize={fontSize}
      item={item}
    />
  );
}

export default ItemLayerCaption;
