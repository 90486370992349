import { ArrowLeft, ArrowRight, Play, Square } from 'react-feather';
import { Dispatch, FC, useEffect, useRef, useState } from 'react';
import { EditTranscript, useCaptions } from './EditTranscript';
import { FocusedFlowToolbar, TipToolbar } from '../Toolbar';
import { Modal, ModalButtons } from 'ui/Modal';
import { SelectionProvider, useSelection } from 'features/TranscriptEditor/selection';
import { StepHeading, useUpload } from '../utils';
import {
  isAnonymousSelector,
  userMetadataSelector,
} from 'features/selectors/authSelectors';
import { objToTime, timeObjToMs } from 'features/Common/Time';

import { Button } from 'ui/Button';
import { ClipStepTimeline } from './ClipStepTimeline';
import { EndBehavior } from 'features/EditorCanvas/components/CanvasTime/EndPlayback';
import { EnterEmailModal } from '../EnterEmailModal';
import { FocusedFlowCanvasPreview } from '../CanvasPreview';
import { Selection } from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/TrimTimeline';
import { Spinner } from 'ui/Spinner';
import { TimeCode } from 'features/EditorCanvas/components/WorkspaceTopNavBar/TimeCode';
import { TimeProvider } from 'features/EditorCanvas/components/CanvasTime/TimeProvider';
import { UserUploadsType } from 'features/types/userLibrarySlice';
import { useDebounceCallback } from '@react-hook/debounce';
import { useHotkeys } from 'react-hotkeys-hook';
import { usePlayback } from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import { useSelector } from 'react-redux';
import { useWatchElementSize } from 'features/Common/useElementSize';
import { useWizardState } from '../State';

export type SetSelection = Dispatch<Selection | undefined>;

const ClipStep = ({ upload }: { upload: UserUploadsType }) => {
  const { selection } = useSelection();
  const { setClip, nextStep, previousStep } = useWizardState();

  const { seekTo, isPlaying, toggle, stop, play } = usePlayback();

  const captions = useCaptions(upload);

  const selectionStart = selection?.start;
  const selectionEnd = selection?.end;

  const selectionStartRef = useRef(selectionStart);
  const selectionEndRef = useRef(selectionEnd);
  const debouncedSeekTo = useDebounceCallback(seekTo, 500);

  useEffect(() => {
    if (!selection) return;

    if (selectionStartRef.current !== selectionStart) {
      debouncedSeekTo({ timeMs: 0 });
    } else if (selectionEndRef.current !== selectionEnd) {
      setTimeout(() => {
        if (selectionEndRef.current != null && selectionStartRef.current != null) {
          debouncedSeekTo({
            timeMs: selectionEndRef.current - selectionStartRef.current - 3000,
          });
        }
      });
    }

    selectionStartRef.current = selectionStart;
    selectionEndRef.current = selectionEnd;
  }, [selection, debouncedSeekTo, selectionStart, selectionEnd]);

  useHotkeys(
    'space',
    event => {
      event.preventDefault();
      toggle();
    },
    [toggle],
  );

  const [containerRef, containerSize] = useWatchElementSize();

  const [captionsModal, setCaptionsModal] =
    useState<'notice' | 'enterEmail' | null>(null);

  const isAnonymous = useSelector(isAnonymousSelector);
  const metadata = useSelector(userMetadataSelector);

  return (
    <div className="flex flex-1 flex-col space-y-6">
      <StepHeading
        title="Select your highlight"
        subtitle="Select the part of your file that you would like to turn into a clip"
        center
      />
      <TimeCode skipControls />
      <div className="flex flex-1">
        <div className="w-[35%] p-5" ref={containerRef}>
          <div className="min-h-[200px]">
            <div className="h-0">
              <FocusedFlowCanvasPreview maxHeight={Math.max(containerSize.height, 200)} />
            </div>
          </div>
        </div>
        <div className="relative w-[65%] rounded-t-xl bg-gray-100">
          <div className="absolute inset-0 overflow-y-auto">
            <EditTranscript upload={upload} selection={selection} />
          </div>
        </div>
      </div>
      <div className="!mt-0 rounded-xl !rounded-tr-none bg-gray-100 py-3">
        <div className="relative h-16 w-full">
          <ClipStepTimeline upload={upload} />
        </div>
      </div>
      {selection ? (
        <FocusedFlowToolbar>
          <Button variant="secondary" leftIcon={ArrowLeft} onClick={() => previousStep()}>
            Go back
          </Button>
          <div className="flex items-center space-x-2">
            <Button
              variant="secondary"
              leftIcon={isPlaying ? Square : Play}
              onClick={() => {
                if (isPlaying) {
                  stop();
                } else {
                  seekTo({ timeMs: 0 });
                  play();
                }
              }}
            >
              Preview clip
            </Button>
            <Button
              variant="primary"
              rightIcon={ArrowRight}
              onClick={() => {
                const range = objToTime(selection);
                setClip({ range });

                if (captions.loading || captions.processing) {
                  if (isAnonymous && !metadata?.notification_email) {
                    setCaptionsModal('enterEmail');
                  } else {
                    setCaptionsModal('notice');
                  }

                  return;
                }

                nextStep();
              }}
            >
              Use this clip
            </Button>
          </div>
        </FocusedFlowToolbar>
      ) : (
        <TipToolbar>Click and drag the timeline to create a clip</TipToolbar>
      )}
      <Modal
        title="Generating captions"
        size="small"
        open={captionsModal === 'notice'}
        onClose={() => setCaptionsModal(null)}
      >
        <div>
          Before you can download your video, your clip&lsquo;s captions need to finish
          generating.
        </div>
        <div className="font-semibold">
          Please try again once captions have been generated.
        </div>
        <ModalButtons confirm={{ label: 'Ok', onClick: () => setCaptionsModal(null) }} />
      </Modal>
      <EnterEmailModal
        title="Generating captions"
        open={captionsModal === 'enterEmail'}
        onSubmit={() => setCaptionsModal(null)}
      >
        <div>
          Before you can download your video, your clip&lsquo;s captions need to finish
          generating.
        </div>
        <div className="font-semibold">
          Please try again once captions have been generated.
        </div>
      </EnterEmailModal>
    </div>
  );
};

const ClipStepContainer = () => {
  const { upload, isLoading, isProcessing } = useUpload();

  const isAnonymous = useSelector(isAnonymousSelector);

  if (isLoading || !upload) {
    return (
      <div className="flex justify-center py-9">
        <Spinner size={32} />
      </div>
    );
  }

  if (isProcessing) {
    return (
      <div className="flex flex-col items-center space-y-6 py-9">
        <Spinner size={32} />
        <div className="text-center text-3xl font-bold">Your upload is processing</div>
        <div className="space-y-6">
          <div>On average, turnaround time is roughly 15-30% of the audio file's duration, with a minimum processing time of around 20-30 seconds</div>
          {!isAnonymous && (
            <div className="space-y-2 rounded-lg border bg-gray-50 p-5 text-center">
              <div>Don&lsquo;t worry, you can close this page at any time.</div>
              <div className="font-medium">We&lsquo;ll email you when it is ready.</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <ClipTimeProvider>
      <ClipStep upload={upload} />
    </ClipTimeProvider>
  );
};

export const ClipTimeProvider: FC<{ endBehavior?: EndBehavior }> = ({
  children,
  endBehavior,
}) => {
  const { clip, setClip } = useWizardState();
  const clipRange = clip?.range && timeObjToMs(clip.range);

  return (
    <SelectionProvider
      initialSelection={clipRange}
      onChange={selection => {
        if (!selection) return;

        const range = objToTime(selection);
        setClip({ range });
      }}
    >
      <SelectionTimeProvider endBehavior={endBehavior}>{children}</SelectionTimeProvider>
    </SelectionProvider>
  );
};

const SelectionTimeProvider: FC<{ endBehavior?: EndBehavior }> = ({
  endBehavior,
  children,
}) => {
  const { upload } = useUpload();
  const { selection } = useSelection();
  let duration = (upload?.duration ?? 0) * 1000;
  if (selection) {
    duration = selection.end - selection.start;
  }

  return (
    <TimeProvider durationMs={duration} endBehavior={endBehavior}>
      {children}
    </TimeProvider>
  );
};

export { ClipStepContainer as ClipStep };
