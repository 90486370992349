import {
  CanvasEditMode,
  CanvasItem,
  CanvasScene,
  PayloadWithId,
  ProjectCanvas,
} from './types/canvasItemsSlice';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {ItemsLoading} from './EditorCanvas/utils';
import {ViewTypes} from './EditorCanvas/constants/ViewConstants';
import arrayUnique from 'array-unique';
import {objMap} from './Common/utils';

export type CanvasLayerDepedency = 'font' | 'wordFrames' | 'media';

export interface CanvasStateProps {
  selected: {
    selectedItemIds: string[];
    groupActiveItem?: string;
  };
  canvasEditMode: CanvasEditMode;
  isLoading: boolean;
  items: ItemsLoading;
}

/**
 * List of item dependencies associated to layer view types.
 */
export const itemDependencies: Record<CanvasLayerDepedency, ViewTypes[]> = {
  font: [ViewTypes.Text, ViewTypes.Caption, ViewTypes.CaptionClip],
  wordFrames: [ViewTypes.Caption, ViewTypes.CaptionClip],
  media: [ViewTypes.Video, ViewTypes.VideoClip],
};

/**
 * Review the viewType of a layer and add the respective canvas layer's dependency list.
 *
 * The dependency list determines whether layer is loading or not.
 */
export const getItemDependencies = (viewType: ViewTypes): CanvasLayerDepedency[] => {
  const dependencies: CanvasLayerDepedency[] = [];

  for (const [dependency, types] of Object.entries(itemDependencies)) {
    if (types.includes(viewType)) {
      dependencies.push(dependency as CanvasLayerDepedency);
    }
  }

  return dependencies;
};

export const initialState: CanvasStateProps = {
  selected: {
    selectedItemIds: [],
  },
  canvasEditMode: null,
  isLoading: false,
  items: {},
};

const canvasStateSlice = createSlice({
  name: 'canvasState',
  initialState,
  reducers: {
    setProjectLoading(state, action: PayloadWithId<{loading: boolean}>) {
      const {projectId, loading} = action.payload;

      state.isLoading = loading;
    },
    projectStartupPreparationSuccessful(state, action) {
      const {projectId} = action.payload;
      state.isLoading = false;
    },
    projectStartupPreparationFailed(state, action) {
      const {projectId} = action.payload;
      state.isLoading = false;
    },
    canvasStateStartupPreparationItemsSetup(
      state,
      action: PayloadAction<{
        canvasItems: Record<string, CanvasItem>;
      }>,
    ) {
      const {canvasItems} = action.payload;

      let newCanvasItems: ItemsLoading = {};

      // Put all items in a loading state
      newCanvasItems = objMap(canvasItems, (item, itemId) => ({
        viewType: item.viewType,
        itemId,
        loading: getItemDependencies(item.viewType),
      }));

      state.items = newCanvasItems;
    },
    setDependencyReady(
      state,
      action: PayloadAction<{
        itemId: string;
        dependency: CanvasLayerDepedency;
        ready?: boolean;
      }>,
    ) {
      const {itemId, dependency, ready = true} = action.payload;

      const item = state.items[itemId];
      if (!item?.loading) return;

      const index = item.loading.indexOf(dependency);

      if (ready) {
        if (index !== -1) item.loading.splice(index, 1);
      } else {
        if (index === -1) item.loading.push(dependency);
      }
    },
    updateCanvasEditMode(state, action: PayloadAction<{canvasEditMode: CanvasEditMode}>) {
      const {canvasEditMode} = action.payload;
      state.canvasEditMode = canvasEditMode;
    },
    updateSelectedItems(
      state,
      action: PayloadAction<{
        selectedItemIds: string[];
        clickedItem?: string;
        project: ProjectCanvas;
      }>,
    ) {
      const {selectedItemIds, clickedItem, project} = action.payload;

      let itemsToSelect = [...selectedItemIds];

      const getItemsInGroup = (items: Record<string, CanvasItem>, groupId: string) => {
        const itemsInGroup: Record<string, CanvasItem> = {};
        for (const [id, item] of Object.entries(items)) {
          if (item.groupId === groupId) {
            itemsInGroup[id] = item;
          }
        }
        return Object.keys(itemsInGroup);
      };

      for (const selectedItem of selectedItemIds) {
        const groupId = project.items[selectedItem].groupId;
        if (!groupId) continue;
        itemsToSelect.push(...getItemsInGroup(project.items, groupId));
      }

      itemsToSelect = arrayUnique(itemsToSelect);
      itemsToSelect = itemsToSelect.filter(id => !project.items[id].locked);

      state.selected.selectedItemIds = itemsToSelect;

      if (
        clickedItem &&
        project.items[clickedItem].groupId &&
        !project.items[clickedItem].locked
      ) {
        state.selected.groupActiveItem = clickedItem;
      } else {
        state.selected.groupActiveItem = undefined;
      }
    },
    groupSelectItem(state, action: PayloadAction<{project: ProjectCanvas; id: string}>) {
      const {id, project} = action.payload;

      const item = project.items[id];
      if (!item.groupId) {
        state.selected.groupActiveItem = undefined;
        return;
      }

      state.selected.groupActiveItem = id;
    },
    addSelectedItem(state, action) {
      const {selectedItemId, item} = action.payload;
      if (item.locked) return;
      state.selected.selectedItemIds = [selectedItemId];
    },
    deselectItems(state) {
      state.selected.selectedItemIds = [];
    },
    deselectItemsNotInScene(
      state,
      action: PayloadAction<{items: Record<string, CanvasItem>; sceneId: string}>,
    ) {
      const {items, sceneId} = action.payload;

      state.selected.selectedItemIds = state.selected.selectedItemIds.filter(id => {
        if (!items[id]) return false;
        return items[id].sceneId === sceneId;
      });
    },
  },
});

export const {
  setProjectLoading,
  projectStartupPreparationFailed,
  projectStartupPreparationSuccessful,
  canvasStateStartupPreparationItemsSetup,
  setDependencyReady,
  updateCanvasEditMode,
  updateSelectedItems,
  groupSelectItem,
  addSelectedItem,
  deselectItems,
  deselectItemsNotInScene,
} = canvasStateSlice.actions;

export default canvasStateSlice.reducer;
