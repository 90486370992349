import { Button, ButtonStyle } from 'features/Common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { AccountColor } from 'services/accountBrandAPI';
import { AccountLabelColor } from 'features/types/userLibrarySlice';
import { Card } from 'features/Common/Card';
import { CardHeader } from 'features/Common/CardHeader';
import { LabelFormInput } from './Labels/LabelFormInput';
import { LabelListItem } from './Labels/LabelListItem';
import { authAccountAccountSelector } from 'features/selectors/authSelectors';
import { fetchUserClips } from 'features/userLibrarySlice';
import { useAccountLabels } from './Labels/useAccountLabels';
import { useAuthAlt } from 'features/Auth/useAuthAlt';
import { userLibrarySelector } from 'features/selectors/userLibrarySelectors';

export function SettingsLabelCard() {
  const account = useSelector(authAccountAccountSelector);
  const [activeField, setActiveField] = useState<HTMLInputElement | null>(null);
  const [isNewLabelOpen, setIsNewLabelOpen] = useState(false);
  const [activeEditingId, setActiveEditingId] = useState<string | null>(null);
  const { userClips } = useSelector(userLibrarySelector);

  const { filteredLabels } = useAccountLabels();

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuthAlt();

  const handleShowLabel = () => {
    if (!isNewLabelOpen) {
      setIsNewLabelOpen(true);
    }
    if (activeField) {
      activeField.focus();
    }
  };

  useEffect(() => {
    if (activeField) {
      activeField.focus();
    }
  }, [activeField]);

  useEffect(() => { }, [activeEditingId]);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(fetchUserClips(token, false));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const handleLabelEdit = (color: null | AccountLabelColor) => {
    if (color) {
      setActiveEditingId(color.name);
    } else {
      setActiveEditingId(color);
    }
  };

  const countClips = (color: AccountLabelColor) => {
    let countResults = 0;
    if (userClips && userClips.length) {
      const labelColors = userClips
        .map(clip => clip.labels)
        .flat()
        .map(label => label.label_value)
        .filter(label => label === color.name);

      countResults = labelColors.length;
    }

    return countResults
  };

  return (
    <Card>
      <CardHeader
        title="Labels"
        subtitle={`Manage team ${account?.name} label settings`}
      />
      <div className="">
        <div className="flex flex-col">
          <div className="my-3 flex px-6">
            <div className="flex w-full flex-row items-center justify-between">
              <span className="text-sm text-gray-500">
                Labels make it easy to organize your workspace around task types or focus
                areas.
              </span>
              <span className="pl-2">
                <Button style={ButtonStyle.PRIMARY} onClick={handleShowLabel}>
                  New label
                </Button>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="my-3 flex flex-col space-y-1 px-6">
            {isNewLabelOpen && (
              <LabelFormInput
                isNew={true}
                closeInput={() => {
                  setIsNewLabelOpen(false);
                }}
                setActiveField={setActiveField}
                handleLabelEdit={handleLabelEdit}
                color={{
                  name: '',
                  bgColor: 'bg-blue-300',
                  selectedColor: 'ring-blue-300',
                }}
              />
            )}
            {filteredLabels.map((color, labelIdx) => (
              <LabelListItem
                handleLabelEdit={handleLabelEdit}
                key={labelIdx}
                clipCount={countClips(color)}
                isEditing={activeEditingId === color.name}
                color={color}
              />
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}
