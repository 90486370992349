import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';
import {useUpdateLandingPageMutation} from 'services/landingPageAPI';
import {Button} from 'ui/Button';

type EditableLandingPageFieldProps = {
  landingPageId: string;
  landingPageComponentId?: string;
  fieldName: 'title' | 'description' | ['component_attributes', 'url'];
  fieldValue: string | null;
  className?: string;
  placeholder?: string;
};

export default function EditableLandingPageField({
  landingPageComponentId,
  landingPageId,
  fieldName,
  fieldValue,
  className,
  placeholder,
}: EditableLandingPageFieldProps) {
  const [updateLandingPage, {isLoading: isUpdating}] = useUpdateLandingPageMutation();
  const [tempFieldValue, setTempFieldValue] = useState(fieldValue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitField = async () => {
    window.analytics.track('Submit updated field from landing page form');
    if (tempFieldValue == null) return;

    if (landingPageComponentId) {
      await handleComponentItemChange(landingPageComponentId, fieldName, tempFieldValue);
    } else {
      await handleLandingPageFieldChange(fieldName as string, tempFieldValue);
    }
  };

  const handleLandingPageFieldChange = (fieldName: string, newFieldValue: string) => {
    return updateLandingPage({
      id: landingPageId,
      [fieldName]: newFieldValue,
    });
  };

  const handleComponentItemChange = (
    landingPageComponentId: string,
    fieldName: string | string[],
    newFieldValue: string,
  ) => {
    const component = {id: landingPageComponentId};
    if (Array.isArray(fieldName)) {
      component[fieldName[0]] = {[fieldName[1]]: newFieldValue};
    } else {
      component[fieldName] = newFieldValue;
    }

    return updateLandingPage({
      id: landingPageId,
      landing_page_components_attributes: [component],
    });
  };

  const input = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTempFieldValue(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (!input.current) return;
    if (tempFieldValue == null) return;
    if (input.current.innerText === tempFieldValue) return;

    input.current.innerText = tempFieldValue;
  }, [tempFieldValue]);

  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="-m-2 flex w-full items-start">
      <div
        className={classNames(
          className,
          'focus-within:outline-none relative w-full cursor-text rounded-md bg-black bg-opacity-0 p-2 ring-0 ring-indigo-500 ring-opacity-0 transition focus-within:ring-2 focus-within:ring-opacity-50 hover:bg-opacity-5',
        )}
      >
        <div className="relative">
          {!tempFieldValue && placeholder && !isFocused && (
            <div className="pointer-events-none absolute inset-0 text-gray-500">
              {placeholder}
            </div>
          )}
          <div
            className="focus:outline-none"
            contentEditable
            onKeyUp={event => {
              setTempFieldValue(event.currentTarget.innerText);
            }}
            ref={input}
            onBlur={() => {
              setIsSubmitting(true);
              submitField().finally(() => {
                setIsSubmitting(false);
              });
              setIsFocused(false);
            }}
            onFocus={() => setIsFocused(true)}
          />
        </div>
      </div>
      {isFocused && (
        <div className="ml-2">
          <Button variant="secondary">Save</Button>
        </div>
      )}
    </div>
  );
}
