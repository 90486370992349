const faqs = [
  {
    question: 'What content should I upload?',
    answer:
      '<a target="_blank" class="text-indigo-500 underline" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Audio or video with clear spoken English dialogue</a> works best. We transcribe the audio track for Animated Captions alongside an audio waveform.',
  },
  {
    question: 'Do you have examples of other content created in ',
    answer:
      'Yes! View our <a target="_blank" class="text-indigo-500 underline" href="https://www.milkvideo.com/examples?ref=podcast-audiogram">examples page</a> to see videos created with <a class="text-indigo-500 underline" href="https://changelog.milkvideo.com/2021/08/28/zoom-integration/?ref=podcast-audiogram" target="_blank">Zoom recordings</a>, YouTube videos, and other content.',
  },

  {
    question: 'Can I customize the fonts or design of the audiogram?',
    answer:
      'You can customize any detail of the audiogram through our advanced editor. To use the advanced editor, please <a target="_blank" class="text-indigo-500 underline" href="https://milkvideo.com/?ref=podcast-audiogram">sign up for an account</a> and you can customize the video using our drag-and-drop editor.',
  },
  {
    question: 'How do you make money?',
    answer:
      'The audiogram tool is free to use. We have a <a target="_blank" class="text-indigo-500 underline" href="https://milkvideo.com/pricing?ref=podcast-audiogram">paid plan</a> for creators which also has a free tier. If you want to edit your designs, combine multiple files, or add transcript glossary terms, you will need to <a target="_blank" class="text-indigo-500 underline" href="https://milkvideo.com/?ref=podcast-audiogram">sign up for an account</a>.',
  },

  {
    question: 'Can I upload my own font?',
    answer:
      'Yes! <a target="_blank" class="text-indigo-500 underline" href="https://milkvideo.com/?ref=podcast-audiogram">Sign up for an account</a> and you can upload your own font in our <a class="text-indigo-500 underline" href="https://changelog.milkvideo.com/2021/08/07/brand-kit/?ref=podcast-audiogram" target="_blank">Brand Kit</a> to use in the audiogram.',
  },

  {
    question: 'Is there a limit to the podcast or video I can upload?',
    answer:
      'Nope! The processing time will be slower for longer content, but you will get great results with a 1 minute clip or a 1 hour podcast.',
  },
  {
    question: 'What happens to my upload after I finish?',
    answer:
      'You can read our <a class="text-indigo-500 underline" href="https://milkvideo.com/terms?ref=podcast-audiograms">Terms of Service</a>. We delete all uploaded files unless you decide to create an account after your download is complete. We want to avoid paying for storage costs!',
  },
  {
    question: 'Can I make a clip using a YouTube link?',
    answer:
      'If you have a Youtube link of your own video, you can sign up through our website and upload it through our YouTube downloader. Simply paste the YouTube link, and we will download the file for you.',
  },

  // More questions...
];

export function UploadFaq() {
  const handleIntercom = () => {
    window.Intercom?.('showNewMessage');
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 lg:py-20">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900">
              Frequently asked questions
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              {"We aren't Wikipedia, but the answer to your question might be here."} If
              you want to talk to a person, contact{' '}
              <span
                onClick={handleIntercom}
                className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500"
              >
                live chat here
              </span>
              .
            </p>
          </div>
          <div className="mt-12 lg:col-span-2 lg:mt-0">
            <dl className="space-y-12">
              {faqs.map(faq => (
                <div key={faq.question}>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    <div dangerouslySetInnerHTML={{__html: faq.answer}} />
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
