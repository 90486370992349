import {LoadingSpinnerInline} from 'features/EditorCanvas/components/shared/LoadingSpinnerInline';
import cn from 'classnames';

export default function ShareDownloadStatus({
  isError,
  isLoading,
  status,
}: {
  isError: boolean;
  isLoading: boolean;
  status: string;
}) {
  if (isError) {
    return (
      <div className="mt-6 flex w-full">
        <div id="artboard-author" className={cn('mx-auto mt-4 w-full')}>
          <span className="mb-2 flex select-none text-sm font-medium text-gray-700">
            Status
          </span>
          <div className="overflow-hidden bg-gray-50 shadow sm:rounded-lg">
            <div className="border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:gap-4 sm:py-5 sm:px-6">
                  <dd className="mt-1 flex flex-row text-sm text-gray-900 sm:mt-0">
                    An error has occured with the download.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-6 flex w-full">
      <div id="artboard-author" className={cn('mx-auto mt-4 w-full')}>
        <span className="mb-2 flex select-none text-sm font-medium text-gray-700">
          Status
        </span>
        <div className="overflow-hidden bg-gray-50 shadow sm:rounded-lg">
          <div className="border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:grid sm:gap-4 sm:py-5 sm:px-6">
                <dd className="mt-1 flex flex-row text-sm text-gray-900 sm:mt-0">
                  {isLoading ? (
                    <>
                      <LoadingSpinnerInline /> {status}
                    </>
                  ) : (
                    'Video is ready'
                  )}
                </dd>
              </div>
              {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Duration</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {sec2time(durationMs / 1000)}
                </dd>
              </div> */}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
