import { ClipListFilter } from './ClipListFilter';
import ClipListHeaderMenu from './ClipListHeaderMenu';
import { FilterListItems } from './FilterListItems';
import { UploadMediaClipType } from 'services/uploadMediaClipAPI';
import { useClipList } from './ClipListContext';

export function ClipListHeader({
  userClips,
  sortedUploadClips,
}: {
  userClips: UploadMediaClipType[];
  sortedUploadClips: UploadMediaClipType[];
}) {
  const {
    state: { activeListFilters, selectedIdList, overrideCheckboxClip },
    dispatch,
  } = useClipList();

  const setOverrideCheckboxClip = (newOverrideValue: boolean) => {
    dispatch({
      type: 'update override checkbox clip value',
      payload: {
        newOverrideValue,
      },
    });
  };

  const setSelectedIdList = (newSelectedIdList: string[]) => {
    dispatch({
      type: 'update selected id list',
      payload: {
        newSelectedIdList,
      },
    });
  };

  const handleOverrideCheckboxClick = () => {
    if (selectedIdList.length) {
      setSelectedIdList([]);
      setOverrideCheckboxClip(false);
    } else {
      const ids = sortedUploadClips.map(clip => clip.id);
      setSelectedIdList([...ids]);
      setOverrideCheckboxClip(true);
    }
  };

  const contentFilters = activeListFilters.filter(
    filter => filter.filter_target === 'content',
  );

  const colorLabelFilters = activeListFilters.filter(
    filter => filter.filter_target === 'color-label',
  );

  const hasContentFilters = contentFilters.length > 0;
  const hasColorLabelFilters = colorLabelFilters.length > 0;
  const hasFilters = hasColorLabelFilters || hasContentFilters;

  const displayListCount = () => {
    if (hasFilters) {
      return `${sortedUploadClips.length} / ${userClips.length}`;
    }
    if (selectedIdList.length > 0) {
      return `${selectedIdList.length} / ${userClips.length}`;
    } else {
      return userClips.length;
    }
  };

  return (
    <div className="relative flex flex-wrap items-center justify-between space-x-4 border-b px-2 py-2 sm:space-x-6 sm:px-4">
      <div className="inline-flex flex-wrap items-center space-x-3">
        <div className="inline-flex items-center justify-center">
          <fieldset>
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  checked={overrideCheckboxClip}
                  onClick={() => {
                    handleOverrideCheckboxClick();
                  }}
                  type="checkbox"
                  className="h-4 w-4 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </div>
              <div className="ml-3 text-sm"></div>
            </div>
          </fieldset>

          <span className="flex items-center justify-center space-x-2 whitespace-nowrap rounded-full border-b-2 border-transparent py-3 px-1 text-sm font-medium text-gray-500 sm:inline-block">
            <span className="text-gray-800">Clips</span>
            <span className="text-gray-400">{displayListCount()}</span>
          </span>
        </div>
        {selectedIdList.length > 0 && <ClipListHeaderMenu />}
        <FilterListItems />
        <ClipListFilter />
      </div>
      {/* <div className="inline-flex items-center justify-end space-x-3">
        <ClipListSortOrder />
      </div> */}
    </div>
  );
}
