import {Fragment, useEffect, useMemo, useState} from 'react';
import {clipListModalOptions, displayedFilterParams, listFilterTypes} from './constants';

import {MouseEvent} from 'react';
import {XIcon} from '@heroicons/react/outline';
import classNames from 'classnames';
import {toLower} from 'lodash';
import {useClipList} from './ClipListContext';

const product = {
  name: 'Select filters',
};

export function ModelContentListOption({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (param: boolean) => void;
}) {
  const {
    state: {activeModal},
    dispatch: dispatchClipList,
  } = useClipList();

  const setActiveModal = (newModalType: clipListModalOptions) => {
    dispatchClipList({
      type: 'set active modal',
      payload: {
        activeModal: newModalType,
      },
    });
  };
  return (
    <div
      className={classNames(
        'relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8',
        {
          hidden: activeModal !== 'filter',
        },
      )}
    >
      <button
        type="button"
        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
        onClick={() => setOpen(false)}
      >
        <span className="sr-only">Close</span>
        <XIcon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-8 lg:gap-x-8">
        <div className="sm:col-span-8">
          <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12">
            {product.name}
          </h2>
          <section aria-labelledby="options-heading" className="mt-2">
            <div className="mt-4 space-y-2">
              {listFilterTypes
                .filter(filterType => displayedFilterParams.includes(filterType.id))
                .map(filterType => {
                  return (
                    <div
                      onClick={() => {
                        setActiveModal(filterType.id);
                      }}
                      key={filterType.title}
                      className={classNames(
                        'focus:outline-none relative block cursor-pointer rounded-lg border border-gray-300 p-4 hover:bg-gray-100',
                      )}
                    >
                      <>
                        <p className="text-base font-medium text-gray-900">
                          Filter by {toLower(filterType.title)}...
                        </p>
                        <div
                          className={classNames(
                            'border-2',
                            'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg',
                          )}
                          aria-hidden="true"
                        />
                      </>
                    </div>
                  );
                })}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
