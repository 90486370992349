import {
  UploadMediaClipType,
  useListUploadMediaClipsQuery,
} from 'services/uploadMediaClipAPI';
import {useEffect, useMemo, useRef} from 'react';

import {ClipListHeader} from './ClipListHeader';
import {ClipListItem} from './ClipListItem';
import {EmptyListMessage} from '../../shared/EmptyListMessage';
import {LoadingClipsList} from './LoadingClipsList';
import {SortByClipType} from './constants';
import {useClipList} from './ClipListContext';
import useDimensions from 'react-use-dimensions';
import {useWindowHeight} from '@react-hook/window-size';

type ClipListResultsProps = {
  userClips: UploadMediaClipType[];
  filteredUserClips: UploadMediaClipType[];
};

export default function ClipListResults({
  userClips,
  filteredUserClips,
}: ClipListResultsProps) {
  const {data, isLoading, isFetching} = useListUploadMediaClipsQuery();

  const {
    state: {selectedIdList, overrideCheckboxClip, sortByValue},
    dispatch,
  } = useClipList();

  const setOverrideCheckboxClip = (newOverrideValue: boolean) => {
    dispatch({
      type: 'update override checkbox clip value',
      payload: {
        newOverrideValue,
      },
    });
  };

  const fileIcon = (
    <svg
      className="w-full fill-current"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M48.1248 17.5001V51.0417C48.1248 57.4876 42.904 62.7084 36.4582 62.7084C30.0123 62.7084 24.7915 57.4876 24.7915 51.0417V14.5834C24.7915 12.6495 25.5597 10.7949 26.9272 9.42743C28.2946 8.05997 30.1493 7.29175 32.0832 7.29175C34.017 7.29175 35.8717 8.05997 37.2392 9.42743C38.6066 10.7949 39.3748 12.6495 39.3748 14.5834V45.2084C39.3748 46.8126 38.0623 48.1251 36.4582 48.1251C34.854 48.1251 33.5415 46.8126 33.5415 45.2084V17.5001H29.1665V45.2084C29.1665 47.1423 29.9347 48.9969 31.3022 50.3644C32.6696 51.7318 34.5243 52.5001 36.4582 52.5001C38.392 52.5001 40.2467 51.7318 41.6142 50.3644C42.9816 48.9969 43.7498 47.1423 43.7498 45.2084V14.5834C43.7498 8.13758 38.529 2.91675 32.0832 2.91675C25.6373 2.91675 20.4165 8.13758 20.4165 14.5834V51.0417C20.4165 59.9084 27.5915 67.0834 36.4582 67.0834C45.3248 67.0834 52.4998 59.9084 52.4998 51.0417V17.5001H48.1248Z" />
    </svg>
  );
  const header = 'All clips that you have access to will show up here.';
  const description =
    'Here you can manage visibility and share clips with collaborators.';

  useEffect(() => {
    if (selectedIdList.length) {
      if (!overrideCheckboxClip) {
        setOverrideCheckboxClip(true);
      }
    } else {
      if (overrideCheckboxClip) {
        setOverrideCheckboxClip(false);
      }
    }
  }, [selectedIdList]);

  function clipDuration(userClip: UploadMediaClipType) {
    return (Number(userClip.end_time) - Number(userClip.start_time)).toFixed(1);
  }

  function sortClips(uploadClips: UploadMediaClipType[], sortByValue: string) {
    let sortedClips = [];
    switch (sortByValue) {
      case SortByClipType.Duration:
        sortedClips = uploadClips
          .filter(upload => upload.created_at)
          .sort(
            (a: UploadMediaClipType, b: UploadMediaClipType) =>
              Number(clipDuration(a)) - Number(clipDuration(b)),
          );
        break;
      case SortByClipType.CreatedAt:
      default:
        sortedClips = uploadClips
          .filter(upload => upload.updated_at)
          .sort(
            (a: UploadMediaClipType, b: UploadMediaClipType) =>
              b.updated_at - a.updated_at,
          );
        break;
    }
    return sortedClips;
  }

  const sortedUploadClips = useMemo(
    () => sortClips(filteredUserClips, sortByValue),
    [sortByValue, filteredUserClips],
  );

  const windowHeight = useWindowHeight();
  const [headerRef, {height: headerHeight}] = useDimensions();

  const tableHeight = useMemo(() => {
    let outputHeight = 0;

    if (windowHeight) {
      outputHeight = windowHeight;
    }
    if (headerHeight) {
      outputHeight = outputHeight - headerHeight;
    }
    return outputHeight;
  }, [windowHeight, headerHeight]);

  return (
    <div className="w-full overflow-hidden bg-white sm:rounded-md">
      <div ref={headerRef}>
        <ClipListHeader userClips={userClips} sortedUploadClips={sortedUploadClips} />
      </div>
      <div
        className="overflow-auto"
        style={{
          height: `${tableHeight}px`,
        }}
      >
        {isLoading || isFetching ? (
          <LoadingClipsList />
        ) : sortedUploadClips.length > 0 ? (
          <ul className="select-none divide-y divide-gray-200">
            {sortedUploadClips.map((userClip, index) => {
              return (
                <ClipListItem
                  userUploadId={userClip.user_upload_id}
                  key={`user-upload-clip-${userClip.id}`}
                  userClip={userClip}
                />
              );
            })}
          </ul>
        ) : (
          <EmptyListMessage icon={fileIcon} header={header} description={description} />
        )}
      </div>
    </div>
  );
}
