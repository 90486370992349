import {useDispatch, useSelector} from 'react-redux';

// import DashboardSidebar from 'features/Dashboard/DashboardWrapWithSidebar';
import MainDownloadPreviewContainer from 'features/Dashboard/DashboardPage/ContentDownloads/MainDownload/MainDownloadPreviewContainer';
import {authSelector} from 'features/selectors/authSelectors';
import {showErrorNotification} from 'features/Common/utils';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';

export function DownloadCanvasPage() {
  const {getAccessTokenSilently} = useAuthAlt();
  const {email} = useSelector(authSelector);
  const dispatch = useDispatch();
  const {projectSlug, downloadId} =
    useParams<{projectSlug: string; downloadId: string}>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [projectSlug, downloadId]);

  useEffect(() => {
    if (!email) {
      alert(' no email ');
      return;
    }
    (async () => {
      try {
        const token = await getAccessTokenSilently();
      } catch (error) {
        const errorParams = {
          title: 'Error fetching download',
          message: '',
        };
        if (error) {
          // @ts-ignore
          errorParams['message'] = error?.message;
          errorParams['error'] = error;
        }
        showErrorNotification(errorParams);
      }
    })();
  }, [dispatch, getAccessTokenSilently, projectSlug, email, downloadId]);

  return (
    <div className="flex min-h-screen w-full">
      <MainDownloadPreviewContainer projectId={projectSlug} downloadId={downloadId} />
    </div>
  );
}
