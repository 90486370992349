import {DragDropContext, Draggable, DropResult, Droppable} from 'react-beautiful-dnd';
import {
  LandingPageComponent,
  LandingPageComponentInput,
  useUpdateLandingPageMutation,
} from 'services/landingPageAPI';
import {useEffect, useState} from 'react';

import {DraggableListItemType} from 'features/Dashboard/shared/draggableHelper';

interface Item {
  id: string;
  content: string;
}
export const DraggableListWrap = ({
  landingPageId,
  itemsList,
  children,
}: {
  landingPageId: string;
  itemsList: LandingPageComponent[];
  children: (props: DraggableListItemType) => JSX.Element;
}) => {
  const itemsState = itemsList.map(item => item);
  const [tempRowState, setTempRowState] = useState<LandingPageComponent[]>(itemsState);

  const [updateLandingPage, {isLoading: isUpdating}] = useUpdateLandingPageMutation();

  const updateOrder = async (
    landingPageId: string,
    items: LandingPageComponentInput | LandingPageComponent[],
  ) => {
    await updateLandingPage({
      id: landingPageId,
      landing_page_components_attributes: items as LandingPageComponentInput[],
    });
  };

  function onDragEnd(result: DropResult) {
    const {source, destination} = result;

    // console.log('result', result);

    // dropped outside the list
    if (!destination) {
      return;
    }

    const items = reorder(itemsList, source.index, destination.index);
    // console.log('items', items);

    setTempRowState(items);
    updateOrder(landingPageId, items);
  }

  useEffect(() => {
    if (itemsList) {
      setTempRowState(itemsList);
    }
  }, [setTempRowState, itemsList]);

  const reorder = (
    itemsList: LandingPageComponent[],
    sourceIndex: number,
    destinationIndex: number,
  ) => {
    const result = Array.from(itemsList);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);
    const resultsWithOrder = result.map((resultItem, index) => ({
      ...resultItem,
      order: index,
    }));

    return resultsWithOrder as LandingPageComponent[];
  };

  const getItemStyle = (draggableStyle: any, isDragging: boolean): {} => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: `${grid} ${grid * 2}`,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? '#fff' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const grid = 8;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'first-row'}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className="w-full">
            {tempRowState.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      provided.draggableProps.style,
                      snapshot.isDragging,
                    )}
                  >
                    {children({
                      tempRowState,
                      setTempRowState,
                      item,
                      isDragging: snapshot.isDragging,
                      index,
                    })}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
