import {ReactNode} from 'react';
import cn from 'classnames';
export const DashboardHeadWrap = ({
  children,
  className,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div
      className={cn(
        'border-b border-gray-200 py-4 px-4 sm:flex sm:items-center sm:justify-between',
        className,
      )}
    >
      {children}
    </div>
  );
};

export const DashboardHead = ({
  title,
  children,
}: {
  title: string;
  children?: ReactNode;
}) => {
  return (
    <DashboardHeadWrap>
      <div className="">
        <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
          {title}
        </h1>
      </div>
      <div className={'flex flex-row'}>
        <div className="flex flex-1 items-center justify-center space-x-3 lg:ml-6 lg:justify-end">
          {children}
        </div>
      </div>
    </DashboardHeadWrap>
  );
};
