import {FileNav, PositionDirection} from '../DashboardPage/ContentUploads/FileNav';
import {ReactNode, useEffect, useState} from 'react';

import FileNameTextField from './PlayableMedia/FileNameTextField';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import LoadingText from 'features/EditorCanvas/components/shared/LoadingText';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {routeHome} from 'routes/routesHelper';
import {useNavigate} from 'react-router-dom';
import {useUserUploadQuery} from 'services/userUploadAPI';

export default function DetailTypeHeader({
  children,
  uploadFile,
}: {
  children?: ReactNode;
  uploadFile: UserUploadsType;
}) {
  const [isShareOpen, setIsShareOpen] = useState(false);

  const navigate = useNavigate();

  const {
    data: userUpload,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  } = useUserUploadQuery({id: uploadFile.id!});

  const goBack = () => {
    navigate(routeHome);
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-4">
        <LoadingSpinner />;
      </div>
    );
  }

  if (!userUpload) {
    return <div />;
  }

  return (
    <>
      {isShareOpen && (
        <div
          onClick={evt => {
            evt.stopPropagation();
            setIsShareOpen(!isShareOpen);
          }}
          className="absolute left-0 top-0 h-screen w-screen"
          style={{zIndex: 19}}
        />
      )}
      <div className="border-b border-gray-200 p-4 ">
        <div className="space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
          <h3 className="flex flex-row justify-start text-lg font-medium leading-6 text-gray-900">
            <button
              type="button"
              onClick={goBack}
              className="focus:ring-blue mr-2 inline-flex items-center justify-center rounded p-1 text-sm font-medium leading-5 text-gray-400 transition duration-150 ease-in-out hover:bg-gray-200 hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
            >
              <svg
                className="fill-current"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3333 7.33335H5.21996L8.94663 3.60669L7.99996 2.66669L2.66663 8.00002L7.99996 13.3334L8.93996 12.3934L5.21996 8.66669H13.3333V7.33335Z"
                  fill="black"
                />
              </svg>
            </button>
            {userUpload ? (
              <div className="relative z-30">
                <FileNameTextField uploadFile={userUpload}>
                  <FileNav
                    positionDirection={PositionDirection.DOWN_RIGHT}
                    userUploadId={userUpload.id}
                  />
                </FileNameTextField>
              </div>
            ) : (
              <span style={{maxWidth: 250, width: '100%'}}>
                <LoadingText lineCount={0} />
              </span>
            )}
          </h3>
        </div>
      </div>
    </>
  );
}
