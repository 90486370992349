import {useEffect, useState} from 'react';

import CanvasModalsWrapContainer from 'features/EditorCanvas/components/CanvasModals/CanvasModalsWrapContainer';
import {CanvasSceneBottomBar} from './components/CanvasScene/CanvasSceneBottomBar';
import CanvasScenes from './components/CanvasScene/CanvasScenes';
import CanvasWrap from 'features/EditorCanvas/components/AppCanvas/CanvasWrap';
import {ContextMenuProvider} from './components/ContextMenu';
import {Dissolve} from 'ui/Dissolve';
import {DraggableClipProvider} from './components/AppCanvas/DraggableClip';
import {EditCropProvider} from './components/AppCanvas/Canvas/utils/useEditCrop';
import {EditorCanvasModals} from './EditorCanvasModalsContext';
import {LoadGoogleFonts} from '../Fonts/LoadGoogleFonts';
import MainAppCanvas from 'features/EditorCanvas/components/AppCanvas/MainAppCanvas';
import {ProjectTimeProvider} from 'features/EditorCanvas/components/CanvasTime/TimeProvider';
import {Sidebar} from './Sidebar/Sidebar';
import {SidebarMenuProvider} from './Sidebar/SidebarMenuContext';
import WorkspaceTopNavBarContainer from 'features/EditorCanvas/components/WorkspaceTopNavBar/WorkspaceTopNavBarContainer';
import {useProjectId} from './useProjectId';
import {SelectionFocusProvider} from './components/SelectionFocusProvider';
import {UndoProvider} from './components/Undo';

function EditorCanvasWrap() {
  const projectId = useProjectId();

  useEffect(() => {
    window.analytics.track('Opened canvas page', {
      project_id: projectId,
    });
  }, []);

  return (
    <EditorCanvasModals.Provider>
      <SidebarMenuProvider>
        <ProjectTimeProvider>
          <DraggableClipProvider projectId={projectId}>
            <ContextMenuProvider projectId={projectId}>
              <EditCropProvider>
                <SelectionFocusProvider>
                  <CanvasWrap>
                    <UndoProvider>
                      <WorkspaceTopNavBarContainer
                        preview={false}
                        projectId={projectId}
                      />
                      <div className="flex flex-1 flex-row overflow-hidden">
                        <Sidebar />
                        <div
                          style={{zIndex: 600}}
                          className="relative flex min-w-0 flex-1 flex-col bg-gray-50"
                        >
                          <MainAppCanvas projectId={projectId} />
                          <div className="w-full bg-gray-50 lg:border-t lg:border-b lg:border-gray-200">
                            {/* Refactor TODO (jacques): Clean up and improve animation */}

                            <Dissolve>
                              <CanvasScenes projectId={projectId} key="scenes" />
                            </Dissolve>
                            <CanvasSceneBottomBar />
                          </div>
                        </div>

                        {/* <SidebarRightTopLevelMenu /> */}
                      </div>
                    </UndoProvider>
                  </CanvasWrap>
                  <CanvasModalsWrapContainer projectId={projectId} />
                </SelectionFocusProvider>
              </EditCropProvider>
            </ContextMenuProvider>
          </DraggableClipProvider>
          <LoadGoogleFonts />
        </ProjectTimeProvider>
      </SidebarMenuProvider>
    </EditorCanvasModals.Provider>
  );
}

export default EditorCanvasWrap;
