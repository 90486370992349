import {ClientStateType} from 'features/serverSyncSlice';
import {DimensionType} from 'features/types/canvasItemsSlice';
import {UserLibraryUserProjectType} from 'features/types/userLibrarySlice';
import {UserUploadsCableActions} from 'cableSubscriptions/userUploadsCable';
import {baseApi} from './baseAPI';
import {subscribeToCable} from './utils';

export type LatestUpdatedUser = {
  first_name: string;
  last_name: string;
  email: string;
  id: string;
};

export type ProjectRecord = {
  account_id: string;
  created_at: number;
  creator_user_id: string;
  owner_id: string;
  id: string;
  name: string;
  slug: string;
  updated_at: number;
  status: string;
  total_views_count: number;
  total_views_unique: number;
  ahoy_visit_id: string | null;
  client_sync_version: number | undefined;
  discarded_at: string | null;
  unique_id: string | null;
  latest_preview: string | undefined;
  is_template: boolean;
  has_exported: boolean;
  has_shared: boolean;
  latest_updated_user: null | LatestUpdatedUser;
  metadata?: {
    published_version?: number;
    preview_image?: string;
  };
  video_properties?: {
    dimensions: DimensionType;
    duration_ms: number;
  } | null;
};

export type ProjectRecordInput = {
  account_id?: string;
  creator_user_id?: string;
  id?: string;
  name?: string;
  slug?: string;
  status?: string;
  client_sync_version?: number | null;
  unique_id?: string | null;
  latest_preview?: string | undefined;
  is_template?: boolean;
  metadata?: {
    published_version?: number;
    preview_image?: string;
  };
  video_properties?: {
    dimensions: DimensionType;
    duration_ms: number;
  } | null;
};

type ProjectRecordUpdateInput = UpdateId<ProjectRecordInput> & {};

type UpdateId<T extends {}> = T & {id: string};
type UpsertId<T extends {}> = T & {id?: string};

interface ListProjectResponse<T> {
  page: number;
  count: number;
  items: number;
  pages: number;
  last: number;
  from: number;
  to: number;
  prev: number;
  next: number;
  data: T[];
}

export const projectAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['Project']})
  .injectEndpoints({
    endpoints: builder => ({
      listProjects: builder.query<ListProjectResponse<ProjectRecord>, number | void>({
        query: (page = 1) => ({
          url: `projects?page=${page}`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading projects data',
          refetchOnReconnect: false,
          refetchOnFocus: false,
        }),
        providesTags: (result, error, page) =>
          result
            ? [
                // Provides a tag for each post in the current page,
                // as well as the 'PARTIAL-LIST' tag.
                ...result.data.map(({id}) => ({type: 'Project' as const, id})),
                {type: 'Project', id: 'PARTIAL-LIST'},
              ]
            : [{type: 'Project', id: 'PARTIAL-LIST'}],
      }),

      project: builder.query<ProjectRecord, {id: string}>({
        query: ({id}) => ({
          url: `projects/${id}`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading project data',
        }),
        providesTags: ['Project'],
        async onCacheEntryAdded(_, api) {
          // NOTE (lenny): Make sure you understand this
          return subscribeToCable(
            {api, action: UserUploadsCableActions.UpdatedPlaybackId},
            () => {
              // api.dispatch(userUploadAPI.util.invalidateTags(['Project']));
            },
          );
        },
      }),

      projectBySlug: builder.query<
        {project: UserLibraryUserProjectType},
        {projectSlug: string}
      >({
        query: ({projectSlug}) => ({
          errorTitle: 'Error fetching project',
          method: 'GET',
          url: `projects/${projectSlug}`,
        }),
        providesTags: ['Project'],
      }),

      createProject: builder.mutation<ProjectRecord, ProjectRecordInput>({
        query: userUpload => ({
          url: `projects`,
          method: `POST`,
          v2: true,
          errorTitle: `Error creating projects page`,
          data: {project: userUpload},
        }),
        invalidatesTags: ['Project'],
      }),

      updateProject: builder.mutation<ProjectRecord, ProjectRecordUpdateInput>({
        query: ({id, ...patch}) => ({
          url: `projects/${id}`,
          method: `PATCH`,
          v2: true,
          errorTitle: `Error updating project`,
          data: {project: patch},
        }),
        invalidatesTags: ['Project'],
      }),

      deleteProject: builder.mutation<{success: boolean; id: string}, string>({
        query: id => ({
          url: `projects/${id}`,
          method: 'DELETE',
          v2: true,
          errorTitle: 'Error deleting project',
        }),
        invalidatesTags: (result, error, id) => [
          {type: 'Project', id},
          {type: 'Project', id: 'PARTIAL-LIST'},
        ],
      }),

      accountTemplates: builder.query<UserLibraryUserProjectType[], void>({
        query: () => ({
          errorTitle: 'Error fetching templates',
          method: 'GET',
          url: `project_templates`,
          v2: true,
          refetchOnFocus: false,
          refetchOnReconnect: false,
        }),
      }),
      latestProjectSync: builder.query<
        {client_state: ClientStateType; project_slug: string},
        string
      >({
        query: projectId => ({
          errorTitle: 'Error fetching template',
          method: 'POST',
          url: `project_syncs/latest`,
          data: {
            project_sync_versions: {
              project_id: projectId,
            },
          },
        }),
      }),
      saveAsTemplate: builder.mutation<UserLibraryUserProjectType, string>({
        query: projectId => ({
          errorTitle: 'Error saving project',
          method: 'POST',
          url: `projects/${projectId}/save_as_template`,
          v2: true,
        }),
      }),
    }),
  });

export const {
  useLazyListProjectsQuery,
  useListProjectsQuery,
  useProjectQuery,
  useAccountTemplatesQuery,
  useSaveAsTemplateMutation,
  useProjectBySlugQuery,
  useLatestProjectSyncQuery,
} = projectAPI;
