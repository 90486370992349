import {useRef, useEffect} from 'react';
import {useSelectionFocus} from '../../SelectionFocusProvider';

export function TextFieldInput({
  value,
  onChange,
}: {
  value: string | false;
  onChange: (value: string) => void;
}) {
  const {setIsTextFieldActive} = useSelectionFocus();

  const handleFocus = () => {
    setIsTextFieldActive(true);
  };

  const handleBlur = () => {
    setIsTextFieldActive(false);
  };

  const elRef = useRef<HTMLTextAreaElement | null>(null);

  // block arrow key movement of canvas item when focused
  useEffect(() => {
    if (elRef.current) {
      elRef.current.addEventListener('focus', handleFocus);
      elRef.current.addEventListener('blur', handleBlur);
    }
    // Remove event listeners on cleanup
    return () => {
      if (elRef.current) {
        elRef.current.removeEventListener('focus', handleFocus);
        elRef.current.removeEventListener('blur', handleBlur);
      }
    };
  }, [elRef]);

  if (typeof value !== 'string') {
    return <div />;
  }

  return (
    <div>
      <label
        htmlFor="text-field-input"
        className="block text-sm font-medium text-gray-700"
      >
        Text field
      </label>
      <div className="mt-1">
        <textarea
          ref={elRef}
          value={value.toString()}
          aria-label="text-field-input"
          onChange={event => {
            if (event.target.value) {
              onChange(event.target.value);
            }
          }}
          onKeyDown={event => {
            if (event.isDefaultPrevented()) return;
          }}
          rows={4}
          name="text-field-input"
          id="text-field-input"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
}
