import {DashParams, routePaths} from 'routes/routesHelper';
import {Link, useParams} from 'react-router-dom';
import {
  MuxAsset,
  RecordingSubmission,
  RecordingSubmissionTrack,
} from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import {useMemo, useRef, useState} from 'react';

import {BASE_REDIRECT_URL} from 'constants/environment';
import CopyToClipboard from 'react-copy-to-clipboard';
import {Loading} from 'features/EditorCanvas/Sidebar/views/Assets/Loading';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import NotificationInfo from 'features/Notifications/NotificationInfo';
import ReactPlayer from 'vendor/react-player-milk';
import {default as ReactPlayerType} from 'vendor/react-player-milk/types/lib';
import classNames from 'classnames';
import {format} from 'date-fns';
import {store} from 'react-notifications-component';
import useDimensions from 'react-use-dimensions';
import {useRecordingFormContactsQuery} from 'services/recordingContactsAPI';
import {useRecordingFormSubmissionsQuery} from 'services/recordingSubmissionsAPI';

export const RecordingSubmissionContent = () => {
  const {recordingFormId, recordingSubmissionId} = useParams() as Record<string, string>;

  const [ref, {width}] = useDimensions();

  const pageUrl = useMemo(() => {
    return `${BASE_REDIRECT_URL}/${routePaths.dashboard}/${DashParams.RecordingFormParams}/${recordingFormId}/${recordingSubmissionId}`;
  }, [recordingFormId, recordingSubmissionId]);

  const {
    data: recordingFormContacts,
    isLoading: isLoadingContacts,
    isSuccess: isSuccessContacts,
  } = useRecordingFormContactsQuery();

  const {
    data: recordingFormSubmissions,
    isLoading: isLoadingSubmissions,
    isSuccess: isSuccessSubmissions,
  } = useRecordingFormSubmissionsQuery();

  const submission = useMemo(() => {
    if (!recordingFormSubmissions) {
      return null;
    }

    return recordingFormSubmissions.find(
      submission => submission.id === recordingSubmissionId,
    );
  }, [recordingFormSubmissions, recordingSubmissionId]);

  if (isLoadingContacts || isLoadingSubmissions) {
    return <Loading />;
  }

  if (!recordingFormContacts || !recordingFormSubmissions) {
    return <div>No content</div>;
  }

  const handleCopyConfirm = () => {
    store.addNotification({
      ...NOTIFICATION_BASE,
      content: (
        <NotificationInfo
          title={'Copied link'}
          message={'You can now share the recording form link.'}
        />
      ),
    });
  };

  // console.log('submission', submission);

  return (
    <div className="flex w-full border-l bg-white px-6">
      <div ref={ref} className="w-full">
        {/* <div>
          <h1>Submission</h1>
          <div>{JSON.stringify(submission)}</div>
        </div> */}
        {submission?.submission_tracks && (
          <div className="relative z-10 h-full w-full">
            <ResponseUser submission={submission} />
            <div className="absolute top-0 left-0 right-0 z-0 flex h-full flex-col items-center justify-center">
              {submission?.submission_tracks.map(track => {
                return (
                  <div key={track.id}>
                    <div className="flex-col items-center space-y-4">
                      <SubmissionTrackPlayer
                        playerWidth={width - 30}
                        submissionTrack={track}
                      />
                      <span className="relative z-0 flex justify-center rounded-md">
                        <CopyToClipboard text={pageUrl} onCopy={handleCopyConfirm}>
                          <a href={pageUrl} onClick={evt => evt.preventDefault()}>
                            <button
                              type="button"
                              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                            >
                              Share
                            </button>
                          </a>
                        </CopyToClipboard>
                        {/* <button
                          type="button"
                          className="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        >
                          Download
                        </button> */}
                        <Link
                          to={`/${routePaths.dashboard}/${DashParams.UploadsParam}/${DashParams.UploadsMediaParam}/${track?.user_upload.id}`}
                        >
                          <button
                            type="button"
                            className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                          >
                            Transcript
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ResponseUser = ({submission}: {submission: RecordingSubmission}) => {
  const {
    data: recordingFormContacts,
    isLoading: isLoadingContacts,
    isSuccess: isSuccessContacts,
  } = useRecordingFormContactsQuery();

  const contact = useMemo(() => {
    return recordingFormContacts?.find(
      contact => contact.id === submission.user_recording_contact_id,
    );
  }, [recordingFormContacts, submission]);

  if (!submission || !submission.submission_tracks) {
    return <div />;
  }

  const previewImage = `https://image.mux.com/${submission.submission_tracks[0].user_upload.mux_playback_id}/thumbnail.png?width=100&height=100&fit_mode=smartcrop`;
  return (
    <div className="absolute z-30 flex flex-col py-8">
      <div className="flex items-center space-x-4 text-sm text-gray-500">
        <div className="flex-none">
          <img src={previewImage} alt="" className="h-10 w-10 rounded-md bg-gray-100" />
        </div>
        <div className={classNames('flex-1')}>
          <h3 className="font-medium text-gray-900">{contact?.name}</h3>
          <p className="text-xs">{format(submission.created_at, 'Pp')}</p>
        </div>
      </div>
      <div className="flex flex-col space-y-1 py-3">
        <a href={`mailto:${contact?.email}`}>
          <p className="text-xs text-indigo-600">{contact?.email}</p>
        </a>
        <p className="text-xs">{contact?.meta?.company}</p>
      </div>
    </div>
  );
};

const SubmissionTrackPlayer = ({
  playerWidth,
  submissionTrack,
}: {
  playerWidth: number;
  submissionTrack: RecordingSubmissionTrack;
}) => {
  const [isPreview, setIsPreview] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const videoRef = useRef<any>(null);

  const track = submissionTrack;

  // console.log('width', playerWidth);
  // console.log('submissionTrack', submissionTrack);
  // console.log('setIsVideoReady', isVideoReady);

  // if (!isVideoReady) {
  //   return <div className="h-40 w-full animate-pulse bg-gray-100" />;
  // }

  if (!track?.user_upload || !track?.user_upload.mux_playback_id) {
    return <div />;
  }

  return (
    <div className="flex flex-col items-center justify-center overflow-hidden rounded-lg">
      <ReactPlayer
        onReady={() => {
          setIsVideoReady(true);
        }}
        width={playerWidth - 30}
        height={'100%'}
        ref={videoRef}
        controls
        className=""
        url={`https://stream.mux.com/${track.user_upload.mux_playback_id}.m3u8`}
      />
    </div>
  );
};
