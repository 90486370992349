import './LoadingMilk.css';

import logo from 'pages/PreviewRender/components/logo-square.png';

export default function LoadingMilk({
  imageCallback,
}: {
  imageCallback?: (newState: boolean) => void;
}) {
  return (
    <div className="flex flex-col items-center justify-center space-y-3">
      <img
        style={{height: 80, width: 80}}
        className="w-20 bg-none"
        src={logo}
        alt="logo"
        onLoad={() => imageCallback && imageCallback(true)}
      />
      <div className="dot-flashing" />
    </div>
  );
}
