import {ReactElement, createContext, useContext} from 'react';
import store, {GetRootState} from '../../../configureStore';

import {ActionCreators} from 'redux-undo';
import {useHotkeys} from 'react-hotkeys-hook';
import {useSelector} from 'react-redux';
import {showSuccessNotification} from '../../Common/utils';
import {UndoLabels} from '../../../api/constants';
const UndoContext = createContext({});
const UndoProvider = ({children}: {children: ReactElement[]}) => {
  const undoCount = useSelector((state: GetRootState) => state.canvasItems.past.length);

  const undo = () => {
    window.analytics.track('Undo canvas action');
    store.dispatch(ActionCreators.undo());
    showSuccessNotification({
      title: UndoLabels.undoSuccessNotificationTitle,
      message: UndoLabels.undoSuccessNotificationMessage,
    });
  };

  const triggerUndo = () => {
    if (undoCount > 0) {
      undo();
    }
  };

  useHotkeys(
    'command+z',
    () => {
      triggerUndo();
    },
    [triggerUndo],
  );

  const value = {undo, undoCount};

  return <UndoContext.Provider value={value}>{children}</UndoContext.Provider>;
};

function useUndo() {
  const context = useContext(UndoContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a UndoProvider');
  }
  return context;
}

export {UndoProvider, useUndo};
