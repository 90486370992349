import {AnimatePresence, motion} from 'framer-motion';
import {ReactElement} from 'react';

export const Dissolve = ({children}: {children: ReactElement}) => {
  return (
    <div className="w-full">
      <AnimatePresence initial={false}>
        <motion.div
          initial={{opacity: 0, height: 0}}
          animate={{opacity: 1, height: 'auto'}}
          exit={{opacity: 0, height: 0}}
          transition={{duration: 0.15}}
          key={children.key}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
