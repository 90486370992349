import App from 'vendor/dailyco/basic-call/components/App';
import {CallProvider} from 'vendor/dailyco/shared/contexts/CallProvider';
import {MediaDeviceProvider} from 'vendor/dailyco/shared/contexts/MediaDeviceProvider';
import {ParticipantsProvider} from 'vendor/dailyco/shared/contexts/ParticipantsProvider';
import {ReactElement} from 'react';
import {RecordingProvider} from '../contexts/RecordingProvider';
import {TracksProvider} from 'vendor/dailyco/shared/contexts/TracksProvider';
import Tray from './Tray';
import {UIStateProvider} from 'vendor/dailyco/shared/contexts/UIStateProvider';

const asides = [] as ReactElement[];
const modals = [] as any[];
const customTrayComponent = <Tray />;
const domain = 'milkvideo';
const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicWwza2FldUNYSGJpZ3ZXWGl4UzkiLCJvIjp0cnVlLCJkIjoiNWE3MDU4YTMtN2ZlNS00MWUyLWFiY2EtMDYzYmM0MDg2MzRjIiwiaWF0IjoxNjQ4NzE4MjcwfQ.-DFesx90ICM5z0vaNY6o2IjAjblQMKJ6t--wdLrB_o8';
const subscribeToTracksAutomatically = true;

// Extend our basic call app component with the recording context
export const AppWithRecording = ({roomId}: {roomId: string}) => (
  <div className="h-full w-full">
    <UIStateProvider
      asides={asides}
      modals={modals}
      customTrayComponent={customTrayComponent}
    >
      <CallProvider
        domain={domain}
        room={roomId}
        token={token}
        subscribeToTracksAutomatically={subscribeToTracksAutomatically}
      >
        <ParticipantsProvider>
          <TracksProvider>
            <MediaDeviceProvider>
              {/* <WaitingRoomProvider> */}
              <RecordingProvider>
                <App />
              </RecordingProvider>
              {/* </WaitingRoomProvider> */}
            </MediaDeviceProvider>
          </TracksProvider>
        </ParticipantsProvider>
      </CallProvider>
    </UIStateProvider>
  </div>
);

export default AppWithRecording;
