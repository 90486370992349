import {UserType} from 'features/types/authSlice';
import {useDispatch, useSelector} from 'react-redux';

import {Card} from 'features/Common/Card';
import {CardHeader} from 'features/Common/CardHeader';
import {LoadingSpinnerInline} from 'features/EditorCanvas/components/shared/LoadingSpinnerInline';
import {authSelector} from 'features/selectors/authSelectors';
import {resetPasswordForUser} from 'features/authSlice';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useEffect} from 'react';

interface SettingsPersonalProps {
  user: UserType;
}

export default function SettingsPersonal({user}: SettingsPersonalProps) {
  const {getAccessTokenSilently} = useAuthAlt();
  const dispatch = useDispatch();
  const {
    currentAccountSettings: {passwordReset},
  } = useSelector(authSelector);

  useEffect(() => {
    window.analytics.track('Loaded settings page - viewed user profile page');
  }, []);

  const onClickResetUserPassword = () => {
    window.analytics.track('User password reset clicked');
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(resetPasswordForUser(token, user.email));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  return (
    <div className="mt-6 space-y-6 divide-y divide-gray-200">
      <Card>
        <CardHeader subtitle="" title="Profile" />
        <div>
          <dl className="divide-y divide-gray-200">
            <div className="space-y-1 py-4 px-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <dt className="text-sm font-medium leading-5 text-gray-500">Email</dt>
              <dd className="flex space-x-4 text-sm leading-5 text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{user.email}</span>
              </dd>
            </div>
            <div className="space-y-1 py-4 px-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <dt className="text-sm font-medium leading-5 text-gray-500">Password</dt>
              <dd className="flex space-x-4 text-sm leading-5 text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">********</span>
                <span className="flex-shrink-0">
                  <button
                    type="button"
                    onClick={onClickResetUserPassword}
                    disabled={passwordReset.wasSent}
                    className="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500"
                  >
                    {passwordReset.isSending ? (
                      <LoadingSpinnerInline isLight={false} />
                    ) : (
                      'Reset'
                    )}
                  </button>
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </Card>
    </div>
  );
}
