import { TrayButton } from 'vendor/dailyco/shared/components/Tray';
import { useUIState } from 'vendor/dailyco/shared/contexts/UIStateProvider';
import { ReactComponent as IconRecord } from 'vendor/dailyco/shared/icons/record-md.svg';

import { useRecording } from '../contexts/RecordingProvider';
import { RECORDING_MODAL } from './RecordingSessionWrap';

export const Tray = () => {
  const { openModal } = useUIState();
  const { isRecording } = useRecording();

  return (
    <TrayButton
      label={isRecording ? 'Recording' : 'Record'}
      orange={isRecording}
      onClick={() => openModal(RECORDING_MODAL)}
    >
      <IconRecord />
    </TrayButton>
  );
};

export default Tray;
