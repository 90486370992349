import {DOMAttributes, Fragment, createContext, useEffect} from 'react';
import {Item, Menu, Separator, useContextMenu} from 'react-contexify';
import {MenuItem, useMenuItems} from './ContextMenuItems';
import {Shortcut, Shortcuts} from 'shortcuts';
import {
  canvasItemsSelectedItemIdsSelector,
  canvasItemsSelectedItemsSelector,
  canvasStateSelector,
} from 'features/selectors/canvasStateSelectors';
import {deselectItems, updateSelectedItems} from 'features/canvasStateSlice';
import {useDispatch, useSelector} from 'react-redux';

import {FC} from 'react';
import {GetRootState} from 'configureStore';
import {Kbd} from 'ui/Kbd';
import {pick} from 'lodash';
import {selectCanvasProject} from 'features/selectors/canvasItemsSelectors';
import {useOnOutsideClick} from './useOnOutsideClick';

type OnContextMenu = DOMAttributes<HTMLDivElement>['onContextMenu'];

type Target = {type: 'canvas' | 'group'} | {type: 'item'; id: string};
export type ActiveTarget = {type: 'canvas'} | {type: 'items'; ids: string[]};

type Context = {
  onContextMenu: (target: Target) => OnContextMenu;
};

export const ContextMenuContext = createContext<Context>({
  onContextMenu: () => () => {},
});

const shortcuts = new Shortcuts();
type Single<T> = T extends any[] ? never : T;
type ShortcutDescriptor = Single<Parameters<Shortcuts['add']>[0]>;

const ShortcutLabel = ({shortcut}: {shortcut: string}) => {
  return (
    <div className="flex items-center">
      <span
        className="rounded-sm bg-gray-200 px-1 py-1 font-bold uppercase text-gray-800"
        style={{fontSize: '0.6rem', lineHeight: 1}}
      >
        {Shortcut.shortcut2symbols(shortcut)}
      </span>
    </div>
  );
};

const useShortcuts = ({
  menuItems,
  projectId,
}: {
  menuItems: MenuItem[];
  projectId: string;
}) => {
  const canvasEditMode = useSelector(canvasStateSelector)?.canvasEditMode;

  useEffect(() => {
    // Disable shortcuts in Edit Mode
    if (canvasEditMode === 'text') return;

    const descriptors: ShortcutDescriptor[] = [];
    for (const item of menuItems) {
      if (!item.shortcuts) continue;

      item.shortcuts.forEach(shortcut => {
        descriptors.push({
          shortcut,
          handler: () => {
            item.action();
            return true;
          },
        });
      });
    }

    shortcuts.add(descriptors);

    return () => {
      shortcuts.remove(descriptors);
    };
  }, [menuItems, canvasEditMode]);
};

export const ContextMenuProvider: FC<{projectId: string}> = ({projectId, children}) => {
  const dispatch = useDispatch();

  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);

  const project = useSelector((state: GetRootState) =>
    selectCanvasProject(state, projectId),
  );
  const {items} = project;
  const selectedItems = useSelector(canvasItemsSelectedItemsSelector)(projectId);

  const {show, hideAll} = useContextMenu({
    id: 'canvasContextMenu',
  });

  const getMenuItems = useMenuItems({projectId});

  const onContextMenu: Context['onContextMenu'] = target => e => {
    e.preventDefault();
    e.stopPropagation();

    let itemIds = selectedItemIds;

    if (target.type === 'item') {
      // Select the right clicked item if it's not currently selected
      if (!itemIds.includes(target.id)) {
        itemIds = [target.id];
      }
    } else if (target.type === 'canvas') {
      itemIds = [];
    }

    dispatch(updateSelectedItems({project, selectedItemIds: itemIds}));

    const menuItems = getMenuItems(pick(items, itemIds));

    if (menuItems.length > 0) {
      show(e);
    } else {
      hideAll();
    }
  };

  const menuRef = useOnOutsideClick(() => {
    hideAll();
  });

  const menuItems = getMenuItems(selectedItems);

  useShortcuts({menuItems, projectId});

  return (
    <ContextMenuContext.Provider value={{onContextMenu}}>
      <div
        onContextMenu={e => {
          e.preventDefault();
          hideAll();
          dispatch(deselectItems());
        }}
      >
        {children}
      </div>
      <div ref={menuRef}>
        <ContextMenu menuItems={menuItems} />
      </div>
    </ContextMenuContext.Provider>
  );
};

const ContextMenu = ({menuItems}: {menuItems: MenuItem[]}) => {
  const sectionsObj: Record<string, MenuItem[]> = {};
  for (const menuItem of menuItems) {
    if (!sectionsObj[menuItem.group]) {
      sectionsObj[menuItem.group] = [];
    }

    sectionsObj[menuItem.group].push(menuItem);
  }

  const sections = Object.values(sectionsObj);
  const lastIndex = sections.length - 1;

  return (
    <Menu id="canvasContextMenu" style={{zIndex: 9999}}>
      {sections.map((menuItems, index) => (
        <Fragment key={index}>
          {menuItems.map(item => (
            <Item key={item.label} onClick={item.action}>
              <div className="mr-3 flex items-center">
                <item.icon size={16} className="mr-2" />
                {item.label}
              </div>
              {item.shortcuts && (
                <Kbd size="small" convertToSymbols>
                  {item.shortcuts[0]}
                </Kbd>
              )}
            </Item>
          ))}
          {index !== lastIndex && <Separator />}
        </Fragment>
      ))}
    </Menu>
  );
};
