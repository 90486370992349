import {useEffect, useState} from 'react';

import {BASE_SHARE_URL} from 'constants/environment';
import {Edit2} from 'react-feather';
import {LoadingSpinnerInline} from 'features/EditorCanvas/components/shared/LoadingSpinnerInline';
import ShareDownloadStatus from './ShareDownloadStatus';
import {UserProjectDownloadAsset} from 'pages/PreviewRender/components/SocialCardPreview';
import {format} from 'date-fns';
import {useUpdateProjectNameMutation} from 'services/userProjectDownloadsAPI';

export const MainDownloadDetails = ({
  userProjectDownload,
  projectId,
  downloadId,
}: {
  userProjectDownload: UserProjectDownloadAsset;
  projectId: string;
  downloadId: string;
}) => {
  const isLoading = userProjectDownload?.is_loading;
  const isError = userProjectDownload?.is_error;
  const projectNameToDisplay = userProjectDownload.name;
  const [updateProjectName, {isLoading: isUpdating}] = useUpdateProjectNameMutation();
  const [tempFieldValue, setTempFieldValue] = useState(projectNameToDisplay);
  const [isEditing, setIsEditing] = useState(false);

  if (!userProjectDownload) {
    return null;
  }

  const downloadUrl = `${BASE_SHARE_URL}/a/${downloadId}/download`;

  const handleBlur = () => {
    setIsEditing(false);
    updateProjectName({
      downloadId,
      name: tempFieldValue,
    });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleBlur();
    }
  };

  const handleEdit = () => {
    setTempFieldValue(projectNameToDisplay);
    setIsEditing(true);
  };

  return (
    <div className="relative mx-auto mt-14 w-full sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0">
      <div className="flex flex-col-reverse">
        <div className="mt-4">
          <span className="-ml-2 inline-flex w-full rounded-md">
            {isEditing ? (
              <span className="flex w-full flex-row justify-center">
                <input
                  onKeyDown={handleKeyDown}
                  autoFocus
                  onBlur={handleBlur}
                  autoCorrect="off"
                  value={tempFieldValue}
                  onChange={(evt: any) => {
                    setTempFieldValue(evt.target.value);
                  }}
                  className="min-w-30 form-input flex w-full flex-1 rounded-md p-2   text-sm font-medium leading-5 transition duration-150 ease-in-out"
                />
                <span
                  onClick={handleBlur}
                  className="focus:outline-none ml-2 inline-flex cursor-pointer items-center rounded-lg border border-transparent border-gray-300 bg-white p-1 px-3 text-sm font-semibold text-gray-900 opacity-75 hover:bg-gray-200"
                >
                  Save
                </span>
              </span>
            ) : (
              <button
                onClick={handleEdit}
                className="focus:outline-none focus:ring-blue inline-flex w-full cursor-text items-center justify-start space-x-3 truncate rounded px-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-200 hover:text-gray-500 focus:border-blue-300 active:bg-gray-50 active:text-gray-800"
              >
                <h1 className="inline-flex truncate text-xl  font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                  {isUpdating || tempFieldValue !== projectNameToDisplay
                    ? tempFieldValue
                    : projectNameToDisplay}
                </h1>
                {isUpdating && <LoadingSpinnerInline />}
              </button>
            )}
            {!isEditing && (
              <span
                onClick={() => {
                  setIsEditing(true);
                }}
                className="align-center ml-2 flex cursor-pointer items-center justify-center pr-2"
              >
                <Edit2 className="w-4 opacity-60" />
              </span>
            )}
          </span>

          <h2 id="information-heading" className="sr-only">
            Product information
          </h2>
          <p className="mt-2 text-sm text-gray-500">
            Created{' '}
            {userProjectDownload.created_at &&
              format(userProjectDownload.created_at, 'Pp')}
          </p>
        </div>
      </div>
      {userProjectDownload.created_at && userProjectDownload.status && (
        <ShareDownloadStatus
          isError={isError}
          isLoading={isLoading}
          status={userProjectDownload.status}
        />
      )}
      {isLoading && !isError && (
        <div className="mt-6 mb-3 bg-blue-50 sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-base font-medium leading-6 text-gray-900">
              {`Don't worry, you can close this page at any time.`}
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>We will email you all the details when the build is finished.</p>
            </div>
          </div>
        </div>
      )}

      {!isError && (
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
          <a href={`${BASE_SHARE_URL}/a/${downloadId}`}>
            <span className="focus:outline-none flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">
              Share
            </span>
          </a>
          {!isLoading && downloadUrl ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={downloadUrl}
              className="focus:outline-none flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-50 py-3 px-8 text-base font-medium text-indigo-700 hover:bg-indigo-100 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
            >
              Download
            </a>
          ) : (
            <span className="focus:outline-none flex w-full cursor-wait items-center justify-center rounded-md border bg-gray-50 py-3 px-3 text-sm font-medium  text-gray-600 hover:bg-gray-100 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">
              Preparing download...
            </span>
          )}
        </div>
      )}
    </div>
  );
};
