import {SelectionToolbar, SelectionToolbarOptions} from './SelectionToolbar';
import {
  TranscriptCorrectionModal,
  useCorrectionModalOpen,
} from '../Dashboard/DashboardUploadDetails/PlayableMedia/TranscriptText/TranscriptCorrectionModal';
import {
  TranscriptJSON,
  TranscriptWord,
  useTranscript,
} from 'features/Captions/Transcripts';
import {useSelectedTranscript, useSelection} from './selection';

import FindReplaceModal from './FindReplaceModal';
import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {SlateEventsProvider} from './SlateEvents';
import {SlateWrapper} from './SlateWrapper';
import {getAuthToken} from 'services/utils';
import {getTrimmedTranscript} from './transcriptUtils';
import {showErrorNotification} from 'features/Common/utils';
import {updateTranscript as updateTranscriptAction} from 'features/userLibrarySlice';
import {useDispatch} from 'react-redux';
import {useState} from 'react';
import {useUploadTranscript} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/UploadTranscriptContext';

type TranscriptEditorProps = {
  userUploadId: string;
  language: LanguageId;
  range?: {startMs: number; endMs: number};
  seekTo?: ({timeMs}: {timeMs: number}) => void;
  timeOffsetMs?: number;
  onClickWord?: (word: TranscriptWord) => void;
  toolbarOptions?: SelectionToolbarOptions;
};

export interface SearchResultState {
  searchResults: TranscriptWord[];
  count: number;
  activeIndex: number;
}

export const TranscriptEditor = ({
  userUploadId,
  language,
  range,
  seekTo,
  timeOffsetMs = 0,
  onClickWord,
  toolbarOptions,
}: TranscriptEditorProps) => {
  const {autoScroll, editMode} = useUploadTranscript();
  const {setSelection, selection, isTemporary, selectedChapter, hoveringClip} =
    useSelection();
  const dispatch = useDispatch();
  const showCorrectionModal = useCorrectionModalOpen();
  const {transcript: fullTranscript} = useTranscript(userUploadId, language);
  const selectedWords = useSelectedTranscript(userUploadId, language);
  const [searchResultsState, setSearchResultsState] = useState<SearchResultState>({
    searchResults: [],
    count: 0,
    activeIndex: 0,
  });

  if (!fullTranscript) return null;

  const trimmedTranscript = getTrimmedTranscript(fullTranscript, range, false);

  const updateTranscript = (newTranscript: TranscriptJSON, editedText: string) => {
    getAuthToken()
      .then(token => {
        dispatch(
          updateTranscriptAction({
            token,
            transcript: newTranscript,
            language,
            userUploadId,
            editedText,
          }),
        );
      })
      .catch(error => {
        showErrorNotification({
          title: "Couldn't save edit",
          message: 'An unknown error occurred',
          error,
        });
      });
  };

  return (
    <>
      <SlateWrapper
        autoScroll={autoScroll}
        transcript={trimmedTranscript}
        onChangeTranscript={updateTranscript}
        onChangeCursor={(timeMs, word) => {
          if (onClickWord && word) {
            onClickWord(word);
          }

          seekTo?.({timeMs});
        }}
        onChangeSelection={selection => {
          if (selection) {
            setSelection([selection.start, selection.end]);
          } else {
            setSelection(null);
          }
        }}
        selectedWords={selectedWords}
        timeOffsetMs={timeOffsetMs}
        editMode={editMode}
        toolbar={
          selection &&
          (!isTemporary || !!selectedChapter) && (
            <SelectionToolbar
              targetedUserUploadId={userUploadId}
              language={language}
              {...toolbarOptions}
            />
          )
        }
        searchResults={searchResultsState.searchResults}
        activeSearchResult={
          searchResultsState.searchResults[searchResultsState.activeIndex]
        }
      />
      {showCorrectionModal && (
        <SlateEventsProvider>
          <TranscriptCorrectionModal
            userUploadId={userUploadId}
            transcript={fullTranscript}
            onChangeTranscript={updateTranscript}
            language={language}
          />
        </SlateEventsProvider>
      )}
      <FindReplaceModal
        transcript={fullTranscript}
        onChangeTranscript={updateTranscript}
        searchResultsState={searchResultsState}
        setSearchResultsState={setSearchResultsState}
      />
    </>
  );
};
