import {CaptionItemStyle} from 'features/types/canvasItemsSlice';
import SceneFrame from '../shared/SceneFrame';
import WordFrame from 'features/Captions/WordFrame';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';
import {useTimeSelector} from 'features/EditorCanvas/components/CanvasTime/useTimeSelector';

/**
 * Calculate the active frame index based on the currentTime.
 *
 * Note: Update this with scene progression later
 *
 * @param currentTime
 * @param frameStartTimes
 * @returns
 */
const calculateActiveFrame = (currentTime: number, frameStartTimes: number[]) => {
  if (currentTime < frameStartTimes[0]) {
    return 0;
  } else if (currentTime >= frameStartTimes[frameStartTimes.length - 1]) {
    return frameStartTimes.length - 1;
  } else {
    return frameStartTimes.findIndex(startTime => startTime > currentTime) - 1;
  }
};

export const useVisibleFrameIndex = (wordFrames: WordFrame[]) => {
  const {activeScene} = useScenes();

  return useTimeSelector(
    ({currentTime}) => {
      const frameStartTimes = wordFrames
        .filter(frame => frame.wordsArr.length > 0)
        .map(frame => frame.wordsArr[0].start);

      if (frameStartTimes.length === 0) return 0;

      const flooredTime = currentTime - activeScene.startTimeMs;
      return calculateActiveFrame(flooredTime, frameStartTimes);
    },
    [activeScene, wordFrames],
  );
};

export default function LayerCaptionsClip({
  style,
  sceneFrames,
  width,
  height,
  lineHeight,
  fontFamily,
  fontSize,
}: {
  style: CaptionItemStyle;
  sceneFrames: WordFrame[];
  width: number;
  height: number;
  lineHeight: number;
  fontFamily: string;
  fontSize: string;
}) {
  const {color, colorInactive} = style;

  const visibleFrameIndex = useVisibleFrameIndex(sceneFrames);

  if (!sceneFrames || !sceneFrames.length) {
    return null;
  }

  const visibleWordFrame = sceneFrames.find((el, index) => index === visibleFrameIndex);

  return (
    <div
      aria-label="caption-clip-layer"
      className="relative m-auto"
      style={{...style, height: `${height}px`, width: `${width}px`}}
    >
      <div
        className="flex select-none flex-col justify-center"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        {visibleWordFrame && visibleWordFrame.wordsArr && (
          <SceneFrame
            color={color}
            colorInactive={colorInactive ?? color}
            lineHeight={lineHeight}
            fontFamily={fontFamily}
            frame={visibleWordFrame}
            fontSize={fontSize}
          />
        )}
      </div>
    </div>
  );
}
