import {useEffect, useRef, useState} from 'react';

import {Button} from 'ui/Button';
import classNames from 'classnames';
import {showSuccessNotification} from 'features/Common/utils';
import {useUpdateLandingPageMutation} from 'services/landingPageAPI';
import {useUpdateRecordingFormMutation} from 'services/recordingFormsAPI';

type EditableRecordingFormDetailPromptFieldProps = {
  recordingFormId: string;
  recordingFormPromptId?: string;
  fieldName: 'title' | 'description' | ['component_attributes', 'url'];
  fieldValue: string | null;
  className?: string;
  placeholder?: string;
};

export default function EditableRecordingFormDetailPromptField({
  recordingFormId,
  recordingFormPromptId,
  fieldName,
  fieldValue,
  className,
  placeholder,
}: EditableRecordingFormDetailPromptFieldProps) {
  const [updateRecordingForm, {isLoading: isUpdating}] = useUpdateRecordingFormMutation();
  const [tempFieldValue, setTempFieldValue] = useState(fieldValue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitField = async () => {
    window.analytics.track('Submit updated field from landing page form');
    if (tempFieldValue == null) return;

    if (recordingFormPromptId) {
      await handleComponentItemChange(recordingFormPromptId, fieldName, tempFieldValue);
    } else {
      await handleLandingPageFieldChange(fieldName as string, tempFieldValue);
    }
  };

  const handleLandingPageFieldChange = (fieldName: string, newFieldValue: string) => {
    showSuccessNotification({
      title: 'Question updated',
      message: 'Click "Share form" to collect your first response.',
    });
    return updateRecordingForm({
      id: recordingFormId,
      [fieldName]: newFieldValue,
    });
  };

  const handleComponentItemChange = (
    recordingFormPromptId: string,
    fieldName: string | string[],
    newFieldValue: string,
  ) => {
    const component = {id: recordingFormPromptId};
    if (Array.isArray(fieldName)) {
      component[fieldName[0]] = {[fieldName[1]]: newFieldValue};
    } else {
      component[fieldName] = newFieldValue;
    }

    return updateRecordingForm({
      id: recordingFormId,
      user_recording_form_prompts_attributes: [component],
    });
  };

  const input = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTempFieldValue(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (!input.current) return;
    if (tempFieldValue == null) return;
    if (input.current.innerText === tempFieldValue) return;

    input.current.innerText = tempFieldValue;
  }, [tempFieldValue]);

  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="flex w-full items-start">
      <div
        className={classNames(
          className,
          'relative w-full cursor-text rounded-md bg-black bg-opacity-0 p-2 ring-0 ring-indigo-500 ring-opacity-0 transition focus-within:outline-none focus-within:ring-2 focus-within:ring-opacity-50 hover:bg-opacity-5',
        )}
      >
        <div className="relative">
          {!tempFieldValue && placeholder && !isFocused && (
            <div className="pointer-events-none absolute inset-0 text-gray-500">
              {placeholder}
            </div>
          )}
          <div
            className="focus:outline-none  "
            contentEditable
            onKeyUp={event => {
              setTempFieldValue(event.currentTarget.innerText);
            }}
            ref={input}
            onBlur={() => {
              setIsSubmitting(true);
              submitField().finally(() => {
                setIsSubmitting(false);
              });
              setIsFocused(false);
            }}
            onFocus={() => setIsFocused(true)}
          />
        </div>
      </div>

      <div className={classNames('ml-2', {})}>
        <Button loading={isSubmitting} variant={isFocused ? 'primary' : 'tertiary'}>
          Save
        </Button>
      </div>
    </div>
  );
}
