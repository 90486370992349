// Section First Campaign

import {Button, ButtonVariant} from 'ui/Button';
import {DashParams, routePaths} from 'routes/routesHelper';

import {CheckCircle} from 'react-feather';
import {Link} from 'react-router-dom';

export const SectionThirdCampaignHeader = ({
  hasProject,
  hasDownloaded,
}: {
  hasDownloaded: boolean;
  hasProject: boolean;
}) => {
  return (
    <>
      {hasDownloaded ? (
        <ViewDownloads />
      ) : (
        <>
          {!hasProject && <SectionLaunch />}
          {hasProject && <SectionDownloadList />}
        </>
      )}
    </>
  );
};

const ViewDownloads = () => {
  return (
    <div className="pb-4">
      <div className="md:flex md:items-center md:justify-between">
        <div className="w-full min-w-0 space-y-2 py-3">
          <h2 className="text-normal font-semibold leading-7 text-gray-700 sm:truncate sm:text-2xl">
            {'Share your downloads'}
          </h2>
          <p className="text-normal  font-semi sm:text-normal text-gray-700 sm:truncate">
            {'To make a new export, go to your project and export.'}
          </p>
        </div>
      </div>
      <Link to={`/${routePaths.dashboard}/${DashParams.DownloadsParam}`}>
        <Button variant={'primary'}>View downloads</Button>
      </Link>
    </div>
  );
};

const SectionDownloadList = () => {
  return (
    <div className="pb-4">
      <div className="md:flex md:items-center md:justify-between">
        <div className="w-full min-w-0 space-y-2 py-3">
          <h2 className="text-normal font-semibold leading-7 text-gray-700 sm:truncate sm:text-2xl">
            {'Download your first project'}
          </h2>
          <p className="text-normal  font-semi sm:text-normal text-gray-700 sm:truncate">
            {'Go to your project and export the project to download.'}
          </p>
        </div>
      </div>
      <Link to={`/${routePaths.dashboard}/${DashParams.ProjectsParam}`}>
        <Button variant={'primary'}>View projects</Button>
      </Link>
    </div>
  );
};

const SectionLaunch = () => {
  return (
    <div>
      <div className="pb-4 md:flex md:items-center md:justify-between">
        <div className="w-full min-w-0 space-y-2 py-3">
          <h2 className="text-normal font-semibold leading-7 text-gray-700 sm:truncate sm:text-2xl">
            {'You haven’t started a campaign yet. Let’s change that.'}
          </h2>
          <p className="text-normal  font-semi sm:text-normal text-gray-700 sm:truncate">
            {'Start at step one, or choose from among these '}{' '}
            <Link
              className="text-indigo-600 underline"
              to={`/${routePaths.dashboard}/${DashParams.CreateCampaign}`}
            >
              {'cool things to create.'}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export const SectionThirdUpload = ({
  hasProject,
  hasDownloaded,
}: {
  hasDownloaded: boolean;
  hasProject: boolean;
}) => {
  return (
    <div className="space-y-6">
      <SectionThirdCampaignHeader hasDownloaded={hasDownloaded} hasProject={hasProject} />
    </div>
  );
};
