import classNames from 'classnames';
import {Children, forwardRef} from 'react';

type DivProps = Omit<JSX.IntrinsicElements['div'], 'ref'>;

type StackProps = DivProps & {spacing: number; direction: 'horizontal' | 'vertical'};

const Stack = forwardRef<HTMLDivElement, StackProps>(
  ({direction, spacing, children, className, ...rest}, ref) => {
    const lastIndex = Children.count(children) - 1;

    let dimension = 'w';
    let flex = 'flex flex-row';
    if (direction === 'vertical') {
      dimension = 'h';
      flex = '';
    }

    return (
      <div className={classNames(flex, className)} ref={ref} {...rest}>
        {Children.map(children, (child, index) => {
          const isLastElement = index === lastIndex;

          return (
            <>
              {child}
              {child && !isLastElement && (
                <div className={`${dimension}-${spacing} flex-shrink-0`} />
              )}
            </>
          );
        })}
      </div>
    );
  },
);

export const VStack = forwardRef<HTMLDivElement, Omit<StackProps, 'direction'>>(
  (props, ref) => {
    return <Stack {...props} direction="vertical" ref={ref} />;
  },
);

export const HStack = forwardRef<HTMLDivElement, Omit<StackProps, 'direction'>>(
  (props, ref) => {
    return <Stack {...props} direction="horizontal" ref={ref} />;
  },
);
