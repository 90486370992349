import {Time} from 'features/Common/Time';
import {useDuration} from 'features/EditorCanvas/components/CanvasTime/useDuration';
import {usePlayback} from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import {usePlaybackPercentage} from 'features/EditorCanvas/components/CanvasTime/utils';
import {SelectionRange} from 'features/TranscriptEditor/selection';
import {clamp} from 'lodash';

export const percentageToTime = (percentage: number, duration: number) => {
  const clampedPercentage = clamp(percentage, 0, 100);
  return new Time((clampedPercentage / 100) * duration);
};

export const useStartClipping = ({
  setSelection,
}: {
  setSelection: (selection: SelectionRange) => void;
}) => {
  const playbackPercentage = usePlaybackPercentage();
  const duration = useDuration();
  const {pause} = usePlayback();

  return () => {
    pause();

    setSelection([
      percentageToTime(playbackPercentage, duration).ms,
      percentageToTime(playbackPercentage + 10, duration).ms,
    ]);
  };
};
