import {Button, ButtonStyle} from 'features/Common/Button';
import {
  getGlossary,
  saveLocalGlossaryTerms,
  saveUpdateGlossary,
} from 'features/userLibrarySlice';
import {
  glossarySelector,
  userLibrarySelector,
} from 'features/selectors/userLibrarySelectors';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import {Card} from 'features/Common/Card';
import {CardHeader} from 'features/Common/CardHeader';
import {Switch} from '@headlessui/react';
import {authAccountIsGlossaryActiveSelector} from 'features/selectors/authSelectors';
import classNames from 'classnames';
import {updateIsGlossaryActive} from 'features/authSlice';
import {useAuthAlt} from 'features/Auth/useAuthAlt';

export function SettingsGlossaryCard() {
  const isGlossaryActive = useSelector(authAccountIsGlossaryActiveSelector);

  const {
    accountSettingsStatuses: {isAccountGlossarySubmitting},
  } = useSelector(userLibrarySelector);
  const glossary = useSelector(glossarySelector)();
  const {getAccessTokenSilently} = useAuthAlt();
  const dispatch = useDispatch();

  const saveGlossary = () => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(saveUpdateGlossary(token, glossary));
      } catch (error) {
        console.error('error', error);
      }
    })();
  };

  useEffect(() => {
    const effect = async () => {
      const token = await getAccessTokenSilently();

      dispatch(getGlossary(token));
    };
    effect();
  }, []);

  const [enabled, setEnabled] = useState(!!isGlossaryActive);

  useEffect(() => {
    setEnabled(!!isGlossaryActive);
  }, [isGlossaryActive]);

  const handleChangeEnabled = () => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(updateIsGlossaryActive(token, !enabled));
        setEnabled(!enabled);
      } catch (error) {
        console.error('error', error);
      }
    })();
  };

  return (
    <>
      <Card>
        <CardHeader
          title="Glossary"
          subtitle="Uncommon terms in your glossary are likely to be transcribed accurately. "
        />
        <div id="account-glossary" className="shadow sm:overflow-hidden">
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <Switch.Group as="div" className="flex items-center justify-between">
              <span className="flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium text-gray-900"
                  passive
                >
                  Activate word glossary
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  When the switch is active, new transcripts will prioritize the words you
                  list in your glossary.
                </Switch.Description>
              </span>
              <Switch
                checked={enabled}
                onChange={handleChangeEnabled}
                className={classNames(
                  enabled ? 'bg-indigo-600' : 'bg-gray-200',
                  'focus:outline-none relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          {enabled && (
            <div className="space-y-6 border-t border-gray-100 bg-white py-6 px-4 sm:p-6">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Terms
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows={3}
                      className={classNames(
                        'mt-1 block w-full max-w-prose rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
                        {
                          'bg-gray-50 opacity-90': isAccountGlossarySubmitting,
                        },
                      )}
                      name="glossary"
                      placeholder={'Enter glossary terms here'}
                      disabled={isAccountGlossarySubmitting || !glossary.terms}
                      value={glossary?.terms?.join('\n')}
                      onChange={evt => {
                        dispatch(
                          saveLocalGlossaryTerms({
                            terms: evt.currentTarget.value.split('\n'),
                          }),
                        );
                      }}
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Each term should be listed on a seperate line.
                  </p>
                </div>
              </div>
            </div>
          )}
          {enabled && (
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <Button style={ButtonStyle.PRIMARY} onClick={saveGlossary}>
                Save
              </Button>
            </div>
          )}
        </div>
      </Card>
    </>
  );
}
