import * as Sentry from '@sentry/browser';

import Uppy from '@uppy/core';

export type UppyFile = Uppy.UppyFile<{key: string}>;

export const getMetadata = (file: UppyFile) => {
  return {
    file_source: file.source,
    file_type: file.type,
    file_size: file.size,
    file_extension: file.extension,
  };
};

/**
 * Uppy.on('upload-error)
 */
export const handleUploadError = (file: UppyFile, error: Error, response: any) => {
  window.analytics.track('User error on file to upload', getMetadata(file));
  Sentry.captureException(error);
  console.error('file', file);
  console.error('error', error);
  console.error('response', response);
};

/**
 * Uppy.on('error)
 */
export const handleError = (error: Error) => {
  window.analytics.track('User upload error');
  Sentry.captureException(error);
  console.error('error', error);
};

/**
 * Uppy.on('restriction-failed)
 */
export const handleRestrictionFailed = (file: UppyFile, error: Error) => {
  window.analytics.track('File restriction error on file to upload', getMetadata(file));
  Sentry.captureException(error);
  console.error('file', file);
};
