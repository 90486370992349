import {FC, ReactElement, ReactNode, useEffect, useState} from 'react';
import {useStytchSession, useStytchUser} from '@stytch/stytch-react';

import {FixedLoadingPage} from 'ui/FixedLoadingPage';
import {Navigate} from 'react-router-dom';
import {announceUserToApi} from 'features/authSlice';
import {routePaths} from 'routes/routesHelper';
import {useCookies} from 'react-cookie';
import {useDispatch} from 'react-redux';
import {useMergeAnonymousUserMutation} from 'services/authAPI';

export const AuthProviderWrapper = ({children}: {children: ReactElement}) => {
  return <AnnounceUser>{children}</AnnounceUser>;
};

const AnnounceUser = ({children}: {children: ReactElement}) => {
  const [cookies, , removeCookie] = useCookies([
    'anonymous-token',
    'stytch_session_jwt',
    'stytch_session',
  ]);

  const {
    stytch_session_jwt: stytchSessionJdkCookie,
    stytch_session: stytchSessionCookie,
  } = cookies;

  const [loading, setLoading] = useState(true);

  const user = useStytchUser();
  const session = useStytchSession();

  // console.log('0-=====');
  // console.log('loading', loading);
  // console.log('session', session);
  // console.log('user', user);
  // console.log('stytchSessionJdkCookie', stytchSessionJdkCookie);
  // console.log('stytchSessionCookie', stytchSessionCookie);

  const anonymousToken = cookies['anonymous-token'];
  const [mergeAnonymousUser] = useMergeAnonymousUserMutation();

  const dispatch = useDispatch();

  // console.log('HERE WE ARE');

  useEffect(() => {
    if (!user || !session) return;

    const announceUser = (token: string) =>
      new Promise<void>((resolve, reject) => {
        dispatch(
          announceUserToApi(token, user, error => {
            if (error) reject(error);
            resolve();
          }),
        );
      });

    const run = async () => {
      await announceUser(stytchSessionJdkCookie);

      if (session && anonymousToken) {
        console.log('Attempting to merge anonymous user', session, anonymousToken);
        await mergeAnonymousUser({anonymousToken});
        removeCookie('anonymous-token');
      }
    };

    run()
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [
    anonymousToken,
    session,
    user,
    dispatch,
    stytchSessionJdkCookie,
    mergeAnonymousUser,
    removeCookie,
  ]);

  if (session === null) {
    return <Navigate to={`/${routePaths.user}/login`} />;
  }

  if (loading) {
    return <FixedLoadingPage />;
  }

  return children;
};
