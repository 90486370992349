import classNames from 'classnames';
import {muxThumbnail} from 'features/Dashboard/DashboardUploadDetails/utils';
import {TimeSeconds} from 'features/types/userLibrarySlice';
import {useEffect, useState} from 'react';

interface WrappedReactPlayerProps {
  clipId: string;
  playbackId: string;
  startTime: number;
  endTime: number;
  isPlaying: boolean;
  setIsPlaying: any;
  videoPlayer: any;
  videoIsReady: boolean;
  setVideoIsReady: any;
  videoRef: any;
}

export default function WrappedReactPlayer({
  clipId,
  playbackId,
  startTime,
  endTime,
  isPlaying,
  videoPlayer,
  setIsPlaying,
  videoIsReady,
  setVideoIsReady,
  videoRef,
}: WrappedReactPlayerProps) {
  const [playerState, setPlayerState] = useState({
    playing: false,
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
    duration: 0,
    seeking: false,
    seekTo: (timeSeconds: TimeSeconds) => {},
  });
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);

  const [activePlayed, setActivePlayed] = useState(0);

  const duration = playerState.duration;
  const playedSeconds = playerState.playedSeconds;
  const playTimeDuration = endTime - startTime;
  const clipRangePlayed = Math.abs(
    (playerState.playedSeconds - startTime) / playTimeDuration,
  );

  useEffect(() => {
    if (videoPlayer !== null && videoIsReady) {
      setActivePlayed(clipRangePlayed);
    }
  }, [videoIsReady, videoPlayer, clipRangePlayed]);

  const handleDuration = (duration: any) => {
    setPlayerState({...playerState, duration});
  };

  const handleSeekMouseDown = () => {
    handlePause();
    setPlayerState({...playerState, seeking: true});
  };

  const handleSeekChange = (e: any) => {
    const newPercent = e.target.value;
    setActivePlayed(newPercent);
  };

  const handleSeekMouseUp = (e: any) => {
    setPlayerState({...playerState, seeking: false});
    // console.log('e.target.value', e.target.value);
    const newTime = startTime + activePlayed * playTimeDuration;
    videoPlayer.seekTo(newTime, 'seconds');
  };

  const handleClick = () => {
    setIsPlaying(!isPlaying);
  };

  const handlePause = () => {
    setPlayerState({...playerState, playing: false});
    setIsPlaying(false);
  };

  useEffect(() => {
    setVideoIsPlaying(isPlaying);
  }, [isPlaying]);

  const resetPlayer = () => {
    if (videoPlayer !== null) {
      setActivePlayed(0);
      videoPlayer.seekTo(startTime, 'seconds');
    }
  };

  useEffect(() => {
    if (videoPlayer !== null) {
      videoPlayer.seekTo(startTime, 'seconds');
    }
  }, [videoPlayer, startTime]);

  useEffect(() => {
    // console.log('videoPlayer', videoPlayer);
    if (isPlaying) {
      if (videoPlayer !== null) {
        if (playedSeconds > endTime) {
          handlePause();
          resetPlayer();
        }
      }
    }
  }, [isPlaying, playedSeconds, endTime]);

  useEffect(() => {
    // console.log('videoPlayer', videoPlayer);
    if (!isPlaying) {
      if (!playerState.seeking) {
        resetPlayer();
      }
    }
  }, [isPlaying]);

  // console.log('clipRangePlayed', clipRangePlayed);

  return (
    <div className="relative">
      <img
        className="h-full w-full"
        alt="preview"
        src={muxThumbnail(playbackId, Number(startTime))}
      />
      {/* {!videoIsReady && <div className="w-full h-full animate-pulse " />}
      <ReactPlayer
        progressInterval={1000 / 30}
        onProgress={handleProgress}
        onDuration={handleDuration}
        playing={isPlaying}
        onReady={() => {
          setVideoIsReady(true);
        }}
        ref={videoRef}
        width="100%"
        height="100%"
        url={`https://stream.mux.com/${playbackId}.m3u8`}
        className="select-none"
      /> */}
      <div
        className={classNames(
          'align-center absolute  bottom-2 mx-auto flex w-full justify-center px-3',
          {
            hidden: !videoIsReady,
          },
        )}
      >
        <input
          className="focus:outline-none h-3 w-full cursor-pointer appearance-none overflow-hidden rounded-lg bg-gray-700"
          type="range"
          min={0}
          max={0.999999}
          step="any"
          value={activePlayed}
          onMouseDown={handleSeekMouseDown}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
        />
      </div>
      {/* <button
        onClick={handleClick}
        type="button"
        className="absolute align-center flex-row top-2 left-2 bg-opacity-50 bg-gray-900 hover:bg-opacity-75 inline-flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white focus:outline-none  transition ease-in-out duration-150 justify-center"
      >
        <span className="ml-1 mr-2">
          {isPlaying ? (
            <svg
              className="h-5 w-5 fill-current inline-flex items-center justify-center flex-row"
              viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3.75 2.5V7.5H2.5V2.5H3.75ZM3.75 1.875H2.5C2.33424 1.875 2.17527 1.94085 2.05806 2.05806C1.94085 2.17527 1.875 2.33424 1.875 2.5V7.5C1.875 7.66576 1.94085 7.82473 2.05806 7.94194C2.17527 8.05915 2.33424 8.125 2.5 8.125H3.75C3.91576 8.125 4.07473 8.05915 4.19194 7.94194C4.30915 7.82473 4.375 7.66576 4.375 7.5V2.5C4.375 2.33424 4.30915 2.17527 4.19194 2.05806C4.07473 1.94085 3.91576 1.875 3.75 1.875V1.875Z" />
              <path d="M7.5 2.5V7.5H6.25V2.5H7.5ZM7.5 1.875H6.25C6.08424 1.875 5.92527 1.94085 5.80806 2.05806C5.69085 2.17527 5.625 2.33424 5.625 2.5V7.5C5.625 7.66576 5.69085 7.82473 5.80806 7.94194C5.92527 8.05915 6.08424 8.125 6.25 8.125H7.5C7.66576 8.125 7.82473 8.05915 7.94194 7.94194C8.05915 7.82473 8.125 7.66576 8.125 7.5V2.5C8.125 2.33424 8.05915 2.17527 7.94194 2.05806C7.82473 1.94085 7.66576 1.875 7.5 1.875V1.875Z" />
            </svg>
          ) : (
            <svg
              className="h-5 w-5 fill-current inline-flex items-center justify-center flex-row"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M3.5 14C3.3674 14 3.24022 13.9473 3.14645 13.8535C3.05268 13.7598 3 13.6326 3 13.5V2.49997C2.99965 2.41187 3.02259 2.32524 3.06649 2.24885C3.11039 2.17246 3.1737 2.10903 3.25 2.06497C3.32601 2.02109 3.41224 1.99799 3.5 1.99799C3.58777 1.99799 3.67399 2.02109 3.75 2.06497L13.25 7.56497C13.327 7.60858 13.391 7.67182 13.4355 7.74824C13.48 7.82466 13.5035 7.91153 13.5035 7.99997C13.5035 8.08842 13.48 8.17528 13.4355 8.2517C13.391 8.32812 13.327 8.39136 13.25 8.43497L3.75 13.935C3.6738 13.9782 3.58759 14.0006 3.5 14ZM4 3.36497V12.635L12 7.99997L4 3.36497Z" />
            </svg>
          )}
        </span>
        {isPlaying || playerState.seeking
          ? `${Math.floor((endTime - startTime) * activePlayed)} sec`
          : `${Math.floor(endTime - startTime)} sec`}
      </button> */}
    </div>
  );
}
