import {TranscriptParagraph} from 'features/Captions/Transcripts';

export function downloadSrtFile(filename: string, text: string) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text),
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

/* Create a JavaScript function that takes a transcript JSON file and the number of characters as an input and outputs an SRT file */
export function createSRT(
  transcriptJsonDocument: TranscriptParagraph[] | undefined,
  numberOfCharacters: number,
) {
  const transcriptJson = transcriptJsonDocument;
  // Check if input is valid
  if (!transcriptJson) {
    console.log('Please provide a valid JSON input.');
  } else {
    // Variable to store output string
    let output = '';
    let counter = 0;

    // Loop through each subtitle array
    for (let i = 0; i < transcriptJson.length; i++) {
      // Loop through each object in subtitle array
      for (let j = 0; j < transcriptJson[i].length; j++) {
        // Increment counter for each object in subtitle array
        counter++;

        // Set start and end time to variables
        let startTime = transcriptJson[i][j].start / 1000;
        let endTime = transcriptJson[i][j].end / 1000;

        // Convert start and end time to hh:mm:ss,fff format
        startTime = new Date(startTime * 1000).toISOString().substr(11, 12);
        endTime = new Date(endTime * 1000).toISOString().substr(11, 12);

        // Set text to a variable
        const text = transcriptJson[i][j].text;

        // Split text into an array of words
        const textArray = text.split(' ');

        // Variable to store new line of text
        let newLine = '';

        // Loop through each word in the array of words
        for (let k = 0; k < textArray.length; k++) {
          // Add word to the new line of text
          newLine += textArray[k] + ' ';

          // If the length of the new line of text is greater than the number of characters, add a new line
          if (newLine.length > numberOfCharacters) {
            output +=
              counter + '\n' + startTime + ' --> ' + endTime + '\n' + newLine + '\n\n';
            // Reset new line of text variable
            newLine = '';
          }
        }

        // Add the last line of text to the output string
        output +=
          counter + '\n' + startTime + ' --> ' + endTime + '\n' + newLine + '\n\n';
      }
    }
    console.log(output);
    return output;
  }
}
