import {NON_RESIZABLE_ITEMS, updateCanvasItemDimensions} from 'features/canvasItemsSlice';
import {useDispatch, useSelector} from 'react-redux';

import {DimensionType} from 'features/types/canvasItemsSlice';
import {NumberInput} from 'features/EditorCanvas/components/shared/common/NumberInput';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

export default function DimensionPicker() {
  const dispatch = useDispatch();
  const projectId = useProjectId();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  if (!selected) return null;

  if (NON_RESIZABLE_ITEMS.includes(selected.item.viewType)) return null;

  const setDimension = (dimension: Partial<DimensionType>) => {
    dispatch(
      updateCanvasItemDimensions({
        projectId,
        id: selected.id,
        dimension,
      }),
    );
  };

  return (
    <div className="flex">
      <div className="mt-1 flex w-full flex-col pr-6 text-sm leading-5 text-gray-700">
        <div className="flex flex-row">
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label className="mb-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Width
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <NumberInput
                    value={selected.item.dimension.width}
                    onChange={width => setDimension({width})}
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label className="mb-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Height
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <NumberInput
                    value={selected.item.dimension.height}
                    onChange={height => setDimension({height})}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
