import {AccountWrapperAccountType, AccountsWrapperType} from 'features/types/authSlice';
import {ArrowRight, ChevronDown} from 'react-feather';
import {Button, ButtonProps} from 'ui/Button';
import {FC, useEffect, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {FormTextInput, InputError, TextInput, getFormError} from 'ui/TextInput';
import {Link, useNavigate} from 'react-router-dom';
import {changeActiveAccount, createUserWorkspace} from 'features/authSlice';
import {useDispatch, useSelector} from 'react-redux';

import {DropdownMenu} from 'ui/DropdownMenu';
import {FORM_ERROR} from 'final-form';
import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import NotificationError from 'features/Notifications/NotificationError';
import {OnboardingContainer} from './OnboardingContainer';
import {StepProps} from './CreateAccountPage';
import {authSelector} from 'features/selectors/authSelectors';
import classNames from 'classnames';
import {format} from 'date-fns';
import {getAuthToken} from 'services/utils';
import {routePaths} from 'routes/routesHelper';
import {shuffle} from 'lodash';
import {store} from 'react-notifications-component';
import {useAuthAlt} from 'features/Auth/useAuthAlt';

export const CompleteSignup = ({nextStep}: StepProps) => {
  const dispatch = useDispatch();
  const {user} = useAuthAlt();
  const navigate = useNavigate();
  const email = user?.email;

  useEffect(() => {
    window.analytics.track('[App] CompleteSignup loaded');
  }, []);

  const submit = async (values: {
    companyName: string;
    companyDomain: string;
    organizationRole: string;
    publishFrequency: string;
  }) => {
    try {
      // if (!email) throw new Error('User email is required');

      const name = `${user?.name.first_name} ${user?.name.last_name}`;
      if (!name) throw new Error('User name is required');

      const {companyName, companyDomain, ...surveyData} = values;

      const token = await getAuthToken();

      dispatch(
        createUserWorkspace({
          token,
          user: {name, email: email!},
          workspace: values.companyName,
          workspaceDomain: values.companyDomain,
          errorCallback: () => {
            store.addNotification({
              ...NOTIFICATION_BASE,
              content: (
                <NotificationError
                  title={'Workspace creation failed'}
                  message={'Please try again'}
                />
              ),
              type: 'warning',
            });
            navigate(`/${routePaths.dashboard}`);
          },
        }),
      );

      nextStep();
    } catch (error) {
      return {
        // @ts-ignore
        [FORM_ERROR]: error.message,
      };
    }
  };

  return (
    <>
      <OnboardingContainer
        title="Create your team's workspace"
        subtitle="Your team's video projects will be in one place - accessible, searchable, and shareable."
        contentBelow={<ExistingAccountList />}
      >
        <Form onSubmit={submit}>
          {({handleSubmit, valid, submitting, submitError, modifiedSinceLastSubmit}) => (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <Label>Workspace name</Label>

                <FormTextInput
                  type="text"
                  name="companyName"
                  autoComplete="organization"
                  placeholder="Company name"
                  autoFocus
                  required
                />
                <h3 className="text-sm">
                  <i>We suggest using your company or organization name.</i>
                </h3>
              </div>
              <div className="space-y-2">
                <Label>
                  <span>Domain</span> <i className="text-xs">(recommended)</i>
                </Label>

                <FormTextInput
                  type="url"
                  name="companyDomain"
                  autoComplete="url"
                  placeholder="domain.com"
                />
              </div>

              <Button
                variant="primary"
                rightIcon={ArrowRight}
                fullWidth
                type="submit"
                disabled={!valid}
                loading={submitting}
              >
                Get started
              </Button>

              {submitError && !submitting && !modifiedSinceLastSubmit && (
                <InputError>{submitError}</InputError>
              )}
            </form>
          )}
        </Form>
      </OnboardingContainer>
    </>
  );
};

const Label: FC = ({children}) => <div className="-mt-1.5 text-lg">{children}</div>;
const LabelBold: FC = ({children}) => <span className="font-semibold">{children}</span>;

const ExistingAccountList = () => {
  const {accounts} = useSelector(authSelector);

  const dispatch = useDispatch();
  const {user, getAccessTokenSilently} = useAuthAlt();

  const handleAccount = (account: AccountWrapperAccountType) => {
    (async () => {
      const token = await getAccessTokenSilently();
      try {
        dispatch(
          changeActiveAccount({
            token,
            account: account,
            email: user?.email!,
          }),
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  return (
    <>
      {accounts.length > 0 && (
        <div className="mx-auto mb-8 flex w-full max-w-md flex-col items-center">
          <h2 className="mb-4 text-lg font-semibold">Or open an existing workspace</h2>
          <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-1">
            {accounts.map(account => (
              <AccountListItem
                key={account.id}
                account={account}
                handleAccount={handleAccount}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export const AccountListItem = ({
  account,
  handleAccount,
}: {
  account: AccountWrapperAccountType;
  handleAccount: (account: AccountWrapperAccountType) => void;
}) => {
  return (
    <div
      key={account.id}
      className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
    >
      <div className="min-w-0 flex-1">
        <Link
          to={`/${routePaths.dashboard}`}
          onClick={evt => {
            handleAccount(account);
          }}
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">{account.name}</p>
          <p className="truncate text-sm text-gray-500">
            {account?.created_at && format(account.created_at, 'P')}
          </p>
        </Link>
      </div>
    </div>
  );
};
