import classNames from 'classnames';
import {FC, Fragment} from 'react';
import {Shortcut} from 'shortcuts';

type Size = 'large' | 'small';

const Key: FC<{size: Size}> = ({children, size}) => {
  return (
    <div
      className={classNames(
        'box-content overflow-hidden rounded bg-gray-300 pb-0.5 text-center font-medium',
        size === 'large' && 'h-[20px] min-w-[24px]',
        size === 'small' && 'h-[18px] min-w-[20px]',
      )}
    >
      <div className="h-full w-full rounded bg-gray-200 px-1.5">{children}</div>
    </div>
  );
};

export const Kbd = ({
  children,
  size = 'large',
  convertToSymbols = false,
}: {
  children: string;
  size?: Size;
  convertToSymbols?: boolean;
}) => {
  let splitSymbols = [children];
  if (convertToSymbols) {
    const symbols = Shortcut.shortcut2symbols(children);
    splitSymbols = symbols.split('');
  }

  const lastIndex = splitSymbols.length - 1;

  return (
    <div
      className={classNames(
        'flex space-x-1 text-gray-800',
        size === 'large' && 'text-sm leading-[20px]',
        size === 'small' && 'text-xs leading-[18px]',
      )}
    >
      {splitSymbols.map((key, index) => (
        <Fragment key={key}>
          <Key size={size}>{key}</Key>
          {index !== lastIndex && <span className="px-0.5">+</span>}
        </Fragment>
      ))}
    </div>
  );
};
