import {
  UPLOAD_TRANSCRIPT_CONTEXT_DEFAULT,
  UploadTranscriptProvider,
} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/UploadTranscriptContext';
import { usePollTranscript, useTranscript } from 'features/Captions/Transcripts';

import LoadingText from 'features/EditorCanvas/components/shared/LoadingText';
import { ScrollContainer } from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/ThumbnailTimeline/VisibleTranscript';
import { SortedSelection } from 'features/TranscriptEditor/selection';
import { Spinner } from 'ui/Spinner';
import { TranscriptEditor } from 'features/TranscriptEditor/TranscriptEditor';
import { UserUploadsType } from 'features/types/userLibrarySlice';
import { usePlayback } from 'features/EditorCanvas/components/CanvasTime/usePlayback';

export const useCaptions = (upload: UserUploadsType | undefined) => {
  const { hasFullResponse, loading } = useTranscript(upload?.id, 'en');

  const processing = upload?.should_expect_transcript && !hasFullResponse;

  usePollTranscript({
    transcriptId: upload?.id,
    run: processing ?? false,
  });

  return { processing, loading };
};

export const EditTranscript = ({
  upload,
  selection,
}: {
  upload: UserUploadsType;
  selection?: SortedSelection;
}) => {
  const { seekTo, pause } = usePlayback();
  const { loading, processing } = useCaptions(upload);

  if (loading) {
    return (
      <div className="flex justify-center py-9">
        <Spinner size={32} />
      </div>
    );
  }

  if (processing) {
    return (
      <div className="absolute inset-0">
        <div className="opacity-80">
          <LoadingText lineCount={20} />
        </div>
        <div className="absolute inset-5 flex flex-col items-center justify-center space-y-2 text-center">
          <div className="text-lg font-bold">We are generating your captions</div>
          <div className="max-w-sm">
            {"On average, turnaround time is roughly 15-30% of the audio file's duration, with a minimum processing time of around 20-30 seconds"}
          </div>
        </div>
      </div>
    );
  }

  const range = {
    startMs: selection?.start ?? 0,
    endMs: selection?.end ?? upload.duration! * 1000,
  };

  return (
    <ScrollContainer className="!absolute inset-0">
      <UploadTranscriptProvider
        initialState={{
          ...UPLOAD_TRANSCRIPT_CONTEXT_DEFAULT,
          initialAutoScroll: true,
          initialEditMode: false,
        }}
      >
        <TranscriptEditor
          userUploadId={upload.id}
          language="en"
          seekTo={({ timeMs }) => {
            let time = timeMs;
            if (range) time += range.startMs;
            seekTo({ timeMs: time });
          }}
          timeOffsetMs={range.startMs}
          onClickWord={() => pause()}
          toolbarOptions={{ allowClipping: false, showCopy: false }}
        />
      </UploadTranscriptProvider>
    </ScrollContainer>
  );
};
