import {ColorInput} from 'features/Common/ColorInput';
import {updateBackgroundColor} from 'features/sceneFrameSlice';
import {canvasBackgroundSelector} from 'features/selectors/sceneFrameSelectors';
import {useDispatch, useSelector} from 'react-redux';

export default function SidebarBrandBackgroundMenu({projectId}: {projectId: string}) {
  const backgroundColor = useSelector(canvasBackgroundSelector(projectId));
  const dispatch = useDispatch();

  return (
    <ColorInput
      label="Canvas background"
      color={backgroundColor.hex}
      onChange={color => {
        dispatch(updateBackgroundColor({projectId, color}));
      }}
      type="canvas_background"
    />
  );
}
