import { TAB_ACCESS, TAB_EXPORT, TAB_PUBLISH } from './constants';
import { useEffect, useState } from 'react';

import { CanvasModalExport } from './CanvasModalExport';
import CanvasModalPublish from './CanvasModalPublish';
import { EditorCanvasModals } from 'features/EditorCanvas/EditorCanvasModalsContext';

const ActiveTabLink = ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="whitespace-nowrap border-b-2 border-indigo-500 py-4 px-1 text-sm font-medium text-indigo-600"
    aria-current="page"
  >
    {children}
  </button>
);
const InactiveTabLink = ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="whitespace-nowrap border-b-2 border-transparent py-4 px-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
  >
    {children}
  </button>
);

const TabLink = ({ children, isActive, onClick }) => {
  if (isActive) {
    return <ActiveTabLink onClick={onClick}>{children}</ActiveTabLink>;
  }
  return <InactiveTabLink onClick={onClick}>{children}</InactiveTabLink>;
};

export function CanvasModalsWrap({
  startExportFn,
  isExporting,
  closeModal,
  projectId,
  canvasDimensions,
  startingTab = undefined,
}) {
  const [activeTab, setActiveTab] = useState(startingTab || TAB_EXPORT);
  const updateActiveTab = newTab => {
    setActiveTab(newTab);
  };

  const editorCanvasModals = EditorCanvasModals.useContainer();

  useEffect(() => {
    if (editorCanvasModals.activeModal === 'export') {
      setActiveTab(TAB_EXPORT);
    }
    if (editorCanvasModals.activeModal === 'share') {
      setActiveTab(TAB_ACCESS);
    }
  }, [editorCanvasModals.activeModal]);

  return (
    <div style={{ zIndex: 700 }} className="fixed inset-0 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/*
        Background overlay, show/hide based on modal state.
        
        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      */}
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-25" />
        </div>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        ></span>
        {/*
        Modal panel, show/hide based on modal state.
        
        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      */}
        <div
          className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-0 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {/* <TabLink
                    onClick={evt => {
                      // console.log('evt', evt)
                      updateActiveTab(TAB_ACCESS);
                    }}
                    isActive={activeTab === TAB_ACCESS}
                  >
                    Share
                  </TabLink> */}
                  <TabLink
                    onClick={evt => {
                      // console.log('evt', evt);
                      updateActiveTab(TAB_EXPORT);
                    }}
                    isActive={activeTab === TAB_EXPORT}
                  >
                    Export
                  </TabLink>
                  {/* <TabLink
                    onClick={(evt) => {
                      // console.log('evt', evt);
                      updateActiveTab(TAB_PUBLISH);
                    }}
                    isActive={activeTab === TAB_PUBLISH}
                  >
                    Publish
                  </TabLink> */}
                </nav>
              </div>
            </div>
          </div>

          <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
            <button
              onClick={closeModal}
              type="button"
              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="sr-only">Close</span>
              {/* Heroicon name: x */}
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="mt-1 flex w-full flex-col sm:flex sm:items-start">
            {activeTab === TAB_ACCESS && <CanvasModalPublish projectId={projectId} />}
            {activeTab === TAB_PUBLISH && <CanvasModalPublish projectId={projectId} />}
            {activeTab === TAB_EXPORT && (
              <CanvasModalExport
                startExportFn={startExportFn}
                isExporting={isExporting}
                projectId={projectId}
                canvasDimensions={canvasDimensions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
