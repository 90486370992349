import {useEffect, useLayoutEffect, useRef} from 'react';

export const useOnOutsideClick = (onOutsideClick: (e: MouseEvent) => void) => {
  const ref = useRef<HTMLDivElement>(null);

  const callbackRef = useRef(onOutsideClick);
  useLayoutEffect(() => {
    callbackRef.current = onOutsideClick;
  }, [onOutsideClick]);

  useEffect(() => {
    const isInsideClick = (target: EventTarget | null) => {
      if (!ref.current || !target) return;
      return ref.current.contains(target as any) || ref.current === target;
    };

    const onMouseUp = (event: MouseEvent) => {
      document.removeEventListener('mouseup', onMouseUp);

      const insideClick = isInsideClick(event.target);
      if (insideClick) return;

      // Left clicks only
      if (event.button !== 0) return;

      callbackRef.current(event);
    };

    const onMouseDown = (event: MouseEvent) => {
      const insideClick = isInsideClick(event.target);
      if (insideClick) return;

      document.addEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousedown', onMouseDown);
    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  return ref;
};
