import {useDispatch, useSelector} from 'react-redux';

import {ColorInput} from 'features/Common/ColorInput';
import {ColorResult} from 'react-color';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {updateItemAttribute} from 'features/canvasItemsSlice';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

export default function ShapeColorPicker() {
  const projectId = useProjectId();
  const dispatch = useDispatch();
  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);

  if (!selected) return null;

  const handleChange = (color: ColorResult) => {
    dispatch(
      updateItemAttribute({
        projectId,
        id: selected.id,
        property: 'background',
        attribute: 'style',
        newValue: color.hex,
      }),
    );
  };

  const shapeColor = selected.item.style.background;

  return (
    <div className="mt-6 w-full space-y-3">
      <label className="block text-sm font-bold leading-5 text-gray-700">Colors</label>
      <ColorInput
        label="Fill"
        color={shapeColor}
        onChange={handleChange}
        type="shape_background"
      />
    </div>
  );
}
