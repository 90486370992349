import {CSSProperties} from 'react';
import styled from 'styled-components';
import {useSceneTiming} from '../../CanvasTime/useSceneTiming';

const ShapeSquare = styled.div.attrs({
  className: 'absolute top-0 left-0 w-full h-full select-none mask-circle object-cover',
})``;

function ItemLayerSquare({id, style}: {id: string; style: CSSProperties}) {
  const {sceneTimingStyle} = useSceneTiming(id);

  return (
    <ShapeSquare
      style={{
        ...style,
        ...sceneTimingStyle,
      }}
    />
  );
}

export default ItemLayerSquare;
