import {useEffect} from 'react';
import {useSidebarMenu} from './SidebarMenuContext';

export const CloseSidebar = () => {
  const {dispatch} = useSidebarMenu();
  const setActiveView = (newActiveView: string) => {
    dispatch({
      type: 'update active sidebar menu',
      activeMenu: newActiveView,
    });
  };

  useEffect(() => {
    setActiveView('Assets');
  }, []);

  return null;
};
