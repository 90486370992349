import {BaseItem, TextItem} from 'features/types/canvasItemsSlice';
import {useDispatch, useSelector} from 'react-redux';

import {TextFieldInput} from 'features/EditorCanvas/components/shared/common/TextFieldInput';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {updateItemAttribute} from 'features/canvasItemsSlice';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

export const TextInputOverride = () => {
  const projectId = useProjectId();
  const dispatch = useDispatch();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  if (!selected) return null;

  const updateActiveTextField = (nextTextField: string) => {
    dispatch(
      updateItemAttribute({
        projectId,
        id: selected.id,
        attribute: 'text',
        newValue: nextTextField,
      }),
    );
  };

  const textItem = selected.item as BaseItem & TextItem;

  if (!textItem) {
    return null;
  }

  const currentTextValue = textItem.text;

  return (
    <div className="mt-4">
      <TextFieldInput value={currentTextValue} onChange={updateActiveTextField} />
    </div>
  );
};
