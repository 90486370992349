import {AnimatePresence, motion} from 'framer-motion';
import {ArrowDown, ArrowUp} from 'react-feather';
import {Modal, ModalButtons} from 'ui/Modal';
import {
  UserLibraryUserProjectType,
  UserLibraryUserProjectTypeWithCmsData,
} from 'features/types/userLibrarySlice';
import {
  openProject,
  useCreateProjectFromTemplate,
} from 'features/Dashboard/useCreateProject';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useFetchCmsTemplatesQuery, useFetchTemplatesQuery} from 'services/templatesAPI';

import {Button} from 'ui/Button';
import ImageCard from 'ui/ImageCard';
import {Spinner} from 'ui/Spinner';
import {TemplateModal} from '../ContentTemplatesBrowser/TemplateModal';
import classNames from 'classnames';
import {convertSanityEntryToTemplate} from 'features/SanityCms/helpers';
import {fadeProps} from 'features/EditorCanvas/Sidebar/Sidebar';
import {isTemplateAccountSelector} from 'features/selectors/authSelectors';
import {showErrorNotification} from 'features/Common/utils';
import {useAccountTemplatesQuery} from 'services/projectAPI';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useSelector} from 'react-redux';

export const GhostCard = () => {
  return (
    <div className="w-full animate-pulse overflow-hidden rounded-lg">
      <div className="aspect-w-7 aspect-h-4 w-full bg-gray-100" />
      <div className="box-content h-5 bg-gray-50 px-3 py-2 text-sm font-medium" />
    </div>
  );
};

export const TemplateCard = ({
  template,
  onClick,
  s3Preview,
}: {
  template: UserLibraryUserProjectType;
  onClick: () => any;
  s3Preview?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const button = useRef<HTMLButtonElement>(null);

  return (
    <div className="relative w-full">
      <ImageCard
        ref={button}
        /**
         * TODO: When we migrate to Sanity for managing templates, remove this s3Preview helper.
         */
        image={s3Preview ? thumbnailURL(template.slug) : template.latest_preview}
        label={template.name}
        onClick={async () => {
          button.current?.blur();

          setLoading(true);

          try {
            await onClick();
          } catch (error) {
            showErrorNotification({
              title: 'Error creating project',
              message: (error as Error).message,
              error: error as Error,
            });
          }

          setLoading(false);
        }}
      />
      <div
        className={classNames(
          'pointer-events-none absolute inset-0 flex items-center justify-center rounded-lg bg-white bg-opacity-80 opacity-0 transition-opacity',
          loading && 'opacity-100',
        )}
      >
        <Spinner size={32} />
      </div>
    </div>
  );
};

export function useWindowWidthSelector<Value>(getValue: (width: number) => Value) {
  const valueRef = useRef(getValue(window.innerWidth));
  const [, forceRender] = useState(0);

  const getValueRef = useRef(getValue);
  useLayoutEffect(() => {
    getValueRef.current = getValue;
  }, [getValue]);

  useEffect(() => {
    const onResize = (event: UIEvent) => {
      const value = getValueRef.current(window.innerWidth);
      if (value === valueRef.current) return;

      valueRef.current = value;
      forceRender(s => s + 1);
    };

    window.addEventListener('resize', onResize, true);
    return () => window.removeEventListener('resize', onResize, true);
  }, []);

  return valueRef.current;
}

export const ResponsiveGrid = ({
  title,
  children,
}: {
  title?: string;
  children: JSX.Element[];
}) => {
  const columns = useWindowWidthSelector(windowWidth => {
    if (windowWidth < 768) return 1;
    else if (windowWidth < 1024) return 2;
    else if (windowWidth < 1280) return 3;
    else if (windowWidth < 1536) return 4;
    return 5;
  });

  const [showMore, setShowMore] = useState(false);
  const truncatedChildren = children.slice(0, columns);

  if (children.length === 0) return null;

  return (
    <div>
      {title && <h2 className="mb-1 text-lg font-semibold text-gray-800">{title}</h2>}
      <div
        className="mb-4 grid gap-4"
        style={{gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`}}
      >
        {showMore ? children : truncatedChildren}
      </div>
      <div className="flex space-x-2">
        {children.length > columns && (
          <Button
            leftIcon={!showMore ? ArrowDown : ArrowUp}
            variant="secondary"
            onClick={() => {
              window.analytics.track(
                'Pg-Dsh-Upld -- Tmpl-list -- Evt-Clk -- Expand more',
              );
              setShowMore(!showMore);
            }}
          >
            {!showMore ? 'Show more templates' : 'Show less'}
          </Button>
        )}
      </div>
    </div>
  );
};

export const TemplatePicker = () => {
  const {data: cmsData} = useFetchCmsTemplatesQuery();
  const {data: milkApiTemplates} = useFetchTemplatesQuery();
  const {data: accountTemplates} = useAccountTemplatesQuery();
  const createFromTemplate = useCreateProjectFromTemplate();

  const isCmsTemplatesActive = useFeatureFlag('cms-templates');
  const milkTemplates = isCmsTemplatesActive
    ? cmsData?.templates?.map(data => convertSanityEntryToTemplate(data))
    : milkApiTemplates;

  const [selectedAccountTemplateId, setSelectedAccountTemplateId] =
    useState<string | null>(null);
  const selectedAccountTemplate = accountTemplates?.find(
    template => template.slug === selectedAccountTemplateId,
  );

  const [selectedMilkTemplate, setSelectedMilkTemplate] =
    useState<UserLibraryUserProjectTypeWithCmsData | undefined>();

  const isTemplateAccount = useSelector(isTemplateAccountSelector);
  if (isTemplateAccount) return null;

  const noTemplates = milkTemplates?.length === 0 && accountTemplates?.length === 0;
  if (noTemplates) return null;

  return (
    <div className="w-full space-y-4 pl-2">
      <div className="flex items-center justify-between">
        {/* <Button leftIcon={Plus} variant="secondary" negativeMargin _className="!mr-0">
          Create a template
        </Button> */}
      </div>
      <AnimatePresence exitBeforeEnter initial={false}>
        {!milkTemplates || !accountTemplates ? (
          <motion.div key="loading" {...fadeProps}>
            <div className="mb-4 box-content h-5 animate-pulse rounded-lg bg-gray-50 py-3" />
            <ResponsiveGrid>
              <GhostCard />
              <GhostCard />
              <GhostCard />
            </ResponsiveGrid>
          </motion.div>
        ) : (
          <motion.div key="loaded" {...fadeProps} className="space-y-4">
            <ResponsiveGrid title="Your team templates">
              {accountTemplates.map(template => (
                <TemplateCard
                  key={template.slug}
                  template={template}
                  onClick={() => {
                    setSelectedAccountTemplateId(template.slug);
                  }}
                />
              ))}
            </ResponsiveGrid>
            <ResponsiveGrid title="Professional templates">
              {milkTemplates.map(template => (
                <TemplateCard
                  key={template.slug}
                  template={template}
                  s3Preview={!isCmsTemplatesActive}
                  onClick={() => {
                    setSelectedMilkTemplate(template);
                  }}
                />
              ))}
            </ResponsiveGrid>
          </motion.div>
        )}
      </AnimatePresence>
      <TemplateModal
        template={selectedMilkTemplate}
        setSelectedTemplate={setSelectedMilkTemplate}
      />
      <Modal
        title={selectedAccountTemplate?.name ?? ''}
        open={!!selectedAccountTemplate}
        onClose={() => setSelectedAccountTemplateId(null)}
        size="large"
      >
        {selectedAccountTemplate && (
          <>
            <div
              className="aspect-w-16 aspect-h-8 rounded-lg border bg-cover"
              style={{
                backgroundImage: `url("${selectedAccountTemplate.latest_preview}")`,
              }}
            />
            <ModalButtons
              cancel={{
                label: 'Edit template',
                onClick: () => openProject(selectedAccountTemplate.slug),
              }}
              confirm={{
                label: 'Create a project',
                onClick: () => {
                  return createFromTemplate({template: selectedAccountTemplate});
                },
              }}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export const thumbnailURL = (slug: string) =>
  `https://assets-milk.s3.amazonaws.com/template-thumbnails/${slug}.png`;
