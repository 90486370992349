import {AppThunk, GetRootState} from 'configureStore';
import {CanvasItem, DimensionType, PositionType} from '../types/canvasItemsSlice';
import {
  SAMPLE_CAPTION as SAMPLE_CAPTION_ITEM,
  SAMPLE_CLIP_CAPTION_ITEM,
} from 'features/EditorCanvas/constants/ItemConstants';
import {
  addVideoCanvasItem,
  getClipTimes,
  setItemError,
  updateItemAttribute,
} from '../canvasItemsSlice';

import {ItemLayerSources} from 'features/EditorCanvas/constants/ViewConstants';
import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {RecentUsage} from 'services/recentUsageAPI';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {convertToSec} from '../EditorCanvas/utils';
import {getItemSource} from 'features/canvasItemsSlice';
import {getUserClipCaption} from 'api/clipsAPI';
import {selectCanvasItemsProjectsCanvas} from 'features/selectors/canvasItemsSelectors';
import {v4 as uuidv4} from 'uuid';
import vttToJson from 'vtt-to-json';

type CreateCaptionItemProps = {
  projectId: string;
  rawVttJson: any;
  url?: string;
  sceneId: string;
  itemSourceId: string;
  itemSource: ItemLayerSources;
  layerName: string;
  style: RecentUsage;
};

const createCaptionItem = ({
  sceneId,
  itemSourceId,
  itemSource,
  projectId,
  rawVttJson,
  layerName,
  style,
}: CreateCaptionItemProps): AppThunk => {
  return dispatch => {
    const itemId = uuidv4();

    const item = {
      ...SAMPLE_CAPTION_ITEM,
      sceneId,
      playLengthSeconds: convertToSec(rawVttJson[rawVttJson.length - 1].end),
      itemSourceId,
      itemSource,
      layerName,
    };

    dispatch(addVideoCanvasItem({projectId, item, itemId, style}));
  };
};

export type CreateCaptionItemFromClipProps = {
  projectId: string;
  uploadMediaClip: UploadMediaClipType;
  position?: PositionType;
  dimension?: DimensionType;
  sceneId: string;
  style: RecentUsage;
  newId?: string;
};

export const createCaptionItemFromClip =
  ({
    projectId,
    sceneId,
    uploadMediaClip,
    position,
    dimension,
    style,
    newId,
  }: CreateCaptionItemFromClipProps): AppThunk =>
  async (dispatch, getState) => {
    // console.log('uploadMediaClip', uploadMediaClip);
    if (uploadMediaClip) {
      const itemId = newId ?? uuidv4();
      const item = {
        ...SAMPLE_CLIP_CAPTION_ITEM,
        ...getClipTimes(uploadMediaClip),
        itemSourceId: uploadMediaClip.id,
        itemSource: ItemLayerSources.Clips,
        sceneId,
      } as CanvasItem;

      if (uploadMediaClip.clip_name) {
        item.layerName = uploadMediaClip?.clip_name;
      }

      if (position) item.position = position;
      if (dimension) item.dimension = dimension;

      dispatch(
        addVideoCanvasItem({
          projectId,
          item,
          itemId,
          style,
        }),
      );
    }
  };

type AddCaptionItemFromItemProps = {
  sceneId: string;
  projectId: string;
  userUpload: UserUploadsType;
  itemSourceId: string;
  itemSource: ItemLayerSources;
  style: RecentUsage;
};

// TODO (Jacques): Write a test
export const addCaptionItemFromItem =
  ({
    sceneId,
    projectId,
    userUpload,
    itemSourceId,
    itemSource,
    style,
  }: AddCaptionItemFromItemProps): AppThunk =>
  async dispatch => {
    if (!userUpload) return;

    // TODO: move this out
    const vttTextCallback = (vttText: string) => {
      const callback = (vttJson: string) => {
        dispatch(
          createCaptionItem({
            projectId,
            rawVttJson: vttJson,
            itemSourceId,
            itemSource,
            layerName: userUpload.file_name,
            sceneId,
            style,
          }),
        );
      };

      const convertVttToJson = (vtt: string, callback: (vttJson: string) => void) => {
        if (vtt) {
          try {
            vttToJson(`${vtt}\n10:00:00.000 --> 10:00:02.910\n`).then(callback);
          } catch (error) {
            console.error(error);
          }
        }
      };

      convertVttToJson(vttText, callback);
    };

    const domainUrl = userUpload.upload_url.split('://')[1];
    const protocolUpdatedUrl = `${window.location.protocol}//${domainUrl}`;
    window
      .fetch(protocolUpdatedUrl)
      .then(response => response.text().then(vttTextCallback));
  };

export type AddCaptionItemFromClipProps = {
  token: string;
  projectId: string;
  clipId: string;
  position?: PositionType;
  dimension?: DimensionType;
};

export const ensureTranscriptId =
  (token: string, itemId: string, projectId: string): AppThunk =>
  async (dispatch, getState) => {
    const throwError = (error: Error | string) => {
      dispatch(setItemError({projectId, itemId, error}));
    };

    try {
      const canvasItem = selectCanvasItemsProjectsCanvas(getState())[projectId].items[
        itemId
      ];
      if (!canvasItem) {
        throwError('ensureTranscriptId - canvasItem undefined');
        return;
      }

      if (canvasItem.transcriptId && canvasItem.language) return;

      const source = getItemSource(canvasItem);
      if (!source) return;

      let transcriptId: string;

      let language: LanguageId = 'en';

      if (source.type === ItemLayerSources.Clips) {
        const {user_upload, upload_transcript} = await getUserClipCaption(
          token,
          source.id,
        );
        transcriptId = user_upload.id;

        if (upload_transcript.language) {
          language = upload_transcript.language;
        }
      } else if (source.type === ItemLayerSources.Uploads) {
        transcriptId = source.id;
      } else {
        throwError(`ensureTranscriptId - invalid itemSource - ${canvasItem.itemSource}`);
        return;
      }

      dispatch(
        updateItemAttribute({
          projectId,
          id: itemId,
          attribute: 'transcriptId',
          newValue: transcriptId,
        }),
      );

      dispatch(
        updateItemAttribute({
          projectId,
          id: itemId,
          attribute: 'language',
          newValue: language,
        }),
      );
    } catch (error) {
      // @ts-ignore
      throwError(error);
    }
  };
