import {FC, useMemo} from 'react';

import {BrandColors} from './BrandColors';
import {BrandFonts} from './BrandFonts';
import {Card} from 'features/Common/Card';
import {CardHeader} from 'features/Common/CardHeader';
import {DashboardContentSkeleton} from 'features/Dashboard/common/DashboardContentSkeleton';
import {DashboardHead} from 'features/Dashboard/common/DashboardHead';
import {Logos} from './Logos';
import useDimensions from 'react-use-dimensions';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useWindowHeight} from '@react-hook/window-size';

// TODO (jacques): Cypress tests
// (font upload button has broken already)

export function BrandKitPage() {
  const fontsEnabled = useFeatureFlag('brand-kit-fonts');
  const themesEnabled = useFeatureFlag('brand-kit-themes');

  const windowHeight = useWindowHeight();
  const [headRef, {height: headHeight}] = useDimensions();
  const [precontentRef, {height: preContentHeight}] = useDimensions();

  const contentHeight = useMemo(() => {
    return (
      windowHeight -
      (headHeight ? headHeight : 0) -
      (preContentHeight ? preContentHeight : 0)
    );
  }, [windowHeight, headHeight, preContentHeight]);

  return (
    <DashboardContentSkeleton>
      <div ref={headRef}>
        <DashboardHead title={'Brand kit'} />
      </div>
      <div ref={precontentRef} />
      <div
        className={'relative w-full overflow-auto'}
        style={{height: `${contentHeight}px`}}
      >
        <main className="flex-1 focus:outline-none" tabIndex={0}>
          <div className="relative mx-auto max-w-3xl pb-8 md:px-4">
            <div className="space-y-8 px-4 pt-7 sm:px-6 md:px-0">
              <Card>
                <CardHeader
                  title="Brand logos"
                  subtitle={`Keep designs on-brand across your team`}
                />
                <Logos />
              </Card>
              {fontsEnabled && (
                <Card>
                  <CardHeader title="Brand fonts" subtitle={`Just your type`} />
                  <BrandFonts />
                </Card>
              )}
              {themesEnabled && (
                <>
                  <Card>
                    <CardHeader title="Brand colors" subtitle={`Set-and-forget colors`} />
                    <BrandColors />
                  </Card>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </DashboardContentSkeleton>
  );
}

const Section: FC<{title: string}> = ({title, children}) => {
  return (
    <div>
      <h3 className="pb-4 text-2xl font-bold leading-6 text-gray-900">{title}</h3>
      {children}
    </div>
  );
};
