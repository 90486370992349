import {CanvasItem, PlayableItem} from 'features/types/canvasItemsSlice';
import {Volume2, VolumeX} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';

import {Checkbox} from 'features/Common/Checkbox';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {toggleMute} from 'features/canvasItemsSlice';

function isMutableItem(item: CanvasItem): item is CanvasItem & PlayableItem {
  return 'isMuted' in item;
}

export const MutePicker = ({projectId}: {projectId: string}) => {
  const dispatch = useDispatch();
  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);

  if (!selected || !selected.item) return null;
  if (!isMutableItem(selected.item)) return null;

  const handleClick = () => {
    if (!isMutableItem(selected.item)) return;
    dispatch(toggleMute(projectId, selected.id, selected.item.isMuted ?? true));
  };

  return (
    <Checkbox
      label={selected.item.isMuted ? 'Unmute video' : 'Mute video'}
      checked={selected.item.isMuted === false}
      onChange={handleClick}
      CheckedIcon={Volume2}
      UncheckedIcon={VolumeX}
    />
  );
};
