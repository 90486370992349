import {AbsoluteBox, Box} from './Box';
import {
  BaseItem,
  CanvasItem,
  DimensionType,
  PositionType,
} from 'features/types/canvasItemsSlice';
import {SCENE_LAYOUTS, SceneLayoutId} from './layouts';

import {ViewTypes} from '../constants/ViewConstants';
import {getItemByType} from '../utils';

export type UpdatedItem = Pick<BaseItem, 'position' | 'dimension'>;

export type LayoutItem = Pick<CanvasItem, 'dimension' | 'position' | 'viewType'>;

type LayoutItems = Record<string, LayoutItem | undefined>;

type LayoutItemsOptions<T extends LayoutItems> = {
  id: SceneLayoutId;
  items: T;
  withinContainer: {dimension: DimensionType; position?: PositionType};
};

export function layoutItems<T extends LayoutItems>({
  id,
  items,
  withinContainer,
}: LayoutItemsOptions<T>) {
  const video = getItemByType(items, [ViewTypes.Video, ViewTypes.VideoClip]);
  const caption = getItemByType(items, [ViewTypes.Caption, ViewTypes.CaptionClip]);
  // Currently exiting early if no video/caption.
  // We should either hide the layout buttons if there are no
  // valid elements or insert placeholders.
  if (!video || !caption) return;

  const itemsToLayOut = {video, caption};

  const itemsToUpdate: Record<string, Partial<UpdatedItem>> = {};

  const defaultPosition = {top: 0, left: 0};
  const {dimension, position = defaultPosition} = withinContainer;

  SCENE_LAYOUTS[id].layout({
    video: video.item,
    caption: caption.item,
    container: {dimension, position},
    updateItem: (item, data) => {
      itemsToUpdate[itemsToLayOut[item].id] = data;
    },
  });

  return itemsToUpdate;
}

export function layoutAndReturnItems<T extends LayoutItems>(
  options: LayoutItemsOptions<T>,
) {
  const itemsToUpdate = layoutItems(options);
  if (!itemsToUpdate) return options.items;

  const newItems: Record<string, LayoutItem> = {};
  for (const id of Object.keys(options.items)) {
    const originalItem = options.items[id];
    if (!originalItem) continue;

    newItems[id] = {
      ...originalItem,
      ...itemsToUpdate[id],
    };
  }
  return newItems as T;
}

export const twoColumns = ({
  container,
  spacing,
}: {
  container: AbsoluteBox;
  spacing: number;
}) => {
  const leftContainer = new Box(
    {
      width: '50%',
      height: '100%',
      padding: `${spacing}px ${spacing / 2}px ${spacing}px ${spacing}px`,
    },
    container,
  );
  const rightContainer = new Box(
    {
      width: '50%',
      height: '100%',
      left: '50%',
      padding: `${spacing}px ${spacing}px ${spacing}px ${spacing / 2}px`,
    },
    container,
  );

  return {leftContainer, rightContainer};
};

export const fitWithin = ({
  container,
  item,
}: {
  container: DimensionType;
  item: DimensionType;
}) => {
  const itemBox = new Box(item, {position: {top: 0, left: 0}, dimension: container})
    .fit()
    .center();

  return itemBox.getBox();
};
