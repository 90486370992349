import {Dialog, Transition} from '@headlessui/react';
import {ExclamationIcon, XIcon} from '@heroicons/react/outline';
import {Fragment, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {removeTeamMemberFromOrganization} from 'features/authSlice';
import {authDeleteTeamMemberIsLoading} from 'features/selectors/authSelectors';
import {Button} from 'ui/Button';

type Props = {
  closeDialog: () => void;
  isDialogOpen: boolean;
  userToDelete: {
    accountUserId: string;
    userId: string;
    email: string;
  };
};

const ConfirmDeleteTeamMemberDialog = ({
  closeDialog,
  isDialogOpen,
  userToDelete,
}: Props) => {
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);
  const {getAccessTokenSilently} = useAuthAlt();
  const dispatch = useDispatch();

  const deleteUserIsLoading = useSelector(authDeleteTeamMemberIsLoading);

  const onClickRemoveMemberFromAccount = (
    accountUserId: string,
    userId: string,
    email: string,
  ) => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        await dispatch(
          removeTeamMemberFromOrganization({accountUserId, userId, email, token}),
        );

        closeDialog();
      } catch (e: any) {
        console.error(e);

        closeDialog();
      }
    })();
  };

  return (
    <Transition.Root show={isDialogOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        style={{zIndex: 2000}}
        initialFocus={cancelButtonRef}
        open={isDialogOpen}
        onClose={closeDialog}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  className="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={closeDialog}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Are you sure?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      This will remove the team member &quot;<b>{userToDelete.email}</b>
                      &quot; from your organization.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <Button
                  destructive
                  disabled={deleteUserIsLoading}
                  loading={deleteUserIsLoading}
                  onClick={() =>
                    onClickRemoveMemberFromAccount(
                      userToDelete.accountUserId,
                      userToDelete.userId,
                      userToDelete.email,
                    )
                  }
                  variant="primary"
                >
                  Remove User
                </Button>
                &nbsp;&nbsp;
                <Button
                  disabled={deleteUserIsLoading}
                  onClick={closeDialog}
                  ref={cancelButtonRef}
                  variant="tertiary"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmDeleteTeamMemberDialog;
