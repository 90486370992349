import {LightBulbIcon} from '@heroicons/react/outline';
import classNames from 'classnames';
import {FC} from 'react';

export const FOCUSED_FLOW_TOOLBAR_HEIGHT = 74;

export const FocusedFlowToolbar: FC<{className?: string}> = ({children, className}) => {
  return (
    <div
      className="fixed inset-x-0 bottom-0 !ml-0 flex flex-col justify-center bg-white px-4"
      style={{
        height: FOCUSED_FLOW_TOOLBAR_HEIGHT,
        boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
        zIndex: 9999,
      }}
    >
      <div
        className={classNames(
          'mx-auto flex w-full max-w-5xl items-center justify-between px-6',
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export const TipToolbar: FC = ({children}) => {
  return (
    <FocusedFlowToolbar>
      <div className="flex w-full items-center justify-center space-x-2">
        <LightBulbIcon className="w-6 text-yellow-500" />
        <div>{children}</div>
      </div>
    </FocusedFlowToolbar>
  );
};
