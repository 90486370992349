import {BASE_SCENE_FRAME_STATE, SceneFrameSliceProps} from 'features/sceneFrameSlice';
import {BASE_SCENE_STATE, getClipTimes} from 'features/canvasItemsSlice';
import {DashParams, routePaths} from 'routes/routesHelper';
import {ItemLayerSources, ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {asyncTrack, objMap} from 'features/Common/utils';
import {getNewProject, initiateProject} from 'api/projectsAPI';

import {Box} from 'features/EditorCanvas/Layout/Box';
import {CanvasItem} from 'features/types/canvasItemsSlice';
import {ClientStateType} from 'features/serverSyncSlice';
import {UnreachableCaseError} from 'features/Common/UnreachableCaseError';
import {UserLibraryUserProjectType} from 'features/types/userLibrarySlice';
import {accountDetailsSelector} from 'features/selectors/authSelectors';
import deepEqual from 'fast-deep-equal';
import {defaultCropRectangle} from 'features/EditorCanvas/components/AppCanvas/Canvas/utils/useEditCrop';
import {getAuthToken} from 'services/utils';
import {getCropForFullBox} from 'features/EditorCanvas/components/CanvasItem/InverseCrop';
import {getUserClip} from 'api/clipsAPI';
import {getUserUpload} from 'api/userUploadsAPI';
import {getUserUploadByBucketKey} from 'api/userLibraryAPI';
import {postClientStatus} from 'api/projectClientSyncAPI';
import produce from 'immer';
import {useSelector} from 'react-redux';
import {v4} from 'uuid';

export type CreateProjectOptions = {
  name?: string;
  items?: Record<string, CanvasItem>;
  sceneFrame?: Partial<SceneFrameSliceProps>;
  replaceIds?: boolean;
};

export const replaceItemIds = (items: Record<string, CanvasItem>) => {
  const newItems: Record<string, CanvasItem> = {};

  Object.keys(items).forEach(key => {
    newItems[v4()] = items[key];
  });

  return newItems;
};

const replaceSceneId = (items: Record<string, CanvasItem>, sceneId: string) => {
  return produce(items, draftItems => {
    Object.keys(draftItems).forEach(key => {
      draftItems[key].sceneId = sceneId;
    });
  });
};

export const useCreateProject = () => {
  const {email, user} = useSelector(accountDetailsSelector);

  return async function createProject({
    name,
    items: inputItems,
    replaceIds = true,
    sceneFrame,
  }: CreateProjectOptions) {
    if (!email) {
      throw new Error('Account email is missing');
    }

    const token = await getAuthToken();
    const emptyProject = await getNewProject(token, email, name);

    const sceneId = v4();

    let items = inputItems ?? {};
    if (replaceIds) {
      items = replaceItemIds(items);
    }

    items = replaceSceneId(items, sceneId);

    const clientState: ClientStateType = {
      items,
      canvasScenes: {
        [sceneId]: {...BASE_SCENE_STATE},
      },
      sceneFrame: {
        ...BASE_SCENE_FRAME_STATE,
        ...sceneFrame,
      },
    };

    const ownerId = user?.id ? user.id : 'anonoymous';

    const {project} = await postClientStatus(
      token,
      emptyProject.slug,
      ownerId,
      clientState,
      1,
    );
    return project.slug as string;
  };
};

export type CanvasItemSource = {type: ItemLayerSources; id: string};

// Refactor TODO (jacques): Use in ensureTranscriptId
// Refactor TODO (jacques): RTK query
export const getSourceData = async (token: string, source: CanvasItemSource) => {
  // console.log('source', source);
  if (source.type === ItemLayerSources.Clips) {
    const clip = await getUserClip(token, source.id);
    const userUpload = await getUserUpload(token, clip.user_upload_id);
    return {clip, userUpload};
  } else if (source.type === ItemLayerSources.Uploads) {
    const userUpload = await getUserUploadByBucketKey(token, source.id);
    return {userUpload, clip: undefined};
  } else {
    throw new UnreachableCaseError(source.type);
  }
};

export const itemHasCrop = (item: Pick<CanvasItem, 'crop'>) => {
  return !deepEqual(item.crop, defaultCropRectangle);
};

export const replaceProjectSource = async (
  items: Record<string, CanvasItem>,
  source: CanvasItemSource,
) => {
  const token = await getAuthToken();
  const {userUpload, clip} = await getSourceData(token, source);

  return objMap(items, item =>
    produce(item, draftItem => {
      if (
        draftItem.viewType === ViewTypes.CaptionClip ||
        draftItem.viewType === ViewTypes.VideoClip
      ) {
        draftItem.itemSourceId = source.id;
        draftItem.itemSource = source.type;

        if (clip) {
          draftItem.layerName = clip.clip_name;
          const clipTimes = getClipTimes(clip);
          draftItem.playLengthSeconds = clipTimes.playLengthSeconds;
          draftItem.timeOffsetSeconds = clipTimes.timeOffsetSeconds;
        } else {
          draftItem.layerName = userUpload.file_name;
          draftItem.playLengthSeconds = userUpload.duration ?? 0;
          draftItem.timeOffsetSeconds = 0;
        }
      }

      if (draftItem.viewType === ViewTypes.CaptionClip) {
        draftItem.transcriptId = userUpload.id;
      }

      if (draftItem.viewType === ViewTypes.VideoClip) {
        draftItem.playbackId = userUpload.mux_playback_id;

        if (userUpload.max_width != null && userUpload.max_height != null) {
          const maxDimension = {
            width: userUpload.max_width,
            height: userUpload.max_height,
          };

          if (itemHasCrop(draftItem)) {
            const newFullBox = new Box(maxDimension, draftItem).cover().center();
            const newCrop = getCropForFullBox({
              cropBox: draftItem,
              fullBox: newFullBox,
              type: draftItem.crop.type,
            });
            draftItem.crop = newCrop;
          } else {
            const newBox = new Box(maxDimension, draftItem).fit().center();
            draftItem = {...draftItem, ...newBox.getBox()};
          }
        }
      }
    }),
  );
};

export type CreateProjectFromTemplateOptions = {
  template: Pick<UserLibraryUserProjectType, 'name' | 'slug' | 'metadata'>;
  source?: CanvasItemSource;
  trackEvent?: string;
  redirect?: boolean;
};

export const openProject = (slug: string) => {
  window.location.href = `/${routePaths.create}/${DashParams.CanvasParam}/${slug}`;
  return new Promise(() => {});
};

export const useCreateProjectFromTemplate = () => {
  const {email} = useSelector(accountDetailsSelector);

  const createProject = useCreateProject();

  return async function createProjectFromTemplate({
    template: {name, slug, metadata},
    source,
    trackEvent,
    redirect = true,
  }: CreateProjectFromTemplateOptions) {
    if (!email) {
      throw new Error('Account email is missing');
    }

    const token = await getAuthToken();
    const template = await initiateProject(
      token,
      slug,
      email,
      metadata?.published_version,
    );

    let items = template.canvas_items;
    if (source) {
      items = await replaceProjectSource(items, source);
    }

    const projectSlug = await createProject({
      name,
      items,
      sceneFrame: template.scene_frame,
    });

    if (trackEvent) {
      await asyncTrack(trackEvent);
    }

    if (redirect) {
      await openProject(projectSlug);
    }
  };
};
