import {baseApi} from './baseAPI';

export type RecordingForm = {
  id: string;
  title: string;
  metadata: any;
  shared_unique_key: string;
  created_at: number;
  updated_at: number;
  account_id: string;
  owner_id: string;
  recording_form_prompts: RecordingFormPrompt[];
};

export type RecordingFormPrompt = {
  id: string;
  prompt_type: 'text';
  description: string | null;
  order: number;
  title: string;
  created_at: number;
  updated_at: number;
  user_recording_interaction_form_id: string;
};

export type RecordingFormInput = {
  title?: string;
  description?: string;
  user_recording_form_prompts_attributes?: RecordingFormPromptInput[];
};

type RecordingFormUpdateInput = UpdateId<RecordingFormInput> & {
  user_recording_form_prompts_attributes?: UpsertId<RecordingFormPromptInput>[];
};

type UpdateId<T extends {}> = T & {id: string};
type UpsertId<T extends {}> = T & {id?: string};

// CHANGE THIS
export type RecordingFormPromptInput = {
  prompt_type?: 'text';
  prompt_id?: string;
  title?: string;
  description?: string;
  order?: number;
  // TODO (lenny 8/16/21) Add the component type when ready
};

const recordingFormAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['RecordingForm']})
  .injectEndpoints({
    endpoints: builder => ({
      recordingForms: builder.query<RecordingForm[], void>({
        query: () => ({
          url: `user_recording_interaction_forms`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading recording forms data',
        }),
        providesTags: ['RecordingForm'],
      }),

      recordingForm: builder.query<RecordingForm, {id: string}>({
        query: ({id}) => ({
          url: `user_recording_interaction_forms/${id}`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading recording form data',
          auth: false,
        }),
        providesTags: ['RecordingForm'],
      }),

      createRecordingForm: builder.mutation<RecordingForm, RecordingFormInput>({
        query: recordingInteractionForm => ({
          url: `user_recording_interaction_forms`,
          method: `POST`,
          v2: true,
          errorTitle: `Error creating recording forms page`,
          data: {
            user_recording_interaction_form: recordingInteractionForm,
          },
        }),
        invalidatesTags: ['RecordingForm'],
      }),

      updateRecordingForm: builder.mutation<RecordingForm, RecordingFormUpdateInput>({
        query: ({id, ...patch}) => ({
          url: `user_recording_interaction_forms/${id}`,
          method: `PATCH`,
          v2: true,
          errorTitle: `Error updating recording form`,
          data: {user_recording_interaction_form: patch},
        }),
        invalidatesTags: ['RecordingForm'],
      }),

      deleteRecordingForm: builder.mutation<void, {id: string}>({
        query: ({id}) => ({
          url: `user_recording_interaction_forms/${id}`,
          method: 'DELETE',
          v2: true,
          errorTitle: 'Error deleting recording form',
        }),
        invalidatesTags: ['RecordingForm'],
      }),
    }),
  });

export const {
  useRecordingFormsQuery,
  useRecordingFormQuery,
  useCreateRecordingFormMutation,
  useUpdateRecordingFormMutation,
  useDeleteRecordingFormMutation,
} = recordingFormAPI;
