import {BASE_WEBSITE_URL} from 'constants/environment';

import {Button} from 'ui/Button';

interface Values {
  email?: string;
  name?: string;
  company?: string;
}

export function RecordingSubmissionCompleted() {
  return (
    <div className="h-full">
      <div className="bg-gray-50 sm:rounded-lg">
        <div className="mb-12 px-4 py-5 sm:p-6">
          <div className="mx-auto max-w-xl text-center text-sm text-gray-500">
            <p>{'🙌 All done! Thank you for answering.'}</p>
          </div>
        </div>
      </div>

      <div className="grid w-full px-4 md:grid-cols-2">
        <div className="flex w-full flex-col items-center justify-center pb-8">
          <div className="p-4">
            <div className="flex flex-col items-start justify-center">
              <p className="text-lg font-semibold text-gray-800">
                Let us help you attract happy customers
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Scale personalized videos, form deeper relationships, and supercharge your
                lead conversion process.
              </p>
              <div className="mt-4 flex">
                <a href={`${BASE_WEBSITE_URL}`}>
                  <Button>Try Milk Video for free</Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://assets-milk.s3.amazonaws.com/share/video-maker.jpg"
          alt="Tool preview"
        />
      </div>
    </div>
  );
}
