import {DashParams, routePaths} from 'routes/routesHelper';

import {Link} from 'react-router-dom';
import cn from 'classnames';
import {format} from 'date-fns';
import {useProjectBySlugQuery} from 'services/projectAPI';
import {useState} from 'react';
import {useUserProjectDownloadsQuery} from 'services/userProjectDownloadsAPI';

export const TabPanelVersions = ({
  downloadId,
  projectId,
}: {
  downloadId: string;
  projectId: string;
}) => {
  const [showVersion, setShowVersion] = useState(false);

  const {data} = useProjectBySlugQuery({projectSlug: projectId});

  const {data: allDownloads = []} = useUserProjectDownloadsQuery();

  const downloadsFromSameProject = allDownloads
    .filter(download => {
      return download.project_slug === projectId;
    })
    .sort((a, b) => {
      return Number(a.created_at) - Number(b.created_at);
    });

  return (
    <>
      <h3 className="sr-only">Versions</h3>

      {data?.project && (
        <dl className="mt-4">
          <dt className="font-medium text-gray-900">
            This download was created using{' '}
            <Link
              to={`/${routePaths.create}/${DashParams.CanvasParam}/${data.project.slug}`}
              className="cursor-pointer underline hover:text-indigo-500"
            >
              {data.project.name || 'Untitled project'}
            </Link>
            .
          </dt>
          <dd className="mt-2 text-sm text-gray-500">
            There are {downloadsFromSameProject.length} downloads created from this
            project.{' '}
            {!showVersion && (
              <span
                onClick={() => {
                  setShowVersion(true);
                }}
                className="cursor-pointer underline hover:text-indigo-500"
              >
                Click here to view them all.
              </span>
            )}
          </dd>
        </dl>
      )}

      {showVersion && (
        <ul className="space-y-3 py-4">
          {downloadsFromSameProject.map((downloadVersion, index) => {
            const isLoading = downloadVersion.mux_playback_id === null;
            const downloadPreview = `https://image.mux.com/${downloadVersion.mux_playback_id}/thumbnail.png?width=300&height=300&smart_crop=true`;
            const isCurrent = downloadVersion.id === downloadId;

            return (
              <li key={downloadVersion.id}>
                <Link
                  to={`/${routePaths.dashboard}/${DashParams.DownloadsParam}/${downloadVersion.project_slug}/${downloadVersion.id}`}
                >
                  <div className={cn('relative flex w-full space-x-6')}>
                    {isLoading ? (
                      <div className="h-20 w-20 flex-none animate-pulse rounded-md bg-gray-300 object-cover object-center opacity-90" />
                    ) : (
                      <img
                        src={downloadPreview}
                        className="h-20 w-20 flex-none rounded-md bg-gray-100 object-cover object-center"
                        alt=""
                      />
                    )}
                    <div className="flex-auto space-y-1">
                      <h3 className="text-gray-900">
                        {isCurrent ? (
                          <span className="text-gray-500">
                            Created {format(downloadVersion.created_at, 'Pp')}
                          </span>
                        ) : (
                          <>Created {format(downloadVersion.created_at, 'Pp')}</>
                        )}
                      </h3>
                    </div>
                    <p className="flex-none font-medium text-gray-900">
                      {isCurrent && (
                        <span
                          className={cn(
                            'inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800',
                          )}
                        >
                          Selected
                        </span>
                      )}
                    </p>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
