import classNames from 'classnames';
import {useWatchElementSize} from 'features/Common/useElementSize';
import {useUploadLogoUppy} from 'features/Dashboard/DashboardPage/ContentBrandKit/Logos';
import {UploadButton} from 'features/Dashboard/DashboardPage/ContentBrandKit/UploadButton';
import {fitWithin} from 'features/EditorCanvas/Layout/utils';
import {
  ImageCard,
  ImageCardButton,
  ImageCardGhost,
} from 'features/EditorCanvas/Sidebar/views/BrandKit';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {User} from 'react-feather';
import {useFetchAccountBrandQuery} from 'services/accountBrandAPI';
import {TextInput} from 'ui/TextInput';
import {Size, Style} from '../Constants';
import {useDesign} from '../State';

export const DesignStepOptions = ({upload}: {upload: UserUploadsType}) => {
  const {data: accountBrand} = useFetchAccountBrandQuery();
  const brandKitLogos = accountBrand?.logos;

  const {logo, title, secondaryTitle, updateDesign} = useDesign();

  const uppy = useUploadLogoUppy(upload => {
    updateDesign({property: 'logo', value: upload.upload_url});
  });

  return (
    <div className="space-y-6">
      <div>
        <div className="mb-2 font-semibold">Title</div>
        <TextInput
          name="title"
          value={title ?? ''}
          onChange={title => updateDesign({property: 'title', value: title})}
        />
      </div>
      <div>
        <div className="mb-2 font-semibold">Secondary Title</div>
        <TextInput
          name="secondaryTitle"
          value={secondaryTitle ?? ''}
          onChange={secondaryTitle =>
            updateDesign({property: 'secondaryTitle', value: secondaryTitle})
          }
        />
      </div>
      <div>
        <div className="mb-2 font-semibold">Logo</div>
        <div className="grid grid-cols-2 gap-2">
          <UploadButton uppy={uppy}>
            {fileInput => (
              <ImageCardButton label="Upload a logo">{fileInput}</ImageCardButton>
            )}
          </UploadButton>
          {brandKitLogos ? (
            <>
              {brandKitLogos.map(brandKitLogo => (
                <ImageCard
                  key={brandKitLogo.id}
                  url={brandKitLogo.upload_url}
                  selected={logo === brandKitLogo.upload_url}
                  onClick={() =>
                    updateDesign({property: 'logo', value: brandKitLogo.upload_url})
                  }
                />
              ))}
              {brandKitLogos.length > 0 && (
                <ImageCardButton
                  label="No logo"
                  selected={!logo}
                  onClick={() => updateDesign({property: 'logo', value: undefined})}
                />
              )}
            </>
          ) : (
            <>
              <ImageCardGhost />
            </>
          )}
        </div>
      </div>
      {/* <div>
        <div className="font-semibold mb-2">Clip Size</div>
        <div className="flex border rounded-md p-2 h-20 space-x-2">
          {Object.values(videoStyles).map(videoStyleOption => (
            <VideoStyleOption
              key={videoStyleOption.id}
              videoStyle={videoStyleOption}
              active={style === videoStyleOption.id}
              onClick={() => updateDesign('style', videoStyleOption.id)}
            />
          ))}
        </div>
      </div> */}
      {/* <div className="flex items-center">
        <Button
          variant="primary"
          onClick={async () => {
            const projectIds = await createProjects(design, captions, [clip.id]);
            if (!projectIds) return;

            const projectId = projectIds[0];
            const downloadId = await downloadProject(projectId);
            setDownloads([{projectId, downloadId}]);
            setStep('download');
          }}
          leftIcon={DownloadCloud}
        >
          Download
        </Button>
        <Button
          variant="secondary"
          onClick={async () => {
            const projectIds = await createProjects(design, captions, [clip.id]);
            if (!projectIds) return;

            router.push(`/canvas/${projectIds[0]}`);
          }}
        >
          Customize in the designer
        </Button>
      </div> */}
    </div>
  );
};

const VideoStyleOption = ({
  videoStyle,
  active,
  onClick,
}: {
  videoStyle: Style;
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      className={classNames(
        'group h-full w-full flex-1 cursor-pointer overflow-hidden',
        videoStyle.id !== 'background' && 'rounded-md border p-2',
      )}
      onClick={onClick}
    >
      <div
        className={classNames(
          'mx-auto flex h-full w-full items-center justify-center rounded-md border border-transparent bg-gray-200 transition group-hover:border-indigo-300',
          videoStyle.id === 'contain' && 'w-10/12',
          active && 'border-indigo-300 bg-indigo-100',
        )}
      >
        <User
          className={classNames(
            'transform text-gray-400 group-hover:text-indigo-400',
            videoStyle.id !== 'contain' && 'scale-125',
            active && 'text-indigo-400',
          )}
        />
      </div>
    </button>
  );
};

export const SizeOption = ({
  size,
  active,
  onClick,
}: {
  size: Size;
  active: boolean;
  onClick: () => void;
}) => {
  const [iconContainerRef, iconContainerSize] = useWatchElementSize();
  const iconSize = fitWithin({item: size.dimensions, container: iconContainerSize});

  return (
    <button
      key={size.id}
      className={classNames(
        'group flex h-full flex-1 cursor-pointer flex-col items-center space-y-1 rounded-md p-2 font-medium transition',
        active && 'border-indigo-500',
      )}
      onClick={onClick}
    >
      <div
        className="flex h-full w-full flex-1 items-center justify-center"
        ref={iconContainerRef}
      >
        <div
          className={classNames(
            'rounded-md border bg-gray-200 transition group-hover:border-indigo-300',
            active && 'border-indigo-300 !bg-indigo-100',
          )}
          style={{...iconSize.dimension}}
        />
      </div>
      <div className="-mb-1 text-sm">{size.label}</div>
    </button>
  );
};
