import {SearchPages, useSearchResults} from './useSearchResults';
import {StringParam, encodeQueryParams, useQueryParam} from 'use-query-params';
import {
  createAccountSearchQuery,
  fetchAccountSearch,
  fetchUserClips,
} from 'features/userLibrarySlice';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';

import {DashboardContentSkeleton} from 'features/Dashboard/common/DashboardContentSkeleton';
import {DashboardContentWrap} from 'features/Dashboard/common/DashboardContentWrap';
import {DashboardHeadWrap} from 'features/Dashboard/common/DashboardHead';
import {Helmet} from 'react-helmet';
import {RecentSearch} from './RecentSearch';
import {SearchFormField} from './SearchFormField';
import {SearchResults} from './SearchResults';
import {stringify} from 'query-string';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {userLibrarySelector} from 'features/selectors/userLibrarySelectors';

export const SearchPage = ({isShowing}: {isShowing: boolean}) => {
  const [showFilter, setShowFilter] = useState(false);
  const {
    state: {activePage, searchQuery},
    dispatch: searchResultsDispatch,
  } = useSearchResults();
  const [urlQuery, setUrlQuery] = useQueryParam('q', StringParam);
  const searchRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const {getAccessTokenSilently} = useAuthAlt();
  const {userClips} = useSelector(userLibrarySelector);

  const setupRecent = () => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();

        dispatch(fetchAccountSearch(token));

        if (!userClips.length) {
          // dispatch(fetchUserUploads(token, true));
          dispatch(fetchUserClips(token, true));
        } else {
          // dispatch(fetchUserUploads(token, true));
          dispatch(fetchUserClips(token, false));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  useEffect(() => {
    window.analytics.track('Starting fetch of user upload and clips from search page', {
      onload_clip_count: userClips.length,
    });
    setupRecent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (urlQuery !== searchQuery && urlQuery) {
      searchResultsDispatch({
        type: 'updateActivePage',
        payload: {
          newPage: SearchPages.SearchResults,
        },
      });
      searchResultsDispatch({
        type: 'updateSearchQuery',
        payload: {
          query: urlQuery,
        },
      });
    } else {
      searchResultsDispatch({
        type: 'updateSearchQuery',
        payload: {
          query: '',
        },
      });
      searchResultsDispatch({
        type: 'updateActivePage',
        payload: {
          newPage: SearchPages.Recent,
        },
      });
    }
  }, [urlQuery]);

  useEffect(() => {
    if (searchRef.current !== null) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  useEffect(() => {
    window.analytics.track('Visited search page');
  }, []);

  return (
    <DashboardContentSkeleton isShowing={isShowing} isLoading={false}>
      {searchQuery && (
        <Helmet>
          <title>Search: {searchQuery} - Milk Video</title>
        </Helmet>
      )}
      <DashboardHeadWrap className="sticky top-0 z-30 bg-white">
        <SearchFormField searchRef={searchRef} />
        <button
          onClick={() => {
            setShowFilter(!showFilter);
          }}
          type="button"
          className="inline-flex items-center whitespace-nowrap rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {showFilter ? 'Hide filters' : 'Show filters'}
        </button>
      </DashboardHeadWrap>
      <DashboardContentWrap className="mt-6">
        {activePage === SearchPages.SearchResults ? <SearchResults /> : <RecentSearch />}
      </DashboardContentWrap>
    </DashboardContentSkeleton>
  );
};
