import {ClockIcon} from '@heroicons/react/outline';
import {UserProjectDownloadAsset} from '../SocialCardPreview';
import logo from '../logo-square.png';

export default function UserProjectDownloadPreview({
  muxPlaybackId,
  accountName,
  assetType,
  asset,
  humanizedDuration,
  textSnippet,
  userAvatar,
  userName,
  assetCreatedAt,
}: {
  muxPlaybackId: string;
  accountName: string;
  assetType: string;
  asset: UserProjectDownloadAsset;
  humanizedDuration: string;
  textSnippet: string;
  userAvatar: string;
  userName: string;
  assetCreatedAt: string;
}) {
  const imageHeight = 420;
  const imageWidth = 1000;
  const thumbnailUrl = `https://image.mux.com/${muxPlaybackId}/thumbnail.png?width=${imageWidth}&height=${imageHeight}&fit_mode=pad`;
  return (
    <>
      <div className="flex flex-col justify-between space-y-6">
        <div className="flex flex-col items-center space-y-3 bg-white">
          <div className="flex w-full justify-between px-20">
            <h3 className="flex flex-grow text-2xl font-bold">{accountName}</h3>
          </div>

          <div
            className="flex h-full w-full items-center justify-center bg-black py-4 align-middle"
            style={{
              backgroundImage: 'url(https://i.imgur.com/AvI5DKE.png)',
              backgroundColor: '#fff',
              backgroundSize: '830px',
              backgroundPositionY: 'center',
            }}
          >
            <div
              className="flex h-full w-full items-center justify-center align-middle"
              style={{
                width: `${imageWidth}px`,
                height: `${imageHeight}px`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundImage: `url(${thumbnailUrl})`,
              }}
            ></div>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-row items-center justify-between bg-white px-20 pt-2 pb-4 align-middle text-white">
        <div className="flex items-center justify-center">
          <div className="mr-2 h-16 w-16">
            <img src={userAvatar} alt="logo" className="overflow-hidden rounded-full" />
          </div>
          <h1 className="items-stacrt ml-3 flex flex-col justify-start text-2xl text-gray-600">
            <span className="whitespace-nowrap">Created by {userName}</span>

            <span className="text-lg opacity-50">{assetCreatedAt}</span>
          </h1>
        </div>
        <div className="mr-2 h-12 w-12">
          <img src={logo} alt="logo" />
        </div>
      </div>
    </>
  );
}
