import {DashParams, routePaths} from 'routes/routesHelper';
import {Icon, Settings, UploadCloud} from 'react-feather';

import {Button} from 'ui/Button';
import {FC} from 'react';
import {Link} from 'react-router-dom';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {addImageCanvasItem} from 'features/canvasItemsSlice';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import {useFetchAccountBrandQuery} from 'services/accountBrandAPI';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useRecentUsage} from 'services/recentUsageAPI';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';

const Container: FC = ({children}) => {
  return (
    <div className="p-5">
      <div className="mb-1 text-lg font-semibold text-gray-800">Brand Logos</div>
      <div className="space-y-3">{children}</div>
    </div>
  );
};

const logoCardContainer =
  'p-3 ring-0 bg-gray-50 rounded-md hover:bg-indigo-50 focus:bg-indigo-50 cursor-pointer transition flex flex-col justify-center items-stretch';

const logoCardInner = ({square}: {square?: boolean}) =>
  classNames(square ? 'aspect-w-1 aspect-h-1' : 'h-10');

// UI TODO (jacques): Move to UI lib as asset card
export const ImageCard = ({
  url,
  onClick,
  selected,
  square,
}: {
  url: string;
  onClick?: () => void;
  selected?: boolean;
  square?: boolean;
}) => {
  return (
    <div
      className={classNames(logoCardContainer, selected && 'ring-2 ring-indigo-500')}
      role={onClick && 'button'}
      onClick={onClick}
    >
      <div
        className={classNames(
          logoCardInner({square}),
          'bg-contain bg-center bg-no-repeat',
        )}
        style={{backgroundImage: `url('${url}')`}}
      />
    </div>
  );
};

export const ImageCardButton: FC<{
  label: string;
  icon?: Icon;
  onClick?: () => void;
  square?: boolean;
  selected?: boolean;
}> = ({label, icon: Icon, onClick, square, children, selected}) => {
  return (
    <div
      className={classNames(
        logoCardContainer,
        'text-indigo-500',
        selected && 'ring-2 ring-indigo-500',
      )}
      role="button"
      onClick={onClick}
    >
      <div className={classNames(logoCardInner({square}), 'relative')}>
        <div className="absolute inset-0 flex flex-col items-center justify-center space-y-2">
          {Icon && <Icon size={24} />}
          <div className="text-center text-sm font-medium">{label}</div>
        </div>
        {children}
      </div>
    </div>
  );
};

export const ImageCardGhost = ({square}: {square?: boolean}) => {
  return (
    <div className={classNames(logoCardContainer, 'pointer-events-none')}>
      <div className={classNames(logoCardInner({square}), 'rounded-md bg-gray-100')} />
    </div>
  );
};

export function BrandKit() {
  const {data, isLoading} = useFetchAccountBrandQuery();

  const dispatch = useDispatch();
  const projectId = useProjectId();
  const {activeScene} = useScenes();
  const {recentUsage} = useRecentUsage();

  const insertLogo = (logo: UserUploadsType) => {
    if (!activeScene) return;

    dispatch(
      addImageCanvasItem({
        projectId,
        url: logo.upload_url,
        fileName: logo.file_name,
        sceneId: activeScene.id,
        style: recentUsage,
      }),
    );
  };

  if (isLoading || !data) {
    return (
      <Container>
        <div className="h-5 animate-pulse bg-gray-50" />
        <ImageCardGhost />
        <ImageCardGhost />
      </Container>
    );
  }

  if (data.logos.length === 0) {
    return (
      <Container>
        <div className="text-sm text-gray-700">
          Upload your custom logos and easily access them here to use in your videos.
        </div>
        <div>
          <Link to={`/${routePaths.dashboard}/${DashParams.BrandKitParam}`}>
            <Button variant="primary" leftIcon={UploadCloud} fullWidth size="small">
              Upload a logo
            </Button>
          </Link>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div className="text-sm text-gray-700">Click a logo below to insert it</div>
      {data.logos.map(logo => (
        <ImageCard key={logo.id} url={logo.upload_url} onClick={() => insertLogo(logo)} />
      ))}
      <div>
        <Link to={`/${routePaths.dashboard}/${DashParams.BrandKitParam}`}>
          <Button variant="inline" fullWidth size="small" leftIcon={Settings}>
            Manage Logos
          </Button>
        </Link>
      </div>
    </Container>
  );
}
