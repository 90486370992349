import {Fragment, useEffect, useState} from 'react';
import {
  SDKProductTypes,
  Stytch,
  useStytch,
  useStytchSession,
  useStytchUser,
} from '@stytch/stytch-react';

import MilkLogo from 'features/Dashboard/shared/MilkLogo';
import {STYTCH_PUBLIC_TOKEN} from 'features/Auth/useAuthAlt';
import {stytchProps} from 'features/Auth/constants';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';

export function UserSignInPage() {
  const stytchUser = useStytchUser();
  const stytchClient = useStytch();
  const session = useStytchSession();
  const isAuthGoogleActive = useFeatureFlag('oauth-google');

  // console.log('stytchUser', stytchUser);
  // console.log('session', session);
  // console.log('stytchClient', stytchClient);

  useEffect(() => {
    // if (session) {
    //   window.location.href = 'https://example.com/profile';
    //   return;
    // }
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      stytchClient.oauth.authenticate(token, {
        session_duration_minutes: 43800,
      });
    }
  }, [stytchClient, session]);

  if (session) {
    return <div>Loading</div>;
  }

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="flex w-full max-w-md flex-col items-center space-y-8">
          <div>
            <div className="w-full items-start">
              <MilkLogo width={80} height={80} />
            </div>
          </div>

          <Stytch
            style={{
              primaryColor: '#4f46e5',
              hideHeaderText: true,
              // fontFamily: string;
              // primaryTextColor?: string;
              // secondaryTextColor?: string;
              // lightGrey?: string;
              // darkGrey?: string;
              // width?: string;
            }}
            publicToken={STYTCH_PUBLIC_TOKEN}
            loginOrSignupView={{
              ...stytchProps.loginOrSignupView,
              products: [SDKProductTypes.oauth, SDKProductTypes.emailMagicLinks],
            }}
          />
        </div>
      </div>
    </>
  );
}
