import {FC} from 'react';
import {ShapeElements} from './Elements/ShapeElements';
import {SmartElements} from './Elements/SmartElements';
import classNames from 'classnames';
import {useFetchAccountBrandQuery} from 'services/accountBrandAPI';

const Container: FC = ({children}) => {
  return (
    <div className="p-5">
      <div className="space-y-3">{children}</div>
    </div>
  );
};

const logoCardContainer =
  'p-3 ring-0 bg-gray-50 rounded-md hover:bg-indigo-50 focus:bg-indigo-50 cursor-pointer transition flex flex-col justify-center items-stretch';

const logoCardInner = ({square}: {square?: boolean}) =>
  classNames(square ? 'aspect-w-1 aspect-h-1' : 'h-10');

export const ImageCardGhost = ({square}: {square?: boolean}) => {
  return (
    <div className={logoCardContainer}>
      <div className={classNames(logoCardInner({square}), 'rounded-md bg-gray-100')} />
    </div>
  );
};

export function StaticElements() {
  const {data, isLoading} = useFetchAccountBrandQuery();

  if (isLoading || !data) {
    return (
      <Container>
        <div className="h-5 animate-pulse bg-gray-50" />
        <ImageCardGhost />
        <ImageCardGhost />
      </Container>
    );
  }

  return (
    <Container>
      <div className="mb-4 text-xs font-bold text-gray-700">Click element to add</div>
      <ShapeElements />
      <SmartElements />
    </Container>
  );
}
