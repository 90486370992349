import { FC, ReactNode, useEffect } from 'react';

import { BASE_URL } from 'constants/environment';
import ahoyBase from 'vendor/ahoy/ahoy.esm';
import { useLocation } from 'react-router-dom';

export const ahoy = ahoyBase;

export const TrackingVisitProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation()

  useEffect(() => {
    window.ahoy = ahoy;

    ahoy.configure({
      visitsUrl: `${BASE_URL}/ahoy/visits`,
      eventsUrl: `${BASE_URL}/api/v2/ahoy/events`,
      urlPrefix: '',
      cookieDomain: BASE_URL,
      withCredentials: true,
      cookies: false
    });

    ahoy.trackView()
  }, [ahoy, location.pathname])

  return <>{children}</>
}