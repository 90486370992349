import { DashParams, routePaths } from 'routes/routesHelper';
import { Field, Form } from 'react-final-form';
import { FormTextInput, InputError } from 'ui/TextInput';
import { Fragment, useRef, useState } from 'react';
import { LightBulbIcon, UserGroupIcon, UserIcon } from '@heroicons/react/outline';

import { Button } from 'ui/Button';
import { Modal } from 'ui/Modal';
import { Package } from 'react-feather';
import { RadioGroup } from '@headlessui/react';
import { RecordingFormSessionContext } from './RecordingFormSessionContext';
import cn from 'classnames';
import { format } from 'date-fns';
import { useCreateRecordingFormMutation } from 'services/recordingFormsAPI';
import { useNavigate } from 'react-router-dom';

export enum ProjectOrientation {
  Landscape = 'Landscape',
  Square = 'Square',
  Portrait = 'Portrait',
}


const videoPreviewPlaybackId = 'iCUcu7b8QuOeIKoGiTleYdaS00lF401ViRs202FDfRKWdQ';
const supportSizeContentUrl =
  'https://support.milkvideo.com/en/articles/5495107-what-orientation-should-i-pick';
const exampleVideoUrl =
  'https://app.milkvideo.com/share/f/1e0493b1-3a0e-48aa-925a-7b567a7e781b';

const formTemplateOptions = [
  { label: 'Employee feedback', value: 'employee-feedback', icon: () => <UserIcon width={24} height={24} /> },
  { label: 'Customer testimonial', value: 'customer-testimonial', icon: () => <UserGroupIcon width={24} height={24} /> },
  { label: 'Thought leadership', value: 'thought-leadership', icon: () => <LightBulbIcon width={24} height={24} /> },
]

const formTemplates = {
  'employee-feedback': {
    title: 'Employee feedback template form',
    description: 'Please share your thoughts and feedback about your team.',
  },
  'customer-testimonial': {
    title: 'Customer testimonial template form',
    description: 'Please share a review of your thoughts about our product.',
  },
  'thought-leadership': {
    title: 'Thought leadership template form',
    description: 'Share ideas that demonstrate your expertise in a particular field, area or topic.',
  },
  'blank': {
    title: 'Blank template form',
    description: 'Replace this with a question prompt',
  },
}

export const CreateRecordingModal = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
  const [createRecordingFormLink] = useCreateRecordingFormMutation();

  const { setIsCreateRecordingModalOpen } = RecordingFormSessionContext.useContainer();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  type submitFormData = {
    formName: string;
    formTemplate: string;
  };

  const onSubmit = (data: submitFormData) => {
    console.log('data', data);
    (async () => {
      setIsSubmitting(true);
      try {
        window.analytics.track('Pg-Idx -- LdPgAdm -- Act-Crt -- Recording Form Page');
        const response = await createRecordingFormLink({
          title: data.formName,
          user_recording_form_prompts_attributes: [{
            title: formTemplates[data.formTemplate].title,
            description: formTemplates[data.formTemplate].description,
            prompt_type: 'text',
            order: 0
          }],
        });
        // console.log('response', response);
        if ('error' in response) return;
        navigate(
          `/${routePaths.dashboard}/${DashParams.RecordingFormParams}/${response.data.id}/build`,
        );
        setIsCreateRecordingModalOpen(false);
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
      }
    })();
  };

  return (
    <Modal
      size="xl"
      title={''}
      open={isOpen}
      onClose={closeModal}
      userCanClose={true}
    >
      <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 md:grid-cols-12 lg:gap-x-8">
        <div className="md:col-span-4 hidden md:flex lg:col-span-5 h-full flex-col items-center justify-center">
          <div className="flex ">
            <img src="https://assets-milk.s3.amazonaws.com/share/video-testimonial.jpg" alt="testimonial video" />
          </div>
          <p className="absolute top-4 left-4 text-center sm:static sm:mt-6">
            <a
              href={exampleVideoUrl}
              target="_blank"
              className="font-medium text-indigo-600 hover:text-indigo-500"
              rel="noreferrer"
            >
              View example form
            </a>
          </p>
        </div>
        <div className="md:col-span-8 lg:col-span-7">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:pr-12">
            New recording form
          </h2>
          <div className="mt-2 flex items-center">
            <p className="max-w-sm text-sm text-gray-500">
              Pick a recording form name a starter template. You can change these
              later.
            </p>
          </div>
          <Form
            onSubmit={async (values: submitFormData) => {
              console.log('values', values);
              onSubmit(values);
            }}
          >
            {({ handleSubmit, submitting, submitError, modifiedSinceLastSubmit, values, valid, form }) => {
              console.log('valid', valid);
              console.log('values', values);
              return (
                <form onSubmit={handleSubmit} className="space-y-4">
                  <section aria-labelledby="formName" className="mt-4">
                    <FormTextInput
                      label='Name'
                      type="text"
                      placeholder={`${format(Date.now(), 'MMMM Y')} recording form`}
                      defaultValue={`${format(Date.now(), 'MMMM Y')} recording form`}
                      name="formName"
                      autoFocus
                      required
                    />
                  </section>
                  <section className="mt-4">
                    <Field name="formTemplate" component="input">
                      {({ input, meta }) => (
                        <RadioGroup value={input.value} onChange={input.onChange}>
                          <RadioGroup.Label className="block text-sm font-medium text-gray-700">
                            Form template
                          </RadioGroup.Label>
                          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-4">

                            {formTemplateOptions.map(option => (
                              <RadioGroup.Option key={option.value} value={option.value} className={({ active }) =>
                                cn(
                                  active ? 'ring-2 ring-indigo-400' : '',
                                  'relative cursor-pointer rounded-md focus:outline-none',
                                )
                              }
                              >
                                {({ active, checked }) => (
                                  <div
                                    className={cn({
                                      'text-gray-900': !checked,
                                      'text-indigo-600': checked,
                                    },
                                      checked
                                        ? 'bg-indigo-100 text-white'
                                        : 'bg-white opacity-75',
                                      'inline-flex w-full flex-col items-center justify-center rounded-md  border border-transparent p-2 hover:bg-indigo-50 hover:opacity-100',
                                    )}
                                  >
                                    {option.icon && (
                                      <RadioGroup.Description
                                        as="div"
                                        className="mb-1"
                                      >
                                        {option.icon}
                                      </RadioGroup.Description>
                                    )}
                                    <RadioGroup.Label
                                      as="p"
                                      className={cn('text-sm font-medium text-center select-none')}
                                    >
                                      {option.label}
                                    </RadioGroup.Label>
                                  </div>
                                )}
                              </RadioGroup.Option>
                            ))}
                            <RadioGroup.Option value={'blank'} className={({ active }) =>
                              cn(
                                active ? 'ring-2 ring-indigo-400' : '',
                                'relative cursor-pointer rounded-md focus:outline-none',
                              )
                            }
                            >
                              {({ active, checked }) => (
                                <div
                                  className={cn({
                                    'text-gray-900': !checked,
                                    'text-indigo-600': checked,
                                  },
                                    checked
                                      ? 'bg-indigo-100 text-white'
                                      : 'bg-white opacity-75',
                                    'inline-flex w-full flex-col items-center justify-center rounded-md  border border-transparent p-2 hover:bg-indigo-50 hover:opacity-100',
                                  )}
                                >

                                  <RadioGroup.Description
                                    as="div"
                                    className="mb-1"
                                  >
                                    <Package />
                                  </RadioGroup.Description>

                                  <RadioGroup.Label
                                    as="p"
                                    className={cn('text-sm font-medium text-center select-none')}
                                  >
                                    Start blank
                                  </RadioGroup.Label>
                                </div>
                              )}
                            </RadioGroup.Option>
                          </div>
                        </RadioGroup>
                      )}
                    </Field>
                  </section>

                  {submitError && !submitting && !modifiedSinceLastSubmit && (
                    <InputError>{submitError}</InputError>
                  )}
                  <Button variant="secondary" disabled={!valid}
                    type="submit" loading={submitting} fullWidth>
                    Create new form
                  </Button>
                </form>
              )
            }}
          </Form>
        </div>
      </div>
    </Modal>
  );
};
