import {FocusedFlowAudioWaveformOptions} from 'features/FocusedFlow/LayoutCalculator';
import {DimensionType} from 'features/types/canvasItemsSlice';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {CSSProperties, useEffect, useState} from 'react';
import {AudioAnalysisParams, useAudioAnalysisQuery} from 'services/userUploadAPI';

import {AudioRenderingContainer} from './AudioWaveforms/AudioRenderingContainer';
import {AudioWaveformOptions} from './AudioWaveforms/constants';
import {AudioRenderingPreview} from './AudioWaveforms/Visual/AudioRenderingPreview';

/**
 * TODO(Lenny): Refactor the redux/clip fallback using frameContext
 *
 * If the frameContext exists, directly request clip.
 */

// TODO (jacques): We can use useTranscriptId here.
// We should create a single way to fetch the upload/clip of an item.

export function DynamicAudioWaveformAmplitude({
  params,
  targetUserUpload,
  style,
  options,
  dimensions,
}: {
  params: AudioAnalysisParams;
  targetUserUpload: UserUploadsType | undefined;
  dimensions: DimensionType;
  style: CSSProperties;
  options: AudioWaveformOptions | FocusedFlowAudioWaveformOptions;
}) {
  const isUploadAudioAnalysisReady = targetUserUpload?.has_audio_analysis;
  const [shouldPoll, setShouldPoll] = useState(true);

  const {data} = useAudioAnalysisQuery(params!, {
    skip: !params,
    pollingInterval: shouldPoll && isUploadAudioAnalysisReady ? 3000 : undefined,
  });

  useEffect(() => {
    setShouldPoll(!data);
  }, [data]);

  if (!data) {
    return (
      <AudioRenderingPreview
        dimensions={dimensions}
        waveformStyle={options.waveformStyle}
      />
    );
  }

  return (
    <div style={{...style, ...dimensions}} className="text-2xl text-gray-800">
      <AudioRenderingContainer
        waveformStyle={options.waveformStyle}
        dimensions={dimensions}
        itemStyle={style}
        audioAnalysisFft={data.fft_analysis}
        fftProperties={data.properties}
      />
    </div>
  );
}
