import {BASE_SCENE_FRAME_STATE, DEFAULT_DIMENSION} from './sceneFrameSlice';
import {BASE_SCENE_STATE, projectStartupPreparation} from './canvasItemsSlice';
import {CanvasScene, PayloadWithId} from './types/canvasItemsSlice';
import {ClientStateType, saveClientStateOfCanvasSuccessful} from './serverSyncSlice';
import {convertToMs, getItemByType, getItemsForLayout} from './EditorCanvas/utils';

import {AppThunk} from 'configureStore';
import {RecentUsage} from 'services/recentUsageAPI';
import {TemplateExportOptionsType} from './Dashboard/DashboardUploadDetails/PlayableMedia/TemplateSelectionModal/TemplateSelectionPopup';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import {UploadOriginUI} from 'services/userUploadAPI';
import {UserProjectDownloadAsset} from 'pages/PreviewRender/components/SocialCardPreview';
import {UserUploadsType} from './types/userLibrarySlice';
import {ViewTypes} from './EditorCanvas/constants/ViewConstants';
import {createSlice} from '@reduxjs/toolkit';
import {getNewProject} from 'api/projectsAPI';
import {layoutAndReturnItems} from './EditorCanvas/Layout/utils';
import {objForEach} from './Common/utils';
import {postClientStatus} from 'api/projectClientSyncAPI';
import {postProjectExportRequest} from 'api/userProjectDownloadAPI';
import produce from 'immer';
import {v4} from 'uuid';

export type DownloadProjectWithLoading = {
  is_loading: boolean;
  created_at?: number;
  duration_ms?: number;
  id?: string;
  mux_playback_id?: string | null;
  mux_status?: string | null;
  project_slug?: string;
  status?: string;
  name?: string;
  updated_at?: number;
  url?: string;
  user_project_sync_version_id?: string;
  isLoading: boolean;
};

const INITIAL_PROJECT_DOWNLOAD = {
  isLoading: true,
} as DownloadProjectWithLoading;

export interface DownloadProjectsProps {
  isExportingProject: boolean;
  isLoading: boolean;
  downloads: Record<string, DownloadProjectWithLoading>;
}

export const INITIAL_DOWNLOADS_PROJECT = {
  isExportingProject: false,
  downloads: {},
  isLoading: false,
} as DownloadProjectsProps;

export interface UserProjectDownloadProps {
  projects: Record<string, DownloadProjectsProps>;
  allDownloads: UserProjectDownloadAsset[];
  isLoadingAllDownloads: boolean;
}

const initialState = {
  projects: {},
  allDownloads: [],
  isLoadingAllDownloads: false,
} as UserProjectDownloadProps;

const userProjectDownloadSlice = createSlice({
  name: 'userProjectDownload',
  initialState,
  reducers: {
    exportProjectStarted(state, action) {
      const {projectId} = action.payload;
      if (!state.projects[projectId]) {
        state.projects[projectId] = {
          ...INITIAL_DOWNLOADS_PROJECT,
        };
      }
      state.projects[projectId].isExportingProject = true;
    },
    exportProjectSuccessful(state, action) {
      const {projectId, userProjectDownload, durationMs, dimensions} = action.payload;
      state.projects[projectId].downloads[userProjectDownload.id] = {
        ...userProjectDownload,
        durationMs,
        dimensions,
      };
      state.projects[projectId].isExportingProject = false;
    },
    exportProjectFailed(state, action) {
      const {projectId} = action.payload;
      state.projects[projectId].isExportingProject = false;
    },

    submitNewDownloadNameStarted(state, action) {
      const {projectId, downloadId, newDownloadName} = action.payload;
      let tempIndex;

      state.allDownloads.find((download, downloadIndex) => {
        if (download.id === downloadId) {
          tempIndex = downloadIndex;
        }
      });
      if (tempIndex !== undefined) {
        state.allDownloads[tempIndex].name = newDownloadName;
      }
    },
    submitNewDownloadNameSuccessful(
      state,
      action: PayloadWithId<{
        projectDownloadId: string;
        userProjectDownload: UserProjectDownloadAsset;
      }>,
    ) {
      const {projectId, projectDownloadId, userProjectDownload} = action.payload;
      if (!state.projects[projectId]) {
        state.projects[projectId] = {
          ...INITIAL_DOWNLOADS_PROJECT,
          downloads: {},
        };
      }
      if (!state.projects[projectId].downloads[projectDownloadId]) {
        state.projects[projectId].downloads[projectDownloadId] = {
          ...INITIAL_PROJECT_DOWNLOAD,
        };
      }
      state.projects[projectId].downloads[projectDownloadId] = {
        ...state.projects[projectId].downloads[projectDownloadId],
        ...userProjectDownload,
        // durationMs,
        // dimensions,
      };
      state.projects[projectId].downloads[projectDownloadId].isLoading = false;
    },
    submitNewDownloadNameFailed(state, action) {
      const {err, projectId} = action.payload;
      console.error('err', err);
    },
  },
});

export const {
  exportProjectFailed,
  exportProjectStarted,
  exportProjectSuccessful,

  submitNewDownloadNameStarted,
  submitNewDownloadNameSuccessful,
  submitNewDownloadNameFailed,
} = userProjectDownloadSlice.actions;

interface RequestExportWithOptionsForClipProps {
  token: string;
  email: string;
  activeClip: UploadMediaClipType;
  exportOptions: TemplateExportOptionsType;
  style: RecentUsage;
  callback: ErrorCallback<{projectId: string}>;
}

export const requestExportWithOptionsForClip =
  ({
    token,
    email,
    activeClip,
    exportOptions,
    style,
    callback,
  }: RequestExportWithOptionsForClipProps): AppThunk =>
  async (dispatch, getState) => {
    const state = getState() as any;
    const {userUploads} = state.userLibrary;
    const userUpload = userUploads.find(
      (userUpload: UserUploadsType) => userUpload.id === activeClip.user_upload_id,
    );

    try {
      if (!activeClip) throw new Error('No clip');

      const projectData = await getNewProject(token, email);
      const projectId = projectData.slug;
      const assignedSceneId = v4();

      await new Promise<void>((resolve, reject) => {
        dispatch(
          projectStartupPreparation({
            oldProjectId: projectId,
            token,
            callback: error => {
              if (error) reject(error);
              else resolve();
            },
            sceneId: assignedSceneId,
          }),
        );
      });

      dispatch(
        prepareTranscriptInitiatedProject({
          token,
          email,
          assignedSceneId,
          activeClip,
          exportOptions,
          projectId,
          userUpload,
          style,
          callback,
        }),
      );
    } catch (error) {
      // @ts-ignore
      callback({error});
      console.error('error', error);
    }
  };

type ErrorCallback<T extends {}> = (result: {error: Error} | T) => void;

/**
 * Creates the export modal video canvas based on a template structure.
 * Used in the video clip wizard.
 *
 * This is a horrible function.
 * @param
 * @returns
 */
export const prepareTranscriptInitiatedProject =
  ({
    assignedSceneId,
    token,
    email,
    activeClip,
    exportOptions,
    projectId,
    userUpload,
    style,
    callback,
  }: {
    assignedSceneId: string;
    token: string;
    email: string;
    activeClip: UploadMediaClipType;
    userUpload: UserUploadsType;
    exportOptions: TemplateExportOptionsType;
    projectId: string;
    style: RecentUsage;
    callback?: ErrorCallback<{projectId: string}>;
  }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    let items = getItemsForLayout({
      activeClip,
      userUpload,
      sceneId: assignedSceneId,
    });

    items = produce(items, draftItems => {
      objForEach(draftItems, item => {
        if (item.viewType === ViewTypes.CaptionClip) {
          item.style.color = style.captions_active_color;
          item.style.colorCurrent = style.captions_active_color;
          item.style.colorInactive = style.captions_inactive_color;
          item.style.fontFamily = style.captions_font;
        }
      });
    });

    const canvasDimensions = DEFAULT_DIMENSION[exportOptions.orientation];

    const positionedItems = layoutAndReturnItems({
      id: exportOptions.layout,
      withinContainer: {dimension: canvasDimensions},
      items,
    });

    const captionItem = getItemByType(positionedItems, ViewTypes.CaptionClip);
    if (!captionItem) return;

    const totalDuration = Number(convertToMs(captionItem.item.playLengthSeconds));
    const canvasScenes = {
      [assignedSceneId]: {
        ...BASE_SCENE_STATE,
        duration: totalDuration,
      },
    } as Record<string, CanvasScene>;

    const clientState: ClientStateType = {
      items: positionedItems,
      canvasScenes,
      sceneFrame: {
        ...BASE_SCENE_FRAME_STATE,
        canvasDimensions,
        backgroundColor: {hex: style.canvas_background},
      },
    };

    const clientSyncVersion = 1;

    try {
      const ownerId = state.auth.user?.id ? state.auth.user?.id : 'anonoymous';

      await postClientStatus(token, projectId, ownerId, clientState, clientSyncVersion);

      dispatch(
        saveClientStateOfCanvasSuccessful({projectId, clientState, clientSyncVersion}),
      );

      callback?.({projectId});
    } catch (error) {
      // @ts-ignore
      callback?.({error});
    }
  };

export const startExportProject =
  (
    token: string,
    projectId: string,
    clientSyncVersion?: number,
    callback?: (result: Error | string) => void,
    originUI?: UploadOriginUI,
  ): AppThunk =>
  async (dispatch, getState) => {
    window.analytics.track('User requested video to be exported');
    dispatch(exportProjectStarted({projectId}));
    const state = getState() as any;
    const {email} = state.auth;

    (async () => {
      try {
        const result = await postProjectExportRequest({
          token,
          projectId,
          email,
          clientSyncVersion:
            clientSyncVersion ?? state.serverSync.projects[projectId].clientSyncVersion,
          originUI,
        });
        const {
          user_project_download: userProjectDownload,
          user_project_sync_version: {duration_ms: durationMs, dimensions},
        } = result.content;
        callback?.(userProjectDownload.id);
        dispatch(
          exportProjectSuccessful({
            projectId,
            userProjectDownload,
            durationMs,
            dimensions,
          }),
        );
      } catch (error) {
        // @ts-ignore
        callback?.(error);
        console.error(error);
        dispatch(
          exportProjectFailed({projectId, error: JSON.parse(JSON.stringify(error))}),
        );
      }
    })();
  };

export default userProjectDownloadSlice.reducer;
