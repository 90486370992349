import { canvasItemsSelectedItemSelector } from 'features/selectors/canvasStateSelectors';
import { useSelector } from 'react-redux';
import { useSidebarMenu } from 'features/EditorCanvas/Sidebar/SidebarMenuContext';

export default function FontTextPicker({ projectId }: { projectId: string }) {
  const { dispatch } = useSidebarMenu();
  const setActiveView = (newActiveView: string) => {
    dispatch({
      type: 'update active sidebar menu',
      activeMenu: newActiveView,
    });
  };

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  if (!selected) return null;

  return (
    <div className="flex">
      <div className="flex w-full flex-col text-sm leading-5 text-gray-700">
        <div className="flex flex-row">
          <div className="relative inline-block text-left">
            <div>
              <button
                type="button"
                className="inline-flex w-32 justify-between rounded-md border border-gray-300 bg-white px-3 py-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                id="options-menu"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() => setActiveView('FontPicker')}
              >
                <span className="truncate text-ellipsis ">
                  {selected.item.style.fontFamily}
                </span>
                {/* Heroicon name: solid/chevron-down */}
                <svg
                  className="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
