import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {useSceneTiming} from '../../CanvasTime/useSceneTiming';

function ItemLayerImage({
  id,
  viewType,
  url,
}: {
  id: string;
  viewType: ViewTypes;
  url: string;
}) {
  const {sceneTimingStyle} = useSceneTiming(id);

  return (
    <img
      style={sceneTimingStyle}
      draggable="false"
      src={url}
      alt="select-layer-item"
      className="mask-circle absolute top-0 left-0 h-full w-full select-none object-cover"
    />
  );
}

export default ItemLayerImage;
