import {
  AccountColor,
  useCreateAccountColorMutation,
  useDeleteAccountColorMutation,
  useFetchAccountBrandQuery,
} from 'services/accountBrandAPI';
import {DeleteButton, ListSkeleton} from './utils';
import {Modal, ModalButtons} from 'ui/Modal';
import {useEffect, useState} from 'react';

import {Button} from 'ui/Button';
import {ColorPicker} from 'ui/ColorPicker';
import {ColorSquare} from 'ui/ColorSquare';
import {HexInput} from 'ui/HexInput';
import {Plus} from 'react-feather';
import {TextInput} from 'ui/TextInput';
import {announceUserToApi} from 'features/authSlice';
import {getAuthToken} from 'services/utils';
import {useDispatch} from 'react-redux';
import {useStytchUser} from '@stytch/stytch-react';

const BrandColor = ({color}: {color: AccountColor}) => {
  const [deleteBrandColor] = useDeleteAccountColorMutation();

  return (
    <li
      key={color.id}
      className="group relative flex w-full items-center justify-between rounded-md border bg-gray-50 px-2 py-2"
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center space-x-2">
          <ColorSquare color={color.color_hex} />
          {color.name ? (
            <>
              <div className="text-sm">{color.name}</div>
              <div className="text-sm text-gray-600">{color.color_hex}</div>
            </>
          ) : (
            <div className="text-sm">{color.color_hex}</div>
          )}
        </div>
        <DeleteButton
          label={color.color_hex}
          onClickConfirm={() => deleteBrandColor({id: color.id})}
        />
      </div>
    </li>
  );
};

const AddColorModal = ({open, onClose}: {open: boolean; onClose: () => void}) => {
  const [color, setColor] = useState<string>('#FFFFFF');
  const [name, setName] = useState('');
  const [addBrandColor] = useCreateAccountColorMutation();

  useEffect(() => {
    if (!open) setColor('#FFFFFF');
  }, [open]);

  // DELETE THIS IN FUTURE
  const user = window.stytch.user.getSync();

  const dispatch = useDispatch();
  const announceUser = async () => {
    const token = await getAuthToken();
    return new Promise<void>((resolve, reject) => {
      dispatch(
        announceUserToApi(token, user!, error => {
          if (error) reject(error);
          resolve();
        }),
      );
    });
  };

  return (
    <Modal open={open} onClose={onClose} title="Add a color">
      <TextInput name="name" type="text" value={name} onChange={setName} label="Name" />
      <ColorPicker
        className="!w-full !max-w-xs"
        color={color}
        onChange={color => setColor(color.hex)}
        alpha={false}
      />
      <HexInput color={color} onChange={color => setColor(color.hex)} />
      <ModalButtons
        cancel={{label: 'Cancel'}}
        confirm={{
          label: 'Add',
          onClick: async () => {
            await addBrandColor({colorHex: color, name});
            await announceUser();
            onClose();
          },
        }}
      />
    </Modal>
  );
};

const ColorsList = ({colors}: {colors: AccountColor[]}) => {
  if (colors.length === 0) {
    return <div />;
  }

  return (
    <div className="my-3 flex flex-col space-y-1 px-6">
      <ul className="space-y-2 ">
        {colors.map(color => (
          <BrandColor color={color} key={color.id} />
        ))}
      </ul>
    </div>
  );
};

export const BrandColors = () => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const {data, isLoading} = useFetchAccountBrandQuery();

  if (!data || isLoading) {
    return <ListSkeleton />;
  }

  const handleClickAdd = () => setAddModalOpen(true);
  const handleClose = () => setAddModalOpen(false);

  return (
    <>
      <div className="">
        <div className="flex flex-col">
          <div className="my-3 flex px-6">
            <div className="flex w-full flex-row items-center justify-between">
              <span className="text-sm text-gray-500">
                Add on-brand colors to your designs with ease - never get the wrong shade
                again.
              </span>
              <span className="pl-2">
                <Button
                  _className="text-xs"
                  variant="primary"
                  fullWidth
                  leftIcon={Plus}
                  onClick={handleClickAdd}
                >
                  Add color
                </Button>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="space-y-2">
            <ColorsList colors={data.colors} />
          </div>
        </div>
      </div>
      <AddColorModal open={addModalOpen} onClose={handleClose} />
    </>
  );
};
