import {
  canvasItemsSelectedItemIdsSelector,
  canvasItemsSelectedItemSelector,
} from 'features/selectors/canvasStateSelectors';
import {
  isAudioComponent,
  isCaptionsComponent,
  isImageComponent,
  isShapeComponent,
  isSmartComponent,
  isTextComponent,
  isVideoComponent,
} from 'features/EditorCanvas/helpers';

import {AudioComponentActionNavBar} from '../CanvasItemLayers/VideoClip/LayerNav/AudioComponentActionNavBar';
import {CaptionsComponentActionNavBar} from '../CanvasItemLayers/CaptionClip/LayerNav/CaptionsComponentActionNavBar';
import {CropToolbar} from './SelectionNavBar/CropToolbar';
import {FC} from 'react';
import {GetRootState} from 'configureStore';
import {ImageComponentActionNavBar} from '../CanvasItemLayers/Image/LayerNav/ImageComponentActionNavBar';
import SceneLengthPicker from './SelectionNavBar/SceneLengthPicker';
import {ShapeComponentActionNavBar} from '../CanvasItemLayers/Square/LayerNav/ShapeComponentActionNavBar';
import {SmartComponentActionNavBar} from '../CanvasItemLayers/SmartComponent/LayerNav/SmartComponentActionNavBar';
import {TextComponentActionNavBar} from '../CanvasItemLayers/Text/LayerNav/TextComponentActionNavBar';
import {TimingOffsetTimeline} from './SelectionNavBar/TimingOffsetTimeline';
import {TrimTimeline} from './SelectionNavBar/TrimTimeline';
import {VideoComponentActionNavBar} from '../CanvasItemLayers/VideoClip/LayerNav/VideoComponentActionNavBar';
import classNames from 'classnames';
import {playTimeMediaTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {selectCanvasItemsProjectsCanvas} from 'features/selectors/canvasItemsSelectors';
import {useIsCropActive} from './Canvas/utils/useEditCrop';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useScenes} from '../CanvasTime/useScenes';
import {useSelector} from 'react-redux';

export const NavBarContainer: FC<{selectedCount: number}> = ({children}) => {
  return (
    <nav
      style={{minHeight: '46px'}}
      className="flex border-b border-gray-200 bg-white"
      aria-label="Breadcrumb"
    >
      <ol className={classNames('mx-auto flex w-full items-center space-x-4 py-1 px-4')}>
        {children}
      </ol>
    </nav>
  );
};

export function ActiveSelectionNavBar() {
  const projectId = useProjectId();
  const {activeScene} = useScenes();
  const trimmingItem = useSelector(
    (state: GetRootState) =>
      selectCanvasItemsProjectsCanvas(state)[projectId].trimmingItem,
  );
  const offsetTimingItem = useSelector(
    (state: GetRootState) =>
      selectCanvasItemsProjectsCanvas(state)[projectId].offsetTimingItem,
  );

  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);

  const selectedItem = useSelector(canvasItemsSelectedItemSelector)(projectId);

  const isCropActive = useIsCropActive();

  const sceneItems = Object.values(activeScene.items);
  const activeScenePlayableItems = sceneItems.filter(
    // @ts-ignore
    item => playTimeMediaTypes.includes(item.viewType) && !item.isDurationIgnored,
  );
  const isStaticScene = activeScenePlayableItems.length === 0;

  const shouldShowSceneLengthPicker = !selectedItem && isStaticScene;

  const shouldShowTimingOffsetTimeline =
    offsetTimingItem && selectedItem?.id === offsetTimingItem;

  const shouldShowTrimTimeline = trimmingItem && selectedItem?.id === trimmingItem;

  const shouldShowCropToolbar = isCropActive && selectedItem;

  if (shouldShowCropToolbar) {
    return (
      <NavBarContainer selectedCount={1}>
        <CropToolbar projectId={projectId} itemKey={selectedItem.id} />
      </NavBarContainer>
    );
  }

  if (shouldShowTrimTimeline) {
    return <TrimTimeline {...selectedItem} />;
  }

  if (shouldShowTimingOffsetTimeline) {
    return <TimingOffsetTimeline {...selectedItem} />;
  }

  return (
    <NavBarContainer selectedCount={selectedItemIds.length}>
      {shouldShowSceneLengthPicker && (
        <SceneLengthPicker projectId={projectId} activeSceneId={activeScene.id} />
      )}
      {selectedItem && isTextComponent(selectedItem) && <TextComponentActionNavBar />}
      {selectedItem && isShapeComponent(selectedItem) && <ShapeComponentActionNavBar />}
      {selectedItem && isImageComponent(selectedItem) && <ImageComponentActionNavBar />}
      {selectedItem && isCaptionsComponent(selectedItem) && (
        <CaptionsComponentActionNavBar />
      )}
      {selectedItem && isAudioComponent(selectedItem) && <AudioComponentActionNavBar />}
      {selectedItem && isVideoComponent(selectedItem) && <VideoComponentActionNavBar />}
      {selectedItem && isSmartComponent(selectedItem) && <SmartComponentActionNavBar />}
    </NavBarContainer>
  );
}
