import {Switch} from '@headlessui/react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function ToggleWithLabelAndDescription({
  title,
  description,
  isActive,
  handleToggle,
}: {
  title: string;
  description: string;
  isActive: boolean;
  handleToggle: () => void;
}) {
  const onHandleToggle = () => {
    handleToggle();
  };
  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
          {title}
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          {description}
        </Switch.Description>
      </span>
      <Switch
        checked={isActive}
        onChange={onHandleToggle}
        className={classNames(
          isActive ? 'bg-indigo-600' : 'bg-gray-200',
          'focus:outline-none relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            isActive ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
    </Switch.Group>
  );
}
