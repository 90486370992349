import {DependencyList, useLayoutEffect, useReducer, useRef} from 'react';
import {TimeEffectOptions, TimeInfo, useTimeEffect} from './useTimeEffect';

export function useTimeSelector<Return>(
  selector: (info: TimeInfo) => Return,
  deps: DependencyList,
  options?: TimeEffectOptions,
) {
  const [, forceRender] = useReducer(s => s + 1, 0);
  const value = useRef(selector({currentTime: 0, type: 'state'}));

  const selectorRef = useRef(selector);
  useLayoutEffect(() => {
    selectorRef.current = selector;
  }, [selector]);

  useTimeEffect(
    info => {
      const newValue = selectorRef.current(info);
      if (newValue !== value.current) {
        value.current = newValue;
        forceRender();
      }
    },
    deps,
    options,
  );

  return value.current;
}
