import {
  BASE_LAYER_ITEM_STYLE,
  SAMPLE_SMART_COMPONENT,
} from 'features/EditorCanvas/constants/ItemConstants';
import {BaseItem, SmartComponentItem} from 'features/types/canvasItemsSlice';

import {AddAudioFrequencyAnalysisButton} from './AudioRelated/AddAudioFrequencyAnalysisButton';
import {Button} from 'ui/Button';
import {ButtonProgressAnimation} from './ButtonProgressAnimation';
import {SmartComponents} from 'features/EditorCanvas/constants/ItemToolbarMapping';
import {addCanvasItem} from 'features/canvasItemsSlice';
import {useDispatch} from 'react-redux';
import {useFeatureFlagsSome} from 'features/hooks/useFeatureFlag';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';

export function SmartElements() {
  const dispatch = useDispatch();
  const projectId = useProjectId();
  const {activeScene} = useScenes();

  const isSmartComponentsEnabled = useFeatureFlagsSome(['smart-components']);

  const addNewCountdownItem = () => {
    window.analytics.track('Add new smart element - countdown');

    const item = {
      ...SAMPLE_SMART_COMPONENT,
      style: {
        ...BASE_LAYER_ITEM_STYLE,
        color: '#000',
      },
      sceneId: activeScene?.id,
      smartComponent: {
        id: SmartComponents.CountdownTimer,
        options: {},
      },
    } as BaseItem & SmartComponentItem;
    dispatch(addCanvasItem({projectId, item}));
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <div className="text-sm font-bold text-gray-700">{'Smart elements'}</div>
        {/* <div className="text-xs font-medium text-gray-400">See all</div> */}
      </div>

      <div className="flex">
        <div className="grid w-full grid-cols-1 gap-2">
          <ButtonProgressAnimation />
          {isSmartComponentsEnabled && (
            <>
              <Button
                onClick={addNewCountdownItem}
                variant="tertiary"
                fullWidth
                alignText="center"
                _className="text-left bg-gray-100"
                size="large"
              >
                <div className="flex w-full items-center justify-center text-sm">
                  Remaining time countdown
                </div>
              </Button>
              <AddAudioFrequencyAnalysisButton />
            </>
          )}
        </div>
      </div>
    </>
  );
}
