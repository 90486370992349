import {AlertTriangle} from 'react-feather';
import MilkLogo from 'features/Dashboard/shared/MilkLogo';

export default function ErrorPage({
  title = 'Error occured',
  explainer = 'Please click the button below to reload the page.',
}: {
  title?: string;
  explainer?: string;
}) {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
      <MilkLogo width={80} height={80} />
      <div />
      <div className="align-center flex flex-col items-center justify-center space-y-5 text-center">
        <h1 className="mt-4 text-3xl font-bold">{title}</h1>
        <p className="mt-4 max-w-md text-gray-600">
          {explainer} <br />
          <a href={window.location.href}>
            <span className="font-semibold text-indigo-500">Reload page.</span>
          </a>{' '}
          to go back home.
        </p>
      </div>
      <div />
      <div />
      <div />
    </div>
  );
}
