import {ArrowLeft, ArrowRight, Save} from 'react-feather';
import {FC, ReactNode, useEffect, useMemo, useRef, useState} from 'react';
import {SerializedTime, Time} from 'features/Common/Time';
import {updateItemAttribute, updateItemTimingOffset} from 'features/canvasItemsSlice';
import {useDocumentMouseUp, useEventListener} from 'features/Common/utils';

import {CanvasButton} from '../../CanvasButton';
import {CanvasItem} from 'features/types/canvasItemsSlice';
import {NavBarContainer} from '../ActiveSelectionNavBar';
import {PreviewFrameCanvas} from '../Canvas/PreviewFrameCanvas';
import clamp from 'lodash.clamp';
import classNames from 'classnames';
import {createContainer} from 'unstated-next';
import {useDispatch} from 'react-redux';
import {useHotkeys} from 'react-hotkeys-hook';
import {useOnOutsideClick} from '../../useOnOutsideClick';
import {usePlayback} from '../../CanvasTime/usePlayback';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useScenes} from '../../CanvasTime/useScenes';
import {useWatchElementSize} from 'features/Common/useElementSize';

function useTimelineContainer(initialState: {item: CanvasItem; itemId: string}) {
  const {item, itemId} = initialState;
  const dispatch = useDispatch();
  const projectId = useProjectId();
  const {activeScene} = useScenes();
  const [focusedHandle, setFocusedHandle] = useState<'start' | 'end' | undefined>();

  const initialOffsetMs = item && item.sceneTiming ? item.sceneTiming.offsetMs : 0;
  const initalPlayLengthMs =
    item && item.sceneTiming
      ? item.sceneTiming.durationMs + item.sceneTiming.offsetMs
      : activeScene.duration;

  const [selection, setSelection] = useState(() => ({
    start: new Time(initialOffsetMs, 'ms'),
    end: new Time(initalPlayLengthMs, 'ms'),
  }));

  const selectionDuration = new Time(Math.abs(selection.end.ms - selection.start.ms));
  const selectionDurationSec = selectionDuration.s.toFixed(2) + 's';

  const [durationInputValue, setDurationInputValue] = useState(selectionDurationSec);

  const onDragStart = (type: 'start' | 'end') => () => {
    if (type !== focusedHandle) {
      setFocusedHandle(undefined);
    }
  };

  const onDragEnd = (type: 'start' | 'end') => () => {
    setFocusedHandle(type);
    updateItemStore();
  };

  const onNudge = (type: 'start' | 'end') => (amount: number) => {
    setSelection({...selection, [type]: selection[type].add(amount, 's')});
  };

  useEffect(() => {
    setDurationInputValue(selectionDurationSec);
  }, [selectionDurationSec]);

  const updateItemStore = () => {
    const data = {
      sceneTiming: {
        offsetMs: selection.start.ms,
        durationMs: selectionDuration.ms,
      },
    };

    console.log('Updating attribute: sceneTiming', data);

    dispatch(
      updateItemAttribute({
        projectId,
        id: itemId,
        attribute: 'sceneTiming',
        newValue: data.sceneTiming,
      }),
    );
  };

  const save = () => {
    updateItemStore();
    dispatch(updateItemTimingOffset({projectId, itemId: undefined}));
  };

  useEffect(() => {
    return () => {
      dispatch(updateItemTimingOffset({projectId, itemId: undefined}));
    };
  }, [dispatch, projectId, itemId]);

  const sceneDuration = new Time(activeScene.duration, 'ms');

  const range: Selection = {start: new Time(0), end: sceneDuration};

  const [sizeRef, size] = useWatchElementSize<HTMLDivElement>();

  return {
    sizeRef,
    width: size.width,

    sceneDuration,
    range,
    onDragStart,
    onDragEnd,
    onNudge,
    focusedHandle,
    setFocusedHandle,
    item,
    itemId,
    save,
    selection,
    setSelection,
    selectionDuration,
    selectionDurationSec,
    durationInputValue,
    setDurationInputValue,
  };
}

// @ts-ignore
const TimelineContext = createContainer(useTimelineContainer);

const TimelineContextProvider = TimelineContext.Provider;
const useTimelineContext = TimelineContext.useContainer;

export const TimingOffsetTimeline = ({item, id}: {item: CanvasItem; id: string}) => {
  return (
    <TimelineContextProvider initialState={{item: item, itemId: id}}>
      <TimingOffsetTimelineContent />
    </TimelineContextProvider>
  );
};

const TimingOffsetTimelineContent = () => {
  const {sizeRef} = useTimelineContext();
  return (
    <div className="bg-white">
      <TimingOffsetNavbar />
      <div className="w-full space-y-2 border-b px-1 py-2">
        <div className="h-20 w-full">
          <div className="h-full w-full px-3">
            <div className="relative h-full w-full select-none" ref={sizeRef}>
              <SceneTimelineBackground />
              <TimelineSceneSelection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TimingOffsetNavbar = () => {
  const {durationInputValue, setDurationInputValue, setSelection, selection, save} =
    useTimelineContext();
  return (
    <>
      <NavBarContainer selectedCount={1}>
        <div className="flex w-full items-center justify-between space-x-2">
          <input
            value={durationInputValue}
            onChange={event => setDurationInputValue(event.currentTarget.value)}
            onBlur={() => {
              const seconds = parseFloat(durationInputValue);
              if (isNaN(seconds)) return;

              setSelection({
                ...selection,
                end: selection.start.add(seconds, 's'),
              });
            }}
            className="w-20 rounded border p-1 px-1.5 text-center font-mono text-sm focus:border-indigo-500 focus:outline-none"
          />
          <CanvasButton leftIcon={Save} onClick={save}>
            Save
          </CanvasButton>
        </div>
      </NavBarContainer>
    </>
  );
};

export type SerializedSelection = {start: SerializedTime; end: SerializedTime};
export type Selection = {start: Time; end: Time};

const TIMELINE_HEIGHT = 70;
const SCENE_BACKGROUND_WIDTH = 120;

const SceneTimelineBackground = () => {
  const {sizeRef, width} = useTimelineContext();
  const {activeScene} = useScenes();
  const sceneCount = width / SCENE_BACKGROUND_WIDTH;
  const projectId = useProjectId();
  const times = useMemo(() => {
    const timesArr = [];
    const frequency = activeScene.duration / sceneCount;
    for (let i = 0; i < Math.round(sceneCount); i++) {
      timesArr.push(i * frequency);
    }
    return timesArr;
  }, [activeScene, sceneCount]);
  return (
    <div className="relative flex h-full items-center">
      {times.map((time, index) => {
        return (
          <div
            key={time}
            className="absolute"
            style={{width: SCENE_BACKGROUND_WIDTH, left: SCENE_BACKGROUND_WIDTH * index}}
          >
            <PreviewFrameCanvas
              maxHeight={TIMELINE_HEIGHT}
              projectId={projectId}
              scaledCanvasDimensions={{height: 1000, width: 1000}}
              timeSetToMs={time}
            />
          </div>
        );
      })}
    </div>
  );
};

const inactiveOverlayStyle = 'absolute inset-y-0 bg-gray-700 bg-opacity-50';

const TimelineSceneSelection = () => {
  const {
    width,
    onDragStart,
    onDragEnd,
    onNudge,
    focusedHandle,
    setFocusedHandle,
    selection,
    setSelection,
    sceneDuration,
  } = useTimelineContext();

  const selectionDuration = new Time(Math.abs(selection.start.ms - selection.end.ms));

  const left = (selection.start.ms / sceneDuration.ms) * width;
  const selectionWidth = (selectionDuration.ms / sceneDuration.ms) * width;

  const container = useRef<HTMLDivElement>(null);

  const handleDrag = (type: 'start' | 'end') => (dragX: number) => {
    if (!container.current) return;

    const containerX = container.current.getBoundingClientRect().left;
    const x = dragX - containerX;

    const percentage = clamp(x / width, 0, 1);
    const newTime = new Time(percentage * sceneDuration.ms);

    const otherValue = type === 'start' ? selection.end : selection.start;
    if (Math.abs(newTime.s - otherValue.s) < 1) return;

    setSelection({...selection, [type]: newTime});
  };

  const right = width - (left + selectionWidth);

  const {activeScene} = useScenes();

  return (
    <>
      <div className="pointer-events-none absolute inset-0" ref={container}>
        <div
          className={classNames(inactiveOverlayStyle, 'left-0 rounded-l-md')}
          style={{width: left}}
        />
        <div
          className={classNames(inactiveOverlayStyle, 'right-0 rounded-r-md')}
          style={{width: right}}
        />
        <div
          className="absolute inset-y-0 top-0 left-0 w-40"
          style={{left, width: selectionWidth}}
        >
          <HandleSceneTimeline
            type="start"
            onDrag={handleDrag('start')}
            time={new Time(activeScene.duration + selection.start.ms).pretty}
            onDragStart={onDragStart('start')}
            onDragEnd={onDragEnd('start')}
            focusedHandle={focusedHandle}
            onBlur={() => setFocusedHandle(undefined)}
            onNudge={onNudge('start')}
          />
          <HandleSceneTimeline
            type="end"
            onDrag={handleDrag('end')}
            onDragStart={onDragStart('end')}
            onDragEnd={onDragEnd('end')}
            onNudge={onNudge('end')}
            time={new Time(activeScene.duration + selection.end.ms).pretty}
            focusedHandle={focusedHandle}
            onBlur={() => setFocusedHandle(undefined)}
          />
        </div>
      </div>
    </>
  );
};

const HandleSceneTimeline = ({
  type,
  onDrag,
  onDragStart,
  onDragEnd,
  onNudge,
  time,

  focusedHandle,
  onBlur,
}: {
  type: 'start' | 'end';
  onDrag: (dragX: number) => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  time: string;

  focusedHandle: 'start' | 'end' | undefined;
  onBlur: () => void;
  onNudge: (amount: number) => void;
}) => {
  const [gestureStartX, setGestureStartX] = useState<number | null>(null);

  const onMouseDown = useDocumentMouseUp(() => {
    setGestureStartX(null);
    onDragEnd?.();
  });

  useEventListener('mousemove', event => {
    if (gestureStartX == null) return;
    onDrag(event.clientX + 12 - gestureStartX);
  });

  const isFocused = focusedHandle === type;

  const container = useOnOutsideClick(() => {
    if (!isFocused) return;
    onBlur();
  });

  useHotkeys(
    'left',
    () => {
      if (!isFocused) return;
      onNudge(-0.1);
    },
    [isFocused, onNudge],
  );

  useHotkeys(
    'right',
    () => {
      if (!isFocused) return;
      onNudge(0.1);
    },
    [isFocused, onNudge],
  );

  const {isPlaying} = usePlayback();

  return (
    <div
      className={classNames(
        'pointer-events-auto absolute inset-y-0 w-6',
        type === 'start' && '-left-3',
        type === 'end' && '-right-3',
      )}
    >
      <div
        className={classNames(
          'absolute inset-0 cursor-horizontal overflow-hidden rounded-l-md',
          type === 'end' && 'rotate-180 transform',
        )}
        onMouseDown={e => {
          setGestureStartX((e.nativeEvent as any).layerX);
          onDragStart?.();
          onMouseDown();
        }}
        ref={container}
      >
        <div className="h-full w-3 rounded-l-md">
          <div
            className={classNames('absolute inset-y-0 left-3 w-[6px] rounded-l-md', {
              'ring-pink-500': type === 'start',
              'ring-blue-500': type === 'end',
            })}
            style={{boxShadow: '-6px 0 0 6px var(--tw-ring-color)'}}
          />
          <div className="absolute inset-y-[22px] -right-[6px] flex w-[12px] items-center justify-center space-x-0.5">
            <div className="h-full w-px rounded-full bg-black bg-opacity-20" />
            <div className="h-full w-px rounded-full bg-black bg-opacity-20" />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'pointer-events-none absolute top-full z-[9999] flex flex-col items-center pt-1.5 opacity-0 transition-opacity',
          type === 'start' && 'left-2 -translate-x-1/2',
          type === 'end' && 'right-2 translate-x-1/2',
          (gestureStartX != null || isFocused) && 'opacity-100',
        )}
      >
        <div className="h-0 w-0 border-l-4 border-r-4 border-b-4 border-transparent border-b-black" />
        <div className="space-y-2 rounded bg-black p-2 text-center font-mono text-sm text-white">
          <div>
            <div>{time}</div>
          </div>
          {gestureStartX == null && isFocused && (
            <div className="space-y-2 whitespace-nowrap text-xs text-gray-300">
              <div className="flex items-center justify-center space-x-1.5">
                <div>Use</div>
                <Key>
                  <ArrowLeft size={12} />
                </Key>
                <Key>
                  <ArrowRight size={12} />
                </Key>
                <div>to nudge</div>
              </div>
              <div className="flex items-center justify-center space-x-1.5">
                <div>Press</div>
                <Key className="!w-auto px-1">space</Key>
                <div>to {isPlaying ? 'pause' : 'play'}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Key: FC<{className?: string}> = ({children, className}) => {
  return (
    <div
      className={classNames(
        'flex h-4 w-4 items-center justify-center rounded bg-gray-700',
        className,
      )}
    >
      {children}
    </div>
  );
};
