import { canvasItemsSelectedItemIdsSelector, canvasStateSelector } from 'features/selectors/canvasStateSelectors';
import {
  previewItemsSelector,
  sceneFrameSelector,
} from 'features/selectors/sceneFrameSelectors';
import { useDispatch, useSelector } from 'react-redux';

import CanvasBackground from './CanvasBackground';
import { CanvasItemContainer } from '../../CanvasItem';
import { CanvasItem as CanvasItemType } from 'features/types/canvasItemsSlice';
import { GetRootState } from 'configureStore';
import Moveable from 'react-moveable';
import { MoveableManager } from './MoveableManager';
import { RefObject } from 'react';
import { selectCanvasProject } from 'features/selectors/canvasItemsSelectors';
import { updateItems } from 'features/canvasItemsSlice';
import { useRefs } from '../../CanvasItem/useRefs';
import { useScenes } from '../../CanvasTime/useScenes';

export function CanvasWrapper({
  preview,
  projectId,
  moveableRef,
}: {
  preview?: boolean;
  projectId: string;
  moveableRef?: RefObject<Moveable>;
}) {
  const { activeScene } = useScenes();

  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);

  const { items: allItems } = useSelector((state: GetRootState) =>
    selectCanvasProject(state, projectId),
  );
  const { canvasDimensions } = useSelector(sceneFrameSelector)(projectId);

  const canvasState = useSelector(canvasStateSelector)

  const items = {};
  Object.keys(allItems).forEach(itemId => {
    items[itemId] = {
      ...allItems[itemId],
      sceneId: allItems[itemId].sceneId ?? activeScene.id,
    };
  });

  const dispatch = useDispatch();

  const updateAttributes = (items: Record<string, Partial<CanvasItemType>>) => {
    dispatch(
      updateItems({
        projectId,
        items,
      }),
    );
  };

  const refs = useRefs<HTMLDivElement>(Object.keys(items));
  const selectedItems = selectedItemIds.map(id => ({
    id,
    ref: refs[id],
  }));

  const previewItems = useSelector(previewItemsSelector)(projectId);

  if (preview) {
    return (
      <>
        {Object.keys(items).map(itemId => (
          <CanvasItemContainer
            itemLoading={canvasState.items[itemId]}
            projectId={projectId}
            itemId={itemId}
            item={items[itemId]}
            key={itemId}
          />
        ))}
      </>
    );
  }

  const itemIds = Object.keys(items);
  const previewItemKeys = Object.keys(previewItems);

  return (
    <>
      {previewItemKeys.length === 0 && (
        <MoveableManager
          ref={moveableRef}
          projectId={projectId}
          selectedItems={selectedItems}
          items={items}
          onChange={styles => updateAttributes(styles)}
          allRefs={Object.values(refs)}
          canvasDimensions={canvasDimensions}
        />
      )}
      <div className="h-full w-full bg-white">
        <CanvasBackground projectId={projectId} />
        {previewItemKeys.length === 0 &&
          itemIds.map(itemId => (
            <CanvasItemContainer
              itemLoading={canvasState.items[itemId]}
              projectId={projectId}
              itemId={itemId}
              item={items[itemId]}
              key={itemId}
              ref={refs[itemId]}
              onChangeSize={() => moveableRef?.current?.updateRect()}
            />
          ))}
        {previewItemKeys.map(itemId => (
          <CanvasItemContainer
            itemLoading={canvasState.items[itemId]}
            projectId={projectId}
            itemId={itemId}
            item={previewItems[itemId]}
            key={itemId}
            ref={refs[itemId]}
            onChangeSize={() => moveableRef?.current?.updateRect()}
            isPreview
          />
        ))}
      </div>
    </>
  );
}
