import {useDispatch, useSelector} from 'react-redux';

import {NumberInput} from 'features/EditorCanvas/components/shared/common/NumberInput';
import {PositionType} from 'features/types/canvasItemsSlice';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {updateItemAttribute} from 'features/canvasItemsSlice';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

export default function PositionPicker() {
  const dispatch = useDispatch();
  const projectId = useProjectId();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  if (!selected) return null;

  const setDimension = (position: Partial<PositionType>) => {
    dispatch(
      updateItemAttribute({
        projectId,
        id: selected.id,
        attribute: 'position',
        newValue: {
          ...selected.item.position,
          ...position,
        },
      }),
    );
  };

  return (
    <div className="flex">
      <div className="flex w-full flex-col pr-6 text-sm leading-5 text-gray-700">
        <div className="flex flex-row">
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label className="mb-2 block text-sm font-medium leading-5 text-gray-700">
                Left
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <NumberInput
                    value={selected.item.position.left}
                    onChange={left => setDimension({left})}
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label className="mb-2 block text-sm font-medium leading-5 text-gray-700">
                Top
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <NumberInput
                    value={selected.item.position.top}
                    onChange={top => setDimension({top})}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
