import {ReactNotificationOptions} from 'react-notifications-component';

export const NOTIFICATION_BASE: ReactNotificationOptions = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  type: 'info',
  dismiss: {
    click: true,
    duration: 2500,
    onScreen: false,
  },
};
