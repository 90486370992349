import { RecordingFormPrompt, useRecordingFormQuery, useUpdateRecordingFormMutation } from 'services/recordingFormsAPI';
import { useEffect, useMemo, useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Loading } from 'features/EditorCanvas/Sidebar/views/Assets/Loading';
import { RecordingFormBuilderDetailPromptList } from './RecordingFormBuilderDetailPromptList';
import { RecordingFormTextInput } from 'features/Dashboard/DashboardPage/ContentRecordingForms/RecordingFormTextInput';
import { RecordingTopBar } from './RecordFormTopbar';
import { Share } from 'react-feather';
import cn from 'classnames';
import { recordingFormUrl } from './utils';
import { showSuccessNotification } from 'features/Common/utils';
import useDimensions from 'react-use-dimensions';
import { useParams } from 'react-router-dom';

export const RecordingElementCard = ({
  isDragging,
  item,
  onClick,
  activeStep,
}: {
  item: RecordingFormPrompt;
  isDragging: boolean;
  activeStep: string | null;
  onClick: () => void;
}) => {
  return (
    <li
      key={item.id}
      className={cn(
        'col-span-1 flex transform flex-col divide-y divide-gray-200 rounded-lg bg-white text-left shadow transition',

        isDragging && 'border-indigo-200 bg-indigo-100',
      )}
      onClick={() => onClick()}
    >
      <div className="flex flex-1 flex-col space-y-3 p-6">
        <div className="flex flex-row items-center justify-between space-x-3">
          <RecordingFormTextInput
            close={() => { }}
            recordingFormId={item.user_recording_interaction_form_id}
            recordingPrompt={item}
          />
        </div>
      </div>
    </li>
  );
};

const PROMPT_BUTTON_HEIGHT = 80;

const RecordingBody = ({
  bodyHeight,
  recordingFormId,
}: {
  bodyHeight: number;
  recordingFormId: string;
}) => {
  const handleChangeStep = (questionId: string) => {
    setActiveStep(questionId);
  };

  const [activeStep, setActiveStep] = useState<string | null>(null);
  const { data: recordingPageData, isLoading } = useRecordingFormQuery({
    id: recordingFormId,
  });

  const previewUrl = useMemo(() => {
    if (!recordingPageData) {
      return '';
    }
    return recordingFormUrl(recordingPageData.id);
  }, [recordingPageData]);

  const form_prompts = recordingPageData?.recording_form_prompts;

  const [updateRecordingForm, { isLoading: isUpdating }] = useUpdateRecordingFormMutation();

  const handleCopyConfirm = () => {
    showSuccessNotification({
      title: 'Copied link',
      message: 'You can now share the recording form link.',
    });
  };

  const handleNewPrompt = async () => {
    window.analytics.track('Pg-Edit -- LdPgAdm -- Act-Crt -- Clip Component');
    try {
      const response = await updateRecordingForm({
        id: recordingFormId,
        user_recording_form_prompts_attributes: [
          {
            title: `Question ${recordingPageData?.recording_form_prompts.length}`,
            description: 'okay',
            order: recordingPageData?.recording_form_prompts.length,
            prompt_type: 'text',
          },
        ],
      });

      // console.log('activeStep', activeStep);
      // console.log('response', response);
      if (form_prompts?.length) {
        setActiveStep(form_prompts[-1].id);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  if (isLoading || !recordingPageData || !recordingPageData?.recording_form_prompts) {
    return <Loading />;
  }

  const selectedRecordingPrompt = recordingPageData.recording_form_prompts.find(
    prompt => prompt.id === activeStep,
  );

  const handleClose = () => {
    setActiveStep(null);
  };

  const handleFocus = event => event.target.select();

  return (
    <div className="flex w-full" style={{ height: bodyHeight }}>
      <div className="flex h-full w-full">
        <div className="relative z-0 flex h-full w-full">
          <main
            className="relative flex w-full flex-col border-r border-gray-200"
          >
            <div
              className="mx-auto flex w-full max-w-3xl items-start justify-center space-y-4 px-4"
            >
              <div className="flex w-full flex-col space-y-6 py-6">
                <RecordingFormBuilderDetailPromptList
                  recordingFormId={recordingFormId}
                  itemsList={recordingPageData.recording_form_prompts}
                >
                  {props => {
                    return (
                      <RecordingElementCard
                        item={props.item}
                        activeStep={activeStep}
                        // isDragging={props.isDragging}
                        isDragging={false}
                        onClick={() => {
                          handleChangeStep(props.item.id);
                        }}
                      />
                    );
                  }}
                </RecordingFormBuilderDetailPromptList>

                <div className="col-span-1 flex transform cursor-grab flex-col rounded-lg bg-white text-left shadow transition">
                  <div className="flex flex-1 flex-col space-y-3 p-6">
                    <div className="flex flex-row items-center justify-between space-x-3">
                      <div className="relative w-full space-y-6">
                        <div>
                          <div className="flex items-start">
                            <div className="flex-shrink-0">
                              <Share
                                className="h-6 w-6 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                              <p className="text-sm font-medium text-gray-900">
                                Step 2: Share your form link
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                Click the link below to share your form and collect a
                                recording.
                              </p>
                              <div className="mt-3 flex space-x-4">
                                <CopyToClipboard
                                  text={previewUrl}
                                  onCopy={handleCopyConfirm}
                                >
                                  <input
                                    onClick={event => {
                                      handleFocus(event);
                                    }}
                                    type="text"
                                    className="block w-full rounded-md border-gray-300 py-3 px-4 text-xs placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                    value={previewUrl}
                                  />
                                </CopyToClipboard>
                                <CopyToClipboard
                                  text={previewUrl}
                                  onCopy={handleCopyConfirm}
                                >
                                  <button
                                    type="button"
                                    className="whitespace-nowrap rounded-md bg-white px-2 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Copy link
                                  </button>
                                </CopyToClipboard>
                              </div>
                            </div>
                            <div className="ml-4 flex flex-shrink-0"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                height: PROMPT_BUTTON_HEIGHT,
              }}
              className="flex w-full px-6 py-3"
            >
              <div className="mx-auto flex w-full max-w-md lg:px-6">
                <Button
                  size="large"
                  fullWidth
                  _className="shadow"
                  variant="secondary"
                  onClick={handleNewPrompt}
                >
                  Add another prompt
                </Button>
              </div>
            </div> */}
          </main>
        </div>
      </div>
    </div>
  );
};

export const TOPBAR_HEIGHT = 60;

export const RecordingFormBuilderDetailContainer = () => {
  const { recordingFormId } = useParams() as Record<string, string>;

  const [ref, { height: pageHeight }] = useDimensions();

  return (
    <main ref={ref} className="flex h-full flex-1 flex-col">
      <RecordingTopBar recordingFormId={recordingFormId} />
      <div className="grid border-t md:grid-cols-1 lg:grid-cols-1">
        <div className="col-span-1 lg:col-span-1">
          <RecordingBody
            bodyHeight={pageHeight - TOPBAR_HEIGHT}
            recordingFormId={recordingFormId}
          />
        </div>
      </div>
    </main>
  );
};
