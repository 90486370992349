import {Plus} from 'react-feather';
import {Button} from 'ui/Button';

export type EmptyStateProps = {
  openModalHandler: () => any;
};

export default function ComponentsEmptyState({openModalHandler}: EmptyStateProps) {
  return (
    <div className="justify-center pt-8 text-center">
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        You haven't added anything to your landing page yet
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by updating the title and description above, and adding a clip.
      </p>
      <div className="mt-6">
        <Button variant="primary" onClick={openModalHandler}>
          <span className="flex space-x-4">
            <Plus />
            <span>Add Clip</span>
          </span>
        </Button>
      </div>
    </div>
  );
}
