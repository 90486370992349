import {ArrowLeft, ArrowRight, Save} from 'react-feather';
import {CanvasItem, ItemSource, ItemWithSource} from 'features/types/canvasItemsSlice';
import {FC, ReactNode, useEffect, useRef, useState} from 'react';
import {ItemLayerSources, ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {SerializedTime, Time} from 'features/Common/Time';
import {
  StoryboardThumbnailTilesData,
  useLoadStoryboardJson,
} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/ThumbnailTimeline/hooks';
import {trimItem, updateItemsWithSource} from 'features/canvasItemsSlice';
import {useDocumentMouseUp, useEventListener} from 'features/Common/utils';
import {useTranscript, useTranscriptIdAndLanguage} from 'features/Captions/Transcripts';

import {CanvasButton} from '../../CanvasButton';
import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {NavBarContainer} from '../ActiveSelectionNavBar';
import {TimelineWaveform} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/ThumbnailTimeline/TimelineWaveform';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import clamp from 'lodash.clamp';
import classNames from 'classnames';
import {distributedCopy} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/ThumbnailTimeline/utils';
import {getAuthToken} from 'services/utils';
import {getTrimmedTranscript} from 'features/TranscriptEditor/transcriptUtils';
import {getUserClip} from 'api/clipsAPI';
import {getUserUpload} from 'api/userUploadsAPI';
import {getUserUploadByBucketKey} from 'api/userLibraryAPI';
import {useDispatch} from 'react-redux';
import {useHotkeys} from 'react-hotkeys-hook';
import {useItemSource} from 'features/EditorCanvas/useItemSource';
import {useOnOutsideClick} from '../../useOnOutsideClick';
import {usePlayback} from '../../CanvasTime/usePlayback';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useWatchElementSize} from 'features/Common/useElementSize';

const ExitTrimMode = ({projectId}: {projectId: string}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trimItem({projectId, itemId: undefined}));
  }, [dispatch, projectId]);

  return null;
};

export const TrimTimeline = ({item, id}: {item: CanvasItem; id: string}) => {
  const dispatch = useDispatch();
  const projectId = useProjectId();

  const [selection, setSelection] = useState(() => ({
    start: new Time(item.timeOffsetSeconds, 's'),
    end: new Time(item.timeOffsetSeconds + item.playLengthSeconds, 's'),
  }));

  const selectionDuration = new Time(Math.abs(selection.end.ms - selection.start.ms));
  const selectionDurationS = selectionDuration.s.toFixed(2) + 's';

  const [durationInputValue, setDurationInputValue] = useState(selectionDurationS);
  useEffect(() => {
    setDurationInputValue(selectionDurationS);
  }, [selectionDurationS]);

  const source = useItemSource({item, itemId: id});

  const save = () => {
    // console.log('Saving', source);
    if (!source) return;

    const data = {
      timeOffsetSeconds: selection.start.s,
      playLengthSeconds: selectionDuration.s,
    };

    dispatch(
      updateItemsWithSource({
        projectId,
        sourceId: source.id,
        sceneId: item.sceneId,
        data,
      }),
    );
  };

  const saveRef = useRef(save);
  saveRef.current = save;

  useEffect(() => {
    return () => {
      saveRef.current();
      dispatch(trimItem({projectId, itemId: undefined}));
    };
  }, [dispatch, projectId, id]);

  if (item.viewType !== ViewTypes.VideoClip && item.viewType !== ViewTypes.Video) {
    return <ExitTrimMode projectId={projectId} />;
  }

  return (
    <div className="bg-white">
      <NavBarContainer selectedCount={1}>
        <div className="flex w-full items-center justify-between space-x-2">
          <input
            value={durationInputValue}
            onChange={event => setDurationInputValue(event.currentTarget.value)}
            onBlur={() => {
              const seconds = parseFloat(durationInputValue);
              if (isNaN(seconds)) return;

              setSelection({
                ...selection,
                end: selection.start.add(seconds, 's'),
              });
            }}
            className="w-20 rounded border p-1 px-1.5 text-center font-mono text-sm focus:border-indigo-500 focus:outline-none"
          />
          <CanvasButton leftIcon={Save} onClick={save}>
            Save
          </CanvasButton>
        </div>
      </NavBarContainer>
      <div className="w-full space-y-2 border-b px-1 py-2">
        <div className="h-20 w-full">
          <TimelineWrapper
            item={item}
            itemId={id}
            selection={selection}
            setSelection={setSelection}
            source={source}
          />
        </div>
      </div>
    </div>
  );
};

export type SerializedSelection = {start: SerializedTime; end: SerializedTime};
export type Selection = {start: Time; end: Time};

const TimelineWrapper = ({
  item,
  itemId,
  selection,
  setSelection,
  source,
}: {
  item: ItemWithSource & {playbackId: string};
  itemId: string;
  selection: Selection;
  setSelection: (selection: Selection) => void;
  source: ItemSource | undefined;
}) => {
  const projectId = useProjectId();
  const {transcriptId, language} = useTranscriptIdAndLanguage({itemId, item, projectId});

  const {data: storyboardData} = useLoadStoryboardJson(source?.playbackId, true, 0);

  const [userUpload, setUserUpload] = useState<UserUploadsType | undefined>(undefined);

  useEffect(() => {
    if (!source) {
      return;
    }

    (async () => {
      try {
        const token = await getAuthToken();
        if (source.type === ItemLayerSources.Clips) {
          const clip = await getUserClip(token, source.id);
          const accessedUserUpload = await getUserUpload(token, clip.user_upload_id);
          setUserUpload(accessedUserUpload);
        } else if (source.type === ItemLayerSources.Uploads) {
          const accessedUserUpload = await getUserUploadByBucketKey(token, source.id);
          setUserUpload(accessedUserUpload);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [itemId, source]);

  if (!storyboardData || !source) {
    return (
      <div className="px-3">
        <div className="relative h-full w-full animate-pulse rounded-md bg-gray-100" />
      </div>
    );
  }

  const offset = new Time(source.timing.offset);
  const duration = new Time(source.timing.duration);

  const range: Selection = {start: offset, end: offset.add(duration)};

  if (!storyboardData || !userUpload) {
    return (
      <div className="h-full w-full px-3">
        <div className="relative h-full w-full animate-pulse rounded-md bg-gray-200" />
      </div>
    );
  }

  return (
    <Timeline
      selection={selection}
      setSelection={setSelection}
      range={range}
      transcriptId={transcriptId}
      language={language}
      duration={new Time(storyboardData.duration, 's')}
      renderBackground={() => (
        <TimelineWaveform
          playbackId={source?.playbackId}
          userUploadId={userUpload.id}
          fileType={userUpload.file_type}
        />
      )}
    />
  );
};

type TimelineProps = {
  selection?: Selection;
  setSelection?: (selection: Selection) => void;
  range?: Selection;
  transcriptId?: string;
  language: LanguageId;
  children?: ReactNode;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  duration: Time;
  renderBackground: () => ReactNode;
};

const DRAG_THRESHOLD = 10;

export const Timeline = ({
  selection,
  setSelection,
  range: rangeProp,
  transcriptId,
  language,
  children,
  onDragStart,
  onDragEnd,
  duration,
  renderBackground,
}: TimelineProps) => {
  const [ref, size] = useWatchElementSize<HTMLDivElement>();

  let range: Selection;
  if (rangeProp) {
    range = rangeProp;
  } else {
    const offset = new Time(0);
    range = {start: offset, end: offset.add(duration)};
  }

  return (
    <div className="h-full w-full px-3">
      <div className="relative h-full w-full select-none" ref={ref}>
        {renderBackground()}
        {children}
        {selection && setSelection && (
          <TimelineSelection
            selection={selection}
            setSelection={setSelection}
            range={range}
            width={size.width}
            transcriptId={transcriptId}
            language={language}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          />
        )}
      </div>
    </div>
  );
};

type DragState = {
  type: 'drag';
  anchorPercentage: number;
  focusPercentage: number;
  rangeSizePercentage: number;
  rangePercentage: [number, number];
};

type GestureState =
  | {
      type: 'hover';
      percentage: number;
    }
  | DragState;

type GestureHandlerProps = {
  className?: string;
  children?: (props: {state: GestureState | null}) => ReactNode;
  onClick?: (percentage: number) => void;
  onDragEnd?: (state: DragState) => void;
};

const toPercentage = (float: number) => {
  const percentage = float * 100;
  return clamp(percentage, 0, 100);
};

export const GestureHandler = ({
  className,
  children,
  onClick,
  onDragEnd,
}: GestureHandlerProps) => {
  const mouseDownX = useRef<number | false>(false);
  const isMouseOver = useRef(false);

  const [gestureState, setGestureState] = useState<GestureState | null>(null);

  const gestureHandler = useRef<HTMLDivElement>(null);

  const getLayerX = (event: MouseEvent) => {
    if (!gestureHandler.current) return 0;

    const left = gestureHandler.current.getBoundingClientRect().left;
    return event.clientX - left;
  };

  const getEventPercentage = (event: MouseEvent) => {
    if (!gestureHandler.current) return 0;

    const x = getLayerX(event);
    return toPercentage(x / gestureHandler.current.clientWidth);
  };

  const handleHover = (event: MouseEvent) => {
    const percentage = getEventPercentage(event);
    setGestureState({type: 'hover', percentage});
  };

  const getMovedX = (event: MouseEvent) => {
    if (mouseDownX.current === false) return 0;
    return Math.abs(getLayerX(event) - mouseDownX.current);
  };

  useEventListener('mousemove', event => {
    if (mouseDownX.current !== false && gestureHandler.current) {
      const ghWidth = gestureHandler.current.clientWidth;

      if (getMovedX(event) > DRAG_THRESHOLD || gestureState?.type === 'drag') {
        const anchorPercentage = toPercentage(mouseDownX.current / ghWidth);
        const focusPercentage = getEventPercentage(event);
        const rangeSizePercentage = Math.abs(focusPercentage - anchorPercentage);
        const rangePercentage: [number, number] = [
          Math.min(anchorPercentage, focusPercentage),
          Math.max(anchorPercentage, focusPercentage),
        ];

        setGestureState({
          type: 'drag',
          anchorPercentage,
          focusPercentage,
          rangeSizePercentage,
          rangePercentage,
        });
      }
    } else if (isMouseOver.current) {
      handleHover(event);
    }
  });

  const mouseDown = useDocumentMouseUp(event => {
    setGestureState(null);

    if (mouseDownX.current === false) return;

    if (gestureState?.type === 'drag') {
      onDragEnd?.(gestureState);

      if (isMouseOver.current) {
        handleHover(event);
      } else {
        setGestureState(null);
      }
    } else if (isMouseOver.current) {
      onClick?.(getEventPercentage(event));
      handleHover(event);
    }

    mouseDownX.current = false;
  });

  return (
    <div className={classNames('absolute inset-0', className)}>
      {children?.({state: gestureState})}
      <div
        ref={gestureHandler}
        className={classNames('absolute inset-0')}
        onMouseDown={event => {
          mouseDownX.current = getLayerX(event.nativeEvent);
          mouseDown();
        }}
        onMouseEnter={event => {
          isMouseOver.current = true;
          handleHover(event.nativeEvent);
        }}
        onMouseLeave={() => {
          isMouseOver.current = false;

          setGestureState(state => {
            if (state?.type === 'hover') return null;
            return state;
          });
        }}
      />
    </div>
  );
};

const inactiveOverlayStyle = 'absolute inset-y-0 bg-gray-700 bg-opacity-50';

const TimelineSelection = ({
  selection,
  setSelection,
  range,
  width,
  transcriptId,
  language,
  onDragStart: onDragStartProp,
  onDragEnd: onDragEndProp,
}: {
  selection: Selection;
  setSelection: (selection: Selection) => void;
  range: Selection;
  width: number;
  transcriptId: string | undefined;
  language: LanguageId;
  onDragStart?: () => void;
  onDragEnd?: () => void;
}) => {
  const relativeSelection = {
    start: new Time(selection.start.ms - range.start.ms),
    end: new Time(selection.end.ms - range.start.ms),
  };

  const selectionDuration = new Time(
    Math.abs(relativeSelection.start.ms - relativeSelection.end.ms),
  );
  const rangeDuration = new Time(Math.abs(range.start.ms - range.end.ms));

  const left = (relativeSelection.start.ms / rangeDuration.ms) * width;
  const selectionWidth = (selectionDuration.ms / rangeDuration.ms) * width;

  const container = useRef<HTMLDivElement>(null);

  // const handlePan = () =>{}

  const handleDrag = (type: 'start' | 'end') => (dragX: number) => {
    if (!container.current) return;

    const containerX = container.current.getBoundingClientRect().left;
    const x = dragX - containerX;

    const percentage = clamp(x / width, 0, 1);
    const newTime = new Time(percentage * rangeDuration.ms + range.start.ms);

    const otherValue = type === 'start' ? selection.end : selection.start;
    if (Math.abs(newTime.s - otherValue.s) < 1) return;

    setSelection({...selection, [type]: newTime});
  };

  let startWord: string | undefined, endWord: string | undefined;

  const {transcript} = useTranscript(transcriptId, language);
  if (transcript) {
    const trimmedTranscript = getTrimmedTranscript(
      transcript,
      {startMs: selection.start.ms, endMs: selection.end.ms},
      false,
    ).flat(1);

    if (trimmedTranscript[0] && trimmedTranscript[0]?.text) {
      startWord = trimmedTranscript[0].text;
    }
    if (
      trimmedTranscript[trimmedTranscript.length - 1] &&
      trimmedTranscript[trimmedTranscript.length - 1]?.text
    ) {
      endWord = trimmedTranscript[trimmedTranscript.length - 1].text;
    }
  }

  const [focusedHandle, setFocusedHandle] = useState<'start' | 'end' | undefined>();

  const onDragStart = (type: 'start' | 'end') => () => {
    if (type !== focusedHandle) {
      setFocusedHandle(undefined);
    }

    onDragStartProp?.();
  };

  const onDragEnd = (type: 'start' | 'end') => () => {
    setFocusedHandle(type);
    onDragEndProp?.();
  };

  const onNudge = (type: 'start' | 'end') => (amount: number) => {
    setSelection({...selection, [type]: selection[type].add(amount, 's')});
  };

  return (
    <div className="pointer-events-none absolute inset-0" ref={container}>
      <div
        className={classNames(inactiveOverlayStyle, 'left-0 rounded-l-md')}
        style={{width: left}}
      />
      <div
        className={classNames(inactiveOverlayStyle, 'right-0 rounded-r-md')}
        style={{width: width - (left + selectionWidth)}}
      />
      <div
        className="absolute inset-y-0 top-0 left-0 w-40"
        style={{left, width: selectionWidth}}
      >
        <TimelineHandle
          type="start"
          onDrag={handleDrag('start')}
          time={relativeSelection.start.pretty}
          word={startWord}
          onDragStart={onDragStart('start')}
          onDragEnd={onDragEnd('start')}
          focusedHandle={focusedHandle}
          onBlur={() => setFocusedHandle(undefined)}
          onNudge={onNudge('start')}
        />
        <TimelineHandle
          type="end"
          onDrag={handleDrag('end')}
          time={relativeSelection.end.pretty}
          word={endWord}
          onDragStart={onDragStart('end')}
          onDragEnd={onDragEnd('end')}
          focusedHandle={focusedHandle}
          onBlur={() => setFocusedHandle(undefined)}
          onNudge={onNudge('end')}
        />
      </div>
    </div>
  );
};

const TimelineHandle = ({
  type,
  onDrag,
  onDragStart,
  onDragEnd,
  time,
  word,
  focusedHandle,
  onBlur,
  onNudge,
}: {
  type: 'start' | 'end';
  onDrag: (dragX: number) => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  time: string;
  word: string | undefined;
  focusedHandle: 'start' | 'end' | undefined;
  onBlur: () => void;
  onNudge: (amount: number) => void;
}) => {
  const [gestureStartX, setGestureStartX] = useState<number | null>(null);

  const onMouseDown = useDocumentMouseUp(() => {
    setGestureStartX(null);
    onDragEnd?.();
  });

  useEventListener('mousemove', event => {
    if (gestureStartX == null) return;
    onDrag(event.clientX + 12 - gestureStartX);
  });

  const isFocused = focusedHandle === type;

  const container = useOnOutsideClick(() => {
    if (!isFocused) return;
    onBlur();
  });

  useHotkeys(
    'left',
    () => {
      if (!isFocused) return;
      onNudge(-0.1);
    },
    [isFocused, onNudge],
  );

  useHotkeys(
    'right',
    () => {
      if (!isFocused) return;
      onNudge(0.1);
    },
    [isFocused, onNudge],
  );

  const {isPlaying} = usePlayback();

  return (
    <div
      className={classNames(
        'pointer-events-auto absolute inset-y-0 w-6',
        type === 'start' && '-left-3',
        type === 'end' && '-right-3',
      )}
    >
      <div
        className={classNames(
          'absolute inset-0 cursor-horizontal overflow-hidden rounded-l-md',
          type === 'end' && 'rotate-180 transform',
        )}
        onMouseDown={e => {
          setGestureStartX((e.nativeEvent as any).layerX);
          onDragStart?.();
          onMouseDown();
        }}
        ref={container}
      >
        <div className="h-full w-3 rounded-l-md">
          <div
            className="absolute inset-y-0 left-3 w-[6px] rounded-l-md ring-blue-500"
            style={{boxShadow: '-6px 0 0 6px var(--tw-ring-color)'}}
          />
          <div className="absolute inset-y-[22px] -right-[6px] flex w-[12px] items-center justify-center space-x-0.5">
            <div className="h-full w-px rounded-full bg-black bg-opacity-20" />
            <div className="h-full w-px rounded-full bg-black bg-opacity-20" />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'pointer-events-none absolute top-full z-[9999] flex flex-col items-center pt-1.5 opacity-0 transition-opacity',
          type === 'start' && 'left-2 -translate-x-1/2',
          type === 'end' && 'right-2 translate-x-1/2',
          (gestureStartX != null || isFocused) && 'opacity-100',
        )}
      >
        <div className="h-0 w-0 border-l-4 border-r-4 border-b-4 border-transparent border-b-black" />
        <div className="space-y-2 rounded bg-black p-2 text-center font-mono text-sm text-white">
          <div>
            <div>{time}</div>
            {word && <div className="text-xs">(&ldquo;{word}&rdquo;)</div>}
          </div>
          {gestureStartX == null && isFocused && (
            <div className="space-y-2 whitespace-nowrap text-xs text-gray-300">
              <div className="flex items-center justify-center space-x-1.5">
                <div>Use</div>
                <Key>
                  <ArrowLeft size={12} />
                </Key>
                <Key>
                  <ArrowRight size={12} />
                </Key>
                <div>to nudge</div>
              </div>
              <div className="flex items-center justify-center space-x-1.5">
                <div>Press</div>
                <Key className="!w-auto px-1">space</Key>
                <div>to {isPlaying ? 'pause' : 'play'}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Key: FC<{className?: string}> = ({children, className}) => {
  return (
    <div
      className={classNames(
        'flex h-4 w-4 items-center justify-center rounded bg-gray-700',
        className,
      )}
    >
      {children}
    </div>
  );
};

export const Storyboard = ({
  storyboardData,
}: {
  storyboardData: StoryboardThumbnailTilesData;
}) => {
  const [ref, size] = useWatchElementSize<HTMLDivElement>();

  const scale = size.height / storyboardData.tile_height;
  const scaledTileWidth = storyboardData.tile_width * scale;
  const tileCount = size.width / scaledTileWidth;
  const tiles = distributedCopy(storyboardData.tiles, tileCount);

  return (
    <div
      ref={ref}
      className="absolute inset-0 flex space-x-[2px] overflow-hidden rounded-md bg-gray-100"
    >
      {tiles.map((tile, index) => (
        <div
          key={index}
          style={{height: size.height}}
          className="relative flex-1 overflow-hidden"
        >
          <div
            className="absolute top-0 left-0 origin-top-left"
            style={{
              backgroundImage: `url(${storyboardData.url})`,
              backgroundPositionX: `${tile.x}px`,
              backgroundPositionY: `${-tile.y}px`,
              width: `${storyboardData.tile_width}px`,
              height: `${storyboardData.tile_height}px`,
              transform: `scale(${
                size.width / Math.floor(tileCount) / storyboardData.tile_width
              })`,
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};
