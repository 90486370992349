// Scene TODO (jacques): Analytics prop

import {
  PropsWithChildren,
  MouseEvent as ReactMouseEvent,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {Icon} from 'react-feather';
import {Kbd} from './Kbd';
import ReactTooltip from 'react-tooltip';
import {Spinner} from './Spinner';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import {useIsMountedRef} from 'features/Common/utils';
import {useMemoOne} from 'use-memo-one';
import {v4} from 'uuid';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'inline';
type Event = ReactMouseEvent<HTMLButtonElement, MouseEvent>;

type AnalyticsConfig = {event: string; properties?: Object; options?: Object};

export type ButtonProps = PropsWithChildren<{
  variant?: ButtonVariant;
  size?: 'large' | 'small';
  onClick?: (event: Event) => any;
  label?: string;
  negativeMargin?: boolean;
  iconOnly?: boolean;
  fullWidth?: boolean;
  destructive?: boolean;
  leftIcon?: Icon;
  rightIcon?: Icon;
  loading?: boolean;
  noPadding?: boolean;
  shortcut?: string;
  overrideColor?: string;
  trackClick?: string | AnalyticsConfig;
  disabled?: boolean;
  type?: 'submit' | 'button';
  preventDoubleClick?: boolean;
  alignText?: 'center' | 'left' | 'right' | 'between';
  /** @private */
  _className?: string;
  /** @private */
  _px?: number;
  /** @private */
  _py?: number;
}>;

const buttonVariantStyles: Record<ButtonVariant, string> = {
  primary: 'bg-indigo-600 hover:bg-indigo-700 text-white shadow-sm',
  secondary: 'bg-indigo-50 text-indigo-600 hover:bg-indigo-100',
  tertiary:
    'text-gray-600 hover:text-gray-700 !bg-white hover:!bg-gray-50 !border-gray-200 shadow-sm',
  inline:
    'text-gray-600 hover:text-gray-700 bg-opacity-0 bg-indigo-600 focus:bg-opacity-5 hover:bg-opacity-5 focus:text-indigo-600 hover:text-indigo-600',
};

const destructiveStyles: Record<ButtonVariant, string> = {
  primary: 'bg-red-600 hover:bg-red-700',
  secondary: 'bg-red-50 text-red-600 hover:bg-red-100',
  tertiary: 'text-red-600 hover:text-red-700',
  inline: 'text-red-600 bg-red-600 focus:text-red-700 hover:text-red-700',
};

const smallStyle = '!text-sm !leading-4';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const context = useContext(ButtonPropsContext);

  const {
    children,
    iconOnly = false,
    variant = 'primary',
    onClick: onClickProp,
    size = 'large',
    label,
    negativeMargin = false,
    noPadding = false,
    fullWidth = false,
    destructive = false,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    loading: loadingProp = false,
    shortcut,
    trackClick,
    disabled,
    preventDoubleClick = true,
    _px,
    _py,
    overrideColor,
    alignText = 'center',
    type = 'button',
  } = {...context?.props, ...props};

  const _className = classNames(
    props._className,
    context?.props?._className,
    context?.variantStyles?.[variant],
  );

  let px = 3.5;
  let py = 1.9;
  if (size === 'small') {
    px = 3;
    py = 2;
  }
  if (_px !== undefined) {
    px = _px;
  }
  if (_py !== undefined) {
    py = _py;
  }

  const iconSize = size === 'large' ? 20 : 16;

  const {loading, onClick} = useOnClickLoading({
    loadingProp,
    onClickProp,
    onResolve: () => {
      if (!trackClick) return;

      if (typeof trackClick === 'string') {
        window.analytics.track(trackClick);
      } else {
        window.analytics.track(
          trackClick.event,
          trackClick.properties,
          trackClick.options,
        );
      }
    },
  });

  const debouncedOnClick = useDebounce(preventDoubleClick, onClick);

  const tooltipId = useMemo(() => v4(), []);

  const internalRef = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (disabled && internalRef.current) {
      internalRef.current.blur();
    }
  }, [disabled]);

  return (
    <button
      className={classNames(
        'relative overflow-hidden rounded-md border border-transparent text-sm font-medium filter transition focus:outline-none',
        variant !== 'inline' &&
          'ring-0 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
        buttonVariantStyles[variant],
        size === 'small' && smallStyle,
        fullWidth && 'w-full',
        destructive && destructiveStyles[variant],
        destructive && 'focus:ring-red-500',
        _className,
        (loading || disabled) && 'pointer-events-none grayscale focus:ring-0',
      )}
      style={{
        padding: !noPadding ? `${py * 0.25}rem ${px * 0.25}rem` : '',
        margin: negativeMargin ? `-${py * 0.25}rem -${px * 0.25}rem` : '',
        color: overrideColor,
      }}
      onClick={disabled ? undefined : debouncedOnClick}
      aria-label={label}
      ref={element => {
        if (typeof ref === 'function') {
          ref(element);
        } else if (ref && typeof ref === 'object') {
          ref.current = element;
        }

        internalRef.current = element;
      }}
      data-tip=""
      data-for={tooltipId}
      onKeyDown={event => {
        // Prevent space hotkeys from triggering if the user
        // is "clicking" the button with the spacebar.
        if (event.key === ' ') {
          event.stopPropagation();
        }
      }}
      aria-busy={loading}
      aria-live="polite"
      type={type}
    >
      <div
        className={classNames(
          'flex h-full w-full items-center transition-opacity',
          alignText === 'center' && 'justify-center',
          alignText === 'left' && 'justify-start',
          alignText === 'right' && 'justify-end',
          alignText === 'between' && 'justify-between space-x-2',
          loading && 'opacity-0',
          !iconOnly && 'space-x-2',
        )}
      >
        {LeftIcon && <LeftIcon style={{width: iconSize, height: iconSize}} />}
        <div>{children}</div>
        {RightIcon && <RightIcon style={{width: iconSize, height: iconSize}} />}
      </div>
      <div
        className={classNames(
          'pointer-events-none absolute inset-0 flex items-center justify-center transition-opacity',
          !loading && 'opacity-0',
        )}
      >
        <Spinner size={size === 'large' ? 20 : 16} />
      </div>
      <Tooltip id={tooltipId} shortcut={shortcut} label={label} />
    </button>
  );
});

const Tooltip = ({
  id,
  shortcut,
  label,
}: {
  id: string;
  shortcut?: string;
  label?: string;
}) => {
  return (
    <ReactTooltip
      id={id}
      className="bg-gray-600 !p-1.5 text-sm font-semibold"
      effect="solid"
      getContent={() => {
        if (!shortcut) return null;

        return (
          <div className="space-y-1.5" id="playpause">
            {label && <div className="leading-4">{label}</div>}
            {shortcut && (
              <div>
                <Kbd size="small">{shortcut}</Kbd>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

const useOnClickLoading = ({
  loadingProp,
  onClickProp,
  onResolve,
}: {
  loadingProp: boolean;
  onClickProp?: (event: Event) => unknown;
  onResolve: () => void;
}) => {
  const isMounted = useIsMountedRef();
  const [onClickLoading, setOnClickLoading] = useState(false);

  const onClick = async (event: Event) => {
    if (!onClickProp) {
      onResolve();
      return;
    }

    const maybeThenable = onClickProp(event);

    if (
      typeof maybeThenable === 'object' &&
      maybeThenable !== null &&
      'then' in maybeThenable
    ) {
      setOnClickLoading(true);
      try {
        await maybeThenable;
        onResolve();
        // eslint-disable-next-line no-empty
      } catch {}

      if (!isMounted.current) return;
      setOnClickLoading(false);
    } else {
      onResolve();
    }
  };

  const loading = loadingProp || onClickLoading;

  return {onClick, loading};
};

function useDebounce<T extends (...args: any) => any>(enabled: boolean, fn: T) {
  const latestFn = useRef(fn);
  useLayoutEffect(() => {
    latestFn.current = fn;
  }, [fn]);

  return useMemoOne(() => {
    const wrappedLatestFn = (...args: any) => {
      latestFn.current(...args);
    };

    if (!enabled) {
      return wrappedLatestFn;
    }

    return debounce(wrappedLatestFn, 300, {leading: true, trailing: false});
  }, [enabled]);
}

export const ButtonPropsContext =
  createContext<{
    props?: Partial<ButtonProps>;
    variantStyles?: Partial<Record<ButtonVariant, string>>;
  } | null>(null);
