import classNames from 'classnames';
import logo from 'pages/PreviewRender/components/logo-square.png';

export default function MilkLogo({
  width = 35,
  height = 35,
  widthClass = 'w-20',
}: {
  width?: number;
  height?: number;
  widthClass?: string;
}) {
  return (
    <img
      style={{height: height, width: width}}
      className={classNames(' bg-none', widthClass)}
      src={logo}
      alt="logo"
    />
  );
}
