import {ActionCableContext} from 'AppProviders';
import cableSubscriptions from 'cableSubscriptions/cableSubscriptions';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {authSettingsAndAccountSelector} from 'features/selectors/authSelectors';
import {ReactElement, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

/**
 * AccountCableSubscribeWrap
 *
 * Setup websocket connection based on Account data
 */

export const AccountCableSubscribeWrap = ({
  children,
  loadingComponent,
}: {
  children: ReactElement;
  loadingComponent?: ReactElement;
}) => {
  const {authenticatedWithApi, isFetchingInitialApiRequest, account, user} = useSelector(
    authSettingsAndAccountSelector,
  );

  const cable = useContext(ActionCableContext);
  const {getAccessTokenSilently} = useAuthAlt();

  const dispatch = useDispatch();

  /**
   * This corresponds to UserNotifcation in the rails app
   */
  useEffect(() => {
    if (!account || !user || isFetchingInitialApiRequest || !authenticatedWithApi) {
      return;
    }

    const email = user.email;

    const subscriptionTarget = {
      channel: 'UserUpdateChannel',
      email,
    };

    const subscriptionOptions = cableSubscriptions(
      dispatch,
      getAccessTokenSilently,
      user,
    );

    const subscription = cable?.subscriptions?.create(
      subscriptionTarget,
      subscriptionOptions,
    );

    return () => {
      subscription?.unsubscribe();
    };
  }, [
    account,
    authenticatedWithApi,
    cable?.subscriptions,
    dispatch,
    getAccessTokenSilently,
    isFetchingInitialApiRequest,
    user,
  ]);

  return children;
};
