import {CalculatedScene} from '../CanvasTime/useScenes';
import {canvasScenesZindex} from './canvasSceneConstants';
import classNames from 'classnames';
import {convertToSec} from 'features/EditorCanvas/utils';
import {usePlayback} from '../CanvasTime/usePlayback';
import {MouseEvent, ReactNode, useRef, useState} from 'react';
import {useTimeSelector} from '../CanvasTime/useTimeSelector';

const getProgress = (scene: CalculatedScene, time: number) => {
  if (time < scene.startTimeMs) return 0;
  if (time > scene.endTimeMs) return 100;
  return ((time - scene.startTimeMs) / scene.duration) * 100;
};

export const MiniTimeline = ({
  scene,
  children,
}: {
  scene: CalculatedScene;
  children: ReactNode;
}) => {
  const {isPlaying, seekTo} = usePlayback();
  const duration = `${Math.round(convertToSec(scene.duration))}s`;

  const progress = useTimeSelector(
    ({currentTime}) => getProgress(scene, currentTime),
    [scene],
  );

  const isSceneActive = progress < 100 && progress > 0;

  const container = useRef<HTMLDivElement>(null);

  const [hover, setHover] = useState<number | null>(null);

  const getXPercentage = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (!container.current) return;

    const offsetX = event.clientX - container.current.getBoundingClientRect().left;
    return offsetX / container.current.clientWidth;
  };

  return (
    <div
      className={classNames(
        'align-center relative flex h-full w-full cursor-[ew-resize] flex-col justify-center text-xs font-semibold',
        `z-${canvasScenesZindex.MiniTimeline}`,
      )}
      aria-label="Scene length"
      ref={container}
      onClick={event => {
        const percentage = getXPercentage(event);
        if (percentage == null) return;

        event.stopPropagation();

        const timeMs = scene.startTimeMs + percentage * scene.duration;
        seekTo({timeMs});

        window.analytics.track('scenes_timeline_seek');
      }}
      onMouseMove={event => {
        const percentage = getXPercentage(event);
        if (percentage == null) return;
        setHover(percentage);
      }}
      onMouseLeave={() => setHover(null)}
    >
      <div className="relative w-full bg-none">
        <div className="h-3 pb-2" />
      </div>
      <div
        className={classNames(
          'absolute inset-0 h-2 rounded-full bg-opacity-50 text-white transition',
          isPlaying ? 'bg-indigo-500' : 'bg-gray-400',
        )}
        style={{clipPath: `inset(0 ${100 - progress}% 0 0)`}}
      />
      {isSceneActive && (
        <div
          className={classNames(
            'pointer-events-none absolute top-0 left-0 h-full w-0.5 bg-gray-500',
            `z-${canvasScenesZindex.MiniTimelineSceneActive}`,
          )}
          style={{left: `${100 - (100 - progress)}%`}}
        />
      )}
      {hover != null && (
        <div
          className={classNames(
            'pointer-events-none absolute top-0 left-0 h-full w-0.5 bg-gray-500',
            `z-${canvasScenesZindex.MiniTimelineSceneActive}`,
          )}
          style={{left: `${100 - (100 - hover * 100)}%`}}
        />
      )}
      <span
        className={classNames(
          'absolute bottom-2 left-2 rounded-md bg-gray-800 px-2 text-white opacity-0 transition-opacity duration-75 group-hover:opacity-100',
        )}
      >
        {duration}
      </span>
      {children}
    </div>
  );
};
