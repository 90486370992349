import { ItemsLoading, allItemsLoaded } from 'features/EditorCanvas/utils';
import { StandaloneCanvas, StandaloneCanvasContainer } from './StandaloneCanvas';
import { createContext, useEffect, useLayoutEffect, useMemo } from 'react';

import { isScreenshot } from 'constants/environment';
import { useParams } from 'react-router-dom';
import { usePlayback } from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import { useScenes } from 'features/EditorCanvas/components/CanvasTime/useScenes';

const urlParams = new URLSearchParams(window.location.search);
const startTimeMs = urlParams.get('snapshot_start_time_ms');

const timeToLoadMs = startTimeMs ? parseFloat(startTimeMs) : 0;
const shouldShowScreenshot = isScreenshot();
const screenshotProperties = {
  showScreenshot: shouldShowScreenshot,
  timeToLoadMs,
};

export const ScreenshotContext = createContext(screenshotProperties);

const WaitForItems = () => {
  const { seekTo } = usePlayback();
  const { activeScene } = useScenes();

  useLayoutEffect(() => {
    seekTo({ timeMs: timeToLoadMs });
  }, [seekTo]);

  const activeSceneItems = activeScene?.items;

  const activeSceneItemsLoading = useMemo(() => {
    const itemsLoading: ItemsLoading = {};

    console.log('activeSceneItems', activeSceneItems)

    if (activeSceneItems) {
      Object.keys(activeSceneItems).forEach((itemId) => {
        if (activeSceneItems[itemId].loading) {
          itemsLoading[itemId] = {
            loading: activeSceneItems[itemId].loading,
            viewType: activeSceneItems[itemId].viewType,
            itemId: itemId
          }
        }
      });
    }


    return itemsLoading
  }, [activeSceneItems])

  // if (!window.readyForScreenshot && activeScene) {
  //   console.log('---');
  //   console.log(
  //     'activeSceneItems',
  //     JSON.stringify(
  //       Object.entries(activeSceneItems).map(itemEntry => {
  //         const [id, item] = itemEntry;
  //         return {
  //           id: id,
  //           itemType: item.viewType,
  //           loading: item.loading,
  //           item: item,
  //         };
  //       }),
  //     ),
  //   );

  //   console.log(
  //     'activeSceneItems',

  //     Object.entries(activeSceneItems).map(itemEntry => {
  //       const [id, item] = itemEntry;
  //       return {
  //         id: id,
  //         itemType: item.viewType,
  //         loading: item.loading,
  //         item: item,
  //       };
  //     }),
  //   );
  // }

  useEffect(() => {
    if (!activeScene) {
      return;
    }
    const ready = allItemsLoaded(activeSceneItemsLoading);
    if (ready) {
      window.readyForScreenshot = ready;
      console.log('window.readyForScreenshot', window.readyForScreenshot);
    }
  }, [activeScene, activeSceneItemsLoading]);

  return null;
};

export function ScreenshotPage() {
  const { projectId } = useParams<{ projectId: string }>();

  return (
    <ScreenshotContext.Provider value={screenshotProperties}>
      <StandaloneCanvasContainer projectId={projectId!}>
        <StandaloneCanvas />
        <WaitForItems />
      </StandaloneCanvasContainer>
    </ScreenshotContext.Provider>
  );
}
