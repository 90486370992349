import DashboardUploadDetailWrapper from 'features/Dashboard/DashboardUploadDetailWrapper';

export function DashboardUploadDetailPage() {
  return (
    <div className="mx-auto flex  h-screen w-full flex-col  items-center justify-center bg-white">
      <main className="z-20 mx-auto flex w-full flex-grow overflow-auto">
        <DashboardUploadDetailWrapper />
      </main>
    </div>
  );
}
