import {Dispatch, forwardRef, SetStateAction} from 'react';
import {LandingPageComponent} from 'services/landingPageAPI';
import {PageListItemCard} from '../LandingPageCreate/PageListItemCard';
import ComponentsEmptyState from './ComponentsEmptyState';
import {DraggableListWrap} from './DraggableListWrap';
import {LandingPageHead} from './LandingPageHead';

export const DraggableItemsList = forwardRef<
  HTMLDivElement,
  {
    landingPageId: string;
    itemsList: LandingPageComponent[];
    openModalHandler: () => any;
  }
>(({landingPageId, itemsList, openModalHandler}, ref) => {
  return (
    <div
      className="mx-auto mt-6 flex max-w-xl flex-col items-center space-y-4 rounded-lg"
      ref={ref}
    >
      <LandingPageHead landingPageId={landingPageId} />
      {itemsList.length > 0 ? (
        <DraggableListWrap landingPageId={landingPageId} itemsList={itemsList}>
          {props => {
            return (
              <div className="group" data-index={props.index}>
                <PageListItemCard
                  landingPageId={landingPageId}
                  state={props.tempRowState}
                  setState={props.setTempRowState}
                  item={props.item}
                  isDragging={props.isDragging}
                />
              </div>
            );
          }}
        </DraggableListWrap>
      ) : (
        <ComponentsEmptyState openModalHandler={openModalHandler} />
      )}
    </div>
  );
});
