import { ReactElement, useEffect } from 'react';

import { authSettingsAndAccountSelector } from 'features/selectors/authSelectors';
import { env } from 'constants/environment';
import { identify } from 'features/Common/tracking';
import { useSelector } from 'react-redux';

/**
 * IdentifyApiAccountWrap
 *
 * Use the identify tracking based on account details.
 */

export const IdentifyApiAccountWrap = ({ children }: { children: ReactElement }) => {
  const { account, plan, user, accountUsers } = useSelector(authSettingsAndAccountSelector);

  const currentAccount = account || null;

  useEffect(() => {
    if (currentAccount && user) {
      const uniqueId = user.email;
      if (uniqueId && env === 'production') {
        identify(uniqueId, {
          company: currentAccount.name,
          company_plan_active: plan?.status === 'active',
          company_plan_name: plan?.name,
          company_plan_seats: plan?.quantity,
          company_website: currentAccount?.company_domain,
          traits: {
            clientOrigin: window.todesktop ? 'desktop-app' : 'web',
          },
        });

        if (window.Intercom) {
          window.Intercom('update', {
            user_id: uniqueId,
            email: uniqueId,
            name: `${user?.first_name} ${user?.last_name}`,
            created_at: user?.created_at,
            company: {
              company_id: currentAccount.id,
              created_at: currentAccount.created_at,
              name: currentAccount.name,
              plan: plan?.name,
              size: plan?.quantity,
              seats: accountUsers ? accountUsers?.length : 1,
              website: currentAccount?.company_domain,
            }
          });
        }
      }
    }
  }, [currentAccount, plan?.name, plan?.quantity, plan?.status, user]);

  return children;
};
