import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import {
  RecordingFormPrompt,
  RecordingFormPromptInput,
  useUpdateRecordingFormMutation,
} from 'services/recordingFormsAPI';
import { useEffect, useState } from 'react';

import { DraggableListItemType } from 'features/Dashboard/shared/draggableHelper';

const grid = 8;

export const RecordingFormBuilderDetailPromptList = ({
  recordingFormId,
  itemsList,
  children,
}: {
  recordingFormId: string;
  itemsList: RecordingFormPrompt[];
  children: (props: DraggableListItemType) => JSX.Element;
}) => {
  const itemsState = itemsList.map(item => item);
  const [tempRowState, setTempRowState] = useState<RecordingFormPrompt[]>(itemsState);

  const [updateRecordingForm, { isLoading: isUpdating }] = useUpdateRecordingFormMutation();

  const updateOrder = async (
    recordingFormId: string,
    items: RecordingFormPrompt[] | RecordingFormPromptInput,
  ) => {
    await updateRecordingForm({
      id: recordingFormId,
      user_recording_form_prompts_attributes: items as RecordingFormPromptInput[],
    });
  };

  function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    // console.log('result', result);

    // dropped outside the list
    if (!destination) {
      return;
    }

    const items = reorder(itemsList, source.index, destination.index);
    // console.log('items', items);

    setTempRowState(items);
    updateOrder(recordingFormId, items);
  }

  useEffect(() => {
    if (itemsList) {
      setTempRowState(itemsList);
    }
  }, [setTempRowState, itemsList]);

  const reorder = (
    itemsList: RecordingFormPrompt[],
    sourceIndex: number,
    destinationIndex: number,
  ) => {
    const result = Array.from(itemsList);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);
    const resultsWithOrder = result.map((resultItem, index) => ({
      ...resultItem,
      order: index,
    }));

    return resultsWithOrder as RecordingFormPrompt[];
  };

  const getItemStyle = (draggableStyle: any, isDragging: boolean): {} => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: `${grid} ${grid * 2}`,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    // background: isDragging ? '#fff' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'first-row'}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className="w-full">
            {tempRowState.map((item, index) => (
              <Draggable isDragDisabled key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      provided.draggableProps.style,
                      snapshot.isDragging,
                    )}
                  >
                    {children({
                      tempRowState,
                      setTempRowState,
                      item,
                      isDragging: snapshot.isDragging,
                      index,
                    })}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
