import { RecordingFormPrompt, useUpdateRecordingFormMutation } from 'services/recordingFormsAPI';

import EditableRecordingFormDetailPromptField from './EditableRecordingFormDetailPromptField';
import { UserCircleIcon } from '@heroicons/react/solid';

export function RecordingFormTextInput({
  close,
  recordingPrompt,
  recordingFormId,
}: {
  close: () => void;
  recordingFormId: string;
  recordingPrompt: RecordingFormPrompt;
}) {
  const [updateRecordingForm, { isLoading: isUpdating }] = useUpdateRecordingFormMutation();

  const handleDeletePrompt = async (recordingPromptId: string) => {
    await updateRecordingForm({
      id: recordingFormId,
      user_recording_form_prompts_attributes: [{ id: recordingPromptId, _destroy: true }],
    });
    close();
  };

  if (!recordingPrompt) {
    return <div />;
  }
  return (
    <div className="relative w-full space-y-6">
      <div className="item-start flex">
        <div className="flex-shrink-0">
          <UserCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium text-gray-900">Step 1: Add your question</p>
          <p className="mt-1 text-sm text-gray-500">
            Enter a question to prompt your recording form recepient.
          </p>
          <div className="mt-3 overflow-hidden rounded-lg border-2 border-indigo-300 shadow-sm ">
            <div className="bg-white">
              <div className="col-span-full bg-gray-50  p-2 text-left">
                {recordingPrompt.description && (
                  <EditableRecordingFormDetailPromptField
                    recordingFormId={recordingFormId}
                    recordingFormPromptId={recordingPrompt.id}
                    fieldName={'description'}
                    fieldValue={recordingPrompt.description}
                    className="block w-full resize-none border-0 text-lg font-medium placeholder-gray-500 focus:ring-0"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ml-4 flex flex-shrink-0"></div>
      </div>
    </div>
  );
}
