import {formatDistanceToNow} from 'date-fns';
import {Edit, Trash2} from 'react-feather';
import {Link} from 'react-router-dom';
import {LandingPage, useDeleteLandingPageMutation} from 'services/landingPageAPI';
import {IconButton} from 'ui/IconButton';

interface LandingPageProps {
  landingPage: LandingPage;
  key: string;
}
export default function LandingPageCard({landingPage}: LandingPageProps) {
  const [deleteLandingPage, {isLoading: isUpdating}] = useDeleteLandingPageMutation();

  const handleDestroyLandingPage = (landingPage: LandingPage) => {
    window.analytics.track('Submit delete landing page');
    (async () => {
      try {
        deleteLandingPage({id: landingPage.id});
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const trackVisitLandingPage = () => {
    window.analytics.track('Pg-Idx -- LdPgAdm -- Evt-Clk -- Landing Page');
  };

  return (
    <li className="col-span-1 flex flex-col rounded-lg bg-white text-center shadow">
      <div className="flex flex-1 flex-col p-8">
        <h3 className="mt-6 text-sm font-medium text-gray-900">{landingPage.title}</h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dt className="sr-only">Last Updated</dt>
          <dd className="text-sm text-gray-500">
            {landingPage.updated_at &&
              `Updated
              ${formatDistanceToNow(new Date(landingPage.updated_at), {
                includeSeconds: false,
              })} ago`}
          </dd>
        </dl>
      </div>
      <div className="flex items-center justify-between px-2 pb-2">
        <Link to={`/landing-pages/${landingPage.id}`} onClick={trackVisitLandingPage}>
          <IconButton variant="inline" icon={Edit} label="Click to Edit" size="small" />
        </Link>
        <IconButton
          variant="inline"
          destructive
          icon={Trash2}
          onClick={() => {
            handleDestroyLandingPage(landingPage);
          }}
          label="Delete"
          size="small"
        />
      </div>
    </li>
  );
}
