import {GetRootState} from 'configureStore';

export const fontDataSelector = (state: GetRootState) => state.fontData;

export const accountFontsSelector =
  (includeDiscarded = true) =>
  (state: GetRootState) => {
    if (includeDiscarded) {
      return state.fontData.accountFontUploads;
    }

    return state.fontData.accountFontUploads.filter(font => !font.discarded_at);
  };
