import {env} from 'constants/environment';
import * as Sentry from '@sentry/react';

export const identify = (email: string | undefined, traits?: Object | undefined) => {
  if (env !== 'production') {
    console.info(
      'Skipping window.analytics.identify() in non-production environment',
      email,
      traits,
    );
    return;
  }

  if (!email || email.endsWith('@example.com')) {
    window.analytics.identify(traits);
  } else {
    window.analytics.identify(email, traits);
  }

  Sentry.setUser({email: email});
};
