import { LandingPageComponent, useUpdateLandingPageMutation } from 'services/landingPageAPI';
import { Move, Trash2 } from 'react-feather';

import EditableLandingPageField from '../EditableLandingPageField';
import { IconButton } from 'ui/IconButton';

export const SectionCard = ({
  isDragging,
  landingPageId,
  state,
  setState,
  item,
}: {
  landingPageId: string;
  isDragging: boolean;
  item: LandingPageComponent;
  state: any;
  setState: any;
}) => {


  const [updateLandingPage, { isLoading: isUpdating }] = useUpdateLandingPageMutation();

  const handleRemoveSection = async (landingPageComponentId: string) => {
    await updateLandingPage({
      id: landingPageId,
      landing_page_components_attributes: [{ id: landingPageComponentId, _destroy: true }],
    });
  };

  return (
    <>
      <div className="col-span-full text-center">
        <EditableLandingPageField
          landingPageComponentId={item.id}
          landingPageId={landingPageId}
          fieldName={'title'}
          className={'text-4xl font-extrabold text-gray-900'}
          fieldValue={item.title || 'Untitled'}
        />
        <div className="mt-6">
          <h4 className="sr-only">Description</h4>
          <EditableLandingPageField
            landingPageComponentId={item.id}
            landingPageId={landingPageId}
            fieldName={'description'}
            className={'text-base font-bold text-gray-700'}
            fieldValue={item.description}
            placeholder="No description"
          />
        </div>
      </div>

      <div className="col-span-full flex items-center justify-between space-x-1">
        <IconButton
          variant="inline"
          icon={Move}
          label="Drag to re-order"
          size="small"
          _className="!cursor-grab"
        />
        <IconButton
          variant="inline"
          destructive
          icon={Trash2}
          onClick={() => handleRemoveSection(item.id)}
          label="Delete"
          size="small"
        />
      </div>
    </>
  );
};
