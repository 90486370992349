import {LoadingSpinnerInline} from 'features/EditorCanvas/components/shared/LoadingSpinnerInline';
import cn from 'classnames';

export default function CardHeaderButtonActive({
  active = false,
  isConnecting = false,
  activeText = '',
  deactiveText = '',
  onClickToActive = () => {},
  onClickToInactive = () => {},
}: {
  active: boolean;
  isConnecting: boolean;
  activeText?: string;
  deactiveText?: string;
  onClickToActive: () => void;
  onClickToInactive: () => void;
}) {
  const handleClick = () => {
    if (active) {
      onClickToInactive();
    } else {
      onClickToActive();
    }
  };
  return (
    <button
      onClick={handleClick}
      type="button"
      className={cn(
        {
          'bg-indigo-600 text-white hover:bg-indigo-700 hover:shadow-lg':
            !isConnecting && !active,
          'bg-green-100 text-green-600 hover:opacity-80': !isConnecting && active,
          'bg-yellow-500 text-white opacity-75 ring-0 hover:translate-y-0 hover:shadow-none focus:ring-0':
            isConnecting,
        },
        'inline-flex transform items-center rounded-md border border-transparent px-4 py-2 text-base font-semibold transition hover:-translate-y-0.5',
      )}
    >
      {isConnecting && (
        <span className="mr-1">
          <LoadingSpinnerInline isLight={true} />
        </span>
      )}
      {isConnecting ? 'Connecting...' : active ? activeText : deactiveText}
    </button>
  );
}
