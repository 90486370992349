import {Button, ButtonProps} from 'ui/Button';

import {ReactNode} from 'react';

export default function EditableTextField({
  children,
  onSubmit,
  startValue,
  tempFieldValue,
  setTempFieldValue,
  isEditing,
  setIsEditing,
  isSubmitting,
  setIsSubmitting,
  saveButtonProps,
}: {
  tempFieldValue: string;
  isEditing: boolean;
  isSubmitting: boolean;
  setTempFieldValue: any;
  setIsEditing: any;
  setIsSubmitting: any;
  children?: ReactNode;
  startValue: string;
  onSubmit: () => void;
  saveButtonProps?: Partial<ButtonProps>;
}) {
  const handleBlur = () => {
    setIsSubmitting(true);
    setIsEditing(false);
    onSubmit();
  };
  return (
    <span
      style={{
        minWidth: '150px',
      }}
      className="inline-flex w-full rounded-md"
    >
      {isEditing ? (
        <span className="flex w-full flex-row justify-center space-x-2">
          <input
            autoFocus
            onBlur={handleBlur}
            autoCorrect="off"
            value={tempFieldValue}
            onChange={(evt: any) => {
              setTempFieldValue(evt.target.value);
            }}
            className="min-w-30 form-input flex w-full flex-1 rounded-md px-2 text-sm font-medium leading-5 transition duration-150 ease-in-out"
          />
          <Button variant="tertiary" onClick={handleBlur} {...saveButtonProps}>
            Save
          </Button>
        </span>
      ) : (
        <button
          onClick={() => {
            setIsEditing(true);
          }}
          className="focus:ring-blue hidden w-full cursor-text items-center truncate rounded px-2 text-sm font-medium leading-5 text-gray-700 ring-opacity-50 transition duration-150 ease-in-out hover:bg-gray-200 hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800 md:inline-flex"
        >
          <span className="">
            {isSubmitting && tempFieldValue !== '' ? tempFieldValue : startValue}
          </span>
        </button>
      )}
      {!isEditing && children}
    </span>
  );
}
