import {STATUSES_PROJECT_FILE} from 'features/SetupNewProject/constants';
import {createSlice} from '@reduxjs/toolkit';
import {videoDurationApi} from 'api/videoUploadsAPI';

export const INITIAL_PROJECT_FILE_UPLOAD = {
  id: null,
  file: null,
  progress: null,
  duration: null,
  durationLoading: false,
  costTime: 0,
  cost: 0,
  status: 'added',
};

const initialState = {
  uploads: [],
  loading: false,
  error: null,
  projectStartManagedFiles: {},
};

const fileUploadsSlice = createSlice({
  name: 'fileUploads',
  initialState,
  reducers: {
    removeProjectFileUpload(state, action) {
      const {fileId} = action.payload;

      delete state.projectStartManagedFiles[fileId];
    },
    projectFileUploadRemove(state, action) {
      const {file} = action.payload;

      delete state.projectStartManagedFiles[file.id];
    },
    updateProjectFileUploadProgress(state, action) {
      const {file, progress} = action.payload;
      if (state.projectStartManagedFiles[file.id]) {
        state.projectStartManagedFiles[file.id].status = STATUSES_PROJECT_FILE.PROGRESS;
        state.projectStartManagedFiles[file.id].progress = progress;
      }
    },
    newProjectFileUploadSuccess(state, action) {
      const {file, response} = action.payload;

      state.projectStartManagedFiles[file.id] = {
        ...state.projectStartManagedFiles[file.id],
        status: STATUSES_PROJECT_FILE.UPLOADED,
      };
    },
    addNewProjectFileUpload(state, action) {
      const {file} = action.payload;
      const newFile = {
        ...INITIAL_PROJECT_FILE_UPLOAD,
        id: file.id,
        file: {
          name: file.name,
          size: file.size,
          type: file.type,
          meta: file.meta,
          id: file.id,
        },
        progress: null,
        status: STATUSES_PROJECT_FILE.ADDED,
      };
      state.projectStartManagedFiles[file.id] = newFile;
    },
    uploadProjectAddDurationStart(state, action) {
      const {fileId} = action.payload;
      state.projectStartManagedFiles[fileId].durationloading = true;
    },
    uploadProjectAddDurationSuccess(state, action) {
      const {fileId, duration} = action.payload;
      state.projectStartManagedFiles[fileId].duration = duration;
      state.projectStartManagedFiles[fileId].costTime = Math.ceil(duration);
      state.projectStartManagedFiles[fileId].status = STATUSES_PROJECT_FILE.HAS_DURATION;
      state.projectStartManagedFiles[fileId].durationloading = false;
    },
    uploadProjectAddDurationFailure(state, action) {
      const {fileId, err} = action.payload;
      state.projectStartManagedFiles[fileId].durationloading = false;
    },
    addNewUploadedFile(state, action) {
      const {id, source, type, size, lastModified, name, uploadURL} = action.payload;

      state.uploads.push({
        id,
        source,
        type,
        size,
        lastModified,
        name,
        uploadURL,
        status: STATUSES_PROJECT_FILE.PROCESSING,
      });
    },
  },
});

export const {
  addNewProjectFileUpload,
  addNewUploadedFile,
  newProjectFileUploadSuccess,
  projectFileUploadRemove,
  removeProjectFileUpload,
  updateProjectFileUploadProgress,
  uploadProjectAddDurationFailure,
  uploadProjectAddDurationStart,
  uploadProjectAddDurationSuccess,
} = fileUploadsSlice.actions;

export const uploadProjectAddDuration =
  ({token, fileId, bucketName, bucketKey}) =>
  async dispatch => {
    try {
      dispatch(uploadProjectAddDurationStart({fileId}));
      const duration = await videoDurationApi(token, bucketName, bucketKey);
      dispatch(uploadProjectAddDurationSuccess({fileId, duration}));
    } catch (err) {
      dispatch(uploadProjectAddDurationFailure({fileId, err}));
    }
  };

export default fileUploadsSlice.reducer;
