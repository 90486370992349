import {useDispatch, useSelector} from 'react-redux';

import {NumberInput} from 'features/EditorCanvas/components/shared/common/NumberInput';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {updateItemAttribute} from 'features/canvasItemsSlice';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

export const getAbsoluteRotation = (rotation: number) => {
  let absoluteRotation = Math.round(rotation);

  const rotations = Math.abs(Math.floor(absoluteRotation / 360));

  if (absoluteRotation < 0) {
    absoluteRotation = rotations * 360 + absoluteRotation;
  } else if (absoluteRotation > 360) {
    absoluteRotation = absoluteRotation - rotations * 360;
  }

  return absoluteRotation;
};

export default function RotationPicker() {
  const projectId = useProjectId();
  const dispatch = useDispatch();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  if (!selected) return null;

  const setRotation = (rotation: number) => {
    dispatch(
      updateItemAttribute({
        projectId,
        id: selected.id,
        attribute: 'rotation',
        newValue: getAbsoluteRotation(rotation),
      }),
    );
  };

  return (
    <div className="flex">
      <div className="mt-1 flex w-full flex-col pr-6 text-sm leading-5 text-gray-700">
        <div className="flex flex-row">
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label className="mb-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Rotation
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <NumberInput value={selected.item.rotation} onChange={setRotation} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
