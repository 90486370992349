import {BaseItem, TextItem} from 'features/types/canvasItemsSlice';
import {SAMPLE_HEADER, SAMPLE_TEXT} from 'features/EditorCanvas/constants/ItemConstants';

import {Button} from 'ui/Button';
import {FC} from 'react';
import {addCanvasItem} from 'features/canvasItemsSlice';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import {useFetchAccountBrandQuery} from 'services/accountBrandAPI';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';

const Container: FC = ({children}) => {
  return (
    <div className="p-5">
      <div className="space-y-3">{children}</div>
    </div>
  );
};

const logoCardContainer =
  'p-3 ring-0 bg-gray-50 rounded-md hover:bg-indigo-50 focus:bg-indigo-50 cursor-pointer transition flex flex-col justify-center items-stretch';

const logoCardInner = ({square}: {square?: boolean}) =>
  classNames(square ? 'aspect-w-1 aspect-h-1' : 'h-6');

export const TextCardGhost = ({square}: {square?: boolean}) => {
  return (
    <div className={logoCardContainer}>
      <div className={classNames(logoCardInner({square}), 'rounded-md bg-gray-100')} />
    </div>
  );
};

export function TextElements() {
  const {data, isLoading} = useFetchAccountBrandQuery();

  const dispatch = useDispatch();
  const projectId = useProjectId();
  const {activeScene} = useScenes();

  const addNewHeaderTitleItem = () => {
    window.analytics.track('Add new header text');
    // @ts-ignore
    const item = {
      ...SAMPLE_HEADER,
      style: {
        ...SAMPLE_HEADER.style,
        fontSize: '120',
        color: '#333',
        fontFamily: 'Open Sans',
      },
      sceneId: activeScene?.id,
    } as BaseItem & TextItem;

    dispatch(addCanvasItem({projectId, item}));
  };

  const addNewSubheaderTitleItem = () => {
    window.analytics.track('Add new subheader text');
    // @ts-ignore
    const item = {
      ...SAMPLE_HEADER,
      style: {
        ...SAMPLE_HEADER.style,
        fontWeight: 'normal',
        color: '#666',
        fontFamily: 'Open Sans',
      },
      sceneId: activeScene?.id,
    } as BaseItem & TextItem;

    dispatch(addCanvasItem({projectId, item}));
  };

  const addNewBodyTitleItem = () => {
    window.analytics.track('Add new body text');
    // @ts-ignore
    const item = {
      ...SAMPLE_TEXT,
      style: {
        ...SAMPLE_TEXT.style,
        color: '#666',
        fontFamily: 'Open Sans',
      },
      sceneId: activeScene?.id,
    } as BaseItem & TextItem;

    dispatch(addCanvasItem({projectId, item}));
  };

  if (isLoading || !data) {
    return (
      <Container>
        <div className="h-5 animate-pulse bg-gray-50" />
        <TextCardGhost />
        <TextCardGhost />
        <TextCardGhost />
      </Container>
    );
  }

  return (
    <Container>
      <div className="text-xs font-bold text-gray-700">
        Click text to add to the scene
      </div>
      <Button
        onClick={addNewHeaderTitleItem}
        alignText="left"
        fullWidth
        variant="tertiary"
        _className="justify-start items-start w-full flex align-start ml-0 text-left bg-gray-100"
        size="small"
      >
        <div className="flex w-full items-start justify-start text-left text-2xl font-bold">
          Add a heading
        </div>
      </Button>
      <Button
        onClick={addNewSubheaderTitleItem}
        alignText="left"
        fullWidth
        variant="tertiary"
        _className="justify-start items-start flex align-start text-left bg-gray-100"
        size="small"
      >
        <div className="flex w-full items-start justify-start text-left text-base font-medium">
          Add a subheading
        </div>
      </Button>
      <Button
        onClick={addNewBodyTitleItem}
        alignText="left"
        fullWidth
        variant="tertiary"
        _className="justify-start items-start flex align-start text-left bg-gray-100"
        size="small"
      >
        <div className="flex w-full items-start justify-start text-left text-xs font-normal">
          Add a little bit of body text
        </div>
      </Button>
    </Container>
  );
}
