import {useEffect, useRef} from 'react';

import {BASE_SHARE_URL} from 'constants/environment';
import equals from 'fast-deep-equal';
import {useLandingPageQuery} from 'services/landingPageAPI';
import {useWatchElementSize} from 'features/Common/useElementSize';

export const LandingPagePreview = ({landingPageId}: {landingPageId: string}) => {
  const {ref, height, scale} = useScaleIframe();
  const iframeRef = useRefreshIframe(landingPageId);

  return (
    <div ref={ref} className="h-full">
      <div className="relative h-full overflow-hidden border-l border-r border-gray-600">
        <iframe
          src={`${BASE_SHARE_URL}/p/${landingPageId}?iframe=true`}
          style={{width: IFRAME_WIDTH, height, transform: `scale(${scale})`}}
          className="absolute top-0 left-0 h-full origin-top-left"
          ref={iframeRef}
        />
      </div>
    </div>
  );
};

const IFRAME_WIDTH = 800;

const useScaleIframe = () => {
  const [ref, size] = useWatchElementSize<HTMLDivElement>();

  let scale = 0;
  if (size) scale = size.width / IFRAME_WIDTH;

  let height = 0;
  if (size) height = size.height / scale;

  return {ref, scale, height};
};

const useRefreshIframe = (landingPageId: string) => {
  const iframe = useRef<HTMLIFrameElement>(null);

  const {data: landingPage} = useLandingPageQuery({id: landingPageId});

  const currentLandingPage = useRef(landingPage);
  useEffect(() => {
    if (!iframe.current) return;

    if (!equals(currentLandingPage.current, landingPage)) {
      iframe.current.src += '';
    }

    currentLandingPage.current = landingPage;
  }, [landingPage]);

  return iframe;
};
