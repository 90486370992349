import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AccountFontUploadsType} from './types/userLibrarySlice';

export type FontDataItemType = {
  family: string;
  variants: string[];
  subsets: string[];
  version: string;
  lastModified: string;
  files: Record<string, string>;
  category: string;
};

export type ExternalFontLoaderFont = {
  family: string;
  source: string;
  variants: (string | number)[];
  previews: Record<string, string>;
};

export type FontDataType = {
  accountFontUploads: AccountFontUploadsType[];
  uploadingFontFile: boolean;
};

export const initialState = {
  accountFontUploads: [],
  uploadingFontFile: false,
} as FontDataType;

const fontDataSlice = createSlice({
  name: 'fontData',
  initialState,
  reducers: {
    setAccountFontUploads(
      state,
      action: PayloadAction<{accountFontUploads: AccountFontUploadsType[]}>,
    ) {
      state.accountFontUploads = action.payload.accountFontUploads;
    },
  },
});

export const {setAccountFontUploads} = fontDataSlice.actions;

export default fontDataSlice.reducer;
