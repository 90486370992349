import DashboardUploadDetails from './DashboardUploadDetails/DashboardUploadDetails';
import {useEffect} from 'react';

function DashboardUploadDetailWrapper() {
  useEffect(() => {
    !!window.Intercom && window.Intercom('hide');
  }, []);
  return <DashboardUploadDetails />;
}

export default DashboardUploadDetailWrapper;
