import {DashParams, routePaths} from 'routes/routesHelper';
import {Link, useParams} from 'react-router-dom';
import {useFetchCmsTemplatesQuery, useFetchTemplatesQuery} from 'services/templatesAPI';

import {DashboardContentSkeleton} from 'features/Dashboard/common/DashboardContentSkeleton';
import {DashboardHead} from 'features/Dashboard/common/DashboardHead';
import {TemplateBrowserContainer} from './TemplateBrowserContainer';
import TemplatesGrid from './TemplatesGrid';
import classNames from 'classnames';
import {convertSanityEntryToTemplate} from 'features/SanityCms/helpers';
import {sanityImage} from 'features/SanityCms/sanityClient';
import useDimensions from 'react-use-dimensions';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useMemo} from 'react';
import {useWindowHeight} from '@react-hook/window-size';

export function TemplatesBrowser() {
  const {categoryId} = useParams() as Record<string, string>;
  const {data: cmsData} = useFetchCmsTemplatesQuery();
  const {data: milkApiTemplates} = useFetchTemplatesQuery();

  const isCmsTemplatesActive = useFeatureFlag('cms-templates');

  const milkTemplates =
    (isCmsTemplatesActive
      ? cmsData?.templates?.map(data => convertSanityEntryToTemplate(data))
      : milkApiTemplates) ?? [];

  const categories = [...(cmsData?.categories ?? [])];

  // Tag for filtering
  const heroCategory =
    categories.find(currCategory => currCategory.slug === categoryId) ?? categories[0];

  const windowHeight = useWindowHeight();
  const [headRef, {height: toolbarHeight}] = useDimensions();

  const templateTableHeight = useMemo(() => {
    return windowHeight - toolbarHeight;
  }, [toolbarHeight, windowHeight]);

  // console.log('templateTableHeight', templateTableHeight);
  // console.log('toolbarHeight', toolbarHeight);
  // console.log('windowHeight', windowHeight);

  return (
    <DashboardContentSkeleton isLoading={false}>
      <div ref={headRef}>
        <DashboardHead title={'Templates'} />
      </div>
      <div>
        <TemplateBrowserContainer>
          <main
            style={{height: `${templateTableHeight}px`}}
            className="flex-1 overflow-auto pr-4 focus:outline-none"
            tabIndex={0}
          >
            <div className="relative ">
              <div className="space-y-8 px-4 py-6 sm:px-6 md:px-0">
                <TemplatesGrid templates={milkTemplates} activeCategory={categoryId} />
              </div>
            </div>
          </main>
        </TemplateBrowserContainer>
      </div>
    </DashboardContentSkeleton>
  );
}
