import {CSSProperties, useEffect} from 'react';
import {updateCanvasItemDimensions, updateItemAttribute} from 'features/canvasItemsSlice';
import {useDispatch, useSelector} from 'react-redux';

import ItemLayerText from './ItemLayerText';
import {canvasStateSelector} from 'features/selectors/canvasStateSelectors';
import {usePlayback} from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useSceneTiming} from '../../CanvasTime/useSceneTiming';
import {useSelectionFocus} from '../../SelectionFocusProvider';

export type ItemLayerTextContainerProps = {
  id: string;
  style: CSSProperties;
  text: string;
  onChangeSize?: () => void;
  isPreview?: boolean;
};

export default function ItemLayerTextContainer({
  id,
  text,
  style,
  onChangeSize,
  isPreview,
}: ItemLayerTextContainerProps) {
  const dispatch = useDispatch();
  const projectId = useProjectId();
  const canvasEditMode = useSelector(canvasStateSelector)?.canvasEditMode;

  const {sceneTimingStyle} = useSceneTiming(id);
  const {isPlaying} = usePlayback();

  const {setIsItemLayerTextActive} = useSelectionFocus();

  // block arrow key movement of canvas item when editing text
  useEffect(() => {
    setIsItemLayerTextActive(!!canvasEditMode);
  }, [canvasEditMode]);

  return (
    <ItemLayerText
      style={{...style, ...sceneTimingStyle}}
      initialText={text}
      editModeText={canvasEditMode === 'text'}
      onChangeText={newValue => {
        dispatch(
          updateItemAttribute({
            projectId,
            id,
            attribute: 'text',
            newValue,
          }),
        );
      }}
      onChangeSize={dimension => {
        if (isPlaying) return;

        onChangeSize?.();
        dispatch(updateCanvasItemDimensions({projectId, id, dimension}));
      }}
      isPreview={isPreview}
    />
  );
}
