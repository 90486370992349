import {paymentTerms} from 'constants/en';

export enum PlanInterval {
  Monthly = 'month',
  Yearly = 'year',
}

export type PaymentPageType = {
  name: string;
  destination: PaymentPlanStepType;
  url: string;
};

export enum PaymentPlanStepType {
  SetPlan = 'setPlan',
  PaymentInput = 'paymentInput',
  Review = 'review',
  SignupComplete = 'signupComplete',
}

export enum PaymentPlanType {
  Professional = 'professional',
  Creative = 'creative',
  Concierge = 'concierge',
}

export const paymentPages = {
  [PaymentPlanStepType.SetPlan]: {
    name: `Adjust ${paymentTerms.paidUserType}s`,
    destination: PaymentPlanStepType.PaymentInput,
    url: '/payments/plan',
    cta: 'Next: Payment information',
  },
  [PaymentPlanStepType.PaymentInput]: {
    name: 'Payment information',
    destination: PaymentPlanStepType.Review,

    url: '/payments/information',
    cta: 'Next: Review',
  },
  [PaymentPlanStepType.Review]: {
    name: 'Review',
    destination: PaymentPlanStepType.SignupComplete,
    url: '/payments/review',
    cta: 'Complete purchase',
  },
  [PaymentPlanStepType.SignupComplete]: {
    name: '',
    destination: PaymentPlanStepType.SignupComplete,
    url: '/',
    cta: '',
  },
};

export const planTerms = {
  seats: {
    [PaymentPlanType.Professional]: {
      min: 1,
    },
    [PaymentPlanType.Creative]: {
      min: 5,
    },
    [PaymentPlanType.Concierge]: {
      min: 10,
    },
  },
};

export const moneyTerms = {
  planSeatPrice: {
    [PaymentPlanType.Professional]: {
      month: Number(
        (99 / planTerms.seats[PaymentPlanType.Professional].min).toPrecision(2),
      ),
      year: Number(
        (79 / planTerms.seats[PaymentPlanType.Professional].min).toPrecision(2),
      ),
    },
    [PaymentPlanType.Creative]: {
      month: Number((360 / planTerms.seats[PaymentPlanType.Creative].min).toPrecision(2)),
      year: Number((300 / planTerms.seats[PaymentPlanType.Creative].min).toPrecision(2)),
    },
    [PaymentPlanType.Concierge]: {
      year: Number((700 / planTerms.seats[PaymentPlanType.Concierge].min).toPrecision(2)),
    },
  },
};

export enum UserPaymentPreferences {
  ViewedPaymentModal = 'viewed_payment_modal',
}
