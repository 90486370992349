export const steps = [
  'template',
  'upload',
  'design',
  'clip',
  'preview',
  'download',
] as const;

export type Step = typeof steps[number];

export const sizes = {
  square: {
    id: 'square',
    label: 'Square',
    dimensions: {width: 1080, height: 1080},
    defaultVideoMode: 'contain',
    aspectRatio: 'aspect-w-1 aspect-h-1',
  },
  landscape: {
    id: 'landscape',
    label: 'Landscape',
    dimensions: {width: 1920, height: 1080},
    defaultVideoMode: 'cover',
    aspectRatio: 'aspect-w-16 aspect-h-9',
  },
  portrait: {
    id: 'portrait',
    label: 'Portrait',
    dimensions: {width: 1080, height: 1920},
    defaultVideoMode: 'cover',
    aspectRatio: 'aspect-w-9 aspect-h-16',
  },
} as const;

export type SizeId = keyof typeof sizes;
export type Size = typeof sizes[SizeId];

export const videoStyles = {
  contain: {
    id: 'contain',
    label: 'Contain',
  },
  cover: {
    id: 'cover',
    label: 'Cover',
  },
  background: {
    id: 'background',
    label: 'Background',
  },
} as const;

export type StyleId = keyof typeof videoStyles;
export type Style = typeof videoStyles[StyleId];

export type ColorScheme = {
  background: string;
  square: string;
  text: string;
  captions: string;
  captionsActive: string;
};

export const colorSchemes: Record<string, ColorScheme> = {
  gray: {
    background: '#111827',
    square: '#374151',
    text: '#FFFFFF',
    captions: '#9CA3AF',
    captionsActive: '#FFFFFF',
  },
  red: {
    background: '#7F1D1D',
    square: '#991B1B',
    text: '#FFFFFF',
    captions: '#E14E4E',
    captionsActive: '#FFFFFF',
  },
  orange: {
    background: '#D97706',
    square: '#B45309',
    text: '#FFFFFF',
    captions: '#FDE68A',
    captionsActive: '#FFFFFF',
  },
  green: {
    background: '#065F46',
    square: '#047857',
    text: '#FFFFFF',
    captions: '#A7F3D0',
    captionsActive: '#FFFFFF',
  },
  blue: {
    background: '#1E3A8A',
    square: '#1D4ED8',
    text: '#FFFFFF',
    captions: '#C4C4C4',
    captionsActive: '#FFFFFF',
  },
  pink: {
    background: '#BE185D',
    square: '#DB2777',
    text: '#FFFFFF',
    captions: '#F9A8D4',
    captionsActive: '#FFFFFF',
  },
};

export type DesignStateCommon = {
  size: SizeId;
  logo?: string;
  colorScheme: ColorScheme;
  showCaptions?: boolean;
  title?: string;
  secondaryTitle?: string;
};

export type DesignStateVideo = {
  type: 'video';
  style: StyleId;
};

export type DesignStateAudio = {
  type: 'audio';
  style?: undefined;
};

export type DesignState = DesignStateCommon & (DesignStateVideo | DesignStateAudio);

export const defaultDesign: DesignState = {
  type: 'audio',
  size: 'square',
  colorScheme: colorSchemes.gray,
  showCaptions: true,
  title: 'Title goes here',
  secondaryTitle: 'You can add a secondary title here',
};
