import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';

import { CreateProjectModal } from 'features/Dashboard/common/CreateProjectModal';
import { DashboardProvider } from 'features/Dashboard/DashboardPage/DashboardContext';
import { DashboardWrapWithSidebar } from 'features/Dashboard/DashboardWrapWithSidebar';
import { FileNavContainer } from 'features/Dashboard/DashboardPage/ContentUploads/FileNavProvider';
import { FileNavModals } from 'features/Dashboard/DashboardPage/ContentUploads/FileNav';
import { FileNavProvider } from 'features/Dashboard/DashboardPage/ContentUploads/FileNavActiveUploadProvider';
import { ProjectModalProvider } from 'features/Dashboard/useProjectModal';

export function DashboardPageWrapper() {
  const location = useLocation();

  useEffect(() => {
    window.analytics.track('Dashboard page opened', {
      display_content: location.pathname,
    });
  }, [location.pathname]);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <DashboardProvider>
      <DashboardWrapWithSidebar isMinimized={false}>
        <ProjectModalProvider>
          <CreateProjectModal />
          <FileNavProvider>
            <FileNavContainer.Provider>
              <FileNavModals />
              <Outlet />
            </FileNavContainer.Provider>
          </FileNavProvider>
        </ProjectModalProvider>
      </DashboardWrapWithSidebar>
    </DashboardProvider>
  );
}
