import {Controller, useForm} from 'react-hook-form';
import {DashParams, routePaths} from 'routes/routesHelper';
import {Dialog, RadioGroup, Transition} from '@headlessui/react';
import {Fragment, useRef, useState} from 'react';
import {
  fetchProjectData,
  fetchUserProjects,
  getUserProjectsSuccess,
} from 'features/userLibrarySlice';
import {useDispatch, useSelector} from 'react-redux';

import {LoadingSpinnerInline} from 'features/EditorCanvas/components/shared/LoadingSpinnerInline';
import {QuestionMarkCircleIcon} from '@heroicons/react/solid';
import ReactPlayer from 'vendor/react-player-milk';
import {XIcon} from '@heroicons/react/outline';
import {authSelector} from 'features/selectors/authSelectors';
import cn from 'classnames';
import {postCreateNewProject} from 'api/projectsAPI';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useNavigate} from 'react-router-dom';
import {useProjectModal} from '../useProjectModal';

export enum ProjectOrientation {
  Landscape = 'Landscape',
  Square = 'Square',
  Portrait = 'Portrait',
}

const product = {
  name: 'New video project',
  sizes: [
    {
      icon: (active: boolean) => {
        return (
          <svg
            width="30"
            height="30"
            className={cn('', {
              'text-gray-600': !active,
              'text-indigo-600': active,
            })}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="60" height="60" fill="none" />
            <path
              d="M51 9H9C5.7 9 3 11.7 3 15V45C3 48.3 5.7 51 9 51H51C54.3 51 57 48.3 57 45V15C57 11.7 54.3 9 51 9ZM51 45H9V15H51V45Z"
              fill="currentColor"
            />
            <path d="M25 24L35 30L25 36V24Z" fill="currentColor" />
          </svg>
        );
      },
      name: ProjectOrientation.Landscape,
    },
    {
      icon: (active: boolean) => {
        return (
          <svg
            width="30"
            height="30"
            viewBox="0 0 60 60"
            xmlns="http://www.w3.org/2000/svg"
            className={cn({
              'text-gray-600': !active,
              'text-indigo-600': active,
            })}
          >
            <rect width="60" height="60" fill="none" />
            <path
              d="M9 16L9 44C9 47.3 11.7 50 15 50L45 50C48.3 50 51 47.3 51 44L51 16C51 12.7 48.3 10 45 10L15 10C11.7 10 9 12.7 9 16ZM45 16L45 44L15 44L15 16L45 16Z"
              fill="currentColor"
            />
            <path d="M25 24L35 30L25 36V24Z" fill="currentColor" />
          </svg>
        );
      },
      name: ProjectOrientation.Square,
    },
    {
      icon: (active: boolean) => {
        return (
          <svg
            className={cn({
              'text-gray-600': !active,
              'text-indigo-600': active,
            })}
            width="30"
            height="30"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="60" height="60" fill="none" />
            <path
              d="M9 9L9 51C9 54.3 11.7 57 15 57L45 57C48.3 57 51 54.3 51 51L51 9C51 5.7 48.3 3 45 3L15 3C11.7 3 9 5.7 9 9ZM45 9L45 51L15 51L15 9L45 9Z"
              fill="currentColor"
            />
            <path d="M25 24L35 30L25 36V24Z" fill="currentColor" />
          </svg>
        );
      },
      name: ProjectOrientation.Portrait,
    },
  ],
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const CreateProjectModal = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
  } = useForm();
  const {
    dispatch: dispatchModal,
    state: {isModalOpen: open},
  } = useProjectModal();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {email} = useSelector(authSelector);
  const {getAccessTokenSilently} = useAuthAlt();

  const setOpen = (newState: boolean) => {
    dispatchModal({
      type: 'setIsModalOpen',
      payload: {
        isModalOpen: newState,
      },
    });
  };

  type submitFormData = {
    projectName: string;
    orientation: ProjectOrientation;
  };

  const onSubmit = (data: submitFormData) => {
    // console.log('data', data);
    // console.log('email', email);
    if (!email) {
      return;
    }
    (async () => {
      setIsSubmitting(true);
      try {
        // console.log(data);
        const token = await getAccessTokenSilently();
        const projectOptions = {
          projectName: data.projectName || 'Untitled project',
          orientation: data.orientation,
        };
        const {project_id: projectId, project} = await postCreateNewProject({
          token,
          projectOptions,
        });

        // console.log('projectId', projectId);
        // console.log('project', project);
        dispatch(
          getUserProjectsSuccess({
            userProjects: [project],
          }),
        );
        dispatch(fetchUserProjects(token));
        dispatch(fetchProjectData(token, email, projectId));
        navigate(`/${routePaths.create}/${DashParams.CanvasParam}/${projectId}`);
      } catch (error) {
        setIsSubmitting(false);
      }
    })();
  };

  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (ev: any) => {
    if (ev.keyCode === 13) {
      triggerSubmit();
    }
  };

  const triggerSubmit = () => {
    if (formRef.current && formRef.current.submit) {
      formRef.current.submit();
    }
  };

  const videoPreviewPlaybackId = 'iCUcu7b8QuOeIKoGiTleYdaS00lF401ViRs202FDfRKWdQ';
  const supportSizeContentUrl =
    'https://support.milkvideo.com/en/articles/5495107-what-orientation-should-i-pick';
  const exampleVideoUrl =
    'https://support.milkvideo.com/en/articles/4995719-what-is-the-ideal-content-to-create-on-milk-video';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-20 overflow-y-auto" onClose={setOpen}>
        <div
          className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
          style={{fontSize: 0}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden md:inline-block md:h-screen md:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex w-full transform text-left text-base transition md:my-8 md:inline-block md:max-w-2xl md:px-4 md:align-middle lg:max-w-3xl">
              <div className="relative flex w-full items-center overflow-hidden rounded-lg bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-12 lg:gap-x-8">
                  <div className="sm:col-span-4 lg:col-span-5">
                    <div className="aspect-w-1 aspect-h-1 overflow-hidden bg-white">
                      <ReactPlayer
                        playing
                        loop
                        url={`https://stream.mux.com/${videoPreviewPlaybackId}.m3u8`}
                        width="100%"
                      />
                    </div>
                    <p className="absolute top-4 left-4 text-center sm:static sm:mt-6">
                      <a
                        href={exampleVideoUrl}
                        target="_blank"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                        rel="noreferrer"
                      >
                        View example video
                      </a>
                    </p>
                  </div>
                  <div onKeyDown={handleKeyDown} className="sm:col-span-8 lg:col-span-7">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:pr-12">
                      {product.name}
                    </h2>

                    <div className="mt-2 flex items-center">
                      <p className="max-w-sm text-sm text-gray-500">
                        Pick a project name a video orientation. You can change these
                        later.
                      </p>
                    </div>

                    <section aria-labelledby="information-heading" className="mt-4">
                      <div className="mt-4 flex flex-col items-start justify-start">
                        <label
                          htmlFor="email-address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                        <input
                          {...register('projectName')}
                          disabled={isSubmitting}
                          autoFocus
                          defaultValue={`Untitled project`}
                          ref={nameRef}
                          placeholder="Untitled project"
                          type="text"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      {errors.projectName && (
                        <span className="mt-1 text-sm text-red-500">
                          Project name is required
                        </span>
                      )}
                    </section>

                    <section aria-labelledby="options-heading" className="mt-6">
                      <h3 id="options-heading" className="sr-only">
                        Product options
                      </h3>

                      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                        <div className="sm:flex sm:justify-between">
                          <Controller
                            name="orientation"
                            control={control}
                            defaultValue={product.sizes[0].name}
                            render={({field}) => (
                              <RadioGroup disabled={isSubmitting} {...field}>
                                <RadioGroup.Label className="block text-sm font-medium text-gray-700">
                                  Orientation
                                </RadioGroup.Label>
                                <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-3">
                                  {product.sizes.map(size => (
                                    <RadioGroup.Option
                                      as="div"
                                      key={size.name}
                                      value={size.name}
                                      className={({active}) =>
                                        classNames(
                                          active ? 'ring-2 ring-indigo-400' : '',
                                          'relative cursor-pointer rounded-md focus:outline-none',
                                        )
                                      }
                                    >
                                      {({active, checked}) => (
                                        <div
                                          className={cn(
                                            checked
                                              ? 'bg-indigo-100 text-white'
                                              : 'bg-white opacity-75',
                                            'inline-flex w-full flex-col items-center justify-center rounded-md  border border-transparent p-2 hover:bg-indigo-50 hover:opacity-100',
                                          )}
                                        >
                                          <RadioGroup.Description
                                            as="div"
                                            className="mb-1"
                                          >
                                            {size.icon(checked)}
                                          </RadioGroup.Description>
                                          <RadioGroup.Label
                                            as="p"
                                            className={cn('text-sm font-medium', {
                                              'text-gray-900': !checked,
                                              'text-indigo-600': checked,
                                            })}
                                          >
                                            {size.name}
                                          </RadioGroup.Label>
                                        </div>
                                      )}
                                    </RadioGroup.Option>
                                  ))}
                                </div>
                              </RadioGroup>
                            )}
                          />
                        </div>
                        <div className="mt-4 flex">
                          <a
                            target="_blank"
                            href={supportSizeContentUrl}
                            className="group flex text-sm text-gray-500 hover:text-gray-700"
                            rel="noreferrer"
                          >
                            <span>What orientation should I pick?</span>
                            <QuestionMarkCircleIcon
                              className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          </a>
                        </div>
                        <div className="mt-6">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className={cn(
                              'flex w-full items-center justify-center rounded-md border border-transparent py-3 px-8 text-base font-medium transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50',
                              {
                                'bg-indigo-600 text-white hover:bg-indigo-600':
                                  !isSubmitting,
                                'bg-gray-100 text-gray-500': isSubmitting,
                              },
                            )}
                          >
                            <Transition
                              show={isSubmitting}
                              enter="transition ease-out duration-100 transform"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="transition ease-in duration-75 transform"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <LoadingSpinnerInline />
                            </Transition>
                            Create project
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
