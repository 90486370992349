import {AlignLeft, Columns, Film, Icon, Upload} from 'react-feather';
import {DragSourceType, DraggableClip, useDraggableClipContext} from './DraggableClip';
import {UploadMediaClipType, useUploadMediaClipQuery} from 'services/uploadMediaClipAPI';
import {UserUpload, useUserUploadQuery} from 'services/userUploadAPI';
import {useCallback, useRef, useState} from 'react';

import {AUDIO_PREVIEW_URL_LARGE} from 'features/Common/constants';
import {ClipUploadMedia} from 'features/types/userLibrarySlice';
import LoadingSpinner from '../shared/LoadingSpinner';
import {default as ReactPlayerType} from 'vendor/react-player-milk/types/lib';
import WrappedReactPlayer from './CardWrappedReactPlayer';
import {addItemsFromClip} from 'features/canvasItemsSlice';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import {useRecentUsage} from 'services/recentUsageAPI';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';

interface UserClipCardProps {
  clip: UploadMediaClipType;
  projectId: string;
}

export default function UserClipCard({clip, projectId}: UserClipCardProps) {
  const [videoIsReady, setVideoIsReady] = useState(false);
  const videoRef = useRef<ReactPlayerType>(null);
  const videoPlayer: any = videoIsReady ? videoRef.current : null;

  const {
    data: userUpload,
    isFetching,
    isLoading,
  } = useUserUploadQuery({id: clip.user_upload_id});

  const [isPlaying, setIsPlaying] = useState(false);
  const {start_time: startTime, end_time: endTime} = clip;

  const [optionsVisibleState, setOptionsVisible] = useState(false);
  const {activeClip} = useDraggableClipContext();
  const optionsVisible = optionsVisibleState || activeClip?.clipId === clip.id;

  if (isFetching || isLoading || !userUpload) {
    return <div />;
  }

  return (
    <div
      className="relative overflow-hidden rounded-lg border border-gray-300 bg-gray-800 text-white"
      draggable="false"
      onMouseEnter={() => setOptionsVisible(true)}
      onMouseLeave={() => setOptionsVisible(false)}
    >
      <div
        className={classNames(
          'blur-0 filter transition-all',
          optionsVisible && 'blur-sm',
        )}
        style={{willChange: 'filter'}}
      >
        <div
          style={{
            backgroundSize: 'cover',
            height: '120px',
            width: '100%',
          }}
          className="relative overflow-hidden"
        >
          <div className="group flex justify-end">
            <div className="relative left-0 top-0 w-full">
              {clip.upload_media_type === ClipUploadMedia.Video && (
                <WrappedReactPlayer
                  clipId={clip.id}
                  videoPlayer={videoPlayer}
                  videoRef={videoRef}
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                  startTime={Number(startTime)}
                  endTime={Number(endTime)}
                  playbackId={userUpload.mux_playback_id}
                  videoIsReady={videoIsReady}
                  setVideoIsReady={setVideoIsReady}
                />
              )}
              {clip.upload_media_type === ClipUploadMedia.Audio && (
                <div className="relative">
                  <img
                    className="h-full w-full"
                    alt="preview"
                    src={AUDIO_PREVIEW_URL_LARGE}
                  />
                </div>
              )}
              <div className="absolute inset-0 bg-black bg-opacity-20" />
            </div>
            <div className="absolute left-2 top-3 flex flex-col">
              <span className="mb-1 font-bold">
                {clip.clip_name !== ''
                  ? clip.clip_name
                  : `${(Number(clip.end_time) - Number(clip.start_time)).toFixed(2)} sec`}
              </span>
            </div>
          </div>
        </div>
        <div className="grid cursor-text grid-cols-4 gap-x-4 p-2 hover:bg-gray-700">
          <div className="col-span-4">
            <h1 className="text-xs truncate-3-lines">{clip.text_snippet}</h1>
          </div>
        </div>
      </div>
      {optionsVisible && (
        <InsertOptions visible={optionsVisible} clipId={clip.id} projectId={projectId} />
      )}
    </div>
  );
}

const CardOption = ({
  uploadMediaClip,
  userUpload,
  icon: Icon,
  label,
  type,
  projectId,
}: {
  uploadMediaClip: UploadMediaClipType;
  userUpload: UserUpload;
  icon: Icon;
  label: string;
  type: DragSourceType;
  projectId: string;
}) => {
  const {activeScene} = useScenes();
  const dispatch = useDispatch();
  const {recentUsage} = useRecentUsage();

  const insertItem = useCallback(() => {
    dispatch(
      addItemsFromClip({
        type,
        projectId,
        uploadMediaClip,
        userUpload,
        sceneId: activeScene.id,
        style: recentUsage,
      }),
    );
  }, [activeScene, uploadMediaClip, userUpload, dispatch, projectId, type, recentUsage]);

  return (
    <DraggableClip
      uploadMediaClip={uploadMediaClip}
      userUpload={userUpload}
      type={type}
      onClick={insertItem}
    >
      <div className="flex items-center rounded-lg px-2 py-1 transition-colors hover:bg-gray-800">
        <Icon size={16} className="mr-2" />
        {label}
      </div>
    </DraggableClip>
  );
};

const InsertOptions = ({
  visible,
  clipId,
  projectId,
}: {
  visible: boolean;
  clipId: string;
  projectId: string;
}) => {
  // console.log('clipId', clipId);
  const {
    data: uploadMediaClip,
    isLoading: isLoadingUploadMediaClip,
    isFetching: isFetchingUploadMediaClip,
  } = useUploadMediaClipQuery({id: clipId});

  const {
    data: userUpload,
    isLoading: isLoadingUserUpload,
    isFetching: isFetchingUserUpload,
  } = useUserUploadQuery(
    {id: uploadMediaClip?.user_upload_id!},
    {
      skip: !uploadMediaClip?.user_upload_id,
    },
  );

  if (
    !uploadMediaClip ||
    !userUpload ||
    isLoadingUploadMediaClip ||
    isLoadingUserUpload
  ) {
    return (
      <div
        className={classNames(
          'absolute inset-0 flex items-center justify-center bg-gray-700 bg-opacity-80 text-center opacity-0 transition-opacity',
          visible && 'opacity-100',
        )}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'absolute inset-0 flex items-center justify-center bg-gray-700 bg-opacity-80 text-center opacity-0 transition-opacity',
        visible && 'opacity-100',
      )}
    >
      <div>
        <div className="mb-2 font-bold">Insert...</div>
        <CardOption
          projectId={projectId}
          uploadMediaClip={uploadMediaClip}
          userUpload={userUpload}
          icon={Film}
          label="Video"
          type="video"
        />
        <CardOption
          projectId={projectId}
          uploadMediaClip={uploadMediaClip}
          userUpload={userUpload}
          icon={AlignLeft}
          label="Captions"
          type="caption"
        />
        <CardOption
          projectId={projectId}
          uploadMediaClip={uploadMediaClip}
          userUpload={userUpload}
          icon={Columns}
          label="Both"
          type="both"
        />
      </div>
    </div>
  );
};
