import {Able, MoveableManagerInterface} from 'react-moveable';

import {useEffect} from 'react';

type RightClickableProps = {
  onRightClickGroup?: (event: MouseEvent) => void;
};

const RightClickableComponent = ({
  moveable,
}: {
  moveable: MoveableManagerInterface<RightClickableProps, any>;
}) => {
  const areaElement = moveable.areaElement;
  const onRightClickGroup = moveable.props.onRightClickGroup;

  useEffect(() => {
    // Ignore if not a group
    if (!areaElement) return;
    if (!onRightClickGroup) return;

    areaElement.addEventListener('contextmenu', onRightClickGroup);
    return () => {
      areaElement.removeEventListener('contextmenu', onRightClickGroup);
    };
  }, [areaElement, onRightClickGroup]);

  return null;
};

export const RightClickable: Able<RightClickableProps> = {
  name: 'rightClickable',
  props: {onRightClickGroup: Function},
  events: {},
  render(moveable: MoveableManagerInterface<RightClickableProps>) {
    return <RightClickableComponent key="RightClickableComponent" moveable={moveable} />;
  },
} as const;
