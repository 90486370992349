import {Fragment, useEffect, useRef, useState} from 'react';

import {SettingsCompanyNameCard} from './SettingsCompanyNameCard';
import {SettingsInviteTeamCard} from './SettingsInviteTeamCard';
import {SettingsLabelCard} from './SettingsLabelCard';
import {SettingsTeamCard} from './SettingsTeamCard/SettingsTeamCard';
import ZoomConnection from './ConnectedAccounts/ZoomConnection';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {SettingsGlossaryCard} from './SettingsGlossaryCard';

export default function SettingsAccount() {
  const zoomImportFlag = useFeatureFlag('zoom-import');

  useEffect(() => {
    window.analytics.track('Loaded settings page - viewed account preferences page');
  }, []);

  return (
    <>
      <div className="mt-6 space-y-6 divide-y divide-gray-200">
        <SettingsLabelCard />
        {zoomImportFlag && <ZoomConnection />}

        <SettingsCompanyNameCard />
        <SettingsInviteTeamCard />
        <SettingsTeamCard />
        <SettingsGlossaryCard />
      </div>
    </>
  );
}
