import {
  ListFilter,
  SortByClipType,
  clipListModalOptions,
  listFilterTypes,
} from './constants';
import {ReactNode, createContext, useContext, useReducer} from 'react';

import {AccountLabelColor} from 'features/types/userLibrarySlice';

type Action =
  | {
      type: 'update selected id list';
      payload: {
        newSelectedIdList: string[];
      };
    }
  | {
      type: 'update sort by';
      payload: {
        sortByValue: string;
      };
    }
  | {
      type: 'update color label filter';
      payload: {
        color: AccountLabelColor;
        shouldRemove: boolean;
      };
    }
  | {
      type: 'setOpenListFilterModal';
      payload: {
        openListFilterModal: boolean;
      };
    }
  | {
      type: 'reset active modal';
    }
  | {
      type: 'remove active list filter';
      payload: {
        activeListFilter: ListFilter;
      };
    }
  | {
      type: 'update search term';
      payload: {
        searchTerm: string;
      };
    }
  | {
      type: 'set active modal';
      payload: {
        activeModal: clipListModalOptions;
      };
    }
  | {
      type: 'update clip rename modal';
      payload: {
        openClipRenameModal: boolean;
      };
    }
  | {
      type: 'update open color label modal';
      payload: {
        openClipLabelAssignmentModal: boolean;
      };
    }
  | {
      type: 'update override checkbox clip value';
      payload: {
        newOverrideValue: boolean;
      };
    };
type Dispatch = (action: Action) => void;

type State = {
  activeModal: clipListModalOptions;
  sortByValue: string;
  activeListFilters: ListFilter[];
  selectedIdList: string[];
  overrideCheckboxClip: boolean;
  openListFilterModal: boolean;
  openClipRenameModal: boolean;
  openClipLabelAssignmentModal: boolean;
};
type ClipListProviderProps = {children: ReactNode};

const initialClipList = {
  activeModal: listFilterTypes[0].id,
  sortByValue: SortByClipType.CreatedAt,
  selectedIdList: [],
  activeListFilters: [],
  overrideCheckboxClip: false,
  openListFilterModal: false,
  openClipRenameModal: false,
  openClipLabelAssignmentModal: false,
} as State;

const ClipListStateContext =
  createContext<{state: State; dispatch: Dispatch} | undefined>(undefined);

function clipListReducer(state: State, action: Action) {
  switch (action.type) {
    case 'update clip rename modal': {
      return {...state, openClipRenameModal: action.payload.openClipRenameModal};
    }
    case 'update open color label modal': {
      return {
        ...state,
        openClipLabelAssignmentModal: action.payload.openClipLabelAssignmentModal,
      };
    }
    case 'set active modal': {
      return {...state, activeModal: action.payload.activeModal};
    }
    case 'update color label filter': {
      const {shouldRemove, color} = action.payload;
      if (shouldRemove) {
        return {...state};
      } else {
        return {
          ...state,
          activeListFilters: [
            ...state.activeListFilters,
            {
              filter_target: 'color-label',
              filter_value: color.name,
              filter_condition: '=',
            } as ListFilter,
          ],
        };
      }
    }
    case 'reset active modal': {
      return {...state, activeModal: initialClipList.activeModal};
    }
    case 'remove active list filter': {
      const {activeListFilter} = action.payload;

      const newActiveListFilter = state.activeListFilters.filter(filter => {
        return !(
          filter.filter_target === activeListFilter.filter_target &&
          filter.filter_value === activeListFilter.filter_value
        );
      });

      return {...state, activeListFilters: newActiveListFilter};
    }
    case 'update search term': {
      return {
        ...state,
        activeListFilters: [
          ...state.activeListFilters,
          {
            filter_target: 'content',
            filter_value: action.payload.searchTerm,
            filter_condition: '=',
          } as ListFilter,
        ],
      };
    }
    case 'update selected id list': {
      return {...state, selectedIdList: action.payload.newSelectedIdList};
    }
    case 'setOpenListFilterModal': {
      if (!action.payload.openListFilterModal) {
        return {
          ...state,
          openListFilterModal: action.payload.openListFilterModal,
          activeModal: listFilterTypes[0].id,
        };
      } else {
        return {...state, openListFilterModal: action.payload.openListFilterModal};
      }
    }
    case 'update sort by': {
      return {...state, sortByValue: action.payload.sortByValue};
    }
    case 'update override checkbox clip value': {
      return {...state, overrideCheckboxClip: action.payload.newOverrideValue};
    }
    default: {
      throw new Error(`Unhandled action type: ${JSON.stringify(action)}`);
    }
  }
}

function ClipListProvider({children}: ClipListProviderProps) {
  const [state, dispatch] = useReducer(clipListReducer, initialClipList);

  const value = {state, dispatch};

  return (
    <ClipListStateContext.Provider value={value}>
      {children}
    </ClipListStateContext.Provider>
  );
}

function useClipList() {
  const context = useContext(ClipListStateContext);
  if (context === undefined) {
    throw new Error('useClipList must be used within a ClipListProvider');
  }
  return context;
}

export {ClipListProvider, useClipList};
