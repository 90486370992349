import {useDispatch, useSelector} from 'react-redux';

import {ColorInput} from 'features/Common/ColorInput';
import {ColorResult} from 'react-color';
import ReactTooltip from 'react-tooltip';
import {Type} from 'react-feather';
import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {updateItemAttribute} from 'features/canvasItemsSlice';

export default function CaptionTextInactiveColorPicker({projectId}: {projectId: string}) {
  const dispatch = useDispatch();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  if (!selected) return null;

  const handleChange = (color: ColorResult) => {
    dispatch(
      updateItemAttribute({
        projectId,
        id: selected.id,
        property: 'colorInactive',
        attribute: 'style',
        newValue: color.hex,
      }),
    );
  };

  if (
    selected.item.viewType !== ViewTypes.Caption &&
    selected.item.viewType !== ViewTypes.CaptionClip
  ) {
    return null;
  }

  const textColorInactive = selected.item.style.colorInactive;
  if (!textColorInactive) return null;

  return (
    <span data-tip={'Inactive caption text color'} className="group">
      <ColorInput
        color={textColorInactive}
        onChange={handleChange}
        type="captions_inactive_color"
      >
        <span className="align-center flex cursor-pointer flex-col items-center justify-center rounded-sm px-1 group-hover:bg-gray-50">
          <Type className="h-5 w-5" />
          <div
            className="cursor-pointer rounded-md shadow-inner"
            style={{
              background: textColorInactive,
              width: '28px',
              height: '5px',
              marginTop: '2px',
            }}
          />
        </span>
      </ColorInput>
      <ReactTooltip className="bg-gray-600 font-semibold" effect="solid" />
    </span>
  );
}
