import {baseApi} from './baseAPI';
import {useMemo} from 'react';

export type RecentUsage = {
  canvas_background: string;
  shape_background: string;
  captions_font: string;
  captions_active_color: string;
  captions_inactive_color: string;
  text_font: string;
  text_color: string;
};

export const defaultStyles: RecentUsage = {
  canvas_background: '#1F2937',
  shape_background: '#1E3A8A',
  captions_font: 'PT Serif',
  captions_inactive_color: '#6B7280',
  captions_active_color: '#FFFFFF',
  text_font: 'PT Serif',
  text_color: '#FFFFFF',
};

export const recentUsageAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['RecentUsage']})
  .injectEndpoints({
    endpoints: builder => ({
      fetchRecentUsage: builder.query<Partial<RecentUsage>, void>({
        query: () => ({
          url: `account/recent_usage`,
          errorTitle: false,
          method: 'GET',
          v2: true,
          refetchOnFocus: false,
          refetchOnReconnect: false,
        }),
        providesTags: ['RecentUsage'],
      }),

      updateRecentUsage: builder.mutation<RecentUsage, Partial<RecentUsage>>({
        query: data => ({
          url: `account/recent_usage`,
          errorTitle: 'Error updating recent usage',
          method: 'POST',
          data,
          v2: true,
          refetchOnFocus: false,
          refetchOnReconnect: false,
        }),
        invalidatesTags: ['RecentUsage'],
      }),
    }),
  });

export const {useUpdateRecentUsageMutation, useFetchRecentUsageQuery} = recentUsageAPI;

const EMPTY_OBJ = {};

export const useRecentUsage = () => {
  const {data = EMPTY_OBJ, ...queryResult} = useFetchRecentUsageQuery();

  const recentUsage = useMemo(() => ({...defaultStyles, ...data}), [data]);

  return {
    ...queryResult,
    recentUsage,
    isSet: Object.keys(data).length > 0,
  };
};
