import styled from 'styled-components';

const LayerText = styled.div.attrs({
  className: 'whitespace-pre-wrap text-white box-decoration-break-clone text-left',
})`
  font-family: '${props => props.style.fontFamily}';
  font-size: ${props => props.style.fontSize}px;
  color: ${props => props.style.color};
`;

export default LayerText;
