import {atom, useSetRecoilState} from 'recoil';

import {ActiveState} from './ColorPicker';
import {useSidebarMenu} from '../../SidebarMenuContext';

export const canvasColorPickerState = atom<ActiveState | null>({
  key: 'canvasColorPicker',
  default: null,
});

export const useOpenCanvasColorPicker = () => {
  const setCanvasColorPicker = useSetRecoilState(canvasColorPickerState);

  const {dispatch} = useSidebarMenu();
  const setActiveView = (newActiveView: string) => {
    dispatch({
      type: 'update active sidebar menu',
      activeMenu: newActiveView,
    });
  };

  return (state: ActiveState) => {
    setCanvasColorPicker(state);
    setActiveView('ColorPicker');
  };
};
