import {StepProps} from './CreateAccountPage';
import {InviteTeamForm} from './InviteTeamForm';
import {OnboardingContainer} from './OnboardingContainer';

export const InviteTeam = ({nextStep}: StepProps) => {
  return (
    <OnboardingContainer title="Invite your co-workers">
      <InviteTeamForm onComplete={nextStep} />
    </OnboardingContainer>
  );
};
