import {ComponentClass} from 'react';
import {CustomPicker, RGBColor} from 'react-color';
import {EditableInput} from 'react-color/lib/components/common';
import {
  ExportedColorProps,
  InjectedColorProps,
} from 'react-color/lib/components/common/ColorWrap';
import {ColorSquare} from 'ui/ColorSquare';

type HexInputProps = {
  onClick?: () => void;
};

const colorToString = (rgb: RGBColor | undefined) => {
  const {r, g, b, a} = rgb || {};
  return `rgba(${r ?? 0}, ${g ?? 0}, ${b ?? 0}, ${a ?? 0})`;
};

const HexInput = ({onClick, ...props}: InjectedColorProps & HexInputProps) => {
  return (
    <div className="flex items-center">
      <ColorSquare color={colorToString(props.rgb)} onClick={onClick} />
      <div
        className="ml-2 w-full rounded-md border border-gray-300 bg-white text-xs"
        onClick={onClick}
      >
        <label>
          <span className="hidden">Color Input</span>
          <EditableInput
            onChange={props.onChange}
            value={props.hex}
            style={{
              input: {
                width: '100%',
                height: '100%',
                padding: '0.5rem',
                borderRadius: '6px',
              },
            }}
          />
        </label>
      </div>
    </div>
  );
};

const WrappedHexInput: ComponentClass<ExportedColorProps & HexInputProps> =
  CustomPicker(HexInput);
export {WrappedHexInput as HexInput};
