import pick from 'lodash.pick';
import {createRef, RefObject, useEffect, useState} from 'react';

function createRefs<T>(ids: string[]) {
  const refs: Record<string, RefObject<T>> = {};
  for (const id of ids) {
    refs[id] = createRef<T>();
  }
  return refs;
}

export function useRefs<T>(ids: string[]) {
  const [refs, setRefs] = useState(createRefs<T>(ids));

  useEffect(() => {
    const currentIds = Object.keys(refs);

    const added = ids.filter(id => !currentIds.includes(id));
    if (added.length) {
      setRefs(refs => ({
        ...refs,
        ...createRefs<T>(ids),
      }));
    }

    const removed = currentIds.filter(id => !ids.includes(id));
    if (removed.length) {
      setRefs(refs => pick(refs, ids));
    }
  }, [refs, ids]);

  return refs;
}
