import { AccountLabelColor } from 'features/types/userLibrarySlice';
import { Edit2 } from 'react-feather';
import { LabelFormInput } from './LabelFormInput';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { userLibrarySelector } from 'features/selectors/userLibrarySelectors';

export const LabelListItem = ({
  handleLabelEdit,
  color,
  clipCount,
  isEditing,
}: {
  handleLabelEdit: (param: null | AccountLabelColor) => void;
  color: AccountLabelColor;
  clipCount: number;
  isEditing: boolean;
}) => {
  const { userClips } = useSelector(userLibrarySelector);

  if (isEditing) {
    return (
      <LabelFormInput isNew={false} handleLabelEdit={handleLabelEdit} color={color} />
    );
  }

  return (
    <div className="relative  flex items-center justify-between rounded-md border bg-gray-50 px-2 py-2">
      <div className="flex items-center justify-center">
        <label
          htmlFor={`color-${color.name}`}
          className={classNames(
            color.bgColor,
            'mr-2 h-3 w-3 cursor-pointer rounded-full',
          )}
        />
        <div className="space-x-3">
          <p className="inline-flex items-center justify-center text-sm font-medium text-gray-700">
            {color.name}
          </p>
          {userClips && userClips.length > 0 && (
            <p className="inline-flex items-center justify-center text-sm font-medium text-gray-500 ">
              {clipCount} clips
            </p>
          )}
        </div>
      </div>
      <div className="ml-3 flex h-5 items-center">
        <button
          onClick={() => {
            handleLabelEdit(color);
          }}
          type="button"
          className="focus:outline-none inline-flex items-center rounded-full border border-transparent bg-none p-1 text-white hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <Edit2 className="h-4 w-4 text-gray-600 hover:text-gray-800" />
        </button>
      </div>
    </div>
  );
};
