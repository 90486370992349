import {ReactNode, createContext, useContext, useReducer} from 'react';

type Action =
  | {
      type: 'setVideoRef';
      payload: {
        videoRef: any;
      };
    }
  | {
      type: 'updateIsVideoReady';
      payload: {
        isVideoReady: boolean;
      };
    }
  | {
      type: 'updateHoveredClipId';
      payload: {
        clipId: string | null;
      };
    }
  | {
      type: 'updateShouldShowAllItems';
      payload: {
        shouldShowAll: boolean;
      };
    }
  | {
      type: 'updateIsPlaying';
      payload: {
        isPlaying: boolean;
      };
    }
  | {
      type: 'setActivePlaybackId';
      payload: {
        activePlaybackId: string;
      };
    };
type Dispatch = (action: Action) => void;
type SearchResultItemProviderProps = {children: ReactNode};

const SearchResultItemStateContext =
  createContext<{state: State; dispatch: Dispatch} | undefined>(undefined);

type State = {
  activePlaybackId: string;
  isPlaying: boolean;
  isReady: boolean;
  videoRef: any;
  shouldShowAll: boolean;
  hoveredClipId: string | null;
};

const initialState = {
  activePlaybackId: '',
  isPlaying: false,
  isReady: false,
  shouldShowAll: false,
  hoveredClipId: null,
  videoRef: null,
} as State;

function searchResultItemReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setVideoRef': {
      return {...state, videoRef: action.payload.videoRef};
    }
    case 'setActivePlaybackId': {
      return {...state, activePlaybackId: action.payload.activePlaybackId};
    }
    case 'updateIsVideoReady': {
      return {...state, isVideoReady: action.payload.isVideoReady};
    }
    case 'updateHoveredClipId': {
      return {...state, hoveredClipId: action.payload.clipId};
    }
    case 'updateShouldShowAllItems': {
      return {...state, shouldShowAll: action.payload.shouldShowAll};
    }
    case 'updateIsPlaying': {
      return {...state, isPlaying: action.payload.isPlaying};
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function SearchResultItemProvider({children}: SearchResultItemProviderProps) {
  const [state, dispatch] = useReducer(searchResultItemReducer, initialState);
  const value = {state, dispatch};
  return (
    <SearchResultItemStateContext.Provider value={value}>
      {children}
    </SearchResultItemStateContext.Provider>
  );
}

function useSearchResultItem() {
  const context = useContext(SearchResultItemStateContext);
  if (context === undefined) {
    throw new Error('useSearchResultItem must be used within a SearchResultItemProvider');
  }
  return context;
}

export {SearchResultItemProvider, useSearchResultItem};
