
import { useUIState } from 'vendor/dailyco/shared/contexts/UIStateProvider';

import DeviceSelect from '../DeviceSelect';
import { Modal } from 'ui/Modal';
import { Button } from 'ui/Button';
export const DEVICE_MODAL = 'device';

export const DeviceSelectModal = () => {
  const { currentModals, closeModal } = useUIState();

  return (
    <Modal
      size="large"
      open={!!currentModals[DEVICE_MODAL]}
      onClose={() => closeModal(DEVICE_MODAL)}
      title="Select your device"
    >
      <div className="flex flex-col">

        <DeviceSelect />
        <div className="flex-1" />
        <div className="flex flex-row py-3 space-x-3">

          <Button onClick={() => { closeModal(DEVICE_MODAL) }} fullWidth variant="primary">
            Update
          </Button>
        </div>
      </div >
    </Modal >
  );
};

export default DeviceSelectModal;
