import {BASE_URL} from 'constants/environment';
import axios from 'axios';
import {requestHeader} from './helpers';

export type Auth0RequestTokenType = string;

export async function postInitiateZoomOauth(
  token: Auth0RequestTokenType,
  returnUri: string,
) {
  const apiUrl = `${BASE_URL}/api/v1/connected_accounts/zoom`;
  const response = await axios.post(
    apiUrl,
    {
      return_to: returnUri,
    },
    requestHeader(token),
  );
  return response.data as {redirect_location: string};
}

/**
 * Endpoint to initiate a deletion of the connect account for Zoom
 *
 * @param token
 * @returns
 */
export async function postDisconnectZoomApi(token: Auth0RequestTokenType) {
  const apiUrl = `${BASE_URL}/api/v1/connected_accounts/zoom/disconnect`;
  const response = await axios.post(apiUrl, {}, requestHeader(token));
  return response.data;
}

/**
 * Update the Zoom sync behavior with a boolean defining whether or not to sync
 *
 * @param token
 * @param newValue
 * @returns
 */
export async function postUpdateZoomSyncApi(
  token: Auth0RequestTokenType,
  newValue: boolean,
) {
  const apiUrl = `${BASE_URL}/api/v1/connected_accounts/zoom/sync/update`;
  const response = await axios.post(
    apiUrl,
    {
      should_zoom_sync: newValue,
    },
    requestHeader(token),
  );
  return response.data as {redirect_url: string};
}
