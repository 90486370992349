import { ReactNode, useEffect } from 'react';

import { CanvasWrapper } from 'features/EditorCanvas/components/AppCanvas/Canvas/CanvasWrapper';
import { DimensionType } from 'features/types/canvasItemsSlice';
import { InverseCropProvider } from '../../CanvasItem/InverseCrop';
import { LoadGoogleFonts } from 'features/Fonts/LoadGoogleFonts';
import { ProjectIdProvider } from 'features/EditorCanvas/useProjectId';
import { ProjectTimeProvider } from '../../CanvasTime/TimeProvider';
import { ScalePreview } from 'features/FocusedFlow/LayoutUtils';
import { ScenesProvider } from '../../CanvasTime/useScenes';
import { SelectionFocusProvider } from '../../SelectionFocusProvider';
import { usePlayback } from '../../CanvasTime/usePlayback';

export const TimeSetter = ({
  children,
  timeSetToMs,
}: {
  children: ReactNode;
  timeSetToMs: number;
}) => {
  const { seekTo } = usePlayback();

  useEffect(() => {
    seekTo({ timeMs: timeSetToMs });
  }, [timeSetToMs]);
  return <div>{children}</div>;
};

export const PreviewFrameCanvas = ({
  maxHeight = 100,
  projectId,
  scaledCanvasDimensions,
  timeSetToMs,
}: {
  maxHeight?: number;
  projectId: string;
  scaledCanvasDimensions: DimensionType;
  timeSetToMs: number;
}) => {
  return (
    <SelectionFocusProvider>
      <ProjectIdProvider projectId={projectId}>
        <InverseCropProvider>
          <ProjectTimeProvider endBehavior="noop">
            <TimeSetter timeSetToMs={timeSetToMs}>
              <ScenesProvider>
                <ScalePreview
                  height={maxHeight}
                  maxHeight={maxHeight}
                  dimensions={scaledCanvasDimensions}
                >
                  <CanvasWrapper projectId={projectId} />
                </ScalePreview>
                <LoadGoogleFonts />
              </ScenesProvider>
            </TimeSetter>
          </ProjectTimeProvider>
        </InverseCropProvider>
      </ProjectIdProvider>
    </SelectionFocusProvider>
  );
};
