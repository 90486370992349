import {Children, FC} from 'react';
import {ButtonProps, ButtonPropsContext} from './Button';

export const ButtonGroup: FC<Partial<ButtonProps>> = ({children, ...defaultProps}) => {
  const lastIndex = Children.count(children) - 1;

  return (
    <div className="flex -space-x-px">
      {Children.map(children, (child, index) => {
        const overrideStyles = ['!ring-offset-0 !ring-inset focus:z-10 border-0'];

        if (index === 0) {
          overrideStyles.push('!rounded-r-none');
        } else if (index === lastIndex) {
          overrideStyles.push('!rounded-l-none');
        } else {
          overrideStyles.push('!rounded-none');
        }

        return (
          <ButtonPropsContext.Provider
            value={{
              props: {...defaultProps, _className: overrideStyles.join(' ')},
              variantStyles: {
                tertiary: '!border focus:!border-indigo-500 focus:!ring-0',
              },
            }}
          >
            {child}
          </ButtonPropsContext.Provider>
        );
      })}
    </div>
  );
};
