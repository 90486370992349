import {useEffect, useState} from 'react';

import {UserUpload} from 'services/userUploadAPI';
import {createContainer} from 'unstated-next';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';

function useFileNav(initialState = null) {
  const [isMoveOpen, setIsMoveOpen] = useState(false);
  const [activeUploadId, setActiveUploadId] = useState<null | string>(null);
  const [isRenameModelOpen, setIsRenameModelOpen] = useState(false);
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);

  const [currentFile, setCurrentFile] = useState<UserUpload | null>(null);
  const [fileName, setFileName] = useState<null | string>(
    currentFile && currentFile.file_name,
  );

  const isShareEnabled = useFeatureFlag('share-page');

  useEffect(() => {
    if (currentFile && currentFile.file_name) {
      setFileName(currentFile.file_name);
    }
  }, [currentFile]);

  return {
    activeUploadId,
    setActiveUploadId,
    isMoveOpen,
    setIsMoveOpen,
    isRenameModelOpen,
    setIsRenameModelOpen,
    isDeleteModelOpen,
    setIsDeleteModelOpen,
    currentFile,
    setCurrentFile,
    fileName,
    setFileName,
  };
}

export const FileNavContainer = createContainer(useFileNav);
