import {DuplicateIcon, XCircleIcon} from '@heroicons/react/outline';
import {LegacyRef, useEffect, useRef, useState} from 'react';
import {duplicateScene, removeSceneFromProject} from 'features/canvasItemsSlice';

import {DotsHorizontalIcon} from '@heroicons/react/solid';
import {Menu} from '@headlessui/react';
import classNames from 'classnames';
import {createPortal} from 'react-dom';
import {useCanvasScenes} from './CanvasScenesContext';
import {useDispatch} from 'react-redux';
import {usePopper} from 'react-popper';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useScenes} from '../CanvasTime/useScenes';
import {v4} from 'uuid';

export function ScenePreviewMenu({
  sceneIndex,
  sceneId,
}: {
  sceneIndex: number;
  sceneId: string;
}) {
  const dispatch = useDispatch();
  const projectId = useProjectId();
  const {scenes, seekToScene} = useScenes();

  const seekToSceneRef = useRef(seekToScene);

  const handleSceneDelete = () => {
    dispatch(
      removeSceneFromProject({
        projectId,
        sceneId: sceneId,
      }),
    );

    window.analytics.track('scenes_delete_scene', {
      count: scenes.length - 1,
    });

    let targetIndex = 0;
    const sortedScenes = [...scenes].sort((a, b) => a.order - b.order).reverse();

    if (sceneIndex > 0) {
      targetIndex = sceneIndex - 1;
    }

    seekToScene(sortedScenes[targetIndex].sceneId);
  };

  const handleSceneDuplicate = () => {
    const newSceneId = v4();

    dispatch(duplicateScene({projectId, sceneId, newSceneId}));

    setTimeout(() => {
      seekToSceneRef.current(newSceneId);
    });
  };

  const canDelete = scenes.length > 1;

  const {
    dispatch: canvasSceneDispatch,
    state: {menuTargetScene},
  } = useCanvasScenes();

  const handleAfterLeave = () => {
    if (menuTargetScene === sceneId) {
      canvasSceneDispatch({
        type: 'update menuTargetScene',
        menuTargetScene: null,
      });
    }
  };

  const handleBeforeEnter = () => {
    if (menuTargetScene !== sceneId) {
      canvasSceneDispatch({
        type: 'update menuTargetScene',
        menuTargetScene: sceneId,
      });
    }
  };

  const [buttonEl, setButtonEl] = useState<HTMLButtonElement | null>(null);
  const [popperEl, setPopperEl] = useState<HTMLDivElement | null>(null);
  const {styles, attributes} = usePopper(buttonEl, popperEl, {
    strategy: 'fixed',
    placement: 'top-start',
  });

  const popperRef = useRef<null | LegacyRef<HTMLDivElement>>(null);

  return (
    <>
      <Menu as="div">
        <div>
          <Menu.Button ref={setButtonEl} className="absolute top-5 right-3 z-50">
            {({open}) => (
              <div
                className={classNames(
                  'flex items-center rounded-full bg-gray-600 text-white opacity-0 transition duration-100 ease-in-out focus:outline-none group-hover:opacity-100',
                  open && '!opacity-100',
                )}
              >
                <span className="sr-only">Open scene {sceneIndex + 1} menu</span>
                <DotsHorizontalIcon className="h-4 w-6" aria-hidden="true" />
              </div>
            )}
          </Menu.Button>
        </div>

        {popperRef.current &&
          createPortal(
            <Menu.Items
              className="z-[999] w-48 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              as="div"
              ref={setPopperEl}
              style={styles.popper}
              {...attributes.popper}
            >
              <div className="py-1">
                <Menu.Item>
                  {({active}) => (
                    <button
                      onClick={handleSceneDuplicate}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex w-full items-center px-4 py-2 text-sm',
                      )}
                    >
                      <DuplicateIcon
                        className={classNames('mr-3 h-5 w-5 text-gray-400', {
                          'group-hover:text-gray-500': canDelete,
                        })}
                        aria-hidden="true"
                      />
                      {`Duplicate scene ${sceneIndex + 1}`}
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item disabled={!canDelete}>
                  {({active}) => (
                    <button
                      onClick={handleSceneDelete}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex w-full items-center px-4 py-2 text-sm',
                        {
                          'opacity-80': !canDelete,
                          'text-gray-500': !canDelete,
                          'cursor-default': !canDelete,
                        },
                      )}
                    >
                      <XCircleIcon
                        className={classNames('mr-3 h-5 w-5 text-gray-400', {
                          'group-hover:text-gray-500': canDelete,
                        })}
                        aria-hidden="true"
                      />
                      {`Delete scene ${sceneIndex + 1}`}
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>,
            popperRef.current!,
          )}
      </Menu>
      <div ref={popperRef} />
    </>
  );
}
