import {CaptionItemStyle} from 'features/types/canvasItemsSlice';
import LoadingText from 'features/EditorCanvas/components/shared/LoadingText';
import SceneFrame from '../shared/SceneFrame';
import WordFrame from 'features/Captions/WordFrame';
import {useVisibleFrameIndex} from '../CaptionClip/LayerCaptionsClip';

export default function LayerCaptions({
  style,
  sceneFrames,
  width,
  height,
  lineHeight,
  fontFamily,
  fontSize,
}: {
  style: CaptionItemStyle;
  sceneFrames: WordFrame[];
  width: number;
  height: number;
  lineHeight: number;
  fontFamily: string;
  fontSize: string;
}) {
  const {color, colorInactive} = style;

  const visibleFrameIndex = useVisibleFrameIndex(sceneFrames);

  if (!sceneFrames || !sceneFrames.length) {
    return <LoadingText />;
  }

  const visibleWordFrame = sceneFrames.find((el, index) => index === visibleFrameIndex);

  return (
    <div className="relative m-auto" style={{height: `${height}px`, width: `${width}px`}}>
      <div
        className="flex select-none flex-col justify-center"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        {visibleWordFrame && visibleWordFrame.wordsArr && (
          <SceneFrame
            color={color}
            colorInactive={colorInactive ?? color}
            lineHeight={lineHeight}
            fontFamily={fontFamily}
            frame={visibleWordFrame}
            fontSize={fontSize}
          />
        )}
      </div>
    </div>
  );
}
