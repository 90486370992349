import {
  canvasItemsSelectedItemIdsSelector,
  canvasItemsSelectedItemSelector,
} from 'features/selectors/canvasStateSelectors';
import {
  removeCanvasItems,
  updateCanvasItemsPositionState,
} from 'features/canvasItemsSlice';
import { useDispatch, useSelector } from 'react-redux';

import { CanvasButton } from 'features/EditorCanvas/components/CanvasButton';
import CropButton from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/CropButton';
import { Trash } from 'react-feather';
import { useProjectId } from 'features/EditorCanvas/useProjectId';

export const ImageComponentActionNavBar = () => {
  const dispatch = useDispatch();
  const projectId = useProjectId();

  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);

  const selectedItem = useSelector(canvasItemsSelectedItemSelector)(projectId);

  const removeSelectedItem = (itemKey: string) => {
    dispatch(updateCanvasItemsPositionState());
    dispatch(
      removeCanvasItems({
        projectId,
        ids: [itemKey],
      }),
    );
  };

  return (
    <>
      {selectedItem && (
        <li className="flex space-x-2">
          <CropButton
            projectId={projectId}
            itemId={selectedItem.id}
            item={selectedItem.item}
          />
        </li>
      )}
      <div className="flex flex-grow" />
      {selectedItemIds.length > 0 && (
        <>
          <li className="flex">
            <div className="flex items-center">
              <CanvasButton
                leftIcon={Trash}
                onClick={() => {
                  selectedItemIds.forEach((itemId: string) => removeSelectedItem(itemId));
                }}
              >
                Delete
              </CanvasButton>
            </div>
          </li>
        </>
      )}
    </>
  );
};
