import {
  TranscriptJSON,
  TranscriptParagraph,
  TranscriptWord,
} from 'features/Captions/Transcripts';

import {NonUndefined} from 'utility-types';
import equal from 'fast-deep-equal';
import {modifyWord} from 'features/TranscriptEditor/transcriptUtils';
import produce from 'immer';
import {useTimeSelector} from 'features/EditorCanvas/components/CanvasTime/useTimeSelector';

export type SlateLeaf = {
  text: string;
  words: TranscriptParagraph;
  selected?: boolean;
  firstSelected?: boolean;
  lastSelected?: boolean;
};

export type SlateElement = {
  children: SlateLeaf[];
};

export const sortSelection = (selection: SelectionRange) => {
  const sortedSelection = [selection.anchor, selection.focus];

  sortedSelection.sort((a, b) => {
    if (a.paragraph === b.paragraph) {
      return a.offset - b.offset;
    } else {
      return a.paragraph - b.paragraph;
    }
  });

  return {
    start: sortedSelection[0],
    end: sortedSelection[1],
  };
};

const transcriptWordsToText = (paragraph: TranscriptParagraph) => {
  return paragraph.map(word => word.text).join(' ');
};

const selectParagraphRange = (
  transcript: SlateElement[],
  paragraphIndex: number,
  range: {from?: number; to?: number},
  position?: {firstSelected?: boolean; lastSelected?: boolean},
) => {
  return produce(transcript, draftTranscript => {
    const paragraph = draftTranscript.find(({children}) => {
      return children.find(paragraph => {
        return paragraph.words.find(word => word.paragraph === paragraphIndex);
      });
    });

    if (!paragraph) return;

    const paragraphWords = paragraph.children[0].words;

    let from = 0;
    if (range.from !== undefined) {
      from = paragraphWords.findIndex(w => w.index === range.from);

      if (from === -1) return;
    }

    let to = paragraphWords.length - 1;
    if (range.to !== undefined) {
      to = paragraphWords.findIndex(w => w.index === range.to);

      if (to === -1) return;
    }

    to += 1;

    paragraph.children = [];

    if (from > 0) {
      const wordsBefore = paragraphWords.slice(0, from);
      paragraph.children.push({
        text: transcriptWordsToText(wordsBefore) + ' ',
        words: wordsBefore,
      });
    }

    const selectedWords = paragraphWords.slice(from, to);
    paragraph.children.push({
      text: transcriptWordsToText(selectedWords),
      words: selectedWords,
      selected: true,
      ...position,
    });

    if (range.to !== undefined) {
      const wordsAfter = paragraphWords.slice(to);
      if (wordsAfter.length > 0) {
        paragraph.children.push({
          text: ' ' + transcriptWordsToText(wordsAfter),
          words: wordsAfter,
        });
      }
    }
  });
};

export const selectRange = (
  transcript: SlateElement[],
  range: {start: TranscriptWord; end: TranscriptWord},
) => {
  let result = transcript;

  if (range.start.paragraph === range.end.paragraph) {
    result = selectParagraphRange(
      result,
      range.start.paragraph,
      {from: range.start.index, to: range.end.index},
      {firstSelected: true, lastSelected: true},
    );
  } else {
    result = selectParagraphRange(
      result,
      range.start.paragraph,
      {from: range.start.index},
      {firstSelected: true},
    );

    for (let index = range.start.paragraph + 1; index < range.end.paragraph; index++) {
      result = selectParagraphRange(result, index, {});
    }

    result = selectParagraphRange(
      result,
      range.end.paragraph,
      {to: range.end.index},
      {lastSelected: true},
    );
  }

  return result;
};

/**
 * Utility for toggling through period, comma, and space.
 */
export const togglePunctuation = (currentString: string) => {
  let newString = currentString;
  const last1 = newString.slice(-1);
  if (last1.includes('.')) {
    newString = replaceLast('.', ',', newString);
  } else if (last1.includes(',')) {
    newString = replaceLast(',', '', newString);
  } else {
    newString = replaceLast('', '.', newString);
  }
  return newString;
};

const getNextWord = ({
  transcript,
  word,
}: {
  transcript: TranscriptJSON;
  word: TranscriptWord;
}) => {
  return transcript[word.paragraph][word.index + 1];
};

export const toggleTranscriptPunctuation = ({
  transcript,
  word,
}: {
  transcript: TranscriptJSON;
  word: TranscriptWord;
}) => {
  const modifiedWords: string[] = [];

  const newWord = togglePunctuation(word.text);

  let newTranscript = modifyWord({transcript, word, text: newWord});
  modifiedWords.push(newWord);

  const nextWord = getNextWord({transcript, word});
  if (nextWord) {
    if (newWord.endsWith('.') && !isCapitalized(nextWord.text)) {
      const newNextWord = capitalizeFirstLetter(nextWord.text);
      newTranscript = modifyWord({
        transcript: newTranscript,
        text: newNextWord,
        word: nextWord,
      });
      modifiedWords.push(newNextWord);
    } else if (!newWord.endsWith('.') && isCapitalized(nextWord.text)) {
      const newNextWord = lowercaseFirstLetter(nextWord.text);
      newTranscript = modifyWord({
        transcript: newTranscript,
        text: newNextWord,
        word: nextWord,
      });
      modifiedWords.push(newNextWord);
    }
  }

  return {newTranscript, modifiedWords};
};

/**
 * Replace the last occurrence in a string.
 */
function replaceLast(find: string, replace: string, string: string) {
  const lastIndex = string.lastIndexOf(find);
  if (lastIndex === -1) return string;

  const beginString = string.substring(0, lastIndex);
  const endString = string.substring(lastIndex + find.length);

  return beginString + replace + endString;
}

/**
 * Capitalizes first letter of a string
 */
const capitalizeFirstLetter = (str: string): string =>
  str.replace(/\S/, match => match.toUpperCase());

/**
 * Lowercase first letters of words in string.
 */
const lowercaseFirstLetter = (str: string): string =>
  str.replace(/\S/, match => match.toLowerCase());

/**
 * Check if first letter of a string is capitalized.
 */
const isCapitalized = (str: string): boolean => {
  if (str.length > 0) {
    const lettersInStr = str.replace(/\s/g, '');
    if (lettersInStr.length === 0) return false;
    return lettersInStr[0] === lettersInStr[0].toUpperCase();
  } else {
    return false;
  }
};

export const toggleCapitalization = (string: string) => {
  return isCapitalized(string)
    ? lowercaseFirstLetter(string)
    : capitalizeFirstLetter(string);
};

export const transcriptToSlateChildren = (transcript: TranscriptJSON): SlateElement[] => {
  return transcript.map(paragraph => {
    const paragraphText = paragraph.map((word: TranscriptWord) => word.text).join(' ');

    const transcriptParagraphChildren = {
      text: paragraphText,
      words: paragraph,
    };

    const transcriptParagraphChildrenWrapper = {
      children: [transcriptParagraphChildren],
    };

    return transcriptParagraphChildrenWrapper;
  });
};

type Selection = {
  start: SelectionPoint;
  end: SelectionPoint;
};

export const getWordsForSelection = (
  transcript: TranscriptJSON,
  selection: Selection,
) => {
  if (equal(selection.start, selection.end)) return;

  const startParagraph = transcript[selection.start.paragraph];
  const endParagraph = transcript[selection.end.paragraph];

  let isSpaceSelected = false;
  if (selection.start.paragraph === selection.end.paragraph) {
    if (selection.end.offset - selection.start.offset === 1) {
      isSpaceSelected =
        findInParagraph(startParagraph, ({endIndex}) => {
          return endIndex === selection.end.offset;
        }) || false;
    }
  }

  if (isSpaceSelected) return;

  const startWord = findInParagraph(startParagraph, ({word, startIndex, endIndex}) => {
    if (endIndex - 1 > selection.start.offset) return word;
  });

  const endWord = findInParagraph(endParagraph, ({word, startIndex, endIndex}) => {
    if (endIndex >= selection.end.offset) return word;
  });

  if (!startWord || !endWord) return;
  return {startWord, endWord};
};

function findInParagraph<T>(
  paragraph: TranscriptParagraph,
  iterator: (opts: {
    word: TranscriptWord;
    startIndex: number;
    endIndex: number;
  }) => T | undefined,
) {
  let characterCount = 0;
  for (const word of paragraph) {
    const newCharacterCount = characterCount + word.text.length + 1;

    const result = iterator({
      word,
      startIndex: characterCount,
      endIndex: newCharacterCount,
    });
    if (result != null) return result;

    characterCount = newCharacterCount;
  }
}

export const getClosestWordToPoint = (
  transcript: TranscriptJSON,
  point: SelectionPoint,
) => {
  const paragraph = transcript[point.paragraph];

  return findInParagraph(paragraph, ({word, endIndex}) => {
    if (endIndex - 1 > point.offset) return word;
  });
};

export const getWordAtPoint = (transcript: TranscriptJSON, point: SelectionPoint) => {
  const paragraph = transcript[point.paragraph];

  return findInParagraph(paragraph, ({word, startIndex}) => {
    if (point.offset > startIndex && point.offset < startIndex + word.text.length) {
      return word;
    }
  });
};

/**
 * Calculate the number of text characters appear
 * before a certain element in a deeply nested tree.
 */
export const findOffsetBeforeNode = (container: Element, target: Node) => {
  let offset = 0;
  let matchFound = false;

  for (const childNode of container.childNodes) {
    if (childNode === target) {
      matchFound = true;
      break;
    }

    if (childNode instanceof Text) {
      const nodeText = childNode.textContent?.trim();
      if (nodeText == null) break;

      offset += nodeText.length;
      if (nodeText.length) offset += 1;
    } else if (childNode instanceof Element) {
      if ((childNode as any).dataset.ignore) continue;

      const childResult = findOffsetBeforeNode(childNode, target);
      offset += childResult.offset;

      if (childResult.matchFound) {
        matchFound = true;
        break;
      }
    }
  }

  return {offset, matchFound};
};

export const getSelectionPoint = (elementOrNode: Node, localOffset: number) => {
  const element =
    elementOrNode instanceof HTMLElement ? elementOrNode : elementOrNode?.parentElement;
  if (!element) return;

  let paragraphEl: HTMLElement | null = null;

  let extraOffset = 0;
  if (element.matches('[data-type="paragraph"]')) {
    paragraphEl = element;
  } else {
    const matchingParent = element.closest('[data-type="paragraph"]');
    if (!matchingParent) return;
    if (!(matchingParent instanceof HTMLElement)) return;

    paragraphEl = matchingParent;
    extraOffset = findOffsetBeforeNode(matchingParent, elementOrNode).offset;
  }

  if (!paragraphEl) return;

  const container = element.closest('[data-type="container"]');
  if (!container) return;

  const paragraph = Array.from(container.children).indexOf(paragraphEl);
  const offset = localOffset + extraOffset;

  return {paragraph, offset, paragraphEl};
};

export const getSelectionRange = () => {
  const selection = window.getSelection();
  if (!selection) return;

  const anchorNode = selection.anchorNode;
  if (!anchorNode) return;

  const focusNode = selection.focusNode;
  if (!focusNode) return;

  const anchor = getSelectionPoint(anchorNode, selection.anchorOffset);
  if (!anchor) return;

  let focus = getSelectionPoint(focusNode, selection.focusOffset);
  if (!focus) return;

  if (focus.paragraph > anchor.paragraph && focus.offset === 0) {
    const previousParagraph = focus.paragraphEl.previousElementSibling;
    if (previousParagraph && previousParagraph instanceof HTMLElement) {
      focus = {
        paragraph: focus.paragraph - 1,
        offset: previousParagraph.innerText.length,
        paragraphEl: previousParagraph,
      };
    }
  }

  return {anchor, focus};
};

export type SelectionPoint = {paragraph: number; offset: number};
export type SelectionRange = {anchor: SelectionPoint; focus: SelectionPoint};

export const findInSlateValue = (
  slateValue: SlateElement[],
  iterator: (word: TranscriptWord) => boolean,
) => {
  for (let paragraphIndex = 0; paragraphIndex < slateValue.length; paragraphIndex++) {
    const paragraph = slateValue[paragraphIndex];

    for (let leafIndex = 0; leafIndex < paragraph.children.length; leafIndex++) {
      const leaf = paragraph.children[leafIndex];

      for (let wordIndex = 0; wordIndex < leaf.words.length; wordIndex++) {
        const word = leaf.words[wordIndex];

        if (iterator(word)) {
          return {paragraphIndex, leafIndex, wordIndex, word};
        }
      }
    }
  }
};

export type SlateWordPosition = NonUndefined<ReturnType<typeof findInSlateValue>>;

export const useActiveWord = (slateValue: SlateElement[], timeOffsetMs: number) => {
  return useTimeSelector(
    ({currentTime}) => {
      const now = currentTime + timeOffsetMs;

      return findInSlateValue(slateValue, word => {
        return word.end > now;
      });
    },
    [slateValue, timeOffsetMs],
  );
};

type EventCoordinates = {
  clientX: number;
  clientY: number;
};

const pointFromCoordinates = ({clientX, clientY}: EventCoordinates) => {
  let container: Node | undefined;
  let offset: number | undefined;

  if (document.caretRangeFromPoint) {
    const range = document.caretRangeFromPoint(clientX, clientY);
    // @ts-ignore
    container = range.startContainer;
    // @ts-ignore
    offset = range.startOffset;
    // @ts-ignore
  } else if (document.caretPositionFromPoint) {
    // @ts-ignore
    const range = document.caretPositionFromPoint(clientX, clientY);
    container = range?.offsetNode;
    offset = range?.offset;
  }

  if (!container || !offset) return;
  return {container, offset};
};

export const getWordAtCoordinates = (
  transcript: TranscriptJSON,
  coordinates: EventCoordinates,
) => {
  const range = pointFromCoordinates(coordinates);
  if (!range) return;

  const point = getSelectionPoint(range.container, range.offset);
  if (!point) return;

  return getClosestWordToPoint(transcript, point);
};

export const transcriptToString = (transcript: TranscriptJSON) => {
  const paragraphs = transcript.map(words => {
    return words.map(word => word.text).join(' ');
  });

  return paragraphs.join('\n\n');
};
