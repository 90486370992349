import {ComponentClass, useState} from 'react';
import ClickAwayListener from 'react-click-away-listener';
import {CustomPicker} from 'react-color';
import {
  ExportedColorProps,
  InjectedColorProps,
} from 'react-color/lib/components/common/ColorWrap';
import {ColorPicker} from '../../ui/ColorPicker';
import {HexInput} from '../../ui/HexInput';

const CustomColorInput = (props: InjectedColorProps) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  return (
    <div className="relative">
      <ClickAwayListener onClickAway={() => setPickerOpen(false)}>
        <div className="flex items-center">
          <HexInput onClick={() => setPickerOpen(true)} {...props} />
          {pickerOpen && (
            <div
              className="absolute top-0 z-20 h-0"
              style={{
                zIndex: 99999,
              }}
            >
              <div className="absolute bottom-4 -ml-4 w-56 rounded-md border bg-white p-3 shadow-lg">
                <ColorPicker {...props} />
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

const WrappedColorInput: ComponentClass<ExportedColorProps> =
  CustomPicker(CustomColorInput);
export {WrappedColorInput as CustomColorInput};
