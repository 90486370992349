import {createContext, Dispatch, FC, SetStateAction, useContext, useState} from 'react';

export const languages = [
  {label: 'English', id: 'en'},
  {label: 'Spanish', id: 'es'},
  {label: 'French', id: 'fr'},
  {label: 'German', id: 'de'},
  {label: 'Japanese', id: 'ja'},
  {label: 'Mandarin (simplified)', id: 'zh-CN'},
  {label: 'Mandarin (traditional)', id: 'zh-TW'},
  {label: 'Korean', id: 'ko'},
] as const;

export type LanguageId = typeof languages[number]['id'];

const LanguageContext = createContext<{
  language: LanguageId;
  setLanguage: Dispatch<SetStateAction<LanguageId>>;
}>({
  language: 'en',
  setLanguage: () => {},
});

export const LanguageProvider: FC = ({children}) => {
  const [language, setLanguage] = useState<LanguageId>('en');

  return (
    <LanguageContext.Provider value={{language, setLanguage}}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
