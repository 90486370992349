import {ReactNode, createContext, useContext, useReducer} from 'react';

type Action =
  | {type: 'open new folder modal'}
  | {type: 'close new folder modal'}
  | {type: 'open youtube upload modal'}
  | {type: 'close youtube upload modal'};
type Dispatch = (action: Action) => void;
type State = {
  isNewFolderOpen: boolean;
  isYoutubeUploadModalOpen: boolean;
};
type DashboardProviderProps = {children: ReactNode};

const initialDashboard = {
  isNewFolderOpen: false,
  isYoutubeUploadModalOpen: false,
} as State;

const DashboardStateContext =
  createContext<{state: State; dispatch: Dispatch} | undefined>(undefined);

function dashboardReducer(state: State, action: Action) {
  switch (action.type) {
    case 'open new folder modal': {
      return {...state, isNewFolderOpen: true};
    }
    case 'close new folder modal': {
      return {...state, isNewFolderOpen: false};
    }
    case 'open youtube upload modal': {
      return {...state, isYoutubeUploadModalOpen: true};
    }
    case 'close youtube upload modal': {
      return {...state, isYoutubeUploadModalOpen: false};
    }
    default: {
      throw new Error(`Unhandled action type: ${JSON.stringify(action)}`);
    }
  }
}

function DashboardProvider({children}: DashboardProviderProps) {
  const [state, dispatch] = useReducer(dashboardReducer, initialDashboard);

  const value = {state, dispatch};

  return (
    <DashboardStateContext.Provider value={value}>
      {children}
    </DashboardStateContext.Provider>
  );
}

function useDashboard() {
  const context = useContext(DashboardStateContext);
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
}

export {DashboardProvider, useDashboard};
