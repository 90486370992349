import {Modal, ModalButtons, ModalDescription} from 'ui/Modal';
import {
  UserUpload,
  useCreateUserUploadMutation,
  useListUserUploadsQuery,
  usePostYoutubeUserUploadMutation,
  useUserUploadQuery,
  userUploadAPI,
} from 'services/userUploadAPI';
import {YoutubeOembedJsonResponse, getYoutubeOembedJson} from 'api/userUploadsAPI';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import NotificationInfo from 'features/Notifications/NotificationInfo';
import PreviewImage from './PreviewImage';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {accountDetailsSelector} from 'features/selectors/authSelectors';
import {baseApi} from 'services/baseAPI';
import {postYoutubeVideoUpload} from 'api/userLibraryAPI';
import {store} from 'react-notifications-component';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useDashboard} from '../../DashboardContext';

export const YOUTUBE_REGEX =
  /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

export const YoutubeUploadModal = () => {
  const {dispatch: dashboardDispatch, state} = useDashboard();
  const {getAccessTokenSilently} = useAuthAlt();

  const [youtubeUrl, setYoutubeUrl] = useState<string>('');
  const [metadata, setMetadata] = useState<null | YoutubeOembedJsonResponse>(null);

  const youtubeMatch = youtubeUrl.match(YOUTUBE_REGEX);
  const youtubeId = youtubeMatch && youtubeMatch[2];
  const title = metadata?.title;

  const dispatch = useDispatch();

  const close = () => {
    dashboardDispatch({
      type: 'close youtube upload modal',
    });
    setYoutubeUrl('');
    setMetadata(null);
  };

  useEffect(() => {
    setYoutubeUrl('');
  }, []);

  useEffect(() => {
    const effect = async () => {
      if (!youtubeId) {
        return;
      }
      const youtubeResponse = await getYoutubeOembedJson(youtubeId);

      setMetadata(youtubeResponse);
    };
    effect();
  }, [youtubeId]);

  const {refetch: refetchList} = useListUserUploadsQuery(1);
  const [postYoutubeUpload] = usePostYoutubeUserUploadMutation();

  const submitToYoutube = (youtubeId: string, youtubeName: string) => {
    // console.log('youtubeName', youtubeName);
    // console.log('youtubeId', youtubeId);
    (async () => {
      const response = await postYoutubeUpload({
        youtubeVideoId: youtubeId,
        youtubeVideoName: youtubeName,
      });
      const status = response?.data?.status;

      if (status === 'success') {
        // refetchList();

        store.addNotification({
          ...NOTIFICATION_BASE,
          content: (
            <NotificationInfo
              title={'Youtube video already exists'}
              message={`Your video was moved to the top of your upload list.`}
            />
          ),
        });
      }

      if (status === 'requested youtube import') {
        // refetchList();
        store.addNotification({
          ...NOTIFICATION_BASE,
          content: (
            <NotificationInfo
              title={'Youtube video processing'}
              message={`Your video was requested and will appear in the upload list once it is ready. Videos can take upwards of 5 minutes to show up.`}
            />
          ),
        });
      }

      close();
    })();
  };

  return (
    <Modal
      title="Upload from Youtube"
      open={state.isYoutubeUploadModalOpen}
      onClose={close}
      size="small"
    >
      <ModalDescription>Paste a link to a Youtube Video</ModalDescription>
      <input
        value={youtubeUrl}
        onChange={e => {
          setYoutubeUrl(e.currentTarget.value);
        }}
        autoFocus
        placeholder="Youtube URL"
        type="text"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      />
      {title && metadata?.thumbnail_url && (
        <PreviewImage title={title} imgSrc={metadata?.thumbnail_url} />
      )}
      <ModalButtons
        confirm={{
          label: 'Upload',
          disabled: !title || !youtubeId,
          onClick: () => {
            if (youtubeId) {
              submitToYoutube(youtubeId, title ? title : 'Untitled video');
            }
          },
        }}
      />
    </Modal>
  );
};
