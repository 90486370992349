import {DashParams, routePaths} from 'routes/routesHelper';
import {StringParam, encodeQueryParams, useQueryParam} from 'use-query-params';

import {Link} from 'react-router-dom';
import {SearchIcon} from '@heroicons/react/outline';
import {format} from 'date-fns';
import {stringify} from 'query-string';
import {useSelector} from 'react-redux';
import {userLibrarySelector} from 'features/selectors/userLibrarySelectors';

function unique(array: any[], propertyName: string) {
  return array.filter(
    (e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i,
  );
}

export const RecentSearchResults = () => {
  const {accountSearchQueries, loadingAccountSearchQueries} =
    useSelector(userLibrarySelector);

  if (!accountSearchQueries || accountSearchQueries.length === 0) {
    return null;
  }

  if (loadingAccountSearchQueries) {
    return (
      <div className="space-y-3">
        <div className="h-6 w-28 animate-pulse bg-gray-100" />
        <ul className="space-y-2">
          {[1, 2, 3, 4].map(message => (
            <li key={message} className="h-8 w-full animate-pulse bg-gray-100" />
          ))}
        </ul>
      </div>
    );
  }

  const recentSearchQueries = unique(accountSearchQueries, 'query').slice(0, 6);

  const handleLinkClick = () => {
    window.analytics.track('User clicked recent history link');
  };

  return (
    <div>
      <h3 className="-ml-1 mb-2 truncate px-3 font-medium text-gray-600">
        Recent searches
      </h3>
      <ul className="divide-y divide-gray-200">
        {recentSearchQueries.map(searchQuery => (
          <li
            key={searchQuery.id}
            className="relative bg-white py-3 px-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50"
          >
            <div className="flex justify-between space-x-3">
              <div className="min-w-0 flex-1">
                <Link
                  onClick={handleLinkClick}
                  to={`/${routePaths.dashboard}/${DashParams.SearchParam}?${stringify(
                    encodeQueryParams(
                      {
                        q: StringParam,
                      },
                      {q: searchQuery.query},
                    ),
                  )}`}
                  className="flex items-center justify-start space-x-2 focus:outline-none"
                >
                  <SearchIcon className="h-4 w-4 text-gray-300" />
                  <div className="flex">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="truncate text-sm font-medium text-gray-900">
                      {searchQuery.query}
                    </p>
                  </div>
                </Link>
              </div>
              <time
                dateTime={searchQuery.created_at}
                className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
              >
                {format(new Date(searchQuery.created_at), 'P')}
              </time>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
