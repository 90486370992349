import {DimensionType} from 'features/types/canvasItemsSlice';
import {FFT_MAX_VALUE} from '../constants';
import {expandFftFrame} from '../helper';
import {useMemo} from 'react';

export const CircleLineCentrifugal = ({
  frequencyData,
  dimensions,
}: {
  frequencyData: number[];
  dimensions: DimensionType;
}) => {
  const fftFrame = useMemo(() => expandFftFrame(frequencyData), [frequencyData]);
  const {width, height} = dimensions;
  const referenceTranslate = (width > height ? height : width) / (Math.PI * 2);

  return (
    <div>
      {fftFrame.map((value, index) => {
        const rotatePoint = 360 * (index / fftFrame.length);
        const barHeight = (value * (height / FFT_MAX_VALUE) + 30) * 0.4;
        const elWidth = width / fftFrame.length - 8;
        return (
          <div
            key={index}
            className="rounded-md ease-out"
            style={{
              transform: `rotate(${rotatePoint}deg) translateY(-${referenceTranslate}px)  `,
              position: 'absolute',
              transformOrigin: 'center 100%',
              left: `${width / 2 - elWidth}px`,
              top: `${height / 2 - barHeight}px`,
              backgroundColor: 'white',
              height: `${barHeight}px`,
              width: `${elWidth}px`,
            }}
          />
        );
      })}
    </div>
  );
};
