import cn from 'classnames';
export const Card = ({children, hideDivider = false}) => {
  return (
    <div
      className={cn('overflow-hidden rounded-lg bg-white shadow', {
        'divide-y divide-gray-200': !hideDivider,
      })}
    >
      {children}
    </div>
  );
};
