import {Plus, X} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import {Button} from 'ui/Button';
import {IconButton} from 'ui/IconButton';
import {ListContainer} from 'features/Dashboard/DashboardPage/ContentBrandKit/utils';
import {TextInput} from 'ui/TextInput';
import {accountDetailsSelector} from 'features/selectors/authSelectors';
import classNames from 'classnames';
import {getAuthToken} from 'services/utils';
import {sendUserInvitesFromWelcomeFlow} from 'features/authSlice';
import {showErrorNotification} from 'features/Common/utils';

export const InviteTeamForm = ({
  onComplete,
  title = 'Invite your co-workers to collaborate in Milk Video',
  buttonLayout = 'full-width',
}: {
  onComplete: (count: number) => void;
  title?: string;
  buttonLayout?: 'full-width' | 'minimal';
}) => {
  const [emailInput, setEmailInput] = useState('');
  const [emails, setEmails] = useState<string[]>([]);

  const addEmail = () => {
    if (!emailInput) return;

    if (!emails.includes(emailInput)) {
      setEmails([...emails, emailInput]);
    }

    setEmailInput('');
  };

  useEffect(() => {
    window.analytics.track('[App] InviteTeam loaded');
  }, []);

  const noInvites = emails.length === 0;

  const dispatch = useDispatch();
  const {email, accountId} = useSelector(accountDetailsSelector);

  const submit = async () => {
    if (noInvites) {
      onComplete(0);
      return;
    }

    if (!email) throw new Error('user email is undefined');
    if (!accountId) throw new Error('user accountId is undefined');

    const token = await getAuthToken();

    await new Promise<void>((resolve, reject) => {
      dispatch(
        sendUserInvitesFromWelcomeFlow({
          token,
          email,
          accountId,
          invitedEmails: emails,
          callback: error => {
            if (error) reject(error);
            else resolve();
          },
        }),
      );
    });

    onComplete(emails.length);
  };

  const handleAddClick = () => {
    addEmail();
    window.analytics.track('[App] User invite "add email" clicked from InviteTeam');
  };

  const handleFinishClick = async () => {
    await submit().catch(error => {
      showErrorNotification({
        title: 'Something went wrong',
        message: error.message,
        error,
      });
    });
    if (noInvites) {
      window.analytics.track('[App] User invite "finish later" clicked from InviteTeam');
    } else {
      window.analytics.track('[App] User invite "send invite" clicked from InviteTeam');
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div
        className={classNames({
          'font-medium': buttonLayout === 'full-width',
          'text-sm font-semibold': buttonLayout === 'minimal',
        })}
      >
        {title}
      </div>
      <div
        className={classNames({
          'space-y-2': buttonLayout === 'full-width',
        })}
      >
        <ListContainer className="!py-0" size="small">
          {emails.map(email => (
            <li key={email} className="group flex items-center justify-between py-1">
              <div>{email}</div>
              <IconButton
                variant="inline"
                size="small"
                onClick={() => setEmails(emails.filter(e => e !== email))}
                _className="opacity-0 group-hover:opacity-100 transition-opacity"
                label={`Remove ${email}`}
                destructive
                icon={X}
              />
            </li>
          ))}
        </ListContainer>
        <div className="flex items-center space-x-2">
          <TextInput
            type="email"
            name="email"
            value={emailInput}
            onChange={setEmailInput}
            placeholder="Type a co-worker's email address"
            autoFocus
            onEnterKey={() => addEmail()}
          />
        </div>
      </div>
      {buttonLayout === 'full-width' && (
        <>
          <div className="flex-1" />
          <Button
            variant={noInvites ? 'primary' : !emailInput ? 'inline' : 'primary'}
            disabled={!emailInput}
            leftIcon={Plus}
            onClick={handleAddClick}
          >
            {!emailInput ? 'Enter email above' : 'Invite with email'}
          </Button>
          <Button
            variant={noInvites ? 'inline' : !emailInput ? 'primary' : 'secondary'}
            onClick={handleFinishClick}
          >
            {noInvites
              ? 'Finish later'
              : `Send ${emails.length} ${emails.length === 1 ? 'invite' : 'invites'}`}
          </Button>
        </>
      )}
      {buttonLayout === 'minimal' && (
        <div className="flex justify-end">
          <Button
            variant={noInvites ? 'primary' : !emailInput ? 'inline' : 'primary'}
            disabled={!emailInput}
            onClick={handleAddClick}
            _className="!text-xs"
          >
            {!emailInput ? 'Enter email above' : 'Share'}
          </Button>
        </div>
      )}
    </div>
  );
};
