import PropTypes from 'prop-types';

export const Field = ({ label, children }) => (
  <div>
    {label && <div className="block text-sm font-medium text-gray-700">{label}</div>}
    <div>{children}</div>

  </div>
);

Field.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

export default Field;
