import {Card} from 'features/Common/Card';
import {CardHeader} from 'features/Common/CardHeader';
import {authAccountAccountSelector} from 'features/selectors/authSelectors';
import {useSelector} from 'react-redux';
import {useState} from 'react';

export function SettingsCompanyNameCard() {
  const account = useSelector(authAccountAccountSelector);
  const [companyName, setCompanyName] = useState(account?.name);
  return (
    <Card>
      <CardHeader subtitle="" title="Company Name" />
      <div className="px-4 py-5 sm:p-6">
        <label htmlFor="name" className="sr-only">
          Company
        </label>
        <input
          type="text"
          name="name"
          disabled
          className="block w-full max-w-md rounded-md border-none text-gray-700 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder={account?.name}
          value={companyName}
          onChange={evt => {
            setCompanyName(evt.target.value);
          }}
        />
      </div>
    </Card>
  );
}
