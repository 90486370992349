import {ComponentClass} from 'react';
import {CustomPicker} from 'react-color';
import {Alpha, Hue, Saturation} from 'react-color/lib/components/common';
import {
  ExportedColorProps,
  InjectedColorProps,
} from 'react-color/lib/components/common/ColorWrap';
import styled from 'styled-components';

type ColorPickerProps = {
  className?: string;
  alpha?: boolean;
};

const Container = styled.div`
  .hue-horizontal {
    border-radius: 12px;
  }
`;

const AlphaContainer = styled.div`
  > div > div {
    border-radius: 12px;
  }
`;

const SaturationPointer = () => (
  <div className="-mt-2 -ml-1.5 h-4 w-4 rounded-full border" />
);

const SliderPointer = () => (
  <div className="-ml-2 -mt-0.5 h-4 w-4 rounded-full bg-white shadow" />
);

const ColorPicker = ({
  className,
  alpha = true,
  ...props
}: InjectedColorProps & ColorPickerProps) => {
  return (
    <Container className="relative space-y-2">
      <div className="aspect-w-2 aspect-h-1 relative w-full overflow-hidden rounded-md">
        {/* @ts-ignore */}
        <Saturation {...props} pointer={SaturationPointer} />
      </div>
      <div className="relative h-3 rounded-full">
        {/* @ts-ignore */}
        <Hue {...props} pointer={SliderPointer} />
      </div>
      {alpha && (
        <AlphaContainer className="relative h-3 rounded-full">
          {/* @ts-ignore */}
          <Alpha {...props} pointer={SliderPointer} />
        </AlphaContainer>
      )}
    </Container>
  );
};

const WrappedColorPicker: ComponentClass<ExportedColorProps & ColorPickerProps> =
  CustomPicker(ColorPicker);
export {WrappedColorPicker as ColorPicker};
