import {CanvasItem, CanvasScene} from 'features/types/canvasItemsSlice';

import {AccountFontUploadsType} from 'features/types/userLibrarySlice';
import {BASE_URL} from 'constants/environment';
import {ProjectOrientation} from 'features/Dashboard/common/CreateProjectModal';
import {ProjectRecord} from 'services/projectAPI';
import {PromiseType} from 'utility-types';
import {SceneFrameSliceProps} from 'features/sceneFrameSlice';
import {SyncStateDataType} from 'features/serverSyncSlice';
import axios from 'axios';
import {getLatestProjectSync} from './projectClientSyncAPI';
import {requestHeader} from './helpers';

export type GetNewProjectBody = {
  project: {email: string; name: string};
};

export async function getNewProject(token: string, email: string, name?: string) {
  const projectEndpoint = `${BASE_URL}/api/v1/projects`;
  const response = await axios.post(
    projectEndpoint,
    {project: {email, name}} as GetNewProjectBody,
    requestHeader(token),
  );
  return response.data as ProjectRecord;
}

export async function postNewProjectName(
  token: string,
  projectId: string,
  projectName: string,
) {
  const projectEndpoint = `${BASE_URL}/api/v1/projects/${projectId}/update_name`;
  const response = await axios.post(
    projectEndpoint,
    {
      project: {
        name: projectName,
        project_id: projectId,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

export async function getProjectData(token: string, bucketKey: string) {
  const projectEndpoint = `${BASE_URL}/api/v1/projects/${bucketKey}`;
  const response = await axios.get(projectEndpoint, requestHeader(token));
  return response.data;
}

export type InitiateProjectBody = {
  project_id: string;
  email: string;
  client_sync_version?: number;
};

export type InitiateProjectResponse = PromiseType<ReturnType<typeof initiateProject>>;

export async function initiateProject(
  token: string,
  projectId: string,
  email: string | null,
  clientSyncVersion?: number,
) {
  const projectEndpoint = `${BASE_URL}/api/v1/projects/initiate`;
  const response = await axios.post(
    projectEndpoint,
    {
      project_id: projectId,
      email: email,
      client_sync_version: clientSyncVersion,
    } as InitiateProjectBody,
    requestHeader(token),
  );
  const responseData = response.data;
  return responseData as {
    project: ProjectRecord;
    account_font_uploads: AccountFontUploadsType[];
    project_id: string;
    canvas_items: Record<string, CanvasItem>;
    canvas_scenes: Record<string, CanvasScene>;
    scene_frame: SceneFrameSliceProps;
    client_sync: {
      client_state: SyncStateDataType;
      client_sync_version: number;
    };
  };
}

export type NewProjectOptions = {
  projectName: string;
  orientation: ProjectOrientation;
};

export async function postCreateNewProject({
  token,
  projectOptions,
}: {
  token: string;
  projectOptions: NewProjectOptions;
}) {
  const projectEndpoint = `${BASE_URL}/api/v2/projects`;
  const response = await axios.post(
    projectEndpoint,
    {
      project_options: {
        project_name: projectOptions.projectName,
        orientation: projectOptions.orientation,
      },
    },
    requestHeader(token),
  );
  return response.data as {
    project: ProjectRecord[];
    account_font_uploads: AccountFontUploadsType[];
    project_id: string;
    canvas_items: Record<string, CanvasItem>;
    canvas_scenes: Record<string, CanvasScene>;
    scene_frame: SceneFrameSliceProps;
    client_sync: {
      client_state: SyncStateDataType;
      client_sync_version: number;
    };
  };
}

export async function getUserProjects(token: string) {
  const projectsEndpoint = `${BASE_URL}/api/v1/projects/list`;
  const response = await axios.post(projectsEndpoint, {}, requestHeader(token));
  return response.data;
}

export async function submitProjectDelete(token: string, projectId: string) {
  const url = `${BASE_URL}/api/v1/projects/${projectId}/delete`;

  const response = await axios.post(url, {}, requestHeader(token));
  return response.data;
}

export async function submitMultipleProjectsDelete(token: string, projectIds: string[]) {
  const url = `${BASE_URL}/api/v1/projects/delete`;

  const response = await axios.post(
    url,
    {
      project_ids: projectIds,
    },
    requestHeader(token),
  );
  return response.data;
}

export async function submitProjectDuplicate(token: string, projectId: string) {
  const urlDuplicate = `${BASE_URL}/api/v1/projects/${projectId}/duplicate`;
  const responseProject = await axios.post(urlDuplicate, {}, requestHeader(token));
  const responseClientSync = await getLatestProjectSync({token, projectId});

  return {
    userProject: responseProject.data as ProjectRecord,
    latestProjectSync: responseClientSync,
  };
}

export async function getUsersProjects(userId: string) {
  const url = `${BASE_URL}/api/v1/projects/${userId}.json`;

  return await axios.get(url);
}
