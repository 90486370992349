import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Layers, X} from 'react-feather';
import {
  removeCanvasItems,
  updateCanvasItemsPositionState,
  updateItemOrders,
} from 'features/canvasItemsSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useRef, useState} from 'react';

import {CanvasButton} from './components/CanvasButton';
import CanvasItemNameField from './components/AppCanvas/CanvasItemNameField';
import {IconButton} from 'ui/IconButton';
import {addSelectedItem} from 'features/canvasStateSlice';
import {canvasItemsSelectedItemIdsSelector} from 'features/selectors/canvasStateSelectors';
import cn from 'classnames';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';

type CanvasItemLayerListProps = {
  projectId: string;
};

export function CanvasItemLayerList({projectId}: CanvasItemLayerListProps) {
  const timelineEl = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);

  const {activeScene} = useScenes();
  const items = activeScene.items;

  const [isListOpen, setIsListOpen] = useState(false);

  const setIsOpen = (isOpen: boolean) => {
    hideLayerNotification();
    setIsListOpen(isOpen);
  };

  const itemRows = () =>
    Object.keys(items).sort((a, b) => items[b].order - items[a].order);

  const selectRow = (itemKey: string) => {
    dispatch(
      addSelectedItem({
        item: items[itemKey],
        selectedItemId: itemKey,
      }),
    );
  };

  const hideLayerNotification = () => {};
  if (itemRows().length === 0) {
    return null;
  }

  // a little function to help us with reordering the result
  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    hideLayerNotification();
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    hideLayerNotification();
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItemsRow = reorder(
      itemRows(),
      result.source.index,
      result.destination.index,
    );

    dispatch(updateItemOrders({projectId, newRowOrder: newItemsRow}));
  };

  const onDeleteLayer = (itemKey: string) => {
    dispatch(updateCanvasItemsPositionState());
    dispatch(
      removeCanvasItems({
        projectId,
        ids: [itemKey],
      }),
    );
  };

  return (
    <div
      id="workspace-main"
      className="absolute bottom-0 ml-2 select-none text-sm focus:outline-none"
      style={{zIndex: 250}}
    >
      <>
        <span className="relative mb-2 inline-flex rounded-md shadow-sm">
          <CanvasButton onClick={() => setIsOpen(!isListOpen)} leftIcon={Layers}>
            {isListOpen ? 'Close layers' : 'Open layers'}
          </CanvasButton>
        </span>
        <div
          ref={timelineEl}
          className={cn('relative', {
            hidden: !isListOpen,
          })}
        >
          <div>
            {itemRows().length && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided: any) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="mb-1 space-y-1"
                    >
                      {itemRows().map((itemKey, index) => {
                        return (
                          <Draggable
                            key={`timeline-item-row-${itemKey}`}
                            draggableId={`timeline-item-row-${itemKey}-id`}
                            index={index}
                          >
                            {(provided: any) => (
                              <div
                                className="group flex items-center"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="align-center flex flex-row items-center justify-start">
                                  <div
                                    className={cn(
                                      'flex w-full flex-row justify-between rounded border bg-white py-1 pl-1 pr-2 text-center text-blue-700',
                                      selectedItemIds.includes(itemKey) &&
                                        'border-indigo-400',
                                    )}
                                    onClick={() => {
                                      selectRow(itemKey);
                                    }}
                                  >
                                    <div className="flex h-full w-full items-center">
                                      <CanvasItemNameField
                                        projectId={projectId}
                                        itemKey={itemKey}
                                      >
                                        <span />
                                      </CanvasItemNameField>
                                    </div>
                                  </div>
                                </div>
                                <IconButton
                                  onClick={() => onDeleteLayer(itemKey)}
                                  variant="inline"
                                  size="small"
                                  _className="opacity-0 group-hover:opacity-100 focus:opacity-100 transition-opacity mx-1"
                                  label="Delete layer"
                                  destructive
                                  icon={X}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
        </div>
      </>
    </div>
  );
}
