import {DesignState, Step, defaultDesign, steps} from './Constants';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {fetchWizardState, useUpdateWizardStateMutation} from 'services/wizardStateAPI';
import {podcastAudiogramRoute, routePaths} from 'routes/routesHelper';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {SerializedSelection} from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/TrimTimeline';
import {createContainer} from 'unstated-next';
import {usePreventWindowClose} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/NewProjectWizard/utils';
import {useRtkReducer} from 'features/Common/utils';

export type TempClip = {
  range: SerializedSelection;
};

export type Download = {
  projectId: string;
  downloadId: string;
};

export type WizardStateType = {
  step: Step;
  clip: TempClip | null;
  design: DesignState;
  uploadId: string | null;
  download: Download | null;
};

const initialState: WizardStateType = {
  step: steps[0],
  clip: null,
  uploadId: null,
  design: defaultDesign,
  download: null,
};

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<WizardStateType>) => {
      return action.payload;
    },
    updateDesign<Key extends keyof DesignState>(
      state: WizardStateType,
      action: PayloadAction<{property: Key; value: DesignState[Key]}>,
    ) {
      const {property, value} = action.payload;
      state.design[property] = value;
    },
    setDesign(state, action: PayloadAction<{design: DesignState}>) {
      state.design = action.payload.design;
    },
    setClip(state, action: PayloadAction<TempClip>) {
      state.clip = action.payload;
    },
    setUpload(state, action: PayloadAction<string | null>) {
      state.uploadId = action.payload;
      state.clip = null;
    },
    nextStep(state) {
      const index = steps.indexOf(state.step);
      // console.log('index', index);
      state.step = steps[index + 1];
    },
    previousStep(state) {
      const index = steps.indexOf(state.step);
      if (index === 0) return;
      state.step = steps[index - 1];
    },
    setStep(state, action: PayloadAction<{step: Step}>) {
      state.step = action.payload.step;
    },
    setDownload(state, action: PayloadAction<Download>) {
      state.download = action.payload;
    },
  },
});

export const useWizardStateId = () => {
  const {wizardStateId} = useParams<{wizardStateId?: string}>();

  const navigate = useNavigate();
  const setWizardStateId = useCallback(
    (id: string | null) => {
      const base = `/${routePaths.create}/${podcastAudiogramRoute}`;
      const url = id ? `${base}/${id}` : `${base}`;
      // console.log('window.location.pathname', window.location.pathname);
      // console.log('base', base);
      // console.log('id', id);
      // console.log('url', url);
      navigate(url, {replace: true});
    },
    [navigate],
  );

  return [wizardStateId, setWizardStateId] as const;
};

const WizardState = createContainer(() => {
  const {state, actions} = useRtkReducer(wizardSlice, initialState);
  const [wizardStateId, setWizardStateId] = useWizardStateId();
  const navigate = useNavigate();

  const [rehydrated, setRehydrated] = useState(false);
  useEffect(() => {
    if (rehydrated) return;

    if (!wizardStateId) {
      setRehydrated(true);
      return;
    }

    fetchWizardState({id: wizardStateId})
      .then(result => {
        actions.setState(result.state);
      })
      .catch(error => {
        console.error(error);
        setWizardStateId(null);
      })
      .finally(() => {
        setRehydrated(true);
      });
  }, [actions, rehydrated, navigate, setWizardStateId, wizardStateId]);

  const [updateWizardState] = useUpdateWizardStateMutation();
  const isSyncing = useRef(false);

  useEffect(() => {
    if (!wizardStateId) return;

    isSyncing.current = true;

    const timeout = setTimeout(() => {
      updateWizardState({state, id: wizardStateId});
      isSyncing.current = false;
    }, 500);

    return () => {
      isSyncing.current = false;
      clearTimeout(timeout);
    };
  }, [state, updateWizardState, wizardStateId]);

  usePreventWindowClose(() => isSyncing.current);

  return {
    state,
    rehydrated,
    ...state,
    ...actions,
  };
});

export const useWizardState = WizardState.useContainer;
export const WizardStateProvider = WizardState.Provider;

export const useDesign = () => {
  const {design, setDesign, updateDesign} = useWizardState();

  return {
    ...design,
    setDesign,
    updateDesign,
  };
};
