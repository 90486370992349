import { ChatIcon, PencilAltIcon, PlusIcon } from '@heroicons/react/solid';
import { DashParams, routePaths } from 'routes/routesHelper';
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  RecordingForm,
  useCreateRecordingFormMutation,
  useRecordingFormsQuery,
} from 'services/recordingFormsAPI';

import { Button } from 'ui/Button';
import { CreateRecordingFormModal } from './CreateRecordingFormModal';
import { CreateRecordingModal } from 'features/Dashboard/DashboardPage/ContentRecordingForms/CreateRecordingModal';
import { DashboardContentSkeleton } from 'features/Dashboard/common/DashboardContentSkeleton';
import { DashboardContentWrap } from 'features/Dashboard/common/DashboardContentWrap';
import { DashboardHead } from 'features/Dashboard/common/DashboardHead';
import { Loading } from 'features/EditorCanvas/Sidebar/views/Assets/Loading';
import { RecordingFormSessionContext } from './RecordingFormSessionContext';
import { RecordingSubmission } from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import { User } from 'react-feather';
import classNames from 'classnames';
import cn from 'classnames';
import { useFeatureFlag } from 'features/hooks/useFeatureFlag';
import { useRecordingFormContactsQuery } from 'services/recordingContactsAPI';
import { useRecordingFormSubmissionsQuery } from 'services/recordingSubmissionsAPI';
import { useState } from 'react';
import { useWindowHeight } from '@react-hook/window-size';

export const RecordingFormsContainer = () => {
  const location = useLocation();

  const isTestimonialFormActive = useFeatureFlag('testimonial-recording-form');

  const windowHeight = useWindowHeight();

  const { isCreateRecordingModalOpen, setIsCreateRecordingModalOpen } =
    RecordingFormSessionContext.useContainer();

  const handleCreateRecordingForm = async () => {
    setIsCreateRecordingModalOpen(true);
  };
  const navigate = useNavigate();

  const { data: recordingFormsData, isLoading, isSuccess } = useRecordingFormsQuery();
  const {
    data: recordingSubmissionsData,
    isLoading: isLoadingSubmissions,
    isSuccess: isSuccessSubmissions,
  } = useRecordingFormSubmissionsQuery();


  const isAllSubmissionsActive =
    location.pathname === `/${routePaths.dashboard}/${DashParams.RecordingFormParams}`;

  if (isLoading || isLoadingSubmissions) {
    return <Loading />;
  }

  if (!isTestimonialFormActive) {
    return (
      <>
        <DashboardContentSkeleton isLoading={false}>
          <DashboardContentWrap
            className={cn(
              'flex h-full w-full min-w-full flex-col items-center justify-start py-12 align-middle',
            )}
          >
            <div className="mx-auto max-w-md text-center">
              <div className="h-68">
                <img
                  src={'https://assets-milk.s3.amazonaws.com/share/video-testimonial.jpg'}
                />
              </div>
              <h3 className="mt-2 py-8 text-xl font-medium text-gray-900">
                You don't have access, yet.
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Recording forms is currently in beta. Forms will help to collect customer
                testimonials, employee recordings, and other user generated video content.
              </p>
              <p className="mt-4 text-sm text-gray-500">
                To get early access, please reach out to support.
              </p>
              <div className="mt-6">
                <Button
                  onClick={() => {
                    !!window.Intercom &&
                      window.Intercom('showNewMessage', 'Hi! I have some feedback');
                  }}
                  variant={'primary'}
                  leftIcon={ChatIcon}
                >
                  Request early access
                </Button>
              </div>
            </div>
          </DashboardContentWrap>
        </DashboardContentSkeleton>
      </>
    );
  }

  return (
    <>
      <CreateRecordingModal
        isOpen={isCreateRecordingModalOpen}
        closeModal={() => {
          setIsCreateRecordingModalOpen(false);
        }}
      />
      <DashboardContentSkeleton isLoading={false}>
        <DashboardContentWrap
          className={cn(
            'flex h-full w-full min-w-full flex-col items-center justify-start align-middle',
          )}
        >
          <div className="flex h-full w-full flex-col">
            {isSuccess &&
              isSuccessSubmissions &&
              (recordingFormsData.length ? (
                <div
                  className="relative z-0 flex flex-1 overflow-hidden"
                  style={{ height: windowHeight }}
                >
                  <div className="bg-white lg:flex-shrink-0 lg:border-r lg:border-gray-200 ">
                    <div className="flex h-full w-52 flex-col py-4">
                      <div className="flex flex-col space-y-2 border-b border-gray-200 px-3 pb-3">
                        <NavLink
                          to={`/${routePaths.dashboard}/${DashParams.RecordingFormParams}`}
                        >
                          {({ isActive }) => (
                            <Button
                              variant="inline"
                              _className={classNames('!text-xs', {
                                '!bg-gray-100': isActive,
                              })}
                              fullWidth
                              alignText="left"
                              leftIcon={ChatIcon}
                            >
                              All submissions
                            </Button>
                          )}
                        </NavLink>

                        <Button
                          variant="tertiary"
                          _className="!text-xs"
                          fullWidth
                          alignText="left"
                          leftIcon={PlusIcon}
                          onClick={handleCreateRecordingForm}
                        >
                          Create new form
                        </Button>
                      </div>

                      <RecordingFormsList
                        recordingSubmissionsData={recordingSubmissionsData}
                        recordingFormsData={recordingFormsData}
                      />
                    </div>
                  </div>
                  <div className="w-full bg-gray-100">
                    <Outlet />
                  </div>
                </div>
              ) : (
                <div className="w-full py-16">
                  <HowItWorksThreeColumns />
                </div>
              ))}
          </div>
        </DashboardContentWrap>
      </DashboardContentSkeleton>
    </>
  );
};

const RecordingFormsList = ({
  recordingFormsData,
  recordingSubmissionsData,
}: {
  recordingFormsData: RecordingForm[];
  recordingSubmissionsData: RecordingSubmission[];
}) => {
  const { recordingFormId } = useParams() as Record<string, string>;
  return (
    <div className="flex flex-col divide-y overflow-y-scroll">
      {recordingFormsData?.map(recordingForm => {
        const formSubmissions = recordingSubmissionsData.filter(
          submission =>
            recordingForm.id === submission.user_recording_interaction_form_id,
        );
        return (
          <Link
            key={recordingForm.id}
            to={`/${routePaths.dashboard}/${DashParams.RecordingFormParams}/${recordingForm.id}`}
          >
            <div
              className={classNames(
                'flex cursor-pointer select-none items-center py-3 px-4 hover:bg-gray-100',
                recordingFormId == recordingForm.id && 'bg-gray-100',
              )}
            >

              <div className=" flex-auto items-center">
                <p
                  className={classNames(
                    'flex justify-between text-xs',
                    recordingFormId === recordingForm.id
                      ? 'text-gray-900'
                      : 'text-gray-700',
                  )}
                >
                  <span>{recordingForm.title}</span>
                  <span className="inline-flex items-center">
                    <span>
                      <span
                        style={{ fontSize: 9 }}
                        className="inline-flex items-center rounded-full bg-gray-200 px-2 py-0.5 text-gray-800"
                      >
                        {formSubmissions.length}
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

const EmptyRecordingForms = () => {

  const { setIsCreateRecordingModalOpen } =
    RecordingFormSessionContext.useContainer();

  const handleCreateRecordingForm = async () => {
    setIsCreateRecordingModalOpen(true);
  };

  return (
    <div className="text-center mb-6">
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new recording form.
      </p>
      <div className="mt-6">
        <Button
          size='large'
          _className='text-xl'
          onClick={handleCreateRecordingForm}
          variant={'primary'}
          leftIcon={PlusIcon}
        >
          Create recording form
        </Button>
      </div>
    </div>
  );
};

const features = [
  {
    name: '1. Send a link for customers to record',
    description:
      'Choose your questions and send them to your customers to record their individual responses.',
    headImage: 'https://milk-website-assets.s3.amazonaws.com/export-link.jpg',
  },
  {
    name: '2. Edit the raw footage within minutes',
    description:
      'Edit your videos in Milk by taking advantage of our video templates and powerful design editor.',
    headImage: 'https://milk-website-assets.s3.amazonaws.com/export-maker.jpg',
  },
  {
    name: '3. Distribute across all platforms with ease',
    description:
      'Create multiple variations of your videos (eg audiogram) to test visuals or to simply size appropriately for all social channels.',
    headImage: 'https://milk-website-assets.s3.amazonaws.com/export-distribute.jpg',
  },
];

function HowItWorksThreeColumns() {
  return (
    <>
      <div className="pt-4">
        <div className="mx-auto items-start justify-start px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="flex flex-col justify-start space-y-8 pb-12 md:pb-0">
            <h2 className="pt-8 text-center font-display text-4xl font-bold">
              Video recordings.
            </h2>
          </div>
        </div>
      </div>
      <EmptyRecordingForms />
      <div className="pb-12">
        <div className="mx-auto max-w-xl items-start justify-start px-4 sm:px-6 lg:max-w-6xl lg:px-8">
          <dl className="space-y-10 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            {features.map((feature, index) => (
              <div key={feature.name} className="overflow-hidden bg-white py-3 px-6">
                <dt>
                  <div className="mt-4 space-y-4">
                    <div className="flex">
                      <div className="rounded-lg overflow-hidden mx-auto w-80 items-center justify-center">
                        <img src={feature.headImage} alt={feature.name} />
                      </div>
                    </div>
                    <p className="text-coolgray-900 font-display text-xl font-semibold">
                      {feature.name}
                    </p>
                  </div>
                </dt>
                <dd className="font-body text-m mt-2 text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </>
  );
}
