import { BASE_REDIRECT_URL, env } from 'constants/environment';
import {
  DashParams,
  focusedFlowRoute,
  podcastAudiogramRoute,
  routePaths,
} from 'routes/routesHelper';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ReactElement, Suspense, useEffect } from 'react';

import { AccountCableSubscribeWrap } from 'pages/AppEntrance/Account/AccountCableSubscribeWrap';
import { AccountCheckWrap } from 'pages/AppEntrance/Account/AccountCheckWrap';
import { AppWrap } from 'AppWrap';
import { AuthProviderWrapper } from 'features/Auth/AuthProviderWrapper';
import { Authenticate } from 'pages/Auth/Authenticate';
import { IdentifyApiAccountWrap } from 'pages/AppEntrance/Account/IdentifyApiAccountWrap';
import { IdentifyAuthenticationWrap } from 'pages/AppEntrance/User/IdentifyAuthenticationWrap';
import { NotificationWrap } from 'pages/AppEntrance/User/NotificationWrap';
import { RequireLogin } from 'features/Auth/Stytch';
import { RoutesCreate } from './routes/RoutesCreate';
import { RoutesDashboardRelated } from './routes/RoutesDashboard';
import { RoutesProgramaticRelated } from './routes/RoutesProgramatic';
import { RoutesShare } from 'routes/RoutesShare';
import { RoutesUserRelated } from 'routes/RoutesUserRelated';

const { user, share, create, dashboard, payments, canvas, robot, authenticate } =
  routePaths;

const faviconSrc = env === 'production' ? '/favicon.png?v=3' : '/favicon-dev.png?v=3';

export const RouteMigration = ({ prefix }: { prefix: string }) => {
  // console.log('RouteMigration', prefix);
  const { search } = useLocation();
  const location = useLocation();
  const oldPath = location.pathname;
  const newPath = `/${prefix}${oldPath}${search}`;

  return <Navigate replace to={newPath} />;
};

export const RouteReplace = ({ before, after }: { after: string; before: string }) => {
  // console.log('ReouteReplace', before, after);
  const location = useLocation();
  const { search } = useLocation();
  const oldPath = location.pathname;
  const newPath = oldPath.replace(before, after) + search;
  // console.log('newPath', newPath);
  return <Navigate replace to={newPath} />;
};

const NoMatch = () => {
  const location = useLocation();
  console.log('NoMatch - location', location.pathname);
  useEffect(() => {
    window.location.href = `${BASE_REDIRECT_URL}/dashboard`;
  }, []);
  return <Navigate to={`/dashboard`} />;
};

/**
 * Level 1 auth check
 *
 */
export const AuthenticationUserCheck = ({ children }: { children: ReactElement }) => {
  // console.log('AuthenticationUserCheck');
  return (
    <RequireLogin>
      <AuthProviderWrapper>
        <IdentifyAuthenticationWrap>{children}</IdentifyAuthenticationWrap>
      </AuthProviderWrapper>
    </RequireLogin>
  );
};

/**
 * Level 2 account check
 */
export const ApiAccountCheck = ({ children }: { children: ReactElement }) => {
  // console.log('ApiAccountCheck');
  return (
    <AccountCheckWrap>
      <AccountCableSubscribeWrap>
        <IdentifyApiAccountWrap>{children}</IdentifyApiAccountWrap>
      </AccountCableSubscribeWrap>
    </AccountCheckWrap>
  );
};

/**
 * Routes under /*
 */
export const RoutesRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<AppWrap />}>
        <Route path={`${authenticate}/*`}>
          <Route path={`link`} element={<Authenticate authType={'link'} />} />
          <Route path={`oauth`} element={<Authenticate authType={'oauth'} />} />
          <Route
            path={`invalid_invite`}
            element={
              <div>
                <h1>Invalid Invite</h1>
              </div>
            }
          />
          <Route path={`invite`} element={<Authenticate authType={'invite'} />} />
        </Route>

        <Route index element={<Navigate to={dashboard} />} />
        <Route path={`${user}/*`} element={<RoutesUserRelated />} />
        <Route
          path={`${dashboard}/*`}
          element={
            <AuthenticationUserCheck>
              <ApiAccountCheck>
                <NotificationWrap>
                  <Suspense fallback={<div />}>
                    <RoutesDashboardRelated />
                  </Suspense>
                </NotificationWrap>
              </ApiAccountCheck>
            </AuthenticationUserCheck>
          }
        />

        <Route
          path={`${share}/*`}
          element={
            <NotificationWrap>
              <Suspense fallback={<div />}>
                <RoutesShare />
              </Suspense>
            </NotificationWrap>
          }
        />

        <Route
          path={`${robot}/*`}
          element={
            <Suspense fallback={<div />}>
              <RoutesProgramaticRelated />
            </Suspense>
          }
        />

        <Route
          path={`${create}/*`}
          element={
            <Suspense fallback={<div />}>
              <RoutesCreate />
            </Suspense>
          }
        />

        {/**
         * NOTE (lenny):
         * Only delete once confirmed not used
         * !!! OLD ROUTE MIGRATION/REPLACE
         */}
        <Route
          path={`${canvas}/*`}
          element={<RouteReplace before={canvas} after={`create/${canvas}`} />}
        />

        <Route
          path={`${podcastAudiogramRoute}/*`}
          element={
            <RouteReplace
              before={podcastAudiogramRoute}
              after={`create/${podcastAudiogramRoute}`}
            />
          }
        />

        <Route
          path={`${focusedFlowRoute}/*`}
          element={
            <RouteReplace
              before={focusedFlowRoute}
              after={`create/${podcastAudiogramRoute}`}
            />
          }
        />

        <Route
          path={`${DashParams.LandingPagesParam}/*`}
          element={
            <RouteReplace
              before={focusedFlowRoute}
              after={`dashboard/${DashParams.LandingPagesParam}`}
            />
          }
        />

        <Route
          path={`${DashParams.LandingPagesParam}/*`}
          element={<RouteMigration prefix={dashboard} />}
        />
        <Route
          path={`${DashParams.BrandKitParam}`}
          element={<RouteMigration prefix={dashboard} />}
        />

        <Route
          path="/font-specimen-preview/*"
          element={<RouteMigration prefix={robot} />}
        />
        <Route path="/font-name-preview/*" element={<RouteMigration prefix={robot} />} />
        <Route
          path="/external-font-name-preview/*"
          element={<RouteMigration prefix={robot} />}
        />
        <Route
          path="/external-font-specimen-preview/*"
          element={<RouteMigration prefix={robot} />}
        />
        <Route
          path="/social-card-preview/*"
          element={<RouteMigration prefix={robot} />}
        />
        <Route path="/screenshot/*" element={<RouteMigration prefix={robot} />} />
        <Route path="/frame/*" element={<RouteMigration prefix={robot} />} />
        <Route path="/downloads/*" element={<RouteMigration prefix={dashboard} />} />

        <Route path="/settings/*" element={<RouteMigration prefix={dashboard} />} />
        <Route
          path="/download/:projectSlug/:downloadId"
          element={<RouteMigration prefix={dashboard} />}
        />
        <Route path="/canvas/*" element={<RouteMigration prefix={create} />} />
        <Route path="/preview-template/*" element={<RouteMigration prefix={robot} />} />
        <Route path="/sample_upload/*" element={<RouteMigration prefix={robot} />} />
        <Route
          path="/zoom-upload-started/*"
          element={<RouteMigration prefix={robot} />}
        />
        <Route path="/project/setup*" element={<RouteMigration prefix={create} />} />

        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};
