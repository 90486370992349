import { CanvasItem, TextSizeMode } from 'features/types/canvasItemsSlice';
import { ForwardedRef, RefObject, forwardRef, useContext, useEffect } from 'react';
import Moveable, {
  OnClick,
  OnDrag,
  OnDragStart,
  OnResize,
  OnResizeStart,
  OnRotate,
  OnRotateStart,
} from 'react-moveable';
import {
  canvasStateSelector,
  selectionConstraintsSelector,
} from 'features/selectors/canvasStateSelectors';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from 'features/EditorCanvas/Layout/Box';
import { ContextMenuContext } from '../../ContextMenu';
import { RATIO_LOCKED_ITEMS } from 'features/canvasItemsSlice';
import { RightClickable } from './RightClickable';
import { ViewTypes } from 'features/EditorCanvas/constants/ViewConstants';
import { getAbsoluteRotation } from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/RotationPicker';
import { updateCanvasEditMode } from 'features/canvasStateSlice';
import useKeyPress from './utils/useKeyPress';
import { useRecoilValue } from 'recoil';
import { useSelectionFocus } from '../../SelectionFocusProvider';
import { useUpdateMoveable } from './utils/useUpdateMoveable';
import { zoomState } from '../ZoomToolbar';

type Style = Partial<
  Pick<CanvasItem, 'dimension' | 'position' | 'rotation'> & { sizeMode: TextSizeMode }
>;
type Styles = Record<string, Style>;

type SelectedItem = {
  id: string;
  ref: RefObject<HTMLDivElement>;
};

export type MoveableManagerProps = {
  projectId: string;
  onChange: (styles: Styles) => void;
  selectedItems: SelectedItem[];
  items: Record<string, CanvasItem>;
  allRefs: RefObject<HTMLDivElement>[];
  canvasDimensions: { width: number; height: number };
};

const toFloat = (value: number | string) => {
  return typeof value === 'string' ? parseFloat(value) : value;
};

const StyledMoveable = styled(Moveable) <{ visible: boolean; editModeText: boolean }>`
  ${props =>
    !props.visible &&
    css`
      display: none;
      pointer-events: none;
    `}

  ${props =>
    props.editModeText &&
    css`
      .moveable-line {
        height: 2px !important;
        background-color: rgb(79, 70, 229) !important;
      }
    `}
`;

export const getMoveableElement = (element: HTMLElement) => {
  if (element.dataset.moveable) return element;
  return element.closest<HTMLDivElement>('[data-moveable]');
};

const MoveableManager = (
  {
    onChange,
    // Add this back when we add back undo support
    // onChangeEnd,
    selectedItems,
    items,
    projectId,
    allRefs,
    canvasDimensions,
  }: MoveableManagerProps,
  ref: ForwardedRef<Moveable>,
) => {
  const { shouldMoveCanvasItem } = useSelectionFocus();
  const upKeyPress: boolean = useKeyPress('ArrowUp');
  const rightKeyPress: boolean = useKeyPress('ArrowRight');
  const downKeyPress: boolean = useKeyPress('ArrowDown');
  const leftKeyPress: boolean = useKeyPress('ArrowLeft');

  const shiftKeyPress: boolean = useKeyPress('Shift');

  // listen for arrow keys, move selected canvas items if shouldMoveCanvasItem is false
  useEffect(() => {
    const selectedId = selectedItems[0]?.id;
    if (!shouldMoveCanvasItem) {
      return;
    }
    const style = {};
    selectedItems.forEach(({ id }) => {
      const item = items[id];

      if (item) {
        const { position } = item;
        const newPosition = {
          top: position.top,
          left: position.left,
        };

        const moveDistance = shiftKeyPress ? 1 : 10;

        if (upKeyPress) {
          newPosition.top -= moveDistance;
        }

        if (rightKeyPress) {
          newPosition.left += moveDistance;
        }

        if (downKeyPress) {
          newPosition.top += moveDistance;
        }

        if (leftKeyPress) {
          newPosition.left -= moveDistance;
        }

        style[id] = {
          position: newPosition,
        };
      }
    });
    onChange(style);
  }, [upKeyPress, rightKeyPress, downKeyPress, leftKeyPress, shouldMoveCanvasItem]);

  const getPositionForEvent = ({ beforeTranslate }: { beforeTranslate: number[] }) => {
    const [left, top] = beforeTranslate;
    return { left: Math.round(left), top: Math.round(top) };
  };

  const getPositionForItem = (id: string) => {
    const item = items[id];
    return [toFloat(item.position.left), toFloat(item.position.top)];
  };

  const getDimensionForEvent = ({ width, height }: { width: number; height: number }) => {
    return { width: Math.round(width), height: Math.round(height) };
  };

  const setPosition = (event: OnDragStart, element: HTMLElement | SVGElement) => {
    event.set(getPositionForItem(element.dataset.id!));
  };

  const handleDragStart = (events: OnDragStart[]) => {
    events.forEach(event => {
      let element = getMoveableElement(event.target as any);
      if (!element) element = getMoveableElement(event.inputEvent.target);
      if (!element) element = event.target as HTMLElement;

      setPosition(event, element);
    });
  };

  const handleDrag = (events: OnDrag[]) => {
    const styles: Styles = {};
    events.forEach(event => {
      const id = event.target.dataset.id!;
      styles[id] = { position: getPositionForEvent(event) };
    });
    onChange(styles);
  };

  useUpdateMoveable({
    selectedItems,
    items,
    moveableRef: ref,
  });

  const handleResizeStart = (events: OnResizeStart[]) => {
    events.forEach(event => {
      event.setOrigin(['%', '%']);

      if (event.dragStart) {
        const id = event.target.dataset.id!;
        event.dragStart.set(getPositionForItem(id));
      }
    });
  };

  const handleResize = (events: OnResize[]) => {
    const styles: Styles = {};
    events.forEach(event => {
      const id = event.target.dataset.id!;
      const item = items[id];

      let sizeMode = undefined;
      if (item.viewType === ViewTypes.Text) {
        sizeMode = 'customWidth' as const;
      }

      let newDimension = getDimensionForEvent(event);
      if (RATIO_LOCKED_ITEMS.includes(item.viewType)) {
        newDimension = new Box(item.dimension, {
          dimension: newDimension,
          position: { top: 0, left: 0 },
        })
          .fit()
          .getBox().dimension;
      }

      styles[id] = {
        position: getPositionForEvent(event.drag),
        dimension: newDimension,
        sizeMode,
      };
    });
    onChange(styles);
  };

  const handleRotateStart = (events: OnRotateStart[]) => {
    events.forEach(event => {
      let element = getMoveableElement(event.target as any);
      if (!element) element = getMoveableElement(event.inputEvent.target);
      if (!element) element = event.target as HTMLElement;

      const id = event.target.dataset.id!;
      event.set(items[id].rotation);
    });
  };

  const handleRotate = (events: OnRotate[]) => {
    const styles: Styles = {};
    events.forEach(event => {
      const id = event.target.dataset.id!;
      styles[id] = { rotation: getAbsoluteRotation(event.rotate) };
    });
    onChange(styles);
  };

  const dispatch = useDispatch();

  const canvasEditMode = useSelector(canvasStateSelector)?.canvasEditMode;

  const handleClick = (event: OnClick) => {
    if (!event.isDouble) return;
    const contentEditable = event.target.querySelector<HTMLElement>('[contenteditable]');
    if (contentEditable) {
      dispatch(updateCanvasEditMode({ canvasEditMode: 'text' }));
      contentEditable.focus();
    }
  };

  const { resizable, resizeHandles, keepRatio } = useSelector(selectionConstraintsSelector)(
    projectId,
  );

  const zoom = useRecoilValue(zoomState);

  const elementGuidelines = allRefs.map(r => r.current);

  const { onContextMenu } = useContext(ContextMenuContext);

  if (canvasEditMode === 'crop') return null;

  return (
    <StyledMoveable
      ables={[RightClickable]}
      props={{
        rightClickable: true,
        onRightClickGroup: onContextMenu({ type: 'group' }),
      }}
      ref={ref}
      origin={false}
      target={selectedItems.map(target => target.ref)}
      visible={selectedItems.length > 0}
      draggable
      resizable={resizable}
      renderDirections={resizeHandles}
      keepRatio={keepRatio}
      zoom={(1 / zoom) * 0.8}
      onClick={handleClick}
      editModeText={canvasEditMode === 'text'}
      passDragArea
      // Dragging
      onDragStart={event => handleDragStart([event])}
      onDrag={event => handleDrag([event])}
      onDragGroupStart={({ events }) => handleDragStart(events)}
      onDragGroup={({ events }) => handleDrag(events)}
      // Resizing
      onResizeStart={event => handleResizeStart([event])}
      onResize={event => handleResize([event])}
      onResizeGroupStart={({ events }) => handleResizeStart(events)}
      onResizeGroup={({ events }) => handleResize(events)}
      // Rotating
      rotatable={selectedItems.length === 1}
      throttleRotate={10}
      onRotateStart={event => handleRotateStart([event])}
      onRotate={event => handleRotate([event])}
      // Snapping
      snappable
      // snapGridWidth={4}
      // snapGridHeight={4}
      snapDirections={{ center: true, middle: true }}
      elementSnapDirections={{ top: true, bottom: true, right: true, left: true }}
      snapGap={false}
      isDisplaySnapDigit={false}
      elementGuidelines={elementGuidelines as any}
      verticalGuidelines={[0, canvasDimensions.width / 2, canvasDimensions.width]}
      horizontalGuidelines={[0, canvasDimensions.height / 2, canvasDimensions.height]}
      hideDefaultLines={true}
    />
  );
};

const MoveableManagerFR = forwardRef(MoveableManager);
export { MoveableManagerFR as MoveableManager };
