import PropTypes from 'prop-types';
import {TableHeader} from 'features/Common/TableHeader';

export const Table = ({items, renderRow, headers, noItemsMessage}) => {
  if (!items) {
    return (
      <table className="min-w-full divide-y divide-gray-200">
        <tr className="bg-white">
          <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
            {noItemsMessage || 'There are no items to display.'}
          </td>
        </tr>
      </table>
    );
  }
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <TableHeader headers={headers} />
      <tbody>
        {items.map((item, idx) => {
          return (
            <tr key={idx} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
              {renderRow(item)}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  headers: PropTypes.array.isRequired,
};
