import * as Sentry from '@sentry/react';

import {DashboardMediaAsset} from 'features/Dashboard/DashboardUploadDetails/DashboardMediaAsset';
// import DashboardSidebar from 'features/Dashboard/DashboardWrapWithSidebar';
import ErrorPage from 'features/EditorCanvas/components/shared/ErrorPage';
import {authSelector} from 'features/selectors/authSelectors';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

function FallbackComponent() {
  return (
    <div className="fixed left-0 top-0 flex h-screen min-h-screen w-screen flex-col items-center justify-center bg-gray-100">
      <ErrorPage
        title="Error occured"
        explainer={'Please refresh to return to a working state.'}
      />
    </div>
  );
}

const myFallback = <FallbackComponent />;

export function DashboardMediaAssetDetailPage() {
  const {email: authEmail, user} = useSelector(authSelector);

  const errorDialog = authEmail
    ? {
        user: {
          email: authEmail,
          name: `${user?.first_name} ${user?.last_name}`,
        },
        title: 'We are sorry you have hit an error',
        subtitle:
          'Our engineering team has been notified and will fix the issue promptly. We would appreciate if you can briefly explain what may have caused the error to help us track the issue.',
      }
    : {};
  const {userUploadId} = useParams<Record<string, string>>();

  return (
    <Sentry.ErrorBoundary
      fallback={myFallback}
      beforeCapture={scope => {
        scope.setTag('projectId', userUploadId);
      }}
      onError={error => {
        Sentry.captureException(error);
      }}
      dialogOptions={errorDialog}
      showDialog
    >
      <div className="flex h-screen w-full flex-col bg-white">
        <main className="flex flex-grow overflow-auto">
          <DashboardMediaAsset userUploadId={userUploadId} />
        </main>
      </div>
    </Sentry.ErrorBoundary>
  );
}
