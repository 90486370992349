import {Copy, Edit3, Scissors} from 'react-feather';

import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {Shortcut} from 'shortcuts';
import {TranscriptToolbarButton} from './TranscriptToolbar';
import copy from 'copy-to-clipboard';
import {showSuccessNotification} from 'features/Common/utils';
import {transcriptToString} from './slateUtils';
import {useCreateClip} from './transcriptUtils';
import {useOpenCorrectionModal} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/TranscriptText/TranscriptCorrectionModal';
import {useParams} from 'react-router-dom';
import {useSelectedTranscript} from './selection';
import {useUserUploadQuery} from 'services/userUploadAPI';

export type SelectionToolbarOptions = {
  allowClipping?: boolean;
  showShortcuts?: boolean;
  showCopy?: boolean;
  targetedUserUploadId?: string;
};

export const SelectionToolbar = ({
  language,
  targetedUserUploadId,
  allowClipping = true,
  showShortcuts = true,
  showCopy = true,
}: {
  language: LanguageId;
} & SelectionToolbarOptions) => {
  let {userUploadId} =
    useParams<{
      userUploadId: string;
    }>();
  userUploadId = targetedUserUploadId ? targetedUserUploadId : userUploadId;
  const {
    data: userUpload,
    isLoading,
    isFetching,
    // @ts-ignore
  } = useUserUploadQuery({id: userUploadId!});

  const createClip = useCreateClip(userUploadId!);
  const openCorrectionModal = useOpenCorrectionModal(userUploadId!, language);
  const selectedTranscript = useSelectedTranscript(userUploadId!, language);

  return (
    <div>
      {allowClipping && (
        <TranscriptToolbarButton
          label="Clip selection"
          shortcut="c"
          icon={Scissors}
          action={() => {
            // console.log('userUploadId', userUploadId);
            createClip();
          }}
          showShortcut={showShortcuts}
        />
      )}
      <TranscriptToolbarButton
        label="Edit"
        shortcut="r"
        icon={Edit3}
        action={() => {
          // console.log('openCorrectionModal - userUploadId', userUploadId);
          openCorrectionModal();
        }}
        showShortcut={showShortcuts}
      />
      {showCopy && (
        <TranscriptToolbarButton
          label="Copy"
          shortcut={Shortcut.shortcut2symbols('Meta + C')}
          handleShortcut={false}
          icon={Copy}
          action={() => {
            if (!selectedTranscript) return;

            const text = transcriptToString(selectedTranscript.transcript);
            if (!text) return;

            copy(text);

            showSuccessNotification({
              title: 'Copied',
              message: 'Selected text copied to clipboard.',
            });
          }}
          showShortcut={showShortcuts}
        />
      )}
    </div>
  );
};
