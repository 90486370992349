import {useTimeSelector} from 'features/EditorCanvas/components/CanvasTime/useTimeSelector';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';
import WordFrame from 'features/Captions/WordFrame';

function SceneFrame({
  color,
  colorInactive,
  lineHeight,
  fontFamily,
  frame,
  fontSize,
}: {
  color: string;
  colorInactive: string;
  lineHeight: number;
  fontFamily: string;
  frame: WordFrame;
  fontSize: string;
}) {
  const {activeScene} = useScenes();
  const activeIndex = useTimeSelector(
    ({currentTime}) => {
      const flooredTime = currentTime - activeScene.startTimeMs;
      return frame.wordsArr.findIndex(word => word.end > flooredTime);
    },
    [frame.wordsArr, activeScene],
  );

  return (
    <p style={{lineHeight, fontSize: `${fontSize}px`}}>
      {frame.wordsArr.map((word, wordIndex) => (
        <span
          style={{
            fontFamily,
            color: wordIndex <= activeIndex ? color : colorInactive,
          }}
          key={wordIndex}
        >
          {word.content}{' '}
        </span>
      ))}
    </p>
  );
}

export default SceneFrame;
