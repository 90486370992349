import {DimensionType} from 'features/types/canvasItemsSlice';
import {RefObject, useLayoutEffect, useRef, useState} from 'react';

export function useOnResize<E extends HTMLElement>(
  ref: RefObject<E> | E | null,
  onResize: (size: DimensionType) => void,
) {
  const onResizeRef = useRef(onResize);
  useLayoutEffect(() => {
    onResizeRef.current = onResize;
  }, [onResize]);

  useLayoutEffect(() => {
    if (!ref) return;

    const element = 'current' in ref ? ref.current : ref;
    if (!element) return;

    const resizeObserver = new ResizeObserver(entries => {
      const {width, height} = entries[0].contentRect;
      onResizeRef.current({width, height});
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);
}

export function useWatchElementSize<E extends HTMLElement>() {
  const [size, setSize] = useState<DimensionType>({width: 0, height: 0});

  const [element, setElement] = useState<E | null>(null);

  useLayoutEffect(() => {
    if (!element) return;
    const {width, height} = element.getBoundingClientRect();
    setSize({width, height});
  }, [element]);

  useOnResize(element, setSize);

  return [setElement, size] as const;
}
