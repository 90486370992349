import {useEffect, useRef} from 'react';

import {Button} from 'ui/Button';
import {Icon} from 'react-feather';
import classNames from 'classnames';
import {useSidebarMenu} from './SidebarMenuContext';

export function MenuButton({
  active,
  onClick,
  label,
  icon: Icon,
}: {
  active: boolean;
  onClick: () => void;
  label: string;
  icon: Icon;
}) {
  const button = useRef<HTMLButtonElement>(null);
  const {
    state: {activeMenu},
  } = useSidebarMenu();

  useEffect(() => {
    button.current?.blur();
  }, [activeMenu]);

  return (
    <Button
      ref={button}
      variant="inline"
      onClick={onClick}
      _className={classNames('w-full !py-2', active && 'bg-opacity-5 !text-indigo-600')}
    >
      <div className="flex h-full w-full flex-col items-center space-y-1.5">
        <Icon size={24} />
        <span className="text-xs font-semibold">{label}</span>
      </div>
    </Button>
  );
}
