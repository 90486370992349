import {DragHandleType, LeafChild, getLeafChildren, useHandleDrag} from './DragHandles';
import {
  FC,
  Fragment,
  MouseEventHandler,
  ReactNode,
  RefObject,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {SelectedTranscript, SortedSelection} from './selection';
import {
  SlateElement,
  SlateLeaf,
  SlateWordPosition,
  findInSlateValue,
  getClosestWordToPoint,
  getSelectionRange,
  getWordAtCoordinates,
  getWordAtPoint,
  getWordsForSelection,
  selectRange,
  sortSelection,
  toggleCapitalization,
  toggleTranscriptPunctuation,
  transcriptToSlateChildren,
  transcriptToString,
  useActiveWord,
} from './slateUtils';
import {TranscriptJSON, TranscriptWord} from 'features/Captions/Transcripts';
import {showSuccessNotification, useEventListener} from 'features/Common/utils';

import classNames from 'classnames';
import equal from 'fast-deep-equal';
import {modifyWord} from './transcriptUtils';
import {useActiveHotkey} from './Hotkeys';
import {useSlateEventHandlers} from './SlateEvents';

export type SlateWrapperProps = {
  transcript: TranscriptJSON;
  selectedWords: SelectedTranscript;
  onChangeTranscript: (transcript: TranscriptJSON, editedText: string) => void;
  onChangeSelection: (selection: SortedSelection) => void;
  onChangeCursor?: (cursorMs: number, word?: TranscriptWord) => void;
  timeOffsetMs: number;
  autoScroll: boolean;
  editMode: boolean;
  toolbar?: ReactNode;
  searchResults: TranscriptWord[];
  activeSearchResult?: TranscriptWord;
};

export const SlateWrapper = ({
  transcript,
  onChangeTranscript,
  onChangeCursor,
  onChangeSelection,
  selectedWords,
  timeOffsetMs,
  autoScroll,
  editMode,
  toolbar,
  searchResults,
  activeSearchResult,
}: SlateWrapperProps) => {
  const slateValue = useMemo(() => {
    const formattedTranscript = transcriptToSlateChildren(transcript);
    if (!selectedWords) return formattedTranscript;

    return selectRange(formattedTranscript, selectedWords);
  }, [selectedWords, transcript]);

  // TODO (jacques): Turn this into a util
  const handleSelection = useCallback(
    (withinContainer: boolean) => {
      const selection = getSelectionRange();

      if (!selection) {
        if (!withinContainer) return;

        onChangeSelection(null);
        return;
      }

      if (equal(selection.focus, selection.anchor)) {
        if (!withinContainer) return;

        onChangeSelection(null);

        const wordAtCursor = getClosestWordToPoint(transcript, selection.focus);
        if (wordAtCursor) {
          onChangeCursor?.(wordAtCursor.start - timeOffsetMs, wordAtCursor);
        }

        return;
      }

      const sortedSelection = sortSelection(selection);

      window.getSelection()?.removeAllRanges();

      const selectionWords = getWordsForSelection(transcript, sortedSelection);
      if (!selectionWords) {
        onChangeSelection(null);
        return;
      }

      onChangeCursor?.(selectionWords.startWord.start - timeOffsetMs);
      onChangeSelection({
        start: selectionWords.startWord.start,
        end: selectionWords.endWord.end,
      });
    },
    [onChangeCursor, onChangeSelection, transcript, timeOffsetMs],
  );

  const activeHotkey = useActiveHotkey();
  // TODO (jacques): Test the below with an E2E test
  const onClick: MouseEventHandler<HTMLDivElement> = event => {
    const selection = getSelectionRange();
    if (!selection) return;

    const word = getWordAtPoint(transcript, selection.focus);
    if (!word) return;

    if (editMode) {
      // Edit: console.log('edit', word);
    } else if (activeHotkey) {
      if (activeHotkey === 'punctuation') {
        const {modifiedWords, newTranscript} = toggleTranscriptPunctuation({
          transcript,
          word,
        });

        onChangeTranscript(newTranscript, modifiedWords.join(' '));
      } else if (activeHotkey === 'capitalization') {
        const newWord = toggleCapitalization(word.text);
        onChangeTranscript(modifyWord({transcript, text: newWord, word}), newWord);
      }
    }
  };

  const events = useSlateEventHandlers();
  const container = useRef<HTMLDivElement>(null);

  const [draggingHandle, setDraggingHandle] = useState<DragHandleType | null>(null);

  const isMouseDown = useRef(false);

  useEventListener('mousedown', () => {
    isMouseDown.current = true;
  });

  useEventListener('mouseup', event => {
    isMouseDown.current = false;

    if (draggingHandle) {
      setDraggingHandle(null);
      return;
    }

    if (editMode) return;

    let withinContainer = false;
    if (event.target instanceof HTMLElement) {
      withinContainer = !!event.target.closest('[data-type="container"]');
    }

    handleSelection(withinContainer);
  });

  const onDragHandleStart = (type: DragHandleType) => {
    setDraggingHandle(type);
  };

  const [hoveringOverSelection, setHoveringOverSelection] = useState(false);

  useHandleDrag({dragging: draggingHandle, transcript});

  const activeWord = useActiveWord(slateValue, timeOffsetMs);

  useEventListener('copy', event => {
    if (!selectedWords) return;

    const text = transcriptToString(selectedWords.transcript);
    if (!text) return;

    event.preventDefault();
    event.clipboardData?.setData('text/plain', text);

    showSuccessNotification({
      title: 'Copied',
      message: 'Selected text copied to clipboard.',
    });
  });

  const [_hoverWord, _setHoverWord] = useState<SlateWordPosition | undefined>();
  const hoverWord = selectedWords ? undefined : _hoverWord;

  const timeout = useRef<NodeJS.Timeout>();

  const setHoverWord = (hoverWord: SlateWordPosition | undefined) => {
    if (isMouseDown.current) return;

    if (timeout.current) clearTimeout(timeout.current);

    if (hoverWord === undefined) {
      timeout.current = setTimeout(() => {
        _setHoverWord(undefined);
      }, 100);
    } else {
      _setHoverWord(hoverWord);
    }
  };

  return (
    <div
      onClick={onClick}
      data-type="container"
      data-testid="transcript-container"
      ref={container}
      {...events}
      className={classNames(draggingHandle && 'select-none')}
      onMouseMove={event => {
        const word = getWordAtCoordinates(transcript, event);

        if (!word) {
          setHoverWord(undefined);
          return;
        }

        const position = findInSlateValue(slateValue, slateWord => {
          return slateWord.paragraph === word.paragraph && slateWord.index === word.index;
        });

        setHoverWord(position);
      }}
    >
      {slateValue.map((paragraph, pIndex) => (
        <Paragraph paragraph={paragraph} key={pIndex} editMode={editMode}>
          {paragraph.children.map((leaf, lIndex) => (
            <Leaf
              leaf={leaf}
              key={lIndex}
              container={container}
              onDragHandleStart={onDragHandleStart}
              onHover={hovering => {
                if (!leaf.selected) return;
                setHoveringOverSelection(hovering);
              }}
              showDragHandles={hoveringOverSelection || !!draggingHandle}
              highlightWord={
                !editMode &&
                activeWord?.paragraphIndex === pIndex &&
                activeWord.leafIndex === lIndex &&
                activeWord.wordIndex
              }
              hoverWord={
                hoverWord?.paragraphIndex === pIndex &&
                hoverWord.leafIndex === lIndex &&
                hoverWord.wordIndex
              }
              searchResults={searchResults
                .filter(r => r.paragraph === pIndex)
                .map(r => r.index)}
              activeSearchResult={
                activeSearchResult?.paragraph === pIndex
                  ? activeSearchResult.index
                  : undefined
              }
              hoverStyle={editMode ? 'bg-green-200' : 'bg-gray-200'}
              autoScroll={autoScroll}
              toolbar={toolbar}
            />
          ))}
        </Paragraph>
      ))}
    </div>
  );
};

const Paragraph: FC<{paragraph: SlateElement; editMode: boolean}> = memo(
  ({paragraph, children, editMode}) => {
    const firstChild = paragraph.children[0];
    const startTime = firstChild.words[0].start;

    const lastChild = paragraph.children[paragraph.children.length - 1];
    const endTime = lastChild.words[lastChild.words.length - 1].end;

    return (
      <div
        data-type="paragraph"
        className={classNames('mb-3', editMode && 'cursor-pointer')}
        data-start-time={startTime}
        data-end-time={endTime}
      >
        {children}
      </div>
    );
  },
);

type LeafProps = {
  leaf: SlateLeaf;
  container: RefObject<HTMLDivElement>;
  onDragHandleStart: (type: DragHandleType) => void;
  onHover: (hovering: boolean) => void;
  showDragHandles: boolean;
  highlightWord: number | false;
  hoverWord: number | false;
  autoScroll: boolean;
  hoverStyle: string;
  toolbar?: ReactNode;
  searchResults: number[];
  activeSearchResult?: number;
};

const Leaf = memo((props: LeafProps) => {
  const {
    leaf,
    container,
    onDragHandleStart,
    onHover,
    showDragHandles,
    highlightWord,
    hoverWord,
    autoScroll,
    hoverStyle,
    toolbar,
    searchResults,
    activeSearchResult,
  } = props;

  const span = useRef<HTMLSpanElement>(null);
  const autoScrollKey = activeSearchResult ? activeSearchResult : highlightWord;
  useAutoScroll({
    span,
    container,
    enabled:
      autoScrollKey === activeSearchResult
        ? !!activeSearchResult
        : autoScroll && highlightWord !== false,
    key: autoScrollKey,
  });

  const children = getLeafChildren({
    firstSelected: leaf.firstSelected,
    lastSelected: leaf.lastSelected,
    text: leaf.text,
    highlightWord,
    hoverWord,
    searchResults,
    activeSearchResult,
  });

  if (!children) return null;

  return (
    <span
      ref={span}
      className={classNames(
        'group relative py-[5px] text-base',
        leaf.selected && 'bg-blue-100',
      )}
      onMouseEnter={() => onHover(true)}
      onMouseMove={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      {children.map((child, index) => {
        if (typeof child === 'string') {
          return <span key={index}> {child} </span>;
        }

        const lastChild = index === children.length - 1;

        return (
          <Fragment key={index}>
            {' '}
            <LeafChild
              child={child}
              onDragHandleStart={onDragHandleStart}
              showDragHandles={showDragHandles}
              hoverStyle={hoverStyle}
              toolbar={lastChild && leaf.lastSelected && toolbar}
            />{' '}
          </Fragment>
        );
      })}
    </span>
  );
});

const useAutoScroll = ({
  span,
  container,
  enabled,
  key,
}: {
  span: RefObject<HTMLSpanElement>;
  container: RefObject<HTMLDivElement>;
  enabled: boolean;
  key?: unknown;
}) => {
  useEffect(() => {
    if (!enabled) return;
    if (!span.current) return;
    if (!container.current) return;

    const scrollParent = container.current.closest<HTMLElement>(
      '[data-type="scrollContainer"]',
    );
    if (!scrollParent) return;

    const target = span.current.querySelector<HTMLElement>(
      `[data-type="leaf"][data-highlighted="true"]`,
    );
    if (!target) return;

    const scrollParentRect = scrollParent.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    const isInView =
      targetRect.top >= scrollParentRect.top &&
      targetRect.bottom <= scrollParentRect.bottom;
    if (isInView) return;

    const targetTop = targetRect.top - scrollParentRect.top + scrollParent.scrollTop;
    scrollParent.scrollTo({top: targetTop - 40, behavior: 'smooth'});
  }, [container, enabled, span, key]);
};
