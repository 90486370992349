import {useState} from 'react';

export default function ItemDetailCaption({item}) {
  const [vttText, setVttText] = useState(null);

  if (!item) {
    return null;
  }

  const vttTextCallback = content => {
    setVttText(content);
  };
  const url = item.upload_url;
  const domainUrl = url.split('://')[1];
  const protocolUpdatedUrl = `${window.location.protocol}//${domainUrl}`;
  window
    .fetch(protocolUpdatedUrl)
    .then(response => response.text().then(vttTextCallback));

  return (
    <div className="max-h-60 overflow-y-scroll whitespace-pre rounded border border-gray-300 px-2 py-1 text-xs">
      {vttText}
    </div>
  );
}
