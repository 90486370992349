import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {useCallState} from 'vendor/dailyco/shared/contexts/CallProvider';
import {useUIState} from 'vendor/dailyco/shared/contexts/UIStateProvider';

import {CALL_STATE_REDIRECTING} from 'vendor/dailyco/shared/contexts/useCallMachine';
import {
  isCloudRecordingType,
  useCallConfig,
} from 'vendor/dailyco/shared/hooks/useCallConfig';
import {
  useAppMessage,
  useDaily,
  useRecording as useDailyRecording,
} from '@daily-co/daily-react-hooks';
import {DailyStreamingOptions} from '@daily-co/daily-js';

export enum RECORDING_STATE {
  ERROR = 'error',
  SAVED = 'saved',
  RECORDING = 'recording',
  UPLOADING = 'uploading',
  COUNTDOWN_1 = 'starting1',
  IDLE = 'idle',
}

export const RECORDING_TYPE_CLOUD = 'cloud';
export const RECORDING_TYPE_CLOUD_BETA = 'cloud-beta';
export const RECORDING_TYPE_RTP_TRACKS = 'rtp-tracks';

const initialState = {
  error: null,
  recordingState: RECORDING_STATE.IDLE,
  isRecording: false,

  stopRecording: null,
  startRecordingWithCountdown: undefined,
} as {
  error: any;
  recordingState: RECORDING_STATE;
  isRecording: boolean;
  startRecordingWithCountdown: null | any;

  stopRecording: null | any;
};

const RecordingContext = createContext(initialState);

export const RecordingProvider = ({children}: {children: ReactElement}) => {
  const {state} = useCallState();
  const {setCustomCapsule} = useUIState();
  const {enableRecording} = useCallConfig();
  const daily = useDaily();
  const [recordingState, setRecordingState] = useState(RECORDING_STATE.IDLE);

  const {
    error,
    isRecording,
    startRecording: startDailyRecording,
    stopRecording: stopDailyRecording,
  } = useDailyRecording();

  /**
   * Prevent users from accidentally leaving the meeting without stopping
   * their running recording.
   */
  const handleOnUnload = useCallback(
    () => 'Unsaved recording in progress. Do you really want to leave?',
    [],
  );

  useEffect(() => {
    if (
      !enableRecording ||
      isCloudRecordingType(enableRecording) ||
      !isRecording ||
      state === CALL_STATE_REDIRECTING
    )
      return;
    const prev = window.onbeforeunload;
    window.onbeforeunload = handleOnUnload;
    return () => {
      window.onbeforeunload = prev;
    };
  }, [enableRecording, handleOnUnload, isRecording, state]);

  /**
   * Actually start the recording via API, after countdown.
   */
  const startRecordingReal = useCallback(() => {
    if (isRecording) return;
    if (isCloudRecordingType(enableRecording)) {
      startDailyRecording({
        width: 1280,
        height: 720,
      });
    } else {
      startDailyRecording({
        width: 1280,
        height: 720,
      });
    }
  }, [enableRecording, isRecording, startDailyRecording]);

  useEffect(() => {
    let timeout: any;
    switch (recordingState) {
      case RECORDING_STATE.COUNTDOWN_1:
        startRecordingReal();
        break;
      case RECORDING_STATE.ERROR:
      case RECORDING_STATE.SAVED:
        timeout = setTimeout(() => {
          setRecordingState(RECORDING_STATE.IDLE);
        }, 5000);
        break;
      default:
        break;
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [recordingState, startRecordingReal]);

  // Show a custom capsule when recording in progress
  useEffect(() => {
    if (!isRecording) {
      setCustomCapsule(null);
    } else {
      setRecordingState(RECORDING_STATE.RECORDING);
      setCustomCapsule({variant: 'recording', label: 'Recording'});
    }
  }, [isRecording, setCustomCapsule]);

  const sendAppMessage = useAppMessage({
    onAppMessage: useCallback(
      ev => {
        switch (ev?.data?.event) {
          /**
           * Initialize recording countdown for remote participants.
           */
          case 'recording-starting':
            setRecordingState(RECORDING_STATE.COUNTDOWN_1);
            break;
        }
      },
      [setRecordingState],
    ),
  });

  const startRecordingWithCountdown = useCallback(() => {
    if (!daily || !enableRecording || isRecording) return;
    setRecordingState(RECORDING_STATE.COUNTDOWN_1);
    sendAppMessage({event: 'recording-starting'});
  }, [daily, enableRecording, isRecording, sendAppMessage]);

  /**
   * Triggered via UI.
   */
  const stopRecording = useCallback(() => {
    if (!enableRecording || !isRecording) return;
    setRecordingState(RECORDING_STATE.SAVED);
    stopDailyRecording();
  }, [enableRecording, isRecording, stopDailyRecording]);

  return (
    <RecordingContext.Provider
      value={{
        error,
        isRecording,
        recordingState,
        startRecordingWithCountdown,
        stopRecording,
      }}
    >
      {children}
    </RecordingContext.Provider>
  );
};

export const useRecording = () => useContext(RecordingContext);
