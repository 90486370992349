import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import {CheckCircle, ChevronDown, ChevronUp} from 'react-feather';

import {Divider} from 'features/EditorCanvas/Sidebar/SidebarMenu';
import {SectionFirstCampaign} from './SectionFirst';
import {SectionSecondUpload} from './SectionSecond';
import {SectionThirdUpload} from './SectionThird';
import {authOnboardinStatusSelector} from 'features/selectors/authSelectors';
import classNames from 'classnames';
import {useEffect} from 'react';
import {useOnboardingProgress} from './HomeContainer';
import {useSelector} from 'react-redux';

export const OnboardingHome = ({
  hasAccountProject,
  hasAccountProjectDownload,
  hasAccountBrandKit,
}: {
  hasAccountProject: boolean;
  hasAccountProjectDownload: boolean;
  hasAccountBrandKit: boolean;
}) => {
  const {stepProgress, updateProgress} = useOnboardingProgress();
  const onboardingStatus = useSelector(authOnboardinStatusSelector);

  useEffect(() => {
    if (hasAccountProjectDownload && hasAccountBrandKit && hasAccountProject) {
      updateProgress({...stepProgress, step1: true, step2: true, step3: true});
    } else if (hasAccountBrandKit && hasAccountProject) {
      updateProgress({...stepProgress, step1: true, step2: true});
    } else if (hasAccountProject) {
      updateProgress({...stepProgress, step1: true});
    }
  }, [onboardingStatus]);

  const preExpanded = !hasAccountProject
    ? ['step-1']
    : !hasAccountBrandKit
    ? ['step-2']
    : !hasAccountProjectDownload
    ? ['step-3']
    : [];

  // console.log('stepProgress', stepProgress);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="relative pb-6">
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .active-accordian-item h4 {
            color: rgb(55, 65, 81) !important;
          }
          .active-accordian-item .chev-down {
            display: none !important;
          }
          .active-accordian-item .chev-up {
            display: block !important;
          }
          .chev-down {
            display: block !important;
          }
          .chev-up {
            display: none !important;
          }
          h4 {
            color: rgb(79, 70, 229) !important;
          }`,
        }}
      />

      <div className="sticky top-0 z-30 flex items-center justify-between border-b border-gray-300 bg-white pb-2 pt-1">
        <div className="min-w-0 flex-1">
          <h2 className="text-sm leading-7 text-gray-600 sm:truncate sm:text-xs">
            Here’s what we recommend you do next...
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <span
            className={classNames(
              'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full border-2 ',
              {
                'border-gray-300 group-hover:border-gray-400': !stepProgress.step3,
                'border-green-500 group-hover:border-green-500': stepProgress.step3,
              },
            )}
          >
            <span className="font-serif text-xl text-gray-700 group-hover:text-gray-900">
              {`${Object.values(stepProgress).filter(step => step).length}/${
                Object.keys(stepProgress).filter(step => step).length
              }`}
            </span>
          </span>
        </div>
      </div>
      <Divider color="bg-none" />
      <div className=" pb-8">
        <Accordion
          allowMultipleExpanded={false}
          allowZeroExpanded={true}
          preExpanded={preExpanded}
        >
          <AccordionItem activeClassName="active-accordian-item" uuid="step-1">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="flex items-center justify-between">
                  <div className="min-w-0 flex-1">
                    <h4
                      className={classNames(
                        'font-semibold leading-7 sm:truncate sm:text-lg',
                      )}
                    >
                      Create your first campaign
                    </h4>
                  </div>
                  <div className="mt-4 flex md:mt-0 md:ml-4">
                    <span className="flex h-12 w-12 flex-shrink-0 items-center justify-center">
                      <span
                        className={classNames(
                          'font-serif text-xl group-hover:text-gray-900',
                          {
                            'text-gray-500': !hasAccountProject,
                            'text-green-500': hasAccountProject,
                          },
                        )}
                      >
                        <CheckCircle />
                      </span>
                    </span>
                    <AccordianCheveron />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <SectionFirstCampaign />
            </AccordionItemPanel>
          </AccordionItem>
          <Divider />
          <AccordionItem activeClassName="active-accordian-item" uuid="step-2">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="flex items-center justify-between">
                  <div className="min-w-0 flex-1">
                    <h4 className="font-semibold leading-7 text-gray-600 sm:truncate sm:text-lg">
                      Setup your brand kit
                    </h4>
                  </div>
                  <div className="mt-4 flex md:mt-0 md:ml-4">
                    <span className="flex h-12 w-12 flex-shrink-0 items-center justify-center">
                      <span
                        className={classNames(
                          'font-serif text-xl group-hover:text-gray-900',
                          {
                            'text-gray-500': hasAccountProject && !hasAccountBrandKit,
                            'text-gray-300': !hasAccountProject && !hasAccountBrandKit,
                            'text-green-500': hasAccountBrandKit,
                          },
                        )}
                      >
                        <CheckCircle />
                      </span>
                    </span>
                    <AccordianCheveron />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <SectionSecondUpload />
            </AccordionItemPanel>
          </AccordionItem>
          <Divider />
          <AccordionItem activeClassName="active-accordian-item" uuid="step-3">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="flex items-center justify-between">
                  <div className="min-w-0 flex-1">
                    <h4 className="font-semibold leading-7 text-gray-600 sm:truncate sm:text-lg">
                      Launch
                    </h4>
                  </div>
                  <div className="mt-4 flex md:mt-0 md:ml-4">
                    <span className="flex h-12 w-12 flex-shrink-0 items-center justify-center">
                      <span
                        className={classNames(
                          'font-serif text-xl group-hover:text-gray-900',
                          {
                            'text-gray-300': !hasAccountProject && !hasAccountBrandKit,
                            'text-gray-500':
                              hasAccountBrandKit && !hasAccountProjectDownload,
                            'text-green-500': hasAccountProjectDownload,
                          },
                        )}
                      >
                        <CheckCircle />
                      </span>
                    </span>
                    <AccordianCheveron />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <SectionThirdUpload
                hasDownloaded={hasAccountProjectDownload}
                hasProject={hasAccountProject}
              />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <Divider />
      </div>
    </div>
  );
};

const AccordianCheveron = () => {
  return (
    <div className="flex items-center justify-center text-gray-700">
      <span className="chev-up">
        <ChevronUp />
      </span>
      <span className="chev-down">
        <ChevronDown />
      </span>
    </div>
  );
};
