import {
  canvasItemsSelectedItemIdsSelector,
  canvasItemsSelectedItemSelector,
} from 'features/selectors/canvasStateSelectors';

import {AlignPicker} from './SidebarModules/AlignPicker';
import {AudioComponentSidebar} from 'features/EditorCanvas/components/CanvasItemLayers/VideoClip/LayerNav/AudioComponentSidebar';
import {CaptionsComponentSidebar} from 'features/EditorCanvas/components/CanvasItemLayers/CaptionClip/LayerNav/CaptionsComponentSidebar';
import {CloseSidebar} from '../../CloseSidebar';
import {ImageComponentSidebar} from 'features/EditorCanvas/components/CanvasItemLayers/Image/LayerNav/ImageComponentSidebar';
import {ShapeComponentSidebar} from 'features/EditorCanvas/components/CanvasItemLayers/Square/LayerNav/ShapeComponentSidebar';
import {SmartComponentSidebar} from 'features/EditorCanvas/components/CanvasItemLayers/SmartComponent/LayerNav/SmartComponentSidebar';
import {TextComponentSidebar} from 'features/EditorCanvas/components/CanvasItemLayers/Text/LayerNav/TextComponentSidebar';
import {VideoComponentSidebar} from 'features/EditorCanvas/components/CanvasItemLayers/VideoClip/LayerNav/VideoComponentSidebar';
import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useSelector} from 'react-redux';

export const LayerSettings = () => {
  const projectId = useProjectId();

  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);

  const selectedItem = useSelector(canvasItemsSelectedItemSelector)(projectId);

  const isTextComponent =
    selectedItem && [ViewTypes.Text].includes(selectedItem.item.viewType);
  const isShapeComponent =
    selectedItem && [ViewTypes.Square].includes(selectedItem.item.viewType);
  const isCaptionsComponent =
    selectedItem && [ViewTypes.CaptionClip].includes(selectedItem.item.viewType);
  const isAudioComponent =
    selectedItem && [ViewTypes.AudioClip].includes(selectedItem.item.viewType);
  const isVideoComponent =
    selectedItem && [ViewTypes.VideoClip].includes(selectedItem.item.viewType);
  const isImageComponent =
    selectedItem && [ViewTypes.Image].includes(selectedItem.item.viewType);

  const isSmartComponent =
    selectedItem && [ViewTypes.SmartComponent].includes(selectedItem.item.viewType);

  if (selectedItemIds.length === 0) {
    return <CloseSidebar />;
  }

  return (
    <div className="p-5">
      {selectedItemIds.length > 1 && <AlignPicker />}
      {selectedItem && isTextComponent && <TextComponentSidebar />}
      {selectedItem && isShapeComponent && <ShapeComponentSidebar />}
      {selectedItem && isImageComponent && <ImageComponentSidebar />}
      {selectedItem && isCaptionsComponent && <CaptionsComponentSidebar />}
      {selectedItem && isAudioComponent && <AudioComponentSidebar />}
      {selectedItem && isVideoComponent && <VideoComponentSidebar />}
      {selectedItem && isSmartComponent && <SmartComponentSidebar />}
    </div>
  );
};
