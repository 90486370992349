import {AppThunk} from 'configureStore';
import {User} from '@stytch/stytch-react';
import {createSlice} from '@reduxjs/toolkit';

/**
 * A list of all feature flags and what their default
 * values should be while feature flags are loading.
 *
 * Add new feature flags here.
 */
export const flagDefaults = Object.freeze({
  'account-templates': true,
  'brand-kit-fonts': true,
  'brand-kit-themes': true,
  'canvas-scene-flag': true,
  'canvas-sidebar-templates': true,
  'canvas-time-offset': true,
  'cms-templates-page': true,
  'cms-templates': true,
  'find-and-replace': true,
  'download-srt': false,
  'global-search': false,
  'landing-pages': false,
  'new-project-modal': true,
  'oauth-google': true,
  'require-verification-email-confirmation': false,
  'share-page': false,
  'smart-components': true,
  'testimonial-recording-form': true,
  'timeline-waveform': true,
  'transcript-translation': false,
  'transcript-chapter-ui-button': false,
  'visual-governance-settings': true,
  'youtube-upload-modal': true,
  'zoom-import': false,
  templates: true,
});

export type FeatureFlag = keyof typeof flagDefaults;

export interface FeatureFlagProps {
  loading: boolean;
  error: any;
  flags: Record<FeatureFlag, boolean>;
}

export const initialState: FeatureFlagProps = {
  loading: false,
  error: null,
  flags: flagDefaults,
};

const featureFlagSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    fetchFeatureFlagsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchFeatureFlagsSuccessful(state, action) {
      const {flags} = action.payload;
      state.flags = flags;
      state.loading = false;
      state.error = null;
    },
    fetchFeatureFlagsFailed(state) {
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  fetchFeatureFlagsStart,
  fetchFeatureFlagsSuccessful,
  fetchFeatureFlagsFailed,
} = featureFlagSlice.actions;

export const fetchFeatureFlags =
  (user: User, company?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(fetchFeatureFlagsStart());
    const email = user?.emails && user?.emails.length ? user.emails[0].email : null;

    try {
      await window.ldcli.identify({
        key: email,
        email: email,
        firstName: user.name.first_name,
        lastName: user.name.last_name,
        custom: {
          company: company,
          clientOrigin: window.todesktop ? 'desktop-app' : 'web',
        },
      });
      const flags = window.ldcli.allFlags();

      dispatch(
        fetchFeatureFlagsSuccessful({
          flags,
        }),
      );
    } catch (error) {
      dispatch(fetchFeatureFlagsFailed());
    }

    // console.log('window.ldcli', window.ldcli.allFlags());
    // console.log('window.ldcli', window.ldcli.getUser());
  };

export default featureFlagSlice.reducer;
