import classNames from 'classnames';
import {useWatchElementSize} from 'features/Common/useElementSize';
import {DimensionType} from 'features/types/canvasItemsSlice';
import {FC} from 'react';
import {Spinner} from 'ui/Spinner';

export const PreviewContainer: FC<{dimensions: DimensionType; loading?: boolean}> = ({
  dimensions,
  loading,
  children,
}) => {
  const [ref, size] = useWatchElementSize<HTMLDivElement>();

  const aspectRatio = dimensions.width / dimensions.height;

  let height: number | undefined = undefined;
  if (size) height = size.width / aspectRatio;

  return (
    <div className="relative w-full" ref={ref}>
      <div className="w-full overflow-hidden rounded-lg bg-white" style={{height}}>
        <div
          className="absolute inset-0 flex flex-col items-center justify-center space-y-2"
          ref={ref}
        >
          <Spinner size={24} />
          <div className="text-sm font-medium text-gray-500">Loading preview&hellip;</div>
        </div>
        <div
          className={classNames(
            'relative z-10 h-full w-full bg-white transition-opacity',
            loading && height !== undefined && 'opacity-0',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
