import ClipListResultActions from '../ContentClips/ClipListResultActions';
import {LoadingSearchResult} from './LoadingSearchResult';
import {ResultItem} from './ClipSearchResult/ResultItem';
import {ResultUploadClips} from './ClipSearchResult/ResultUploadClips';
import {ResultUploadParent} from './ClipSearchResult/ResultUploadParent';
import {UploadClipListItem} from './SearchResults';
import {useSelector} from 'react-redux';
import {userLibrarySelector} from 'features/selectors/userLibrarySelectors';

export const AccountsLatestClips = () => {
  const {loadingClips, loadingUploads, userClips, userUploads} =
    useSelector(userLibrarySelector);
  const recentClips = userClips;
  const recentClipUploadIds = [...new Set(userClips.map(clip => clip.user_upload_id))];
  const recentUploadClipList = recentClipUploadIds.map(uploadId => {
    const uploadClips = recentClips.filter(clip => clip.user_upload_id === uploadId);
    return {
      uploadId,
      upload: userUploads.find(upload => upload.id === uploadId),
      uploadSecondaryItems: uploadClips,
    } as UploadClipListItem;
  });

  const listToDisplay = recentUploadClipList.slice(0, 10);

  if (recentUploadClipList.length === 0) {
    return <div />;
  }

  if (loadingClips || loadingUploads) {
    return <LoadingSearchResult />;
  }

  // console.log('listToDisplay', listToDisplay);
  // console.log('userUploads', userUploads);

  return (
    <div>
      <h3 className="-ml-1 mb-2 truncate px-3 font-medium text-gray-600">Recent clips</h3>
      <ul className="space-y-3 divide-y divide-gray-200">
        {listToDisplay.map(recentUploadClip =>
          recentUploadClip?.upload?.mux_playback_id ? (
            <ResultItem key={recentUploadClip.uploadId}>
              <ResultUploadParent uploadClip={recentUploadClip}>
                <ResultUploadClips uploadClip={recentUploadClip}>
                  {props => <ClipListResultActions {...props} />}
                </ResultUploadClips>
              </ResultUploadParent>
            </ResultItem>
          ) : (
            <div className="h-10 w-full animate-pulse" />
          ),
        )}
      </ul>
    </div>
  );
};
