import { ProjectIdProvider, useProjectId } from 'features/EditorCanvas/useProjectId';
import { PropsWithChildren, useEffect } from 'react';
import { STATIC_EMAIL_OVERRIDE, STATIC_TOKEN_OVERRIDE } from 'constants/environment';
import { useDispatch, useSelector } from 'react-redux';

import { CanvasWrapper } from 'features/EditorCanvas/components/AppCanvas/Canvas/CanvasWrapper';
import { GetRootState } from 'configureStore';
import { InverseCropProvider } from 'features/EditorCanvas/components/CanvasItem/InverseCrop';
import { LoadGoogleFonts } from 'features/Fonts/LoadGoogleFonts';
import { ProjectTimeProvider } from 'features/EditorCanvas/components/CanvasTime/TimeProvider';
import { SelectionFocusProvider } from 'features/EditorCanvas/components/SelectionFocusProvider';
import { canvasStateSelector } from 'features/selectors/canvasStateSelectors';
import { createGlobalStyle } from 'styled-components';
import { fetchProjectData } from 'features/userLibrarySlice';
import { recordingStartupPreparation } from 'features/canvasItemsSlice';
import { sceneFrameSelector } from 'features/selectors/sceneFrameSelectors';
import { selectCanvasProject } from 'features/selectors/canvasItemsSelectors';
import { useWindowSize } from '@react-hook/window-size';

export const StandaloneCanvas = () => {
  const projectId = useProjectId();
  const { canvasDimensions, backgroundColor } = useSelector(sceneFrameSelector)(projectId);

  const [windowWidth, windowHeight] = useWindowSize();

  const scaledWidth = windowWidth / canvasDimensions.width;
  const scaledHeight = windowHeight / canvasDimensions.height;
  const scale = Math.min(scaledWidth, scaledHeight);

  return (
    <div className="relative h-screen w-screen">
      <GlobalStyles background={backgroundColor.hex} />
      <div
        id="canvas"
        style={{ ...canvasDimensions, transform: `scale(${scale})` }}
        className="origin-top-left"
      >
        <CanvasWrapper projectId={projectId} />
      </div>
    </div>
  );
};

export const StandaloneCanvasContainer = ({
  projectId,
  children,
}: PropsWithChildren<{ projectId: string }>) => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector(canvasStateSelector);

  useEffect(() => {
    dispatch(fetchProjectData(STATIC_TOKEN_OVERRIDE, STATIC_EMAIL_OVERRIDE, projectId));
    dispatch(recordingStartupPreparation(projectId, STATIC_TOKEN_OVERRIDE));
  }, [projectId, dispatch]);

  if (isLoading) return <h1>Loading</h1>;

  return (
    <SelectionFocusProvider>
      <ProjectIdProvider projectId={projectId}>
        <HideIntercom />
        <InverseCropProvider>
          <ProjectTimeProvider endBehavior="noop">
            {children}
            <LoadGoogleFonts />
          </ProjectTimeProvider>
        </InverseCropProvider>
      </ProjectIdProvider>
    </SelectionFocusProvider>
  );
};

const GlobalStyles = createGlobalStyle<{ background: string }>`
  body {
    overflow: hidden;
    background-color: ${props => props.background};
  }
`;

const HideIntercom = createGlobalStyle`
  .intercom-lightweight-app {
    display: none !important;
  }
`;
