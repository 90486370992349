import classNames from 'classnames';
import {LandingPageComponent} from 'services/landingPageAPI';
import {EmbedCard} from './EmbedCard';
import {SectionCard} from './SectionCard';
import {VideoCard} from './VideoCard';

export const PageListItemCard = ({
  isDragging,
  landingPageId,
  state,
  setState,
  item,
}: {
  landingPageId: string;
  isDragging: boolean;
  item: LandingPageComponent;
  state: any;
  setState: any;
}) => {
  return (
    <div
      className={classNames(
        'focus:outline-none relative grid w-full cursor-grab grid-cols-1 items-start gap-y-8 gap-x-6 rounded-lg border border-gray-200 bg-white p-4 ring-0 ring-indigo-300 ring-opacity-0 transition hover:border-transparent hover:ring hover:ring-opacity-100 sm:grid-cols-12 lg:items-center lg:gap-x-8',
        isDragging && 'border-transparent ring-2 ring-opacity-100',
      )}
    >
      {
        {
          mux_video: (
            <VideoCard
              landingPageId={landingPageId}
              isDragging={isDragging}
              item={item}
              state={state}
              setState={setState}
            />
          ),
          embed: (
            <EmbedCard
              landingPageId={landingPageId}
              isDragging={isDragging}
              item={item}
              state={state}
              setState={setState}
            />
          ),
          section: (
            <SectionCard
              landingPageId={landingPageId}
              isDragging={isDragging}
              item={item}
              state={state}
              setState={setState}
            />
          ),
        }[item.element_type]
      }
    </div>
  );
};
