import {AlignLeft, ArrowLeft, ArrowRight, UploadCloud} from 'react-feather';
import {ArrowCircleLeftIcon, ArrowCircleRightIcon} from '@heroicons/react/outline';
import {StepHeading, usePrepareState, useUpload} from '../utils';
import {
  isAnonymousSelector,
  userMetadataSelector,
} from 'features/selectors/authSelectors';
import {
  useListUserUploadsQuery,
  useUpdateUserUploadMutation,
} from 'services/userUploadAPI';
import {useWizardState, useWizardStateId} from '../State';

import {Button} from 'ui/Button';
import {EmailCapture} from './EmailCapture';
import {EnterEmailModal} from '../EnterEmailModal';
import {FocusedFlowToolbar} from '../Toolbar';
import {Spinner} from 'ui/Spinner';
import {UploadButton} from 'features/Dashboard/DashboardPage/ContentBrandKit/UploadButton';
import {UploadFaq} from './UploadFaq';
import {Uploader} from 'features/UploadsViewer/Uploader';
import Uppy from '@uppy/core';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useSelector} from 'react-redux';
import {useState} from 'react';

export const UploadStep = () => {
  const {nextStep, setUpload, previousStep} = useWizardState();
  const {uploadId, upload} = useUpload();

  const [uppy, setUppy] = useState<Uppy.Uppy<Uppy.TypeChecking> | null>(null);

  const prepareState = usePrepareState();

  const [uppyResult, setUppyResult] = useState<string | null>(null);

  const isAnonymous = useSelector(isAnonymousSelector);
  const userMetadata = useSelector(userMetadataSelector);

  const {isAuthenticated} = useAuthAlt();

  const [wizardStateId] = useWizardStateId();
  const [updateUpload] = useUpdateUserUploadMutation();

  if (uploadId) {
    if (!upload) {
      return (
        <div className="flex justify-center py-9">
          <Spinner size={32} />
        </div>
      );
    }

    return (
      <div className="mx-auto w-full max-w-md space-y-4">
        <StepHeading title="Upload a video or audio file" center />
        <div className="rounded-lg bg-gray-100 p-4">
          <div className="font-semibold">Selected file:</div>
          <div className="text-sm">{upload.file_name}</div>
        </div>
        <div className="flex items-baseline space-x-4">
          <Button variant="primary" fullWidth onClick={() => nextStep()}>
            Use this file
          </Button>
          <div>or</div>
          <Button variant="secondary" fullWidth onClick={() => setUpload(null)}>
            Choose a different file
          </Button>
        </div>
      </div>
    );
  }

  const setUploadAndProceed = (uploadId: string | null) => {
    setUpload(uploadId);
    nextStep();
  };

  const handleNoAudioClick = () => {
    window.analytics.track('Pg-Focused-Flow -- FF-Audiogram -- Evt-Clk -- No audio file');
  };

  return (
    <div className="space-y-4">
      <StepHeading
        title="Upload a video or audio file"
        subtitle="You can trim it later on"
        center
      />

      <Uploader
        requireMedia
        shouldNotRemoveAfterUpload={false}
        onUploadComplete={async uploads => {
          if (wizardStateId) {
            await updateUpload({
              uploadId: uploads[0],
              originUI: {
                type: 'focused-flow',
                wizardStateId,
              },
            });
          }

          if (isAnonymous && !userMetadata?.notification_email) {
            // console.log('Here');
            setUppyResult(uploads[0]);
          } else {
            // console.log('Here2');
            setUploadAndProceed(uploads[0]);
          }
        }}
        onUppy={setUppy}
        onBeforePostProcess={async () => {
          await prepareState();
        }}
      />
      <div className="w-full text-center">
        <a
          onClick={handleNoAudioClick}
          href="https://assets-milk.s3.amazonaws.com/social/leaves-of-grass-from-walt-whitman+-+03.wav"
          className="text-lg font-medium text-indigo-500 underline"
          download="milkvideo-walt-whitman-test.wav"
        >
          No audio file? Try our sample.
        </a>
      </div>
      {import.meta.env.DEV && (
        <DebugList
          onClick={async id => {
            await prepareState();
            setUploadAndProceed(id);
          }}
        />
      )}
      {!isAuthenticated && <UploadFaq />}
      {!isAuthenticated && <EmailCapture />}
      <FocusedFlowToolbar>
        <Button variant="secondary" onClick={() => previousStep()} leftIcon={ArrowLeft}>
          Go back
        </Button>
        {uppy && (
          <UploadButton uppy={uppy} showProgress={false}>
            {children => (
              <Button variant="primary" leftIcon={UploadCloud}>
                Click to browse files
                {children}
              </Button>
            )}
          </UploadButton>
        )}
      </FocusedFlowToolbar>
      <EnterEmailModal
        title="Upload Processing"
        open={!!uppyResult}
        onSubmit={() => setUploadAndProceed(uppyResult)}
      >
        Depending on the upload size and site traffic, processing can take up to 15
        minutes.
      </EnterEmailModal>
    </div>
  );
};

const DebugList = ({onClick}: {onClick: (id: string) => void}) => {
  const [page, setPage] = useState(1);

  const {data: userUploads, isLoading, isFetching} = useListUserUploadsQuery(page);

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (!userUploads?.data) {
    return <div>No uploads :(</div>;
  }

  return (
    <div>
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{userUploads.from}</span> to{' '}
            <span className="font-medium">{userUploads.to}</span> of{' '}
            <span className="font-medium">{userUploads.count}</span> results
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <Button
            onClick={() => setPage(prev => prev - 1)}
            loading={isFetching}
            disabled={page === 1}
            leftIcon={ArrowLeft}
            size="small"
            _className={
              'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
            }
            iconOnly
          />
          <Button
            onClick={() => setPage(prev => prev + 1)}
            loading={isFetching}
            size="small"
            disabled={page === userUploads?.pages}
            leftIcon={ArrowRight}
            _className={
              'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
            }
            iconOnly
          />
        </div>
      </nav>
      <div className="font-bold">Debug list:</div>
      {userUploads?.data?.map(upload => (
        <div key={upload.id} onClick={() => onClick(upload.id)}>
          {upload.file_name}
        </div>
      ))}
    </div>
  );
};
