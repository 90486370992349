import React, {ReactElement, ReactNode} from 'react';
import {useStytchSession, useStytchUser} from '@stytch/stytch-react';

import {Navigate} from 'react-router-dom';
import {routePaths} from 'routes/routesHelper';

function RequireLogin({children}: {children: ReactElement}) {
  const user = useStytchUser();
  const session = useStytchSession();
  // console.log('user', user);
  // console.log('session', session);
  if (!session || !user) {
    return <Navigate to={`/${routePaths.user}/login`} />;
  }

  return children;
}

function RequireLoggedOut({children}: {children: ReactElement}) {
  const user = useStytchUser();
  const session = useStytchSession();
  if (session || user) {
    return <Navigate to={`/${routePaths.dashboard}`} />;
  }

  return children;
}

export {RequireLogin, RequireLoggedOut};
