import {useCallback, useState} from 'react';
import {
  useCreateRecordingInteractionResponseStateMutation,
  useUpdateRecordingInteractionResponseStateMutation,
} from 'services/recordingInteractionsAPI';

import {ApiRecordingSession} from './contexts/ApiRecordingSessionProvider';
import {MILK_API_RECORDING_STATE} from './components/RecordingSessionWrap';
import {useParams} from 'react-router-dom';

export const usePrepareUserRecordingInteractionState = () => {
  const {recordingInteractionFormId} = useParams<Record<string, string>>();
  const {
    setRecordingStep,
    recordingRoomId,
    setRecordingSubmission,
    setRecordingContactUser,
    updateRecordingRoomId,
  } = ApiRecordingSession.useContainer();

  // console.log('recordingInteractionState', recordingInteractionState);

  const [createRecordingCollectionState] =
    useCreateRecordingInteractionResponseStateMutation();
  const [updateRecordingCollectionState] =
    useUpdateRecordingInteractionResponseStateMutation();

  const ensureRecordingInteractionState = async () => {
    // console.log('userRecordingStateId', userRecordingStateId);
    if (!recordingInteractionFormId) {
      return;
    }

    const result = recordingRoomId
      ? await updateRecordingCollectionState({
          userRecordingSubmissionRoomName: recordingRoomId,
          step: {
            type: MILK_API_RECORDING_STATE.ROOM_JOIN,
          },
        })
      : await createRecordingCollectionState({
          userRecordingInteractionFormId: recordingInteractionFormId,
          step: {
            type: MILK_API_RECORDING_STATE.ROOM_CREATE,
          },
        });

    // console.log('result - 1', result);

    if ('data' in result) {
      if (result?.data?.step) {
        setRecordingStep({step: result?.data.step});
      }

      if (result?.data?.user_recording_contact) {
        setRecordingContactUser(result?.data?.user_recording_contact);
      }

      if (result?.data?.user_recording_interaction_submission) {
        setRecordingSubmission(result?.data?.user_recording_interaction_submission);
      }
    } else {
      throw result.error;
    }
  };

  return async () => {
    await ensureRecordingInteractionState();
  };
};
