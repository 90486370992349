import { AnimatePresence, motion } from 'framer-motion';
import { BASE_SCENE_FRAME_STATE, SceneFrameSliceProps } from 'features/sceneFrameSlice';
import {
  BaseItem,
  CanvasItem,
  DimensionType,
  ParsedCrop,
  PositionType,
  VideoClipItem,
} from 'features/types/canvasItemsSlice';
import {
  BoundActions,
  asyncTrack,
  objMap,
  showErrorNotification,
  useRtkReducer,
} from 'features/Common/utils';
import {
  CanvasItemSource,
  CreateProjectOptions,
  getSourceData,
  replaceProjectSource,
  useCreateProject,
} from 'features/Dashboard/useCreateProject';
import { DashParams, routePaths } from 'routes/routesHelper';
import { Modal, ModalContent, ModalHeader } from 'ui/Modal';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  StepHeader,
  Stepper,
  fitUserUpload,
  usePreventWindowClose,
  useSourceData,
} from './utils';
import { VFC, useEffect, useRef, useState } from 'react';

import { Button } from 'ui/Button';
import { ChooseTemplate } from './ChooseTemplate';
import { ClipPreview } from './ClipPreview';
import { Intro } from './Intro';
import { PenTool } from 'react-feather';
import { Review } from './Review';
import { SAMPLE_CLIP } from 'features/EditorCanvas/constants/ItemConstants';
import { SetImage } from './SetImage';
import { SetText } from './SetText';
import { TemplatePreview } from './TemplatePreview';
import { ViewTypes } from 'features/EditorCanvas/constants/ViewConstants';
import classNames from 'classnames';
import { fadeProps } from 'features/EditorCanvas/Sidebar/Sidebar';
import { getAuthToken } from 'services/utils';
import useDimensions from 'react-use-dimensions';
import { useExportProject } from 'features/EditorCanvas/components/useExportProject';

type ItemOverride = {
  text?: string;
  url?: string;
  crop?: ParsedCrop;
  dimension?: DimensionType;
  position?: PositionType;
};

export type WizardState = {
  step: number;
  templateSlug: string | null;
  templateLoading: boolean;
  name: string;
  items: Record<string, CanvasItem>;
  itemOverrides: Record<string, ItemOverride | undefined>;
  sceneFrame: SceneFrameSliceProps;
  showConfirmClose: boolean;
};

const initialState: WizardState = {
  step: 0,
  templateSlug: null,
  templateLoading: false,
  name: 'Untitled',
  items: {},
  itemOverrides: {},
  sceneFrame: BASE_SCENE_FRAME_STATE,
  showConfirmClose: false,
};

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setTemplateSlug(state, action: PayloadAction<string>) {
      state.templateSlug = action.payload;
    },
    setTemplateLoading(state, action: PayloadAction<boolean>) {
      state.templateLoading = action.payload;
    },
    nextStep(state, action: PayloadAction<Partial<WizardState> | undefined>) {
      return {
        ...state,
        step: state.step + 1,
        ...action.payload,
      };
    },
    previousStep(state) {
      if (state.step === 0) return;
      state.step = state.step - 1;
    },
    updateItemOverride(
      state,
      action: PayloadAction<{ id: string; item: Partial<ItemOverride> }>,
    ) {
      const { id, item } = action.payload;

      state.itemOverrides[id] = {
        ...state.itemOverrides[id],
        ...item,
      };
    },
    setTemplate(
      state,
      action: PayloadAction<Pick<WizardState, 'templateSlug' | 'items' | 'sceneFrame'>>,
    ) {
      return {
        ...state,
        ...action.payload,
        itemOverrides: {},
      };
    },
    setShowConfirmClose(state, action: PayloadAction<boolean>) {
      state.showConfirmClose = action.payload;
    },
  },
});

export type WizardActions = BoundActions<typeof wizardSlice>;

export type WizardStepProps = {
  state: WizardState;
  actions: WizardActions;
  source: CanvasItemSource;
  iframe: HTMLIFrameElement | null;
  createProject: (download?: boolean) => Promise<void>;
};

export type WizardStepFC = VFC<WizardStepProps>;
export type ItemStepFC = VFC<WizardStepProps & { itemId: string; item: CanvasItem }>;

type Step =
  | {
    title?: string;
    type: 'wizard';
    component: WizardStepFC;
  }
  | {
    title?: string;
    type: 'item';
    component: ItemStepFC;
    itemId: string;
    item: CanvasItem;
  };

export type StepData = Omit<Step, 'component'>;

export const stepContainer = 'flex flex-col justify-items-stretch space-y-4 flex-1';

export const NewProjectWizard = ({
  source,
  onClose,
}: {
  source?: CanvasItemSource;
  onClose: () => void;
}) => {
  const { state, actions } = useRtkReducer(wizardSlice, initialState);

  const sourceData = useSourceData(source);

  const name = sourceData?.clip?.clip_name ?? sourceData?.userUpload.file_name;
  useEffect(() => {
    if (name) actions.setName(name);
  }, [name, actions]);

  const steps: Step[] = [];

  steps.push({ type: 'wizard', component: Intro, title: 'Create a video' });
  steps.push({ type: 'wizard', component: ChooseTemplate });

  const sortedItemIds = Object.keys(state.items).sort(
    (a, b) => state.items[a].order - state.items[b].order,
  );

  for (const itemId of sortedItemIds) {
    const item = state.items[itemId];
    if (item.locked) continue;
    if (item.layerName.trim().length === 0) continue;

    if (item.viewType === ViewTypes.Text) {
      steps.push({ type: 'item', component: SetText, itemId, item });
    }

    if (item.viewType === ViewTypes.Image) {
      steps.push({ type: 'item', component: SetImage, itemId, item });
    }

    // if (item.viewType === ViewTypes.VideoClip && itemHasCrop(item)) {
    //   steps.push({type: 'item', component: SetCrop, itemId, item});
    // }
  }

  steps.push({ type: 'wizard', component: Review });

  const clickedCustomize = useRef(false);
  usePreventWindowClose(() => !!state.templateSlug && !clickedCustomize.current);

  // UI TODO (jacques): Build logic to unmount entire modal comp. to
  // avoid this, and improve perf
  const modalOpen = !!source;
  useEffect(() => {
    if (!modalOpen) actions.reset();
  }, [actions, modalOpen]);

  const [iframe, setIframe] = useState<HTMLIFrameElement | null>(null);

  const createProjectHookFn = useCreateProject();
  const { exportProject } = useExportProject();

  const createProject = async (download?: boolean) => {
    try {
      const options: CreateProjectOptions = {
        name: state.name,
      };

      if (state.templateSlug) {
        options.items = objMap(state.items, (item, id) => ({
          ...item,
          ...state.itemOverrides[id],
        }));

        options.sceneFrame = { ...state.sceneFrame };
      } else {
        if (!source) return;

        const token = await getAuthToken();

        const { userUpload } = await getSourceData(token, source);

        const { dimension } = fitUserUpload({
          userUpload,
          within: { width: 1920, height: 1080 },
        });

        const videoItem: BaseItem & VideoClipItem = {
          ...SAMPLE_CLIP,
          dimension,
        };

        options.items = await replaceProjectSource({ videoItem }, source);
        options.sceneFrame = { ...BASE_SCENE_FRAME_STATE, canvasDimensions: dimension };
      }

      const projectSlug = await createProjectHookFn(options);

      if (download) {
        await exportProject(projectSlug, 1);
      } else {
        window.location.href = `/${routePaths.create}/${DashParams.CanvasParam}/${projectSlug}`;
      }

      await new Promise(() => { });
    } catch (error) {
      showErrorNotification({
        title: 'Something went wrong',
        // @ts-ignore
        message: error.message,
        // @ts-ignore
        error,
      });
    }
  };

  const commonProps = { state, actions, source: source!, iframe, createProject };

  const activeStep = steps[state.step];

  const [ref, { height }] = useDimensions()

  return (
    <Modal
      title=""
      size="2xl"
      open={modalOpen}
      onClose={() => actions.setShowConfirmClose(true)}
      customContent
      fixedHeight={520}
    >
      <div ref={ref} className="grid h-full grid-cols-2 relative">
        <div className="overflow-scroll flex flex-col justify-center bg-gray-100 p-3 relative">
          {state.templateSlug ? (
            <div className="relative flex flex-col justify-center" style={{ height: height }} >
              <div className="z-20">
                <TemplatePreview
                  activeItemId={activeStep.type === 'item' && activeStep.itemId}
                  cropItemId={
                    activeStep.type === 'item' &&
                    activeStep.item.viewType === ViewTypes.VideoClip &&
                    activeStep.itemId
                  }
                  state={state}
                />
              </div>
              <div className="absolute bottom-4 left-0 z-50 right-0 mt-4 flex justify-center">
                <Button
                  variant="inline"
                  size="small"
                  onClick={async () => {
                    await asyncTrack('TmpWzd -- Evt-Clk -- "Customize template"', {
                      step: state.step,
                    });

                    clickedCustomize.current = true;

                    try {
                      await createProject();
                      // eslint-disable-next-line no-empty
                    } catch (e) {
                      clickedCustomize.current = false;
                    }
                  }}
                  negativeMargin
                  leftIcon={PenTool}
                  disabled={state.templateLoading}
                >
                  Customize in the designer
                </Button>
              </div>
            </div>
          ) : (
            <ClipPreview userUpload={sourceData?.userUpload} />
          )}

          {state.step >= 2 && (
            <Stepper steps={steps.length - 1} activeStep={state.step - 1} />
          )}
        </div>
        <ModalContent className="flex h-full flex-col overflow-y-auto pb-0">
          <ModalHeader
            title={''}
            onClose={() => actions.setShowConfirmClose(true)}
            size="2xl"
          />
          <AnimatePresence initial={false} exitBeforeEnter>
            <motion.div {...fadeProps} key={state.step} className={stepContainer}>
              {activeStep.type === 'wizard' ? (
                <activeStep.component {...commonProps} />
              ) : (
                <activeStep.component
                  {...commonProps}
                  item={activeStep.item}
                  itemId={activeStep.itemId}
                />
              )}
            </motion.div>
          </AnimatePresence>
        </ModalContent>
        <div
          className={classNames(
            'absolute inset-0 z-50 flex flex-col items-center justify-center space-y-4 bg-white text-center transition',
            !state.showConfirmClose && 'pointer-events-none opacity-0',
          )}
        >
          <StepHeader
            heading="Are you sure?"
            state={{ ...state, step: 0 }}
            actions={actions}
          >
            If you close this window you will lose all your progress so far.
          </StepHeader>
          <div className="space-x-4">
            <Button variant="secondary" destructive onClick={onClose}>
              Yes, close
            </Button>
            <Button
              variant="secondary"
              onClick={() => actions.setShowConfirmClose(false)}
            >
              No, keep creating
            </Button>
          </div>
        </div>
      </div>
    </Modal >
  );
};
