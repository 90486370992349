import arrayUnique from 'array-unique';
import {GetRootState} from 'configureStore';
import {objForEach} from 'features/Common/utils';
import {ExternalFontLoaderFont} from 'features/fontDataSlice';
import {EXTERNAL_FONTS_WITH_PREVIEW, FontSource} from 'features/Fonts/constants/fonts';
import {
  convertItemFontsToExternalLoaderFonts,
  getFontLoaderFont,
  identifyFontsInItems,
} from 'features/Fonts/utils';
import {selectCanvasProject} from 'features/selectors/canvasItemsSelectors';
import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useRecentUsage} from 'services/recentUsageAPI';
import {useProjectId} from '../EditorCanvas/useProjectId';

export const LoadGoogleFonts = () => {
  const projectId = useProjectId();

  const fontsToLoad = useSelector((state: GetRootState) => {
    const {items} = selectCanvasProject(state, projectId);
    const previewItems = state.sceneFrame.projects[projectId]?.preview?.items;
    const canvasItemFonts = identifyFontsInItems({...items, ...previewItems});
    const fonts = convertItemFontsToExternalLoaderFonts(canvasItemFonts);
    return fonts.filter(font => font.source === FontSource.GoogleFonts);
  });

  const {recentUsage} = useRecentUsage();

  const recentUsageFonts: string[] = [];
  if (recentUsage) {
    if (recentUsage.captions_font) {
      recentUsageFonts.push(recentUsage.captions_font);
    }

    if (recentUsage.text_font) {
      recentUsageFonts.push(recentUsage.text_font);
    }
  }

  for (const font of recentUsageFonts) {
    const externalFont = EXTERNAL_FONTS_WITH_PREVIEW.find(
      externalFont => externalFont.family === font,
    );

    if (externalFont) {
      fontsToLoad.push(getFontLoaderFont(externalFont));
    }
  }

  if (fontsToLoad.length === 0) {
    return null;
  }

  const uniqueFonts = getUniqueFonts(fontsToLoad);

  const families: string[] = [];
  objForEach(uniqueFonts, (variants, family) => {
    families.push(`${family.replace(/ +/g, '+')}:${variants.join(',')}`);
  });

  const href = `https://fonts.googleapis.com/css?family=${families.join('|')}`;

  return <LinkTag href={href} />;
};

export const LinkTag = ({href}: {href: string}) => {
  const link = useRef<HTMLLinkElement>();

  useEffect(() => {
    if (!link.current) {
      link.current = document.createElement('link');
      link.current.rel = 'stylesheet';
      link.current.href = href;
      document.head.appendChild(link.current);
    }

    link.current.setAttribute('href', href);
  }, [href]);

  return null;
};

const getUniqueFonts = (fonts: ExternalFontLoaderFont[]) => {
  const uniqueFonts: Record<string, string[]> = {};

  for (const font of fonts) {
    const variants = font.variants.map(variant => {
      if (variant === 'regular') return '400';
      if (variant === 'italics') return '400i';
      return variant.toString();
    });

    if (!uniqueFonts[font.family]) {
      uniqueFonts[font.family] = [];
    }

    uniqueFonts[font.family].push(...variants);
    uniqueFonts[font.family] = arrayUnique(uniqueFonts[font.family]);
  }

  return uniqueFonts;
};
