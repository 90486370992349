import {Switch} from '@headlessui/react';
import classNames from 'classnames';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {updateIsDefaultColorsDisabled} from 'features/authSlice';
import {Card} from 'features/Common/Card';
import {CardHeader} from 'features/Common/CardHeader';
import {isDefaultColorsDisabledSelector} from 'features/selectors/authSelectors';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

export function SettingsColorsCard() {
  const {getAccessTokenSilently} = useAuthAlt();

  const disabled = useSelector(isDefaultColorsDisabledSelector);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleChangeDefaultColorsDisabled = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    try {
      const token = await getAccessTokenSilently();
      await dispatch(updateIsDefaultColorsDisabled(token, !disabled));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Card>
        <CardHeader title="Colors" subtitle="" />
        <div id="account-glossary" className="shadow sm:overflow-hidden">
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <Switch.Group as="div" className="flex items-center justify-between">
              <span className="flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium text-gray-900"
                  passive
                >
                  Disable default colors
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  When the switch is active, the default color palette will be hidden.
                </Switch.Description>
              </span>
              <Switch
                checked={disabled}
                onChange={handleChangeDefaultColorsDisabled}
                className={classNames(
                  disabled ? 'bg-indigo-600' : 'bg-gray-200',
                  'focus:outline-none relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:pointer-events-none disabled:animate-pulse disabled:opacity-50',
                )}
                disabled={isLoading}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    disabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>
      </Card>
    </>
  );
}
