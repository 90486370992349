import {baseApi} from './baseAPI';

export type RecordingFormContact = {
  id: string;
  email: string;
  is_anonymous: boolean;
  meta: Record<string, string>;
  name: string;
  phone: string;
  session_key: string;
  created_at: number;
  updated_at: number;
  account_id: string;
};

const recordingContactsAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['RecordingForm']})
  .injectEndpoints({
    endpoints: builder => ({
      recordingFormContacts: builder.query<RecordingFormContact[], void>({
        query: () => ({
          url: `user_recording_contacts`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading recording form contacts data',
        }),
        providesTags: ['RecordingFormContact'],
      }),
    }),
  });

export const {useRecordingFormContactsQuery} = recordingContactsAPI;
