import classNames from 'classnames';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {Pause, Play, RotateCcw, RotateCw} from 'react-feather';
import {useHotkeys} from 'react-hotkeys-hook';
import {IconButton} from 'ui/IconButton';
import {usePlayback} from '../CanvasTime/usePlayback';
import {useTimeCode} from '../CanvasTime/utils';

export function TimeCode({
  skipControls,
  enableShortcuts = true,
}: {
  skipControls?: boolean;
  enableShortcuts?: boolean;
}) {
  const timeCode = useTimeCode(1);

  const [minutes, seconds] = timeCode.split(':');

  const {isPlaying, toggle, seekBy} = usePlayback();

  const scenesEnabled = useFeatureFlag('canvas-scene-flag');

  const togglePlayback = () => {
    window.analytics.track('Handle video play');
    toggle({reset: !scenesEnabled});
  };

  useHotkeys(
    'space',
    event => {
      if (!enableShortcuts) return;
      event.preventDefault();
      togglePlayback();
    },
    [togglePlayback],
  );

  return (
    <div className="flex select-none items-center justify-center py-2">
      <div
        className={classNames(
          'flex min-w-[200px] items-center justify-center rounded-full border border-gray-300 bg-white px-4 py-1 transition-opacity',
        )}
      >
        {skipControls && (
          <IconButton
            icon={RotateCcw}
            variant="inline"
            label="Jump back 5 seconds"
            size="small"
            onClick={() => {
              window.analytics.track('Handle video reverse');
              seekBy({amountMs: -5000});
            }}
            preventDoubleClick={false}
          />
        )}
        <IconButton
          icon={isPlaying ? Pause : Play}
          variant="inline"
          label={isPlaying ? 'Pause' : 'Play'}
          size="small"
          onClick={togglePlayback}
          shortcut="space"
        />
        {skipControls && (
          <IconButton
            icon={RotateCw}
            variant="inline"
            label="Jump forward 5 seconds"
            size="small"
            onClick={() => {
              window.analytics.track('Handle video fast forward');
              seekBy({amountMs: 5000});
            }}
            preventDoubleClick={false}
          />
        )}
        <p
          className="flex w-[150px] justify-center text-2xl font-semibold text-gray-800"
          aria-label="Playback time"
        >
          <span className="min-w-[40px] pr-[3px] text-right">{minutes}</span>
          <span>:</span>
          <span className="min-w-[60px] pl-[3px] text-left">{seconds}</span>
        </p>
      </div>
    </div>
  );
}
