import {
  SAMPLE_CAPTION,
  SAMPLE_CLIP,
  SAMPLE_CLIP_CAPTION_ITEM,
  SAMPLE_VIDEO,
} from './constants/ItemConstants';
import {ViewTypes, playTimeMediaTypes} from './constants/ViewConstants';

import {CanvasItem} from 'features/types/canvasItemsSlice';

export const addMediaPropertiesToOldClientStateItems = (
  currentItems: Record<string, CanvasItem>,
  oldClientStateItems: Record<string, CanvasItem>,
) => {
  if (!oldClientStateItems) {
    return currentItems;
  }
  const newItems = {};
  Object.entries(currentItems).forEach(([key, item]) => {
    if (playTimeMediaTypes.includes(item.viewType as any)) {
      switch (item.viewType) {
        case ViewTypes.CaptionClip:
          newItems[key] = oldClientStateItems[key]
            ? {
                ...SAMPLE_CLIP_CAPTION_ITEM,
                ...oldClientStateItems[key],
                ...item,
              }
            : {
                ...SAMPLE_CLIP_CAPTION_ITEM,
                ...item,
              };
          break;
        case ViewTypes.Caption:
          newItems[key] = oldClientStateItems[key]
            ? {...SAMPLE_CAPTION, ...oldClientStateItems[key], ...item}
            : {...SAMPLE_CAPTION, ...item};
          break;
        case ViewTypes.AudioClip:
        case ViewTypes.VideoClip:
          newItems[key] = oldClientStateItems[key]
            ? {...SAMPLE_CLIP, ...oldClientStateItems[key], ...item}
            : {...SAMPLE_CLIP, ...item};
          break;
        case ViewTypes.Video:
          newItems[key] = oldClientStateItems[key]
            ? {...SAMPLE_VIDEO, ...oldClientStateItems[key], ...item}
            : {...SAMPLE_VIDEO, ...item};
          break;
        default:
          break;
      }
    } else {
      newItems[key] = oldClientStateItems[key]
        ? {...oldClientStateItems[key], ...item}
        : {...item};
    }
  });
  return newItems;
};
