import {useDispatch, useSelector} from 'react-redux';

import ClickAwayListener from 'react-click-away-listener';
import {Link} from 'react-router-dom';
import {Transition} from 'react-transition-group';
import {authSelector} from 'features/selectors/authSelectors';
import {changeActiveAccount} from 'features/authSlice';
import {routePaths} from 'routes/routesHelper';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useState} from 'react';
import classNames from 'classnames';

export default function AccountPickerNav({loadFromBottom = false}) {
  const dispatch = useDispatch();
  const {logout, getAccessTokenSilently} = useAuthAlt();
  const {accountWrapper, accounts, user} = useSelector(authSelector);
  const account = accountWrapper?.account;
  const [isAccountNavOpen, setIsAccountNavOpen] = useState(false);

  const otherAccounts = accounts.filter(
    accountFilter => accountFilter.name !== account.name && !accountFilter.personal,
  );
  const handleClickAway = () => {
    setIsAccountNavOpen(false);
  };
  const handleChangeAccount = accountEl => {
    window.analytics.track('Change active account clicked');
    setIsAccountNavOpen(false);

    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(
          changeActiveAccount({
            token,
            account: accountEl,
            email: user.email,
          }),
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  if (!account || !account.name || !user) {
    return null;
  }

  return (
    <div className="relative inline-block">
      <div
        className="flex h-full flex-col justify-start truncate"
        style={{maxWidth: 156}}
      >
        <button
          onClick={evt => {
            evt.stopPropagation();
            window.analytics.track('Toggle account menu');
            setIsAccountNavOpen(!isAccountNavOpen);
          }}
          type="button"
          className="inline-flex h-full w-full items-center justify-start truncate rounded-md border border-transparent bg-white px-2 py-2 text-left text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          {account && account.name}
        </button>
      </div>
      {isAccountNavOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Transition
            show={isAccountNavOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {ref => (
              <div
                ref={ref}
                className={classNames(
                  'absolute left-0 mt-2 w-56 divide-y divide-gray-100 overflow-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5',
                  {
                    'bottom-12 origin-bottom-right ': loadFromBottom,
                    'origin-top-right ': !loadFromBottom,
                  },
                )}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
                style={{zIndex: 1000, maxHeight: 500}}
              >
                <div className="py-3">
                  <p className="mb-2 px-4 text-xs text-gray-400">{user.email}</p>
                  <button
                    // onClick={() => {
                    //   setIsAccountNavOpen(false);
                    //   logout({ returnTo: window.location.origin });
                    // }}
                    className="flex w-full flex-row items-center justify-between px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    <p className="truncate text-sm font-medium text-gray-900">
                      {account.name}
                    </p>
                    <span>
                      <svg
                        className="h-4 w-4 text-gray-400"
                        x-description="Heroicon name: check"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </button>
                  {otherAccounts.map((accountEl, key) => {
                    return (
                      <button
                        key={`account-select-${key}`}
                        onClick={() => {
                          handleChangeAccount(accountEl);
                        }}
                        className="group block flex w-full flex-row items-center justify-start px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                      >
                        <p className="truncate text-sm font-medium text-gray-900">
                          {accountEl.name}
                        </p>
                      </button>
                    );
                  })}
                </div>
                <div className="py-1">
                  <Link
                    onClick={() => {
                      setIsAccountNavOpen(false);
                      window.analytics.track(
                        'Click workspace settings from account menu',
                      );
                    }}
                    to={`/${routePaths.dashboard}/settings`}
                    className="block px-4 py-2 text-xs text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                    role="menuitem"
                  >
                    Workspace settings
                  </Link>

                  <Link
                    onClick={() => {
                      setIsAccountNavOpen(false);
                      window.analytics.track(
                        'Click create new workspace from account menu',
                      );
                    }}
                    to={`/${routePaths.user}/join`}
                    className="block px-4 py-2 text-xs text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                    role="menuitem"
                  >
                    Create or join a workspace
                  </Link>
                </div>
                <div className="py-1">
                  <button
                    onClick={() => {
                      setIsAccountNavOpen(false);
                      window.analytics.track('Log out from account menu');
                      logout({returnTo: window.location.origin});
                    }}
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Logout
                  </button>
                </div>
              </div>
            )}
          </Transition>
        </ClickAwayListener>
      )}
    </div>
  );
}
