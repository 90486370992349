import {
  CalendarIcon,
  FilmIcon,
  SpeakerphoneIcon,
  TerminalIcon,
} from '@heroicons/react/outline';
import {DashParams, routePaths} from 'routes/routesHelper';
import {ProjectRecord, useListProjectsQuery} from 'services/projectAPI';

import {Button} from 'ui/Button';
/* This example requires Tailwind CSS v2.0+ */
import {ChevronRightIcon} from '@heroicons/react/solid';
import {Dashboard} from '@uppy/react';
import {Divider} from 'features/EditorCanvas/Sidebar/SidebarMenu';
import {Film} from 'react-feather';
import {Link} from 'react-router-dom';
import UploadIcon from 'features/Dashboard/shared/UploadIcon';
import classNames from 'classnames';
import cn from 'classnames';
import {format} from 'date-fns';
import {isVideoMedia} from 'features/Dashboard/DashboardUploadDetails/utils';

export const LatestProjects = () => {
  return (
    <div className="space-y-3">
      <UploadsListContentWithoutWrap />
    </div>
  );
};

export const UploadsListContentWithoutWrap = () => {
  const {
    data: flatResults,
    isLoading,

    isSuccess,
    isError,
  } = useListProjectsQuery(1);

  const shortProjectList = isSuccess
    ? flatResults.data.slice(1, flatResults.data.length > 5 ? 5 : flatResults.data.length)
    : [];

  return (
    <div className="relative divide-y divide-gray-300">
      <div className="grid grid-cols-1">
        <div className="col-span-1 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-800 sm:pl-0">
          Recent projects
        </div>
      </div>

      {isLoading && <LoadingProjects />}
      {isError && <div>Something went wrong</div>}
      {isSuccess &&
        (flatResults?.data.length === 0 ? (
          <EmptyProject />
        ) : (
          <div className={cn('relative w-full gap-3 pt-3 md:grid md:grid-cols-5')}>
            {flatResults.data.length > 0 && (
              <ProjectElement unit="hero" project={flatResults.data[0]} />
            )}
            {flatResults.data.length > 1 && (
              <div className="col-span-3 py-2 px-3">
                {shortProjectList.map((project: ProjectRecord, index) => {
                  return (
                    <>
                      {index !== 0 && <Divider size="small" color="bg-gray-100" />}
                      <ProjectElement key={project.id} project={project} />
                    </>
                  );
                })}
                <div className="flex w-full justify-start py-2">
                  <Link to={`/${routePaths.dashboard}/${DashParams.ProjectsParam}`}>
                    <Button _className=" text-xs" variant="tertiary">
                      View all projects
                    </Button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

function startAndEnd(str: string, stringLength = 14) {
  if (!str) {
    return str;
  }
  if (str.length > stringLength) {
    return `${str.substr(0, stringLength)}...${str.substr(
      str.length - (stringLength - 10),
      str.length,
    )}`;
  }
  return str;
}

const ProjectElement = ({
  project,
  unit = 'row',
}: {
  project: ProjectRecord;
  unit?: 'hero' | 'row';
}) => {
  const previewImage = project?.latest_preview
    ? project.latest_preview
    : 'http://fakeimg.pl/300x300/';

  if (unit === 'hero') {
    return (
      <div
        data-upload={project.id}
        className={cn(
          'flex flex-col justify-center divide-y overflow-hidden rounded-md border md:col-span-2',
          'transition hover:bg-gray-50',
        )}
      >
        <Link
          className="flex flex-grow flex-col items-center justify-center bg-gray-50"
          to={`/${routePaths.create}/${DashParams.CanvasParam}/${project.slug}`}
        >
          <div className="h-56 w-72">
            <div className="bg-gray-50 p-3 group-hover:bg-gray-100">
              <div className="aspect-w-4 aspect-h-3 flex flex-col items-center justify-center overflow-hidden">
                <img
                  className="object-contain object-center"
                  src={previewImage}
                  alt={`Project preview`}
                />
              </div>
            </div>
          </div>
        </Link>
        <div className="flex w-full flex-row items-center truncate bg-white py-1 px-4">
          <div className="flex aspect-1 h-10 w-10 items-center justify-center">
            <div
              className={classNames(
                'flex items-center justify-center rounded-full',
                'h-8 w-8 bg-blue-300 p-2',
              )}
            >
              <Film className="w-5 text-white" />
            </div>
          </div>

          <div>
            <Link
              className="flex flex-col items-start justify-center px-1"
              to={`/${routePaths.create}/${DashParams.CanvasParam}/${project.slug}`}
            >
              <div className="truncate text-xs font-medium text-gray-900">
                Name: {startAndEnd(project.name)}
              </div>
              <div className="truncate text-xs font-medium text-gray-400">
                Updated: {format(project.updated_at, 'P p')}
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Link
      className="group flex space-x-4 pr-4 transition hover:bg-gray-50"
      to={`/${routePaths.create}/${DashParams.CanvasParam}/${project.slug}`}
      data-upload={project.id}
      style={{
        width: '100%',
      }}
    >
      <div className="flex h-20 w-20 flex-col items-center justify-center ">
        <div className="w-full rounded-lg bg-gray-50 p-2 group-hover:bg-gray-100 ">
          <div className="aspect-w-4 aspect-h-3 flex flex-col items-end justify-end overflow-hidden">
            <img
              className="object-contain object-center"
              src={previewImage}
              alt={`Project preview`}
            />
          </div>
        </div>
      </div>

      <div className="col-span-4 flex items-center overflow-x-scroll">
        <div className="blog whitespace-nowrap">
          <div className="text-xs font-medium text-gray-900">{project.name}</div>
          <div className="text-xs font-medium text-gray-400">
            Updated: {format(project.updated_at, 'P p')}
          </div>
        </div>
      </div>
    </Link>
  );
};

const LoadingProjects = () => {
  return (
    <div className="relative grid w-full gap-3 divide-gray-100 pt-3 md:grid-cols-5">
      <div className="col-span-2 flex flex-col divide-y divide-gray-100 rounded-md border border-gray-100">
        <div className="flex flex-grow flex-col items-center justify-center  py-4">
          <div className="h-56 w-72 animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        </div>
        <div className="flex w-full flex-1 flex-row items-center truncate py-1 px-2">
          <div className="flex aspect-1 h-10 w-10 items-center justify-center">
            <div
              className={classNames(
                'flex items-center justify-center rounded-full',
                'h-8 w-8  animate-pulse overflow-hidden rounded-md bg-gray-50 p-2',
              )}
            ></div>
          </div>

          <div className="w-full">
            <div className="flex flex-col items-start justify-center space-y-1 px-2 py-4">
              <div className="h-4 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>

              <div className="h-3 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-3 space-y-1 py-2 px-3">
        <div className="h-10 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        <Divider color="bg-gray-100 animate-pulse" size="small" />
        <div className="h-10 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        <Divider color="bg-gray-100 animate-pulse" size="small" />
        <div className="h-10 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        <Divider color="bg-gray-100 animate-pulse" size="small" />
        <div className="h-10 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        <div className="flex w-full justify-end p-2">
          <Link to={`/${routePaths.dashboard}/${DashParams.ProjectsParam}`}>
            <Button _className="text-xs" variant="tertiary">
              View all projects
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export function EmptyProject() {
  return (
    <div className="py-6">
      <button
        type="button"
        className="relative flex w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
      >
        <div className="bordre-2 flex h-20 w-20 items-center justify-center rounded-full border-2 border-gray-300">
          <FilmIcon className="mx-auto h-12 w-8 text-gray-400" />
        </div>
        <span className="mt-2 block text-sm font-medium text-gray-400">
          Create a new project
        </span>
      </button>
    </div>
  );
}
