import {BASE_REDIRECT_URL} from 'constants/environment';
import {Button} from 'ui/Button';
import CopyToClipboard from 'react-copy-to-clipboard';
import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import NotificationInfo from 'features/Notifications/NotificationInfo';
import {Share} from 'react-feather';
import {store} from 'react-notifications-component';

export const recordingFormUrl = (recordingFormId: string) => {
  const previewUrl = `${BASE_REDIRECT_URL}/share/f/${recordingFormId}`;
  return previewUrl;
};

export const ClickToCopy = ({
  url,
  iconOnly = false,
  children,
  notificationTitle = 'Copied link',
  notificationMessage = 'You can now share the recording form link.',
  icon = Share,
  linkText = 'Share link',
  fullWidth = true,
}: {
  iconOnly?: boolean;
  url: string;
  children?: any;
  notificationMessage?: string;
  notificationTitle?: string;
  linkText?: string;
  icon?: any;
  fullWidth?: boolean;
}) => {
  const handleCopyConfirm = () => {
    store.addNotification({
      ...NOTIFICATION_BASE,
      content: (
        <NotificationInfo title={notificationTitle} message={notificationMessage} />
      ),
    });
  };

  return (
    <CopyToClipboard text={url} onCopy={handleCopyConfirm}>
      <div className="inline-flex">
        <Button
          leftIcon={icon}
          alignText={fullWidth ? 'between' : 'left'}
          variant="tertiary"
          _className="!text-xs inline-flex"
          size="small"
          iconOnly={iconOnly}
        >
          {children}
        </Button>
      </div>
    </CopyToClipboard>
  );
};
