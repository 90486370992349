import convert from 'color-convert';

type RGBColor = [number, number, number];

const FS = 255;
const offset = 0.05;

const linearisedValue = (color: number) => {
  return parseFloat(Math.pow(color / FS, 2.2).toFixed(5));
};

const luminosity = (color: RGBColor) => {
  return (
    linearisedValue(color[0]) * 0.2126 +
    linearisedValue(color[1]) * 0.7152 +
    linearisedValue(color[2]) * 0.0722
  );
};

export const getContrastRatio = (color1: RGBColor, color2: RGBColor) => {
  const L1 = luminosity(color1);
  const L2 = luminosity(color2);

  if (L1 > L2) return (L1 + offset) / (L2 + offset);
  return (L2 + offset) / (L1 + offset);
};

export const getForegroundColor = (color: string) => {
  const white: RGBColor = [255, 255, 255];
  const rgbColor = convert.hex.rgb(color);

  return getContrastRatio(white, rgbColor) >= 2 ? 'white' : 'black';
};
