import {ClientStateType} from 'features/serverSyncSlice';

export const CANVAS_ITEMS_STATE_KEY = 'items';
export const ITEM_CAPTIONS_STATE_KEY = 'itemCaptions';
export const SCENE_FRAME_STATE_KEY = 'sceneFrame';
export const CANVAS_SCENES = 'canvasScenes';

export const CRUCIAL_CLIENT_STATE_KEYS = [
  CANVAS_ITEMS_STATE_KEY,
  ITEM_CAPTIONS_STATE_KEY,
  SCENE_FRAME_STATE_KEY,
];

export const checkCrucialSyncStateKeys = (lastSyncState: Partial<ClientStateType>) => {
  return Object.keys(lastSyncState).every(val => CRUCIAL_CLIENT_STATE_KEYS.includes(val));
};
