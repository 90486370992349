import {addImageCanvasItem, addVideoUploadCanvasItem} from 'features/canvasItemsSlice';
import {
  isAudioMedia,
  isImageMedia,
  isVideoMedia,
} from 'features/Dashboard/DashboardUploadDetails/utils';

import {CanvasItem} from 'features/types/canvasItemsSlice';
import ItemDetailAudio from './ItemDetailAudio';
import ItemDetailCaption from './ItemDetailCaption';
import ItemDetailVideo from './ItemDetailVideo';
import {ItemLayerSources} from 'features/EditorCanvas/constants/ViewConstants';
import {SAMPLE_VIDEO} from 'features/EditorCanvas/constants/ItemConstants';
import UploadIcon from 'features/Dashboard/shared/UploadIcon';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {addCaptionItemFromItem} from 'features/Captions/CaptionItems';
import {useDispatch} from 'react-redux';
import {useRecentUsage} from 'services/recentUsageAPI';
import {useScenes} from '../../CanvasTime/useScenes';
import {useState} from 'react';

const FILE_NAME_SHORTENING_LENGTH = 20;

function startAndEnd(str: string) {
  if (!str) {
    return str;
  }
  if (str.length > FILE_NAME_SHORTENING_LENGTH) {
    return `${str.substr(0, 14)}...${str.substr(str.length - 5, str.length)}`;
  }
  return str;
}

type SelectedUploadedItemDetailProps = {
  uploadId: string;
  projectId: string;
  userUpload: UserUploadsType;
};

export default function SelectedUploadedItemDetail({
  uploadId,
  projectId,
  userUpload,
}: SelectedUploadedItemDetailProps) {
  const dispatch = useDispatch();
  let mediaEl;

  const [isPreviewVisible, setIsPreviewVisible] = useState(true);
  const {activeScene} = useScenes();
  const {recentUsage} = useRecentUsage();

  if (!userUpload) {
    return null;
  }

  const {file_type: fileType, upload_url: uploadUrl, file_name: fileName} = userUpload;

  const isImage = isImageMedia(fileType);
  const isVideo = isVideoMedia(fileType);
  const isAudio = isAudioMedia(fileType);
  const isCaption = fileType.includes('octet-stream') || fileType.includes('text/vtt');

  const insertToCanvas = async () => {
    if (isImage) {
      dispatch(
        addImageCanvasItem({
          projectId,
          url: uploadUrl,
          fileName,
          sceneId: activeScene.id,
          style: recentUsage,
        }),
      );
    }
    if (isVideo || isAudio) {
      if (!userUpload.mux_playback_id) {
        return;
      }
      const newItem = {
        ...SAMPLE_VIDEO,
        playbackId: userUpload.mux_playback_id,
        itemSourceId: uploadId,
        itemSource: ItemLayerSources.Uploads,
        sceneId: activeScene.id,
      } as CanvasItem;

      dispatch(
        addVideoUploadCanvasItem({
          projectId,
          item: newItem,
          userUpload,
          style: recentUsage,
        }),
      );
    }
    if (isCaption) {
      dispatch(
        addCaptionItemFromItem({
          projectId,
          userUpload,
          itemSourceId: userUpload.id,
          itemSource: ItemLayerSources.Uploads,
          sceneId: activeScene.id,
          style: recentUsage,
        }),
      );
    }
  };

  if (isImage) {
    mediaEl = (
      <div className="px-2 py-1">
        <img alt={uploadUrl} src={uploadUrl} />
      </div>
    );
  }

  if (isAudio) {
    mediaEl = (
      <div className="px-2 py-1">
        <ItemDetailAudio item={userUpload} />
      </div>
    );
  }

  if (isVideo) {
    mediaEl = (
      <div className="px-2 py-1">
        <ItemDetailVideo item={userUpload} />
      </div>
    );
  }

  if (isCaption) {
    mediaEl = (
      <div className="px-2 py-1">
        <ItemDetailCaption item={userUpload} />
      </div>
    );
  }

  if (isAudio) {
    mediaEl = (
      <div className="px-2 py-1">
        <ItemDetailVideo item={userUpload} />
      </div>
    );
  }

  if (fileType.includes('text')) {
    mediaEl = <div className="px-2 py-1">Text here</div>;
  }

  return (
    <>
      <div className="relative flex flex-col border-t border-gray-300 bg-white">
        {isPreviewVisible ? (
          <span className="absolute right-2 top-2 z-20">
            <button
              onClick={() => {
                setIsPreviewVisible(false);
              }}
              type="button"
              className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
          </span>
        ) : (
          <span className="absolute right-2 -top-10 rotate-180 transform">
            <button
              onClick={() => {
                setIsPreviewVisible(true);
              }}
              type="button"
              className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
          </span>
        )}
        <div className="flex w-full flex-col">
          <div>{isPreviewVisible && mediaEl}</div>
          <div
            style={{
              maxHeight: '140px',
            }}
            className="flex flex-col items-center justify-center border-t  border-gray-300 py-2"
          >
            <span className="relative inline-flex rounded-md shadow-sm">
              {(isVideo ||
                (isAudio && userUpload.mux_playback_id) ||
                (!isVideo && userUpload)) && (
                <button
                  onClick={insertToCanvas}
                  type="button"
                  className=" relative inline-flex items-center rounded border px-4 py-2 "
                >
                  <UploadIcon fileType={userUpload.file_type} />
                  <span className="ml-1">Insert to canvas </span>
                </button>
              )}
            </span>
          </div>
          <div className="border-t border-gray-300">
            <div className="mb-2 py-2">
              <div className="text-center text-sm text-gray-400">
                {startAndEnd(fileName)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
