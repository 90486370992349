import {OnChange} from 'features/Common/utils';
import {LayoutButton} from 'features/EditorCanvas/components/AppCanvas/LayoutButton';
import {
  SceneLayoutId,
  SCENE_LAYOUTS,
  SCENE_LAYOUT_IDS,
} from 'features/EditorCanvas/Layout/layouts';

interface TemplateLayoutPickerProps {
  setActiveLayout: OnChange<SceneLayoutId>;
  activeLayout: SceneLayoutId;
}

const TemplateLayoutPicker = ({
  setActiveLayout,
  activeLayout,
}: TemplateLayoutPickerProps) => {
  return (
    <div className="flex space-x-2">
      {SCENE_LAYOUT_IDS.map(id => (
        <div key={id} className="flex flex-col items-center">
          <LayoutButton
            id={id}
            onClick={() => setActiveLayout(id)}
            active={id === activeLayout}
            height={60}
          />
          <div className="mt-1 text-xs">{SCENE_LAYOUTS[id].label}</div>
        </div>
      ))}
    </div>
  );
};

export default TemplateLayoutPicker;
