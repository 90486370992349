import SidebarBrandBackgroundMenu from './SidebarBrandBackgroundMenu';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

export function ProjectSettings() {
  const projectId = useProjectId();

  return (
    <div className="p-5">
      <SidebarBrandBackgroundMenu projectId={projectId} />
    </div>
  );
}
