import {BASE_URL} from 'constants/environment';
import {UserProjectDownloadAsset} from 'pages/PreviewRender/components/SocialCardPreview';
import axios from 'axios';
import {requestHeader} from './helpers';
import {UploadOriginUI} from 'services/userUploadAPI';

// TODO(lenny): Add the ability to have the VERSION that was sent here
export async function postProjectExportRequest({
  token,
  projectId,
  email,
  clientSyncVersion,
  originUI,
}: {
  token: string;
  projectId: string;
  email: string;
  clientSyncVersion: string;
  originUI?: UploadOriginUI;
}) {
  const projectExportEndpoint = `${BASE_URL}/api/v2/projects/${projectId}/export`;
  const response = await axios.post(
    projectExportEndpoint,
    {
      project: {
        client_sync_version: clientSyncVersion,
        email,
        client_api_semvar: 2,
      },
      origin_ui: originUI,
    },
    requestHeader(token),
  );
  return response.data;
}

export async function getProjectDownload({
  token,
  userProjectDownloadId,
}: {
  token: string;
  userProjectDownloadId: string;
}) {
  const userProjectDownloadUpdateEndpoint = `${BASE_URL}/api/v2/canvas_download/${userProjectDownloadId}`;
  const response = await axios.get(
    userProjectDownloadUpdateEndpoint,
    requestHeader(token),
  );
  return response.data;
}

export async function postNewdownloadName(
  token: string,
  projectId: string,
  downloadId: string,
  downloadName: string,
) {
  const url = `${BASE_URL}/api/v2/canvas_download/${downloadId}/update`;

  const response = await axios.post(
    url,
    {
      canvas_download: {
        name: downloadName,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

export async function postAccountDownloadRequest({
  token,
  email,
}: {
  token: string;
  email: string;
}) {
  const userProjectDownloadUpdateEndpoint = `${BASE_URL}/api/v2/canvas_downloads`;
  const response = await axios.post(
    userProjectDownloadUpdateEndpoint,
    {email},
    requestHeader(token),
  );
  return response.data as {
    status: string;
    content: {
      user_project_downloads: UserProjectDownloadAsset[];
    };
  };
}
