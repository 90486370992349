import { useEffect, useState } from 'react';

import ClickAwayListener from 'react-click-away-listener';
import { Link } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { routePaths } from 'routes/routesHelper'
import { useAuthAlt } from 'features/Auth/useAuthAlt';
import classNames from 'classnames';

export default function UserPickerNav({ loadFromBottom = false }) {
  const { logout, user } = useAuthAlt();
  const [isUserNavOpen, setIsUserNavOpen] = useState(false);

  const handleClickAway = () => {
    setIsUserNavOpen(false);
  };

  return (
    <div className="relative inline-block z-20 justify-center">
      <button
        onClick={evt => {
          evt.stopPropagation();
          setIsUserNavOpen(!isUserNavOpen);
          window.analytics.track('Toggle user nav');
        }}
        type="button"
        className="focus:outline-none inline-flex w-full items-center justify-center rounded-md bg-white px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <img
          className="inline-block h-6 w-6 rounded-full"
          src={user?.picture}
          alt={'user avatar'}
        />
        <svg
          className="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isUserNavOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Transition
            show={isUserNavOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {ref => (
              <div
                ref={ref}
                className={classNames("absolute right-0 mt-2 w-44 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5", {
                  'bottom-12 origin-bottom-left ': loadFromBottom,
                  'origin-top-right ': !loadFromBottom,
                })}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
                style={{ zIndex: 1000 }}
              >
                <div className="py-1">
                  <Link
                    onClick={() => {
                      window.analytics.track('Click user settings from user nav');
                      setIsUserNavOpen(false);
                    }}
                    to={`/${routePaths.dashboard}/settings/personal`}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Profile
                  </Link>
                </div>
                <div className="py-1">
                  <a
                    onClick={() => {
                      setIsUserNavOpen(false);
                      window.analytics.track('Open help support from user nav');
                    }}
                    href="https://support.milk.video"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Help and support
                  </a>
                </div>
                <div className="py-1">
                  <button
                    onClick={() => {
                      setIsUserNavOpen(false);
                      window.analytics.track('Log out from user nav');
                      logout({ returnTo: window.location.origin });
                    }}
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Logout
                  </button>
                </div>
              </div>
            )}
          </Transition>
        </ClickAwayListener>
      )
      }
    </div >
  );
}
