const {host} = window.location;

export const env =
  host.includes('localhost') || host.includes('ngrok.io')
    ? 'development'
    : host.includes('staging-app')
    ? 'staging'
    : 'production';

export const isEnvDev = env === 'development';
export const isEnvStaging = env === 'staging';
export const isEnvProd = env === 'production';

export const STATIC_TOKEN_OVERRIDE = 'no-token-server-unautheticated-2022-05-05';
export const STATIC_EMAIL_OVERRIDE = 'preview@milk.video';

export const BASE_URL = import.meta.env.VITE_BASE_API_URL
  ? import.meta.env.VITE_BASE_API_URL
  : isEnvDev
  ? 'http://localhost:5000'
  : isEnvStaging
  ? 'https://staging-api.milk.video'
  : 'https://api.milkvideo.com';

export const BASE_REDIRECT_URL = import.meta.env.VITE_BASE_CLIENT_URL
  ? import.meta.env.VITE_BASE_CLIENT_URL
  : isEnvDev
  ? 'http://localhost:3006'
  : isEnvStaging
  ? 'https://staging-app.milk.video'
  : 'https://app.milkvideo.com';

export const BASE_WEBSITE_URL = isEnvDev
  ? 'http://localhost:3000'
  : 'https://milkvideo.com';

export const BASE_SHARE_URL = import.meta.env.VITE_BASE_SHARE_URL
  ? import.meta.env.VITE_BASE_SHARE_URL
  : isEnvDev
  ? 'http://localhost:5000'
  : isEnvStaging
  ? 'https://staging-share.milk.video'
  : 'https://share.milk.video';

export const UPLOAD_COMPANION_URL = host.includes('milkvideo.com')
  ? 'https://upload.milk.video'
  : 'https://dev-upload.milk.video';

export const AUTH0_DOMAIN = isEnvDev ? 'dev-auth.milkvideo.com' : 'auth.milkvideo.com';

export const AUTH0_BASE_URL = `https://${AUTH0_DOMAIN}`;

export const localEnvAuthOverride =
  (import.meta.env.VITE_OVERRIDE_PLAN_REQUIREMENT as string) === 'true' || false;

const WEBSOCKET_URL = import.meta.env.VITE_BASE_WS_URL
  ? import.meta.env.VITE_BASE_WS_URL
  : env === 'development'
  ? 'localhost:5000'
  : isEnvStaging
  ? 'staging-api.milk.video'
  : 'api.milkvideo.com';

const WS_PROTOCOL = window.location.protocol.includes('https') ? 'wss' : 'ws';

export const BASE_WEBSOCKET_URL = `${WS_PROTOCOL}://${WEBSOCKET_URL}/cable`;

export const ACCOUNT_IMPERSONATION_QUERY_PARAM = 'multitudes';
export const ACCOUNT_IMPERSONATION_LOCALSTORAGE_KEY = 'isMultitudesActive';

const urlParams = new URLSearchParams(window.location.search);
const debugParam = urlParams.get('is_debug') === 'true';

const debugLocalParam = localStorage.getItem('is_debug') === 'true';

export const IS_DEBUG_ACTIVE = debugLocalParam || debugParam;

export const FONT_PREVIEW_BASE_URL = 'https://milkvideo-private-fonts.s3.amazonaws.com/';

const MILK_TEST_ID =
  'pk_test_51HaOBRE6uTJ6KucUPphgwKX9zATCN0n7P5cGlbfPIEn1f1mJ6uD3nyMl96JzpwU7FDuxid3QNzPers8nuy7aJFBN00U4cmEs47';
const MILK_PROD_ID =
  'pk_live_51HaOBRE6uTJ6KucUn5IRZsZh6xfFwD4JHuQOfZhrB6hAys1n3FiPBLKeh0hdNHjSEDBZQyv6HrXquU0UwUJ6aeep00oA7yISky';
export const publishableStripeKey = isEnvDev ? MILK_TEST_ID : MILK_PROD_ID;

export const isFrameRecording = () => {
  return window.location.href.includes('/frame/');
};

export const isScreenshot = () => {
  return window.location.href.includes('/screenshot/');
};

export const isLegacyRecording = () => {
  return window.location.href.includes('/record/');
};

export const isRobotRoute = () => {
  return window.location.href.includes('/robot-routes/');
};

export const isRecording = () => {
  return isRobotRoute() || isLegacyRecording() || isFrameRecording() || isScreenshot();
};
