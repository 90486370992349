import {
  BaseTranscriptWord,
  UserUploadsType,
  BaseTranscriptChapter,
} from 'features/types/userLibrarySlice';

import {BASE_URL} from 'constants/environment';
import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {UserUpload} from 'services/userUploadAPI';
import axios from 'axios';
import {requestHeader} from './helpers';

export async function getProjectScenarioDataByKey(projectScenarioKey: string) {
  const url = `${BASE_URL}/api/v1/project_scenarios/${projectScenarioKey}.json`;

  return await axios.get(url);
}

/**
 * Get user upload by bucket_key (instead of user_upload.id)
 *
 * @param token
 * @param bucketKey
 * @returns
 */
export async function getUserUploadByBucketKey(
  token: string,
  bucketKey: string,
): Promise<UserUploadsType> {
  const apiUrl = `${BASE_URL}/api/v1/uploads/${bucketKey}`;
  const response = await axios.get(apiUrl, requestHeader(token));
  return response.data;
}

export async function getFileUploadVersions(token: string, bucketKey: string) {
  const changesEndpoint = `${BASE_URL}/api/v1/uploads/${bucketKey}/changes`;
  const response = await axios.get(changesEndpoint, requestHeader(token));
  return response.data;
}

export enum TranscriptProviders {
  AssemblyAi = 'aa',
}

export async function getFileUploadTranscript(
  token: string,
  userUploadId: string,
  language: LanguageId,
): Promise<{
  provider: TranscriptProviders;
  transcript: BaseTranscriptWord[][];
  has_full_response: boolean;
}> {
  const transcriptEndpoint = `${BASE_URL}/api/v2/transcript/${userUploadId}/${language}`;
  const response = await axios.get(transcriptEndpoint, requestHeader(token));
  return response.data;
}

export async function getUserTemplates(token: string, email: string) {
  const templatesEndpoint = `${BASE_URL}/api/v1/user_templates/list`;
  const response = await axios.post(
    templatesEndpoint,
    {
      email,
    },
    requestHeader(token),
  );
  return response.data;
}

export async function postNewUploadFolder(
  token: string,
  email: string,
  newFolderName: string,
  parentFolderId: string | null,
) {
  const newFolderEndpoint = `${BASE_URL}/api/v1/user_upload_folders`;
  const response = await axios.post(
    newFolderEndpoint,
    {
      email,
      new_folder_name: newFolderName,
      parent_folder_id: parentFolderId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
}

export async function getUserUploadFolders(token: string, email: string) {
  const listFolderEndpoint = `${BASE_URL}/api/v1/user_upload_folders/list`;
  const response = await axios.post(
    listFolderEndpoint,
    {
      email,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
}

export type YoutubeUserUploadRequestedResponse = {
  status: 'requested youtube import';
  message: 'No existing user_upload with youtube_video_id';
  user_upload: UserUpload;
};

export type YoutubeUserUploadExistsResponse = {
  status: 'success';
  message: 'Found existing user_upload with youtube_video_id';
  user_upload: UserUpload;
};

export async function postYoutubeVideoUpload(
  token: string,
  youtubeVideoId: string,
  youtubeVideoName: string,
): Promise<YoutubeUserUploadExistsResponse | YoutubeUserUploadRequestedResponse> {
  const postYoutubeVideoUpload = `${BASE_URL}/api/v2/external_media/youtube_video_upload`;

  const response = await axios.post(
    postYoutubeVideoUpload,
    {
      youtube_video_params: {
        youtube_video_id: youtubeVideoId,
        youtube_video_name: youtubeVideoName,
      },
    },
    requestHeader(token),
  );
  return response.data;
}
