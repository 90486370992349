import {createSRT, downloadSrtFile} from './helper';

import {Button} from 'ui/Button';
import {Modal} from 'ui/Modal';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {useLanguage} from './LanguageState';
import {useTranscript} from 'features/Captions/Transcripts';
import {useUploadTranscript} from './UploadTranscriptContext';

export const SrtDownloadModal = ({uploadFile}: {uploadFile: UserUploadsType}) => {
  const {language} = useLanguage();
  const {setIsDownloadModalOpen, isDownloadModalOpen} = useUploadTranscript();
  const handleDownload = () => {
    window.analytics.track('Download SRT');

    const outputFile = createSRT(transcript, 40);
    if (outputFile) {
      downloadSrtFile('file.srt', outputFile);
      setIsDownloadModalOpen(false);
    }
  };

  const {
    transcript,
    hasFullResponse,
    loading: transcriptLoading,
  } = useTranscript(uploadFile.id, language);

  return (
    <Modal
      size="large"
      open={isDownloadModalOpen}
      onClose={() => setIsDownloadModalOpen(false)}
      title="Download transcript SRT"
    >
      <div>Click the button below to download the word-by-word SRT file to be used.</div>
      <div>
        Please note that this SRT file is intentionally loading one word at a time to
        preserve the ability to style word-level timestamps.
      </div>
      <Button onClick={handleDownload}>Download</Button>
    </Modal>
  );
};
