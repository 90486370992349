import {AudioWaveVizOptions} from '../constants';
import {CircleLineCentrifugal} from './CircleLineCentrifugal';
import {DimensionType} from 'features/types/canvasItemsSlice';
import {LineFlat} from './LineFlat';
import {LineFlatEmojiWave} from './LineFlatEmojiWave';
import {LineYAxis} from './LineYAxis';

export const AudioRenderingPreview = ({
  dimensions,
  waveformStyle,
}: {
  dimensions: DimensionType;
  waveformStyle: AudioWaveVizOptions;
}) => {
  const currentFftFrame = [
    44, 127, 107, 76, 127, 27, 72, 60, 43, 72, 9, 2, 152, 118, 29, 120, 67, 96, 9,
  ];

  if (waveformStyle === AudioWaveVizOptions.CircleCentrifugalLine) {
    return (
      <CircleLineCentrifugal dimensions={dimensions} frequencyData={currentFftFrame} />
    );
  }

  if (waveformStyle === AudioWaveVizOptions.LinesYAxis) {
    return <LineYAxis dimensions={dimensions} frequencyData={currentFftFrame} />;
  }

  if (waveformStyle === AudioWaveVizOptions.LineFlatEmojiWave) {
    return <LineFlatEmojiWave dimensions={dimensions} frequencyData={currentFftFrame} />;
  }

  return <LineFlat dimensions={dimensions} frequencyData={currentFftFrame} />;
};
