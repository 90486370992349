import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {TranscriptJSON, useTranscript} from 'features/Captions/Transcripts';
import {UploadMediaClipType, uploadMediaClipAPI} from 'services/uploadMediaClipAPI';

import {BaseTranscriptChapter} from 'features/types/userLibrarySlice';
import {GetRootState} from 'configureStore';
import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {TranscriptNotification} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/DetailTypePlayableMediaContainer';
import {UploadMediaClipAsset} from 'pages/PreviewRender/components/SocialCardPreview';
import {getTrimmedTranscript} from './transcriptUtils';
import {useSelector} from 'react-redux';

type Range = [number, number];
export type {Range as SelectionRange};

export type SortedSelection = {
  start: number;
  end: number;
} | null;

const sortSelection = (range: Range | null): SortedSelection => {
  if (!range) return null;

  return {
    start: Math.min(...range),
    end: Math.max(...range),
  };
};

const toRange = (selection: SortedSelection): Range | null => {
  return selection && [selection.start, selection.end];
};

type ContextType = {
  selection: SortedSelection;
  dragSelection: SortedSelection;
  setSelection: (selection: Range | null) => void;
  selectedChapter: BaseTranscriptChapter | null;
  setSelectedChapter: Dispatch<SetStateAction<BaseTranscriptChapter | null>>;
  hoveringClip: UploadMediaClipType | null;
  setHoveringClip: Dispatch<SetStateAction<UploadMediaClipType | null>>;
  isTemporary: boolean;
};

const SelectionStateContext = createContext<ContextType | null>(null);

export const SelectionProvider: FC<{
  initialSelection?: SortedSelection;
  onChange?: (sortedSelection: SortedSelection) => void;
}> = ({children, initialSelection, onChange}) => {
  const [dragSelection, setDragSelection] = useState(toRange(initialSelection ?? null));
  const [hoveringClip, setHoveringClip] = useState<UploadMediaClipType | null>(null);
  const [selectedChapter, setSelectedChapter] =
    useState<UploadMediaClipType | null>(null);

  let selection = dragSelection;
  if (hoveringClip) {
    selection = [
      Number(hoveringClip.start_time) * 1000,
      Number(hoveringClip.end_time) * 1000,
    ];
  }
  if (selectedChapter) {
    selection = [Number(selectedChapter.start), Number(selectedChapter.end)];
  }

  const sortedDragSelection = useMemo<SortedSelection>(
    () => sortSelection(dragSelection),
    [dragSelection],
  );

  const sortedSelection = useMemo<SortedSelection>(
    () => sortSelection(selection),
    [selection],
  );

  const setSelection = (selection: Range | null) => {
    // console.log('setSelection');
    setHoveringClip(null);
    setSelectedChapter(null);
    setDragSelection(selection);
  };

  const state = {
    selection: sortedSelection,
    dragSelection: sortedDragSelection,
    setSelection,
    hoveringClip,
    setHoveringClip,
    selectedChapter,
    setSelectedChapter,
    isTemporary: !!hoveringClip,
  };

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const latestSelection = useRef(sortedSelection);
  latestSelection.current = sortedSelection;

  useEffect(() => {
    const id = setTimeout(() => {
      onChangeRef.current?.(latestSelection.current);
    }, 500);

    return () => {
      clearTimeout(id);
    };
  }, [sortedSelection]);

  useEffect(() => {
    return () => {
      onChangeRef.current?.(latestSelection.current);
    };
  }, []);

  return (
    <SelectionStateContext.Provider value={state}>
      {children}
    </SelectionStateContext.Provider>
  );
};

export const useSelection = () => {
  const state = useContext(SelectionStateContext);
  if (!state) throw new Error('SelectionStateProvider not found');
  return state;
};

const getStartAndEnd = (transcript: TranscriptJSON) => {
  const firstParagraph = transcript[0];
  if (!firstParagraph) return;
  const start = firstParagraph[0];

  const lastParagraph = transcript[transcript.length - 1];
  if (!lastParagraph) return;
  const end = lastParagraph[lastParagraph.length - 1];

  return {start, end};
};

const getTranscriptText = (transcript: TranscriptJSON) => {
  return transcript
    .flat(1)
    .map(word => word.text)
    .join(' ');
};

export const useSelectedTranscript = (userUploadId: string, language: LanguageId) => {
  // console.log('userUploadId', userUploadId);
  const {transcript} = useTranscript(userUploadId, language);
  const {selection} = useSelection();

  return useMemo(() => {
    if (!selection || !transcript) return;

    const trimmedTranscript = getTrimmedTranscript(
      transcript,
      {startMs: selection.start, endMs: selection.end},
      false,
    );

    const startAndEnd = getStartAndEnd(trimmedTranscript);
    if (!startAndEnd) return;

    return {
      transcript: trimmedTranscript,
      start: startAndEnd.start,
      end: startAndEnd.end,
      get text() {
        return getTranscriptText(trimmedTranscript);
      },
    };
  }, [transcript, selection]);
};

export type SelectedTranscript = ReturnType<typeof useSelectedTranscript>;
