import {
  ChevronsDown,
  ChevronsUp,
  Clipboard,
  Copy,
  Icon,
  Layers,
  Trash,
} from 'react-feather';
import {
  copyItems,
  pasteItems,
  updateCanvasItemsPositionState,
} from 'features/canvasItemsSlice';
import {
  groupItems,
  removeCanvasItems,
  ungroupItems,
  updateLayerOrder,
} from 'features/canvasItemsSlice';
import {useDispatch, useSelector} from 'react-redux';

import {CanvasItem} from 'features/types/canvasItemsSlice';
import arrayUnique from 'array-unique';
import {canPasteSelector} from 'features/selectors/canvasItemsSelectors';
import {useScenes} from './CanvasTime/useScenes';

export type MenuItemsOptions = {
  projectId: string;
};

export type MenuItem = {
  label: string;
  icon: Icon;
  shortcuts?: string[];
  action: () => void;
  show?: () => boolean;
  group: string;
};

export const useMenuItems = ({projectId}: MenuItemsOptions) => {
  const dispatch = useDispatch();
  const canPaste = useSelector(canPasteSelector)(projectId);
  const {activeScene} = useScenes();

  const getMenuItems = (items: Record<string, CanvasItem>) => {
    const ids = Object.keys(items);
    const itemCount = ids.length;

    const menuItems: MenuItem[] = [];

    if (itemCount > 0) {
      menuItems.push({
        label: 'Copy',
        icon: Copy,
        shortcuts: ['Meta+C'],
        action: () => {
          dispatch(copyItems({projectId, ids, sceneId: activeScene.id}));
        },
        group: 'copy',
      });
    }

    if (canPaste) {
      menuItems.push({
        label: 'Paste',
        icon: Clipboard,
        shortcuts: ['Meta+V'],
        action: () => {
          dispatch(pasteItems({projectId, sceneId: activeScene.id}));
        },
        group: 'copy',
      });
    }

    if (itemCount > 1) {
      const selectedGroups = arrayUnique(Object.values(items).map(item => item.groupId));

      let singleSelectedGroup: string | undefined;
      if (selectedGroups.length === 1 && selectedGroups[0]) {
        singleSelectedGroup = selectedGroups[0];
      }

      if (singleSelectedGroup) {
        menuItems.push({
          label: `Ungroup ${layerLabel('item', itemCount)}`,
          icon: Layers,
          shortcuts: ['Meta+G'],
          action: () => {
            dispatch(ungroupItems({projectId, groupId: singleSelectedGroup!}));
          },
          group: 'group',
        });
      } else if (itemCount > 1) {
        // console.log('itemCount', itemCount);
        // console.log('menuItems', menuItems);
        // console.log('projectId', projectId);
        // console.log('ids', ids);
        menuItems.push({
          label: `Group ${layerLabel('item', itemCount)}`,
          icon: Layers,
          shortcuts: ['Meta+G'],
          action: () => {
            dispatch(groupItems({projectId, ids}));
          },
          group: 'group',
        });
      }
    }

    if (itemCount === 1) {
      menuItems.push({
        label: 'Move layer up',
        icon: ChevronsUp,
        shortcuts: ['Meta+]'],
        action: () => {
          dispatch(updateLayerOrder({projectId, id: ids[0], direction: 'up'}));
        },
        group: 'move',
      });

      menuItems.push({
        label: 'Move layer down',
        icon: ChevronsDown,
        shortcuts: ['Meta+['],
        action: () => {
          dispatch(updateLayerOrder({projectId, id: ids[0], direction: 'down'}));
        },
        group: 'move',
      });
    }

    if (itemCount > 0) {
      menuItems.push({
        label: `Delete ${layerLabel('layer', itemCount)}`,
        icon: Trash,
        shortcuts: ['Meta+Backspace', 'Del'],
        action: () => {
          dispatch(updateCanvasItemsPositionState());
          dispatch(removeCanvasItems({projectId, ids}));
        },
        group: 'delete',
      });
    }

    return menuItems;
  };

  return getMenuItems;
};

const layerLabel = (label: string, count: number) => {
  // console.log('label', label);
  // console.log('count', count);
  if (count === 1) return label;

  return `${count} ${label}s`;
};
