import {Slice, SliceCaseReducers, bindActionCreators} from '@reduxjs/toolkit';
import {useEffect, useReducer, useRef} from 'react';

import {GetRootState} from 'configureStore';
import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import NotificationError from 'features/Notifications/NotificationError';
import NotificationInfo from 'features/Notifications/NotificationInfo';
import {store} from 'react-notifications-component';
import {useMemoOne} from 'use-memo-one';
import {useSelector} from 'react-redux';

export type OnChange<T> = (newValue: T) => void;

export function useEventListener<EventName extends keyof DocumentEventMap>(
  eventName: EventName,
  handler: (event: DocumentEventMap[EventName]) => void,
) {
  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  useEffect(() => {
    const _handler = (event: DocumentEventMap[EventName]) => {
      handlerRef.current(event);
    };

    document.addEventListener(eventName, _handler);
    return () => document.removeEventListener(eventName, _handler);
  }, [eventName]);
}

/**
 * Ensures onMouseUp fires, even when the mouse is no longer
 * over the target element.
 */
export const useDocumentMouseUp = (onMouseUp: (event: MouseEvent) => void) => {
  const isMouseDown = useRef(false);

  useEventListener('mouseup', event => {
    if (!isMouseDown.current) return;

    onMouseUp(event);
    isMouseDown.current = false;
  });

  return () => {
    isMouseDown.current = true;
  };
};

/**
 * Returns true if the app is running in DEV mode,
 * or if the user's email ends in @milkvideo.com.
 *
 * Useful for displaying UIs that only internal employees
 * should see.
 */
export const useIsInternalUser = () => {
  const email = useSelector((state: GetRootState) => state.auth.email);
  return import.meta.env.DEV || email?.endsWith('@milkvideo.com');
};

export type ErrorNotificationOptions = {
  title: string;
  message: string;
  // @ts-ignore
  error?: Error;
};

export const showErrorNotification = ({
  title,
  message,
  error,
}: ErrorNotificationOptions) => {
  console.error(`Error notification displayed: "${title}: ${message}"`);

  if (error) {
    console.error('Original error for above:', error);
  }

  store.addNotification({
    ...NOTIFICATION_BASE,
    content: <NotificationError title={title} message={message} />,
    type: 'warning',
  });
};

export type SuccessNotificationOptions = {
  title: string;
  message: string;
};

export const showSuccessNotification = ({title, message}: SuccessNotificationOptions) => {
  store.addNotification({
    ...NOTIFICATION_BASE,
    content: <NotificationInfo title={title} message={message} />,
  });
};

/**
 * Returns a ref that will be true if the component
 * is mounted and false if it is not.
 *
 * Useful for stopping async operations that keep
 * running after a component has unmounted.
 *
 * @example
 * const [result, setResult] = useState()
 * const isMounted = useIsMountedRef()
 *
 * <div
 *  onClick={async () => {
 *    const result = await somethingAsync()
 *
 *    // Prevent setResult from being called if the component
 *    // is unmounted before the async operation finishes
 *    if(!isMounted.current) return
 *
 *    setResult(result)
 *  }}
 * />
 */
export const useIsMountedRef = () => {
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

/**
 * Iterate over an object.
 *
 * @example
 * const obj = {a: 1, b: 2}
 *
 * objForEach(obj, (value, key) => {
 *  console.log(value, key) // [a, 1], [b, 2]
 * })
 */
export function objForEach<Object extends {}>(
  object: Object,
  iterator: (value: Object[keyof Object], id: keyof Object) => void,
) {
  for (const [id, value] of Object.entries(object)) {
    iterator(value as Object[keyof Object], id as keyof Object);
  }
}

/**
 * Map an object's values.
 *
 * @example
 * const obj = {a: 1, b: 2}
 *
 * const newObj = objMap(obj, (value, key) => {
 *  return value * 2
 * })
 *
 * console.log(newObj) // {a: 2, b: 4}
 */
export function objMap<Object extends {}, Result>(
  object: Object,
  iterator: (value: Object[keyof Object], id: keyof Object) => Result,
) {
  const result = {} as Record<keyof Object, Result>;
  objForEach(object, (value, id) => {
    result[id] = iterator(value, id);
  });
  return result;
}

/**
 * Filter an object.
 *
 * @example
 * const obj = {a: 1, b: 2, c: 3}
 *
 * const newObj = objFilter(obj, (value, key) => {
 *  return value > 1
 * })
 *
 * console.log(newObj) // {b: 2, c: 3}
 */
export function objFilter<Object extends {}>(
  object: Object,
  iterator: (value: Object[keyof Object], id: keyof Object) => any,
) {
  const result = {} as Object;
  objForEach(object, (value, id) => {
    if (iterator(value, id)) {
      result[id] = value;
    }
  });
  return result;
}

type AnyFunction = (...a: any) => any;
type ReplaceReturnType<F, TNewReturn> = F extends AnyFunction
  ? (...a: Parameters<F>) => TNewReturn
  : F;

export type BoundActions<TSlice extends Slice> = {
  [T in keyof TSlice['actions']]: ReplaceReturnType<TSlice['actions'][T], void>;
};

export function useRtkReducer<
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string,
>(slice: Slice<State, CaseReducers, Name>, initialState: State) {
  const [state, dispatch] = useReducer(slice.reducer, initialState);

  const actions = useMemoOne(() => {
    return bindActionCreators(
      slice.actions as any,
      dispatch as any,
    ) as any as BoundActions<typeof slice>;
  }, [slice.actions]);

  return {state, dispatch, actions};
}

export const useTrackOnce = () => {
  const hasTracked = useRef(false);

  return function track(
    event: string,
    properties?: Object,
    options?: SegmentAnalytics.SegmentOpts,
  ) {
    if (hasTracked.current) return;
    hasTracked.current = true;

    return asyncTrack(event, properties, options);
  };
};

export const asyncTrack = (
  event: string,
  properties?: Object,
  options?: SegmentAnalytics.SegmentOpts,
) => {
  const trackPromise = new Promise<void>(resolve => {
    window.analytics.track(event, properties, options, () => {
      resolve();
    });
  });

  const timeoutPromise = new Promise<void>(resolve => setTimeout(resolve, 2000));

  return Promise.race([trackPromise, timeoutPromise]);
};
