import {CableMessagePayload} from './types';
import {baseApi} from 'services/baseAPI';

enum UserProjectDownloadCableActions {
  HasSaved = 'has_saved',
}

const userProjectDownloadsCable = (
  dispatch: any,
  getAccessTokenSilently: any,
  message: CableMessagePayload,
  user: any,
) => {
  if (message.body.action === UserProjectDownloadCableActions.HasSaved) {
    const {project_id: projectId, project_download_id: projectDownloadId} = message.body
      .payload as {
      mux_playback_id: string;
      status: string;
      url: string;
      project_download_id: string;
      project_id: string;
    };

    // console.log('projectId', projectId);
    // console.log('projectDownloadId', projectDownloadId);
    // const videoFailConfirmed = status === STATUS_FAILED_DOWNLOAD_REQUEST;

    // TODO(lenny) Move this to specify the download to reset
    dispatch(baseApi.util.invalidateTags(['UserProjectDownload'])); // // Once this works, then remove the query param - OR if it is found to be working, remove
  }
};

export default userProjectDownloadsCable;
