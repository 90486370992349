import {AnimatePresence, motion} from 'framer-motion';
import {Suspense, lazy} from 'react';

import {Assets} from './views/Assets/Assets';
import {BrandKit} from './views/BrandKit';
import {CanvasResizeMenu} from './views/CanvasResizeMenu';
import {ColorPicker} from './views/ColorPicker/ColorPicker';
import {LayerSettings} from './views/LayerSettings/LayerSettings';
import {ProjectSettings} from './views/ProjectSettings/ProjectSettings';
import {SidebarMenu} from './SidebarMenu';
import {Spinner} from 'ui/Spinner';
import {StaticElements} from './views/StaticElements';
import {Templates} from './views/Templates/Templates';
import {TextElements} from './views/TextElements';
import {Transcript} from './views/Transcript';
import {useSidebarMenu} from './SidebarMenuContext';

const views = {
  StaticElements,
  TextElements,
  Transcript,
  Templates,
  Assets,
  Layer: LayerSettings,
  FontPicker: lazy(() => import('./views/FontPicker/FontPicker')),
  ColorPicker,
  BrandKit,
  ProjectSettings,
  CanvasResize: CanvasResizeMenu,
};

export type SidebarView = keyof typeof views;

// UI TODO (jacques): Make re-useable fade between
export const fadeProps = {
  animate: {opacity: 1},
  transition: {duration: 0.15, ease: 'easeInOut'},
  initial: {opacity: 0},
  exit: {opacity: 0},
};

export const Sidebar = () => {
  const {
    state: {activeMenu},
  } = useSidebarMenu();

  const ActiveViewComponent = activeMenu && views[activeMenu];

  return (
    <div className="flex border-r">
      <SidebarMenu />
      {ActiveViewComponent && (
        <div className="w-60 overflow-y-auto border-l bg-white">
          <AnimatePresence exitBeforeEnter>
            <Suspense
              key="loading"
              fallback={
                <motion.div
                  {...fadeProps}
                  key="loading"
                  className="flex h-full w-full items-center justify-center"
                >
                  <Spinner size={32} />
                </motion.div>
              }
            >
              <motion.div {...fadeProps} key={activeMenu} className="h-full">
                <ActiveViewComponent />
              </motion.div>
            </Suspense>
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};
