import {useEffect, useRef} from 'react';
import {useSelectionFocus} from '../../SelectionFocusProvider';

export function NumberInput({
  value,
  onChange,
}: {
  value: number | false;
  onChange: (value: number) => void;
}) {
  const {setIsTextFieldActive} = useSelectionFocus();

  const handleFocus = () => {
    setIsTextFieldActive(true);
  };

  const handleBlur = () => {
    setIsTextFieldActive(false);
  };

  const elRef = useRef<HTMLInputElement | null>(null);

  // block arrow key movement of canvas item when focused
  useEffect(() => {
    if (elRef.current) {
      elRef.current.addEventListener('focus', handleFocus);
      elRef.current.addEventListener('blur', handleBlur);
    }
    // Remove event listeners on cleanup
    return () => {
      if (elRef.current) {
        elRef.current.removeEventListener('focus', handleFocus);
        elRef.current.removeEventListener('blur', handleBlur);
      }
    };
  }, [elRef]);

  if (typeof value !== 'number') {
    return <div />;
  }

  return (
    <input
      ref={elRef}
      value={value.toString()}
      type="number"
      aria-label="number-input"
      onChange={event => {
        const value = Number(event.target.value);
        if (!isNaN(value)) onChange(value);
      }}
      onKeyDown={event => {
        if (typeof value !== 'number') return;
        if (event.isDefaultPrevented()) return;

        if (event.key === 'ArrowUp') {
          event.preventDefault();
          onChange((value += event.shiftKey ? 10 : 1));
        } else if (event.key === 'ArrowDown') {
          event.preventDefault();
          onChange((value -= event.shiftKey ? 10 : 1));
        }
      }}
      className="w-full rounded-md border border-gray-300 bg-white py-2 px-2 text-xs"
    />
  );
}
