import {FC, useState} from 'react';
import {Info, X} from 'react-feather';
import {
  useUserUploadQuery,
  useUserUploadsUploadMediaClipsQuery,
} from 'services/userUploadAPI';

import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import {SceneLayoutId} from 'features/EditorCanvas/Layout/layouts';
import TemplateConfirmExport from './TemplateConfirmExport';
import TemplateExportPreview from './TemplateExportPreview';
import {Z_INDEX_MODAL} from 'features/Common/constants';
import {useExportProject} from 'features/EditorCanvas/components/useExportProject';
import {useRecentUsage} from 'services/recentUsageAPI';
import {useSelector} from 'react-redux';
import {userLibrarySelector} from 'features/selectors/userLibrarySelectors';

export type PreviewType = 'clip' | 'upload' | 'project';

export type TemplateSelectionPopupProps = {
  handleClose: () => void;
  previewTypeId: string;
  userUploadId: string;
};

export enum OrientationType {
  Landscape = 'landscape',
  Square = 'square',
}

export type TemplateExportOptionsType = {
  orientation: OrientationType;
  layout: SceneLayoutId;
};

export const BASE_EXPORT_OPTIONS: TemplateExportOptionsType = {
  orientation: OrientationType.Landscape,
  layout: 'VideoLeft',
};

const TemplateSelectionPopup = ({
  previewTypeId,
  userUploadId,
  handleClose,
}: TemplateSelectionPopupProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const {data: upload} = useUserUploadQuery({id: userUploadId});
  const {data: userClips} = useUserUploadsUploadMediaClipsQuery({id: userUploadId});

  const [exportOptions, setExportOptions] =
    useState<TemplateExportOptionsType>(BASE_EXPORT_OPTIONS);
  const updateExportOptions = (newOptions: Partial<TemplateExportOptionsType>) => {
    setExportOptions(current => ({...current, ...newOptions}));
  };

  const {exportProject} = useExportProject();
  const syncAndExportProject = async (projectId: string) => {
    await exportProject(projectId);
    setIsLoading(true);
  };
  const {isSet: isRecentUsageSet} = useRecentUsage();

  if (isLoading) return <FullScreenSpinner />;

  const clip = userClips?.find(c => c.id === previewTypeId);
  if (!clip) throw new Error('Clip not found');

  if (!upload) throw new Error('Upload not found');

  return (
    <Modal onClose={handleClose}>
      <div className="sm:flex sm:items-start">
        <div className="relative mx-auto">
          <div className="overflow-hidden rounded-lg bg-gradient-to-r from-orange-400 to-pink-500 lg:grid lg:grid-cols-2 lg:gap-4">
            <div className="align-center relative flex flex-col justify-center bg-gray-200 p-4">
              {clip && upload && (
                <div>
                  <TemplateExportPreview
                    exportOptions={exportOptions}
                    clip={clip}
                    userUpload={upload}
                  />
                  {isRecentUsageSet && (
                    <div className="mt-3 flex items-center justify-center space-x-1 text-gray-500">
                      <Info size={16} />
                      <div className="text-xs font-medium">
                        The above design is based on your recent projects.
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="flex flex-col justify-center px-6 py-6 sm:px-6 sm:pt-6 lg:pl-0 xl:py-12 xl:px-6">
              {clip && (
                <TemplateConfirmExport
                  activeClip={clip}
                  exportOptions={exportOptions}
                  updateExportOptions={updateExportOptions}
                  handleStartLoading={() => setIsLoading(true)}
                  syncAndExportProject={syncAndExportProject}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Modal: FC<{onClose: () => void}> = ({children, onClose}) => {
  return (
    <div
      style={{zIndex: Z_INDEX_MODAL}}
      className="fixed inset-0 overflow-y-auto bg-gray-500 bg-opacity-75"
    >
      <div className="flex min-h-screen items-center justify-center px-4 pt-4">
        <div
          className="inline-block max-w-4xl transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <button
            type="button"
            onClick={onClose}
            className="absolute top-4 right-4 z-50 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="sr-only">Close</span>
            <X size={24} />
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};

const FullScreenSpinner = () => {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75"
      style={{zIndex: Z_INDEX_MODAL}}
    >
      <LoadingSpinner color="text-white" />
    </div>
  );
};

export default TemplateSelectionPopup;
