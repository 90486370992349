import classNames from 'classnames';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {onClickCopyLink} from 'features/EditorCanvas/components/CanvasModals/CanvasModalPublish';
import {FC, useState} from 'react';
import {ArrowRight} from 'react-feather';
import {Button} from 'ui/Button';
import {ColorScheme, colorSchemes, sizes} from '../Constants';
import {SizeOption} from '../DesignStep/DesignStepOptions';
import {useDesign, useWizardState} from '../State';
import {FocusedFlowToolbar} from '../Toolbar';
import {EmailCapture} from '../UploadStep/EmailCapture';
import {UploadFaq} from '../UploadStep/UploadFaq';
import {StepHeading, TwoColumns} from '../utils';

export const handleShareClick = () => {
  window.analytics.track('Clicked link share on podcast audiogram');
  onClickCopyLink({
    title: 'Link copied to clipboard',
    message: 'You can now share this link.',
    copyLink: window.location.href,
  });
};

export const TemplateStep = () => {
  const {updateDesign} = useDesign();
  const [hoverColor, setHoverColor] = useState<ColorScheme | null>(null);

  const {isAuthenticated} = useAuthAlt();
  const {nextStep, design} = useWizardState();

  return (
    <>
      <TwoColumns
        noVerticalPadding
        preview={{
          design: {
            ...design,
            colorScheme: hoverColor ?? design.colorScheme,
            logo: '/sample-logo.png',
          },
        }}
      >
        <div className="space-y-3">
          {!isAuthenticated && (
            <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-800">
              FREE
            </span>
          )}
          <StepHeading
            title="Design your clip"
            subtitle="Choose a color scheme and a video size"
          />
          <div className="flex space-x-3 pb-12">
            <Button variant="primary" onClick={() => nextStep()}>
              Upload audio or video
            </Button>

            <Button variant="tertiary" onClick={handleShareClick}>
              Share
            </Button>
          </div>
          <div className="space-y-6">
            <Property label="Color scheme">
              <div className="grid grid-cols-6 gap-4">
                {Object.keys(colorSchemes).map(id => {
                  return (
                    <div
                      key={id}
                      className={classNames(
                        'aspect-w-1 aspect-h-1 w-full cursor-pointer rounded-md border border-white transition delay-75',

                        design.colorScheme.background === colorSchemes[id].background &&
                          'outline-none ring-2 ring-indigo-500 focus:border-indigo-500 focus:shadow-sm focus:ring-indigo-800',
                      )}
                      style={{backgroundColor: colorSchemes[id].background}}
                      onMouseOver={() => setHoverColor(colorSchemes[id])}
                      onMouseOut={() => setHoverColor(null)}
                      onClick={() =>
                        updateDesign({property: 'colorScheme', value: colorSchemes[id]})
                      }
                    />
                  );
                })}
              </div>
            </Property>
            <Property label="Video size" className="!px-0 !pb-2">
              <div className="flex h-24 items-center">
                {Object.values(sizes).map(sizeOption => (
                  <SizeOption
                    key={sizeOption.id}
                    size={sizeOption}
                    active={design.size === sizeOption.id}
                    onClick={() => updateDesign({property: 'size', value: sizeOption.id})}
                  />
                ))}
              </div>
            </Property>
          </div>
        </div>

        <FocusedFlowToolbar>
          <div />
          <Button variant="primary" rightIcon={ArrowRight} onClick={() => nextStep()}>
            Next step
          </Button>
        </FocusedFlowToolbar>
      </TwoColumns>

      {!isAuthenticated && <EmailCapture />}
      {!isAuthenticated && <UploadFaq />}
    </>
  );
};

const Property: FC<{label: string; className?: string}> = ({
  label,
  className,
  children,
}) => {
  return (
    <div className={classNames('relative mt-2 rounded-xl border-2 p-5', className)}>
      <div className="absolute top-0 left-5 z-10 -translate-y-1/2 bg-white px-2 font-semibold">
        {label}
      </div>
      {children}
    </div>
  );
};
