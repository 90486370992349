import {authSelector, authSettingsSelector} from 'features/selectors/authSelectors';
import {useEffect, useMemo} from 'react';

import {DashboardContentSkeleton} from 'features/Dashboard/common/DashboardContentSkeleton';
import {DashboardHead} from 'features/Dashboard/common/DashboardHead';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import SettingsAccount from './SettingsAccount';
import SettingsPayment from './SettingsPayment';
import SettingsPersonal from './SettingsPersonal';
import SettingsVisualGovernance from './SettingsVisualGovernance';
import {TabNavContainer} from 'features/Common/TabNavContainer';
import {TabNavItem} from 'features/Common/TabNavItem';
import {Transition} from '@headlessui/react';
import useDimensions from 'react-use-dimensions';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useWindowHeight} from '@react-hook/window-size';

interface SettingsProps {
  isShowing: boolean;
}

export enum SettingsActivePage {
  ConnectedAccounts = 'connected-accounts',
  AccountPreferences = 'account-preferences',
  Personal = 'personal',
  Billing = 'billing',
  VisualGovernance = 'visual-governance',
}

export function DashboardSettings({isShowing}: SettingsProps) {
  const {settingsDetail} = useParams() as Record<string, string>;
  const activePage =
    settingsDetail && settingsDetail !== SettingsActivePage.ConnectedAccounts
      ? settingsDetail
      : SettingsActivePage.AccountPreferences;
  const {account, user, processorId, accountUsers} = useSelector(authSettingsSelector);

  const isVisualGovernanceEnabled = useFeatureFlag('visual-governance-settings');

  const isAdmin =
    accountUsers?.find(accountUser => accountUser.user_id === user?.id)?.roles.admin ??
    false;

  useEffect(() => {
    if (!activePage) {
      return;
    }
    window.analytics.track('Loaded settings page', {
      settings_detail: activePage,
    });
  }, [activePage]);

  const windowHeight = useWindowHeight();
  const [headRef, {height: headHeight}] = useDimensions();
  const [precontentRef, {height: preContentHeight}] = useDimensions();

  const contentHeight = useMemo(() => {
    return (
      windowHeight -
      (headHeight ? headHeight : 0) -
      (preContentHeight ? preContentHeight : 0)
    );
  }, [windowHeight, headHeight, preContentHeight]);

  if (!account || !account.name) {
    return <LoadingSpinner />;
  }

  return (
    <DashboardContentSkeleton isShowing={isShowing} isLoading={false}>
      <div ref={headRef}>
        <DashboardHead title={'Settings'} />
      </div>
      <div ref={precontentRef} />
      <div
        className={'relative w-full overflow-auto'}
        style={{height: `${contentHeight}px`}}
      >
        <main className="flex-1 focus:outline-none" tabIndex={0}>
          <div className="relative mx-auto max-w-4xl md:px-8 xl:px-0">
            <div className="pt-10 pb-16">
              <div className="px-4 sm:px-6 md:px-0">
                <div className="py-6">
                  {/* Tabs */}
                  <div className="lg:hidden">
                    <select
                      aria-label="Selected tab"
                      className="form-select focus:ring-blue mt-1 block w-full border-gray-300 py-2 pl-3 pr-10 text-base leading-6 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
                    >
                      <option
                        selected={
                          activePage === SettingsActivePage.AccountPreferences ||
                          activePage === SettingsActivePage.ConnectedAccounts
                        }
                      >
                        Workspace preferences
                      </option>
                      <option selected={activePage === SettingsActivePage.Billing}>
                        Workspace billing
                      </option>
                      <option selected={activePage === SettingsActivePage.Personal}>
                        General
                      </option>
                    </select>
                  </div>
                  <div className="hidden lg:block">
                    <TabNavContainer>
                      <TabNavItem
                        isFirst
                        activePage={activePage}
                        menuPage={SettingsActivePage.AccountPreferences}
                      >
                        <span>Workspace Preferences</span>
                      </TabNavItem>
                      <TabNavItem
                        isFirst={false}
                        activePage={activePage}
                        menuPage={SettingsActivePage.Billing}
                      >
                        <span>Workspace Billing</span>
                      </TabNavItem>
                      <TabNavItem
                        isFirst={false}
                        activePage={activePage}
                        menuPage={SettingsActivePage.Personal}
                      >
                        <span>Profile</span>
                      </TabNavItem>
                      {isVisualGovernanceEnabled && isAdmin ? (
                        <TabNavItem
                          isFirst={false}
                          activePage={activePage}
                          menuPage={SettingsActivePage.VisualGovernance}
                        >
                          <span>Visual Governance</span>
                        </TabNavItem>
                      ) : null}
                    </TabNavContainer>
                  </div>
                  {user && (
                    <>
                      {activePage === SettingsActivePage.AccountPreferences && (
                        <SettingsAccount />
                      )}
                      {activePage === SettingsActivePage.Billing && processorId && (
                        <SettingsPayment email={user.email} customerId={processorId} />
                      )}
                      {activePage === SettingsActivePage.Personal && (
                        <SettingsPersonal user={user} />
                      )}
                      {isVisualGovernanceEnabled &&
                        activePage === SettingsActivePage.VisualGovernance && (
                          <SettingsVisualGovernance />
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </DashboardContentSkeleton>
  );
}
