import {Switch} from '@headlessui/react';
import classNames from 'classnames';
import {Check, Icon} from 'react-feather';
import ReactTooltip from 'react-tooltip';

export const Checkbox = ({
  label,
  checked,
  onChange,
  CheckedIcon = Check,
  UncheckedIcon,
  tooltip = true,
}: {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  CheckedIcon?: Icon;
  UncheckedIcon?: Icon;
  tooltip?: boolean;
}) => {
  return (
    <>
      <Switch
        data-tip={tooltip && label}
        checked={checked}
        onChange={onChange}
        className={classNames(
          'outline-none focus:outline-none flex h-[30px] w-[30px] items-center justify-center rounded-md border border-gray-300 bg-white text-xs text-indigo-500 transition-shadow focus:ring-2',
        )}
      >
        <span className="sr-only">{label}</span>
        <div className="relative">
          <CheckedIcon
            size={20}
            className={classNames('transition-opacity duration-75', {
              'opacity-0': !checked,
            })}
            color="currentColor"
          />
          {UncheckedIcon && (
            <UncheckedIcon
              size={20}
              className={classNames(
                'absolute top-0 left-0 text-gray-500 transition-opacity duration-75',
                {
                  'opacity-0': checked,
                },
              )}
            />
          )}
        </div>
      </Switch>
      {tooltip ? (
        <ReactTooltip className="bg-gray-600 font-semibold" effect="solid" />
      ) : null}
    </>
  );
};
