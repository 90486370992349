import {WordWithDimensions} from 'features/Captions/Fonts';

export type WordFrameOptions = {
  box: {width: number; height: number};
};

class WordFrame {
  box: WordFrameOptions['box'];
  wordsArr: WordWithDimensions[];
  rowDimensions: number[];

  constructor(options: WordFrameOptions) {
    const {box} = options;
    this.box = box;
    this.wordsArr = [];
    this.rowDimensions = [];
  }

  get words() {
    return this.wordsArr;
  }

  get dimensions() {
    return {
      ...this.box,
    };
  }

  get contentDimensions() {
    return {
      width: this.maxWidth(),
      height: this.maxHeight(),
    };
  }

  get rows() {
    return this.rowDimensions;
  }

  // Return widest row
  maxWidth() {
    return Math.max(...this.rows);
  }

  // Get height of frame, based on one row and multiplying it by number of rows
  maxHeight() {
    if (this.rows.length && this.words.length) {
      return this.rows.length * this.words[0].fontHeight;
    }
    return 0;
  }

  checkWordFit(word: WordWithDimensions) {
    // Has enough rows to fit
    if (this.maxHeight() + word.fontHeight <= this.dimensions.height) {
      return true;
    }
    if (this.rows[this.rows.length - 1] + word.fontWidth <= this.dimensions.width) {
      return true;
    }
    return false;
  }

  // return false === no room
  addWord(word: WordWithDimensions) {
    // console.log('word', word);
    if (this.checkWordFit(word)) {
      this.wordsArr.push(word);

      // if no rows, set to length
      if (!this.rows.length) {
        this.rowDimensions.push(word.fontWidth);
        // otherwise, get last row
      } else {
        const lastRowWidth = this.rows[this.rows.length - 1];
        const newRowWidth = lastRowWidth + word.fontWidth;

        // If the row is short enough to fit new word, then add it
        if (newRowWidth <= this.dimensions.width) {
          this.rowDimensions[this.rows.length - 1] = lastRowWidth + word.fontWidth;

          // otherwise, create new row
        } else {
          this.rowDimensions.push(word.fontWidth);
        }
      }
      return true;
    }
    return false;
  }
}

export default WordFrame;
