import {useDispatch, useSelector} from 'react-redux';

import {ColorInput} from 'features/Common/ColorInput';
import {ColorResult} from 'react-color';
import ReactTooltip from 'react-tooltip';
import {Type} from 'react-feather';
import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {updateItemAttribute} from 'features/canvasItemsSlice';

export default function TextColorPicker({projectId}: {projectId: string}) {
  const dispatch = useDispatch();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  if (!selected) return null;

  const handleChange = (color: ColorResult) => {
    dispatch(
      updateItemAttribute({
        projectId,
        id: selected.id,
        property: 'color',
        attribute: 'style',
        newValue: color.hex,
      }),
    );
  };

  const isCaption = [ViewTypes.Caption, ViewTypes.CaptionClip].includes(
    selected.item.viewType,
  );

  const tooltipMessage = isCaption ? 'Active caption text color' : 'Text color';

  const textColor = selected.item.style.color;
  if (!textColor) return <span />;

  return (
    <span data-tip={tooltipMessage} className="group">
      <ColorInput
        color={textColor}
        onChange={handleChange}
        type={isCaption ? 'captions_active_color' : 'text_color'}
      >
        <span className="align-center flex cursor-pointer flex-col items-center justify-center rounded-sm px-1 group-hover:bg-gray-50">
          <Type className="h-5 w-5" />
          <div
            className="cursor-pointer rounded-md shadow-inner"
            style={{
              background: textColor,
              width: '28px',
              height: '5px',
              marginTop: '2px',
            }}
          />
        </span>
      </ColorInput>
      <ReactTooltip className="bg-gray-600 font-semibold" effect="solid" />
    </span>
  );
}
