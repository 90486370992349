import {Lock, Menu, Unlock} from 'react-feather';
import {ReactNode, useState} from 'react';
import {toggleLocked, updateItemName} from 'features/canvasItemsSlice';
import {useDispatch, useSelector} from 'react-redux';

import EditableTextField from '../shared/EditableTextField';
import {IconButton} from 'ui/IconButton';
import {selectProjectCanvasItem} from 'features/selectors/canvasItemsSelectors';
import {useAuthAlt} from 'features/Auth/useAuthAlt';

export default function CanvasItemNameField({
  projectId,
  itemKey,
  children,
}: {
  projectId: string;
  itemKey: string;
  children?: ReactNode;
}) {
  const item = useSelector(state =>
    selectProjectCanvasItem(state, {projectId, itemId: itemKey}),
  );

  const itemName = item?.layerName ?? '';
  const dispatch = useDispatch();
  const {getAccessTokenSilently} = useAuthAlt();

  const [tempFieldValue, setTempFieldValue] = useState(itemName);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitItemName = () => {
    window.analytics.track('Submit new item name from canvas item layer');
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(updateItemName(token, projectId, itemKey, tempFieldValue));
      } catch (e) {
        console.error(e);
      }
    })();
    setIsEditing(false);
  };

  return (
    <EditableTextField
      tempFieldValue={tempFieldValue}
      setTempFieldValue={setTempFieldValue}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      isSubmitting={isSubmitting}
      setIsSubmitting={setIsSubmitting}
      startValue={itemName}
      onSubmit={submitItemName}
      saveButtonProps={{size: 'small', variant: 'inline'}}
    >
      {children}
      <IconButton icon={Menu} variant="inline" label="Re-order layer" size="small" />
      <IconButton
        icon={item?.locked ? Lock : Unlock}
        variant="inline"
        label={`${item?.locked ? 'Unlock' : 'Lock'} layer`}
        size="small"
        onClick={() => {
          dispatch(toggleLocked({projectId, itemId: itemKey}));
        }}
      />
    </EditableTextField>
  );
}
