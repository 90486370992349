import classNames from 'classnames';
import {TranscriptWord} from 'features/Captions/Transcripts';
import {useDuration} from 'features/EditorCanvas/components/CanvasTime/useDuration';
import {useSelection} from 'features/TranscriptEditor/selection';
import {ChangeEvent, useEffect, useState} from 'react';
import useDimensions from 'react-use-dimensions';
import {useMemo} from 'use-memo-one';
import {msToTimeObject, timeObjectToMs} from './utils';

type ActiveSelectionWordFlagsProps = {
  width: number;
  start: number;
  end: number;
  activeSelectionTranslateX: number;
  activeSelectionWidth: number;
  startWord: TranscriptWord;
  endWord: TranscriptWord;
};

const LOWER_ROW_TRANSLATE = -22;
const HIGHER_ROW_TRANSLATE = -42;

const inputStyle =
  'w-[1.1rem] text-center h-full bg-transparent focus:outline-none relative -top-px';

const useEditTime = (type: 'start' | 'end') => {
  const {selection, setSelection} = useSelection();
  const duration = useDuration();

  const startTime = useMemo(() => {
    return msToTimeObject(selection?.start ?? 0);
  }, [selection?.start]);

  const endTime = useMemo(() => {
    return msToTimeObject(selection?.end ?? 0);
  }, [selection?.end]);

  const time = type === 'start' ? startTime : endTime;

  const [tempTime, setTempTime] = useState(time);
  const [touched, setTouched] = useState(false);
  useEffect(() => {
    setTempTime(time);
    setTouched(false);
  }, [time]);

  const onChange =
    (unit: 'minutes' | 'seconds' | 'deciseconds', max: number) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      if (parseInt(value) > max) return;
      if (parseInt(value) < 0) return;

      setTempTime({...tempTime, [unit]: value});
      setTouched(true);
    };

  let min: number;
  let max: number;
  if (type === 'start') {
    min = 0;
    max = timeObjectToMs(endTime) - 1000;
  } else {
    min = timeObjectToMs(startTime) + 1000;
    max = duration;
  }

  const validNumbers = Object.values(tempTime).every(time => !isNaN(parseInt(time)));
  const timeMs = timeObjectToMs(tempTime);
  const valid = validNumbers && timeMs > min && timeMs < max;

  return {
    value: tempTime,
    touched,
    valid,
    save: () => {
      if (!valid) return;

      const newSelection = {...selection, [type]: timeMs};
      const newRange = Object.values(newSelection) as [number, number];

      setSelection(newRange);
    },
    onChange,
  };
};

const TimeInput = ({type, word}: {word: string; type: 'start' | 'end'}) => {
  const {value, onChange, valid, save, touched} = useEditTime(type);

  return (
    <div
      className={classNames(
        'flex h-full items-center rounded-full px-2 ring-opacity-50 transition-colors focus-within:bg-white focus-within:ring-2 hover:bg-white',
        !valid && 'ring-red-500',
      )}
      onMouseUp={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      onMouseDown={e => e.stopPropagation()}
    >
      <input
        className={inputStyle}
        value={value.minutes}
        onChange={onChange('minutes', 59)}
      />
      :
      <input
        className={inputStyle}
        value={value.seconds}
        onChange={onChange('seconds', 59)}
      />
      ,
      <input
        className={classNames(inputStyle, '!text-left')}
        value={value.deciseconds}
        onChange={onChange('deciseconds', 99)}
      />
      <div className="relative">({word})</div>
      {touched && (
        <div
          className={classNames(
            'pl-1.5 transition-colors',
            valid ? 'cursor-pointer hover:text-green-700' : 'text-gray-400',
          )}
          onClick={save}
        >
          Save
        </div>
      )}
    </div>
  );
};

export default function ActiveSelectionWordFlags({
  width,
  start,
  end,
  activeSelectionTranslateX,
  activeSelectionWidth,
  startWord,
  endWord,
}: ActiveSelectionWordFlagsProps) {
  const [startRef, {width: startWordWidth}] = useDimensions();
  const [endRef, {width: endWordWidth}] = useDimensions();

  // TODO (lenny) WARNING - BLACK MAGIC AHEAD
  // Sorry but if you don't have to touch this - dont
  // Otherwise, you should make some proper tests.

  let endWordTransformX = 0;
  let endWordTransformY = LOWER_ROW_TRANSLATE;
  let startWordTransformX = 0;
  let startWordTransformY = LOWER_ROW_TRANSLATE;

  if (activeSelectionWidth > startWordWidth) {
    startWordTransformY = LOWER_ROW_TRANSLATE;
  }

  if (width - activeSelectionTranslateX < startWordWidth) {
    startWordTransformX = startWordWidth;
    startWordTransformY = LOWER_ROW_TRANSLATE;
  }

  // *Check 1*
  if (activeSelectionTranslateX < startWordWidth) {
    startWordTransformY = HIGHER_ROW_TRANSLATE;
    endWordTransformX = endWordWidth;
  }

  const widthAfterSelection = width - activeSelectionTranslateX - activeSelectionWidth;

  if (activeSelectionTranslateX > endWordWidth) {
    if (activeSelectionTranslateX > startWordWidth) {
      startWordTransformX = -startWordWidth;
    }
    if (activeSelectionWidth < endWordWidth + startWordWidth) {
      if (activeSelectionTranslateX > startWordWidth) {
        startWordTransformX = -startWordWidth;
      } else {
        endWordTransformY = HIGHER_ROW_TRANSLATE;
        startWordTransformY = LOWER_ROW_TRANSLATE;
      }
    }

    if (width - activeSelectionTranslateX < endWordWidth) {
      endWordTransformX = 0;
      endWordTransformY = HIGHER_ROW_TRANSLATE;
    }

    if (widthAfterSelection < endWordWidth + startWordWidth) {
      endWordTransformX = 0;
      endWordTransformY = HIGHER_ROW_TRANSLATE;
      if (activeSelectionWidth > endWordWidth + startWordWidth) {
        endWordTransformY = LOWER_ROW_TRANSLATE;
      }
    } else {
      // console.log('test activeSelectionWidth', activeSelectionWidth);
      // console.log('test endWordWidth', endWordWidth);
      if (activeSelectionWidth < endWordWidth) {
        endWordTransformY = HIGHER_ROW_TRANSLATE;
      }
    }

    if (activeSelectionWidth > endWordWidth + startWordWidth) {
      endWordTransformY = LOWER_ROW_TRANSLATE;
      startWordTransformY = LOWER_ROW_TRANSLATE;
    }

    if (widthAfterSelection > endWordWidth && startWordTransformX !== 0) {
      endWordTransformX = endWordWidth;
      endWordTransformY = LOWER_ROW_TRANSLATE;
    }
  } else {
    if (
      activeSelectionWidth < startWordWidth ||
      activeSelectionWidth < startWordWidth + endWordWidth
    ) {
      startWordTransformY = HIGHER_ROW_TRANSLATE;
    }

    if (width - (activeSelectionWidth + activeSelectionTranslateX) > endWordWidth) {
      endWordTransformX = endWordWidth;
    } else {
      endWordTransformX = 0;
    }
  }

  // Overrides the *Check 1*
  if (startWordWidth < activeSelectionWidth - 10) {
    startWordTransformY = LOWER_ROW_TRANSLATE;
  }

  return (
    <>
      <div
        ref={startRef}
        className="absolute top-0 left-0 inline-flex items-center justify-center whitespace-nowrap rounded-full bg-blue-400 bg-opacity-50 text-xs font-semibold text-gray-800"
        style={{
          minWidth: '30px',
          transform: `translateY(${startWordTransformY}px) translateX(${startWordTransformX}px)`,
          height: '20px',
        }}
        id="active-selection-handle-start"
      >
        <TimeInput word={startWord.text} type="start" />
      </div>
      <div
        ref={endRef}
        className="absolute top-0 right-0 inline-flex items-center justify-center whitespace-nowrap rounded-full bg-blue-400 bg-opacity-50 text-xs font-semibold text-gray-800"
        style={{
          minWidth: '30px',
          transform: `translateY(${endWordTransformY}px) translateX(${endWordTransformX}px)`,
          height: '20px',
        }}
        id="active-selection-handle-end"
      >
        <TimeInput word={endWord.text} type="end" />
      </div>
    </>
  );
}
