// Refactor TODO (jacques): Use projectId context in more places

import {FC, createContext, useContext} from 'react';

const ProjectIdContext = createContext<string | null>(null);

export const ProjectIdProvider: FC<{projectId: string}> = ({projectId, children}) => (
  <ProjectIdContext.Provider value={projectId}>{children}</ProjectIdContext.Provider>
);

export const useProjectId = () => {
  const projectId = useContext(ProjectIdContext);
  if (projectId == null)
    throw new Error('Cannot use useProjectId outside of <ProjectIdProvider />');
  return projectId;
};
