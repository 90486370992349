import {
  ChevronDownIcon,
  FilterIcon,
  MinusSmIcon,
  PlusSmIcon,
  ViewGridIcon,
} from '@heroicons/react/solid';
import {DashParams, routePaths} from 'routes/routesHelper';
import {Dialog, Disclosure, Menu, Transition} from '@headlessui/react';
import {Fragment, ReactElement, useMemo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useFetchCmsTemplatesQuery, useFetchTemplatesQuery} from 'services/templatesAPI';

import {XIcon} from '@heroicons/react/outline';
import classNames from 'classnames';
import {convertSanityEntryToTemplate} from 'features/SanityCms/helpers';

const sortOptions = [
  {name: 'Most Popular', href: '#', current: true},
  {name: 'Best Rating', href: '#', current: false},
  {name: 'Newest', href: '#', current: false},
  {name: 'Price: Low to High', href: '#', current: false},
  {name: 'Price: High to Low', href: '#', current: false},
];
const subCategories = [
  {name: 'All', href: '#'},
  {name: 'Marketing', href: '#'},
  {name: 'Social media', href: '#'},
  {name: 'Webinars', href: '#'},
];
const filters = [
  // {
  //   id: 'color',
  //   name: 'Color',
  //   options: [
  //     {value: 'white', label: 'White', checked: false},
  //     {value: 'beige', label: 'Beige', checked: false},
  //     {value: 'blue', label: 'Blue', checked: true},
  //     {value: 'brown', label: 'Brown', checked: false},
  //     {value: 'green', label: 'Green', checked: false},
  //     {value: 'purple', label: 'Purple', checked: false},
  //   ],
  // },
  // {
  //   id: 'category',
  //   name: 'Category',
  //   options: [
  //     {value: 'new-arrivals', label: 'New Arrivals', checked: false},
  //     {value: 'sale', label: 'Sale', checked: false},
  //     {value: 'travel', label: 'Travel', checked: true},
  //     {value: 'organization', label: 'Organization', checked: false},
  //     {value: 'accessories', label: 'Accessories', checked: false},
  //   ],
  // },
  // {
  //   id: 'size',
  //   name: 'Size',
  //   options: [
  //     {value: '2l', label: '2L', checked: false},
  //     {value: '6l', label: '6L', checked: false},
  //     {value: '12l', label: '12L', checked: false},
  //     {value: '18l', label: '18L', checked: false},
  //     {value: '20l', label: '20L', checked: false},
  //     {value: '40l', label: '40L', checked: true},
  //   ],
  // },
] as {
  id: string;
  name: string;
  options: {value: string; label: string; checked: boolean}[];
}[];

export function TemplateBrowserContainer({children}: {children: ReactElement}) {
  const {data: cmsData} = useFetchCmsTemplatesQuery();
  const {data: milkApiTemplates} = useFetchTemplatesQuery();
  const location = useLocation();
  const milkTemplates =
    cmsData?.templates?.map(data => convertSanityEntryToTemplate(data)) ?? [];

  // title: string;
  // headline: string;
  // description: string;
  // image?: SanityImageSource;
  // slug: string;

  const categories = useMemo(() => {
    const cmsCategories = [...(cmsData?.categories ?? [])];
    return [{title: 'All', slug: ''}, ...cmsCategories];
  }, [cmsData]);

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4 border-t border-gray-200">
                  <h3 className="sr-only">Categories</h3>
                  <ul role="list" className="px-2 py-3 font-medium text-gray-900">
                    {categories.map(category => (
                      <li key={category.title}>
                        <Link
                          to={`/${routePaths.dashboard}/${DashParams.TemplatesPageParam}/category/${category.slug}`}
                          className="block px-2 py-3"
                        >
                          {category.title}
                        </Link>
                      </li>
                    ))}
                  </ul>

                  {filters.map(section => (
                    <Disclosure
                      as="div"
                      key={section.id}
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({open}) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900">
                                {section.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                                ) : (
                                  <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {section.options.map((option, optionIdx) => (
                                <div key={option.value} className="flex items-center">
                                  <input
                                    id={`filter-mobile-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    defaultChecked={option.checked}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                    className="ml-3 min-w-0 flex-1 text-gray-400"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto max-w-7xl pl-4 ">
          <div className="relative z-10 flex items-baseline justify-between border-b border-gray-200 px-4 py-4 lg:hidden">
            <div />

            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  {/* <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Sort
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button> */}
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map(option => (
                        <Menu.Item key={option.name}>
                          {({active}) => (
                            <a
                              href={option.href}
                              className={classNames(
                                option.current
                                  ? 'font-medium text-gray-900'
                                  : 'text-gray-500',
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              {/* <button
                type="button"
                className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
              >
                <span className="sr-only">View grid</span>
                <ViewGridIcon className="h-5 w-5" aria-hidden="true" />
              </button> */}
              <button
                type="button"
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Filters</span>
                <FilterIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 gap-x-4 gap-y-10 lg:grid-cols-5">
              {/* Filters */}
              <form className="hidden lg:block">
                <h3 className="sr-only">Categories</h3>
                <div>
                  <ul
                    role="list"
                    className="space-y-2 pb-6 pt-6 text-xs font-medium text-gray-900"
                  >
                    {categories.map(category => {
                      const url = category.slug
                        ? `/${routePaths.dashboard}/${DashParams.TemplatesPageParam}/category/${category.slug}`
                        : `/${routePaths.dashboard}/${DashParams.TemplatesPageParam}`;
                      return (
                        <li key={category.title}>
                          <Link
                            to={url}
                            className={classNames(
                              'block rounded-md px-4 py-2 hover:bg-gray-50',
                              {
                                'bg-gray-100': location.pathname === url,
                              },
                            )}
                          >
                            {category.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                {filters.map(section => (
                  <Disclosure
                    as="div"
                    key={section.id}
                    className="border-b border-gray-200 py-6"
                  >
                    {({open}) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              {section.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                              ) : (
                                <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <div key={option.value} className="flex items-center">
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  defaultChecked={option.checked}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>
              <div className="lg:col-span-4">{children}</div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
