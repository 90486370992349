import {AudioWaveVizOptions} from './constants';
import {CSSProperties} from 'react';
import {CircleLineCentrifugal} from './Visual/CircleLineCentrifugal';
import {DimensionType} from 'features/types/canvasItemsSlice';
import {LineFlat} from './Visual/LineFlat';
import {LineFlatEmojiWave} from './Visual/LineFlatEmojiWave';
import {LineYAxis} from './Visual/LineYAxis';
import {fftFrameFinder} from './helper';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';
import {useTimeSelector} from 'features/EditorCanvas/components/CanvasTime/useTimeSelector';
import {AudioAnalysisFftFrame, AudioAnalysisProperties} from 'services/userUploadAPI';

export const AudioRenderingContainer = ({
  waveformStyle,
  dimensions,
  audioAnalysisFft,
  itemStyle,
  fftProperties,
}: {
  waveformStyle: AudioWaveVizOptions;
  dimensions: DimensionType;
  audioAnalysisFft: AudioAnalysisFftFrame[];
  itemStyle: CSSProperties;
  fftProperties: AudioAnalysisProperties;
}) => {
  const {activeScene} = useScenes();
  const currentTime = useTimeSelector(
    ({currentTime}) => {
      const flooredTime = currentTime - activeScene.startTimeMs;
      return flooredTime / 1000;
    },
    [],
    {
      throttleFPS: 24,
    },
  );

  const currentFftFrame = fftFrameFinder(
    audioAnalysisFft,
    currentTime,
    fftProperties.fps,
  );

  const pastFftFrame = fftFrameFinder(
    audioAnalysisFft,
    currentTime - 0.04 > 0 ? currentTime - 0.04 : 0,
    fftProperties.fps,
  );

  if (waveformStyle === AudioWaveVizOptions.CircleCentrifugalLine) {
    return (
      <CircleLineCentrifugal dimensions={dimensions} frequencyData={currentFftFrame} />
    );
  }

  if (waveformStyle === AudioWaveVizOptions.LinesYAxis) {
    return <LineYAxis dimensions={dimensions} frequencyData={currentFftFrame} />;
  }

  if (waveformStyle === AudioWaveVizOptions.LineFlatEmojiWave) {
    return <LineFlatEmojiWave dimensions={dimensions} frequencyData={currentFftFrame} />;
  }

  return <LineFlat dimensions={dimensions} frequencyData={currentFftFrame} />;
};
