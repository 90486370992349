import {CalendarIcon, SpeakerphoneIcon, TerminalIcon} from '@heroicons/react/outline';
import {ChevronRightIcon, FilterIcon, SearchIcon} from '@heroicons/react/solid';
import {ClickToCopy, recordingFormUrl} from './utils';
import {DashParams, routePaths} from 'routes/routesHelper';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {Button} from 'ui/Button';
import {Loading} from 'features/EditorCanvas/Sidebar/views/Assets/Loading';
import {RecordingFormContact} from 'services/recordingContactsAPI';
import {RecordingFormSessionContext} from './RecordingFormSessionContext';
import {RecordingSubmission} from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import classNames from 'classnames';
import {format} from 'date-fns';
import useDimensions from 'react-use-dimensions';
import {useRecordingFormsQuery} from 'services/recordingFormsAPI';
import {useWindowHeight} from '@react-hook/window-size';

type RecordingListUsage = 'all-submission-list' | 'selected-recording-form-list';

export const RecordingContactList = ({
  selectedRecordingFormSubmissions,
  recordingFormContacts,
}: {
  recordingFormContacts: RecordingFormContact[];
  selectedRecordingFormSubmissions: RecordingSubmission[];
}) => {
  const [listToolbarRef, {height: listToolbarHeight}] = useDimensions();
  const windowHeight = useWindowHeight();
  const {recordingSubmissionId} = useParams() as Record<string, string>;
  const {data: recordingForms, isLoading, isSuccess} = useRecordingFormsQuery();
  const navigate = useNavigate();
  const submissionLink = (recordingFormSubmissionId: string) => {
    return `/${routePaths.dashboard}/${DashParams.RecordingFormParams}/submissions/${recordingFormSubmissionId}`;
  };

  const checkActiveListItem = useCallback(() => {
    if (
      !recordingSubmissionId &&
      selectedRecordingFormSubmissions &&
      selectedRecordingFormSubmissions[0]
    ) {
      navigate(`submissions/${selectedRecordingFormSubmissions[0].id}`);
    }
  }, []);

  useEffect(() => {
    checkActiveListItem();
  }, [checkActiveListItem]);

  if (isLoading) {
    return <div className="h-12 w-full animate-pulse bg-gray-50" />;
  }

  return (
    <aside
      className="flex w-full flex-col border-r"
      style={{
        maxWidth: '19rem',
        minWidth: '19rem',
      }}
    >
      <div ref={listToolbarRef} className="border-b px-3 pt-6 pb-4">
        <ListToolbar />
      </div>

      <nav
        className="min-h-0 flex-1 overflow-y-auto"
        aria-label="Directory"
        style={{
          height: windowHeight - listToolbarHeight,
        }}
      >
        {selectedRecordingFormSubmissions.length > 0 ? (
          <ul role="list" className="relative z-0 divide-y divide-gray-200">
            {selectedRecordingFormSubmissions.map(selectedRecordingFormSubmission => {
              const recordingContact = recordingFormContacts.find(
                contact =>
                  contact.id ===
                  selectedRecordingFormSubmission.user_recording_contact_id,
              );

              const recordingForm = recordingForms?.find(
                recordingForm =>
                  recordingForm.id ==
                  selectedRecordingFormSubmission.user_recording_interaction_form_id,
              );

              const linkUrl = submissionLink(selectedRecordingFormSubmission.id);
              return (
                <li key={selectedRecordingFormSubmission.id}>
                  {linkUrl && (
                    <Link to={linkUrl}>
                      <div className="relative flex items-center space-x-3 px-4 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 hover:bg-gray-50">
                        <div className="flex-shrink-0">
                          {selectedRecordingFormSubmission.submission_tracks[0] && (
                            <img
                              className="h-10 w-10"
                              src={`https://image.mux.com/${
                                selectedRecordingFormSubmission.submission_tracks[0]
                                  .user_upload.mux_playback_id
                              }/thumbnail.png?width=${100}&height=${100}&fit_mode=smartcrop`}
                              alt=""
                            />
                          )}
                        </div>

                        <div className="min-w-0 flex-1">
                          {/* Extend touch target to entire panel */}
                          <span className="absolute inset-0" aria-hidden="true" />
                          <p className="relative items-start justify-center text-xs font-medium text-gray-900">
                            {recordingContact && `${recordingContact.name}`}{' '}
                            <Button
                              variant="inline"
                              onClick={evt => evt.stopPropagation()}
                              _className="!text-xs !p-1 ml-2 !text-indigo-600 hover:!text-indigo-800"
                              size="small"
                            >
                              <Link
                                to={`/${routePaths.dashboard}/${DashParams.RecordingFormParams}/${selectedRecordingFormSubmission.user_recording_interaction_form_id}`}
                                onClick={evt => evt.stopPropagation()}
                              >
                                {recordingForm?.title}
                              </Link>
                            </Button>
                          </p>
                          <p className="truncate text-xs text-gray-500">
                            Submitted{' '}
                            {format(selectedRecordingFormSubmission.updated_at, 'Pp')}
                          </p>
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <ListEmptyState
            selectedRecordingFormSubmissions={selectedRecordingFormSubmissions}
          />
        )}
      </nav>
    </aside>
  );
};

const items = [
  {
    name: 'Marketing Campaign',
    description: 'I think the kids call these memes these days.',
    href: '#',
    iconColor: 'bg-pink-500',
    icon: SpeakerphoneIcon,
  },
  {
    name: 'Engineering Project',
    description: 'Something really expensive that will ultimately get cancelled.',
    href: '#',
    iconColor: 'bg-purple-500',
    icon: TerminalIcon,
  },
  {
    name: 'Event',
    description: 'Like a conference all about you that no one will care about.',
    href: '#',
    iconColor: 'bg-yellow-500',
    icon: CalendarIcon,
  },
];

const ListEmptyState = ({
  selectedRecordingFormSubmissions,
}: {
  selectedRecordingFormSubmissions: RecordingSubmission[];
}) => {
  const {isCreateRecordingModalOpen, setIsCreateRecordingModalOpen} =
    RecordingFormSessionContext.useContainer();

  const {data: recordingForms, isLoading, isSuccess} = useRecordingFormsQuery();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname !== `/${routePaths.dashboard}/${DashParams.RecordingFormParams}`
    ) {
      return;
    }

    if (recordingForms && recordingForms.length > 0) {
      const lastForm = recordingForms[0];
      // console.log(lastForm);
      if (lastForm) {
        navigate(
          `/${routePaths.dashboard}/${DashParams.RecordingFormParams}/${lastForm.id}`,
        );
      }
    }
  }, [recordingForms, location]);

  const items = useMemo(() => {
    if (recordingForms) {
      return recordingForms;
    }
    return [];
  }, [recordingForms]);

  if (isLoading) {
    return <div />;
  }

  return (
    <div className="mx-auto mt-4 max-w-lg px-4">
      <h2 className="text-lg font-medium text-gray-900">Collect your first recording</h2>
      <p className="mt-1 text-sm text-gray-500">
        Capture testimonials, leadership updates, or employee feedback with a single link.
      </p>
      <img
        className="mt-4"
        src="https://assets-milk.s3.amazonaws.com/share/video-testimonial.jpg"
        alt="video testimonial preview"
      />
      <ul role="list" className="mt-6 space-y-3">
        {items.map((item, itemIdx) => (
          <li key={itemIdx}>
            <div className="group relative flex items-center space-x-3 rounded-md bg-white py-4 px-3">
              <div className="min-w-0 flex-1">
                <div className="text-sm font-medium text-gray-900">
                  <Link
                    to={`/${routePaths.dashboard}/${DashParams.RecordingFormParams}/${item.id}`}
                  >
                    <span className="absolute inset-0" aria-hidden="true" />
                    {item.title}
                  </Link>
                </div>
              </div>
              <div className="flex-shrink-0 self-center">
                <ClickToCopy url={recordingFormUrl(item.id)}>Share</ClickToCopy>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="mt-6 flex">
        <Button
          _className="!p-2 !m-0"
          variant="secondary"
          onClick={() => setIsCreateRecordingModalOpen(true)}
        >
          Create a new collection link <span aria-hidden="true"> &rarr;</span>
        </Button>
      </div>
    </div>
  );
};

const ListToolbar = () => {
  const {data: recordingFormsData, isLoading, isSuccess} = useRecordingFormsQuery();
  const listTitle = useMemo(() => {
    return 'All submissions';
  }, [recordingFormsData]);
  return (
    <>
      <h2 className="text-sm font-medium text-gray-900">{listTitle}</h2>
    </>
  );
};
