import {ReactNode, useState} from 'react';
import {RecordingForm, useUpdateRecordingFormMutation} from 'services/recordingFormsAPI';

import {Button} from 'ui/Button';
import {Edit} from 'react-feather';
import EditableTextField from 'features/EditorCanvas/components/shared/EditableTextField';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {useUpdateUserUploadMutation} from 'services/userUploadAPI';

export default function RecordingFormNameTextField({
  children,
  recordingForm,
}: {
  children?: ReactNode;
  recordingForm: RecordingForm;
}) {
  const recordingFormTitle = recordingForm.title;

  const [tempFieldValue, setTempFieldValue] = useState(recordingFormTitle);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updateRecordingForm, {isLoading: isUpdating}] = useUpdateRecordingFormMutation();

  const submitFileName = async () => {
    window.analytics.track('Update name of recording form');

    await updateRecordingForm({
      id: recordingForm.id,
      title: tempFieldValue,
    });

    setIsEditing(false);
  };

  return (
    <EditableTextField
      tempFieldValue={tempFieldValue}
      setTempFieldValue={setTempFieldValue}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      isSubmitting={isSubmitting}
      setIsSubmitting={setIsSubmitting}
      startValue={recordingFormTitle}
      onSubmit={submitFileName}
    >
      {children}
      {!isEditing && (
        <div className="inline-flex">
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
            iconOnly
            rightIcon={Edit}
            fullWidth
            variant="inline"
            size="small"
          />
        </div>
      )}
    </EditableTextField>
  );
}
