import {
  AlignDirection,
  AlignType,
} from './EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/AlignPicker';
import {AppThunk, GetRootState} from 'configureStore';
import {
  BASE_LAYER_ITEM,
  DEFAULT_ITEMS_DATA,
  LayerItemStyleType,
  SAMPLE_CLIP,
  SAMPLE_IMAGE,
  UNNAMED_LAYER,
} from 'features/EditorCanvas/constants/ItemConstants';
import {
  CanvasItem,
  CanvasItemsState,
  CanvasScene,
  DimensionType,
  ItemAttribute,
  ItemObjectAttributes,
  ItemSource,
  MovingItem,
  PayloadWithId,
  PositionType,
  ProjectCanvas,
  StoredCrop,
} from 'features/types/canvasItemsSlice';
import {
  CanvasLayerDepedency,
  canvasStateStartupPreparationItemsSetup,
  projectStartupPreparationFailed,
  projectStartupPreparationSuccessful,
} from './canvasStateSlice';
import {
  CreateCaptionItemFromClipProps,
  createCaptionItemFromClip,
} from './Captions/CaptionItems';
import {Draft, PayloadAction, createSlice} from '@reduxjs/toolkit';
import {ItemLayerSources, ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {TimeMilliSeconds, UserUploadsType} from './types/userLibrarySlice';
import {
  applyStyleToItem,
  checkToMigrationCanvasItemsToScenes,
  getBoundingRect,
  getDirectionPosition,
  getFitSize,
  getItemByType,
  getRectForItem,
  getVideoDimensions,
} from './EditorCanvas/utils';
import {objFilter, objForEach, objMap} from './Common/utils';
import {
  saveClientStateOfCanvasStarted,
  saveClientStateOfCanvasSuccessful,
} from './serverSyncSlice';
import {
  selectCanvasItemsProjectsCanvas,
  selectProjectCanvasItem,
} from './selectors/canvasItemsSelectors';
import {updateCanvasDimensions, updateSceneFrame} from './sceneFrameSlice';
import {v4 as uuidv4, v4} from 'uuid';

import {Box} from './EditorCanvas/Layout/Box';
import {DragSourceType} from './EditorCanvas/components/AppCanvas/DraggableClip';
import {RecentUsage} from 'services/recentUsageAPI';
import {STATIC_EMAIL_OVERRIDE} from 'constants/environment';
import {SceneLayoutId} from './EditorCanvas/Layout/layouts';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import {UserUpload} from 'services/userUploadAPI';
import {addMediaPropertiesToOldClientStateItems} from './EditorCanvas/clientStateMigration';
import {batch} from 'react-redux';
import {cloneDeep} from 'lodash';
import {getCroppedBox} from './EditorCanvas/components/AppCanvas/Canvas/utils/useEditCrop';
import {initiateProject} from 'api/projectsAPI';
import {layoutItems} from './EditorCanvas/Layout/utils';
import {migrateClipPaths} from './EditorCanvas/migrateClipPath';
import {orderCanvasScenes} from './EditorCanvas/components/CanvasTime/utils';
import pick from 'lodash.pick';
import produce from 'immer';
import {sceneFrameSelector} from './selectors/sceneFrameSelectors';
import {setAccountFontUploads} from './fontDataSlice';

export type DefaultItemData = typeof DEFAULT_ITEMS_DATA[number];

const textView = 'text' as ViewTypes.Text;
export const NON_RESIZABLE_ITEMS: ViewTypes[] = [textView];
export const RATIO_LOCKED_ITEMS: ViewTypes[] = [
  'image' as ViewTypes.Image,
  'video' as ViewTypes.Video,
  'video-clip' as ViewTypes.VideoClip,
];
export const CROPPABLE_ITEMS: ViewTypes[] = [
  'image' as ViewTypes.Image,
  'video' as ViewTypes.Video,
  'video-clip' as ViewTypes.VideoClip,
];
export const VIDEO_ITEMS: ViewTypes[] = [
  'video' as ViewTypes.Video,
  'video-clip' as ViewTypes.VideoClip,
  'caption-clip' as ViewTypes.CaptionClip,
];

export const DEFAULT_SCENE_DURATION = 3000;

export const BASE_SCENE_STATE: CanvasScene = {
  order: 0,
  duration: DEFAULT_SCENE_DURATION,
  transitionStart: null,
  transitionEnd: null,
  isLoading: true,
  isOverrideDuration: false,
};

export const BASE_CANVAS_STATE: ProjectCanvas = {
  items: {},
  canvasScenes: {},
};

export const initialState: CanvasItemsState = {
  projectsCanvas: {},
  pasteCounts: {},
  copiedItems: [],
};

const canvasItemsSlice = createSlice({
  name: 'canvasItems',
  initialState,
  reducers: {
    // SETTING UP EMPTY PROJECT HERE
    setupBaseProject(state, action: PayloadAction<string>) {
      const projectId = action.payload;
      const sceneId = uuidv4();
      state.projectsCanvas[projectId] = {
        ...BASE_CANVAS_STATE,
        canvasScenes: {
          [sceneId]: {
            ...BASE_SCENE_STATE,
            order: 0,
            duration: DEFAULT_SCENE_DURATION,
          },
        },
      };
    },
    updateCanvasSceneDuration(
      state,
      action: PayloadWithId<{sceneId: string; durationMs: TimeMilliSeconds}>,
    ) {
      const {durationMs, sceneId, projectId} = action.payload;
      if (
        state.projectsCanvas[projectId].canvasScenes &&
        state.projectsCanvas[projectId].canvasScenes[sceneId]
      ) {
        state.projectsCanvas[projectId].canvasScenes[sceneId].duration = durationMs;
      } else {
        console.error('There was an issue finding scene', sceneId);
      }
    },
    setupActiveCanvasScene(state, action) {
      const {canvasScenes, projectId} = action.payload;
      const oldSceneIds = Object.keys(canvasScenes);
      const newScenes = oldSceneIds.length
        ? canvasScenes
        : {
            [uuidv4()]: {...BASE_SCENE_STATE},
          };
      state.projectsCanvas[projectId].canvasScenes = newScenes;
    },
    addNewScene(
      state,
      action: PayloadWithId<{
        projectId: string;
        newSceneId?: string | undefined;
        targetIndex?: number;
      }>,
    ) {
      const {targetIndex, projectId, newSceneId} = action.payload;
      const sceneId = newSceneId || uuidv4();

      const canvasScenes = state.projectsCanvas[projectId].canvasScenes;

      const highestOrder = Math.max(
        ...Object.values(canvasScenes).map(scene => scene.order),
      );

      if (targetIndex !== undefined) {
        Object.values(canvasScenes).forEach(scene => {
          if (scene.order >= targetIndex) {
            scene.order = scene.order + 1;
          }
        });

        canvasScenes[sceneId] = {
          ...BASE_SCENE_STATE,
          order: targetIndex,
          duration: 3000,
        };
      } else {
        canvasScenes[sceneId] = {
          ...BASE_SCENE_STATE,
          order: highestOrder + 1,
          duration: 3000,
        };
      }
    },
    updateSceneOrders(
      state,
      action: PayloadWithId<{canvasScenes: Record<string, CanvasScene>}>,
    ) {
      const {projectId, canvasScenes} = action.payload;

      state.projectsCanvas[projectId] = {
        ...state.projectsCanvas[projectId],
        canvasScenes,
      };
    },
    resetCanvas(state, action: PayloadAction<string>) {
      const projectId = action.payload;
      const project = {
        ...state.projectsCanvas[projectId],
        items: {},
      };
      state.projectsCanvas[projectId] = project;
    },
    replaceDataItems(
      state,
      action: PayloadWithId<{
        projectId: string;
        currentItems: Record<string, CanvasItem>;
        syncItems: Record<string, CanvasItem>;
        canvasScenes: Record<string, CanvasScene>;
      }>,
    ) {
      const {
        currentItems,
        canvasScenes,
        syncItems: unmigratedItems,
        projectId,
      } = action.payload;
      const items = addMediaPropertiesToOldClientStateItems(
        currentItems,
        migrateClipPaths(unmigratedItems),
      );
      // console.log('items', items);

      const sceneId = uuidv4();

      const project: ProjectCanvas = {
        ...state.projectsCanvas[projectId],
        canvasScenes:
          Object.keys(canvasScenes).length > 0
            ? canvasScenes
            : {sceneId: {...BASE_SCENE_STATE}},
        items,
      };
      project.isLoading = false;
      state.projectsCanvas[projectId] = project;
    },

    updateItemOrders(state, action: PayloadWithId<{newRowOrder: string[]}>) {
      const {projectId, newRowOrder} = action.payload;
      const project = {...state.projectsCanvas[projectId]};
      newRowOrder.reverse().forEach((itemKey: string, order: number) => {
        project.items[itemKey].order = order;
      });
      state.projectsCanvas[projectId] = project;
    },
    addCanvasItem(
      state,
      action: PayloadWithId<{
        projectId: string;
        item: CanvasItem;
        id?: string;
        style?: RecentUsage;
      }>,
    ) {
      const {projectId, item, id, style} = action.payload;

      if (!item.sceneId || item.sceneId === '') {
        throw new Error('Item missing sceneId');
      }

      const project = {...state.projectsCanvas[projectId]};

      const newId = id || uuidv4();
      const orders = Object.values(project.items).map(item => item.order);
      const newOrder = orders.length ? Math.max(...orders) + 1 : 1;
      const position = {
        top: item.position.top,
        left: item.position.left,
      };

      const newItemPosition = {...item, position};

      let layerName = `Layer ${Object.keys(project.items).length} - `;
      if (item.layerName && item.layerName !== UNNAMED_LAYER) {
        layerName += item.layerName;
      } else {
        layerName += newItemPosition.viewType;
      }

      project.items[newId] = {
        ...newItemPosition,
        order: newOrder,
        layerName,
      };

      if (style) {
        project.items[newId] = applyStyleToItem(project.items[newId], style);
      }

      state.projectsCanvas[projectId] = project;
    },
    moveCanvasItem(state, action) {
      const {id, left, top, projectId} = action.payload;
      const project = {...state.projectsCanvas[projectId]};
      project.items[id].position = {
        left,
        top,
      };
      state.projectsCanvas[projectId] = project;
    },
    updateItemsPosition(state, action: PayloadWithId<{newMovingItems: MovingItem[]}>) {
      const {newMovingItems, projectId} = action.payload;
      const project = {...state.projectsCanvas[projectId]};
      // id, left, top
      newMovingItems.forEach(item => {
        const {id, left, top} = item;
        project.items[id].position = {
          left,
          top,
        };
      });
      state.projectsCanvas[projectId] = project;
    },
    updatingItemsPosition(state, action: PayloadWithId<{newMovingItems: MovingItem[]}>) {
      const {newMovingItems, projectId} = action.payload;
      const project = {...state.projectsCanvas[projectId]};
      // id, left, top
      newMovingItems.forEach(item => {
        const {id, left, top} = item;
        project.items[id].position = {
          left,
          top,
        };
      });
      state.projectsCanvas[projectId] = project;
    },
    movingCanvasItem(state, action) {
      const {id, left, top, projectId} = action.payload;
      const project = {...state.projectsCanvas[projectId]};
      project.items[id].position = {
        left,
        top,
      };
      state.projectsCanvas[projectId] = project;
    },
    updatingCanvasItemDimensions(
      state,
      action: PayloadWithId<{id: string; width: number; height: number}>,
    ) {
      const {id, height, width, projectId} = action.payload;
      const project = {...state.projectsCanvas[projectId]};
      project.items[id].dimension = {height, width};
      state.projectsCanvas[projectId] = project;
    },
    updateCanvasItemDimensions(
      state,
      action: PayloadWithId<{id: string; dimension: Partial<DimensionType>}>,
    ) {
      const {id, dimension, projectId} = action.payload;
      const project = {...state.projectsCanvas[projectId]};
      if (project.items[id] && project.items[id].dimension) {
        project.items[id].dimension = {...project.items[id].dimension, ...dimension};
      }
      state.projectsCanvas[projectId] = project;
    },

    updateCanvasItemsPositionState() {},

    removeCanvasItems(state, action: PayloadWithId<{ids: string[]}>) {
      const {ids, projectId} = action.payload;
      const project = state.projectsCanvas[projectId];

      for (const id of ids) {
        const sceneId = project.items[id].sceneId;
        delete project.items[id];
        normalizeItemOrders(state, projectId, sceneId);
      }
    },
    updateItemsWithSource(
      state: Draft<CanvasItemsState>,
      action: PayloadWithId<{
        sourceId: string;
        sceneId: string;
        data: Partial<CanvasItem>;
      }>,
    ) {
      const {projectId, sourceId, data, sceneId} = action.payload;

      const items = state.projectsCanvas[projectId].items;

      const itemsToUpdate = objFilter(items, item => {
        if (item.sceneId !== sceneId) return;

        const itemSource = getItemSource(item);
        if (!itemSource) return;

        return itemSource.id === sourceId;
      });

      Object.keys(itemsToUpdate).forEach(id => {
        // @ts-ignore
        items[id] = {...items[id], ...data};
      });
    },
    updateItem(
      state: Draft<CanvasItemsState>,
      action: PayloadWithId<{
        itemId: string;
        data: Partial<CanvasItem>;
      }>,
    ) {
      const {projectId, data, itemId} = action.payload;

      const items = state.projectsCanvas[projectId].items;

      // @ts-ignore
      items[itemId] = {...items[itemId], ...data};
    },
    updateItemAttribute(
      state: Draft<CanvasItemsState>,
      action: PayloadWithId<{
        id: string;
        attribute: ItemAttribute | any;
        property?: ItemObjectAttributes | any;
        newValue: any;
      }>,
    ) {
      const {projectId, id, property, attribute, newValue} = action.payload;
      const project = {...state.projectsCanvas[projectId]};
      if (property) {
        // @ts-ignore
        project.items[id][attribute][property] = newValue;
      } else if (attribute) {
        // @ts-ignore
        if (
          project.items[id] &&
          (project.items[id][attribute] !== null ||
            project.items[id][attribute] !== undefined)
        ) {
          // @ts-ignore
          project.items[id][attribute] = newValue;
        }
      }
      state.projectsCanvas[projectId] = project;
    },
    updateItemStyle(
      state,
      action: PayloadWithId<{itemId: string; style: Partial<LayerItemStyleType>}>,
    ) {
      const {projectId, itemId, style} = action.payload;

      const item = state.projectsCanvas[projectId].items[itemId];
      if (!item) return;

      item.style = {...item.style, ...style};
    },
    updateItems(
      state: Draft<CanvasItemsState>,
      action: PayloadWithId<{
        items: Record<string, Partial<CanvasItem>>;
      }>,
    ) {
      const {projectId, items} = action.payload;

      for (const id of Object.keys(items)) {
        if (!state.projectsCanvas[projectId].items[id]) continue;

        // @ts-ignore
        state.projectsCanvas[projectId].items[id] = {
          ...state.projectsCanvas[projectId].items[id],
          ...items[id],
        };
      }
    },
    updateItemCrop(
      state: Draft<CanvasItemsState>,
      action: PayloadWithId<{
        id: string;
        crop: StoredCrop;
      }>,
    ) {
      const {projectId, id, crop} = action.payload;
      const item = state.projectsCanvas[projectId].items[id];

      const {position, dimension} = getCroppedBox({
        currentCrop: item.crop,
        croppedDimension: item.dimension,
        croppedPosition: item.position,
        newCrop: crop,
      });

      item.position = position;
      item.dimension = dimension;
      item.crop = crop;
    },
    updateScenePlayTime(
      state,
      action: PayloadWithId<{scenePlayTimeMs: number; sceneId: string}>,
    ) {
      const {projectId, scenePlayTimeMs, sceneId} = action.payload;
      const project = {...state.projectsCanvas[projectId]};
      const activeScene = project.canvasScenes[sceneId];
      if (activeScene) {
        activeScene.duration = scenePlayTimeMs;
      }
      state.projectsCanvas[projectId] = project;
    },
    submitUpdatedItemNameStarted(state, action) {
      const {projectId, itemKey, itemName} = action.payload;
      state.projectsCanvas[projectId].items[itemKey].layerName = itemName;
    },
    submitUpdatedItemNameSuccessful(state, action) {},
    submitUpdatedItemNameFailed(state, action) {},
    projectStartupPreparationStarted(state, action) {
      const {projectId} = action.payload;
      if (!state.projectsCanvas[projectId]) {
        state.projectsCanvas[projectId] = {...BASE_CANVAS_STATE};
      }
      state.projectsCanvas[projectId].isLoading = true;
    },
    projectStartupPreparationItemsSetup(
      state,
      action: PayloadWithId<{
        canvasItems: Record<string, CanvasItem>;
        sceneId?: string;
        canvasScenes?: Record<string, CanvasScene>;
      }>,
    ) {
      const {projectId, canvasItems, sceneId, canvasScenes} = action.payload;

      let newCanvasItems = canvasItems;

      // Add a sceneId to items missing it
      if (sceneId) {
        newCanvasItems = objMap(newCanvasItems, item => ({
          ...item,
          sceneId,
        }));
      }

      // Add style object to items missing it
      newCanvasItems = objMap(newCanvasItems, item => {
        if (item.style) return item;
        return {...item, style: {...BASE_LAYER_ITEM.style}} as CanvasItem;
      });

      newCanvasItems = migrateClipPaths(newCanvasItems);

      // Delete any items that don't have a valid scene
      if (canvasScenes) {
        const sceneIds = Object.keys(canvasScenes);
        newCanvasItems = objFilter(newCanvasItems, item => {
          return sceneIds.includes(item.sceneId);
        });
      }

      state.projectsCanvas[projectId].items = newCanvasItems;
    },

    addCaptionItemFromClipStarted(state, action) {
      // console.log('addCaptionItemFromClipStarted!');
    },
    addCaptionItemFromClipSuccessful(state, action) {
      // console.log('addCaptionItemFromClipSuccessful!');
    },
    addCaptionItemFromClipFailed(state, action) {
      // console.log('addCaptionItemFromClipFailed!');
    },
    alignItems(
      state,
      action: PayloadWithId<{
        ids: string[];
        direction: AlignDirection;
        type: AlignType;
      }>,
    ) {
      const {projectId, ids, direction, type} = action.payload;

      const allItems = state.projectsCanvas[projectId].items;
      const selectedItems = pick(allItems, ids);

      const selectionRect = getBoundingRect(selectedItems);
      const selectionPosition = getDirectionPosition({
        type,
        direction,
        rect: selectionRect,
      });

      for (const id of ids) {
        const item = allItems[id];
        const itemPosition = getDirectionPosition({
          type,
          direction,
          rect: getRectForItem(item),
        });

        const delta = selectionPosition - itemPosition;
        if (type === 'horizontal') {
          item.position.top += delta;
        } else {
          item.position.left += delta;
        }
      }
    },
    copyItems(state, action: PayloadWithId<{ids: string[]; sceneId: string}>) {
      const {ids, sceneId} = action.payload;
      state.copiedItems = ids;
      state.pasteCounts = {};
      state.pasteCounts[sceneId] = 1;
    },
    pasteItems(state, action: PayloadWithId<{sceneId: string}>) {
      const {projectId, sceneId} = action.payload;
      const project = state.projectsCanvas[projectId];

      if (!state.copiedItems) return;

      if (state.pasteCounts[sceneId] == null) {
        state.pasteCounts[sceneId] = 0;
      }

      const itemsToCopy = orderItems(pick(project.items, state.copiedItems));

      let order = getHighestOrder(state, projectId, sceneId) + 1;

      const newIds: string[] = [];
      for (const {item} of itemsToCopy) {
        const newId = uuidv4();
        const newItem = {
          ...item,
          order: order + 1,
          position: {
            top: item.position.top + 20 * state.pasteCounts[sceneId],
            left: item.position.left + 20 * state.pasteCounts[sceneId],
          },
          sceneId,
        };

        project.items[newId] = newItem;
        newIds.push(newId);
        order++;
      }

      normalizeItemOrders(state, projectId, sceneId);

      state.pasteCounts[sceneId]++;
    },
    updateLayerOrder(
      state,
      action: PayloadWithId<{id: string; direction: 'up' | 'down'}>,
    ) {
      const {projectId, id, direction} = action.payload;
      const project = state.projectsCanvas[projectId];

      const item = project.items[id];
      const oldOrder = item.order;

      const highestOrder = getHighestOrder(state, projectId, item.sceneId);

      let newOrder = oldOrder;
      if (direction === 'up') {
        if (oldOrder === highestOrder) return;
        newOrder += 1;
      } else if (direction === 'down') {
        if (oldOrder === 0) return;
        newOrder -= 1;
      }

      const itemsInScene = getItemsInScene(state, projectId, item.sceneId);
      const otherItem = itemsInScene.find(item => item.item.order === newOrder);

      if (otherItem) otherItem.item.order = oldOrder;
      item.order = newOrder;

      normalizeItemOrders(state, projectId, item.sceneId);
    },
    groupItems(state, action: PayloadWithId<{ids: string[]}>) {
      const {projectId, ids} = action.payload;

      const project = state.projectsCanvas[projectId];
      const itemsToGroup = Object.values(pick(project.items, ids));

      const groupId = v4();
      for (const item of itemsToGroup) {
        item.groupId = groupId;
      }
    },
    ungroupItems(state, action: PayloadWithId<{groupId: string}>) {
      const {projectId, groupId} = action.payload;

      const project = state.projectsCanvas[projectId];

      for (const item of Object.values(project.items)) {
        if (item.groupId === groupId) {
          item.groupId = undefined;
        }
      }
    },
    duplicateScene(
      state,
      action: PayloadWithId<{
        sceneId: string;
        newSceneId?: string;
      }>,
    ) {
      const {projectId, sceneId, newSceneId: _newSceneId} = action.payload;
      const project = state.projectsCanvas[projectId];

      const sceneToDuplicate = project.canvasScenes[sceneId];
      if (!sceneToDuplicate) return;

      const newOrder = sceneToDuplicate.order + 1;

      Object.values(project.canvasScenes).forEach(scene => {
        if (scene.order >= newOrder) {
          scene.order = scene.order + 1;
        }
      });

      const newSceneId = _newSceneId || v4();
      project.canvasScenes[newSceneId] = {
        ...sceneToDuplicate,
        order: newOrder,
      };

      const sceneItems = getItemsInScene(state, projectId, sceneId);

      for (const item of sceneItems) {
        const newId = uuidv4();

        project.items[newId] = {
          ...item.item,
          sceneId: newSceneId,
        };
      }
    },
    removeSceneFromProject(state, action: PayloadWithId<{sceneId: string}>) {
      const {projectId, sceneId: sceneIdToRemove} = action.payload;

      const project = state.projectsCanvas[projectId];
      const {canvasScenes, items} = project;

      if (Object.keys(canvasScenes).length === 1) return;

      delete canvasScenes[sceneIdToRemove];

      objForEach(items, (item, id) => {
        if (item.sceneId === sceneIdToRemove) {
          delete items[id];
        }
      });
    },

    setItemError(state, action: PayloadWithId<{itemId: string; error: Error | string}>) {
      const {projectId, itemId, error} = action.payload;

      let errorClass: Error;
      if (typeof error === 'string') {
        errorClass = new Error(error);
      } else {
        errorClass = error;
      }

      const item = state.projectsCanvas[projectId].items[itemId];
      if (!item) return;
      item.error = errorClass;
    },

    toggleLocked(state, action: PayloadWithId<{itemId: string}>) {
      const {projectId, itemId} = action.payload;

      const project = state.projectsCanvas[projectId];

      const item = project.items[itemId];
      item.locked = !item.locked;
    },
    trimItem(
      state,
      action: PayloadWithId<{projectId: string; itemId: string | undefined}>,
    ) {
      const {projectId, itemId} = action.payload;

      const project = state.projectsCanvas[projectId];
      project.trimmingItem = itemId;
    },
    updateItemTimingOffset(
      state,
      action: PayloadWithId<{projectId: string; itemId: string | undefined}>,
    ) {
      const {projectId, itemId} = action.payload;

      const project = state.projectsCanvas[projectId];
      project.offsetTimingItem = itemId;
    },
    updateItemSource(
      state,
      action: PayloadWithId<{projectId: string; itemId: string; source: ItemSource}>,
    ) {
      const {projectId, itemId, source} = action.payload;

      const project = state.projectsCanvas[projectId];
      project.items[itemId].source = source;
    },
    clearProjectState(state, action: PayloadWithId<{projectId: string}>) {
      delete state.projectsCanvas[action.payload.projectId];
    },
    setItems(state, action: PayloadWithId<{items: Record<string, CanvasItem>}>) {
      const {projectId, items} = action.payload;

      const project = state.projectsCanvas[projectId];
      if (!project) return;

      project.items = cloneDeep(items);

      const sceneId = Object.keys(project.canvasScenes)[0];

      for (const id in project.items) {
        project.items[id].sceneId = sceneId;
      }
    },

    addItems(state, action: PayloadWithId<{items: CanvasItem[]}>) {
      const {projectId, items} = action.payload;

      const project = state.projectsCanvas[projectId];
      if (!project) return;

      items.forEach(item => {
        project.items[v4()] = item;
      });
    },
  },
});

export const {
  addCanvasItem,
  addCaptionItemFromClipFailed,
  addCaptionItemFromClipStarted,
  addCaptionItemFromClipSuccessful,
  addItems,
  addNewScene,
  alignItems,
  clearProjectState,
  copyItems,
  duplicateScene,
  groupItems,
  moveCanvasItem,
  movingCanvasItem,
  pasteItems,
  projectStartupPreparationItemsSetup,
  projectStartupPreparationStarted,
  removeCanvasItems,
  removeSceneFromProject,
  replaceDataItems,
  resetCanvas,
  setItemError,
  setItems,
  setupActiveCanvasScene,
  setupBaseProject,
  submitUpdatedItemNameFailed,
  submitUpdatedItemNameStarted,
  submitUpdatedItemNameSuccessful,
  toggleLocked,
  trimItem,
  ungroupItems,
  updateCanvasItemDimensions,
  updateCanvasItemsPositionState,
  updateCanvasSceneDuration,
  updateItem,
  updateItemAttribute,
  updateItemCrop,
  updateItemOrders,
  updateItems,
  updateItemSource,
  updateItemsPosition,
  updateItemStyle,
  updateItemsWithSource,
  updateItemTimingOffset,
  updateLayerOrder,
  updateSceneOrders,
  updateScenePlayTime,
  updatingCanvasItemDimensions,
  updatingItemsPosition,
} = canvasItemsSlice.actions;

export const undoableActions = Object.keys({
  addCanvasItem,
  removeCanvasItems,
  pasteItems,
  updateCanvasItemsPositionState,
}).map((action: String) => `canvasItems/${action}`);

const itemsToArray = (items: Record<string, CanvasItem>) => {
  return Object.entries(items).map(([id, item]) => ({
    id,
    item,
  }));
};

const getItemsInScene = (state: CanvasItemsState, projectId: string, sceneId: string) => {
  const project = state.projectsCanvas[projectId];

  const itemArray = itemsToArray(project.items);
  return itemArray.filter(item => item.item.sceneId === sceneId);
};

const getHighestOrder = (state: CanvasItemsState, projectId: string, sceneId: string) => {
  const items = getItemsInScene(state, projectId, sceneId);
  return Math.max(...items.map(item => item.item.order), 0);
};

const orderItems = (items: Record<string, CanvasItem>) => {
  return itemsToArray(items).sort((a, b) => a.item.order - b.item.order);
};

const normalizeItemOrders = (
  state: CanvasItemsState,
  projectId: string,
  sceneId: string,
) => {
  const project = state.projectsCanvas[projectId];

  const sceneItems = getItemsInScene(state, projectId, sceneId).sort(
    (a, b) => a.item.order - b.item.order,
  );

  let order = 0;
  for (const {id} of sceneItems) {
    project.items[id].order = order;
    order++;
  }
};

export const setSceneLayout =
  ({projectId, id}: {projectId: string; id: SceneLayoutId}): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const {canvasDimensions} = sceneFrameSelector(state)(projectId);
    const items = selectCanvasItemsProjectsCanvas(state)[projectId].items;

    const updatedItems = layoutItems({
      id,
      items,
      withinContainer: {dimension: canvasDimensions},
    });
    if (!updatedItems) return;

    dispatch(updateItems({projectId, items: updatedItems}));
  };

export const updateCanvasItemFont =
  ({
    projectId,
    id,
    fontName,
  }: {
    projectId: string;
    id: string;
    fontName: string;
  }): AppThunk =>
  async dispatch => {
    dispatch(
      updateItemAttribute({
        projectId,
        id,
        attribute: 'style',
        property: 'fontFamily',
        newValue: fontName,
      }),
    );
  };

export const getClipTimes = (clip: UploadMediaClipType) => {
  const playLengthSeconds = parseFloat(clip.end_time) - parseFloat(clip.start_time);
  const timeOffsetSeconds = parseFloat(clip.start_time);

  return {playLengthSeconds, timeOffsetSeconds};
};

export const getItemSource = (item: CanvasItem) => {
  if (
    item.viewType !== ViewTypes.AudioClip &&
    item.viewType !== ViewTypes.CaptionClip &&
    item.viewType !== ViewTypes.Video &&
    item.viewType !== ViewTypes.VideoClip
  ) {
    return;
  }

  if (item.source) return item.source;
  return {id: item.itemSourceId, type: item.itemSource};
};

export const getVideoItemFromClip = ({
  userUpload,
  uploadMediaClip,
  canvasDimensions,
  sceneId,
}: {
  userUpload: UserUpload;
  uploadMediaClip: UploadMediaClipType;
  sceneId: string;
  canvasDimensions?: DimensionType;
}) => {
  const item = {
    ...SAMPLE_CLIP,
    sceneId,
    ...getClipTimes(uploadMediaClip),
    playbackId: userUpload.mux_playback_id,
    itemSourceId: uploadMediaClip.id,
    itemSource: ItemLayerSources.Clips,
    layerName: uploadMediaClip.clip_name,
  };

  item.dimension = getVideoDimensions({
    currentDimensions: item.dimension,
    userUpload,
    fitWithin: canvasDimensions,
  });

  return item;
};

export const addVideoCanvasItem =
  ({
    projectId,
    item,
    itemId = undefined,
    userUpload,
    style,
  }: {
    projectId: string;
    item: CanvasItem;
    itemId?: string;
    userUpload?: UserUploadsType;
    style: RecentUsage;
  }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const {canvasDimensions} = sceneFrameSelector(state)(projectId);

    let maxDimension = item.dimension;
    if (userUpload && userUpload.max_width != null && userUpload.max_height != null) {
      maxDimension = {width: userUpload.max_width, height: userUpload.max_height};
    }

    const newItem = {
      ...item,
      dimension: getFitSize(maxDimension, canvasDimensions),
    };

    dispatch(addCanvasItem({projectId, item: newItem, id: itemId, style}));
  };

export const addItemsFromClip =
  ({
    projectId,
    userUpload,
    uploadMediaClip,
    position = {top: 0, left: 0},
    type,
    layout = 'VideoLeft',
    sceneId,
    style,
  }: {
    projectId: string;
    userUpload: UserUpload;
    uploadMediaClip: UploadMediaClipType;
    sceneId: string;
    position?: PositionType;
    type: DragSourceType;
    layout?: SceneLayoutId;
    style: RecentUsage;
  }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const {canvasDimensions} = sceneFrameSelector(state)(projectId);

    // console.log('type', type);

    let videoItem: CanvasItem | undefined;
    if (type === 'video' || type === 'both') {
      videoItem = getVideoItemFromClip({
        userUpload,
        uploadMediaClip,
        canvasDimensions,
        sceneId,
      });
    }

    let captionItem: CreateCaptionItemFromClipProps | undefined;
    if (type === 'caption' || type === 'both') {
      captionItem = {projectId, uploadMediaClip: uploadMediaClip, sceneId, style};
    }

    // console.log('captionItem', captionItem);

    const containerDimension = {
      width: Math.max(300, canvasDimensions.width - position.left),
      height: Math.max(150, canvasDimensions.height - position.top),
    };

    const fullContainer = {dimension: containerDimension, position};

    if (videoItem && captionItem) {
      // Position both according to layout
      const positionedItems = layoutItems({
        withinContainer: fullContainer,
        id: layout,
        items: {
          videoItem,
          captionItem: {
            viewType: ViewTypes.Caption,
            dimension: {width: 0, height: 0},
            position: {top: 0, left: 0},
          },
        },
      });
      if (!positionedItems) return;

      videoItem = {
        ...videoItem,
        ...positionedItems.videoItem,
      };

      captionItem = {
        ...captionItem,
        position: positionedItems.captionItem.position,
        dimension: positionedItems.captionItem.dimension,
      };
    } else if (videoItem) {
      // Position video in center of canvas
      const videoBox = new Box(videoItem.dimension, fullContainer).fit();
      videoItem = {...videoItem, ...videoBox.getBox()};
    } else if (captionItem) {
      // Fill canvas with caption
      captionItem = {...captionItem, ...fullContainer};
    }

    if (videoItem) {
      dispatch(addVideoCanvasItem({projectId, item: videoItem, style}));
    }

    if (captionItem) {
      dispatch(createCaptionItemFromClip(captionItem));
    }
  };

export const replaceVideoWithClip =
  ({
    id,
    projectId,
    sceneId,
    userUpload,
    uploadMediaClip,
    style,
  }: {
    id: string;
    sceneId: string;
    projectId: string;
    userUpload: UserUpload;
    uploadMediaClip: UploadMediaClipType;
    style: RecentUsage;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const oldItem = selectCanvasItemsProjectsCanvas(state)[projectId].items[id];

    const item = getVideoItemFromClip({
      userUpload,
      uploadMediaClip,
      sceneId,
    });

    const box = new Box(item.dimension, oldItem).fit().center();

    const newItem = {
      ...item,
      ...box.getBox(),
    };
    dispatch(updateCanvasItemsPositionState());
    dispatch(removeCanvasItems({projectId, ids: [id]}));
    dispatch(addVideoCanvasItem({projectId, item: newItem, style}));
  };

export const replaceCaptionWithClip =
  ({
    id,
    projectId,
    sceneId,
    uploadMediaClip,
    style,
  }: {
    id: string;
    uploadMediaClip: UploadMediaClipType;
    sceneId: string;
    projectId: string;
    style: RecentUsage;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const oldItem = selectCanvasItemsProjectsCanvas(state)[projectId].items[id];
    const {dimension, position, style: oldItemStyle} = oldItem;

    dispatch(updateCanvasItemsPositionState());
    dispatch(removeCanvasItems({projectId, ids: [id]}));

    const newId = v4();

    dispatch(
      createCaptionItemFromClip({
        projectId,
        uploadMediaClip,
        dimension,
        sceneId,
        position,
        style,
        newId,
      }),
    );

    dispatch(updateItemStyle({projectId, itemId: newId, style: oldItemStyle}));
  };

export const updateImage =
  ({projectId, id, url}: {projectId: string; id: string; url: string}): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const item = selectProjectCanvasItem(state, {projectId, itemId: id});
    if (!item) return;

    const maxSize = await getImageSize(url);
    const dimension = getFitSize(maxSize, item.dimension);

    batch(() => {
      dispatch(
        updateCanvasItemDimensions({
          projectId,
          id,
          dimension,
        }),
      );

      dispatch(
        updateItemAttribute({
          projectId,
          id,
          attribute: 'url',
          newValue: url,
        }),
      );
    });
  };

export const addImageCanvasItem =
  ({
    projectId,
    url,
    fileName,
    sceneId,
    style,
  }: {
    sceneId: string;
    projectId: string;
    url: string;
    fileName: string;
    style?: RecentUsage;
  }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const {canvasDimensions} = sceneFrameSelector(state)(projectId);

    const maxSize = await getImageSize(url);
    const dimension = getFitSize(maxSize, canvasDimensions);

    // console.log('url', url);

    const newItem = {
      ...SAMPLE_IMAGE,
      url,
      sceneId,
      dimension,
      layerName: fileName,
    };

    if (style) {
      dispatch(addCanvasItem({projectId, item: newItem, style}));
    } else {
      dispatch(addCanvasItem({projectId, item: newItem}));
    }
  };

export const getImageSize = (url: string) => {
  return new Promise<DimensionType>((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      resolve({width: image.naturalWidth, height: image.naturalHeight});
    };

    image.onerror = () => {
      reject('Error loading image');
    };

    image.src = url;
  });
};

export const addVideoUploadCanvasItem =
  ({
    projectId,
    item,
    itemId = undefined,
    userUpload,
    style,
  }: {
    projectId: string;
    item: CanvasItem;
    itemId?: string;
    userUpload?: UserUploadsType;
    style: RecentUsage;
  }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const {canvasDimensions} = sceneFrameSelector(state)(projectId);

    let maxDimension = item.dimension;
    if (userUpload && userUpload.max_width != null && userUpload.max_height != null) {
      maxDimension = {width: userUpload.max_width, height: userUpload.max_height};
    }

    const newItem = {
      ...item,
      dimension: getFitSize(maxDimension, canvasDimensions),
    };

    if (userUpload) {
      newItem.layerName = userUpload.file_name;
    }

    if (userUpload && userUpload.duration) {
      newItem.playLengthSeconds = userUpload.duration;
    }

    dispatch(addCanvasItem({projectId, item: newItem, id: itemId, style}));
  };

export const recordingStartupPreparation =
  (oldProjectId: string, token: string, callback?: (error?: Error) => void): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      canvasSetup({
        token,
        initialProjectId: oldProjectId,
        referenceEmail: STATIC_EMAIL_OVERRIDE,
        callback,
      }),
    );
  };

export const projectStartupPreparation =
  ({
    oldProjectId,
    token,
    callback,
    sceneId,
  }: {
    oldProjectId: string;
    token: string;
    sceneId?: string;
    callback?: (error?: Error) => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const state: GetRootState = getState();
    const {email} = state.auth;

    dispatch(setupBaseProject(oldProjectId));

    try {
      dispatch(
        canvasSetup({
          token,
          initialProjectId: oldProjectId,
          referenceEmail: email,
          callback,
          sceneId,
        }),
      );
    } catch (error) {
      dispatch(projectStartupPreparationFailed({projectId: oldProjectId}));
      // @ts-ignore
      callback?.(error);
    }
  };

// Setup canvas here
export const canvasSetup =
  ({
    token,
    initialProjectId,
    referenceEmail,
    sceneId,
    callback,
  }: {
    token: string;
    initialProjectId: string;
    sceneId?: string;
    referenceEmail: string | null;
    callback?: (error?: Error) => void;
  }): AppThunk =>
  async dispatch => {
    dispatch(projectStartupPreparationStarted({projectId: initialProjectId}));

    try {
      const {
        project,
        account_font_uploads: accountFontUploads,
        project_id: projectId,
        canvas_items: canvasItems,
        canvas_scenes: canvasScenes,
        scene_frame: sceneFrame,
        client_sync: latestClientSync,
      } = await initiateProject(token, initialProjectId, referenceEmail);

      dispatch(updateSceneFrame({projectId, sceneFrame}));
      dispatch(saveClientStateOfCanvasStarted({projectId}));
      dispatch(
        saveClientStateOfCanvasSuccessful({
          projectId,
          clientState: latestClientSync.client_state,
          clientSyncVersion: latestClientSync.client_sync_version,
        }),
      );
      dispatch(setupActiveCanvasScene({projectId, canvasScenes}));
      /**
       * Account Font Uploads must load before Item Setup
       */
      dispatch(setAccountFontUploads({accountFontUploads}));
      /**
       * Item Setup must load after
       */
      if (checkToMigrationCanvasItemsToScenes(canvasItems)) {
        // console.log('orderCanvasScenes', canvasScenes);
        const firstCanvasSceneId =
          orderCanvasScenes(canvasScenes).length &&
          orderCanvasScenes(canvasScenes)[0]?.sceneId
            ? orderCanvasScenes(canvasScenes)[0].sceneId
            : v4();
        dispatch(
          projectStartupPreparationItemsSetup({
            projectId,
            canvasItems,
            sceneId: firstCanvasSceneId,
          }),
        );
        dispatch(
          canvasStateStartupPreparationItemsSetup({
            canvasItems,
          }),
        );
      } else {
        dispatch(
          projectStartupPreparationItemsSetup({
            projectId,
            canvasItems,
            canvasScenes,
          }),
        );
        dispatch(
          canvasStateStartupPreparationItemsSetup({
            canvasItems,
          }),
        );
      }
      dispatch(projectStartupPreparationSuccessful({projectId}));
      callback?.();
    } catch (error) {
      // @ts-ignore
      callback?.(error);
      dispatch(projectStartupPreparationFailed({projectId: initialProjectId}));
    }
  };

export const updateItemName =
  (token: string, projectId: string, itemKey: string, itemName: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(
        submitUpdatedItemNameStarted({
          projectId,
          itemKey,
          itemName,
        }),
      );
      dispatch(submitUpdatedItemNameSuccessful({}));
    } catch (err) {
      dispatch(
        submitUpdatedItemNameFailed({
          projectId,
          err,
        }),
      );
    }
  };

export const toggleMute =
  (projectId: string, itemKey: string, isMuted: boolean): AppThunk =>
  async dispatch => {
    const currentMute = !!isMuted;
    dispatch(
      updateItemAttribute({
        projectId,
        id: itemKey,
        attribute: 'isMuted',
        newValue: !currentMute,
      }),
    );
  };

export const toggleDurationIgnore =
  (projectId: string, itemKey: string, newIsDurationIgnoredItem: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      updateItemAttribute({
        projectId,
        id: itemKey,
        attribute: 'isDurationIgnored',
        newValue: newIsDurationIgnoredItem,
      }),
    );
  };

export const removeCanvasItemByType =
  ({type, projectId}: {type: ViewTypes | ViewTypes[]; projectId: string}): AppThunk =>
  (dispatch, getState) => {
    const items = selectCanvasItemsProjectsCanvas(getState())[projectId].items;
    const item = getItemByType(items, type);
    if (item) {
      dispatch(updateCanvasItemsPositionState());
      dispatch(removeCanvasItems({projectId, ids: [item.id]}));
    }
  };

export const resizeCanvas = ({
  dimensions,
  items,
  currentDimensions,
}: {
  dimensions: DimensionType;
  items: Record<string, CanvasItem>;
  currentDimensions: DimensionType;
}) => {
  const fitBox = new Box(currentDimensions, {
    position: {top: 0, left: 0},
    dimension: dimensions,
  })
    .fit()
    .center();

  const scale = fitBox.dimension.width / currentDimensions.width;

  const newItems = produce(items, draftItems => {
    objForEach(draftItems, item => {
      let fontSize = parseFloat(item.style.fontSize as string);
      fontSize *= scale;

      item.position = {
        top: item.position.top * scale + fitBox.position.top,
        left: item.position.left * scale + fitBox.position.left,
      };
      item.dimension = {
        width: item.dimension.width * scale,
        height: item.dimension.height * scale,
      };
      item.style = {
        ...item.style,
        fontSize,
      };
    });
  });

  return {
    items: newItems,
    dimensions,
  };
};

export const resizeProject =
  ({projectId, dimensions}: {projectId: string; dimensions: DimensionType}): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const currentItems = selectCanvasItemsProjectsCanvas(state)[projectId].items;
    const currentDimensions = state.sceneFrame.projects[projectId].canvasDimensions;

    const {items} = resizeCanvas({
      dimensions,
      items: currentItems,
      currentDimensions,
    });

    dispatch(updateItems({projectId, items}));
    dispatch(updateCanvasDimensions({projectId, canvasDimensions: dimensions}));
  };

export default canvasItemsSlice.reducer;
