import {ViewTypes} from './ViewConstants';

export enum SmartComponents {
  Default = 'default',
  ProgressAnimation = 'video-duration-progress-bar',
  CountdownTimer = 'timer-countdown',
  AudioWaveform = 'audio-waveform',
}

/**
 * Type to determine which workspace toolbar to show in the canvas
 *
 * Introduced 10-30-2021.
 * Prior, toolbar options were inferred based on the item viewType.
 */
export type ItemToolbarSettings = {
  containerColors?: boolean;
  /**
   * Used for layers that can be cropped.
   *
   * @example: Image, Video, Clips
   */
  containerCrop?: boolean;
  containerDimensions?: boolean;
  containerPosition?: boolean;
  containerStyles?: boolean;
  /**
   * Used for layers with time dependant behavior.
   *
   * @example: Video, Audio, Clips
   */
  mediaPlayable?: boolean;
  mediaVolume?: boolean;
  /**
   * Used for text colors.
   *
   * @example: Text layer, captions
   */
  textColors?: boolean;
  textStyle?: boolean;
  /**
   * Used when layer accepts another layer as a parameter.
   *
   * @example: AudioWaveform
   */
  inheritLayer?: boolean;
  /**
   * Audio waveform style type
   *
   * Introduced 11-17-2021.
   * @example: AudioWaveform
   */
  waveformStyleOptions?: boolean;
  /**
   * General smart component options
   *
   * Introduced 12-4-2021.
   * @example: ProgressAnimationComponent
   */
  smartComponentStyleOptions?: boolean;
};

export const getComponentToolbar = (
  componentId: ViewTypes,
  options?: {componentId: SmartComponents},
): ItemToolbarSettings | null => {
  if (componentId === ViewTypes.SmartComponent) {
    if (!options) {
      return null;
    }
    const {componentId} = options;
    return componentToolbarMap[componentId];
  } else {
    return null;
  }
};

export const componentToolbarMap = {
  [SmartComponents.Default]: {
    containerDimensions: true,
    containerPosition: true,
    containerStyles: true,
    textColors: true,
    textStyle: true,
    containerColors: false,
    containerCrop: false,
    mediaPlayable: false,
    mediaVolume: false,
  },
  [SmartComponents.ProgressAnimation]: {
    textColor: false,
    textStyle: false,
    containerDimensions: true,
    containerPosition: true,
    containerColors: true,
    containerStyles: true,
  },
  [SmartComponents.AudioWaveform]: {
    textColors: false,
    textStyle: false,
    containerDimensions: true,
    containerPosition: true,
    containerStyles: true,
    inheritLayer: true,
    waveformStyleOptions: true,
  },
  [SmartComponents.CountdownTimer]: {
    textColor: true,
    textStyle: true,
    containerDimensions: true,
    containerPosition: true,
    containerStyles: true,
  },
};
