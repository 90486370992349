import {AnimatePresence, motion} from 'framer-motion';
import {FC, ReactNode, useRef, useState} from 'react';
import {
  GestureHandler,
  Selection,
  Timeline,
} from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/TrimTimeline';
import {SelectionRange, useSelection} from 'features/TranscriptEditor/selection';
import classNames, {Argument} from 'classnames';
import {percentageToTime, useStartClipping} from './utils';

import {Time} from 'features/Common/Time';
import {TimelineWaveform} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/ThumbnailTimeline/TimelineWaveform';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {fadeProps} from 'features/EditorCanvas/Sidebar/Sidebar';
import {formatTime} from 'features/EditorCanvas/components/CanvasTime/utils';
import {useDuration} from 'features/EditorCanvas/components/CanvasTime/useDuration';
import {usePlayback} from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import {useTimeSelector} from 'features/EditorCanvas/components/CanvasTime/useTimeSelector';

export const ClipStepTimeline = ({upload}: {upload: UserUploadsType}) => {
  const {selection, setSelection} = useSelection();
  const {seekTo, play, isPlaying} = usePlayback();
  const startClipping = useStartClipping({setSelection});

  const wasPlaying = useRef(false);

  const [isDraggingHandle, setIsDraggingHandle] = useState(false);

  const duration = new Time(upload.duration ?? 0, 's');

  const playbackPercentage = useTimeSelector(
    ({currentTime}) => {
      let time = currentTime;
      if (selection?.start) {
        time += selection.start;
      }

      return (time / duration.ms) * 100;
    },
    [duration.ms, selection],
  );

  let timelineSelection: Selection | undefined;
  if (selection && selection.start !== selection.end) {
    timelineSelection = {start: new Time(selection.start), end: new Time(selection.end)};
  }

  return (
    <Timeline
      selection={timelineSelection}
      setSelection={selection => {
        setSelection([selection.start.ms, selection.end.ms]);
      }}
      duration={duration}
      transcriptId={upload.id}
      language="en"
      onDragStart={() => {
        setIsDraggingHandle(true);

        wasPlaying.current = isPlaying;
      }}
      onDragEnd={() => {
        setIsDraggingHandle(false);
        if (wasPlaying.current) play();
      }}
      renderBackground={() => (
        <TimelineWaveform
          userUploadId={upload.id}
          fileType={upload.file_type}
          playbackId={upload.mux_playback_id}
        />
      )}
    >
      <GestureHandler
        onClick={percentage => {
          let timeMs = (percentage / 100) * duration.ms;
          if (selection?.start) {
            timeMs -= selection.start;
          }

          seekTo({timeMs: Math.max(0, timeMs)});
        }}
        onDragEnd={({rangePercentage}) => {
          const selection: SelectionRange = [
            percentageToTime(rangePercentage[0], duration.ms).ms,
            percentageToTime(rangePercentage[1], duration.ms).ms,
          ];

          setSelection(selection);
        }}
        className={classNames(selection ? 'cursor-pointer' : 'cursor-horizontal')}
      >
        {({state}) => (
          <AnimatePresence>
            {state?.type !== 'drag' && !isDraggingHandle && (
              <VIndicator
                key="playback"
                left={playbackPercentage}
                className="bg-blue-800"
              >
                <button
                  className={classNames(
                    'cursor-pointer whitespace-nowrap rounded-md bg-indigo-500 px-2 py-1 text-xs font-semibold text-white transition hover:bg-indigo-600',
                    (selection || state?.type === 'hover') &&
                      'pointer-events-none opacity-0',
                  )}
                  onClick={() => startClipping()}
                >
                  Start clipping here
                </button>
              </VIndicator>
            )}
            {state?.type === 'hover' && !selection && (
              <VIndicator key="hover" left={state.percentage} className="bg-blue-500">
                <div className="whitespace-nowrap rounded-md rounded-bl-none bg-blue-400 px-2 py-1 text-xs font-semibold text-white">
                  Click and drag to start clipping
                </div>
              </VIndicator>
            )}
            {state?.type === 'drag' && (
              <RangeIndicator range={state.rangePercentage} key="drag">
                <div className="absolute left-0 right-0 bottom-full flex flex-wrap justify-between font-mono text-xs text-white">
                  <TimeIndicator
                    timePercentage={state.rangePercentage[0]}
                    className="mr-2"
                  />
                  <TimeIndicator
                    timePercentage={state.rangePercentage[1]}
                    className="ml-auto"
                  />
                </div>
              </RangeIndicator>
            )}
            {/* {activeClip && (
              <RangeIndicator
                key="selection"
                range={[
                  timeToPercentage(selection.start, duration),
                  timeToPercentage(selection.end, duration),
                ]}
              />
            )} */}
          </AnimatePresence>
        )}
      </GestureHandler>
    </Timeline>
  );
};

const VIndicator = ({
  left,
  className,
  opacity = 1,
  children,
}: {
  left: number;
  className?: Argument;
  opacity?: number;
  children?: ReactNode;
}) => (
  <motion.div
    className={classNames('absolute inset-y-0 w-1 rounded-sm bg-blue-500', className)}
    style={{left: `${left}%`}}
    {...fadeProps}
    animate={{opacity}}
  >
    {children && <div className="absolute bottom-full left-0 mb-1">{children}</div>}
  </motion.div>
);

const RangeIndicator: FC<{range: [number, number]}> = ({range, children}) => {
  return (
    <motion.div
      key="drag"
      className="absolute inset-y-0 rounded-lg bg-blue-500 bg-opacity-50"
      style={{
        left: `${range[0]}%`,
        width: `${range[1] - range[0]}%`,
      }}
      {...fadeProps}
    >
      {children}
    </motion.div>
  );
};

const TimeIndicator = ({
  timePercentage,
  className,
}: {
  timePercentage: number;
  className: Argument;
}) => {
  const duration = useDuration();

  return (
    <div className={classNames('mb-1 rounded-full bg-blue-500 px-1.5 py-0.5', className)}>
      {formatTime((timePercentage / 100) * duration, 2)}
    </div>
  );
};
