import {CalculatedScene} from '../CanvasTime/useScenes';
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd';
import {getForegroundColor} from 'ui/colorUtils';
import {sceneFrameSelector} from 'features/selectors/sceneFrameSelectors';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useSelector} from 'react-redux';

export const ScenePreview = ({
  items,
  isActive,
  dragHandleProps,
  height,
  sceneId,
  sceneName,
}: {
  items: CalculatedScene['items'];
  isActive: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  height: number;
  sceneId: string;
  sceneName: string;
}) => {
  const projectId = useProjectId();

  const {backgroundColor, canvasDimensions} = useSelector(sceneFrameSelector)(projectId);
  let background: string | undefined = undefined;
  if (isActive) background = backgroundColor.hex;

  const sceneItems = Object.values(items || {});

  const itemPositions = sceneItems.map(item => {
    return {
      top: `${(item.position.top / canvasDimensions.height) * 100}%`,
      left: `${(item.position.left / canvasDimensions.width) * 100}%`,
      width: `${(item.dimension.width / canvasDimensions.width) * 100}%`,
      height: `${(item.dimension.height / canvasDimensions.height) * 100}%`,
    };
  });

  let itemColor = getForegroundColor(backgroundColor.hex);
  if (!isActive) itemColor = 'black';

  return (
    <div
      className="relative inline-block h-full w-full overflow-hidden bg-gray-200 transition"
      style={{background}}
      {...dragHandleProps}
    >
      <div className="absolute inset-1">
        {itemPositions.map((item, key) => (
          <div
            className="absolute rounded-sm opacity-20 transition-colors"
            key={key}
            style={{...item, background: itemColor}}
          />
        ))}
      </div>
    </div>
  );
};
