import {FC, ReactNode} from 'react';

import LogoFull from 'ui/LogoFull.svg';
import classNames from 'classnames';
import {createGlobalStyle} from 'styled-components';
import {emailSelector} from 'features/selectors/authSelectors';
import {useSelector} from 'react-redux';

const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100vh;
    background-color: #F9FAFB;
  }
`;

export const OnboardingContainer: FC<{
  title: string;
  subtitle?: string;
  className?: string;
  contentBelow?: ReactNode;
}> = ({title, subtitle, children, className, contentBelow}) => {
  const email = useSelector(emailSelector);

  return (
    <>
      <GlobalStyle />
      <div className="flex min-h-screen w-full flex-col">
        <div className="flex flex-row items-center justify-between p-6 px-7">
          <img src={LogoFull} alt="Logo" className="h-10" />
          {email && (
            <div className="text-right text-sm">
              <div className="text-gray-600">Logged in as</div>
              <div className="truncate font-medium text-gray-800">{email}</div>
            </div>
          )}
        </div>
        <div className="flex w-full flex-col items-center p-4 pb-12">
          <div className="w-full max-w-md">
            <div className="space-y-2 pb-4 text-center">
              <h2 className="text-3xl font-bold">{title}</h2>
              {subtitle && <h3 className="text-base">{subtitle}</h3>}
            </div>
            <div className={classNames('rounded-lg bg-white p-6 shadow', className)}>
              {children}
            </div>
          </div>
        </div>
        {contentBelow}
      </div>
    </>
  );
};
