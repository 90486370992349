import {Copy, MoreVertical, PlusCircle, Trash} from 'react-feather';
import {Modal, ModalButtons, ModalDescription} from 'ui/Modal';
import {deleteProject, duplicateProject} from 'features/userLibrarySlice';
import {
  openProject,
  useCreateProjectFromTemplate,
} from 'features/Dashboard/useCreateProject';
import {useDispatch, useSelector} from 'react-redux';

import {DropdownMenu} from 'ui/DropdownMenu';
import {IconButton} from 'ui/IconButton';
import {TemplateIcon} from '@heroicons/react/outline';
import {isTemplateAccountSelector} from 'features/selectors/authSelectors';
import {routeHome} from 'routes/routesHelper';
import {showErrorNotification} from 'features/Common/utils';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useSaveAsTemplateMutation} from 'services/projectAPI';
import {useState} from 'react';
import {userLibraryUserProjectSelector} from 'features/selectors/userLibrarySelectors';

export default function ProjectNav({projectId}: {projectId: string}) {
  const project = useSelector(userLibraryUserProjectSelector)(projectId);

  const {getAccessTokenSilently} = useAuthAlt();
  const dispatch = useDispatch();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const confirmDelete = async () => {
    window.analytics.track('Confirm project delete');

    try {
      const token = await getAccessTokenSilently();
      setDeleteModalOpen(false);
      dispatch(deleteProject(token, projectId));
      window.location.href = routeHome;
    } catch (error) {
      showErrorNotification({
        title: 'Error deleting project',
        // @ts-ignore
        message: error.message,
        // @ts-ignore
        error,
      });
    }
  };

  const startDuplicate = async () => {
    window.analytics.track('Trigger duplicate project');
    const token = await getAccessTokenSilently();
    dispatch(duplicateProject(token, projectId, true));
  };

  const [saveAsTemplate] = useSaveAsTemplateMutation();

  const createFromTemplate = useCreateProjectFromTemplate();

  const accountTemplatesEnabled = useFeatureFlag('account-templates');

  const isTemplateAccount = useSelector(isTemplateAccountSelector);

  return (
    <>
      <DropdownMenu
        anchor="left"
        button={
          <IconButton
            icon={MoreVertical}
            variant="inline"
            label="Project options"
            size="small"
          />
        }
        items={[
          project?.is_template && {
            label: 'New project from template',
            icon: PlusCircle,
            onClick: () => createFromTemplate({template: project}),
          },
          {
            label: project?.is_template ? 'Duplicate template' : 'Duplicate',
            icon: Copy,
            onClick: startDuplicate,
          },
          !isTemplateAccount &&
            project?.is_template === false &&
            accountTemplatesEnabled && {
              label: 'Save as template',
              icon: TemplateIcon,
              onClick: async () => {
                const result = await saveAsTemplate(projectId);
                if ('data' in result) {
                  await openProject(result.data.slug);
                }
              },
            },
          {
            label: 'Delete',
            icon: Trash,
            destructive: true,
            onClick: () => {
              window.analytics.track('Trigger delete project');
              setDeleteModalOpen(true);
            },
          },
        ]}
      />
      <Modal
        size="small"
        title="Are you sure?"
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalDescription>
          This will permanently delete &ldquo;
          <b>{project?.name}</b>.&rdquo;
          <br />
          Any active collaborators will no longer be able to access the project. This
          action cannot be undone.
        </ModalDescription>
        <ModalButtons
          cancel={{label: 'Cancel'}}
          confirm={{
            label: 'Yes, delete',
            destructive: true,
            onClick: confirmDelete,
          }}
        />
      </Modal>
    </>
  );
}
