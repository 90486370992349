import {CanvasItem, DimensionType, PositionType} from 'features/types/canvasItemsSlice';
import {ClipDropZone, DragSourceType} from '../AppCanvas/DraggableClip';
import {
  EditCrop,
  clipStyleToCrop,
  cropToClipStyle,
  getInset,
  getOriginalBox,
} from '../AppCanvas/Canvas/utils/useEditCrop';
import {InverseCropMoveable, useInverseCrop} from './InverseCrop';
import {
  NON_RESIZABLE_ITEMS,
  replaceCaptionWithClip,
  replaceVideoWithClip,
} from 'features/canvasItemsSlice';
import {forwardRef, useContext, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ContextMenuContext} from '../ContextMenu';
import {CropOverlay} from './CropOverlay';
import Moveable from 'react-moveable';
import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {canvasStateSelector} from 'features/selectors/canvasStateSelectors';
import classNames from 'classnames';
import {useRecentUsage} from 'services/recentUsageAPI';
import {useRecoilValue} from 'recoil';
import {zoomState} from '../AppCanvas/ZoomToolbar';

type DivProps = Omit<JSX.IntrinsicElements['div'], 'ref'>;

type MoveableLayerProps = DivProps & {
  id: string;
  activeSceneId: string;
  item: CanvasItem;
  projectId: string;
  enabled: boolean;
  isSelected: boolean;
  editCrop: EditCrop;
  isPreview?: boolean;
};

export const translateFrom = (
  {position, rotation}: {position?: PositionType; rotation?: number},
  unit: '%' | 'px' = 'px',
) => {
  const css: string[] = [];

  if (position) {
    css.push(`translate(${position.left}${unit}, ${position.top}${unit})`);
  }

  if (rotation != null) {
    css.push(`rotate(${rotation}deg)`);
  }

  return css.join(' ');
};

const getDimensionStyle = (item: CanvasItem, dimension: DimensionType) => {
  if (NON_RESIZABLE_ITEMS.includes(item.viewType)) {
    if (item.viewType === ViewTypes.Text && item.sizeMode === 'customWidth') {
      return {width: dimension.width};
    } else {
      return {};
    }
  }

  return dimension;
};

export const MoveableLayer = forwardRef<HTMLDivElement, MoveableLayerProps>(
  (
    {
      activeSceneId,
      id,
      item,
      enabled,
      projectId,
      isSelected,
      editCrop,
      isPreview,
      ...divProps
    },
    ref,
  ) => {
    const inverseCrop = useInverseCrop();

    const originalBox = getOriginalBox({
      crop: inverseCrop.activeCropFor(id) || item.crop,
      croppedDimension: item.dimension,
      croppedPosition: item.position,
    });

    const inset = getInset(inverseCrop.activeCropFor(id) || item.crop);

    const innerRef = useRef<HTMLDivElement>(null);

    const zoom = useRecoilValue(zoomState);

    let droppableType: DragSourceType | null = null;
    if ([ViewTypes.Video, ViewTypes.VideoClip].includes(item.viewType)) {
      droppableType = 'video';
    } else if ([ViewTypes.Caption, ViewTypes.CaptionClip].includes(item.viewType)) {
      droppableType = 'caption';
    }

    const dispatch = useDispatch();
    const {onContextMenu} = useContext(ContextMenuContext);

    const isActive = isPreview || activeSceneId === item.sceneId;

    const {recentUsage} = useRecentUsage();

    const canvasEditMode = useSelector(canvasStateSelector)?.canvasEditMode;

    let zIndex = divProps && divProps.style && divProps.style.zIndex;

    if (inverseCrop.active) {
      if (inverseCrop.active.itemId === id) {
        zIndex = 9;
      } else {
        zIndex = 8;
      }
    }

    return (
      <>
        <div
          aria-label="moveable-item"
          data-moveable
          data-id={id}
          ref={ref}
          {...divProps}
          data-selected={isSelected}
          style={{
            position: 'absolute',
            transform: translateFrom({
              position: item.position,
              rotation: item.rotation,
            }),
            ...getDimensionStyle(item, item.dimension),
            overflow: canvasEditMode === 'crop' ? 'visible' : 'hidden',
            zIndex,
            opacity: isActive ? 1 : 0,
            pointerEvents: isActive ? 'initial' : 'none',
            borderRadius: item.style?.borderRadius ?? 0,
            backdropFilter: 'opacity(1)',
          }}
          className={classNames(
            !(inverseCrop.active || editCrop.active || item.locked) &&
              'cursor-move ring-2 ring-green-400 ring-opacity-0 hover:ring-opacity-100',
            activeSceneId !== item.sceneId && 'pointer-events-none opacity-0',
          )}
          onContextMenu={onContextMenu({type: 'item', id})}
        >
          <ClipDropZone
            id={`item-${id}`}
            type={droppableType}
            enabled={droppableType != null}
            onDrop={({clip}) => {
              // console.log('clip', clip);

              if (clip.type === 'video') {
                dispatch(
                  replaceVideoWithClip({
                    id,
                    sceneId: activeSceneId,
                    projectId,
                    userUpload: clip.userUpload,
                    uploadMediaClip: clip.uploadMediaClip,
                    style: recentUsage,
                  }),
                );
              } else if (clip.type === 'caption') {
                dispatch(
                  replaceCaptionWithClip({
                    id,
                    sceneId: activeSceneId,
                    projectId,
                    uploadMediaClip: clip.uploadMediaClip,
                    style: recentUsage,
                  }),
                );
              }
            }}
          >
            {({active, droppable, ref}) => {
              // console.log('active', active);
              // console.log('droppable', droppable);
              return (
                <>
                  <div
                    {...divProps}
                    style={{
                      position: 'relative',
                      top: 0,
                      left: 0,
                      transform: translateFrom(
                        {position: {top: -inset.top * 100, left: -inset.left * 100}},
                        '%',
                      ),
                      ...getDimensionStyle(item, originalBox.dimension),
                      clipPath:
                        !editCrop.active && !inverseCrop.activeCropFor(id)
                          ? cropToClipStyle(
                              inverseCrop.activeCropFor(id) ||
                                editCrop.value ||
                                item.crop,
                            )
                          : undefined,
                      zIndex: undefined,
                    }}
                  >
                    {divProps.children}
                    <div
                      style={{
                        position: 'absolute',
                        inset: 0,
                        clipPath: cropToClipStyle(
                          inverseCrop.activeCropFor(id) || editCrop.value || item.crop,
                        ),
                        pointerEvents: !inverseCrop.activeCropFor(id)
                          ? 'none'
                          : 'initial',
                      }}
                      data-crop
                      ref={innerRef}
                    />
                    <CropOverlay
                      id={id}
                      dimension={originalBox.dimension}
                      crop={inverseCrop.activeCropFor(id) || editCrop.value}
                    />
                  </div>
                  <div
                    ref={ref}
                    className={classNames(
                      'pointer-events-none absolute inset-0 flex items-center justify-center border-2 border-transparent bg-gray-800 bg-opacity-70 font-sans text-xl font-semibold text-white transition-all',
                      droppable
                        ? 'pointer-events-auto opacity-100'
                        : 'pointer-events-none opacity-0',
                      droppable && active && 'border-blue-400 bg-blue-800',
                    )}
                  >
                    Replace
                  </div>
                </>
              );
            }}
          </ClipDropZone>
        </div>

        {inverseCrop.active?.itemId === id && (
          <InverseCropMoveable item={item} itemId={id} target={innerRef} />
        )}

        {editCrop.active && (
          <Moveable
            ref={editCrop.moveableRef}
            target={innerRef}
            draggable={false}
            resizable={false}
            origin={false}
            clippable
            zoom={(1 / zoom) * 0.8}
            onClip={event => {
              editCrop.update(clipStyleToCrop(event.clipStyle, originalBox.dimension));
            }}
            clipArea
            clipVerticalGuidelines={[0, '50%', '100%']}
            clipHorizontalGuidelines={[0, '50%', '100%']}
            clipTargetBounds
            clipRelative
            defaultClipPath={editCrop.value?.type || 'inset'}
          />
        )}
      </>
    );
  },
);
