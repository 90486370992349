import {BASE_SCENE_FRAME_STATE, SceneFrameSliceProps} from 'features/sceneFrameSlice';

import {GetRootState} from 'configureStore';

export const sceneFrameSelector =
  (state: GetRootState) =>
  (projectId: string): SceneFrameSliceProps =>
    state.sceneFrame.projects[projectId]
      ? state.sceneFrame.projects[projectId]
      : {
          ...BASE_SCENE_FRAME_STATE,
        };

export const previewItemsSelector = (state: GetRootState) => (projectId: string) => {
  return state.sceneFrame.projects[projectId]?.preview?.items || {};
};

export const canvasBackgroundSelector = (projectId: string) => (state: GetRootState) => {
  return state.sceneFrame.projects[projectId]?.backgroundColor || '#FFFFFF';
};
