import {AUTH0_BASE_URL, BASE_URL} from 'constants/environment';
import {
  AccountInvitationType,
  AccountOnboardingStatus,
  AccountWrapperAccountType,
  AccountWrapperAccountUsersType,
  AccountWrapperPlanType,
  AccountsType,
  UserType,
} from 'features/types/authSlice';

import {User} from '@stytch/stytch-react';
import axios from 'axios';
import {requestHeader} from './helpers';

export type ConnectAccountZoomType = {
  provider: string;
  email: string;
  first_name: string;
  last_name: string;
  zoom_id: string;
  zoom_role_name: string;
  zoom_account_id: string;
  zoom_verified: number;
};

export type AccountUsage = {
  file_data_uploaded: number;
  media_encoded_seconds: number;
  project_count: number;
  transcript_processed_seconds: number;
  upload_count: number;
};

export type AccountLabel = {
  id: string;
  label_options: {
    bgColor: string;
    selectedColor: string;
  };
  label_type: string;
  label_value: string;
};

export type ApiUserResponseBeforeWorkspace = {
  version: number;
  user: UserType;
  current_account: null;
  accounts: AccountWrapperAccountType[];
};

export type ApiUserResponseNormal = {
  version: number;
  user: UserType;
  current_account: {
    account_invitations: AccountInvitationType[];
    account_label: AccountLabel[];
    account_usage: AccountUsage;
    account_users: AccountWrapperAccountUsersType[];
    active_theme_id: string;
    account: AccountsType;
    onboarding_status: AccountOnboardingStatus;
    plan: AccountWrapperPlanType;
    users: UserType[];
  };
  connected_accounts: ConnectAccountZoomType[];
  accounts: AccountWrapperAccountType[];
};

export type ApiUserReponseType = ApiUserResponseNormal | ApiUserResponseBeforeWorkspace;

export async function postUserToApi(
  token: string,
  email: string,
  user: any,
): Promise<ApiUserReponseType> {
  const postUserEndpoint = `${BASE_URL}/api/v1/user`;
  // console.log('user', user);
  const response = await axios.post(
    postUserEndpoint,
    {
      auth: {
        user,
        email,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export async function postUserToApiV2(
  token: string,
  email: string,
  user: User,
): Promise<ApiUserReponseType> {
  const postUserEndpoint = `${BASE_URL}/api/v2/user`;
  // console.log('user', user);
  try {
    const response = await axios.post(
      postUserEndpoint,
      {
        auth: {
          user,
          email,
        },
      },
      requestHeader(token),
    );

    return response.data;
  } catch (error) {
    await sleep(1000);

    return await postUserToApiV2(token, email, user);
  }
}

export async function resetUserPassword(token: string, email: string) {
  const resetPasswordEndpoint = `${AUTH0_BASE_URL}/dbconnections/change_password`;
  const response = await axios.post(
    resetPasswordEndpoint,
    {
      email: email,
      connection: 'Username-Password-Authentication',
    },
    requestHeader(token),
  );
  return response.data;
}

export const triggerVerificationResend = async ({email}: {email: string}) => {
  const postResendVerificationEndpoint = `${BASE_URL}/api/v2/users/resend_verification`;
  try {
    const response = await axios.post(postResendVerificationEndpoint, {
      user: {
        email,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
