import {WizardStateType} from 'features/FocusedFlow/State';
import {baseApi} from './baseAPI';
import {wrappedAxios} from './utils';

export const fetchWizardState = async ({
  id,
}: {
  id: string;
}): Promise<{state: WizardStateType}> => {
  const result = await wrappedAxios({
    url: `wizard_states/${id}`,
    method: 'GET',
    errorTitle: 'Failed to get wizard state',
    v2: true,
  });

  if (result.error) {
    throw new Error(result.error.message);
  }

  return result.data;
};

export const wizardStateAPI = baseApi.injectEndpoints({
  endpoints: builder => ({
    createWizardState: builder.mutation<{id: string}, {state: WizardStateType}>({
      query: state => ({
        url: 'wizard_states',
        method: 'POST',
        errorTitle: 'Error creating wizard state',
        v2: true,
        data: {state},
      }),
    }),
    updateWizardState: builder.mutation<void, {id: string; state: WizardStateType}>({
      query: ({id, state}) => ({
        url: `wizard_states/${id}`,
        method: 'POST',
        errorTitle: 'Error creating wizard state',
        v2: true,
        data: {state},
      }),
    }),
  }),
});

export const {useCreateWizardStateMutation, useUpdateWizardStateMutation} =
  wizardStateAPI;
