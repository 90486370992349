import classNames from 'classnames';
import {useSidebarMenu} from '../../SidebarMenuContext';

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

export function SidebarAssetMenu() {
  const {
    dispatch,
    state: {activeUploadMenu, uploadMenuTabs: tabs},
  } = useSidebarMenu();

  const setUploadTypeView = (newActiveUploadMenu: string) => {
    dispatch({
      type: 'update upload type menu',
      activeUploadMenu: newActiveUploadMenu,
    });
  };

  return (
    <div className="w-full">
      <div className="px-3 sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          onChange={event => {
            setUploadTypeView(event.target.value);
          }}
          className="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          defaultValue={activeUploadMenu}
        >
          {tabs.map(tab => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden px-2 sm:block">
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex items-center justify-center space-x-6"
            aria-label="Tabs"
          >
            {tabs.map(tab => (
              <div
                key={tab.name}
                onClick={() => {
                  setUploadTypeView(tab.name);
                }}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
