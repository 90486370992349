import {ReactElement, useEffect, useState} from 'react';
import {StringParam, updateLocation, useQueryParam} from 'use-query-params';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';

import {ApiRecordingSession} from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import {AppWithRecording} from 'features/RecordingForms/recording/components/RecordingApp';
import {BASE_URL} from 'constants/environment';
import Loader from 'vendor/dailyco/shared/components/Loader/Loader';
import axios from 'axios';

export const RecordingCamera = () => {
  const {recordingRoomId, updateRecordingRoomId} = ApiRecordingSession.useContainer();

  if (!recordingRoomId) {
    return (
      <div className="flex w-full items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <AppWithRecording roomId={recordingRoomId} />
    </div>
  );
};
