import {AccountWrapperAccountType} from 'features/types/authSlice';
import {baseApi} from './baseAPI';

export const accountFeatureFlags = baseApi
  .enhanceEndpoints({addTagTypes: ['AccountFeatureFlag']})
  .injectEndpoints({
    endpoints: builder => ({
      fetchAccountFeatureFlags: builder.query<{flags: Record<string, boolean>}, void>({
        query: () => ({
          url: `/account_feature_flags`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error fetching account flags',
        }),
      }),
    }),
  });

export const {useFetchAccountFeatureFlagsQuery} = accountFeatureFlags;
