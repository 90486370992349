import {TimeSeconds} from 'features/types/userLibrarySlice';

export function distributedCopy<T>(items: T[], length: number) {
  if (items.length === 0) return [];

  const elements = [items[0]];
  const totalItems = items.length - 2;
  const interval = Math.floor(totalItems / (length - 2));
  for (let i = 1; i < length - 1; i++) {
    if (!items[i * interval]) continue;
    elements.push(items[i * interval]);
  }

  if (items[items.length - 1]) {
    elements.push(items[items.length - 1]);
  }

  return elements;
}

export type TimeObject = {minutes: string; seconds: string; deciseconds: string};

// TODO (jacques): Tests
export const msToTimeObject = (milliseconds: number) => {
  const minutesInt = Math.floor(milliseconds / 1000 / 60);
  const secondsInt = Math.floor(milliseconds / 1000) - minutesInt * 60;
  const decisecondsInt =
    Math.floor(milliseconds / 10) - secondsInt * 100 - minutesInt * 60 * 100;

  const minutes = String(minutesInt).padStart(2, '0');
  const seconds = String(secondsInt).padStart(2, '0');
  const deciseconds = String(decisecondsInt).padStart(2, '0');

  return {minutes, seconds, deciseconds};
};

const intOr0 = (str: string) => {
  const parsed = parseInt(str);
  return isNaN(parsed) ? 0 : parsed;
};

export const timeObjectToMs = (time: TimeObject) => {
  const {minutes, seconds, deciseconds} = time;
  return intOr0(minutes) * 60 * 1000 + intOr0(seconds) * 1000 + intOr0(deciseconds) * 10;
};

export const formatSecondsToTime = (playTime: TimeSeconds) => {
  const {minutes, seconds} = msToTimeObject(playTime);
  return `${minutes}:${seconds}`;
};
