import {Dialog, Transition} from '@headlessui/react';

import {Fragment} from 'react';
import LandingClipListResultActions from './LandingClipListResultActions';
import {LandingPage} from 'services/landingPageAPI';
import {ResultItem} from '../ContentSearch/ClipSearchResult/ResultItem';
import {ResultUploadClips} from '../ContentSearch/ClipSearchResult/ResultUploadClips';
import {ResultUploadParent} from '../ContentSearch/ClipSearchResult/ResultUploadParent';
import {SearchResultsProvider} from '../ContentSearch/useSearchResults';
import {UploadClipListItem} from '../ContentSearch/SearchResults';
import {XIcon} from '@heroicons/react/outline';
import {useSelector} from 'react-redux';
import {userLibrarySelector} from 'features/selectors/userLibrarySelectors';

type ClipPickerProps = {
  landingPage: LandingPage;
  open: boolean;
  onClose: () => void;
};
export default function ClipPickerModal({landingPage, open, onClose}: ClipPickerProps) {
  const {loadingClips, loadingUploads, userClips, userUploads} =
    useSelector(userLibrarySelector);
  const recentClips = userClips;
  const recentClipUploadIds = [...new Set(userClips.map(clip => clip.user_upload_id))];
  const recentUploadClipList = recentClipUploadIds.map(uploadId => {
    const uploadClips = recentClips.filter(clip => clip.user_upload_id === uploadId);
    return {
      uploadId,
      upload: userUploads.find(upload => upload.id === uploadId),
      uploadSecondaryItems: uploadClips,
    } as UploadClipListItem;
  });

  const listToDisplay = recentUploadClipList.slice(0, 10);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-20 overflow-y-auto" onClose={onClose}>
        <div
          className="flex min-h-screen  text-center sm:block sm:px-6 lg:px-8"
          style={{fontSize: 0}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-500 sm:bg-opacity-75 sm:transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-105"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-105"
          >
            <div className="flex w-full max-w-3xl transform text-left text-base transition sm:my-8 sm:inline-block sm:align-middle">
              <div className="relative flex w-full flex-col overflow-hidden bg-white pt-6 pb-8 sm:rounded-lg sm:pb-6 lg:py-8">
                <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8">
                  <div>
                    <h2 className="text-lg font-medium text-gray-900">Pick a clip</h2>
                    <p>Select from the below clips to add to your landing page</p>
                  </div>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => onClose()}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {/* <section
                  aria-labelledby="summary-heading"
                  className="mt-4 sm:px-6 lg:px-8"
                >
                  <div className="bg-gray-50 p-3 sm:p-3 sm:rounded-lg">
                    <div className="flow-root">
                      <dl className="-my-4 text-sm divide-y divide-gray-200">
                        <div className="py-4 flex items-center justify-between">
                          <dt className="text-gray-600">Search</dt>
                          <dd className="font-medium text-gray-900">
                            <div>
                              <div className="mt-1 relative flex items-center">
                                <input
                                  type="text"
                                  name="search"
                                  id="search"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                                />
                                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                  <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
                                    ⌘K
                                  </kbd>
                                </div>
                              </div>
                            </div>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </section> */}
                <div className="px-4 sm:px-6 lg:px-8">
                  <section aria-labelledby="cart-heading">
                    <SearchResultsProvider>
                      <div className="overflow-y-auto">
                        <ul className="space-y-3 divide-y divide-gray-200">
                          {listToDisplay.map(recentUploadClip =>
                            recentUploadClip?.upload?.mux_playback_id ? (
                              <ResultItem key={recentUploadClip.uploadId}>
                                <ResultUploadParent uploadClip={recentUploadClip}>
                                  <ResultUploadClips uploadClip={recentUploadClip}>
                                    {props => {
                                      // console.log('props', props);
                                      return (
                                        <LandingClipListResultActions
                                          userUploadId={props.userUploadId}
                                          userClipId={props.userClipId}
                                          setIsMenuOpen={props.setIsMenuOpen}
                                          cardType="picker"
                                        />
                                      );
                                    }}
                                  </ResultUploadClips>
                                </ResultUploadParent>
                              </ResultItem>
                            ) : (
                              <div className="h-10 w-full animate-pulse" />
                            ),
                          )}
                        </ul>
                      </div>
                    </SearchResultsProvider>
                  </section>
                </div>

                <div className="mt-8 flex justify-center px-4 sm:px-6 lg:px-8">
                  {/* <button
                    type="submit"
                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                  >
                    View more
                  </button> */}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
