import {File} from 'react-feather';
import {UserUploadAsset} from '../SocialCardPreview';
import logo from '../logo-square.png';

export default function UserUploadPreview({
  muxPlaybackId,
  accountName,
  assetType,
  asset,
  humanizedDuration,

  textSnippet,
  userAvatar,
  userName,
  assetCreatedAt,
}: {
  muxPlaybackId: string;
  accountName: string;
  assetType: string;
  asset: UserUploadAsset;
  humanizedDuration: string;

  textSnippet: string;
  userAvatar: string;
  userName: string;
  assetCreatedAt: string;
}) {
  const FILE_NAME_SHORTENING_LENGTH = 20;

  const imageHeight = 420;
  const imageWidth = 1000;
  const thumbnailUrl = `https://image.mux.com/${muxPlaybackId}/thumbnail.png?width=${imageWidth}&height=${imageHeight}&fit_mode=pad`;

  function startAndEnd(str: string) {
    if (!str) {
      return str;
    }
    if (str.length > FILE_NAME_SHORTENING_LENGTH) {
      return `${str.substr(0, 14)}...${str.substr(str.length - 5, str.length)}`;
    }
    return str;
  }

  const displayFileName = startAndEnd(asset.file_name);
  return (
    <>
      <div className="flex flex-col justify-between space-y-6">
        <div className="flex flex-col items-center space-y-3 bg-white">
          <div className="flex w-full justify-between px-20">
            <h3 className="flex flex-grow text-2xl font-bold">{accountName}</h3>
            <div className="flex flex-shrink flex-row justify-end">
              <div className="flex items-center justify-center space-x-20 pl-2">
                <a href="#" className="group block flex-shrink-0">
                  <div className="flex items-start justify-start">
                    <div>
                      <File className="inline-block h-6 w-6 opacity-75" />
                    </div>
                    <div className="ml-3 text-xl">
                      <p className="font-medium text-gray-700 group-hover:text-gray-900">
                        {displayFileName}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div
            className="flex h-full w-full items-center justify-center bg-black py-4 align-middle"
            style={{
              backgroundImage: 'url(https://i.imgur.com/AvI5DKE.png)',
              backgroundColor: '#fff',
              backgroundSize: '830px',
              backgroundPositionY: 'center',
            }}
          >
            <div
              className="flex h-full w-full items-center justify-center align-middle"
              style={{
                width: `${imageWidth}px`,
                height: `${imageHeight}px`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundImage: `url(${thumbnailUrl})`,
              }}
            ></div>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-row items-center justify-between bg-white px-20 pt-2 pb-4 align-middle text-white">
        <div className="flex items-center justify-center">
          <div className="mr-2 h-16 w-16">
            <img src={userAvatar} alt="logo" className="overflow-hidden rounded-full" />
          </div>
          <h1 className="ml-3 flex flex-col items-start justify-start text-2xl text-gray-600">
            <span className="whitespace-nowrap">Uploaded by {userName}</span>

            <span className="text-lg opacity-50">{assetCreatedAt}</span>
          </h1>
        </div>
        <div className="mr-2 h-12 w-12">
          <img src={logo} alt="logo" />
        </div>
      </div>
    </>
  );
}
