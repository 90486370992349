import { DashParams, routePaths } from 'routes/routesHelper';
import { Navigate, Route, Routes } from 'react-router-dom';

import { BillingSuccess } from 'features/Dashboard/DashboardPage/ContentSettings/BillingSuccess';
import { BrandKitPage } from 'features/Dashboard/DashboardPage/ContentBrandKit/BrandKit';
import { CampaignCreateContainer } from 'features/Dashboard/DashboardPage/CampaignCreate/CampaignCreateContainer';
import { ClipsListContainer } from 'features/Dashboard/DashboardPage/ContentClips/ClipsListContainer';
import { DashboardLandingPageCreatePage } from 'pages/Dashboard/DashboardLandingPageCreatePage';
import { DashboardMediaAssetDetailPage } from 'pages/Dashboard/DashboardMediaAssetDetailPage';
import { DashboardPageWrapper } from 'pages/Dashboard/DashboardPageWrapper';
import { DashboardRecordingFormBuilder } from 'pages/Dashboard/DashboardRecordingFormBuilder';
import { DashboardSettings } from 'features/Dashboard/DashboardPage/ContentSettings/DashboardSettings';
import { DashboardSettingsWrap } from 'features/Dashboard/DashboardPage/ContentSettings/DashboardSettingsWrap';
import { DashboardUploadDetailPage } from 'pages/Dashboard/DashboardUploadDetailPage';
import { DownloadCanvasPage } from 'pages/Dashboard/DownloadCanvasPage';
import { DownloadsListContainer } from 'features/Dashboard/DashboardPage/ContentDownloads/DownloadsListContainer';
import { Helmet } from 'react-helmet';
import { HomeContainer } from 'features/Dashboard/DashboardPage/ContentHome/HomeContainer';
import { LandingPages } from 'features/Dashboard/DashboardPage/ContentLandingPages/LandingPages';
import { LandingPagesWrap } from 'features/Dashboard/DashboardPage/ContentLandingPages/LandingPagesWrap';
import { ProjectsListContainer } from 'features/Dashboard/DashboardPage/ContentProjects/ProjectsListContainer';
import { RecordingContactContent } from 'features/Dashboard/DashboardPage/ContentRecordingForms/RecordingContactContent';
import { RecordingContactListContainer } from 'features/Dashboard/DashboardPage/ContentRecordingForms/RecordingContactListContainer';
import { RecordingFormContent } from 'features/Dashboard/DashboardPage/ContentRecordingForms/RecordingFormContent';
import { RecordingFormPreview } from 'features/Dashboard/DashboardPage/ContentRecordingForms/RecordingFormPreview';
import { RecordingFormsContainer } from 'features/Dashboard/DashboardPage/ContentRecordingForms/RecordingFormsContainer';
import { RecordingFormsContainerWrap } from 'pages/UserRecordingForms/RecordingFormsContainerWrap';
import { RecordingSubmissionContent } from 'features/Dashboard/DashboardPage/ContentRecordingForms/RecordingSubmissionContent';
import { RecordingSubmissionListContainer } from 'features/Dashboard/DashboardPage/ContentRecordingForms/RecordingSubmissionListContainer';
import { SearchPage } from 'features/Dashboard/DashboardPage/ContentSearch/SearchPage';
import { SearchResultsProvider } from 'features/Dashboard/DashboardPage/ContentSearch/useSearchResults';
import { TemplatePage } from 'features/Dashboard/DashboardPage/ContentTemplatesBrowser/TemplatePage';
import { TemplatesBrowser } from 'features/Dashboard/DashboardPage/ContentTemplatesBrowser/TemplatesBrowser';
import { TemplatesPageWrap } from 'features/Dashboard/DashboardPage/ContentTemplatesBrowser/TemplatesPageWrap';
import { UploadsFolderListContainer } from 'features/Dashboard/DashboardPage/ContentUploads/UploadsFolderListContainer';
import { UploadsListContainer } from 'features/Dashboard/DashboardPage/ContentUploads/UploadsListContainer';

export const paymentRoutes = {
  paymentSuccess: 'success',
};

/**
 * Routes under /dashboard/*
 */
export const RoutesDashboardRelated = () => {
  return (
    <Routes>
      <Route path={`${DashParams.CreateCampaign}/*`}>
        <Route index element={<CampaignCreateContainer />} />
        <Route path={'*'} element={<CampaignCreateContainer />} />
      </Route>
      <Route path="/" element={<DashboardPageWrapper />}>
        <Route index element={<HomeContainer />} />

        <Route path={`*`} element={<HomeContainer />} />
        <Route
          path={DashParams.SearchParam}
          element={
            <SearchResultsProvider>
              <Helmet>
                <title>Search - Milk Video</title>
              </Helmet>
              <SearchPage isShowing />
            </SearchResultsProvider>
          }
        />
        <Route
          path={`${DashParams.RecordingFormParams}/*`}
          element={
            <RecordingFormsContainerWrap>
              <RecordingFormsContainer />
            </RecordingFormsContainerWrap>
          }
        >
          <Route
            path={':recordingFormId/build'}
            element={<DashboardRecordingFormBuilder />}
          />
          <Route path={'contacts/*'} element={<RecordingContactListContainer />}>
            <Route path={':recordingContactId'} element={<RecordingContactContent />} />
          </Route>
          <Route path={'submissions/*'} element={<RecordingSubmissionListContainer />}>
            <Route
              path={':recordingSubmissionId'}
              element={<RecordingSubmissionContent />}
            />
          </Route>
          <Route path={':recordingFormId/*'} element={<RecordingFormContent />}>
            <Route index element={<RecordingFormPreview />} />
            <Route
              path={':recordingSubmissionId'}
              element={<RecordingSubmissionContent />}
            />
          </Route>
          <Route index element={<RecordingSubmissionListContainer />} />
        </Route>
        <Route
          path={DashParams.BillingSuccessParam}
          element={<BillingSuccess isShowing />}
        />
        <Route path={`${DashParams.DownloadsParam}/*`}>
          <Route path=":projectSlug/:downloadId" element={<DownloadCanvasPage />} />
          <Route index element={<DownloadsListContainer isShowing />} />
          <Route
            path={`*`}
            element={
              <Navigate to={`/${routePaths.dashboard}/${DashParams.DownloadsParam}`} />
            }
          />
        </Route>

        <Route path={`${DashParams.SettingsParam}/*`} element={<DashboardSettingsWrap />}>
          <Route index element={<DashboardSettings isShowing />} />
          <Route path={`:settingsDetail`} element={<DashboardSettings isShowing />} />
        </Route>

        <Route
          path={`${DashParams.TemplatesPageParam}/*`}
          element={<TemplatesPageWrap />}
        >
          <Route path="category/*" element={<TemplatesPageWrap />}>
            <Route path=":categoryId" element={<TemplatesBrowser />} />
            <Route index element={<Navigate to="/dashboard/templates" />} />
          </Route>
          <Route path=":id" element={<TemplatePage />} />
          <Route index element={<TemplatesBrowser />} />
        </Route>

        <Route path={`${DashParams.LandingPagesParam}/*`} element={<LandingPagesWrap />}>
          <Route index element={<LandingPages isShowing />} />
          <Route path=":landingPageId" element={<DashboardLandingPageCreatePage />} />
        </Route>

        <Route path={DashParams.BrandKitParam} element={<BrandKitPage />} />

        <Route path={DashParams.ClipsParam} element={<ClipsListContainer />} />

        <Route path={DashParams.ProjectsParam} element={<ProjectsListContainer />} />

        <Route path={`${DashParams.UploadsParam}/*`}>
          <Route
            path="*"
            element={
              <Navigate to={`/${routePaths.dashboard}/${DashParams.UploadsParam}`} />
            }
          />
          <Route index element={<UploadsListContainer isShowing />} />
          <Route path="media/:userUploadId" element={<DashboardMediaAssetDetailPage />} />
          <Route path=":userUploadId" element={<DashboardUploadDetailPage />} />
        </Route>

        <Route
          path={`${DashParams.UploadsParam}/${DashParams.UploadsFolderParam}/:folderId`}
          element={<UploadsFolderListContainer />}
        />
      </Route>
    </Routes>
  );
};
