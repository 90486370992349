import {RecordingFormBuilderDetailContainer} from 'features/Dashboard/DashboardPage/ContentRecordingForms/RecordingFormBuilderDetailContainer';

export function DashboardRecordingFormBuilder() {
  return (
    <div className="flex h-screen w-full flex-col bg-white">
      <main className="flex flex-grow overflow-auto">
        <div className="flex w-full  overflow-hidden bg-white">
          {/* <DashboardSidebar isMinimized /> */}
          <div className="min-h-screen w-full bg-gray-100">
            <div className="flex w-full min-w-0 flex-1 flex-col overflow-hidden">
              <div className="relative z-0 flex w-full flex-1 overflow-hidden">
                <RecordingFormBuilderDetailContainer />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
