import {DashParams, routePaths} from 'routes/routesHelper';

import {ArrowDown} from 'react-feather';
import {Button} from 'ui/Button';
import {ErrorBoundary} from '@sentry/react';
import {Link} from 'react-router-dom';
import ReactPlayer from 'vendor/react-player-milk';
import {UserProjectDownloadAsset} from 'pages/PreviewRender/components/SocialCardPreview';
import {format} from 'date-fns';
import {secondToPlayerTime} from '../ContentSearch/utils';

export default function DownloadsListResults({
  userProjectDownloads,
  contentHeight,
  hasNextPage,
  fetchNextPage,
}: {
  fetchNextPage: () => void;
  hasNextPage: boolean;
  contentHeight: number;
  userProjectDownloads: UserProjectDownloadAsset[];
}) {
  return (
    <>
      <ul className="grid w-full grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-3 xl:grid-cols-4">
        {userProjectDownloads
          .filter(downloadItem => !downloadItem.is_error)
          .map((downloadItem, index) => {
            const isLoading = downloadItem.is_loading;
            const isError = downloadItem.is_error;

            return (
              <li key={downloadItem.id} className="group relative">
                <div className="overflow-hidden rounded-lg bg-gray-100 p-3">
                  <div className="aspect-w-4 aspect-h-3">
                    {isError ? (
                      <img
                        className="object-contain object-center"
                        src={`https://fakeimg.pl/400x300?text=Error`}
                        alt={`Download preview`}
                      />
                    ) : isLoading ? (
                      <ErrorBoundary>
                        <ReactPlayer
                          width={`100%`}
                          height={`100%`}
                          playing
                          loop
                          playsinline
                          className="object-contain object-center"
                          url={`https://assets-milk.s3.amazonaws.com/dashboard/processing.mp4`}
                        />
                      </ErrorBoundary>
                    ) : (
                      <img
                        className="object-contain object-center"
                        src={`https://image.mux.com/${downloadItem.mux_playback_id}/thumbnail.png?width=400&height=300&smart_crop=true`}
                        alt={`Download preview`}
                      />
                    )}
                  </div>
                  <div>
                    <Link
                      className="flex items-end opacity-0 group-hover:opacity-100"
                      aria-hidden="true"
                      to={`/${routePaths.dashboard}/${DashParams.DownloadsParam}/${downloadItem.project_slug}/${downloadItem.id}`}
                    >
                      <div className="w-full rounded-md bg-white bg-opacity-75 py-2 px-4 text-center text-xs font-medium text-gray-900 shadow backdrop-blur backdrop-filter">
                        View download
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="mt-4 flex items-center justify-between space-x-8 text-xs font-medium text-gray-900">
                  <h3 className="truncate ">
                    <Link
                      to={`/${routePaths.dashboard}/${DashParams.DownloadsParam}/${downloadItem.project_slug}/${downloadItem.id}`}
                    >
                      <span aria-hidden="true" className="absolute inset-0" />
                      {downloadItem.name || 'Untitled'}
                    </Link>
                  </h3>
                  <p>{secondToPlayerTime(downloadItem.duration_ms / 1000)}</p>
                </div>
                <p className="mt-1 text-xs text-gray-500">
                  Created {format(downloadItem.created_at, 'Pp')}
                </p>
              </li>
            );
          })}
      </ul>
      <div className="flex w-full items-center justify-center bg-white py-8">
        <Button
          size="large"
          onClick={() => {
            fetchNextPage();
          }}
          variant="tertiary"
          leftIcon={ArrowDown}
        >
          Load more
        </Button>
      </div>
    </>
  );
}
