import {AbsoluteBox, Box} from './Box';
import {LayoutItem, twoColumns, UpdatedItem} from './utils';

export const SCENE_LAYOUT_IDS = ['VideoLeft', 'VideoRight', 'VideoTop'] as const;
export type SceneLayoutId = typeof SCENE_LAYOUT_IDS[number];

type SceneLayout = {
  label: string;
  layout: (props: {
    video: LayoutItem;
    caption: LayoutItem;
    container: AbsoluteBox;
    updateItem: (item: 'video' | 'caption', data: Partial<UpdatedItem>) => void;
  }) => void;
};

type SceneLayouts = Record<SceneLayoutId, SceneLayout>;

// Also see JSDoc comments for Box
export const SCENE_LAYOUTS: SceneLayouts = {
  VideoLeft: {
    label: 'Video on the left',
    layout: ({video, container, updateItem}) => {
      const {leftContainer, rightContainer} = twoColumns({container, spacing: 40});

      // e.g. Place the video inside the left-hand column (leftContainer)
      //      and fit and center it inside that column
      const videoBox = new Box(video.dimension, leftContainer).fit().center().getBox();
      updateItem('video', videoBox);

      // e.g. Place the captions inside the right-hand column (rightContainer).
      //      It should take up 60% of the height of rightContainer.
      //      And then center it inside rightContainer.
      const captionBox = new Box({width: '100%', height: '60%'}, rightContainer)
        .center()
        .getBox();
      updateItem('caption', captionBox);
    },
  },
  VideoRight: {
    label: 'Video on the right',
    layout: ({video, container, updateItem}) => {
      const {leftContainer, rightContainer} = twoColumns({container, spacing: 40});

      const captionBox = new Box({width: '100%', height: '60%'}, leftContainer)
        .center()
        .getBox();
      updateItem('caption', captionBox);

      const videoBox = new Box(video.dimension, rightContainer).fit().center().getBox();
      updateItem('video', videoBox);
    },
  },
  VideoTop: {
    label: 'Video on top',
    layout: ({video, container, updateItem}) => {
      const videoContainer = new Box(
        {height: '70%', padding: '0 20px'},
        container,
      ).center();

      const videoBox = new Box(video.dimension, videoContainer)
        .fit()
        .center()
        .moveY(container.dimension.height * -0.05);

      updateItem('video', videoBox.getBox());

      const captionContainer = new Box(
        {height: '10%', width: '100%'},
        container,
      ).placeBelow(videoBox);

      const captionBox = new Box({height: '100%', width: '70%'}, captionContainer)
        .center()
        .getBox();

      updateItem('caption', captionBox);
    },
  },
};
