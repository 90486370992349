import {Captions, Container, Heading, getThumbnail} from './LayoutUtils';
import {TempClip} from './State';

import {UserUploadsType} from 'features/types/userLibrarySlice';
import {fitWithin} from 'features/EditorCanvas/Layout/utils';
import {forwardRef} from 'react';
import {useWatchElementSize} from 'features/Common/useElementSize';
import {
  DesignState,
  DesignStateAudio,
  DesignStateCommon,
  DesignStateVideo,
} from './Constants';

export type FocusedFlowMedia = {
  upload: UserUploadsType;
  clip?: TempClip | null;
};

export type FocusedFlowLayoutProps<Design = DesignState> = {
  title?: string;
  design: Design & DesignStateCommon;
  media?: FocusedFlowMedia;
  onLayout?: () => void;
};

export const FocusedFlowLayout = forwardRef<HTMLDivElement, FocusedFlowLayoutProps>(
  ({design, ...rest}, ref) => {
    if (design.type === 'video') {
      return <VideoLayout {...rest} design={design} ref={ref} />;
    } else {
      return <AudioLayout {...rest} design={design} ref={ref} />;
    }
  },
);

const VideoLayout = forwardRef<HTMLDivElement, FocusedFlowLayoutProps<DesignStateVideo>>(
  ({title, design, media, onLayout}, ref) => {
    const thumbnail = getThumbnail(media);

    const [imageRef, imageSize] = useWatchElementSize();
    const imageBox = fitWithin({
      item: {width: thumbnail.width, height: thumbnail.height},
      container: imageSize,
    });

    return (
      <Container design={design} ref={ref}>
        <Heading title={title} design={design} onChangeLogo={onLayout} />
        {design.style === 'background' && (
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{backgroundImage: `url('${thumbnail.url}')`}}
          />
        )}
        {design.style === 'cover' && (
          <div
            className="-mx-10 flex-1 bg-cover bg-center"
            style={{backgroundImage: `url('${thumbnail.url}')`}}
          />
        )}
        {design.style === 'contain' && (
          <div className="relative flex-1 overflow-hidden" ref={imageRef}>
            <img src={thumbnail.url} className="w-full opacity-0" alt="" />
            <img
              src={thumbnail.url}
              className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform rounded-3xl bg-gray-900 bg-opacity-20"
              alt=""
              style={{...imageBox.dimension}}
              data-layer="video"
            />
          </div>
        )}
        {design.showCaptions && <Captions design={design} />}
      </Container>
    );
  },
);

const AudioLayout = forwardRef<HTMLDivElement, FocusedFlowLayoutProps<DesignStateAudio>>(
  ({title, design, onLayout}, ref) => {
    return (
      <Container design={design} ref={ref}>
        <Heading title={title} design={design} onChangeLogo={onLayout} />
        <div className="min-h-0 flex-1 flex-shrink">
          {design.showCaptions && (
            <Captions
              design={design}
              className="!h-full !text-left !text-[7rem] !line-clamp-3"
              containerClassName="h-full"
            />
          )}
        </div>
        <div
          className="!mt-0 h-40 w-full bg-white bg-opacity-10 bg-cover"
          style={{backgroundImage: 'url(/sample-audio-wave.svg)'}}
          data-layer="audio-waveform"
        />
        {design.secondaryTitle && (
          <div
            className="w-full rounded-3xl px-10 py-8 text-5xl font-semibold leading-normal"
            style={{backgroundColor: design.colorScheme.square}}
            data-layer="secondary-title-background"
          >
            <div className="line-clamp-2" data-layer="secondary-title">
              {design.secondaryTitle}
            </div>
          </div>
        )}
      </Container>
    );
  },
);
