import {useLandingPageQuery} from 'services/landingPageAPI';
import EditableLandingPageField from '../EditableLandingPageField';

export const LandingPageHead = ({landingPageId}: {landingPageId: string}) => {
  const {data: landingPageData, isLoading} = useLandingPageQuery({id: landingPageId});

  if (!landingPageData) {
    return null;
  }

  return (
    <div className="w-full max-w-xl space-y-2">
      <EditableLandingPageField
        landingPageId={landingPageId}
        fieldName={'title'}
        className={
          'text-center text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl'
        }
        fieldValue={landingPageData.title || 'Untitled project'}
      />
      <EditableLandingPageField
        landingPageId={landingPageId}
        fieldName={'description'}
        className={'mx-auto max-w-xl text-center text-xl text-gray-500'}
        fieldValue={landingPageData.description || 'Optional description'}
      />
    </div>
  );
};
