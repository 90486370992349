import { ClickToCopy, recordingFormUrl } from './utils';
import { DashParams, routePaths } from 'routes/routesHelper';
import { Edit, Share } from 'react-feather';
import { FileNav, PositionDirection } from '../ContentUploads/FileNav';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from 'ui/Button';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Loading } from 'features/EditorCanvas/Sidebar/views/Assets/Loading';
import { NOTIFICATION_BASE } from 'features/Notifications/constants';
import NotificationInfo from 'features/Notifications/NotificationInfo';
import { RecordingFormContact } from 'services/recordingContactsAPI';
import RecordingFormNameTextField from './RecordingFormNameTextField';
import { RecordingSubmission } from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import classNames from 'classnames';
import { format } from 'date-fns';
import { store } from 'react-notifications-component';
import useDimensions from 'react-use-dimensions';
import { useRecordingFormsQuery } from 'services/recordingFormsAPI';
import { useWindowHeight } from '@react-hook/window-size';

type RecordingListUsage = 'all-submission-list' | 'selected-recording-form-list';

export const RecordingFormResponseList = ({
  selectedRecordingFormSubmissions,
  recordingFormContacts,
}: {
  recordingFormContacts: RecordingFormContact[];
  selectedRecordingFormSubmissions: RecordingSubmission[];
}) => {
  const [listToolbarRef, { height: listToolbarHeight }] = useDimensions();
  const windowHeight = useWindowHeight();

  const { recordingFormId, recordingSubmissionId } = useParams() as Record<string, string>;

  const submissionLink = (recordingFormSubmissionId: string) => {
    return `/${routePaths.dashboard}/${DashParams.RecordingFormParams}/${recordingFormId}/${recordingFormSubmissionId}`;
  };

  const navigate = useNavigate();

  const checkActiveListItem = useCallback(() => {
    if (
      !recordingSubmissionId &&
      selectedRecordingFormSubmissions &&
      selectedRecordingFormSubmissions[0]
    ) {
      navigate(selectedRecordingFormSubmissions[0].id);
    }
  }, [recordingFormId]);

  useEffect(() => {
    checkActiveListItem();
  }, [checkActiveListItem]);

  const previewUrl = useMemo(
    () => recordingFormUrl(recordingFormId),
    [recordingFormUrl, recordingFormId],
  );

  return (
    <aside
      className="flex w-full flex-col max-w-md w-full"
    // style={{
    //   maxWidth: '19rem',
    //   minWidth: '19rem',
    // }}
    >
      <div ref={listToolbarRef} className="px-3 pt-6 pb-4">
        <ListToolbar />
      </div>

      <nav
        className="min-h-0 flex-1 overflow-y-auto"
        aria-label="Directory"
        style={{
          height: windowHeight - listToolbarHeight,
        }}
      >
        {selectedRecordingFormSubmissions.length > 0 ? (
          <ul role="list" className="relative z-0 divide-y divide-gray-200">
            {selectedRecordingFormSubmissions.map(selectedRecordingFormSubmission => {
              const recordingContact = recordingFormContacts.find(
                contact =>
                  contact.id ===
                  selectedRecordingFormSubmission.user_recording_contact_id,
              );

              const linkUrl = submissionLink(selectedRecordingFormSubmission.id);
              return (
                <li key={selectedRecordingFormSubmission.id}>
                  {linkUrl && (
                    <Link to={linkUrl}>
                      <div
                        className={classNames(
                          'relative flex items-center space-x-3 px-4 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 hover:bg-gray-50',
                          {
                            'bg-gray-50':
                              selectedRecordingFormSubmission.id ===
                              recordingSubmissionId,
                          },
                        )}
                      >
                        <div className="flex-shrink-0">
                          {selectedRecordingFormSubmission.submission_tracks[0] && (
                            <img
                              className="h-10 w-10"
                              src={`https://image.mux.com/${selectedRecordingFormSubmission.submission_tracks[0]
                                .user_upload.mux_playback_id
                                }/thumbnail.png?width=${100}&height=${100}&fit_mode=smartcrop`}
                              alt=""
                            />
                          )}
                        </div>

                        <div className="min-w-0 flex-1">
                          <a href="#" className="focus:outline-none">
                            {/* Extend touch target to entire panel */}
                            <span className="absolute inset-0" aria-hidden="true" />
                            <p className="text-xs font-medium text-gray-900">
                              {recordingContact && `${recordingContact.name}`}
                            </p>
                            <p className="truncate text-xs text-gray-500">
                              Submitted{' '}
                              {format(selectedRecordingFormSubmission.updated_at, 'Pp')}
                            </p>
                          </a>
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <>
            <ListNoSubmissionsState />
          </>
        )}
      </nav>
    </aside>
  );
};

const ListNoSubmissionsState = () => {
  const { recordingFormId } = useParams() as Record<string, string>;
  const previewUrl = useMemo(
    () => recordingFormUrl(recordingFormId),
    [recordingFormUrl, recordingFormId],
  );

  const handleCopyConfirm = () => {
    store.addNotification({
      ...NOTIFICATION_BASE,
      content: (
        <NotificationInfo
          title={'Copied link'}
          message={'You can now share the recording form link.'}
        />
      ),
    });
  };

  return (
    <div className="relative z-0 divide-y divide-gray-200 px-3">
      <div className="rounded-md bg-white p-4">
        <div className="flex flex-col items-start space-y-3">
          <div>
            <h2 className="text-base font-medium text-gray-900">Your link is live!</h2>
            <p className="mt-1 text-xs text-gray-500">
              <a
                className="font-semibold text-indigo-500"
                href={previewUrl}
                target="_blank"
                rel="noreferrer"
              >
                {previewUrl}
              </a>
            </p>
            <p className="mt-1 text-xs text-gray-500">
              Video recording submissions will appear here.
            </p>
          </div>
          <div className="w-full">
            <CopyToClipboard text={previewUrl} onCopy={handleCopyConfirm}>
              <Button
                rightIcon={Share}
                fullWidth
                _className="!w-full !justify-between !flex !text-xs"
                alignText="between"
                variant="tertiary"
              >
                Share link
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ListToolbar = () => {
  const { data: recordingFormsData, isLoading, isSuccess } = useRecordingFormsQuery();

  const { recordingFormId } = useParams() as Record<string, string>;

  const recordingForm = useMemo(() => {
    const recordingForm = recordingFormsData?.find(
      recordingForm => recordingForm.id === recordingFormId,
    );
    return recordingForm;
  }, [recordingFormsData, recordingFormId]);

  const previewUrl = useMemo(
    () => recordingFormUrl(recordingFormId),
    [recordingFormUrl, recordingFormId],
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!recordingFormsData) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-row items-center justify-between">
        <div className="flex w-full truncate">
          <div className="relative z-30">
            {recordingForm && (
              <RecordingFormNameTextField
                recordingForm={recordingForm}
              ></RecordingFormNameTextField>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row space-x-3">
        {/* <div className="h-20 w-20">
          <img
            src="http://fakeimg.pl/70x70/"
            className="overflow-hidden rounded-md"
            alt=""
          />
        </div> */}

        <div className="space-x-2">
          <Link
            to={`/${routePaths.dashboard}/${DashParams.RecordingFormParams}/${recordingFormId}/build`}
          >
            <Button leftIcon={Edit} _className="!text-xs" variant="tertiary" size="small">
              Edit form
            </Button>
          </Link>
          <span className="inline-flex md:hidden">
            <ClickToCopy iconOnly fullWidth={false} url={previewUrl} />
          </span>
          <span className="hidden md:inline-flex">
            <ClickToCopy fullWidth={false} url={previewUrl}>Copy link</ClickToCopy>
          </span>
        </div>
      </div>
    </div>
  );
};
