import {Link, Navigate, Outlet} from 'react-router-dom';
import {ReactNode, useEffect, useState} from 'react';
import {TemplateStep, handleShareClick} from './TemplateStep/TemplateStep';
import {WizardStateProvider, useWizardState} from './State';

import {Button} from 'ui/Button';
import {ClipStep} from './ClipStep/ClipStep';
import {DesignStep} from './DesignStep/DesignStep';
import {DownloadStep} from './DownloadStep/DownloadStep';
import {FOCUSED_FLOW_TOOLBAR_HEIGHT} from './Toolbar';
import {FixedLoadingPage} from 'ui/FixedLoadingPage';
import {FocusedFlowStepper} from './Stepper';
import {GetRootState} from 'configureStore';
import {InviteTeamForm} from 'features/NewOnboarding/InviteTeamForm';
import {LinkTag} from 'features/Fonts/LoadGoogleFonts';
import LogoFull from 'ui/LogoFull.svg';
import {Modal} from 'ui/Modal';
import {PreviewStep} from './PreviewStep/PreviewStep';
import {UnreachableCaseError} from 'features/Common/UnreachableCaseError';
import {UploadStep} from './UploadStep/UploadStep';
import {Users} from 'react-feather';
import {isAnonymousSelector} from 'features/selectors/authSelectors';
import {routePaths} from 'routes/routesHelper';
import {showSuccessNotification} from 'features/Common/utils';
import {steps} from './Constants';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useSelector} from 'react-redux';

const FocusedFlowPage = () => {
  const {step, setStep} = useWizardState();

  let stepElement: ReactNode = null;
  if (step === 'template' || !step) {
    stepElement = <TemplateStep />;
  } else if (step === 'upload') {
    stepElement = <UploadStep />;
  } else if (step === 'clip') {
    stepElement = <ClipStep />;
  } else if (step === 'design') {
    stepElement = <DesignStep />;
  } else if (step === 'preview') {
    stepElement = <PreviewStep />;
  } else if (step === 'download') {
    stepElement = <DownloadStep />;
  } else {
    throw new UnreachableCaseError(step);
  }

  useEffect(() => {
    if (step) {
      window.analytics.track(
        'Pg-Focused-Flow -- FF-Audiogram -- Evt-Clk -- Step Loaded',
        {step: step},
      );
    }
  }, [step]);

  useEffect(() => {
    window.Intercom?.('update', {
      hide_default_launcher: true,
    });

    return () => {
      window.Intercom?.('update', {
        hide_default_launcher: false,
      });
    };
  }, []);

  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const handleInviteCollaboratorsClick = () => {
    setInviteModalOpen(true);
    window.analytics.track(
      'Pg-Focused-Flow -- FF-Audiogram -- Evt-Clk -- Invite Collaborators',
      {step: step},
    );
  };

  const isAnonymous = useSelector(isAnonymousSelector);
  const {isAuthenticated} = useAuthAlt();

  const activeStep = steps.indexOf(step!) > 0 ? steps.indexOf(step!) : 0;

  return (
    <div className="h-screen w-full overflow-y-auto">
      <div className="mx-auto flex min-h-screen w-full max-w-5xl flex-col space-y-6 px-6 pt-4 md:space-y-10 md:pt-8">
        <div className="flex items-center justify-between pb-2">
          {isAuthenticated ? (
            <Link to="/h">
              <img src={LogoFull} alt="Logo" className="h-8 md:h-10" />
            </Link>
          ) : (
            <a
              target="_blank"
              href="https://milkvideo.com/?ref=podcast-audiogram"
              rel="noreferrer"
            >
              <img src={LogoFull} alt="Logo" className="h-8 md:h-10" />
            </a>
          )}
          <div className="flex items-center space-x-2">
            {!isAnonymous && (
              <Button
                leftIcon={Users}
                variant="secondary"
                onClick={handleInviteCollaboratorsClick}
              >
                Invite collaborators
              </Button>
            )}
            <Button variant="inline" onClick={() => window.Intercom?.('showNewMessage')}>
              Contact support
            </Button>
            <Button variant="tertiary" onClick={handleShareClick}>
              Copy link
            </Button>
          </div>
        </div>
        <FocusedFlowStepper
          activeStep={activeStep}
          onClick={index => {
            setStep({step: steps[index]});
          }}
          canGoBack={step !== 'download'}
        />
        {stepElement}
        <div className="flex-shrink-0" style={{height: FOCUSED_FLOW_TOOLBAR_HEIGHT}} />
        <LinkTag href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap" />
      </div>
      <Modal
        size="large"
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        title="Invite collaborators"
      >
        <InviteTeamForm
          onComplete={count => {
            setInviteModalOpen(false);

            if (count === 0) return;

            showSuccessNotification({
              title: 'Success',
              message: `Invitation emails sent to ${count} ${
                count === 1 ? 'user' : 'users'
              }`,
            });
          }}
        />
      </Modal>
    </div>
  );
};

const FocusedFlowPageWrapper = () => {
  const {rehydrated} = useWizardState();
  if (!rehydrated) return <FixedLoadingPage />;

  return <FocusedFlowPage />;
};

export const FocusedFlowWrapWithState = () => {
  const accountExists = useSelector((state: GetRootState) => !!state.auth.accountWrapper);
  const isAnonymous = useSelector(isAnonymousSelector);

  if (!isAnonymous && !accountExists) {
    return (
      <Navigate
        replace
        to={`/${routePaths.user}/join/?redirectTo=${window.location.pathname}`}
      />
    );
  }

  return (
    <WizardStateProvider>
      <Outlet />
    </WizardStateProvider>
  );
};

export {FocusedFlowPageWrapper as FocusedFlowPage};
