import 'react-notifications-component/dist/theme.css';
import 'styles/react-notification-override.css';

import {ReactElement} from 'react';
import ReactNotificationNS from 'react-notifications-component';

// @ts-ignore - See https://github.com/vitejs/vite/issues/2139
export const ReactNotification = ReactNotificationNS.default || ReactNotificationNS;

export const NotificationWrap = ({children}: {children: ReactElement}) => {
  return (
    <>
      <ReactNotification />
      {children}
    </>
  );
};
