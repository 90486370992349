import { ChangeEvent, FC, InputHTMLAttributes } from 'react';
import { Field, FieldMetaState } from 'react-final-form';

import classNames from 'classnames';

type TextInputProps = {
  name: string;
  value: string | undefined;
  label?: string;
  onChange: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  onEnterKey?: () => void;
} & Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'onFocus' | 'onBlur' | 'autoFocus' | 'placeholder' | 'type' | 'autoComplete'
>;

export const TextInput = ({
  name,
  value = '',
  label,
  onChange,
  error,
  type = 'text',
  onEnterKey,
  ...rest
}: TextInputProps) => {
  return (
    <InputLabel label={label}>
      <input
        type={type}
        name={name}
        value={value}
        onChange={event => onChange(event.currentTarget.value, event)}
        onKeyPress={event => {
          if (event.key === 'Enter') onEnterKey?.();
        }}
        className={classNames(
          'block w-full rounded-md border-gray-200 text-sm ring-0 transition focus:border-indigo-500 focus:shadow-sm focus:ring-indigo-500 autofill:shadow-fill-white',
          error && '!border-red-300 focus:!ring-red-300',
        )}
        {...rest}
      />
      {error && <InputError>{error}</InputError>}
    </InputLabel>
  );
};

export const InputError: FC = ({ children }) => (
  <div className="mt-1 text-xs font-medium text-red-500">{children}</div>
);

export const InputLabel: FC<{ label?: string }> = ({ children, label }) => {
  return (
    <label className="block w-full">
      {label && <div className="mb-0.5 text-sm font-medium text-gray-700">{label}</div>}
      {children}
    </label>
  );
};

export const getFormError = (meta: FieldMetaState<unknown>) => {
  if (!meta.touched || !meta.dirty) return;
  return Array.isArray(meta.error) ? meta.error.join(', ') : meta.error;
};

export const FormTextInput = ({
  name,
  type = 'text',
  onChange,
  required,
  validate,
  autoFocus,
  defaultValue,
  ...rest
}: Omit<TextInputProps, 'value' | 'onChange'> & {
  onChange?: (value: string) => void;
  required?: boolean;
  autoFocus?: boolean;
  type?: string;
  defaultValue?: string;
  validate?: (value: string | undefined) => string | undefined;
}) => {
  return (
    <Field
      defaultValue={defaultValue}
      name={name}
      autoFocus={autoFocus}
      validate={value => {
        if (required && !value) {
          return rest.placeholder ? `${rest.placeholder} is required` : 'Required';
        }

        if (validate) {
          return validate(value);
        }
      }}
      render={({ input, meta }) => {
        return (
          <TextInput
            {...input}
            {...rest}
            autoFocus={autoFocus}
            error={getFormError(meta)}
            onChange={(value, event) => {
              input.onChange(event);
              onChange?.(value);
            }}
          />
        );
      }}
    />
  );
};
