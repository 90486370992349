import { ApiAccountCheck, AuthenticationUserCheck } from 'RoutesRoot';
import {
  FocusedFlowPage,
  FocusedFlowWrapWithState,
} from 'features/FocusedFlow/FocusedFlowPage';
import { Route, Routes } from 'react-router-dom';
import { podcastAudiogramRoute, routePaths } from 'routes/routesHelper';

import { AuthProviderWrapper } from 'features/Auth/AuthProviderWrapper';
import { NotificationWrap } from 'pages/AppEntrance/User/NotificationWrap';
import { RoutesCanvasRelated } from 'routes/RoutesCanvas';
import { Suspense } from 'react';

const CatchAllRoute = () => {
  return <div>Replace me: CatchAllRoute</div>;
};

export const RoutesCreate = () => {
  return (
    <Routes>
      <Route index element={<CatchAllRoute />} />

      <Route
        path={`${routePaths.canvas}/*`}
        element={
          <AuthenticationUserCheck>
            <ApiAccountCheck>
              <NotificationWrap>
                <Suspense fallback={<div />}>
                  <RoutesCanvasRelated />
                </Suspense>
              </NotificationWrap>
            </ApiAccountCheck>
          </AuthenticationUserCheck>
        }
      />

      <Route
        path={`${podcastAudiogramRoute}/*`}
        element={
          <Suspense fallback={<div />}>
            <AuthProviderWrapper>
              <NotificationWrap>
                <FocusedFlowWrapWithState />
              </NotificationWrap>
            </AuthProviderWrapper>
          </Suspense>
        }
      >
        <Route path={`:wizardStateId`} element={<FocusedFlowPage />} />
        <Route index element={<FocusedFlowPage />} />
      </Route>

      <Route path="*" element={<CatchAllRoute />} />
    </Routes>
  );
};
