import {TimeSeconds} from 'features/types/userLibrarySlice';
import {convertToMs} from 'features/EditorCanvas/utils';
import queryString from 'query-string';
import {v4 as uuidv4} from 'uuid';

export type IgnoreSecTimeOptions = {
  showHour: boolean;
  showMinutes: boolean;
};

export function isVideoMedia(fileType: string): boolean {
  return fileType.includes('video');
}

export function isImageMedia(fileType: string): boolean {
  return fileType.includes('image');
}

export function isAudioMedia(fileType: string): boolean {
  return fileType.includes('audio');
}

export function isPlayableMedia(fileType: string): boolean {
  return isAudioMedia(fileType) || isVideoMedia(fileType);
}

export function sec2time(
  timeInSeconds: TimeSeconds,
  ignoreOptions: IgnoreSecTimeOptions = {
    showHour: true,
    showMinutes: true,
  },
) {
  const pad = function (num: number, size: number) {
    return `000${num}`.slice(size * -1);
  };
  const time =
    typeof timeInSeconds === 'number'
      ? parseFloat(`${timeInSeconds.toFixed(3)}`)
      : timeInSeconds;
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);
  const milliseconds = Number(`${time}`.slice(-3));

  if (ignoreOptions.showHour) {
    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(
      milliseconds,
      3,
    )}`;
  } else {
    return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
  }
}

export function time2sec(secondsInTime: TimeSeconds) {
  const timeArr = `${secondsInTime}` ? `${secondsInTime}`.split(':') : ['0', '0', '0'];
  const hour = Number(timeArr[0]) * 60 * 60;
  const minute = Number(timeArr[1]) * 60;
  const seconds = Number.parseFloat(timeArr[2]);
  const totalSeconds = seconds + minute + hour;
  return totalSeconds;
}

export function muxThumbnail(
  playbackId: string,
  time = 0,
  width = 600,
  height = 400,
  fit_mode = 'smartcrop',
) {
  return queryString.stringifyUrl({
    url: `https://image.mux.com/${playbackId}/thumbnail.png`,
    query: {width, height, fit_mode, time},
  });
}

export function captionToTextSnippet({
  result,
  startTimeSec: filterStartTime,
  endTimeSec: filterEndTime,
}: {
  result: any;
  startTimeSec: TimeSeconds;
  endTimeSec: TimeSeconds;
}) {
  let displayWords = '';
  let entityRanges = {};
  let currentOffset = 0;
  let filteredWords = [];
  if (
    result.alternatives[0] &&
    result.alternatives[0].words &&
    result.alternatives[0].words.length > 0
  ) {
    filteredWords = result.alternatives[0].words.filter((wordEl: any) => {
      const {startTime, endTime} = wordEl;
      const startNano = startTime.nanos ? startTime.nanos / 1e8 : 0;
      const endNano = endTime.nanos ? endTime.nanos / 1e8 : 0;
      const startTimeSec = parseFloat(startTime.seconds + '.' + startNano);
      const endTimeSec = parseFloat(endTime.seconds + '.' + endNano);

      return (
        parseFloat(`${startTimeSec}`) >= parseFloat(`${filterStartTime}`) &&
        parseFloat(`${endTimeSec}`) <= parseFloat(`${filterEndTime}`)
      );
    });
    // console.log('filteredWords', filteredWords);
    entityRanges = filteredWords.map((wordEl: any) => {
      const key = uuidv4();
      const data = {
        content: wordEl.word,
        startTime: wordEl.startTime,
        endTime: wordEl.endTime,
      };
      const newEntity = {
        offset: currentOffset,
        length: wordEl.word.length,
        key,
        data,
      };
      currentOffset = wordEl.word.length + 1; // 1 for a space
      return newEntity;
    });

    displayWords = result.alternatives[0].words
      .filter((wordEl: any) => {
        const {startTime, endTime} = wordEl;
        const startNano = startTime.nanos ? startTime.nanos / 1e8 : 0;
        const endNano = endTime.nanos ? endTime.nanos / 1e8 : 0;
        const startTimeSec = parseFloat(startTime.seconds + '.' + startNano);
        const endTimeSec = parseFloat(endTime.seconds + '.' + endNano);

        return (
          parseFloat(`${startTimeSec}`) >= parseFloat(`${filterStartTime}`) &&
          parseFloat(`${endTimeSec}`) <= parseFloat(`${filterEndTime}`)
        );
      })
      .map((wordEl: any) => wordEl.word)
      .join(' ');
  }

  return [entityRanges, displayWords];
}

export const secondToTimestamp = (seconds: TimeSeconds) =>
  new Date(convertToMs(seconds)).toISOString().substr(11, 8);
