import { BASE_REDIRECT_URL, BASE_URL, publishableStripeKey } from 'constants/environment';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { Button } from 'ui/Button';
import { PlanInterval } from './constants';
import { authSettingsSelector } from 'features/selectors/authSelectors';
import axios from 'axios';
import classNames from 'classnames';
import { getAuthToken } from 'services/utils';
import { loadStripe } from '@stripe/stripe-js/pure';
import { paymentRoutes } from 'routes/RoutesDashboard';
import { requestHeader } from 'api/helpers';
import { routePaths } from 'routes/routesHelper';
import { useAppWrapStyles } from 'AppWrap';
import { useSelector } from 'react-redux';

const showErrorMessage = function (message: any) {
  const errorEl = document.getElementById('error-message');
  if (errorEl) {
    errorEl.textContent = message;
    errorEl.style.display = 'block';
  }
};

enum PlanFrequeuncy {
  MONTHLY = '/mo',
  YEARLY = '/yr',
}

const pricing = {
  tiers: [
    {
      id: 'starter',
      planName: 'Starter (Metered)',
      variant: 'secondary',
      title: 'Starter',
      subtitle: 'For hobbyists just getting started',
      price: {
        [PlanFrequeuncy.YEARLY]: 24,
        [PlanFrequeuncy.MONTHLY]: 29,
      },
      range: [1, 6],
      steps: 1,
      valueMultiple: 10,
      frequency: '/mo',
      description:
        'Access to all the base templates. Create social content like video clips, audio snippets.',
      features: [
        'Up to 3 users',
        'Magic resize',
        'Automated captions',
        'High engagement storyboards (coming soon)',
        'Support chat',
      ],
      antifeatures: ['Custom templates', 'Brand kit'],
      cta: 'Start free trial',
      mostPopular: false,
      ctaUrl: '',
    },
    {
      id: 'pro',
      planName: 'Professional (Metered)',
      variant: 'primary',
      title: 'Producer',
      subtitle: 'For marketers in businesses',
      price: {
        [PlanFrequeuncy.YEARLY]: 49,
        [PlanFrequeuncy.MONTHLY]: 59,
      },
      range: [5, 180],
      steps: 15,
      valueMultiple: 30,
      frequency: '/mo',
      description:
        'Everything in Starter plan + powerful tools for creating branded content at scale.',
      features: [
        'Custom templates',
        'Brand kit',
        'Bulk variant export',
        'Transcript export',
        'Increased limits on templates',
        'Grows with your team',
        'Support chat',
      ],
      antifeatures: [],
      cta: 'Start free trial',
      mostPopular: true,
      ctaUrl: '',
    },
  ],
};

const ActiveToggle = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: ReactNode;
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="relative w-1/2 whitespace-nowrap rounded-md border-2 border-gray-200 bg-white py-1 text-xs font-medium text-gray-900 shadow-sm focus:z-10 focus:outline-none sm:w-auto sm:px-6"
    >
      {children}
    </button>
  );
};
const InactiveToggle = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: ReactNode;
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="relative w-1/2 whitespace-nowrap rounded-md border-2 border-transparent py-1 text-xs font-medium text-gray-700 focus:z-10 focus:outline-none sm:w-auto sm:px-6"
    >
      {children}
    </button>
  );
};

const ToggleButton = ({
  onClick,
  isActive,
  children,
}: {
  onClick: () => void;
  isActive: boolean;
  children: ReactNode;
}) => {
  return isActive ? (
    <ActiveToggle onClick={onClick}>{children}</ActiveToggle>
  ) : (
    <InactiveToggle onClick={onClick}>{children}</InactiveToggle>
  );
};

const submitPaymentUrl = `${BASE_URL}/api/v2/payments/start_checkout`;

export function PlanSelection() {
  const { setAppBgClass } = useAppWrapStyles();
  useEffect(() => {
    setAppBgClass('bg-gray-50');

    return () => {
      setAppBgClass('');
    };
  }, [setAppBgClass]);

  useEffect(() => {
    window.analytics.track('Opened PlanSelection');
  }, []);
  const [frequency, setFrequency] = useState<PlanFrequeuncy>(PlanFrequeuncy.YEARLY);

  useEffect(() => {
    window.analytics.track('On PlanSelection changed frequency', {
      frequency,
    });
  }, [frequency]);

  const [value, setValue] = useState({
    starter: [2],
    pro: [5],
  });
  const { account, user, processorId, accountUsers } = useSelector(authSettingsSelector);

  const email = user?.email;

  const planInterval =
    PlanFrequeuncy.YEARLY === frequency ? PlanInterval.Yearly : PlanInterval.Monthly;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getPrice = ({
    price,
    multiplyBy,
    valueMultiple,
  }: {
    price: number;
    multiplyBy: number;
    valueMultiple: number;
  }) => {
    return price + multiplyBy * valueMultiple;
  };

  // Handle any errors returned from Checkout
  const handleResult = function (result: any) {
    if (result.error) {
      showErrorMessage(result.error.message);
    }
    setIsSubmitting(false);
  };

  const handleSubmitPlan = (planName: string) => {
    setIsSubmitting(true);
    (async () => {
      const token = await getAuthToken();
      try {
        const response = await axios.post(
          submitPaymentUrl,
          {
            payments: {
              plan: planName,
              plan_interval: planInterval,
              customer_email: email,
              customer_id: processorId,
              url_cancel: `${BASE_REDIRECT_URL}/${routePaths.payments}`,
              url_success: `${BASE_REDIRECT_URL}/${routePaths.payments}/${paymentRoutes.paymentSuccess}`,
            },
          },
          requestHeader(token),
        );

        const sessionReponse = response.data;
        const stripe = await loadStripe(publishableStripeKey);
        stripe &&
          stripe
            .redirectToCheckout({
              sessionId: sessionReponse.session.id,
            })
            .then(handleResult);
      } catch (error) {
        setIsSubmitting(false);
        console.error('error', error);
      }
    })();
  };

  return (
    <div className="mx-auto max-w-3xl bg-gray-50 py-24 px-4 sm:px-6 lg:px-8">
      <h2 className="text-center text-xl font-bold text-gray-900 sm:text-5xl  sm:leading-none sm:tracking-tight lg:text-4xl">
        Try it free for 5 days
      </h2>
      <p className="mt-3 text-center text-base text-gray-500">
        A plan for every size of business.
      </p>
      <div className="sm:align-center sm:flex sm:flex-col">
        <div className="relative mt-6 flex justify-center space-x-2 self-center rounded-lg bg-gray-50 p-0.5 sm:mt-8">
          <ToggleButton
            onClick={() => {
              setFrequency(PlanFrequeuncy.MONTHLY);
            }}
            isActive={frequency === PlanFrequeuncy.MONTHLY}
          >
            Monthly
          </ToggleButton>

          <ToggleButton
            onClick={() => {
              setFrequency(PlanFrequeuncy.YEARLY);
            }}
            isActive={frequency == PlanFrequeuncy.YEARLY}
          >
            Yearly
          </ToggleButton>
        </div>
      </div>
      {/* Tiers */}
      <div className="mt-6 space-y-12 text-left lg:grid lg:grid-cols-2 lg:gap-x-8 lg:space-y-0">
        {pricing.tiers.map(tier => (
          <div
            key={tier.title}
            className={classNames(
              'relative flex flex-col rounded-lg border-2 bg-white p-8 shadow-sm',
              {
                'order-1 border-indigo-500  md:order-2': tier.variant === 'primary',
                'order-2 border-gray-200 md:order-1': tier.variant === 'secondary',
              },
            )}
          >
            <div className="flex-1 ">
              <div className="inline-flex items-center space-x-2">
                <div>
                  {tier.variant === 'secondary' && (
                    <h3 className="rounded-full bg-indigo-50 py-0.5 px-3 text-xs font-semibold text-indigo-700">
                      {tier.title}
                    </h3>
                  )}
                  {tier.variant === 'primary' && (
                    <h3 className="rounded-full bg-indigo-600 py-0.5 px-3 text-xs font-semibold text-white">
                      {tier.title}
                    </h3>
                  )}
                </div>
                <span className="text-xs">{tier.subtitle}</span>
              </div>

              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-extrabold tracking-tight">
                  ${tier.price[frequency]}
                </span>
                <span className="ml-1 text-base">{tier.frequency}</span>
              </p>
              <p className="mt-6 text-sm text-gray-500">{tier.description}</p>

              <ul role="list" className="mt-6 space-y-3 text-xs">
                {tier.features.map(feature => (
                  <li key={feature} className="flex items-center">
                    <CheckIcon
                      className="h-4 w-4 flex-shrink-0 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
                {tier.antifeatures.map(feature => (
                  <li key={feature} className="flex items-center">
                    <XIcon
                      className="h-4 w-4 flex-shrink-0 text-gray-200"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <Button
              _className="mt-6"
              loading={isSubmitting}
              onClick={() => {
                window.analytics.track('On PlanSelection selected submit plan', {
                  planName: tier.planName,
                });
                handleSubmitPlan(tier.planName);
              }}
              variant={tier.variant == 'primary' ? 'primary' : 'tertiary'}
            >
              {tier.cta}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
