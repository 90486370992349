import {ReactNode, createContext, useContext, useReducer} from 'react';

type Action =
  | {
      type: 'update active sidebar menu';
      activeMenu: string;
    }
  | {
      type: 'update upload type menu';
      activeUploadMenu: string;
    };
type Dispatch = (action: Action) => void;

type UploadMenuTab = {
  name: string;
  current: boolean;
};

type State = {
  uploadMenuTabs: UploadMenuTab[];
  activeMenu: string;
  activeUploadMenu: string;
};
type SidebarMenuProviderProps = {children: ReactNode};

const initialSidebarMenu = {
  uploadMenuTabs: [
    {name: 'Videos', current: true},
    {name: 'Audio', current: false},
    {name: 'Images', current: false},
  ],
  activeUploadMenu: 'Videos',
  activeMenu: 'Assets',
} as State;

const SidebarMenuStateContext =
  createContext<{state: State; dispatch: Dispatch} | undefined>(undefined);

function sidebarMenuReducer(state: State, action: Action) {
  switch (action.type) {
    case 'update active sidebar menu': {
      return {...state, activeMenu: action.activeMenu};
    }
    case 'update upload type menu': {
      const newMenuTabs = [...state.uploadMenuTabs].map(item => {
        if (item.name === action.activeUploadMenu) {
          return {
            ...item,
            current: true,
          };
        } else {
          return {
            ...item,
            current: false,
          };
        }
      });

      return {
        ...state,
        activeUploadMenu: action.activeUploadMenu,
        uploadMenuTabs: newMenuTabs,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${JSON.stringify(action)}`);
    }
  }
}

function SidebarMenuProvider({children}: SidebarMenuProviderProps) {
  const [state, dispatch] = useReducer(sidebarMenuReducer, initialSidebarMenu);

  const value = {state, dispatch};

  return (
    <SidebarMenuStateContext.Provider value={value}>
      {children}
    </SidebarMenuStateContext.Provider>
  );
}

function useSidebarMenu() {
  const context = useContext(SidebarMenuStateContext);
  if (context === undefined) {
    throw new Error('useSidebarMenu must be used within a SidebarMenuProvider');
  }
  return context;
}

export {SidebarMenuProvider, useSidebarMenu};
