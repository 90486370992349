import {Outlet, useParams} from 'react-router-dom';

import {Loading} from 'features/EditorCanvas/Sidebar/views/Assets/Loading';
import {RecordingFormResponseList} from './RecordingFormResponseList';
import {useMemo} from 'react';
import {useRecordingFormContactsQuery} from 'services/recordingContactsAPI';
import {useRecordingFormSubmissionsQuery} from 'services/recordingSubmissionsAPI';
import {useWindowHeight} from '@react-hook/window-size';

export const RecordingContactContent = () => {
  const {recordingContactId} = useParams() as Record<string, string>;

  const {
    data: recordingFormContacts,
    isLoading: isLoadingContacts,
    isSuccess: isSuccessContacts,
  } = useRecordingFormContactsQuery();

  const {
    data: recordingFormSubmissions,
    isLoading: isLoadingSubmissions,
    isSuccess: isSuccessSubmissions,
  } = useRecordingFormSubmissionsQuery();

  // console.log('recordingFormContacts', recordingFormContacts);
  // console.log('recordingFormSubmissions', recordingFormSubmissions);

  const selectedRecordingFormSubmissions = useMemo(() => {
    if (recordingFormSubmissions) {
      return recordingFormSubmissions.filter(recordingFormSubmission => {
        return recordingFormSubmission.user_recording_contact_id === recordingContactId;
      });
    } else {
      return [];
    }
  }, [recordingContactId, recordingFormSubmissions]);

  const windowHeight = useWindowHeight();

  if (isLoadingContacts || isLoadingSubmissions) {
    return <Loading />;
  }

  if (!recordingFormContacts || !recordingFormSubmissions) {
    return <div>Error</div>;
  }

  return (
    <>
      <div
        className="flex w-full min-w-0 flex-1 flex-col overflow-hidden"
        style={{
          height: windowHeight,
        }}
      >
        <div className="relative z-0 flex w-full flex-1 overflow-hidden">
          <RecordingFormResponseList
            recordingFormContacts={recordingFormContacts}
            selectedRecordingFormSubmissions={selectedRecordingFormSubmissions}
          />
          <Outlet />
        </div>
      </div>
    </>
  );
};
