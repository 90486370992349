/**
 * Take in FFT array and find the FFT frame correlated to the time
 *
 * @param fft
 * @param time
 * @param fps
 * @returns
 */
export const fftFrameFinder = (fft: number[][], time: number, fps: number): number[] => {
  const targetFrame = Math.round(time * fps);

  return fft[targetFrame];
};

/**
 * Takes in a fixed bit array and expands it through interpolations
 * @param fftFrame
 * @returns
 */
export const expandFftFrame = (fftFrame: number[]): number[] => {
  /**
   * Catch and return empty array during transition points where frame isn't set.
   */
  if (!fftFrame || fftFrame.length === 0) {
    return [];
  }

  const duplicatedFftFrame = fftFrame.map((value, index) =>
    interpolateFftExpand(
      value,
      fftFrame[index > 0 ? index - 1 : 0],
      fftFrame[index < fftFrame.length - 1 ? index + 1 : fftFrame.length - 1],
    ),
  );
  return duplicatedFftFrame.flat();
};

/**
 * Process a rudimentary interpolation on the FFT array
 * @param value
 * @param valueBefore
 * @param valueAfter
 * @returns
 */
export const interpolateFftExpand = (
  value: number,
  valueBefore: number,
  valueAfter: number,
): number[] => {
  const diffBefore = value - valueBefore;
  const diffAfter = valueAfter - value;

  return [
    // value - diffBefore * 0.7,
    value - diffBefore * 0.4,
    value,
    value + diffAfter * 0.3,
    // value + diffAfter * 0.6,
  ].map(v => Math.sqrt(v) * (Math.sqrt(v) / 2)); // Reduce the jumpiness
};
