import {trimItem} from 'features/canvasItemsSlice';
import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {SelectedItem} from 'features/selectors/canvasItemsSelectors';
import {Scissors} from 'react-feather';
import {useDispatch} from 'react-redux';
import {CanvasButton} from '../../CanvasButton';

export const TrimButton = ({selectedItem}: {selectedItem: SelectedItem}) => {
  const dispatch = useDispatch();
  const projectId = useProjectId();

  const {item, id} = selectedItem;

  if (item.viewType !== ViewTypes.Video && item.viewType !== ViewTypes.VideoClip) {
    return null;
  }

  return (
    <CanvasButton
      leftIcon={Scissors}
      onClick={() => dispatch(trimItem({projectId, itemId: id}))}
    >
      Trim video
    </CanvasButton>
  );
};
