import {isAudioMedia, isImageMedia, isVideoMedia} from '../DashboardUploadDetails/utils';

export enum UserUploadsTypes {
  Image = 'image',
  OctetStream = 'octet-stream',
  Vtt = 'text/vtt',
  Video = 'video',
  VideoMp4 = 'video/mp4',
  VideoMov = 'video/mov',
  VideoMkv = 'video/mkv',
  Audio = 'audio',
}

export default function UploadIcon({fileType}: {fileType: UserUploadsTypes | string}) {
  if (!fileType) {
    return null;
  }
  if (isImageMedia(fileType)) {
    return (
      <svg
        className="fill-current"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.25 10.5C14.695 10.5 15.13 10.368 15.5 10.1208C15.87 9.87357 16.1584 9.52217 16.3287 9.11104C16.499 8.69991 16.5436 8.24751 16.4568 7.81105C16.3699 7.37459 16.1557 6.97368 15.841 6.65901C15.5263 6.34434 15.1254 6.13005 14.689 6.04323C14.2525 5.95642 13.8001 6.00097 13.389 6.17127C12.9778 6.34157 12.6264 6.62996 12.3792 6.99997C12.132 7.36998 12 7.80499 12 8.25C12 8.84674 12.2371 9.41904 12.659 9.84099C13.081 10.2629 13.6533 10.5 14.25 10.5V10.5ZM14.25 7.5C14.3983 7.5 14.5433 7.54399 14.6667 7.6264C14.79 7.70881 14.8861 7.82594 14.9429 7.96299C14.9997 8.10003 15.0145 8.25083 14.9856 8.39632C14.9566 8.5418 14.8852 8.67544 14.7803 8.78033C14.6754 8.88522 14.5418 8.95665 14.3963 8.98559C14.2508 9.01453 14.1 8.99968 13.963 8.94291C13.8259 8.88615 13.7088 8.79002 13.6264 8.66668C13.544 8.54334 13.5 8.39834 13.5 8.25C13.5 8.05109 13.579 7.86032 13.7197 7.71967C13.8603 7.57902 14.0511 7.5 14.25 7.5Z" />
        <path d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3V3ZM19.5 19.5H4.5V15L8.25 11.25L12.4425 15.4425C12.7235 15.7219 13.1037 15.8787 13.5 15.8787C13.8963 15.8787 14.2765 15.7219 14.5575 15.4425L15.75 14.25L19.5 18V19.5ZM19.5 15.8775L16.8075 13.185C16.5265 12.9056 16.1463 12.7488 15.75 12.7488C15.3537 12.7488 14.9735 12.9056 14.6925 13.185L13.5 14.3775L9.3075 10.185C9.02646 9.90562 8.64628 9.74881 8.25 9.74881C7.85372 9.74881 7.47354 9.90562 7.1925 10.185L4.5 12.8775V4.5H19.5V15.8775Z" />
      </svg>
    );
  }
  if (
    fileType.includes(UserUploadsTypes.OctetStream) ||
    fileType.includes(UserUploadsTypes.Vtt)
  ) {
    return (
      <svg
        className="fill-current"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.25 15.75H7.5C7.10218 15.75 6.72064 15.592 6.43934 15.3107C6.15804 15.0294 6 14.6478 6 14.25V9.75C6 9.35218 6.15804 8.97064 6.43934 8.68934C6.72064 8.40804 7.10218 8.25 7.5 8.25H11.25V9.75H7.5V14.25H11.25V15.75Z" />
        <path d="M18.75 15.75H15C14.6022 15.75 14.2206 15.592 13.9393 15.3107C13.658 15.0294 13.5 14.6478 13.5 14.25V9.75C13.5 9.35218 13.658 8.97064 13.9393 8.68934C14.2206 8.40804 14.6022 8.25 15 8.25H18.75V9.75H15V14.25H18.75V15.75Z" />
        <path d="M21 19.5H3C2.60218 19.5 2.22064 19.342 1.93934 19.0607C1.65804 18.7794 1.5 18.3978 1.5 18V6C1.5 5.60218 1.65804 5.22064 1.93934 4.93934C2.22064 4.65804 2.60218 4.5 3 4.5H21C21.3978 4.5 21.7794 4.65804 22.0607 4.93934C22.342 5.22064 22.5 5.60218 22.5 6V18C22.5 18.3978 22.342 18.7794 22.0607 19.0607C21.7794 19.342 21.3978 19.5 21 19.5ZM3 6V18H21V6H3Z" />
      </svg>
    );
  }
  if (isVideoMedia(fileType)) {
    return (
      <svg
        className="fill-current"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.75 19.5H3C2.60218 19.5 2.22064 19.342 1.93934 19.0607C1.65804 18.7794 1.5 18.3978 1.5 18V6C1.5 5.60218 1.65804 5.22064 1.93934 4.93934C2.22064 4.65804 2.60218 4.5 3 4.5H15.75C16.1478 4.5 16.5294 4.65804 16.8107 4.93934C17.092 5.22064 17.25 5.60218 17.25 6V9.045L21.315 6.1425C21.4269 6.0628 21.5586 6.01538 21.6957 6.00543C21.8327 5.99547 21.9699 6.02336 22.0922 6.08605C22.2144 6.14874 22.3171 6.24382 22.3891 6.3609C22.461 6.47799 22.4994 6.61259 22.5 6.75V17.25C22.4994 17.3874 22.461 17.522 22.3891 17.6391C22.3171 17.7562 22.2144 17.8513 22.0922 17.914C21.9699 17.9766 21.8327 18.0045 21.6957 17.9946C21.5586 17.9846 21.4269 17.9372 21.315 17.8575L17.25 14.955V18C17.25 18.3978 17.092 18.7794 16.8107 19.0607C16.5294 19.342 16.1478 19.5 15.75 19.5ZM3 6V18H15.75V13.5C15.7506 13.3626 15.789 13.228 15.8609 13.1109C15.9329 12.9938 16.0356 12.8987 16.1578 12.836C16.2801 12.7734 16.4173 12.7455 16.5543 12.7554C16.6914 12.7654 16.8231 12.8128 16.935 12.8925L21 15.795V8.205L16.935 11.1075C16.8231 11.1872 16.6914 11.2346 16.5543 11.2446C16.4173 11.2545 16.2801 11.2266 16.1578 11.164C16.0356 11.1013 15.9329 11.0062 15.8609 10.8891C15.789 10.772 15.7506 10.6374 15.75 10.5V6H3Z" />
      </svg>
    );
  }
  if (isAudioMedia(fileType)) {
    return (
      <svg
        className="fill-current"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.25 10.5V12.75C17.25 14.1424 16.6969 15.4777 15.7123 16.4623C14.7277 17.4469 13.3924 18 12 18C10.6076 18 9.27226 17.4469 8.28769 16.4623C7.30312 15.4777 6.75 14.1424 6.75 12.75V10.5H5.25V12.75C5.25079 14.4098 5.86309 16.0111 6.96991 17.248C8.07674 18.4849 9.60048 19.2706 11.25 19.455V21H8.25V22.5H15.75V21H12.75V19.455C14.3995 19.2706 15.9233 18.4849 17.0301 17.248C18.1369 16.0111 18.7492 14.4098 18.75 12.75V10.5H17.25Z" />
        <path d="M12 16.5C12.9946 16.5 13.9484 16.1049 14.6517 15.4017C15.3549 14.6984 15.75 13.7446 15.75 12.75V5.25C15.75 4.25544 15.3549 3.30161 14.6517 2.59835C13.9484 1.89509 12.9946 1.5 12 1.5C11.0054 1.5 10.0516 1.89509 9.34835 2.59835C8.64509 3.30161 8.25 4.25544 8.25 5.25V12.75C8.25 13.7446 8.64509 14.6984 9.34835 15.4017C10.0516 16.1049 11.0054 16.5 12 16.5ZM9.75 5.25C9.75 4.65326 9.98705 4.08097 10.409 3.65901C10.831 3.23705 11.4033 3 12 3C12.5967 3 13.169 3.23705 13.591 3.65901C14.0129 4.08097 14.25 4.65326 14.25 5.25V12.75C14.25 13.3467 14.0129 13.919 13.591 14.341C13.169 14.7629 12.5967 15 12 15C11.4033 15 10.831 14.7629 10.409 14.341C9.98705 13.919 9.75 13.3467 9.75 12.75V5.25Z" />
      </svg>
    );
  }
  return null;
}
