import {
  LanguageId,
  useLanguage,
} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {
  fetchFileUploadTranscript,
  getFileUploadTranscriptSuccess,
} from 'features/userLibrarySlice';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import {BaseTranscriptWord} from 'features/types/userLibrarySlice';
import {GetRootState} from 'configureStore';
import {ItemWithSource} from 'features/types/canvasItemsSlice';
import {ensureTranscriptId} from 'features/Captions/CaptionItems';
import {getAuthToken} from 'services/utils';
import {getFileUploadTranscript} from 'api/userLibraryAPI';
import {isEnvProd} from 'constants/environment';

export type TranscriptIdAndLanguage = ReturnType<typeof useTranscriptIdAndLanguage>;

export const useTranscriptIdAndLanguage = ({
  projectId,
  itemId,
  item,
}: {
  projectId: string;
  itemId: string;
  item: ItemWithSource;
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!item.transcriptId || !item.language) {
      getAuthToken()
        .then(token => {
          dispatch(ensureTranscriptId(token, itemId, projectId));
        })
        .catch(console.error);
    }
  }, [dispatch, item.language, item.transcriptId, itemId, projectId]);

  return {transcriptId: item.transcriptId, language: item.language ?? 'en'};
};

export type TranscriptWord = BaseTranscriptWord & {
  paragraph: number;
  index: number;
};

export type TranscriptParagraph = TranscriptWord[];

export type TranscriptJSON = TranscriptParagraph[];

export const useTranscript = (userUploadId: string | undefined, language: LanguageId) => {
  const [loading, setLoading] = useState(false);

  const transcript = useSelector((state: GetRootState) => {
    if (!userUploadId) return;
    return state.userLibrary.transcripts[userUploadId]?.[language];
  });

  if (transcript?.error) {
    console.error(transcript.error);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (!userUploadId) return;
    if (transcript) return;

    setLoading(true);

    getAuthToken()
      .then(token => {
        dispatch(fetchFileUploadTranscript(token, userUploadId, language));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [userUploadId, transcript, dispatch, language]);

  if (!transcript) return {loading};
  if (!transcript.transcript) return {loading};

  const mappedTranscript = transcript.transcript.map(
    (paragraph: TranscriptWord[], pIndex: number) => {
      return paragraph.map((word: TranscriptWord, index: number) => ({
        ...word,
        paragraph: pIndex,
        index,
      }));
    },
  );

  if (!transcript.hasFullResponse) return {loading};

  return {
    transcript: mappedTranscript,
    hasFullResponse: transcript.hasFullResponse,
    loading,
  };
};

const POLL_INTERVAL_MS = isEnvProd ? 2000 : 15000;

export const usePollTranscript = ({
  transcriptId,
  run,
}: {
  transcriptId: string | undefined;
  run: boolean;
}) => {
  const dispatch = useDispatch();
  const {language} = useLanguage();

  useEffect(() => {
    if (!transcriptId) return;
    if (!run) return;

    const id = setInterval(async () => {
      const token = await getAuthToken();
      const response = await getFileUploadTranscript(token, transcriptId, language);

      dispatch(
        getFileUploadTranscriptSuccess({
          userUploadId: transcriptId,
          language,
          ...response,
        }),
      );
    }, POLL_INTERVAL_MS);

    return () => clearInterval(id);
  }, [dispatch, run, transcriptId, language]);
};
