import {DashParams, routePaths} from 'routes/routesHelper';
import {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {UserUpload, userUploadAPI} from 'services/userUploadAPI';
import {
  isImageMedia,
  isVideoMedia,
} from 'features/Dashboard/DashboardUploadDetails/utils';

import {ArrowDown} from 'react-feather';
import {Button} from 'ui/Button';
import {DashboardSidebarContainer} from 'features/Dashboard/DashboardWrapWithSidebar';
import {Divider} from 'features/EditorCanvas/Sidebar/SidebarMenu';
import ErrorPage from 'features/EditorCanvas/components/shared/ErrorPage';
import {FileNav} from './FileNav';
import {Link} from 'react-router-dom';
import {LoadingSpinnerInline} from 'features/EditorCanvas/components/shared/LoadingSpinnerInline';
import LoadingUploadsList from './LoadingUploadsList';
import UploadIcon from 'features/Dashboard/shared/UploadIcon';
import cn from 'classnames';
import {formatDistance} from 'date-fns';
import useDimensions from 'react-use-dimensions';
import {useFileNavActiveUpload} from './FileNavActiveUploadProvider';
import {useInfiniteQuery} from 'services/useInfiniteQuery';
import {useVirtual} from 'react-virtual';

const ROW_HEIGHT = 57;

interface UploadsListContentParams {
  uploadTableHeight: number;
}

const UploadsListContentWithoutWrap = ({uploadTableHeight}: UploadsListContentParams) => {
  const {dashboardSidebarHeight} = DashboardSidebarContainer.useContainer();

  const {
    hasNextPage,
    isFetching,
    fetchNextPage,
    resultPages: pages,
    isLoading,
    isSuccess,
    isError,
  } = useInfiniteQuery(userUploadAPI.endpoints.listUserUploads, {
    getNextPageParam: lastPage => lastPage.next ?? undefined,
  });

  const flatResults = useMemo(() => {
    return pages
      ? pages
          .map(page => page.data)
          .flat()
          .filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)
          .sort((a, b) => {
            return b.updated_at - a.updated_at;
          })
      : [];
  }, [pages]);

  const [headCol1Ref, {width: col1Width}] = useDimensions();
  const [headCol2Ref, {width: col2Width}] = useDimensions();
  const parentRef = useRef<HTMLDivElement>(null);

  const [tableHeaderRef, {height: tableHeaderHeight}] = useDimensions();

  const derivedTableHeight = useMemo(() => {
    return uploadTableHeight - tableHeaderHeight - dashboardSidebarHeight;
  }, [dashboardSidebarHeight, tableHeaderHeight, uploadTableHeight]);

  return (
    <div
      style={{height: `${uploadTableHeight}px`}}
      className="relative table h-full min-w-full"
    >
      <div ref={tableHeaderRef} className="border-t border-b border-gray-200 ">
        <div className="mx-auto grid max-w-7xl grid-cols-2">
          <div
            ref={headCol1Ref}
            className="col-span-1 py-2 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
          >
            Name
          </div>
          <div
            ref={headCol2Ref}
            className="col-span-1 px-3 py-2 text-left text-xs font-semibold text-gray-900"
          >
            Title
          </div>
        </div>
      </div>
      {/* TODO (Lenny) */}
      {isLoading && (
        <div
          style={{
            height: `${derivedTableHeight}px`,
          }}
          className="overflow-auto"
        >
          <LoadingUploadsList />
        </div>
      )}
      {isError && <ErrorPage />}
      {isSuccess && (
        <div
          ref={parentRef}
          style={{
            height: `${derivedTableHeight}px`,
          }}
          className={cn('flex w-full overflow-auto')}
        >
          <div className="relative mx-auto flex w-full max-w-7xl flex-col divide-y divide-gray-200">
            {flatResults.map(userUpload => {
              return (
                <UploadsListContentRow
                  key={userUpload.id}
                  userUpload={userUpload}
                  col1Width={col1Width}
                  col2Width={col2Width}
                />
              );
            })}
            {hasNextPage && isFetching && (
              <div className="flex flex-col items-center justify-center space-y-1 py-2">
                <div className="w-full px-4">
                  <div className="h-10 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
                </div>
                <Divider size="small" color="bg-gray-100" />
                <div className="w-full px-4">
                  <div className="h-10 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
                </div>
                <Divider size="small" color="bg-gray-100" />
                <div className="w-full px-4">
                  <div className="h-10 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
                </div>
                <Divider size="small" color="bg-gray-100" />
                <LoadingSpinnerInline />
              </div>
            )}
            {hasNextPage && !isFetching && (
              <div className="flex h-32 w-full flex-col items-center justify-center py-12">
                <Button
                  size="large"
                  onClick={() => {
                    fetchNextPage();
                  }}
                  variant="tertiary"
                  leftIcon={ArrowDown}
                >
                  Load more
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const UploadsListContentRow = ({
  userUpload,
  col1Width,
  col2Width,
}: {
  userUpload: UserUpload;
  col1Width: number;
  col2Width: number;
}) => {
  const {state} = useFileNavActiveUpload();
  const activeFileNavUploadId = state.userUploadId;

  // console.log('---');
  // console.log('---');
  // console.log('activeFileNavUploadId', activeFileNavUploadId);

  return (
    <div
      data-upload={userUpload.id}
      className={cn('table-row transition hover:bg-gray-50')}
      style={{
        width: '100%',
        height: `${ROW_HEIGHT}px`,
        zIndex: activeFileNavUploadId === userUpload.id ? 100 : 0,
      }}
    >
      <Link
        onClick={evt => {
          if (activeFileNavUploadId) {
            // console.log('activeFileNavUploadId', activeFileNavUploadId);
            evt.preventDefault();
            evt.stopPropagation();
          }
        }}
        style={{
          maxWidth: `${col1Width}px`,
          width: `${col1Width}px`,
          height: `${ROW_HEIGHT}px`,
        }}
        className="table-cell whitespace-nowrap py-0 pl-4 pr-3 text-sm sm:pl-6"
        to={`/${routePaths.dashboard}/${DashParams.UploadsParam}/${userUpload.id}`}
      >
        <div className="flex items-center" style={{height: `${ROW_HEIGHT}px`}}>
          <div className="h-10 w-10 flex-shrink-0">
            {isVideoMedia(userUpload.file_type) &&
              (userUpload.mux_playback_id ? (
                <img
                  className="h-10 w-10"
                  src={`https://image.mux.com/${userUpload.mux_playback_id}/thumbnail.png?width=100&height=100&fit_mode=smartcrop`}
                  alt=""
                />
              ) : (
                <div className="flex h-10 w-10 items-center justify-center">
                  <UploadIcon fileType={userUpload.file_type} />
                </div>
              ))}
            {isImageMedia(userUpload.file_type) && (
              <div className="flex h-10 w-10 items-center justify-center">
                <img src={userUpload.upload_url} />
              </div>
            )}
            {!isVideoMedia(userUpload.file_type) && !isImageMedia(userUpload.file_type) && (
              <div className="flex h-10 w-10 items-center justify-center">
                <UploadIcon fileType={userUpload.file_type} />
              </div>
            )}
          </div>
          <div className="ml-4 flex h-10 items-center overflow-x-scroll">
            <div className="font-medium text-gray-900">{userUpload.file_name}</div>
          </div>
        </div>
      </Link>
      <div
        style={{maxWidth: `${col2Width}px`, height: `${ROW_HEIGHT}px`}}
        className="table-cell whitespace-nowrap px-3 py-0 align-middle text-sm text-gray-500"
      >
        <div
          className="relative flex w-full flex-row items-center justify-between"
          style={{width: `${col2Width - 50}px`}}
        >
          <div
            className="flex items-center text-gray-900"
            style={{height: `${ROW_HEIGHT}px`}}
          >
            Updated{' '}
            {formatDistance(new Date(userUpload.updated_at), new Date(), {
              addSuffix: true,
            })}
          </div>
          {/* <FileNav userUploadId={userUpload.id} /> */}
        </div>
      </div>
    </div>
  );
};

interface UploadsListContentProps extends UploadsListContentParams {}

export const UploadsListContent = ({uploadTableHeight}: UploadsListContentProps) => {
  return <UploadsListContentWithoutWrap uploadTableHeight={uploadTableHeight} />;
};
