import {DashParams, routePaths} from 'routes/routesHelper';
import {Link, useNavigate} from 'react-router-dom';
import {Link as LinkIcon, Share} from 'react-feather';

import {BASE_REDIRECT_URL} from 'constants/environment';
import {Button} from 'ui/Button';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import NotificationInfo from 'features/Notifications/NotificationInfo';
import {TOPBAR_HEIGHT} from './RecordingFormBuilderDetailContainer';
import {recordingFormUrl} from './utils';
import {showSuccessNotification} from 'features/Common/utils';
import {store} from 'react-notifications-component';
import {useMemo} from 'react';
import {useRecordingFormQuery} from 'services/recordingFormsAPI';

export const RecordingTopBar = ({recordingFormId}: {recordingFormId: string}) => {
  const handlePreview = () => {};
  const {data: recordingPageData, isLoading} = useRecordingFormQuery({
    id: recordingFormId,
  });

  const title = recordingPageData?.title;
  const previewUrl = useMemo(() => {
    if (!recordingPageData) {
      return '';
    }
    return recordingFormUrl(recordingPageData.id);
  }, [recordingPageData]);
  const navigate = useNavigate();

  const handleCopyConfirm = () => {
    showSuccessNotification({
      title: 'Copied link',
      message: 'You can now share the recording form link.',
    });
  };

  return (
    <div
      className="grid grid-cols-2 space-x-2"
      style={{
        minHeight: TOPBAR_HEIGHT,
      }}
    >
      <div className="col-span-1 flex items-center justify-start px-2 pl-2 md:pl-4">
        <span className="inline-flex rounded-md shadow-sm">
          <Link to={`/${routePaths.dashboard}/${DashParams.RecordingFormParams}`}>
            <button className="focus:ring-blue mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3333 7.33335H5.21996L8.94663 3.60669L7.99996 2.66669L2.66663 8.00002L7.99996 13.3334L8.93996 12.3934L5.21996 8.66669H13.3333V7.33335Z"
                  fill="black"
                />
              </svg>
            </button>
          </Link>
        </span>
        <p className="ml-3 text-sm">{title}</p>
      </div>

      <div className="col-span-1 flex w-full items-center justify-end space-x-1 px-6">
        <a href={previewUrl} target="_blank" rel="noreferrer">
          <Button
            _className="!text-xs"
            size="small"
            variant="tertiary"
            onClick={handlePreview}
          >
            Preview
          </Button>
        </a>
        <CopyToClipboard text={previewUrl} onCopy={handleCopyConfirm}>
          <Button
            variant="inline"
            onClick={handlePreview}
            iconOnly
            leftIcon={LinkIcon}
            size="small"
          ></Button>
        </CopyToClipboard>
        <CopyToClipboard text={previewUrl} onCopy={handleCopyConfirm}>
          <Button
            _className="!text-xs"
            variant="primary"
            size="small"
            onClick={handlePreview}
            leftIcon={Share}
          >
            Share form
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};
