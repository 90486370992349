import {ReactNode, createContext, useContext, useReducer} from 'react';

type Action = {
  type: 'update menuTargetScene';
  menuTargetScene: null | string;
};
type Dispatch = (action: Action) => void;

type State = {
  menuTargetScene: null | string;
};
type CanvasScenesProviderProps = {children: ReactNode};

const initialCanvasScenes = {
  menuTargetScene: null,
} as State;

const CanvasScenesStateContext =
  createContext<{state: State; dispatch: Dispatch} | undefined>(undefined);

function canvasScenesReducer(state: State, action: Action) {
  switch (action.type) {
    case 'update menuTargetScene': {
      return {...state, menuTargetScene: action.menuTargetScene};
    }
    default: {
      throw new Error(`Unhandled action type: ${JSON.stringify(action)}`);
    }
  }
}

function CanvasScenesProvider({children}: CanvasScenesProviderProps) {
  const [state, dispatch] = useReducer(canvasScenesReducer, initialCanvasScenes);

  const value = {state, dispatch};

  return (
    <CanvasScenesStateContext.Provider value={value}>
      {children}
    </CanvasScenesStateContext.Provider>
  );
}

function useCanvasScenes() {
  const context = useContext(CanvasScenesStateContext);
  if (context === undefined) {
    throw new Error('useCanvasScenes must be used within a CanvasScenesProvider');
  }
  return context;
}

export {CanvasScenesProvider, useCanvasScenes};
