import {CSSProperties, useEffect, useState} from 'react';

import {selectProjectCanvasItem} from 'features/selectors/canvasItemsSelectors';
import {useCurrentTime} from './utils';
import {usePlayback} from './usePlayback';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useScenes} from './useScenes';
import {useSelector} from 'react-redux';

export const ITEM_HIDDEN_OPACITY_IS_PLAYING = 0;
export const ITEM_HIDDEN_OPACITY_IS_PAUSED = 0;
export const ITEM_VISIBLE_OPACITY = 1;

export function useSceneTiming(id: string): {sceneTimingStyle: CSSProperties} {
  const [isVisible, setIsVisible] = useState(true);
  const currentTime = useCurrentTime();

  const projectId = useProjectId();

  const item = useSelector(state =>
    selectProjectCanvasItem(state, {projectId, itemId: id}),
  );

  const {getItemScene} = useScenes();

  const {isPlaying} = usePlayback();
  const itemScene = getItemScene(item!);

  useEffect(() => {
    if (item?.sceneTiming && itemScene) {
      const itemVisibilityStartTime = itemScene.startTimeMs + item.sceneTiming.offsetMs;
      const itemVisibilityEndTime =
        itemScene.startTimeMs + item.sceneTiming.offsetMs + item.sceneTiming.durationMs;
      setIsVisible(
        currentTime >= itemVisibilityStartTime && currentTime < itemVisibilityEndTime,
      );
    } else {
      setIsVisible(true);
    }
  });

  // when playing, hide items that should not be showing due to scene timing
  // when not playing, show them with reduced opacity
  const hiddenItemOpacity = isPlaying
    ? ITEM_HIDDEN_OPACITY_IS_PLAYING
    : ITEM_HIDDEN_OPACITY_IS_PAUSED;
  const opacity = isVisible ? ITEM_VISIBLE_OPACITY : hiddenItemOpacity;

  return {
    sceneTimingStyle: {
      opacity,
    },
  };
}
