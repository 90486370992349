import {BASE_REDIRECT_URL} from 'constants/environment';
import {useEffect} from 'react';

import {Outlet, useParams} from 'react-router-dom';

export const SocialCardPreviewPageWrap = ({
  ignoreRedirect,
}: {
  ignoreRedirect?: boolean;
}) => {
  const {contentId} = useParams() as Record<string, string>;

  useEffect(() => {
    if (!contentId && !ignoreRedirect) {
      window.location.href = BASE_REDIRECT_URL;
    }
  }, [contentId]);

  return <Outlet />;
};
