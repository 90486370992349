import {ClipMediaType} from 'api/clipsAPI';
import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {SearchTranscriptWordInstances} from './authSlice';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import {UserLibraryShareProjectType} from 'features/userLibrarySlice';
import {UserPaymentPreferences} from 'features/Payments/constants';
import {UserUploadFolder} from 'services/userUploadFolderAPI';
import {UserUploadsTypes} from 'features/Dashboard/shared/UploadIcon';

export type TimeSeconds = number;

export type TimeMilliSeconds = number;

export type MuxPlaybackIdType = string;

export type UserPreferences = {
  [UserPaymentPreferences.ViewedPaymentModal]?: boolean;
};

export type UserLibraryUser = {
  fullName: null | string;
  photoUrl: null | string;
};

export type ClipTranscriptResponseType = {
  created_at: number;
  duration: number | null;
  edited_words: any | null;
  has_full_response: boolean;
  has_preview: boolean;
  id: number;
  job_status: string;
  text_body: string;
  transcript_id: string;
  updated_at: number;
  user_upload_id: string;
  words: BaseTranscriptWord[];
};

export type UserLibraryTranscriptType = {
  loading: boolean;
  isEditingCaptionLoading: boolean;
  error: string | null;
  transcript: null | BaseTranscriptWord[][];
  hasFullResponse?: boolean;
};

export type UserLibraryTranscriptChaptersType = {
  loading: boolean;
  error: string | null;
  chapters: null | BaseTranscriptChapter[];
};

export type TotalViewType = {
  count: number;
  unique: number;
};

export type UserUploadsType = {
  parent_folder_id: null | string;
  account_id: string;
  bucket_file_name: string;
  bucket_key: string;
  created_at: number;
  extension: string;
  file_name: string;
  file_size: string;
  file_type: string | UserUploadsTypes;
  id: string;
  mux_playback_id: string;
  owner_id: string;
  source: string;
  status: string;
  updated_at: number;
  upload_id: string;
  upload_url: string;
  discarded_at: any;
  last_modified: any;
  has_transcript: boolean;
  has_audio_analysis: boolean;
  should_expect_transcript: boolean;
  max_width: number | null;
  max_height: number | null;
  duration: number | null;
  account_logo: boolean | null;
};

export type AccountFontUploadsType = {
  account_id: string;
  bucket_file_name: string;
  bucket_key: string;
  created_at: number;
  extension: string;
  file_name: string;
  file_size: string;
  file_type: UserUploadsTypes;
  id: string;
  owner_id: string;
  source: string;
  status: string;
  updated_at: number;
  upload_id: string;
  upload_url: string;
  discarded_at: any;
  last_modified: any;
  font_fullname: string;
  font_family: string;
  font_license: string;
  font_subfamily: string;
  font_name_preview_url: string;
  font_specimen_preview_url: string;
};

export type ExternalFontUploadsType = {
  id: string;
  origin: string;
  url: string;
  variant: string;
  family: string;
  category: string;
  version: string;
  subset: string;
};

export type BaseTranscriptChapter = {
  start: TimeMilliSeconds;
  end: TimeMilliSeconds;
  gist: string;
  summary: string;
  headline: string;
};

export type BaseTranscriptWord = {
  start: TimeMilliSeconds;
  end: TimeMilliSeconds;
  text: string;
  id?: string;
};

export type UserLibraryUserProjectType = {
  name: string;
  created_at: number;
  updated_at: number;
  account_id: string;
  owner_id: string;
  slug: string;
  status: string;
  unique_id: any;
  discarded_at: any;
  latest_preview: string | undefined;
  is_template: boolean;
  metadata?: {
    published_version?: number;
    preview_image?: string;
  };
  client_sync_version?: number;
};

export type UserLibraryUserProjectTypeWithCmsData = UserLibraryUserProjectType & {
  description?: string;
  tags?: {title: string; slug: string}[];
  categories?: {title: string; slug: string}[];
};

export type LabelValueType = {
  label_value: string;
  label_type: 'color';
};

export enum ClipUploadMedia {
  Video = 'video',
  Audio = 'audio',
  Other = 'other',
}

export type UserLibraryUserTemplateType = any;

export enum UploadFolderContentType {
  UserUpload = 'UserUpload',
}

export type UserUploadFolderItem = {
  item_type: UploadFolderContentType;
  id: string;
  folder_id: string;
  item_id: string;
  created_at: number;
  updated_at: number;
};

export type UserUploadFolders = UserUploadFolder[];
export type UserUploadFolderItems = UserUploadFolderItem[];

export type AccountSearchQuery = {
  created_at: number;
  updated_at: number;
  user_id: string;
  account_id: string;
  query: string;
  id: string;
  is_hidden: boolean;
};

export type AccountSearchResult = {
  hasResults: boolean;
  searchQuery: string;
  uploads: {
    results: UserUploadsType[];
  };
  clips: {
    results: UploadMediaClipType[];
  };
  transcripts: {
    results: ClipTranscriptResponseType[];
  };
  transcripts_word_instances: {
    results: SearchTranscriptWordInstances[];
  };
};

export type AccountLabelColor = {
  id?: string;
  name: string;
  bgColor: string;
  selectedColor: string;
};

export type Glossary = {
  terms: string[];
};

export type AccountSettingsStatuses = {
  isAccountGlossarySubmitting: boolean;
};

export type UserLibrarySliceType = {
  accountSearchQueries: AccountSearchQuery[];
  accountSearchResults: AccountSearchResult;
  accountSettingsStatuses: AccountSettingsStatuses;
  creatingClip: boolean;
  deletingClip: boolean;
  error: Error | any;
  fileUploads: Record<string, any>;
  fileUploadsAnalytics: Record<string, any>;
  isLoading: boolean;
  loading: boolean;
  loadingAccountSearchQueries: boolean;
  loadingClips: boolean;
  loadingProjects: boolean;
  loadingTemplates: boolean;
  loadingUploadFolders: boolean;
  loadingUploads: boolean;
  processingAccountSearchQuery: boolean;
  projectId: null | string;
  projectUser: null | UserLibraryUser;
  selectedProjectIds: any[];
  shareProjects: Record<string, UserLibraryShareProjectType>;
  transcripts: Record<string, Partial<Record<LanguageId, UserLibraryTranscriptType>>>;
  transcriptsChapters: Record<
    string,
    Partial<Record<LanguageId, UserLibraryTranscriptChaptersType>>
  >;
  uploadingClip: boolean;
  uploadingFile: boolean;
  userClips: UploadMediaClipType[];
  userProjects: UserLibraryUserProjectType[];
  userTemplates: UserLibraryUserTemplateType[];
  userUploadFolderItems: UserUploadFolderItems;
  userUploadFolders: UserUploadFolders;
  versionHistory: Record<string, any>;
  glossary: Glossary;
};
