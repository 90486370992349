import {AccountsWrapperType, UserType} from 'features/types/authSlice';
import {ApiUserResponseBeforeWorkspace, ApiUserResponseNormal} from 'api/authAPI';

import {User} from '@stytch/stytch-react';
import {baseApi} from './baseAPI';
import {subscribeToCable} from './utils';

type SignupResult = {
  user: UserType;
  current_account: AccountsWrapperType;
  token: string;
};

export const authAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['User', 'Account']})
  .injectEndpoints({
    endpoints: builder => ({
      announceUser: builder.query<
        ApiUserResponseNormal | ApiUserResponseBeforeWorkspace,
        {
          email: string;
          user: User | null;
        }
      >({
        query: ({email, user}) => ({
          url: `user`,
          method: 'POST',
          errorTitle: 'Error loading user data',
          v2: true,
          data: {auth: {email, user}},
        }),
        providesTags: ['User', 'Account'],
        async onCacheEntryAdded(_, api) {
          return subscribeToCable(
            {api, action: 'updated_account'},
            ({account_response}, draft) => {
              // console.log('updated_account', account_response);
              api.dispatch(authAPI.util.invalidateTags(['User', 'Account']));
            },
          );
        },
      }),
      anonymousSignup: builder.mutation<SignupResult, void>({
        query: () => ({
          url: `anonymous_signup`,
          errorTitle: 'Error signing up',
          method: 'POST',
          auth: false,
        }),
      }),
      mergeAnonymousUser: builder.mutation<
        {status: 'success'; data: {old_user_email: string; new_user_email: string}},
        {anonymousToken: string}
      >({
        query: ({anonymousToken}) => ({
          url: `users/merge_anonymous`,
          errorTitle: false,
          method: 'POST',
          v2: true,
          data: {anonymous_token: anonymousToken},
        }),
      }),
      startSignup: builder.mutation<void, {email: string; origin: string}>({
        query: data => ({
          url: `users/start_signup`,
          errorTitle: false,
          method: 'POST',
          v2: true,
          auth: false,
          data: {user: data},
        }),
      }),
      submitAnonymousUserEmail: builder.mutation<UserType, {email: string}>({
        query: data => ({
          url: `anonymous_user/submit_email`,
          errorTitle: 'Error updating user',
          method: 'POST',
          v2: true,
          data,
        }),
        invalidatesTags: ['User'],
      }),
    }),
  });

export const {
  useAnnounceUserQuery,
  useAnonymousSignupMutation,
  useMergeAnonymousUserMutation,
  useStartSignupMutation,
  useSubmitAnonymousUserEmailMutation,
} = authAPI;
