const PreviewImage = ({title, imgSrc}: {title: string | null; imgSrc: string | null}) => {
  if (!title || !imgSrc) {
    return <></>;
  }
  return (
    <>
      <p>{title}</p>
      <img src={imgSrc} className="w-40" />
    </>
  );
};

export default PreviewImage;
