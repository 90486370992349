import {BASE_URL} from 'constants/environment';
import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {TranscriptJSON} from 'features/Captions/Transcripts';
import axios from 'axios';
import {requestHeader} from './helpers';

export async function getTranscriptVtt(url: string) {
  // const url = `${apiDomainOrigin}/project_scenarios/${projectScenarioKey}.json`
  const response = await axios.get(url);
  return response.data;
}

type PostUpdatedTranscriptEditType = {
  token: string;
  language: LanguageId;
  transcript: TranscriptJSON;
  userUploadId: string;
  editedText: string;
};

export async function postUpdatedTranscriptEdit({
  token,
  language,
  transcript,
  userUploadId,
  editedText,
}: PostUpdatedTranscriptEditType) {
  const transcriptEditEndpoint = `${BASE_URL}/api/v1/user_uploads/${userUploadId}/edit_transcript`;

  const response = await axios.post(
    transcriptEditEndpoint,
    {
      language,
      edited_text: editedText,
      updated_transcript: transcript,
    },
    requestHeader(token),
  );
  return response.data;
}
