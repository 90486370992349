import {DashParams, routePaths} from 'routes/routesHelper';
import {RefObject, useEffect, useRef, useState} from 'react';

import {Link} from 'react-router-dom';
import ReactPlayer from 'vendor/react-player-milk';

// Ratio = width / height
function MainDownloadPreview({
  playbackId,
  projectSlug,
  isLoading,
  isError,
}: {
  playbackId: string | null;
  projectSlug: string;
  isLoading: boolean;
  isError: boolean;
}) {
  const loadedVideoRef = useRef<any>();

  const [videoIsReady, setVideoIsReady] = useState(false);

  useEffect(() => {
    if (videoIsReady && loadedVideoRef.current) {
      window.analytics.track('Download preview page loaded', {
        duration: loadedVideoRef.current.getDuration(),
      });
    }
  }, [videoIsReady]);

  if (isError) {
    return (
      <div className="bg-white xl:aspect-w-5 xl:aspect-h-3">
        <div className="my-auto flex-shrink-0 py-16 sm:py-32">
          <p className="text-sm font-semibold uppercase tracking-wide text-indigo-600">
            Download error
          </p>
          <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
            Video did not render.
          </h1>
          <p className="mt-2 text-base text-gray-500">
            Sorry, our video rendering couldn’t complete successfully.
          </p>
          <div className="mt-6">
            <Link
              to={`/${routePaths.create}/${DashParams.CanvasParam}/${projectSlug}`}
              className="text-base font-medium text-indigo-600 hover:text-indigo-500"
            >
              Try exporting the video again
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white xl:aspect-w-5 xl:aspect-h-3">
        {isLoading || !playbackId ? (
          <ReactPlayer
            className="object-contain object-center"
            width={`100%`}
            playing
            loop
            url={`https://assets-milk.s3.amazonaws.com/dashboard/processing.mp4`}
          />
        ) : (
          <ReactPlayer
            className="object-contain object-center"
            width={`100%`}
            controls
            onReady={() => {
              setVideoIsReady(true);
            }}
            ref={loadedVideoRef}
            url={`https://stream.mux.com/${playbackId}.m3u8`}
          />
        )}
      </div>
      {isLoading && (
        <div className="mx-auto mt-3 inline-flex w-full animate-pulse items-center justify-center rounded-md bg-indigo-600 p-2 font-semibold text-white">
          Video is processing. Please check back later.
        </div>
      )}
    </>
  );
}

export default MainDownloadPreview;
