import {ButtonGroup, CanvasButton} from 'features/EditorCanvas/components/CanvasButton';
import {Hash, Star} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';

import {TextSizeMode} from 'features/types/canvasItemsSlice';
import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {updateItemAttribute} from 'features/canvasItemsSlice';

export const TextContainerSizeModePicker = ({projectId}: {projectId: string}) => {
  const dispatch = useDispatch();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  if (!selected) return null;
  if (selected.item.viewType !== ViewTypes.Text) return null;

  const customWidth = selected.item.sizeMode === 'customWidth';

  const setSizeMode = (sizeMode: TextSizeMode) => {
    dispatch(
      updateItemAttribute({
        projectId,
        id: selected.id,
        attribute: 'sizeMode',
        newValue: sizeMode,
      }),
    );
  };

  return (
    <div className="flex">
      <div className="flex w-full flex-col text-sm leading-5 text-gray-700">
        <div className="flex flex-row">
          <ButtonGroup>
            <CanvasButton
              onClick={() => setSizeMode('auto')}
              variant={!customWidth ? 'active' : 'default'}
              leftIcon={Star}
            >
              Auto
            </CanvasButton>
            <CanvasButton
              onClick={() => setSizeMode('customWidth')}
              variant={customWidth ? 'active' : 'default'}
              leftIcon={Hash}
            >
              Custom
            </CanvasButton>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};
