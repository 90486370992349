import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {useMemo, useRef} from 'react';

import {createContainer} from 'unstated-next';
import {useCallbackOne} from 'use-memo-one';
import {useRtkReducer} from 'features/Common/utils';

export type TimeState = {
  playStarted: number | null;
  playOffset: number;
  isScrubbing: boolean;
  lastAction?: 'seek' | 'sync';
};

const initialState: TimeState = {
  playStarted: null,
  playOffset: 0,
  isScrubbing: false,
};

type PlayAction = PayloadAction<{reset?: boolean} | undefined>;

const selectors = {
  currentTime: (state: Pick<TimeState, 'playStarted' | 'playOffset'>) => {
    if (state.playStarted == null) {
      return state.playOffset;
    }

    return Date.now() - state.playStarted + state.playOffset;
  },
  isPlaying: (state: TimeState) => {
    return state.playStarted !== null;
  },
};

const playbackSlice = createSlice({
  name: 'playback',
  initialState,
  reducers: {
    play(state, action: PlayAction) {
      if (selectors.isPlaying(state)) return;
      state.lastAction = undefined;

      state.playStarted = Date.now();

      if (action.payload?.reset) {
        state.playOffset = 0;
      }
    },
    syncDependencyTime(state, action: PayloadAction<{timeMs: number}>) {
      if (!selectors.isPlaying(state)) return;
      state.lastAction = 'sync';

      const {timeMs} = action.payload;
      const currentTime = selectors.currentTime(state);

      const currentDiscrepancy = Math.abs(currentTime - timeMs);

      if (currentDiscrepancy > 50) {
        state.playOffset = timeMs;
        state.playStarted = Date.now();
      }
    },
    pause(state) {
      if (!selectors.isPlaying(state)) return;
      state.lastAction = undefined;

      state.playOffset = selectors.currentTime(state);
      state.playStarted = null;
    },
    toggle(state, action: PlayAction) {
      if (selectors.isPlaying(state)) {
        playbackSlice.caseReducers.pause(state);
      } else {
        playbackSlice.caseReducers.play(state, action);
      }
    },
    stop(state) {
      state.lastAction = undefined;

      state.playStarted = null;
      state.playOffset = 0;
    },
    seekTo(state, action: PayloadAction<{timeMs: number}>) {
      state.lastAction = 'seek';

      state.playOffset = action.payload.timeMs;
      if (selectors.isPlaying(state)) {
        state.playStarted = Date.now();
      }
    },
    seekBy(state, action: PayloadAction<{amountMs: number}>) {
      let timeMs = selectors.currentTime(state) + action.payload.amountMs;
      timeMs = Math.max(0, timeMs);

      playbackSlice.caseReducers.seekTo(state, {
        ...action,
        payload: {timeMs},
      });
    },
    startScrubbing(state) {
      if (state.isScrubbing) return;

      state.lastAction = undefined;
      state.isScrubbing = true;
    },
    stopScrubbing(state) {
      if (!state.isScrubbing) return;

      state.lastAction = undefined;
      state.isScrubbing = false;
    },
  },
});

const Playback = createContainer(() => {
  const scrubTimeMs = useRef(0);
  const {state, actions} = useRtkReducer(playbackSlice, initialState);

  const isPlaying = selectors.isPlaying(state);
  const isScrubbing = state.isScrubbing;
  const lastAction = state.lastAction;

  const getCurrentTime = useCallbackOne(() => {
    if (state.isScrubbing) {
      return scrubTimeMs.current;
    }

    return selectors.currentTime({
      playOffset: state.playOffset,
      playStarted: state.playStarted,
    });
  }, [state.isScrubbing, state.playOffset, state.playStarted]);

  const scrub = useCallbackOne(
    (timeMs: number) => {
      if (!state.isScrubbing) {
        actions.startScrubbing();
      }

      scrubTimeMs.current = timeMs;
    },
    [actions, state.isScrubbing],
  );

  return useMemo(
    () => ({...actions, isPlaying, isScrubbing, getCurrentTime, scrub, lastAction}),
    [actions, getCurrentTime, isPlaying, scrub, isScrubbing, lastAction],
  );
});

export const PlaybackProvider = Playback.Provider;
export const usePlayback = Playback.useContainer;
