import {ChangeEvent, useEffect, useRef, useState} from 'react';

import {OutsideClickHandler} from 'vendor/OutsideClickHandler';
import {Transition} from '@headlessui/react';
import classNames from 'classnames';

type PrimitiveOption = string | number;

type ObjectOption = {
  id: PrimitiveOption;
  label: string;
};

const getPrimitiveOption = (option: PrimitiveOption | ObjectOption) => {
  if (typeof option === 'object') return option.id;
  return option;
};

const getLabel = (option: PrimitiveOption | ObjectOption) => {
  if (typeof option === 'object') return option.label;
  return option.toString();
};

export function FontSizeInputAndSelect<Option extends PrimitiveOption | ObjectOption>({
  value,
  onChange,
  options,
  buttonLabel,
}: {
  value: Option | null | undefined;
  options: Option[];
  onChange: (value: number) => void;
  buttonLabel?: string;
}) {
  const label = value && getLabel(value);
  const [inputField, setInputField] = useState<number>(Number(label));
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [highlight, setHighlight] = useState<PrimitiveOption | null>(null);

  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (value) {
      setInputField(Number(value));
    }
  }, [value]);

  const open = () => {
    if (timeoutId.current) clearTimeout(timeoutId.current);

    setIsOpen(true);
    setIsVisible(true);
  };

  const close = () => {
    if (timeoutId.current) clearTimeout(timeoutId.current);

    setIsOpen(false);
    timeoutId.current = setTimeout(() => {
      setIsVisible(false);
    }, 75);
  };

  const toggleOpen = () => {
    if (isOpen) close();
    else open();
  };

  return (
    <OutsideClickHandler onOutsideClick={close}>
      <div className={classNames('relative mt-0', {'z-50': isVisible})}>
        <span className="inline-block w-full rounded-md shadow-sm">
          <button
            type="button"
            aria-haspopup="listbox"
            aria-expanded="true"
            className="relative w-full "
            aria-labelledby="listbox-label"
          >
            <input
              onClick={toggleOpen}
              type="text"
              name="font-size"
              id="font-size"
              className="block w-full cursor-pointer rounded-md border border-gray-300 bg-white py-1 pl-3 pr-10 text-left text-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 sm:text-sm sm:leading-5"
              style={{minHeight: 20}}
              value={inputField || ''}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                if (Number(evt.target.value) > 1) {
                  setInputField(Number(evt.target.value));
                  onChange(Number(evt.target.value));
                }
              }}
            />

            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <svg
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
              >
                <path
                  d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </span>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="absolute z-50 mt-1 w-full rounded-md bg-white shadow-lg">
            <ul
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-item-3"
              className="max-h-60 overflow-auto rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm sm:leading-5"
            >
              {options.map(option => {
                const primitiveOption = getPrimitiveOption(option);

                return (
                  <li
                    key={primitiveOption}
                    id={primitiveOption.toString()}
                    onClick={() => {
                      close();
                      onChange(Number(option));
                    }}
                    onMouseEnter={() => setHighlight(primitiveOption)}
                    onMouseLeave={() => setHighlight(null)}
                    role="option"
                    aria-selected
                    className={classNames(' relative select-none py-2 pl-3 pr-9', {
                      'text-gray-900 ': primitiveOption !== highlight,
                      'bg-gray-100 text-gray-600': primitiveOption === highlight,
                    })}
                  >
                    <span className="block font-normal">{getLabel(option)}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </Transition>
      </div>
    </OutsideClickHandler>
  );
}
