// UI TODO (jacques): Move some of this to UI Lib

import classNames from 'classnames';
import {Children, FC, useState} from 'react';
import {Icon, Trash} from 'react-feather';
import {Button} from 'ui/Button';
import {IconButton} from 'ui/IconButton';

export const ListSkeleton = () => {
  return (
    <div className="space-y-2" role="alert" aria-live="polite" aria-busy="true">
      <div className="h-9 animate-pulse rounded bg-gray-100" />
      <div className="h-9 animate-pulse rounded bg-gray-100" />
    </div>
  );
};

export const ListContainer: FC<{className?: string; size?: 'large' | 'small'}> = ({
  className,
  children,
  size = 'large',
}) => {
  if (Children.count(children) === 0) return null;

  return (
    <ul
      className={classNames(
        'divide-y divide-gray-200 rounded-md border border-gray-200 px-3',
        size === 'large' ? 'text-base' : 'text-sm',
        className,
      )}
    >
      {children}
    </ul>
  );
};

export const DeleteButton = ({
  onClickConfirm,
  label,
}: {
  onClickConfirm: () => any;
  label: string;
}) => {
  const [confirm, setConfirm] = useState(false);

  if (confirm) {
    return (
      <div className="flex-shrink-0 text-sm">
        <span className="pr-1">Are you sure?</span>
        <Button
          variant="inline"
          size="small"
          onClick={onClickConfirm}
          label={`Confirm deletion of ${label}`}
          destructive
        >
          Yes, delete
        </Button>
      </div>
    );
  }

  return (
    <IconButton
      variant="inline"
      size="small"
      onClick={() => setConfirm(true)}
      _className="opacity-0 group-hover:opacity-100 transition-opacity"
      label={`Delete ${label}`}
      destructive
      icon={Trash}
    />
  );
};

export const LargeDashedButton: FC<{icon: Icon; text: string; onClick?: () => void}> = ({
  children,
  icon: Icon,
  text,
  onClick,
}) => {
  return (
    <button
      className="py-y relative flex h-36 w-full flex-col items-center justify-center rounded border border-dashed border-indigo-300 bg-gray-50 text-gray-800 transition-colors hover:border-indigo-600"
      onClick={onClick}
    >
      <Icon className="mb-2 text-indigo-600" />
      <div className="text-lg font-medium">{text}</div>
      {children}
    </button>
  );
};
