import {
  DraggableCore,
  DraggableData,
  DraggableEvent,
  DraggableEventHandler,
} from 'react-draggable';
import {
  FC,
  RefObject,
  cloneElement,
  createContext,
  createRef,
  useContext,
  useRef,
  useState,
} from 'react';

import {PositionType} from 'features/types/canvasItemsSlice';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import {UserUpload} from 'services/userUploadAPI';
import classNames from 'classnames';
import {roundObject} from 'features/EditorCanvas/Layout/Box';
import {translateFrom} from '../CanvasItem/MoveableLayer';

type Coords = {x: number; y: number};

const useDraggable = ({
  onDragStart,
  onDrag,
  onDragEnd,
}: {
  onDragStart: () => void;
  onDrag: (mouse: Coords) => void;
  onDragEnd: (mouse: Coords) => void;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({top: 0, left: 0});
  const containerRef = useRef<HTMLDivElement>(null);
  const [fixedSize, setFixedSize] = useState({width: 0, height: 0});
  const [scale, setScale] = useState(1);

  const setStartPosition = (event: DraggableEvent, drag: DraggableData) => {
    const nodeRect = drag.node.getBoundingClientRect();
    const top = nodeRect.top + document.documentElement.scrollTop;
    const left = nodeRect.left + document.documentElement.scrollLeft;

    setPosition({top, left});
    return {top, left};
  };

  const startDrag: DraggableEventHandler = (event, drag) => {
    setIsDragging(true);

    if (!containerRef.current) return;
    const containerRect = containerRef.current.getBoundingClientRect();
    setFixedSize({width: containerRect.width, height: containerRect.height});

    onDragStart();

    requestAnimationFrame(() => {
      setScale(1.1);
    });
  };

  const startDragRef =
    useRef<{timeout: NodeJS.Timeout; position: PositionType} | null>(null);

  return {
    isDragging,
    draggableProps: {
      onStart: (event: DraggableEvent, drag: DraggableData) => {
        const position = setStartPosition(event, drag);

        const timeout = setTimeout(() => {
          startDragRef.current = null;
          startDrag(event, drag);
        }, 400);

        startDragRef.current = {
          timeout,
          position,
        };
      },
      onDrag: (event: DraggableEvent, drag: DraggableData) => {
        const {top, left} = position;
        const newPosition = {top: top + drag.deltaY, left: left + drag.deltaX};

        if (startDragRef.current) {
          const startPosition = startDragRef.current.position;
          const deltaTop = Math.abs(startPosition.top - newPosition.top);
          const deltaLeft = Math.abs(startPosition.left - newPosition.left);

          if (deltaTop > 5 || deltaLeft > 5) {
            clearTimeout(startDragRef.current.timeout);
            startDragRef.current = null;
            startDrag(event, drag);
          }
        }
        setPosition(newPosition);
        // @ts-ignore
        onDrag({y: event.clientY, x: event.clientX});
      },
      onStop: (event: DraggableEvent) => {
        if (startDragRef.current) {
          clearTimeout(startDragRef.current.timeout);
          return;
        }

        setIsDragging(false);
        // @ts-ignore
        onDragEnd({y: event.clientY, x: event.clientX});
        setScale(1);
      },
    },
    previewOuterProps: {
      style: {
        position: 'fixed' as const,
        top: 0,
        left: 0,
        ...fixedSize,
        transform: translateFrom({position}),
        zIndex: 99999999,
        opacity: 0.5,
      },
    },
    previewInnerProps: {
      className: 'transition-transform',
      style: {transform: `scale(${scale})`},
    },
    containerRef,
  };
};

type ActiveClip = {
  userUpload: UserUpload;
  uploadMediaClip: UploadMediaClipType;
  type: DragSourceType;
};

export const DraggableClip: FC<{
  uploadMediaClip: UploadMediaClipType;
  userUpload: UserUpload;
  type: DragSourceType;
  onClick?: () => void;
}> = ({uploadMediaClip, userUpload, children, type, onClick}) => {
  const {onDragStart, onDrag, onDragEnd} = useDraggableClipContext();

  const clip = {
    uploadMediaClip,
    userUpload,
    type,
  };

  const {containerRef, draggableProps, isDragging, previewOuterProps, previewInnerProps} =
    useDraggable({
      onDrag,
      onDragEnd,
      onDragStart: () => onDragStart(clip),
    });

  return (
    <DraggableCore {...draggableProps}>
      <div className={classNames(isDragging ? 'cursor-grabbing' : 'cursor-grab')}>
        {isDragging && (
          <div {...previewOuterProps}>
            <div {...previewInnerProps}>{children}</div>
          </div>
        )}
        <div ref={containerRef} style={{width: '100%'}} onClick={onClick}>
          {children}
        </div>
      </div>
    </DraggableCore>
  );
};

type RegisterDropZone = (options: DropZoneOptions) => RefObject<HTMLElement>;

type Context = {
  registerDropZone: RegisterDropZone;
  onDrag: (mouse: Coords) => void;
  onDragStart: (clip: ActiveClip) => void;
  onDragEnd: (mouse: Coords) => void;
  dragging: boolean;
  activeDropZone: DropZone | null;
  activeClip: ActiveClip | null;
};

const DraggableClipContext = createContext<Context | null>(null);

export const useDraggableClipContext = () => {
  // console.log('DraggableClipContext', DraggableClipContext);
  const context = useContext(DraggableClipContext);
  if (context == null) {
    throw new Error(
      'Cannot call useDraggableClipContext() outside of <DraggableClipProvider />',
    );
  }

  return context;
};

type DropZoneEventHandler = (event: {
  clip: ActiveClip;
  mouse: Coords;
  position: {top: number; left: number};
}) => void;

type DropZone = {
  id: string;
  ref: RefObject<HTMLElement>;
  type?: DragSourceType;
  onDrop: DropZoneEventHandler;
  onEnter?: DropZoneEventHandler;
  onExit?: DropZoneEventHandler;
};

type DropZoneOptions = Omit<DropZone, 'ref'>;

type DropZones = Record<string, DropZone>;

const findDropZoneAt = (
  position: Coords,
  type: DragSourceType,
  dropZonesObj: DropZones,
) => {
  const elements = document.elementsFromPoint(position.x, position.y);
  const dropZones = Object.values(dropZonesObj);

  let dropZone: DropZone | null = null;
  for (const element of elements) {
    const match = dropZones.find(dropZone => {
      if (!dropZone.ref.current) return false;

      const matchingElement = dropZone.ref.current === element;
      const matchingType = !dropZone.type || dropZone.type === type;

      return matchingElement && matchingType;
    });

    if (match) {
      dropZone = match;
      break;
    }
  }

  return dropZone;
};

export const DraggableClipProvider: FC<{projectId: string}> = ({children, projectId}) => {
  const dropZones = useRef<DropZones>({});
  const [activeClip, setActiveClip] = useState<ActiveClip | null>(null);
  const [activeDropZone, setActiveDropZone] = useState<DropZone | null>(null);

  const getEvent = ({
    dropZone,
    mouse,
    clip,
  }: {
    mouse: Coords;
    dropZone: DropZone;
    clip: ActiveClip;
  }) => {
    const dropZoneRect = dropZone.ref.current?.getBoundingClientRect();

    let position = {top: 0, left: 0};
    if (dropZoneRect) {
      position = {
        top: (mouse.y - dropZoneRect.top) / dropZoneRect.height,
        left: (mouse.x - dropZoneRect.left) / dropZoneRect.width,
      };
    }

    return {
      position,
      clip,
      mouse: roundObject(mouse),
    };
  };

  const onDragStart = (clip: ActiveClip) => setActiveClip(clip);
  const onDragEnd = (mouse: Coords) => {
    // console.log('here', mouse);
    // console.log('activeClip', activeClip);
    if (activeClip && activeDropZone) {
      const event = getEvent({mouse, dropZone: activeDropZone, clip: activeClip});
      activeDropZone.onDrop(event);
      activeDropZone.onExit?.(event);
    }

    setActiveClip(null);
    setActiveDropZone(null);
  };

  const onDrag = (mouse: Coords) => {
    if (!activeClip) return;

    const newDropZone = findDropZoneAt(mouse, activeClip.type, dropZones.current);

    if (newDropZone?.id !== activeDropZone?.id) {
      if (newDropZone && newDropZone.onEnter) {
        const event = getEvent({mouse, dropZone: newDropZone, clip: activeClip});
        newDropZone.onEnter(event);
      }

      if (activeDropZone && activeDropZone.onExit) {
        const event = getEvent({mouse, dropZone: activeDropZone, clip: activeClip});
        activeDropZone.onExit(event);
      }
    }

    setActiveDropZone(newDropZone);
  };

  const registerDropZone: RegisterDropZone = dropZone => {
    const id = dropZone.id;

    if (dropZones.current[id]) {
      dropZones.current[id] = {
        ref: dropZones.current[id].ref,
        ...dropZone,
      };
    } else {
      dropZones.current[id] = {
        ref: createRef<HTMLElement>(),
        ...dropZone,
      };
    }

    return dropZones.current[id].ref;
  };

  return (
    <DraggableClipContext.Provider
      value={{
        registerDropZone,
        onDrag,
        activeDropZone,
        onDragStart,
        onDragEnd,
        dragging: activeClip != null,
        activeClip,
      }}
    >
      {children}
    </DraggableClipContext.Provider>
  );
};

type TargetRenderProps = (props: {
  active: boolean;
  activeClip: ActiveClip | null;
  droppable: boolean;
  ref: RefObject<any> | undefined;
}) => JSX.Element;

export type DragSourceType = 'video' | 'caption' | 'both' | null;

export const ClipDropZone = ({
  children,
  id,
  enabled = true,
  ...options
}: {
  children: TargetRenderProps | JSX.Element;
  enabled?: boolean;
} & DropZoneOptions) => {
  const context = useContext(DraggableClipContext);
  if (!context || !enabled) {
    const child =
      typeof children === 'function'
        ? children({active: false, activeClip: null, droppable: false, ref: undefined})
        : children;

    return child;
  }

  const {activeClip, activeDropZone, registerDropZone} = context;

  const active = activeDropZone?.id === id;
  const droppable = !options.type || options.type === activeClip?.type;

  const ref = registerDropZone({id, ...options});

  const childElement =
    typeof children === 'function'
      ? children({active, activeClip, droppable, ref})
      : cloneElement(children, {ref});

  return childElement;
};
