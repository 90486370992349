import {AppThunk, GetRootState} from 'configureStore';
import {
  BaseTranscriptWord,
  ClipTranscriptResponseType,
  Glossary,
  TimeSeconds,
  TotalViewType,
  UserLibrarySliceType,
  UserLibraryTranscriptChaptersType,
  UserLibraryTranscriptType,
  UserLibraryUser,
  UserLibraryUserProjectType,
  UserLibraryUserTemplateType,
  UserUploadFolderItems,
  UserUploadFolders,
  UserUploadsType,
} from './types/userLibrarySlice';
import {DashParams, routePaths} from 'routes/routesHelper';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {
  getAccountSearchQueries,
  getShowGlossary,
  postAccountSearchQuery,
  putUpdateGlossary,
} from 'api/accountsAPI';
import {
  getFileUploadTranscript,
  getFileUploadVersions,
  getUserTemplates,
  getUserUploadFolders,
  postNewUploadFolder,
} from 'api/userLibraryAPI';
import {
  getNewProject,
  getProjectData,
  getUserProjects,
  submitMultipleProjectsDelete,
  submitProjectDelete,
  submitProjectDuplicate,
} from 'api/projectsAPI';
import {
  getUserClips,
  postCreateUserUploadClip,
  postNewClipName,
  postResetClipLabelColor,
  postUpdateClipLabelColor,
  submitUserClipDelete,
} from 'api/clipsAPI';
import {getUserUploadAnalytics, postUploadUserFile} from 'api/userUploadsAPI';
import {syncProjectState, syncingProjectStoreFailed} from 'features/serverSyncSlice';

import {LanguageId} from './Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {NOTIFICATION_BASE} from './Notifications/constants';
import NotificationError from './Notifications/NotificationError';
import {NotificationInfo} from './Notifications/NotificationInfo';
import {SearchTranscriptWordInstances} from './types/authSlice';
import {TranscriptJSON} from 'features/Captions/Transcripts';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import {accountDetailsSelector} from './selectors/authSelectors';
import {getLatestProjectSync} from 'api/projectClientSyncAPI';
import {postAnalyticsEvent} from 'api/analyticsAPI';
import {postUpdatedTranscriptEdit} from 'api/transcriptAPI';
import {replace} from 'redux-first-history';
import {resetCanvas} from 'features/canvasItemsSlice';
import {resetSceneFrame} from 'features/sceneFrameSlice';
import {showErrorNotification} from './Common/utils';
import {store} from 'react-notifications-component';

const TEMP_NEW_PROJECT_USER = {
  fullName: null,
  photoUrl: null,
} as UserLibraryUser;

const TEMP_NEW_SHARE_PROJECT = {
  isAccountSubscribed: false,
  loading: false,
  createdAt: null,
  name: null,
  status: null,
  totalViews: {
    count: 0,
    unique: 0,
  },
  layers: [],
} as UserLibraryShareProjectType;

export const initialUserLibraryState = {
  accountSearchQueries: [],
  accountSearchResults: {
    hasResults: false,
    searchQuery: '',
    uploads: {results: []},
    transcripts: {results: []},
    transcripts_word_instances: {results: []},
    clips: {results: []},
  },
  accountSettingsStatuses: {
    isAccountGlossarySubmitting: false,
  },
  creatingClip: false,
  deletingClip: false,
  error: null,
  fileUploads: {},
  fileUploadsAnalytics: {},
  glossary: {terms: []},
  isLoading: false,
  loading: false,
  loadingAccountSearchQueries: false,
  loadingClips: false,
  loadingProjects: false,
  loadingTemplates: false,
  loadingUploadFolders: false,
  loadingUploads: false,
  processingAccountSearchQuery: false,
  projectId: null,
  projectUser: null,
  selectedProjectIds: [],
  shareProjects: {},
  transcripts: {},
  transcriptsChapters: {},
  uploadingClip: false,
  uploadingFile: false,
  userClips: [],
  userProjects: [],
  userTemplates: [],
  userUploadFolderItems: [],
  userUploadFolders: [],
  versionHistory: {},
} as UserLibrarySliceType;

const userLibrarySlice = createSlice({
  name: 'userLibrary',
  initialState: initialUserLibraryState,
  reducers: {
    updateSelectedProjectIds(state, action) {
      state.selectedProjectIds = action.payload;
    },
    getFileUploadAnalyticsStart(state) {
      state.fileUploadsAnalytics = state.fileUploadsAnalytics || {};
    },
    getFileUploadAnalyticsSuccess(state, action) {
      const {bucketKey, analytics, callback} = action.payload;
      state.fileUploadsAnalytics[bucketKey] = analytics;
      if (callback) {
        callback();
      }
    },
    getFileUploadTranscriptStart(
      state,
      action: PayloadAction<{userUploadId: string; language: LanguageId}>,
    ) {
      const {userUploadId, language} = action.payload;
      if (!state.transcripts[userUploadId]) state.transcripts[userUploadId] = {};
      state.transcripts[userUploadId][language] = BASE_TRANSCRIPT;
    },
    getFileUploadTranscriptSuccess(
      state,
      action: PayloadAction<{
        userUploadId: string;
        language: LanguageId;
        transcript: BaseTranscriptWord[][];
        has_full_response: boolean;
      }>,
    ) {
      const {userUploadId, language, transcript, has_full_response} = action.payload;
      if (!transcript) return;

      state.transcripts[userUploadId][language]!.transcript = transcript.filter(
        els => els.length > 0,
      );
      state.transcripts[userUploadId][language]!.loading = false;
      state.transcripts[userUploadId][language]!.hasFullResponse = has_full_response;
    },
    getFileUploadTranscriptFailure(
      state,
      action: PayloadAction<{userUploadId: string; language: LanguageId; error: string}>,
    ) {
      const {error, userUploadId, language} = action.payload;
      state.transcripts[userUploadId][language]!.loading = false;
      state.transcripts[userUploadId][language]!.error = error;
    },
    getFileUploadVersionstStart(state, action) {
      const {bucketKey} = action.payload;
      state.versionHistory[bucketKey] = {
        versions: [],
        loading: true,
        error: null,
      };
    },
    getFileUploadVersionsSuccess(state, action) {
      const {bucketKey, versions} = action.payload;
      // console.log('version', versions)
      state.versionHistory[bucketKey].versions = versions;
      state.versionHistory[bucketKey].loading = false;
    },
    getFileUploadVersionsFailure(state, action) {
      const {err, bucketKey} = action.payload;
      state.versionHistory[bucketKey].loading = false;
      state.versionHistory[bucketKey].error = err;
    },
    getProjectDataStart(state) {
      state.error = null;
    },
    getProjectDataSuccess(state, action) {
      const {projectAccount, projectData, projectUserData} = action.payload;
      if (!projectUserData) return;

      const {created_at, name, slug, status, total_views_count, total_views_unique} =
        projectData;
      const {full_name, photo_url} = projectUserData;
      state.projectId = slug;
      state.shareProjects[slug] = {
        ...TEMP_NEW_SHARE_PROJECT,
        createdAt: created_at,
        status,
        name,
        totalViews: {
          count: total_views_count,
          unique: total_views_unique,
        },
      };
      if (projectAccount.account_subscription === 'active') {
        state.shareProjects[slug].isAccountSubscribed = true;
      }
      state.projectUser = {
        ...TEMP_NEW_PROJECT_USER,
        photoUrl: photo_url,
        fullName: full_name,
      };
    },
    getProjectDataFailure(state, action) {
      state.error = action.payload;
    },
    submitDuplicateProjectStart(state) {
      state.loadingProjects = true;
      state.error = null;
    },
    submitDuplicateProjectSuccess(state, action) {
      const {userProject} = action.payload;
      const {userProjects} = state;
      userProjects.push(userProject);
      const sortedProjects = userProjects.sort(
        (a: UserLibraryUserProjectType, b: UserLibraryUserProjectType) =>
          b.updated_at - a.updated_at,
      );
      state.userProjects = sortedProjects;
      state.loadingProjects = false;
    },
    submitDuplicateProjectFailure(state, action) {
      state.loadingProjects = false;
      state.error = action.payload;
    },
    submitDeleteMultipleProjectsStart(state, action) {
      const {projectIds} = action.payload;
      state.loadingProjects = false;
      state.userProjects = state.userProjects.filter(
        project => !projectIds.includes(project.slug),
      );
      state.error = null;
    },
    submitDeleteMultipleProjectsSuccess(state, action) {
      const {userProjects} = action.payload;
      const sortedProjects = userProjects.sort(
        (a: UserLibraryUserProjectType, b: UserLibraryUserProjectType) =>
          b.updated_at - a.updated_at,
      );
      state.userProjects = [];
      state.userProjects = sortedProjects;
      state.loadingProjects = false;
    },
    submitDeleteProjectFailure(state, action) {
      state.loadingProjects = false;
      state.error = action.payload;
    },
    submitDeleteProjectStart(state, action) {
      const {projectId} = action.payload;
      state.loadingProjects = false;
      state.userProjects = state.userProjects.filter(
        project => project.slug !== projectId,
      );
      state.error = null;
    },
    submitDeleteProjectSuccess(state, action) {
      const {userProjects} = action.payload;
      const sortedProjects = userProjects.sort(
        (a: UserLibraryUserProjectType, b: UserLibraryUserProjectType) =>
          b.updated_at - a.updated_at,
      );
      state.userProjects = [];
      state.userProjects = sortedProjects;
      state.loadingProjects = false;
    },
    getUserProjectsStart(state, action) {
      const {loadingOverride} = action.payload;
      state.loadingProjects = loadingOverride !== null ? loadingOverride : true;
      state.error = null;
    },
    getUserProjectsSuccess(state, action) {
      const {userProjects} = action.payload;
      const sortedProjects = userProjects.sort(
        (a: UserLibraryUserProjectType, b: UserLibraryUserProjectType) =>
          b.updated_at - a.updated_at,
      );
      state.userProjects = sortedProjects;
      state.loadingProjects = false;
    },
    finishDuplication(state) {
      state.loading = false;
    },
    getUserProjectsFailure(state, action) {
      state.loadingProjects = false;
      state.error = action.payload;
    },

    getUserTemplatesStart(state, action) {
      const {loadingOverride} = action.payload;
      state.loadingTemplates = loadingOverride !== null ? loadingOverride : true;
      state.error = null;
    },
    getUserTemplatesSuccess(state, action) {
      const {userTemplates} = action.payload;
      const sortedTemplates = userTemplates.sort(
        (a: UserLibraryUserTemplateType, b: UserLibraryUserTemplateType) =>
          b.updated_at - a.updated_at,
      );
      state.userTemplates = sortedTemplates;
      state.loadingTemplates = false;
    },
    getUserTemplatesFailure(state, action) {
      state.loadingTemplates = false;
      state.error = action.payload;
    },
    getUserClipStart(
      state,
      action: PayloadAction<{
        loadingOverride: boolean;
        clipId: string;
      }>,
    ) {
      const {clipId, loadingOverride} = action.payload;
    },
    getUserClipSuccess(
      state,
      action: PayloadAction<{
        userClip: UploadMediaClipType;
      }>,
    ) {
      const {userClip} = action.payload;
      const newClips = state.userClips.filter(clip => clip.id !== userClip.id);
      const tempUserClips = [...newClips, userClip];
      const sortedClips = tempUserClips.sort(
        (a: UploadMediaClipType, b: UploadMediaClipType) =>
          Math.abs((new Date(b.created_at) as any) - (new Date(a.created_at) as any)),
      );
      state.userClips = sortedClips;
    },
    getUserClipsStart(state, action) {
      const {loadingOverride} = action.payload;
      state.loadingClips = loadingOverride !== null ? loadingOverride : true;
      state.error = null;
    },
    getUserClipsSuccess(state, action) {
      const {userClips} = action.payload;
      const sortedClips =
        userClips &&
        userClips.length &&
        userClips.sort((a: UploadMediaClipType, b: UploadMediaClipType) =>
          Math.abs((new Date(b.created_at) as any) - (new Date(a.created_at) as any)),
        );
      state.userClips = sortedClips;
      state.loadingClips = false;
    },
    getUserClipsFailure(state, action) {
      state.loadingClips = false;
      state.error = action.payload;
    },
    requestAnalyticsEventSuccess() {
      // NOP
    },

    submitNewClipNameStarted(state) {
      state.uploadingClip = true;
      state.error = null;
    },
    submitNewClipNameSuccessful(state, action) {
      const {updatedClip} = action.payload;
      state.userClips = state.userClips.map(clip => {
        if (clip.id === updatedClip.id) {
          clip.clip_name = updatedClip.clip_name;
        }
        return clip;
      });
      state.uploadingClip = false;
    },
    submitNewClipNameFailed(state, action) {
      state.uploadingClip = false;
      state.error = action.payload;
    },
    submitDeleteUserClipStart(state) {
      state.deletingClip = true;
      state.error = null;
    },
    submitDeleteUserClipSuccess(state) {
      // const sortedClips = userClips.sort(
      //   (a: UploadMediaClipType, b: UploadMediaClipType) =>
      //     Math.abs((new Date(b.updated_at) as any) - (new Date(a.updated_at) as any))
      // );
      // state.userClips = sortedClips;
      state.deletingClip = false;
    },
    submitDeleteUserClipFailure(state, action) {
      state.deletingClip = false;
      state.error = action.payload;
    },
    createUserUploadClipStart(state) {
      state.creatingClip = true;
      state.error = null;
    },
    createUserUploadClipSuccess(state, action) {
      const {clipData} = action.payload;
      state.userClips.push(clipData);
      state.creatingClip = false;
    },
    createUserUploadClipFailure(state, action) {
      state.creatingClip = false;
      state.error = action.payload;
    },

    cableUpdateToDashboardVideoDetailAssetsStart(state, action) {
      const {userUploadFromCable} = action.payload;
      if (!state.transcripts && !state.transcripts[userUploadFromCable.bucket_key]) {
        state.transcripts = {
          ...(state.transcripts as Record<string, UserLibraryTranscriptType>),
          [userUploadFromCable.bucket_key]: BASE_TRANSCRIPT,
        };
      }
    },
    cableUpdateToDashboardVideoDetailAssetsSuccess(
      state,
      action: PayloadAction<{
        hasFullResponse: boolean;
        hasPreview: boolean;
        language: LanguageId;
        userUploadFromCable: UserUploadsType;
        transcript: BaseTranscriptWord[][];
      }>,
    ) {
      const {language, userUploadFromCable, transcript} = action.payload;
      const newUserUploads = [...state.userUploads];

      const uploadedUserUploads = newUserUploads.map(uploadEl => {
        if (uploadEl.bucket_key === userUploadFromCable.bucket_key) {
          return userUploadFromCable;
        } else {
          return uploadEl;
        }
      });
      state.userUploads = uploadedUserUploads;

      state.transcripts = {
        ...state.transcripts,
        [userUploadFromCable.bucket_key]: {
          [language]: {
            ...BASE_TRANSCRIPT,
            transcript: transcript,
            loading: false,
          },
        },
      };
    },
    cableUpdateToDashboardVideoDetailAssetsFailed(state, action) {
      const {userUploadFromCable, error} = action.payload;
      console.error('Sorry to tell ya, it didnt happen', error);
      state.transcripts = {
        ...state.transcripts,
        [userUploadFromCable.bucket_key]: {
          ...BASE_TRANSCRIPT,
          loading: false,
        },
      };
    },
    sendUpdatedTranscriptEditStart(
      state,
      action: PayloadAction<{
        userUploadId: string;
        language: LanguageId;
        transcript: TranscriptJSON;
      }>,
    ) {
      const {userUploadId, transcript, language} = action.payload;
      state.transcripts[userUploadId][language]!.isEditingCaptionLoading = true;
      state.transcripts[userUploadId][language]!.transcript = transcript;
    },
    sendUpdatedTranscriptEditSuccess(
      state,
      action: PayloadAction<{userUploadId: string; language: LanguageId}>,
    ) {
      const {userUploadId, language} = action.payload;
      state.transcripts[userUploadId][language]!.isEditingCaptionLoading = false;
    },
    sendUpdatedTranscriptEditFailure(
      state,
      action: PayloadAction<{userUploadId: string; language: LanguageId}>,
    ) {
      const {userUploadId, language} = action.payload;
      state.transcripts[userUploadId][language]!.isEditingCaptionLoading = false;
    },
    createUploadFolderStart(state, action) {
      state.loadingUploadFolders = true;
    },
    createUploadFolderSuccess(state, action) {
      const {folders, folderItems} = action.payload;
      state.userUploadFolders = folders;
      state.userUploadFolderItems = folderItems;
      state.loadingUploadFolders = false;
    },
    createUploadFolderFailure(state, action) {
      state.loadingUploadFolders = false;
    },
    fetchUserUploadFoldersStart(state, action) {
      state.loadingUploadFolders = true;
    },
    fetchUserUploadFoldersSuccess(state, action) {
      const {folders, folderItems} = action.payload;
      state.userUploadFolders = folders;
      state.userUploadFolderItems = folderItems;
      state.loadingUploadFolders = false;
    },
    fetchUserUploadFoldersFailure(state, action) {
      state.loadingUploadFolders = false;
    },

    submitNewProjectNameStarted(state, action) {
      const {projectId, newProjectName} = action.payload;
      let tempIndex;
      state.userProjects.find(
        (project: UserLibraryUserProjectType, projectIndex: number) => {
          if (project.slug === projectId) {
            tempIndex = projectIndex;
          }
        },
      );
      if (tempIndex !== undefined) {
        state.userProjects[tempIndex].name = newProjectName;
      }
    },
    submitNewProjectNameSuccessful(state, action) {
      // const {projectId} = action.payload;
    },
    submitNewProjectNameFailed(state, action) {
      const {err, projectId} = action.payload;
      console.error('err', err);
    },
    getAccountSearchQueriesStart(state, action) {
      state.loadingAccountSearchQueries = true;
    },
    getAccountSearchQueriesSuccess(state, action) {
      const {accountSearchQueries} = action.payload;
      state.loadingAccountSearchQueries = false;
      state.accountSearchQueries = accountSearchQueries;
    },
    getAccountSearchQueriesFailure(state, action) {
      state.loadingAccountSearchQueries = false;
    },
    postAccountSearchQueryStart(state, action) {
      state.processingAccountSearchQuery = true;
      state.accountSearchResults = {
        ...initialUserLibraryState.accountSearchResults,
        hasResults: false,
        searchQuery: action.payload.query,
      };
    },
    postAccountSearchQuerySuccess(
      state,
      action: PayloadAction<{
        searchQuery: string;
        accountSearchQueries: {
          uploads: {
            results: UserUploadsType[];
          };
          transcripts: {
            results: ClipTranscriptResponseType[];
          };
          clips: {
            results: UploadMediaClipType[];
          };
          transcripts_word_instances: {
            results: SearchTranscriptWordInstances[];
          };
        };
      }>,
    ) {
      const {accountSearchQueries, searchQuery} = action.payload;
      state.accountSearchResults = {
        ...accountSearchQueries,
        hasResults: true,
        searchQuery,
      };
      state.processingAccountSearchQuery = false;
    },
    postAccountSearchQueryFailure(state, action) {
      state.processingAccountSearchQuery = false;
    },
    updateClipLabelColorStart(
      state,
      action: PayloadAction<{
        newColor: string;
        clipIds: string[];
        shouldRemove?: boolean;
      }>,
    ) {
      const {shouldRemove, clipIds, newColor} = action.payload;
      clipIds.forEach((clipId: string) => {
        state.userClips.find(userClip => {
          if (userClip.id === clipId) {
            if (shouldRemove) {
              const newLabels = userClip.labels.filter(label => {
                return label.label_value !== newColor;
              });
              userClip.labels = newLabels;
            } else {
              userClip.labels = [
                ...userClip.labels,
                {label_type: 'color', label_value: newColor},
              ];
            }
            return true;
          }
          return false;
        });
      });
    },
    updateClipLabelColorSuccess(state, action) {},
    resetClipLabelColorStart(state, action: PayloadAction<{clipIds: string[]}>) {
      const {clipIds} = action.payload;
      clipIds.forEach((clipId: string) => {
        state.userClips.find(userClip => {
          if (userClip.id === clipId) {
            userClip.labels = [];
            return true;
          }
          return false;
        });
      });
    },
    updateClipLabelColorFailure(state, action) {},

    resetClipLabelColorSuccess(state, action) {},
    resetClipLabelColorFailure(state, action) {},
    saveUpdateGlossaryStart(state, action: PayloadAction<{glossary: Glossary}>) {
      state.accountSettingsStatuses.isAccountGlossarySubmitting = true;
      const {glossary} = action.payload;
    },
    saveUpdateGlossarySuccess(state, action) {
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationInfo
            title={'Glossary Saved'}
            message={'Glossary was successfully saved.'}
          />
        ),
      });
      state.accountSettingsStatuses.isAccountGlossarySubmitting = false;
    },
    saveUpdateGlossaryFailure(state, action) {
      state.accountSettingsStatuses.isAccountGlossarySubmitting = false;
    },
    getGlossaryStart(state) {},
    getGlossarySuccess(state, action: PayloadAction<Glossary>) {
      state.glossary = action.payload;
    },
    getGlossaryFailure(state, action) {},
    saveLocalGlossaryTerms(state, action: PayloadAction<Glossary>) {
      state.glossary = action.payload;
    },
  },
});

export const {
  cableUpdateToDashboardVideoDetailAssetsFailed,
  cableUpdateToDashboardVideoDetailAssetsStart,
  cableUpdateToDashboardVideoDetailAssetsSuccess,
  createUploadFolderFailure,
  submitDuplicateProjectFailure,
  createUploadFolderStart,
  createUploadFolderSuccess,
  createUserUploadClipFailure,
  createUserUploadClipStart,
  createUserUploadClipSuccess,
  fetchUserUploadFoldersFailure,
  fetchUserUploadFoldersStart,
  fetchUserUploadFoldersSuccess,
  finishDuplication,
  getAccountSearchQueriesFailure,
  getAccountSearchQueriesStart,
  getAccountSearchQueriesSuccess,
  getFileUploadAnalyticsStart,
  getFileUploadAnalyticsSuccess,
  getFileUploadTranscriptFailure,
  getFileUploadTranscriptStart,
  getFileUploadTranscriptSuccess,
  getFileUploadVersionsFailure,
  getFileUploadVersionsSuccess,
  getFileUploadVersionstStart,
  getGlossaryFailure,
  getGlossaryStart,
  getGlossarySuccess,
  getProjectDataFailure,
  getProjectDataStart,
  getProjectDataSuccess, // TODO(lenny): Remove this or replace it
  getUserClipsFailure,
  getUserClipsStart,
  getUserClipsSuccess,
  getUserClipStart,
  getUserClipSuccess,
  getUserProjectsFailure,
  getUserProjectsStart,
  getUserProjectsSuccess,
  getUserTemplatesFailure,
  getUserTemplatesStart,
  getUserTemplatesSuccess,

  postAccountSearchQueryFailure,
  postAccountSearchQueryStart,
  postAccountSearchQuerySuccess,

  requestAnalyticsEventSuccess,
  resetClipLabelColorFailure,
  resetClipLabelColorStart,
  resetClipLabelColorSuccess,
  saveLocalGlossaryTerms,
  saveUpdateGlossaryFailure,
  saveUpdateGlossaryStart,
  saveUpdateGlossarySuccess,
  sendUpdatedTranscriptEditFailure,
  sendUpdatedTranscriptEditStart,
  sendUpdatedTranscriptEditSuccess,
  submitDeleteMultipleProjectsStart,
  submitDeleteMultipleProjectsSuccess,
  submitDeleteProjectFailure,
  submitDeleteProjectStart,
  submitDeleteProjectSuccess,
  submitDeleteUserClipFailure,
  submitDeleteUserClipStart,
  submitDeleteUserClipSuccess,

  submitDuplicateProjectStart,
  submitDuplicateProjectSuccess,
  submitNewClipNameFailed,
  submitNewClipNameStarted,
  submitNewClipNameSuccessful,
  submitNewProjectNameFailed,
  submitNewProjectNameStarted,
  submitNewProjectNameSuccessful,
  updateClipLabelColorFailure,
  updateClipLabelColorStart,
  updateClipLabelColorSuccess,
  updateSelectedProjectIds,
} = userLibrarySlice.actions;

export type UserLibraryShareProjectType = {
  isAccountSubscribed: boolean;
  loading: boolean;
  createdAt: null | string;
  name: null | string;
  status: null | string;
  totalViews: TotalViewType;
  layers: any[];
};

export const BASE_TRANSCRIPT = {
  transcript: null,
  loading: true,
  isEditingCaptionLoading: true,
  error: null,
} as UserLibraryTranscriptType;

export const BASE_TRANSCRIPT_CHAPTERS = {
  chapters: null,
  loading: true,
  error: null,
} as UserLibraryTranscriptChaptersType;

export const duplicateProject =
  (token: string, projectId: string, shouldNavigateAfterDuplicate = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitDuplicateProjectStart());
      const {userProject, latestProjectSync} = await submitProjectDuplicate(
        token,
        projectId,
      );

      dispatch(submitDuplicateProjectSuccess({userProject}));
      if (shouldNavigateAfterDuplicate) {
        dispatch(
          syncProjectState(userProject.slug, {
            clientState: latestProjectSync.client_state,
            client_sync_version: latestProjectSync.client_sync_version,
            lastUpdated: latestProjectSync.updated_at,
          }),
        );
        dispatch(finishDuplication());
      }

      window.location.href = `/${routePaths.create}/${DashParams.CanvasParam}/${userProject.slug}`;
    } catch (err) {
      dispatch(submitDuplicateProjectFailure(err));
    }
  };

export const updateClipName =
  (token: string, clipId: string, newClipName: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitNewClipNameStarted());
      const {data: updatedClip}: {data: UploadMediaClipType} = await postNewClipName(
        token,
        clipId,
        newClipName,
      );

      // dispatch(fetchUserUploads(token, false));
      dispatch(
        submitNewClipNameSuccessful({
          updatedClip,
        }),
      );
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationInfo
            title={'Clip renamed'}
            message={'Clip was successfully renamed.'}
          />
        ),
      });
    } catch (err) {
      dispatch(submitNewClipNameFailed(err));
    }
  };

export const deleteMultipleProjects =
  (token: string, projectIds: string[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitDeleteMultipleProjectsStart({projectIds}));
      const userProjects = await submitMultipleProjectsDelete(token, projectIds);
      dispatch(submitDeleteMultipleProjectsSuccess({projectIds, userProjects}));
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationInfo
            title={'Projects deleted'}
            message={'Projects were successfully deleted.'}
          />
        ),
      });
    } catch (err) {
      dispatch(submitDeleteProjectFailure(err));
    }
  };

export const deleteProject =
  (token: string, projectId: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitDeleteProjectStart({projectId}));
      const userProjects = await submitProjectDelete(token, projectId);
      dispatch(submitDeleteProjectSuccess({projectId, userProjects}));
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationInfo
            title={'Project deleted'}
            message={'Project was successfully deleted.'}
          />
        ),
      });
    } catch (err) {
      dispatch(submitDeleteProjectFailure(err));
    }
  };

export const deleteUserClip =
  (token: string, clipId: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(submitDeleteUserClipStart());
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationInfo
            title={'Clip deleted'}
            message={'Clip was successfully deleted.'}
          />
        ),
      });
      const userClips = await submitUserClipDelete(token, clipId);
      dispatch(submitDeleteUserClipSuccess());
    } catch (err) {
      dispatch(submitDeleteUserClipFailure(err));
    }
  };

// Refactor TODO (jacques): Move over to RTK query
// and reuse logic from BrandKit uploads.
// Then clean up BrandKit logo uploads.
export const uploadUserFile =
  (
    token: string,
    file: UserUpload,
    callback?: (err?: Error, id?: string) => void,
  ): AppThunk =>
  async (dispatch, getState) => {
    const state: GetRootState = getState();
    const {email, accountId} = accountDetailsSelector(state);

    if (email && accountId) {
      try {
        const data = {
          user_upload: {
            ...file,
            account_id: accountId,
          },
        };

        const fileData = await postUploadUserFile(token, data, email, accountId);

        store.addNotification({
          ...NOTIFICATION_BASE,
          content: (
            <NotificationInfo
              title={'File uploaded'}
              message={'Your file was successfully uploaded.'}
            />
          ),
        });
        callback?.(undefined, fileData.id);
      } catch (err) {
        console.error(err);
        // @ts-ignore
        callback?.(err);
      }
    }
  };

type CreateUserUploadClipOption = {
  thumbnailUrl: string;
  content: string;
  clipStart: TimeSeconds;
  clipEnd: TimeSeconds;
  uploadBucketKey: string;
  language: LanguageId;
};

export const createUserUploadClip = (
  token: string,
  options: CreateUserUploadClipOption,
  callback?: (error?: Error) => void,
): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const {email, accountId} = accountDetailsSelector(state);

    if (!email || !accountId) return;

    const clipDuration = options.clipEnd - options.clipStart;
    // Clip must be longer than 0.5s, otherwise Mux will not process the request
    if (clipDuration < 0.5) {
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationError
            title={'Clip creation failed'}
            message={`Effective clip duration of ${clipDuration} too short, minimum clip duration is 0.500 seconds. Please try again.`}
          />
        ),
      });
      dispatch(createUserUploadClipFailure({}));
      callback?.();
    } else {
      try {
        dispatch(createUserUploadClipStart());
        const clipData = await postCreateUserUploadClip(token, options, email, accountId);
        // TODO (jacques): Remove workaround by getting RNC to work with Jest
        if (!process.env.JEST_WORKER_ID) {
          store.addNotification({
            ...NOTIFICATION_BASE,
            content: (
              <NotificationInfo
                title={'Clip created'}
                message={'Your selected clip was successfully created.'}
              />
            ),
          });
        }
        dispatch(createUserUploadClipSuccess({clipData}));
        callback?.();
      } catch (err) {
        if (!process.env.JEST_WORKER_ID) {
          store.addNotification({
            ...NOTIFICATION_BASE,
            content: (
              <NotificationError
                title={'Creating clip failed'}
                message={'Your clip could not be created. Please try again.'}
              />
            ),
          });
        }
        dispatch(createUserUploadClipFailure(err));
        // @ts-ignore
        callback?.(err);
      }
    }
  };
};

type RequestAnalyticsEventProps = {
  token: string;
  visitorId: string;
  visitorAhoyUuid: string;
  eventName: string;
  resourceId: string;
};

export const requestAnalyticsEvent =
  ({
    token,
    visitorId,
    visitorAhoyUuid,
    eventName,
    resourceId,
  }: RequestAnalyticsEventProps): AppThunk =>
  async dispatch => {
    try {
      const response = await postAnalyticsEvent({
        token,
        visitorId,
        visitorAhoyUuid,
        eventName,
        resourceId,
      });
      dispatch(requestAnalyticsEventSuccess());
    } catch (err) {
      //
    }
  };

type SetupNewProjectType = {
  token: string;
  email: string;
};

export const setupNewProject =
  ({token, email}: SetupNewProjectType): AppThunk =>
  async dispatch => {
    // console.log('token', token);
    // console.log('email', email);
    // console.log('templateId', templateId);
    let projectData;
    try {
      dispatch(getProjectDataStart());
      projectData = await getNewProject(token, email);
      const projectId = projectData.slug;
      dispatch(replace(`/${routePaths.create}/${DashParams.CanvasParam}/${projectId}`));

      // dispatch(fetchProjectData({ token, email, bucketKey: projectId }));
    } catch (err) {
      console.error('err', err);
      dispatch(getProjectDataFailure(err));
    }
  };

export const fetchProjectData =
  (
    token: string,
    email: string,
    projectId: string,
    callback?: (error?: Error) => void,
  ): AppThunk =>
  async dispatch => {
    let projectData;
    let projectUserData;
    let projectAccount;
    try {
      dispatch(getProjectDataStart());
      // Refactor TODO (jacques): Remove all projectId === 'new' logic
      // and replace with useCreateProject hook
      if (projectId !== 'new') {
        const rawProjectData = await getProjectData(token, projectId);
        const latestProjectSyncState = await getLatestProjectSync({
          token,
          projectId,
        });
        // console.log('bucketKey', bucketKey);
        // console.log('rawProjectData', rawProjectData);
        projectAccount = rawProjectData.project_account || {}; // Remove once backend deploy complete
        projectUserData = rawProjectData.project_user;
        projectData = rawProjectData.project;
        if (latestProjectSyncState && latestProjectSyncState.client_state) {
          // console.log('newLatestProjectState', newLatestProjectState);
          dispatch(
            syncProjectState(projectId, {
              clientState: latestProjectSyncState.client_state,
              client_sync_version: latestProjectSyncState.client_sync_version,
              lastUpdated: latestProjectSyncState.updated_at,
            }),
          );
        } else {
          dispatch(syncingProjectStoreFailed({projectId}));
        }
      } else {
        projectData = await getNewProject(token, email);
      }

      dispatch(getProjectDataSuccess({projectAccount, projectData, projectUserData})); // TODO(lenny): Identify where this is used and remove it
      callback?.();
    } catch (err) {
      console.error('err', err);
      dispatch(getProjectDataFailure(err));
      // @ts-ignore
      callback?.(err);
    }
  };

export const fetchDownloadData =
  (token: string, email: string, projectId: string): AppThunk =>
  async dispatch => {
    let projectData;
    let projectUserData;
    let projectAccount;
    try {
      dispatch(getProjectDataStart());

      const rawProjectData = await getProjectData(token, projectId);

      projectAccount = rawProjectData.project_account || {}; // Remove once backend deploy complete
      projectUserData = rawProjectData.project_user;
      projectData = rawProjectData.project;

      dispatch(getProjectDataSuccess({projectAccount, projectData, projectUserData})); // TODO(lenny): Identify where this is used and remove it
    } catch (err) {
      console.error('err', err);
      dispatch(getProjectDataFailure(err));
    }
  };

export const fetchFileUploadAnalytics =
  (token: string, bucketKey: string, callback: () => void): AppThunk =>
  async dispatch => {
    try {
      dispatch(getFileUploadAnalyticsStart());
      const analytics = await getUserUploadAnalytics(token, bucketKey);
      dispatch(getFileUploadAnalyticsSuccess({bucketKey, analytics, callback}));
    } catch (err) {
      // TODO: dispatch(getFileUploadAnalyticsFailure({ bucketKey, err }));
    }
  };

export const fetchFileUploadVersionHistory =
  (token: string, bucketKey: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(getFileUploadVersionstStart({bucketKey}));
      const versions = await getFileUploadVersions(token, bucketKey);
      dispatch(getFileUploadVersionsSuccess({bucketKey, versions}));
    } catch (err) {
      dispatch(getFileUploadVersionsFailure({bucketKey, err}));
    }
  };

export const fetchFileUploadTranscript = (
  token: string,
  userUploadId: string,
  language: LanguageId,
): AppThunk => {
  return async (dispatch, getState) => {
    const {userLibrary} = getState();
    if (userLibrary.transcripts[userUploadId]?.[language]?.loading) return;

    try {
      dispatch(getFileUploadTranscriptStart({userUploadId, language}));

      const response = await getFileUploadTranscript(token, userUploadId, language);
      dispatch(getFileUploadTranscriptSuccess({userUploadId, language, ...response}));
    } catch (error) {
      dispatch(
        // @ts-ignore
        getFileUploadTranscriptFailure({userUploadId, language, error: error.toString()}),
      );
    }
  };
};

export const fetchUserProjects =
  (token: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(
        getUserProjectsStart({
          loadingOverride: true,
        }),
      );
      const userProjects = await getUserProjects(token);
      dispatch(getUserProjectsSuccess({userProjects}));
    } catch (err) {
      dispatch(getUserProjectsFailure(err));
    }
  };

export const fetchUserTemplates =
  (token: string): AppThunk =>
  async (dispatch, getState) => {
    const state: GetRootState = getState();
    const {email} = state.auth;
    if (email) {
      try {
        dispatch(
          getUserTemplatesStart({
            loadingOverride: true,
          }),
        );
        const userTemplates = await getUserTemplates(token, email);
        dispatch(getUserTemplatesSuccess({userTemplates: userTemplates.templates}));
      } catch (err) {
        dispatch(getUserTemplatesFailure(err));
      }
    }
  };

export const updateUserProjects =
  (token: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(getUserProjectsStart({loadingOverride: false}));
      const userProjects = await getUserProjects(token);
      dispatch(getUserProjectsSuccess({userProjects}));
    } catch (err) {
      dispatch(getUserProjectsFailure(err));
    }
  };

export const fetchUserClips =
  (token: string, loadingOverride: boolean): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(
        getUserClipsStart({
          loadingOverride,
        }),
      );
      const userClips = await getUserClips(token);
      dispatch(getUserClipsSuccess({userClips}));
    } catch (err) {
      dispatch(getUserClipsFailure(err));
    }
  };

export const resetProject =
  (projectId: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(resetSceneFrame({projectId}));
      dispatch(resetCanvas(projectId));
    } catch (err) {
      dispatch(getUserProjectsFailure(err));
    }
  };

export const updateTranscript = ({
  token,
  transcript,
  userUploadId,
  language,
  editedText,
}: {
  token: string;
  transcript: TranscriptJSON;
  userUploadId: string;
  language: LanguageId;
  editedText: string;
}): AppThunk => {
  return async dispatch => {
    try {
      dispatch(sendUpdatedTranscriptEditStart({userUploadId, transcript, language}));

      await postUpdatedTranscriptEdit({
        token,
        language,
        userUploadId,
        transcript,
        editedText,
      });

      dispatch(sendUpdatedTranscriptEditSuccess({userUploadId, language}));
    } catch (error) {
      // @ts-ignore
      showErrorNotification({title: "Couldn't save edit", message: error.message});
      dispatch(sendUpdatedTranscriptEditFailure({userUploadId, language}));
    }
  };
};

export const createUploadFolder =
  ({
    token,
    email,
    newFolderName,
    parentFolderId,
  }: {
    token: string;
    email: string;
    newFolderName: string;
    parentFolderId: string | null;
  }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(createUploadFolderStart({}));
    try {
      const {
        folders,
        folder_items: folderItems,
      }: {
        folders: UserUploadFolders;
        folder_items: UserUploadFolderItems;
      } = await postNewUploadFolder(token, email, newFolderName, parentFolderId);
      dispatch(
        createUploadFolderSuccess({
          folders,
          folderItems,
        }),
      );
    } catch (error) {
      dispatch(createUploadFolderFailure({}));
    }
  };

export const fetchUserUploadFolders =
  (token: string): AppThunk =>
  async (dispatch, getState) => {
    const state: GetRootState = getState();
    const {email} = state.auth;

    if (email) {
      dispatch(fetchUserUploadFoldersStart({}));
      try {
        const {
          folders,
          folder_items: folderItems,
        }: {
          folders: UserUploadFolders;
          folder_items: UserUploadFolderItems;
        } = await getUserUploadFolders(token, email);
        dispatch(
          fetchUserUploadFoldersSuccess({
            folders,
            folderItems,
          }),
        );
      } catch (error) {
        dispatch(fetchUserUploadFoldersFailure({}));
      }
    }
  };

export const updateUploadedFolderName =
  (token: string, folderId: string, folderName: string): AppThunk =>
  async (dispatch, getState) => {};

export const deleteUploadFolder =
  (token: string, folderId: string): AppThunk =>
  async (dispatch, getState) => {};

export const fetchAccountSearch =
  (token: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(getAccountSearchQueriesStart({}));

    try {
      const response = await getAccountSearchQueries({token});
      dispatch(
        getAccountSearchQueriesSuccess({
          accountSearchQueries: response.payload.search_queries,
        }),
      );
    } catch (error) {
      console.error(error);
      dispatch(getAccountSearchQueriesFailure({}));
    }
  };

export const createAccountSearchQuery =
  (token: string, query: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(postAccountSearchQueryStart({query}));

    try {
      const response = await postAccountSearchQuery({token, query});
      dispatch(
        postAccountSearchQuerySuccess({
          searchQuery: query,
          accountSearchQueries: response.payload,
        }),
      );
    } catch (error) {
      console.error(error);
      dispatch(postAccountSearchQueryFailure({}));
    }
  };

export const updateClipLabelColor =
  (token: string, newColor: string, clipIds: string[], shouldRemove: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      updateClipLabelColorStart({
        newColor,
        clipIds,
        shouldRemove,
      }),
    );

    try {
      const response = await postUpdateClipLabelColor({
        token,
        newColor,
        clipIds,
        shouldRemove,
      });
      dispatch(updateClipLabelColorSuccess({}));
    } catch (error) {
      console.error(error);
      dispatch(updateClipLabelColorFailure({}));
    }
  };

export const resetClipLabelColor =
  (token: string, clipIds: string[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      resetClipLabelColorStart({
        clipIds,
      }),
    );

    try {
      const response = await postResetClipLabelColor({
        token,
        clipIds,
      });
      dispatch(resetClipLabelColorSuccess({}));
    } catch (error) {
      console.error(error);
      dispatch(resetClipLabelColorFailure({}));
    }
  };

export const saveUpdateGlossary =
  (token: string, glossary: Glossary): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      saveUpdateGlossaryStart({
        glossary,
      }),
    );

    try {
      const response = await putUpdateGlossary(token, glossary);
      dispatch(saveUpdateGlossarySuccess({}));
    } catch (error) {
      dispatch(saveUpdateGlossaryFailure({}));
    }
  };

export const getGlossary =
  (token: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(getGlossaryStart());

    try {
      const response = await getShowGlossary(token);
      dispatch(getGlossarySuccess(response));
    } catch (error) {
      dispatch(saveUpdateGlossaryFailure({}));
    }
  };

export default userLibrarySlice.reducer;
