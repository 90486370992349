import {useCallback, useState} from 'react';
import {UnreachableCaseError} from './UnreachableCaseError';

type TimeUnit = 's' | 'ms';

export type SerializedTime = {ms: number};

export class Time {
  ms: number;

  constructor(serializedTime: SerializedTime);
  constructor(value: number | string, unit?: TimeUnit);
  constructor(value: number | string | SerializedTime, unit: TimeUnit = 'ms') {
    if (typeof value === 'number' || typeof value === 'string') {
      this.ms = this._valueToMs(value, unit);
    } else {
      this.ms = value.ms;
    }
  }

  get s() {
    return this.ms / 1000;
  }

  get units() {
    const minutes = Math.floor(this.ms / 1000 / 60);
    const seconds = Math.floor(this.ms / 1000) - minutes * 60;
    const deciseconds = Math.floor(this.ms / 10) - seconds * 100 - minutes * 60 * 100;

    return {minutes, seconds, deciseconds};
  }

  get prettyObject() {
    const {minutes, seconds, deciseconds} = this.units;

    return {
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
      deciseconds: String(deciseconds).padStart(2, '0'),
    };
  }

  get pretty() {
    const {minutes, seconds, deciseconds} = this.units;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0',
    )}.${String(deciseconds).padStart(2, '0')}`;
  }

  clone() {
    return new Time(this.ms, 'ms');
  }

  add(amount: number, unit?: TimeUnit): Time;
  add(time: Time): Time;
  add(amount: number | Time, unit?: TimeUnit) {
    if (amount instanceof Time) {
      return new Time(this.ms + amount.ms);
    } else {
      return new Time(this.ms + new Time(amount, unit).ms);
    }
  }

  sub(amount: number, unit?: TimeUnit): Time;
  sub(time: Time): Time;
  sub(amount: number | Time, unit?: TimeUnit) {
    if (amount instanceof Time) {
      return new Time(this.ms - amount.ms);
    } else {
      return new Time(this.ms - new Time(amount, unit).ms);
    }
  }

  toJSON(): SerializedTime {
    return {ms: this.ms};
  }

  toString() {
    return this.ms.toString();
  }

  private _valueToMs(value: number | string, unit: TimeUnit) {
    if (unit === 's') {
      return Number(value) * 1000;
    } else if (unit === 'ms') {
      return Number(value);
    } else {
      throw new UnreachableCaseError(unit);
    }
  }
}

export function useTimeState(initialValue?: number, initialUnit?: TimeUnit) {
  const [timeClass, setTimeClass] = useState(() => {
    if (initialValue != null && initialUnit != null) {
      return new Time(initialValue, initialUnit);
    } else {
      return new Time(0, 'ms');
    }
  });

  const setValue = useCallback((value: number, unit: TimeUnit) => {
    setTimeClass(new Time(value, unit));
  }, []);

  return [timeClass, setValue];
}

export function objToTime<
  O extends Record<string | number | symbol, number>,
  K extends keyof O,
>(object: O, unit?: TimeUnit) {
  const timeObj = {} as any;
  for (const key in object) {
    timeObj[key] = new Time(object[key], unit);
  }
  return timeObj as Record<K, Time>;
}

export function timeObjToMs<
  O extends Record<string | number | symbol, SerializedTime>,
  K extends keyof O,
>(object: O) {
  const timeObj = {} as any;
  for (const key in object) {
    timeObj[key] = object[key].ms;
  }
  return timeObj as Record<K, number>;
}
