import {SanityTemplateEntryResponse} from 'services/templatesAPI';
import {
  UserLibraryUserProjectType,
  UserLibraryUserProjectTypeWithCmsData,
} from 'features/types/userLibrarySlice';
import {sanityImage} from './sanityClient';

export const convertSanityEntryToTemplate = (
  sanityTemplateResult: SanityTemplateEntryResponse,
): UserLibraryUserProjectTypeWithCmsData => {
  const templateEntry = JSON.parse(
    sanityTemplateResult.data,
  ) as UserLibraryUserProjectType;

  const templateImageUrl = sanityTemplateResult.image
    ? sanityImage(sanityTemplateResult.image).url()
    : '';

  return {
    ...templateEntry,
    latest_preview: templateImageUrl,
    description: sanityTemplateResult.description,
    tags: sanityTemplateResult.tags,
    categories: sanityTemplateResult.categories,
  };
};
