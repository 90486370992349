/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';

import { ApiRecordingSession } from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import { Button } from 'ui/Button';
import { MilkExplainerSlideOver } from './MilkExplainerSlideOver';
import MilkLogo from 'features/Dashboard/shared/MilkLogo';
import { Outlet } from 'react-router-dom';
import { RecordingSessionCheck } from '../../features/RecordingForms/recording/contexts/RecordingSessionCheck';
import { ahoy } from 'features/providers/TrackingVisitProvider';

export function RecordingWrap() {
  // console.log('Loading RecordingInteractionFormWrap');

  const [open, setOpen] = useState(false);

  const showExplainerButton = false;

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <div className="relative min-h-full">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center  justify-between space-y-4 rounded-b-lg bg-indigo-50 py-3 px-3 sm:flex-row sm:space-y-0 sm:px-6">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <a href="https://milkvideo.com">
                  <h1 className="flex items-center space-x-1">
                    <MilkLogo />
                    <span className="font-semibold text-gray-800">Milk Video</span>
                  </h1>
                </a>
              </div>
            </div>
            <div className="ml-6 flex items-center space-x-3">
              <h1 className="flex items-center text-xs">
                Use Milk Video to create, edit, and share video ads.
              </h1>
              <a href="https://milkvideo.com">
                <Button
                  _className="!text-xs !font-semibold"
                  variant="secondary"
                  size="small"
                >
                  Sign up for free
                </Button>
              </a>
            </div>
          </div>
        </div>

        <div className="py-10">
          <header>
            {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Dashboard
              </h1>
            </div> */}
          </header>
          <main>
            <div className="mx-auto w-full max-w-5xl sm:px-6 lg:px-8">
              {/* Replace with your content */}
              <ApiRecordingSession.Provider>
                <RecordingSessionCheck>
                  <Outlet />
                </RecordingSessionCheck>
              </ApiRecordingSession.Provider>
              {/* /End replace */}
            </div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              {showExplainerButton && (
                <Button
                  variant="tertiary"
                  _className="!fixed bottom-10"
                  onClick={() => setOpen(true)}
                >
                  What is this?
                </Button>
              )}
            </div>
          </main>
        </div>
      </div>
      <MilkExplainerSlideOver open={open} setOpen={setOpen} />
    </>
  );
}
