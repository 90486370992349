import {Route, Routes} from 'react-router-dom';

import {CreateSampleUpload} from 'pages/Dashboard/CreateSampleUpload';
import {ExternalFontNamePreviewPage} from 'pages/PreviewRender/ExternalFontNamePreviewPage';
import {FontNamePreviewPage} from 'pages/PreviewRender/FontNamePreviewPage';
import {FramePage} from 'pages/Canvas/FramePage';
import {PreviewFontPageWrap} from 'pages/PreviewRender/PreviewFontPageWrap';
import {PreviewTemplateCanvas} from 'pages/Canvas/PreviewTemplateCanvas';
import {ScreenshotPage} from 'pages/Canvas/ScreenshotPage';
import {SocialCardPreviewPage} from 'pages/PreviewRender/SocialCardPreviewPage';
import {SocialCardPreviewPageWrap} from 'pages/PreviewRender/SocialCardPreviewPageWrap';
import {Suspense} from 'react';
import ZoomUploadStartedPage from 'features/Dashboard/DashboardPage/ContentSettings/ConnectedAccounts/ZoomUploadStartedPage';

window.readyForScreenshot = false;

/**
 * Routes under /robot-routes/*
 */
export const RoutesProgramaticRelated = () => {
  return (
    <Routes>
      <Route path="sample_upload" element={<CreateSampleUpload />} />
      <Route path="zoom-upload-started" element={<ZoomUploadStartedPage />} />
      <Route
        path="font-specimen-preview/*"
        element={<PreviewFontPageWrap ignoreRedirect />}
      >
        <Route
          path=":accountFontUploadId"
          element={
            <Suspense fallback={<div />}>
              <FontNamePreviewPage displayText="AaBbCc" />
            </Suspense>
          }
        />
      </Route>

      <Route path="font-name-preview/*" element={<PreviewFontPageWrap ignoreRedirect />}>
        <Route
          path=":accountFontUploadId"
          element={
            <Suspense fallback={<div />}>
              <FontNamePreviewPage />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="external-font-name-preview/*"
        element={<PreviewFontPageWrap ignoreRedirect />}
      >
        <Route
          path=":externalFontId"
          element={
            <Suspense fallback={<div />}>
              <ExternalFontNamePreviewPage />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="external-font-specimen-preview/*"
        element={<PreviewFontPageWrap ignoreRedirect />}
      >
        <Route
          path=":externalFontId"
          element={
            <Suspense fallback={<div />}>
              <ExternalFontNamePreviewPage displayText="AaBbCc" />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="social-card-preview/*"
        element={<SocialCardPreviewPageWrap ignoreRedirect />}
      >
        <Route
          path=":contentId"
          element={
            <Suspense fallback={<div />}>
              <SocialCardPreviewPage />
            </Suspense>
          }
        />
      </Route>

      <Route path="/preview-template/:templateSlug" element={<PreviewTemplateCanvas />} />

      <Route path="screenshot/:projectId" element={<ScreenshotPage />} />
      <Route path="frame/:projectId" element={<FramePage />} />
    </Routes>
  );
};
