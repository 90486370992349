import {UploadMediaClipType, uploadMediaClipAPI} from 'services/uploadMediaClipAPI';
import {filterByCount, sortedArrById} from './utils';
import {useEffect, useMemo, useRef, useState} from 'react';

import ClipListResults from './ClipListResults';
import {ListFilter} from './constants';
import {LoadingClipsList} from './LoadingClipsList';
import {useClipList} from './ClipListContext';
import {useInfiniteQuery} from 'services/useInfiniteQuery';

export const ClipContentWrap = () => {
  const {
    state: {activeListFilters},
  } = useClipList();

  const {
    hasNextPage: hasNextClipsPage,
    fetchNextPage: fetchNextClipsPage,
    resultPages: userClipsPages,
    data: clipsData,
  } = useInfiniteQuery(uploadMediaClipAPI.endpoints.listUploadMediaClips, {
    getNextPageParam: lastPage => lastPage.next ?? undefined,
  });

  const {
    pages: clipsPages,
    page: clipsPage,
    count: clipsCount,
  } = clipsData || {
    pages: 0,
    page: 0,
    count: 0,
  };

  useEffect(() => {
    if (clipsPages > clipsPage && hasNextClipsPage) {
      fetchNextClipsPage();
    }
  }, [hasNextClipsPage, clipsPages, clipsPage, clipsCount]);

  const userClips = useMemo(() => {
    if (!userClipsPages) {
      return [];
    }
    return userClipsPages.map(userClipsPage => userClipsPage.data).flat();
  }, [userClipsPages]);

  /**
   * Take a list of user clips and surface the ones that appear more than once.
   *
   * @param arr
   * @returns
   */
  const findDuplicateClips = (arr: UploadMediaClipType[], minAppearCount: number) => {
    const sortedArr = sortedArrById(arr);
    const results = [];
    for (let i = 0; i < sortedArr.length - 1; i++) {
      if (sortedArr[i + 1].id === sortedArr[i].id) {
        results.push(sortedArr[i]);
      }
    }
    return filterByCount(results, minAppearCount);
  };

  /**
   * Memoized instance to run content filter against the list of clips.
   */
  const filteredUserClips = useMemo(() => {
    const contentFilters = activeListFilters.filter(
      filter => filter.filter_target === 'content',
    );

    const colorLabelFilters = activeListFilters.filter(
      filter => filter.filter_target === 'color-label',
    );

    const filteredResults = [];
    let numberFilterTypes = 0;

    const contentFilterResults = (contentFilters: ListFilter[]) => {
      if (contentFilters.length > 1) {
        const filteredClips = contentFilters.map(filter => {
          return userClips.filter(
            (item: UploadMediaClipType) =>
              item.text_snippet &&
              item.text_snippet.toLowerCase().includes(filter.filter_value.toLowerCase()),
          );
        });
        const flattenedResults = filteredClips.flat();

        return findDuplicateClips(flattenedResults, contentFilters.length - 1);
      } else if (contentFilters.length === 1) {
        const filteredClips = contentFilters.map(filter => {
          return userClips.filter(
            (item: UploadMediaClipType) =>
              item.text_snippet &&
              item.text_snippet.toLowerCase().includes(filter.filter_value.toLowerCase()),
          );
        });
        return filteredClips.flat();
      }

      return [];
    };

    const colorLabelFilterResults = (colorFilters: ListFilter[]) => {
      if (colorFilters.length > 1) {
        const filteredClips = colorFilters.map(filter => {
          return userClips.filter(
            (item: UploadMediaClipType) =>
              item.labels &&
              item.labels
                .map(label => label.label_value.toLowerCase())
                .includes(filter.filter_value.toLowerCase()),
          );
        });
        const flattenedResults = filteredClips.flat();

        return findDuplicateClips(flattenedResults, colorFilters.length - 1);
      } else if (colorFilters.length === 1) {
        const filteredClips = colorFilters.map(filter => {
          return userClips.filter(
            (item: UploadMediaClipType) =>
              item.labels &&
              item.labels
                .map(label => label.label_value.toLowerCase())
                .includes(filter.filter_value.toLowerCase()),
          );
        });

        return filteredClips.flat();
      }

      return [];
    };

    if (activeListFilters.length > 0) {
      if (contentFilters.length > 0) {
        numberFilterTypes = numberFilterTypes + 1;
        filteredResults.push(contentFilterResults(contentFilters).flat());
      }
      if (colorLabelFilters.length > 0) {
        numberFilterTypes = numberFilterTypes + 1;
        filteredResults.push(colorLabelFilterResults(colorLabelFilters).flat());
      }
      const flatResults = filteredResults.flat();

      if (numberFilterTypes > 1) {
        return findDuplicateClips(flatResults, numberFilterTypes - 1);
      } else {
        return flatResults;
      }
    } else {
      return userClips;
    }
  }, [userClips, activeListFilters]);

  return <ClipListResults userClips={userClips} filteredUserClips={filteredUserClips} />;
};
