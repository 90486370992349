import {useEffect, useState} from 'react';

import {AccountFontUploadsType} from 'features/types/userLibrarySlice';
import {BASE_REDIRECT_URL, BASE_URL} from 'constants/environment';
import FontPreviewer from 'features/FontPreviews/FontPreviewer';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import axios from 'axios';
import {useParams} from 'react-router-dom';

export function FontNamePreviewPage({displayText}: {displayText?: string}) {
  const {accountFontUploadId} = useParams() as Record<string, string>;
  const [font, setFont] = useState<null | AccountFontUploadsType>(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/api/v1/account_font_uploads/${accountFontUploadId}`,
        );
        setFont(response.data);
      } catch (error) {
        window.location.href = BASE_REDIRECT_URL;
      }
    })();
  }, []);

  if (!font) {
    return <LoadingSpinner />;
  } else {
    const uploadUrl = `${font.upload_url}`.replace('http:', 'https:');

    return (
      <FontPreviewer
        uploadUrl={uploadUrl}
        fontFamily={font.font_family}
        displayText={displayText ? displayText : font.font_fullname}
      />
    );
  }
}
