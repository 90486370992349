import { useDaily, useDevices, useLocalParticipant } from '@daily-co/daily-react-hooks';
import { useEffect, useMemo, useState } from 'react';

import { ACCESS_STATE_LOBBY } from 'vendor/dailyco/shared/constants';
import { ApiRecordingSession } from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import { AudioIndicator } from './AudioIndicator';
import { Button } from 'ui/Button';
import { DEVICE_MODAL } from 'vendor/dailyco/shared/components/DeviceSelectModal/DeviceSelectModal';
import { ReactComponent as IconSettings } from 'vendor/dailyco/shared/icons/settings-sm.svg';
import Loader from 'vendor/dailyco/shared/components/Loader';
import MuteButton from 'vendor/dailyco/shared/components/MuteButton';
import { TextInput } from 'vendor/dailyco/shared/components/Input';
import Tile from 'vendor/dailyco/shared/components/Tile';
import { ahoy } from 'features/providers/TrackingVisitProvider';
import { useDeepCompareMemo } from 'use-deep-compare';
import { useRecordingFormQuery } from 'services/recordingFormsAPI';
import { useUIState } from 'vendor/dailyco/shared/contexts/UIStateProvider';

/**
 * Hair check
 * ---
 * - Setup local media devices to see how you look / sound
 * - Toggle mute state of camera and mic
 * - Set user name and join call / request access
 */
export const HairCheck = () => {
  const daily = useDaily();
  const { camState, hasCamError, hasMicError, micState } = useDevices();
  const { openModal } = useUIState();
  const [waiting, setWaiting] = useState(false);
  const [joining, setJoining] = useState(false);
  const [denied, setDenied] = useState();
  const [userName, setUserName] = useState(
    localStorage.getItem('PLUOT_PARTICIPANT_NAME') || 'user-cam',
  );
  const localParticipant = useLocalParticipant();

  const camMuted = useMemo(
    () => !localParticipant?.video || hasCamError,
    [hasCamError, localParticipant?.video],
  );

  const micMuted = useMemo(
    () => !localParticipant?.audio || hasMicError,
    [hasMicError, localParticipant?.audio],
  );

  const isLoading = camState === 'pending' || micState === 'pending';
  const deviceInUse = camState === 'in-use' || micState === 'in-use';

  const { recordingInteractionFormId } = ApiRecordingSession.useContainer();

  const {
    data: recordingInteractionForm,
    isLoading: isLoadingForm,
    isFetching: isFetchingForm,
    isError,
    isSuccess,
    error,
  } = useRecordingFormQuery({ id: recordingInteractionFormId! });

  const formQuestion = useMemo(() => {
    if (
      !recordingInteractionForm?.recording_form_prompts ||
      !recordingInteractionForm?.recording_form_prompts[0] ||
      !recordingInteractionForm?.recording_form_prompts[0].description
    ) {
      return '';
    }
    return recordingInteractionForm?.recording_form_prompts[0].description;
  }, [recordingInteractionForm]);

  // Initialise devices (even though we're not yet in a call)
  useEffect(() => {
    if (!daily) return;
    daily.startCamera();
  }, [daily]);

  useEffect(() => {
    if (recordingInteractionFormId) {
      window.analytics.track('RecordingInteractionForm HairCheck opened', {
        recording_interaction_form_id: recordingInteractionFormId,
      });
      ahoy.track('RecordingInteractionForm HairCheck opened', {
        recording_interaction_form_id: recordingInteractionFormId,
      });
    }
  }, [recordingInteractionFormId])

  const joinCall = async () => {
    if (!daily) return;

    // Disable join controls
    setJoining(true);

    // Set the local participants name
    await daily.setUserName(userName);

    // Async request access (this will block until the call owner responds to the knock)
    const { access } = daily.accessState();
    await daily.join();

    // If we we're in the lobby, wait for the owner to let us in
    if (access?.level === ACCESS_STATE_LOBBY) {
      setWaiting(true);
      const { granted } = await daily.requestAccess({
        name: userName,
        access: {
          level: 'full',
        },
      });

      if (granted) {
        // Note: we don't have to do any thing here as the call state will mutate
        console.log('👋 Access granted');
        localStorage.setItem('PLUOT_PARTICIPANT_NAME', userName);
      } else {
        console.log('❌ Access denied');
        setDenied(true);
      }
    }
  };

  // Memoize the to prevent unnecassary re-renders
  const tileMemo = useDeepCompareMemo(
    () => (
      <Tile
        sessionId={localParticipant?.session_id}
        mirrored
        showAvatar
        showName={false}
      />
    ),
    [localParticipant],
  );

  const camErrorVerbose = useMemo(() => {
    switch (camState) {
      case 'not-found':
        return 'Camera not found';
      case 'in-use':
        return 'Camera in use';
      default:
        return 'unknown';
    }
  }, [camState]);

  const micErrorVerbose = useMemo(() => {
    switch (micState) {
      case 'not-found':
        return 'Microphone not found';
      case 'in-use':
        return 'Microphone in use';
      default:
        return 'unknown';
    }
  }, [micState]);

  return (
    <>
      {/* Main 3 column grid */}
      <div className="flex justify-center py-3">
        <h2 className="text-2xl font-semibold">{"Let's check your camera and mic"}</h2>
      </div>

      <div className="mx-auto grid items-start gap-2 md:grid-cols-3">
        <div className="relative col-span-1 grid grid-cols-1 text-center md:px-6 md:text-left">
          <section className="mb-6 space-y-2">
            <p className="text-sm">You were asked:</p>
            <h2 className="text-xl font-bold">{formQuestion}</h2>
          </section>
          <section>
            <div className="p-3 md:bg-gray-50">
              <div className="text-xs text-gray-500 md:space-y-3">
                <p>Hit the "Ready to answer" button to enter the recording process.</p>
                <p>Once you’re happy with your responses, just hit “Submit”</p>
                <p>Pro tip: you can re-record each answer as many times as you need</p>
              </div>
            </div>
          </section>
        </div>
        <div className="md:col-span-2">
          <div className="grid grid-cols-1">
            <div className="col-span-1">
              <div>{tileMemo}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-1 flex flex-col items-center justify-center space-y-4 py-4">
        <div className="flex flex-row items-center justify-center space-x-3">
          <MuteButton className="" isMuted={camMuted} disabled={!!hasCamError} />
          <MuteButton className="" mic isMuted={micMuted} disabled={!!hasMicError} />
          <Button
            variant="tertiary"
            _className="!items-center !justify-center !inline-flex !flex-col"
            onClick={() => openModal(DEVICE_MODAL)}
          >
            <div
              className=" mx-auto flex items-center justify-center"
              style={{ width: 24, height: 24 }}
            >
              <IconSettings />
            </div>
          </Button>
        </div>
        <div>
          {isLoading && (
            <div className="overlay-message">Loading devices, please wait...</div>
          )}
          {hasCamError ||
            (hasMicError && !deviceInUse && (
              <>
                {hasCamError && <div className="overlay-message">{camErrorVerbose}</div>}
                {hasMicError && <div className="overlay-message">{micErrorVerbose}</div>}
              </>
            ))}
        </div>

        <div className="panel">
          <Button
            variant="primary"
            size="large"
            _className="text-xl !px-6"
            onClick={() => {
              joinCall();
            }}
          >
            Ready to answer
          </Button>
        </div>
      </div>
    </>
  );
};

export default HairCheck;
