import {LabelColorName, filterMatchCondition} from './constants';

import {AccountLabelColor} from 'features/types/userLibrarySlice';
import {ChangeEvent} from 'react';
import {XIcon} from '@heroicons/react/outline';
import classNames from 'classnames';
import {useAccountLabels} from '../ContentSettings/Labels/useAccountLabels';
import {useClipList} from './ClipListContext';

const product = {
  name: 'Search labels',
};

export function ModelContentSearchColorLabels({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (param: boolean) => void;
}) {
  const {
    state: {activeModal, activeListFilters},
    dispatch: dispatchClipList,
  } = useClipList();

  const {filteredLabels, setLabelSearchTerm, labelSearchTerm} = useAccountLabels();

  const createLabelFilter = (color: AccountLabelColor) => {
    const isSelected = activeListFilters.find(
      listFilter =>
        listFilter.filter_target === 'color-label' &&
        listFilter.filter_value === color.name,
    );
    if (isSelected) {
      dispatchClipList({
        type: 'remove active list filter',
        payload: {
          activeListFilter: {
            filter_target: 'color-label',
            filter_value: color.name as LabelColorName,
            filter_condition: '=' as filterMatchCondition,
          },
        },
      });
    } else {
      dispatchClipList({
        type: 'update color label filter',
        payload: {
          color: color,
          shouldRemove: false,
        },
      });
    }
    // setOpen(false);
  };

  return (
    <div
      className={classNames(
        'relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8',
        {
          hidden: activeModal !== 'color-label',
        },
      )}
    >
      <button
        type="button"
        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
        onClick={() => setOpen(false)}
      >
        <span className="sr-only">Close</span>
        <XIcon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-8 lg:gap-x-8">
        <div className="sm:col-span-8">
          <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12">
            {product.name}
          </h2>
          <section aria-labelledby="options-heading" className="mt-2">
            <div className="mt-4 mb-4 space-y-2">
              <div>
                <div className="relative mt-1 flex items-center">
                  <input
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      setLabelSearchTerm(evt.target.value);
                    }}
                    value={labelSearchTerm}
                    placeholder="Search label name"
                    type="text"
                    name="search"
                    id="search"
                    className="block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            {filteredLabels.map((color, labelIdx) => (
              <FilterLabelItem
                key={labelIdx}
                inputClick={() => {
                  createLabelFilter(color);
                }}
                color={color}
                isChecked={
                  !!activeListFilters.find(
                    listFilter =>
                      listFilter.filter_target === 'color-label' &&
                      listFilter.filter_value === color.name,
                  )
                }
              />
            ))}
          </section>
        </div>
      </div>
    </div>
  );
}

export const FilterLabelItem = ({
  inputClick,
  color,
  isChecked,
}: {
  inputClick: () => void;
  color: AccountLabelColor;
  isChecked?: boolean;
}) => {
  return (
    <div className="relative flex items-center justify-start py-1">
      <div className="mr-3 flex h-5 items-center">
        <input
          onChange={() => {
            inputClick();
          }}
          id={`color-${color.name}`}
          name={`color-${color.name}`}
          checked={isChecked}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
      </div>
      <div className="flex items-center justify-center space-x-3">
        <label
          htmlFor={`color-${color.name}`}
          className={classNames(
            color.bgColor,
            'h-3 w-3 cursor-pointer rounded-full border border-black border-opacity-10',
          )}
        ></label>
        <span>{color.name}</span>
      </div>
    </div>
  );
};
