import {useDuration} from '../CanvasTime/useDuration';
import {useTimeSelector} from '../CanvasTime/useTimeSelector';

export const AccessibleProgress = () => {
  const durationMs = useDuration();
  const progress = useTimeSelector(
    ({currentTime}) => {
      return Math.round((currentTime / durationMs) * 100);
    },
    [durationMs],
  );

  return (
    <div
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin={0}
      aria-valuemax={100}
      className="hidden"
    />
  );
};
