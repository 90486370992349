import {
  ClipTranscriptResponseType,
  UserUploadsType,
} from 'features/types/userLibrarySlice';

import {LoadingSearchResult} from './LoadingSearchResult';
import {SearchTranscriptWordInstances} from 'features/types/authSlice';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import {UploadTranscriptParent} from './ClipSearchResult/UploadTranscriptParent';
import {useEffect} from 'react';
// import {UploadTranscriptParent} from './ClipSearchResult/UploadTranscriptParent';
import {useSelector} from 'react-redux';
import {userLibrarySelector} from 'features/selectors/userLibrarySelectors';

enum SearchListItemType {
  Clips = 'clips',
  Transcripts = 'transcripts',
}

export type UploadClipListItem = {
  listType: SearchListItemType.Clips;
  uploadId: string;
  upload: UserUploadsType;
  uploadSecondaryItems: UploadMediaClipType[];
};

export type UploadTranscriptListItem = {
  listType: SearchListItemType.Transcripts;
  uploadId: string;
  upload: UserUploadsType;
  uploadSecondaryItems: SearchTranscriptWordInstances[];
};

export const SearchResults = () => {
  const {processingAccountSearchQuery, accountSearchResults} =
    useSelector(userLibrarySelector);

  const transcript_word_instances =
    accountSearchResults.transcripts_word_instances.results;

  const searchResultList = accountSearchResults.uploads.results.map(upload => {
    const uploadTranscriptWordInstances = transcript_word_instances.filter(
      transcriptObj => transcriptObj.upload_id === upload.id,
    );

    return {
      listType: SearchListItemType.Transcripts,
      uploadId: upload.id,
      upload,
      uploadSecondaryItems: uploadTranscriptWordInstances,
    } as UploadTranscriptListItem;
  });

  // console.log('accountSearchResults', accountSearchResults);
  // console.log('searchResultList', searchResultList);

  const listToDisplay = searchResultList.slice(0, 10);

  return (
    <div className="flex w-full flex-col space-y-8 pb-6">
      {/* No results */}
      {accountSearchResults.uploads.results.length === 0 && <div />}

      {/* Loading */}
      {processingAccountSearchQuery && <LoadingSearchResult />}

      {/* Found results */}
      {searchResultList.length !== 0 &&
        !processingAccountSearchQuery &&
        accountSearchResults.hasResults && (
          <div>
            <h3 className="-ml-1 mb-2 truncate px-3 font-medium text-gray-600">
              Search results
            </h3>
            <ul className="space-y-3 divide-y divide-gray-200 pr-6">
              {listToDisplay.map(recentUploadTranscript =>
                recentUploadTranscript?.upload?.mux_playback_id ? (
                  <UploadTranscriptParent
                    key={recentUploadTranscript.uploadId}
                    uploadClip={recentUploadTranscript}
                  />
                ) : (
                  // <div>{JSON.stringify(recentUploadTranscript)}</div>
                  <div className="h-10 w-full animate-pulse" />
                ),
              )}
            </ul>
          </div>
        )}
    </div>
  );
};
