import {useDispatch, useSelector} from 'react-redux';

import {CanvasButton} from 'features/EditorCanvas/components/CanvasButton';
import {Trash} from 'react-feather';
import {canvasItemsSelectedItemIdsSelector} from 'features/selectors/canvasStateSelectors';
import {
  removeCanvasItems,
  updateCanvasItemsPositionState,
} from 'features/canvasItemsSlice';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

export const ShapeComponentActionNavBar = () => {
  const dispatch = useDispatch();
  const projectId = useProjectId();
  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);

  const removeSelectedItem = (itemKey: string) => {
    dispatch(updateCanvasItemsPositionState());
    dispatch(
      removeCanvasItems({
        projectId,
        ids: [itemKey],
      }),
    );
  };

  return (
    <>
      <div className="flex flex-grow" />
      {selectedItemIds.length > 0 && (
        <>
          <li className="flex">
            <div className="flex items-center">
              <CanvasButton
                leftIcon={Trash}
                onClick={() => {
                  selectedItemIds.forEach(itemId => removeSelectedItem(itemId));
                }}
              >
                Delete
              </CanvasButton>
            </div>
          </li>
        </>
      )}
    </>
  );
};
