import {createContainer} from 'unstated-next';
import {useState} from 'react';

export const UPLOAD_TRANSCRIPT_CONTEXT_DEFAULT = {
  initialAutoScroll: true,
  initialEditMode: false,
  initialIsDownloadModalOpen: false,
  initialIsShareModalOpen: false,
  initialTranscriptChaptersActive: false,
};

function useUploadTranscriptContainer(initialState = UPLOAD_TRANSCRIPT_CONTEXT_DEFAULT) {
  const [transcriptChaptersActive, setTranscriptChapters] = useState(
    initialState.initialTranscriptChaptersActive,
  );
  const [autoScroll, setAutoScroll] = useState(initialState.initialAutoScroll);
  const [editMode, setEditMode] = useState(initialState.initialEditMode);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(
    initialState.initialIsDownloadModalOpen,
  );
  const [isShareModalOpen, setIsShareModalOpen] = useState(
    initialState.initialIsShareModalOpen,
  );

  return {
    isShareModalOpen,
    setIsShareModalOpen,
    autoScroll,
    setAutoScroll,
    editMode,
    setEditMode,
    isDownloadModalOpen,
    setIsDownloadModalOpen,
    transcriptChaptersActive,
    setTranscriptChapters,
  };
}

const UploadTranscript = createContainer(useUploadTranscriptContainer);

export const UploadTranscriptProvider = UploadTranscript.Provider;
export const useUploadTranscript = UploadTranscript.useContainer;
