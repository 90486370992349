import { Fragment, useState } from 'react';
/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from '@headlessui/react';

import { ChevronDownIcon } from '@heroicons/react/solid';
import { ClipColorLabelModal } from './ClipColorLabelModal';
import classNames from 'classnames';
import { useClipList } from './ClipListContext';

export default function ClipListHeaderMenu() {
  const { dispatch: dispatchClipList } = useClipList();

  const setOpenColorLabel = () => {
    dispatchClipList({
      type: 'update open color label modal',
      payload: {
        openClipLabelAssignmentModal: true,
      },
    });
  };

  const handleLabelClick = () => {
    setOpenColorLabel();
  };

  return (
    <>
      <Menu as="div" className="relative z-30 inline-block text-left">
        <div>
          <Menu.Button className="focus:outline-none inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            Manage
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="focus:outline-none absolute left-0 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={handleLabelClick}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block cursor-pointer px-4 py-2 text-sm',
                    )}
                  >
                    Add label...
                  </div>
                )}
              </Menu.Item>
              {/* <Menu.Item>
                {({active}) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    Download
                  </a>
                )}
              </Menu.Item> */}
            </div>
            {/* <div className="py-1">
            <div className="px-4 pt-3 pb-2">
              <p className="text-sm font-medium text-gray-900 truncate">Create</p>
            </div>
            <Menu.Item>
              {({active}) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  )}
                >
                  Landing page
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({active}) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  )}
                >
                  Highlight reel
                </a>
              )}
            </Menu.Item>
          </div> */}
          </Menu.Items>
        </Transition>
      </Menu>
      <ClipColorLabelModal />
    </>
  );
}
