import {AnimatePresence, motion} from 'framer-motion';
import {DashParams, routePaths} from 'routes/routesHelper';
import {MouseEvent, useState} from 'react';
import {
  ResponsiveGrid,
  TemplateCard,
  TemplatePicker,
  thumbnailURL,
} from '../ContentUploads/TemplatePicker';

import {Button} from 'ui/Button';
import {Link} from 'react-router-dom';
import {TemplateModal} from './TemplateModal';
import {UserLibraryUserProjectTypeWithCmsData} from 'features/types/userLibrarySlice';
import {fadeProps} from 'features/EditorCanvas/Sidebar/Sidebar';
import {useAccountTemplatesQuery} from 'services/projectAPI';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';

interface Props {
  templates: UserLibraryUserProjectTypeWithCmsData[];
  activeCategory?: string;
}

export default function TemplatesGrid({templates, activeCategory}: Props) {
  const isCmsTemplatesActive = useFeatureFlag('cms-templates');

  const [selectedTemplate, setSelectedTemplate] =
    useState<UserLibraryUserProjectTypeWithCmsData | undefined>();

  const filteredTemplates = activeCategory
    ? templates.filter(
        template =>
          template.categories &&
          template.categories.findIndex(category => category.slug === activeCategory) !==
            -1,
      )
    : templates;

  const {data: accountTemplates} = useAccountTemplatesQuery();
  // console.log('accountTemplates', accountTemplates);

  return (
    <div>
      <AnimatePresence exitBeforeEnter initial={false}>
        {!accountTemplates ? (
          <motion.div key="loading" {...fadeProps}>
            <div className="mb-4 box-content h-5 animate-pulse rounded-lg bg-gray-50 py-3" />
            <ResponsiveGrid>
              <GhostCard />
              <GhostCard />
              <GhostCard />
            </ResponsiveGrid>
          </motion.div>
        ) : (
          <motion.div key="loaded" {...fadeProps} className="space-y-4">
            <ResponsiveGrid title="Your team templates">
              {accountTemplates.map(template => (
                <TemplateCard
                  key={template.slug}
                  template={template}
                  onClick={() => {
                    setSelectedTemplate(template);
                  }}
                />
              ))}
            </ResponsiveGrid>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="w-full space-y-4 pl-2">
        <div className="flex items-center justify-between">
          {/* <Button leftIcon={Plus} variant="secondary" negativeMargin _className="!mr-0">
          Create a template
        </Button> */}
        </div>
      </div>
      <TemplateModal
        template={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
      />
      <AnimatePresence exitBeforeEnter initial={false}>
        {!templates.length ? (
          <motion.div key="loading" {...fadeProps}>
            <div className="mb-4 grid grid-cols-3 gap-4">
              <GhostCard />
              <GhostCard />
              <GhostCard />
            </div>
          </motion.div>
        ) : (
          <motion.div key="loaded" {...fadeProps}>
            <div className="mb-4 grid grid-cols-2 gap-6 lg:grid-cols-3">
              {filteredTemplates.map((template, index) => {
                const url = template.latest_preview
                  ? template.latest_preview
                  : thumbnailURL(template.slug);
                return (
                  <div key={template.slug} className="group relative flex flex-col">
                    <div className="relative">
                      <div className="rounded-lg  bg-gray-50 p-3 transition duration-75 group-hover:bg-gray-200">
                        <div className="aspect-w-4 aspect-h-3 flex flex-col items-end justify-end overflow-hidden">
                          <img
                            className="object-contain object-center"
                            src={url}
                            alt={`Download preview`}
                          />
                        </div>
                        <div>
                          <Link
                            className="z-20 flex cursor-pointer items-end opacity-0 transition-opacity duration-75 group-hover:opacity-100"
                            aria-hidden="true"
                            to={`/${routePaths.dashboard}/${DashParams.TemplatesPageParam}/${template.slug}`}
                            onClick={(evt: MouseEvent<HTMLElement>) => {
                              evt.stopPropagation();
                              evt.preventDefault();
                              setSelectedTemplate(template);
                            }}
                          >
                            <div className="w-full rounded-md bg-white bg-opacity-75 py-2 px-2 text-center text-xs font-medium text-gray-900 shadow-lg backdrop-blur backdrop-filter">
                              View download
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 flex items-center justify-between space-x-8 text-xs font-medium text-gray-900">
                      <Link
                        to={`/${routePaths.dashboard}/${DashParams.TemplatesPageParam}/${template.slug}`}
                        onClick={(evt: MouseEvent<HTMLElement>) => {
                          evt.stopPropagation();
                          evt.preventDefault();
                          setSelectedTemplate(template);
                        }}
                      >
                        <h3 className="truncate ">
                          <div onClick={() => setSelectedTemplate(template)}>
                            <span aria-hidden="true" className="absolute inset-0" />
                            {template.name}
                          </div>
                        </h3>
                      </Link>
                      {/* <p>{secondToPlayerTime(downloadItem.duration_ms / 1000)}</p> */}
                    </div>
                    {/* <p className="mt-1 text-xs text-gray-500">
                      Created {format(downloadItem.created_at, 'Pp')}
                    </p> */}
                  </div>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

const GhostCard = () => {
  return (
    <div className="w-full animate-pulse overflow-hidden rounded-lg">
      <div className="aspect-w-7 aspect-h-4 w-full bg-gray-100" />
    </div>
  );
};
