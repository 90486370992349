import './styles/index.css';
import './styles/react-contexify.css';
import './global';

import * as Sentry from '@sentry/react';

import App from './App';
import Cohere from 'cohere-js';
import {Integrations} from '@sentry/tracing';
import ReactDOM from 'react-dom';
import {disableReactDevTools} from '@fvilers/disable-react-devtools';
import {env} from 'constants/environment';

export const isStaticSnapshot = () => {
  const ignoreLocations = [
    '/font-specimen-preview/',
    '/font-name-preview/',
    '/social-card-preview/',
    '/external-font-name-preview/',
    '/external-font-specimen-preview/',
    '/preview-template/',
    '/frame/',
    '/screenshot/',
    '/robot-routes/',
  ];
  return (
    ignoreLocations
      .map(locationFragment => window.location.href.includes(locationFragment))
      .filter(locationChecks => locationChecks).length > 0
  );
};

if (env === 'production') {
  const sentryConfig = {
    environment: env,
    dsn: 'https://87cbda3ea38d43d99a22714e26c66f4d@o431653.ingest.sentry.io/5494234',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
    release: `milkvideo-app@${import.meta.env.npm_package_version}`,
  };

  if (window.location.origin === 'https://app.milkvideo.com' && !isStaticSnapshot()) {
    Cohere.init('GBAIy3qj9t25Z4NsyDGGwbz8');
  }

  if (isStaticSnapshot()) {
    sentryConfig.environment = 'prod-snapshot';
    sentryConfig.tracesSampleRate = 0.0001;
  }

  Sentry.init(sentryConfig);

  // disableReactDevTools();
} else {
  Sentry.init({
    environment: env,
    dsn: 'https://87cbda3ea38d43d99a22714e26c66f4d@o431653.ingest.sentry.io/5494234',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    release: `milkvideo-app@${import.meta.env.npm_package_version}`,
  });
}

if (process.env.NODE_ENV === 'development') {
  // const {worker} = require('./mocks/browser');
  // worker.start();
}

declare const __COMMIT_HASH__: string;
console.log('Build running: ', __COMMIT_HASH__);

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
