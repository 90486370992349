import VideoContainer from 'vendor/dailyco/shared/components/VideoContainer/VideoContainer';

import { Container } from './Container';
import { Header } from './Header';
import { VideoGrid } from './VideoGrid';
import { RecordingSessionWrap } from 'features/RecordingForms/recording/components/RecordingSessionWrap';
export function Room({ children }) {
  return (
    <div className="relative flex flex-col w-full h-full">
      {/* <Header /> */}
      <RecordingSessionWrap>
        <VideoContainer>{children ? children : <VideoGrid />}</VideoContainer>
      </RecordingSessionWrap>
    </div>
  );
}

export default Room;
