import classNames from 'classnames';
import {forwardRef} from 'react';

type ImageCardProps = {
  image: string | undefined;
  label: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

// UI TODO (jacques): Make component (consider scenes cards in canvas)
const ImageCard = forwardRef<HTMLButtonElement, ImageCardProps>(
  ({image, label, selected = false, disabled = false, onClick}, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          'group focus:outline-none w-full rounded-lg border border-gray-100 text-left ring-0 filter transition duration-300 hover:shadow-lg focus:shadow-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
          disabled && 'pointer-events-none grayscale',
          selected && 'border-white shadow-none ring-2 ring-indigo-500 ring-offset-2',
        )}
        onClick={onClick}
      >
        <div
          className="aspect-w-7 aspect-h-4 w-full rounded-lg rounded-b-none bg-gray-200 bg-cover"
          style={{backgroundImage: `url('${image}')`}}
        />

        <div
          className={classNames(
            'rounded-lg rounded-t-none px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-300 group-hover:text-indigo-600 group-focus:text-indigo-600',
            selected && '!text-indigo-600',
          )}
        >
          <div className="line-clamp-1">{label}</div>
        </div>
      </button>
    );
  },
);

export default ImageCard;
