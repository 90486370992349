import {StepButtons, StepHeader} from './utils';
import {asyncTrack, useTrackOnce} from 'features/Common/utils';

import {TextInput} from 'ui/TextInput';
import {WizardStepFC} from './NewProjectWizard';

export const Review: WizardStepFC = ({state, actions, createProject}) => {
  const trackOnce = useTrackOnce();

  return (
    <>
      <StepHeader heading="That&lsquo;s it!" state={state} actions={actions}>
        <strong>You are now ready to download your clip.</strong> You can also customize
        it further in the designer if you&lsquo;d like.
      </StepHeader>
      <TextInput
        name="export name"
        label="Name"
        value={state.name}
        onChange={name => {
          trackOnce('TmpWzd -- Ev-TxtIn -- Project name');
          actions.setName(name);
        }}
        autoFocus
      />
      <StepButtons
        state={state}
        actions={actions}
        overrideConfirm={{
          label: 'Download',
          onClick: async () => {
            await asyncTrack('TmpWzd -- Act-Dwnld -- Template project');
            await createProject(true);
          },
        }}
      />
    </>
  );
};
