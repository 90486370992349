import * as Sentry from '@sentry/react';

import {useDispatch, useSelector} from 'react-redux';

import EditorCanvasWrapContainer from 'features/EditorCanvas/EditorCanvasWrapContainer';
import ErrorPage from 'features/EditorCanvas/components/shared/ErrorPage';
import LoadingPage from 'features/EditorCanvas/components/shared/LoadingPage';
import {authSelector} from 'features/selectors/authSelectors';
import {updateUserProjects} from 'features/userLibrarySlice';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useProjectQuery} from 'services/projectAPI';

function FallbackComponent() {
  return (
    <div className="fixed left-0 top-0 flex h-screen min-h-screen w-screen flex-col items-center justify-center bg-gray-100">
      <ErrorPage
        title="Error occured"
        explainer={'Please refresh to return to a working state.'}
      />
    </div>
  );
}

const myFallback = <FallbackComponent />;

export function EditorCanvasPage() {
  const {email: authEmail, user} = useSelector(authSelector);
  useEffect(() => {
    !!window.Intercom && window.Intercom('hide');
  }, []);
  const {projectId} = useParams() as Record<string, string>;

  const {isLoading, isFetching, isError, isSuccess, data} = useProjectQuery({
    id: projectId,
  });

  // console.log('data', data);
  // console.log('isLoading', isLoading);
  // console.log('isFetching', isFetching);
  // console.log('isSuccess', isSuccess);
  // console.log('isError', isError);

  const errorDialog = authEmail
    ? {
        user: {
          email: authEmail,
          name: `${user?.first_name} ${user?.last_name}`,
        },
        title: 'We are sorry you have hit an error',
        subtitle:
          'Our engineering team has been notified and will fix the issue promptly. We would appreciate if you can briefly explain what may have caused the error to help us track the issue.',
      }
    : {};

  // const canvasProject = useSelector((state: GetRootState) =>
  //   selectCanvasProject(state, projectScenarioKey),
  // );

  // const userLibraryProject = useSelector(userLibraryUserProjectSelector)(
  //   projectScenarioKey,
  // );

  const {getAccessTokenSilently} = useAuthAlt();
  const dispatch = useDispatch();

  useEffect(() => {
    getAccessTokenSilently()
      .then(token => dispatch(updateUserProjects(token)))
      .catch(console.error);
  }, [dispatch, getAccessTokenSilently]);

  // const isLoading = canvasProject?.isLoading !== false || !userLibraryProject;

  // console.log('projectScenarioKey', projectScenarioKey);
  return (
    <Sentry.ErrorBoundary
      fallback={myFallback}
      beforeCapture={scope => {
        scope.setTag('projectId', projectId);
      }}
      onError={error => {
        Sentry.captureException(error);
      }}
      dialogOptions={errorDialog}
      showDialog
    >
      {isLoading && (
        <div
          className="fixed left-0 top-0 flex h-screen min-h-screen w-screen flex-col items-center justify-center bg-gray-100"
          style={{zIndex: 10001}}
        >
          <LoadingPage />
        </div>
      )}

      {projectId && <EditorCanvasWrapContainer projectId={projectId} />}
    </Sentry.ErrorBoundary>
  );
}
