import {
  CalculatedScene,
  useScenes,
} from 'features/EditorCanvas/components/CanvasTime/useScenes';
import {DraggableProvided, DraggableStateSnapshot} from 'react-beautiful-dnd';

import {MiniTimeline} from './MiniTimeline';
import {RadioGroup} from '@headlessui/react';
import {ScenePreview} from './ScenePreview';
import {ScenePreviewLength} from './ScenePreviewLength';
import {ScenePreviewMenu} from './ScenePreviewMenu';
import {canvasScenesZindex} from './canvasSceneConstants';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import {sceneFrameSelector} from 'features/selectors/sceneFrameSelectors';
import {useCanvasScenes} from './CanvasScenesContext';
import {usePlayback} from '../CanvasTime/usePlayback';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useSelector} from 'react-redux';
import {useState} from 'react';

type SceneCardProps = {
  scene: CalculatedScene;
  dragSnapshot: DraggableStateSnapshot;
  dragProvided: DraggableProvided;
  index: number;
  focused: boolean;
  onRef?: (ref: HTMLDivElement | null) => void;
};

export const SceneCard = ({
  scene,
  dragProvided,
  index,
  focused,
  onRef,
}: SceneCardProps) => {
  const projectId = useProjectId();
  const {canvasDimensions} = useSelector(sceneFrameSelector)(projectId);
  const sceneId = scene.sceneId;
  const {activeScene, seekToScene} = useScenes();
  const isActive = activeScene.id === sceneId;
  const {
    state: {menuTargetScene},
  } = useCanvasScenes();
  const {isScrubbing} = usePlayback();

  const aspectRatio = canvasDimensions.height / canvasDimensions.width;

  const previewHeight = 80;
  const cardWidth = previewHeight / aspectRatio;

  const [hovering, setHovering] = useState(false);

  let highlighted = !isScrubbing && isActive;
  if (!highlighted) highlighted = hovering || focused;

  const sceneName = `Scene ${index + 1}`;

  return (
    <motion.div
      animate={{opacity: 1, transition: {delay: 0.25}}}
      transition={{duration: 0.25}}
      initial={{opacity: 0}}
      exit={{opacity: 0}}
      className={classNames('group relative h-full', {
        [`z-${canvasScenesZindex.ScenePreviewActiveCardMenu}`]:
          menuTargetScene !== null && menuTargetScene === scene.sceneId,
        [`z-${canvasScenesZindex.ScenePreviewInactiveCardMenu}`]:
          menuTargetScene !== null && menuTargetScene !== scene.sceneId,
      })}
      ref={ref => {
        dragProvided.innerRef(ref);
        onRef?.(ref);
      }}
      {...dragProvided.draggableProps}
      data-scene-id={sceneId}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <RadioGroup.Label className="hidden">{sceneName}</RadioGroup.Label>
      <MiniTimeline scene={scene}>
        <div
          onClick={() => {
            seekToScene(scene.sceneId);
            window.analytics.track('scenes_switch_to_scene', {index});
          }}
          className={classNames(
            'group group relative flex h-full transform flex-col items-center rounded-lg bg-gray-100 text-center transition focus:outline-none',
            `z-${canvasScenesZindex.ScenePreview}`,
          )}
          style={{
            transitionTimingFunction: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
            transitionDuration: '250ms',
            transitionProperty: 'transform, box-shadow, width',
            width: cardWidth,
          }}
        >
          <div
            className={classNames(
              'absolute h-full w-full overflow-hidden rounded-lg border-2',
              `z-${canvasScenesZindex.ScenePreview}`,
              {
                'border-indigo-500': isActive,
                'border-gray-200': !isActive,
              },
            )}
          >
            <ScenePreview
              dragHandleProps={dragProvided.dragHandleProps}
              items={scene.items}
              isActive={isActive}
              height={previewHeight}
              sceneId={scene.sceneId}
              sceneName={sceneName}
            />
          </div>
        </div>
        <ScenePreviewMenu sceneIndex={index} sceneId={sceneId} />
        <ScenePreviewLength sceneDuration={scene.duration} />
      </MiniTimeline>
    </motion.div>
  );
};
