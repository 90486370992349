import { DashParams, routePaths } from 'routes/routesHelper';
import { Film, Globe, Plus, UploadCloud } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, ModalButtons, ModalDescription } from 'ui/Modal';
import { useMemo, useState } from 'react';

import { Button } from 'ui/Button';
import { HomeAccountSummary } from './HomeAccountSummary';
import { LatestProjects } from './LatestProjects';
import { LatestUploads } from './LatestUploads';
import { OnboardingHome } from './OnboardingHome';
import { authOnboardinStatusSelector } from 'features/selectors/authSelectors';
import classNames from 'classnames';
import { useProjectModal } from 'features/Dashboard/useProjectModal';
import { useSelector } from 'react-redux';

export function Home({ paymentStatus }: { paymentStatus?: 'success' }) {
  const { dispatch } = useProjectModal();
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(
    paymentStatus === 'success',
  );

  const navigate = useNavigate();
  const handlePaymentModalClose = () => {
    setShowPaymentSuccess(false);
    navigate(`/${routePaths.dashboard}`);
  };

  const newItems = useMemo(() => {
    const handleNewProject = () => {
      dispatch({
        type: 'setIsModalOpen',
        payload: {
          isModalOpen: true,
        },
      });
    };

    return [
      {
        buttonType: 'link',
        destination: `/${routePaths.dashboard}/${DashParams.UploadsParam}`,
        id: 'upload',
        title: 'New Upload',
        description: 'Transcript and clip',
        iconColor: 'bg-indigo-400',
        action: '',
        icon: <UploadCloud className="w-5 text-white" />,
      },
      {
        buttonType: 'link',
        destination: `/${routePaths.dashboard}/${DashParams.RecordingFormParams}`,
        id: 'testimonial',
        title: 'New Video Form',
        description: 'Collect video',
        iconColor: 'bg-purple-400',
        action: '',
        icon: <UploadCloud className="w-5 text-white" />,
      },
      {
        buttonType: 'link',
        destination: `/${routePaths.dashboard}/${DashParams.ProjectsParam}`,
        id: 'project',
        title: 'New Project',
        description: 'Design a video',
        iconColor: 'bg-blue-300',
        action: 'method',
        method: handleNewProject,
        icon: <Film className="w-5 text-white" />,
      },
      // {
      //   destination: `/${routePaths.dashboard}/${DashParams.Projects}`,
      //   id: 'template',
      //   title: 'New Template',
      //   description: 'Reusable design',
      //   iconColor: 'bg-white',
      //   action: '',
      //   icon: <Globe className="w-5 text-gray-500" />,
      // },
    ];
  }, []);

  const onboardingStatus = useSelector(authOnboardinStatusSelector);

  const {
    has_account_project: hasAccountProject,
    has_account_project_download: hasAccountProjectDownload,
    has_brand_kit_assets: hasAccountBrandKit,
  } = onboardingStatus
      ? onboardingStatus
      : {
        has_account_project: false,
        has_account_project_download: false,
        has_brand_kit_assets: false,
      };

  const showOnboarding =
    hasAccountProject && hasAccountProjectDownload && hasAccountBrandKit;

  return (
    <>
      {showPaymentSuccess && (
        <>
          <Modal
            size="small"
            title="Welcome"
            open={showPaymentSuccess}
            onClose={handlePaymentModalClose}
          >
            <ModalDescription>
              Your plan was successfully setup! <br />
            </ModalDescription>
            <ModalButtons
              // cancel={{label: 'Cancel'}}
              confirm={{
                label: 'Okay',
                onClick: handlePaymentModalClose,
              }}
            />
          </Modal>
        </>
      )}
      {!showOnboarding && (
        <OnboardingHome
          hasAccountProject={hasAccountProject}
          hasAccountProjectDownload={hasAccountProjectDownload}
          hasAccountBrandKit={hasAccountBrandKit}
        />
      )}
      <div className="grid max-w-xl grid-cols-1 md:grid-cols-2 gap-2 lg:max-w-2xl lg:grid-cols-3">
        {newItems.map((item, index) => (
          <div key={item.id}>
            <Link
              to={item.destination}
              onClick={evt => {
                if (item?.action === 'method' && item?.method) {
                  evt.preventDefault();
                  item?.method();
                }
              }}
            >
              <div className="group flex flex-row items-center justify-between overflow-hidden rounded-md border hover:bg-gray-50">
                <div className="flex flex-row items-center space-x-4">
                  <div className="flex aspect-1 h-16 w-12 items-center justify-center pl-4">
                    <div
                      className={classNames(
                        'flex items-center justify-center rounded-full',
                        item.iconColor,
                        {
                          ' h-8 w-8 p-2': item.iconColor !== 'bg-white',
                          ' h-10 w-10 p-1':
                            item.iconColor == 'bg-white group-hover:bg-gray-50',
                        },
                      )}
                    >
                      {item.icon}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <h3 className="text-sm font-medium">{item.title}</h3>
                    <h5 className="text-xs text-gray-400">{item.description}</h5>
                  </div>
                </div>
                <Button
                  _className="hover:bg-gray-50"
                  variant="inline"
                  size="small"
                  iconOnly
                  leftIcon={Plus}
                />
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="grid gap-6 py-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        <div className="md:col-span-3 lg:col-span-3 xl:col-span-4">
          <div className="grid grid-cols-1 gap-6">
            <div className="col-span-1">
              <LatestProjects />
            </div>
            <div className="col-span-1">
              <LatestUploads />
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <HomeAccountSummary />
        </div>
      </div>
    </>
  );
}
