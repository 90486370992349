export default function LoadingSpinner({color = ''}) {
  return (
    <svg
      aria-label="loading-spinner"
      className={`h-20 w-20 animate-spin-slow fill-current ${color}`}
      width="159"
      height="159"
      viewBox="0 0 159 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M38.2594 23.353C32.5602 27.7264 27.5374 32.9167 23.3531 38.7562L31.3031 44.7186C34.9524 39.6576 39.3021 35.1406 44.2219 31.303L38.2594 23.353Z" />
      <path d="M22.8562 61.1155L13.4156 58.1343C11.0155 64.9979 9.8383 72.2294 9.93749 79.4999H19.875C19.8548 73.2498 20.862 67.0389 22.8562 61.1155V61.1155Z" />
      <path d="M13.4156 101.363C15.7199 108.291 19.0712 114.826 23.3531 120.741L31.3031 114.778C27.7111 109.749 24.866 104.226 22.8562 98.3813L13.4156 101.363Z" />
      <path d="M38.7563 135.647C44.6707 139.929 51.2058 143.28 58.1344 145.584L61.1156 136.144C55.2711 134.134 49.7482 131.289 44.7188 127.697L38.7563 135.647Z" />
      <path d="M58.1344 13.4157L61.1156 22.8564C67.039 20.8621 73.2499 19.8549 79.5 19.8751V9.93761C72.2295 9.83842 64.998 11.0156 58.1344 13.4157V13.4157Z" />
      <path d="M120.244 135.647C126.096 131.285 131.285 126.096 135.647 120.244L127.697 114.281C124.002 119.468 119.468 124.002 114.281 127.697L120.244 135.647Z" />
      <path d="M136.144 97.8844L145.584 100.866C147.746 93.9435 148.917 86.7503 149.062 79.5H139.125C139.145 85.7501 138.138 91.961 136.144 97.8844V97.8844Z" />
      <path d="M145.088 57.6374C142.783 50.7088 139.432 44.1737 135.15 38.2593L127.2 44.2218C130.792 49.2512 133.637 54.7741 135.647 60.6187L145.088 57.6374Z" />
      <path d="M119.747 22.8562C113.832 18.5743 107.297 15.223 100.369 12.9187L97.3875 22.3593C103.232 24.3691 108.755 27.2142 113.784 30.8062L119.747 22.8562Z" />
      <path d="M100.866 145.584L97.8844 136.144C91.961 138.138 85.7501 139.145 79.5 139.125V149.063C86.7216 148.632 93.8805 147.467 100.866 145.584V145.584Z" />
    </svg>
  );
}
