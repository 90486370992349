import { LandingPageComponent, useUpdateLandingPageMutation } from 'services/landingPageAPI';
import { Link2, Move, Trash2 } from 'react-feather';

import EditableLandingPageField from '../EditableLandingPageField';
import { IconButton } from 'ui/IconButton';

export const EmbedCard = ({
  isDragging,
  landingPageId,
  state,
  setState,
  item,
}: {
  landingPageId: string;
  isDragging: boolean;
  item: LandingPageComponent;
  state: any;
  setState: any;
}) => {


  const [updateLandingPage, { isLoading: isUpdating }] = useUpdateLandingPageMutation();

  const handleRemoveEmbed = async (landingPageComponentId: string) => {
    await updateLandingPage({
      id: landingPageId,
      landing_page_components_attributes: [{ id: landingPageComponentId, _destroy: true }],
    });
  };

  return (
    <>
      <div className="col-span-full">
        <EditableLandingPageField
          landingPageComponentId={item.id}
          landingPageId={landingPageId}
          fieldName={'title'}
          className={'text-center text-xl font-extrabold tracking-tight text-gray-900'}
          fieldValue={item.title || 'Untitled'}
        />
        <div className="mt-6">
          <h4 className="sr-only">Description</h4>
          <EditableLandingPageField
            landingPageComponentId={item.id}
            landingPageId={landingPageId}
            fieldName={'description'}
            className={'text-center text-base text-gray-700'}
            fieldValue={item.description}
            placeholder="No description"
          />
        </div>

        <div className="mt-6">
          <h4 className="sr-only">Url</h4>
          <div className="flex items-center space-x-2 ">
            <Link2 size={20} className="flex-shrink-0" />
            <EditableLandingPageField
              landingPageComponentId={item.id}
              landingPageId={landingPageId}
              fieldName={['component_attributes', 'url']}
              className={'text-sm text-gray-700'}
              fieldValue={item.component?.url || 'https://example.com'}
            />
          </div>
        </div>
        <section aria-labelledby="options-heading" className="mt-3">
          <h3 id="options-heading" className="sr-only">
            Card options
          </h3>
        </section>
      </div>

      <div className="col-span-full flex items-center justify-between">
        <IconButton
          variant="inline"
          icon={Move}
          label="Drag to re-order"
          size="small"
          _className="!cursor-grab"
        />
        <IconButton
          variant="inline"
          destructive
          icon={Trash2}
          onClick={() => handleRemoveEmbed(item.id)}
          label="Delete"
          size="small"
        />
      </div>
    </>
  );
};
