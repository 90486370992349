import {SelectedTranscript, useSelectedTranscript, useSelection} from './selection';
import {
  TranscriptJSON,
  TranscriptParagraph,
  TranscriptWord,
  useTranscript,
} from 'features/Captions/Transcripts';
import {UserUpload, useUserUploadQuery} from 'services/userUploadAPI';
import {showErrorNotification, showSuccessNotification} from 'features/Common/utils';
import {useDispatch, useSelector} from 'react-redux';

import {NonUndefined} from 'react-hook-form';
import {PlayableItem} from 'features/types/canvasItemsSlice';
import {accountDetailsSelector} from 'features/selectors/authSelectors';
import {convertToSec} from 'features/EditorCanvas/utils';
import {muxThumbnail} from 'features/Dashboard/DashboardUploadDetails/utils';
import produce from 'immer';
import {useCreateUploadMediaClipMutation} from 'services/uploadMediaClipAPI';
import {useLanguage} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';

export type TranscriptRange = {startMs: number; endMs: number};

/**
 *
 * @param transcript
 * @param startMs
 * @param endMs
 * @param floorTime Whether or not the output word times should start at 0
 */
export const getTrimmedTranscript = (
  transcript: TranscriptJSON,
  range: TranscriptRange | undefined,
  floorTime: boolean,
) => {
  if (!range) return transcript;

  if (range.startMs === 0 && range.endMs === 0) {
    return transcript;
  }

  const {startMs, endMs} = range;

  const filteredTranscript: TranscriptJSON = [];

  let done = false;
  let paragraphIndex = -1;
  for (const paragraphWords of transcript) {
    paragraphIndex++;
    let wordIndex = -1;

    const trimmedParagraph: TranscriptParagraph = [];

    for (const word of paragraphWords) {
      wordIndex++;

      const outsideOfRangeLeft = parseFloat(`${word.end}`) <= startMs;
      if (outsideOfRangeLeft) continue;

      const outsideOfRangeRight = parseFloat(`${word.start}`) >= endMs;
      if (outsideOfRangeRight) {
        done = true;
        continue;
      }

      const startTime = floorTime ? word.start - startMs : word.start;
      const endTime = floorTime ? word.end - startMs : word.end;

      trimmedParagraph.push({
        start: startTime,
        end: endTime,
        text: word.text,
        paragraph: paragraphIndex,
        index: wordIndex,
      });
    }

    if (trimmedParagraph.length) {
      filteredTranscript.push(trimmedParagraph);
    }
    if (done) break;
  }

  return filteredTranscript;
};

export const transcriptRangeForItem = ({item}: {item: PlayableItem}) => {
  const timeOffsetSeconds = parseFloat(item.timeOffsetSeconds as any);
  const playLengthSeconds = parseFloat(item.playLengthSeconds as any);

  return {
    startMs: timeOffsetSeconds * 1000,
    endMs: (timeOffsetSeconds + playLengthSeconds) * 1000,
  };
};

export const modifyWord = ({
  transcript,
  word,
  text,
}: {
  transcript: TranscriptJSON;
  word: TranscriptWord;
  text: string;
}) => {
  return produce(transcript, draftTranscript => {
    const foundWord = draftTranscript[word.paragraph]?.[word.index];
    if (!foundWord) return;

    foundWord.text = text;
  });
};

export const useCreateClipFromTranscriptChapter = ({
  userUploadId,
  start,
  end,
}: {
  userUploadId: string;
  start: number;
  end: number;
}) => {
  // const dispatch = useDispatch();
  // const {getAccessTokenSilently} = useAuth0();

  const [createUserUploadClip] = useCreateUploadMediaClipMutation();

  const {
    data: userUpload,
    // isLoading,
    // isFetching,
    // @ts-ignore
  } = useUserUploadQuery({id: userUploadId!});

  const {language} = useLanguage();
  const selectedTranscript = useSelectedTranscript(userUploadId, language);

  const {accountId} = useSelector(accountDetailsSelector);

  const createClipFromChapter = async () => {
    try {
      if (!userUpload) throw new Error('uploadFile is undefined');
      if (!start || !end) throw new Error('start or end is null');

      window.analytics.track('Primary clip action on video caption');

      const startSec = convertToSec(start);
      const endSec = convertToSec(end);

      const sortedTime = [startSec, endSec].sort((a, b) => a - b);

      const result = createUserUploadClip({
        account_id: accountId,
        start_time: `${sortedTime[0]}`,
        end_time: `${sortedTime[1]}`,
        language: language,
        text_snippet: selectedTranscript?.text ?? '',
        thumbnail_url: muxThumbnail(userUpload.mux_playback_id, startSec, 640, 360),
        user_upload_id: userUploadId,
      });
      if (result)
        showSuccessNotification({
          title: 'Clip created',
          message:
            'Your selected clip was successfully created. It will appear in the panel on the left after a while.',
        });
    } catch (error) {
      showErrorNotification({
        title: 'Error creating clip from chapter',
        // @ts-ignore
        message: error.message,
      });
      throw error;
    }
  };

  return createClipFromChapter;
};

export const useCreateClip = (userUploadId: string) => {
  const [createUserUploadClip] = useCreateUploadMediaClipMutation();

  const {
    data: userUpload,
    // @ts-ignore
  } = useUserUploadQuery({id: userUploadId!});

  // console.log('userUpload', userUpload);

  const {selection, setSelection} = useSelection();
  const {language} = useLanguage();
  const selectedTranscript = useSelectedTranscript(userUploadId, language);
  const transcript = useTranscript(userUploadId, language);

  const {accountId} = useSelector(accountDetailsSelector);

  const createClip = async () => {
    try {
      if (!userUpload) throw new Error('uploadFile is undefined');
      if (!selection) throw new Error('selection is null');

      window.analytics.track('Primary clip action on video caption');

      const startSec = convertToSec(selection.start);
      let endSec = convertToSec(selection.end);

      if (transcript.transcript && selectedTranscript) {
        const flatTranscript = transcript.transcript.flat();
        const endWordIndex = flatTranscript.findIndex(
          word =>
            word.index === selectedTranscript.end.index &&
            word.paragraph === selectedTranscript.end.paragraph,
        );

        const nextWord = flatTranscript[endWordIndex + 1];
        if (nextWord) {
          const nextWordStart = convertToSec(nextWord.start);
          endSec = Math.min(nextWordStart, endSec + 0.5);
        } else {
          endSec = endSec + 0.5;
        }
      }

      const sortedTime = [startSec, endSec].sort((a, b) => a - b);

      createUserUploadClip({
        account_id: accountId,
        start_time: `${sortedTime[0]}`,
        end_time: `${sortedTime[1]}`,
        language: language,
        text_snippet: selectedTranscript?.text ?? '',
        thumbnail_url: muxThumbnail(userUpload.mux_playback_id, startSec, 640, 360),
        user_upload_id: userUploadId,
      });

      setSelection(null);
    } catch (error) {
      // @ts-ignore
      showErrorNotification({title: 'Error creating clip', message: error.message});
      throw error;
    }
  };

  return createClip;
};

export const replaceSelectedWords = ({
  fullTranscript,
  selectedTranscript,
  newText,
}: {
  fullTranscript: TranscriptJSON;
  selectedTranscript: Pick<NonUndefined<SelectedTranscript>, 'start' | 'end'>;
  newText: string;
}) => {
  if (selectedTranscript.start.paragraph !== selectedTranscript.end.paragraph) {
    throw new Error('Cannot edit transcript words across multiple paragraphs');
  }

  const newWords = newText.split(' ');

  const startTimeMs = selectedTranscript.start.start;
  const endTimeMs = selectedTranscript.end.end;
  const durationMs = endTimeMs - startTimeMs;
  const wordDurationMs = durationMs / newWords.length;

  const paragraphIndex = selectedTranscript.start.paragraph;

  const newWordsObject = newWords
    .map((text, wordIndex) => {
      const newWordDuration = (wordIndex + 1) * wordDurationMs;

      const start = startTimeMs + wordDurationMs * wordIndex;
      let end = start + newWordDuration - 20;
      if (wordIndex === newWords.length - 1) end = endTimeMs;

      return {start, end, text, paragraph: paragraphIndex};
    })
    .filter(word => word.text !== '');

  const paragraphWithoutEditedSection = fullTranscript[paragraphIndex].filter(word => {
    return (
      (word.start < startTimeMs && word.end < endTimeMs) ||
      (word.start > startTimeMs && word.end > endTimeMs)
    );
  });

  const newParagraph = [...paragraphWithoutEditedSection, ...newWordsObject]
    .sort((a, b) => a.start - b.start)
    .map((word, index) => ({...word, index}));

  const newTranscript = produce(fullTranscript, draftTranscript => {
    draftTranscript[paragraphIndex] = newParagraph;
  });

  return newTranscript;
};

export const findWord = (
  find: string,
  transcript: TranscriptJSON,
  matchCase: boolean = false,
): TranscriptWord[] => {
  const flatTranscript = transcript.flat();

  const res: TranscriptWord[] = [];
  for (const word of flatTranscript) {
    const isMatch = matchCase
      ? word.text === find
      : word.text.toLowerCase() === find.toLowerCase();
    if (isMatch) {
      res.push(word);
    }
  }

  return res;
};

export const findReplaceWords = ({
  fullTranscript,
  find,
  replace,
  matchCase = false,
}: {
  fullTranscript: TranscriptJSON;
  find: string;
  replace: string;
  matchCase?: boolean;
}): TranscriptJSON => {
  const occurrences = findWord(find, fullTranscript, matchCase);

  const newTranscript = produce(fullTranscript, draft => {
    for (const word of occurrences) {
      const foundWord = draft[word.paragraph]?.[word.index];
      if (!foundWord) continue;
      foundWord.text = replace;
    }
  });
  return newTranscript;
};
