import {ReactNode, useEffect} from 'react';

import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import filesize from 'filesize';
import {format} from 'date-fns';
import {useUserUploadQuery} from 'services/userUploadAPI';

export default function DetailTypeSidebar({
  children,
  uploadFile,
}: {
  children?: ReactNode;
  uploadFile: UserUploadsType;
}) {
  const {
    data: userUpload,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  } = useUserUploadQuery({id: uploadFile.id!});

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-4">
        <LoadingSpinner />;
      </div>
    );
  }

  if (!userUpload) {
    return <div />;
  }

  return (
    <div className="col-span-1 pl-4">
      <div className="mt-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Details</h3>
      </div>
      <div className="mt-5 mb-6 overflow-scroll border-t border-gray-200 pt-5">
        <dl className="">
          <div className="sm:grid sm:grid-cols-3 sm:gap-2">
            <dt className="text-sm font-medium leading-5 text-gray-500">Date uploaded</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:col-span-3 sm:mt-0">
              {format(new Date(userUpload?.created_at), 'Pp')}
            </dd>
          </div>
          <div className="mt-8 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm font-medium leading-5 text-gray-500">Last modified</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:col-span-3 sm:mt-0">
              {format(new Date(userUpload?.updated_at), 'Pp')}
            </dd>
          </div>
          <div className="mt-8 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm font-medium leading-5 text-gray-500">Extension</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:col-span-3 sm:mt-0">
              {userUpload?.extension}
            </dd>
          </div>
          <div className="mt-8 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm font-medium leading-5 text-gray-500">Size</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:col-span-3 sm:mt-0">
              {filesize(Number(userUpload?.file_size))}
            </dd>
          </div>
          {children}
        </dl>
      </div>
    </div>
  );
}
