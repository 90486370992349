import SocialCardPreview, {AssetClipResponse} from './components/SocialCardPreview';
import {useEffect, useState} from 'react';

import {BASE_URL} from 'constants/environment';
import {Helmet} from 'react-helmet';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import axios from 'axios';
import {useParams} from 'react-router-dom';

export function SocialCardPreviewPage() {
  const {contentId, destination} = useParams() as Record<string, string>;
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/share_content/${contentId}`);
        setIsLoading(false);
        setResponseData(response.data);
      } catch {
        console.error('Failed to connect to ', contentId);
      }
    })();
  }, []);

  const [responseData, setResponseData] = useState<AssetClipResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {isLoading || !responseData ? (
        <LoadingSpinner />
      ) : (
        <SocialCardPreview
          responseData={responseData}
          destination={destination}
          contentId={contentId}
        />
      )}
    </>
  );
}
