import {DashParams, routePaths} from 'routes/routesHelper';
import {SearchPages, useSearchResults} from './useSearchResults';
import {StringParam, encodeQueryParams} from 'use-query-params';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useEffect, useRef, useState} from 'react';

import {SearchIcon} from '@heroicons/react/outline';
import {X} from 'react-feather';
import {createAccountSearchQuery} from 'features/userLibrarySlice';
import {stringify} from 'query-string';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

type Inputs = {
  search: string;
};

export const SearchFormField = ({searchRef}: {searchRef: any}) => {
  const {
    state: {searchQuery},
    dispatch: searchResultsDispatch,
  } = useSearchResults();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = data => {
    // console.log(data);
    handleSearchQuery(data.search);
  };

  const dispatch = useDispatch();
  const {getAccessTokenSilently} = useAuthAlt();
  const [isSearching, setIsSearching] = useState(false);

  const navigate = useNavigate();

  const initiateSearch = (searchTerm: string) => {
    if (searchTerm === '') {
      return;
    }
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(createAccountSearchQuery(token, searchTerm));
        setValue('search', searchQuery);
        searchResultsDispatch({
          type: 'searchQuerySuccess',
          payload: {
            query: searchTerm,
          },
        });
      } catch (e) {
        console.error(e);
        searchResultsDispatch({
          type: 'searchQueryFailed',
        });
      }
    })();
  };

  const handleSearchQuery = (searchTerm: string) => {
    searchResultsDispatch({
      type: 'updateActivePage',
      payload: {
        newPage: SearchPages.SearchResults,
      },
    });

    searchResultsDispatch({
      type: 'searchQueryStart',
      payload: {
        query: searchTerm,
      },
    });

    navigate(
      `/${routePaths.dashboard}/${DashParams.SearchParam}?${stringify(
        encodeQueryParams(
          {
            q: StringParam,
          },
          {q: searchTerm},
        ),
      )}`,
    );
    initiateSearch(searchTerm);
  };

  useEffect(() => {
    // console.log('searchQuery', searchQuery);
    if (searchQuery) {
      handleSearchQuery(searchQuery);
    } else {
      setValue('search', '');
    }
  }, [searchQuery]);

  const onSearchBlur = (shouldClear: boolean) => {
    const searchTerm = getValues('search');
    if (shouldClear) {
      setValue('search', '');
      setIsSearching(false);
    } else if (searchTerm === '') {
      setIsSearching(false);
    }
  };

  const handleClearSearch = () => {
    setValue('search', '');
    searchResultsDispatch({
      type: 'updateActivePage',
      payload: {
        newPage: SearchPages.Recent,
      },
    });

    searchResultsDispatch({
      type: 'searchQueryStart',
      payload: {
        query: '',
      },
    });
  };

  const firstFocus = useRef(true);
  const onSearchFocus = () => {
    if (firstFocus.current) {
      firstFocus.current = false;
      return;
    }

    window.analytics.track('Focus on search within global search');
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="-ml-4 flex w-full flex-row pr-4">
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            {...register('search')}
            autoComplete="off"
            onFocus={onSearchFocus}
            onBlur={() => onSearchBlur(false)}
            className="block w-full border border-transparent bg-white py-2 pl-10 pr-3 leading-5 placeholder-gray-500 transition duration-150 ease-in-out focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
            placeholder="Search"
            type="search"
            autoFocus
          />
        </div>
        <button
          onClick={handleClearSearch}
          type="button"
          className="inline-flex items-center rounded-full border border-transparent p-1"
        >
          <X className="h-4 w-4 text-gray-400" />
        </button>
        <input hidden type="submit" />
      </div>
    </form>
  );
};
