import {Divider} from 'features/EditorCanvas/Sidebar/SidebarMenu';

export function LoadingProjectsList() {
  return (
    <div className="flex h-full w-full flex-col border-b border-gray-200 py-2">
      <div className="flex flex-col space-y-1">
        <div className="px-4">
          <div className="h-20 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        </div>
        <Divider size="small" color="bg-gray-100" />
        <div className="px-4">
          <div className="h-20 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        </div>
        <Divider size="small" color="bg-gray-100" />
        <div className="px-4">
          <div className="h-20 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        </div>
        <Divider size="small" color="bg-gray-100" />
        <div className="px-4">
          <div className="h-20 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        </div>
        <Divider size="small" color="bg-gray-100" />
        <div className="px-4">
          <div className="h-20 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        </div>
      </div>
    </div>
  );
}
