import {createContainer} from 'unstated-next';
import {useState} from 'react';

function useRecordingFormSession(initialState = 0) {
  const [isCreateRecordingModalOpen, setIsCreateRecordingModalOpen] =
    useState<boolean>(false);

  return {
    isCreateRecordingModalOpen,
    setIsCreateRecordingModalOpen,
  };
}

export const RecordingFormSessionContext = createContainer(useRecordingFormSession);
