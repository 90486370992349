import 'react-contexify/dist/ReactContexify.min.css';

import {useMemo, useState} from 'react';

import {DashboardContentSkeleton} from 'features/Dashboard/common/DashboardContentSkeleton';
import {DashboardHead} from 'features/Dashboard/common/DashboardHead';
import ErrorPage from 'features/EditorCanvas/components/shared/ErrorPage';
import {LoadingProjectsList} from './LoadingProjectsList';
import {ProjectsVirtualResults} from './ProjectsVirtualResults';
import cn from 'classnames';
import {projectAPI} from 'services/projectAPI';
import useDimensions from 'react-use-dimensions';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useInfiniteQuery} from 'services/useInfiniteQuery';
import {useProjectModal} from 'features/Dashboard/useProjectModal';
import {useWindowSize} from '@react-hook/window-size';

// import ProjectTemplateRow from './ProjectTemplateRow';
export const ProjectsListContainer = () => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [listViewType, setListViewType] = useState('list');

  const {
    hasNextPage,
    isFetching,
    fetchNextPage,
    resultPages: pages,
    isLoading,
    isSuccess,
    isError,
  } = useInfiniteQuery(projectAPI.endpoints.listProjects, {
    getNextPageParam: lastPage => lastPage.next ?? undefined,
  });

  const accountProjects = useMemo(() => {
    if (!pages) {
      return [];
    }

    return pages
      .map(page => page.data)
      .flat()
      .filter(accountProject => !accountProject.is_template);
  }, [pages]);

  const [width, heightContainer] = useWindowSize();
  const [refToolbar, {height: heightToolbar, width: widthToolbar}] = useDimensions();

  const onSearchFocus = () => {
    window.analytics.track('Focus on search within projects');
    setIsSearching(true);
  };

  const {dispatch: newProjectDispatch} = useProjectModal();
  const handleNewProject = () => {
    newProjectDispatch({
      type: 'setIsModalOpen',
      payload: {
        isModalOpen: true,
      },
    });
  };

  const projectTableHeight = useMemo(() => {
    // console.log('heightContainer', heightContainer);
    if (heightContainer && heightToolbar) {
      return heightContainer - heightToolbar;
    } else {
      return null;
    }
  }, [heightToolbar, heightContainer]);

  // console.log('projectTableHeight', projectTableHeight);

  return (
    <DashboardContentSkeleton>
      <div ref={refToolbar}>
        <DashboardHead title={'Video projects'}>
          <span className="shadow-xs relative z-0 inline-flex rounded-md">
            <button
              onClick={handleNewProject}
              type="button"
              className="relative inline-flex h-7 items-center rounded-md border border-gray-300 bg-white px-3 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
              New project
            </button>
            {/* <Menu as="span" className="relative -ml-px block">
              <Menu.Button className="relative inline-flex h-7 items-center rounded-r-md border border-gray-300 bg-white px-1 py-1 text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                <span className="sr-only">Open options</span>
                <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {items.map(item => (
                      <Menu.Item key={item.name}>
                        {({active}) => (
                          <div
                            onClick={item.onClick}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'flex w-full cursor-pointer flex-row items-center justify-start space-x-2 px-3 py-1 text-xs',
                            )}
                          >
                            <Youtube className="w-5" />
                            <span>{item.name}</span>
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu> */}
          </span>
        </DashboardHead>
        <div className="flex w-full border-b">
          <div className="mx-auto flex w-full max-w-7xl items-start py-2 text-center text-xs">
            <div style={{width: 160}}>Preview</div>
            <div
              className="flex flex-col items-start justify-center"
              style={{
                width: 300,
              }}
            >
              <div className="px-6">Details</div>
            </div>
            <div className="hidden w-28 justify-start md:flex">Status</div>
            <div
              className="hidden justify-start md:flex"
              style={{
                width: 140,
              }}
            >
              Properties
            </div>
          </div>
        </div>
      </div>
      <div
        style={{height: `${projectTableHeight}px`}}
        className={cn('mx-auto flex h-full w-full max-w-7xl flex-grow flex-col', {
          'items-start justify-start':
            (searchTerm !== '' && accountProjects.length !== 0) ||
            (!isSearching && !isLoading && accountProjects.length > 0),
          'items-center justify-center':
            (searchTerm === '' || accountProjects.length === 0) &&
            (isSearching || isLoading || accountProjects.length === 0),
        })}
      >
        {isLoading && (
          <div className="w-full" style={{height: `${projectTableHeight}px`}}>
            <LoadingProjectsList />
          </div>
        )}
        {isError && <ErrorPage />}
        {isSuccess && projectTableHeight && (
          <ProjectsVirtualResults
            parentHeight={projectTableHeight}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetching={isFetching}
            listView={listViewType}
            accountProjects={accountProjects.length > 0 ? accountProjects : []}
          />
        )}
      </div>
    </DashboardContentSkeleton>
  );
};
