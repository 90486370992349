import {ReactNode, createContext, useReducer, useContext} from 'react';

type Action = {type: 'setIsModalOpen'; payload: {isModalOpen: boolean}};
type Dispatch = (action: Action) => void;
type State = {isModalOpen: boolean};

const initialState = {
  isModalOpen: false,
};
type ProjectModalProviderProps = {children: ReactNode};

const ProjectModalStateContext =
  createContext<{state: State; dispatch: Dispatch} | undefined>(undefined);

function projectModalReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setIsModalOpen': {
      return {...state, isModalOpen: action.payload.isModalOpen};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ProjectModalProvider({children}: ProjectModalProviderProps) {
  const [state, dispatch] = useReducer(projectModalReducer, initialState);
  const value = {state, dispatch};
  return (
    <ProjectModalStateContext.Provider value={value}>
      {children}
    </ProjectModalStateContext.Provider>
  );
}

function useProjectModal() {
  const context = useContext(ProjectModalStateContext);
  if (context === undefined) {
    throw new Error('useProjectModal must be used within a ProjectModalProvider');
  }
  return context;
}
export {ProjectModalProvider, useProjectModal};
