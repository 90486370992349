import {Dialog, Transition} from '@headlessui/react';
import {ChevronUpIcon, ChevronDownIcon} from '@heroicons/react/solid';
import {useDebounceCallback} from '@react-hook/debounce';
import {TranscriptJSON} from 'features/Captions/Transcripts';
import {Checkbox} from 'features/Common/Checkbox';
import {Dispatch, Fragment, SetStateAction, useEffect, useRef, useState} from 'react';
import {atom, useRecoilValue, useSetRecoilState} from 'recoil';
import {SearchResultState} from './TranscriptEditor';
import {findReplaceWords, findWord} from './transcriptUtils';

interface Props {
  transcript: TranscriptJSON;
  onChangeTranscript: (transcript: TranscriptJSON, editedText: string) => void;
  searchResultsState: SearchResultState;
  setSearchResultsState: Dispatch<SetStateAction<SearchResultState>>;
}

export default function FindReplaceModal({
  transcript,
  onChangeTranscript,
  searchResultsState,
  setSearchResultsState,
}: Props) {
  const isOpen = useFindReplaceModalOpen();
  const setOpen = useSetRecoilState(openState);
  const initialFocusRef = useRef(null);
  const [find, setFind] = useState('');
  const [replace, setReplace] = useState('');
  const [matchCase, setMatchCase] = useState(false);

  const clearInputs = () => {
    setFind('');
    setReplace('');
  };

  const debouncedFindWord = useDebounceCallback(() => {
    const searchResults = findWord(find, transcript, matchCase);
    setSearchResultsState({
      searchResults,
      count: searchResults.length,
      activeIndex: 0,
    });
  }, 200);

  useEffect(() => {
    debouncedFindWord();
  }, [find, matchCase]);

  const handleReplaceAll = () => {
    const updatedTranscript = findReplaceWords({
      fullTranscript: transcript,
      find,
      replace,
      matchCase,
    });

    onChangeTranscript(updatedTranscript, '');

    clearInputs();
    setOpen(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={initialFocusRef}
        onClose={() => {
          clearInputs();
          setOpen(false);
        }}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Find and replace
                  </Dialog.Title>
                  <div className="text-sm text-gray-600">
                    Only exact matches will be found.
                  </div>
                  <div className="mt-2 grid w-full grid-cols-12 items-center gap-1 gap-y-2">
                    <label className="col-span-3" htmlFor="find">
                      Find
                    </label>
                    <div className="relative col-span-7">
                      <input
                        id="find"
                        value={find}
                        onChange={evt => setFind(evt.target.value)}
                        className="block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        ref={initialFocusRef}
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span className="text-gray-500 sm:text-sm">
                          {searchResultsState.count
                            ? `${searchResultsState.activeIndex + 1} of ${
                                searchResultsState.count
                              }`
                            : ''}
                        </span>
                      </div>
                    </div>

                    <button
                      type="button"
                      disabled={!searchResultsState.count}
                      className="cols-span-1 focus:outline-none inline-flex justify-center rounded-md  py-2 hover:bg-gray-100 focus:ring-2  focus:ring-blue-500 disabled:opacity-50"
                      onClick={() => {
                        setSearchResultsState(prev => ({
                          ...prev,
                          activeIndex:
                            prev.activeIndex === 0
                              ? prev.count - 1
                              : prev.activeIndex - 1,
                        }));
                      }}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                    </button>
                    <button
                      type="button"
                      disabled={!searchResultsState.count}
                      className="cols-span-1 focus:outline-none inline-flex justify-center rounded-md  py-2 hover:bg-gray-100 focus:ring-2  focus:ring-blue-500 disabled:opacity-50"
                      onClick={() => {
                        setSearchResultsState(prev => ({
                          ...prev,
                          activeIndex:
                            prev.activeIndex === prev.count - 1
                              ? 0
                              : prev.activeIndex + 1,
                        }));
                      }}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                    </button>

                    <label className="col-span-3" htmlFor="replace">
                      Replace with
                    </label>
                    <input
                      id="replace"
                      value={replace}
                      onChange={evt => setReplace(evt.target.value)}
                      className="col-span-9 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                    <div className="col-span-3">Match case</div>
                    <div className="col-span-1">
                      <Checkbox
                        tooltip={false}
                        label={'Match case'}
                        checked={matchCase}
                        onChange={() => setMatchCase(!matchCase)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  disabled={!searchResultsState.count}
                  className="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleReplaceAll}
                >
                  Replace all
                </button>
                <button
                  type="button"
                  className="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => {
                    clearInputs();
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const openState = atom({
  key: 'findReplaceModalOpen',
  default: false,
});

export const useFindReplaceModalOpen = () => useRecoilValue(openState);

export const useOpenFindReplaceModal = () => {
  const setOpen = useSetRecoilState(openState);

  return () => {
    setOpen(true);
  };
};
