import {MILK_API_RECORDING_STATE} from 'features/RecordingForms/recording/components/RecordingSessionWrap';
import {baseApi} from './baseAPI';

export type UserRecordingStep =
  | {
      type: 'first-page';
    }
  | {
      type: 'second-page-summary';
    }
  | {
      type: 'third-page-recording';
    }
  | {
      type: 'fourth-page-review';
    }
  | {
      type: 'fifth-page-review';
    };

export type UserRecordingContact = {};
export type UserRecordingInteractionSubmission = {};
export type UserRecordingInteractionForm = {};
export type RecordingInteractionStateType = {};

export type RecordingInteractionResponseStateType = {
  step: UserRecordingStep;
  userRecordingContact: UserRecordingContact | null;
  userRecordingInteractionSubmission: UserRecordingInteractionSubmission | null;
  userRecordingInteractionForm: UserRecordingInteractionForm | null;
};
export type ApiRecordingInteractionResponseType = {
  step: UserRecordingStep;
  user_recording_contact: UserRecordingContact | null;
  user_recording_interaction_submission: UserRecordingInteractionSubmission | null;
  user_recording_interaction_form: UserRecordingInteractionForm | null;
};

type UpdateId<T extends {}> = T & {userRecordingSubmissionRoomName: string};
// type UpsertId<T extends {}> = T & {userRecordingSubmissionRoomName?: string};

export type RecordingInteractionInput = {
  step?: {type: MILK_API_RECORDING_STATE};
};

type RecordingInteractionResponseStateInput = UpdateId<RecordingInteractionInput>;

export const recordingInteractionsAPI = baseApi.injectEndpoints({
  endpoints: builder => ({
    createRecordingInteractionResponseState: builder.mutation<
      ApiRecordingInteractionResponseType,
      {
        userRecordingInteractionFormId: string;
        step?: {type: MILK_API_RECORDING_STATE};
      }
    >({
      query: ({userRecordingInteractionFormId, step}) => ({
        url: 'recording_states',
        method: 'POST',
        errorTitle: 'Error creating user interaction response state',
        v2: true,
        data: {
          recording_state: {
            user_recording_interaction_form_id: userRecordingInteractionFormId,
            step,
          },
        },
        auth: false,
      }),
    }),
    updateRecordingInteractionResponseState: builder.mutation<
      ApiRecordingInteractionResponseType,
      RecordingInteractionResponseStateInput
    >({
      query: ({userRecordingSubmissionRoomName, ...patch}) => ({
        url: `recording_states/${userRecordingSubmissionRoomName}`,
        method: 'POST',
        errorTitle: 'Error creating user interaction response state',
        v2: true,
        data: {
          recording_state: patch,
        },
        auth: false,
      }),
    }),
  }),
});

export const {
  useCreateRecordingInteractionResponseStateMutation,
  useUpdateRecordingInteractionResponseStateMutation,
} = recordingInteractionsAPI;
