import {
  AccountLabelColor,
  AccountSearchQuery,
  ClipTranscriptResponseType,
  Glossary,
  UserUploadsType,
} from 'features/types/userLibrarySlice';
import {
  AccountWrapperAccountType,
  SearchTranscriptWordInstances,
} from 'features/types/authSlice';

import {BASE_URL} from 'constants/environment';
import {ResponseStatus} from './constants';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import axios from 'axios';
import {requestHeader} from './helpers';

/**
 * Notes on semvar
 *
 * # v1
 * clientState <items, sceneFrame, itemCaptions>
 *
 * # v2
 * clientState <items, sceneFrame, itemCaptions>
 */

export async function postUserEmailSubscriptionPreference(
  token: string,
  isSubscribed: boolean,
  email: string,
) {
  const endpoint = `${BASE_URL}/api/v2/user/update_email_preferences`;
  const response = await axios.post(
    endpoint,
    {
      user: {
        email,
        is_subscribed: isSubscribed,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

/**
 * Used to make updates when payment modal has been viewed. Should also
 * be used to update admin settings.
 */
export async function postUpdateUserPreferences({
  token,
  newUserPreferences,
}: {
  token: string;
  newUserPreferences: Record<string, string | boolean | number>;
}) {
  const endpoint = `${BASE_URL}/api/v2/user/update_user_preferences`;
  const response = await axios.post(
    endpoint,
    {
      user_preferences: newUserPreferences,
    },
    requestHeader(token),
  );
  return response.data;
}

/**
 * Used to make a search query and get results
 * @returns
 */
export async function postAccountSearchQuery({
  token,
  query,
}: {
  token: string;
  query: string;
}) {
  const endpoint = `${BASE_URL}/api/v2/accounts/search`;
  const response = await axios.post(
    endpoint,
    {
      search: {
        query,
      },
    },
    requestHeader(token),
  );
  return response.data as {
    status: string;
    payload: {
      uploads: {
        results: UserUploadsType[];
      };
      clips: {
        results: UploadMediaClipType[];
      };
      transcripts: {
        results: ClipTranscriptResponseType[];
      };
      transcripts_word_instances: {
        results: SearchTranscriptWordInstances[];
      };
    };
  };
}

/**
 * Used to get the recent search queries
 * @returns
 */
export async function getAccountSearchQueries({token}: {token: string}) {
  const endpoint = `${BASE_URL}/api/v2/accounts/search`;
  const response = await axios.get(endpoint, requestHeader(token));
  return response.data as {
    status: string;
    payload: {
      search_queries: AccountSearchQuery[];
    };
  };
}

export async function sendNewInvitation(
  token: string,
  accountId: string,
  currentUserEmail: string,
  invitedEmail: string,
): Promise<{
  payload: {
    message: string;
    account_invitations: {
      account_id: string;
      created_at: number;
      email: string;
      id: string;
      invited_by_id: string;
      name: string;
      token: string;
      updated_at: number;
      roles: {};
    }[];
    invitation: {
      account_id: string;
      created_at: number;
      email: string;
      id: string;
      invited_by_id: string;
      name: string;
      roles: {};
      token: string;
      updated_at: number;
    };
  };
  status: string;
}> {
  const endpoint = `${BASE_URL}/api/v2/account_invitations`;
  const response = await axios.post(
    endpoint,
    {
      account_invitation: {
        account_id: accountId,
        current_user_email: currentUserEmail,
        invited_user_email: invitedEmail,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

export async function updateCompanyName(
  token: string,
  email: string,
  accountId: string,
  name: string,
) {
  const endpoint = `${BASE_URL}/api/v2/accounts/${accountId}`;
  const response = await axios.patch(
    endpoint,
    {
      account: {
        name,
        id: accountId,
        email,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

export async function revokeAccountUser(token: string, email: string, inviteId: string) {
  const endpoint = `${BASE_URL}/api/v2/account_invitations/${inviteId}`;
  const response = await axios.delete(
    endpoint,
    requestHeader(token, {requesting_user: email}),
  );
  return response.data;
}

export async function postCreateWorkspaceToApi({
  token,
  email,
  workspace,
  workspaceDomain,
}: {
  token: string;
  email: string;
  workspace: string;
  workspaceDomain: string | undefined;
}) {
  const postWorkspaceEndpoint = `${BASE_URL}/api/v2/workspace`;
  const response = await axios.post(
    postWorkspaceEndpoint,
    {
      account: {
        name: workspace,
        domain: workspaceDomain,
        email,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

export async function postSubmitOnboardingSurvey(
  token: string,
  email: string,
  experienceLevel: string | undefined,
  organizationSize: string | undefined,
  organizationRole: string | undefined,
  publishFrequency: string | undefined,
) {
  const postPreferencesEndpoint = `${BASE_URL}/api/v2/user/onboarding`;
  const response = await axios.post(
    postPreferencesEndpoint,
    {
      account: {
        experience_level: experienceLevel,
        organization_size: organizationSize,
        organization_role: organizationRole,
        publish_frequency: publishFrequency,
        email,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

export async function postUserCompletedAccountWelcome(
  token: string,
  email: string,
  user: any,
  accountId: string,
) {
  const postWorkspaceEndpoint = `${BASE_URL}/api/v2/account/complete_welcome`;
  const response = await axios.post(
    postWorkspaceEndpoint,
    {
      account: {
        user: user,
        account_id: accountId,
        email,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

export async function postChangeActiveAccount(
  token: string,
  accountId: string,
  email: string,
) {
  const changeActiveEndpoint = `${BASE_URL}/api/v2/workspace/change_active`;
  const response = await axios.post(
    changeActiveEndpoint,
    {
      account: {
        account_id: accountId,
        email,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

type PostPaywallVisitResponse = {
  status: ResponseStatus;
  account_status: {
    project_count: number;
    upload_count: number;
    download_count: number;
  };
};

export async function postPaywallVisit(
  token: string,
  email: string,
  account: AccountWrapperAccountType,
): Promise<PostPaywallVisitResponse> {
  const postPaywallVisitEndpoint = `${BASE_URL}/api/v1/workspace/paywall_visit`;

  const response = await axios.post(
    postPaywallVisitEndpoint,
    {
      email,
      account_name: account.name,
    },
    requestHeader(token),
  );
  return response.data;
}

export async function postTrialExtension(
  token: string,
  accountId: string,
  email: string,
): Promise<any> {
  const postTrialExtensionEndpoint = `${BASE_URL}/api/v1/workspace/extend_trial`;

  const response = await axios.post(
    postTrialExtensionEndpoint,
    {
      email,
      account_id: accountId,
    },
    requestHeader(token),
  );
  return response.data;
}

type UpdateLabelColorResponse = {
  status: ResponseStatus;
  id: string;
  label_options: {
    bgColor: string;
    selectedColor: string;
  };
  label_type: 'color';
  label_value: string;
};

export async function postUpdateLabelColor(
  token: string,
  colorName: string,
  color: AccountLabelColor,
): Promise<UpdateLabelColorResponse> {
  const postUpdateLabelColorEndpoint = `${BASE_URL}/api/v2/account_labels`;

  const response = await axios.post(
    postUpdateLabelColorEndpoint,
    {
      label_options: {
        bgColor: color.bgColor,
        selectedColor: color.selectedColor,
      },
      label_type: 'color',
      label_id: color.id,
      label_value: colorName,
    },
    requestHeader(token),
  );

  return response.data;
}

export async function postCreateLabelColor(
  token: string,
  colorName: string,
  color: AccountLabelColor,
): Promise<UpdateLabelColorResponse> {
  const postUpdateLabelColorEndpoint = `${BASE_URL}/api/v2/account_labels/${color.id}`;

  const response = await axios.post(
    postUpdateLabelColorEndpoint,
    {
      label_options: {
        bgColor: color.bgColor,
        selectedColor: color.selectedColor,
      },
      label_type: 'color',
      label_value: colorName,
    },
    requestHeader(token),
  );

  return response.data;
}

type UpdateGlossaryResponse = {
  status: ResponseStatus;
};

export async function putUpdateGlossary(
  token: string,
  glossary: Glossary,
): Promise<UpdateGlossaryResponse> {
  const putUpdateGlossaryEndpoint = `${BASE_URL}/api/v2/glossaries/update`;

  const response = await axios.put(
    putUpdateGlossaryEndpoint,
    {
      glossary: glossary,
    },
    requestHeader(token),
  );

  return response.data;
}

export async function getShowGlossary(token: string): Promise<Glossary> {
  const getShowGlossaryEndpoint = `${BASE_URL}/api/v2/glossaries/current`;

  const response = await axios.get(getShowGlossaryEndpoint, requestHeader(token));
  return response.data;
}

export async function postUpdateIsGlossaryActive(
  token: string,
  enabled: boolean,
): Promise<{status: string; current_account: AccountWrapperAccountType}> {
  const postUpdateIsGlossaryActiveEndpoint = `${BASE_URL}/api/v2/glossaries/account`;

  const response = await axios.post(
    postUpdateIsGlossaryActiveEndpoint,
    {
      is_glossary_active: enabled,
    },
    requestHeader(token),
  );
  return response.data;
}

type PostRemoveTeamUserFromOrganization = {
  accountUserId: string;
  token: string;
};

export async function postRemoveTeamUserFromOrganization({
  accountUserId,
  token,
}: PostRemoveTeamUserFromOrganization) {
  const deleteUserEndpoint = `${BASE_URL}/api/v1/account_users/${accountUserId}`;

  const response = await axios.delete(deleteUserEndpoint, requestHeader(token));
  return response.data;
}

type PostUpdateTeamMemberRoleParams = {
  accountUserId: string;
  newRole: string;
  token: string;
};

export async function postUpdateTeamMemberRole({
  accountUserId,
  newRole,
  token,
}: PostUpdateTeamMemberRoleParams) {
  const updateTeamMemberRoleEndpoint = `${BASE_URL}/api/v1/account_users/${accountUserId}/roles`;

  const response = await axios.patch(
    updateTeamMemberRoleEndpoint,
    {roles: {[newRole.toLowerCase()]: true}},
    requestHeader(token),
  );
  return response.data;
}

export async function postUpdateIsDefaultColorsDisabled(
  token: string,
  disabled: boolean,
): Promise<{
  status: string;
  message?: string;
  current_account?: AccountWrapperAccountType;
}> {
  const endpoint = `${BASE_URL}/api/v2/visual_governance/default_colors_disabled/account`;

  const response = await axios.post(
    endpoint,
    {
      is_default_colors_disabled: disabled,
    },
    requestHeader(token),
  );
  return response.data;
}
