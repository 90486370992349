import {
  AccountWrapperAccountType,
  AccountWrapperAccountUsersType,
  AccountWrapperPlanType,
  CurrentAccountSettingsType,
  UserType,
} from 'features/types/authSlice';

import {GetRootState} from 'configureStore';
import {createSelector} from '@reduxjs/toolkit';

export const authSelector = (state: GetRootState) => state.auth;

export const isAnonymousSelector = (state: GetRootState) => {
  if (state.auth.user == null) return true;
  return state.auth.user.is_anonymous;
};

export const userMetadataSelector = (state: GetRootState) => {
  return state.auth.user?.metadata;
};

export const authConnectedAccountSelector = (state: GetRootState) =>
  state.auth.connectedAccounts;

export const accountIdSelector = (state: GetRootState) => {
  return state.auth.accountWrapper?.account?.id as string | undefined;
};

export const emailSelector = (state: GetRootState) => {
  return state.auth.email;
};

export const authAccountUsersSelector = (
  state: GetRootState,
): AccountWrapperAccountUsersType[] | undefined => {
  return state.auth.accountWrapper?.account_users;
};

export const authUsersSelector = (state: GetRootState) => {
  return state.auth.accountWrapper?.users;
};

export const authAccountPlanSelector = (state: GetRootState) => {
  return state.auth.accountWrapper?.plan;
};

export const authOnboardinStatusSelector = (state: GetRootState) => {
  return state.auth.accountWrapper?.onboarding_status;
};

export const authUserSelector = (state: GetRootState) => {
  return state.auth.user;
};

export const authAccountLabelSelector = (state: GetRootState) => {
  return state.auth.accountWrapper?.account_label;
};

export const authAccountAccountSelector = (state: GetRootState) => {
  return state.auth.accountWrapper?.account;
};

export const authAccountIsGlossaryActiveSelector = (state: GetRootState) => {
  return state.auth.accountWrapper?.account?.is_glossary_active;
};

export const authAccountCurrentSettingsSelector = (state: GetRootState) => {
  return state.auth.currentAccountSettings;
};

export const accountInvitationsSelector = (state: GetRootState) => {
  return state.auth.accountWrapper?.account_invitations;
};

export const authProcessorIdSelector = (state: GetRootState) => {
  return state.auth.accountWrapper?.account?.processor_id;
};

export const authAccountUsersDetailsSelector = (state: GetRootState) => {
  const accountUsers = authAccountUsersSelector(state);
  const users = authUsersSelector(state);
  const user = authUserSelector(state);

  return {accountUsers, users, user};
};

export const accountDetailsSelector = (state: GetRootState) => {
  const accountId = accountIdSelector(state);
  const email = emailSelector(state);
  const user = authUserSelector(state);

  return {email, accountId, user};
};

export const authSettingsSelector = (state: GetRootState) => {
  const account = authAccountAccountSelector(state);
  const user = authUserSelector(state);
  const processorId = authProcessorIdSelector(state);
  const accountUsers = authAccountUsersSelector(state);

  return {account, user, processorId, accountUsers};
};

export const authInviteTeamDetailsSelector = (state: GetRootState) => {
  const currentAccountSettings = authAccountCurrentSettingsSelector(state);
  const account = authAccountAccountSelector(state);
  const user = authUserSelector(state);
  const accountId = accountIdSelector(state);
  const accountInvitations = accountInvitationsSelector(state);
  const accountUsers = authAccountUsersSelector(state);

  return {
    currentAccountSettings,
    account,
    user,
    accountId,
    accountInvitations,
    accountUsers,
  };
};

export const authSettingsAndAccountSelector = (
  state: GetRootState,
): {
  account: AccountWrapperAccountType | undefined;
  accountUsers: AccountWrapperAccountUsersType[] | undefined;
  currentAccountSettings: CurrentAccountSettingsType;
  plan: AccountWrapperPlanType | undefined;
  user: UserType | null;
  authenticatedWithApi: boolean;
  isSubmittingNewWorkspace: boolean;
  isFetchingInitialApiRequest: boolean;
} => {
  const currentAccountSettings = authAccountCurrentSettingsSelector(state);
  const account = authAccountAccountSelector(state);
  const accountUsers = authAccountUsersSelector(state);
  const plan = authAccountPlanSelector(state);
  const user = authUserSelector(state);

  return {
    account,
    accountUsers,
    currentAccountSettings,
    plan,
    user,
    authenticatedWithApi: state.auth.authenticatedWithApi, // default: false
    isSubmittingNewWorkspace: state.auth.isSubmittingNewWorkspace, // default: false
    isFetchingInitialApiRequest: state.auth.isFetchingInitialApiRequest, // default: true
  };
};

export const authAccountUserPlanSelector = (state: GetRootState) => {
  const plan = authAccountPlanSelector(state);
  const user = authUserSelector(state);

  return {
    plan,
    user,
  };
};

export const authDeleteTeamMemberIsLoading = createSelector(
  [authAccountCurrentSettingsSelector],
  accountSettings => {
    return accountSettings.removeTeamMemberFromOrganization.isSending;
  },
);

export const isTemplateAccountSelector = (state: GetRootState) => {
  return state.auth.accountWrapper?.account.is_template_account;
};

export const isDefaultColorsDisabledSelector = (state: GetRootState) => {
  return state.auth.accountWrapper?.account?.is_default_colors_disabled ?? false;
};
