import {Divider} from 'features/EditorCanvas/Sidebar/SidebarMenu';

export function LoadingClipsList() {
  return (
    <div className="flex h-full w-full select-none flex-col border-b border-gray-200">
      <div className=" px-4 py-2">
        <LoadingClipListItem />
      </div>
      <Divider size="small" />
      <div className=" px-4 py-2">
        <LoadingClipListItem />
      </div>
      <Divider size="small" />
      <div className=" px-4 py-2">
        <LoadingClipListItem />
      </div>
    </div>
  );
}

const LoadingClipListItem = () => {
  return (
    <div className="flex w-full space-x-2">
      <div className="flex h-24 w-40 max-w-xs animate-pulse bg-gray-50"></div>
      <div className="flex w-full flex-col space-y-4">
        <div className="flex w-full flex-row items-center justify-between space-x-3">
          <div className="h-6 w-full max-w-xs animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
          <div className="h-6 w-20 max-w-xs animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        </div>
        <div className="flex w-full flex-col space-y-3">
          <div className="h-6 w-full max-w-2xl animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
          <div className="h-6 w-full max-w-2xl animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        </div>
      </div>
    </div>
  );
};
