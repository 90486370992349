import {
  selectCanvasItemsProjectsCanvas,
  selectCanvasProject,
} from 'features/selectors/canvasItemsSelectors';
import { useDispatch, useSelector } from 'react-redux';

import { CalculatedScene } from '../../CanvasTime/useScenes';
import { GetRootState } from 'configureStore';
import { ImagePlaceholder } from './ItemLayerVideoClip';
import { ScreenshotContext } from 'pages/Canvas/ScreenshotPage';
import { setDependencyReady } from 'features/canvasStateSlice';
import { useContext } from 'react';
import { useProjectId } from 'features/EditorCanvas/useProjectId';

export const ScreenshotFrame = ({
  itemId,
  playbackId,
  scene,
}: {
  itemId: string;
  playbackId: string;
  scene: CalculatedScene;
}) => {
  const dispatch = useDispatch();
  const projectId = useProjectId();
  const screenshotContext = useContext(ScreenshotContext);

  const { items: allItems } = useSelector((state: GetRootState) =>
    selectCanvasProject(state, projectId),
  );
  const item = allItems[itemId];

  let { timeToLoadMs } = screenshotContext;

  const timeOffset = scene.startTimeMs || 0;
  let videoStartTimeMs = scene.startTimeMs - timeOffset;

  if (item.viewType === 'video-clip') {
    const clipTimeOffsetMs = item.timeOffsetSeconds * 1000;
    videoStartTimeMs = videoStartTimeMs + clipTimeOffsetMs;
  }

  if (videoStartTimeMs) {
    timeToLoadMs = videoStartTimeMs;
  }

  const itemState = useSelector((state: GetRootState) => {
    return selectCanvasItemsProjectsCanvas(state)[projectId].items[itemId];
  });

  const dimension = itemState ? itemState.dimension : null;

  if (!dimension) {
    return <div />;
  }

  const clipTimeSeconds = timeToLoadMs / 1000;
  const url = `https://image.mux.com/${playbackId}/thumbnail.png?time=${clipTimeSeconds}&width=${Math.floor(
    dimension.width,
  )}&height=${Math.floor(dimension.height)}`;

  // console.log('url', url);

  return (
    <ImagePlaceholder
      src={url}
      onLoad={() => {
        dispatch(
          setDependencyReady({
            itemId,
            dependency: 'media',
          }),
        );
      }}
    />
  );
};
