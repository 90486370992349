import {CaptionItemStyle, ItemWithSource} from 'features/types/canvasItemsSlice';
import LayerCaptionsClipWrap from './LayerCaptionsClipWrap';

const lineHeight = 1.25;

function ItemLayerCaptionClip({
  dimension,
  id,
  projectId,
  style,
  item,
}: {
  dimension: {height: number; width: number};
  id: string;
  projectId: string;
  style: CaptionItemStyle;
  item: ItemWithSource;
}) {
  const {fontSize} = style;
  const {height, width} = dimension;

  return (
    <LayerCaptionsClipWrap
      fontSize={fontSize.toString()}
      height={height}
      itemId={id}
      lineHeight={lineHeight}
      projectId={projectId}
      width={width}
      item={item}
    />
  );
}

export default ItemLayerCaptionClip;
