import classNames from 'classnames';

export const ButtonStyle = {
  SUBTLE: 'subtle',
  PRIMARY: 'primary',
  GRAY: 'gray',
};

function getClasses(style) {
  switch (style) {
    case ButtonStyle.GRAY:
      return 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';
    case ButtonStyle.SUBTLE:
      return 'text-sm text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out';
    case ButtonStyle.PRIMARY:
    default:
      return 'inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';
  }
}

export const Button = ({style = ButtonStyle.PRIMARY, onClick, children}) => {
  return (
    <button
      type="button"
      className={classNames('whitespace-nowrap', getClasses(style))}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
