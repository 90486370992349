import {ButtonGroup, CanvasButton} from 'features/EditorCanvas/components/CanvasButton';
import styled, {css} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import {alignItems} from 'features/canvasItemsSlice';
import {canvasItemsSelectedItemIdsSelector} from 'features/selectors/canvasStateSelectors';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

const TYPES = ['vertical', 'horizontal'] as const;
const DIRECTIONS = ['start', 'center', 'end'] as const;

export type AlignType = typeof TYPES[number];
export type AlignDirection = typeof DIRECTIONS[number];

export const AlignPicker = () => {
  const projectId = useProjectId();
  const dispatch = useDispatch();

  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);
  return (
    <>
      <div className="mt-4 mb-2 flex w-full flex-row items-center justify-between">
        <label
          htmlFor="about"
          className="block text-sm font-bold leading-5 text-gray-700"
        >
          Align Layers
        </label>
        <span className="inline-flex rounded-md shadow-sm"></span>
      </div>
      <div className="space-y-2">
        {TYPES.map(type => (
          <ButtonGroup key={type}>
            {DIRECTIONS.map(direction => {
              return (
                <AlignButton
                  key={`${type}-${direction}`}
                  type={type}
                  direction={direction}
                  onClick={() => {
                    dispatch(
                      alignItems({
                        direction,
                        type,
                        ids: selectedItemIds,
                        projectId,
                      }),
                    );
                  }}
                />
              );
            })}
          </ButtonGroup>
        ))}
      </div>
    </>
  );
};

const AlignButton = ({
  type,
  direction,
  onClick,
}: {
  type: AlignType;
  direction: AlignDirection;
  onClick: () => void;
}) => {
  return (
    <CanvasButton onClick={onClick}>
      <AlignIcon direction={direction} type={type}>
        <Line direction={direction} />
        <Layer />
        <Layer style={{width: 8}} />
      </AlignIcon>
    </CanvasButton>
  );
};

const AlignIcon = styled.div<{direction: AlignDirection; type: AlignType}>`
  position: relative;
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    background-color: currentColor;
  }

  ${props =>
    props.type === 'horizontal' &&
    css`
      transform: rotate(90deg);
    `}

  ${props =>
    props.direction === 'start' &&
    css`
      align-items: flex-start;
      padding-left: 2px;

      ${Line} {
        left: 0;
      }
    `}

  ${props =>
    props.direction === 'center' &&
    css`
      align-items: center;

      ${Line} {
        left: 50%;
        margin-left: -1px;
      }
    `}

  ${props =>
    props.direction === 'end' &&
    css`
      align-items: flex-end;
      padding-right: 2px;

      ${Line} {
        right: 0;
      }
    `}
`;

const Line = styled.div<{direction: AlignDirection}>`
  position: absolute;
  top: 0;
  width: 2px;
  height: 16px;
`;

const Layer = styled.div`
  width: 12px;
  height: 3px;
  margin: 1px;
`;
