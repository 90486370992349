import {Menu} from '@headlessui/react';
import classNames from 'classnames';
import {DraggableProvided, DraggableStateSnapshot} from 'react-beautiful-dnd';
import {Plus, PlusSquare} from 'react-feather';
import ReactTooltip from 'react-tooltip';
import {Button} from 'ui/Button';
import {IconButton} from 'ui/IconButton';
import {CalculatedScene} from '../CanvasTime/useScenes';
import {canvasScenesZindex} from './canvasSceneConstants';
import {SceneCard} from './SceneCard';

export function CanvasSceneDraggable({
  scene,
  active,
  dragProvided,
  dragSnapshot,
  index,
  handleNewScene,
}: {
  scene: CalculatedScene;
  active: boolean;
  dragProvided: DraggableProvided;
  dragSnapshot: DraggableStateSnapshot;
  index: number;
  handleNewScene: (targetIndex?: number | undefined) => void;
}) {
  const handleSceneAdd = (newIndex: number) => {
    handleNewScene(newIndex);
  };

  return (
    <div className={classNames('relative h-full')}>
      <SceneCard
        scene={scene}
        dragSnapshot={dragSnapshot}
        dragProvided={dragProvided}
        index={index}
        focused={active}
      />
      {!dragSnapshot.isDragging && (
        <div
          className={classNames(
            'pointer-events-none absolute top-0 -right-6 bottom-0 flex items-center justify-center',
            `z-${canvasScenesZindex.CanvasSceneDraggable}`,
          )}
        >
          <div>
            <Menu>
              {({open}) => (
                <div className="pointer-events-auto relative flex flex-col items-center justify-center">
                  <ReactTooltip
                    place="top"
                    className="bg-gray-600 font-semibold"
                    effect="solid"
                  />
                  {!open && (
                    <Menu.Button>
                      <IconButton
                        icon={Plus}
                        variant="tertiary"
                        label="Add scene"
                        size="small"
                        _className="rounded-full mt-2.5"
                      />
                    </Menu.Button>
                  )}
                  <Menu.Items>
                    <div
                      className={classNames(
                        'absolute right-0 -top-4 space-y-2',
                        `z-${canvasScenesZindex.CanvasSceneDraggableMenuItem}`,
                      )}
                    >
                      <Menu.Item>
                        <Button
                          leftIcon={PlusSquare}
                          _px={2}
                          _py={2}
                          shortcut="Add blank scene"
                          iconOnly
                          variant="tertiary"
                          size="small"
                          _className="rounded-full"
                          onClick={() => {
                            handleSceneAdd(index + 1);
                          }}
                        />
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </div>
              )}
            </Menu>
          </div>
        </div>
      )}
    </div>
  );
}
