// Section First Campaign

import {Button, ButtonVariant} from 'ui/Button';
import {DashParams, routePaths} from 'routes/routesHelper';

import {CheckCircle} from 'react-feather';
import {Link} from 'react-router-dom';

export const SectionSecondCampaignHeader = () => {
  return (
    <>
      <SectionUploadCta />
    </>
  );
};

const SectionNoContentHero = () => {
  return (
    <div className="relative mx-auto w-full border">
      <div className="relative p-4 lg:flex  lg:items-center  lg:pt-0 lg:pb-0 lg:pl-0 lg:pr-6">
        <div className="hidden lg:block lg:flex-shrink-0">
          <img
            className="h-32 w-48"
            src="https://assets-milk.s3.amazonaws.com/share/import.png"
            alt=""
          />
        </div>

        <div className="relative space-y-3 pb-4 pr-6 lg:ml-6 lg:pb-0">
          <h2 className="text-lg tracking-tight text-gray-900 sm:text-xl">
            No videos? No problem.
          </h2>
          <blockquote className="relative">
            <div className="text-sm font-medium text-gray-600">
              <p>
                {
                  "We'll help you build your Milk Video catalog with testimonial collection, YouTube imports, and more."
                }
              </p>
            </div>
          </blockquote>
        </div>
        <div className="break">
          <Button
            onClick={() => {
              !!window.Intercom &&
                window.Intercom('showNewMessage', 'Help making branded template');
            }}
            variant={'tertiary'}
            _className="whitespace-nowrap"
          >
            Branded templates
          </Button>
        </div>
      </div>
    </div>
  );
};

const SectionUploadCta = () => {
  return (
    <div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="w-full min-w-0 space-y-2 py-3">
          <h2 className="text-normal font-semibold leading-7 text-gray-700 sm:truncate sm:text-2xl">
            {'Upload your logos and set your brand colors.'}
          </h2>
          <p className="text-normal  font-semi sm:text-normal text-gray-700 sm:truncate">
            {'Once you set up brand kit, you can create new videos quickly.'}
          </p>
        </div>
      </div>
      <Link to={`/${routePaths.dashboard}/${DashParams.BrandKitParam}`}>
        <Button variant={'primary'}>Upload assets</Button>
      </Link>
    </div>
  );
};

export const SectionSecondUpload = () => {
  return (
    <div className="space-y-6">
      <SectionSecondCampaignHeader />
      <SectionNoContentHero />
      <div className="flex items-center py-6 lg:pt-0">
        <p className="w-full text-left text-xs">
          Videos in a different app right now?{' '}
          <Link
            onClick={evt => {
              evt.preventDefault();
              window.analytics.track('Dashboard - Request migration content');
              !!window.Intercom &&
                window.Intercom('showNewMessage', 'Help me migrate my content');
            }}
            className="text-indigo-600 underline"
            to={`/${routePaths.dashboard}`}
          >
            We can help migrate
          </Link>
        </p>
      </div>
    </div>
  );
};
