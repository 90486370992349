import {DEFAULT_SCENE_DURATION, updateScenePlayTime} from 'features/canvasItemsSlice';
import {Fragment, useEffect} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {convertToMs, convertToSec} from 'features/EditorCanvas/utils';
import {useDispatch, useSelector} from 'react-redux';

import {Clock} from 'react-feather';
import {GetRootState} from 'configureStore';
import {NumberInput} from '../../shared/common/NumberInput';
import {Range} from 'react-range';
import {selectCanvasProject} from 'features/selectors/canvasItemsSelectors';
import {useState} from 'react';

const SceneLengthPicker = ({
  projectId,
  activeSceneId,
}: {
  activeSceneId: string;
  projectId: string;
}) => {
  const dispatch = useDispatch();
  const {canvasScenes} = useSelector((state: GetRootState) =>
    selectCanvasProject(state, projectId),
  );

  const activeScene = canvasScenes[activeSceneId];

  const [isEditingNumber, setIsEditingNumber] = useState(false);
  const [currentDuration, setCurrentDuration] = useState([
    activeScene?.duration | DEFAULT_SCENE_DURATION,
  ]);

  useEffect(() => {
    if (activeScene?.duration) {
      setCurrentDuration([activeScene.duration]);
    }
  }, [activeScene]);

  const handleRangeChange = (values: number[]) => {
    setCurrentDuration(values);
  };

  const onNumberChange = (value: number) => {
    if (0.1 <= value && value <= 10) {
      setCurrentDuration([convertToMs(value)]);
    }
  };
  const numberInputDisplay = convertToSec(currentDuration[0]);

  const handleFinalChange = (values: number[]) => {
    dispatch(
      updateScenePlayTime({
        projectId,
        sceneId: activeSceneId,
        scenePlayTimeMs: values[0],
      }),
    );
  };

  return (
    <Menu
      as="div"
      aria-label="scene-length-picker"
      style={{zIndex: 800}}
      className="relative inline-block text-left"
    >
      {({open}) => (
        <>
          <div>
            <Menu.Button className="focus:outline-none flex items-center justify-center rounded-full text-gray-400 hover:text-gray-600 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
              <Clock />{' '}
              <span className="ml-2 text-sm font-semibold">
                {convertToSec(currentDuration[0])} sec
              </span>
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="focus:outline-none absolute left-0 mt-2 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
            >
              <div className="w-64 px-4 py-3">
                <div className="flex w-full flex-row items-center justify-between">
                  <p className="text-sm">Duration</p>
                  {isEditingNumber ? (
                    <div className="w-20">
                      <NumberInput value={numberInputDisplay} onChange={onNumberChange} />
                    </div>
                  ) : (
                    <p
                      onClick={() => {
                        setIsEditingNumber(!isEditingNumber);
                      }}
                      className="cursor-pointer text-sm"
                      aria-label="Duration value"
                    >
                      {convertToSec(currentDuration[0])}sec
                    </p>
                  )}
                </div>

                <Range
                  values={currentDuration}
                  step={100}
                  min={100}
                  max={30000}
                  onFinalChange={handleFinalChange}
                  onChange={handleRangeChange}
                  renderTrack={({props, children}) => (
                    <div
                      {...props}
                      className="my-4 h-3 w-full rounded-md bg-gray-200 pr-2"
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({props}) => (
                    <div
                      {...props}
                      className="focus:outline-none h-5 w-5 translate-x-10 transform rounded-full bg-indigo-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    />
                  )}
                />
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default SceneLengthPicker;
