import {ArrowLeft, Cloud, UploadCloud, CornerUpLeft} from 'react-feather';
import {DashParams, routePaths} from 'routes/routesHelper';

import ActiveUserNav from 'features/Dashboard/shared/ActiveUserNav';
import {Button} from 'ui/Button';
import {EditorCanvasModals} from 'features/EditorCanvas/EditorCanvasModalsContext';
import {GetRootState} from 'configureStore';
import {IconButton} from 'ui/IconButton';
import ProjectNameField from './ProjectNameField';
import ReactTooltip from 'react-tooltip';
import {TimeCode} from './TimeCode';
import {UserLibraryUserProjectType} from 'features/types/userLibrarySlice';
import {isTemplateAccountSelector} from 'features/selectors/authSelectors';
import styled from 'styled-components';
import {useCreateProjectFromTemplate} from 'features/Dashboard/useCreateProject';
import {useNavigate} from 'react-router-dom';
import {useProjectBySlugQuery} from 'services/projectAPI';
import {usePublishTemplateMutation} from 'services/templatesAPI';
import {useSelector} from 'react-redux';
import {useUndo} from 'features/EditorCanvas/components/Undo';
import {UndoLabels} from '../../../../api/constants';

interface WorkspaceTopNavBarProps {
  isSaving: boolean;
  lastSavedDistance: string;
  preview: any;
  projectId: string;
  saveThenGoToProjects: any;
  syncClientState: () => Promise<void>;
}

const DraggableDesktopBar = styled.nav`
  -webkit-app-region: drag;
`;

function WorkspaceTopNavBar({
  isSaving,
  lastSavedDistance,
  preview,
  projectId,
  saveThenGoToProjects,
  syncClientState,
}: WorkspaceTopNavBarProps) {
  const navigate = useNavigate();
  const {undo, undoCount} = useUndo();
  const showEditor = () => {
    window.analytics.track('Show the editor');
    navigate(`/${routePaths.create}/${DashParams.CanvasParam}/${projectId}`);
  };

  const {data} = useProjectBySlugQuery({projectSlug: projectId});
  const project = data?.project;

  if (!project) return null;

  if (preview) {
    return (
      <nav className="top z-50 w-full flex-shrink-0 bg-gray-50">
        <div className="mx-auto w-full ">
          <DraggableDesktopBar className="relative flex h-16 items-center justify-between px-2 sm:px-4">
            <div className="flex flex-shrink justify-center lg:justify-start">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  onClick={() => {
                    showEditor();
                  }}
                  type="button"
                  className="focus:ring-blue mr-4 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M23 7V1h-6v2H7V1H1v6h2v10H1v6h6v-2h10v2h6v-6h-2V7h2zM3 3h2v2H3V3zm2 18H3v-2h2v2zm12-2H7v-2H5V7h2V5h10v2h2v10h-2v2zm4 2h-2v-2h2v2zM19 5V3h2v2h-2zm-5.27 9h-3.49l-.73 2H7.89l3.4-9h1.4l3.41 9h-1.63l-.74-2zm-3.04-1.26h2.61L12 8.91l-1.31 3.83z" />
                  </svg>
                </button>
              </span>
            </div>
          </DraggableDesktopBar>
        </div>
      </nav>
    );
  }

  return (
    <div className="select-none">
      {project.is_template && (
        <div className="bg-indigo-700 py-2 text-center text-sm font-semibold text-white">
          You are editing a template
        </div>
      )}
      <DraggableDesktopBar className="top flex h-16 w-full items-center justify-between border-b border-gray-200 bg-gray-50 px-2 sm:px-4">
        <div className="flex w-[400px] items-center space-x-2">
          <IconButton
            variant="tertiary"
            icon={ArrowLeft}
            label="Go back"
            onClick={() => saveThenGoToProjects()}
            size="small"
          />
          <div>
            <ProjectNameField projectId={projectId} />
          </div>
          {projectId && (
            <span data-tip={!undoCount ? UndoLabels.cannotUndoTip : UndoLabels.undoTip}>
              <IconButton
                variant="inline"
                icon={CornerUpLeft}
                label={UndoLabels.undoButtonLabel}
                onClick={() => undo()}
                disabled={!undoCount}
                size="small"
                _className={!undoCount ? '!text-gray-300' : ''}
              >
                <span className="sr-only">{UndoLabels.undo}</span>
              </IconButton>
              <ReactTooltip
                place="bottom"
                className="bg-gray-600 font-semibold"
                effect="solid"
              />
            </span>
          )}
        </div>
        <TimeCode />
        <div className="flex flex-shrink justify-center space-x-2 lg:justify-start">
          <ActiveUserNav projectId={projectId} />
          <span
            className="inline-flex items-center justify-center hover:bg-gray-100"
            data-tip={`Last saved ${lastSavedDistance}`}
          >
            <Button
              size="small"
              variant="inline"
              leftIcon={isSaving ? UploadCloud : Cloud}
              onClick={() => syncClientState()}
              iconOnly
              label={
                isSaving
                  ? 'Please wait. Canvas is saving.'
                  : 'Latest canvas state is synced'
              }
            >
              <ReactTooltip
                place="bottom"
                className="bg-gray-600 font-semibold"
                effect="solid"
              />
            </Button>
          </span>

          <RightButtons project={project} syncClientState={syncClientState} />
        </div>
      </DraggableDesktopBar>
    </div>
  );
}

const RightButtons = ({
  project,
  syncClientState,
}: {
  project: UserLibraryUserProjectType;
  syncClientState: () => Promise<void>;
}) => {
  const editorCanvasModals = EditorCanvasModals.useContainer();

  const showShareModal = () => {
    window.analytics.track('Show the canvas share modal');
    editorCanvasModals.updateExportModal(true);
  };

  const createFromTemplate = useCreateProjectFromTemplate();

  const isTemplateAccount = useSelector(isTemplateAccountSelector);
  if (isTemplateAccount) {
    return <GlobalTemplateButtons project={project} />;
  }

  if (project.is_template) {
    return (
      <Button
        size="small"
        variant="primary"
        onClick={() => createFromTemplate({template: project})}
      >
        New project from template
      </Button>
    );
  }

  return (
    <Button
      size="small"
      variant="primary"
      onClick={async () => {
        await syncClientState();
        showShareModal();
      }}
    >
      Export Video
    </Button>
  );
};

const GlobalTemplateButtons = ({project}: {project: UserLibraryUserProjectType}) => {
  const [publishTemplate] = usePublishTemplateMutation();

  const latestVersion = useSelector(
    (state: GetRootState) => state.serverSync.projects[project.slug]?.clientSyncVersion,
  );

  const {published_version} = project.metadata || {};
  const unpublishedChanges = latestVersion !== published_version;

  return (
    <>
      {project.metadata?.published_version == null && (
        <div className="flex flex-col justify-center">
          <div className="inline-flex rounded-md bg-purple-500 p-1 px-1.5 text-sm font-bold uppercase text-white">
            Draft
          </div>
        </div>
      )}
      <Button
        variant="primary"
        size="small"
        onClick={() => publishTemplate({projectSlug: project.slug})}
        disabled={!unpublishedChanges}
      >
        {unpublishedChanges ? 'Publish template' : 'Published'}
      </Button>
    </>
  );
};

export default WorkspaceTopNavBar;
