import {TranscriptToolbar, TranscriptToolbarButton} from './TranscriptToolbar';

import {XCircle} from 'react-feather';
import {useUploadTranscript} from '../Dashboard/DashboardUploadDetails/PlayableMedia/UploadTranscriptContext';

export const EditModeToolbar = () => {
  const {editMode, setEditMode} = useUploadTranscript();

  return (
    <TranscriptToolbar>
      {editMode && (
        <>
          <div className="pl-1 font-semibold">Click on a word to edit it</div>
          <TranscriptToolbarButton
            action={() => {
              setEditMode(false);
            }}
            icon={XCircle}
            label="Exit"
          />
        </>
      )}
    </TranscriptToolbar>
  );
};
