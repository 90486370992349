import {ChevronDownIcon, FilterIcon} from '@heroicons/react/solid';
import {DashParams, routePaths} from 'routes/routesHelper';
import {Disclosure, Menu, Transition} from '@headlessui/react';
import {Link, useNavigate} from 'react-router-dom';
import {authSelector, authUserSelector} from 'features/selectors/authSelectors';
import {differenceInDays, format, formatDistanceToNow} from 'date-fns';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {DashboardSidebarContainer} from 'features/Dashboard/DashboardWrapWithSidebar';
import {EmptyListMessage} from '../../shared/EmptyListMessage';
import {Fragment} from 'react';
import {LoadingSpinnerInline} from 'features/EditorCanvas/components/shared/LoadingSpinnerInline';
import {ProjectRecord} from 'services/projectAPI';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import useDimensions from 'react-use-dimensions';
import {useSelector} from 'react-redux';
import {useVirtual} from 'react-virtual';

const ROW_HEIGHT = 132;

export function ProjectsVirtualResults({
  accountProjects,
  parentHeight,
  hasNextPage,
  fetchNextPage,
  isFetching,
  listView,
}: {
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFetching: boolean;
  accountProjects: ProjectRecord[];
  listView: string;
  parentHeight: number;
}) {
  const {accounts} = useSelector(authSelector);
  const activeAccounts = accounts;

  const {dashboardSidebarHeight} = DashboardSidebarContainer.useContainer();

  const parentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const rowVirtualizer = useVirtual({
    size: hasNextPage ? accountProjects.length + 1 : accountProjects.length,
    parentRef,
    estimateSize: useCallback(() => ROW_HEIGHT, []),
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= accountProjects.length - 1 && hasNextPage && !isFetching) {
      // console.log('getting');
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    accountProjects.length,
    isFetching,
    rowVirtualizer.virtualItems,
  ]);

  const [tableHeaderRef, {height: tableHeaderHeight}] = useDimensions();

  const derivedTableHeight = useMemo(() => {
    return (
      parentHeight - (tableHeaderHeight ? tableHeaderHeight : 0) - dashboardSidebarHeight
    );
  }, [dashboardSidebarHeight, tableHeaderHeight, parentHeight]);

  if (!activeAccounts) {
    return null;
  }

  const fileIcon = (
    <svg
      className="w-full fill-current"
      width="70"
      height="50"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M56.2188 20.3438L40.9063 5.03125C40.4688 4.59375 40.0313 4.375 39.375 4.375H17.5C15.0937 4.375 13.125 6.34375 13.125 8.75V61.25C13.125 63.6563 15.0937 65.625 17.5 65.625H52.5C54.9063 65.625 56.875 63.6563 56.875 61.25V21.875C56.875 21.2187 56.6563 20.7813 56.2188 20.3438ZM39.375 9.625L51.625 21.875H39.375V9.625ZM52.5 61.25H17.5V8.75H35V21.875C35 24.2813 36.9687 26.25 39.375 26.25H52.5V61.25Z" />
      <path d="M21.875 48.125H48.125V52.5H21.875V48.125Z" />
      <path d="M21.875 35H48.125V39.375H21.875V35Z" />
    </svg>
  );
  const header = 'All Projects that you have access to will show up here.';
  const description =
    'Here you can manage visibility and share Projects with collaborators.';

  return accountProjects.length ? (
    <div className="mx-auto w-full" style={{height: `${parentHeight}px`}}>
      <div className=" border-b border-gray-200" style={{height: `${parentHeight}px`}}>
        <div
          className="min-w-full divide-y divide-gray-200"
          style={{height: `${parentHeight}px`}}
        >
          {/* <div ref={tableHeaderRef} className="select-none">
            <ProjectFilters />
          </div> */}
          <div
            className="relative divide-y divide-gray-200 overflow-auto bg-white"
            style={{height: `${derivedTableHeight}px`}}
            ref={parentRef}
          >
            {rowVirtualizer.virtualItems.map(virtualRow => {
              const isLoaderRow = virtualRow.index > accountProjects.length - 1;
              const accountProject = accountProjects[virtualRow.index];

              return isLoaderRow ? (
                hasNextPage ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                    key={virtualRow.index}
                  >
                    <div className="flex items-center justify-center py-2">
                      <LoadingSpinnerInline />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                    key={virtualRow.index}
                  >
                    {'Nothing more to load'}
                  </div>
                )
              ) : (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                  key={virtualRow.index}
                >
                  <ProjectListContentRow
                    key={accountProject.id}
                    accountProject={accountProject}
                    virtualRow={virtualRow}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <EmptyListMessage icon={fileIcon} header={header} description={description} />
  );
}

const ProjectListContentRow = ({
  accountProject,
  virtualRow,
}: {
  accountProject: ProjectRecord;
  virtualRow: any;
}) => {
  const user = useSelector(authUserSelector);
  const previewImage = accountProject?.latest_preview
    ? accountProject.latest_preview
    : 'http://fakeimg.pl/300x900/';

  const displayLatestUser =
    accountProject?.latest_updated_user?.id === user?.id
      ? 'you'
      : `${accountProject?.latest_updated_user?.first_name} ${accountProject?.latest_updated_user?.last_name}`;

  const lastEditedUserFragment = accountProject?.latest_updated_user
    ? ` by <span class="font-medium">${displayLatestUser}</span>`
    : '';

  const videoProperties = accountProject.video_properties;

  const videoDimensions = useMemo(() => {
    if (videoProperties?.dimensions) {
      return videoProperties.dimensions;
    } else {
      return null;
    }
  }, [videoProperties]);

  const projectDurationMs = useMemo(() => {
    if (videoProperties?.duration_ms) {
      return videoProperties.duration_ms;
    } else {
      return null;
    }
  }, [videoProperties]);

  return (
    <div className="group flex h-full w-full flex-row items-center transition duration-150 ease-in-out hover:bg-gray-50">
      <Link
        to={`/${routePaths.create}/${DashParams.CanvasParam}/${accountProject.slug}`}
        className="flex items-center justify-center"
        style={{
          height: `${virtualRow.size}px`,
        }}
      >
        <div className="w-40 py-2 px-4">
          <div className="rounded-lg bg-gray-50 p-3 group-hover:bg-gray-100">
            <div className="aspect-w-4 aspect-h-3 flex flex-col items-end justify-end overflow-hidden">
              <img
                className="object-contain object-center"
                src={previewImage}
                alt={`Project preview`}
              />
            </div>
          </div>
        </div>
      </Link>
      <Link
        to={`/${routePaths.create}/${DashParams.CanvasParam}/${accountProject.slug}`}
        className="block cursor-pointer transition duration-150 ease-in-out"
      >
        <div
          className="flex flex-col items-start justify-center px-6"
          style={{
            width: 300,
          }}
        >
          <div className="flex flex-row items-center text-xs font-medium leading-5 text-gray-300 ">
            <span className="text-indigo-500">{accountProject.name}</span>
          </div>

          <div className="text-xs leading-5 text-gray-700">
            Last updated{' '}
            <span className=" font-medium text-gray-900">
              {differenceInDays(new Date(), new Date(accountProject.updated_at)) < 7
                ? formatDistanceToNow(new Date(accountProject.updated_at), {
                    includeSeconds: false,
                  })
                : `${format(
                    new Date(accountProject.updated_at),
                    'MMM, d yyy',
                  )} at ${format(new Date(accountProject.updated_at), 'K:mm a')}`}
            </span>
            <div
              className="inline"
              dangerouslySetInnerHTML={{__html: lastEditedUserFragment}}
            />
          </div>
        </div>
      </Link>
      <div className="hidden w-28 justify-start md:flex">
        {accountProject.has_exported ? (
          <span
            data-tip={`This project was exported for download.`}
            className="inline-flex items-center rounded-md bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-800"
          >
            Downloaded
          </span>
        ) : (
          <span
            data-tip={`This project has not be exported.`}
            className="inline-flex items-center rounded bg-yellow-300 px-2 py-0.5 text-xs font-medium text-yellow-800 opacity-75"
          >
            Draft
          </span>
        )}
        <ReactTooltip
          place="bottom"
          className="bg-gray-600 font-semibold"
          effect="solid"
        />
      </div>
      <div>
        <div
          className="hidden flex-col items-start justify-center md:flex"
          style={{
            width: 140,
          }}
        >
          {(videoDimensions || projectDurationMs) && (
            <div className="flex flex-col items-start justify-center text-xs font-medium leading-5 text-gray-300">
              {videoDimensions && (
                <span className="text-gray-500">
                  {videoDimensions.width}x{videoDimensions.height}
                </span>
              )}
              {projectDurationMs && (
                <span className="text-gray-400">
                  {Math.ceil(projectDurationMs / 1000 / 60)} min
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const filters = {
  price: [
    {value: '0', label: '$0 - $25', checked: false},
    {value: '25', label: '$25 - $50', checked: false},
    {value: '50', label: '$50 - $75', checked: false},
    {value: '75', label: '$75+', checked: false},
  ],
  color: [
    {value: 'white', label: 'White', checked: false},
    {value: 'beige', label: 'Beige', checked: false},
    {value: 'blue', label: 'Blue', checked: true},
    {value: 'brown', label: 'Brown', checked: false},
    {value: 'green', label: 'Green', checked: false},
    {value: 'purple', label: 'Purple', checked: false},
  ],
  size: [
    {value: 'xs', label: 'XS', checked: false},
    {value: 's', label: 'S', checked: true},
    {value: 'm', label: 'M', checked: false},
    {value: 'l', label: 'L', checked: false},
    {value: 'xl', label: 'XL', checked: false},
    {value: '2xl', label: '2XL', checked: false},
  ],
  category: [
    {value: 'all-new-arrivals', label: 'All New Arrivals', checked: false},
    {value: 'tees', label: 'Tees', checked: false},
    {value: 'objects', label: 'Objects', checked: false},
    {value: 'sweatshirts', label: 'Sweatshirts', checked: false},
    {value: 'pants-and-shorts', label: 'Pants & Shorts', checked: false},
  ],
};
const sortOptions = [
  {name: 'Most Popular', href: '#', current: true},
  {name: 'Best Rating', href: '#', current: false},
  {name: 'Newest', href: '#', current: false},
];

const ProjectFilters = () => {
  return (
    <div className="bg-white">
      {/* Filters */}
      <Disclosure
        as="section"
        aria-labelledby="filter-heading"
        className="relative z-10 grid items-center"
      >
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>
        <div className="relative col-start-1 row-start-1 py-4">
          <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
            <div>
              <Disclosure.Button className="group flex items-center font-medium text-gray-700">
                <FilterIcon
                  className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                2 Filters
              </Disclosure.Button>
            </div>
            <div className="pl-6">
              <button type="button" className="text-gray-500">
                Clear all
              </button>
            </div>
          </div>
        </div>
        <Disclosure.Panel className="border-t border-gray-200 py-10">
          <div className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
              <fieldset>
                <legend className="block font-medium">Price</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.price.map((option, optionIdx) => (
                    <div
                      key={option.value}
                      className="flex items-center text-base sm:text-sm"
                    >
                      <input
                        id={`price-${optionIdx}`}
                        name="price[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        defaultChecked={option.checked}
                      />
                      <label
                        htmlFor={`price-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-gray-600"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">Color</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.color.map((option, optionIdx) => (
                    <div
                      key={option.value}
                      className="flex items-center text-base sm:text-sm"
                    >
                      <input
                        id={`color-${optionIdx}`}
                        name="color[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        defaultChecked={option.checked}
                      />
                      <label
                        htmlFor={`color-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-gray-600"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
              <fieldset>
                <legend className="block font-medium">Size</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.size.map((option, optionIdx) => (
                    <div
                      key={option.value}
                      className="flex items-center text-base sm:text-sm"
                    >
                      <input
                        id={`size-${optionIdx}`}
                        name="size[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        defaultChecked={option.checked}
                      />
                      <label
                        htmlFor={`size-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-gray-600"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">Category</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.category.map((option, optionIdx) => (
                    <div
                      key={option.value}
                      className="flex items-center text-base sm:text-sm"
                    >
                      <input
                        id={`category-${optionIdx}`}
                        name="category[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        defaultChecked={option.checked}
                      />
                      <label
                        htmlFor={`category-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-gray-600"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
        </Disclosure.Panel>
        <div className="col-start-1 row-start-1 py-4">
          <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
            <Menu as="div" className="relative inline-block">
              <div className="flex">
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map(option => (
                      <Menu.Item key={option.name}>
                        {({active}) => (
                          <a
                            href={option.href}
                            className={classNames(
                              option.current
                                ? 'font-medium text-gray-900'
                                : 'text-gray-500',
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm',
                            )}
                          >
                            {option.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </Disclosure>
    </div>
  );
};
