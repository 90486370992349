import {ButtonGroup, CanvasButton} from '../../CanvasButton';
import {Circle, RotateCcw, Square, XCircle} from 'react-feather';

import {GetRootState} from 'configureStore';
import {HStack} from 'features/Common/Stack';
import {Toolbar} from '../../CanvasItem/Toolbar';
import {selectProjectCanvasItem} from 'features/selectors/canvasItemsSelectors';
import {useEditCrop} from '../Canvas/utils/useEditCrop';
import {useSelector} from 'react-redux';

export const CropToolbar = ({
  projectId,
  itemKey,
}: {
  projectId: string;
  itemKey: string;
}) => {
  const item = useSelector((state: GetRootState) =>
    selectProjectCanvasItem(state, {projectId, itemId: itemKey}),
  );

  const editCrop = useEditCrop({item, itemKey, projectId});

  if (!editCrop.active) return null;

  return (
    <Toolbar
      left={
        <ButtonGroup>
          <CanvasButton
            onClick={() => editCrop.reset('rectangle')}
            variant={editCrop.active.type === 'rectangle' ? 'active' : 'default'}
          >
            <Square size={16} />
          </CanvasButton>
          <CanvasButton
            onClick={() => editCrop.reset('circle')}
            variant={editCrop.active.type === 'circle' ? 'active' : 'default'}
          >
            <Circle size={16} />
          </CanvasButton>
        </ButtonGroup>
      }
      right={
        <HStack spacing={1}>
          <CanvasButton leftIcon={XCircle} onClick={editCrop.exit}>
            Cancel
          </CanvasButton>
          <CanvasButton leftIcon={RotateCcw} onClick={editCrop.resetAndSave}>
            Reset
          </CanvasButton>
        </HStack>
      }
    />
  );
};
