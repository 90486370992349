import throttle from 'lodash.throttle';
import {createNanoEvents} from 'nanoevents';
import {DependencyList, useEffect, useLayoutEffect, useRef} from 'react';
import {createContainer} from 'unstated-next';
import {useMemoOne} from 'use-memo-one';
import {NonUndefined} from 'utility-types';
import {TimeState, usePlayback} from './usePlayback';

type TimeChangeReason = NonUndefined<
  'scrub' | 'playback' | 'state' | TimeState['lastAction']
>;

export type TimeInfo = Readonly<{currentTime: number; type: TimeChangeReason}>;

type Events = {
  time: (info: TimeInfo) => void;
};

export const CurrentTime = createContainer(() => {
  const emitter = useMemoOne(() => createNanoEvents<Events>(), []);

  const {getCurrentTime, isPlaying, isScrubbing, lastAction} = usePlayback();

  const rAFActive = useRef(false);

  useEffect(() => {
    emitter.emit('time', {currentTime: getCurrentTime(), type: lastAction ?? 'state'});
  }, [emitter, getCurrentTime, lastAction]);

  const currentTimeRef = useRef(getCurrentTime);
  useLayoutEffect(() => {
    currentTimeRef.current = getCurrentTime;
  }, [getCurrentTime]);

  const lastTime = useRef<number | null>(null);
  useLayoutEffect(() => {
    if (!isPlaying && !isScrubbing) return;

    rAFActive.current = true;

    let rAF: number;

    const run = () => {
      if (!rAFActive.current) return;

      const currentTime = currentTimeRef.current();
      if (lastTime.current !== currentTime) {
        emitter.emit('time', {
          currentTime,
          type: isScrubbing ? 'scrub' : 'playback',
        });
      }

      lastTime.current = currentTime;
      rAF = requestAnimationFrame(run);
    };

    rAF = requestAnimationFrame(run);
    return () => {
      rAFActive.current = false;
      cancelAnimationFrame(rAF);
    };
  }, [emitter, isPlaying, isScrubbing]);

  return emitter;
});

export type TimeEffectOptions = {
  throttleFPS?: number;
};

/**
 * Fires a callback whenever the currentTime changes.
 */
export const useTimeEffect = (
  callback: (info: TimeInfo) => void,
  deps: DependencyList,
  options?: TimeEffectOptions,
) => {
  const {throttleFPS} = options || {};

  const emitter = CurrentTime.useContainer();

  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const throttledCallback = useThrottle(callback, throttleFPS);
  useLayoutEffect(() => {
    return emitter.on('time', throttledCallback);
  }, [throttleFPS, emitter, throttledCallback]);

  const {getCurrentTime, lastAction} = usePlayback();
  useEffect(() => {
    const currentTime = getCurrentTime();
    throttledCallback({currentTime, type: lastAction ?? 'state'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentTime, throttledCallback, ...deps]);
};

function useThrottle<T extends (...args: any) => any>(fn: T, fps: number | undefined): T {
  const latestFn = useRef(fn);
  latestFn.current = fn;

  // @ts-ignore
  return useMemoOne(() => {
    if (fps == null) {
      return (...args: any) => {
        return latestFn.current(...args);
      };
    }

    return throttle(
      (...args) => {
        return latestFn.current(...args);
      },
      1000 / fps,
      {leading: true, trailing: true},
    );
  }, [fps]);
}
