import {
  canvasItemsSelectedItemIdsSelector,
  canvasItemsSelectedItemSelector,
} from 'features/selectors/canvasStateSelectors';

import {CloseSidebar} from 'features/EditorCanvas/Sidebar/CloseSidebar';
import DimensionPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/DimensionPicker';
import PositionPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/PositionPicker';
import RotationPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/RotationPicker';
import {SceneTimingPicker} from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/SceneTimingPicker';
import {TextInputOverride} from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/TextInputOverride';
import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useSelector} from 'react-redux';

export const TextComponentSidebar = () => {
  const projectId = useProjectId();

  const selectedItem = useSelector(canvasItemsSelectedItemSelector)(projectId);
  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);

  if (!selectedItem || selectedItemIds.length === 0) {
    return <CloseSidebar />;
  }

  return (
    <>
      <PositionPicker />
      <DimensionPicker />
      <RotationPicker />

      {ViewTypes.Text === selectedItem.item.viewType && <TextInputOverride />}
      <SceneTimingPicker />
    </>
  );
};
