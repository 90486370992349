import {ReactNode, createContext, useContext, useReducer} from 'react';

type Action = {type: 'set active upload id'; userUploadId: string | null};
type Dispatch = (action: Action) => void;
type State = {userUploadId: null | string};
type FileNavProviderProps = {children: ReactNode};

const FileNavStateContext =
  createContext<{state: State; dispatch: Dispatch} | undefined>(undefined);

function fileNavReducer(state: State, action: Action) {
  switch (action.type) {
    case 'set active upload id': {
      // console.log('action', action);
      return {userUploadId: action.userUploadId};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function FileNavProvider({children}: FileNavProviderProps) {
  const [state, dispatch] = useReducer(fileNavReducer, {userUploadId: null});
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, dispatch};
  return (
    <FileNavStateContext.Provider value={value}>{children}</FileNavStateContext.Provider>
  );
}

function useFileNavActiveUpload() {
  const context = useContext(FileNavStateContext);
  if (context === undefined) {
    throw new Error('useFileNav must be used within a FileNavProvider');
  }
  return context;
}

export {FileNavProvider, useFileNavActiveUpload};
