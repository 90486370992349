import {CanvasItem} from 'features/types/canvasItemsSlice';
import {PlayableContainingAudioMedia} from 'features/EditorCanvas/constants/ViewConstants';

export type CanvasItemsEntries = [string, CanvasItem];

/**
 * Isolate items containing audio media using Object.entries(items)
 * @param Object.entries(items)
 * @returns Object.entries(items) without items without audio media
 */
export const filterRemoveNonAudioLayers = ([
  itemKey,
  item,
]: CanvasItemsEntries): CanvasItemsEntries => {
  // @ts-ignore
  return PlayableContainingAudioMedia.includes(item.viewType);
};

/**
 * Return the first playable media item's key based on a scene's item list
 * @param items
 * @returns item key or null
 */
export const getFirstPlayableMediaItemKey = (
  items: Record<string, CanvasItem>,
): string | null => {
  const mediaLayersContainingAudio = Object.entries(items)
    .filter(filterRemoveNonAudioLayers)
    .map(([itemKey, item]) => itemKey);

  if (mediaLayersContainingAudio.length > 0) {
    return mediaLayersContainingAudio[0];
  }
  return null;
};
