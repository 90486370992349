import { ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { usePrepareUserRecordingInteractionState } from 'features/RecordingForms/recording/utils';

export const RecordingSessionCheck = ({ children }: { children: ReactElement }) => {
  const prepareRecordingInteractionState = usePrepareUserRecordingInteractionState();
  const { recordingInteractionFormId } = useParams<Record<string, string>>();
  const navigate = useNavigate();

  useEffect(() => {
    if (recordingInteractionFormId && recordingInteractionFormId === 'undefined') {
      console.error(
        'Issue with recording room from recordingInteractionFormId === "undefined"',
      );
      navigate(`/share/f/${recordingInteractionFormId}`);
    }
  }, [recordingInteractionFormId, navigate]);

  useEffect(() => {
    (async () => {
      await prepareRecordingInteractionState();
    })();
  }, []);

  return <div>{children}</div>;
};
