import {ItemStepFC, stepContainer} from './NewProjectWizard';
import {Pulse, StepButtons, StepHeader, useShouldPulse} from './utils';

import {Form} from 'react-final-form';
import {FormTextInput} from 'ui/TextInput';
import {useTrackOnce} from 'features/Common/utils';

export const SetText: ItemStepFC = ({state, item, itemId, actions}) => {
  const itemOverride = state.itemOverrides[itemId];

  const {shouldPulse, cancelPulse} = useShouldPulse({
    after: 3000,
    onlyIf: !itemOverride?.text,
  });

  return (
    <>
      <StepHeader heading={item.layerName} state={state} actions={actions}>
        <strong>Start by typing some text below.</strong> You will see your video update
        on the left as you type.
      </StepHeader>
      <Form<{text?: string}>
        onSubmit={({text}) => {
          window.analytics.track('TmpWzd -- Lc-Fin -- Text input step', {
            step: state.step,
            prompt: item.layerName,
          });

          actions.updateItemOverride({id: itemId, item: {text}});
          actions.nextStep();
        }}
        validate={values => {
          if (!values.text?.trim().length) {
            return {text: 'Please enter a value'};
          }
        }}
        initialValues={{text: itemOverride?.text}}
        validateOnBlur={false}
      >
        {({handleSubmit, valid}) => (
          <form className={stepContainer} onSubmit={handleSubmit}>
            <Pulse active={shouldPulse} className="rounded-md">
              <FormTextInput
                name="text"
                autoFocus
                onChange={text => {
                  actions.updateItemOverride({id: itemId, item: {text}});
                  cancelPulse();
                }}
              />
            </Pulse>
            <StepButtons
              state={state}
              actions={actions}
              overrideConfirm={{
                disabled: !valid,
                onClick: undefined,
                type: 'submit',
              }}
            />
          </form>
        )}
      </Form>
    </>
  );
};
