import Moveable, { getElementInfo } from 'react-moveable';
import { ZoomToolbar, zoomState } from './ZoomToolbar';
import {
  groupSelectItem,
  updateCanvasEditMode,
  updateSelectedItems,
} from 'features/canvasStateSlice';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActiveSelectionNavBar } from './ActiveSelectionNavBar';
import { CanvasItemLayerList } from '../../CanvasItemLayerList';
import { CanvasWrapper } from 'features/EditorCanvas/components/AppCanvas/Canvas/CanvasWrapper';
import { ClipDropZone } from './DraggableClip';
import { ContextMenuContext } from '../ContextMenu';
import { DimensionType } from 'features/types/canvasItemsSlice';
import Selecto from 'react-selecto';
import { addItemsFromClip } from 'features/canvasItemsSlice';
import { objFilter } from 'features/Common/utils';
import { sceneFrameSelector } from 'features/selectors/sceneFrameSelectors';
import { selectCanvasProject } from 'features/selectors/canvasItemsSelectors';
import { useRecentUsage } from 'services/recentUsageAPI';
import { useRecoilState } from 'recoil';
import { useScenes } from 'features/EditorCanvas/components/CanvasTime/useScenes';
import { useWatchElementSize } from 'features/Common/useElementSize';

const PADDING = 24;

const useFitCanvas = ({ canvasDimensions }: { canvasDimensions: DimensionType }) => {
  const [containerRef, size] = useWatchElementSize<HTMLDivElement>();
  const { width, height } = size || { width: 0, height: 0 };

  let scaleX = null;
  if (height != null) {
    scaleX = Math.min(1, (width - PADDING * 2) / (canvasDimensions.width / 0.8));
  }

  let scaleY = null;
  if (height != null) {
    scaleY = Math.min(1, (height - PADDING * 2) / (canvasDimensions.height / 0.8));
  }

  let scale = null;
  if (scaleX !== null && scaleY !== null) {
    scale = Math.min(scaleX, scaleY);
  }

  return [containerRef, { scale, containerSize: { width, height } }] as const;
};

function MainAppCanvas({ projectId }: { projectId: string }) {
  const { canvasDimensions } = useSelector(sceneFrameSelector)(projectId);
  const [zoom, setZoom] = useRecoilState(zoomState);
  const project = useSelector(state => selectCanvasProject(state, projectId));
  const [containerRef, { scale, containerSize }] = useFitCanvas({ canvasDimensions });

  useEffect(() => {
    if (scale !== null) {
      setZoom(scale);
    }
  }, [scale, setZoom]);

  const moveable = useRef<Moveable>(null);

  const dispatch = useDispatch();

  const scaledWidth = canvasDimensions.width * zoom;
  const scaledHeight = canvasDimensions.height * zoom;

  const paddingVertical = Math.max(0, (containerSize.height - scaledHeight) / 2);
  const paddingHorizontal = Math.max(0, (containerSize.width - scaledWidth) / 2);

  const { onContextMenu } = useContext(ContextMenuContext);

  const { activeScene } = useScenes();
  const selectableItemIds = Object.keys(
    objFilter(activeScene.items, item => item.locked !== true),
  );

  const { recentUsage } = useRecentUsage();

  return (
    <>
      <ActiveSelectionNavBar />

      <div
        className="relative flex-1"
        style={{ minHeight: 0 }}
        ref={containerRef}
        onContextMenu={onContextMenu({ type: 'canvas' })}
      >
        <div
          id="main-scrollable-canvas"
          className="min-w-screen top-0 bottom-0 h-full w-full overflow-auto bg-gray-50"
          style={{ padding: `${paddingVertical}px ${paddingHorizontal}px` }}
        >
          <div style={{ width: scaledWidth / 2, height: scaledHeight / 2 }}>
            <ClipDropZone
              id="artboard"
              onDrop={({
                clip: { uploadMediaClip, userUpload, type },
                position: { top, left },
              }) => {
                const position = {
                  top: top * canvasDimensions.height,
                  left: left * canvasDimensions.width,
                };

                dispatch(
                  addItemsFromClip({
                    type,
                    position,
                    projectId,
                    sceneId: activeScene.id,
                    uploadMediaClip,
                    userUpload,
                    style: recentUsage,
                  }),
                );
              }}
            >
              <div
                id="artboard"
                style={{
                  width: canvasDimensions.width,
                  height: canvasDimensions.height,
                  transform: `scale(${zoom})`,
                  transformOrigin: 'top left',
                }}
                className="z-1 relative flex-none shadow-md focus:outline-none"
              >
                <CanvasWrapper projectId={projectId} preview={false} moveableRef={moveable} />
              </div>
            </ClipDropZone>
          </div>
          {/*
            Testing TODO (jacques): E2E tests:
              - Only selects items within selection box
              - Doesn't select inactive items
          */}
          <Selecto
            getElementRect={getElementInfo}
            dragContainer={document.getElementById('artboard')!}
            selectableTargets={['[data-moveable]']}
            hitRate={0}
            selectByClick
            selectFromInside={false}
            toggleContinueSelect={['shift']}
            preventDefault
            onDragStart={event => {
              const target = event.inputEvent.target;

              const isMoveableElement =
                moveable.current?.isMoveableElement(target) ||
                target.className?.includes?.('moveable-');
              const isMoveableToolbar =
                target.dataset.moveableToolbar ||
                target.closest('[data-moveable-toolbar="true"]');

              if (isMoveableElement || isMoveableToolbar) {
                event.stop();
              }
            }}
            onSelectEnd={({ isDragStart, selected, inputEvent, rect }) => {
              const target = inputEvent.target;
              const selectedLayer =
                target.dataset.selected || target.closest('[data-selected="true"]');

              if (selectedLayer && selectedLayer.dataset && selectedLayer.dataset.id) {
                dispatch(groupSelectItem({ project, id: selectedLayer.dataset.id }));
              } else {
                const clickedLayer =
                  target.dataset.moveable || target.closest('[data-moveable="true"]');
                const clickedItem = clickedLayer?.dataset.id;

                dispatch(updateCanvasEditMode({ canvasEditMode: null }));

                let ids = selected.map(element => element.dataset.id!);
                ids = ids.filter(id => selectableItemIds.includes(id));

                dispatch(
                  updateSelectedItems({ project, selectedItemIds: ids, clickedItem }),
                );
              }

              if (isDragStart) {
                inputEvent.preventDefault();
                moveable.current?.dragStart(inputEvent);
              }
            }}
          />
        </div>
        {/* <CanvasLayoutPicker projectId={projectId} /> */}
        <CanvasItemLayerList projectId={projectId} />
        {scale && <ZoomToolbar fitZoom={scale} setZoom={setZoom} />}
      </div>
    </>
  );
}

export default MainAppCanvas;
