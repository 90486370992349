import {
  Hexagon,
  Icon,
  Layout,
  Maximize,
  Sliders,
  Tool,
  Type,
  Upload,
} from 'react-feather';
import {useEffect, useRef} from 'react';

import {MenuButton} from './MenuButton';
import {SidebarView} from './Sidebar';
import {canvasItemsSelectedItemIdsSelector} from 'features/selectors/canvasStateSelectors';
import classNames from 'classnames';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useProjectId} from '../useProjectId';
import {useSelector} from 'react-redux';
import {useSidebarMenu} from './SidebarMenuContext';

export function SidebarMenu() {
  const isTemplateSidebarActive = useFeatureFlag('canvas-sidebar-templates');

  const setActiveView = (newActiveView: string) => {
    dispatch({
      type: 'update active sidebar menu',
      activeMenu: newActiveView,
    });
  };
  const {
    dispatch,
    state: {activeMenu},
  } = useSidebarMenu();
  const projectId = useProjectId();

  const brandKitThemes = useFeatureFlag('brand-kit-themes');

  const insertButtons = [
    {
      id: 'uploads',
      label: 'Uploads',
      icon: Upload,
      onClick: () => setActiveView('Assets'),
    },
    {
      id: 'templates',
      label: 'Templates',
      icon: Layout,
      visible: isTemplateSidebarActive,
      onClick: () => setActiveView('Templates'),
    },
    {
      id: 'text',
      label: 'Text',
      icon: Type,
      onClick: () => setActiveView('TextElements'),
    },
    {
      id: 'shape',
      label: 'Elements',
      icon: () => (
        <svg
          className="w-7 fill-current text-gray-600"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13.5 2.5C7.425 2.5 2.5 7.425 2.5 13.5C2.5 18.892 6.38 23.378 11.5 24.319V26.853C4.993 25.887 0 20.276 0 13.5C0 6.044 6.044 0 13.5 0C20.276 0 25.887 4.993 26.853 11.5H24.319C23.379 6.38 18.892 2.5 13.5 2.5ZM19.25 14C17.8576 14 16.5223 14.5531 15.5377 15.5377C14.5531 16.5223 14 17.8576 14 19.25V34.75C14 37.65 16.35 40 19.25 40H34.75C37.65 40 40 37.65 40 34.75V19.25C40 16.35 37.65 14 34.75 14H19.25ZM16.5 19.25C16.5 18.5207 16.7897 17.8212 17.3055 17.3055C17.8212 16.7897 18.5207 16.5 19.25 16.5H34.75C35.4793 16.5 36.1788 16.7897 36.6945 17.3055C37.2103 17.8212 37.5 18.5207 37.5 19.25V34.75C37.5 35.4793 37.2103 36.1788 36.6945 36.6945C36.1788 37.2103 35.4793 37.5 34.75 37.5H19.25C18.5207 37.5 17.8212 37.2103 17.3055 36.6945C16.7897 36.1788 16.5 35.4793 16.5 34.75V19.25Z" />
        </svg>
      ),
      onClick: () => setActiveView('StaticElements'),
    },

    {
      id: 'BrandKit',
      label: 'Brand kit',
      icon: Hexagon,
      // Refactor TODO (jacques): Centralise sidebar show/hide logic
      visible: brandKitThemes,
      onClick: () => setActiveView('BrandKit'),
    },
  ];

  // Refactor TODO (jacques): Centralise sidebar show/hide logic
  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);
  const previousSelectedLength = useRef(selectedItemIds.length);

  // Refactor TODO (jacques): Centralise sidebar show/hide logic
  useEffect(() => {
    if (selectedItemIds.length > 0 && previousSelectedLength.current === 0) {
      setActiveView('Layer');
    }

    previousSelectedLength.current = selectedItemIds.length;
  }, [selectedItemIds]);

  const sidebarButtons: {
    id: SidebarView;
    label: string;
    icon: Icon;
    visible?: boolean;
  }[] = [
    {
      id: 'Layer',
      label: 'Layer',
      icon: Sliders,
      // Refactor TODO (jacques): Centralise sidebar show/hide logic
      visible: selectedItemIds.length > 0,
    },
    {
      id: 'ProjectSettings',
      label: 'Project',
      icon: Tool,
    },
    {
      id: 'CanvasResize',
      label: 'Resize',
      icon: Maximize,
    },
  ];

  return (
    <div className="w-20 overflow-y-auto bg-gray-50">
      <div className="flex w-full flex-col items-center py-3">
        <div className="w-full flex-1 space-y-2 px-2">
          <div className="text-center text-xs font-semibold text-gray-500">
            Insert&hellip;
          </div>
          {insertButtons.map(({id, visible = true, ...button}) => {
            if (!visible) return null;

            return <MenuButton key={id} active={activeMenu === id} {...button} />;
          })}
          <Divider />
          {sidebarButtons.map(({id, visible = true, ...button}) => {
            if (!visible) return null;

            return (
              <MenuButton
                key={id}
                active={activeMenu === id}
                {...button}
                onClick={() => setActiveView(id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export const Divider = ({
  size = 'normal',
  color = 'bg-gray-300',
}: {
  size?: 'small' | 'normal';
  color?: string;
}) => {
  if (size === 'small') {
    return (
      <span className="flex h-[1px] w-full py-1">
        <span className={classNames('flex h-[1px] w-full', color)} />
      </span>
    );
  }
  return (
    <span className="flex h-[1px] w-full py-3">
      <span className={classNames('flex h-[1px] w-full ', color)} />
    </span>
  );
};
