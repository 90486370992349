import {useEffect, useRef, useState} from 'react';

import {AccountLabelColor} from 'features/types/userLibrarySlice';
import classNames from 'classnames';
import {labelColors} from '../../ContentClips/constants';
import {useAccountLabels} from './useAccountLabels';

export const LabelFormInput = ({
  isNew,
  color,
  handleLabelEdit,
  setActiveField,
  closeInput,
}:
  | {
      isNew?: true;
      setActiveField?: (param: HTMLInputElement) => void;
      closeInput?: () => void;
      color: AccountLabelColor;
      handleLabelEdit: (param: AccountLabelColor | null) => void;
    }
  | {
      isNew?: false;
      setActiveField?: (param: HTMLInputElement) => void;
      closeInput?: () => void;
      color: AccountLabelColor;
      handleLabelEdit: (param: AccountLabelColor | null) => void;
    }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [tempLabelName, setTempLabelName] = useState<string>(color.name);
  const [tempActiveColor, setTempActiveColor] = useState<AccountLabelColor>(color);

  const [showColors, setShowColors] = useState(false);
  const {handleLabelSave} = useAccountLabels();

  useEffect(() => {
    if (setActiveField && ref?.current) {
      setActiveField(ref.current);
    }
  }, [ref, setActiveField]);

  const handleCancel = () => {
    if (isNew && closeInput) {
      closeInput();
    }
    handleLabelEdit(null);
  };

  return (
    <div className="relative flex items-center justify-between rounded-md border bg-gray-50 px-2 py-2">
      <div className="flex w-full flex-grow items-center justify-center">
        <button
          onClick={() => {
            setShowColors(true);
          }}
          className="focus:outline-none mr-2 flex h-full items-center justify-center rounded-md border border-gray-300 bg-white px-2 py-3 text-xs font-medium text-white hover:bg-indigo-100 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <label
            className={classNames(
              tempActiveColor.bgColor,
              'h-3 w-3 cursor-pointer rounded-full',
            )}
          >
            {/* {color.name} */}
          </label>
        </button>
        {showColors && (
          <div className="absolute bottom-0 left-0 z-30 flex space-x-1 border border-gray-300 bg-white p-1">
            {labelColors.map(labelColor => (
              <button
                key={labelColor.name}
                onClick={() => {
                  const newColor = color.id
                    ? {id: color.id, ...labelColor}
                    : {...labelColor};

                  setTempActiveColor(newColor);
                  setShowColors(false);
                }}
                className="focus:outline-none flex h-full items-center justify-center rounded-md border border-gray-200 bg-white px-1 py-1 text-xs font-medium text-white hover:bg-indigo-100 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <label
                  className={classNames(
                    labelColor.bgColor,
                    'flex h-3 w-3 cursor-pointer rounded-full',
                  )}
                >
                  {/* {color.name} */}
                </label>
              </button>
            ))}
          </div>
        )}

        <input
          required
          ref={ref}
          value={tempLabelName}
          onChange={evt => {
            setTempLabelName(evt.target.value);
          }}
          className="w-full flex-grow rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="Name your label"
        />
      </div>
      <div className="mr-3 flex h-5 items-center">
        <div className="sm:mt-0 sm:ml-2 sm:flex-shrink-0 ">
          <button
            onClick={handleCancel}
            className="focus:outline-none flex h-full w-full items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
        </div>
        <div className="sm:mt-0 sm:ml-2 sm:flex-shrink-0 ">
          <button
            onClick={() => {
              handleLabelSave(tempLabelName, tempActiveColor, handleCancel);
            }}
            className="focus:outline-none flex h-full w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-xs font-medium text-white hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
