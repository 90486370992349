import {ClipUploadMedia, LabelValueType} from 'features/types/userLibrarySlice';

import {ClipMediaType} from 'api/clipsAPI';
import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {SerializedSelection} from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/TrimTimeline';
import {UploadMediaClipCableActions} from 'cableSubscriptions/uploadMediaClipsCable';
import {baseApi} from './baseAPI';
import {subscribeToCable} from './utils';
import {userUploadAPI} from './userUploadAPI';

type CreateUploadMediaClipInput = {
  textSnippet: string;
  range: SerializedSelection;
  playbackId: string;
  uploadBucketKey: string;
  mediaType: string;
  clipName?: string;
};

export type UploadMediaClipType = {
  labels: LabelValueType[];
  media_type: ClipMediaType;
  user_upload_id: string;
  start_time: string;
  end_time: string;
  owner_id: string;
  created_at: number;
  updated_at: number;
  text_snippet: string;
  upload_media_type: ClipUploadMedia;
  thumbnail_url: string;
  account_id: string;
  id: string;
  clip_name: string;
  tag_list: any[];
  mux_playback_id?: string;
  url?: string;
  is_downloadable: boolean;
};

export type UploadMediaClipTypeInput = {
  account_id?: string;
  start_time?: string;
  end_time?: string;
  clip_name?: string;
  is_downloadable?: boolean;
  labels?: LabelValueType[];
  language?: LanguageId;
  media_type?: ClipMediaType;
  mux_playback_id?: string;
  owner_id?: string;
  tag_list?: any[];
  text_snippet?: string;
  thumbnail_url?: string;
  upload_media_type?: ClipUploadMedia;
  url?: string;
  user_upload_id?: string;
};

type UpdateId<T extends {}> = T & {id: string};
type UpsertId<T extends {}> = T & {id?: string};
interface ListUserUploadResponse<T> {
  page: number;
  count: number;
  items: number;
  pages: number;
  last: number;
  from: number;
  to: number;
  prev: number;
  next: number;
  data: T[];
}

export const uploadMediaClipAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['UploadMediaUploadMediaClip']})
  .injectEndpoints({
    endpoints: builder => ({
      listUploadMediaClips: builder.query<
        ListUserUploadResponse<UploadMediaClipType>,
        number | void
      >({
        query: (page = 1) => ({
          url: `upload_media_clips?page=${page}`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading clips data',
          refetchOnReconnect: false,
          refetchOnFocus: false,
        }),
        providesTags: (result, error, page) =>
          result
            ? [
                // Provides a tag for each post in the current page,
                // as well as the 'PARTIAL-LIST' tag.
                ...result.data.map(({id}) => ({type: 'UploadMediaClip' as const, id})),
                {type: 'UploadMediaClip', id: 'PARTIAL-LIST'},
              ]
            : [{type: 'UploadMediaClip', id: 'PARTIAL-LIST'}],
      }),
      uploadMediaClip: builder.query<UploadMediaClipType, {id: string}>({
        query: ({id}) => ({
          url: `upload_media_clips/${id}`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading upload media clip data',
        }),
        providesTags: (result, error, {id}) => [{type: 'UploadMediaClip', id}],
        async onCacheEntryAdded(_, api) {
          // NOTE (lenny): Make sure you understand this
          return subscribeToCable(
            {api, action: UploadMediaClipCableActions.MuxDownloadReady},
            () => {
              api.dispatch(uploadMediaClipAPI.util.invalidateTags(['UploadMediaClip']));
            },
          );
        },
      }),
      createUploadMediaClip: builder.mutation<
        UploadMediaClipType,
        UploadMediaClipTypeInput
      >({
        query: uploadMediaClip => ({
          url: `upload_media_clips`,
          method: `POST`,
          v2: true,
          errorTitle: `Error creating upload media clip page`,
          data: {
            upload_media_clip: uploadMediaClip,
          },
        }),
        invalidatesTags: (result, error) => {
          return [
            {type: 'UploadMediaClip', id: 'PARTIAL-LIST'},
            {type: 'UploadMediaClip', id: result?.id},
            'UploadMediaClip',
          ];
        },
        // async onQueryStarted({...patch}, {dispatch, queryFulfilled}) {
        //   const patchResult = dispatch(
        //     userUploadAPI.util.updateQueryData(
        //       'userUploadsUploadMediaClips',
        //       {id: patch?.user_upload_id!},
        //       draft => {
        //         Object.assign(draft, patch);
        //       },
        //     ),
        //   );
        //   try {
        //     await queryFulfilled;
        //   } catch {
        //     patchResult.undo();

        //     /**
        //      * Alternatively, on failure you can invalidate the corresponding cache tags
        //      * to trigger a re-fetch:
        //      * dispatch(api.util.invalidateTags(['Post']))
        //      */
        //   }
        // },
      }),

      updateUploadMediaClip: builder.mutation<
        UploadMediaClipType,
        UploadMediaClipTypeInput
      >({
        query: ({id, ...patch}) => ({
          url: `upload_media_clips/${id}`,
          method: `PATCH`,
          v2: true,
          errorTitle: `Error updating upload media clip`,
          data: {user_upload: patch},
        }),
        invalidatesTags: (result, error, {id}) => {
          // console.log('result', result);
          // console.log('id', id);
          // console.log('error', error);

          return [
            {type: 'UploadMediaClip', id},
            {type: 'UploadMediaClip', id: 'PARTIAL-LIST'},
          ];
        },
      }),

      renameUploadMediaClip: builder.mutation<
        {data: UploadMediaClipType},
        {id: string; name: string}
      >({
        query: clip => ({
          url: 'clips/update_name',
          method: 'POST',
          errorTitle: 'Error renaming clip',
          data: {
            clip: {clip_id: clip.id, clip_name: clip.name},
          },
        }),
        invalidatesTags: ['UploadMediaClip'],
      }),
      deleteUploadMediaClip: builder.mutation<{data: UploadMediaClipType}, string>({
        query: id => ({
          url: `clips/${id}/delete`,
          method: 'POST',
          errorTitle: 'Error deleting clip',
        }),
        invalidatesTags: ['UploadMediaClip'],
      }),
    }),
  });

export const {
  useCreateUploadMediaClipMutation,
  useRenameUploadMediaClipMutation,
  useDeleteUploadMediaClipMutation,
  useListUploadMediaClipsQuery,
  useUploadMediaClipQuery,
} = uploadMediaClipAPI;

// export const selectUploadMediaClipResult = (id: string) => {
//   return uploadMediaClipAPI.endpoints.uploadMediaClip.select({id});
// };

// export const selectUploadMediaClipById = createSelector(
//   [selectUploadMediaClipResult, (state, clipId) => clipId],
//   (uploadMediaClipResult, clipId) => {
//     console.log('clipId', clipId);
//     console.log('uploadMediaClipResult', uploadMediaClipResult(clipId));

//     return uploadMediaClipResult(clipId)?.data ?? null;
//   },
// );
