import { useMemo } from 'react';
import { Audio } from 'vendor/dailyco/shared/components/Audio';
import { BasicTray } from 'vendor/dailyco/shared/components/Tray';
import { useParticipants } from 'vendor/dailyco/shared/contexts/ParticipantsProvider';
import { useJoinSound } from 'vendor/dailyco/shared/hooks/useJoinSound';
import PropTypes from 'prop-types';
import { WaitingRoom } from './WaitingRoom';

export const Container = ({ children }) => {
  const { isOwner } = useParticipants();

  const roomComponents = useMemo(
    () => (
      <>
        {/* Show waiting room notification & modal if call owner */}
        {isOwner && <WaitingRoom />}
        {/* Tray buttons */}
        <BasicTray />
        {/* Audio tags */}
        <Audio />
      </>
    ),
    [isOwner]
  );

  return (
    <div className="room">
      {children}
      {roomComponents}

    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;
