import { Link, Pause, Play } from 'react-feather';
import { MouseEvent, useRef, useState } from 'react';

import { AUDIO_PREVIEW_URL_LARGE } from 'features/Common/constants';
import { BASE_SHARE_URL } from 'constants/environment';
import { ClipLabels } from 'features/Dashboard/DashboardPage/ContentClips/ClipLabels';
import { ClipUploadMedia } from 'features/types/userLibrarySlice';
import { IconButton } from 'ui/IconButton';
import { SparklesIcon } from '@heroicons/react/outline';
import { UploadMediaClipType } from 'services/uploadMediaClipAPI';
import classNames from 'classnames';
import { deleteUserClip } from 'features/userLibrarySlice';
import { useAuthAlt } from 'features/Auth/useAuthAlt';
import { useClipList } from 'features/Dashboard/DashboardPage/ContentClips/ClipListContext';
import { useContextMenu } from 'react-contexify';
import { useDispatch } from 'react-redux';
import { usePlayRange } from 'features/EditorCanvas/components/CanvasTime/utils';
import { useSelection } from 'features/TranscriptEditor/selection';

interface UploadClipCardProps {
  clip: UploadMediaClipType;
  onClickCreate: (clipId: string) => void;
}

export function UploadClipCard({ onClickCreate, clip }: UploadClipCardProps) {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuthAlt();

  const { dispatch: clipDispatch } = useClipList();
  const [isDeleted, setIsDeleted] = useState(false);

  const startTimeMs = Number(clip.start_time) * 1000;
  const endTimeMs = Number(clip.end_time) * 1000;
  const { isPlaying, toggle } = usePlayRange({ fromMs: startTimeMs, toMs: endTimeMs });
  const { show } = useContextMenu({
    id: `clip-${clip.id}`,
  });

  const { hoveringClip, setHoveringClip } = useSelection();

  const hoveringClipRef = useRef(hoveringClip);

  if (isDeleted || !clip) return null;

  const clipDurationSec = Math.floor(endTimeMs - startTimeMs) / 1000;

  const openLabelModal = () => {
    clipDispatch({
      type: 'update open color label modal',
      payload: {
        openClipLabelAssignmentModal: true,
      },
    });
    clipDispatch({
      type: 'update selected id list',
      payload: {
        newSelectedIdList: [clip.id],
      },
    });
  };

  const openRenameModal = () => {
    clipDispatch({
      type: 'update selected id list',
      payload: {
        newSelectedIdList: [clip.id],
      },
    });
    clipDispatch({
      type: 'update clip rename modal',
      payload: {
        openClipRenameModal: true,
      },
    });
  };

  const handleTogglePlay = (event: MouseEvent) => {
    event.stopPropagation();

    window.analytics.track('Handle clip card click');

    if (!isPlaying) {
      window.analytics.track('Initiate timed video playback', {
        playback_length: (endTimeMs - startTimeMs) / 1000,
      });
    }

    toggle();
  };

  const deleteClip = async () => {
    setIsDeleted(true);

    window.analytics.track('Delete clip card');

    try {
      const token = await getAccessTokenSilently();
      dispatch(deleteUserClip(token, clip.id));
    } catch (error) {
      setIsDeleted(false);
      console.error(error);
    }
  };

  const optionButtonProps = {
    variant: 'inline' as const,
    showLabel: true,
    _className:
      '!text-white !bg-gray-900 hover:!bg-opacity-90 backdrop-filter backdrop-blur-sm !w-[64px] h-full',
  };

  const isHighlighted = isPlaying;

  hoveringClipRef.current = hoveringClip;

  const isScaled = hoveringClip?.id === clip?.id;

  return (
    <>
      <div
        className={classNames(
          'group scale-100 transform overflow-hidden rounded-xl bg-gray-900 text-white ring-0 ring-indigo-400 ring-opacity-0 transition',
          isHighlighted && 'opacity scale-100 ring-4 ring-opacity-50',
          isScaled && 'scale-100',
        )}
        onMouseEnter={() => setHoveringClip(clip)}
        onMouseLeave={() => {
          setTimeout(() => {
            if (hoveringClipRef?.current?.id !== clip.id) return;
            setHoveringClip(null);
          }, 100);
        }}
      >
        <div
          className={'relative bg-cover bg-center lg:aspect-w-16 lg:aspect-h-4'}
          style={{
            backgroundImage: `url('${clip.upload_media_type === ClipUploadMedia.Video
              ? clip.thumbnail_url
              : AUDIO_PREVIEW_URL_LARGE
              }')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:
              clip.upload_media_type === ClipUploadMedia.Video ? 'cover' : 'contain',
            backgroundColor:
              clip.upload_media_type === ClipUploadMedia.Video ? 'none' : 'white',
          }}
        >
          <div
            className={classNames(
              'relative inset-0 flex items-center justify-between space-x-3 rounded-t-xl px-3 transition group-hover:opacity-100 lg:absolute',
              {
                'bg-white bg-opacity-30 backdrop-blur-sm backdrop-filter':
                  isScaled || isHighlighted,
              },
            )}
          >
            <div className="flex flex-row space-x-1">
              <div className="flex flex-col space-y-1">
                <IconButton
                  {...optionButtonProps}
                  _className={
                    '!text-white !bg-gray-900 !bg-opacity-60 hover:!bg-opacity-90 backdrop-filter backdrop-blur-sm !w-[30px]'
                  }
                  icon={Link}
                  label=""
                  showLabel={false}
                  size="small"
                  smallIcon
                  onClick={() => {
                    window.open(`${BASE_SHARE_URL}/a/${clip.id}`, '_blank');
                  }}
                />
                {/* <IconButton
                  {...optionButtonProps}
                  _className={
                    '!text-white !bg-gray-900 !bg-opacity-60 hover:!bg-opacity-90 backdrop-filter backdrop-blur-sm !w-[30px]'
                  }
                  icon={MoreVertical}
                  label=""
                  showLabel={false}
                  size="small"
                  smallIcon
                  onClick={event => {
                    show(event);
                    setMenuVisible(true);
                  }}
                /> */}
              </div>
              <ClipLabels colorScheme={'dark'} userClip={clip} />
            </div>
            <div className="flex h-full flex-col space-x-3 py-2">
              <IconButton
                {...optionButtonProps}
                icon={SparklesIcon}
                label="Export"
                onClick={() => onClickCreate(clip.id)}
              />
            </div>
          </div>
        </div>
        <div className="hidden items-center space-x-3 px-3 py-2 sm:flex">
          <IconButton
            variant="inline"
            icon={isPlaying ? Pause : Play}
            label={`${Math.round(clipDurationSec)} sec`}
            negativeMargin
            _className={classNames(
              '!text-gray-400 hover:!text-white !bg-transparent',
              isPlaying && '!text-white',
            )}
            showLabel
            onClick={handleTogglePlay}
          />
          <div className="flex flex-grow text-xs leading-tight truncate-3-lines">
            &ldquo;{clip.text_snippet}
          </div>
        </div>
      </div>
      {/* <UploadClipCardMenu
        clip={clip}
        setMenuVisible={setMenuVisible}
      // openLabelModal={openLabelModal}
      // openRenameModal={openRenameModal}
      // deleteClip={deleteClip}
      /> */}
    </>
  );
}
