import classNames from 'classnames';
import {getImageSize} from 'features/canvasItemsSlice';
import {Time} from 'features/Common/Time';
import {useWatchElementSize} from 'features/Common/useElementSize';
import {muxThumbnail} from 'features/Dashboard/DashboardUploadDetails/utils';
import {fitWithin} from 'features/EditorCanvas/Layout/utils';
import {DimensionType} from 'features/types/canvasItemsSlice';
import {
  FC,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import {FocusedFlowLayoutProps} from './Layout';
import {DesignState, SizeId, sizes} from './Constants';

export const getThumbnail = (media: FocusedFlowLayoutProps['media']) => {
  if (!media) {
    return {
      url: '/webinar-preview.jpg',
      width: 1600,
      height: 1000,
    };
  }

  const url = muxThumbnail(
    media.upload.mux_playback_id,
    media.clip ? new Time(media.clip.range.start).s : 0,
    1920,
    1080,
    'preserve',
  );

  return {
    url,
    width: media.upload.max_width ?? 1,
    height: media.upload.max_height ?? 1,
  };
};

export type Thumbnail = ReturnType<typeof getThumbnail>;

export const useLogo = (logoUrl: DesignState['logo'], onChange?: () => void) => {
  const [headingRef, headingSize] = useWatchElementSize();
  const [logo, setLogo] = useState<{url: string; size: DimensionType} | null>(null);

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  useEffect(() => {
    const status = {stale: false};
    const url = logoUrl;

    if (!url || !headingSize) {
      setLogo(null);
    } else {
      getImageSize(url).then(size => {
        if (status.stale) return;

        const fit = fitWithin({
          container: {width: headingSize.width * 0.2, height: headingSize.height},
          item: size,
        });

        setLogo({size: fit.dimension, url});
      });
    }

    onChangeRef.current?.();

    return () => {
      status.stale = true;
    };
  }, [logoUrl, headingSize, logo?.url]);

  return {headingRef, logo};
};

export const Captions = ({
  design,
  className,
  containerClassName,
}: {
  design: DesignState;
  className?: string;
  containerClassName?: string;
}) => {
  return (
    <div
      data-layer={design.style === 'background' && 'captions-background'}
      className={classNames(
        design.style === 'background' &&
          'absolute inset-x-0 bottom-0 bg-black bg-opacity-60',
        containerClassName,
      )}
      style={{
        color: design.colorScheme.captions,
      }}
    >
      <div
        className={classNames(
          'text-center text-6xl font-bold leading-tight line-clamp-2',
          design.style === 'background' && 'my-12',
          design.style === 'cover' && 'mx-4 my-3',
          className,
        )}
        data-layer="captions"
      >
        <span
          style={{
            color: design.colorScheme.captionsActive,
          }}
        >
          Active captions
        </span>{' '}
        and inactive captions
      </div>
    </div>
  );
};

export const PreviewContainer = ({
  children,
  size,
}: {
  children: ReactNode;
  size: SizeId;
}) => {
  const [ref, previewDimensions] = useWatchElementSize();

  const previewSize = previewDimensions.width;

  const scaleX = previewSize / sizes[size].dimensions.width;
  const scaleY = previewSize / sizes[size].dimensions.height;
  const scale = Math.min(scaleX, scaleY);

  const scaledWidth = sizes[size].dimensions.width * scale;
  const scaledHeight = sizes[size].dimensions.height * scale;

  return (
    <div className="aspect-w-1 aspect-h-1 relative w-full">
      <div className="absolute inset-0" ref={ref}>
        <div
          className="relative mx-auto"
          style={{width: scaledWidth, height: scaledHeight}}
        >
          <div
            className="relative w-full origin-top-left"
            style={{
              ...sizes[size].dimensions,
              transform: `scale(${scale})`,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export const FitWithin: FC<{size: SizeId; autoHeight?: boolean}> = ({
  children,
  size,
  autoHeight,
}) => {
  const [ref, containerSize] = useWatchElementSize();

  let scale = 1;
  if (autoHeight) {
    scale = containerSize.width / sizes[size].dimensions.width;
  } else {
    const scaled = fitWithin({container: containerSize, item: sizes[size].dimensions});

    const scaleX = scaled.dimension.width / sizes[size].dimensions.width;
    const scaleY = scaled.dimension.height / sizes[size].dimensions.height;

    scale = Math.min(scaleX, scaleY);
  }

  const scaledWidth = sizes[size].dimensions.width * scale;
  const scaledHeight = sizes[size].dimensions.height * scale;

  return (
    <div style={{width: scaledWidth, height: scaledHeight}}>
      <div
        className="absolute inset-0 flex h-full w-full items-center justify-center"
        ref={ref}
      >
        <div style={{width: scaledWidth, height: scaledHeight}}>
          <div
            className="relative origin-top-left"
            style={{
              ...sizes[size].dimensions,
              transform: `scale(${scale})`,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ScalePreview: FC<{
  height?: number;
  maxHeight?: number;
  dimensions: DimensionType;
  className?: string;
  overlay?: ReactNode;
}> = ({height, maxHeight, dimensions, children, className, overlay}) => {
  const [containerRef, containerSize] = useWatchElementSize();

  let scale = 1;
  if (height != null) {
    scale = height / dimensions.height;
  } else {
    scale = containerSize.width / dimensions.width;

    if (maxHeight != null) {
      const maxHeightScale = maxHeight / dimensions.height;
      if (maxHeightScale < scale) scale = maxHeightScale;
    }
  }

  const left = Math.max(0, (containerSize.width - dimensions.width * scale) / 2);

  return (
    <div className="w-full" ref={containerRef}>
      <div
        className={classNames('relative', className)}
        style={{
          width: dimensions.width * scale,
          height: dimensions.height * scale,
          left,
        }}
      >
        <div
          style={{...dimensions, transform: `scale(${scale})`}}
          className="origin-top-left"
        >
          {children}
        </div>
        <div className="absolute inset-0">{overlay}</div>
      </div>
    </div>
  );
};

export const Container = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{design: DesignState}>
>(({design, children}, ref) => {
  return (
    <div
      className={classNames(
        'absolute inset-0 flex flex-col p-10',
        design.style !== 'background' && 'space-y-10',
        design.style === 'contain' && '!p-16',
      )}
      style={{
        fontFamily: 'Open Sans',
        backgroundColor: design.colorScheme.background,
        color: design.colorScheme.text,
      }}
      ref={ref}
    >
      {children}
    </div>
  );
});

export const Heading = ({
  title,
  design,
  className,
  onChangeLogo,
}: {
  title: string | undefined;
  design: DesignState;
  className?: string;
  onChangeLogo?: () => void;
}) => {
  const {headingRef, logo} = useLogo(design.logo, onChangeLogo);

  if (!title && !logo) return null;

  return (
    <div
      data-layer={design.style === 'background' && 'title-background'}
      className={classNames(
        'z-10',
        design.style === 'background' &&
          'absolute inset-x-0 top-0 bg-black bg-opacity-60 text-white',
        design.style === 'background' && 'p-10 px-14',
        design.style === 'cover' && 'px-5',
        className,
      )}
    >
      <div
        className="flex h-full min-h-[4.5rem] w-full items-center justify-center space-x-10 text-6xl font-bold leading-normal"
        ref={headingRef}
      >
        &#8203;
        {title && (
          <div className="flex-1" data-layer="title" style={{fontFamily: 'Open Sans'}}>
            {title}
          </div>
        )}
        {logo && <img alt="" src={logo.url} style={logo.size} data-layer="logo" />}
      </div>
    </div>
  );
};
