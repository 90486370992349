import {ReactNode} from 'react';

export type CardHeaderOptionType = {
  active: boolean;
  activeText: string;
  deactiveText: string;
};

export default function CardPageHeader({
  children,
  title,
  cardImage,
  description,
}: {
  children?: ReactNode;
  title: string;
  cardImage: any;
  description: string;
}) {
  return (
    <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
      <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-4">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img className="h-12 w-12" src={cardImage} alt="logo" />
            </div>
            <div className="ml-4 space-y-1">
              <h3 className="text-xl font-bold leading-6 text-gray-900">{title}</h3>
              <p className="text-sm text-gray-500">{description}</p>
            </div>
          </div>
        </div>
        <div className="ml-4 mt-4 flex flex-shrink-0">{children}</div>
      </div>
    </div>
  );
}
