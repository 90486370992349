import DimensionPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/DimensionPicker';
import PositionPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/PositionPicker';
import {ProgressAnimationActiveColorPicker} from './ProgressAnimationActiveColorPicker';
import RotationPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/RotationPicker';

export const ProgressAnimationSidebar = () => {
  return (
    <>
      <PositionPicker />
      <DimensionPicker />
      <RotationPicker />
      <ProgressAnimationActiveColorPicker />
    </>
  );
};
