import {createSRT, downloadSrtFile} from './helper';

import {BASE_SHARE_URL} from 'constants/environment';
import {Button} from 'ui/Button';
import CopyToClipboard from 'react-copy-to-clipboard';
import {InviteTeamForm} from 'features/NewOnboarding/InviteTeamForm';
import {Modal} from 'ui/Modal';
import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import NotificationInfo from 'features/Notifications/NotificationInfo';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {showSuccessNotification} from 'features/Common/utils';
import {store} from 'react-notifications-component';
import {useLanguage} from './LanguageState';
import {useTranscript} from 'features/Captions/Transcripts';
import {useUploadTranscript} from './UploadTranscriptContext';

export const ShareTranscriptModal = ({uploadFile}: {uploadFile: UserUploadsType}) => {
  const {setIsShareModalOpen, isShareModalOpen} = useUploadTranscript();

  const handleLinkCopyWorkspace = () => {};

  const handleCopyConfirmWorkspace = () => {
    store.addNotification({
      ...NOTIFICATION_BASE,
      content: (
        <NotificationInfo
          title={'Copied link'}
          message={'You can now share the recording form link.'}
        />
      ),
    });
  };

  const handleLinkCopyShare = () => {};

  const handleCopyConfirmShare = () => {
    store.addNotification({
      ...NOTIFICATION_BASE,
      content: (
        <NotificationInfo
          title={'Copied link'}
          message={'You can now share the recording form link.'}
        />
      ),
    });
  };

  const pageUrl = window.location.href;

  const shareUrl = `${BASE_SHARE_URL}/a/${uploadFile.id}`;

  return (
    <Modal
      size="large"
      open={isShareModalOpen}
      onClose={() => setIsShareModalOpen(false)}
      title="Share"
    >
      <div className="pt-3">
        <InviteTeamForm
          title="Add people"
          buttonLayout="minimal"
          onComplete={count => {
            setIsShareModalOpen(false);

            if (count === 0) return;

            showSuccessNotification({
              title: 'Success',
              message: `Invitation emails sent to ${count} ${
                count === 1 ? 'user' : 'users'
              }`,
            });
          }}
        />
      </div>
      <div className="h-1"></div>
      <div className="-mx-5 !-mb-5 bg-gray-100 pt-3">
        <div className="p-5 pb-8">
          <div className="space-y-8">
            <div className="flex flex-row items-end justify-between">
              <div className="space-y-2">
                <div className="text-sm font-semibold">Workspace link</div>
                <div className="text-xs">
                  Workspace can <span className="font-semibold">access</span> and anyone
                  else has <span className="font-semibold">no access</span>
                </div>
              </div>
              <div>
                <CopyToClipboard text={pageUrl} onCopy={handleCopyConfirmWorkspace}>
                  <Button
                    _className="!text-xs"
                    variant="tertiary"
                    onClick={handleLinkCopyWorkspace}
                  >
                    Copy workspace link
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
            <div className="flex flex-row items-end justify-between">
              <div className="space-y-2">
                <div className="text-sm font-semibold">Public link</div>
                <div className="text-xs">
                  Anyone with the link can <span className="font-semibold">view</span>
                </div>
              </div>
              <div>
                <CopyToClipboard text={shareUrl} onCopy={handleCopyConfirmShare}>
                  <Button
                    _className="!text-xs"
                    variant="tertiary"
                    onClick={handleLinkCopyShare}
                  >
                    Copy public link
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
