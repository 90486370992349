import {CSSProperties, useContext, useRef} from 'react';

import {DimensionType} from 'features/types/canvasItemsSlice';
import {FrameContext} from 'pages/Canvas/FramePage';
import {updateCanvasItemDimensions} from 'features/canvasItemsSlice';
import {useDispatch} from 'react-redux';
import {useDuration} from '../../../../CanvasTime/useDuration';
import {useOnResize} from 'features/Common/useElementSize';
import {usePlayback} from '../../../../CanvasTime/usePlayback';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useTimeCode} from '../../../../CanvasTime/utils';

export function DynamicProgressBar({
  itemId,
  style,
  options,
}: {
  itemId: string;
  style?: CSSProperties;
  options?: {
    dimension?: DimensionType;
    background: string;
    passiveColor: string;
  };
}) {
  const elRef = useRef<any>(null);
  const dispatch = useDispatch();
  const projectId = useProjectId();
  const onChangeSize = (dimension: any) => {
    dispatch(updateCanvasItemDimensions({projectId, id: itemId, dimension}));
  };
  const {isPlaying, isScrubbing} = usePlayback();

  useOnResize(elRef, size => {
    const height = Math.round(size.height);
    let width = Math.round(size.width);
    if (width === 0) width = height;

    onChangeSize({width: Math.ceil(width), height: Math.ceil(height)});
  });

  const timeCode = useTimeCode(2);
  const durationMs = useDuration();

  const [minutes, seconds] = timeCode.split(':');
  const timeProgressSec = (Number(minutes) * 60 + Number(seconds)) * 1000;
  const percentage = timeProgressSec > 0 ? timeProgressSec / durationMs : 0;

  const frameContext = useContext(FrameContext);

  if (!options || !options?.dimension) {
    return null;
  }

  // console.log('options', options);
  // console.log('options?.passiveColor', options?.passiveColor);

  if (!frameContext && !isPlaying && !isScrubbing && percentage === 0) {
    return (
      <div
        ref={elRef}
        style={{
          ...style,
          width: options.dimension.width,
          height: options.dimension.height,
          background: options?.passiveColor,
        }}
      >
        <div
          style={{
            ...style,
            background: options?.background,
            width: Number(options.dimension.width) * 0.66,
            height: options.dimension.height,
          }}
        ></div>
      </div>
    );
  }

  return (
    <div
      ref={elRef}
      style={{
        ...style,
        width: options.dimension.width,
        height: options.dimension.height,
        background: options?.passiveColor,
      }}
    >
      <div
        style={{
          ...style,
          background: options?.background,
          width: Number(options.dimension.width) * percentage,
          height: options.dimension.height,
        }}
      ></div>
    </div>
  );
}
