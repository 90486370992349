import {NON_RESIZABLE_ITEMS, RATIO_LOCKED_ITEMS} from 'features/canvasItemsSlice';

import {GetRootState} from 'configureStore';
import {NonUndefined} from 'utility-types';
import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {createSelector} from '@reduxjs/toolkit';
import {pick} from 'lodash';
import {selectCanvasProject} from './canvasItemsSelectors';

export const canvasStateSelector = (state: GetRootState) => state.canvasState;

export const selectCanvasStateSelected = (state: GetRootState) =>
  state.canvasState.selected;

export const selectCanvasItemLoadingState = createSelector(
  (state: GetRootState) => canvasStateSelector(state),
  (state: GetRootState, itemId: string) => itemId,
  (canvasState, itemId) => {
    return canvasState.items[itemId]
      ? canvasState.items[itemId]
      : {
          loading: [],
        };
  },
);

export const canvasItemsSelectedItemIdsSelector = (state: GetRootState) =>
  selectCanvasStateSelected(state).selectedItemIds;

export const canvasItemsSelectedItemsSelector =
  (state: GetRootState) => (projectId: string) => {
    const items = selectCanvasProject(state, projectId).items;
    const selectedIds = canvasItemsSelectedItemIdsSelector(state);
    return pick(items, selectedIds);
  };

export const canvasItemsSelectedItemSelector =
  (state: GetRootState) => (projectId: string) => {
    const projectCanvas = selectCanvasProject(state, projectId);
    const {groupActiveItem, selectedItemIds} = canvasStateSelector(state).selected;
    let selectedId: string | undefined;
    if (groupActiveItem && selectedItemIds.includes(groupActiveItem)) {
      selectedId = groupActiveItem;
    } else if (selectedItemIds.length === 1) {
      selectedId = selectedItemIds[0];
    }

    if (!selectedId) return;

    const item = projectCanvas.items[selectedId];
    if (!item) return;

    return {
      item,
      id: selectedId,
    };
  };

export type SelectedItem = NonUndefined<
  ReturnType<ReturnType<typeof canvasItemsSelectedItemSelector>>
>;

export const selectionConstraintsSelector =
  (state: GetRootState) => (projectId: string) => {
    const selectedItems = Object.values(
      canvasItemsSelectedItemsSelector(state)(projectId),
    );
    const selectedItemTypes = selectedItems.map(item => item.viewType);

    const keepRatio = selectedItemTypes.some(type => RATIO_LOCKED_ITEMS.includes(type));

    let resizable = !selectedItemTypes.some(type => NON_RESIZABLE_ITEMS.includes(type));
    let resizeHandles;
    if (selectedItems.length === 1 && selectedItems[0].viewType === ViewTypes.Text) {
      resizable = true;
      resizeHandles = ['w', 'e'];
    }

    return {resizable, keepRatio, resizeHandles};
  };
