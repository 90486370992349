import {ClipListFilterModal} from './ClipListFilterModal';
import {useClipList} from './ClipListContext';

export function ClipListFilter() {
  const {
    state: {openListFilterModal},
    dispatch,
  } = useClipList();

  const setOpenListFilterModal = (newOpenListFilterModal: boolean) => {
    dispatch({
      type: 'setOpenListFilterModal',
      payload: {
        openListFilterModal: newOpenListFilterModal,
      },
    });
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setOpenListFilterModal(true);
        }}
        className="focus:outline-none inline-flex items-center whitespace-nowrap rounded border border-dashed border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-400 shadow-sm hover:bg-gray-50 hover:text-gray-600 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        + Filter
      </button>

      <ClipListFilterModal open={openListFilterModal} setOpen={setOpenListFilterModal} />
    </>
  );
}
