import {CanvasItem, ItemSource} from 'features/types/canvasItemsSlice';
import {getItemSource, updateItemSource} from 'features/canvasItemsSlice';
import {useEffect, useRef} from 'react';

import {Time} from 'features/Common/Time';
import {getSourceData} from 'features/Dashboard/useCreateProject';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useDispatch} from 'react-redux';
import {useProjectId} from './useProjectId';

export const useItemSource = ({item, itemId}: {item: CanvasItem; itemId: string}) => {
  const {getAccessTokenSilently} = useAuthAlt();
  const dispatch = useDispatch();
  const projectId = useProjectId();

  const source = getItemSource(item);
  const sourceId = useRef(item.source?.id);

  useEffect(() => {
    if (!source) return;
    if (sourceId.current && source?.id === sourceId.current) return;

    getAccessTokenSilently()
      .then(token => {
        return getSourceData(token, source);
      })
      .then(({clip, userUpload}) => {
        let playbackId: string;
        let timing: ItemSource['timing'];

        if (clip) {
          playbackId = clip.mux_playback_id ?? userUpload.mux_playback_id;

          const clipStartTime = new Time(parseFloat(clip.start_time), 's');
          const clipEndTime = new Time(parseFloat(clip.end_time), 's');

          timing = {
            offset: clipStartTime,
            duration: clipEndTime.sub(clipStartTime),
          };
        } else {
          playbackId = userUpload.mux_playback_id;

          timing = {
            offset: new Time(0),
            duration: new Time(userUpload.duration ?? 0, 's'),
          };
        }

        dispatch(
          updateItemSource({
            projectId,
            itemId,
            source: {...source, playbackId, timing},
          }),
        );
      })
      .catch(error => {
        console.error('Failed to fetch sourceClipPlaybackId');
        console.error(error);
      })
      .finally(() => {
        sourceId.current = source.id;
      });
  }, [dispatch, getAccessTokenSilently, itemId, projectId, source]);

  return item.source;
};
