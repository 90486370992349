import {
  CalculatedScene,
  useScenes,
} from 'features/EditorCanvas/components/CanvasTime/useScenes';
import { CanvasItemSource, getSourceData } from 'features/Dashboard/useCreateProject';
import {
  ClipUploadMedia,
  MuxPlaybackIdType,
  TimeSeconds,
} from 'features/types/userLibrarySlice';
import { FrameContext, useOverrideDurations } from 'pages/Canvas/FramePage';
import { ItemLayerSources, ViewTypes } from 'features/EditorCanvas/constants/ViewConstants';
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { ControlledVideo } from '../../CanvasTime/ControlledVideo';
import { ScreenshotContext } from 'pages/Canvas/ScreenshotPage';
import { ScreenshotFrame } from './ScreenshotFrame';
import { Time } from 'features/Common/Time';
import { clamp } from 'lodash';
import { getAuthToken } from 'services/utils';
import { setDependencyReady } from 'features/canvasStateSlice';
import { useDispatch } from 'react-redux';

type ItemLayerVideoClipProps = {
  isMuted: boolean;
  itemId: string;
  itemSourceType: ItemLayerSources;
  itemSourceId: string;
  playbackId: MuxPlaybackIdType;
  playLengthSeconds: TimeSeconds;
  projectId: string;
  sceneId: string;
  timeOffsetSeconds: TimeSeconds;
};

export const calculateVideoLayerTime = () => { };

export function ItemLayerVideoClip({
  isMuted,
  itemId,
  itemSourceType,
  itemSourceId,
  playbackId,
  playLengthSeconds,
  projectId,
  sceneId,
  timeOffsetSeconds,
}: ItemLayerVideoClipProps) {
  const dispatch = useDispatch();
  const { scenes } = useScenes();
  const scene = scenes.find(scene => scene.sceneId === sceneId);

  /**
   * When loading screenshot, if source asset is audio, mark as "ready".
   */
  useEffect(() => {
    if (!sceneId || !playbackId) {
      console.log('No scene or playbackId');
      return;
    }

    (async () => {
      const token = await getAuthToken();
      const { userUpload: userUploadSource, clip: clipSource } = await getSourceData(
        token,
        {
          type: itemSourceType,
          id: itemSourceId,
        } as CanvasItemSource,
      );
      if (
        clipSource?.upload_media_type === ClipUploadMedia.Audio ||
        userUploadSource?.file_type.includes(ClipUploadMedia.Audio)
      ) {
        dispatch(
          setDependencyReady({
            itemId,
            dependency: 'media',
            ready: true,
          }),
        );
      }
    })();
  }, [itemSourceType, itemSourceId]);

  const sceneTimingLeaderId = useMemo(() => {
    if (!scene?.items) return;

    const items = Object.entries(scene.items)
      .sort((a, b) => a[1].playLengthSeconds - b[1].playLengthSeconds)
      .reverse();

    let id = items.find(([id, item]) => item.viewType === ViewTypes.VideoClip)?.[0];
    if (!id) id = items.find(([id, item]) => item.viewType === ViewTypes.Video)?.[0];
    if (!id) id = items.find(([id, item]) => item.viewType === ViewTypes.AudioClip)?.[0];

    return id;
  }, [scene?.items]);

  const duration = new Time(playLengthSeconds, 's');
  const startMs = timeOffsetSeconds * 1000;
  const endMs = startMs + duration.ms;

  const frameContext = useContext(FrameContext);

  const overrideDurations = useOverrideDurations();
  const overrideDuration = overrideDurations[itemId];

  const screenshotContext = useContext(ScreenshotContext);

  const item = useMemo(() => {
    return scene?.items?.[itemId];
  }, [itemId, scene]);

  if (!playbackId || !sceneId) {
    return <div />;
  }

  if (frameContext) {
    if (overrideDuration === null) return null;

    console.log('Successfully rendering video frame');

    return (
      <div style={{ ...item?.style }} className="overflow-hidden">
        <VideoFrame
          scene={scene}
          storedDuration={duration}
          {...frameContext}
          itemId={itemId}
        />
      </div>
    );
  }

  if (screenshotContext.showScreenshot && scene) {
    return (
      <div style={{ ...item?.style }} className="overflow-hidden">
        <ScreenshotFrame playbackId={playbackId} itemId={itemId} scene={scene} />;
      </div>
    );
  }

  return (
    <div style={{ ...item?.style }} className="overflow-hidden">
      <ControlledVideo
        playbackId={playbackId}
        durationMs={duration.ms}
        playRange={{ startMs, endMs }}
        isMuted={isMuted}
        timeOffset={scene?.startTimeMs ?? 0}
        onReady={() => {
          dispatch(
            setDependencyReady({
              itemId,
              dependency: 'media',
              ready: true,
            }),
          );
        }}
        timingRole={itemId === sceneTimingLeaderId ? 'leader' : 'follower'}
      />
    </div>
  );
}

const VideoFrame = ({
  scene,
  storedDuration,
  frame,
  fps,
  itemId,
  markItemLoaded,
}: {
  scene: CalculatedScene | undefined;
  storedDuration: Time;
  frame: number;
  fps: number;
  itemId: string;
  markItemLoaded: (forFrame: number) => void;
}) => {
  const overrideDurations = useOverrideDurations();
  const overrideDuration = overrideDurations[itemId];
  const duration = new Time(overrideDuration?.duration ?? storedDuration);

  const sceneStartTime = new Time(scene?.startTimeMs ?? 0, 'ms');
  const startFrame = Math.floor((sceneStartTime.ms / 1000) * fps);
  const frameCount = Math.floor((duration.ms / 1000) * fps);

  const sourceFrame = clamp(frame - startFrame, 1, frameCount);

  const [sourceFramesUrl] = useQueryParam('sourceFramesUrl', StringParam);

  const imageUrl = sourceFramesUrl
    ?.replace('%itemId', itemId)
    .replace('%d', sourceFrame.toString());

  // const imageUrl = `http://localhost:5001/${sourceFrame.toString()}.jpg`;

  console.log('sourceFrame imageUrl', imageUrl);

  useLayoutEffect(() => {
    if (!imageUrl) return;

    const image = new Image();
    image.src = imageUrl;

    let rAF: number | undefined;
    image.onload = () => {
      console.log('onLoad', imageUrl);
      rAF = requestAnimationFrame(() => {
        rAF = requestAnimationFrame(() => {
          markItemLoaded(frame);
        });
      });
    };

    image.onerror = error => {
      console.error(`Source image failed to load: ${imageUrl}`, error);
    };

    return () => {
      if (rAF) cancelAnimationFrame(rAF);
    };
  }, [imageUrl, markItemLoaded, frame]);

  const [debug] = useQueryParam('debug', StringParam);

  return (
    <ImagePlaceholder src={imageUrl}>
      {debug === 'true' && (
        <div className="absolute inset-x-0 bottom-0 border-t-2 border-red-500 text-2xl text-black">
          imageUrl: {imageUrl}
        </div>
      )}
    </ImagePlaceholder>
  );
};

export const ImagePlaceholder = ({
  src,
  children,
  onLoad,
}: PropsWithChildren<{ src: string | undefined; onLoad?: () => void }>) => {
  return (
    <div className="absolute inset-0">
      <img src={src} alt="" className="block w-full" onLoad={onLoad} />
      {children}
    </div>
  );
};
