import LoadingPage from 'features/EditorCanvas/components/shared/LoadingPage';

export const FixedLoadingPage = () => {
  return (
    <div
      className="fixed inset-0 flex flex-col items-center justify-center bg-gray-100"
      style={{zIndex: 10001}}
    >
      <LoadingPage title="Loading..." explainer="Please wait" />
    </div>
  );
};
