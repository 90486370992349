import {DashParams, routePaths} from 'routes/routesHelper';
import {FC, useState} from 'react';

import {TemplateExportOptionsType as TemplateExportOptions} from './TemplateSelectionPopup';
import TemplateLayoutPicker from './pickers/TemplateLayoutPicker';
import TemplateOrientationPicker from './pickers/TemplateOrientationPicker';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import classNames from 'classnames';
import {requestExportWithOptionsForClip} from 'features/userProjectDownloadSlice';
import {showErrorNotification} from 'features/Common/utils';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useDispatch} from 'react-redux';
import {useRecentUsage} from 'services/recentUsageAPI';

type TemplateConfirmExportProps = {
  activeClip: UploadMediaClipType;
  exportOptions: TemplateExportOptions;
  updateExportOptions: (options: Partial<TemplateExportOptions>) => void;
  handleStartLoading: () => void;
  syncAndExportProject: (projectId: string) => Promise<void>;
};

const TemplateConfirmExport = ({
  activeClip,
  exportOptions,
  handleStartLoading,
  updateExportOptions,
  syncAndExportProject,
}: TemplateConfirmExportProps) => {
  const dispatch = useDispatch();
  const {getAccessTokenSilently, user} = useAuthAlt();
  const {recentUsage} = useRecentUsage();

  const openCanvas = (projectId: string) => {
    window.location.href = `/${routePaths.create}/${DashParams.CanvasParam}/${projectId}`;
    handleStartLoading();
  };

  const createProject = async () => {
    const token = await getAccessTokenSilently();

    return new Promise<string>((resolve, reject) => {
      dispatch(
        requestExportWithOptionsForClip({
          token,
          email: user?.email as string,
          activeClip,
          exportOptions,
          style: recentUsage,
          callback: result => {
            if ('error' in result) reject(result.error);
            else resolve(result.projectId);
          },
        }),
      );
    });
  };

  return (
    <div className="w-full lg:self-center">
      <h2 className="text-3xl font-extrabold text-green-400 sm:text-4xl">
        <span className="block text-gray-900">Setup export</span>
      </h2>
      <p className="mt-4 mb-4 text-sm text-orange-50">
        Pick a size and layout for your project. If you want to customize it more, you can
        click Customize more and open up the canvas editor.
      </p>
      <div className="mb-4 inline-flex flex-col">
        <label className="inline-flex pb-2 text-sm font-semibold">Orientation</label>
        <div className="mb-4 inline-flex w-auto rounded-md border border-gray-200 p-2">
          <TemplateOrientationPicker
            activeOrientation={exportOptions.orientation}
            setActiveOrientation={orientation => updateExportOptions({orientation})}
          />
        </div>
      </div>
      <div className="mb-4 inline-flex flex-col">
        <label className="inline-flex pb-2 text-sm font-semibold">Layout</label>
        <div className="mb-4 inline-flex w-auto rounded-md border border-gray-200 p-2">
          <TemplateLayoutPicker
            activeLayout={exportOptions.layout}
            setActiveLayout={layout => updateExportOptions({layout})}
          />
        </div>
      </div>
      <div className="flex flex-row space-x-3">
        <AsyncButton
          onClick={async () => {
            try {
              const projectId = await createProject();
              if (projectId) await syncAndExportProject(projectId);
            } catch (error) {
              showErrorNotification({
                title: 'Error downloading clip',
                // @ts-ignore
                message: error.message,
                // @ts-ignore
                error,
              });
            }
          }}
          className="bg-indigo-600 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Download
        </AsyncButton>
        <AsyncButton
          onClick={async () => {
            try {
              const projectId = await createProject();
              if (projectId) openCanvas(projectId);
            } catch (error) {
              showErrorNotification({
                title: 'Error creating project',
                // @ts-ignore
                message: error.message,
                // @ts-ignore
                error,
              });
            }
          }}
          className="text-gray-900 hover:bg-gray-100 focus:outline-none"
        >
          Customize more
        </AsyncButton>
      </div>
    </div>
  );
};

const AsyncButton: FC<{onClick: () => Promise<unknown>; className?: string}> = ({
  onClick,
  children,
  className,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <button
      onClick={async () => {
        if (loading) return;

        setLoading(true);

        onClick()
          .catch(console.error)
          .finally(() => setLoading(false));
      }}
      className={classNames(
        'mt-6 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-base font-medium transition-opacity',
        className,
        loading && 'pointer-events-none opacity-50',
      )}
    >
      {loading ? 'Please wait...' : children}
    </button>
  );
};

export default TemplateConfirmExport;
