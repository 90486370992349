export default function ActiveUserNav({projectId}) {
  return (
    <>
      <div className="relative ml-4 inline-block flex items-center text-left">
        <div>
          <div className="flex items-center" />
        </div>
      </div>
    </>
  );
}
