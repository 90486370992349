import { CanvasItem, DimensionType, ProjectCanvas } from 'features/types/canvasItemsSlice';
import { FC, useEffect, useState } from 'react';
import {
  ProjectTimeProvider,
  useTimeProviderPresent,
} from 'features/EditorCanvas/components/CanvasTime/TimeProvider';
import {
  clearProjectState,
  setupBaseProject,
} from 'features/canvasItemsSlice';
import { setPreview, updateSceneFrame } from 'features/sceneFrameSlice';
import { useDispatch, useSelector } from 'react-redux';

import { CanvasWrapper } from 'features/EditorCanvas/components/AppCanvas/Canvas/CanvasWrapper';
import { GetRootState } from 'configureStore';
import { InverseCropProvider } from 'features/EditorCanvas/components/CanvasItem/InverseCrop';
import { LoadGoogleFonts } from 'features/Fonts/LoadGoogleFonts';
import { ProjectIdProvider } from 'features/EditorCanvas/useProjectId';
import { ScalePreview } from 'features/FocusedFlow/LayoutUtils';
import { ScenesProvider } from 'features/EditorCanvas/components/CanvasTime/useScenes';
import { SelectionFocusProvider } from 'features/EditorCanvas/components/SelectionFocusProvider';
import { canvasStateSelector } from 'features/selectors/canvasStateSelectors';
import equal from 'fast-deep-equal';
import { sceneFrameSelector } from 'features/selectors/sceneFrameSelectors';
import { selectCanvasItemsProjectsCanvas } from 'features/selectors/canvasItemsSelectors';
import { setProjectLoading } from 'features/canvasStateSlice';
import { v4 } from 'uuid';

export const InlineCanvas = ({
  items,
  backgroundColor = '#fff',
  canvasDimensions,
  height,
}: {
  items: Record<string, CanvasItem>;
  backgroundColor?: string;
  canvasDimensions: DimensionType;
  height?: number;
}) => {
  // console.log('items', items);
  const dispatch = useDispatch();
  const [projectId] = useState(() => v4());

  const canvasState = useSelector(canvasStateSelector)

  const project: ProjectCanvas | undefined = useSelector(
    (state: GetRootState) => selectCanvasItemsProjectsCanvas(state)[projectId],
  );

  useEffect(() => {
    if (project) return;

    dispatch(setupBaseProject(projectId));
  }, [projectId, project, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearProjectState({ projectId }));
    };
  }, [dispatch, projectId]);

  const sceneFrame = useSelector(sceneFrameSelector)(projectId);

  useEffect(() => {
    dispatch(
      updateSceneFrame({
        projectId,
        sceneFrame: {
          backgroundColor: {
            hex: backgroundColor,
          },
          canvasDimensions: {
            width: canvasDimensions.width,
            height: canvasDimensions.height,
          },
        },
      }),
    );
  }, [
    backgroundColor,
    dispatch,
    projectId,
    canvasDimensions.width,
    canvasDimensions.height,
  ]);

  useEffect(() => {
    if (sceneFrame.preview?.items && equal(items, sceneFrame.preview.items)) return;

    dispatch(setPreview({ projectId, items }));
    dispatch(setProjectLoading({ projectId, loading: false }));
  }, [dispatch, projectId, items, sceneFrame.preview]);

  if (!project) return null;
  if (canvasState.isLoading) return <h1>Loading</h1>;

  return (
    <SelectionFocusProvider>
      <ProjectIdProvider projectId={projectId}>
        <InverseCropProvider>
          <MaybeTimeProvider>
            <ScenesProvider>
              <ScalePreview dimensions={canvasDimensions}>
                <CanvasWrapper projectId={projectId} />
              </ScalePreview>
              <LoadGoogleFonts />
            </ScenesProvider>
          </MaybeTimeProvider>
        </InverseCropProvider>
      </ProjectIdProvider>
    </SelectionFocusProvider>
  );
};

export const MaybeTimeProvider: FC = ({ children }) => {
  const alreadyPresent = useTimeProviderPresent();
  if (alreadyPresent) return <>{children}</>;

  return <ProjectTimeProvider endBehavior="noop">{children}</ProjectTimeProvider>;
};
