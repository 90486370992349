import {
  AccountWrapperAccountUsersType,
  AccountWrapperUserType,
  UserType,
} from 'features/types/authSlice';
import {authAccountUsersDetailsSelector} from 'features/selectors/authSelectors';
import {Card} from 'features/Common/Card';
import {CardHeader} from 'features/Common/CardHeader';
import {Table} from 'features/Common/Table';
import {useSelector} from 'react-redux';
import {ChangeEvent, useState} from 'react';
import ConfirmDeleteTeamMemberDialog from './components/ConfirmDeleteTeamMemberDialog';
import {X} from 'react-feather';
import ConfirmChangeRoleDialog from './components/ConfirmChangeRoleDialog';

type AccountWrapperAccountRowProp = {
  user: UserType;
  account_user: AccountWrapperAccountUsersType;
};

export function SettingsTeamCard() {
  const {accountUsers, users, user} = useSelector(authAccountUsersDetailsSelector);
  const isAdmin =
    accountUsers?.find(accountUser => accountUser.user_id === user?.id)?.roles.admin ??
    false;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<{
    accountUserId: string;
    userId: string;
    email: string;
  }>({
    accountUserId: '',
    userId: '',
    email: '',
  });

  const handleOpenDeleteDialog = (
    accountUserId: string,
    userId: string,
    email: string,
  ) => {
    setIsDeleteDialogOpen(true);
    setUserToDelete({
      accountUserId,
      userId,
      email,
    });
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const [isChangeRoleDialogOpen, setIsChangeRoleDialogOpen] = useState(false);
  const [roleChange, setRoleChange] = useState<{
    accountUserId: string;
    email: string;
    newRole: string;
  }>({accountUserId: '', email: '', newRole: ''});

  const handleOpenChangeRoleDialog = (
    accountUserId: string,
    email: string,
    newRole: string,
  ) => {
    setIsChangeRoleDialogOpen(true);
    setRoleChange({accountUserId, email, newRole});
  };

  const handleCloseChangeRoleDialog = () => {
    setIsChangeRoleDialogOpen(false);
  };

  if (!users || !accountUsers || !user) {
    return null;
  }

  return (
    <Card>
      <CardHeader subtitle="" title="Team Members" />
      <ConfirmDeleteTeamMemberDialog
        isDialogOpen={isDeleteDialogOpen}
        closeDialog={handleCloseDeleteDialog}
        userToDelete={userToDelete}
      />
      <ConfirmChangeRoleDialog
        isDialogOpen={isChangeRoleDialogOpen}
        closeDialog={handleCloseChangeRoleDialog}
        roleChange={roleChange}
      />
      <div className="">
        <div className="flex flex-col">
          <div className="">
            <div className="inline-block min-w-full align-middle">
              <Table
                items={users.map((user: AccountWrapperUserType) => {
                  return {
                    user,
                    account_user: accountUsers.find(
                      accountUser => accountUser.user_id === user.id,
                    ),
                  };
                })}
                renderRow={(item: AccountWrapperAccountRowProp) => {
                  return (
                    <>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        {item.user.first_name
                          ? `${item.user.first_name} ${item.user.last_name}`
                          : item.user.email}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        {!isAdmin || item.user.id === user.id ? (
                          item.account_user.roles && item.account_user.roles.admin ? (
                            'Admin'
                          ) : (
                            'User'
                          )
                        ) : (
                          <select
                            value={
                              item.account_user.roles && item.account_user.roles.admin
                                ? 'Admin'
                                : 'User'
                            }
                            onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                              handleOpenChangeRoleDialog(
                                item.account_user.id,
                                item.user.email,
                                evt.target.value,
                              );
                            }}
                            className="focus:outline-none -my-2 block rounded-md border-gray-300 py-1 pl-3 text-sm text-gray-500 focus:border-indigo-500 focus:ring-indigo-500"
                          >
                            <option value={'Admin'}>Admin</option>
                            <option value={'User'}>User</option>
                          </select>
                        )}
                      </td>
                      <td className="inline-flex w-full flex-row justify-end whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                        {item.user.id !== user.id && (
                          <button
                            onClick={() =>
                              handleOpenDeleteDialog(
                                item.account_user.id,
                                item.account_user.user_id,
                                item.user.email,
                              )
                            }
                            className="focus:outline-none flex text-sm leading-5 text-red-600 hover:text-red-700 focus:text-red-600"
                            role="menuitem"
                          >
                            <X size={20} />
                            <span className="ml-2">Remove</span>
                          </button>
                        )}
                      </td>
                    </>
                  );
                }}
                headers={['User', 'Role', '']}
                noItemsMessage="There are no team members."
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
