import {THUMBNAIL_CONTAINER_HEIGHT, THUMBNAIL_CONTAINER_Y_PADDING} from './constants';

import classNames from 'classnames';
import {usePlayback} from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import {usePlaybackPercentage} from 'features/EditorCanvas/components/CanvasTime/utils';

export default function TimelineCurrentTimeMarker() {
  const percentage = usePlaybackPercentage();
  const {isPlaying} = usePlayback();

  return (
    <div
      className={classNames(
        'relative z-[70] cursor-horizontal select-none bg-indigo-600 shadow-md',
        !isPlaying && 'transition-all',
      )}
      style={{
        zIndex: 70,
        width: '2px',
        height: `${THUMBNAIL_CONTAINER_HEIGHT}px`,
        display: 'block',
        left: `${percentage}%`,
        top: `${THUMBNAIL_CONTAINER_Y_PADDING / 4 - 4}px`,
      }}
    >
      <div
        className="absolute bg-indigo-600 "
        style={{
          width: '12px',
          height: '7px',
          top: '-1px',
          left: '-5px',
        }}
      />
      <div
        className="absolute border-t-4 border-indigo-600"
        style={{
          left: '-5px',
          top: '6px',
          width: 0,
          height: 0,
          borderLeft: '6px solid transparent',
          borderRight: '6px solid transparent',
        }}
      />
    </div>
  );
}
