export default function LoadingText({lineCount = 10}) {
  const lineCountArr = Array.from(Array(lineCount).keys());
  return (
    <div className="mx-auto w-full p-4" role="alert" aria-live="polite" aria-busy="true">
      <div className="flex animate-pulse">
        <div className="flex-1 space-y-4 py-1">
          {lineCountArr.map((row, index) => (
            <div
              key={`loading-row-${index}`}
              className="h-3 w-full rounded bg-gray-200"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
