import {ApiRecordingSession} from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import Loader from 'vendor/dailyco/shared/components/Loader/Loader';
import {RecordingCamera} from './RecordingCamera';
import {useMemo} from 'react';
import {useRecordingFormQuery} from 'services/recordingFormsAPI';

export function RecordingSummary() {
  const {recordingInteractionFormId} = ApiRecordingSession.useContainer();
  const {
    data: recordingInteractionForm,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  } = useRecordingFormQuery({id: recordingInteractionFormId!});

  let hasPersonalMessage,
    avatarUrl,
    messageAuthor,
    messageBody = null;

  if (isSuccess && recordingInteractionForm?.metadata) {
    const {has_personal_message, avatar_url, message_author, message_body} =
      recordingInteractionForm.metadata;
    hasPersonalMessage = has_personal_message;
    avatarUrl = avatar_url;
    messageAuthor = message_author;
    messageBody = message_body;
  }

  const formQuestion = useMemo(() => {
    if (
      !recordingInteractionForm?.recording_form_prompts ||
      !recordingInteractionForm?.recording_form_prompts[0] ||
      !recordingInteractionForm?.recording_form_prompts[0].description
    ) {
      return '';
    }
    return recordingInteractionForm?.recording_form_prompts[0].description;
  }, [recordingInteractionForm]);

  if (isLoading || isFetching) {
    return <LoadingVideo />;
  }

  const testQuestion = {
    prompt: 'Will you tell me about your time such',
  };

  return <RecordingCamera />;
}

type RecordingQuestion = {
  prompt: string;
};

const LoadingVideo = () => {
  return (
    <div className="flex w-full items-center justify-center">
      <Loader />
    </div>
  );
};
