import {BASE_URL, STATIC_TOKEN_OVERRIDE, isRecording} from 'constants/environment';
import {Middleware, isRejectedWithValue} from '@reduxjs/toolkit';
import axios, {AxiosError, AxiosRequestConfig} from 'axios';

import {ActionCableConsumer} from 'cableSubscriptions/ActionCableConsumer';
import {BaseQueryFn} from '@reduxjs/toolkit/dist/query';
import {CableBody} from 'cableSubscriptions/types';
import Cookies from 'universal-cookie';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {MaybeDrafted} from '@reduxjs/toolkit/dist/query/core/buildThunks';
import {QueryCacheLifecycleApi} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {requestHeader} from 'api/helpers';
import {showErrorNotification} from 'features/Common/utils';
import {useStytch} from '@stytch/stytch-react';

/**
 * Catch the recurring errors resulting from auth queries
 *
 * @param data
 */
export const reportAuthQueryError = async (data: any) => {
  if (import.meta.env.DEV) return;
  const user = window.stytch.user.getSync();
  const email = user?.emails[0].email;
  try {
    if (email) {
      window.analytics.track('User auth query error', {
        error: data.title,
      });
    }
  } catch (e) {
    //
  }

  const token = await getAuthToken();
  const reportUrl = `${BASE_URL}/api/v2/auths/report-error`;
  try {
    await axios.post(reportUrl, {...data, ...user}, requestHeader(token));
  } catch (error) {
    console.error('error reporting', error);
  }
};

const cookies = new Cookies();

export const getAuthToken = async () => {
  if (isRecording()) return STATIC_TOKEN_OVERRIDE;

  try {
    const token = await Promise.resolve(cookies.get('stytch_session_jwt'));
    return token as string;
  } catch (error) {
    const anonymousToken = cookies.get('anonymous-token');
    if (anonymousToken) {
      return `Anonymous ${anonymousToken}`;
    }

    throw error;
  }
};

const fpjsPromise = FingerprintJS.load({
  // @ts-ignore
  monitoring: false,
});

export const getVisitorId = async () => {
  try {
    const fpjsAgent = await fpjsPromise;
    const fingerprint = await fpjsAgent.get();

    return fingerprint.visitorId;
  } catch (error) {
    return 'unknown';
  }
};

type QueryParams = {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  errorTitle: string | false;
  v2?: boolean;
  auth?: boolean;
};

export const wrappedAxios = async ({
  url,
  method,
  data,
  errorTitle,
  v2 = false,
  auth = true,
}: QueryParams) => {
  let token: string | undefined;

  const session = window.stytch.session.getSync();
  const user = window.stytch.user.getSync();

  if (auth) token = await getAuthToken();

  if (auth && (!session || !user)) {
    return;
  }

  try {
    const result = await axios({
      url: `${BASE_URL}/api/${v2 ? 'v2' : 'v1'}/` + url,
      method,
      data,
      headers: {
        ...(token && requestHeader(token).headers),
      },
    });

    if (result.data.status === 'error') {
      reportAuthQueryError({title: errorTitle, message: result.data.message});
      return {
        error: {title: errorTitle, message: result.data.message},
      };
    }

    return {data: result.data};
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    reportAuthQueryError({
      status: err.response?.status,
      title: errorTitle,
      data: err.response?.data,
    });
    return {
      error: {status: err.response?.status, title: errorTitle, data: err.response?.data},
    };
  }
};

/**
 * The base query for all authenticated queries to `milk-api`.
 *
 * It will automatically get an access token for the request.
 */
export const authenticatedQuery: BaseQueryFn<QueryParams> = async params => {
  return wrappedAxios(params);
};

/**
 * Middleware to display an error message if a request fails.
 *
 * Pass `errorTitle` as a string to the query object to customise the title.
 */
export const rtkQueryErrorMessage: Middleware = api => next => action => {
  if (isRejectedWithValue(action)) {
    if (!action.payload) return;

    const {title, message} = action.payload;
    if (title === false) return;

    showErrorNotification({
      title: title || 'Unknown error',
      message: message || 'Please try again',
    });
  }

  return next(action);
};

/**
 * Use this to subscribe to actioncable events
 * and update the RTK Query cache when an
 * event is received
 */
export async function subscribeToCable<
  QueryArg,
  BaseQuery extends BaseQueryFn,
  ResultType,
  Action extends CableBody['action'],
>(
  options: {
    api: QueryCacheLifecycleApi<QueryArg, BaseQuery, ResultType>;
    action: Action;
  },
  callback: (
    payload: Extract<CableBody, {action: Action}>['payload'],
    draft: MaybeDrafted<ResultType>,
  ) => void,
) {
  const {api, action} = options;
  const {cacheDataLoaded, cacheEntryRemoved} = api;

  const user = window.stytch.user.getSync();
  const email = user?.emails[0].email;
  if (!user || !email) return;

  await cacheDataLoaded;

  const subscription = ActionCableConsumer.subscriptions.create(
    {channel: 'UserUpdateChannel', email: email},
    {
      received: ({body}: {body: CableBody}) => {
        // console.log('subscription body', body);
        if (body.action === action) {
          api.updateCachedData(draft => {
            callback(body.payload as any, draft);
          });
        }
      },
    },
  );

  await cacheEntryRemoved;

  subscription.unsubscribe();
}

axios.interceptors.request.use(async function (config) {
  const fingerprint = await getVisitorId();
  config.headers['X-Fingerprint'] = fingerprint;

  return config;
});
