import * as Sentry from '@sentry/react';

import { ReactElement, useEffect } from 'react';

import Cohere from 'cohere-js';
import { env } from 'constants/environment';
import { fetchFeatureFlagsSuccessful } from 'features/featureFlagSlice';
import { identify } from 'features/Common/tracking';
import { updateUserNotLoggedIn } from 'features/authSlice';
import { useAuthAlt } from 'features/Auth/useAuthAlt';
import { useDispatch } from 'react-redux';
import { useFetchAccountFeatureFlagsQuery } from 'services/accountFeatureFlagsAPI';

/**
 * IdentifyAuthenticationWrap
 *
 * Use tracking and telemetry based on authentication provider data.
 */

export const IdentifyAuthenticationWrap = ({ children }: { children: ReactElement }) => {
  const { user, isAuthenticated, isLoading } = useAuthAlt();
  const dispatch = useDispatch();

  const {
    data: featureFlags,
    isLoading: isFlagsLoading,
    isSuccess: isFlagsSuccess,
  } = useFetchAccountFeatureFlagsQuery(undefined, { skip: !isAuthenticated })

  useEffect(() => {
    if (isFlagsSuccess) {
      dispatch(
        fetchFeatureFlagsSuccessful({
          flags: featureFlags.flags,
        }),
      );
    }
  }, [featureFlags, isFlagsSuccess])

  const email = !!user?.emails && user.emails.length > 0 ? user.emails[0].email : null;

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      dispatch(updateUserNotLoggedIn());
    }
  }, [isLoading, isAuthenticated, dispatch]);

  useEffect(() => {
    if (isAuthenticated && user) {
      const uniqueId = user.emails[0].email;

      if (uniqueId) {
        Sentry.setUser({ email: uniqueId });

        Cohere.getSessionUrl((sessionUrl: string) => {
          Sentry.configureScope(scope => {
            scope.setExtra('Cohere session URL', sessionUrl);
          });
        });

        const name = user.name.first_name
          ? `${user.name.first_name} ${user.name.last_name}`
          : uniqueId;

        if (env === 'production') {
          if (window.location.origin === 'https://app.milkvideo.com') {
            Cohere.identify(
              uniqueId, // Required: can be any unique ID
              {
                displayName: name, // Optional
                email: uniqueId,
              },
            );
          }

          identify(email!, {
            name: name,
            email: uniqueId,
            traits: {
              clientOrigin: window.todesktop ? 'desktop-app' : 'web',
            },
          });
        }
      }
    }
  }, [email, isAuthenticated, user]);

  return children;
};
