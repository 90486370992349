import {
  UserLibraryUserProjectType,
  UserUploadsType,
} from 'features/types/userLibrarySlice';

import {GetRootState} from 'configureStore';
import {UserUploadFolder} from 'services/userUploadFolderAPI';

export const userLibrarySelector = (state: GetRootState) => state.userLibrary;

export const userLibraryUploadFolderSelector =
  (state: GetRootState) => (folderId: string) =>
    state.userLibrary.userUploadFolders.find(
      (folder: UserUploadFolder) => folder.id === folderId,
    );

export const userLibraryShareProjectSelector =
  (state: GetRootState) => (projectId: string) =>
    state.userLibrary.shareProjects[projectId];

export const userLibraryUserProjectSelector =
  (state: GetRootState) => (projectId: string) =>
    state.userLibrary.userProjects.find(
      (project: UserLibraryUserProjectType) => project.slug === projectId,
    );

export const analyticsSelector = (state: GetRootState) => (bucketKey: string) => {
  return state.userLibrary.fileUploadsAnalytics[bucketKey] || {};
};

export const glossarySelector = (state: GetRootState) => () => {
  return state.userLibrary.glossary || {terms: []};
};
