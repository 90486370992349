import {AppDispatch} from 'configureStore';
import accountsCable from 'cableSubscriptions/accountsCable';
import projectsCable from 'cableSubscriptions/projectsCable';
import uploadMediaClipsCable from 'cableSubscriptions/uploadMediaClipsCable';
import userProjectDownloadsCable from 'cableSubscriptions/userProjectDownloadsCable';
import userUploadsCable from 'cableSubscriptions/userUploadsCable';

type CableSubscriptionMethodsType = {
  received: any;
};

type ActionCableMessageBodyType = {
  model: string;
  action: string;
  payload: any;
};

export type ActionCableMessageType = {
  body: ActionCableMessageBodyType;
};

export enum CableSubscriptionModel {
  AccountFontUploads = 'account_font_uploads',
  Accounts = 'accounts',
  AssemblyTranscriptStatus = 'assembly_transcript_status',
  AuthCheck = 'auth_check',
  Projects = 'projects',
  UploadMediaClips = 'upload_media_clips',
  UserProjectDownloads = 'user_project_downloads',
  UserUploads = 'user_uploads',
}

const cableSubscriptions = (
  dispatch: AppDispatch,
  getAccessTokenSilently: any,
  user: any,
): CableSubscriptionMethodsType => {
  return {
    received: (message: ActionCableMessageType) => {
      if (!message || !message.body) {
        return {status: 'fail'};
      }

      switch (message.body.model) {
        case CableSubscriptionModel.AuthCheck:
          console.log('confirmed');
          break;
        case CableSubscriptionModel.Projects:
          projectsCable(dispatch, getAccessTokenSilently, message, user);
          break;
        case CableSubscriptionModel.Accounts:
          accountsCable(dispatch, getAccessTokenSilently, message, user);
          break;
        case CableSubscriptionModel.UserProjectDownloads:
          userProjectDownloadsCable(dispatch, getAccessTokenSilently, message, user);
          break;
        case CableSubscriptionModel.UploadMediaClips:
          uploadMediaClipsCable(dispatch, getAccessTokenSilently, message, user);
          break;
        case CableSubscriptionModel.UserUploads:
          userUploadsCable(dispatch, getAccessTokenSilently, message, user);
          break;

        default:
          break;
      }

      return {status: 'success'};
    },
  };
};

export default cableSubscriptions;
