import { CROPPABLE_ITEMS } from 'features/canvasItemsSlice';
import { CanvasButton } from '../../CanvasButton';
import { CanvasItem } from 'features/types/canvasItemsSlice';
import { Crop } from 'react-feather';
import { useEditCrop } from '../Canvas/utils/useEditCrop';

export default function CropButton({
  projectId,
  itemId,
  item,
}: {
  projectId: string;
  itemId: string;
  item: CanvasItem;
}) {
  const editCrop = useEditCrop({ projectId, itemId, item });
  const croppable = CROPPABLE_ITEMS.includes(item.viewType);

  if (!croppable) return null;

  return (
    <CanvasButton leftIcon={Crop} onClick={editCrop.start}>
      Crop
    </CanvasButton>
  );
}
