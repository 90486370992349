import {getMuxStoryboardImageJson} from 'api/externalMuxAPI';
import {useEffect, useState} from 'react';

export type ThumbnailTile = {
  start: number;
  x: number;
  y: number;
};

export type StoryboardThumbnailTilesData = {
  url: string;
  tile_width: number;
  tile_height: number;
  duration: number;
  tiles: ThumbnailTile[];
};

export const BLANK_STORYBOARD_TILES_DATA = {
  url: '',
  tile_width: 100,
  tile_height: 100,
  duration: 10,
  tiles: [],
};

export const generateAudioStoryBoard = (duration: number) => {
  return {...BLANK_STORYBOARD_TILES_DATA, duration};
};

export const useLoadStoryboardJson = (
  playbackId: string | undefined,
  isVideo: boolean,
  duration: number | null,
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<StoryboardThumbnailTilesData | undefined>(undefined);
  useEffect(() => {
    if (!playbackId || duration == null) return;

    window.analytics.track('Video thumbnail loading started');
    if (isVideo) {
      getMuxStoryboardImageJson(playbackId)
        .then(response => {
          window.analytics.track('Video thumbnail loaded correctly');
          setIsLoading(false);
          setData(response.data);
        })
        .catch(error => {
          setIsLoading(false);
          setData(generateAudioStoryBoard(duration));
          // TODO(lenny): Figure out what happens once loading is finished
          window.analytics.track('Video thumbnail didnt load correctly');
          console.error(error.response); // this is the main part. Use the response property from the error object
        });
    } else {
      setIsLoading(false);
      setData(generateAudioStoryBoard(duration));
    }
  }, [playbackId, isVideo, duration]);

  return {isLoading, data};
};
