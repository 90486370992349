import {BASE_CANVAS_STATE} from 'features/canvasItemsSlice';
import {GetRootState} from 'configureStore';
import {createSelector} from '@reduxjs/toolkit';

export const canvasItemsSelector = (state: GetRootState) => state.canvasItems.present;

export const selectCanvasItemsProjectsCanvas = (state: GetRootState) =>
  canvasItemsSelector(state).projectsCanvas;

export const selectCanvasProject = createSelector(
  (state: GetRootState) => selectCanvasItemsProjectsCanvas(state),
  (state: GetRootState, projectId: string) => projectId,
  (projectsCanvas, projectId) => {
    return projectsCanvas[projectId]
      ? projectsCanvas[projectId]
      : {
          ...BASE_CANVAS_STATE,
          isLoading: true,
        };
  },
);

export const selectProjectCanvasItem = createSelector(
  (state: GetRootState) => selectCanvasItemsProjectsCanvas(state),
  (state: GetRootState, params: {projectId: string; itemId: string}) => [
    params.projectId,
    params.itemId,
  ],
  (projectsCanvas, [projectId, itemId]) => projectsCanvas[projectId].items[itemId],
);

export const canPasteSelector = (state: GetRootState) => (projectId: string) => {
  return (
    canvasItemsSelector(state).copiedItems &&
    canvasItemsSelector(state).copiedItems.length > 0
  );
};
