export const LoadingSearchResult = () => {
  return (
    <div className="space-y-3">
      <div className="h-6 w-40 animate-pulse bg-gray-100" />
      <div className="space-y-2">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-2 md:col-span-1">
            <div className="flex h-36 w-full animate-pulse items-start justify-start bg-gray-100"></div>
          </div>
          <div className="col-span-2 space-y-3 md:col-span-3">
            <div className="h-6 w-32 animate-pulse bg-gray-100" />
            <div className="h-8 w-full animate-pulse bg-gray-100" />
            <div className="h-8 w-full animate-pulse bg-gray-100" />
            <div className="h-8 w-full animate-pulse bg-gray-100" />
          </div>
        </div>
      </div>
    </div>
  );
};
