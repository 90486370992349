import {ArrowRight, ChevronDown} from 'react-feather';
import {Button, ButtonProps} from 'ui/Button';
import {FC, useEffect, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {InputError, TextInput, getFormError} from 'ui/TextInput';
import {StringParam, useQueryParam} from 'use-query-params';

import {DropdownMenu} from 'ui/DropdownMenu';
import {FORM_ERROR} from 'final-form';
import {OnboardingContainer} from './OnboardingContainer';
import {StepProps} from './CreateAccountPage';
import classNames from 'classnames';
import {getAuthToken} from 'services/utils';
import {shuffle} from 'lodash';
import {submitOnboardingSurvey} from 'features/authSlice';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useDispatch} from 'react-redux';
import {useUpdateAccountMutation} from 'services/accountAPI';

export const AccountOnboardingSurvey = ({nextStep}: StepProps) => {
  const dispatch = useDispatch();
  const {user} = useAuthAlt();

  const email = user?.email;

  const [accountId] = useQueryParam('accountId', StringParam);

  useEffect(() => {
    window.analytics.track('[App] AccountOnboardingSurvey loaded');
  }, []);

  const [updateAccount] = useUpdateAccountMutation();

  const submit = async (values: {organizationRole: string; publishFrequency: string}) => {
    try {
      // if (!email) throw new Error('User email is required');

      const name = `${user?.name.first_name} ${user?.name.last_name}`;
      if (!name) throw new Error('User name is required');

      const {...surveyData} = values;

      const token = await getAuthToken();
      dispatch(
        submitOnboardingSurvey({
          token,
          user: {name, email: email!},
          ...surveyData,
        }),
      );

      nextStep();
    } catch (error) {
      return {
        // @ts-ignore
        [FORM_ERROR]: error.message,
      };
    }
  };

  return (
    <OnboardingContainer
      title="How do you plan to use Milk Video?"
      subtitle="This will help us understand your needs and build a better Milk Video for you."
    >
      <Form onSubmit={submit}>
        {({handleSubmit, valid, submitting, submitError, modifiedSinceLastSubmit}) => (
          <form onSubmit={handleSubmit} className="space-y-6">
            <JobInputFormField />

            <Field
              name="publishFrequency"
              validate={value => !value && 'Please choose an option'}
            >
              {({input, meta}) => (
                <div>
                  <FrequencyInput
                    frequency={input.value}
                    onChange={value => input.onChange(value)}
                  />
                  <InputError>{getFormError(meta)}</InputError>
                </div>
              )}
            </Field>

            <Button
              variant="primary"
              rightIcon={ArrowRight}
              fullWidth
              type="submit"
              disabled={!valid}
              loading={submitting}
            >
              Get started
            </Button>

            {submitError && !submitting && !modifiedSinceLastSubmit && (
              <InputError>{submitError}</InputError>
            )}
          </form>
        )}
      </Form>
    </OnboardingContainer>
  );
};

export const JobInputFormField = () => {
  return (
    <Field
      name="organizationRole"
      validate={value => !value && 'Please choose an option'}
    >
      {({input, meta}) => {
        return (
          <div>
            <JobInput
              job={input.value}
              onChange={value => {
                input.onBlur();
                input.onChange(value);
              }}
            />
            <InputError>{getFormError(meta)}</InputError>
          </div>
        );
      }}
    </Field>
  );
};

const jobs = shuffle([
  'Marketing',
  'Sales',
  'People',
  'Product',
  'Engineering',
  'Leadership',
]);

const Label: FC = ({children}) => <div className="-mt-1.5 text-lg">{children}</div>;
const LabelBold: FC = ({children}) => <span className="font-semibold">{children}</span>;

const JobInput = ({job, onChange}: {job: string; onChange: (job: string) => void}) => {
  const [showCustomInput, setShowCustomInput] = useState(false);

  return (
    <div className="space-y-1.5">
      <Label>
        What best describes <LabelBold>your job role</LabelBold>?
      </Label>
      <div className="grid grid-cols-2 gap-1.5">
        {jobs.map(jobOption => (
          <ToggleButton
            key={jobOption}
            onClick={() => {
              setShowCustomInput(false);
              onChange(jobOption);
            }}
            selected={job === jobOption}
          >
            {jobOption}
          </ToggleButton>
        ))}
        <ToggleButton
          _className="col-span-2"
          onClick={() => {
            setShowCustomInput(true);
            onChange('');
          }}
          selected={showCustomInput}
        >
          Other
        </ToggleButton>
      </div>
      {showCustomInput && (
        <TextInput
          name="Job title"
          value={job}
          onChange={onChange}
          placeholder="Please specify"
          autoFocus
        />
      )}
    </div>
  );
};

const ToggleButton = ({
  selected,
  onClick,
  _className,
  ...rest
}: {selected: boolean} & Omit<ButtonProps, 'variant'>) => {
  return (
    <Button
      {...rest}
      variant={selected ? 'secondary' : 'tertiary'}
      _className={classNames('shadow-none', selected && '!border-indigo-300', _className)}
      onClick={event => {
        event.currentTarget.blur();
        onClick?.(event);
      }}
    />
  );
};

const frequencies = [
  'More than 1 per week',
  '1 per week',
  '1-2 per month',
  '1 per month',
  'Less than 1 per month',
  'Not currently publishing',
];

const FrequencyInput = ({
  frequency,
  onChange,
}: {
  frequency: string;
  onChange: (frequency: string) => void;
}) => {
  return (
    <div className="space-y-1.5">
      <div className="text-lg">
        <span className="font-semibold">How frequently</span> do you publish video
        content?
      </div>
      <DropdownMenu
        fullWidth
        anchor="left"
        items={frequencies.map(option => ({
          label: option,
          onClick: () => onChange(option),
        }))}
        button={
          <button
            className={classNames(
              'flex w-full items-center justify-between rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-600 shadow-sm ring-0 transition hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
            )}
          >
            {frequency || 'Click to select'}
            <ChevronDown size={20} className="text-current" />
          </button>
        }
      />
    </div>
  );
};
