import { Home } from './Home';
import { RecordingFormsContainerWrap } from 'pages/UserRecordingForms/RecordingFormsContainerWrap';
import { createContainer } from 'unstated-next';
import { useState } from 'react';

export function useOnboardingProgress(
  initialState = {
    step1: false,
    step2: false,
    step3: false,
  },
) {
  const [stepProgress, setStepProgress] = useState(initialState);

  const updateProgress = (newSteps: typeof initialState) =>
    setStepProgress({ ...stepProgress, ...newSteps });
  return { stepProgress, updateProgress };
}

const OnboardingProgressContainer = createContainer(useOnboardingProgress);

export function HomeContainer({ paymentStatus }: { paymentStatus?: 'success' }) {
  return (
    <RecordingFormsContainerWrap>
      <div className="relative mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:py-6 lg:px-8">
        <div className="mx-auto w-full">
          <OnboardingProgressContainer.Provider>
            <Home paymentStatus={paymentStatus} />
          </OnboardingProgressContainer.Provider>
        </div>
      </div>
    </RecordingFormsContainerWrap>
  );
}
