import {GetRootState} from 'configureStore';

export const userDownloadsSelector = (state: GetRootState) => state.userProjectDownload;

export const userProjectDownloadProjectsSelector =
  (state: GetRootState) => (projectId: string) =>
    state.userProjectDownload.projects[projectId];

export const allUserProjectDownloadsSelector = (state: GetRootState) => {
  return state.userProjectDownload.projects;
};
