import {StringParam, useQueryParam} from 'use-query-params';
import {useEffect, useState} from 'react';

import {AccountOnboardingSurvey} from './AccountOnboardingSurvey';
import {CompleteSignup} from './CompleteSignup';
import {InviteTeam} from 'features/NewOnboarding/InviteTeam';
import {routeHome} from 'routes/routesHelper';
import {useNavigate} from 'react-router-dom';

const Steps = [CompleteSignup, AccountOnboardingSurvey, InviteTeam];

export type StepProps = {nextStep: () => void};

export const CreateAccountPage = ({email}: {email?: string}) => {
  const [step, setStep] = useState(0);
  const ActiveStep = Steps[step];
  const navigate = useNavigate();

  const [redirectTo] = useQueryParam('redirectTo', StringParam);

  useEffect(() => {
    window.analytics.track('[App] CreateAccountPage loaded');
  }, []);

  const nextStep = () => {
    const nextStepIndex = step + 1;

    if (nextStepIndex === Steps.length) {
      navigate(redirectTo ?? routeHome);
    } else {
      setStep(nextStepIndex);
    }
  };

  return <ActiveStep key={step} nextStep={nextStep} />;
};
