import {CanvasPreviewProps, FocusedFlowCanvasPreview} from './CanvasPreview';
import {FC, ReactNode, useEffect, useState} from 'react';
import {useWizardState, useWizardStateId} from './State';

import {Button} from 'ui/Button';
import {DownloadCloud} from 'react-feather';
import {LayoutCalculator} from './LayoutCalculator';
import {announceUserToApi} from 'features/authSlice';
import classNames from 'classnames';
import {getAuthToken} from 'services/utils';
import {useAnonymousSignupMutation} from 'services/authAPI';
import {useCookies} from 'react-cookie';
import {useCreateWizardStateMutation} from 'services/wizardStateAPI';
import {useDispatch} from 'react-redux';
import {useDownloadProject} from './DownloadStep/DownloadStep';
import {useStytchUser} from '@stytch/stytch-react';
import {useUserProjectDownloadQuery} from 'services/userProjectDownloadsAPI';
import {useUserUploadQuery} from 'services/userUploadAPI';
import {useWatchElementSize} from 'features/Common/useElementSize';

export const StepHeading = ({
  title,
  subtitle,
  center,
}: {
  title: string;
  subtitle?: string;
  center?: boolean;
}) => {
  return (
    <div className={classNames('space-y-2', center && 'text-center')}>
      <div className="text-3xl font-bold">{title}</div>
      {subtitle && <div>{subtitle}</div>}
    </div>
  );
};

export const TwoColumns: FC<{
  preview?: Partial<CanvasPreviewProps>;
  heading?: ReactNode;
  noVerticalPadding?: boolean;
}> = ({preview, noVerticalPadding, heading, children}) => {
  const [containerRef, containerSize] = useWatchElementSize();

  return (
    <div className="flex flex-1 flex-col">
      <div
        className="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-3 lg:grid-cols-2"
        ref={containerRef}
      >
        <div className="col-span-1">
          <div className="mx-auto flex w-3/5 sm:w-2/5 md:w-full">
            <FocusedFlowCanvasPreview maxHeight={containerSize.height} {...preview} />
          </div>
        </div>
        <div
          className={`col-span-1 md:col-span-2 lg:col-span-1 ${
            !noVerticalPadding && 'space-y-6'
          }`}
        >
          {heading}
          {children}
        </div>
      </div>
    </div>
  );
};

export const DownloadButton: FC = () => {
  const {download, layoutCalculatorProps} = useDownloadProject();
  const {setStep} = useWizardState();

  return (
    <>
      <Button
        variant="primary"
        leftIcon={DownloadCloud}
        onClick={async () => {
          await download();
          await setStep({step: 'download'});
        }}
      >
        Download video
      </Button>
      <LayoutCalculator {...layoutCalculatorProps} />
    </>
  );
};

export const useUpload = () => {
  const {uploadId} = useWizardState();
  const [shouldPoll, setShouldPoll] = useState(true);
  const queryResult = useUserUploadQuery(
    {id: uploadId!},
    {pollingInterval: shouldPoll ? 2000 : undefined, skip: !uploadId},
  );

  const upload = queryResult.data;

  let isProcessing = false;
  if (!upload?.mux_playback_id) isProcessing = true;
  if (upload?.duration == null) isProcessing = true;

  useEffect(() => {
    setShouldPoll(isProcessing);
  }, [isProcessing]);

  return {
    uploadId,
    upload,
    isLoading: uploadId && queryResult.isLoading,
    isProcessing,
  };
};

export const useProjectDownload = (downloadId: string | undefined) => {
  const [shouldPoll, setShouldPoll] = useState(false);

  const queryResult = useUserProjectDownloadQuery(
    {downloadId: downloadId!},
    {
      refetchOnFocus: true,
      pollingInterval: shouldPoll ? 5000 : undefined,
      skip: !downloadId,
    },
  );

  const download = queryResult.data;

  useEffect(() => {
    setShouldPoll(!download?.is_loading);
  }, [download?.is_loading]);

  return {download, ...queryResult};
};

export const usePrepareState = () => {
  const [, setCookie] = useCookies(['anonymous-token']);
  const [anonymousSignup] = useAnonymousSignupMutation();
  const dispatch = useDispatch();

  const user = useStytchUser();

  const ensureUser = async () => {
    try {
      await getAuthToken();
    } catch (error) {
      const response = await anonymousSignup();
      if (!('data' in response)) return;

      setCookie('anonymous-token', response.data.token);

      const token = await getAuthToken();
      await new Promise<void>((resolve, reject) => {
        dispatch(
          announceUserToApi(token, {}, error => {
            if (error) reject(error);
            resolve();
          }),
        );
      });
    }
  };

  const [wizardStateId, setWizardStateId] = useWizardStateId();
  const {state} = useWizardState();
  const [createWizardState] = useCreateWizardStateMutation();

  const ensureWizardState = async () => {
    if (!wizardStateId) {
      const result = await createWizardState({state});
      if ('data' in result) {
        setWizardStateId(result.data.id);
      } else {
        throw result.error;
      }
    }
  };

  return async () => {
    await ensureUser();
    await ensureWizardState();
  };
};
