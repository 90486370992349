import {ClipContentWrap} from './ClipContentWrap';
import {ClipListProvider} from './ClipListContext';
import {DashboardContentSkeleton} from 'features/Dashboard/common/DashboardContentSkeleton';
import {DashboardContentWrap} from 'features/Dashboard/common/DashboardContentWrap';
import {LoadingClipsList} from './LoadingClipsList';
import cn from 'classnames';
import {useListUploadMediaClipsQuery} from 'services/uploadMediaClipAPI';

export const ClipsListContainer = () => {
  const {data, isLoading, isFetching} = useListUploadMediaClipsQuery();

  if (isLoading || isFetching) {
    return (
      <>
        <div className="flex h-full w-full flex-col space-y-3 border-b border-gray-200 px-4 py-4">
          <div className="grid grid-cols-1 gap-4">
            <div className="h-8 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
          </div>
        </div>
        <LoadingClipsList />
      </>
    );
  }

  return (
    <DashboardContentSkeleton>
      <DashboardContentWrap
        className={cn(
          'relative z-0 mx-auto flex flex-1 flex-row items-start justify-center overflow-y-auto',
        )}
      >
        <ClipListProvider>
          <ClipContentWrap />
        </ClipListProvider>
      </DashboardContentWrap>
    </DashboardContentSkeleton>
  );
};
