import {BanIcon} from '@heroicons/react/solid';
import {ChaptersContainer} from '../ThumbnailTimeline/VisibleTranscript';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import {TranscriptsSummaryChapters} from './TranscriptsSummaryChapters';
import {userUploadTranscriptsSummaryAPI} from 'services/userUploadTranscriptsSummaryAPI';

export const TranscriptsSummaryPanel = ({
  hasFullResponse,
  transcriptLength,
  userUploadId,
  textContainerHeight,
}: {
  hasFullResponse: boolean;
  transcriptLength: number;
  userUploadId: string;
  textContainerHeight: number;
}) => {
  const {
    data: chaptersData,
    isLoading: chaptersLoading,
    isFetching: chaptersFetching,
  } = userUploadTranscriptsSummaryAPI.useUserUploadTranscriptsSummaryQuery(
    {
      userUploadId,
    },
    {
      skip: !(hasFullResponse && transcriptLength),
    },
  );

  const summaryChapters = chaptersData?.user_upload_transcripts_summary || [];

  return chaptersFetching || chaptersLoading ? (
    <div className="hidden min-h-full w-1/3 flex-col items-center justify-center sm:flex">
      <div className="relative z-10 flex min-h-full flex-col items-center justify-center opacity-50">
        <span className="block">
          <LoadingSpinner />
        </span>
      </div>
    </div>
  ) : (
    <ChaptersContainer height={textContainerHeight}>
      {summaryChapters?.length > 0 ? (
        <TranscriptsSummaryChapters
          summaryChapters={summaryChapters}
          userUploadId={userUploadId}
        />
      ) : (
        <div className="relative flex min-h-full w-1/3 min-w-full flex-col items-center justify-center opacity-50">
          <h1 className="mt-4 min-w-full text-center text-sm leading-7 text-gray-600">
            <span className="block">
              <BanIcon className="inline-block h-5 w-5 text-gray-600" />
            </span>
            No chapters found.
          </h1>
        </div>
      )}
    </ChaptersContainer>
  );
};
