import {useDispatch, useSelector} from 'react-redux';

import {AccountLabelColor} from 'features/types/userLibrarySlice';
import {authAccountLabelSelector} from 'features/selectors/authSelectors';
import {saveUpdateLabelColor} from 'features/authSlice';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useState} from 'react';

export const useAccountLabels = () => {
  const accountLabels = useSelector(authAccountLabelSelector);
  const [labelSearchTerm, setLabelSearchTerm] = useState('');
  const labelsToDisplay = accountLabels
    ? accountLabels.map(label => ({
        id: label.id,
        name: label.label_value,
        bgColor: label.label_options['bgColor'],
        selectedColor: label.label_options['selectedColor'],
      }))
    : [];

  // console.log('labelsToDisplay', labelsToDisplay);

  const {getAccessTokenSilently} = useAuthAlt();

  const dispatch = useDispatch();

  const filteredLabels = labelsToDisplay
    .filter(label => label.name.toLowerCase().includes(labelSearchTerm.toLowerCase()))
    .sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  const handleLabelSave = (
    tempLabelName: string,
    color: AccountLabelColor,
    callback: () => void,
  ) => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(saveUpdateLabelColor(token, tempLabelName, color, color.id));
      } catch (error) {
        console.error('error', error);
      }
    })();

    callback();
  };

  return {handleLabelSave, filteredLabels, labelSearchTerm, setLabelSearchTerm};
};
