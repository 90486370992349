export const routePaths = {
  authenticate: 'authenticate',
  robot: 'robot-routes',
  dashboard: 'dashboard',
  canvas: 'canvas',
  create: 'create',
  recording: 'recording',
  payments: 'payments',
  share: 'share',
  user: 'user',
};

export enum DashParams {
  BillingSuccessParam = 'billing-success',
  BrandKitParam = 'brand',
  CanvasParam = 'canvas',
  ClipsParam = 'clips',
  DownloadsParam = 'downloads',
  HomeParam = 'home',
  LandingPagesParam = 'landing-pages',
  ProjectsParam = 'projects',
  RecordingFormParams = 'recording',
  SearchParam = 'search',
  SettingsParam = 'settings',
  TemplatePageParam = 'template-page',
  TemplatesBrowserParam = 'templates-browser',
  TemplatesPageParam = 'templates',
  UploadsFolderParam = 'f',
  UploadsMediaParam = 'media',
  UploadsParam = 'uploads',
  CreateCampaign = 'create-campaign',
}

export const focusedFlowRoute = 'focused-flow';
export const podcastAudiogramRoute = 'podcast-audiogram';

export const routeHome = '/dashboard';
