import {DashParams, routeHome, routePaths} from 'routes/routesHelper';

import {Navigate} from 'react-router-dom';
import SuccessPromptModal from 'features/Common/Modal/SuccessPromptModal';
import {useState} from 'react';

enum DecisionDestinations {
  Settings = 'settings',
  Uploads = 'uploads',
}

export default function ZoomUploadStartedPage() {
  const [hasDecided, setHasDecided] = useState<DecisionDestinations>();

  if (hasDecided) {
    if (hasDecided === DecisionDestinations.Settings) {
      return <Navigate to={`/${routePaths.dashboard}/${DashParams.SettingsParam}`} />;
    } else {
      return <Navigate to={routeHome} />;
    }
  } else {
    return (
      <div>
        <SuccessPromptModal
          title="Zoom account connection started"
          content="Please note your account syncing can take up to 10 minutes. You will receive an email confirmation once it is complete. In the meanwhile, you can either return to the dashboard."
          handleCancel={() => {
            setHasDecided(DecisionDestinations.Settings);
          }}
          handleConfirm={() => {
            setHasDecided(DecisionDestinations.Uploads);
          }}
          buttonAction="Go to uploads"
          buttonSecondaryAction="Return to settings"
        />
      </div>
    );
  }
}
