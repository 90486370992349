import {
  AccountLabel,
  ApiUserResponseBeforeWorkspace,
  ApiUserResponseNormal,
  postUserToApi,
  postUserToApiV2,
  resetUserPassword,
} from 'api/authAPI';
import { AccountLabelColor, UserPreferences } from './types/userLibrarySlice';
import {
  AccountWrapperAccountType,
  AccountsWrapperType,
  AuthSliceType,
  CurrentAccountSettingsType,
} from './types/authSlice';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  postChangeActiveAccount,
  postCreateLabelColor,
  postCreateWorkspaceToApi,
  postPaywallVisit,
  postRemoveTeamUserFromOrganization,
  postSubmitOnboardingSurvey,
  postTrialExtension,
  postUpdateIsDefaultColorsDisabled,
  postUpdateIsGlossaryActive,
  postUpdateLabelColor,
  postUpdateTeamMemberRole,
  postUserCompletedAccountWelcome,
  postUserEmailSubscriptionPreference,
  revokeAccountUser,
  sendNewInvitation,
  updateCompanyName,
} from 'api/accountsAPI';

import { AppThunk } from 'configureStore';
import { NOTIFICATION_BASE } from './Notifications/constants';
import NotificationError from './Notifications/NotificationError';
import { NotificationInfo } from './Notifications/NotificationInfo';
import { User } from '@stytch/stytch-react';
import { UserPaymentPreferences } from './Payments/constants';
import { identify } from './Common/tracking';
import { prepareIdentifyPayload } from './Auth/utils';
import { store } from 'react-notifications-component';

const INITIAL_CURRENT_ACCOUNT_SETTINGS: CurrentAccountSettingsType = {
  passwordReset: {
    isSending: false,
    wasSent: false,
    message: '',
  },
  sendInvitation: {
    isSending: false,
    wasSent: false,
    message: '',
  },
  updateCompany: {
    isSending: false,
    wasSent: false,
    message: '',
  },
  revokeAccountUser: {
    message: '',
    success: false,
  },
  removeTeamMemberFromOrganization: {
    message: '',
    isSending: false,
  },
  paywall: {
    receivedResponse: false,
    isChecking: false,
    projectCount: 0,
    uploadCount: 0,
    downloadCount: 0,
  },
};

export const initialState: AuthSliceType = {
  authenticatedWithApi: false,
  isSubmittingNewWorkspace: false,
  isFetchingInitialApiRequest: true,
  isSubmittingWelcomeStart: false,
  hasData: false,
  connectedAccounts: [],
  accounts: [],
  accountWrapper: null,
  user: null,
  email: null,
  currentAccountSettings: INITIAL_CURRENT_ACCOUNT_SETTINGS,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUserNotLoggedIn(state) {
      state.isFetchingInitialApiRequest = false;
    },
    postUserToApiStart(state, action) {
      state.isFetchingInitialApiRequest = true;
    },
    postUserToApiSuccess(
      state,
      action: PayloadAction<{
        userStatus: ApiUserResponseNormal | ApiUserResponseBeforeWorkspace;
      }>,
    ) {
      const { userStatus } = action.payload;
      state.email = userStatus.user.email;
      state.user = userStatus.user;
      state.accounts = userStatus.accounts;
      state.isFetchingInitialApiRequest = false;
      state.authenticatedWithApi = true;

      // Response was ApiUserResponseNormal
      if (userStatus.current_account) {
        state.accountWrapper = userStatus.current_account;
        state.connectedAccounts = userStatus.connected_accounts;
      }
    },
    postUserToApiFailure(state, action) {
      const { err } = action.payload;
      // OLD
      state.isFetchingInitialApiRequest = false;
      console.error(err);
    },
    postUserToApiDiscretelyStart(state, action) {
      // state.isFetchingInitialApiRequest = true;
    },
    postUserToApiDiscretelySuccess(state, action) {
      const { email, userStatus } = action.payload;
      // console.log('userStatus', userStatus);
      // state.email = email;
      // state.user = userStatus.user;
      state.accountWrapper = userStatus.current_account;
      state.accounts = userStatus.accounts;
      // state.isFetchingInitialApiRequest = false;
      // state.authenticatedWithApi = true;
    },
    postUserToApiDiscretelyFailure(state, action) {
      const { err } = action.payload;
      // OLD
      // state.isFetchingInitialApiRequest = false;
      console.error(err);
    },
    removeTeamMemberFromOrganizationPending(state) {
      state.currentAccountSettings.removeTeamMemberFromOrganization.isSending = true;
      state.currentAccountSettings.removeTeamMemberFromOrganization.message = '';
    },
    removeTeamMemberFromOrganizationSuccess(
      state,
      action: PayloadAction<{
        accountUserId: string;
        userId: string;
      }>,
    ) {
      state.currentAccountSettings.removeTeamMemberFromOrganization.isSending = false;

      // drop deleted user from account_users and users
      if (state.accountWrapper?.account_users && state.accountWrapper?.users) {
        state.accountWrapper.users = state.accountWrapper.users.filter(
          user => user.id !== action.payload.userId,
        );

        state.accountWrapper.account_users = state.accountWrapper.account_users.filter(
          user => user.id !== action.payload.accountUserId,
        );
      }
    },
    removeTeamMemberFromOrganizationFailure(
      state,
      action: PayloadAction<{
        message: string;
      }>,
    ) {
      state.currentAccountSettings.removeTeamMemberFromOrganization.isSending = false;
      state.currentAccountSettings.removeTeamMemberFromOrganization.message =
        action.payload.message;
    },
    updateTeamMemberRoleSuccess(
      state,
      action: PayloadAction<{
        accountUserId: string;
        newRole: string;
      }>,
    ) {
      // update users role
      if (state.accountWrapper?.account_users) {
        const { accountUserId, newRole } = action.payload;
        state.accountWrapper.account_users.map(accountUser => {
          if (accountUser.id === accountUserId) {
            newRole === 'Admin'
              ? (accountUser.roles.admin = true)
              : (accountUser.roles.admin = false);
          }
        });
      }
    },
    updateTeamMemberRoleFailure(state, action: PayloadAction<{ message: string }>) {
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationError
            title={'Updating role failed'}
            message={action.payload.message}
          />
        ),
      });
    },
    resetPasswordStart(state) {
      state.currentAccountSettings.passwordReset.isSending = true;
      state.currentAccountSettings.passwordReset.wasSent = false;
      state.currentAccountSettings.passwordReset.message = '';
    },
    resetPasswordSuccess(state, action) {
      const { message } = action.payload;
      state.currentAccountSettings.passwordReset.isSending = false;
      state.currentAccountSettings.passwordReset.wasSent = true;
      state.currentAccountSettings.passwordReset.message = message;
    },
    resetPasswordFailure(state, action) {
      const { message } = action.payload;
      state.currentAccountSettings.passwordReset.isSending = false;
      state.currentAccountSettings.passwordReset.wasSent = true;
      state.currentAccountSettings.passwordReset.message = message;
    },
    sendInvitationStart(state) {
      state.currentAccountSettings.sendInvitation.isSending = true;
      state.currentAccountSettings.sendInvitation.wasSent = false;
      state.currentAccountSettings.sendInvitation.message = '';
    },
    sendInvitationSuccess(state, action) {
      const { message, accountInvitations } = action.payload;
      // console.log('message', message);
      // console.log('invitation', invitation);
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationInfo
            title={'Invitation sent'}
            message={'Your account invite was successfully sent.'}
          />
        ),
      });
      state.currentAccountSettings.sendInvitation.isSending = false;
      state.currentAccountSettings.sendInvitation.wasSent = true;
      state.currentAccountSettings.sendInvitation.message = message;
      state.accountWrapper = {
        ...state.accountWrapper,
        account_invitations: accountInvitations,
      } as AccountsWrapperType;
    },
    sendInvitationFailure(state, action) {
      const { message } = action.payload;
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: <NotificationError title={'Invitation failed'} message={message} />,
        type: 'warning',
      });
      state.currentAccountSettings.sendInvitation.isSending = false;
      state.currentAccountSettings.sendInvitation.wasSent = true;
      state.currentAccountSettings.sendInvitation.message = message;
    },
    updateCompanyStart(state) {
      state.currentAccountSettings.updateCompany.isSending = true;
      state.currentAccountSettings.updateCompany.wasSent = false;
      state.currentAccountSettings.updateCompany.message = '';
    },
    updateCompanySuccess(state, action) {
      const { message, name } = action.payload;
      state.currentAccountSettings.updateCompany.isSending = false;
      state.currentAccountSettings.updateCompany.wasSent = true;
      state.currentAccountSettings.updateCompany.message = message;
      if (!state.accountWrapper) {
        return;
      }
      state.accountWrapper = {
        ...state.accountWrapper,
        account: {
          ...state.accountWrapper.account,
          name: name,
        } as AccountWrapperAccountType,
      } as AccountsWrapperType;
    },
    updateCompanyFailure(state, action) {
      const { message } = action.payload;
      state.currentAccountSettings.updateCompany.isSending = false;
      state.currentAccountSettings.updateCompany.wasSent = true;
      state.currentAccountSettings.updateCompany.message = message;
    },
    revokeAccountUserSuccess(state, action) {
      const { id } = action.payload;
      state.currentAccountSettings.revokeAccountUser.success = true;
      if (!state.accountWrapper) {
        return;
      }
      const { account_invitations } = state.accountWrapper;
      state.accountWrapper.account_invitations = account_invitations.filter(
        invite => invite.id !== id,
      );
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationInfo
            title={'Invite Revoked'}
            message={'The invite was successfully revoked.'}
          />
        ),
      });
    },
    revokeAccountUserFailure(state, action) {
      const { message } = action.payload;
      state.currentAccountSettings.revokeAccountUser.message = message;
      state.currentAccountSettings.revokeAccountUser.success = false;
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: <NotificationError title={'Invite revoke failed'} message={message} />,
        type: 'warning',
      });
    },
    postCreateWorkspaceToApiStart(state) {
      state.isSubmittingNewWorkspace = true;
    },
    postCreateWorkspaceToApiSuccess(state, action) {
      const { accounts, current_account } = action.payload;
      state.accountWrapper = current_account;
      state.accounts = accounts;
      state.isSubmittingNewWorkspace = false;
    },
    postCreateWorkspaceToApiFailure(state) {
      state.isSubmittingNewWorkspace = false;
    },

    postSubmitOnboardingSurveyStart(state) {
      state.isSubmittingNewWorkspace = true;
    },
    postSubmitOnboardingSurveySuccess(state, action) {
      const { accounts, current_account } = action.payload;
      state.accountWrapper = current_account;
      state.accounts = accounts;
      state.isSubmittingNewWorkspace = false;
    },
    postSubmitOnboardingSurveyFailure(state) {
      state.isSubmittingNewWorkspace = false;
    },
    postUserCompletedAccountWelcomeStart(state) {
      state.isSubmittingWelcomeStart = true;
    },
    postUserCompletedAccountWelcomeSuccess(state) {
      state.isSubmittingWelcomeStart = false;
    },
    postUserCompletedAccountWelcomeFailure(state) {
      state.isSubmittingWelcomeStart = false;
    },
    sendUserInvitesFromWelcomeFlowStart(state) {
      state.currentAccountSettings.sendInvitation.isSending = true;
    },
    sendUserInvitesFromWelcomeFlowSuccess(state) {
      state.currentAccountSettings.sendInvitation.isSending = false;
    },
    sendUserInvitesFromWelcomeFlowFailure(state) {
      state.currentAccountSettings.sendInvitation.isSending = false;
    },
    acceptAccountInviteStart(state) {
      state.isSubmittingNewWorkspace = true;
    },
    acceptAccountInviteFailed(state) {
      state.isSubmittingNewWorkspace = false;
    },
    changeActiveAccountStart() { },
    changeActiveAccountSuccess() { },
    changeActiveAccountFailure() { },
    checkPaywallVisitStart(state, action) {
      state.currentAccountSettings.paywall = {
        ...state.currentAccountSettings.paywall,
        isChecking: true,
      };
    },
    checkPaywallVisitSuccessful(state, action) {
      const { projectCount, uploadCount, downloadCount } = action.payload;
      state.currentAccountSettings.paywall = {
        ...state.currentAccountSettings.paywall,
        projectCount,
        uploadCount,
        downloadCount,
        isChecking: false,
        receivedResponse: true,
      };
    },
    checkPaywallVisitFailure(state, action) {
      state.currentAccountSettings.paywall = {
        ...state.currentAccountSettings.paywall,
        isChecking: false,
        receivedResponse: true,
      };
    },
    requestTrialExtensionStart(state, action) {
      state.currentAccountSettings.paywall = {
        ...state.currentAccountSettings.paywall,
        isChecking: true,
        receivedResponse: false,
      };
    },
    requestTrialExtensionSuccess(state, action) {
      state.currentAccountSettings.paywall = {
        ...state.currentAccountSettings.paywall,
        isChecking: false,
        receivedResponse: true,
      };
    },
    requestTrialExtensionFailure(state, action) {
      state.currentAccountSettings.paywall = {
        ...state.currentAccountSettings.paywall,
        isChecking: false,
        receivedResponse: true,
      };
    },
    saveUpdateLabelColorStart(
      state,
      action: PayloadAction<{
        color: AccountLabelColor;
        colorId?: string;
        colorName: string;
      }>,
    ) {
      const { colorId, color, colorName } = action.payload;

      if (!state.accountWrapper) {
        return;
      }

      if (colorId) {
        let targetIndex = 0;
        state.accountWrapper?.account_label.find((accountLabel, index) => {
          if (accountLabel.id === colorId) {
            targetIndex = index;
          }
        });

        const newAccountLabels = [...state.accountWrapper.account_label];
        newAccountLabels[targetIndex] = {
          ...newAccountLabels[targetIndex],
          label_options: {
            bgColor: color.bgColor,
            selectedColor: color.selectedColor,
          },
          label_value: colorName,
        };

        state.accountWrapper.account_label = newAccountLabels;
      } else {
        state.accountWrapper.account_label.push({
          id: `temp-id-${colorName}`,
          label_options: {
            bgColor: color.bgColor,
            selectedColor: color.selectedColor,
          },
          label_type: 'color',
          label_value: colorName,
        });
      }
    },
    saveUpdateLabelColorSuccess(state, action: PayloadAction<{ newLabel: AccountLabel }>) {
      const { newLabel } = action.payload;

      if (!state.accountWrapper) {
        return;
      }

      state.accountWrapper.account_label.find(label => {
        if (label.id === `temp-id-${newLabel.label_value}`) {
          label.id = newLabel.id;
        }
      });
    },
    saveUpdateLabelColorFailure(state, action) { },
    updateIsGlossaryActiveStart(state, action) { },
    updateIsGlossaryActiveSuccess(state, action) { },
    updateIsGlossaryActiveFailure(state, actio: PayloadAction<UserPreferences>) { },
    updateUserPreferences(state, action) {
      const userPreferences = action.payload;

      if (!state.user || !state.user.created_at) {
        return;
      }

      const newUserState = {
        ...state.user,
        user_preferences: {
          ...userPreferences,
          [UserPaymentPreferences.ViewedPaymentModal]: true,
        },
      };

      if (newUserState && state.user) {
        state.user = newUserState;
      }
    },
    updateIsDefaultColorsDisabledSuccess(
      state,
      action: PayloadAction<{ disabled: boolean }>,
    ) {
      if (state.accountWrapper) {
        state.accountWrapper.account.is_default_colors_disabled = action.payload.disabled;
      }
    },
    updateIsDefaultColorsDisabledFailure(
      state,
      action: PayloadAction<{ message: string }>,
    ) {
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationError title={'Update failed'} message={action.payload.message} />
        ),
        type: 'warning',
      });
    },
  },
});

export const {
  acceptAccountInviteFailed,
  acceptAccountInviteStart,
  changeActiveAccountFailure,
  changeActiveAccountStart,
  changeActiveAccountSuccess,
  checkPaywallVisitFailure,
  checkPaywallVisitStart,
  checkPaywallVisitSuccessful,
  postCreateWorkspaceToApiFailure,
  postCreateWorkspaceToApiStart,
  postCreateWorkspaceToApiSuccess,
  postSubmitOnboardingSurveyFailure,
  postSubmitOnboardingSurveyStart,
  postSubmitOnboardingSurveySuccess,
  postUserCompletedAccountWelcomeFailure,
  postUserCompletedAccountWelcomeStart,
  postUserCompletedAccountWelcomeSuccess,
  postUserToApiDiscretelyFailure,
  postUserToApiDiscretelyStart,
  postUserToApiDiscretelySuccess,
  postUserToApiFailure,
  postUserToApiStart,
  postUserToApiSuccess,
  removeTeamMemberFromOrganizationFailure,
  removeTeamMemberFromOrganizationPending,
  removeTeamMemberFromOrganizationSuccess,
  updateTeamMemberRoleFailure,
  updateTeamMemberRoleSuccess,
  requestTrialExtensionFailure,
  requestTrialExtensionStart,
  requestTrialExtensionSuccess,
  resetPasswordFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  revokeAccountUserFailure,
  revokeAccountUserSuccess,
  saveUpdateLabelColorFailure,
  saveUpdateLabelColorStart,
  saveUpdateLabelColorSuccess,
  sendInvitationFailure,
  sendInvitationStart,
  sendInvitationSuccess,
  sendUserInvitesFromWelcomeFlowFailure,
  sendUserInvitesFromWelcomeFlowStart,
  sendUserInvitesFromWelcomeFlowSuccess,
  updateCompanyFailure,
  updateCompanyStart,
  updateCompanySuccess,
  updateIsGlossaryActiveFailure,
  updateIsGlossaryActiveStart,
  updateIsGlossaryActiveSuccess,
  updateUserNotLoggedIn,
  updateUserPreferences,
  updateIsDefaultColorsDisabledSuccess,
  updateIsDefaultColorsDisabledFailure,
} = authSlice.actions;

export default authSlice.reducer;

type ChangeActiveAccountProps = {
  token: string;
  account: any;
  email: string;
};

export const updateIsGlossaryActive =
  (token: string, enabled: boolean): AppThunk =>
    async dispatch => {
      dispatch(updateIsGlossaryActiveStart({}));
      try {
        await postUpdateIsGlossaryActive(token, enabled);
        dispatch(updateIsGlossaryActiveSuccess({}));
      } catch (error) {
        console.error(error);
        dispatch(updateIsGlossaryActiveFailure({}));
      }
    };

export const updateIsDefaultColorsDisabled =
  (token: string, disabled: boolean): AppThunk =>
    async dispatch => {
      try {
        const response = await postUpdateIsDefaultColorsDisabled(token, disabled);
        if (response.status !== 'success') {
          throw new Error(response.message);
        }

        dispatch(updateIsDefaultColorsDisabledSuccess({ disabled }));
      } catch (error) {
        dispatch(updateIsDefaultColorsDisabledFailure({ message: (error as Error).message }));
      }
    };

export const requestTrialExtension =
  (token: string, account: AccountWrapperAccountType, email: string): AppThunk =>
    async dispatch => {
      dispatch(requestTrialExtensionStart({}));
      try {
        await postTrialExtension(token, account.id, email);
        window.location.reload();
        dispatch(requestTrialExtensionSuccess({}));
      } catch (error) {
        console.error(error);
        dispatch(requestTrialExtensionFailure({}));
      }
    };

export const changeActiveAccount =
  ({ token, account, email }: ChangeActiveAccountProps): AppThunk =>
    async dispatch => {
      try {
        dispatch(changeActiveAccountStart());

        await postChangeActiveAccount(token, account.id, email);
        // console.log('response', 'Just doing a hard reset');
        // TODO(lenny) Update this sometime when it matters
        window.location.reload();
        dispatch(changeActiveAccountSuccess());
      } catch (err) {
        dispatch(changeActiveAccountFailure());
      }
    };

type AnnounceUserCompletedAccountWelcomeProps = {
  accountId: string;
  user: any;
  token: string;
};

export const announcePaywallVisit =
  (token: string, email: string, account: AccountWrapperAccountType): AppThunk =>
    async dispatch => {
      dispatch(checkPaywallVisitStart({}));
      try {
        (async () => {
          const response = await postPaywallVisit(token, email, account);
          // console.log('response', response);
          const { account_status: accountStatus } = response;
          const {
            project_count: projectCount,
            upload_count: uploadCount,
            download_count: downloadCount,
          } = accountStatus;
          dispatch(
            checkPaywallVisitSuccessful({
              uploadCount,
              projectCount,
              downloadCount,
            }),
          );
        })();
      } catch (error) {
        console.error(error);
        dispatch(checkPaywallVisitFailure({}));
      }
    };

export const announceUserCompletedAccountWelcome =
  ({ accountId, user, token }: AnnounceUserCompletedAccountWelcomeProps): AppThunk =>
    async dispatch => {
      const { email } = user;

      window.analytics.track('Announce user completed account welcome');

      try {
        dispatch(postUserCompletedAccountWelcomeStart());
        const userStatus = await postUserCompletedAccountWelcome(
          token,
          email,
          user,
          accountId,
        );
        // dispatch(postUserCompletedAccountWelcomeSuccess());
      } catch (err) {
        dispatch(postUserCompletedAccountWelcomeFailure());
      }
    };

type SubmitOnboardingSurveyProps = {
  token: string;
  experienceLevel?: string;
  organizationSize?: string;
  organizationRole?: string;
  publishFrequency?: string;
  user: { name: string; email: string };
  callback?: (error?: Error) => void;
};

export const submitOnboardingSurvey =
  ({
    token,
    experienceLevel,
    organizationSize,
    organizationRole,
    publishFrequency,
    user,
    callback,
  }: SubmitOnboardingSurveyProps): AppThunk =>
    async dispatch => {
      const { email } = user;
      try {
        dispatch(postSubmitOnboardingSurveyStart());
        const newAccountData = await postSubmitOnboardingSurvey(
          token,
          email,
          experienceLevel,
          organizationSize,
          organizationRole,
          publishFrequency,
        );

        const { accounts, current_account } = newAccountData;
        window.analytics.track('New workspace successfully requested', {
          account_id: current_account.account.id,
          user_experience_level: experienceLevel,
          organization_size: organizationSize,
          organization_role: organizationRole,
        });

        const uniqueId = email;
        identify(uniqueId, {
          name: user.name,
          email: user.email,
          company: current_account.account.id,
          plan: current_account.account.plan,
        });

        dispatch(postSubmitOnboardingSurveySuccess({ email, accounts, current_account }));
        callback?.();
      } catch (err) {
        dispatch(postSubmitOnboardingSurveyFailure());
        // @ts-ignore
        callback?.(err);
      }
    };

type CreateAdditionalWorkspaceProps = {
  token: string;
  workspace: any;
  user: any;
  experienceLevel: any;
  organizationSize: any;
  organizationRole: any;
};

type CreateUserWorkspaceProps = {
  token: string;
  workspace: string;
  workspaceDomain?: string;
  user: { name?: string; email: string };
  callback?: (error?: Error) => void;
  errorCallback?: (error?: Error) => void;
};

export const createUserWorkspace =
  ({
    token,
    workspace,
    workspaceDomain,
    user,
    callback,
    errorCallback,
  }: CreateUserWorkspaceProps): AppThunk =>
    async dispatch => {
      const { email } = user;
      try {
        dispatch(postCreateWorkspaceToApiStart());
        try {
          const newAccountData = await postCreateWorkspaceToApi({
            token,
            email,
            workspace,
            workspaceDomain,
          });

          const { accounts, current_account } = newAccountData;
          window.analytics.track('New workspace successfully requested', {
            account_id: current_account.account.id,
            account_name: workspace,
          });

          const uniqueId = email;
          identify(uniqueId, {
            name: user.name,
            email: user.email,
            company: current_account.account.id,
            plan: current_account.account.plan,
          });

          dispatch(postCreateWorkspaceToApiSuccess({ email, accounts, current_account }));
          callback?.();
        } catch (error) { }
      } catch (error) {
        dispatch(postCreateWorkspaceToApiFailure());
        // @ts-ignore
        callback?.(error);
      }
    };

export const announceUserToApi =
  (token: string, user: User, callback?: (error?: Error) => void): AppThunk =>
    async dispatch => {
      const email = user.emails[0].email;

      window.analytics.track('User initiated connection to API');

      try {
        dispatch(postUserToApiStart({ email, user }));
        const userStatus = await postUserToApiV2(token, email, user);

        if (userStatus.current_account && userStatus.current_account.account) {
          const uniqueId = userStatus.user.email;
          identify(uniqueId, prepareIdentifyPayload(user, userStatus));
        }

        dispatch(postUserToApiSuccess({ userStatus }));
        callback?.();
      } catch (err) {
        dispatch(postUserToApiFailure({ email, err }));
        // @ts-ignore
        callback?.(err);
      }
    };

export const announceDiscretelyUserToApi =
  (token: string, user: any): AppThunk =>
    async dispatch => {
      const { email } = user;

      try {
        dispatch(postUserToApiDiscretelyStart({ email, user }));
        const userStatus = await postUserToApi(token, email, user);

        // console.log('userStatus.current_account', userStatus.current_account);

        if (userStatus.current_account && userStatus.current_account.account) {
          const uniqueId = email;
          identify(uniqueId, prepareIdentifyPayload(user, userStatus));
        }

        dispatch(postUserToApiDiscretelySuccess({ email, userStatus }));
      } catch (err) {
        dispatch(postUserToApiDiscretelyFailure({ email, err }));
      }
    };

export const resetPasswordForUser =
  (token: string, email: string): AppThunk =>
    async dispatch => {
      window.analytics.track('User reset password');
      try {
        dispatch(resetPasswordStart());
        const passwordResetStatus = await resetUserPassword(token, email);
        dispatch(resetPasswordSuccess({ message: passwordResetStatus }));
      } catch (err) {
        // @ts-ignore
        dispatch(resetPasswordFailure({ message: err.message }));
      }
    };

export const sendInvitationToUser =
  (
    token: string,
    accountId: string,
    currentUserEmail: string,
    invitedUserEmail: string,
  ): AppThunk =>
    async dispatch => {
      window.analytics.track('User sent invitation to user', {
        invited_user: invitedUserEmail,
        account_id: accountId,
      });
      try {
        dispatch(sendInvitationStart());
        const accountInviteResponse = await sendNewInvitation(
          token,
          accountId,
          currentUserEmail,
          invitedUserEmail,
        );
        // console.log('accountInviteResponse', accountInviteResponse);
        const { payload, status } = accountInviteResponse;
        if (status === 'Success') {
          dispatch(
            sendInvitationSuccess({
              message: payload.message,
              invitation: payload.invitation,
              accountInvitations: payload.account_invitations,
            }),
          );
        } else {
          dispatch(sendInvitationFailure({ message: payload.message }));
        }
      } catch (err) {
        // @ts-ignore
        dispatch(sendInvitationFailure({ message: err.message }));
      }
    };

export const updateCompanyNameActionGroup =
  (token: string, email: string, accountId: string, companyName: string): AppThunk =>
    async dispatch => {
      window.analytics.track('User updated company name', {
        account_id: accountId,
        new_company_name: companyName,
      });
      try {
        dispatch(updateCompanyStart());
        const { message } = await updateCompanyName(token, email, accountId, companyName);
        dispatch(updateCompanySuccess({ message: message }));
      } catch (err) {
        // @ts-ignore
        dispatch(updateCompanyFailure({ message: err.message }));
      }
    };

export const revokeAccountUserInvite =
  (token: string, email: string, inviteId: string): AppThunk =>
    async dispatch => {
      try {
        await revokeAccountUser(token, email, inviteId);
        dispatch(revokeAccountUserSuccess({ id: inviteId }));
      } catch (err) {
        // @ts-ignore
        dispatch(revokeAccountUserFailure({ message: err.message }));
      }
    };

type SendUserInvitesFromWelcomeFlowProps = {
  token: string;
  email: string;
  accountId: string;
  invitedEmails: string[];
  callback: (error?: Error) => void;
};

export const sendUserInvitesFromWelcomeFlow =
  ({
    token,
    email: currentUserEmail,
    accountId,
    invitedEmails,
    callback,
  }: SendUserInvitesFromWelcomeFlowProps): AppThunk =>
    async dispatch => {
      window.analytics.track('User sent invitation to user', {
        invited_user_count: invitedEmails.length,
        account_id: accountId,
      });
      dispatch(sendUserInvitesFromWelcomeFlowStart());

      try {
        const promises = invitedEmails.map(async invitedUserEmail => {
          // console.log('Invite sent: ', invitedUserEmail);

          const invite = await sendNewInvitation(
            token,
            accountId,
            currentUserEmail,
            invitedUserEmail,
          );

          return invite;
        });

        await Promise.all(promises);

        dispatch(sendUserInvitesFromWelcomeFlowSuccess());
        callback?.();
      } catch (error) {
        console.error(error);
        dispatch(sendUserInvitesFromWelcomeFlowFailure());
        // @ts-ignore
        callback?.(error);
      }
    };

type ChangePreferenceUserSubscribedEmailUpdatesProps = {
  token: string;
  isSubscribed: boolean;
  email: string;
};

export const changePreferenceUserSubscribedEmailUpdates =
  ({
    token,
    isSubscribed,
    email,
  }: ChangePreferenceUserSubscribedEmailUpdatesProps): AppThunk =>
    async () => {
      window.analytics.track('Update users email updates preference', {
        subscribe_preference: isSubscribed,
      });

      try {
        await postUserEmailSubscriptionPreference(token, isSubscribed, email);
      } catch (err) {
        console.error(err);
      }
    };

type AcceptAccountInvitationProps = {
  token: string;
  inviteToken: string;
  email: string;
};

export const saveUpdateLabelColor =
  (
    token: string,
    colorName: string,
    color: AccountLabelColor,
    colorId?: string,
  ): AppThunk =>
    async (dispatch, getState) => {
      dispatch(
        saveUpdateLabelColorStart({
          colorName,
          colorId,
          color,
        }),
      );

      try {
        const response = colorId
          ? await postCreateLabelColor(token, colorName, color)
          : await postUpdateLabelColor(token, colorName, color);

        dispatch(
          saveUpdateLabelColorSuccess({
            newLabel: {
              id: response.id,
              label_options: response.label_options,
              label_type: response.label_type,
              label_value: response.label_value,
            },
          }),
        );
      } catch (error) {
        dispatch(saveUpdateLabelColorFailure({}));
      }
    };

type RemoveTeamMemberFromOrganizationParams = {
  accountUserId: string;
  userId: string;
  email: string;
  token: string;
};

export const removeTeamMemberFromOrganization =
  ({
    accountUserId,
    userId,
    email,
    token,
  }: RemoveTeamMemberFromOrganizationParams): AppThunk =>
    async (dispatch, _getState) => {
      window.analytics.track('Admin: remove team member from organization', {
        account_id: accountUserId,
        email,
      });

      dispatch(removeTeamMemberFromOrganizationPending());

      try {
        await postRemoveTeamUserFromOrganization({ accountUserId, token });

        dispatch(removeTeamMemberFromOrganizationSuccess({ accountUserId, userId }));
      } catch (err) {
        // assume the api fail response has a message string
        // @ts-ignore
        dispatch(removeTeamMemberFromOrganizationFailure({ message: err.message }));
      }
    };

type UpdateTeamMemberRoleParams = {
  accountUserId: string;
  email: string;
  newRole: string;
  token: string;
};

export const updateTeamMemberRole =
  ({ accountUserId, email, newRole, token }: UpdateTeamMemberRoleParams): AppThunk =>
    async (dispatch, _getState) => {
      window.analytics.track('Admin: update team member role', {
        account_id: accountUserId,
        email,
        role: newRole,
      });

      try {
        const response = await postUpdateTeamMemberRole({ accountUserId, newRole, token });
        if (response.status !== 'success') {
          throw new Error(response.message);
        }

        dispatch(updateTeamMemberRoleSuccess({ accountUserId, newRole }));
      } catch (err) {
        dispatch(updateTeamMemberRoleFailure({ message: (err as Error).message }));
      }
    };
