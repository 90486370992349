import InputModal from 'features/Dashboard/common/InputModal';
import {createUploadFolder} from 'features/userLibrarySlice';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useDashboard} from '../DashboardContext';
import {useDispatch} from 'react-redux';
import {useState} from 'react';

export const NewFolderModal = () => {
  const {getAccessTokenSilently, user} = useAuthAlt();
  const parentFolderId = null;
  const {dispatch: dashboardDispatch, state} = useDashboard();
  const closeModal = () => {
    dashboardDispatch({
      type: 'close new folder modal',
    });
  };
  const dispatch = useDispatch();
  const [newFolderName, setNewFolderName] = useState('Untitled folder');

  const handleSubmitFolder = () => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(
          createUploadFolder({
            token,
            email: user?.email as string,
            newFolderName,
            parentFolderId,
          }),
        );
        closeModal();
      } catch (error) {
        console.error('error', error);
      }
    })();
  };

  return (
    <InputModal
      open={state.isNewFolderOpen}
      onClose={closeModal}
      title={'New folder'}
      defaultPlaceholder={'Untitled folder'}
      inputOnChange={setNewFolderName}
      primaryAction={handleSubmitFolder}
      secondaryAction={closeModal}
      primaryActionText={'Create'}
      secondaryActionText={'Cancel'}
    />
  );
};
