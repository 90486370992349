import {CaptionItemStyle, ItemWithSource} from 'features/types/canvasItemsSlice';

import LayerCaptions from './LayerUploadCaptions';
import {useWordFrames} from 'features/Captions/WordFrames';

export default function LayerCaptionsWrap({
  projectId,
  itemId,
  width,
  height,
  lineHeight,
  fontSize,
  item,
}: {
  fontSize: string;
  height: number;
  itemId: string;
  item: ItemWithSource;
  lineHeight: number;
  projectId: string;
  width: number;
}) {
  const {wordFrames, fontFamily} = useWordFrames({itemId, item, projectId});
  const style = item.style as CaptionItemStyle;

  return (
    <div className="relative m-auto" style={{height: `${height}px`, width: `${width}px`}}>
      <LayerCaptions
        sceneFrames={wordFrames || []}
        style={style}
        width={width}
        height={height}
        lineHeight={lineHeight}
        fontFamily={fontFamily}
        fontSize={fontSize}
      />
    </div>
  );
}
