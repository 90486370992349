import {DashParams, routePaths} from 'routes/routesHelper';
import {
  isAudioMedia,
  isPlayableMedia,
} from 'features/Dashboard/DashboardUploadDetails/utils';
import {useEffect, useRef, useState} from 'react';

import {Link} from 'react-router-dom';
import ReactPlayer from 'vendor/react-player-milk';
import {UploadTranscriptListElement} from './UploadTranscriptListElement';
import {UploadTranscriptListItem} from '../SearchResults';
import cn from 'classnames';
import {useSearchResults} from '../useSearchResults';

export const UploadTranscriptParent = ({
  uploadClip,
}: {
  uploadClip: UploadTranscriptListItem;
}) => {
  // console.log('Warning UploadTranscriptParent is loaded and not ready');
  const {
    state: {clipIdPlaying},
    dispatch: searchResultsDispatch,
  } = useSearchResults();

  const [activePlaybackId, setActivePlaybackId] = useState(
    uploadClip.upload.mux_playback_id,
  );

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [shouldShowAll, setShouldShowAll] = useState<boolean>(false);

  const videoRef = useRef<any>(null);
  const [isVideoReady, setIsVideoReady] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      searchResultsDispatch({
        type: 'addNewVideoPlayerReference',
        payload: {
          videoRef: videoRef.current,
        },
      });
    }
    return () => {
      if (videoRef.current) {
        searchResultsDispatch({
          type: 'removeVideoPlayerReference',
          payload: {
            videoRef: videoRef.current,
          },
        });
      }
    };
  }, [videoRef]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.pause) {
      setIsPlaying(false);
      videoRef.current.pause();
    }
  }, [clipIdPlaying]);

  const handlePlayerClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  const shouldShowHour = !!(
    uploadClip.upload.duration && uploadClip.upload.duration >= 60
  );

  const uploadClipToDisplay = 3;

  const uploadClipList =
    !shouldShowAll && uploadClip.uploadSecondaryItems.length > uploadClipToDisplay
      ? uploadClip.uploadSecondaryItems.slice(0, uploadClipToDisplay)
      : uploadClip.uploadSecondaryItems;

  const fileType = uploadClip.upload.file_type;
  const isAudio = isAudioMedia(fileType);

  return (
    <li className="pt-2">
      <div className="grid grid-cols-5 gap-2">
        <div className="col-span-2 md:col-span-2">
          <div className="flex items-start justify-start p-3">
            <div
              onClick={handlePlayerClick}
              className={cn('cursor-pointer overflow-hidden', {
                'rounded-md shadow-md ': !isAudio,
              })}
            >
              <ReactPlayer
                onReady={() => {
                  setIsVideoReady(true);
                }}
                ref={videoRef}
                playing={isPlaying}
                url={`https://stream.mux.com/${activePlaybackId}.m3u8`}
                width="100%"
                height="100%"
                className={'h-full w-full select-none'}
                controls
              />
            </div>
          </div>
        </div>
        <div className="col-span-3 space-y-3 py-3 md:col-span-3">
          <Link
            onClick={() => {
              window.analytics.track('User navigated to video page from search result');
            }}
            to={`/${routePaths.dashboard}/${DashParams.UploadsParam}/${
              isPlayableMedia(fileType) ? 'media' : ''
            }/${uploadClip.upload.id}`}
          >
            <h3 className="mt-1 truncate px-3 text-sm font-medium text-gray-900 hover:opacity-75">
              {uploadClip.upload.file_name}
            </h3>
          </Link>
          <ul className="flex flex-col divide-y divide-gray-200">
            {uploadClipList.map((clip, index) => {
              return (
                <UploadTranscriptListElement
                  shouldShowHour={shouldShowHour}
                  videoRef={videoRef}
                  key={`${index}`}
                  clip={clip}
                  // uploadMediaClip={uploadMediaClip}
                  setIsPlaying={setIsPlaying}
                />
              );
            })}
            {!shouldShowAll &&
              uploadClip.uploadSecondaryItems.length > uploadClipToDisplay && (
                <li className="w-full bg-white py-2 px-2">
                  <div className="relative w-full">
                    <button
                      onClick={() => {
                        setShouldShowAll(true);
                      }}
                      type="button"
                      className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Show all
                    </button>
                  </div>
                </li>
              )}
          </ul>
        </div>
      </div>
    </li>
  );
};
