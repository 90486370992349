import {BASE_REDIRECT_URL} from 'constants/environment';
import {useEffect} from 'react';
import {Outlet, useParams} from 'react-router-dom';

export const PreviewFontPageWrap = ({ignoreRedirect}: {ignoreRedirect?: boolean}) => {
  const {accountFontUploadId} = useParams() as Record<string, string>;

  useEffect(() => {
    if (!accountFontUploadId && !ignoreRedirect) {
      window.location.href = BASE_REDIRECT_URL;
    }
  }, [accountFontUploadId]);

  return <Outlet />;
};
