import {Navigate, Route, Routes} from 'react-router-dom';

import {CanvasPageWrap} from 'pages/Canvas/CanvasPageWrap';
import {EditorCanvasPage} from 'pages/Canvas/EditorCanvasPage';

/**
 * Routes under /canvas/*
 */
export const RoutesCanvasRelated = () => {
  return (
    <Routes>
      <Route path="/" element={<CanvasPageWrap />}>
        {/* <Route index element={<Navigate to="/dashboard" />} /> */}
        <Route path=":projectId" element={<EditorCanvasPage />} />
        {/* <Route path={`*`} element={<Navigate to="/dashboard" />} /> */}
      </Route>
    </Routes>
  );
};
