import {ChevronLeft, Folder, FolderPlus, MoreVertical} from 'react-feather';
import {DashParams, routePaths} from 'routes/routesHelper';
import {Dialog, Menu, Transition} from '@headlessui/react';
import {ExclamationIcon, XIcon} from '@heroicons/react/outline';
import {Navigate, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDeleteUserUploadMutation, useUserUploadQuery} from 'services/userUploadAPI';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useLayoutEffect, useState} from 'react';
import {
  useMoveUserUploadsFolderMutation,
  useUserUploadFoldersQuery,
} from 'services/userUploadFolderAPI';

import ClickAwayListener from 'react-click-away-listener';
import {FileNavContainer} from './FileNavProvider';
import FileNavRenameModal from './FileNavRenameModal';
import {Fragment} from 'react';
import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import {NotificationInfo} from 'features/Notifications/NotificationInfo';
import {authUserSelector} from 'features/selectors/authSelectors';
import classNames from 'classnames';
import {store} from 'react-notifications-component';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useFileNavActiveUpload} from './FileNavActiveUploadProvider';

export enum PositionDirection {
  UP = 'up',
  DOWN = 'down',
  DOWN_RIGHT = 'down-right',
}

export function FileNav({
  positionDirection = PositionDirection.UP,
  userUploadId,
}: {
  positionDirection?: PositionDirection;
  userUploadId: string;
}) {
  const {setCurrentFile} = FileNavContainer.useContainer();
  const {
    data: file,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  } = useUserUploadQuery({id: userUploadId!});

  useEffect(() => {
    if (!isLoading && !isFetching && file) {
      setCurrentFile(file);
    }
  }, [file, isLoading, isFetching]);

  if (isFetching || isLoading) {
    return <div />;
  }

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        {({open}) => (
          <FileNavMenu
            positionDirection={positionDirection}
            userUploadId={userUploadId}
            open={open}
          />
        )}
      </Menu>
    </>
  );
}

export const FileNavModals = () => {
  const {isRenameModelOpen, isDeleteModelOpen} = FileNavContainer.useContainer();

  if (isDeleteModelOpen) {
    return <FileNavDeleteModal />;
  }

  if (isRenameModelOpen) {
    return (
      <FileNavRenameModal
      // TODO (LENNY) UPDATE THIS
      />
    );
  }

  return <div />;
};

const FileNavDeleteModal = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {activeUploadId, isDeleteModelOpen, setIsDeleteModelOpen, fileName} =
    FileNavContainer.useContainer();

  const [deleteUserUpload, {isLoading: isUpdating}] = useDeleteUserUploadMutation();

  const deleteConfirm = (callback: any) => {
    window.analytics.track('User upload delete confirmation');
    deleteUserUpload(activeUploadId!);
    store.addNotification({
      ...NOTIFICATION_BASE,
      content: (
        <NotificationInfo
          title={'File deleted'}
          message={'File was successfully deleted.'}
        />
      ),
    });
    callback();

    if (
      location.pathname.includes(`/${routePaths.dashboard}/${DashParams.UploadsParam}`)
    ) {
      navigate(`/${routePaths.dashboard}/${DashParams.UploadsParam}`);
      window.analytics.track('User upload delete from uploads page');
    }
  };

  const closeDeleteModel = () => {
    window.analytics.track('User upload close delete model');
    setIsDeleteModelOpen(false);
  };
  return (
    <Transition.Root show={isDeleteModelOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={isDeleteModelOpen}
        onClose={setIsDeleteModelOpen}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={closeDeleteModel}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Are you sure?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      This will permanently delete &quot;
                      <b>{fileName}</b>.&quot; Any active collaborators will no longer be
                      able to access the file. This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => deleteConfirm(closeDeleteModel)}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={closeDeleteModel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const FileNavMenu = ({
  positionDirection,
  userUploadId,
  open,
}: {
  positionDirection: PositionDirection;
  userUploadId: string;
  open: boolean;
}) => {
  const {
    setActiveUploadId,
    isMoveOpen,
    setIsMoveOpen,
    setIsRenameModelOpen,
    setIsDeleteModelOpen,
  } = FileNavContainer.useContainer();

  const dispatch = useDispatch();

  const {state, dispatch: fileNavDispatch} = useFileNavActiveUpload();

  const setActiveFileNavUploadId = (userUploadId: string | null) => {
    fileNavDispatch({type: 'set active upload id', userUploadId});
  };
  const activeFileNavUploadId = state.userUploadId;

  useLayoutEffect(() => {
    if (open) {
      setActiveUploadId(userUploadId);
      setActiveFileNavUploadId(userUploadId);
    } else {
      if (activeFileNavUploadId === userUploadId) {
        setActiveFileNavUploadId(null);
      }
    }
  }, [open, activeFileNavUploadId, userUploadId]);

  const startRename = (evt: any) => {
    setIsRenameModelOpen(true);
    window.analytics.track('User upload start rename');
  };

  const startMoveUpload = (evt: any) => {
    window.analytics.track('User upload start move');
    evt.stopPropagation();
    setIsMoveOpen(true);
  };
  const [moveUserUpload] = useMoveUserUploadsFolderMutation();

  const handleMoveUpload = (folderId: string | null, bucketKey: string) => {
    setIsMoveOpen(false);
    (async () => {
      try {
        moveUserUpload({
          userUploadId: bucketKey,
          userUploadFolderId: folderId!,
        });
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const triggerDeleteFile = (evt: any) => {
    window.analytics.track('User upload delete clicked');
    evt.stopPropagation();
    setIsDeleteModelOpen(true);
  };

  const user = useSelector(authUserSelector);
  const {
    data: foldersData,
    isLoading,
    isFetching,
    isError,
  } = useUserUploadFoldersQuery({userId: user?.id ? user.id : ''});
  const {folderId} = useParams() as Record<string, string>;
  const activeFolder = foldersData?.folders.find(folder => folder.id === folderId);

  return (
    <>
      <div>
        <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open file nav</span>
          <MoreVertical className="h-5 w-5" />
        </Menu.Button>
      </div>

      <Transition
        show={open}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave=""
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          static
          className={classNames(
            'absolute mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            {
              'right-0  origin-top-right':
                positionDirection !== PositionDirection.DOWN_RIGHT,
              'left-0  origin-top-left':
                positionDirection === PositionDirection.DOWN_RIGHT,
            },
          )}
        >
          {isMoveOpen ? (
            <ClickAwayListener
              onClickAway={() => {
                setIsMoveOpen(false);
              }}
            >
              <div className="mt-1 w-full rounded-md bg-white shadow-lg">
                <ul
                  tabIndex={-1}
                  role="listbox"
                  aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-item-3"
                  className="max-h-60 overflow-auto rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  <button
                    key={`upload-folder-back`}
                    onClick={(evt: any) => {
                      evt.stopPropagation();
                      setIsMoveOpen(false);
                    }}
                    className="flex w-full flex-row items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                    role="menuitem"
                  >
                    <ChevronLeft className="w-4 transform" />
                    <span className="ml-2">Back</span>
                  </button>
                  {/* If there is an active folder, then we ARE NOT in the "home" folder -- show home option */}
                  {activeFolder && (
                    <button
                      key={`upload-folder-home`}
                      onClick={(evt: any) => {
                        evt.stopPropagation();
                        handleMoveUpload(null, userUploadId);
                      }}
                      className="flex w-full flex-row items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                      role="menuitem"
                    >
                      <Folder className="w-4 transform" />
                      <span className="ml-2">Home</span>
                    </button>
                  )}
                  {foldersData?.folders.map((folder, key) => (
                    <button
                      key={`upload-folder-${key}`}
                      onClick={(evt: any) => {
                        evt.stopPropagation();
                        handleMoveUpload(folder.id, userUploadId);
                      }}
                      className="flex w-full flex-row items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                      role="menuitem"
                    >
                      <Folder className="w-4 transform" />
                      <span className="ml-2">{folder.name}</span>
                    </button>
                  ))}
                </ul>
              </div>
            </ClickAwayListener>
          ) : (
            <div className="py-1">
              <div className="py-1">
                <button
                  onClick={startMoveUpload}
                  className="flex w-full flex-row items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                  role="menuitem"
                >
                  <FolderPlus className="w-4 transform" />
                  <span className="ml-2">Move</span>
                </button>

                <button
                  onClick={startRename}
                  className="flex w-full flex-row items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                  role="menuitem"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7 5.5C14.1 5.1 14.1 4.5 13.7 4.1L11.9 2.3C11.5 1.9 10.9 1.9 10.5 2.3L3 9.8V13H6.2L13.7 5.5ZM11.2 3L13 4.8L11.5 6.3L9.7 4.5L11.2 3ZM4 12V10.2L9 5.2L10.8 7L5.8 12H4Z"
                      fill="black"
                    />
                  </svg>
                  <span className="ml-2">Rename</span>
                </button>

                {/* <a
                  onClick={e => e.stopPropagation()}
                  href={`${BASE_SHARE_URL}/a/${currentFile.id}`}
                  target="_blank"
                  className="flex w-full flex-row items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                  role="menuitem"
                  rel="noreferrer"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.9578 3.34278C12.93 3.27592 12.8895 3.21514 12.8383 3.16389L12.8361 3.16167C12.7321 3.058 12.5913 2.99985 12.4444 3H9.11111C8.96377 3 8.82246 3.05853 8.71827 3.16272C8.61409 3.26691 8.55556 3.40821 8.55556 3.55556C8.55556 3.7029 8.61409 3.84421 8.71827 3.94839C8.82246 4.05258 8.96377 4.11111 9.11111 4.11111H11.1033L7.05167 8.16278C6.99861 8.21403 6.95628 8.27533 6.92717 8.34311C6.89805 8.41089 6.88272 8.48379 6.88208 8.55756C6.88144 8.63132 6.8955 8.70448 6.92343 8.77275C6.95137 8.84103 6.99262 8.90306 7.04478 8.95522C7.09694 9.00738 7.15897 9.04863 7.22725 9.07657C7.29552 9.1045 7.36868 9.11856 7.44244 9.11792C7.51621 9.11728 7.58911 9.10195 7.65689 9.07283C7.72467 9.04372 7.78597 9.00139 7.83722 8.94833L11.8889 4.89667V6.88889C11.8889 7.03623 11.9474 7.17754 12.0516 7.28173C12.1558 7.38591 12.2971 7.44444 12.4444 7.44444C12.5918 7.44444 12.7331 7.38591 12.8373 7.28173C12.9415 7.17754 13 7.03623 13 6.88889V3.55556V3.55389C13 3.48167 12.9856 3.41056 12.9578 3.34278ZM3 5.77778C3 5.04107 3.29266 4.33453 3.81359 3.81359C4.33453 3.29266 5.04107 3 5.77778 3H6.33333C6.48068 3 6.62198 3.05853 6.72617 3.16272C6.83036 3.26691 6.88889 3.40821 6.88889 3.55556C6.88889 3.7029 6.83036 3.84421 6.72617 3.94839C6.62198 4.05258 6.48068 4.11111 6.33333 4.11111H5.77778C5.33575 4.11111 4.91183 4.28671 4.59927 4.59927C4.28671 4.91183 4.11111 5.33575 4.11111 5.77778V10.2222C4.11111 10.6643 4.28671 11.0882 4.59927 11.4007C4.91183 11.7133 5.33575 11.8889 5.77778 11.8889H10.2222C10.6642 11.8889 11.0882 11.7133 11.4007 11.4007C11.7133 11.0882 11.8889 10.6643 11.8889 10.2222V9.66667C11.8889 9.51932 11.9474 9.37802 12.0516 9.27383C12.1558 9.16964 12.2971 9.11111 12.4444 9.11111C12.5918 9.11111 12.7331 9.16964 12.8373 9.27383C12.9415 9.37802 13 9.51932 13 9.66667V10.2222C13 10.9589 12.7073 11.6655 12.1864 12.1864C11.6655 12.7073 10.9589 13 10.2222 13H5.77778C5.04107 13 4.33453 12.7073 3.81359 12.1864C3.29266 11.6655 3 10.9589 3 10.2222V5.77778Z"
                      fill="black"
                    />
                  </svg>
                  <span className="ml-2">Share</span>
                </a> */}
              </div>
              <div className="border-t border-gray-100" />
              <div className="py-1">
                <button
                  onClick={triggerDeleteFile}
                  className="flex w-full flex-row items-center px-4 py-2 text-left text-sm leading-5 text-red-600 hover:bg-red-50 hover:text-red-700 focus:bg-red-100 focus:text-red-600 focus:outline-none"
                  role="menuitem"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6 6H7V12H6V6Z" fill="#e02424" />
                    <path d="M9 6H10V12H9V6Z" fill="#e02424" />
                    <path
                      d="M2 3V4H3V14C3 14.2652 3.10536 14.5196 3.29289 14.7071C3.48043 14.8946 3.73478 15 4 15H12C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14V4H14V3H2ZM4 14V4H12V14H4Z"
                      fill="#e02424"
                    />
                    <path d="M6 1H10V2H6V1Z" fill="#e02424" />
                  </svg>
                  <span className="ml-2">Delete</span>
                </button>
              </div>
            </div>
          )}
        </Menu.Items>
      </Transition>
    </>
  );
};
