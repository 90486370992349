import {createContext, FC, useContext} from 'react';

const DurationContext = createContext(0);

export const DurationProvider: FC<{durationMs: number}> = ({durationMs, children}) => {
  return (
    <DurationContext.Provider value={durationMs}>{children}</DurationContext.Provider>
  );
};

export const useDuration = () => {
  return useContext(DurationContext);
};
