import {createContainer} from 'unstated-next';
import {useMemo, useState} from 'react';

function useSelectionFocusContainer(initialState = 0) {
  const [isSidebarSelected, setIsSidebarSelected] = useState<boolean>(false);
  const [isCanvasItemSelected, setIsCanvasItemSelected] = useState<boolean>(false);
  const [isTextFieldActive, setIsTextFieldActive] = useState<boolean>(false); // text input in sidebar
  const [isItemLayerTextActive, setIsItemLayerTextActive] = useState<boolean>(false); // text input on canvas

  const shouldMoveCanvasItem = useMemo(() => {
    let shouldMove = true;

    if (isSidebarSelected || isTextFieldActive || isItemLayerTextActive) {
      shouldMove = false;
    }

    return shouldMove;
  }, [isSidebarSelected, isCanvasItemSelected, isTextFieldActive, isItemLayerTextActive]);

  return {
    isSidebarSelected,
    isCanvasItemSelected,
    isTextFieldActive,
    setIsSidebarSelected,
    setIsCanvasItemSelected,
    setIsTextFieldActive,
    setIsItemLayerTextActive,
    shouldMoveCanvasItem,
  };
}

const SelectionFocusContainer = createContainer(useSelectionFocusContainer);

export const SelectionFocusProvider = SelectionFocusContainer.Provider;
export const useSelectionFocus = SelectionFocusContainer.useContainer;
