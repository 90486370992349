import {CanvasItem, ParsedCrop} from 'features/types/canvasItemsSlice';
import Moveable, {OnDragStart} from 'react-moveable';
import {RefObject, useCallback, useRef, useState} from 'react';
import {cropParseFloat, getOriginalBox} from '../AppCanvas/Canvas/utils/useEditCrop';
import {useOnMessage, useSendMessage} from 'features/Common/PostMessage';

import {AbsoluteBox} from 'features/EditorCanvas/Layout/Box';
import {createContainer} from 'unstated-next';
import {updateCanvasEditMode} from 'features/canvasStateSlice';
import {updateItems} from 'features/canvasItemsSlice';
import {useDispatch} from 'react-redux';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useRecoilValue} from 'recoil';
import {zoomState} from '../AppCanvas/ZoomToolbar';

const toFloat = (value: number | string) => {
  return typeof value === 'string' ? parseFloat(value) : value;
};

const InverseCrop = createContainer(() => {
  const projectId = useProjectId();
  const dispatch = useDispatch();

  const [state, setState] = useState<{crop: ParsedCrop; itemId: string} | null>(null);

  const start = useCallback(
    (item: CanvasItem, itemId: string) => {
      setState({crop: cropParseFloat(item.crop), itemId});
      dispatch(updateCanvasEditMode({canvasEditMode: 'crop'}));
    },
    [dispatch, projectId],
  );

  const save = () => {
    if (!state) return;

    setState(null);

    dispatch(
      updateItems({
        projectId,
        items: {
          [state.itemId]: {crop: state.crop},
        },
      }),
    );

    dispatch(updateCanvasEditMode({canvasEditMode: null}));
  };

  const sendMessage = useSendMessage(window.parent);

  return {
    active: state,
    update: (crop: ParsedCrop) => {
      if (!state) return;
      setState({...state, crop});
      sendMessage({channel: 'preview-template-crop', message: 'on-change', data: {crop}});
    },
    start,
    save,
    activeCropFor: (id: string) => state?.itemId === id && state.crop,
  };
});

export const InverseCropProvider = InverseCrop.Provider;
export const useInverseCrop = InverseCrop.useContainer;

export const getCropForFullBox = ({
  fullBox,
  cropBox,
  type,
}: {
  fullBox: AbsoluteBox;
  cropBox: AbsoluteBox;
  type: 'circle' | 'rectangle';
}): ParsedCrop => {
  const cropDimension = cropBox.dimension;
  const cropPosition = cropBox.position;

  const insetTopPx = cropPosition.top - fullBox.position.top;
  const insetBottomPx = fullBox.dimension.height - (insetTopPx + cropDimension.height);
  const insetLeftPx = cropPosition.left - fullBox.position.left;
  const insetRightPx = fullBox.dimension.width - (insetLeftPx + cropDimension.width);

  if (type === 'rectangle') {
    const inset = {
      top: insetTopPx / fullBox.dimension.height,
      bottom: insetBottomPx / fullBox.dimension.height,
      left: insetLeftPx / fullBox.dimension.width,
      right: insetRightPx / fullBox.dimension.width,
    };

    return {
      type: 'rectangle',
      ...inset,
    };
  } else {
    const avgDimension =
      Math.sqrt(fullBox.dimension.width ** 2 + fullBox.dimension.height ** 2) /
      Math.sqrt(2);

    const radiusPx = cropBox.dimension.width / 2;
    const radius = radiusPx / avgDimension;

    const centerX = (insetLeftPx + radiusPx) / fullBox.dimension.width;
    const centerY = (insetTopPx + radiusPx) / fullBox.dimension.height;

    const originalAspectRatio = fullBox.dimension.width / fullBox.dimension.height;

    return {
      type: 'circle',
      centerX,
      centerY,
      radius,
      originalAspectRatio,
    };
  }
};

const scaleAmount = 0.15;

export const InverseCropMoveable = ({
  item,
  itemId,
  target,
}: {
  item: CanvasItem;
  itemId: string;
  target: RefObject<HTMLDivElement>;
}) => {
  const zoom = useRecoilValue(zoomState);

  const position = [toFloat(item.position.left), toFloat(item.position.top)];

  const setPosition = (event: OnDragStart) => {
    event.set(position);
  };

  const {active, update} = useInverseCrop();

  const moveable = useRef<Moveable>(null);

  useOnMessage(event => {
    if (event.message === 'scale') {
      if (!active) return;

      const fullBox = getOriginalBox({
        crop: active.crop,
        croppedDimension: item.dimension,
        croppedPosition: item.position,
      });

      const oldDimension = {...fullBox.dimension};

      const newDimension = {...fullBox.dimension};

      if (event.data.direction === 'larger') {
        newDimension.width *= 1 + scaleAmount;
        newDimension.height *= 1 + scaleAmount;
      } else {
        newDimension.width *= 1 - scaleAmount;
        newDimension.height *= 1 - scaleAmount;
      }

      const newPosition = {...fullBox.position};
      newPosition.left -= (newDimension.width - oldDimension.width) / 2;
      newPosition.top -= (newDimension.height - oldDimension.height) / 2;

      fullBox.dimension = newDimension;
      fullBox.position = newPosition;

      const newCrop = getCropForFullBox({
        fullBox,
        cropBox: item,
        type: active.crop.type,
      });

      update(newCrop);

      setTimeout(() => {
        moveable.current?.updateRect();
      });
    }
  }, 'preview-template-crop');

  return (
    <Moveable
      ref={moveable}
      target={target}
      draggable
      resizable
      origin={false}
      zoom={(1 / zoom) * 0.8}
      keepRatio
      onDragStart={event => {
        setPosition(event);
      }}
      onDrag={event => {
        if (!active) return;

        const fullBox = getOriginalBox({
          crop: active.crop,
          croppedDimension: item.dimension,
          croppedPosition: {
            left: item.position.left + event.delta[0],
            top: item.position.top + event.delta[1],
          },
        });

        const newCrop = getCropForFullBox({
          fullBox,
          cropBox: item,
          type: active.crop.type,
        });

        update(newCrop);
      }}
      onResizeStart={event => {
        event.setOrigin(['%', '%']);

        if (event.dragStart) {
          setPosition(event.dragStart);
        }
      }}
      onResize={event => {
        if (!active) return;

        const fullBox = getOriginalBox({
          crop: active.crop,
          croppedDimension: {
            width: item.dimension.width,
            height: item.dimension.height,
          },
          croppedPosition: {
            left: item.position.left + event.drag.delta[0],
            top: item.position.top + event.drag.delta[1],
          },
        });

        fullBox.dimension.width += event.delta[0];
        fullBox.dimension.height += event.delta[1];

        const newCrop = getCropForFullBox({
          fullBox,
          cropBox: item,
          type: active.crop.type,
        });

        update(newCrop);
      }}
      // passDragArea
    />
  );
};
