import {CanvasItem, DimensionType, PayloadWithId} from './types/canvasItemsSlice';
import {UploadMediaClipType, uploadMediaClipAPI} from 'services/uploadMediaClipAPI';
import {UserUpload, userUploadAPI} from 'services/userUploadAPI';

import {AppThunk} from 'configureStore';
import {OrientationType} from './Dashboard/DashboardUploadDetails/PlayableMedia/TemplateSelectionModal/TemplateSelectionPopup';
import {SAMPLE_CAPTION} from './EditorCanvas/constants/ItemConstants';
import {SceneLayoutId} from './EditorCanvas/Layout/layouts';
import {baseApi} from 'services/baseAPI';
import {createSlice} from '@reduxjs/toolkit';
import {defaultStyles} from 'services/recentUsageAPI';
import {getVideoItemFromClip} from './canvasItemsSlice';
import {layoutAndReturnItems} from './EditorCanvas/Layout/utils';
import {sceneFrameSelector} from './selectors/sceneFrameSelectors';

export interface SceneFrameSliceProps {
  canvasDimensions: DimensionType;
  backgroundColor: {hex: string};
  preview?: {
    items: Record<string, CanvasItem>;
  };
}

export const DEFAULT_DIMENSION: Record<OrientationType, DimensionType> = {
  landscape: {width: 1200, height: 600},
  square: {width: 700, height: 700},
};

export const BASE_SCENE_FRAME_STATE: SceneFrameSliceProps = {
  canvasDimensions: {
    width: 1200,
    height: 600,
  },
  backgroundColor: {hex: defaultStyles.canvas_background},
};

export interface SceneFrameProps {
  projects: Record<string, SceneFrameSliceProps>;
}

export const initialCanvasState = {projects: {}} as SceneFrameProps;

const sceneFrameSlice = createSlice({
  name: 'sceneFrame',
  initialState: initialCanvasState,
  reducers: {
    updateCanvasDimensions(
      state,
      action: PayloadWithId<{canvasDimensions: Partial<DimensionType>}>,
    ) {
      const {projectId, canvasDimensions} = action.payload;
      if (!state.projects[projectId]) {
        state.projects[projectId] = {...BASE_SCENE_FRAME_STATE};
      }
      state.projects[projectId].canvasDimensions = {
        ...state.projects[projectId].canvasDimensions,
        ...canvasDimensions,
      };
    },
    replaceDataSceneFrame(state, action) {
      const {projectId, sceneFrame} = action.payload;
      if (!state.projects[projectId]) {
        state.projects[projectId] = {...BASE_SCENE_FRAME_STATE};
      }
      if (sceneFrame) {
        const {backgroundColor, canvasDimensions} = sceneFrame;
        state.projects[projectId].backgroundColor = backgroundColor;
        state.projects[projectId].canvasDimensions = canvasDimensions;
      }
    },
    updateBackgroundColor(state, action) {
      const {projectId, color} = action.payload;
      if (!state.projects[projectId]) {
        state.projects[projectId] = {...BASE_SCENE_FRAME_STATE};
      }
      state.projects[projectId].backgroundColor = color;
    },
    updateSceneFrame(state, action) {
      const {projectId, sceneFrame} = action.payload;
      if (!state.projects[projectId]) {
        state.projects[projectId] = {...BASE_SCENE_FRAME_STATE};
      }
      state.projects[projectId] = {...BASE_SCENE_FRAME_STATE, ...sceneFrame};
    },
    resetSceneFrame(state, action) {
      const {projectId} = action.payload;
      if (!state.projects[projectId]) {
        state.projects[projectId] = {...BASE_SCENE_FRAME_STATE};
      }
      state.projects[projectId].canvasDimensions = {
        width: 1200,
        height: 600,
      };
      state.projects[projectId].backgroundColor = {hex: '#fff'};
    },
    setPreview(state, action: PayloadWithId<{items: Record<string, CanvasItem>}>) {
      const {items, projectId} = action.payload;
      state.projects[projectId].preview = {items};
    },
    clearPreview(state, action: PayloadWithId) {
      const {projectId} = action.payload;
      state.projects[projectId].preview = undefined;
    },
  },
});

export const {
  replaceDataSceneFrame,
  resetSceneFrame,
  updateBackgroundColor,
  updateCanvasDimensions,
  updateSceneFrame,
  clearPreview,
  setPreview,
} = sceneFrameSlice.actions;

export const showPreview =
  ({
    layout,
    uploadMediaClip,
    userUpload,
    projectId,
    sceneId,
  }: {
    layout: SceneLayoutId;
    uploadMediaClip: UploadMediaClipType;
    userUpload: UserUpload;
    projectId: string;
    sceneId: string;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const {canvasDimensions} = sceneFrameSelector(state)(projectId);

    const videoItem = getVideoItemFromClip({
      userUpload,
      uploadMediaClip,
      canvasDimensions,
      sceneId,
    });

    const captionItem = {
      ...SAMPLE_CAPTION,
      sceneId,
    };

    const items = layoutAndReturnItems({
      withinContainer: {dimension: canvasDimensions},
      id: layout,
      items: {videoItem, captionItem},
    });

    dispatch(sceneFrameSlice.actions.setPreview({projectId, items}));
  };

export default sceneFrameSlice.reducer;
