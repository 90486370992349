import {DashParams, routePaths} from 'routes/routesHelper';
import {Link, useParams} from 'react-router-dom';
import {MinusSmIcon, PlusSmIcon} from '@heroicons/react/solid';
import {useFetchCmsTemplatesQuery, useFetchTemplatesQuery} from 'services/templatesAPI';

import {ChevronLeft} from 'react-feather';
import {Disclosure} from '@headlessui/react';
import MilkLogo from 'features/Dashboard/shared/MilkLogo';
import ReactTooltip from 'react-tooltip';
import TemplatesGrid from '../ContentTemplatesBrowser/TemplatesGrid';
import classNames from 'classnames';
import {convertSanityEntryToTemplate} from 'features/SanityCms/helpers';
import {homeNav} from 'features/Dashboard/DashboardWrapWithSidebar';
import {sanityImage} from 'features/SanityCms/sanityClient';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';

const filters = [
  {
    open: true,
    id: 'webinar',
    name: 'Webinar',
    options: [
      {value: 'white', label: 'LinkedIn post', checked: false},
      {value: 'beige', label: 'Instagram ad', checked: false},
      {value: 'blue', label: 'Twitter post', checked: true},
      {value: 'brown', label: 'Email embed', checked: false},
      {value: 'green', label: 'YouTube short', checked: false},
    ],
  },
  {
    open: false,
    id: 'testimonial',
    name: 'Testimonial',
    options: [
      {value: 'white', label: 'LinkedIn post', checked: false},
      {value: 'beige', label: 'Instagram ad', checked: false},
      {value: 'blue', label: 'Twitter post', checked: true},
      {value: 'brown', label: 'Email embed', checked: false},
      {value: 'green', label: 'YouTube short', checked: false},
    ],
  },
  {
    open: false,
    id: 'podcast',
    name: 'Podcast',
    options: [{value: 'audiogram', label: 'Audiogram', checked: false}],
  },
];

export const CampaignCreateContainer = () => {
  const {categoryId} = useParams() as Record<string, string>;
  const {data: cmsData} = useFetchCmsTemplatesQuery();
  const {data: milkApiTemplates} = useFetchTemplatesQuery();

  const isCmsTemplatesActive = useFeatureFlag('cms-templates');

  const milkTemplates =
    (isCmsTemplatesActive
      ? cmsData?.templates?.map(data => convertSanityEntryToTemplate(data))
      : milkApiTemplates) ?? [];

  const categories = [...(cmsData?.categories ?? [])];

  // Tag for filtering
  const heroCategory =
    categories.find(currCategory => currCategory.slug === categoryId) ?? categories[0];

  return (
    <main className="">
      <section aria-labelledby="create-campaigns" className="pt-5 pb-24">
        <div className="flex space-x-8">
          <div className="w-80">
            <div className="flex w-full flex-shrink-0">
              <div className="w-full space-y-2">
                <div className="relative flex text-left">
                  <div data-tip={'Back home'} className="flex w-full justify-between">
                    <Link
                      to={homeNav.href}
                      className="flex flex-row items-center space-x-1 px-3"
                    >
                      <ChevronLeft />
                      <MilkLogo />
                    </Link>
                    <ReactTooltip
                      place="bottom"
                      className="bg-gray-600 font-semibold"
                      effect="solid"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Filters */}
            <div className="block w-full">
              <h3 className="sr-only">Categories</h3>
              {filters.map(section => (
                <Disclosure
                  defaultOpen={section.open}
                  as="div"
                  key={section.id}
                  className="border-b border-gray-200 py-6"
                >
                  {({open}) => (
                    <>
                      <h3 className="-my-3 flow-root ">
                        <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 px-4 text-sm text-gray-400  hover:bg-gray-50  hover:text-gray-500 sm:px-6 lg:px-8">
                          <span className="text-xl font-medium text-gray-900">
                            {section.name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                            ) : (
                              <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-2">
                        <div className="space-y-1">
                          {section.options.map((option, optionIdx) => (
                            <div key={option.value} className="flex items-center">
                              <Link
                                to=""
                                id={`filter-${section.id}-${optionIdx}`}
                                className=" w-full py-3 px-4 pl-3 hover:bg-gray-50 sm:px-6 sm:pl-6  lg:block lg:px-8 lg:pl-12"
                              >
                                {option.label}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </div>
          <div className="flex flex-grow px-8">
            <div className="mx-auto grid max-w-7xl grid-cols-1 gap-y-10 gap-x-4 lg:grid-cols-3">
              {/* Product grid */}
              <div className="grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:col-span-3 lg:gap-x-8">
                <main className="flex-1 focus:outline-none" tabIndex={0}>
                  <div className="relative mx-auto max-w-6xl md:px-8 xl:px-0">
                    <div className="pb-16">
                      <div className="relative mx-auto grid w-full max-w-7xl grid-cols-2 items-center gap-6 text-left">
                        <div className="mt-6 sm:max-w-xl">
                          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                            {heroCategory?.headline}
                          </h1>
                          <p className="mt-6 text-xl text-gray-500">
                            {heroCategory?.description}
                          </p>
                        </div>

                        <div className="relative h-full max-h-[350px] w-full">
                          {heroCategory?.image ? (
                            <img
                              className="h-full w-full rounded-lg  object-cover"
                              src={sanityImage(heroCategory.image).url()}
                              alt=""
                            />
                          ) : null}
                        </div>
                      </div>
                      <nav
                        className="flex justify-center space-x-6 py-2"
                        aria-label="Template Categories"
                      >
                        {[{title: 'All', slug: ''}, ...categories].map((tag, i) => {
                          return (
                            <Link
                              key={i}
                              to={`/${routePaths.dashboard}/${DashParams.TemplatesPageParam}/category/${tag.slug}`}
                              className={classNames(
                                'inline-flex items-center rounded-md py-2 px-3 text-sm font-medium',
                                window.location.pathname ===
                                  `/${routePaths.dashboard}/${DashParams.TemplatesPageParam}/category/${tag.slug}`
                                  ? 'pointer-events-none bg-indigo-50 text-indigo-600'
                                  : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                              )}
                            >
                              {tag.title}
                            </Link>
                          );
                        })}
                      </nav>
                      <div className="space-y-8 px-4 pt-7 sm:px-6 md:px-0">
                        <TemplatesGrid
                          templates={milkTemplates}
                          activeCategory={categoryId}
                        />
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
