export enum ItemLayerSources {
  Uploads = 'uploads',
  Clips = 'clips',
}

export enum ViewTypes {
  SmartComponent = 'smart-component',
  Square = 'square',
  Text = 'text',
  Video = 'video',
  CaptionClip = 'caption-clip',
  VideoClip = 'video-clip',
  AudioClip = 'audio-clip',
  Image = 'image',
  Caption = 'caption',
}

export enum ViewTypesMedia {
  Video = ViewTypes.Video,
  CaptionClip = ViewTypes.CaptionClip,
  VideoClip = ViewTypes.VideoClip,
  AudioClip = ViewTypes.AudioClip,
  Caption = ViewTypes.Caption,
}

export const PlayableContainingAudioMedia = [
  ViewTypes.Video,
  ViewTypes.VideoClip,
  ViewTypes.AudioClip,
] as const;

export const playTimeMediaTypes = [
  ViewTypes.Caption,
  ViewTypes.CaptionClip,
  ViewTypes.Video,
  ViewTypes.VideoClip,
  ViewTypes.AudioClip,
] as const;

export const captionBasedItemTypes = [ViewTypes.Caption, ViewTypes.CaptionClip];

export const textBasedItemTypes = [...captionBasedItemTypes, ViewTypes.Text];

export const NOT_USER_RESIZEABLE: ViewTypes[] = [ViewTypes.Text];
