import {BASE_URL} from 'constants/environment';
import {ClientStateType} from 'features/serverSyncSlice';
import {ProjectSyncHistoryItem} from 'features/EditorCanvas/CanvasHistory';
import axios from 'axios';
import {requestHeader} from './helpers';

/**
 * Notes on semvar
 *
 * # v1
 * clientState <items, sceneFrame, itemCaptions>
 *
 * # v2
 * clientState <items, sceneFrame, itemCaptions>
 */

interface GetLatestProjectSyncProps {
  token: string;
  projectId: string;
}

export async function getLatestProjectSync({
  token,
  projectId,
}: GetLatestProjectSyncProps) {
  const projectLatestSyncEndpoint = `${BASE_URL}/api/v1/project_syncs/latest`;
  const response = await axios.post(
    projectLatestSyncEndpoint,
    {
      project_sync_versions: {
        project_id: projectId,
      },
    },
    requestHeader(token),
  );
  return response.data as ProjectSyncHistoryItem;
}

export type PostClientStatusBody = {
  project_sync_versions: {
    project_id: string;
    client_sync_version: number;
    client_api_semvar: 2;
    client_state: string;
  };
};

export async function postClientStatus(
  token: string,
  projectId: string,
  ownerId: string,
  clientState: Partial<ClientStateType>,
  clientSyncVersion: number,
) {
  const projectEndpoint = `${BASE_URL}/api/v1/project_syncs`;
  const response = await axios.post(
    projectEndpoint,
    {
      project_sync_versions: {
        owner_id: ownerId,
        project_id: projectId,
        client_sync_version: clientSyncVersion,
        client_api_semvar: 2,
        client_state: JSON.stringify(clientState),
      },
    } as PostClientStatusBody,
    requestHeader(token),
  );
  return response.data;
}

export async function listProjectSyncHistory(token: string, projectId: string) {
  const projectEndpoint = `${BASE_URL}/api/v1/project_syncs/list`;
  const response = await axios.post(
    projectEndpoint,
    {
      project_sync_versions: {
        project_id: projectId,
      },
    },
    requestHeader(token),
  );
  return response.data;
}
