import {CanvasItem, FontWeight} from 'features/types/canvasItemsSlice';
import {ItemLayerSources, ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';

import {defaultCropRectangle} from '../components/AppCanvas/Canvas/utils/useEditCrop';
import {defaultStyles} from 'services/recentUsageAPI';

export function isPlayTimeMediaType<T extends object>(
  item: T,
): item is T & {playLengthSeconds: number} {
  return 'playLengthInSeconds' in item;
}

export type LayerItemStyleType = {
  color: string;
  lineHeight: number;
  fontSize: number | string;
  fontFamily: string;
  background: string;
  colorInactive?: string;
  colorCurrent?: string;
  borderRadius?: number;
  fontWeight?: FontWeight;
};

export const BASE_LAYER_ITEM_STYLE: LayerItemStyleType = {
  color: defaultStyles.captions_active_color,
  colorInactive: defaultStyles.captions_inactive_color,
  colorCurrent: defaultStyles.captions_active_color,
  lineHeight: 1.25,
  fontSize: '36',
  fontFamily: 'PT Serif',
  background: 'none',
};

export const UNNAMED_LAYER = 'Unnamed layer';

export const BASE_LAYER_ITEM = {
  sceneId: '',
  layerName: UNNAMED_LAYER,
  playLengthSeconds: 0,
  timeOffsetSeconds: 0,
  rotation: 0,
  order: 0,
  itemSourceId: '',
  itemSource: ItemLayerSources.Clips,
  fontVariant: 'regular',
  style: {
    ...BASE_LAYER_ITEM_STYLE,
  },
  crop: defaultCropRectangle,
};

export const SAMPLE_VIDEO = {
  ...BASE_LAYER_ITEM,
  viewType: ViewTypes.Video as const,
  isDurationIgnored: false,
  isMuted: false,
  playbackId: '',
  url: 'blobl:https://milkvideo.com/48b918ab-3493-4dac-aee5-de160e737415',
  playLengthSeconds: 10, // seconds
  position: {
    left: 0,
    top: 0,
  },
  dimension: {
    width: 400,
    height: 300,
  },
};

export const SAMPLE_CLIP = {
  ...SAMPLE_VIDEO,
  viewType: ViewTypes.VideoClip as const,
  timeOffsetSeconds: 0,
};

export const SAMPLE_IMAGE = {
  ...BASE_LAYER_ITEM,
  viewType: ViewTypes.Image as const,
  url: 'https://fakeimg.pl/1200x600/',
  position: {
    left: 0,
    top: 0,
  },
  dimension: {
    width: 1200,
    height: 600,
  },
};

export const SAMPLE_HEADER = {
  ...BASE_LAYER_ITEM,
  viewType: ViewTypes.Text as const,
  text: 'Text goes here',
  style: {
    ...BASE_LAYER_ITEM_STYLE,
    fontSize: '72',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    background: 'none',
    lineHeight: 1.25,
  },
  position: {
    left: 20,
    top: 20,
  },
  dimension: {
    width: 400,
    height: 400,
  },
} as CanvasItem;

export const SAMPLE_TEXT = {
  ...BASE_LAYER_ITEM,
  viewType: ViewTypes.Text as const,
  text: 'Text goes here',
  style: {
    ...BASE_LAYER_ITEM_STYLE,
    fontSize: '30',
    fontFamily: 'Open Sans',
    background: 'none',
    lineHeight: 1.25,
  },
  position: {
    left: 20,
    top: 20,
  },
  dimension: {
    width: 400,
    height: 400,
  },
};

export const SAMPLE_SQUARE = {
  ...BASE_LAYER_ITEM,
  viewType: ViewTypes.Square as const,
  style: {
    ...BASE_LAYER_ITEM_STYLE,
    borderRadius: 0,
    background: defaultStyles.shape_background,
  },
  dimension: {
    width: 300,
    height: 80,
  },
  position: {
    left: 50,
    top: 50,
  },
};

/**
 * Base component used for smart components
 *
 * Includes: Audiowave, Countdown timer, Progress bars
 */
export const SAMPLE_SMART_COMPONENT = {
  ...BASE_LAYER_ITEM,
  viewType: ViewTypes.SmartComponent as const,
  dimension: {
    width: 300,
    height: 80,
  },
  position: {
    left: 50,
    top: 50,
  },
};

export const SAMPLE_CAPTION = {
  ...SAMPLE_SQUARE,
  ...SAMPLE_TEXT,
  playLengthSeconds: 0,
  viewType: ViewTypes.Caption as const,
  style: {
    ...BASE_LAYER_ITEM_STYLE,
    ...SAMPLE_TEXT.style,
    lineHeight: 1.25,
    fontSize: '36',
    fontFamily: 'PT Serif',
    background: 'none',
  },
};

export const SAMPLE_CLIP_CAPTION_ITEM = {
  ...SAMPLE_SQUARE,
  ...SAMPLE_TEXT,
  playLengthSeconds: 0,
  timeOffsetSeconds: 0,
  viewType: ViewTypes.CaptionClip as const,
  style: {
    ...BASE_LAYER_ITEM_STYLE,
    ...SAMPLE_TEXT.style,
    lineHeight: 1.25,
    fontSize: '36',
    fontFamily: 'PT Serif',
    background: 'none',
  } as LayerItemStyleType,
};

export const SAMPLE_ITEMS: Record<ViewTypes, object> = {
  [ViewTypes.Text]: SAMPLE_TEXT,
  [ViewTypes.Square]: SAMPLE_SQUARE,
  [ViewTypes.Caption]: SAMPLE_CAPTION,
  [ViewTypes.CaptionClip]: SAMPLE_CLIP_CAPTION_ITEM,
  [ViewTypes.Video]: SAMPLE_VIDEO,
  [ViewTypes.VideoClip]: SAMPLE_CLIP,
  [ViewTypes.AudioClip]: SAMPLE_CLIP,
  [ViewTypes.SmartComponent]: SAMPLE_SMART_COMPONENT,
  [ViewTypes.Image]: SAMPLE_IMAGE,
};

export const DEFAULT_ITEMS_DATA = [SAMPLE_CAPTION];
