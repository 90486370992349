import {useEffect, useRef, useState} from 'react';

import ReactPlayer from 'vendor/react-player-milk';
import cn from 'classnames';
import {useSearchResultItem} from './useSearchResultItem';

export const SearchResultItemVideoPlayer = ({isAudio}: {isAudio?: boolean}) => {
  const videoRef = useRef<any>(null);
  const {
    state: {activePlaybackId, isPlaying},
    dispatch,
  } = useSearchResultItem();

  const [hasSetVideoRef, setHasSetVideoRef] = useState(false);

  const setIsVideoReady = (newState: boolean) => {
    dispatch({
      type: 'updateIsVideoReady',
      payload: {
        isVideoReady: newState,
      },
    });
  };
  const setIsPlaying = (newState: boolean) => {
    dispatch({
      type: 'updateIsPlaying',
      payload: {
        isPlaying: newState,
      },
    });
  };

  const handlePlayerClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    // console.log('videoRef.current', videoRef.current);
    if (videoRef.current && !hasSetVideoRef && activePlaybackId) {
      setHasSetVideoRef(true);
      dispatch({
        type: 'setVideoRef',
        payload: {
          videoRef: videoRef,
        },
      });
    }
  }, [videoRef, activePlaybackId]);

  // console.log('isPlaying', isPlaying);

  if (!activePlaybackId) {
    return <div />;
  }

  return (
    <div className="flex items-start justify-start p-3">
      <div
        onClick={handlePlayerClick}
        className={cn('cursor-pointer overflow-hidden', {
          'rounded-md shadow-md ': !isAudio,
        })}
      >
        <ReactPlayer
          onReady={() => {
            setIsVideoReady(true);
          }}
          ref={videoRef}
          playing={isPlaying}
          url={`https://stream.mux.com/${activePlaybackId}.m3u8`}
          width="100%"
          height="100%"
          className={'h-full w-full select-none'}
          controls
        />
      </div>
    </div>
  );
};
