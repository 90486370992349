import {canvasScenesZindex} from './canvasSceneConstants';
import classNames from 'classnames';
import {convertToSecRounded} from 'features/EditorCanvas/utils';

export function ScenePreviewLength({sceneDuration}: {sceneDuration: number}) {
  return (
    <div
      className={classNames(
        'absolute bottom-2 left-3 inline-flex items-center justify-center text-left opacity-0 group-hover:opacity-100',
        'py-.5 focus:outline-none flex items-center rounded-full bg-gray-600 px-1.5 text-white hover:text-gray-200',
        'transition duration-100 ease-in-out',
        `z-${canvasScenesZindex.ScenesPreviewLength}`,
      )}
      style={{
        fontSize: '10px',
      }}
    >
      {convertToSecRounded(sceneDuration, 1)}s
    </div>
  );
}
