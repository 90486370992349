import {BookOpen, ChevronsDown, Edit3, FileText} from 'react-feather';
import {createSRT, downloadSrtFile} from './helper';

import ReactTooltip from 'react-tooltip';
import {SearchIcon} from '@heroicons/react/outline';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import classNames from 'classnames';
import {format} from 'date-fns';
import {useActiveHotkey} from 'features/TranscriptEditor/Hotkeys';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useLanguage} from './LanguageState';
import {useOpenFindReplaceModal} from 'features/TranscriptEditor/FindReplaceModal';
import {useTranscript} from 'features/Captions/Transcripts';
import {useUploadTranscript} from './UploadTranscriptContext';

export default function TranscriptEditorToolbar({
  uploadFile,
  toolbarRef,
}: {
  uploadFile: UserUploadsType;
  toolbarRef: any;
}) {
  const {
    setIsDownloadModalOpen,
    autoScroll,
    setAutoScroll,
    setTranscriptChapters,
    transcriptChaptersActive,
    editMode,
    setEditMode,
  } = useUploadTranscript();
  const activeHotkey = useActiveHotkey();
  const openFindReplaceModal = useOpenFindReplaceModal();

  const isFindAndReplaceActive = useFeatureFlag('find-and-replace');

  const isTranscriptChaptersFeatureActive = useFeatureFlag(
    'transcript-chapter-ui-button',
  );

  return (
    <div ref={toolbarRef} className="flex flex-row justify-between px-4 py-1 sm:px-6">
      <p className="mt-1 max-w-2xl text-sm text-gray-500">
        Imported at {format(new Date(uploadFile.created_at), 'Pp')}
      </p>
      <div className="bg-ping-400 flex flex-row space-x-2 text-gray-400">
        {/* <button
          type="button"
          className={classNames(
            'transition rounded inline-flex items-center p-1 border border-transparent text-gray-500 focus:outline-none ',
            editMode && 'bg-indigo-100 !text-indigo-600',
          )}
          data-tip={`${editMode ? 'Exit' : 'Enter'} transcript correction mode`}
          onClick={() => setEditMode(!editMode)}
        >
          <Edit3 size={20} />
          <ReactTooltip
            place="top"
            className="font-semibold bg-gray-600"
            effect="solid"
          />
        </button> */}

        <button
          type="button"
          className={classNames(
            'inline-flex items-center rounded border border-transparent p-1 text-gray-500 transition focus:outline-none ',
            autoScroll && 'bg-indigo-100 !text-indigo-600',
          )}
          data-tip={`${autoScroll ? 'Disable' : 'Enable'} transcript autoscroll`}
          onClick={() => setAutoScroll(!autoScroll)}
        >
          <ChevronsDown size={20} />
          <ReactTooltip
            place="top"
            className="bg-gray-600 font-semibold"
            effect="solid"
          />
        </button>

        <button
          type="button"
          className={classNames(
            'inline-flex items-center rounded border border-transparent bg-white p-1 text-white opacity-50 transition hover:bg-gray-200 focus:outline-none ',
            activeHotkey === 'capitalization' && 'bg-indigo-300',
          )}
          data-tip="Click + Q to toggle capitalization"
        >
          <svg
            width={21}
            height={13}
            viewBox="0 0 21 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.0599 12C18.9595 11.712 18.8924 11.4133 18.8599 11.11C18.1899 11.81 17.3799 12.16 16.4499 12.16C15.6199 12.16 14.9299 11.92 14.3999 11.45C13.8699 11 13.5999 10.39 13.5999 9.66C13.5999 8.78 13.9299 8.1 14.5999 7.61C15.2699 7.12 16.2099 6.88 17.4299 6.88H18.8299V6.24C18.8299 5.75 18.6799 5.36 18.3799 5.07C18.0799 4.78 17.6299 4.64 17.0499 4.64C16.5299 4.64 16.0999 4.76 15.7499 5C15.3999 5.25 15.2299 5.54 15.2299 5.89H13.7699C13.7699 5.46 13.9199 5.05 14.2199 4.65C14.4999 4.25 14.9299 3.94 15.4399 3.71C15.9499 3.5 16.4999 3.36 17.1299 3.36C18.1099 3.36 18.8699 3.6 19.4199 4.09C19.9699 4.58 20.2599 5.25 20.2799 6.11V10C20.2799 10.8 20.3799 11.42 20.5799 11.88V12H19.0599ZM16.6599 10.88C17.1099 10.88 17.5399 10.77 17.9499 10.56C18.3499 10.35 18.6499 10.07 18.8299 9.73V8.16H17.6999C15.9299 8.16 15.0399 8.63 15.0399 9.57C15.0399 10 15.1899 10.3 15.4999 10.53C15.7999 10.76 16.1799 10.88 16.6599 10.88ZM4.45993 7.71H8.52993L6.49993 2.29L4.45993 7.71ZM5.63993 0H7.35993L12.0699 12H10.1399L9.16993 9.43H3.81993L2.85993 12H0.929932L5.63993 0Z"
              fill="black"
            />
          </svg>
          <ReactTooltip
            place="top"
            className="bg-gray-600 font-semibold"
            effect="solid"
          />
        </button>

        <button
          type="button"
          data-tip="Click + W to toggle punctuation"
          className={classNames(
            'inline-flex items-center rounded border border-transparent bg-white p-1 text-white opacity-50 transition hover:bg-gray-200 focus:outline-none ',
            activeHotkey === 'punctuation' && 'bg-indigo-300',
          )}
        >
          <svg
            width={21}
            height={16}
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 6H16V8.77592L14.797 11H13.3015L14.4985 8.77592H13V6Z"
              fill="black"
            />
            <path d="M5 6H8V9H5V6Z" fill="black" />
          </svg>
          <ReactTooltip
            place="top"
            className="bg-gray-600 font-semibold"
            effect="solid"
          />
        </button>
        {isFindAndReplaceActive && (
          <button
            data-tip="Find and replace"
            className="inline-flex items-center rounded border border-transparent bg-white p-1 transition hover:bg-gray-100 focus:outline-none"
            onClick={() => openFindReplaceModal()}
          >
            <span className="sr-only">Find and replace</span>
            <SearchIcon className="h-4 w-4 text-gray-700" />
            <ReactTooltip
              place="top"
              className="bg-gray-600 font-semibold"
              effect="solid"
            />
          </button>
        )}

        <button
          type="button"
          className={classNames(
            'hidden items-center rounded border border-transparent p-1 text-gray-500 transition focus:outline-none md:inline-flex ',
            transcriptChaptersActive && 'bg-indigo-100 !text-indigo-600',
          )}
          data-tip={`${transcriptChaptersActive ? 'Hide' : 'Show'} transcript chapters`}
          onClick={() => setTranscriptChapters(!transcriptChaptersActive)}
        >
          <BookOpen size={20} />
          <ReactTooltip
            place="top"
            className="bg-gray-600 font-semibold"
            effect="solid"
          />
        </button>
      </div>
    </div>
  );
}
