import {ChangeEvent, MouseEvent, SyntheticEvent} from 'react';
import {Fragment, useEffect, useMemo, useRef, useState} from 'react';

import {XIcon} from '@heroicons/react/outline';
import classNames from 'classnames';
import {listFilterContent} from './constants';
import {useClipList} from './ClipListContext';

const product = {
  name: 'Search content',
};

export function ModelContentSearchTextContent({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (param: boolean) => void;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const {
    state: {activeModal},
    dispatch: dispatchClipList,
  } = useClipList();

  const addContentFilter = () => {
    dispatchClipList({
      type: 'update search term',
      payload: {
        searchTerm: searchTerm,
      },
    });
    setOpen(false);
  };

  return (
    <div
      className={classNames(
        'relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8',
        {
          hidden: activeModal !== 'content',
        },
      )}
    >
      <button
        type="button"
        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
        onClick={() => setOpen(false)}
      >
        <span className="sr-only">Close</span>
        <XIcon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-8 lg:gap-x-8">
        <div className="sm:col-span-8">
          <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12">
            {product.name}
          </h2>

          <section aria-labelledby="options-heading" className="mt-2">
            <form
              onSubmit={(e: SyntheticEvent) => {
                e.preventDefault();
              }}
            >
              <div className="mt-4 space-y-2">
                <div>
                  <div className="relative mt-1 flex items-center">
                    <input
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        setSearchTerm(evt.target.value);
                      }}
                      autoFocus
                      type="text"
                      placeholder="Enter search term"
                      name="search"
                      id="search"
                      className="block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <button
                onClick={addContentFilter}
                type="submit"
                className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 py-1 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Search {'"'}
                {searchTerm}
                {'"'}
              </button>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
}
