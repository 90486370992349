import {
  FC,
  createContext,
  KeyboardEventHandler,
  useContext,
  useEffect,
  useRef,
} from 'react';
import {useCallback} from 'use-memo-one';
import {v4} from 'uuid';

type ContextType = {
  subscribe: (id: string, subscription: Subscription) => void;
  unsubscribe: (id: string) => void;
  fireEvent: (name: EventName, event: KeyboardEvent) => void;
};

const Context = createContext<ContextType | null>(null);

export type Listener = (event: KeyboardEvent) => void;
export type EventName = 'keydown' | 'keyup';
type Subscription = {event: EventName; listener: Listener};

export const SlateEventsProvider: FC = ({children}) => {
  const subscriptions = useRef<Record<string, Subscription>>({});

  const subscribe = (id: string, subscription: Subscription) => {
    subscriptions.current[id] = subscription;
  };

  const unsubscribe = (id: string) => {
    delete subscriptions.current[id];
  };

  const fireEvent = useCallback((name: EventName, event: KeyboardEvent) => {
    for (const subscription of Object.values(subscriptions.current)) {
      if (subscription.event !== name) continue;
      subscription.listener(event);
    }
  }, []);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      fireEvent('keydown', event);
    };

    const onKeyUp = (event: KeyboardEvent) => {
      fireEvent('keyup', event);
    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [fireEvent]);

  return (
    <Context.Provider value={{subscribe, unsubscribe, fireEvent}}>
      {children}
    </Context.Provider>
  );
};

export const useSlateEventHandlers = () => {
  const context = useContext(Context);

  const onKeyUp = useCallback<KeyboardEventHandler>(
    event => {
      event.stopPropagation();
      context?.fireEvent('keyup', event.nativeEvent);
    },
    [context],
  );

  const onKeyDown = useCallback<KeyboardEventHandler>(
    event => {
      event.stopPropagation();
      context?.fireEvent('keydown', event.nativeEvent);
    },
    [context],
  );

  return {onKeyDown, onKeyUp};
};

export const useSlateEvent = (event: EventName, listener: Listener) => {
  const context = useContext(Context);

  useEffect(() => {
    const id = v4();
    context?.subscribe(id, {event, listener});
    return () => context?.unsubscribe(id);
  }, [event, listener, context]);
};
