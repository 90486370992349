import {
  CanvasItem,
  CanvasScene,
  PlayableItem,
  PlaybleCanvasItem,
} from 'features/types/canvasItemsSlice';

import {DEFAULT_SCENE_DURATION} from 'features/canvasItemsSlice';
import {OverrideDurations} from 'pages/Canvas/FramePage';
import {Time} from 'features/Common/Time';
import {TimeMilliSeconds} from 'features/types/userLibrarySlice';
import {convertToMs} from 'features/EditorCanvas/utils';
import {useDuration} from './useDuration';
import {usePlayback} from './usePlayback';
import {useState} from 'react';
import {useTimeEffect} from './useTimeEffect';
import {useTimeSelector} from './useTimeSelector';

export type OrderedCanvasScene = CanvasScene & {sceneId: string};

/**
 * Take the redux store canvasScene object and return an ordered array of canvasScenes
 * @param canvasScenes
 * @returns OrderedCanvasScene[]
 */
export const orderCanvasScenes = (
  canvasScenes: Record<string, CanvasScene>,
): OrderedCanvasScene[] => {
  const orderedCanvasScenes = Object.entries(canvasScenes)
    .map(canvasSceneEntry => {
      const itemKey = canvasSceneEntry[0];
      const itemValue = canvasSceneEntry[1];
      return {
        ...itemValue,
        sceneId: itemKey,
      } as OrderedCanvasScene;
    })
    .sort((a, b) => a.order - b.order);
  return orderedCanvasScenes;
};

export const getSceneRanges = (canvasScenes: OrderedCanvasScene[]) => {
  let startTimeCumulative = 0;
  return canvasScenes.map(scene => {
    const startTimeMs = Math.round(startTimeCumulative);
    const endTimeMs = Math.round(startTimeMs + parseFloat(scene.duration as any));
    startTimeCumulative = endTimeMs;

    return {id: scene.sceneId, startTimeMs, endTimeMs};
  });
};

/**
 * Calculate the scene length of all the items within a sceneId
 *
 * @param items
 * @returns sceneId duration in TimeMilliSeconds
 */
export const getSceneDuration = (
  items: Record<string, CanvasItem>,
  sceneId: string,
  targetScene: CanvasScene,
  overrideDurations: OverrideDurations = {},
): TimeMilliSeconds => {
  const itemsEntries = Object.entries(items);

  const sceneItemsWithDuration = itemsEntries.filter(
    ([id, item]) =>
      item.playLengthSeconds &&
      item.sceneId === sceneId &&
      item &&
      // @ts-ignore
      !item?.isDurationIgnored,
  );

  if (!sceneItemsWithDuration.length) {
    return targetScene?.duration ? targetScene.duration : DEFAULT_SCENE_DURATION;
  }

  const sceneItemDurations = sceneItemsWithDuration.map(([id, item]) => {
    const overrideDuration = overrideDurations[id];
    if (overrideDuration) {
      return overrideDuration.duration.ms;
    }

    if (!item || !item.playLengthSeconds) return 0;
    return convertToMs(item.playLengthSeconds);
  });

  if (!sceneItemDurations.length && targetScene?.duration) {
    return targetScene.duration;
  }

  return Math.max.apply(null, sceneItemDurations);
};

/**
 * Calculate the duration of a video based on the canvasScenes
 *
 * @returns duration of the scenes
 */
export const getProjectDuration = (
  canvasScenes: Record<string, CanvasScene>,
  items: Record<string, CanvasItem>,
): TimeMilliSeconds => {
  const sceneIds = Object.keys(canvasScenes);
  const targetScenesDurations = sceneIds.map(sceneId =>
    getSceneDuration(items, sceneId, canvasScenes[sceneId]),
  );

  const totalDurationMs = targetScenesDurations.reduce((a, b) => a + b, 0);
  return totalDurationMs;
};

export const usePlaybackPercentage = () => {
  const durationMs = useDuration();

  return useTimeSelector(
    ({currentTime}) => {
      return (currentTime / durationMs) * 100;
    },
    [durationMs],
  );
};

export const usePlayRange = (
  range: {fromMs: number; toMs: number; pausable?: boolean} | undefined,
) => {
  const {fromMs, toMs, pausable = false} = range || {};
  const {pause, play, seekTo, isPlaying} = usePlayback();

  const [isPlayingRange, setIsPlayingRange] = useState(false);

  useTimeEffect(
    ({currentTime}) => {
      if (!isPlayingRange) return;
      if (fromMs == null || toMs == null) return;

      const inClipBounds = currentTime > fromMs && currentTime < toMs;
      if (!inClipBounds) {
        setIsPlayingRange(false);
        if (isPlaying) pause();
      }
    },
    [fromMs, toMs, isPlayingRange, isPlaying, pause],
  );

  const playRange = () => {
    if (fromMs == null || toMs == null) return;
    if (isPlayingRange) return;

    if (!pausable) {
      seekTo({timeMs: fromMs});
    }

    setTimeout(() => {
      play();
      setIsPlayingRange(true);
    }, 50);
  };

  const pauseRange = () => {
    if (fromMs == null || toMs == null) return;

    if (!pausable) {
      seekTo({timeMs: fromMs});
    }

    pause();
    setIsPlayingRange(false);
  };

  const toggle = () => {
    if (isPlayingRange) {
      pauseRange();
    } else {
      playRange();
    }
  };

  return {isPlaying: isPlayingRange, toggle, play, isPlayingRange};
};

export const formatTime = (time: Time | number, decimalPlaces = 0) => {
  const ms = typeof time === 'number' ? time : time.ms;
  const totalSeconds = (ms / 1000) % 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const minutesStr = String(minutes).padStart(2, '0');

  let secondsStr;
  if (decimalPlaces > 0) {
    secondsStr = seconds.toFixed(decimalPlaces).padStart(3 + decimalPlaces, '0');
  } else {
    secondsStr = seconds.toFixed(decimalPlaces).padStart(2, '0');
  }

  return `${minutesStr}:${secondsStr}`;
};

export const useTimeCode = (decimalPlaces = 0) => {
  return useTimeSelector(
    ({currentTime}) => {
      return formatTime(new Time(currentTime > 0 ? currentTime : 0), decimalPlaces);
    },
    [decimalPlaces],
  );
};

export const useCurrentTime = () => {
  return useTimeSelector(({currentTime}) => {
    return currentTime;
  }, []);
};
