import {CableMessagePayload} from './types';
import {announceDiscretelyUserToApi} from 'features/authSlice';

enum AccountsCableActions {
  Updated = 'updated',
}

const accountsCable = (
  dispatch: any,
  getAccessTokenSilently: any,
  message: CableMessagePayload,
  user: any,
) => {
  if (message.body.action === AccountsCableActions.Updated) {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
      } catch (e) {
        console.error(e);
      }
    })();
  }
};

export default accountsCable;
