export enum SortByClipType {
  CreatedAt = 'created-at',
  Duration = 'duration',
}

export const labelColors = [
  {name: 'Yellow', bgColor: 'bg-yellow-300', selectedColor: 'ring-yellow-300'},
  {name: 'Orange', bgColor: 'bg-yellow-600', selectedColor: 'ring-yellow-600'},
  {name: 'Red', bgColor: 'bg-red-600', selectedColor: 'ring-red-600'},
  {name: 'Magenta', bgColor: 'bg-pink-800', selectedColor: 'ring-pink-800'},
  {name: 'Lavender', bgColor: 'bg-purple-400', selectedColor: 'ring-purple-400'},
  {name: 'Indigo', bgColor: 'bg-indigo-700', selectedColor: 'ring-indigo-700'},
  {name: 'Sky blue', bgColor: 'bg-blue-300', selectedColor: 'ring-blue-300'},
  {name: 'Blue', bgColor: 'bg-blue-700', selectedColor: 'ring-blue-700'},
  {name: 'Lime', bgColor: 'bg-green-300', selectedColor: 'ring-green-300'},
  {name: 'Green', bgColor: 'bg-green-500', selectedColor: 'ring-green-500'},
] as const;

export type LabelColor = typeof labelColors[number];

export type LabelColorName = LabelColor['name'];

export type ListFilter =
  | {
      filter_target: 'content';
      filter_value: string;
      filter_condition: filterMatchCondition;
    }
  | {
      filter_target: 'color-label';
      filter_value: LabelColorName;
      filter_condition: filterMatchCondition;
    };

export type filterMatchCondition = '=' | '!=';

export const listFilterContent = {
  title: 'Clip content',
  id: 'content',
};

export const listFilterColorLabel = {
  title: 'Label colors',
  id: 'color-label',
};

export const listFilterTypes = [
  {
    title: 'Filter',
    id: 'filter',
  },
  listFilterContent,
  listFilterColorLabel,
] as const;

export const displayedFilterParams = [listFilterContent.id, listFilterColorLabel.id];

export type listFilterType = typeof listFilterTypes[number];

export type clipListModalOptions = listFilterType['id'];
