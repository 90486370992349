import EditableLandingPageField from '../EditableLandingPageField';
import LandingClipListResultActions from '../LandingClipListResultActions';
import { LandingPageComponent } from 'services/landingPageAPI';
import ReactPlayer from 'vendor/react-player-milk';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { userLibrarySelector } from 'features/selectors/userLibrarySelectors';

export const VideoCard = ({
  isDragging,
  landingPageId,
  state,
  setState,
  item,
}: {
  landingPageId: string;
  isDragging: boolean;
  item: LandingPageComponent;
  state: any;
  setState: any;
}) => {
  const { userClips, userUploads } =
    useSelector(userLibrarySelector);

  const videoRef = useRef<any>(null);

  const clip = userClips.find(clip => clip.id === item.component_id);
  const userUpload = userUploads.find(upload => upload.id === clip?.user_upload_id);

  const handleOnReady = () => {
    if (videoRef.current !== null && clip) {
      videoRef.current.seekTo(Number(clip.start_time), 'seconds');
    }
  };

  const handleOnPause = () => {
    if (videoRef.current !== null && clip) {
      videoRef.current.seekTo(Number(clip.start_time), 'seconds');
    }
  };

  return (
    <>
      <div className="aspect-w-3 aspect-h-2 overflow-hidden sm:col-span-4 lg:col-span-5">
        <ReactPlayer
          onReady={handleOnReady}
          onPause={handleOnPause}
          ref={videoRef}
          // playing={isPlaying}
          url={`https://stream.mux.com/${userUpload?.mux_playback_id}.m3u8`}
          width="100%"
          height="100%"
          className={'h-full w-full select-none'}
          controls
        />
      </div>
      <div className="sm:col-span-8 lg:col-span-7">
        <EditableLandingPageField
          landingPageComponentId={item.id}
          landingPageId={landingPageId}
          fieldName={'title'}
          className={'text-xl font-medium text-gray-900 sm:pr-12'}
          fieldValue={item.title || 'Untitled'}
        />
        <div className="mt-6">
          <h4 className="sr-only">Description</h4>
          <EditableLandingPageField
            landingPageComponentId={item.id}
            landingPageId={landingPageId}
            fieldName={'description'}
            className={'text-sm text-gray-700'}
            fieldValue={item.description || 'Untitled'}
          />
        </div>
        <section aria-labelledby="options-heading" className="mt-3">
          <h3 id="options-heading" className="sr-only">
            Card options
          </h3>
        </section>
      </div>

      {userUpload && (
        <LandingClipListResultActions
          userUploadId={userUpload?.id}
          userClipId={item.component_id}
          cardType="landingPage"
        />
      )}
    </>
  );
};
