import cn from 'classnames';
import {Film, Icon, Link2, Type} from 'react-feather';
import {LandingPageComponent} from 'services/landingPageAPI';

const icons: Record<LandingPageComponent['element_type'], Icon> = {
  embed: Link2,
  mux_video: Film,
  section: Type,
};

export const ListChapterCard = ({
  isDragging,
  item,
  onClick,
}: {
  item: LandingPageComponent;
  isDragging: boolean;
  onClick: () => void;
}) => {
  const Icon = icons[item.element_type];

  return (
    <div className={cn(item.element_type !== 'section' && 'ml-2')}>
      <div
        className={cn(
          'flex w-full items-center space-x-2 rounded-md border px-2 py-1 text-gray-900 transition hover:border-indigo-50 hover:bg-indigo-50 hover:text-indigo-600',
          isDragging && 'border-indigo-400 bg-indigo-100',
        )}
        onClick={() => onClick()}
      >
        <Icon size={16} className="flex-shrink-0" />
        <h2 className="truncate text-sm">{item.title || 'Untitled'}</h2>
      </div>
    </div>
  );
};
