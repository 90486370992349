import {deselectItems, deselectItemsNotInScene} from 'features/canvasStateSlice';
import {
  getSceneDuration,
  getSceneRanges,
  orderCanvasScenes,
} from 'features/EditorCanvas/components/CanvasTime/utils';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useMemo} from 'react';

import {CanvasItem} from 'features/types/canvasItemsSlice';
import {GetRootState} from 'configureStore';
import {createContainer} from 'unstated-next';
import {objFilter} from 'features/Common/utils';
import {selectCanvasProject} from 'features/selectors/canvasItemsSelectors';
import {useCallback} from 'use-memo-one';
import {useDuration} from './useDuration';
import {useOverrideDurations} from 'pages/Canvas/FramePage';
import {usePlayback} from './usePlayback';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useTimeSelector} from './useTimeSelector';

export type CalculatedScene = ReturnType<typeof Scenes.useContainer>['scenes'][number];

const Scenes = createContainer(() => {
  const projectId = useProjectId();

  const canvasScenes = useSelector((state: GetRootState) => {
    return selectCanvasProject(state, projectId).canvasScenes;
  });

  const items = useSelector((state: GetRootState) => {
    return selectCanvasProject(state, projectId).items;
  });

  const overrideDurations = useOverrideDurations();

  const scenes = useMemo(() => {
    const ordered = orderCanvasScenes(canvasScenes);

    const withDuration = ordered.map(scene => ({
      ...scene,
      duration: getSceneDuration(items, scene.sceneId, scene, overrideDurations),
    }));

    const withItems = withDuration.map(scene => ({
      ...scene,
      items: itemsForScene(items, scene.sceneId),
    }));

    const sceneRanges = getSceneRanges(withItems);

    return withItems.map((scene, index) => ({
      ...scene,
      ...sceneRanges[index],
    }));
  }, [canvasScenes, items, overrideDurations]);

  const durationMs = useDuration();

  const activeScene = useTimeSelector(
    ({currentTime}) => {
      const activeScene = scenes.find(({startTimeMs, endTimeMs}) => {
        return currentTime >= startTimeMs && currentTime < endTimeMs;
      });

      if (!activeScene) {
        if (currentTime >= durationMs) {
          return scenes[scenes.length - 1];
        }

        return scenes[0];
      }

      return activeScene;
    },
    [scenes, durationMs],
  );

  const {seekTo} = usePlayback();
  const seekToScene = useCallback(
    (sceneId: string) => {
      const range = scenes.find(range => range.id === sceneId);
      if (!range) return;

      seekTo({timeMs: range.startTimeMs});
    },
    [scenes, seekTo],
  );

  const getItemScene = (item: CanvasItem) => {
    return useMemo(() => {
      if (!item) {
        return null;
      }
      const itemScene = scenes.find(scene => scene.id == item?.sceneId);
      if (itemScene) {
        return itemScene;
      }
      return null;
    }, [scenes, item?.sceneId]);
  };

  const activeSceneId = activeScene?.id;

  const dispatch = useDispatch();
  useEffect(() => {
    if (!activeSceneId) {
      dispatch(deselectItems());
    } else {
      dispatch(deselectItemsNotInScene({items, sceneId: activeSceneId}));
    }
  }, [activeSceneId, dispatch, projectId]);

  return useMemo(
    () => ({scenes, activeScene, seekToScene, getItemScene}),
    [scenes, activeScene, seekToScene, getItemScene],
  );
});

export const ScenesProvider = Scenes.Provider;

/**
 * TODO [FIX](lenny) This point appears is errors when page refresh happens.
 */
export const useScenes = Scenes.useContainer;

export const itemsForScene = (
  items: Record<string, CanvasItem>,
  sceneId: string | undefined,
) => {
  return objFilter(items, item => item.sceneId === sceneId);
};
