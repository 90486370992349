import {Helmet} from 'react-helmet';

export const extensionMap = {
  ttf: 'opentype',
  otf: 'opentype',
  woff: 'woff',
  woff2: 'woff2',
};

export default function FontPreviewer({
  fontFamily,
  uploadUrl,
  displayText,
}: {
  fontFamily: string;
  uploadUrl: string;
  displayText: string;
}) {
  const urlList = uploadUrl.split('.');
  const extension = urlList[urlList.length - 1];

  const cssString = `
    @font-face {
      font-family: '${fontFamily}';
      src: url('${uploadUrl}') format('${extensionMap[extension]}');
      }
    `;
  return (
    <>
      <Helmet>
        <link href={`${uploadUrl}`} rel="stylesheet" />
        <style>{cssString}</style>
      </Helmet>

      <div className="p-8">
        <div
          style={{
            fontSize: 24,
            fontFamily: fontFamily,
          }}
          className="px-1"
          id="font-rendered"
        >
          {displayText}
        </div>
      </div>
    </>
  );
}
