export enum ResponseStatus {
  Success = 'success',
  Failed = 'failed',
}
export enum UndoLabels {
  undoSuccessNotificationTitle = 'Undo successful',
  undoSuccessNotificationMessage = 'Your action has been undone.',
  undoTip = 'Undo one step',
  cannotUndoTip = 'Cannot move backward any more steps',
  undoButtonLabel = 'Backward changes',
  undo = 'Undo',
}
