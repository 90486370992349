import {
  BASE_LAYER_ITEM_STYLE,
  SAMPLE_SMART_COMPONENT,
} from 'features/EditorCanvas/constants/ItemConstants';
import {BaseItem, SmartComponentItem} from 'features/types/canvasItemsSlice';

import {AudioWaveVizOptions} from 'features/EditorCanvas/components/CanvasItemLayers/SmartComponent/AudioWaveforms/constants';
import {Button} from 'ui/Button';
import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import NotificationError from 'features/Notifications/NotificationError';
import {SmartComponents} from 'features/EditorCanvas/constants/ItemToolbarMapping';
import {addCanvasItem} from 'features/canvasItemsSlice';
import {getFirstPlayableMediaItemKey} from './helpers';
import {store} from 'react-notifications-component';
import {useDispatch} from 'react-redux';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';
import {useSidebarMenu} from '../../../SidebarMenuContext';

export const AddAudioFrequencyAnalysisButton = () => {
  const dispatch = useDispatch();
  const projectId = useProjectId();
  const {activeScene} = useScenes();

  const {dispatch: sidebarDispatch} = useSidebarMenu();

  const addAudioWaveformItem = (layerId: string) => {
    window.analytics.track('Add new smart element - audio waveform');

    const audioWaveformSmartComponent = {
      id: SmartComponents.AudioWaveform,
      options: {
        targetPlayableMediaId: layerId,
        waveformStyle: AudioWaveVizOptions.LinesFlat,
      },
    };

    const item = {
      ...SAMPLE_SMART_COMPONENT,
      style: {
        ...BASE_LAYER_ITEM_STYLE,
        color: '#000',
      },
      sceneId: activeScene?.id,
      smartComponent: audioWaveformSmartComponent,
    } as BaseItem & SmartComponentItem;

    dispatch(addCanvasItem({projectId, item}));
    sidebarDispatch({
      type: 'update active sidebar menu',
      activeMenu: 'Layer',
    });
  };

  const items = activeScene.items;

  const handleAddAudiowaveform = () => {
    const firstPlayableMediaLayerId = getFirstPlayableMediaItemKey(items);

    if (firstPlayableMediaLayerId) {
      addAudioWaveformItem(firstPlayableMediaLayerId);
    } else {
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationError
            title={'Need playable media layer'}
            message={
              'Please add a audio or video layer before adding a audio wave element.'
            }
          />
        ),
        type: 'warning',
      });
    }
  };

  return (
    <Button
      onClick={handleAddAudiowaveform}
      variant="tertiary"
      fullWidth
      alignText="center"
      _className="text-left bg-gray-100"
      size="large"
    >
      <div className="flex w-full items-center justify-center text-sm">
        Audio waveform test
      </div>
    </Button>
  );
};
