import {DashParams, routePaths} from 'routes/routesHelper';
import {Fragment, MouseEvent} from 'react';
import {Menu, Transition} from '@headlessui/react';

import {BASE_SHARE_URL} from 'constants/environment';
import {ChevronDownIcon} from '@heroicons/react/solid';
import {useNavigate} from 'react-router-dom';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export type ClipListResultActionsProps = {
  userUploadId: string;
  userClipId: string;
  setIsMenuOpen?: (param: string) => void;
};

export default function ClipListResultActions({
  userClipId,
  userUploadId,
  setIsMenuOpen,
}: ClipListResultActionsProps) {
  const navigate = useNavigate();

  const handlePlayClick = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    window.analytics.track('Click play clip on the clip index page');
    const url = `/${routePaths.dashboard}/${DashParams.UploadsParam}/media/${userUploadId}?clip_id=${userClipId}`;

    navigate(url);
  };
  const handleShareClick = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    window.analytics.track('Click share clip on the clip index page');
    const url = `${BASE_SHARE_URL}/a/${userClipId}`;

    window.open(url, '_blank');
  };
  const handleDownloadClick = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    const url = `${BASE_SHARE_URL}/a/${userClipId}/download`;
    window.open(url, '_blank');
  };

  const items = [
    {name: 'Edit', onClick: handlePlayClick},
    {name: 'Download', onClick: handleDownloadClick},
  ];

  return (
    <span className="relative inline-flex rounded-md shadow-sm">
      <a
        onClick={handleShareClick}
        target="_blank"
        href={`${BASE_SHARE_URL}/a/${userClipId}`}
        className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        rel="noreferrer"
      >
        Share
      </a>
      <Menu as="span" className="relative -ml-px block">
        {({open}) => {
          if (setIsMenuOpen) {
            if (open) {
              setIsMenuOpen(userClipId);
            }
          }
          return (
            <>
              <Menu.Button
                style={{height: 28}}
                className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              >
                <span className="sr-only">Open options</span>
                <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
              </Menu.Button>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="absolute right-0 z-10 mt-2 -mr-1 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    {items.map(item => (
                      <Menu.Item key={item.name}>
                        {({active}) => (
                          <button
                            onClick={item.onClick}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block w-full px-4 py-2 text-left text-sm',
                            )}
                          >
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    </span>
  );
}
