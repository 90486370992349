import {CanvasModalsWrap} from './CanvasModalsWrap';
import {EditorCanvasModals} from 'features/EditorCanvas/EditorCanvasModalsContext';
import {sceneFrameSelector} from 'features/selectors/sceneFrameSelectors';
import {useExportProject} from '../useExportProject';
import {useSelector} from 'react-redux';

export default function CanvasModalsWrapContainer({projectId}: {projectId: string}) {
  const {canvasDimensions} = useSelector(sceneFrameSelector)(projectId);
  const editorCanvasModals = EditorCanvasModals.useContainer();

  const closeModal = () => {
    editorCanvasModals.updateExportModal(false);
  };

  const {exportProject, isExporting} = useExportProject();

  if (!editorCanvasModals.isExportModalOpen) {
    return null;
  }

  return (
    <CanvasModalsWrap
      canvasDimensions={canvasDimensions}
      closeModal={closeModal}
      isExporting={isExporting}
      projectId={projectId}
      startExportFn={exportProject}
    />
  );
}
