import {createContext, FC, useContext} from 'react';
import {CurrentTime} from './useTimeEffect';
import {EndBehavior, EndPlayback} from './EndPlayback';
import {PlaybackProvider} from './usePlayback';
import {ScenesProvider} from './useScenes';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useSelector} from 'react-redux';
import {GetRootState} from 'configureStore';
import {selectCanvasProject} from 'features/selectors/canvasItemsSelectors';
import {getProjectDuration} from './utils';
import {DurationProvider} from './useDuration';

export const TimeProviderPresentCtx = createContext(false);
export const useTimeProviderPresent = () => useContext(TimeProviderPresentCtx);

export const TimeProvider: FC<{durationMs: number; endBehavior?: EndBehavior}> = ({
  durationMs,
  children,
  endBehavior,
}) => {
  return (
    <TimeProviderPresentCtx.Provider value={true}>
      <DurationProvider durationMs={durationMs}>
        <PlaybackProvider>
          <CurrentTime.Provider>
            {endBehavior !== 'noop' && <EndPlayback behavior={endBehavior} />}
            {children}
          </CurrentTime.Provider>
        </PlaybackProvider>
      </DurationProvider>
    </TimeProviderPresentCtx.Provider>
  );
};

export const ProjectTimeProvider: FC<{endBehavior?: EndBehavior}> = ({
  children,
  endBehavior,
}) => {
  const projectId = useProjectId();

  const durationMs = useSelector((state: GetRootState) => {
    const {canvasScenes, items} = selectCanvasProject(state, projectId);
    return getProjectDuration(canvasScenes, items);
  });

  return (
    <TimeProvider durationMs={durationMs} endBehavior={endBehavior}>
      <ScenesProvider>{children}</ScenesProvider>
    </TimeProvider>
  );
};
