export function Loading() {
  return (
    <div className="align-center flex h-full w-full flex-col items-center justify-start space-y-4 p-6">
      <div className="h-8 w-full animate-pulse bg-gray-300" />
      <div className="h-8 w-full animate-pulse bg-gray-300" />
      <div className="h-32 w-full animate-pulse bg-gray-200" />
      <div className="h-32 w-full animate-pulse bg-gray-200" />
      <div className="h-8 w-full animate-pulse bg-gray-200" />
      <div className="h-32 w-full animate-pulse bg-gray-200" />
      <div className="h-8 w-full animate-pulse bg-gray-200" />
      <div className="h-32 w-full animate-pulse bg-gray-200" />
    </div>
  );
}
