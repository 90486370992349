import WordFrame from 'features/Captions/WordFrame';
import {useSidebarMenu} from 'features/EditorCanvas/Sidebar/SidebarMenuContext';

export type itemCaptionsType = {
  frames: WordFrame[];
};

export function CorrectCaptionsButton() {
  const {dispatch} = useSidebarMenu();
  const setActiveView = (newActiveView: string) => {
    dispatch({
      type: 'update active sidebar menu',
      activeMenu: newActiveView,
    });
  };

  return (
    <div className="my-4 flex w-full flex-row items-center justify-between">
      <label
        htmlFor="about"
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        Captions
      </label>
      <span className="inline-flex rounded-md shadow-sm">
        <button
          onClick={() => {
            window.analytics.track('Attempt to correct transcript from the project page');
            setActiveView('Transcript');
          }}
          className="focus:outline-none focus:ring-blue inline-flex w-full items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-center text-xs font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 active:bg-gray-50 active:text-gray-800"
        >
          Correct
        </button>
      </span>
    </div>
  );
}
