import {DimensionType, PositionType} from 'features/types/canvasItemsSlice';

type NewBox = {
  width: string | number;
  height: string | number;
  top: string | number;
  left: string | number;
  padding?: string;
};

export type AbsoluteBox = {
  dimension: {width: number; height: number};
  position: {top: number; left: number};
};

export function roundObject<T extends string | number | symbol>(
  object: Record<T, number>,
) {
  const roundedObj = {};
  for (const key of Object.keys(object)) {
    roundedObj[key] = Math.round(object[key]);
  }
  return roundedObj as Record<T, number>;
}

export class Box {
  defaultBox: NewBox = {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  };

  public dimension: DimensionType;
  public position: PositionType;
  parent: AbsoluteBox;

  /**
   * Creates a new Box.
   *
   * The "Box" class lets you create virtual shapes
   * that you can position within another parent Box.
   *
   * The idea is that it lets you lay out items on the canvas
   * with abstracted layout methods.
   *
   * @param newBox The properties of the box.
   * @param parent The Box that the new Box should be inside.
   */
  constructor(newBox: Partial<NewBox>, parent: AbsoluteBox) {
    const box = {...this.defaultBox, ...newBox};
    const {dimension, position} = this.createBox(box, parent);
    this.dimension = dimension;
    this.position = position;
    this.parent = parent;
  }

  /** Fit this box's dimensions within its parent. */
  public fit() {
    const aspectRatio = this.aspectRatio;

    const containerWidth = this.parent.dimension.width;
    const containerHeight = this.parent.dimension.height;

    let width = containerWidth;
    let height = containerWidth / aspectRatio;
    if (height > containerHeight) {
      height = containerHeight;
      width = containerHeight * aspectRatio;
    }

    this.dimension = roundObject({width, height});

    return this;
  }

  /** Scale this box's dimensions to be as small as possible while covering its parent. */
  public cover() {
    const parentAspectRatio = this.parent.dimension.width / this.parent.dimension.height;

    let width, height;

    if (parentAspectRatio > this.aspectRatio) {
      width = this.parent.dimension.width;
      height = this.parent.dimension.width / this.aspectRatio;
    } else {
      width = this.parent.dimension.height * this.aspectRatio;
      height = this.parent.dimension.height;
    }

    this.dimension = roundObject({width, height});

    return this;
  }

  /** Center the box within its parent. */
  public center() {
    const top =
      (this.parent.dimension.height - this.dimension.height) / 2 +
      this.parent.position.top;

    const left =
      (this.parent.dimension.width - this.dimension.width) / 2 +
      this.parent.position.left;

    this.position = roundObject({top, left});

    return this;
  }

  /**
   * Returns the Box's position and dimension.
   *
   * Position is relative to the top-left of the canvas.
   */
  public getBox() {
    return {position: this.position, dimension: this.dimension};
  }

  /** Move the box vertically. */
  public moveY(px: number) {
    this.position.top += px;
    return this;
  }

  /** Position the Box below another Box. */
  public placeBelow(box: Box) {
    this.position.top = box.position.top + box.dimension.height;
    return this;
  }

  private get aspectRatio() {
    return this.dimension.width / this.dimension.height;
  }

  private createBox(newBox: NewBox, parent: AbsoluteBox) {
    const dimension = {
      width: this.toPx(newBox.width, parent.dimension.width),
      height: this.toPx(newBox.height, parent.dimension.height),
    };

    const position = {
      top: this.toPx(newBox.top, parent.dimension.height) + parent.position.top,
      left: this.toPx(newBox.left, parent.dimension.width) + parent.position.left,
    };

    if (newBox.padding) {
      const padding = newBox.padding.split(' ').map(this.floatOr0);
      let top, left, right, bottom;
      if (padding.length === 1) {
        top = padding[0];
        left = padding[0];
        right = padding[0];
        bottom = padding[0];
      } else if (padding.length === 2) {
        top = padding[0];
        bottom = padding[0];
        left = padding[1];
        right = padding[1];
      } else {
        [top, right, bottom, left] = padding;
      }

      dimension.width -= left + right;
      dimension.height -= top + bottom;
      position.top += top;
      position.left += left;
    }

    return {
      dimension,
      position,
    };
  }

  private floatOr0(value: string) {
    let float = parseFloat(value);
    if (isNaN(float)) float = 0;

    return float;
  }

  private toPx(percentageOrPx: string | number, abs: number) {
    if (typeof percentageOrPx === 'number') return percentageOrPx;
    return (this.floatOr0(percentageOrPx) / 100) * abs;
  }
}
