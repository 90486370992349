import {Pause, Play} from 'react-feather';

import Highlighter from 'react-highlight-words';
import {MilkIcon} from 'features/Common/MilkIcon';
import {SearchTranscriptWordInstances} from 'features/types/authSlice';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import cn from 'classnames';
import {secondToPlayerTime} from '../utils';
import {useSearchResults} from '../useSearchResults';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import {userLibrarySelector} from 'features/selectors/userLibrarySelectors';

export const UploadTranscriptListElement = ({
  videoRef,
  clip,
  shouldShowHour,
  setIsPlaying,
}: {
  shouldShowHour: boolean;
  setIsPlaying: (param: boolean) => void;
  clip: SearchTranscriptWordInstances;
  videoRef: any;
}) => {
  const {
    state: {clipIdPlaying, searchQuery},
    dispatch: searchResultsDispatch,
  } = useSearchResults();

  const [hoveringClip, setHoveringClip] = useState<string | null>(null);

  const transcriptClipId = `${clip.transcript_record_id}-${clip.words_arr_index}`;

  const setClipIdPlaying = (clipId: null | string) => {
    searchResultsDispatch({
      type: 'updateClipIdPlaying',
      payload: {
        clipId: transcriptClipId,
      },
    });
  };

  const {accountSearchResults} = useSelector(userLibrarySelector);
  const clipTranscript = accountSearchResults.transcripts.results.find(
    transcript => transcript.id === clip.transcript_record_id,
  );

  if (!clipTranscript) {
    return <div />;
  }

  const clipWordTime = Number(clipTranscript.words[clip.words_arr_index].start) / 1000;
  const clipSubset = clipTranscript.words.slice(
    clip.words_arr_index - 3,
    clip.words_arr_index + 15,
  );

  const updateActiveClipAndPlay = (clip: SearchTranscriptWordInstances) => {
    if (videoRef?.current) {
      window.analytics.track('User played video on search query result');
      setClipIdPlaying(transcriptClipId);
      videoRef.current.seekTo(Number(clip.start) / 1000, 'seconds');
      window.setTimeout(() => {
        setIsPlaying(true);
      }, 1);
    }
  };

  const stopAndClearActiveClip = () => {
    searchResultsDispatch({
      type: 'updateClipIdPlaying',
      payload: {
        clipId: null,
      },
    });
    setIsPlaying(false);
    if (videoRef?.current?.player?.player?.pause) {
      videoRef.current.player.player.pause();
    }
  };

  const targetClipHovered = (clipId: string) => {
    setHoveringClip(clipId);
  };

  const cancelHover = () => {
    setHoveringClip(null);
  };

  const clipSubsetText = clipSubset
    .map((clipWord, clipWordIndex) => {
      if (clipWordIndex === clipSubset.length - 1) {
        return clipWord.text;
      }
      return `${clipWord.text} `;
    })
    .join('');

  const transcriptListItemSubset = `${'...'}${clipSubsetText}${'...'}`;

  return (
    <li
      onMouseEnter={() => {
        targetClipHovered(transcriptClipId);
      }}
      onMouseLeave={() => {
        cancelHover();
      }}
      key={transcriptClipId}
      className="w-full bg-white py-2 px-2"
    >
      <div className="relative w-full">
        <div className="flex items-center justify-between space-x-3">
          <button
            type="button"
            onClick={() => {
              if (clipIdPlaying === transcriptClipId) {
                stopAndClearActiveClip();
              } else {
                updateActiveClipAndPlay(clip);
              }
            }}
            className={cn(
              'relative z-50 flex flex-shrink-0 items-center justify-center rounded-full border border-transparent p-1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
              {
                'bg-gray-50': hoveringClip === transcriptClipId,
              },
            )}
          >
            {hoveringClip === transcriptClipId || clipIdPlaying === transcriptClipId ? (
              clipIdPlaying === transcriptClipId ? (
                <Pause className="h-5 w-5" aria-hidden="true" />
              ) : (
                <Play className="h-5 w-5" aria-hidden="true" />
              )
            ) : (
              <MilkIcon
                width={20}
                height={20}
                viewBox="0 0 16 16"
                className="h-5 w-5"
                icon="Clip"
              />
            )}
          </button>
          <div className="min-w-0 flex-1">
            <div className="block focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="truncate text-sm text-gray-500">
                <Highlighter
                  highlightClassName="bg-yellow-100 border-b-2 border-yellow-300"
                  searchWords={[searchQuery]}
                  autoEscape={true}
                  textToHighlight={transcriptListItemSubset}
                />
              </p>
            </div>
          </div>
          <span className="flex-shrink-0 whitespace-nowrap text-sm text-gray-300">
            {secondToPlayerTime(clipWordTime, shouldShowHour)}
          </span>
        </div>
      </div>
    </li>
  );
};
