import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {AccountListItem} from 'features/NewOnboarding/CompleteSignup';
import {BASE_URL} from 'constants/environment';
import {Button} from 'ui/Button';
import ErrorPage from 'features/EditorCanvas/components/shared/ErrorPage';
import {Helmet} from 'react-helmet';
import LoadingPage from 'features/EditorCanvas/components/shared/LoadingPage';
import {LoadingSpinnerInline} from 'features/EditorCanvas/components/shared/LoadingSpinnerInline';
import {authAccountAccountSelector} from 'features/selectors/authSelectors';
import axios from 'axios';
import classNames from 'classnames';
import {requestHeader} from 'api/helpers';
import {routePaths} from 'routes/routesHelper';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useSelector} from 'react-redux';

export const AcceptInvitePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteToken = searchParams?.get('token') || null;
  const {getAccessTokenSilently, user} = useAuthAlt();
  const account = useSelector(authAccountAccountSelector);

  // console.log('account', account);

  const email = user?.email || null;
  const [organizationName, setOrganizationName] = useState<null | string>(null);

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  const handleLoadInvite = async (inviteToken: string) => {
    const acceptInviteUrl = `${BASE_URL}/api/v2/account_invitations/${inviteToken}`;
    const token = await getAccessTokenSilently();
    const response = await axios.get(acceptInviteUrl, requestHeader(token));

    if (response.data?.payload?.account_name) {
      setOrganizationName(response.data.payload.account_name);
      // console.log('response.data', response.data);
      setIsLoading(false);
    } else {
      setError(response);
    }
  };

  const handleAccept = async () => {
    setIsSubmitting(true);
    const acceptInviteUrl = `${BASE_URL}/api/v2/account/accept_invite`;
    const token = await getAccessTokenSilently();
    const response = await axios.post(
      acceptInviteUrl,
      {
        account: {
          invite_token: inviteToken,
          invited_email: user?.email,
        },
      },
      requestHeader(token),
    );

    if (response.data.current_account) {
      navigate(`/${routePaths.dashboard}`);
    } else {
      setError(response);
    }
  };

  useEffect(() => {
    if (inviteToken) {
      try {
        (async () => {
          await handleLoadInvite(inviteToken);
        })();
      } catch (error) {
        console.log('Error', error);
        setIsLoading(false);
      }
    } else {
      navigate(`/${routePaths.dashboard}`);
    }
  }, [inviteToken]);

  if (error) {
    return <ErrorPage />;
  }

  if (isLoading) {
    return <LoadingPage title="Preparing invite" />;
  }

  return (
    <div className="flex min-h-screen w-full flex-col text-gray-700">
      <Helmet>
        <title>Milk Video - Accept your invitation</title>
      </Helmet>
      <header className="flex-shrink bg-white">
        <div className="mx-auto px-2 sm:px-4 lg:px-8">
          <div className="relative flex h-16 justify-between">
            <div className="lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
              <div className="relative ml-4 flex-shrink-0">
                <div>
                  <div
                    className="flex rounded-md bg-white focus:outline-none"
                    id="user-menu"
                    aria-haspopup="true"
                  >
                    <div className="justify-start px-2 py-2 text-left">
                      {email ? (
                        <>
                          <p className="text-sm">Accepting invite as:</p>
                          <p className="truncate text-sm font-medium text-gray-900">
                            {email}
                          </p>
                        </>
                      ) : (
                        <p className="text-sm">Accepting invite</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0"></div>
            <div className="relative z-10 flex px-2 lg:px-0">
              <div className="flex flex-shrink-0 items-center"></div>
            </div>
          </div>
        </div>
      </header>
      <div className="flex-grow-1 mx-auto flex max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-2xl font-extrabold">
            <span className="block">
              Accept your invitation to <u>{organizationName}</u> workspace
            </span>
          </h2>
          <p className="text-md mt-4 leading-6">
            Workspaces are shared environments where teams can upload videos, organize
            clips and make sharable highlights.
          </p>
        </div>
        <div className="py-4 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="my-8 mx-auto flex w-full max-w-xs items-center justify-center">
            <div>
              <Button loading={isSubmitting} onClick={handleAccept}>
                Accept your personal invite
              </Button>
            </div>
          </div>
        </div>
        {account && (
          <div className="mx-auto max-w-xl space-y-2 text-center">
            <h2 className="text-lg font-extrabold">
              <span className="block">Or return to your current workspace</span>
            </h2>
            <AccountListItem account={account} handleAccount={() => {}} />
          </div>
        )}
      </div>
    </div>
  );
};
