import {authenticatedQuery} from './utils';
import {createApi} from '@reduxjs/toolkit/dist/query/react';

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: authenticatedQuery,
  endpoints: () => ({}),

  tagTypes: [
    'AccountFeatureFlag',
    'AccountFontUpload',
    'LandingPage',
    'Project',
    'RecentUsage',
    'RecordingForm',
    'RecordingFormContact',
    'RecordingFormSubmission',
    'Template',
    'UploadMediaClip',
    'UserProjectDownload',
    'UserUpload',
    'UserUploadFolder',
    'UserUploadTranscriptsSummary',
  ],
});
