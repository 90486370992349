import {BaseItem, SmartComponentItem} from 'features/types/canvasItemsSlice';

import {ProgressAnimationSidebar} from '../ProgressAnimation';
import {SmartComponents} from 'features/EditorCanvas/constants/ItemToolbarMapping';
import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useSelector} from 'react-redux';

export const SmartComponentSidebar = () => {
  const projectId = useProjectId();

  const selectedItem = useSelector(canvasItemsSelectedItemSelector)(projectId);

  const isSmartComponent =
    selectedItem && [ViewTypes.SmartComponent].includes(selectedItem.item.viewType);

  if (!selectedItem || !selectedItem.item || !isSmartComponent) {
    return null;
  }

  const smartComponentItem = selectedItem.item as BaseItem & SmartComponentItem;

  if (smartComponentItem.smartComponent.id === SmartComponents.ProgressAnimation) {
    return <ProgressAnimationSidebar />;
  }

  return <h1>SIDEBAR</h1>;
};
