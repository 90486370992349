import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {NonUndefined} from 'utility-types';
import classNames from 'classnames';
import {formatTime} from 'features/EditorCanvas/components/CanvasTime/utils';
import {isPresent} from 'ts-is-present';
import {useDuration} from 'features/EditorCanvas/components/CanvasTime/useDuration';

type VisibleScroll = {start: number; end: number};

const VisibleScrollContext = createContext<VisibleScroll>({start: 0, end: 0});
const SetVisibleScrollContext = createContext<(visibleScroll: VisibleScroll) => void>(
  () => {},
);

export const VisibleScrollProvider: FC = ({children}) => {
  const [visibleScroll, setVisibleScroll] = useState({start: 0, end: 0});

  return (
    <SetVisibleScrollContext.Provider value={setVisibleScroll}>
      <VisibleScrollContext.Provider value={visibleScroll}>
        {children}
      </VisibleScrollContext.Provider>
    </SetVisibleScrollContext.Provider>
  );
};

export const useVisibleScroll = () => useContext(VisibleScrollContext);
export const useSetVisibleScroll = () => useContext(SetVisibleScrollContext);

export const ChaptersContainer: FC<{
  height?: number;
  maxHeight?: number;
}> = ({children, height, maxHeight}) => {
  return (
    <div className="relative hidden w-1/3 max-w-sm flex-col overflow-scroll bg-gray-50 py-2 md:flex">
      <div className={classNames('relative min-h-full px-4')} style={{height, maxHeight}}>
        {children}
      </div>
    </div>
  );
};

export const ScrollContainer: FC<{
  height?: number;
  maxHeight?: number;
  className?: string;
}> = ({children, height, maxHeight, className}) => {
  const setVisibleScroll = useSetVisibleScroll();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const calculateVisibleScroll = useCallback(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const scroll = getScroll(scrollContainer);

    const paragraphs = getVisibleParagraphs(scrollContainer);
    if (!paragraphs) return;

    const start = getParagraphTime(paragraphs.top, scroll.top);
    const end = getParagraphTime(paragraphs.bottom, scroll.bottom);

    setVisibleScroll({start, end});
  }, [setVisibleScroll]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    const handler = () => {
      timeout = setTimeout(() => {
        calculateVisibleScroll();
      });
    };

    window.addEventListener('resize', handler);
    return () => {
      if (timeout) clearTimeout(timeout);
      window.removeEventListener('resize', handler);
    };
  }, [calculateVisibleScroll]);

  useEffect(() => {
    const id = setInterval(() => {
      calculateVisibleScroll();
    }, 2000);

    return () => clearTimeout(id);
  }, [calculateVisibleScroll]);

  return (
    <div
      className={classNames(
        'relative flex min-h-full flex-col overflow-scroll px-4 py-5 pr-6 sm:px-6',
        className,
      )}
      style={{height, maxHeight}}
      onScroll={() => calculateVisibleScroll()}
      ref={scrollContainerRef}
      data-type="scrollContainer"
    >
      {children}
    </div>
  );
};

const getPaddingTop = (element: HTMLElement) => {
  const paddingTop = window
    .getComputedStyle(element, null)
    .getPropertyValue('padding-top');

  let paddingTopInt = 0;
  if (paddingTop.includes('px')) {
    paddingTopInt = parseInt(paddingTop);
  }

  return paddingTopInt;
};

const getScroll = (element: HTMLElement) => {
  const paddingTop = getPaddingTop(element);
  const scrollBottom = element.scrollTop + element.clientHeight - paddingTop;

  return {top: element.scrollTop, bottom: scrollBottom};
};

const getVisibleParagraphs = (container: HTMLElement) => {
  const scroll = getScroll(container);

  const paragraphEls = container.querySelectorAll<HTMLElement>('[data-type="paragraph"]');

  const paragraphs = Array.from(paragraphEls)
    .map((paragraphEl, index) => {
      const dataset = paragraphEl.dataset;

      const startTime = parseInt(dataset.startTime ?? '');
      const endTime = parseInt(dataset.endTime ?? '');

      if (isNaN(startTime) || isNaN(endTime)) return null;

      const startY = paragraphEl.offsetTop;

      let endY = startY + paragraphEl.clientHeight;

      const nextParagraph = paragraphEls[index + 1];
      if (nextParagraph) {
        endY = nextParagraph.offsetTop;
      }

      return {
        startY,
        endY,
        startTime,
        endTime,
        duration: endTime - startTime,
        height: endY - startY,
      };
    })
    .filter(isPresent);

  const top = paragraphs.find(paragraph => {
    return paragraph.endY >= scroll.top;
  });

  if (!top) return;

  const bottom = [...paragraphs].reverse().find(time => {
    return time.startY <= scroll.bottom;
  });

  if (!bottom) return;

  return {top, bottom};
};

type Paragraph = NonUndefined<ReturnType<typeof getVisibleParagraphs>>['top'];

const getParagraphTime = (paragraph: Paragraph, scroll: number) => {
  const absoluteScrollY = scroll - paragraph.startY;
  let scrollPercentage = absoluteScrollY / paragraph.height;
  if (scrollPercentage > 1) scrollPercentage = 1;

  return paragraph.startTime + scrollPercentage * paragraph.duration;
};

export const VisibleTimeIndicator = ({width}: {width: number}) => {
  const visibleScroll = useVisibleScroll();
  const duration = useDuration();

  const visibleX = (visibleScroll.start / duration) * width;
  const visibleWidth = ((visibleScroll.end - visibleScroll.start) / duration) * width;

  return (
    <div
      className="absolute top-0 z-0 h-4 rounded-full bg-indigo-300 bg-opacity-50"
      style={{left: visibleX, width: visibleWidth}}
    />
  );
};

export const VisibleTimeText = () => {
  const visibleScroll = useVisibleScroll();

  return (
    <div className="absolute top-4 bottom-4 right-6 font-mono text-xs font-medium text-gray-500">
      <div className="absolute top-0 right-0">
        {formatTime(Math.max(visibleScroll.start, 0))}
      </div>
      <div className="absolute bottom-0 right-0">{formatTime(visibleScroll.end)}</div>
    </div>
  );
};
