import {AccountFontUploadsType} from 'features/types/userLibrarySlice';
import {accountDetailsSelector} from 'features/selectors/authSelectors';
import {baseApi} from './baseAPI';
import {subscribeToCable} from './utils';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';

export type UploadAccountFontData = Omit<
  AccountFontUploadsType,
  | 'id'
  | 'created_at'
  | 'updated_at'
  | 'owner_id'
  | 'account_id'
  | 'status'
  | 'discarded_at'
  | 'font_name_preview_url'
  | 'font_specimen_preview_url'
>;

const fontDataAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['AccountFontUpload']})
  .injectEndpoints({
    endpoints: builder => ({
      fetchAccountFontUploads: builder.query<
        AccountFontUploadsType[],
        {email: string; accountId: string}
      >({
        providesTags: ['AccountFontUpload'],
        query: ({email, accountId}) => ({
          url: 'account_font_uploads/list',
          data: {email, account_id: accountId},
          method: 'POST',
          errorTitle: 'Error loading fonts',
        }),
        async onCacheEntryAdded(_, api) {
          return subscribeToCable(
            {api, action: 'updated_font_name_preview_url'},
            ({account_font_upload}, draft) => {
              const newFont = account_font_upload;

              const index = draft.findIndex(font => font.id === newFont.id);
              if (index === -1) return;
              draft[index] = newFont;
            },
          );
        },
      }),
      uploadAccountFontFile: builder.mutation<
        AccountFontUploadsType,
        {
          file: UploadAccountFontData;
          email: string;
          accountId: string;
        }
      >({
        invalidatesTags: ['AccountFontUpload'],
        query: ({email, accountId, file}) => ({
          url: 'account_font_uploads',
          data: {email, account_id: accountId, account_font_upload: file},
          method: 'POST',
          errorTitle: 'Error uploading font',
        }),
        transformResponse: (response: {account_font_upload: AccountFontUploadsType}) => {
          return response.account_font_upload;
        },
      }),
      deleteAccountFontUpload: builder.mutation<
        AccountFontUploadsType,
        {id: string; accountId: string; email: string}
      >({
        invalidatesTags: ['AccountFontUpload'],
        query: ({id}) => ({
          url: `account_font_uploads/${id}`,
          method: 'DELETE',
          errorTitle: 'Error deleting font',
        }),
        transformResponse: (response: {account_font_upload: AccountFontUploadsType}) => {
          return response.account_font_upload;
        },
        async onQueryStarted({accountId, email, id}, {dispatch, queryFulfilled}) {
          const patch = dispatch(
            fontDataAPI.util.updateQueryData(
              'fetchAccountFontUploads',
              {accountId, email},
              fonts => {
                const font = fonts.find(font => font.id === id);
                if (font) font.discarded_at = new Date().toUTCString();
              },
            ),
          );

          queryFulfilled.catch(patch.undo);
        },
      }),
    }),
  });

// TODO (jacques): Create util to run queries and migrations
// with data from the store

export const useAccountFontUploads = ({
  includeDiscarded,
}: {
  includeDiscarded: boolean;
}) => {
  const {email, accountId} = useSelector(accountDetailsSelector);

  if (!accountId) {
    throw new Error('useAccountFontUploads accountId not found');
  }

  const result = fontDataAPI.useFetchAccountFontUploadsQuery(
    {email: email!, accountId},
    {skip: !email || !accountId},
  );

  let data = result.data;
  if (!includeDiscarded && data) {
    data = data.filter(font => !font.discarded_at);
  }

  return {...result, data};
};

export const useUploadAccountFont = () => {
  const {email, accountId} = useSelector(accountDetailsSelector);
  const [uploadFont, data] = fontDataAPI.useUploadAccountFontFileMutation();

  const upload = useCallback(
    (file: UploadAccountFontData) => {
      if (!email || !accountId) return;
      return uploadFont({email, accountId, file});
    },
    [email, accountId, uploadFont],
  );

  return [upload, data] as const;
};

export const useDeleteAccountFont = () => {
  const {email, accountId} = useSelector(accountDetailsSelector);
  const [deleteFont, data] = fontDataAPI.useDeleteAccountFontUploadMutation();

  const deleteFn = useCallback(
    (id: string) => {
      if (!email || !accountId) return;
      return deleteFont({email, accountId, id});
    },
    [email, accountId, deleteFont],
  );

  return [deleteFn, data] as const;
};

export const {} = fontDataAPI;
