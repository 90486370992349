import {SCENE_LAYOUTS, SceneLayoutId} from 'features/EditorCanvas/Layout/layouts';

import classNames from 'classnames';
import {forwardRef} from 'react';

const Icon = ({
  id,
  hoverVariant,
  visible,
}: {
  id: SceneLayoutId;
  hoverVariant?: boolean;
  visible?: boolean;
}) => {
  const icon = `/LayoutIcons/${id}${hoverVariant ? '_Active' : ''}.svg`;

  return (
    <div
      className={classNames(
        'absolute inset-0 transition-opacity',
        hoverVariant && 'opacity-0 hover:opacity-100',
        visible && 'opacity-100',
      )}
      style={{
        backgroundImage: `url('${icon}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
};

type LayoutButtonProps = {
  id: SceneLayoutId;
  onClick: () => void;
  active?: boolean;
  tooltip?: boolean;
  height?: number;
};

const HEIGHT = 30;
const AR = 16 / 9;

export const LayoutButton = forwardRef<HTMLButtonElement, LayoutButtonProps>(
  ({id, onClick, active, tooltip = true, height = HEIGHT}, ref) => {
    const layout = SCENE_LAYOUTS[id];

    return (
      <button
        style={{
          width: Math.ceil(height * AR),
          height,
        }}
        className="focus:outline-none relative cursor-pointer"
        data-tip={tooltip ? layout.label : undefined}
        onClick={onClick}
        ref={ref}
      >
        <Icon id={id} />
        <Icon id={id} hoverVariant visible={active} />
      </button>
    );
  },
);
