import {CanvasItem} from 'features/types/canvasItemsSlice';
import {ViewTypes} from './constants/ViewConstants';

export type SelectedItem =
  | {
      item: CanvasItem;
      id: string;
    }
  | undefined;

export const isTextComponent = (selectedItem: SelectedItem) =>
  selectedItem && [ViewTypes.Text].includes(selectedItem.item.viewType);

export const isShapeComponent = (selectedItem: SelectedItem) =>
  selectedItem && [ViewTypes.Square].includes(selectedItem.item.viewType);

export const isCaptionsComponent = (selectedItem: SelectedItem) =>
  selectedItem && [ViewTypes.CaptionClip].includes(selectedItem.item.viewType);

export const isAudioComponent = (selectedItem: SelectedItem) =>
  selectedItem && [ViewTypes.AudioClip].includes(selectedItem.item.viewType);

export const isVideoComponent = (selectedItem: SelectedItem) =>
  selectedItem &&
  ([ViewTypes.VideoClip].includes(selectedItem.item.viewType) ||
    [ViewTypes.Video].includes(selectedItem.item.viewType));

export const isImageComponent = (selectedItem: SelectedItem) =>
  selectedItem && [ViewTypes.Image].includes(selectedItem.item.viewType);

export const isSmartComponent = (selectedItem: SelectedItem) =>
  selectedItem && [ViewTypes.SmartComponent].includes(selectedItem.item.viewType);
