import {ArrowDown, Upload} from 'react-feather';
import {DashParams, routePaths} from 'routes/routesHelper';
import {PlusIcon, SearchIcon} from '@heroicons/react/outline';
import {useEffect, useMemo, useState} from 'react';
import {
  useUserProjectDownloadsQuery,
  userProjectDownloadAPI,
} from 'services/userProjectDownloadsAPI';

import {Button} from 'ui/Button';
import {DashboardContentSkeleton} from 'features/Dashboard/common/DashboardContentSkeleton';
import {DashboardContentWrap} from 'features/Dashboard/common/DashboardContentWrap';
import {DashboardHead} from 'features/Dashboard/common/DashboardHead';
import {DashboardSearchField} from 'features/Dashboard/common/DashboardSearchField';
import {Download} from 'react-feather';
import DownloadsListResults from './DownloadsListResults';
import {EmptyListMessage} from '../../shared/EmptyListMessage';
import ErrorPage from 'features/EditorCanvas/components/shared/ErrorPage';
import {Link} from 'react-router-dom';
import LoadingDownloads from './LoadingDownloads';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import cn from 'classnames';
import useDimensions from 'react-use-dimensions';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useInfiniteQuery} from 'services/useInfiniteQuery';
import {useProjectModal} from 'features/Dashboard/useProjectModal';
import {useWindowHeight} from '@react-hook/window-size';

const searchIcon = (
  <svg
    className="w-full fill-current"
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M65.625 62.5407L49.1094 45.9376C53.0577 41.1737 55.0191 35.0719 54.5863 28.8996C54.1535 22.7274 51.3597 16.959 46.7852 12.7928C42.2106 8.6265 36.207 6.38251 30.0213 6.52688C23.8356 6.67126 17.9432 9.19291 13.568 13.5681C9.19285 17.9432 6.6712 23.8356 6.52682 30.0214C6.38244 36.2071 8.62644 42.2107 12.7927 46.7852C16.959 51.3598 22.7273 54.1536 28.8996 54.5864C35.0718 55.0192 41.1736 53.0578 45.9375 49.1095L62.5406 65.6251L65.625 62.5407ZM10.9375 30.6251C10.9375 26.7313 12.0922 22.9249 14.2555 19.6873C16.4187 16.4497 19.4935 13.9263 23.0909 12.4362C26.6884 10.9461 30.6469 10.5562 34.4659 11.3159C38.2849 12.0755 41.7928 13.9506 44.5462 16.7039C47.2995 19.4573 49.1746 22.9652 49.9342 26.7842C50.6939 30.6032 50.304 34.5617 48.8139 38.1592C47.3238 41.7566 44.8004 44.8313 41.5628 46.9946C38.3252 49.1579 34.5188 50.3126 30.625 50.3126C25.4036 50.3126 20.396 48.2384 16.7039 44.5462C13.0117 40.8541 10.9375 35.8465 10.9375 30.6251V30.6251Z" />
  </svg>
);

export const DownloadsListContainer = ({isShowing}: {isShowing: boolean}) => {
  const {
    hasNextPage,
    isFetching,
    fetchNextPage,
    resultPages: pages,
    isLoading,
    isSuccess,
    isError,
  } = useInfiniteQuery(userProjectDownloadAPI.endpoints.listUserProjectDownloads, {
    getNextPageParam: lastPage => lastPage.next ?? undefined,
  });

  const userProjectDownloads = useMemo(() => {
    return pages
      ? pages
          .map(page => page.data)
          .flat()
          .filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)
          .sort((a, b) => {
            return b.updated_at - a.updated_at;
          })
      : [];
  }, [pages]);

  const {dispatch: newProjectDispatch} = useProjectModal();

  const filteredUserDownloads = userProjectDownloads ? userProjectDownloads : [];

  const header = 'No downloads, yet.';
  const description =
    'Create and export a project to and you will see your downloads here.';

  const handleNewProject = () => {
    newProjectDispatch({
      type: 'setIsModalOpen',
      payload: {
        isModalOpen: true,
      },
    });
  };

  const windowHeight = useWindowHeight();
  const [headRef, {height: headHeight}] = useDimensions();
  const [precontentRef, {height: preContentHeight}] = useDimensions();

  const contentHeight = useMemo(() => {
    return (
      windowHeight -
      (headHeight ? headHeight : 0) -
      (preContentHeight ? preContentHeight : 0)
    );
  }, [windowHeight, headHeight, preContentHeight, hasNextPage, isSuccess]);

  return (
    <DashboardContentSkeleton isShowing={isShowing} isLoading={false}>
      <div ref={headRef}>
        <DashboardHead title={'Project downloads'} />
      </div>
      <div ref={precontentRef} />
      <div
        className={'relative w-full overflow-auto'}
        style={{height: `${contentHeight}px`}}
      >
        {isLoading && <LoadingDownloads />}
        {isError && <ErrorPage />}
        {isSuccess && filteredUserDownloads.length > 0 ? (
          <main className={cn('mx-auto w-full max-w-7xl flex-1 flex-col px-4 py-6')}>
            <DownloadsListResults
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              contentHeight={contentHeight}
              userProjectDownloads={filteredUserDownloads}
            />
          </main>
        ) : (
          <div className="flex h-full flex-col items-center justify-center text-center">
            <Download className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">{header}</h3>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
            <div className="mt-6">
              <Button onClick={handleNewProject} variant="primary">
                New Project
              </Button>
            </div>
          </div>
        )}
      </div>
    </DashboardContentSkeleton>
  );
};
