import {BaseTranscriptWord, UserUploadsType} from 'features/types/userLibrarySlice';

import {Auth0RequestTokenType} from './clipsAPI';
import {BASE_REDIRECT_URL, BASE_URL} from 'constants/environment';
import {TranscriptProviders} from './userLibraryAPI';
import axios from 'axios';
import {requestHeader} from './helpers';

export async function postUploadUserFile(
  token: string,
  data: any,
  email: string,
  accountId: string,
): Promise<UserUploadsType> {
  const apiUrl = `${BASE_URL}/api/v1/user_uploads`;
  const response = await axios.post(
    apiUrl,
    {...data, email, accound_id: accountId},
    requestHeader(token),
  );
  return response.data;
}

export async function getUserUploadAnalytics(token: string, bucketKey: string) {
  const analyticsEndpoint = `${BASE_URL}/api/v1/uploads/${bucketKey}/analytics`;
  const response = await axios.get(analyticsEndpoint, requestHeader(token));
  return response.data;
}

export async function getUserUpload(token: string, id: string): Promise<UserUploadsType> {
  const uploadEndpoint = `${BASE_URL}/api/v1/user_uploads/${id}`;
  const response = await axios.get(uploadEndpoint, requestHeader(token));
  return response.data;
}

export async function getUserUploads(token: string, email: string, accountId: string) {
  const uploadsEndpoint = `${BASE_URL}/api/v1/user_uploads/list`;
  const response = await axios.post(
    uploadsEndpoint,
    {
      email,
      account_id: accountId,
    },
    requestHeader(token),
  );
  return response.data;
}

export async function moveFileUpload(
  token: string,
  folderId: string | null,
  bucketKey: string,
) {
  const url = `${BASE_URL}/api/v1/user_uploads/move`;

  const response = await axios.post(
    url,
    {
      user_upload: {
        bucket_key: bucketKey,
        folder_id: folderId,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

export async function getUserUploadUser(token: string, bucketKey: string) {
  const apiEndpoint = `${BASE_URL}/api/v1/uploads/${bucketKey}/user`;
  const response = await axios.get(apiEndpoint, requestHeader(token));
  return response.data;
}

export async function submitUserFileDelete(token: string, bucketKey: string) {
  const url = `${BASE_URL}/api/v1/user_uploads/delete`;

  const response = await axios.post(
    url,
    {
      user_upload: {
        bucket_key: bucketKey,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

export async function postNewFileName(
  token: string,
  bucketKey: string,
  fileName: string,
) {
  const url = `${BASE_URL}/api/v1/user_uploads/update_name`;

  const response = await axios.post(
    url,
    {
      user_upload: {
        bucket_key: bucketKey,
        file_name: fileName,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

export async function getUserUploadCaption(
  token: Auth0RequestTokenType,
  uploadId: string,
) {
  const uploadsCaptionEndpoint = `${BASE_URL}/api/v1/user_uploads/caption`;
  const response = await axios.post(
    uploadsCaptionEndpoint,
    {upload_id: uploadId},
    requestHeader(token),
  );

  return response.data.payload as {
    user_upload: UserUploadsType;
    upload_transcript: {
      provider: TranscriptProviders;
      transcript: BaseTranscriptWord[][];
    };
  };
}

export type AudiowaveFfprobe = {
  channels: number;
  duration_ts: number;
  sample_rate: number;
  bit_rate: string;
  duration: string;
  format_name: string;
  size: string;
  time_base: string;
};

export type AudiowaveWaveformData = {
  bits: number;
  channels: number;
  data: number[];
  length: number;
  sample_rate: number;
  samples_per_pixel: number;
  version: number;
};

export type YoutubeOembedJsonResponse = {
  author_name: string;
  author_url: string;
  html: string;
  provider_name: string;
  provider_url: string;
  thumbnail_url: string;
  title: string;
  type: string;
  version: string;
  thumbnail_height: number;
  height: number;
  thumbnail_width: number;
  width: number;
};

export async function getYoutubeOembedJson(
  youtubeId: string,
): Promise<YoutubeOembedJsonResponse> {
  const url = `https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=${youtubeId}&format=json&origin=${BASE_REDIRECT_URL}`;

  const response = await fetch(url)
    .then(data => {
      return data.json();
    })
    .catch(exception => {
      console.error(exception);
    });

  return response;
}
