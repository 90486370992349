import {Button} from 'ui/Button';
import {Form} from 'react-final-form';
import {FormTextInput} from 'ui/TextInput';
import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import NotificationInfo from 'features/Notifications/NotificationInfo';
import {store} from 'react-notifications-component';
import {string} from 'zod';
import {useState} from 'react';
import {useSubmitAnonymousUserEmailMutation} from 'services/authAPI';

export const EmailCapture = () => {
  const [submitAnonymousUserEmail] = useSubmitAnonymousUserEmailMutation();
  const [didSubmit, setDidSubmit] = useState(false);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-6 md:mt-12 lg:py-8">
        <div className="sm:py- rounded-3xl border-2 border-gray-200 bg-white py-10 px-6 sm:px-12 lg:flex lg:items-center lg:p-10">
          <div className="lg:w-0 lg:flex-1">
            <h2 className="text-xl font-bold tracking-tight text-gray-800">
              Nothing to upload, yet?
            </h2>
            <p className="mt-4 max-w-3xl text-lg text-gray-600">
              Stay in touch and get notified for future updates.
            </p>
          </div>
          <div className="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1">
            <Form
              onSubmit={async values => {
                if (values.email) {
                  await submitAnonymousUserEmail({email: values.email});
                  setDidSubmit(true);
                  window.analytics.track(
                    'Pg-Focused-Flow -- FF-Audiogram -- Evt-Clk -- Email submitted for capture',
                  );
                  store.addNotification({
                    ...NOTIFICATION_BASE,
                    content: (
                      <NotificationInfo
                        title={'Email received'}
                        message={'You are subscribed to future updates!'}
                      />
                    ),
                  });
                }
              }}
            >
              {({handleSubmit, submitting, values, valid}) =>
                didSubmit ? (
                  <div className="flex w-full">
                    <h1 className="text-center text-xl font-bold text-white">
                      🎉 Received!
                    </h1>
                  </div>
                ) : (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className="mt-4 sm:flex sm:max-w-md lg:mt-0"
                    >
                      <label htmlFor="email-address" className="sr-only">
                        Email address
                      </label>
                      <FormTextInput
                        type="email"
                        placeholder="Your email address"
                        name="email"
                        autoFocus={false}
                        validate={value => {
                          if (
                            value?.length &&
                            !string().email().safeParse(value).success
                          ) {
                            return 'Invalid email address';
                          }
                        }}
                      />
                      <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                        <Button
                          variant={values.email && valid ? 'primary' : 'secondary'}
                          type="submit"
                          loading={submitting}
                          fullWidth
                        >
                          {values.email && valid ? 'Subscribe' : 'Enter valid email'}
                        </Button>
                      </div>
                    </form>
                  </>
                )
              }
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
