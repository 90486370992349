import {DashParams, routePaths} from 'routes/routesHelper';
/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useMemo} from 'react';
import {Menu, Transition} from '@headlessui/react';

import {Button} from 'ui/Button';
import {ChevronDownIcon} from '@heroicons/react/solid';
import FileInput from '@uppy/react/src/FileInput';
import {Link} from 'react-router-dom';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {Youtube} from '@icons-pack/react-simple-icons';
import classNames from 'classnames';
import {useDashboard} from '../DashboardContext';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useProjectModal} from 'features/Dashboard/useProjectModal';
import {useUppy} from '../ContentBrandKit/UploadButton';

interface DashboardUploadsToolbarProps {}

export default function DashboardUploadsToolbar({}: DashboardUploadsToolbarProps) {
  const {dispatch: dashboardDispatch} = useDashboard();
  const handleYoutubeUpload = () => {
    dashboardDispatch({
      type: 'open youtube upload modal',
    });
  };
  const {dispatch} = useProjectModal();
  const handleNewProject = () => {
    dispatch({
      type: 'setIsModalOpen',
      payload: {
        isModalOpen: true,
      },
    });
  };
  const isNewProjectModal = useFeatureFlag('new-project-modal');

  const items = useMemo(() => {
    return [{name: 'Upload from Youtube', onClick: handleYoutubeUpload}];
  }, [handleYoutubeUpload]);

  const handleUpload = () => {
    document.querySelector('.uppy-Dashboard-browse')?.click();
  };

  return (
    <div className="z-30 border-b border-gray-200 p-4">
      <div className="space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Uploads</h3>

        <span className="shadow-xs relative z-0 inline-flex rounded-md">
          <button
            onClick={handleUpload}
            type="button"
            className="relative inline-flex h-7 items-center rounded-l-md border border-gray-300 bg-white px-3 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          >
            Upload
          </button>
          <Menu as="span" className="relative -ml-px block">
            <Menu.Button className="relative inline-flex h-7 items-center rounded-r-md border border-gray-300 bg-white px-1 py-1 text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
              <span className="sr-only">Open options</span>
              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {items.map(item => (
                    <Menu.Item key={item.name}>
                      {({active}) => (
                        <div
                          onClick={item.onClick}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'flex w-full cursor-pointer flex-row items-center justify-start space-x-2 px-3 py-1 text-xs',
                          )}
                        >
                          <Youtube className="w-5" />
                          <span>{item.name}</span>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </span>
      </div>
    </div>
  );
}
