import classNames from 'classnames';
import {useContext} from 'react';
import {Icon} from 'react-feather';
import {Button, ButtonProps, ButtonPropsContext} from './Button';

type IconButtonProps = Omit<ButtonProps, 'leftIcon' | '_px' | '_py'> & {
  label: string;
  icon: Icon;
  showLabel?: boolean;
  smallIcon?: boolean;
};

export const IconButton = (props: IconButtonProps) => {
  const context = useContext(ButtonPropsContext);

  const {
    icon: IconComponent,
    size = 'large',
    _className,
    showLabel = false,
    smallIcon = false,
    ...rest
  } = {...context?.props, ...props};

  let iconSize = size === 'large' ? 24 : 16;
  let padding = size === 'large' ? 1.5 : 2;

  if (size === 'large' && smallIcon) {
    iconSize = 20;
    padding = 2;
  }

  return (
    <Button
      {...rest}
      _className={classNames(_className, 'flex-shrink-0')}
      _px={padding}
      _py={padding}
    >
      <div className="flex flex-1 flex-col items-center justify-center">
        <IconComponent style={{width: iconSize, height: iconSize}} />
        {showLabel && <div className="mt-1 -mb-0.5 text-xs">{rest.label}</div>}
      </div>
    </Button>
  );
};
