export const COST_TO_TRANSCRIBE = 1.25;
export const COST_TO_SPEEDUP = 1;

export const STATUSES_PROJECT_FILE = {
  ADDED: 'added',
  PROGRESS: 'progress',
  UPLOADED: 'uploaded',
  HAS_DURATION: 'has duration',
  PROCESSING: 'processing',
};
