import {AppThunk, GetRootState} from 'configureStore';
import {CanvasItem, CanvasScene, DimensionType} from './types/canvasItemsSlice';
import {Color, ColorResult} from 'react-color';
import {
  fetchUserProjects,
  getProjectDataSuccess,
  submitNewProjectNameFailed,
  submitNewProjectNameStarted,
  submitNewProjectNameSuccessful,
} from './userLibrarySlice';

import {createSlice} from '@reduxjs/toolkit';
import {deselectItems} from 'features/canvasStateSlice';
import {postClientStatus} from 'api/projectClientSyncAPI';
import {postNewProjectName} from 'api/projectsAPI';
import {replaceDataItems} from 'features/canvasItemsSlice';
import {replaceDataSceneFrame} from 'features/sceneFrameSlice';
import {selectCanvasItemsProjectsCanvas} from './selectors/canvasItemsSelectors';

export type SyncStateDataType = {
  data: Partial<ClientStateType>;
  lastUpdated: any;
};

export type ProjectSyncBaseType = {
  isExportingProject: boolean;
  submitProjectNameInProgress: boolean;
  clientToServerSyncInProgress: boolean;
  serverToClientSyncInProgress: boolean;
  clientSyncVersion: number;
  lastSyncedState: SyncStateDataType;
  error: any;
};

const SYNC_STATE = {
  data: {},
  lastUpdated: null,
} as SyncStateDataType;

export const SERVER_SYNC_PROJECT_DEFAULT = {
  isExportingProject: false,
  submitProjectNameInProgress: false,
  clientToServerSyncInProgress: false,
  serverToClientSyncInProgress: false,
  clientSyncVersion: 0,
  lastSyncedState: {
    ...SYNC_STATE,
  },
  error: null,
} as ProjectSyncBaseType;

export interface ServerSyncSliceProps {
  projectDownloads: Record<string, any>;
  projects: Record<string, ProjectSyncBaseType>;
  loading: boolean;
  error: any;
}

const initialState = {
  projectDownloads: {},
  projects: {},
  loading: false,
  error: null,
} as ServerSyncSliceProps;

const serverSyncSlice = createSlice({
  name: 'serverSync',
  initialState,
  reducers: {
    setServerSyncProjectKey(state, action) {
      const {projectId} = action.payload;
      state.projects[projectId] = state.projects[projectId] || {
        ...SERVER_SYNC_PROJECT_DEFAULT,
      };
    },

    saveClientStateOfCanvasStarted(state, action) {
      const {projectId} = action.payload;
      state.projects[projectId] = state.projects[projectId] || {
        ...SERVER_SYNC_PROJECT_DEFAULT,
      };
      state.projects[projectId].clientToServerSyncInProgress = true;
    },
    saveClientStateOfCanvasSuccessful(state, action) {
      const {projectId, clientState, clientSyncVersion} = action.payload;
      state.projects[projectId].clientSyncVersion = clientSyncVersion;
      state.projects[projectId].lastSyncedState.data = clientState;
      state.projects[projectId].lastSyncedState.lastUpdated = new Date().toISOString();
      state.projects[projectId].clientToServerSyncInProgress = false;
    },
    saveClientStateOfCanvasFailed(state, action) {
      const {projectId, err} = action.payload;
      state.projects[projectId].clientToServerSyncInProgress = false;
      state.projects[projectId].error = err;
    },
    syncingProjectStoreStarted(state, action) {
      const {projectId, clientState, clientSyncVersion, isNew} = action.payload;

      const project = isNew
        ? {...SERVER_SYNC_PROJECT_DEFAULT}
        : {...state.projects[projectId]} || {...SERVER_SYNC_PROJECT_DEFAULT};
      const lastSyncedState = {
        data: clientState,
        lastUpdated: new Date().toISOString(),
      };
      project.clientSyncVersion = clientSyncVersion;
      project.lastSyncedState = lastSyncedState;
      project.serverToClientSyncInProgress = true;
      state.projects[projectId] = project;
    },
    syncingProjectStoreSuccessful(state, action) {
      const {projectId} = action.payload;
      state.projects[projectId].serverToClientSyncInProgress = false;
    },
    syncingProjectStoreFailed(state, action) {
      const {projectId, err} = action.payload;
      // console.log('projectId', projectId);
      state.projects[projectId].serverToClientSyncInProgress = false;
      state.projects[projectId].error = err;
    },
  },
});

export const {
  saveClientStateOfCanvasFailed,
  saveClientStateOfCanvasStarted,
  saveClientStateOfCanvasSuccessful,
  setServerSyncProjectKey,

  syncingProjectStoreFailed,
  syncingProjectStoreStarted,
  syncingProjectStoreSuccessful,
} = serverSyncSlice.actions;

export type ClientStateType = {
  canvasScenes: Record<string, CanvasScene>;
  items: Record<string, CanvasItem>;
  sceneFrame: {
    canvasDimensions: DimensionType;
    backgroundColor: {hex: Color} | ColorResult;
  };
};

export const updateProjectName =
  (token: string, projectId: string, newProjectName: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(
        submitNewProjectNameStarted({
          projectId,
          newProjectName,
        }),
      );
      const result = await postNewProjectName(token, projectId, newProjectName);
      dispatch(fetchUserProjects(token));
      dispatch(getProjectDataSuccess({projectId, projectData: result}));
      dispatch(submitNewProjectNameSuccessful({projectId, result}));
    } catch (err) {
      dispatch(
        submitNewProjectNameFailed({
          projectId,
          err,
        }),
      );
    }
  };

export const saveClientStateOfCanvas =
  (
    token: string,
    projectId: string,
    clientState: Partial<ClientStateType>,
    callback = () => {},
  ): AppThunk =>
  async (dispatch, getState) => {
    const state: GetRootState = getState();
    const {clientSyncVersion: currentClientSyncVersion} =
      state.serverSync.projects[projectId];
    try {
      dispatch(saveClientStateOfCanvasStarted({projectId}));

      const ownerId = state.auth.user?.id ? state.auth.user?.id : 'anonoymous';

      const result = await postClientStatus(
        token,
        projectId,
        ownerId,
        clientState,
        currentClientSyncVersion,
      );
      const {project, project_sync} = result;
      const {client_sync_version: newClientSyncVersion, client_state: savedClientState} =
        project_sync;
      // console.log('result', result);
      dispatch(
        saveClientStateOfCanvasSuccessful({
          clientState: savedClientState,
          projectId,
          clientSyncVersion: newClientSyncVersion,
        }),
      );
      callback();
    } catch (err) {
      dispatch(saveClientStateOfCanvasFailed({projectId, err}));
    }
  };

export type LatestProjectSyncType = {
  clientState: ClientStateType;
  client_sync_version: number;
  lastUpdated: number;
};

export const syncProjectState =
  (
    projectId: string,
    latestProjectSync: LatestProjectSyncType,
    callback?: () => void,
  ): AppThunk =>
  async (dispatch, getState) => {
    const {clientState, client_sync_version: clientSyncVersion} = latestProjectSync;
    const {items, sceneFrame} = clientState;
    const canvasScenes = clientState.canvasScenes || {};

    try {
      dispatch(syncingProjectStoreStarted({projectId, clientState, clientSyncVersion}));

      const state: GetRootState = getState();

      const {items: currentItems} = selectCanvasItemsProjectsCanvas(state)[projectId];
      dispatch(
        replaceDataItems({canvasScenes, currentItems, syncItems: items, projectId}),
      );
      dispatch(replaceDataSceneFrame({projectId, sceneFrame}));

      dispatch(syncingProjectStoreSuccessful({projectId}));
      dispatch(deselectItems());
    } catch (err) {
      console.error('err', err);
      dispatch(syncingProjectStoreFailed({err, projectId}));
    }

    callback?.();
  };

export default serverSyncSlice.reducer;
