import {CreateLandingPage} from 'features/Dashboard/DashboardPage/ContentLandingPages/CreateLandingPage';
// import DashboardSidebar from 'features/Dashboard/DashboardWrapWithSidebar';
import classNames from 'classnames';

export function DashboardLandingPageCreatePage() {
  return (
    <div className="flex h-screen  w-full flex-col bg-white">
      <main className="flex flex-grow">
        <div className="flex w-full  bg-white">
          {/* <DashboardSidebar isMinimized={false} /> */}
          <div
            className="h-full w-full flex-1 focus:outline-none"
            tabIndex={0}
            data-controller="projects-list"
          >
            <main className="flex h-full flex-1">
              <div
                className={classNames(
                  'flex w-full flex-row md:grid md:grid-cols-2 md:gap-4',
                )}
              >
                <div className="col-span-2 flex w-full flex-col">
                  {/* White BG start */}
                  <div
                    className="ml-2 flex flex-grow flex-col bg-white "
                    style={{
                      borderTopRightRadius: 0,
                    }}
                  >
                    <CreateLandingPage isShowing />
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </main>
    </div>
  );
}
