export default function LoadingDashboard() {
  return (
    <div className="container mx-auto flex h-screen items-start justify-start py-4 px-2 lg:px-4">
      <div className="flex h-full w-full space-x-4 bg-white">
        <div className="flex h-full w-20 flex-col space-y-4 pt-0 lg:w-64">
          <div className="flex flex-row items-center justify-between">
            <div className="flex h-6 w-36 bg-gray-50"></div>
            <div className="flex space-x-4">
              <div className="h-8 w-8 rounded-full bg-gray-50"></div>
            </div>
          </div>
          <div className="flex h-full flex-col justify-between">
            <div className="flex w-full flex-col space-y-3">
              <div className="flex h-10 w-full overflow-hidden rounded-md bg-gray-50"></div>
              <div className="flex h-10 w-full overflow-hidden rounded-md bg-gray-50"></div>
              <div className="flex h-10 w-full overflow-hidden rounded-md bg-gray-50"></div>
            </div>
            <div className="flex w-full flex-col space-y-3">
              <div className="flex h-10 w-full overflow-hidden rounded-md bg-gray-50"></div>
              <div className="flex h-10 w-full overflow-hidden rounded-md bg-gray-50"></div>
            </div>
          </div>
        </div>
        <div className="flex h-full w-full flex-col justify-start space-y-4 pt-0">
          <div className="flex flex-row justify-between">
            <div className="flex h-10 w-64 bg-gray-50"></div>
            <div className="flex w-full justify-end space-x-4">
              <div className="flex h-8 w-48 bg-gray-50"></div>
              <div className="flex h-8 w-32 bg-gray-50"></div>
            </div>
          </div>
          <div className="flex h-full flex-col justify-between space-y-2">
            <div className="flex w-full flex-col space-y-4">
              <div className="flex h-64 w-full bg-gray-50"></div>
              <div className="flex h-10 w-full bg-gray-50"></div>
              <div className="flex h-10 w-full bg-gray-50"></div>
              <div className="flex h-10 w-full bg-gray-50"></div>
            </div>
            <div className="flex h-10 w-full bg-gray-50"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
