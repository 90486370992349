import {ClockIcon, ColorSwatchIcon, UserAddIcon} from '@heroicons/react/outline';
import {DashParams, routePaths} from 'routes/routesHelper';
import {
  authAccountAccountSelector,
  authAccountCurrentSettingsSelector,
  authAccountPlanSelector,
  authAccountUsersSelector,
  authProcessorIdSelector,
  authSelector,
  authUsersSelector,
} from 'features/selectors/authSelectors';

import {Button} from 'ui/Button';
import {DashboardSidebarContainer} from 'features/Dashboard/DashboardWrapWithSidebar';
import {Divider} from 'features/EditorCanvas/Sidebar/SidebarMenu';
import {File} from 'react-feather';
import Gravatar from 'react-gravatar';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {useSelector} from 'react-redux';

export const HomeAccountSummary = () => {
  const account = useSelector(authAccountAccountSelector);
  const plan = useSelector(authAccountPlanSelector);

  // console.log('plan', plan);
  // console.log('account', account);

  return (
    <>
      {/* <div>
        <h2 className="text-sm font-semibold leading-7 text-gray-700 sm:truncate sm:text-base">
          {account?.name}
        </h2>
        <div className="flex flex-col py-4">
          <dt className="order-2 text-sm font-medium text-gray-500">{plan?.data} plan</dt>
          <dd className="order-1 text-2xl font-extrabold text-indigo-600 sm:text-3xl">
            {auth.accountWrapper?.plan.}
          </dd>
        </div>
      </div>
      <Divider size="small" /> */}
      <AccountUsersStatus />

      <AccountPlanStatus />
    </>
  );
};

const AccountPlanStatus = () => {
  const auth = useSelector(authSelector);
  const plan = useSelector(authAccountPlanSelector);
  const numberOfProjects = 2;

  // console.log('account_usage', auth?.accountWrapper?.account_usage);
  const {
    file_data_uploaded: fileDataUploaded,
    media_encoded_seconds: mediaEncodedSeconds,
    project_count: projectCount,
    transcript_processed_seconds: transcriptProcessedSeconds,
    upload_count: uploadCount,
  } = auth?.accountWrapper?.account_usage
    ? auth.accountWrapper.account_usage
    : {
        file_data_uploaded: 0,
        media_encoded_seconds: 0,
        project_count: 0,
        transcript_processed_seconds: 0,
        upload_count: 0,
      };

  // console.log('fileDataUploaded', fileDataUploaded);
  // console.log('mediaEncodedSeconds', mediaEncodedSeconds);
  // console.log('projectCount', projectCount);
  // console.log('uploadCount', uploadCount);
  // console.log('transcriptProcessedSeconds', transcriptProcessedSeconds);

  // file_data_uploaded
  // media_encoded_seconds
  // project_count
  // transcript_processed_seconds
  // upload_count
  const numberOfMinutes = Math.ceil(mediaEncodedSeconds / 60);
  const planTargetProject = 3;
  const planTargetMinutes = 120;

  if (plan) {
    return <div />;
  }

  return (
    <>
      <Divider size="small" />
      <div className="space-y-2 py-6">
        <h2 className="pb-2 text-sm font-semibold leading-7 text-gray-700 sm:truncate sm:text-sm">
          Starter team overview
        </h2>
        <div className="space-y-3 text-gray-800">
          <div className="flex items-center justify-start space-x-2">
            <div>
              <ClockIcon className="h-5 w-5 rounded-full text-gray-500" />
            </div>
            <div className="text-xs">
              {numberOfMinutes} of {planTargetMinutes} Milk minutes used
            </div>
          </div>
          <div className="flex items-center justify-start space-x-2">
            <div>
              <File className="h-5 w-5 rounded-full text-gray-500" />
            </div>
            <div className="text-xs">
              {projectCount} of {planTargetProject} Milk projects used
            </div>
          </div>
          <div className="flex items-center justify-start space-x-2">
            <div>
              <ColorSwatchIcon className="h-5 w-5 rounded-full text-gray-500" />
            </div>
            <Link to={`/${routePaths.dashboard}/${DashParams.BrandKitParam}`}>
              <div className="text-xs">Brand kit (Professional only)</div>
            </Link>
          </div>
        </div>
      </div>
      <Divider />
      <p className="text-xs">
        <Link
          className="text-indigo-600"
          to={`/${routePaths.dashboard}/${DashParams.SettingsParam}/billing`}
        >
          Upgrade your account
        </Link>{' '}
        for increased minutes, projects and customizable branding.
      </p>
    </>
  );
};

const AccountUsersStatus = () => {
  const users = useSelector(authUsersSelector);
  const {setInviteModalOpen} = DashboardSidebarContainer.useContainer();

  return (
    <div className="space-y-2 py-6">
      <h2 className="pb-2 text-sm font-semibold leading-7 text-gray-700 sm:truncate sm:text-sm">
        Members
      </h2>
      <div className="space-y-2">
        {users?.map((user, index) => {
          return (
            <div className="flex items-center justify-start space-x-2" key={user.id}>
              <div>
                <Gravatar className="h-6 w-6 rounded-full" email={user.email} />
              </div>
              <div className="text-xs">{`${user.first_name} ${user.last_name}`}</div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-1 flex-col pt-1">
        <Button
          variant="inline"
          onClick={evt => {
            evt.preventDefault();
            setInviteModalOpen(true);
          }}
          _className={classNames('text-xs bg-white hover:bg-white text-indigo-500')}
          noPadding
          alignText="left"
          leftIcon={() => (
            <UserAddIcon
              className={classNames('mr-1 h-5 w-5 flex-shrink-0')}
              aria-hidden="true"
            />
          )}
        >
          Invite collaborators
        </Button>
      </div>
    </div>
  );
};
