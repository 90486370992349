import {formatTime, useTimeCode} from '../CanvasTime/utils';

import {HelpCircle} from 'react-feather';
import {useDuration} from '../CanvasTime/useDuration';

export function CanvasSceneBottomBar() {
  const timeCode = useTimeCode(0);
  const durationMs = useDuration();
  const [minutes, seconds] = timeCode.split(':');

  return (
    <div className="flex h-8 w-full items-center justify-between bg-white px-3">
      <div>
        <div className="space-x-1 text-xs font-medium text-gray-700">
          <span>
            {minutes}:{seconds}
          </span>
          <span>/</span>
          <span>{formatTime(durationMs)}</span>
        </div>
      </div>
      <div className="">
        <button
          onClick={() => {
            !!window.Intercom &&
              window.Intercom('showNewMessage', 'Hi! I have some feedback');
          }}
          className="group flex w-full flex-col items-center rounded-md bg-gray-50 p-0 text-xs font-medium text-gray-900"
          aria-current="page"
        >
          <HelpCircle className="h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        </button>
      </div>
    </div>
  );
}
