export default function ThumbnailTile({
  tileIndex,
  tileWidth,
  tileHeight,
  tile,
  storyboardImage,
  storyboardDimension,
}) {
  // console.log('======');
  // console.log('tileIndex', tileIndex);
  // console.log('tile', tile);
  // console.log('tile.x', tile.x);
  // console.log('tile.y', tile.y);
  // console.log('storyboardDimension.width', storyboardDimension.width);
  // console.log('storyboardDimension.height', storyboardDimension.height);

  // console.log('storyboardImage', storyboardImage);
  // console.log('tile', tile);

  if (!tile) {
    return null;
  }

  return (
    <div
      className="tile"
      style={{
        width: `${tileWidth * 0.5}px`,
        zoom: `${1}`,
      }}
    >
      <div
        className="border-r border-white"
        style={{
          backgroundImage: `url(${storyboardImage})`,
          backgroundPositionX: `${tile.x - tileWidth}px`,
          backgroundPositionY: `${-tile.y}px`,
          width: `${storyboardDimension.width * (1 / 2)}px`,
          height: `${storyboardDimension.height}px`,
          zoom: tileHeight / storyboardDimension.height,
        }}
      ></div>
    </div>
  );
}
