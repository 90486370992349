import {Loading} from 'features/EditorCanvas/Sidebar/views/Assets/Loading';
import {Outlet} from 'react-router-dom';
import {RecordingContactList} from './RecordingContactList';
import {useRecordingFormContactsQuery} from 'services/recordingContactsAPI';
import {useRecordingFormSubmissionsQuery} from 'services/recordingSubmissionsAPI';
import {useWindowHeight} from '@react-hook/window-size';

export const RecordingContactListContainer = () => {
  const {
    data: recordingFormContacts,
    isLoading: isLoadingContacts,
    isSuccess: isSuccessContacts,
  } = useRecordingFormContactsQuery();

  const {
    data: recordingFormSubmissions,
    isLoading: isLoadingSubmissions,
    isSuccess: isSuccessSubmissions,
  } = useRecordingFormSubmissionsQuery();

  const windowHeight = useWindowHeight();

  if (isLoadingContacts || isLoadingSubmissions) {
    return <Loading />;
  }

  if (!recordingFormContacts || !recordingFormSubmissions) {
    return <div>No content</div>;
  }

  return (
    <div
      className="flex min-w-0 flex-1 flex-col overflow-hidden"
      style={{
        height: windowHeight,
      }}
    >
      <div className="relative z-0 flex flex-1 overflow-hidden">
        <RecordingContactList
          selectedRecordingFormSubmissions={recordingFormSubmissions}
          recordingFormContacts={recordingFormContacts}
        />
        <Outlet />
      </div>
    </div>
  );
};
