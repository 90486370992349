import {useDispatch, useSelector} from 'react-redux';

import {MouseEventHandler} from 'react';
import {deselectItems} from 'features/canvasStateSlice';
import {sceneFrameSelector} from 'features/selectors/sceneFrameSelectors';

export default function CanvasBackground({projectId}: {projectId: string}) {
  const dispatch = useDispatch();
  const {backgroundColor} = useSelector(sceneFrameSelector)(projectId);

  // TODO: Reimplement this
  const backgroundClicked: MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();

    dispatch(deselectItems());
  };

  return (
    <div
      id="canvas-background"
      onClick={backgroundClicked}
      style={{background: backgroundColor.hex}}
      className="absolute left-0 top-0 h-full w-full"
    />
  );
}
