import { DashParams, routePaths } from 'routes/routesHelper';
import { UserUpload, useListUserUploadsQuery, userUploadAPI } from 'services/userUploadAPI';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DashboardUploadsToolbar from './DashboardUploadsToolbar';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { Uploader } from 'features/UploadsViewer/Uploader';
import { UploadsListContent } from './UploadsListContent';
import { UploadsListModals } from './UploadsListModals';
import { authUserSelector } from 'features/selectors/authSelectors';
import classNames from 'classnames';
import { format } from 'date-fns';
import { isPlayableMedia } from 'features/Dashboard/DashboardUploadDetails/utils';
import useDimensions from 'react-use-dimensions';
import { useOpenUpload } from './UploadsListResults';
import { useUserUploadFoldersQuery } from 'services/userUploadFolderAPI';
import { useWindowSize } from '@react-hook/window-size';

interface UploadsListContainerProps {
  isShowing: boolean;
}

export function UploadsListContainer({ isShowing }: UploadsListContainerProps) {
  const dispatch = useDispatch();

  const [width, heightContainer] = useWindowSize();
  const [refToolbar, { height: heightToolbar }] = useDimensions();
  const [refUploader, { height: heightUploader }] = useDimensions();
  const [refFolders, { height: heightFolders }] = useDimensions();

  const { data, isLoading, isFetching } = useListUserUploadsQuery(1);
  const { count } = data || { count: null };

  const user = useSelector(authUserSelector);

  const [modalFile, setModalFile] = useState<UserUpload | null>(null);
  const openUpload = useOpenUpload();
  const fileType = modalFile?.file_type;

  // only show dialog to clip upload if audio/video, doesn't make sense for images/other
  const showStartClippingUploadModal = Boolean(fileType && isPlayableMedia(fileType));

  const uploadTableHeight = useMemo(() => {
    let newHeight = 0;
    if (heightContainer) {
      newHeight = heightContainer;
    }
    if (heightToolbar) {
      newHeight = newHeight - heightToolbar;
    }
    if (heightUploader) {
      newHeight = newHeight - heightUploader;
    }
    if (heightFolders) {
      newHeight = newHeight - heightFolders;
    }

    return newHeight;
  }, [heightToolbar, heightContainer, heightUploader, heightFolders]);

  const handleUploadComplete = useCallback(
    userUpload => {
      // console.log('param', userUpload);
      // console.log('count', count);
      // dispatch(
      //   userUploadAPI.util.updateQueryData('listUserUploads', undefined, draftUploads => {
      //     console.log('draftUploads', draftUploads);
      //     // draftUploads.push({ id: 1, name: 'Teddy' })
      //   }),
      // );
      if (count === 0) {
        openUpload(userUpload);
        // console.log('redirect directly');
      } else {
        setModalFile(userUpload);
      }
    },
    [dispatch, userUploadAPI, count],
  );

  const onUploadCompleteRef = useRef(handleUploadComplete);
  useLayoutEffect(() => {
    onUploadCompleteRef.current = handleUploadComplete;
  }, [handleUploadComplete]);

  const shouldNotRemoveAfterUpload = false;

  return (
    <div className="h-full">
      <UploadsListModals
        showStartClippingUploadModal={showStartClippingUploadModal}
        openUpload={openUpload}
        modalFile={modalFile}
        setModalFile={setModalFile}
      />
      <div ref={refToolbar} className="relative z-10">
        <DashboardUploadsToolbar />
        {/* <UploadsListFilter /> */}
      </div>
      <div className="flex flex-col p-3" ref={refUploader}>
        <Uploader
          shouldNotRemoveAfterUpload={false}
          onUploadComplete={handleUploadComplete}
        />
      </div>
      <div ref={refFolders} className="relative z-10">
        {user && <DashboardUploadsFolders userId={user.id} />}
        {/* <UploadsListFilter /> */}
      </div>

      {/* {templatesEnabled && <TemplatePicker />} */}
      {uploadTableHeight && (
        <>
          <UploadsListContent uploadTableHeight={uploadTableHeight} />
        </>
      )}
    </div>
  );
}

const DashboardUploadsFolders = ({ userId }: { userId: string }) => {
  const {
    data: foldersData,
    isLoading,
    isFetching,
    isError,
  } = useUserUploadFoldersQuery({ userId });

  if (isLoading || isFetching || !foldersData?.folders?.length) {
    return <div />;
  }

  return (
    <div className="border-t px-4 pb-0 pt-3">
      <h2 className="text-xs font-semibold tracking-wide text-gray-800">Folders</h2>
      <ul className="mt-3 grid grid-cols-1 pb-4 gap-3 sm:grid-cols-2 sm:gap-3 lg:grid-cols-4 max-h-24 overflow-scroll">
        {foldersData?.folders.map(folder => (
          <li key={folder.name} className="col-span-1 flex rounded-md shadow-sm">
            <Link
              to={`/${routePaths.dashboard}/${DashParams.UploadsParam}/${DashParams.UploadsFolderParam}/${folder.id}`}
              className="font-medium text-gray-900 hover:text-gray-600"
            >
              <div
                className={classNames(
                  'bg-indigo-300',
                  'flex h-14 w-16 flex-shrink-0 items-center justify-center rounded-l-md text-xs font-medium text-white',
                )}
              >
                {folder.file_count} file
                {folder.file_count > 1 || folder.file_count == 0 ? 's' : ''}
              </div>
            </Link>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
              <div className="flex-1 truncate px-4 py-2 text-xs">
                <Link
                  to={`/${routePaths.dashboard}/${DashParams.UploadsParam}/${DashParams.UploadsFolderParam}/${folder.id}`}
                  className="flex flex-col font-medium text-gray-900 hover:text-gray-600"
                >
                  <span>{folder.name}</span>
                  <span className="text-gray-400">
                    Created: {format(folder.created_at, 'P')}
                  </span>
                </Link>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
