import {DashParams, routePaths} from 'routes/routesHelper';
import {ReactNode, useEffect} from 'react';
import {
  isAudioMedia,
  isPlayableMedia,
} from 'features/Dashboard/DashboardUploadDetails/utils';

import {Link} from 'react-router-dom';
import {SearchResultItemVideoPlayer} from './ResultItemVideoPlayer';
import {UploadClipListItem} from '../SearchResults';
import cn from 'classnames';
import {useSearchResultItem} from './useSearchResultItem';
import {useSearchResults} from '../useSearchResults';

export const ResultUploadParent = ({
  uploadClip,
  children,
}: {
  uploadClip: UploadClipListItem;
  children: ReactNode;
}) => {
  const {
    state: {clipIdPlaying},
    dispatch: searchResultsDispatch,
  } = useSearchResults();
  const {
    state: {videoRef},
    dispatch: searchResultItemDispatch,
  } = useSearchResultItem();

  const setIsPlaying = (newState: boolean) => {
    searchResultItemDispatch({
      type: 'updateIsPlaying',
      payload: {
        isPlaying: newState,
      },
    });
  };

  useEffect(() => {
    if (uploadClip.upload.mux_playback_id) {
      searchResultItemDispatch({
        type: 'setActivePlaybackId',
        payload: {
          activePlaybackId: uploadClip.upload.mux_playback_id,
        },
      });
    }
  }, [uploadClip]);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      searchResultsDispatch({
        type: 'addNewVideoPlayerReference',
        payload: {
          videoRef: videoRef.current,
        },
      });
    }

    return () => {
      if (videoRef && videoRef.current) {
        searchResultsDispatch({
          type: 'removeVideoPlayerReference',
          payload: {
            videoRef: videoRef.current,
          },
        });
      }
    };
  }, [videoRef]);

  useEffect(() => {
    if (videoRef && videoRef.current && videoRef.current.pause) {
      setIsPlaying(false);
      videoRef.current.pause();
    }
  }, [clipIdPlaying]);

  const fileType = uploadClip.upload.file_type;

  const isAudio = isAudioMedia(fileType);

  return (
    <div className="grid grid-cols-5 gap-2 xl:grid-cols-7">
      <div className={cn('col-span-2 flex items-start justify-center md:col-span-2')}>
        <div className="flex max-w-sm items-center justify-center">
          <SearchResultItemVideoPlayer isAudio={isAudio} />
        </div>
      </div>
      <div className={cn('col-span-3 space-y-3  py-2 md:col-span-3 xl:col-span-5')}>
        <Link
          to={`/${routePaths.dashboard}/${DashParams.UploadsParam}/${
            isPlayableMedia(fileType) ? 'media' : ''
          }/${uploadClip.upload.id}`}
        >
          <h3 className="mt-1 truncate px-3 text-sm font-medium text-gray-900 hover:opacity-75">
            {uploadClip.upload.file_name}
          </h3>
        </Link>
        {children}
      </div>
    </div>
  );
};
