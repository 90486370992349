export const TableHeader = ({headers}) => {
  return (
    <thead className="select-none bg-gray-50">
      <tr>
        {headers.map((header, key) => {
          return (
            <th
              key={key}
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              {header}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
