import {BeakerIcon} from '@heroicons/react/outline';
import {useState} from 'react';
import {ArrowLeft, ArrowRight} from 'react-feather';
import {Button} from 'ui/Button';
import {Spinner} from 'ui/Spinner';
import {useWizardState} from '../State';
import {FocusedFlowToolbar} from '../Toolbar';
import {StepHeading, TwoColumns, useUpload} from '../utils';
import {DesignStepOptions} from './DesignStepOptions';

export const DesignStep = () => {
  const {upload} = useUpload();
  const {previousStep, nextStep} = useWizardState();

  const [debugLayout, setDebugLayout] = useState(false);

  if (!upload) {
    return (
      <div className="flex justify-center py-9">
        <Spinner size={32} />
      </div>
    );
  }

  return (
    <TwoColumns preview={{debug: debugLayout}}>
      <StepHeading title="Customize your clip" />
      <DesignStepOptions upload={upload} />
      <FocusedFlowToolbar>
        <Button variant="secondary" leftIcon={ArrowLeft} onClick={() => previousStep()}>
          Go back
        </Button>
        <div className="flex items-center space-x-4">
          {import.meta.env.DEV && (
            <>
              <Button
                variant="secondary"
                leftIcon={BeakerIcon}
                onClick={() => setDebugLayout(!debugLayout)}
              >
                Debug layout ({debugLayout ? 'on' : 'off'})
              </Button>
              {/* <Button
                variant="secondary"
                leftIcon={BeakerIcon}
                onClick={async () => {
                  const projectIds = await createProjects([clip.id]);
                  if (!projectIds) return;

                  window.open(`/canvas/${projectIds[0]}`, '_blank');
                }}
              >
                Open canvas
              </Button> */}
            </>
          )}
          <Button variant="primary" rightIcon={ArrowRight} onClick={() => nextStep()}>
            Next
          </Button>
        </div>
      </FocusedFlowToolbar>
    </TwoColumns>
  );
};
