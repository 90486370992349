import {
  canvasItemsSelectedItemIdsSelector,
  canvasItemsSelectedItemSelector,
} from 'features/selectors/canvasStateSelectors';

import {CloseSidebar} from 'features/EditorCanvas/Sidebar/CloseSidebar';
import DimensionPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/DimensionPicker';
import PositionPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/PositionPicker';
import RotationPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/RotationPicker';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useSelector} from 'react-redux';

export const AudioComponentSidebar = () => {
  const projectId = useProjectId();

  const selectedItem = useSelector(canvasItemsSelectedItemSelector)(projectId);
  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);
  if (!selectedItem || selectedItemIds.length === 0) {
    return <CloseSidebar />;
  }

  return (
    <>
      <PositionPicker />
      <DimensionPicker />
      <RotationPicker />
    </>
  );
};
