import {
  ItemLayerSources,
  ViewTypes,
  ViewTypesMedia,
} from 'features/EditorCanvas/constants/ViewConstants';
import {TimeMilliSeconds, TimeSeconds} from './userLibrarySlice';

import {CanvasLayerDepedency} from 'features/canvasStateSlice';
import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {LayerItemStyleType} from 'features/EditorCanvas/constants/ItemConstants';
import {PayloadAction} from '@reduxjs/toolkit';
import {SerializedTime} from 'features/Common/Time';
import {SmartComponents} from 'features/EditorCanvas/constants/ItemToolbarMapping';

export type DimensionHeightType = number;
export type DimensionWidthType = number;

export type PositionTopType = number;
export type PositionLeftType = number;

export type DimensionType = {
  height: DimensionHeightType;
  width: DimensionWidthType;
};

export type PositionType = {
  top: PositionTopType;
  left: PositionLeftType;
};

export type SceneTimingType = {
  offsetMs: TimeMilliSeconds;
  durationMs: TimeMilliSeconds;
};

/** @deprecated */
export type PxClipPath =
  | {
      type: 'rectangle';
      top: number;
      left: number;
      right: number;
      bottom: number;
    }
  | {
      type: 'circle';
      radius: number;
      centerX: number;
      centerY: number;
    };

/** @deprecated */
export type PercentClipPath =
  | {
      type: 'rectangle';
      top: string;
      left: string;
      right: string;
      bottom: string;
    }
  | {
      type: 'circle';
      radius: string;
      centerX: string;
      centerY: string;
    };

/** @deprecated */
export type ClipPath = PxClipPath | PercentClipPath;

export type StoredCrop =
  | {
      type: 'rectangle';
      top: number | string;
      left: number | string;
      right: number | string;
      bottom: number | string;
    }
  | {
      type: 'circle';
      radius: number | string;
      centerX: number | string;
      centerY: number | string;
      originalAspectRatio: number | string;
    };

export type ParsedCrop =
  | {
      type: 'rectangle';
      top: number;
      left: number;
      right: number;
      bottom: number;
    }
  | {
      type: 'circle';
      radius: number;
      centerX: number;
      centerY: number;
      originalAspectRatio: number;
    };

export type CropType = StoredCrop['type'];

export type ItemSource = {
  id: string;
  type: ItemLayerSources;
  playbackId: string;
  timing: {
    duration: SerializedTime;
    offset: SerializedTime;
  };
};

export type BaseItem = {
  sceneId: string;
  layerName: string;
  viewType: ViewTypes | ViewTypesMedia;
  position: PositionType;
  order: number;
  dimension: DimensionType;
  rotation: number;
  style: LayerItemStyleType;
  fontVariant: string | number;
  playLengthSeconds: TimeSeconds;
  timeOffsetSeconds: TimeSeconds;
  itemSourceId?: string;
  itemSource?: ItemLayerSources;
  sceneTiming?: SceneTimingType;
  crop: StoredCrop;
  /** @deprecated */
  clipPath?: ClipPath;
  groupId?: string;
  loading?: CanvasLayerDepedency[];
  error?: Error;
  locked?: boolean;
  source?: ItemSource;
  transcriptId?: string;
  language?: LanguageId;
};

export type ImageItem = {
  viewType: ViewTypes.Image;
  url: string;
};

export type TextSizeMode = 'auto' | 'customWidth';

export type TextAlign = 'left' | 'center' | 'right' | 'justify';

export const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900] as const;

export type FontWeight = typeof fontWeights[number];

export type TextItemStyle = {
  color: string;
  fontSize: string;
  fontWeight?: FontWeight;
  fontFamily: string;
  background: string;
  lineHeight: number | string;
};

export type TextItem = {
  viewType: ViewTypes.Text;
  text: string;
  sizeMode?: TextSizeMode;
  fontVariant: string | number;
  style: TextItemStyle;
};

export type CaptionItemStyle = LayerItemStyleType;

export type CaptionItem = {
  viewType: ViewTypes.Caption;
  text: string;
  layerName: string;
  fontVariant: string | number;
  style: CaptionItemStyle;
  position: PositionType;
  dimension: DimensionType;
  itemSourceId: string;
  itemSource: ItemLayerSources;
};

export type PlayableItem = {
  playLengthSeconds: TimeSeconds;
  timeOffsetSeconds: TimeSeconds;
  isMuted?: boolean;
  isDurationIgnored?: boolean;
};

export type VideoItem = {
  viewType: ViewTypes.Video;
  playbackId: string;
  itemSourceId: string;
  itemSource: ItemLayerSources;
} & PlayableItem;

export type VideoClipItem = {
  viewType: ViewTypes.VideoClip;
  playbackId: string;
  itemSourceId: string;
  itemSource: ItemLayerSources;
} & PlayableItem;

export type AudioClipItem = {
  viewType: ViewTypes.AudioClip;
  playbackId: string;
  itemSourceId: string;
  itemSource: ItemLayerSources;
} & PlayableItem;

export type SquareItem = {
  viewType: ViewTypes.Square;
};

export type SmartComponentItem = {
  viewType: ViewTypes.SmartComponent;
  smartComponent: {
    id: SmartComponents;
    options: Record<string, any>;
  };
};

export type CaptionClipItem = {
  viewType: ViewTypes.CaptionClip;
  itemSourceId: string;
  itemSource: ItemLayerSources;
  text: string;
  fontVariant: string | number;
  style: CaptionItemStyle;
  position: PositionType;
  dimension: DimensionType;
} & PlayableItem;

export type CanvasItem =
  | (BaseItem & VideoItem)
  | (BaseItem & VideoClipItem)
  | (BaseItem & AudioClipItem)
  | (BaseItem & ImageItem)
  | (BaseItem & TextItem)
  | (BaseItem & CaptionItem)
  | (BaseItem & SquareItem)
  | (BaseItem & SmartComponentItem)
  | (BaseItem & CaptionClipItem);

export type PlaybleCanvasItem = BaseItem & (VideoItem | VideoClipItem | AudioClipItem);

type KeysOfUnion<T> = T extends T ? keyof T : never;

export type ItemAttribute = KeysOfUnion<CanvasItem>;

export type ObjectTypeKeys<T extends object> = Exclude<
  {
    [K in keyof T]: T[K] extends object ? keyof T[K] : never;
  }[keyof T],
  never
>;

export type ItemObjectAttributes = ObjectTypeKeys<CanvasItem>;

export type MovingItem = {
  id: string;
  top: number;
  left: number;
};

export type CanvasEditMode = 'text' | 'crop' | null;

export type CanvasScene = {
  order: number;
  duration: TimeMilliSeconds;
  transitionStart: null | any;
  transitionEnd: null | any;
  isLoading: boolean;
  isOverrideDuration: boolean;
};

export type ProjectCanvas = {
  items: Record<string, CanvasItem>;
  canvasScenes: Record<string, CanvasScene>;
  trimmingItem?: string;
  offsetTimingItem?: string;
};

export type CanvasItemsState = {
  copiedItems?: string[];
  pasteCounts: Record<string, number>;
  projectsCanvas: Record<string, ProjectCanvas>;
};

export type PayloadWithId<T = {}> = PayloadAction<{projectId: string} & T>;

export type ItemWithSource = BaseItem &
  (VideoClipItem | CaptionClipItem | VideoItem | AudioClipItem | CaptionItem);
