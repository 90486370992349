import {ListFilter, listFilterContent} from './constants';

import {SearchIcon} from '@heroicons/react/outline';
import classNames from 'classnames';
import {useAccountLabels} from '../ContentSettings/Labels/useAccountLabels';
import {useClipList} from './ClipListContext';

export function FilterListItems() {
  const {
    state: {activeListFilters},
    dispatch,
  } = useClipList();

  const removeFilter = (activeListFilter: ListFilter) => {
    dispatch({
      type: 'remove active list filter',
      payload: {
        activeListFilter,
      },
    });
  };

  const {filteredLabels} = useAccountLabels();

  return (
    <>
      {activeListFilters.map((activeListFilter, index) => {
        if (activeListFilter.filter_target === 'content') {
          return (
            <span
              key={index}
              className="inline-flex select-none items-center space-x-2 whitespace-nowrap rounded-full bg-gray-100 py-1 pl-2.5 pr-1 text-xs font-medium text-gray-700"
            >
              <SearchIcon className="h-3 w-3" />
              <span>{activeListFilter.filter_value}</span>
              <button
                onClick={() => {
                  removeFilter(activeListFilter);
                }}
                type="button"
                className="focus:outline-none ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white"
              >
                <span className="sr-only">Remove large option</span>
                <svg
                  className="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
              </button>
            </span>
          );
        }
        if (activeListFilter.filter_target === 'color-label') {
          const labelColor = filteredLabels.find(
            color => color.name === activeListFilter.filter_value,
          );
          return (
            <span
              key={index}
              className="inline-flex select-none items-center space-x-2 whitespace-nowrap rounded-full bg-gray-100 py-1 pl-2.5 pr-1 text-xs font-medium text-gray-700"
            >
              <span
                className={classNames(
                  'inline-block h-2 w-2 rounded-full',
                  labelColor?.bgColor,
                )}
              />
              <span>{activeListFilter.filter_value}</span>
              <button
                onClick={() => {
                  removeFilter(activeListFilter);
                }}
                type="button"
                className="focus:outline-none ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white"
              >
                <span className="sr-only">Remove large option</span>
                <svg
                  className="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
              </button>
            </span>
          );
        }
      })}
    </>
  );
}
