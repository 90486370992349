export enum canvasScenesZindex {
  CanvasSceneDraggable = 50,
  CanvasSceneDraggableMenuItem = 50,
  MiniTimeline = 30,
  MiniTimelineSceneActive = 30,
  PlayPauseToggle = 50,
  ScenePreview = 20,
  ScenePreviewActiveCardMenu = 30,
  ScenePreviewInactiveCardMenu = 10,
  ScenesPreviewLength = 50,
  ScenesPreviewMenu = 50,
}
