import {
  BaseItem,
  CropType,
  DimensionType,
  ParsedCrop,
  PositionType,
  StoredCrop,
} from 'features/types/canvasItemsSlice';
import {
  FC,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Moveable from 'react-moveable';
import { UnreachableCaseError } from 'features/Common/UnreachableCaseError';
import { canvasStateSelector } from 'features/selectors/canvasStateSelectors';
import produce from 'immer';
import { updateCanvasEditMode } from 'features/canvasStateSlice';
// TODO (jacques): Tests
import { updateItemCrop } from 'features/canvasItemsSlice';

type Context = {
  activeItemId?: string;
  temp?: ParsedCrop;
  setTemp: (crop: ParsedCrop | undefined) => void;
  setActiveItemId: (activeItemId: string | undefined) => void;
  moveableRef: RefObject<Moveable<{}>>;
};

export const EditCropContext = createContext<Context>({
  setTemp: () => { },
  setActiveItemId: () => { },
  moveableRef: { current: null },
});

export const EditCropProvider: FC = ({ children }) => {
  const [temp, setTemp] = useState<ParsedCrop | undefined>();
  const [activeItemId, setActiveItemId] = useState<string | undefined>();
  const moveableRef = useRef<Moveable>(null);

  return (
    <EditCropContext.Provider
      value={{ activeItemId, temp, setTemp, setActiveItemId, moveableRef }}
    >
      {children}
    </EditCropContext.Provider>
  );
};

export const useIsCropActive = () => {
  const { activeItemId } = useContext(EditCropContext);
  return activeItemId !== undefined;
};

export const useEditCrop = ({
  projectId,
  item,
  itemId,
}: {
  projectId: string;
  item: BaseItem;
  itemId: string;
}) => {
  const {
    setActiveItemId,
    setTemp: setTempState,
    activeItemId,
    temp,
    moveableRef,
  } = useContext(EditCropContext);
  const dispatch = useDispatch();

  const setTemp = useCallback(
    (crop: ParsedCrop | undefined) => {
      setTempState(crop);
      setTimeout(() => {
        moveableRef.current?.updateRect();
      });
    },
    [setTempState, moveableRef],
  );
  const canvasEditMode = useSelector(canvasStateSelector)?.canvasEditMode;

  useEffect(() => {
    if (canvasEditMode !== 'crop' && temp && activeItemId) {
      dispatch(
        updateItemCrop({
          projectId,
          id: activeItemId,
          crop: temp,
        }),
      );

      setActiveItemId(undefined);
      setTemp(undefined);
    }
  }, [canvasEditMode, projectId, activeItemId, setTemp, dispatch, temp, setActiveItemId]);

  const reset = (newType: CropType) => {
    const originalBox = getOriginalBox({
      crop: item.crop,
      croppedDimension: item.dimension,
      croppedPosition: item.position,
    });
    const newCrop = getDefaultCrop(newType, originalBox.dimension);
    setTemp(newCrop);
  };

  const start = () => {
    setTemp(cropParseFloat(item.crop));
    setActiveItemId(itemId);
    dispatch(updateCanvasEditMode({ canvasEditMode: 'crop' }));
  };

  const exit = () => {
    setTemp(undefined);
    setActiveItemId(undefined);
    dispatch(updateCanvasEditMode({ canvasEditMode: null }));
  };

  const save = () => {
    if (!temp || !activeItemId) {
      throw new Error('temp and/or activeItemId is set to undefined');
    }

    dispatch(
      updateItemCrop({
        projectId,
        id: activeItemId,
        crop: temp,
      }),
    );

    exit();
  };

  const resetAndSave = () => {
    if (!activeItemId) {
      throw new Error('activeItemId is set to undefined');
    }

    dispatch(
      updateItemCrop({
        projectId,
        id: activeItemId,
        crop: defaultCropRectangle,
      }),
    );

    exit();
  };

  const currentItemActive = activeItemId === itemId;

  return {
    active: currentItemActive && temp,
    value: currentItemActive ? temp : undefined,
    update: setTemp,
    reset,
    start,
    save,
    exit,
    moveableRef,
    resetAndSave,
  };
};

export type EditCrop = ReturnType<typeof useEditCrop>;

export const clipStyleToCrop = (
  clipStyleCSS: string,
  originalDimension: DimensionType,
): ParsedCrop => {
  if (clipStyleCSS.startsWith('inset')) {
    const coordinates = clipStyleCSS
      .replace('inset(', '')
      .replace(')', '')
      .split(' ')
      .map(value => parseFloat(value) / 100);

    return {
      type: 'rectangle',
      top: coordinates[0],
      right: coordinates[1],
      bottom: coordinates[2],
      left: coordinates[3],
    };
  }

  if (clipStyleCSS.startsWith('circle')) {
    const coordinates = clipStyleCSS
      .replace('circle(', '')
      .replace(')', '')
      .split(' ')
      .map(value => parseFloat(value) / 100);

    return {
      type: 'circle',
      radius: coordinates[0],
      centerX: coordinates[2],
      centerY: coordinates[3],
      originalAspectRatio: originalDimension.width / originalDimension.height,
    };
  }

  throw new Error('Unsupported Clip Path');
};

export const getOriginalBox = ({
  crop,
  croppedDimension,
  croppedPosition,
}: {
  crop: StoredCrop;
  croppedDimension: DimensionType;
  croppedPosition: PositionType;
}) => {
  const { top, right, bottom, left } = getInset(crop);

  const totalInsetX = parseFloat(left as any) + parseFloat(right as any);
  const originalWidth = croppedDimension.width / (1 - totalInsetX);

  const totalInsetY = top + bottom;
  const originalHeight = croppedDimension.height / (1 - totalInsetY);

  const originalTop = croppedPosition.top - originalHeight * top;
  const originalLeft = croppedPosition.left - originalWidth * left;

  return {
    dimension: { width: originalWidth, height: originalHeight },
    position: { top: originalTop, left: originalLeft },
  };
};

export const getCroppedBox = ({
  currentCrop,
  newCrop,
  croppedDimension,
  croppedPosition,
}: {
  currentCrop: StoredCrop;
  newCrop: StoredCrop;
  croppedDimension: DimensionType;
  croppedPosition: PositionType;
}) => {
  const original = getOriginalBox({
    crop: cropParseFloat(currentCrop),
    croppedDimension: croppedDimension,
    croppedPosition: croppedPosition,
  })!;

  const insetPx = getInsetPx({ crop: newCrop, dimension: original.dimension });

  const croppedTop = Math.round(original.position.top + insetPx.top);
  const croppedLeft = Math.round(original.position.left + insetPx.left);

  const croppedHeight = Math.round(
    original.dimension.height - insetPx.top - insetPx.bottom,
  );
  const croppedWidth = Math.round(
    original.dimension.width - insetPx.left - insetPx.right,
  );

  return {
    dimension: { width: croppedWidth, height: croppedHeight },
    position: { top: croppedTop, left: croppedLeft },
  };
};

export const cropToClipStyle = (storedCrop?: StoredCrop) => {
  if (!storedCrop) return '';

  const crop = cropParseFloat(storedCrop);

  if (crop.type === 'rectangle') {
    return [
      'inset(',
      `${crop.top * 100}%`,
      `${crop.right * 100}%`,
      `${crop.bottom * 100}%`,
      `${crop.left * 100}%`,
      ')',
    ].join(' ');
  }

  if (crop.type === 'circle') {
    return [
      'circle(',
      `${crop.radius * 100}%`,
      'at',
      `${crop.centerX * 100}%`,
      `${crop.centerY * 100}%`,
    ].join(' ');
  }

  return '';
};

export const getInset = (storedCrop: StoredCrop) => {
  const crop = cropParseFloat(storedCrop);

  let top, left, right, bottom;

  if (crop.type === 'rectangle') {
    top = crop.top;
    left = crop.left;
    right = crop.right;
    bottom = crop.bottom;
  } else if (crop.type === 'circle') {
    // https://developer.mozilla.org/en-US/docs/Web/CSS/basic-shape
    const avgDimension = Math.sqrt(crop.originalAspectRatio ** 2 + 1) / Math.sqrt(2);
    const radiusPx = crop.radius * avgDimension;

    const centerXPx = crop.centerX * crop.originalAspectRatio;
    left = (centerXPx - radiusPx) / crop.originalAspectRatio;
    right = 1 - (radiusPx * 2) / crop.originalAspectRatio - left;

    const centerYPx = crop.centerY;
    top = centerYPx - radiusPx;
    bottom = 1 - radiusPx * 2 - top;
  } else {
    throw new UnreachableCaseError(crop);
  }

  return { top, left, right, bottom };
};

export const getInsetPx = ({
  dimension,
  crop,
}: {
  dimension: DimensionType;
  crop: StoredCrop;
}) => {
  const inset = getInset(crop);

  return {
    top: inset.top * dimension.height,
    right: inset.right * dimension.width,
    bottom: inset.bottom * dimension.height,
    left: inset.left * dimension.width,
  };
};

export const defaultCropRectangle = {
  type: 'rectangle' as const,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export const getDefaultCrop = (type: CropType, dimension: DimensionType): ParsedCrop => {
  if (type === 'circle') {
    return {
      type: 'circle',
      radius: 0.25,
      centerX: 0.5,
      centerY: 0.5,
      originalAspectRatio: dimension.width / dimension.height,
    };
  } else if (type === 'rectangle') {
    return defaultCropRectangle;
  } else {
    throw new UnreachableCaseError(type);
  }
};

export const cropParseFloat = (crop: StoredCrop) => {
  return produce(crop, draftCrop => {
    const keys = Object.keys(draftCrop);
    for (const key of keys) {
      if (key === 'type') continue;
      draftCrop[key] = parseFloat(draftCrop[key]);
    }
  }) as ParsedCrop;
};
