import {DashParams, routePaths} from 'routes/routesHelper';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';

import {allUserProjectDownloadsSelector} from 'features/selectors/userProjectDownloadSelectors';
import {startExportProject} from 'features/userProjectDownloadSlice';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useNavigate} from 'react-router-dom';

const useWaitForDownload = (
  projectId: string | undefined,
  onReady: (id: string) => void,
) => {
  const onReadyRef = useRef(onReady);
  useLayoutEffect(() => {
    onReadyRef.current = onReady;
  }, [onReady]);

  const allDownloads = useSelector(allUserProjectDownloadsSelector);
  const existingIdsRef = useRef<string[] | null>(null);

  useEffect(() => {
    if (!projectId) return;

    const projectDownloads = allDownloads[projectId]?.downloads || {};

    if (existingIdsRef.current == null) {
      existingIdsRef.current = Object.keys(projectDownloads);
    }

    const latestIds = Object.keys(projectDownloads);

    const existingIds = existingIdsRef.current!;
    const difference = existingIds
      .filter(x => !latestIds.includes(x))
      .concat(latestIds.filter(x => !existingIds.includes(x)));

    if (difference[0]) onReadyRef.current(difference[0]);
  }, [projectId, allDownloads]);
};

export const useExportProject = () => {
  const [isExporting, setIsExporting] = useState(false);
  const dispatch = useDispatch();
  const {getAccessTokenSilently} = useAuthAlt();
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState<string | undefined>();

  useWaitForDownload(projectId, id => {
    navigate(`/${routePaths.dashboard}/${DashParams.DownloadsParam}/${projectId}/${id}`);
    setIsExporting(false);
  });

  const exportProject = async (projectIdArg: string, clientSyncVersion?: number) => {
    try {
      setIsExporting(true);
      setProjectId(projectIdArg);
      const token = await getAccessTokenSilently();
      dispatch(startExportProject(token, projectIdArg, clientSyncVersion));
    } catch (e) {
      console.error(e);
      setIsExporting(false);
    }
  };

  return {exportProject, isExporting};
};
