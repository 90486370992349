import {CanvasItem, CanvasScene, DimensionType} from 'features/types/canvasItemsSlice';
import {
  ClientStateType,
  SyncStateDataType,
  saveClientStateOfCanvas,
} from 'features/serverSyncSlice';
import {DashParams, routePaths} from 'routes/routesHelper';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {GetRootState} from 'configureStore';
import WorkspaceTopNavBar from './WorkSpaceTopNavBar';
import {checkCrucialSyncStateKeys} from './utils';
import equal from 'fast-deep-equal';
import {format} from 'date-fns';
import {pick} from 'lodash';
import produce from 'immer';
import {sceneFrameSelector} from 'features/selectors/sceneFrameSelectors';
import {selectCanvasProject} from 'features/selectors/canvasItemsSelectors';
import {serverSyncProjectsSelector} from 'features/selectors/serverSyncSelectors';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useNavigate} from 'react-router-dom';

const lastSavedSynced = (lastSyncedState: SyncStateDataType): number => {
  return lastSyncedState ? lastSyncedState.lastUpdated : new Date();
};

/** Returns the keys of changed objects in ClientState */
const getChangedKeys = (
  oldState: Partial<ClientStateType>,
  newState: Partial<ClientStateType>,
) => {
  const keys: string[] = [];

  for (const key of Object.keys(oldState)) {
    if (!equal(oldState[key], newState[key])) {
      keys.push(key);
    }
  }

  return keys;
};

export const evaluateClientState = (
  canvasScenes: Record<string, CanvasScene>,
  rawItems: Record<string, CanvasItem>,
  canvasDimensions: DimensionType,
  backgroundColor: {hex: string},
) => {
  const items = produce(rawItems, draftItems => {
    for (const item of Object.values(draftItems)) {
      if (item.loading) delete item.loading;
      if (item.error) delete item.error;
    }
  });

  const clientState: ClientStateType = {
    canvasScenes,
    items,
    sceneFrame: {
      canvasDimensions,
      backgroundColor,
    },
  };
  return clientState;
};

interface WorkspaceTopNavBarContainerProps {
  projectId: string;
  preview: any;
}

function WorkspaceTopNavBarContainer({
  projectId,
  preview,
}: WorkspaceTopNavBarContainerProps) {
  const {getAccessTokenSilently} = useAuthAlt();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {canvasScenes, items} = useSelector((state: GetRootState) =>
    selectCanvasProject(state, projectId),
  );
  const {lastSyncedState} = useSelector(serverSyncProjectsSelector)(projectId);
  const {canvasDimensions, backgroundColor} = useSelector(sceneFrameSelector)(projectId);
  const lastSaved = lastSavedSynced(lastSyncedState);
  const [isSaving, setIsSaving] = useState(false);
  const [lastSavedDistance, setLastSavedDistance] = useState(
    format(new Date(lastSaved), 'M/dd h:m:s'),
  );

  const hasCrucialSyncStateKeys = checkCrucialSyncStateKeys(lastSyncedState?.data);

  const clientState = useMemo(
    () => evaluateClientState(canvasScenes, items, canvasDimensions, backgroundColor),
    [items, canvasScenes, canvasDimensions, backgroundColor],
  );
  const [lastSynced, setLastSynced] = useState(lastSyncedState.data);
  const previousClientState = useRef<ClientStateType | null>(clientState);

  useEffect(() => {
    previousClientState.current = clientState;
  });

  const syncLatestState = useAwaitSingle(
    useCallback(async () => {
      if (isSaving) return;
      if (clientState === lastSynced) return;

      const changedKeys = getChangedKeys(clientState, lastSynced);
      if (changedKeys.length === 0) return;

      setIsSaving(true);

      window.analytics.track('Canvas is syncing');

      const clientStateToSave = hasCrucialSyncStateKeys
        ? pick(clientState, changedKeys)
        : clientState;

      const token = await getAccessTokenSilently();
      dispatch(
        saveClientStateOfCanvas(token, projectId, clientStateToSave, () => {
          setIsSaving(false);
          setLastSynced(clientState);
        }),
      );
    }, [
      clientState,
      lastSynced,
      isSaving,
      getAccessTokenSilently,
      projectId,
      dispatch,
      hasCrucialSyncStateKeys,
    ]),
  );

  /**
   * Used to create a syncing between clientState and remote store.
   *
   * When a change takes place, a check is made to sync or not.
   */
  useEffect(() => {
    syncLatestState();
  }, [syncLatestState]);

  useEffect(() => {
    setLastSavedDistance(format(new Date(lastSaved), 'M/dd h:m:s'));
    window.setTimeout(() => {
      setIsSaving(false);
    }, 2000);
  }, [lastSaved, lastSyncedState]);

  const goToProjects = () => {
    window.analytics.track('Go to projects');
    navigate(`/${routePaths.dashboard}/${DashParams.ProjectsParam}`);
  };

  const saveThenGoToProjects = async () => {
    await syncLatestState();
    goToProjects();
  };

  const syncClientState = async () => {
    setIsSaving(true);
    await syncLatestState();
    setIsSaving(false);
  };

  return (
    <WorkspaceTopNavBar
      isSaving={isSaving}
      lastSavedDistance={lastSavedDistance}
      preview={preview}
      projectId={projectId}
      saveThenGoToProjects={saveThenGoToProjects}
      syncClientState={syncClientState}
    />
  );
}

const useAwaitSingle = (fn: () => Promise<any>) => {
  const promise = useRef<Promise<any> | null>(null);

  return async () => {
    if (promise.current) {
      return promise.current;
    }

    promise.current = fn();

    promise.current?.finally(() => {
      promise.current = null;
    });

    return promise.current;
  };
};

export default WorkspaceTopNavBarContainer;
