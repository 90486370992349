import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { ApiRecordingSession } from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import { BASE_URL } from 'constants/environment';
import { Button } from 'ui/Button';
import { Divider } from 'features/EditorCanvas/Sidebar/SidebarMenu';
import { Form } from 'react-final-form';
import { FormTextInput } from 'ui/TextInput';
import { LockClosedIcon } from '@heroicons/react/solid';
import { MILK_API_RECORDING_STATE } from 'features/RecordingForms/recording/components/RecordingSessionWrap';
import { ahoy } from 'features/providers/TrackingVisitProvider';
import axios from 'axios';
import classNames from 'classnames';
import { string } from 'prop-types';
import { useRecordingFormQuery } from 'services/recordingFormsAPI';
import { useUpdateRecordingInteractionResponseStateMutation } from 'services/recordingInteractionsAPI';

interface Values {
  email?: string;
  name?: string;
  company?: string;
}

export function RecordingSubmissionReview() {
  const navigate = useNavigate();
  const { recordingRoomId, recordingInteractionFormId } =
    ApiRecordingSession.useContainer();
  const [updateRecordingCollectionState] =
    useUpdateRecordingInteractionResponseStateMutation();

  const [formValues, setFormValues] = useState({
    email: '',
    name: '',
    company: '',
  });

  const handleSubmit = (values: Values) => {
    const recordingFormSubmissionUrl = `${BASE_URL}/api/v2/recording_states/${recordingRoomId}/form`;

    (async () => {
      try {
        await axios.post(recordingFormSubmissionUrl, {
          recording_room_id: recordingRoomId,
          recording_form: values,
          recording_state: {
            step: {
              type: MILK_API_RECORDING_STATE.SUBMIT_FORM,
            },
          },
        });
        navigate(`/share/f/${recordingInteractionFormId}/success`);
      } catch (error) {
        console.error('error', error);
      }
    })();
  };

  useEffect(() => {
    if (recordingInteractionFormId) {
      window.analytics.track('RecordingInteractionForm SubmissionReview opened', {
        recording_interaction_form_id: recordingInteractionFormId,
      });
      ahoy.track('RecordingInteractionForm SubmissionReview opened', {
        recording_interaction_form_id: recordingInteractionFormId,
      });
    }
  }, [recordingInteractionFormId])

  return (
    <>
      {/* Main 3 column grid */}
      <div className="flex flex-col items-center justify-center space-y-2 py-3 text-center">
        <p className="mx-auto max-w-md text-lg font-semibold">
          {`Before you go, please leave your contact details so we can get back to you....`}
        </p>
      </div>

      <div className="mx-auto grid grid-cols-1 items-start gap-2"></div>
      <div className="col-span-1 flex flex-col items-center justify-center space-y-4 py-4">
        <div className="flex w-full max-w-xs flex-row items-center justify-center space-x-3 rounded-md border px-4 py-6">
          <Form<Values>
            onSubmit={handleSubmit}
            validate={values => {
              if (!values.email?.includes('@')) {
                return { email: 'Please enter a value' };
              }
              if (!values.name?.length) {
                return { name: 'Please enter a value' };
              }
              if (!values.company?.length) {
                return { company: 'Please enter a value' };
              }
            }}
            initialValues={{
              email: formValues.email,
              name: formValues.name,
              company: formValues.company,
            }}
            validateOnBlur={false}
          >
            {({ handleSubmit, valid, submitting, pristine }) => (
              <form className={'w-full  space-y-4'} onSubmit={handleSubmit}>
                <FormTextInput
                  label="Email"
                  name="email"
                  type={'email'}
                  autoFocus
                  onChange={text => {
                    // actions.updateItemOverride({id: itemId, item: {text}});
                  }}
                />
                <FormTextInput
                  label="Full name"
                  name="name"
                  onChange={text => {
                    // actions.updateItemOverride({id: itemId, item: {text}});
                  }}
                />
                <FormTextInput
                  label="Company"
                  name="company"
                  onChange={text => {
                    // actions.updateItemOverride({id: itemId, item: {text}});
                  }}
                />
                <Divider color="bg-white" size="small" />
                <Button
                  type="submit"
                  disabled={submitting || pristine || !valid}
                  fullWidth
                  variant="primary"
                  _className="!text-base !py-3"
                  size="large"
                >
                  Send your response
                </Button>
              </form>
            )}
          </Form>
        </div>
      </div>
    </>
  );
}

const ErrorState = () => {
  return (
    <div>
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold uppercase tracking-wide text-indigo-600">
              Invalid link
            </p>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Request not found
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Please check with the original requestor for the correct link.
            </p>
            <div className="mt-6">
              If you feel this is an error,{' '}
              <a
                href="mailto:support@milkvideo.com"
                className="text-base font-medium text-indigo-600 hover:text-indigo-500"
              >
                email our support team.
              </a>
            </div>
          </div>
        </div>
      </main>
      {/* <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
      <nav className="flex justify-center space-x-4">
        <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
          Contact Support
        </a>
        <span className="inline-block border-l border-gray-300" aria-hidden="true" />
        <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
          Status
        </a>
        <span className="inline-block border-l border-gray-300" aria-hidden="true" />
        <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
          Twitter
        </a>
      </nav>
    </footer> */}
    </div>
  );
};

const LoadingState = () => {
  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <div className="mx-auto flex h-12 w-12 animate-pulse rounded-lg bg-gray-100"></div>
        </div>

        <div className="mt-8 pb-6">
          <div className="flex">
            <div className="mr-4 flex-shrink-0">
              <span className="inline-block h-10 w-10 animate-pulse overflow-hidden rounded-full bg-gray-100"></span>
            </div>
            <div className="w-full space-y-3">
              <div
                className="h-6 w-full animate-pulse rounded-lg bg-gray-200"
                style={{ maxWidth: 200 }}
              />

              <div className="flex w-full flex-col space-y-2">
                <div
                  className="h-4 w-full animate-pulse rounded-md bg-gray-200"
                  style={{ maxWidth: 300 }}
                />
                <div
                  className="h-4 w-full animate-pulse rounded-md bg-gray-200"
                  style={{ maxWidth: 300 }}
                />
                <div
                  className="h-4 w-full animate-pulse rounded-md bg-gray-200"
                  style={{ maxWidth: 300 }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-xs space-y-3">
          <div
            className="w-full animate-pulse rounded-md bg-gray-200"
            style={{ height: 56 }}
          />
          <div
            className="w-full animate-pulse rounded-md bg-gray-100"
            style={{ height: 56 }}
          />
        </div>
      </div>
    </div>
  );
};

export const RecordingPersonalMessage = ({
  color = 'dark',
  author,
  body,
  avatarUrl,
}: {
  color?: 'dark' | 'light';
  author: string;
  body: string;
  avatarUrl: string;
}) => {
  return (
    <div className="flex">
      <div className="mr-4 flex-shrink-0">
        <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
          {avatarUrl ? (
            <img src={avatarUrl} className="h-full w-full" />
          ) : (
            <svg
              className="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          )}
        </span>
      </div>
      <div className="text-sm">
        <h4
          className={classNames('font-semibold', {
            'text-white': 'light' === color,
          })}
        >
          {author} <span className="font-normal">says:</span>
        </h4>
        <p
          className={classNames('mt-1', {
            'text-white': 'light' === color,
          })}
        >
          {body}
        </p>
      </div>
    </div>
  );
};
