import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useMemo } from 'react';
import { XCircleIcon, XIcon } from '@heroicons/react/outline';
import { resetClipLabelColor, updateClipLabelColor } from 'features/userLibrarySlice';

import { AccountLabelColor } from 'features/types/userLibrarySlice';
import { FilterLabelItem } from './ModelContentSearchColorLabels';
import { MouseEvent } from 'react';
import { NOTIFICATION_BASE } from 'features/Notifications/constants';
import NotificationInfo from 'features/Notifications/NotificationInfo';
import arrayUnique from 'array-unique';
import { store } from 'react-notifications-component';
import { useAccountLabels } from '../ContentSettings/Labels/useAccountLabels';
import { useAuthAlt } from 'features/Auth/useAuthAlt';
import { useClipList } from './ClipListContext';
import { useDispatch } from 'react-redux';
import {
  useUserUploadsUploadMediaClipsQuery,
} from 'services/userUploadAPI';

export function ClipColorLabelModal({ userUploadId }: { userUploadId: string }) {
  const {
    data: userClips,
    isLoading: isLoadingClips,
    isFetching: isFetchingClips,
    // @ts-ignore
  } = useUserUploadsUploadMediaClipsQuery({ id: userUploadId }, { skip: !userUploadId });

  const { getAccessTokenSilently } = useAuthAlt();
  const { filteredLabels } = useAccountLabels();

  const product = {
    name: 'Add label',
    colors: filteredLabels,
  };

  const {
    state: { selectedIdList, openClipLabelAssignmentModal },
    dispatch: dispatchClipList,
  } = useClipList();

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatchClipList({
      type: 'update open color label modal',
      payload: {
        openClipLabelAssignmentModal: false,
      },
    });
  };

  useEffect(() => {
    if (openClipLabelAssignmentModal) {
      // setSelectedColor();
    }
  }, [selectedIdList, openClipLabelAssignmentModal]);

  /**
   * Add a color label on a target clip. Accept option to remove the selected color.
   *
   * @param newColor
   * @param shouldRemove
   */
  const updateColor = (newColor: AccountLabelColor, shouldRemove: boolean = false) => {
    const targetIds = [...selectedIdList];
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(updateClipLabelColor(token, newColor.name, targetIds, shouldRemove));
      } catch (e) {
        console.error(e);
      }
    })();
    if (!shouldRemove) {
      store.addNotification({
        ...NOTIFICATION_BASE,
        content: (
          <NotificationInfo
            title={'Label added'}
            message={'The label was successfully added.'}
          />
        ),
      });
      closeModal();
      dispatchClipList({
        type: 'update selected id list',
        payload: {
          newSelectedIdList: [],
        },
      });
    }
  };

  const activeColors = useMemo(() => {
    const labelColors =
      userClips &&
      userClips.length &&
      userClips
        .filter(
          clip =>
            clip?.labels && clip.labels.length > 0 && selectedIdList.includes(clip.id),
        )
        .map(clip => clip.labels)
        .flat()
        .map(label => label.label_value);
    if (labelColors) {
      return arrayUnique(labelColors);
    } else {
      return [];
    }
  }, [userClips, selectedIdList]);

  const removeColors = () => {
    const targetIds = [...selectedIdList];
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(resetClipLabelColor(token, targetIds));
      } catch (e) {
        console.error(e);
      }
    })();

    closeModal();
    dispatchClipList({
      type: 'update selected id list',
      payload: {
        newSelectedIdList: [],
      },
    });
  };

  const handleClear = (evt: MouseEvent) => {
    evt.stopPropagation();

    removeColors();
  };

  if (isLoadingClips) {
    return <div />
  }

  return (
    <Transition.Root show={openClipLabelAssignmentModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        onClose={closeModal}
      >
        <div
          className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
          style={{ fontSize: 0 }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-75"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden md:inline-block md:h-screen md:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-75"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-75"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex w-full transform text-left text-base transition md:my-8 md:inline-block md:max-w-sm md:px-4 md:align-middle lg:max-w-sm">
              <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                  onClick={() => closeModal()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-8 lg:gap-x-8">
                  <div className="sm:col-span-8">
                    <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12">
                      {product.name}
                    </h2>
                    <section aria-labelledby="options-heading" className="mt-2">
                      <form>
                        {/* Colors */}
                        <div>
                          <fieldset>
                            <div className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200">
                              {product.colors.map((color, personIdx) => (
                                <FilterLabelItem
                                  key={personIdx}
                                  inputClick={() => {
                                    if (!activeColors.includes(color.name)) {
                                      updateColor(color);
                                    } else {
                                      updateColor(color, true);
                                    }
                                  }}
                                  color={color}
                                  isChecked={activeColors.includes(color.name)}
                                />
                              ))}
                            </div>
                          </fieldset>
                        </div>
                        <div className="mt-4 flex w-full select-none justify-end">
                          <button
                            onClick={handleClear}
                            type="button"
                            className="focus:outline-none inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-3 py-1 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                          >
                            <XCircleIcon className="h-4 w-4" />
                            <span>Reset labels</span>
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
