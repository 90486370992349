import {UserProjectDownloadAsset} from 'pages/PreviewRender/components/SocialCardPreview';
import {baseApi} from './baseAPI';

export type UserProjectDownload = {
  is_loading: boolean;
  is_error: boolean;
  created_at: number;
  duration_ms: number;
  id: string;
  mux_playback_id: string | null;
  mux_status: string | null;
  project_slug: string;
  status: string;
  name: string;
  updated_at: number;
  url: string;
  user_project_sync_version_id: string;
};

export type UserProjectDownloadInput = {
  is_loading?: boolean;
  is_error?: boolean;
  created_at?: number;
  duration_ms?: number;
  id?: string;
  mux_playback_id?: string | null;
  mux_status?: string | null;
  project_slug?: string;
  status?: string;
  name?: string;
  updated_at?: number;
  url?: string;
  user_project_sync_version_id?: string;
};

type UserProjectDownloadUpdateInput = UpdateId<UserProjectDownloadInput> & {};

type UpdateId<T extends {}> = T & {id: string};
type UpsertId<T extends {}> = T & {id?: string};

interface ListUserProjectDownloadResponse<T> {
  page: number;
  count: number;
  items: number;
  pages: number;
  last: number;
  from: number;
  to: number;
  prev: number;
  next: number;
  data: T[];
}

export const userProjectDownloadAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['UserProjectDownload']})
  .injectEndpoints({
    endpoints: builder => ({
      listUserProjectDownloads: builder.query<
        ListUserProjectDownloadResponse<UserProjectDownload>,
        number | void
      >({
        query: (page = 1) => ({
          url: `user_project_downloads?page=${page}`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading user_project_downloads data',
          refetchOnReconnect: false,
          refetchOnFocus: false,
        }),
        providesTags: (result, error, page) =>
          result
            ? [
                // Provides a tag for each post in the current page,
                // as well as the 'PARTIAL-LIST' tag.
                ...result.data.map(({id}) => ({
                  type: 'UserProjectDownload' as const,
                  id,
                })),
                {type: 'UserProjectDownload', id: 'PARTIAL-LIST'},
              ]
            : [{type: 'UserProjectDownload', id: 'PARTIAL-LIST'}],
      }),

      userProjectDownloads: builder.query<UserProjectDownloadAsset[], void>({
        transformResponse: (response: {
          content: {
            user_project_downloads: UserProjectDownloadAsset[];
          };
        }) => {
          return response.content.user_project_downloads;
        },
        query: () => ({
          url: 'canvas_downloads',
          method: 'POST',
          errorTitle: 'Error fetching downloads',
          v2: true,
        }),
        providesTags: ['UserProjectDownload'],
      }),
      userProjectDownload: builder.query<UserProjectDownloadAsset, {downloadId: string}>({
        transformResponse: (response: {
          state: string;
          content: {
            user_project_download: UserProjectDownloadAsset;
            user_project_sync_version: {
              dimensions: {
                width: number;
                height: number;
              };
            };
          };
        }) => {
          return response.content.user_project_download;
        },
        query: ({downloadId}) => ({
          url: `canvas_download/${downloadId}`,
          method: 'GET',
          errorTitle: 'Error fetching download',
          v2: true,
        }),
        providesTags: ['UserProjectDownload'],
      }),
      updateProjectName: builder.mutation<
        UserProjectDownloadAsset,
        {downloadId: string; name: string}
      >({
        query: ({downloadId, name}) => ({
          url: `canvas_download/${downloadId}/update`,
          method: 'POST',
          errorTitle: 'Error updating download name',
          v2: true,
          data: {
            canvas_download: {
              name,
            },
          },
        }),
        invalidatesTags: ['UserProjectDownload'],
        async onQueryStarted(
          arg,
          {dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry},
        ) {},
        async onCacheEntryAdded(
          arg,
          {
            dispatch,
            getState,
            extra,
            requestId,
            cacheEntryRemoved,
            cacheDataLoaded,
            getCacheEntry,
          },
        ) {},
      }),
    }),
  });

export const {
  useListUserProjectDownloadsQuery,
  useLazyListUserProjectDownloadsQuery,
  useUpdateProjectNameMutation,
  useUserProjectDownloadsQuery,
  useUserProjectDownloadQuery,
} = userProjectDownloadAPI;
