import {AUDIO_EXTENSIONS, canPlay} from './patterns';

import FilePlayer from './FilePlayer';
import {createReactPlayer} from './ReactPlayer';
import {supportsWebKitPresentationMode} from './utils';

// Fall back to FilePlayer if nothing else can play the URL
const players = [
  {
    key: 'file',
    name: 'FilePlayer',
    canPlay: canPlay.file,
    canEnablePIP: url => {
      return (
        canPlay.file(url) &&
        (document.pictureInPictureEnabled || supportsWebKitPresentationMode()) &&
        !AUDIO_EXTENSIONS.test(url)
      );
    },
    lazyPlayer: FilePlayer,
  },
];

export default createReactPlayer(players);
