import {AnimatePresence, motion} from 'framer-motion';
import {ArrowDown, ArrowLeft, ArrowUp} from 'react-feather';
import {addItems, addNewScene} from 'features/canvasItemsSlice';
import {forwardRef, useEffect, useState} from 'react';
import {useAccountTemplatesQuery, useLatestProjectSyncQuery} from 'services/projectAPI';
import {useDispatch, useSelector} from 'react-redux';
import {useFetchCmsTemplatesQuery, useFetchTemplatesQuery} from 'services/templatesAPI';

import {Button} from 'ui/Button';
import classNames from 'classnames';
import {convertSanityEntryToTemplate} from 'features/SanityCms/helpers';
import {fadeProps} from 'features/EditorCanvas/Sidebar/Sidebar';
import {isTemplateAccountSelector} from 'features/selectors/authSelectors';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {v4} from 'uuid';

const LoadingCards = () => {
  return (
    <ResponsiveGrid>
      <div className="w-full  animate-pulse overflow-hidden">
        <div className="aspect-w-7 aspect-h-4 w-full bg-gray-100" />
        <div className="box-content h-5 bg-gray-50 px-3 py-1 text-sm font-medium" />
      </div>
      <div className="w-full  animate-pulse overflow-hidden">
        <div className="aspect-w-7 aspect-h-4 w-full bg-gray-100" />
        <div className="box-content h-5 bg-gray-50 px-3 py-1 text-sm font-medium" />
      </div>
    </ResponsiveGrid>
  );
};

type ImageCardProps = {
  image?: string;
  label: string;
  selected?: boolean;
  disabled?: boolean;
  onClickImageCard?: () => void;
};

const ImageCard = forwardRef<HTMLButtonElement, ImageCardProps>(
  ({image, label, selected = false, disabled = false, onClickImageCard}, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          'group w-full border  border-gray-100 text-left ring-0 filter transition duration-300 hover:shadow-lg focus:shadow-none focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
          disabled && 'pointer-events-none grayscale',
          selected && 'border-white shadow-none ring-2 ring-indigo-500 ring-offset-2',
        )}
        onClick={onClickImageCard}
      >
        <div
          className="aspect-w-7 aspect-h-4 w-full rounded-b-none bg-gray-200  bg-cover"
          style={{backgroundImage: `url('${image}')`}}
        />

        <div
          className={classNames(
            ' rounded-t-none px-3 py-1 text-sm font-medium text-gray-700 transition-all duration-300 group-hover:text-indigo-600 group-focus:text-indigo-600',
            selected && '!text-indigo-600',
          )}
        >
          <div className="text-xs line-clamp-1">{label}</div>
        </div>
      </button>
    );
  },
);

const ResponsiveGrid = ({
  title,
  children,
  numberOfChildrenToshow = 2,
}: {
  title?: string;
  children: JSX.Element[];
  numberOfChildrenToshow?: number;
}) => {
  const [showMore, setShowMore] = useState(false);
  const truncatedChildren = children.slice(0, numberOfChildrenToshow);

  if (children.length === 0) return null;

  return (
    <div>
      {title && (
        <h2 className="mb-1 pb-2 pt-4 text-sm font-semibold text-gray-800">{title}</h2>
      )}
      <div
        className="mb-4 grid gap-3"
        style={{gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'}}
      >
        {showMore ? children : truncatedChildren}
      </div>
      <div className="flex space-x-2">
        {children.length > numberOfChildrenToshow && (
          <Button
            size="small"
            fullWidth
            leftIcon={!showMore ? ArrowDown : ArrowUp}
            variant="inline"
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            {!showMore ? 'Show more' : 'Show less'}
          </Button>
        )}
      </div>
    </div>
  );
};

export const Templates = () => {
  const {data: cmsData} = useFetchCmsTemplatesQuery();
  const {data: milkApiTemplates} = useFetchTemplatesQuery();
  const {data: accountTemplates} = useAccountTemplatesQuery();
  const isCmsTemplatesActive = useFeatureFlag('cms-templates');
  const milkTemplates = isCmsTemplatesActive
    ? cmsData?.templates?.map(data => convertSanityEntryToTemplate(data))
    : milkApiTemplates;

  const [selectedTemplateSlug, setSelectedTemplateSlug] = useState<string | null>(null);
  const [displayTemplateDetails, setDisplayTemplateDetails] =
    useState<boolean | null>(false);

  const {data: scenes} = useLatestProjectSyncQuery(selectedTemplateSlug!, {
    skip: !selectedTemplateSlug,
  });
  const selectedTemplate =
    accountTemplates?.find(template => template.slug === selectedTemplateSlug) ||
    milkTemplates?.find(template => template.slug === selectedTemplateSlug);

  const dispatch = useDispatch();
  const projectId = useProjectId();

  const thumbnailURL = (slug: string) =>
    `https://assets-milk.s3.amazonaws.com/template-thumbnails/${slug}.png`;

  useEffect(() => {
    if (selectedTemplateSlug === scenes?.project_slug) {
      if (scenes && Object.keys(scenes.client_state.canvasScenes).length === 1) {
        const newSceneId = v4();
        const items = Object.values(scenes.client_state.items)
          .filter(item => {
            return item.sceneId === Object.keys(scenes.client_state.canvasScenes)[0];
          })
          .map(item => {
            return {...item, sceneId: newSceneId};
          });

        dispatch(
          addNewScene({
            projectId,
            newSceneId,
          }),
        );
        dispatch(addItems({projectId, items}));
        setSelectedTemplateSlug(null);
      } else {
        setDisplayTemplateDetails(true);
      }
    }
  }, [selectedTemplateSlug, scenes, dispatch, projectId]);

  return (
    <div className="w-full space-y-4 pl-2 pr-2 pt-2 pb-5">
      <AnimatePresence exitBeforeEnter initial={false}>
        {displayTemplateDetails && selectedTemplateSlug && selectedTemplate && scenes ? (
          <motion.div key="loading" {...fadeProps}>
            <Button
              size="small"
              fullWidth
              leftIcon={ArrowLeft}
              variant="inline"
              alignText="left"
              onClick={() => {
                setSelectedTemplateSlug(null);
                setDisplayTemplateDetails(false);
              }}
            >
              {selectedTemplate.name}
            </Button>
            {Object.keys(scenes.client_state.canvasScenes).length >= 2 && (
              <Button
                _className="pt-4 mt-4"
                size="small"
                fullWidth
                variant="secondary"
                alignText="center"
                onClick={() => {
                  Object.entries(scenes.client_state.canvasScenes).forEach(([id]) => {
                    const newSceneId = v4();
                    const items = Object.values(scenes.client_state.items)
                      .filter(item => {
                        return item.sceneId === id;
                      })
                      .map(item => {
                        return {...item, sceneId: newSceneId};
                      });

                    dispatch(
                      addNewScene({
                        projectId,
                        newSceneId,
                      }),
                    );
                    dispatch(addItems({projectId, items}));
                  });
                }}
              >
                Apply all scenes
              </Button>
            )}
            <ResponsiveGrid numberOfChildrenToshow={8} title="Template scenes">
              {Object.entries(scenes.client_state.canvasScenes).map(([id]) => (
                <ImageCard
                  label={id}
                  key={id}
                  onClickImageCard={() => {
                    const newSceneId = v4();
                    const items = Object.values(scenes.client_state.items)
                      .filter(item => {
                        return item.sceneId === id;
                      })
                      .map(item => {
                        return {...item, sceneId: newSceneId};
                      });

                    dispatch(
                      addNewScene({
                        projectId,
                        newSceneId,
                      }),
                    );
                    dispatch(addItems({projectId, items}));
                  }}
                />
              ))}
            </ResponsiveGrid>
          </motion.div>
        ) : (
          <div>
            {!milkTemplates || !accountTemplates ? (
              <motion.div key="loading" {...fadeProps}>
                <LoadingCards />
              </motion.div>
            ) : (
              <motion.div key="loaded" {...fadeProps}>
                <ResponsiveGrid title="Your team templates">
                  {accountTemplates.map(template => (
                    <ImageCard
                      label={template.name}
                      key={template.slug}
                      onClickImageCard={() => {
                        setSelectedTemplateSlug(template.slug);
                      }}
                    />
                  ))}
                </ResponsiveGrid>
                <ResponsiveGrid title="Professional templates">
                  {milkTemplates.map(template => (
                    <ImageCard
                      label={template.name}
                      key={template.slug}
                      image={
                        !isCmsTemplatesActive
                          ? thumbnailURL(template.slug)
                          : template.latest_preview
                      }
                      onClickImageCard={() => {
                        setSelectedTemplateSlug(template.slug);
                      }}
                    />
                  ))}
                </ResponsiveGrid>
              </motion.div>
            )}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};
