import {CaptionItemStyle, ItemWithSource} from 'features/types/canvasItemsSlice';

import LayerCaptionsClip from './LayerCaptionsClip';
import {useWordFrames} from 'features/Captions/WordFrames';

export default function LayerCaptionsClipWrap({
  fontSize,
  height,
  itemId,
  lineHeight,
  projectId,
  width,
  item,
}: {
  fontSize: string;
  height: number;
  itemId: string;
  lineHeight: number;
  projectId: string;
  width: number;
  item: ItemWithSource;
}) {
  const {wordFrames, fontFamily} = useWordFrames({item, projectId, itemId});
  const style = item.style as CaptionItemStyle;

  return (
    <div className="relative m-auto" style={{height: `${height}px`, width: `${width}px`}}>
      <LayerCaptionsClip
        sceneFrames={wordFrames || []}
        style={style}
        width={width}
        height={height}
        lineHeight={lineHeight}
        fontFamily={fontFamily}
        fontSize={fontSize}
      />
    </div>
  );
}
