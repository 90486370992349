import {Link, useParams} from 'react-router-dom';

import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import {Transition} from '@headlessui/react';
import {authAccountAccountSelector} from 'features/selectors/authSelectors';
import {routeHome} from 'routes/routesHelper';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';

interface BillingSuccessProps {
  isShowing: boolean;
}

export function BillingSuccess({isShowing}: BillingSuccessProps) {
  const {settingsDetail} = useParams() as Record<string, string>;
  const activePage = settingsDetail ? settingsDetail : 'account-preferences';
  const account = useSelector(authAccountAccountSelector);

  // console.log('activePage', activePage);

  useEffect(() => {
    if (!activePage) {
      return;
    }
    window.analytics.track('Loaded settings page', {
      settings_detail: activePage,
    });
  }, [activePage]);

  // console.log('accountWrapper', accountWrapper);

  if (!account || !account.name) {
    return <LoadingSpinner />;
  }

  // console.log('account', accountWrapper.account);

  return (
    <Transition
      appear
      show={isShowing}
      enter-active-class="transition-opacity duration-75"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-150"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <main className="flex-1 focus:outline-none" tabIndex={0}>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mt-12 max-w-prose text-lg">
            <h1>
              <span className="mt-2 block text-left text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                Your payment has been processed
              </span>
            </h1>
            <p className="mt-8 text-xl leading-8 text-gray-500">
              Thanks for hopping onboard! You should receive an email confirming your
              payment shortly.
            </p>
          </div>
          <div className="prose-lg mx-auto mt-6 text-gray-500">
            <Link
              to={routeHome}
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Back to Dashboard
            </Link>
          </div>
        </div>
      </main>
    </Transition>
  );
}
