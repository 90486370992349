import {DimensionType} from 'features/types/canvasItemsSlice';
import {expandFftFrame} from '../helper';
import {useMemo} from 'react';

export const LineYAxis = ({
  frequencyData,
  dimensions,
}: {
  frequencyData: number[];
  dimensions: DimensionType;
}) => {
  const fftFrame = useMemo(() => expandFftFrame(frequencyData), [frequencyData]);

  const {width, height} = dimensions;
  return (
    <div>
      {fftFrame.map((value, index) => {
        const barHeight = value * (height / 256) + 30;
        return (
          <div
            key={index}
            className="overflow-hidden rounded-md ease-out"
            style={{
              position: 'absolute',
              transform: `translateY(${barHeight / 2}px)`,
              bottom: `${height / 2}px`,
              left: `${(index * width) / fftFrame.length}px`,
              backgroundColor: 'white',
              height: `${barHeight}px`,
              width: `${width / fftFrame.length - 8}px`,
            }}
          />
        );
      })}
    </div>
  );
};
