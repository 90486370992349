import {
  BASE_LAYER_ITEM_STYLE,
  SAMPLE_SMART_COMPONENT,
} from 'features/EditorCanvas/constants/ItemConstants';
import {BaseItem, SmartComponentItem} from 'features/types/canvasItemsSlice';

import {Button} from 'ui/Button';
import {SmartComponents} from 'features/EditorCanvas/constants/ItemToolbarMapping';
import {addCanvasItem} from 'features/canvasItemsSlice';
import {useDispatch} from 'react-redux';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';
import {useSidebarMenu} from '../../SidebarMenuContext';

export const ButtonProgressAnimation = () => {
  const dispatch = useDispatch();
  const projectId = useProjectId();
  const {activeScene} = useScenes();
  const {dispatch: sidebarDispatch} = useSidebarMenu();

  const addNewDurationProgressItem = () => {
    window.analytics.track('Add new smart element - duration progress');

    const item = {
      ...SAMPLE_SMART_COMPONENT,
      style: {
        ...BASE_LAYER_ITEM_STYLE,
      },
      sceneId: activeScene?.id,
      smartComponent: {
        id: SmartComponents.ProgressAnimation,
        options: {
          background: '#000',
          passiveColor: 'none',
        },
      },
    } as BaseItem & SmartComponentItem;
    dispatch(addCanvasItem({projectId, item}));
    sidebarDispatch({
      type: 'update active sidebar menu',
      activeMenu: 'Layer',
    });
  };

  return (
    <Button
      onClick={addNewDurationProgressItem}
      variant="tertiary"
      fullWidth
      alignText="center"
      _className="text-left bg-gray-100"
      size="large"
    >
      <div className="flex w-full flex-col items-center justify-center text-sm">
        <svg
          className="w-full"
          viewBox="0 0 200 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x={10} y={15} width={179} height={29} rx={2} fill="black" />
          <rect x={13} y={18} width={173} height={23} rx={1} fill="white" />
          <path d="M14.5 39.5V19.5H99.5L114.5 39.5H14.5Z" fill="black" />
          <mask
            id="mask0_28_2"
            style={{maskType: 'alpha'}}
            maskUnits="userSpaceOnUse"
            x={100}
            y={19}
            width={85}
            height={21}
          >
            <rect x={100} y="19.5" width="84.5" height={20} rx="0.5" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_28_2)">
            <path
              d="M149.5 19.5L164.5 39.5L162.5 39.5L147 19.5L149.5 19.5Z"
              fill="black"
            />
            <path
              d="M167.5 19.5L182.5 39.5L180.5 39.5L165 19.5L167.5 19.5Z"
              fill="black"
            />
            <path
              d="M174.5 19.5L189.5 39.5L187.5 39.5L172 19.5L174.5 19.5Z"
              fill="black"
            />
            <path
              d="M180.5 19.5L195.5 39.5L193.5 39.5L178 19.5L180.5 19.5Z"
              fill="black"
            />
            <path
              d="M185.5 19.5L200.5 39.5L198.5 39.5L183 19.5L185.5 19.5Z"
              fill="black"
            />
            <path d="M159 19.5L174 39.5L172 39.5L156.5 19.5L159 19.5Z" fill="black" />
            <path d="M139 19.5L154 39.5L152 39.5L136.5 19.5L139 19.5Z" fill="black" />
            <path
              d="M127.5 19.5L142.5 39.5L140.5 39.5L125 19.5L127.5 19.5Z"
              fill="black"
            />
            <path d="M115 19.5L130 39.5L128 39.5L112.5 19.5L115 19.5Z" fill="black" />
          </g>
        </svg>

        <p>Progress animation</p>
      </div>
    </Button>
  );
};
