import {AudioAnalysisParams, useUserUploadQuery} from 'services/userUploadAPI';
import {CSSProperties, useEffect, useState} from 'react';
import {DimensionType, SmartComponentItem} from 'features/types/canvasItemsSlice';

import {AudioWaveformOptions} from './AudioWaveforms/constants';
import {DynamicAudioWaveformAmplitude} from './DynamicAudioWaveformAmplitude';
import {FocusedFlowAudioWaveformOptions} from 'features/FocusedFlow/LayoutCalculator';
import {GetRootState} from 'configureStore';
import {ItemLayerSources} from 'features/EditorCanvas/constants/ViewConstants';
import {getAuthToken} from 'services/utils';
import {getUserClip} from 'api/clipsAPI';
import {getUserUploadByBucketKey} from 'api/userLibraryAPI';
import {selectCanvasItemsProjectsCanvas} from 'features/selectors/canvasItemsSelectors';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useSelector} from 'react-redux';
import {userLibrarySelector} from 'features/selectors/userLibrarySelectors';
import {validate} from 'uuid';

/**
 * TODO(Lenny): Refactor the redux/clip fallback using frameContext
 *
 * If the frameContext exists, directly request clip.
 */

// TODO (jacques): We can use useTranscriptId here.
// We should create a single way to fetch the upload/clip of an item.

export function DynamicAudioWaveformAmplitudeContainer({
  style,
  options,
  dimensions,
  smartComponent,
}: {
  dimensions: DimensionType;
  style: CSSProperties;
  options: AudioWaveformOptions | FocusedFlowAudioWaveformOptions;
  smartComponent: SmartComponentItem['smartComponent'];
}) {
  const [params, setParams] = useState<AudioAnalysisParams | null>(null);
  const {userClips} = useSelector(userLibrarySelector);

  const {targetPlayableMediaId} = smartComponent.options;

  const [shouldPollUploads, setShouldPollUploads] = useState(true);
  // console.log('params', params);
  const queryResult = useUserUploadQuery(
    {id: params?.uploadId!},
    {
      pollingInterval: shouldPollUploads && params?.uploadId ? 2000 : undefined,
      skip: !params?.uploadId,
    },
  );

  const {data: targetUserUpload} = queryResult;

  useEffect(() => {
    if (targetUserUpload && targetUserUpload?.has_audio_analysis) {
      setShouldPollUploads(false);
    }
  }, [targetUserUpload]);

  const projectId = useProjectId();
  const targetItem = useSelector((state: GetRootState) => {
    const {preview} = state.sceneFrame.projects[projectId];
    const {items} = selectCanvasItemsProjectsCanvas(state)[projectId];

    return items[targetPlayableMediaId] || preview?.items[targetPlayableMediaId];
  });

  useEffect(() => {
    (async () => {
      if (!targetPlayableMediaId || !targetItem) return;

      let sourceId = targetItem.source?.id || targetItem.itemSourceId;
      if (!sourceId) return;

      const sourceType = targetItem.source?.type || targetItem.itemSource;

      if (sourceType === ItemLayerSources.Clips) {
        let userClip = userClips.find(clip => clip.id === sourceId);
        if (!userClip) {
          const token = await getAuthToken();
          userClip = await getUserClip(token, sourceId);
        }

        const {user_upload_id: userUploadId} = userClip;
        setParams({
          uploadId: userUploadId,
          rangeStart: userClip.start_time,
          rangeEnd: userClip.end_time,
        });
      } else if (sourceType === ItemLayerSources.Uploads) {
        if (!validate(sourceId)) {
          const token = await getAuthToken();
          const upload = await getUserUploadByBucketKey(token, sourceId);
          sourceId = upload.id;
        }

        if (!sourceId) return;

        const timeOffsetSeconds = parseFloat(targetItem.timeOffsetSeconds as any);
        const playLengthSeconds = parseFloat(targetItem.playLengthSeconds as any);

        setParams({
          uploadId: sourceId,
          rangeStart: timeOffsetSeconds.toString(),
          rangeEnd: (timeOffsetSeconds + playLengthSeconds).toString(),
        });
      }
    })();
  }, [targetItem, targetPlayableMediaId, userClips]);

  if (!params || !targetUserUpload) {
    return null;
  }

  return (
    <DynamicAudioWaveformAmplitude
      params={params}
      dimensions={dimensions}
      options={options}
      style={style}
      targetUserUpload={targetUserUpload}
    />
  );
}
