import {BaseItem, CanvasItem, SquareItem} from 'features/types/canvasItemsSlice';
import {Circle, Minus, Square} from 'react-feather';

import {Button} from 'ui/Button';
import {SAMPLE_SQUARE} from 'features/EditorCanvas/constants/ItemConstants';
import {addCanvasItem} from 'features/canvasItemsSlice';
import useAssetsList from '../Assets/hooks/useAssetsList';
import {useDispatch} from 'react-redux';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useRecentUsage} from 'services/recentUsageAPI';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';
import {useSidebarMenu} from '../../SidebarMenuContext';

export function ShapeElements() {
  const dispatch = useDispatch();
  const projectId = useProjectId();
  const {activeScene} = useScenes();
  const {recentUsage} = useRecentUsage();
  const {dispatch: sidebarDispatch} = useSidebarMenu();

  const addToCanvas = (item: CanvasItem) => {
    dispatch(addCanvasItem({projectId, item, style: recentUsage}));
    sidebarDispatch({
      type: 'update active sidebar menu',
      activeMenu: 'Layer',
    });
  };

  const addNewShapeSquareItem = () => {
    window.analytics.track('Add new shape square');

    const item = {
      ...SAMPLE_SQUARE,
      sceneId: activeScene?.id,
      style: {
        ...SAMPLE_SQUARE.style,
      },
      dimension: {
        width: 300,
        height: 300,
      },
    } as BaseItem & SquareItem;
    addToCanvas(item);
  };

  const addNewShapeRectangleItem = () => {
    window.analytics.track('Add new shape rectangle');

    const item = {
      ...SAMPLE_SQUARE,
      sceneId: activeScene?.id,
      style: {
        ...SAMPLE_SQUARE.style,
      },
      dimension: {
        width: 500,
        height: 200,
      },
    } as BaseItem & SquareItem;
    addToCanvas(item);
  };

  const addNewShapeLineItem = () => {
    window.analytics.track('Add new shape line');

    const item = {
      ...SAMPLE_SQUARE,
      sceneId: activeScene?.id,
      style: {
        ...SAMPLE_SQUARE.style,
        borderRadius: 200,
      },
      dimension: {
        width: 600,
        height: 20,
      },
    } as BaseItem & SquareItem;
    addToCanvas(item);
  };

  const addNewShapeCircleItem = () => {
    window.analytics.track('Add new shape circle');

    const item = {
      ...SAMPLE_SQUARE,
      sceneId: activeScene?.id,
      style: {
        ...SAMPLE_SQUARE.style,
        borderRadius: 1000,
      },
      dimension: {
        width: 400,
        height: 400,
      },
    } as BaseItem & SquareItem;
    addToCanvas(item);
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <div className="text-sm font-bold text-gray-700">{'Shapes'}</div>
        {/* <div className="text-xs font-medium text-gray-400">See all</div> */}
      </div>

      <div className="flex">
        <div className="grid w-full grid-cols-2 gap-2">
          <Button
            onClick={addNewShapeSquareItem}
            variant="tertiary"
            fullWidth
            alignText="center"
            _className="text-left bg-gray-100"
            size="large"
          >
            <div className="flex w-full items-center justify-center">
              <Square />
            </div>
          </Button>
          <Button
            onClick={addNewShapeCircleItem}
            variant="tertiary"
            fullWidth
            alignText="center"
            _className="text-left bg-gray-100"
            size="large"
          >
            <div className="flex w-full items-center justify-center">
              <Circle />
            </div>
          </Button>
          <Button
            onClick={addNewShapeLineItem}
            variant="tertiary"
            fullWidth
            alignText="center"
            _className="text-left bg-gray-100"
            size="large"
          >
            <div className="flex w-full items-center justify-center">
              <Minus />
            </div>
          </Button>
          <Button
            onClick={addNewShapeRectangleItem}
            variant="tertiary"
            fullWidth
            alignText="center"
            _className="text-left bg-gray-100"
            size="large"
          >
            <div className="flex w-full items-center justify-center">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="css-i6dzq1"
              >
                <rect x="1" y="6" width="18" height="12" rx="2" ry="2"></rect>
              </svg>
            </div>
          </Button>
        </div>
      </div>
    </>
  );
}
