import {Layout, LayoutCalculator} from './LayoutCalculator';
import {ReactNode, useState} from 'react';
import {FocusedFlowLayoutProps} from './Layout';
import {InlineCanvas} from 'pages/Canvas/InlineCanvas';
import {ScalePreview} from './LayoutUtils';
import {useCaptions} from './ClipStep/EditTranscript';
import {useWizardState} from './State';
import {useUpload} from './utils';
import {sizes} from './Constants';

export type CanvasPreviewProps = Partial<FocusedFlowLayoutProps> & {
  maxHeight?: number;
  debug?: boolean;
  overlay?: ReactNode;
};

export const FocusedFlowCanvasPreview = ({
  maxHeight,
  debug,
  overlay,
  ...overrides
}: CanvasPreviewProps) => {
  const {clip, design} = useWizardState();
  const {upload} = useUpload();

  const props = {
    design: design,
    title: design.title,
    media: upload && {upload, clip},
    ...overrides,
  };

  const [layout, setLayout] = useState<Layout | null>(null);

  const {processing, loading} = useCaptions(upload);

  return (
    <>
      {layout && (
        <ScalePreview
          dimensions={sizes[design.size].dimensions}
          className="overflow-hidden rounded-xl"
          maxHeight={maxHeight}
          overlay={overlay}
        >
          <div className="relative">
            <InlineCanvas
              items={layout.items}
              backgroundColor={layout.sceneFrame.backgroundColor.hex}
              canvasDimensions={layout.sceneFrame.canvasDimensions}
              height={240}
            />
            <div className="absolute inset-0" />
          </div>
        </ScalePreview>
      )}

      <LayoutCalculator
        {...props}
        onLayout={setLayout}
        debug={debug}
        sampleCaptions={!upload || processing || loading}
      />
    </>
  );
};
