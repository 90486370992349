import {Dialog, Transition} from '@headlessui/react';
import classNames from 'classnames';
import {createContext, FC, Fragment, RefObject, useContext, useRef} from 'react';
import {X} from 'react-feather';
import {useHotkeys} from 'react-hotkeys-hook';
import {Button, ButtonProps} from './Button';
import {IconButton} from './IconButton';

type ModalContextType = {
  initialFocusRef: RefObject<HTMLButtonElement>;
  onClose: () => void;
};

const ModalContext = createContext<ModalContextType>({
  initialFocusRef: {current: null},
  onClose: () => {},
});

type ModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  size?: '2xl' | 'xl' | 'large' | 'small';
  customContent?: boolean;
  fixedHeight?: number;
  userCanClose?: boolean;
};

export const ModalHeader = ({
  title,
  onClose,
  size,
  userCanClose,
}: Pick<ModalProps, 'title' | 'onClose' | 'size' | 'userCanClose'>) => {
  return (
    <div className="-my-1 flex items-center justify-between">
      <Dialog.Title
        className={classNames(
          'text-lg font-medium leading-6 text-gray-900',
          size === 'large' && 'text-xl',
          size === 'xl' && 'text-2xl',
          size === '2xl' && 'text-3xl',
        )}
      >
        {title}
      </Dialog.Title>
      {userCanClose && (
        <IconButton
          icon={X}
          variant="inline"
          label="Close"
          negativeMargin
          onClick={onClose}
        />
      )}
    </div>
  );
};

export const ModalContent: FC<{className?: string}> = ({className, children}) => {
  return <div className={classNames('space-y-4 p-5', className)}>{children}</div>;
};

export const Modal: FC<ModalProps> = ({
  open,
  onClose,
  children,
  title,
  size = 'small',
  customContent = false,
  fixedHeight,
  userCanClose = true,
}) => {
  useHotkeys('esc', onClose, {}, [onClose]);

  const initialFocusRef = useRef<HTMLButtonElement>(null);

  const cachedTitle = useRef(title);
  if (open) cachedTitle.current = title;

  const titleToRender = open ? title : cachedTitle.current;

  const cachedChildren = useRef(children);
  if (open) cachedChildren.current = children;

  const childrenToRender = open ? children : cachedChildren.current;

  return (
    <ModalContext.Provider value={{initialFocusRef, onClose}}>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          open={open}
          onClose={onClose}
          className="fixed inset-0 z-[9999] flex items-center justify-center transition-opacity"
          initialFocus={initialFocusRef}
        >
          <Transition.Child as={Fragment} {...fade}>
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child as={Fragment} {...fadeAndScale}>
            <div className={'max-h-screen w-full overflow-y-auto p-8'}>
              <div
                className={classNames(
                  'relative mx-auto transform overflow-hidden rounded-lg bg-white shadow-xl transition-all',
                  size === 'small' && 'max-w-sm',
                  size === 'large' && 'w-full max-w-xl',
                  size === 'xl' && 'w-full max-w-3xl',
                  size === '2xl' && 'w-full max-w-4xl',
                )}
                style={{
                  minWidth: 360,
                  height: fixedHeight,
                  maxHeight: `calc(100vh - 4rem)`,
                }}
                onKeyUp={e => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              >
                {customContent ? (
                  childrenToRender
                ) : (
                  <ModalContent>
                    <ModalHeader
                      title={titleToRender}
                      onClose={onClose}
                      size={size}
                      userCanClose={userCanClose}
                    />
                    {childrenToRender}
                  </ModalContent>
                )}
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </ModalContext.Provider>
  );
};

export type ButtonConfig = {
  label: string;
} & Pick<
  ButtonProps,
  'disabled' | 'onClick' | 'type' | 'loading' | 'destructive' | 'leftIcon' | 'rightIcon'
>;

export const ModalButtons = ({
  confirm,
  cancel,
}: {
  confirm?: ButtonConfig;
  cancel?: ButtonConfig;
}) => {
  const {initialFocusRef, onClose} = useContext(ModalContext);

  return (
    <div className="flex flex-row space-x-4">
      {cancel && (
        <Button
          variant="tertiary"
          onClick={event => {
            if (cancel.onClick) {
              return cancel.onClick(event);
            } else {
              onClose();
            }
          }}
          fullWidth
          ref={initialFocusRef}
          disabled={cancel.disabled}
          type={cancel.type}
          loading={cancel.loading}
          destructive={cancel.destructive}
          leftIcon={cancel.leftIcon}
          rightIcon={cancel.rightIcon}
        >
          {cancel.label}
        </Button>
      )}
      {confirm && (
        <Button
          variant="primary"
          fullWidth
          ref={initialFocusRef}
          onClick={confirm.onClick}
          disabled={confirm.disabled}
          type={confirm.type}
          loading={confirm.loading}
          destructive={confirm.destructive}
          leftIcon={confirm.leftIcon}
          rightIcon={confirm.rightIcon}
        >
          {confirm.label}
        </Button>
      )}
    </div>
  );
};

export const ModalDescription: FC = ({children}) => {
  return <Dialog.Description>{children}</Dialog.Description>;
};

const fade = {
  enter: 'ease-out duration-300',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'ease-in duration-200',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};

const fadeAndScale = {
  enter: 'ease-out duration-300',
  enterFrom: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
  enterTo: 'opacity-100 translate-y-0 sm:scale-100',
  leave: 'ease-in duration-200',
  leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
  leaveTo: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
};
