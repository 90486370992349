import {BASE_REDIRECT_URL, BASE_URL} from 'constants/environment';
import {authAccountPlanSelector, authSelector} from 'features/selectors/authSelectors';
import {useEffect, useState} from 'react';

import {Card} from 'features/Common/Card';
import {CardHeader} from 'features/Common/CardHeader';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import {PlanInterval} from 'features/Payments/constants';
import axios from 'axios';
import cn from 'classnames';
import {publishableStripeKey} from 'constants/environment';
import {requestHeader} from 'api/helpers';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useSelector} from 'react-redux';

const stripe = window.Stripe && window.Stripe(publishableStripeKey);

const showErrorMessage = function (message: any) {
  const errorEl = document.getElementById('error-message');
  if (errorEl) {
    errorEl.textContent = message;
    errorEl.style.display = 'block';
  }
};

// Handle any errors returned from Checkout
const handleResult = function (result: any) {
  if (result.error) {
    showErrorMessage(result.error.message);
  }
};

interface SettingsPaymentProps {
  email: string;
  customerId: string;
}

export enum PlanSelected {
  Professional = 'professional',
}

const PLAN_LIST_BLANK = {
  [PlanInterval.Monthly]: [],
  [PlanInterval.Yearly]: [],
} as Record<PlanInterval, any[]>;

export default function SettingsPayment({email, customerId}: SettingsPaymentProps) {
  const [selectedPlan, setSelectedPlan] = useState(PlanSelected.Professional);
  const [isBillingAnnual, setIsBillingAnnual] = useState(true);
  const [planList, setPlanList] = useState(PLAN_LIST_BLANK);

  const planInterval = isBillingAnnual ? PlanInterval.Yearly : PlanInterval.Monthly;
  const plan = useSelector(authAccountPlanSelector);

  const {getAccessTokenSilently} = useAuthAlt();

  const listPaymentPlansUrl = `${BASE_URL}/api/v1/payments/plans`;
  const submitPaymentUrl = `${BASE_URL}/api/v2/payments/start_checkout`;
  const customerPortalSessionUrl = `${BASE_URL}/api/v1/payments/customer_portal`;

  const handleChangeSelectedPlan = (evt: any) => {
    setSelectedPlan(evt.target.value);
  };

  useEffect(() => {
    try {
      (async () => {
        const response = await axios.get(listPaymentPlansUrl);
        setPlanList(response.data.plans);
      })();
    } catch (error) {
      console.error('error', error);
    }
  }, []);

  const handleCustomerPortalRequest = () => {
    window.analytics.track('Open customer portal to stripe from billing setting');
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.post(
          customerPortalSessionUrl,
          {
            payments: {
              customer_id: customerId,
              url_success: `${BASE_REDIRECT_URL}/settings/billing`,
            },
          },
          requestHeader(token),
        );

        const {session_url: sessionUrl} = response.data;

        window.location.href = sessionUrl;

        // console.log('sessionUrl', sessionUrl);
      } catch (error) {
        console.error('error', error);
      }
    })();
  };

  const handleSubmitPlan = () => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.post(
          submitPaymentUrl,
          {
            payments: {
              plan: selectedPlan,
              plan_interval: planInterval,
              customer_email: email,
              customer_id: customerId,
              url_cancel: `${BASE_REDIRECT_URL}/settings/billing/cancel`,
              url_success: `${BASE_REDIRECT_URL}/settings/billing/success`,
            },
          },
          requestHeader(token),
        );

        const sessionReponse = response.data;

        stripe &&
          stripe
            .redirectToCheckout({
              sessionId: sessionReponse.session.id,
            })
            .then(handleResult);
      } catch (error) {
        console.error('error', error);
      }
    })();
  };

  useEffect(() => {
    window.analytics.track('Loaded settings page - viewed billing page');
    // (async () => {
    //   try {
    //     const response = await axios.post(`${BASE_URL}/api/v1/payments/secure`, {
    //       email,
    //     });
    //     // console.log('response', response);
    //     const { email_hash: emailHashParam } = response.data;
    //     setEmailHash(emailHashParam);
    //   } catch (error) {
    //     console.error('error', error);
    //   }
    // })();
  }, []);

  const planListArr = planList[planInterval];

  if (!planListArr.length) {
    return (
      <div className="mt-6 flex justify-center space-y-6">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="mt-6 space-y-6">
      {!plan && (
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-yellow-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">Please pick a plan below.</p>
            </div>
          </div>
        </div>
      )}

      {plan ? (
        <section aria-labelledby="plan_heading">
          <div id="payments" className="space-y-6">
            <Card>
              <CardHeader subtitle="" title="Your plan" />
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <p className="max-w-lg text-sm font-medium text-gray-900">
                  Plans are managed in Stripe.
                </p>
                <p className="mt-1 max-w-lg text-sm text-gray-500">
                  Use the button below to make changes to your current plan, payment
                  methods, billing information, or view your billing history.
                </p>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  onClick={handleCustomerPortalRequest}
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-800 focus:ring-offset-2"
                >
                  View customer portal
                </button>
              </div>
            </Card>
            <Card>
              <CardHeader subtitle={''} title="Urgent question?" />
              <div className="bg-white px-4 py-3 text-right sm:px-6">
                <button
                  onClick={() => {
                    !!window.Intercom &&
                      window.Intercom(
                        'showNewMessage',
                        'I have a question about billing',
                      );
                  }}
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Send a message
                </button>
              </div>
            </Card>
          </div>
        </section>
      ) : (
        <section aria-labelledby="plan_heading">
          <div id="payments" className="space-y-6">
            <Card>
              <CardHeader subtitle={''} title="Pick a plan" />
              <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                <p className="text-sm">
                  Select the paid plan below. For more details, view the{' '}
                  <a className="text-indigo-500" href="https://milkvideo.com/pricing">
                    pricing page here
                  </a>
                  .
                </p>
                <fieldset>
                  <legend className="sr-only">Pricing plans</legend>
                  <ul className="relative -space-y-px rounded-md bg-white">
                    {planList[planInterval].map((plan, planIndex) => (
                      <li key={`plan-index-${planIndex}`}>
                        <div
                          className={cn(
                            'relative flex flex-col border p-4 md:grid md:grid-cols-2 md:pl-4 md:pr-6',
                            {
                              'rounded-tl-md rounded-tr-md': planIndex === 0,
                              'rounded-bl-md rounded-br-md':
                                planIndex === planList[planInterval].length - 1,
                            },
                          )}
                        >
                          <label className="flex cursor-pointer items-center text-sm">
                            <input
                              name={`pricing_plan`}
                              value={plan.name.toLowerCase()}
                              checked={plan.name.toLowerCase() === selectedPlan}
                              type="radio"
                              onClick={handleChangeSelectedPlan}
                              className="h-4 w-4 cursor-pointer border-gray-300 text-orange-500 focus:ring-gray-900"
                              aria-describedby={`plan-option-pricing-${planIndex} plan-option-limit-${planIndex}`}
                            />
                            <span className="ml-3 font-medium text-gray-900">
                              {plan.name}
                            </span>
                          </label>
                          <p
                            id="plan-option-pricing-0"
                            className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
                          >
                            {/* On: "text-orange-900", Off: "text-gray-900" */}
                            <span className="font-medium">
                              $
                              {isBillingAnnual
                                ? Math.round(plan.amount / 12)
                                : plan.amount}{' '}
                              / seat / mo{' '}
                            </span>
                            {/* On: "text-orange-700", Off: "text-gray-500" */}
                            <span>
                              (${isBillingAnnual ? plan.amount : plan.amount * 12} / yr)
                            </span>
                          </p>
                          {/* On: "text-orange-700", Off: "text-gray-500" */}
                        </div>
                      </li>
                    ))}
                  </ul>
                </fieldset>
              </div>
              <div className="align-center flex flex-row justify-between space-x-6 bg-gray-50 px-4 py-3 text-right sm:px-6">
                <div className="align-center flex items-center justify-center">
                  {/* Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" */}
                  <button
                    onClick={() => {
                      setIsBillingAnnual(!isBillingAnnual);
                    }}
                    type="button"
                    className={cn(
                      ' relative inline-flex h-6 w-11 flex-shrink-0 rotate-180 transform cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                      {
                        'bg-indigo-600': isBillingAnnual,
                        'bg-gray-200 ': !isBillingAnnual,
                      },
                    )}
                    aria-pressed="false"
                    aria-labelledby="annual-billing-label"
                  >
                    <span className="sr-only">Use setting</span>
                    {/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
                    <span
                      aria-hidden="true"
                      className={cn(
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        {
                          'translate-x-5': isBillingAnnual,
                          'translate-x-0 ': !isBillingAnnual,
                        },
                      )}
                    />
                  </button>
                  <span className="ml-3" id="annual-billing-label">
                    <span className="text-sm font-medium  ">Annual billing </span>
                    <span className="text-sm text-gray-500">(Save 20%)</span>
                  </span>
                </div>
                <div className="space-x-4">
                  <span
                    onClick={handleCustomerPortalRequest}
                    className="inline-flex cursor-pointer items-center justify-center text-sm font-medium text-gray-700 underline"
                  >
                    Customer portal
                  </span>
                  <button
                    onClick={handleSubmitPlan}
                    className="inline justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-800 focus:ring-offset-2"
                  >
                    Add payment method
                  </button>
                </div>
              </div>
            </Card>
            <Card>
              <CardHeader subtitle="" title="Have a billing question?" />
              <div className="bg-white px-4 py-3 text-right sm:px-6">
                <button
                  onClick={() => {
                    !!window.Intercom &&
                      window.Intercom(
                        'showNewMessage',
                        'I have a question about billing',
                      );
                  }}
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Send a message
                </button>
              </div>
            </Card>
          </div>
        </section>
      )}
    </div>
  );
}
