import {DashParams, routePaths} from 'routes/routesHelper';
import {Folder, Youtube} from 'react-feather';

import {AccountsType} from 'features/types/authSlice';
import {FileNav} from './FileNav';
import FileToolbar from './FileToolbar';
import FolderNav from './FolderNav';
import UploadIcon from '../../shared/UploadIcon';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import {UserUpload} from 'services/userUploadAPI';
import {UserUploadFolder} from 'services/userUploadFolderAPI';
import {UserUploadFolders} from 'features/types/userLibrarySlice';
import {authSelector} from 'features/selectors/authSelectors';
import {format} from 'date-fns';
import {isPlayableMedia} from 'features/Dashboard/DashboardUploadDetails/utils';
import {useDashboard} from '../DashboardContext';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

const FILE_NAME_SHORTENING_LENGTH = 35;

function startAndEnd(str: string) {
  if (!str) {
    return str;
  }
  if (str.length > FILE_NAME_SHORTENING_LENGTH) {
    return `${str.substr(0, 24)}...${str.substr(str.length - 7, str.length)}`;
  }
  return str;
}

export const useOpenUpload = () => {
  const navigate = useNavigate();

  return function openUpload(userUpload: UserUpload) {
    const {bucket_key: bucketKey, file_type: fileType} = userUpload;

    if (isPlayableMedia(fileType)) {
      navigate(
        `/${routePaths.dashboard}/${DashParams.UploadsParam}/${DashParams.UploadsMediaParam}/${userUpload.id}`,
      );
    } else {
      navigate(`/${routePaths.dashboard}/${DashParams.UploadsParam}/${userUpload.id}`);
    }
  };
};

interface UploadsListResultsProps {
  /** Current folder */
  folder: UserUploadFolder | undefined;
  /* Folders within the current folder */
  subFolders: UserUploadFolders;
  userClips: UploadMediaClipType[];
  userUploads: UserUpload[];
}

export function UploadsListResults({
  folder,
  userClips,
  userUploads,
  subFolders,
}: UploadsListResultsProps) {
  const {dispatch: dashboardDispatch} = useDashboard();
  const handleYoutubeUpload = () => {
    dashboardDispatch({
      type: 'open youtube upload modal',
    });
  };
  const navigate = useNavigate();
  const handleNewFolder = () => {
    dashboardDispatch({
      type: 'open new folder modal',
    });
  };

  const openUpload = useOpenUpload();

  const openUploadFolder = (id: string) => {
    navigate(
      `/${routePaths.dashboard}/${DashParams.UploadsParam}/${DashParams.UploadsFolderParam}/${id}`,
    );
  };

  const {accounts} = useSelector(authSelector);

  const activeAccounts = accounts as AccountsType[];

  if (!activeAccounts) {
    return null;
  }

  const fileIcon = (
    <svg
      className="w-full fill-current"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M48.1248 17.5001V51.0417C48.1248 57.4876 42.904 62.7084 36.4582 62.7084C30.0123 62.7084 24.7915 57.4876 24.7915 51.0417V14.5834C24.7915 12.6495 25.5597 10.7949 26.9272 9.42743C28.2946 8.05997 30.1493 7.29175 32.0832 7.29175C34.017 7.29175 35.8717 8.05997 37.2392 9.42743C38.6066 10.7949 39.3748 12.6495 39.3748 14.5834V45.2084C39.3748 46.8126 38.0623 48.1251 36.4582 48.1251C34.854 48.1251 33.5415 46.8126 33.5415 45.2084V17.5001H29.1665V45.2084C29.1665 47.1423 29.9347 48.9969 31.3022 50.3644C32.6696 51.7318 34.5243 52.5001 36.4582 52.5001C38.392 52.5001 40.2467 51.7318 41.6142 50.3644C42.9816 48.9969 43.7498 47.1423 43.7498 45.2084V14.5834C43.7498 8.13758 38.529 2.91675 32.0832 2.91675C25.6373 2.91675 20.4165 8.13758 20.4165 14.5834V51.0417C20.4165 59.9084 27.5915 67.0834 36.4582 67.0834C45.3248 67.0834 52.4998 59.9084 52.4998 51.0417V17.5001H48.1248Z" />
    </svg>
  );

  const clipCount = (uploadId: string) =>
    userClips.filter(clip => clip.user_upload_id === uploadId).length;
  const header = 'All uploads that you have access to will show up here.';

  // Build list of all files/folders inside the current folder
  const resultListItems = [
    ...userUploads.map(upload => ({...upload, isFolder: false})),
    ...subFolders.map(uploadFolder => ({...uploadFolder, isFolder: true})),
  ];

  // File can be moved to any sub folder if in the "home" folder
  const moveFileDestinations = (!folder && subFolders) || [];

  return userUploads.length + subFolders.length ? (
    <div className="flex h-full w-full flex-col border-b border-gray-200">
      <FileToolbar
        folder={folder}
        handleNewFolder={handleNewFolder}
        handleYoutubeUpload={handleYoutubeUpload}
      />

      <table className="min-w-full divide-y divide-gray-200">
        <thead className="select-none">
          <tr className="select-none ">
            <th className="bg-white px-4 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-400">
              File Name
            </th>
            <th className="bg-white px-4 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-400">
              Created
            </th>
            <th className="bg-white px-4 py-3" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 overflow-y-scroll bg-white">
          {resultListItems
            .sort((a, b) => (a.isFolder ? -1 : b.created_at - a.created_at))
            .map((fileRow, index) => {
              const isUserUploadFolder = fileRow.isFolder;
              if (isUserUploadFolder) {
                const userUploadFolder = fileRow as UserUploadFolder;

                return (
                  <tr
                    // data-key={userUpload.key}
                    onClick={evt => {
                      window.analytics.track('Click user folder', {
                        total_user_upload_count: userUploads.length,
                      });
                      // console.log('userUploadFolder.id', userUploadFolder.id);
                      openUploadFolder(userUploadFolder.id);
                    }}
                    key={`${userUploadFolder.id}-${index}`}
                    className="cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50"
                  >
                    <td className="whitespace-nowrap px-4 py-4 text-sm font-medium leading-5 text-gray-700">
                      <div className="flex flex-row items-center text-gray-500">
                        <Folder className="mr-2 scale-90 transform opacity-80" />
                        <span className="text-gray-600">
                          {startAndEnd(userUploadFolder.name)}
                        </span>
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-4 py-4 text-sm leading-5 text-gray-700">
                      {/* {userUpload.created_at &&
                        format(new Date(userUpload.created_at), 'M/dd/yyyy')} */}
                    </td>

                    <td className="whitespace-nowrap px-4 py-4 text-right text-sm font-medium leading-5">
                      <FolderNav
                        hideMove={false}
                        hideRename={false}
                        hideDelete={false}
                        folder={userUploadFolder}
                      />
                    </td>
                  </tr>
                );
              } else {
                const userUpload: UserUpload = fileRow as UserUpload;
                return (
                  <tr
                    // data-key={userUpload.key}
                    onClick={evt => {
                      window.analytics.track('Click user upload and open', {
                        total_user_upload_count: userUploads.length,
                      });
                      openUpload(userUpload);
                    }}
                    key={`${userUpload.id}-${index}`}
                    className="cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50"
                  >
                    <td className="whitespace-nowrap px-4 py-4 text-sm font-medium leading-5 text-gray-700">
                      <div className="flex flex-row items-center text-gray-500">
                        <UploadIcon fileType={userUpload.file_type} />
                        <span className="text-gray-600">
                          {startAndEnd(userUpload.file_name)}
                        </span>
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-4 py-4 text-sm leading-5 text-gray-700">
                      {userUpload.created_at &&
                        format(new Date(userUpload.created_at), 'M/dd/yyyy')}
                    </td>

                    <td className="whitespace-nowrap px-4 py-4 text-right text-sm font-medium leading-5">
                      <FileNav userUploadId={userUpload.id} />
                    </td>
                  </tr>
                );
              }
            })}
        </tbody>
      </table>
    </div>
  ) : (
    <div className="flex h-full w-full flex-col border-b border-gray-200">
      <FileToolbar
        folder={folder}
        handleNewFolder={handleNewFolder}
        handleYoutubeUpload={handleYoutubeUpload}
      />
    </div>
  );
}
