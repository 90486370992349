import {
  OAuthProvidersTypes,
  OneTapPositions,
  SDKProductTypes,
} from '@stytch/stytch-react';

import {BASE_REDIRECT_URL} from 'constants/environment';

console.log('BASE_REDIRECT_URL', BASE_REDIRECT_URL);

const authProjects = [SDKProductTypes.oauth, SDKProductTypes.emailMagicLinks];

export const stytchProps = {
  loginOrSignupView: {
    oauthOptions: {
      loginRedirectURL: `${BASE_REDIRECT_URL}/authenticate/oauth`,
      signupRedirectURL: `${BASE_REDIRECT_URL}/authenticate/oauth`,
      providers: [
        {
          one_tap: true,
          position: OneTapPositions.embedded,
          type: OAuthProvidersTypes.Google,
        },
        // {
        //   type: OAuthProvidersTypes.Microsoft,
        // },
      ],
    },
    emailMagicLinksOptions: {
      createUserAsPending: true,
      loginExpirationMinutes: 30,
      signupExpirationMinutes: 30,
      loginRedirectURL: `${BASE_REDIRECT_URL}/authenticate/link`,
      signupRedirectURL: `${BASE_REDIRECT_URL}/authenticate/link`,
    },
    products: authProjects,
  },
};
