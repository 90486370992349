import {ReactNode} from 'react';

export const DashboardContentWrap = ({
  children,
  className = 'px-4 mt-6 sm:px-6 ',
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <div className={className}>{children}</div>;
};
