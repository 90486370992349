import axios from 'axios';

/**
 * Get the Mux json blob that contains the thumbnail tile data
 *
 * @param storyboardImageJsonUrl string
 * @returns StoryboardThumbnailTilesData
 */
export const getMuxStoryboardImageJson = async (playbackId: string) => {
  const storyboardImageJsonUrl = `${window.location.protocol}//image.mux.com/${playbackId}/storyboard.json`;
  return await axios.get(storyboardImageJsonUrl);
};
