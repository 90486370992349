import * as Sentry from '@sentry/react';

import {Action, Store, ThunkAction, configureStore} from '@reduxjs/toolkit';
import {IS_DEBUG_ACTIVE, env} from 'constants/environment';

import {CurriedGetDefaultMiddleware} from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import authReducer from 'features/authSlice';
import {baseApi} from 'services/baseAPI';
import canvasItemsReducer from 'features/canvasItemsSlice';
import canvasStateReducer from 'features/canvasStateSlice';
import {combineReducers} from '@reduxjs/toolkit';
import {createBrowserHistory} from 'history';
import {createReduxHistoryContext} from 'redux-first-history';
import featureFlagReducer from 'features/featureFlagSlice';
import fileUploadsReducer from 'features/fileUploadsSlice';
import fontDataReducer from 'features/fontDataSlice';
import loggerMiddleware from './middleware/logger';
import monitorReducersEnhancer from './enhancers/monitorReducers';
import {rtkQueryErrorMessage} from 'services/utils';
import sceneFrameReducer from 'features/sceneFrameSlice';
import serverSyncReducer from 'features/serverSyncSlice';
import {setupListeners} from '@reduxjs/toolkit/dist/query';
import {useDispatch} from 'react-redux';
import userLibraryReducer from 'features/userLibrarySlice';
import userProjectDownloadReducer from 'features/userProjectDownloadSlice';
import undoable, {includeAction, combineFilters} from 'redux-undo';

import {undoableActions} from 'features/canvasItemsSlice';

const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({
  history: createBrowserHistory(),
});

export const rootReducer = () =>
  combineReducers({
    auth: authReducer,
    router: routerReducer,
    canvasItems: undoable(canvasItemsReducer, {
      limit: 5,
      filter: combineFilters(includeAction(undoableActions)),
    }),
    canvasState: canvasStateReducer,
    serverSync: serverSyncReducer,
    userProjectDownload: userProjectDownloadReducer,
    fontData: fontDataReducer,
    userLibrary: userLibraryReducer,
    sceneFrame: sceneFrameReducer,
    fileUploads: fileUploadsReducer,
    featureFlags: featureFlagReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  });

export type RootState = ReturnType<typeof rootReducer>;

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const storeConfig = {
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) => [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    loggerMiddleware,
    routerMiddleware,
    baseApi.middleware,
    rtkQueryErrorMessage,
  ],
  enhancers: [monitorReducersEnhancer, sentryReduxEnhancer],
  devTools: env === 'development' || IS_DEBUG_ACTIVE,
};

export const store: Store = configureStore(storeConfig);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();
export type GetRootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, ReturnType<RootState>, unknown, Action>;

export default store;

export const history = createReduxHistory(store);
