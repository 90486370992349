import {DimensionType} from 'features/types/canvasItemsSlice';
import {useMemo} from 'react';

export const LineFlatEmojiWave = ({
  frequencyData,
  dimensions,
}: {
  frequencyData: number[];
  dimensions: DimensionType;
}) => {
  const emojiFontSize = 28;
  const emojiWidth = 32;
  const emojiToDisplay = '🌊';

  const fftFrame = useMemo(() => frequencyData, [frequencyData]);
  const frameLength = fftFrame && fftFrame.length ? fftFrame.length : 0;
  const {width, height} = dimensions;
  const emojiOffsetX =
    frameLength * emojiWidth < width ? width - frameLength * emojiWidth : 0;
  const emojiOffsetY =
    frameLength * emojiWidth < height ? height - frameLength * (emojiWidth * 0.7) : 0;

  const heightArr = [...Array(8).keys()];

  if (!fftFrame || !fftFrame.length) {
    return null;
  }

  return (
    <div>
      {fftFrame.map((value, index) => {
        const barHeight = value * (height / 256) + 20;

        const barHeightMultiple = Math.round(barHeight / emojiWidth);

        return heightArr.map(jindex => {
          const emojiStyle = {
            fontSize: emojiFontSize,
            position: 'absolute' as any,
            bottom: jindex * emojiWidth + emojiOffsetY * (jindex / heightArr.length),
            left: `${index * emojiWidth + emojiOffsetX * (jindex / heightArr.length)}px`,
            height: `${emojiWidth}px`,
            width: `${emojiWidth}px`,
          };

          if (barHeightMultiple < Math.round(barHeight) - jindex * emojiWidth) {
            return (
              <div style={emojiStyle} key={jindex}>
                {emojiToDisplay}
              </div>
            );
          } else {
            return (
              <div style={emojiStyle} key={jindex}>
                {/* ◼️ */}
              </div>
            );
          }
        });
      })}
    </div>
  );
};
