import {DeleteButton, LargeDashedButton, ListContainer, ListSkeleton} from './utils';
import {Plus, UploadCloud} from 'react-feather';
import {
  UploadAccountFontData,
  useAccountFontUploads,
  useDeleteAccountFont,
  useUploadAccountFont,
} from 'services/fontDataAPI';
import {UploadButton, useUppy} from './UploadButton';

import {AccountFontUploadsType} from 'features/types/userLibrarySlice';
import {Button} from 'ui/Button';
import {UserUpload} from 'services/userUploadAPI';
import {getOpenfontData} from 'api/openfontAPI';
import {showSuccessNotification} from 'features/Common/utils';

export const BrandFontList = () => {
  const {data: accountFontUploads, isLoading} = useAccountFontUploads({
    includeDiscarded: false,
  });

  if (!accountFontUploads || isLoading) {
    return <ListSkeleton />;
  }

  if (accountFontUploads.length === 0) {
    return <div />;
  }

  return (
    <div className="my-3 flex flex-col space-y-1 px-6">
      <List fonts={accountFontUploads} />
    </div>
  );
};

export const BrandFonts = () => {
  const [uploadFont] = useUploadAccountFont();

  const uppy = useUppy({
    postProcess: async file => {
      const data = await getFontData(file);
      await uploadFont(data);
    },
  });

  return (
    <div className="">
      <div className="flex flex-col">
        <div className="my-3 flex px-6">
          <div className="flex w-full flex-row items-center justify-between">
            <span className="text-sm text-gray-500">
              Upload your own fonts and save to your library, ready to be added to any
              design.
            </span>
            <span className="pl-2">
              <UploadFontButton uppy={uppy} />
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <BrandFontList />
      </div>
    </div>
  );
};

const UploadFontButton = ({uppy}: {uppy: ReturnType<typeof useUppy>}) => {
  const {data: accountFontUploads, isLoading} = useAccountFontUploads({
    includeDiscarded: false,
  });

  if (!accountFontUploads || isLoading) {
    return <div />;
  }

  return (
    <UploadButton uppy={uppy}>
      {fileInput => (
        <Button _className="text-xs" variant="primary" leftIcon={Plus} fullWidth>
          Upload font
          {fileInput}
        </Button>
      )}
    </UploadButton>
  );
};

const FontName = ({font}: {font: AccountFontUploadsType}) => {
  if (font.font_specimen_preview_url && font.font_name_preview_url) {
    return (
      <div className="flex items-center space-x-1">
        <img className="h-6" src={font.font_name_preview_url} alt={font.font_family} />
        <img className="h-5 opacity-40" src={font.font_specimen_preview_url} alt="" />
      </div>
    );
  }

  return <div className="text-lg font-medium">{font.font_family}</div>;
};

const List = ({fonts}: {fonts: AccountFontUploadsType[]}) => {
  const [deleteFont] = useDeleteAccountFont();

  return (
    <ul className="space-y-2 ">
      {fonts.map(font => {
        if (!font.font_fullname) return null;

        return (
          <li
            key={font.id}
            className="group relative flex w-full items-center justify-between rounded-md border bg-gray-50 px-2 py-2"
          >
            <div className="flex w-full items-center justify-between">
              <FontName font={font} />
              <DeleteButton
                label={font.font_family}
                onClickConfirm={async () => {
                  await deleteFont(font.id);

                  showSuccessNotification({
                    title: 'Success',
                    message: `${font.font_family} has been deleted`,
                  });
                }}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
};

const getFontData = async (userUpload: UserUpload): Promise<UploadAccountFontData> => {
  const newFont = await getOpenfontData(userUpload.upload_url);

  return {
    ...userUpload,
    font_fullname: newFont.names.fullName?.en,
    font_family: newFont.names.fontFamily?.en,
    font_license: newFont.names.license?.en,
    font_subfamily: newFont.names.fontSubfamily?.en,
  };
};
