import {
  ImageCard,
  ImageCardButton,
  ImageCardGhost,
} from 'features/EditorCanvas/Sidebar/views/BrandKit';
import {StepButtons, StepHeader, fitUserUpload} from './utils';
import {
  UploadButton,
  useUppy,
} from 'features/Dashboard/DashboardPage/ContentBrandKit/UploadButton';
import {
  useCreateUserUploadMutation,
  useListUserUploadsQuery,
} from 'services/userUploadAPI';

import {DimensionType} from 'features/types/canvasItemsSlice';
import {ItemStepFC} from './NewProjectWizard';
import {Upload} from 'react-feather';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {accountIdSelector} from 'features/selectors/authSelectors';
import {isImageMedia} from '../../utils';
import {useFetchAccountBrandQuery} from 'services/accountBrandAPI';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import {useUploadLogoUppy} from 'features/Dashboard/DashboardPage/ContentBrandKit/Logos';

const getImageSize = (src: string) =>
  new Promise<DimensionType>((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      resolve({width: img.width, height: img.height});
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = src;
  });

export const SetImage: ItemStepFC = ({state, itemId, item, actions}) => {
  const itemOverride = state.itemOverrides[itemId];
  const selectedImage = itemOverride?.url;

  const [brandKitSelected, setBrandKitSelected] = useState(false);

  const setImage = async (userUpload: UserUploadsType, brandKit: boolean) => {
    setBrandKitSelected(brandKit);

    const url = userUpload.upload_url;
    const imageSize = await getImageSize(url);

    const box = fitUserUpload({
      userUpload: {max_width: imageSize.width, max_height: imageSize.height},
      within: item.dimension,
      position: item.position,
    });

    actions.updateItemOverride({
      id: itemId,
      item: {
        url: userUpload.upload_url,
        ...box,
      },
    });
  };

  return (
    <>
      <StepHeader heading={item.layerName} state={state} actions={actions}>
        <strong>Choose or upload an image below.</strong> You will see your video update
        on the left when you choose an image.
      </StepHeader>
      <Logos selectedImage={selectedImage} setImage={setImage} />
      <div>
        <div className="mb-2 text-sm font-medium text-gray-600">Uploads</div>
        <Uploads
          selectedImage={selectedImage}
          setImage={setImage}
          onUpload={() => {
            window.analytics.track('TmpWzd -- Ev-Upld -- New upload image layer', {
              step: state.step,
              prompt: item.layerName,
            });
          }}
        />
      </div>
      <StepButtons
        state={state}
        actions={actions}
        overrideConfirm={{
          label: !selectedImage ? 'Skip' : 'Next',
          onClick: () => {
            window.analytics.track('TmpWzd -- Lc-Fin -- Image step', {
              step: state.step,
              prompt: item.layerName,
              type: brandKitSelected ? 'brand kit' : 'upload',
            });

            actions.nextStep();
          },
        }}
      />
    </>
  );
};

const Logos = ({
  selectedImage,
  setImage,
}: {
  selectedImage?: string;
  setImage: (userUpload: UserUploadsType, brandKit: boolean) => void;
}) => {
  const {data: accountBrand} = useFetchAccountBrandQuery();
  const logos = accountBrand?.logos;

  const uppy = useUploadLogoUppy();

  if (!logos || logos.length === 0) return null;

  return (
    <div>
      <div className="mb-2 text-sm font-medium text-gray-600">Brand Kit Logos</div>
      <div className="grid grid-cols-2 gap-2">
        {logos.map(logo => (
          <ImageCard
            key={logo.id}
            url={logo.upload_url}
            selected={selectedImage === logo.upload_url}
            onClick={() => setImage(logo, true)}
          />
        ))}
        <UploadButton uppy={uppy}>
          {fileInput => (
            <ImageCardButton label="Upload a logo">{fileInput}</ImageCardButton>
          )}
        </UploadButton>
      </div>
    </div>
  );
};

const Uploads = ({
  selectedImage,
  setImage,
  onUpload,
}: {
  selectedImage?: string;
  setImage: (userUpload: UserUploadsType, brandKit: boolean) => void;
  onUpload: () => void;
}) => {
  const {data: accountBrand} = useFetchAccountBrandQuery();
  const {data: userUploads, isLoading, isFetching} = useListUserUploadsQuery();

  const logos = accountBrand?.logos;
  const userImages =
    !isLoading && userUploads && userUploads.data
      ? userUploads.data.filter(upload => {
          if (!isImageMedia(upload.file_type)) {
            return false;
          }

          return !logos?.find(logo => logo.id === upload.id);
        })
      : [];

  const [createUserUpload] = useCreateUserUploadMutation();
  const accountId = useSelector(accountIdSelector);

  const uppy = useUppy({
    postProcess: async userUpload => {
      if (!accountId) throw new Error('accountId missing');
      const result = await createUserUpload({...userUpload, account_id: accountId});
      if ('error' in result) return;

      setImage(result.data, false);
      onUpload();
    },
  });

  return (
    <div className="grid grid-cols-2 gap-2">
      <UploadButton uppy={uppy}>
        {fileInput => (
          <ImageCardButton icon={Upload} label="Upload an image" square>
            {fileInput}
          </ImageCardButton>
        )}
      </UploadButton>
      {!userImages ? (
        <ImageCardGhost square />
      ) : (
        userImages.map(userImage => (
          <ImageCard
            key={userImage.id}
            url={userImage.upload_url}
            square
            selected={selectedImage === userImage.upload_url}
            onClick={() => setImage(userImage, false)}
          />
        ))
      )}
    </div>
  );
};
