import {
  CanvasItem,
  FontWeight,
  ItemSource,
  PositionType,
  StoredCrop,
  VideoItem,
  fontWeights,
} from 'features/types/canvasItemsSlice';
import {FocusedFlowLayout, FocusedFlowLayoutProps, FocusedFlowMedia} from './Layout';
import {ItemLayerSources, ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {
  SAMPLE_CLIP,
  SAMPLE_CLIP_CAPTION_ITEM,
  SAMPLE_IMAGE,
  SAMPLE_SMART_COMPONENT,
  SAMPLE_SQUARE,
  SAMPLE_TEXT,
} from 'features/EditorCanvas/constants/ItemConstants';
import {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';

import {AudioWaveVizOptions} from 'features/EditorCanvas/components/CanvasItemLayers/SmartComponent/AudioWaveforms/constants';
import {Box} from 'features/EditorCanvas/Layout/Box';
import {PromiseType} from 'utility-types';
import {SceneFrameSliceProps} from 'features/sceneFrameSlice';
import {SmartComponents} from 'features/EditorCanvas/constants/ItemToolbarMapping';
import {Time} from 'features/Common/Time';
import classNames from 'classnames';
import {defaultCropRectangle} from 'features/EditorCanvas/components/AppCanvas/Canvas/utils/useEditCrop';
import equal from 'fast-deep-equal';
import {focusedFlowRoute} from 'routes/routesHelper';
import {getCropForFullBox} from 'features/EditorCanvas/components/CanvasItem/InverseCrop';
import {getThumbnail} from './LayoutUtils';
import {sizes} from './Constants';

export type Layout = {
  items: Record<string, CanvasItem>;
  sceneFrame: SceneFrameSliceProps;
};

export type GetLayout = () => Promise<Layout>;

export type LayoutCalculatorRef = {
  getLayout: GetLayout;
};

export type FocusedFlowAudioWaveformOptions = {
  targetPlayableMediaId: string;
  waveformStyle: AudioWaveVizOptions;
  originLocation: 'focused-flow' | 'podcast-audiogram';
  media: FocusedFlowMedia;
};

const sleep = (timeout = 0) => new Promise(resolve => setTimeout(resolve, timeout));

type LayoutCalculatorProps = {
  onLayout?: (layout: PromiseType<ReturnType<GetLayout>>) => void;
  debug?: boolean;
  sampleCaptions?: boolean;
} & Omit<FocusedFlowLayoutProps, 'onLayout'>;

type PlayableProps = Pick<
  CanvasItem & VideoItem,
  | 'itemSource'
  | 'itemSourceId'
  | 'timeOffsetSeconds'
  | 'playLengthSeconds'
  | 'playbackId'
  | 'transcriptId'
  | 'source'
>;

export const LayoutCalculator = forwardRef<LayoutCalculatorRef, LayoutCalculatorProps>(
  ({onLayout, debug, ...props}, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const thumbnail = getThumbnail(props.media);

    const getLayout: GetLayout = async () => {
      await sleep();

      if (!containerRef.current) {
        throw new Error('containerRef is null');
      }

      const sceneFrame: SceneFrameSliceProps = {
        backgroundColor: {hex: getComputedStyle(containerRef.current).backgroundColor},
        canvasDimensions: sizes[props.design.size].dimensions,
      };

      const items: Record<string, CanvasItem> = {};

      let playableProps: PlayableProps | undefined;

      if (props.media) {
        let timing: ItemSource['timing'];

        if (props.media.clip) {
          const start = new Time(props.media.clip.range.start);
          const end = new Time(props.media.clip.range.end);
          const duration = end.sub(start);

          timing = {offset: start, duration};
        } else {
          let duration = 0;
          duration = props.media.upload.duration ?? 0;

          timing = {
            offset: new Time(0),
            duration: new Time(duration, 's'),
          };
        }

        const playbackId = props.media.upload.mux_playback_id;

        playableProps = {
          timeOffsetSeconds: new Time(timing.offset).s,
          playLengthSeconds: new Time(timing.duration).s,
          itemSource: ItemLayerSources.Uploads,
          playbackId,
          transcriptId: props.media.upload.id,
          itemSourceId: props.media.upload.id,
        };
      }

      let audioWaveSource: string | null = null;

      const video = getItem(containerRef.current, 'video');
      if (video) {
        let crop: StoredCrop = {...defaultCropRectangle};

        if (props.design.style !== 'contain') {
          const videoBox = new Box(
            {width: thumbnail.width, height: thumbnail.height},
            video,
          )
            .cover()
            .center();

          crop = getCropForFullBox({
            fullBox: videoBox,
            type: 'rectangle',
            cropBox: video,
          });
        }

        items.video = {
          ...SAMPLE_CLIP,
          viewType: ViewTypes.VideoClip,
          dimension: video.dimension,
          position: video.position,
          style: {
            ...SAMPLE_CLIP.style,
            borderRadius: parseInt(video.style.borderRadius),
          },
          order: 0,
          crop,
          ...playableProps,
        };

        audioWaveSource = 'video';
      } else {
        items.audio = {
          ...SAMPLE_CLIP,
          viewType: ViewTypes.AudioClip,
          dimension: {width: 0, height: 0},
          order: 0,
          ...playableProps,
        };

        audioWaveSource = 'audio';
      }

      const titleBackround = getItem(containerRef.current, 'title-background');
      if (titleBackround) {
        items.titleBackground = {
          ...SAMPLE_SQUARE,
          dimension: titleBackround.dimension,
          position: titleBackround.position,
          style: {
            ...SAMPLE_SQUARE.style,
            background: titleBackround.style.backgroundColor,
          },
          order: 1,
        };
      }

      const getFontWeight = (style: CSSStyleDeclaration) => {
        const parsedFontWeight = parseInt(style.fontWeight);

        if (isNaN(parsedFontWeight)) return;
        if (!fontWeights.includes(parsedFontWeight as any)) return;
        return parsedFontWeight as FontWeight;
      };

      const title = getItem(containerRef.current, 'title');

      if (title) {
        const fontSize = parseInt(title.style.fontSize);
        const lineHeight = parseInt(title.style.lineHeight) / fontSize;
        const fontWeight = getFontWeight(title.style);

        items.title = {
          ...SAMPLE_TEXT,
          dimension: title.dimension,
          position: title.position,
          text: title.element.innerText,
          sizeMode: 'customWidth',
          style: {
            ...SAMPLE_TEXT.style,
            fontSize,
            fontFamily: title.style.fontFamily.replaceAll('"', ''),
            color: title.style.color,
            background: title.style.backgroundColor,
            // @ts-ignore
            textAlign: title.style.textAlign,
            lineHeight,
            fontWeight,
          },
          order: 2,
        };
      }

      const logo = getItem(containerRef.current, 'logo');

      if (logo) {
        items.logo = {
          ...SAMPLE_IMAGE,
          dimension: logo.dimension,
          position: logo.position,
          url: logo.element.getAttribute('src')!,
          order: 3,
        };
      }

      const captionsBackround = getItem(containerRef.current, 'captions-background');
      if (captionsBackround) {
        items.captionsBackground = {
          ...SAMPLE_SQUARE,
          dimension: captionsBackround.dimension,
          position: captionsBackround.position,
          style: {
            ...SAMPLE_SQUARE.style,
            background: captionsBackround.style.backgroundColor,
          },
          order: 4,
        };
      }

      const captions = getItem(containerRef.current, 'captions');
      if (captions) {
        const fontSize = parseInt(captions.style.fontSize);
        const lineHeight = parseInt(captions.style.lineHeight) / fontSize;
        const fontWeight = getFontWeight(captions.style);

        const activeCaptions = captions.element.querySelector<HTMLSpanElement>('span');

        const captionProps = {
          dimension: captions.dimension,
          position: captions.position,
          style: {
            fontSize,
            lineHeight,
            fontFamily: captions.style.fontFamily.replaceAll('"', ''),
            colorInactive: captions.style.color,
            color: activeCaptions!.style.color,
            colorCurrent: activeCaptions!.style.color,
            background: captions.style.backgroundColor,
            // @ts-ignore
            textAlign: captions.style.textAlign,
            fontWeight,
          },
          order: 5,
        };

        if (props.sampleCaptions) {
          items.captions = {
            ...SAMPLE_TEXT,
            ...captionProps,
            style: {
              ...SAMPLE_TEXT.style,
              ...captionProps.style,
              fontSize: captionProps.style.fontSize.toString(),
            },
            text: 'Captions will display here.',
            sizeMode: 'customWidth',
          };
        } else if (playableProps) {
          items.captions = {
            ...SAMPLE_CLIP_CAPTION_ITEM,
            ...captionProps,
            style: {
              ...SAMPLE_CLIP_CAPTION_ITEM.style,
              ...captionProps.style,
            },
            ...playableProps,
          };
        }
      }

      const audioWaveform = getItem(containerRef.current, SmartComponents.AudioWaveform);
      if (audioWaveform) {
        items.audioWaveform = {
          ...SAMPLE_SMART_COMPONENT,
          dimension: audioWaveform.dimension,
          position: audioWaveform.position,
          smartComponent: {
            id: SmartComponents.AudioWaveform,
            options: {
              targetPlayableMediaId: audioWaveSource,
              waveformStyle: AudioWaveVizOptions.LinesFlat,
              originLocation: focusedFlowRoute,
              media: props.media,
            } as FocusedFlowAudioWaveformOptions,
          },
          order: 6,
        };
      }

      const secondaryTitleBackground = getItem(
        containerRef.current,
        'secondary-title-background',
      );
      if (secondaryTitleBackground) {
        items.secondaryTitleBackground = {
          ...SAMPLE_SQUARE,
          dimension: secondaryTitleBackground.dimension,
          position: secondaryTitleBackground.position,
          style: {
            ...SAMPLE_SQUARE.style,
            background: secondaryTitleBackground.style.backgroundColor,
            borderRadius: parseInt(secondaryTitleBackground.style.borderRadius),
          },
          order: 7,
        };
      }

      const secondaryTitle = getItem(containerRef.current, 'secondary-title');
      if (secondaryTitle) {
        const fontSize = parseInt(secondaryTitle.style.fontSize);
        const lineHeight = parseInt(secondaryTitle.style.lineHeight) / fontSize;
        const fontWeight = getFontWeight(secondaryTitle.style);

        items.secondaryTitle = {
          ...SAMPLE_TEXT,
          dimension: secondaryTitle.dimension,
          position: secondaryTitle.position,
          style: {
            ...SAMPLE_TEXT.style,
            fontSize: fontSize.toString(),
            lineHeight,
            fontWeight,
          },
          order: 8,
          text: secondaryTitle.element.innerText,
          sizeMode: 'customWidth',
        };
      }

      return {items, sceneFrame};
    };

    const getStyleRef = useRef(getLayout);
    getStyleRef.current = getLayout;

    useImperativeHandle(ref, () => ({getLayout: () => getStyleRef.current()}));

    const onLayoutRef = useRef(onLayout);
    onLayoutRef.current = onLayout;

    const propsRef = useRef<typeof props | null>(null);

    useEffect(() => {
      if (equal(propsRef.current, props)) {
        return;
      }

      if (!onLayoutRef.current) return;

      propsRef.current = props;

      getStyleRef
        .current()
        .then(style => {
          if (!onLayoutRef.current) return;
          onLayoutRef.current(style);
        })
        .catch(console.error);
    }, [props]);

    return (
      <div
        className={classNames(
          'fixed top-0 left-full',
          debug && '!left-auto right-0 z-50',
        )}
        style={{...sizes[props.design.size].dimensions, zoom: debug ? 0.5 : undefined}}
      >
        <FocusedFlowLayout
          ref={containerRef}
          {...props}
          onLayout={() => {
            getLayout().then(style => onLayout?.(style));
          }}
        />
      </div>
    );
  },
);

const getItem = (container: HTMLElement, item: string) => {
  const element = container.querySelector<HTMLElement>(`[data-layer="${item}"]`);
  if (!element) return;

  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  const position: PositionType = {
    top: elementRect.top - containerRect.top,
    left: elementRect.left - containerRect.left,
  };

  const style = getComputedStyle(element);

  return {
    position,
    dimension: {width: parseFloat(style.width), height: parseFloat(style.height)},
    style,
    element,
  };
};
