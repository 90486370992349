import {
  RecordingInteractionStateType,
  UserRecordingStep,
} from 'services/recordingInteractionsAPI';
import {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {BASE_URL} from 'constants/environment';
import {MILK_API_RECORDING_STATE} from '../components/RecordingSessionWrap';
import {UserUpload} from 'services/userUploadAPI';
import axios from 'axios';
import {createContainer} from 'unstated-next';
import {updateLocation} from 'use-query-params';

export type RecordingInteractionForm = {};

export type RecordingContactUser = {
  account_id: string;
  created_at: number;
  id: string;
  email: null | string;
  is_anonymous: boolean;
  meta: {};
  name: null | string;
  phone: null | string;
  session_key: null | string;
  updated_at: number;
};

export type CreateRoomPostResponse = {
  api_created: boolean;
  config: {
    enable_recording: 'rtp-tracks';
  };
  created_at: string;
  id: string;
  name: string;
  privacy: 'public';
  url: string;
};

export type MuxAsset = {
  created_at: number;
  duration: number;
  id: string;
  max_height: number;
  max_width: number;
  mux_playback_id: string;
  mux_status: 'ready' | string;
  updated_at: number;
};

export type RecordingSubmissionTrack = {
  created_at: number;
  id: string;
  user_upload: UserUpload;
  status: 'success-combined-audio-and-video-tracks' | string;
  updated_at: number;
};

export type RecordingSubmission = {
  account_id: string;
  created_at: number;
  discarded_at: null;
  id: string;
  meta: {
    user_upload_id?: string;
    room_name?: string;
    dailyco_room_response?: CreateRoomPostResponse;
    dailyco_room_status?: 'created';
  };
  status: 'started' | string;
  updated_at: number;
  url: null | string;
  user_recording_contact_id: string;
  user_recording_interaction_form_id: string;
  submission_tracks: RecordingSubmissionTrack[];
};

function useApiRecordingSession(initialState = 0) {
  const {recordingInteractionFormId} = useParams<Record<string, string>>();
  const navigate = useNavigate();
  const [recordingStep, setRecordingStep] = useState<UserRecordingStep>({
    type: 'first-page',
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const [recordingInteractionForm, setRecordingInteractionForm] =
    useState<null | RecordingInteractionForm>(null);

  const [recordingRoomId, setRecordingRoomId] = useState<null | string>(
    searchParams.get('recordingId') || null,
  );

  const [isCreateRecordingModalOpen, setIsCreateRecordingModalOpen] =
    useState<boolean>(false);

  const [recordingSubmission, setRecordingSubmission] =
    useState<null | RecordingSubmission>(null);

  const [recordingContactUser, setRecordingContactUser] =
    useState<null | RecordingContactUser>(null);

  const updateRecordingRoomId = (roomId: string) => {
    console.log('Running updateRecordingRoomId: ', roomId);

    if (roomId && roomId !== 'undefined') {
      setRecordingRoomId(roomId);
      const updatedLocation = updateLocation({recordingId: roomId}, window.location);
      setSearchParams({recordingId: roomId});
    } else {
      console.error('Issue with recording room');
      navigate(`/share/f/${recordingInteractionFormId}`);
    }
  };

  useEffect(() => {
    if (recordingInteractionFormId && recordingSubmission?.id) {
      (async () => {
        try {
          const url = `${BASE_URL}/api/v2/provider/video-stream/create-room`;
          const response = await axios.post(url, {
            recording: {
              action: MILK_API_RECORDING_STATE.ROOM_CREATE,
              user_recording_interaction_submission_id: `${recordingSubmission.id}`,
            },
          });
          const createRoomResponse = response.data.body as RecordingSubmission;
          // console.log('createRoomResponse', createRoomResponse);

          if (createRoomResponse?.meta?.dailyco_room_response?.name) {
            updateRecordingRoomId(createRoomResponse.meta.dailyco_room_response.name);
          }
        } catch (error) {
          console.error('error', error);
        }
      })();
    }
  }, [recordingInteractionFormId, recordingSubmission]);

  const recordingState: RecordingInteractionStateType = {
    step: recordingStep,
    userRecordingContact: recordingContactUser,
    userRecordingInteractionSubmission: recordingSubmission,
    userRecordingInteractionForm: recordingInteractionForm,
  };

  // console.log('recordingState', recordingState);
  // console.log('recordingRoomId', recordingRoomId);

  return {
    recordingState,
    recordingInteractionFormId,
    recordingRoomId,
    isCreateRecordingModalOpen,
    setRecordingStep,
    setRecordingRoomId,
    setRecordingSubmission,
    setRecordingInteractionForm,
    setRecordingContactUser,
    updateRecordingRoomId,
    setIsCreateRecordingModalOpen,
  };
}

export const ApiRecordingSession = createContainer(useApiRecordingSession);
