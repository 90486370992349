import {baseApi} from './baseAPI';

export type LandingPage = {
  id: string;
  description: string;
  title: string;
  created_at: number;
  updated_at: number;
  account_id: string;
  owner_id: string;
  landing_page_components: LandingPageComponent[];
};

export type LandingPageComponent = {
  id: string;
  component_type:
    | 'UploadMediaClip'
    | 'UserProjectDownload'
    | 'UserUpload'
    | 'LandingPageEmbed';
  description: string | null;
  order: number;
  title: string;
  created_at: number;
  updated_at: number;
  component_id: string;
  landing_page_id: string;
  element_type: 'mux_video' | 'section' | 'embed';
  component: LandingPageComponentComponent;
};

export type LandingPageComponentComponent = {
  id: string;
  url?: string;
};

type UpdateId<T extends {}> = T & {id: string};
type UpsertId<T extends {}> = T & {id?: string};

export type LandingPageComponentInput = {
  component_type?:
    | 'UploadMediaClip'
    | 'UserProjectDownload'
    | 'UserUpload'
    | 'LandingPageEmbed';
  component_id?: string;
  title?: string;
  description?: string;
  order?: number;
  element_type?: 'mux_video' | 'section' | 'embed';
  // TODO (lenny 8/16/21) Add the component type when ready
};

export type LandingPageInput = {
  title?: string;
  description?: string;
  landing_page_components_attributes?: LandingPageComponentInput[];
};

type LandingPageUpdateInput = UpdateId<LandingPageInput> & {
  landing_page_components_attributes?: UpsertId<LandingPageComponentInput>[];
};

const landingPageAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['LandingPage']})
  .injectEndpoints({
    endpoints: builder => ({
      landingPages: builder.query<LandingPage[], void>({
        query: () => ({
          url: `landing_pages`,
          method: 'GET',
          errorTitle: 'Error loading landing pages data',
        }),
        providesTags: ['LandingPage'],
      }),

      landingPage: builder.query<LandingPage, {id: string}>({
        query: ({id}) => ({
          url: `landing_pages/${id}`,
          method: 'GET',
          errorTitle: 'Error loading landing page data',
        }),
        providesTags: ['LandingPage'],
      }),

      createLandingPage: builder.mutation<LandingPage, LandingPageInput>({
        query: landingPage => ({
          url: `landing_pages`,
          method: `POST`,
          errorTitle: `Error creating landing page`,
          data: {landing_page: landingPage},
        }),
        invalidatesTags: ['LandingPage'],
      }),

      // removeLandingPage: builder.mutation<LandingPage, LandingPageUpdateInput>({
      //   query: ({id, ...patch}) => ({
      //     url: `landing_pages/${id}`,
      //     method: `PATCH`,
      //     errorTitle: `Error updating landing page`,
      //     data: {landing_page: patch},
      //   }),
      //   invalidatesTags: ['LandingPage'],
      // }),

      updateLandingPage: builder.mutation<LandingPage, LandingPageUpdateInput>({
        query: ({id, ...patch}) => ({
          url: `landing_pages/${id}`,
          method: `PATCH`,
          errorTitle: `Error updating landing page`,
          data: {landing_page: patch},
        }),
        invalidatesTags: ['LandingPage'],
      }),

      deleteLandingPage: builder.mutation<void, {id: string}>({
        query: ({id}) => ({
          url: `landing_pages/${id}`,
          method: 'DELETE',
          errorTitle: 'Error deleting landing page',
        }),
        invalidatesTags: ['LandingPage'],
      }),
    }),
  });

export const {
  useLandingPagesQuery,
  useLandingPageQuery,
  useCreateLandingPageMutation,
  useUpdateLandingPageMutation,
  useDeleteLandingPageMutation,
} = landingPageAPI;
