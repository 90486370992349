import { AnimatePresence, motion } from 'framer-motion';
import {
  InverseCropProvider,
  useInverseCrop,
} from 'features/EditorCanvas/components/CanvasItem/InverseCrop';
import { ProjectIdProvider, useProjectId } from 'features/EditorCanvas/useProjectId';
import { STATIC_EMAIL_OVERRIDE, STATIC_TOKEN_OVERRIDE } from 'constants/environment';
import {
  canvasItemsSelector,
  selectCanvasProject,
} from 'features/selectors/canvasItemsSelectors';
import { recordingStartupPreparation, updateItems } from 'features/canvasItemsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useOnMessage, useSendMessage } from 'features/Common/PostMessage';

import { Box } from 'features/EditorCanvas/Layout/Box';
import { CanvasWrapper } from 'features/EditorCanvas/components/AppCanvas/Canvas/CanvasWrapper';
import { GetRootState } from 'configureStore';
import { LoadGoogleFonts } from 'features/Fonts/LoadGoogleFonts';
import { ProjectTimeProvider } from 'features/EditorCanvas/components/CanvasTime/TimeProvider';
import { SelectionFocusProvider } from 'features/EditorCanvas/components/SelectionFocusProvider';
import { createGlobalStyle } from 'styled-components';
import { fetchProjectData } from 'features/userLibrarySlice';
import { sceneFrameSelector } from 'features/selectors/sceneFrameSelectors';
import { useParams } from 'react-router-dom';
import { useWindowSize } from '@react-hook/window-size';

const NoScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }

  .intercom-lightweight-app {
    display: none !important;
  }  
`;

const InverseCropListener = ({
  loading,
  cropId,
  onChangeCropId,
}: {
  loading: boolean;
  cropId: string | null;
  onChangeCropId: (id: string | null) => void;
}) => {
  const { start, active, save } = useInverseCrop();

  useOnMessage(event => {
    if (event.message === 'start') {
      onChangeCropId(event.data.itemId);
    }

    if (event.message === 'stop') {
      onChangeCropId(null);
    }
  }, 'preview-template-crop');

  const projectId = useProjectId();
  const { items } = useSelector((state: GetRootState) =>
    selectCanvasProject(state, projectId),
  );

  useEffect(() => {
    if (loading) return;
    if (active?.itemId === cropId) return;

    const item = cropId && items[cropId];
    if (cropId && item) {
      start(item, cropId);
    } else {
      save();
    }
  }, [active?.itemId, cropId, items, loading, start, save]);

  return null;
};

export const PreviewTemplateCanvas = () => {
  const dispatch = useDispatch();
  const { templateSlug } = useParams<{ templateSlug: string }>();

  const [loading, setLoading] = useState(true);

  const sendMessage = useSendMessage(window.parent);

  useEffect(() => {
    new Promise<void>((resolve, reject) => {
      dispatch(
        recordingStartupPreparation(templateSlug!, STATIC_TOKEN_OVERRIDE, error => {
          if (error) reject(error);
          else resolve();
        }),
      );
    })
      .then(() => {
        return new Promise<void>((resolve, reject) => {
          dispatch(
            fetchProjectData(
              STATIC_TOKEN_OVERRIDE,
              STATIC_EMAIL_OVERRIDE,
              templateSlug!,
              error => {
                if (error) reject(error);
                else resolve();
              },
            ),
          );
        });
      })
      .then(() => {
        sendMessage({ channel: 'preview-template', message: 'ready' });
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSlug]);

  const { projectsCanvas } = useSelector(canvasItemsSelector);
  const project = projectsCanvas && projectsCanvas[templateSlug!];

  const { canvasDimensions, backgroundColor } = useSelector(sceneFrameSelector)(
    templateSlug!,
  );

  const [width, height] = useWindowSize();

  const canvasBox = new Box(canvasDimensions, {
    dimension: { width, height },
    position: { top: 0, left: 0 },
  });
  canvasBox.fit();

  const [highlightId, setHighlightId] = useState<string | null>(null);
  useOnMessage(event => {
    if (event.message === 'set-active-item') {
      setHighlightId(event.data.itemId);
    }

    if (event.message === 'set-items') {
      dispatch(
        updateItems({
          projectId: templateSlug!,
          items: event.data.items,
        }),
      );
    }
  }, 'preview-template');

  const [cropId, setCropId] = useState<string | null>(null);

  const highlightItem = highlightId != null && project?.items[highlightId];

  if (!project) return null;

  return (
    <SelectionFocusProvider>
      <ProjectIdProvider projectId={templateSlug!}>
        <InverseCropProvider>
          <ProjectTimeProvider>
            <NoScroll />
            <div className="flex min-h-screen w-full flex-col items-center justify-start bg-black">
              <div
                id="artboard"
                style={{
                  ...canvasDimensions,
                  transform: `scale(${canvasBox.dimension.width / canvasDimensions.width
                    })`,
                  backgroundColor: backgroundColor.hex,
                }}
                className="relative mx-auto origin-top-left overflow-hidden"
              >
                <CanvasWrapper projectId={templateSlug!} />
                <AnimatePresence initial={false}>
                  {!cropId && highlightItem && (
                    <motion.div
                      className="pointer-events-none absolute"
                      initial={{
                        opacity: 0,
                        ...highlightItem.dimension,
                        ...highlightItem.position,
                      }}
                      animate={{
                        ...highlightItem.dimension,
                        ...highlightItem.position,
                        opacity: 1,
                      }}
                      exit={{ opacity: 0 }}
                      transition={{ bounce: 0 }}
                      style={{ zIndex: 99999 }}
                    >
                      <div className="absolute -inset-3 rounded-lg border-4 border-green-400" />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
            <LoadGoogleFonts />
            {!cropId && <div className="fixed inset-0 z-[99999]" />}
            <InverseCropListener
              loading={loading}
              cropId={cropId}
              onChangeCropId={setCropId}
            />
          </ProjectTimeProvider>
        </InverseCropProvider>
      </ProjectIdProvider>
    </SelectionFocusProvider>
  );
};
