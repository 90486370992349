import {FC} from 'react';
import {Icon} from 'react-feather';
import classNames from 'classnames';
import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;

  > button:focus {
    z-index: 1;
  }

  > button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }

  > button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const CanvasButton: FC<{
  leftIcon?: Icon;
  disabled?: boolean;
  onClick: () => void;
  variant?: 'active' | 'default';
}> = ({disabled = false, children, onClick, leftIcon: LeftIcon, variant = 'default'}) => {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={classNames(
        'focus:ring-blue inline-flex items-center rounded border bg-white px-2 py-1.5 font-sans text-xs font-medium leading-4 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800',
        variant === 'default' && 'border-gray-300 text-gray-700',
        variant === 'active' && 'border-indigo-300 text-indigo-600',
        disabled && 'cursor-not-allowed opacity-50',
      )}
    >
      {LeftIcon && <LeftIcon size={16} className="mr-1" />}
      {children}
    </button>
  );
};
