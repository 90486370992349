import {ApiUserResponseNormal} from 'api/authAPI';
import {User} from '@stytch/stytch-react';

export const prepareIdentifyPayload = (user: User, userStatus: ApiUserResponseNormal) => {
  // console.log('user', user);
  // console.log('userStatus', userStatus);
  const {name, emails} = user;
  const {email} = emails[0];
  const {current_account: currentAccount} = userStatus;
  const {
    account,
    account_usage: {
      file_data_uploaded: fileDataUploaded,
      media_encoded_seconds: mediaEncodedSeconds,
      transcript_processed_seconds: transcriptProcessedSeconds,
      upload_count: uploadCount,
    },
    onboarding_status,
    plan,
  } = currentAccount;

  const {
    has_account_upload_clip: hasAccountUploadClip,
    has_account_project_download: hasAccountProjectDownload,
    has_account_teammates: hasAccountTeammates,
  } = onboarding_status
    ? onboarding_status
    : {
        has_account_upload_clip: false,
        has_account_project_download: false,
        has_account_teammates: false,
      };

  return {
    name,
    email,
    company: account.id,
    plan,
    usage_file_data_uploaded: fileDataUploaded,
    usage_media_encoded_seconds: mediaEncodedSeconds,
    usage_project_count: userStatus.current_account.account_usage.project_count,
    usage_transcript_processed_seconds: transcriptProcessedSeconds,
    usage_upload_count: uploadCount,
    usage_has_created_clip: hasAccountUploadClip,
    usage_has_downloaded: hasAccountProjectDownload,
    has_invited_teammate: hasAccountTeammates,
  };
};
