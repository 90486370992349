type CardHeaderProps = {
  title: string;
  subtitle: string;
};

export const CardHeader = ({title, subtitle = ''}: CardHeaderProps) => {
  return (
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
      {subtitle.length > 0 && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
    </div>
  );
};
