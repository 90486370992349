import {
  postDisconnectZoomApi,
  postInitiateZoomOauth,
  postUpdateZoomSyncApi,
} from 'api/connectedAccountsAPI';
import { showErrorNotification, showSuccessNotification } from 'features/Common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { BASE_URL } from 'constants/environment';
import { Card } from 'features/Common/Card';
import CardHeaderButtonActive from 'features/Common/CardPage/CardHeaderButtonActive';
import CardPageHeader from 'features/Common/CardPage/Header';
import DeactivateModal from 'features/Common/Modal/DeactivateModal';
import ToggleWithLabelAndDescription from 'features/Common/ToggleWithLabelAndDescription';
import ZoomImage from './zoom-logo.jpg';
import { announceDiscretelyUserToApi } from 'features/authSlice';
import { authConnectedAccountSelector } from 'features/selectors/authSelectors';
import { useAuthAlt } from 'features/Auth/useAuthAlt';
import zoomApprove from './export-zoom.jpg';
import zoomSearch from './zoom-search.jpg';

const SetupInstructionsZoom = () => {
  return (
    <div className="space-y-2">
      <h3 className="mb-2 text-lg font-semibold">Instructions</h3>
      <p className="max-w-prose text-sm text-gray-500">
        For Zoom, connect your Zoom account from an administrative user and approve the
        connection.
      </p>
      <img
        className="max-w-lg overflow-hidden rounded-md border pt-4"
        src={zoomApprove}
        alt="Zoom approve UI"
      />
    </div>
  );
};

const AboutZoom = () => {
  return (
    <div className="space-y-2">
      <h3 className="mb-2 text-lg font-semibold">About</h3>
      <p className="max-w-prose text-sm text-gray-500">
        When connected, your Zoom recordings will be transcribed and searchable. You can
        chose any recording and quickly share links to important sections or turn clips
        into designed videos.
      </p>
      <img
        className="max-w-lg overflow-hidden rounded-md border pt-4"
        src={zoomSearch}
        alt="Zoom search UI"
      />
    </div>
  );
};
export default function ZoomConnection() {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, user } = useAuthAlt();
  const connectedAccounts = useSelector(authConnectedAccountSelector);
  const zoomAccounts = connectedAccounts.filter(account => account.provider === 'zoom');
  zoomAccounts.forEach(zoomAccount => { });
  const [loading, setLoading] = useState(false);
  const [showCancelModel, setShowCancelModel] = useState(false);
  const [showUnsyncModel, setShowUnsyncModel] = useState(false);
  const [isRecordingSyncActive, setIsRecordingSyncActive] = useState(true);
  const [isConnectingZoom, setIsConnectingZoom] = useState(false);

  const [isZoomConnected, setIsZoomConnected] = useState(!!zoomAccounts.length);

  useEffect(() => {
    setIsZoomConnected(!!zoomAccounts.length);
  }, [zoomAccounts]);

  /**
   * Zoom auth initiates a web request with server, then redirects to Zoom to login user.
   * Once the login is complete, the redirect brings user back to the uploads page
   */
  const handleInitiateZoomOauth = async () => {
    setLoading(true);
    window.analytics.track('Initiate Zoom oauth flow');
    try {
      const token = await getAccessTokenSilently();
      const redirectDestination = `${window.location.origin}/zoom-upload-started`;
      const response = await postInitiateZoomOauth(token, redirectDestination);

      window.location.href = BASE_URL + response.redirect_location;
      showSuccessNotification({
        title: 'Zoom account is connecting',
        message: 'Please wait while your Zoom recordings are synced.',
      });
    } catch (error) {
      setLoading(false);
      setIsConnectingZoom(false);
      showErrorNotification({
        title: 'Something went wrong',
        message: 'There was an error connecting to your Zoom account',
        // @ts-ignore
        error,
      });
    }
  };

  const showCancelModal = () => {
    setShowCancelModel(true);
  };

  const handleClickToActive = () => {
    setIsConnectingZoom(true);
    handleInitiateZoomOauth();
  };

  const handleClickToInactive = () => {
    showCancelModal();
  };

  const handleDisconnectZoom = async () => {
    setIsZoomConnected(false);
    try {
      const token = await getAccessTokenSilently();
      await postDisconnectZoomApi(token);
      showSuccessNotification({
        title: 'Zoom account disconnected',
        message: 'Please contact support@milkvideo.com for any additional questions.',
      });
      window.location.reload();
    } catch (error) {
      setLoading(false);
      setIsConnectingZoom(true);
      showErrorNotification({
        title: 'Something went wrong',
        message: 'There was an error disconnecting to your Zoom account',
        // @ts-ignore
        error,
      });
    }
  };

  const updateZoomSync = async (newValue: boolean) => {
    try {
      const token = await getAccessTokenSilently();
      await postUpdateZoomSyncApi(token, newValue);

      showSuccessNotification({
        title: 'Zoom Recording sync uploaded',
        message: 'Please contact support@milkvideo.com for any additional questions.',
      });
    } catch (error) {
      setLoading(false);
      setIsRecordingSyncActive(false);
      showErrorNotification({
        title: 'Something went wrong',
        message: 'There was an error connecting to your Zoom account',
        // @ts-ignore
        error,
      });
    }
  };

  const handleUnsyncZoom = async () => {
    setShowUnsyncModel(false);
    updateZoomSync(false);
    setIsRecordingSyncActive(false);
  };

  const toggleRecordingSync = () => {
    const nextRecordingSyncValue = !isRecordingSyncActive;
    if (nextRecordingSyncValue === false) {
      setShowUnsyncModel(true);
    } else {
      updateZoomSync(true);
      setIsRecordingSyncActive(nextRecordingSyncValue);
    }
  };

  return (
    <>
      <Card hideDivider>
        <CardPageHeader
          title="Zoom"
          description="Deep integration with Zoom recordings"
          cardImage={ZoomImage}
        >
          <CardHeaderButtonActive
            onClickToActive={handleClickToActive}
            onClickToInactive={handleClickToInactive}
            active={isZoomConnected}
            activeText={'Connected'}
            deactiveText={'Connect'}
            isConnecting={isConnectingZoom}
          />
        </CardPageHeader>
        <div className="px-4 py-5 sm:p-6">
          {isZoomConnected ? (
            <div className="space-y-4">
              <ToggleWithLabelAndDescription
                title={'Sync recordings from Zoom'}
                description={
                  'This will update your Milk Video upload library when new Recordings are created in Zoom'
                }
                isActive={isRecordingSyncActive}
                handleToggle={toggleRecordingSync}
              />
              <AboutZoom />
            </div>
          ) : (
            <div className="space-y-4">
              <AboutZoom />
              <SetupInstructionsZoom />
            </div>
          )}
        </div>
      </Card>
      {showCancelModel && (
        <DeactivateModal
          title="Deactivate account"
          handleCancel={() => {
            setShowCancelModel(false);
          }}
          handleConfirm={handleDisconnectZoom}
        >
          <ol className="mt-4 list-decimal pl-3">
            <li>
              Login to your Zoom Account and navigate to the <b>Zoom App Marketplace</b>.
            </li>
            <li>
              Click <b>Manage</b> then <b>Installed Apps</b> or search for the Milk Video
              app.
            </li>
            <li>
              Click the <b>Milk Video</b> app.
            </li>
            <li>
              Click <b>Uninstall</b>
            </li>
          </ol>
        </DeactivateModal>
      )}
      {showUnsyncModel && (
        <DeactivateModal
          title="Unsync Zoom recordings"
          content="Are you sure you want to stop your Zoom account from syncing with Milk Video? All of your new recordings will not be index or discoverable."
          handleCancel={() => {
            setIsRecordingSyncActive(true);
            setShowUnsyncModel(false);
          }}
          handleConfirm={handleUnsyncZoom}
        />
      )}
    </>
  );
}
