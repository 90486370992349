import {FileInput, StatusBar} from '@uppy/react';
import {ReactNode, useEffect, useRef} from 'react';
import Uppy, {UploadHandler} from '@uppy/core';
import {
  UppyFile,
  getMetadata,
  handleError,
  handleRestrictionFailed,
  handleUploadError,
} from 'features/FileUploader/utils';

import AwsS3 from '@uppy/aws-s3';
import {UPLOAD_COMPANION_URL} from 'constants/environment';
import Url from '@uppy/url';
import {UserUpload} from 'services/userUploadAPI';
import {UserUploadsTypes} from 'features/Dashboard/shared/UploadIcon';
import ms from 'ms';
import {showErrorNotification} from 'features/Common/utils';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {useMemoOne} from 'use-memo-one';
import {whitelistedCharsRegex} from 'features/UploadsViewer/Uploader';

// Refactor TODO (jacques): Move to generic dir
export const accessUserUpload = (file: UppyFile) => {
  const bucketKey = file.meta.key.replace(new RegExp(`.${file.extension}$`), '');

  // @ts-ignore
  const uploadURL = file.xhrUpload.endpoint + '/' + file.meta.key;

  return {
    bucket_file_name: file.meta.key,
    bucket_key: bucketKey,
    extension: file.extension,
    // @ts-ignore
    file_name: file.data.name || file.meta.name,
    // @ts-ignore
    last_modified: file.data.lastModified,
    file_size: file.size.toString(),
    source: file.source ?? '',
    file_type: file.type as UserUploadsTypes,
    upload_id: file.id,
    upload_url: uploadURL,
  };
};

type UppyConfig = {
  postProcess: (file: UserUpload) => Promise<any>;
};

// Refactor TODO (jacques): Move to more generic directory
// Refactor TODO (jacques): Convert to using useUppy from package
export const useUppy = ({postProcess}: UppyConfig) => {
  const dispatch = useDispatch();

  const countFilesAdded = useRef(0);
  const successfulFiles = useRef<UppyFile[]>([]);

  const uppy = useMemoOne(() => {
    const onFileAdded = (file: UppyFile) => {
      const isContainingIllegalCharacter = [',', '?', '#', '%', '&'].some(character =>
        file.meta.name.includes(character),
      );

      if (isContainingIllegalCharacter) {
        uppy.removeFile(file.id);
        showErrorNotification({
          title: 'Upload error',
          message:
            'Uploaded file names can not contain illegal characters ?, #, %, and &. Please rename and reupload.',
        });
      } else {
        countFilesAdded.current = countFilesAdded.current + 1;
        window.analytics.track('User added file to upload', {
          number_files_added: countFilesAdded.current,
          ...getMetadata(file),
        });
      }
    };

    const onUploadSuccess = (file: UppyFile) => {
      successfulFiles.current.push(file);
      window.analytics.track('User upload success', {
        number_files_added: successfulFiles.current.length,
        ...getMetadata(file),
      });
    };

    const onBeforeFileAdded = (currentFile: Uppy.UppyFile) => {
      return {
        ...currentFile,
        name: currentFile.name.replace(whitelistedCharsRegex, '_'),
      };
    };

    const onFileRemove = (file: UppyFile) => {
      window.analytics.track('User upload removed', getMetadata(file));
    };

    return Uppy({
      autoProceed: true,
      onBeforeFileAdded,
    })
      .use(AwsS3, {
        timeout: ms('1 minute'),
        companionUrl: UPLOAD_COMPANION_URL,
      })
      .use(Url, {
        companionUrl: UPLOAD_COMPANION_URL,
      })
      .on('error', handleError)
      .on('upload-error', handleUploadError)
      .on('restriction-failed', handleRestrictionFailed)
      .on('file-added', onFileAdded)
      .on('file-remove', onFileRemove)
      .on('upload-success', onUploadSuccess);
  }, []);

  useEffect(() => {
    const postProcessWrapper: UploadHandler = async ids => {
      const filesToProcess = successfulFiles.current
        .filter(file => ids.includes(file.id))
        .map(file => accessUserUpload(file));

      await Promise.all(
        filesToProcess.map(file => {
          return postProcess(file);
        }),
      );
    };

    uppy.addPostProcessor(postProcessWrapper);
    return () => uppy.removePostProcessor(postProcessWrapper);
  }, [uppy, dispatch, postProcess]);

  return uppy;
};

const InputContainer = styled.div`
  .uppy-Root,
  .uppy-FileInput-container {
    position: static;
    margin-bottom: 0;
  }

  .uppy-FileInput-input {
    position: absolute;
    inset: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
`;

export const UploadButton = ({
  uppy,
  children,
  showProgress = true,
}: {
  uppy: ReturnType<typeof useUppy>;
  children: (children: ReactNode) => ReactNode;
  showProgress?: boolean;
}) => {
  return (
    <div>
      {children(
        <InputContainer>
          <FileInput uppy={uppy} pretty={false} />
        </InputContainer>,
      )}
      {showProgress && (
        <div className="-mt-1">
          <StatusBar uppy={uppy} hideUploadButton showProgressDetails />
        </div>
      )}
    </div>
  );
};
