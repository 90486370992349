import {FC, useEffect, useMemo, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import {Helmet} from 'react-helmet';
import classNames from 'classnames';
import {createContainer} from 'unstated-next';
import {routePaths} from 'routes/routesHelper';

export const AppWrap: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Milk Video</title>
      </Helmet>
      <Outlet />
    </>
  );
};

function useAppWrapStylesContainer(initialState = 0) {
  const [appBgClass, setAppBgClass] = useState('');

  const appClass = useMemo(() => {
    return classNames(appBgClass);
  }, [appBgClass]);

  return {appBgClass, setAppBgClass, appClass};
}

const AppWrapStyles = createContainer(useAppWrapStylesContainer);

export const AppWrapStylesProvider = AppWrapStyles.Provider;
export const useAppWrapStyles = AppWrapStyles.useContainer;
