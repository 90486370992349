import {Navigate, Outlet, Route, Routes} from 'react-router-dom';

import {RecordingCamera} from 'pages/UserRecordingForms/InteractionForm/RecordingCamera';
import {RecordingLanding} from 'pages/UserRecordingForms/InteractionForm/RecordingLanding';
import {RecordingSubmissionCompleted} from 'pages/UserRecordingForms/InteractionForm/RecordingSubmissionCompleted';
import {RecordingSubmissionReview} from 'pages/UserRecordingForms/InteractionForm/RecordingSubmissionReview';
import {RecordingSummary} from 'pages/UserRecordingForms/InteractionForm/RecordingSummary';
import {RecordingWrap} from 'pages/UserRecordingForms/RecordingWrap';

export const RoutesShare = () => {
  return (
    <Routes>
      <Route path="/" element={<SharePageWrap />}>
        <Route path="f/*" element={<RecordingWrap />}>
          <Route path={`:recordingInteractionFormId/*`}>
            <Route index element={<RecordingLanding />} />
            <Route path="summary" element={<RecordingSummary />} />
            <Route path="answer" element={<RecordingCamera />} />
            <Route path="review" element={<RecordingSubmissionReview />} />
            <Route path="success" element={<RecordingSubmissionCompleted />} />
          </Route>
          <Route index element={<Navigate to="/dashboard" />} />
        </Route>
        <Route index element={<Navigate to="/dashboard" />} />
        {/* <Route path=":projectId" element={<EditorCanvasPage />} /> */}
        <Route path={`*`} element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
};

const SharePageWrap = () => {
  return <Outlet />;
};
