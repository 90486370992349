import {SanityImageSource} from '@sanity/image-url/lib/types/types';
import imageUrlBuilder from '@sanity/image-url';

const sanityDatasetConfig =
  (import.meta.env.VITE_SANITY_DATASET as string) || 'production';

const sanityProjectIdConfig = (import.meta.env.VITE_SANITY_PROJECT_ID as string) || '';

export const sanityConfig = {
  apiVersion: '2021-10-21',
  dataset: sanityDatasetConfig,
  projectId: sanityProjectIdConfig,
  useCdn: import.meta.env.PROD,
};

export const imageBuilder = imageUrlBuilder(sanityConfig);

/**
 * Sanity image objects are stored with reference ids, which need to be parsed
 * using the image builder to get the url.
 *
 * @param source
 * @returns
 */
export const sanityImage = (source: SanityImageSource) => imageBuilder.image(source);
