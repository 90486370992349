import {CanvasButton} from '../../CanvasButton';
import {Scissors} from 'react-feather';
import {SelectedItem} from 'features/selectors/canvasItemsSelectors';
import {updateItemTimingOffset} from 'features/canvasItemsSlice';
import {useDispatch} from 'react-redux';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

export const TimingOffsetButtom = ({selectedItem}: {selectedItem: SelectedItem}) => {
  const dispatch = useDispatch();
  const projectId = useProjectId();

  const {item, id} = selectedItem;

  return (
    <CanvasButton
      leftIcon={Scissors}
      onClick={() => dispatch(updateItemTimingOffset({projectId, itemId: id}))}
    >
      Update timing
    </CanvasButton>
  );
};
