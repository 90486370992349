import {ReactNode} from 'react';
import {Transition} from '@headlessui/react';
export const DashboardContentSkeleton = ({
  isShowing = true,
  isLoading = false,
  children = <div />,
}: {
  children: ReactNode;
  isShowing?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <Transition
      appear
      show={isShowing}
      enter-active-class="transition-opacity duration-75"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-150"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <main className="z-1 relative h-screen flex-1 overflow-y-auto focus:outline-none">
        {children}
      </main>
    </Transition>
  );
};
