import {
  VIDEO_ITEMS,
  removeCanvasItems,
  updateCanvasItemsPositionState,
} from 'features/canvasItemsSlice';
import {
  ViewTypes,
  captionBasedItemTypes,
} from 'features/EditorCanvas/constants/ViewConstants';
import {
  canvasItemsSelectedItemIdsSelector,
  canvasItemsSelectedItemSelector,
} from 'features/selectors/canvasStateSelectors';
import { useDispatch, useSelector } from 'react-redux';

import { CanvasButton } from 'features/EditorCanvas/components/CanvasButton';
import CaptionTextInactiveColorPicker from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/CaptionTextInactiveColorPicker';
import CropButton from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/CropButton';
import { DurationIgnore } from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/DurationIgnore';
import { MutePicker } from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/MutePicker';
import { TextAlignPicker } from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/TextAlignPicker';
import TextColorPicker from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/TextColorPicker';
import { TextContainerSizeModePicker } from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/TextContainerSizeModePicker';
import TextFontFamilyPicker from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/TextFontFamilyPicker';
import TextSizePicker from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/TextSizePicker';
import { Trash } from 'react-feather';
import { TrimButton } from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/TrimButton';
import { useFeatureFlag } from 'features/hooks/useFeatureFlag';
import { useProjectId } from 'features/EditorCanvas/useProjectId';

export const VideoComponentActionNavBar = () => {
  const dispatch = useDispatch();
  const projectId = useProjectId();

  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);

  const selectedItem = useSelector(canvasItemsSelectedItemSelector)(projectId);

  const isSelectedCaption =
    selectedItem && captionBasedItemTypes.includes(selectedItem.item.viewType);
  const isSelectedText = selectedItem && selectedItem.item.viewType === ViewTypes.Text;
  const isSelectedVideo =
    selectedItem && VIDEO_ITEMS.includes(selectedItem.item.viewType);

  const removeSelectedItem = (itemKey: string) => {
    dispatch(updateCanvasItemsPositionState());
    dispatch(
      removeCanvasItems({
        projectId,
        ids: [itemKey],
      }),
    );
  };

  const canvasSceneFlag = useFeatureFlag('canvas-scene-flag');

  return (
    <>
      {(isSelectedText || isSelectedCaption) && (
        <li className="flex">
          <div className="flex items-center">
            <TextFontFamilyPicker projectId={projectId} />
          </div>
        </li>
      )}
      {(isSelectedText || isSelectedCaption) && (
        <li className="flex">
          <div className="flex items-center">
            <TextSizePicker projectId={projectId} />
          </div>
        </li>
      )}
      {(isSelectedText || isSelectedCaption) && (
        <li className="flex">
          <div className="flex items-center">
            <TextAlignPicker projectId={projectId} />
          </div>
        </li>
      )}
      {(isSelectedText || isSelectedCaption) && (
        <li className="flex">
          <div className="flex items-center">
            <TextColorPicker projectId={projectId} />
          </div>
        </li>
      )}
      {isSelectedText && (
        <li className="flex">
          <div className="flex items-center">
            <TextContainerSizeModePicker projectId={projectId} />
          </div>
        </li>
      )}
      {isSelectedCaption && (
        <li className="flex">
          <div className="flex items-center">
            <CaptionTextInactiveColorPicker projectId={projectId} />
          </div>
        </li>
      )}
      {isSelectedVideo && (
        <li className="flex" data-tip="Mute/Unmute">
          <div className="flex items-center">
            <MutePicker projectId={projectId} />
          </div>
        </li>
      )}
      {canvasSceneFlag && isSelectedVideo && (
        <li className="flex" data-tip="Mute/Unmute">
          <div className="flex items-center">
            <DurationIgnore projectId={projectId} />
          </div>
        </li>
      )}
      {selectedItem && (
        <li className="flex space-x-2">
          <CropButton
            projectId={projectId}
            itemId={selectedItem.id}
            item={selectedItem.item}
          />
          <TrimButton selectedItem={selectedItem} />
        </li>
      )}
      <div className="flex flex-grow" />
      {selectedItemIds.length > 0 && (
        <>
          <li className="flex">
            <div className="flex items-center">
              <CanvasButton
                leftIcon={Trash}
                onClick={() => {
                  selectedItemIds.forEach(itemId => removeSelectedItem(itemId));
                }}
              >
                Delete
              </CanvasButton>
            </div>
          </li>
        </>
      )}
    </>
  );
};
