import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import Uppy, {UploadHandler, UploadResult} from '@uppy/core';
import {
  UppyFile,
  getMetadata,
  handleError,
  handleRestrictionFailed,
  handleUploadError,
} from 'features/FileUploader/utils';
import {
  accessUserUpload,
  useUppy,
} from 'features/Dashboard/DashboardPage/ContentBrandKit/UploadButton';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useLayoutEffect, useMemo, useRef} from 'react';

import AwsS3 from '@uppy/aws-s3';
import {Dashboard} from '@uppy/react';
import {UPLOAD_COMPANION_URL} from 'constants/environment';
import Url from '@uppy/url';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import Zoom from '@uppy/zoom';
import {accountDetailsSelector} from 'features/selectors/authSelectors';
import {getAuthToken} from 'services/utils';
import ms from 'ms';
import {showErrorNotification} from 'features/Common/utils';
import styled from 'styled-components';
import {useCreateUserUploadMutation} from 'services/userUploadAPI';
import {v4 as uuidv4} from 'uuid';

export const whitelistedCharsRegex = /[^0-9a-zA-Z\-\.\_\~]/g;

const Container = styled.div`
  .uppy-Dashboard-inner,
  .uppy-Dashboard-AddFiles {
    border-radius: 0.5rem;
  }

  .uppy-Dashboard-browse {
    color: #6366f1;
  }
`;

export const useUploadFileUppy = (
  onUploadComplete?: (upload: UserUploadsType) => void,
) => {
  const [createUserUpload] = useCreateUserUploadMutation();
  const {accountId, email} = useSelector(accountDetailsSelector);

  return useUppy({
    postProcess: async file => {
      if (!accountId) throw new Error('accountId missing');
      if (!email) throw new Error('email missing');

      const result = await createUserUpload({...file, account_id: accountId});
      'data' in result && onUploadComplete?.(result.data);
    },
  });
};

export function Uploader({
  requireMedia = false,
  shouldNotRemoveAfterUpload = false,
  onUploadComplete,
  onUppy,
  onBeforePostProcess,
}: {
  requireMedia?: boolean;
  shouldNotRemoveAfterUpload: boolean;
  onUploadComplete?: (userUpload: UserUploadsType) => void;
  onUppy?: (uppy: Uppy.Uppy<Uppy.TypeChecking>) => void;
  onBeforePostProcess?: () => void | Promise<void>;
}) {
  const dispatch = useDispatch();

  const countFilesAdded = useRef(0);
  const successfulFiles = useRef<UppyFile[]>([]);
  const successfulUploads = useRef<string[]>([]);

  const onUploadCompleteRef = useRef(onUploadComplete);
  useLayoutEffect(() => {
    onUploadCompleteRef.current = onUploadComplete;
  }, [onUploadComplete]);

  const uppy = useUploadFileUppy(userUpload => {
    // console.log('success', userUpload);
    if (onUploadComplete) {
      onUploadComplete(userUpload);
    }
  });
  const onUppyRef = useRef(onUppy);

  useLayoutEffect(() => {
    onUppyRef.current = onUppy;
  }, [onUppy]);

  useEffect(() => {
    onUppyRef.current?.(uppy);
  }, [uppy]);

  const onBeforePostProcessRef = useRef(onBeforePostProcess);

  useLayoutEffect(() => {
    onBeforePostProcessRef.current = onBeforePostProcess;
  }, [onBeforePostProcess]);

  useEffect(() => () => uppy.close(), [uppy]);

  return (
    <Container>
      <Dashboard
        uppy={uppy}
        plugins={
          [
            // 'Zoom',
            // 'Url'
          ]
        }
        showProgressDetails
        proudlyDisplayPoweredByUppy={false}
        height={200}
        width="100%"
      />
    </Container>
  );
}
