export default function LoadingDownloads() {
  return (
    <div className="flex h-full w-full flex-col space-y-3 border-b border-gray-200 px-4 py-4">
      <div className="grid grid-cols-3 gap-4">
        <div className="h-48 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        <div className="h-48 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        <div className="h-48 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        <div className="h-48 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        <div className="h-48 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
        <div className="h-48 w-full animate-pulse overflow-hidden rounded-md bg-gray-50"></div>
      </div>
    </div>
  );
}
