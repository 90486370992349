import {StoredCrop, DimensionType} from 'features/types/canvasItemsSlice';

import {UnreachableCaseError} from 'features/Common/UnreachableCaseError';
import {cropParseFloat} from '../AppCanvas/Canvas/utils/useEditCrop';

const toPercent = (decimal: number) => {
  return `${decimal * 100}%`;
};

export const Mask = ({
  dimension,
  crop: storedCrop,
}: {
  dimension: DimensionType;
  crop: StoredCrop;
}) => {
  const crop = cropParseFloat(storedCrop);

  if (crop.type === 'rectangle') {
    const insetXPx = dimension.width * (crop.left + crop.right);
    const insetYPx = dimension.height * (crop.top + crop.bottom);

    return (
      <rect
        x={toPercent(crop.left)}
        y={toPercent(crop.top)}
        width={dimension.width - insetXPx}
        height={dimension.height - insetYPx}
        fill="black"
      />
    );
  } else if (crop.type === 'circle') {
    return (
      <circle
        r={toPercent(crop.radius)}
        cx={toPercent(crop.centerX)}
        cy={toPercent(crop.centerY)}
        fill="black"
      />
    );
  } else {
    throw new UnreachableCaseError(crop);
  }
};

export const CropOverlay = ({
  dimension,
  crop,
  id,
}: {
  dimension: DimensionType;
  crop?: StoredCrop;
  id: string;
}) => {
  if (!crop) return null;

  return (
    <svg
      style={{
        position: 'absolute',
        inset: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      }}
    >
      <defs>
        <mask id={`hole-${id}`}>
          <rect width={dimension.width} height={dimension.height} fill="white" />
          <Mask crop={crop} dimension={dimension} />
        </mask>
      </defs>
      <rect
        id="overlay"
        width="100%"
        height="100%"
        mask={`url(#hole-${id})`}
        fill="rgba(0, 0, 0, 0.7)"
      />
    </svg>
  );
};
