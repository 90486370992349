import { NetworkAside, PeopleAside } from 'vendor/dailyco/shared/components/Aside';
import { useUIState } from 'vendor/dailyco/shared/contexts/UIStateProvider';

export const Asides = () => {
  const { asides } = useUIState();

  return (
    <>
      <PeopleAside />
      <NetworkAside />
      {asides.map((AsideComponent) => (
        <AsideComponent key={AsideComponent.name} />
      ))}
    </>
  );
};

export default Asides;
