import {useEffect, useState} from 'react';

import {InlineCanvas} from 'pages/Canvas/InlineCanvas';
import {PreviewContainer} from './PreviewContainer';
import {ScalePreview} from 'features/FocusedFlow/LayoutUtils';
import {WizardState} from './NewProjectWizard';
import {objMap} from 'features/Common/utils';

export const TemplatePreview = ({
  state,
  activeItemId,
  cropItemId,
}: {
  state: WizardState;
  activeItemId: string | false;
  cropItemId: string | false;
}) => {
  const [localState, setLocalState] = useState(state.items);

  useEffect(() => {
    const items = objMap(state.items, (item, id) => ({
      ...item,
      ...state.itemOverrides[id],
    }));
    setLocalState({...items});
  }, [state.items, state.itemOverrides]);

  return (
    <PreviewContainer dimensions={state.sceneFrame.canvasDimensions}>
      <ScalePreview
        dimensions={state.sceneFrame.canvasDimensions}
        className="overflow-hidden rounded-xl"
        maxHeight={300}
        overlay={<div />}
      >
        <div className="relative">
          <InlineCanvas
            key={JSON.stringify(localState)}
            items={localState}
            backgroundColor={'#fff'}
            canvasDimensions={state.sceneFrame.canvasDimensions}
            height={240}
          />
          <div className="absolute inset-0" />
        </div>
      </ScalePreview>
    </PreviewContainer>
  );
};
