import {Layout, Plus} from 'react-feather';
import {Button} from 'ui/Button';

export type EmptyStateProps = {
  createHandler: () => any;
};

export default function LandingPageIndexEmptyState({createHandler}: EmptyStateProps) {
  return (
    <div className="justify-center pt-8 text-center">
      <h3 className="mt-2 text-sm font-medium text-gray-900">No landing pages</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new landing page.
      </p>
      <div className="mt-6">
        <Button variant="primary" onClick={createHandler}>
          <span className="flex items-center space-x-2">
            <Plus />
            <span>New Landing Page</span>
          </span>
        </Button>
      </div>
    </div>
  );
}
