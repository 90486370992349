import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UploadMediaClipType } from 'services/uploadMediaClipAPI';
import { updateClipName } from 'features/userLibrarySlice';
import { useAuthAlt } from 'features/Auth/useAuthAlt';
import { useClipList } from 'features/Dashboard/DashboardPage/ContentClips/ClipListContext';
import { userLibrarySelector } from 'features/selectors/userLibrarySelectors';

export default function ClipCardRenameModal({ userClips }: { userClips: UploadMediaClipType[] }) {
  const {
    state: { selectedIdList, openClipRenameModal },
    dispatch: clipDispatch,
  } = useClipList();
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuthAlt();

  const clip = userClips.find(clip => selectedIdList?.includes(clip?.id));

  const [clipName, setClipName] = useState(clip?.clip_name);
  const [hasLoadedName, setHasLoadedName] = useState(false);

  useEffect(() => {
    if (!hasLoadedName && clip?.clip_name) {
      setClipName(clip.clip_name);
      setHasLoadedName(true);
    }
  }, [clip, hasLoadedName]);

  const closeModal = () => {
    clipDispatch({
      type: 'update clip rename modal',
      payload: {
        openClipRenameModal: false,
      },
    });
  };

  const startUploadClipNameUpdate = () => {
    if (!clip || !clipName) {
      return;
    }
    window.analytics.track('User upload name update');
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(updateClipName(token, clip.id, clipName));
      } catch (e) {
        console.error(e);
      }
    })();
    closeModal();
  };

  return (
    <Transition.Root show={openClipRenameModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Rename clip
                  </Dialog.Title>
                  <div className="mt-2 w-full">
                    <div className="mt-1">
                      <input
                        value={clipName}
                        onChange={evt => {
                          window.analytics.track('Clip name changed');
                          setClipName(evt.target.value);
                        }}
                        className="block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={startUploadClipNameUpdate}
                >
                  Update
                </button>
                <button
                  type="button"
                  className="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
