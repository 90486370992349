import {ButtonConfig, ModalButtons} from 'ui/Modal';
import {CanvasItemSource, getSourceData} from 'features/Dashboard/useCreateProject';
import {DimensionType, PositionType} from 'features/types/canvasItemsSlice';
import {FC, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {WizardActions, WizardState, WizardStepProps} from './NewProjectWizard';
import styled, {css, keyframes} from 'styled-components';

import {ArrowLeft} from 'react-feather';
import {Box} from 'features/EditorCanvas/Layout/Box';
import {IconButton} from 'ui/IconButton';
import {PromiseType} from 'utility-types';
import classNames from 'classnames';
import {useAuthAlt} from 'features/Auth/useAuthAlt';

export const StepButtons = ({
  actions,
  overrideConfirm,
}: Pick<WizardStepProps, 'state' | 'actions'> & {
  overrideConfirm?: Partial<ButtonConfig>;
}) => {
  let confirm: ButtonConfig = {
    label: 'Next',
    onClick: () => actions.nextStep(),
  };

  if (overrideConfirm) {
    confirm = {...confirm, ...overrideConfirm};
  }

  return (
    <>
      <div className="!mt-0 flex-1" />
      <div className="sticky left-0 bottom-0 -mx-5 -mt-5 bg-white p-5 pt-4">
        <ModalButtons confirm={confirm} />
      </div>
    </>
  );
};

const Instructions = styled.div`
  strong {
    font-weight: 600;
  }
`;

export const StepHeader: FC<{
  heading: string;
  state: WizardState;
  actions: WizardActions;
}> = ({heading, state, actions, children}) => (
  <div>
    <div className="mb-1.5 flex items-center justify-start space-x-2">
      {state.step !== 0 && (
        <IconButton
          variant="inline"
          icon={ArrowLeft}
          label="Go back"
          size="small"
          negativeMargin
          onClick={() => {
            window.analytics.track('TmpWzd -- Evt-Clk -- Back step', {
              step: state.step,
            });

            actions.previousStep();
          }}
        />
      )}
      {/* UI TODO (jacques): Typography Heading component */}
      <div className="flex-1 text-3xl font-extrabold text-gray-900">{heading}</div>
    </div>
    <Instructions>{children}</Instructions>
  </div>
);

export const fitUserUpload = ({
  userUpload,
  within,
  position = {top: 0, left: 0},
}: {
  userUpload: {max_width: number | null; max_height: number | null};
  within: DimensionType;
  position?: PositionType;
}) => {
  if (userUpload.max_width && userUpload.max_height) {
    const box = new Box(
      {width: userUpload.max_width, height: userUpload.max_height},
      {dimension: within, position},
    )
      .fit()
      .center();

    return box.getBox();
  }

  return {dimension: within, position};
};

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(67, 56, 202, 1);
  }

  100% {
    box-shadow: 0 0 0 5px rgba(67, 56, 202, 0);
  }
`;

export const Pulse = styled.div<{active?: boolean}>`
  ${({active}) =>
    active &&
    css`
      animation: 1.5s ${pulse} infinite;
    `}
`;

export const useShouldPulse = ({
  after,
  onlyIf = true,
  startDelay = true,
}: {
  after: number;
  onlyIf?: boolean;
  startDelay?: boolean;
}) => {
  const [timedOut, setTimedOut] = useState(false);
  useEffect(() => {
    if (!startDelay) return;

    const id = setTimeout(() => {
      setTimedOut(true);
    }, after);

    return () => clearTimeout(id);
  }, [after, startDelay]);

  const [cancelled, setCancelled] = useState(false);

  return {
    shouldPulse: timedOut && onlyIf && !cancelled,
    cancelPulse: () => setCancelled(true),
  };
};

export const Stepper = ({steps, activeStep}: {steps: number; activeStep: number}) => {
  return (
    <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center space-x-2 p-5">
      {Array(steps)
        .fill(null)
        .map((_, index) => {
          const active = activeStep === index;
          const activeOrComplete = index <= activeStep;

          return (
            <div
              key={index}
              className={classNames(
                'h-2.5 w-2.5 rounded-full ring-0 ring-indigo-100 transition',
                activeOrComplete ? 'bg-indigo-600' : 'bg-indigo-100',
                active && 'ring',
              )}
            />
          );
        })}
    </div>
  );
};

export const usePreventWindowClose = (shouldPrevent: () => boolean) => {
  const shouldPreventRef = useRef(shouldPrevent);
  useLayoutEffect(() => {
    shouldPreventRef.current = shouldPrevent;
  }, [shouldPrevent]);

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      if (shouldPreventRef.current()) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, []);
};

type SourceData = PromiseType<ReturnType<typeof getSourceData>>;

export const useSourceData = (source: CanvasItemSource | undefined) => {
  const [data, setData] = useState<SourceData | undefined>();
  const {getAccessTokenSilently} = useAuthAlt();

  const {id: sourceId, type: sourceType} = source || {};

  useEffect(() => {
    if (!sourceId || !sourceType) return;

    const stale = {current: false};

    getAccessTokenSilently()
      .then(token => {
        if (stale.current) return;
        return getSourceData(token, {id: sourceId, type: sourceType});
      })
      .then(data => {
        if (!data || stale.current) return;
        setData(data);
      });

    return () => {
      stale.current = true;
    };
  }, [getAccessTokenSilently, sourceId, sourceType]);

  return data;
};
