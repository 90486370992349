import {SanityImageSource} from '@sanity/image-url/lib/types/types';
import {UserLibraryUserProjectType} from 'features/types/userLibrarySlice';
import {baseApi} from './baseAPI';

export type SanityResponse = {
  templates: SanityTemplateEntryResponse[];
  categories: SanityTemplateCategoryResponse[];
};

export type SanityTemplateEntryResponse = {
  _createdAt: string;
  _id: string;
  _rev: string;
  _type: string;
  _updatedAt: string;
  data: string; // Stringified JSON
  title: string;
  description: string;
  image?: SanityImageSource;
  tags: {title: string; slug: string}[];
  categories: {title: string; slug: string}[];
};

export type SanityTemplateCategoryResponse = {
  title: string;
  headline: string;
  description: string;
  image?: SanityImageSource;
  slug: string;
};

const SANITY_QUERY = `
{
  'templates': *[_type=='template']|order(_updatedAt desc){...,tags[]->{title,'slug':slug.current},categories[]->{title,'slug':slug.current}},
  'categories': *[_type=='templateCategory']{title, headline, description, image, 'slug': slug.current},
}`;

export const templatesAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['Template']})
  .injectEndpoints({
    endpoints: builder => ({
      fetchTemplates: builder.query<UserLibraryUserProjectType[], void>({
        query: () => ({
          url: `templates`, // /api/v1/templates
          errorTitle: 'Error fetching templates',
          method: 'GET',
          refetchOnFocus: false,
          refetchOnReconnect: false,
        }),
        providesTags: ['Template'],
      }),
      fetchCmsTemplates: builder.query<SanityResponse, void>({
        query: () => ({
          url: `templates?query=${JSON.stringify(SANITY_QUERY)}`, // /api/v2/templates
          errorTitle: 'Error fetching templates',
          method: 'GET',
          refetchOnFocus: false,
          refetchOnReconnect: false,
          v2: true,
        }),
        providesTags: ['Template'],
      }),
      publishTemplate: builder.mutation<void, {projectSlug: string}>({
        query: ({projectSlug}) => ({
          url: `templates/${projectSlug}/publish`,
          errorTitle: 'Error publishing template',
          method: 'POST',
        }),
        invalidatesTags: ['Template', 'Project'],
      }),
    }),
  });

export const {
  useFetchTemplatesQuery,
  usePublishTemplateMutation,
  useFetchCmsTemplatesQuery,
} = templatesAPI;
