import {
  AccountInvitationType,
  AccountWrapperAccountUsersType,
  UserType,
} from 'features/types/authSlice';
import {Button, ButtonStyle} from 'features/Common/Button';
import {Dialog, Transition} from '@headlessui/react';
import {ExclamationIcon, XIcon} from '@heroicons/react/outline';
import {Fragment, useEffect, useRef, useState} from 'react';
import {
  authInviteTeamDetailsSelector,
  authSelector,
} from 'features/selectors/authSelectors';
import {revokeAccountUserInvite, sendInvitationToUser} from 'features/authSlice';
import {useDispatch, useSelector} from 'react-redux';

import {Card} from 'features/Common/Card';
import {CardHeader} from 'features/Common/CardHeader';
import {LoadingSpinnerInline} from 'features/EditorCanvas/components/shared/LoadingSpinnerInline';
import {Table} from 'features/Common/Table';
import {X} from 'react-feather';
import {format} from 'date-fns';
import {useAuthAlt} from 'features/Auth/useAuthAlt';

type AccountWrapperAccountRowProp = {
  user: UserType;
  account_user: AccountWrapperAccountUsersType;
};

export function SettingsInviteTeamCard() {
  const {user, currentAccountSettings, accountId, accountInvitations, accountUsers} =
    useSelector(authInviteTeamDetailsSelector);
  const {sendInvitation} = currentAccountSettings;
  const {getAccessTokenSilently} = useAuthAlt();
  const dispatch = useDispatch();
  const [inviteEmail, setInviteEmail] = useState('');
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);
  const [inviteId, setInviteId] = useState('');
  const [revokeEmail, setRevokeEmail] = useState('');
  const cancelButtonRef = useRef(null);

  const [, setApiError] = useState('');
  useEffect(() => {
    window.analytics.track('Loaded settings page - viewed account preferences page');
  }, []);

  useEffect(() => {
    if (sendInvitation && sendInvitation.wasSent) {
      window.analytics.track('Account invitation sent');
      setInviteEmail('');
    }
  }, [sendInvitation]);

  const revokeModalOpen = (inviteId: string, email: string) => {
    setInviteId(inviteId);
    setRevokeEmail(email);
    setIsRevokeModalOpen(true);
  };

  if (!user) {
    return null;
  }

  const closeRevokeModal = () => {
    window.analytics.track('Invite revoke close model');
    setIsRevokeModalOpen(false);
  };

  const revokeInviteConfirm = (callback: () => void) => {
    window.analytics.track('Account invite revoke clicked');
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(revokeAccountUserInvite(token, user.email, inviteId));
      } catch (e: any) {
        setApiError(e.message);
        console.error(e);
      }
      callback();
    })();
  };

  const onClickInvite = () => {
    window.analytics.track('Account invite send clicked');
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(
          sendInvitationToUser(
            token,
            `${accountId}`,
            user.email,
            `${inviteEmail}`.toLowerCase(),
          ),
        );
      } catch (e: any) {
        setApiError(e.message);
        console.error(e);
      }
    })();
  };

  return (
    <Card>
      <CardHeader
        title="Invite Members"
        subtitle="Invite anyone to your team. Once added they will have access to all of your teams projects and uploads."
      />
      <div className="">
        <div className="flex flex-col">
          <div className="mt-1 flex px-6 pt-6">
            <div className="mb-2 flex w-full flex-row">
              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                Email
              </span>
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <input
                  type="text"
                  name="invite_url"
                  id="invite_url"
                  className="block w-4/5 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="name@domain.com"
                  value={inviteEmail}
                  onChange={evt => {
                    window.analytics.track('Account invite email changed');
                    setInviteEmail(evt.target.value);
                  }}
                />
              </div>
              <div className="w-3/5 px-3">
                <Button style={ButtonStyle.PRIMARY} onClick={onClickInvite}>
                  Invite
                  {sendInvitation.isSending && (
                    <span className="ml-3">
                      <LoadingSpinnerInline isLight={true} />
                    </span>
                  )}
                </Button>
              </div>
            </div>
          </div>
          {accountUsers && accountInvitations && (
            <div className="mt-4">
              {accountInvitations.length > 0 && (
                <Table
                  items={accountInvitations.map(
                    (user: AccountInvitationType, idx: number) => {
                      return {user, account_user: accountUsers[idx]};
                    },
                  )}
                  renderRow={(item: AccountWrapperAccountRowProp) => {
                    return (
                      <>
                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                          {item.user.first_name
                            ? `${item.user.first_name} ${item.user.last_name}`
                            : item.user.email}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                          {format(item.user.created_at, 'Pp')}
                        </td>
                        <td className="inline-flex w-full flex-row justify-end whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                          <button
                            onClick={() => revokeModalOpen(item.user.id, item.user.email)}
                            className="flex text-sm leading-5 text-red-600 hover:text-red-700 focus:text-red-600 focus:outline-none"
                            role="menuitem"
                          >
                            <X size={20} />
                            <span className="ml-2">Revoke</span>
                          </button>
                        </td>
                      </>
                    );
                  }}
                  headers={['User', 'Invite sent', '']}
                  noItemsMessage="There are no team members."
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Transition.Root show={isRevokeModalOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-10 overflow-y-auto"
          style={{zIndex: 2000}}
          initialFocus={cancelButtonRef}
          open={isRevokeModalOpen}
          onClose={setIsRevokeModalOpen}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => closeRevokeModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Are you sure?
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        This will revoke the invite sent to &quot;<b>{revokeEmail}</b>
                        .&quot; You will need to resend it if you want to invite them
                        again.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => revokeInviteConfirm(closeRevokeModal)}
                  >
                    Deactivate
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => closeRevokeModal()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Card>
  );
}
