import {
  BaseTranscriptWord,
  TimeSeconds,
  UserUploadsType,
} from 'features/types/userLibrarySlice';

import {BASE_URL} from 'constants/environment';
import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {TranscriptProviders} from './userLibraryAPI';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import axios from 'axios';
import {requestHeader} from './helpers';

export type ClipIdType = string;
export type AccountIdType = string;
export type CurrentUserEmailType = string;

export type UploadBucketKeyType = string;
export type ThumbnailUrlType = string;
export type ClipMediaType = string;
export type Auth0RequestTokenType = string;

type CreateUserUploadClipOptionType = {
  thumbnailUrl: ThumbnailUrlType;
  content: string;
  clipStart: TimeSeconds;
  clipEnd: TimeSeconds;
  uploadBucketKey: UploadBucketKeyType;
  language: LanguageId;
};

export async function postCreateUserUploadClip(
  token: Auth0RequestTokenType,
  options: CreateUserUploadClipOptionType,
  email: CurrentUserEmailType,
  accountId: AccountIdType,
) {
  // console.log('email', email)
  // console.log('accountId', accountId)
  const {thumbnailUrl, content, clipStart, clipEnd, uploadBucketKey, language} = options;
  const apiUrl = `${BASE_URL}/api/v1/clips`;
  const sortedTimes = [clipStart, clipEnd].sort((a, b) => a - b);
  const response = await axios.post(
    apiUrl,
    {
      upload_media_clip: {
        thumbnail_url: thumbnailUrl,
        text_snippet: content,
        end_time: sortedTimes[1],
        start_time: sortedTimes[0],
        account_id: accountId,
        bucket_key: uploadBucketKey,
        language: language,
      },
      email,
      account_id: accountId,
    },
    requestHeader(token),
  );
  return response.data.data;
}

export async function getUserClip(
  token: string,
  id: string,
): Promise<UploadMediaClipType> {
  const clipsEndpoint = `${BASE_URL}/api/v1/clips/${id}`;
  const response = await axios.get(clipsEndpoint, requestHeader(token));
  return response.data;
}

export async function getUserClips(token: Auth0RequestTokenType) {
  const clipsEndpoint = `${BASE_URL}/api/v2/clips/list`;
  const response = await axios.get(clipsEndpoint, requestHeader(token));
  return response.data;
}

export async function submitUserClipDelete(
  token: Auth0RequestTokenType,
  clipId: ClipIdType,
) {
  const url = `${BASE_URL}/api/v1/clips/${clipId}/delete`;

  const response = await axios.post(url, {}, requestHeader(token));
  return response.data;
}

export async function postNewClipName(
  token: string,
  clipId: string,
  newClipName: string,
) {
  const url = `${BASE_URL}/api/v1/clips/update_name`;

  const response = await axios.post(
    url,
    {
      clip: {
        clip_id: clipId,
        clip_name: newClipName,
      },
    },
    requestHeader(token),
  );
  return response.data;
}

export async function getUserClipCaption(token: Auth0RequestTokenType, clipId: string) {
  const clipsCaptionEndpoint = `${BASE_URL}/api/v1/clips/caption`;
  const response = await axios.post(
    clipsCaptionEndpoint,
    {clip_id: clipId},
    requestHeader(token),
  );

  // console.log('response', response);
  return response.data.payload as {
    upload_media_clip: UploadMediaClipType;
    user_upload: UserUploadsType;
    upload_transcript: {
      provider: TranscriptProviders;
      transcript: BaseTranscriptWord[][];
      language: LanguageId | undefined;
    };
  };
}

/**
 * Create a colored label on a clip.
 *
 * @param token
 * @param newColor
 * @param clipIds
 * @returns
 */
export async function postUpdateClipLabelColor({
  token,
  newColor,
  clipIds,
  shouldRemove,
}: {
  token: string;
  newColor: string;
  clipIds: string[];
  shouldRemove: boolean;
}) {
  const clipsLabelEndpoint = `${BASE_URL}/api/v2/clips/label`;

  const response = await axios.post(
    clipsLabelEndpoint,
    {
      clip_ids: clipIds,
      new_color: newColor,
      should_remove: shouldRemove,
    },
    requestHeader(token),
  );

  // console.log('response', response);
  return response.data.payload as {
    clip: UploadMediaClipType[];
  };
}

/**
 * Reset labels on a clip.
 *
 * @param token
 * @param clipIds
 * @returns
 */
export async function postResetClipLabelColor({
  token,
  clipIds,
}: {
  token: string;
  clipIds: string[];
}) {
  const clipsLabelEndpoint = `${BASE_URL}/api/v2/clips/label/reset`;

  const response = await axios.post(
    clipsLabelEndpoint,
    {
      clip_ids: clipIds,
    },
    requestHeader(token),
  );

  // console.log('response', response);
  return response.data.payload as {
    clip: UploadMediaClipType[];
  };
}
