import cn from 'classnames';
import copy from 'copy-to-clipboard';
import {store} from 'react-notifications-component';
import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import NotificationInfo from 'features/Notifications/NotificationInfo';

export const onClickCopyLink = options => {
  const {title, message, copyLink} = options;
  copy(copyLink);
  store.addNotification({
    ...NOTIFICATION_BASE,
    content: <NotificationInfo title={title} message={message} />,
  });
};

export default function CanvasModalPublish({projectId}) {
  const previewUrl = `${window.location.origin}/share/p/${projectId}`;
  return (
    <>
      <div className="mt-3 mb-2 w-full px-1 text-center sm:mt-0 sm:text-left">
        <div className="mt-5 w-full rounded-md border border-gray-200 px-4">
          <dl className="w-full sm:divide-y  sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <div
                onClick={() => {
                  console.error('This is not a real url');
                  onClickCopyLink({
                    copyLink: previewUrl,
                    title: 'Copy successful',
                    message: 'Project url was successfully saved to clipboard',
                  });
                }}
                className="col-span-3 flex w-full items-center rounded-lg bg-gray-100 py-2 px-2 text-xs "
              >
                <div className="flex-grow">{previewUrl}</div>
                <div className="flex-shrink">
                  <svg className="-ml-1 mr-2 h-5 w-5" viewBox="0 0 24 24" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.09 9a3.09 3.09 0 100 6.182h2.455a1 1 0 010 2H7.091A5.09 5.09 0 117.09 7h2.454a1 1 0 010 2H7.091zM16.91 15.182a3.09 3.09 0 100-6.182h-2.456a1 1 0 010-2h2.455a5.091 5.091 0 010 10.182h-2.454a1 1 0 010-2h2.454z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.727 12.091a1 1 0 011-1h6.546a1 1 0 110 2H8.727a1 1 0 01-1-1z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="py-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="col-span-2 text-sm font-medium text-gray-500">
                Anyone with the link
              </dt>
              <dd className="col-span-1 mt-1 flex justify-end text-sm text-gray-900 sm:mt-0">
                Can access
              </dd>
            </div>
          </dl>
        </div>
        <div className="mt-4 flex justify-end">
          <a
            href={`${previewUrl}`}
            type="button"
            className={cn(
              'bg-indigo-600 hover:bg-indigo-700',
              ' focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent px-3 py-1 text-sm  font-medium text-white focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto',
            )}
          >
            View share page
          </a>
        </div>
      </div>
    </>
  );
}
