import { Link, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

import { ApiRecordingSession } from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import { Button } from 'ui/Button';
import { LockClosedIcon } from '@heroicons/react/solid';
import { ahoy } from 'features/providers/TrackingVisitProvider';
import classNames from 'classnames';
import { string } from 'prop-types';
import { useRecordingFormQuery } from 'services/recordingFormsAPI';

export function RecordingLanding({ formOverrideId }: { formOverrideId?: string }) {
  const { recordingInteractionFormId } = ApiRecordingSession.useContainer();

  useEffect(() => {
    if (recordingInteractionFormId) {
      window.analytics.track('RecordingInteractionForm opened', {
        recording_interaction_form_id: recordingInteractionFormId,
      });
      ahoy.track('RecordingInteractionForm opened', {
        recording_interaction_form_id: recordingInteractionFormId,
      });
    }
  }, [recordingInteractionFormId])

  const recordingFormId = formOverrideId ? formOverrideId : recordingInteractionFormId;
  const {
    data: recordingInteractionFormData,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useRecordingFormQuery({ id: recordingFormId! });

  let hasPersonalMessage,
    avatarUrl,
    messageAuthor,
    messageBody = null;

  if (isSuccess && recordingInteractionFormData?.metadata) {
    const { has_personal_message, avatar_url, message_author, message_body } =
      recordingInteractionFormData.metadata;
    hasPersonalMessage = has_personal_message;
    avatarUrl = avatar_url;
    messageAuthor = message_author;
    messageBody = message_body;
  }

  const formQuestion = useMemo(() => {
    if (
      !recordingInteractionFormData?.recording_form_prompts ||
      !recordingInteractionFormData?.recording_form_prompts[0] ||
      !recordingInteractionFormData?.recording_form_prompts[0].description
    ) {
      return '';
    }
    return recordingInteractionFormData?.recording_form_prompts[0].description;
  }, [recordingInteractionFormData]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (isError) {
    return <ErrorState />;
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          {/* <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt="Workflow"
            />
          </div> */}
          {hasPersonalMessage && avatarUrl && messageAuthor && messageBody && (
            <div className="mt-8 pb-6">
              <RecordingPersonalMessage
                avatarUrl={avatarUrl}
                author={messageAuthor}
                body={messageBody}
              />
            </div>
          )}
          <div className="space-y-3 pb-8">
            <h1 className="text-center text-2xl font-bold">{formQuestion}</h1>
            <p className="text-center text-base">
              Proceed to recording a video response by clicking the button below.
            </p>
          </div>
          <div className="mx-auto max-w-xs space-y-3">
            <Link to="summary">
              <Button
                fullWidth
                variant="primary"
                _className="!text-lg !py-3"
                size="large"
              >
                Get started
              </Button>
            </Link>
            {/* <Button
              fullWidth
              variant="secondary"
              _className="!text-lg !py-3"
              size="large"
            >
              Schedule for later
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
}

const ErrorState = () => {
  return (
    <div>
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold uppercase tracking-wide text-indigo-600">
              Invalid link
            </p>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Request not found
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Please check with the original requestor for the correct link.
            </p>
            <div className="mt-6">
              If you feel this is an error,{' '}
              <a
                href="mailto:support@milkvideo.com"
                className="text-base font-medium text-indigo-600 hover:text-indigo-500"
              >
                email our support team.
              </a>
            </div>
          </div>
        </div>
      </main>
      {/* <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
      <nav className="flex justify-center space-x-4">
        <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
          Contact Support
        </a>
        <span className="inline-block border-l border-gray-300" aria-hidden="true" />
        <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
          Status
        </a>
        <span className="inline-block border-l border-gray-300" aria-hidden="true" />
        <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
          Twitter
        </a>
      </nav>
    </footer> */}
    </div>
  );
};

const LoadingState = () => {
  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <div className="mx-auto flex h-12 w-12 animate-pulse rounded-lg bg-gray-100"></div>
        </div>

        <div className="mt-8 pb-6">
          <div className="flex">
            <div className="mr-4 flex-shrink-0">
              <span className="inline-block h-10 w-10 animate-pulse overflow-hidden rounded-full bg-gray-100"></span>
            </div>
            <div className="w-full space-y-3">
              <div
                className="h-6 w-full animate-pulse rounded-lg bg-gray-200"
                style={{ maxWidth: 200 }}
              />

              <div className="flex w-full flex-col space-y-2">
                <div
                  className="h-4 w-full animate-pulse rounded-md bg-gray-200"
                  style={{ maxWidth: 300 }}
                />
                <div
                  className="h-4 w-full animate-pulse rounded-md bg-gray-200"
                  style={{ maxWidth: 300 }}
                />
                <div
                  className="h-4 w-full animate-pulse rounded-md bg-gray-200"
                  style={{ maxWidth: 300 }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-xs space-y-3">
          <div
            className="w-full animate-pulse rounded-md bg-gray-200"
            style={{ height: 56 }}
          />
          <div
            className="w-full animate-pulse rounded-md bg-gray-100"
            style={{ height: 56 }}
          />
        </div>
      </div>
    </div>
  );
};

export const RecordingPersonalMessage = ({
  color = 'dark',
  author,
  body,
  avatarUrl,
}: {
  color?: 'dark' | 'light';
  author: string;
  body: string;
  avatarUrl: string;
}) => {
  return (
    <div className="flex">
      <div className="mr-4 flex-shrink-0">
        <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
          {avatarUrl ? (
            <img src={avatarUrl} className="h-full w-full" />
          ) : (
            <svg
              className="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          )}
        </span>
      </div>
      <div className="text-sm">
        <h4
          className={classNames('font-semibold', {
            'text-white': 'light' === color,
          })}
        >
          {author} <span className="font-normal">says:</span>
        </h4>
        <p
          className={classNames('mt-1', {
            'text-white': 'light' === color,
          })}
        >
          {body}
        </p>
      </div>
    </div>
  );
};
