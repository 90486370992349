import { LanguageId, useLanguage } from './LanguageState';
import {
  ScrollContainer,
  VisibleScrollProvider,
  VisibleTimeText,
} from './ThumbnailTimeline/VisibleTranscript';
import { useEffect, useState } from 'react';
import { usePollTranscript, useTranscript } from 'features/Captions/Transcripts';

import { ControlledVideo } from 'features/EditorCanvas/components/CanvasTime/ControlledVideo';
import DetailTypeWorkspaceNav from './DetailTypeWorkspaceNav';
import DetailUploadsClips from './DetailUploadsClips';
import { DetailVideoPlayerThumbnails } from './ThumbnailTimeline/DetailVideoPlayerThumbnails';
import { EditModeToolbar } from 'features/TranscriptEditor/EditModeToolbar';
import { HotkeyProvider } from 'features/TranscriptEditor/Hotkeys';
import InlineNotification from './InlineNotification';
import { ItemLayerSources } from 'features/EditorCanvas/constants/ViewConstants';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import LoadingText from 'features/EditorCanvas/components/shared/LoadingText';
import { NewProjectWizard } from './NewProjectWizard/NewProjectWizard';
import { SelectionProvider } from 'features/TranscriptEditor/selection';
import { ShareTranscriptModal } from './ShareTranscriptModal';
import { SlateEventsProvider } from 'features/TranscriptEditor/SlateEvents';
import { SrtDownloadModal } from './SrtDownloadModal';
import TemplateSelectionPopup from './TemplateSelectionModal/TemplateSelectionPopup';
import { TimeProvider } from 'features/EditorCanvas/components/CanvasTime/TimeProvider';
import { TranscriptEditor } from 'features/TranscriptEditor/TranscriptEditor';
import TranscriptEditorToolbar from './TranscriptEditorToolbar';
import { TranscriptsSummaryPanel } from './UploadTranscriptsSummary/TranscriptsSummaryPanel';
import cn from 'classnames';
import { isAudioMedia } from '../utils';
import { isEnvProd } from 'constants/environment';
import { recentUsageAPI } from 'services/recentUsageAPI';
import useDimensions from 'react-use-dimensions';
import { useFeatureFlag } from 'features/hooks/useFeatureFlag';
import { usePlayback } from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import { useUploadTranscript } from './UploadTranscriptContext';
import { useUserUploadQuery } from 'services/userUploadAPI';

type DetailTypePlayableMediaContainerProps = {
  userUploadId: string;
};

export type TranscriptNotification = {
  title: string;
  subtitle: string;
  extraNote?: string;
};

const TranscriptEditorWrap = ({
  userUploadId,
  language,
}: {
  userUploadId: string;
  language: LanguageId;
}) => {
  const { seekTo } = usePlayback();

  return (
    <TranscriptEditor userUploadId={userUploadId} language={language} seekTo={seekTo} />
  );
};

export const DetailTypePlayableMediaContainer = ({
  userUploadId,
}: DetailTypePlayableMediaContainerProps) => {
  const { language } = useLanguage();

  const {
    transcript,
    hasFullResponse,
    loading: transcriptLoading,
  } = useTranscript(userUploadId, language);

  const [pollingInterval, setPollingInterval] = useState(0);

  const { data: userUpload, isLoading } = useUserUploadQuery(
    { id: userUploadId! },
    {
      pollingInterval: pollingInterval,
    },
  );

  const { mux_playback_id: playbackId, should_expect_transcript: shouldExpectTranscript } =
    userUpload || { mux_playback_id: null, should_expect_transcript: null };

  useEffect(() => {
    setPollingInterval(!playbackId ? POLL_INTERVAL_MS : 0);
  }, [playbackId, setPollingInterval]);

  const duration = userUpload?.duration ?? 0;
  if (userUpload?.duration == null) {
    console.warn('userUpload is missing duration');
  }

  usePollTranscript({
    transcriptId: userUploadId,
    run: !!playbackId && shouldExpectTranscript && !hasFullResponse,
  });

  const [ref, { height }] = useDimensions();
  const [playerControlsRef, { height: playerControlsHeight }] = useDimensions();
  const [videoPlayerRef, { height: videoPlayerHeight, width: videoPlayerWidth }] =
    useDimensions();
  const [toolbarRef, { height: toolbarHeight }] = useDimensions();
  const [playerFooterRef, { width: playerFooterWidth, height: playerFooterHeight }] =
    useDimensions();

  // TODO (lenny): Figure out why this is needed
  const hackToCorrectFooter = playerFooterHeight < 100 ? 100 : playerFooterHeight;

  const contentContainerHeight = height - playerControlsHeight - hackToCorrectFooter;
  const textContainerHeight = contentContainerHeight - toolbarHeight - videoPlayerHeight;

  let notification: TranscriptNotification | null = null;

  if (!playbackId) {
    notification = {
      title: 'Uploaded processing',
      subtitle: 'Please wait until your file is ready.',
    };
  }

  if (shouldExpectTranscript && !hasFullResponse && !transcriptLoading) {
    notification = {
      title: 'Please wait until your transcript is ready',
      subtitle:
        "On average, turnaround time is roughly 15-30% of the audio file's duration, with a minimum processing time of around 20-30 seconds",
      extraNote:
        'You will receive an email notification with a link when the transcript is complete.',
    };
  }

  const [selectedClipId, setSelectedClipId] = useState<string | null>(null);

  useEffect(() => {
    window.analytics.track('Opened a video and checking for transcript', {
      user_upload_id: userUploadId,
      duration,
    });
  }, [userUploadId, duration]);

  useEffect(() => {
    if (transcript && hasFullResponse) {
      window.analytics.track('Opened a video with transcript', {
        user_upload_id: userUploadId,
        duration,
      });
    }
  }, [transcript, hasFullResponse, userUploadId, duration]);

  const prefetch = recentUsageAPI.usePrefetch('fetchRecentUsage');
  useEffect(() => prefetch(), [prefetch]);

  const templatesEnabled = useFeatureFlag('templates');

  const durationMs = userUpload?.duration! * 1000;
  const fileType = userUpload ? userUpload.file_type : 'unknown';
  const [hideVideoPlayer, setHideVideoPlayer] = useState(isAudioMedia(fileType));

  const isAudio = isAudioMedia(fileType);

  const { transcriptChaptersActive } = useUploadTranscript();

  useEffect(() => {
    if (isAudio) {
      setHideVideoPlayer(true);
    }
  }, [isAudio]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <SelectionProvider>
      <VisibleScrollProvider>
        <TimeProvider durationMs={durationMs}>
          <SlateEventsProvider>
            <HotkeyProvider>
              <div ref={ref} className="min-h-screen w-full bg-gray-100">
                <NewProjectWizard
                  source={
                    templatesEnabled && selectedClipId
                      ? { type: ItemLayerSources.Clips, id: selectedClipId }
                      : undefined
                  }
                  onClose={() => setSelectedClipId(null)}
                />
                {selectedClipId && !templatesEnabled && (
                  <TemplateSelectionPopup
                    userUploadId={userUploadId}
                    previewTypeId={selectedClipId}
                    handleClose={() => setSelectedClipId(null)}
                  />
                )}
                {userUpload && <SrtDownloadModal uploadFile={userUpload} />}
                {userUpload && <ShareTranscriptModal uploadFile={userUpload} />}

                <main className="flex flex-1 flex-col">
                  {userUpload && (
                    <DetailTypeWorkspaceNav
                      playerControlsRef={playerControlsRef}
                      uploadFile={userUpload}
                      transcript={transcript}
                    />
                  )}
                  <div
                    className="overflow-hidden"
                    style={{ height: `${contentContainerHeight}px` }}
                  >
                    <div className="mx-auto grid grid-flow-col-dense grid-cols-3">
                      <section
                        className="md:col-span-1 md:col-start-1"
                        style={{ height: `${contentContainerHeight}px` }}
                      >
                        <div
                          style={{ height: `${contentContainerHeight}px` }}
                          className="relative overflow-y-auto px-6 py-2"
                        >
                          <DetailUploadsClips
                            handleStartTemplate={id => {
                              if (templatesEnabled) {
                                window.analytics.track(
                                  'Pg-Transcript -- Elm-UsrClpCd -- Evt-Clk -- "Create video"',
                                );
                              }

                              setSelectedClipId(id);
                            }}
                            userUploadId={userUploadId}
                          />
                        </div>
                      </section>
                      <div
                        className="col-span-2 col-start-2 space-y-6 pr-4"
                        style={{ height: `${height - playerControlsHeight}px` }}
                      >
                        <section
                          className="rounded-t-xl bg-gray-200 shadow "
                          aria-labelledby="applicant-information-title"
                        >
                          <div
                            ref={videoPlayerRef}
                            className={cn({
                              'h-0 overflow-hidden': hideVideoPlayer,
                              'flow-root h-40': !hideVideoPlayer,
                            })}
                            aria-label="Video player container"
                          >
                            {playbackId && (
                              <ControlledVideo
                                playbackId={playbackId}
                                durationMs={durationMs}
                                timingRole="leader"
                              />
                            )}
                          </div>
                          <div className="relative bg-white">
                            {userUpload && (
                              <TranscriptEditorToolbar
                                uploadFile={userUpload}
                                toolbarRef={toolbarRef}
                              />
                            )}
                            <div className="flex min-w-full">
                              <div className="relative flex-1 border-t border-gray-200">
                                <VisibleTimeText />
                                {notification && <InlineNotification {...notification} />}
                                <ScrollContainer height={textContainerHeight}>
                                  {!transcript && (
                                    <div className="flex h-full flex-col items-start justify-center text-gray-200">
                                      <LoadingText lineCount={20} />
                                    </div>
                                  )}
                                  <div
                                    style={{ maxWidth: '680px' }}
                                    className={cn('mx-auto flex flex-1 text-lg', {
                                      hidden: !transcript,
                                    })}
                                  >
                                    {transcript && (
                                      <TranscriptEditorWrap
                                        userUploadId={userUploadId}
                                        language={language}
                                      />
                                    )}
                                  </div>
                                  {transcript && !hasFullResponse && (
                                    <div className="-mx-4 -mt-4 flex h-full items-start justify-center text-gray-200">
                                      <LoadingText lineCount={8} />
                                    </div>
                                  )}
                                  <EditModeToolbar />
                                </ScrollContainer>
                              </div>
                              {transcriptChaptersActive && (
                                <TranscriptsSummaryPanel
                                  transcriptLength={transcript.length}
                                  hasFullResponse={hasFullResponse}
                                  userUploadId={userUploadId}
                                  textContainerHeight={textContainerHeight}
                                />
                              )}
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                  <div ref={playerFooterRef} className="relative bg-gray-200">
                    {userUpload && playbackId && (
                      <DetailVideoPlayerThumbnails
                        uploadFile={userUpload}
                        width={playerFooterWidth}
                        playbackId={playbackId}
                      />
                    )}
                  </div>
                </main>
              </div>
            </HotkeyProvider>
          </SlateEventsProvider>
        </TimeProvider>
      </VisibleScrollProvider>
    </SelectionProvider>
  );
};

const POLL_INTERVAL_MS = isEnvProd ? 2000 : 20000;
