import {useCallback, useEffect, useRef, useState} from 'react';

import {ErrorBoundary} from '@sentry/react';
import ReactPlayer from 'vendor/react-player-milk';
import {default as ReactPlayerRef} from 'vendor/react-player-milk/types/lib';
import clamp from 'lodash.clamp';
import classNames from 'classnames';
import {usePlayback} from './usePlayback';
import {useTimeEffect} from './useTimeEffect';
import {useTimeSelector} from './useTimeSelector';

type PlayRange = {startMs: number; endMs: number | 'end'};

type ControlledVideoProps = {
  playbackId: string;
  durationMs: number;
  /** The time range of the video to play. */
  playRange?: PlayRange;
  onReady?: () => void;
  isMuted?: boolean;
  /** How many ms into the global time the video should play. */
  timeOffset?: number;
  timingRole?: 'leader' | 'follower';
};

const defaultPlayRange: PlayRange = {startMs: 0, endMs: 'end'};

export const ControlledVideo = ({
  playbackId,
  durationMs,
  playRange = defaultPlayRange,
  onReady,
  isMuted,
  timeOffset = 0,
  timingRole = 'leader',
}: ControlledVideoProps) => {
  const player = useRef<ReactPlayerRef>(null);
  const [loading, setLoading] = useState(true);

  const {isPlaying, syncDependencyTime, isScrubbing} = usePlayback();

  let playDuration = durationMs;
  if (playRange.endMs !== 'end') {
    playDuration = playRange.endMs - playRange.startMs;
  }
  playDuration = Math.floor(playDuration);

  const isInBounds = useTimeSelector(
    ({currentTime}) => {
      const flooredTime = Math.round(currentTime - timeOffset);
      return flooredTime >= 0 && flooredTime < playDuration;
    },
    [playDuration, timeOffset],
  );

  const active = !isScrubbing && isPlaying && isInBounds;

  const getFlooredTime = useCallback(
    (timeMs: number) => {
      const flooredTime = Math.round(timeMs - timeOffset);
      return clamp(flooredTime, 0, playDuration);
    },
    [timeOffset, playDuration],
  );

  const seekTo = useCallback(
    (timeMs: number) => {
      if (loading) return;

      const flooredTime = getFlooredTime(timeMs);
      const timeS = (flooredTime + playRange.startMs) / 1000;
      player.current?.seekTo(timeS, 'seconds');
    },
    [getFlooredTime, playRange.startMs, loading],
  );

  useEffect(() => {
    seekTo(0);
  }, [seekTo]);

  useTimeEffect(
    ({currentTime, type}) => {
      if (type === 'scrub') {
        seekTo(currentTime);
      }

      if (timingRole === 'follower' && type === 'sync') {
        seekTo(currentTime);
      }
    },
    [seekTo, timingRole],
    {throttleFPS: 15},
  );

  useTimeEffect(
    ({currentTime, type}) => {
      if (type === 'seek' || type === 'state') {
        seekTo(currentTime);
      }
    },
    [seekTo],
  );

  return (
    <div className="h-full w-full">
      {(loading || !playbackId) && (
        <div className="flex h-full w-full items-center justify-center">
          <div className="h-full w-1/2 animate-pulse bg-gray-300" />
        </div>
      )}
      <div
        className={classNames('w-full', 'h-full', {hidden: loading})}
        data-testid="detail-video-player-container"
      >
        <ErrorBoundary>
          <ReactPlayer
            playing={active}
            progressInterval={1000 / 60}
            url={`https://stream.mux.com/${playbackId}.m3u8`}
            ref={player as any}
            className="select-none"
            width="100%"
            height="100%"
            onReady={() => {
              setLoading(false);
              onReady?.();
            }}
            onProgress={({playedSeconds}: {playedSeconds: number}) => {
              if (timingRole !== 'leader') return;
              if (!active) return;

              const playedMs = playedSeconds * 1000;
              const timeMs = playedMs - playRange.startMs + timeOffset;

              const flooredTimeMs = timeMs - timeOffset;
              if (flooredTimeMs < 0 || flooredTimeMs > playDuration) return;

              syncDependencyTime({timeMs});
            }}
            muted={isMuted}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};
