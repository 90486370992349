import {ChevronsLeft, ChevronsRight, Pause, Play} from 'react-feather';
import {Fragment, MouseEvent, useRef, useState} from 'react';
import {Menu, Transition} from '@headlessui/react';

import {BaseTranscriptChapter} from 'features/types/userLibrarySlice';
import {ChevronDownIcon} from '@heroicons/react/solid';
import classNames from 'classnames';
import {formatTime} from '../../../../EditorCanvas/components/CanvasTime/utils';
import {useCreateClipFromTranscriptChapter} from 'features/TranscriptEditor/transcriptUtils';
import {usePlayRange} from 'features/EditorCanvas/components/CanvasTime/utils';
import {usePlayback} from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import {useSelection} from 'features/TranscriptEditor/selection';

const TranscriptChapterTimeText = ({
  transcriptSummaryChapter,
}: {
  transcriptSummaryChapter: BaseTranscriptChapter;
}) => {
  return (
    <div className="absolute top-0 bottom-0 right-0 font-mono text-xs font-medium text-gray-500">
      <div className="absolute top-0 right-0">
        {formatTime(Math.max(transcriptSummaryChapter.start, 0))}
      </div>
      <div className="absolute bottom-0 right-0">
        {formatTime(transcriptSummaryChapter.end)}
      </div>
    </div>
  );
};

export const TranscriptsSummaryChapters = function ({
  summaryChapters,
  userUploadId,
}: {
  userUploadId: string;
  summaryChapters: BaseTranscriptChapter[];
}) {
  return (
    <>
      <ul className="select-none space-y-2">
        {summaryChapters?.map((transcriptSummaryChapter, index) => {
          return (
            <li key={Math.random()}>
              <SummaryChapterSection
                transcriptSummaryChapter={transcriptSummaryChapter}
                userUploadId={userUploadId}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
};

const SummaryChapterSection = function ({
  transcriptSummaryChapter,
  userUploadId,
}: {
  transcriptSummaryChapter: BaseTranscriptChapter;
  userUploadId: string;
}) {
  const {start, end} = transcriptSummaryChapter;
  const createClip = useCreateClipFromTranscriptChapter({
    userUploadId,
    start,
    end,
  });
  const [fullTranscript, setFullTranscript] = useState(false);
  const {setSelectedChapter} = useSelection();
  const {pause, play, seekTo} = usePlayback();
  const {isPlaying, toggle} = usePlayRange({
    fromMs: transcriptSummaryChapter.start,
    toMs: transcriptSummaryChapter.end,
  });

  const handlePlay = () => {
    setSelectedChapter(transcriptSummaryChapter);
    toggle();
  };

  const handleSelection = () => {
    pause();
    seekTo({timeMs: transcriptSummaryChapter.start});
    setSelectedChapter(transcriptSummaryChapter);
  };

  return (
    <div className="relative cursor-pointer rounded-lg bg-white py-2 px-2 transition-colors">
      <div
        className={classNames('relative py-4 text-xs text-gray-500')}
        onClick={() => setFullTranscript(!fullTranscript)}
      >
        <TranscriptChapterTimeText transcriptSummaryChapter={transcriptSummaryChapter} />
        <div className="space-y-2">
          <h3 className="text-gray-900">{transcriptSummaryChapter?.gist}</h3>
          <div>
            <p className="inline">
              {!fullTranscript
                ? transcriptSummaryChapter?.headline + '...'
                : transcriptSummaryChapter.summary}
            </p>
            <button
              type="button"
              className={classNames(
                'inline-flex rounded border border-transparent p-1 text-gray-500 transition focus:outline-none',
              )}
              onClick={() => setFullTranscript(!fullTranscript)}
            >
              {fullTranscript ? <ChevronsLeft size={10} /> : <ChevronsRight size={10} />}
            </button>
          </div>
          <div className="flex flex-row-reverse">
            <span
              onClick={e => e.stopPropagation()}
              className="shadow-xs relative z-50 clear-both my-1 inline-flex rounded-md text-right"
            >
              <button
                type="button"
                onClick={handlePlay}
                className="relative inline-flex h-6 items-center rounded-l-md border-l border-t border-b border-gray-300 bg-white px-2 py-1 text-xs text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              >
                {isPlaying ? (
                  <Pause width={10} height={10} />
                ) : (
                  <Play width={10} height={10} />
                )}
              </button>
              <button
                type="button"
                onClick={handleSelection}
                className="relative inline-flex h-6 items-center border border-gray-300 bg-white px-2 py-1 text-xs text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              >
                Select
              </button>
              <Menu>
                <Menu.Button className="relative -ml-1 inline-flex h-6 items-center rounded-r-md border border-gray-300 bg-white px-1 py-1 text-left text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                  <span className="sr-only">{'>'}</span>
                  <ChevronDownIcon className="h-3 w-4" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-7 origin-bottom-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({active}) => (
                        <div
                          onClick={createClip}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            ' cursor-pointer flex-row items-center justify-start space-x-2 bg-white px-2 py-0.5 text-xs',
                          )}
                        >
                          <span>Create clip</span>
                        </div>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
