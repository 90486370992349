import DetailTypeHeader from './DetailTypeHeader';
import DetailTypeSidebar from './DetailTypeSidebar';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import {UserUpload} from 'services/userUploadAPI';
import cn from 'classnames';
import {useEffect} from 'react';

export default function ImageDetail({userUpload}: {userUpload: UserUpload}) {
  useEffect(() => {
    window.analytics.track('Loading dashboard detail page for image');
  }, []);
  return (
    <>
      <DetailTypeHeader uploadFile={userUpload} />
      <div className="mx-auto flex h-full w-full flex-col overflow-y-auto">
        <div className="flex h-full flex-col">
          <main
            className={cn(
              'relative z-0 flex flex-1 items-start justify-start overflow-y-auto border-gray-200',
            )}
          >
            <div className="flex h-full w-full flex-grow">
              <div className="flex flex-grow flex-col">
                <div className="mx-auto flex w-full flex-grow flex-col">
                  <div className="flex grid flex-grow grid-cols-3 flex-col gap-6">
                    <DetailTypeSidebar uploadFile={userUpload} />
                    <div className="col-span-2 flex h-full border-l border-gray-200 bg-gray-50">
                      <>
                        <main
                          className={cn(
                            'align-center relative z-0 flex flex-1 items-center justify-center overflow-y-auto border-gray-200 px-4',
                          )}
                        >
                          <div className="w-full">
                            <div className="flex flex-col">
                              <div className="overflow-x-auto">
                                <div className="inline-block min-w-full pb-2 align-middle">
                                  {!userUpload ? (
                                    <div className="flex items-center justify-center text-gray-200">
                                      <LoadingSpinner />
                                    </div>
                                  ) : (
                                    userUpload && (
                                      <>
                                        <div className="overflow-hidden">
                                          <img
                                            alt={userUpload.upload_url}
                                            src={userUpload.upload_url}
                                            className="w-full"
                                          />
                                        </div>
                                      </>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </main>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
