import {
  FileNav,
  PositionDirection,
} from 'features/Dashboard/DashboardPage/ContentUploads/FileNav';
import {Fragment, useCallback, useMemo} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {languages, useLanguage} from './LanguageState';

import {ChevronDownIcon} from '@heroicons/react/solid';
import DetailVideoPlayerControls from './DetailVideoPlayerControls';
import FileNameTextField from './FileNameTextField';
import {TranscriptParagraph} from 'features/Captions/Transcripts';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {Youtube} from 'react-feather';
import classNames from 'classnames';
import {routeHome} from 'routes/routesHelper';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useNavigate} from 'react-router-dom';
import {useUploadTranscript} from './UploadTranscriptContext';

export default function DetailTypeWorkspaceNav({
  playerControlsRef,
  uploadFile,
  transcript,
}: {
  playerControlsRef: any;
  uploadFile: UserUploadsType;
  transcript: TranscriptParagraph[] | undefined;
}) {
  const {setIsShareModalOpen, setIsDownloadModalOpen} = useUploadTranscript();
  const navigate = useNavigate();

  const {language, setLanguage} = useLanguage();
  const transcriptTranslation = useFeatureFlag('transcript-translation');

  const handleShare = () => {
    setIsShareModalOpen(true);
  };

  const handleDownloadSrt = useCallback(() => {
    setIsDownloadModalOpen(true);
  }, [setIsDownloadModalOpen]);

  const items = useMemo(() => {
    return [{name: 'Download SRT', onClick: handleDownloadSrt}];
  }, [handleDownloadSrt]);

  return (
    <div
      ref={playerControlsRef}
      className="z-50 grid grid-cols-5 space-x-2 md:grid-cols-7"
    >
      <div className="col-span-1 flex items-center justify-start px-2 pl-2 md:col-span-2 md:pl-4">
        <span className="inline-flex rounded-md shadow-sm">
          <button
            onClick={() => {
              navigate(routeHome);
            }}
            className="focus:ring-blue mr-2 inline-flex items-center rounded border border-gray-300 bg-white px-1.5 py-1 text-xs font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 7.33335H5.21996L8.94663 3.60669L7.99996 2.66669L2.66663 8.00002L7.99996 13.3334L8.93996 12.3934L5.21996 8.66669H13.3333V7.33335Z"
                fill="black"
              />
            </svg>
          </button>
        </span>
        <div className="flex w-full truncate">
          <div className="z-60 relative">
            {uploadFile && (
              <FileNameTextField uploadFile={uploadFile}>
                <FileNav
                  positionDirection={PositionDirection.DOWN_RIGHT}
                  userUploadId={uploadFile.id}
                />
              </FileNameTextField>
            )}
          </div>
        </div>
      </div>
      <div className="col-span-3 flex flex-grow">
        <DetailVideoPlayerControls />
      </div>
      <div className="z-50 col-span-1 flex items-center justify-end px-6 md:col-span-2">
        {transcriptTranslation && (
          <select
            disabled={!transcript}
            value={language}
            onChange={event => setLanguage(event.currentTarget.value as any)}
            className="block rounded-md border-gray-300 py-1 pl-3 pr-10 text-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-xs"
          >
            {languages.map(language => (
              <option value={language.id} key={language.id}>
                {language.label}
              </option>
            ))}
          </select>
        )}
        <span className="shadow-xs relative z-50 inline-flex rounded-md">
          <button
            onClick={handleShare}
            type="button"
            className="relative inline-flex h-7 items-center rounded-l-md border border-gray-300 bg-white px-3 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          >
            Share
          </button>
          <Menu as="span" className="relative -ml-px block">
            <Menu.Button className="relative inline-flex h-7 items-center rounded-r-md border border-gray-300 bg-white px-1 py-1 text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
              <span className="sr-only">Open options</span>
              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {items.map(item => (
                    <Menu.Item key={item.name}>
                      {({active}) => (
                        <div
                          onClick={item.onClick}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'flex w-full cursor-pointer flex-row items-center justify-start space-x-2 px-3 py-1 text-xs',
                          )}
                        >
                          <Youtube className="w-5" />
                          <span>{item.name}</span>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </span>
      </div>
    </div>
  );
}
