import {DimensionType, PositionType} from 'features/types/canvasItemsSlice';
import {
  SAMPLE_CAPTION,
  SAMPLE_VIDEO,
} from 'features/EditorCanvas/constants/ItemConstants';

import {DEFAULT_DIMENSION} from 'features/sceneFrameSlice';
import {TemplateExportOptionsType} from './TemplateSelectionPopup';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import classNames from 'classnames';
import {getVideoDimensions} from 'features/EditorCanvas/utils';
import {layoutAndReturnItems} from 'features/EditorCanvas/Layout/utils';
import {muxThumbnail} from '../../utils';
import {useRecentUsage} from 'services/recentUsageAPI';
import {useWatchElementSize} from 'features/Common/useElementSize';

type TemplateExportPreviewProps = {
  exportOptions: TemplateExportOptionsType;
  userUpload: UserUploadsType;
  clip: UploadMediaClipType;
};

const toPercentage = (abs: number, total: number) => {
  return `${(abs / total) * 100}%`;
};

const getItemStyle = (
  {dimension, position}: {dimension: DimensionType; position: PositionType},
  canvasDimensions: DimensionType,
) => {
  return {
    top: toPercentage(position.top, canvasDimensions.height),
    left: toPercentage(position.left, canvasDimensions.width),
    width: toPercentage(dimension.width, canvasDimensions.width),
    height: toPercentage(dimension.height, canvasDimensions.height),
  };
};

const TemplateExportPreview = ({
  exportOptions,
  clip,
  userUpload,
}: TemplateExportPreviewProps) => {
  const {recentUsage} = useRecentUsage();

  const canvasDimensions = DEFAULT_DIMENSION[exportOptions.orientation];
  const aspectRatio = canvasDimensions.height / canvasDimensions.width;

  const items = {
    video: {...SAMPLE_VIDEO},
    caption: {...SAMPLE_CAPTION},
  };

  items.video.dimension = getVideoDimensions({
    userUpload,
    currentDimensions: items.video.dimension,
  });

  const layedOutItems = layoutAndReturnItems({
    id: exportOptions.layout,
    items,
    withinContainer: {dimension: canvasDimensions},
  });

  const captionText = clip.text_snippet;
  const previewUrl = muxThumbnail(
    userUpload.mux_playback_id,
    Number(clip.start_time),
    items.video.dimension.width,
    items.video.dimension.height,
  );

  const [lineRef, lineSize] = useWatchElementSize<HTMLParagraphElement>();
  const [captionRef, captionSize] = useWatchElementSize<HTMLDivElement>();

  let captionMaxHeight: number | undefined;
  if (lineSize?.height != null && captionSize?.height != null) {
    captionMaxHeight = Math.floor(captionSize.height / lineSize.height) * lineSize.height;
  }

  return (
    <div className="w-full">
      <div className="relative w-full" style={{paddingTop: `${aspectRatio * 100}%`}}>
        <div
          className="absolute inset-0"
          style={{background: recentUsage.canvas_background}}
        >
          <div
            className="absolute"
            style={{
              ...getItemStyle(layedOutItems.video, canvasDimensions),
              backgroundImage: `url('${previewUrl}')`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div
            className={classNames(
              'absolute flex flex-col justify-center',
              captionMaxHeight == null && 'opacity-0',
            )}
            style={{
              ...getItemStyle(layedOutItems.caption, canvasDimensions),
              color: recentUsage.captions_inactive_color,
              fontFamily: recentUsage.captions_font,
            }}
            ref={captionRef}
          >
            <p
              className="max-h-full overflow-hidden text-sm font-semibold"
              style={{maxHeight: captionMaxHeight}}
            >
              {captionText}
            </p>
          </div>
          <p
            ref={lineRef}
            className="pointer-events-none absolute top-0 left-0 text-sm font-semibold opacity-0"
          >
            M
          </p>
        </div>
      </div>
    </div>
  );
};

export default TemplateExportPreview;
