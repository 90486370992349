import { RECORDING_STATE, useRecording } from '../contexts/RecordingProvider';
import { ReactElement, useEffect, useMemo, useState } from 'react';

import { ApiRecordingSession } from '../contexts/ApiRecordingSessionProvider';
import { BASE_URL } from 'constants/environment';
import { Button } from 'ui/Button';
import { PauseIcon } from '@heroicons/react/solid';
import { ahoy } from 'features/providers/TrackingVisitProvider';
import axios from 'axios';
import classNames from 'classnames';
import { useCallConfig } from 'vendor/dailyco/shared/hooks/useCallConfig';
import { useCallState } from 'vendor/dailyco/shared/contexts/CallProvider';
import { useDaily } from '@daily-co/daily-react-hooks';
import { useNavigate } from 'react-router-dom';
import { useRecordingFormQuery } from 'services/recordingFormsAPI';
import { useUpdateRecordingInteractionResponseStateMutation } from 'services/recordingInteractionsAPI';

export enum MILK_API_RECORDING_STATE {
  ROOM_CREATE = 'create-dailyco-room',
  ROOM_JOIN = 'join-dailyco-room',
  RECORDING_PAGE_OPEN = 'open-recording-answer-page',
  RECORDING_START = 'start-recording-on-answer-page',
  RECORDING_END = 'end-recording',
  SUBMIT_RECORDING = 'submit-recording',
  SUBMIT_FORM = 'submit-form',
}

export const RECORDING_MODAL = 'recording';

export const RecordingSessionWrap = ({ children }: { children: ReactElement }) => {
  const [hasRecorded, setHasRecorded] = useState(false);
  const { enableRecording } = useCallConfig();
  const { recordingState, startRecordingWithCountdown, stopRecording } = useRecording();
  const daily = useDaily();
  const [updateRecordingCollectionState] =
    useUpdateRecordingInteractionResponseStateMutation();

  const { recordingRoomId, recordingInteractionFormId } =
    ApiRecordingSession.useContainer();

  const { callObject } = useCallState();

  // console.log('callObject', callObject);

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('unload', handleEndConcert);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', handleEndConcert);
      handleEndConcert();
    };
  }, []);

  const alertUser = e => {
    e.preventDefault();
    e.returnValue = '';
  };

  const handleEndConcert = async () => {
    if (recordingActive) {
      handleStopRecording();
    }
  };

  useEffect(() => {
    if (recordingInteractionFormId) {
      if (recordingState === RECORDING_STATE.RECORDING) {
        window.analytics.track('RecordingInteractionForm RecordingSessionWrap recording started', {
          recording_interaction_form_id: recordingInteractionFormId,
        });
        ahoy.track('RecordingInteractionForm RecordingSessionWrap recording started', {
          recording_interaction_form_id: recordingInteractionFormId,
        });
      }
      if (recordingState === RECORDING_STATE.SAVED) {
        window.analytics.track('RecordingInteractionForm RecordingSessionWrap saved', {
          recording_interaction_form_id: recordingInteractionFormId,
        });
        ahoy.track('RecordingInteractionForm RecordingSessionWrap saved', {
          recording_interaction_form_id: recordingInteractionFormId,
        });
      }
      if (recordingState === RECORDING_STATE.IDLE) {
        window.analytics.track('RecordingInteractionForm RecordingSessionWrap idle', {
          recording_interaction_form_id: recordingInteractionFormId,
        });
        ahoy.track('RecordingInteractionForm RecordingSessionWrap idle', {
          recording_interaction_form_id: recordingInteractionFormId,
        });
      }
    }
  }, [recordingInteractionFormId, recordingState])

  const {
    data: recordingInteractionForm,
    isLoading: isLoadingForm,
    isFetching: isFetchingForm,
    isError,
    isSuccess,
    error,
  } = useRecordingFormQuery({ id: recordingInteractionFormId! });

  const formQuestion = useMemo(() => {
    if (
      !recordingInteractionForm?.recording_form_prompts ||
      !recordingInteractionForm?.recording_form_prompts[0] ||
      !recordingInteractionForm?.recording_form_prompts[0].description
    ) {
      return '';
    }
    return recordingInteractionForm?.recording_form_prompts[0].description;
  }, [recordingInteractionForm]);

  const buttonReady = [
    RECORDING_STATE.COUNTDOWN_1,
    RECORDING_STATE.RECORDING,
    RECORDING_STATE.UPLOADING,
    RECORDING_STATE.SAVED,
  ].includes(recordingState);

  const recordingActive = [RECORDING_STATE.RECORDING].includes(recordingState);

  const disabled =
    enableRecording &&
    [RECORDING_STATE.IDLE, RECORDING_STATE.RECORDING].includes(recordingState);

  function renderButtonLabel() {
    if (!enableRecording) {
      return 'Recording disabled';
    }

    switch (recordingState) {
      case RECORDING_STATE.COUNTDOWN_1:
        return 'Recording starting...';
      case RECORDING_STATE.RECORDING:
        return 'Click to stop recording';
      case RECORDING_STATE.UPLOADING:
      case RECORDING_STATE.SAVED:
        return 'Recording stopped. Processing...';
      default:
        return 'Start recording';
    }
  }

  const handleStopRecording = () => {
    setHasRecorded(true);
    if (recordingRoomId) {
      updateRecordingCollectionState({
        userRecordingSubmissionRoomName: recordingRoomId,
        step: {
          type: MILK_API_RECORDING_STATE.RECORDING_END,
        },
      });
    }
    stopRecording();
  };

  const handleStartRecording = () => {
    if (recordingState === RECORDING_STATE.IDLE) {
      if (recordingRoomId) {
        updateRecordingCollectionState({
          userRecordingSubmissionRoomName: recordingRoomId,
          step: {
            type: MILK_API_RECORDING_STATE.RECORDING_START,
          },
        });
      }
      startRecordingWithCountdown();
    }
  };

  const handleRestartRecording = () => {
    setHasRecorded(false);
    startRecordingWithCountdown();
  };

  const handleReviewAndSubmit = async () => {
    if (recordingRoomId) {
      updateRecordingCollectionState({
        userRecordingSubmissionRoomName: recordingRoomId,
        step: {
          type: MILK_API_RECORDING_STATE.SUBMIT_RECORDING,
        },
      });
    }
    navigate(`../review`);
    daily?.leave();
  };

  if (hasRecorded) {
    return (
      <>
        <div
          className={classNames(
            'absolute top-0 z-10 flex h-full w-full flex-col items-center justify-center',
            {
              'bg-gray-800 bg-opacity-75':
                !buttonReady || (buttonReady && !recordingActive),
            },
          )}
        >
          <div className="items-center justify-center">
            {!buttonReady && (
              <div className="space-y-2">
                <Button
                  fullWidth
                  size="large"
                  _className="!text-2xl"
                  disabled={!disabled}
                  onClick={() => handleReviewAndSubmit()}
                >
                  Review and submit
                </Button>
                <Button
                  variant="inline"
                  fullWidth
                  _className="!text-lg !text-gray-300 hover:!text-gray-100 hover:!bg-gray-800 hover:!bg-opacity-50"
                  disabled={!disabled}
                  onClick={() => handleRestartRecording()}
                >
                  Re-record
                </Button>
              </div>
            )}
            {buttonReady && !recordingActive && (
              <h1 className="text-2xl text-white">{renderButtonLabel()}</h1>
            )}
          </div>
        </div>
        {children}
      </>
    );
  }

  return (
    <>
      <div className="relative">
        <div
          className={classNames(
            'absolute top-0 z-10 flex h-full w-full flex-col items-center justify-center',
            {
              'bg-gray-800 bg-opacity-75':
                !buttonReady || (buttonReady && !recordingActive),
            },
          )}
        >
          <div className="items-center justify-center">
            {!buttonReady && (
              <Button
                fullWidth
                size="large"
                _className="!text-2xl"
                disabled={!disabled}
                key="record"
                onClick={() => handleStartRecording()}
              >
                {renderButtonLabel()}
              </Button>
            )}
            {buttonReady && !recordingActive && (
              <h1 className="text-2xl text-white">{renderButtonLabel()}</h1>
            )}
          </div>
        </div>
        {children}
      </div>
      {recordingActive && (
        <div className="absolute top-0 z-10 mt-4 flex w-full flex-col items-center justify-end">
          <div className="items-center justify-center">
            <Button
              fullWidth
              size="large"
              _className="!text-2xl !bg-red-500 text-white hover:!bg-red-400"
              disabled={!disabled}
              key="record"
              leftIcon={PauseIcon}
              onClick={() => handleStopRecording()}
            >
              {renderButtonLabel()}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default RecordingSessionWrap;
