import {Menu, Transition} from '@headlessui/react';
import classNames from 'classnames';
import {Fragment} from 'react';
import {Icon} from 'react-feather';
import {Falsey} from 'utility-types';

type Item = {
  label: string;
  icon?: Icon;
  onClick: () => void;
  disabled?: boolean;
  destructive?: boolean;
};

export const DropdownMenu = ({
  button,
  items,
  anchor = 'right',
  fullWidth = false,
}: {
  button: JSX.Element;
  items: (Item | Falsey)[];
  anchor?: 'left' | 'right';
  fullWidth?: boolean;
}) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className={classNames(fullWidth && 'w-full')}>{button}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            'focus:outline-none absolute z-[9999] mt-2 w-60 rounded-md border border-gray-200 bg-white p-1 shadow-lg',
            anchor === 'left' && 'left-0 origin-top-left',
            anchor === 'right' && 'right-0 origin-top-right',
          )}
        >
          {items.map(item => {
            if (!item) return null;

            const {label, icon: Icon, destructive, ...rest} = item;

            return (
              <Menu.Item key={label} {...rest}>
                {({active, disabled}) => (
                  <div
                    className={classNames(
                      'flex h-9 cursor-pointer items-center space-x-2 rounded-md px-2.5 text-sm text-gray-800 transition hover:bg-gray-50',
                      active && 'bg-gray-100',
                      disabled && 'pointer-events-none opacity-50',
                      destructive && 'text-red-500',
                    )}
                  >
                    {Icon && (
                      <Icon style={{width: 16, height: 16, color: 'currentcolor'}} />
                    )}
                    <div>{label}</div>
                  </div>
                )}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
