import {TranscriptNotification} from './DetailTypePlayableMediaContainer';

export default function InlineNotification({
  title,
  subtitle,
  extraNote,
}: TranscriptNotification) {
  return (
    <div
      style={{zIndex: 1}}
      className="pointer-events-none absolute top-0 left-0 right-0 flex w-full items-end justify-center px-4 py-6 sm:items-end sm:justify-center sm:p-6"
    >
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-yellow-300 bg-opacity-80 shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-700">{subtitle}</p>
              {extraNote && <p className="mt-1 text-xs text-gray-700">{extraNote}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
