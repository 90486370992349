import {
  UploadFolderContentType,
  UserUploadFolderItem,
} from 'features/types/userLibrarySlice';
import {useEffect, useRef, useState} from 'react';

import {UserUpload} from './userUploadAPI';
import {baseApi} from './baseAPI';

export type UserUploadFolder = {
  parent_folder_id: string;
  name: string;
  id: string;
  tags: string;
  file_count: number;
  created_at: number;
  updated_at: number;
  content_type: UploadFolderContentType;
  items: UserUpload[];
};

export type UserUploadFolderInput = {
  parent_folder_id?: string;
  name?: string;
  id?: string;
  tags?: string;
  created_at?: number;
  updated_at?: number;
  content_type?: UploadFolderContentType;
};

type UserUploadFolderUpdateInput = UpdateId<UserUploadFolderInput> & {};

type UpdateId<T extends {}> = T & {id: string};
type UpsertId<T extends {}> = T & {id?: string};

export const userUploadFolderAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['UserUploadFolder', 'UserUpload']})
  .injectEndpoints({
    endpoints: builder => ({
      userUploadFolders: builder.query<
        {folders: UserUploadFolder[]; folder_items: UserUploadFolderItem[]},
        {userId: string}
      >({
        query: ({userId}) => ({
          url: `user_upload_folders`,
          v2: true,
          method: `POST`,
          errorTitle: 'Error fetching upload folders',
          data: {
            user_id: userId,
          },
        }),
        providesTags: ['UserUploadFolder'],
      }),
      moveUserUploadsFolder: builder.mutation<
        UserUpload,
        {userUploadId: string; userUploadFolderId: string}
      >({
        query: ({userUploadId, userUploadFolderId}) => ({
          url: `user_uploads/move`,
          v2: false,
          method: `POST`,
          errorTitle: 'Error moving user uploads folder',
          data: {
            user_upload: {
              bucket_key: userUploadId,
              folder_id: userUploadFolderId,
            },
          },
        }),
        invalidatesTags: ['UserUploadFolder'],
      }),
    }),
  });

export const {useUserUploadFoldersQuery, useMoveUserUploadsFolderMutation} =
  userUploadFolderAPI;
