import {useEffect, useRef} from 'react';

import {WaveformData} from 'waveform-data';
import {d3} from 'vendor/d3';
import {useWatchElementSize} from 'features/Common/useElementSize';

export function AudioWaveform({waveformData}: {waveformData: WaveformData}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [setContainerRef, {width: innerWidth, height: innerHeight}] =
    useWatchElementSize();

  useEffect(() => {
    if (!containerRef.current) return;

    const channel = waveformData.channel(0);
    const container = d3.select(containerRef.current);
    const x = d3.scaleLinear();
    const y = d3.scaleLinear();
    const offsetX = innerHeight / 2;

    const min = channel.min_array();
    const max = channel.max_array();
    const yMin = d3.min(min);
    const yMax = d3.max(max);

    if (!yMax || !yMin) return;

    x.domain([0, waveformData.length]).rangeRound([0, innerWidth]);
    y.domain([yMin, yMax]).rangeRound([offsetX, -offsetX]);

    const area = d3
      .area()
      .x((d: any, i: number) => x(i))
      .y0((d: any, i: number) => y(min[i]))
      .y1((d: any, i: number) => y(d));

    if (!area) {
      return;
    }

    const svg = container
      .append('svg')
      .style('width', `${innerWidth}px`)
      .style('height', `${innerHeight}px`)
      .style('fill', d3.color('steelblue') as any)
      .datum(max);

    svg
      .append('path')
      .attr('transform', () => `translate(0, ${offsetX})`)
      .attr('d', area as any)
      .attr('stroke', d3.color('steelblue') as any);

    return () => {
      svg.remove();
    };
  }, [containerRef, innerWidth, innerHeight, waveformData]);

  return (
    <div
      ref={ref => {
        containerRef.current = ref;
        setContainerRef(ref);
      }}
      className="w-full"
    />
  );
}
