import {AudioWaveform} from './AudioWaveform';
import ThumbnailTileElement from './ThumbnailTile';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import classNames from 'classnames';
import {distributedCopy} from './utils';
import {isVideoMedia} from '../../utils';
import {useAudioWaveformQuery} from 'services/userUploadAPI';
import {useDuration} from 'features/EditorCanvas/components/CanvasTime/useDuration';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useLoadStoryboardJson} from './hooks';
import {useWatchElementSize} from 'features/Common/useElementSize';

export function TimelineWaveform({
  playbackId,
  userUploadId,
  fileType,
  className,
}: {
  playbackId: string;
  fileType: string;
  userUploadId: string;
  className?: string;
}) {
  const isTimelineWaveformActive = true;
  const {data: waveformData} = useAudioWaveformQuery(
    {uploadId: userUploadId},
    {refetchOnFocus: false, skip: !isTimelineWaveformActive},
  );

  // console.log('waveformData', waveformData);
  // console.log('isTimelineWaveformActive', isTimelineWaveformActive);

  if (waveformData) {
    return (
      <div
        aria-label="timeline-frame-container"
        className={classNames(
          'h-ful absolute inset-0 flex w-full flex-row overflow-hidden rounded-md bg-white',
          className,
        )}
      >
        <AudioWaveform waveformData={waveformData} />
      </div>
    );
  }

  return (
    <TimelineTiles
      isVideo={isVideoMedia(fileType)}
      playbackId={playbackId}
      className={className}
    />
  );
}

const TimelineTiles = ({
  isVideo,
  playbackId,
  className,
}: {
  isVideo: boolean;
  playbackId: string;
  className?: string;
}) => {
  const duration = useDuration();
  const storyboardJson = useLoadStoryboardJson(playbackId, isVideo, duration);
  const [container, {width: innerWidth, height: innerHeight}] = useWatchElementSize();

  if (!storyboardJson || !storyboardJson.data) {
    return null;
  }

  const storyboardDimension = {
    width: storyboardJson.data.tile_width,
    height: storyboardJson.data.tile_height,
  };
  const actualTileWidth =
    storyboardDimension.width * (innerHeight / storyboardDimension.height);
  const countOfTiles = (innerWidth / actualTileWidth) * 2;
  const filteredTiles = distributedCopy(storyboardJson.data.tiles, countOfTiles);
  const storyboardImage = storyboardJson.data.url;

  return (
    <div
      aria-label="timeline-frame-container"
      className={classNames(
        'absolute inset-0 flex w-full flex-row overflow-hidden rounded-md bg-white',
        className,
      )}
      ref={container}
    >
      {filteredTiles.map((tile, index) => {
        return (
          <div key={index}>
            <ThumbnailTileElement
              tileIndex={index}
              storyboardDimension={storyboardDimension}
              storyboardImage={storyboardImage}
              tile={tile}
              tileWidth={actualTileWidth}
              tileHeight={innerHeight}
            />
          </div>
        );
      })}
    </div>
  );
};
