import {
  canvasItemsSelectedItemIdsSelector,
  canvasItemsSelectedItemSelector,
} from 'features/selectors/canvasStateSelectors';

import {CloseSidebar} from 'features/EditorCanvas/Sidebar/CloseSidebar';
import {CornerRadiusPicker} from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/CornerRadiusPicker';
import DimensionPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/DimensionPicker';
import PositionPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/PositionPicker';
import RotationPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/RotationPicker';
import {SceneTimingPicker} from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/SceneTimingPicker';
import ShapeColorPicker from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/ShapeColorPicker';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useSelector} from 'react-redux';

export const ShapeComponentSidebar = () => {
  const selectedItemIds = useSelector(canvasItemsSelectedItemIdsSelector);
  const projectId = useProjectId();

  const selectedItem = useSelector(canvasItemsSelectedItemSelector)(projectId);

  if (!selectedItem || selectedItemIds.length === 0) {
    return <CloseSidebar />;
  }

  return (
    <>
      <PositionPicker />
      <DimensionPicker />
      <RotationPicker />
      <CornerRadiusPicker />
      <ShapeColorPicker />
      <SceneTimingPicker />
    </>
  );
};
