import {CanvasItem} from 'features/types/canvasItemsSlice';
import {EXTERNAL_FONTS_WITH_PREVIEW} from './constants/fonts';
import {ExternalFontLoaderFont} from 'features/fontDataSlice';
import {ExternalFontUploadsType} from 'features/types/userLibrarySlice';
import {FONT_PREVIEW_BASE_URL} from 'constants/environment';
import memoize from 'lodash.memoize';

export type FontItem = {
  variant: string;
  fontFamily: string;
};

export const identifyFontsInItems = (items: Record<string, CanvasItem>): FontItem[] => {
  const fontItems: FontItem[] = Object.values(items)
    .filter(item => item.fontVariant && item.style)
    .map(item => {
      return {
        variant: item.fontVariant,
        fontFamily: item.style.fontFamily,
      } as FontItem;
    });
  return fontItems;
};

export const convertItemFontsToExternalLoaderFonts = memoize(
  (itemFonts: FontItem[]): ExternalFontLoaderFont[] => {
    const externalFontsToLoad = itemFonts.map(itemFont => {
      const externalFontToDisplay = externalFontsToDisplay().find(externalFont => {
        return externalFont.family === itemFont.fontFamily;
      });

      if (!externalFontToDisplay) {
        return undefined;
      }

      return externalFontToDisplay;
    });

    const filteredActiveFonts = externalFontsToLoad.filter(fontsToLoad => {
      return fontsToLoad;
    }) as ExternalFontLoaderFont[];
    // console.log('Run');

    if (filteredActiveFonts) {
      return filteredActiveFonts;
    } else {
      return [];
    }
  },
);

export const fontNamePreviewImage = (id: string) =>
  `${FONT_PREVIEW_BASE_URL}${id}-font-name-preview.png`;

export const fontSpecimenImage = (id: string) =>
  `${FONT_PREVIEW_BASE_URL}${id}-font-specimen.png`;

export const getFontLoaderFont = (font: typeof EXTERNAL_FONTS_WITH_PREVIEW[number]) => ({
  source: font.origin,
  family: font.family,
  variants: [font.variant],
  previews: {
    [font.variant]: fontNamePreviewImage(font.id),
  },
});

export const externalFontsToDisplay = memoize((): ExternalFontLoaderFont[] => {
  const newActiveFonts = EXTERNAL_FONTS_WITH_PREVIEW.map(getFontLoaderFont)
    .reduce(
      (
        accumulator: ExternalFontLoaderFont[],
        currentValue: ExternalFontLoaderFont,
      ): ExternalFontLoaderFont[] => {
        const newValue = accumulator;
        const fontInArray = newValue.find(
          (val: ExternalFontLoaderFont) => val.family === currentValue.family,
        );
        if (fontInArray) {
          fontInArray.variants.push(currentValue.variants[0]);
          fontInArray.previews[currentValue.variants[0]] =
            currentValue.previews[currentValue.variants[0]];
        } else {
          newValue.push(currentValue);
        }
        return newValue;
      },
      [],
    )
    .sort((a: ExternalFontLoaderFont, b: ExternalFontLoaderFont) =>
      a.family.localeCompare(b.family),
    );
  // console.log('Run2');
  return newActiveFonts;
});
