/**
 * Max value of the FFT array values
 */
export const FFT_MAX_VALUE = 256;

/**
 * List of audio visualization options
 */
export enum AudioWaveVizOptions {
  CircleCentrifugalLine = 'circle-centrifugal-line',
  LinesFlat = 'line-flats',
  LinesYAxis = 'line-y-axis',
  LineFlatEmojiWave = 'line-flat-emoji-wave',
}

export type AudioWaveformOptions = {
  targetPlayableMediaId: null | string;
  waveformStyle: AudioWaveVizOptions;
  originLocation: 'store';
  media: null;
};
