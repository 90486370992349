import React, {useEffect, useState} from 'react';
import {SessionDurationOptions, useStytch} from '@stytch/stytch-react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import ErrorPage from 'features/EditorCanvas/components/shared/ErrorPage';
import LoadingDashboard from 'pages/Dashboard/LoadingDashboard';
import LoadingPage from 'features/EditorCanvas/components/shared/LoadingPage';
import {routePaths} from 'routes/routesHelper';

export const Authenticate = ({authType}: {authType: 'invite' | 'oauth' | 'link'}) => {
  const navigate = useNavigate();
  const stytch = useStytch();
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteToken = searchParams?.get('token') || null;

  // console.log('inviteToken', inviteToken);

  const handleInvite = (
    token: string,
    authParams: SessionDurationOptions,
    handleAuthComplete: () => void,
  ) => {
    const authPromise = stytch.magicLinks
      .authenticate(token!, authParams)
      .then(handleAuthComplete);

    return authPromise;
  };

  const handleMagicLink = (
    token: string,
    authParams: SessionDurationOptions,
    handleAuthComplete: () => void,
  ) => {
    const authPromise = stytch.magicLinks
      .authenticate(token!, authParams)
      .then(handleAuthComplete);

    return authPromise;
  };

  const handleOauth = (
    token: string,
    authParams: SessionDurationOptions,
    handleAuthComplete: () => void,
  ) => {
    const authPromise = stytch.oauth
      .authenticate(token!, authParams)
      .then(handleAuthComplete);

    return authPromise;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    window.analytics.track('Authenticate running with authType', {
      auth_type: authType,
    });

    const authParams = {session_duration_minutes: 43800};
    const handleAuthComplete = () => navigate(`/${routePaths.dashboard}`);
    const handleInviteComplete = () =>
      navigate(`/${routePaths.user}/accept-invite?token=${token}`);

    let authPromise;
    switch (authType) {
      case 'link':
        authPromise = handleMagicLink(token!, authParams, handleAuthComplete);
        break;

      case 'invite':
        authPromise = handleInvite(token!, authParams, handleInviteComplete);
        break;

      case 'oauth':
        authPromise = handleOauth(token!, authParams, handleAuthComplete);
        break;

      default:
        break;
    }

    if (authPromise) {
      authPromise.catch(err => {
        setError(err);
        console.log('err', err);
        window.analytics.track('Error in auth or invite based on auth token', {
          error_message: err,
        });
        setTimeout(() => navigate(`/${routePaths.user}/login`), 10000);
      });
    }
  }, [navigate, stytch]);

  return (
    <>
      {error ? (
        <>
          <ErrorPage />
        </>
      ) : (
        <LoadingPage
          title="Authenticating"
          explainer="Please wait while your account is prepared."
        />
      )}
    </>
  );
};
