import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

export const ColorSquare = ({
  color,
  name,
  onClick,
  size = 8,
}: {
  color: string;
  name?: string;
  onClick?: () => void;
  size?: number | 'full';
}) => {
  return (
    <div className="relative">
      <div
        className={classNames(
          'flex-shrink-0 overflow-hidden rounded-md transition-shadow',
          size === 'full' ? 'aspect-w-1 aspect-h-1 h-full w-full' : `w-${size} h-${size}`,
          onClick && 'cursor-pointer hover:ring-2',
        )}
        style={{backgroundImage: 'url(/checkerboard.svg)'}}
        onClick={onClick}
        data-tip={name ? `${name}<br />${color}` : color}
        data-multiline={true}
      >
        <div className="h-full w-full shadow-inner" style={{backgroundColor: color}} />
      </div>
      <ReactTooltip place="bottom" className="bg-gray-600 font-semibold" effect="solid" />
    </div>
  );
};
