import { DashParams, routePaths } from 'routes/routesHelper';
import { useDispatch, useSelector } from 'react-redux';

import { ActionCreators } from 'redux-undo';
import EditorCanvasWrap from './EditorCanvasWrap';
import { Helmet } from 'react-helmet';
import { InverseCropProvider } from './components/CanvasItem/InverseCrop';
import LoadingPage from './components/shared/LoadingPage';
import { ProjectIdProvider } from './useProjectId';
import { baseApi } from 'services/baseAPI';
import { canvasStateSelector } from 'features/selectors/canvasStateSelectors';
import { initiateProject } from 'api/projectsAPI';
import { projectStartupPreparation } from 'features/canvasItemsSlice';
import store from '../../configureStore';
import { useAuthAlt } from 'features/Auth/useAuthAlt';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProjectQuery } from 'services/projectAPI';

function EditorCanvasWrapContainer({ projectId }: { projectId: string }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isLoading: isLoadingProject,
    isFetching: isFetchingProject,
    isError,
    isSuccess,
    data,
  } = useProjectQuery({
    id: projectId,
  });

  const { isLoading: isLoadingCanvasProject } = useSelector(canvasStateSelector);

  const { getAccessTokenSilently, user } = useAuthAlt();

  /**
   * When a projectId is 'new', then create a project on backend and get the projectId as a response.
   */
  useEffect(() => {
    if (projectId) {
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          if (projectId === 'new') {
            // TODO(lenny) fix the NEW project state?
            if (!user?.email) {
              throw new Error('user didnt have email');
            }
            const { project_id: newProjectId, project } = await initiateProject(
              token,
              projectId,
              user.email,
            );
            navigate(`/${routePaths.create}/${DashParams.CanvasParam}/${newProjectId}`, {
              replace: true,
            });
            dispatch(
              baseApi.util.invalidateTags([{ type: 'UserUpload', id: 'PARTIAL-LIST' }]),
            );
          } else {
            dispatch(projectStartupPreparation({ oldProjectId: projectId, token: token }));
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!isLoadingProject) {
      store.dispatch(ActionCreators.clearHistory());
    }
  }, [isLoadingProject]);

  if (isLoadingCanvasProject || isLoadingProject || isFetchingProject) {
    return (
      <div className="flex h-full w-full">
        <div className="flex min-h-screen w-full flex-col items-center justify-center">
          <LoadingPage
            title={'Loading page'}
            explainer={'Please wait as the page is prepared.'}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Milk Video | {projectId}</title>
      </Helmet>
      <ProjectIdProvider projectId={projectId}>
        <InverseCropProvider>
          <EditorCanvasWrap />
        </InverseCropProvider>
      </ProjectIdProvider>
    </>
  );
}

export default EditorCanvasWrapContainer;
