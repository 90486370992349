import {DEFAULT_SCENE_DURATION, updateItemAttribute} from 'features/canvasItemsSlice';
import {useDispatch, useSelector} from 'react-redux';

import {CanvasButton} from 'features/EditorCanvas/components/CanvasButton';
import {SceneTimingType} from 'features/types/canvasItemsSlice';
import {TimingOffsetButtom} from 'features/EditorCanvas/components/AppCanvas/SelectionNavBar/TimingOffsetButtom';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useProjectId} from 'features/EditorCanvas/useProjectId';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';

export function SceneTimingPicker() {
  const dispatch = useDispatch();
  const projectId = useProjectId();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  const {getItemScene} = useScenes();

  const isCanvasTimeOffsetActive = useFeatureFlag('canvas-time-offset');

  if (!isCanvasTimeOffsetActive) {
    return null;
  }

  if (!selected) return null;

  const scene = getItemScene(selected.item);

  const setSceneTiming = (sceneTiming: Partial<SceneTimingType>) => {
    // before writing, assign defaults if either property does not exist
    const defaultValues = {
      offsetMs: 0,
      durationMs: scene?.duration ? scene.duration : DEFAULT_SCENE_DURATION,
    };

    dispatch(
      updateItemAttribute({
        projectId,
        id: selected.id,
        attribute: 'sceneTiming',
        newValue: {
          ...defaultValues,
          ...selected.item.sceneTiming,
          ...sceneTiming,
        },
      }),
    );
  };

  const handleReset = () => {
    console.log('Updating attribute: sceneTiming');
    dispatch(
      updateItemAttribute({
        projectId,
        id: selected.id,
        attribute: 'sceneTiming',
        newValue: null,
      }),
    );
  };

  // console.log('selected', selected);

  return (
    <div className="mt-4">
      <div className="flex flex-row items-center justify-between">
        <div className="text-sm font-bold text-gray-700">Timing</div>
      </div>

      {/* <div className="flex">
        <div className="flex w-full flex-col pr-6 text-sm leading-5 text-gray-700">
          <div className="flex flex-row">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label className="mb-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                  Offset
                </label>
                <div className="sm:col-span-2 sm:mt-0">
                  <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <NumberInput
                      value={selected.item.sceneTiming?.offsetMs || 0}
                      onChange={offsetMs => setSceneTiming({offsetMs})}
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-3">
                <label className="mb-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                  Duration
                </label>
                <div className="sm:col-span-2 sm:mt-0">
                  <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <NumberInput
                      value={
                        selected.item.sceneTiming
                          ? selected.item.sceneTiming.durationMs
                          : scene?.duration
                          ? scene.duration
                          : DEFAULT_SCENE_DURATION
                      }
                      onChange={durationMs => setSceneTiming({durationMs})}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="mt-4 flex flex-row items-center space-x-2">
        <TimingOffsetButtom selectedItem={selected} />

        <CanvasButton onClick={handleReset} disabled={!selected.item.sceneTiming}>
          Reset
        </CanvasButton>
      </div>
    </div>
  );
}
