import {FC, useCallback, useEffect, useState} from 'react';
import {canvasColorPickerState, useOpenCanvasColorPicker} from './colorPickerState';

import {Button} from 'ui/Button';
import {ColorResult} from 'react-color';
import {ColorSquare} from 'ui/ColorSquare';
import {CustomColorInput} from 'features/Common/CustomColorInput';
import {GetRootState} from 'configureStore';
import {Link} from 'react-router-dom';
import {Plus} from 'react-feather';
import classNames from 'classnames';
import convert from 'color-convert';
import {isDefaultColorsDisabledSelector} from 'features/selectors/authSelectors';
import {selectCanvasItemsProjectsCanvas} from 'features/selectors/canvasItemsSelectors';
import unique from 'array-unique';
import {useFeatureFlag} from 'features/hooks/useFeatureFlag';
import {useFetchAccountBrandQuery} from 'services/accountBrandAPI';
import {useOnOutsideClick} from '../../../components/useOnOutsideClick';
import {useProjectId} from '../../../useProjectId';
import {useRecoilState} from 'recoil';
import {useSelector} from 'react-redux';
import {useSidebarMenu} from '../../SidebarMenuContext';
import {useUpdateRecentUsageMutation} from 'services/recentUsageAPI';

type OnChange = (color: ColorResult) => void;
type ColorType =
  | 'canvas_background'
  | 'shape_background'
  | 'captions_inactive_color'
  | 'captions_active_color'
  | 'text_color';

type Color = {
  hex: string;
  name?: string;
};

export type ActiveState = {color: string; onChange: OnChange; type: ColorType};

const useCanvasColorPicker = () => {
  const [canvasColorPicker, setCanvasColorPicker] =
    useRecoilState(canvasColorPickerState);

  const {dispatch} = useSidebarMenu();
  const setActiveView = (newActiveView: string) => {
    dispatch({
      type: 'update active sidebar menu',
      activeMenu: newActiveView,
    });
  };

  const start = useOpenCanvasColorPicker();

  const exit = useCallback(() => {
    if (canvasColorPicker) {
      setActiveView('Layer');
      setCanvasColorPicker(null);
    }
  }, [setCanvasColorPicker, canvasColorPicker]);

  return {
    current: canvasColorPicker,
    start,
    exit,
  };
};

const documentColorsSelector = (state: GetRootState) => (projectId: string) => {
  const items = selectCanvasItemsProjectsCanvas(state)[projectId].items;
  const colors: string[] = [];

  const addColor = (color: string | undefined) => {
    if (color === undefined) return;
    if (color[0] !== '#') return;
    colors.push(color);
  };

  for (const item of Object.values(items)) {
    if ('style' in item) {
      addColor(item.style.background);
      addColor(item.style.color);
      addColor(item.style.colorCurrent);
      addColor(item.style.colorInactive);
    }
  }

  const backgroundColor =
    state.sceneFrame.projects[projectId] &&
    state.sceneFrame.projects[projectId].backgroundColor &&
    state.sceneFrame.projects[projectId].backgroundColor.hex
      ? state.sceneFrame.projects[projectId].backgroundColor.hex
      : '#ffffff';

  addColor(backgroundColor);

  return unique(colors);
};

const Label: FC = ({children}) => {
  return (
    <label className="mb-2 block text-sm font-medium leading-5 text-gray-700">
      {children}
    </label>
  );
};

const Section: FC<{
  label: string;
  className?: string;
}> = ({label, children, className}) => {
  return (
    <div>
      <Label>{label}</Label>
      <div
        className={classNames('grid grid-cols-6 gap-2', className)}
        style={{gridAutoRows: '1fr'}}
      >
        {children}
      </div>
    </div>
  );
};

const Colors = ({
  label,
  colors,
  setColor,
}: {
  label: string;
  colors: Color[];
  setColor: (color: string) => void;
}) => {
  return (
    <Section label={label}>
      {colors.map(color => (
        <ColorSquare
          key={color.hex}
          color={color.hex}
          name={color.name}
          onClick={() => setColor(color.hex)}
          size="full"
        />
      ))}
    </Section>
  );
};

const BrandColors = ({setColor}: {setColor: (color: string) => void}) => {
  const {data: accountBrand, isLoading} = useFetchAccountBrandQuery();
  const brandColors = accountBrand?.colors.map(color => ({
    hex: color.color_hex,
    name: color.name,
  }));

  if (isLoading || brandColors === undefined) {
    return (
      <Section label="Brand colors" className="animate-pulse">
        <ColorSquare color="#CCC" size="full" />
        <ColorSquare color="#CCC" size="full" />
        <ColorSquare color="#CCC" size="full" />
      </Section>
    );
  }

  if (brandColors.length === 0) {
    return (
      <Section label="Brand colors" className="!block">
        <Link to="/brandkit">
          <Button variant="secondary" size="small" leftIcon={Plus} fullWidth>
            Add brand colors
          </Button>
        </Link>
      </Section>
    );
  }

  return <Colors label="Brand colors" colors={brandColors} setColor={setColor} />;
};

export const ColorPicker = () => {
  const {current, exit} = useCanvasColorPicker();

  const isDefaultColorsDisabled = useSelector(isDefaultColorsDisabledSelector);

  const themesEnabled = useFeatureFlag('brand-kit-fonts');

  const projectId = useProjectId();
  const documentColors = useSelector(documentColorsSelector)(projectId);

  const ref = useOnOutsideClick(event => {
    const colorInput = event.composedPath().find(element => {
      // @ts-ignore
      if (!element.dataset) return false;
      // @ts-ignore
      return element.dataset.colorinput;
    });

    if (colorInput) return;

    exit();
  });

  const [customColorTemp, setCustomColorTemp] = useState(current?.color || '#FFFFFF');
  const currentColor = current?.color;

  useEffect(() => {
    if (!currentColor) return;
    setCustomColorTemp(currentColor);
  }, [currentColor]);

  const [updateRecentUsage] = useUpdateRecentUsageMutation();

  if (!current) return null;

  const setColor = (color: string) => {
    const [h, s, l] = convert.hex.hsl(color);
    const [r, g, b] = convert.hex.rgb(color);

    current.onChange({
      hex: color,
      hsl: {h, s, l},
      rgb: {r, g, b},
    });

    updateRecentUsage({
      [current.type]: color,
    });
  };

  return (
    <div ref={ref} className="space-y-5 p-5">
      <Colors
        label="Document colors"
        colors={documentColors.map(color => ({hex: color}))}
        setColor={setColor}
      />
      {themesEnabled && <BrandColors setColor={setColor} />}
      {isDefaultColorsDisabled ? null : (
        <Colors
          label="Default colors"
          colors={defaultColors.map(color => ({hex: color}))}
          setColor={setColor}
        />
      )}
      <div>
        <Label>Custom color</Label>
        <CustomColorInput
          color={customColorTemp}
          onChange={color => {
            setCustomColorTemp(color.hex);
            current.onChange(color);
          }}
        />
      </div>
    </div>
  );
};

const defaultColors = [
  '#EF4444',
  '#F97316',
  '#F59E0B',
  '#84CC16',
  '#10B981',
  '#14B8A6',
  '#06B6D4',
  '#0EA5E9',
  '#3B82F6',
  '#6366F1',
  '#D946EF',
  '#EC4899',
  '#000000',
  '#64748B',
  '#FFFFFF',
];
