export enum RequestType {
  Post = 'post',
  Get = 'get',
}

export const requestHeader = (token: string, additionParams?: Record<string, string>) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  if (token && token.startsWith('Anonymous ')) {
    headers.Authorization = token;
  }

  if (additionParams) {
    return {
      headers: {
        ...additionParams,
        ...headers,
      },
    };
  } else {
    return {
      headers: headers,
    };
  }
};
