import {CanvasItem, PlayableItem} from 'features/types/canvasItemsSlice';
import {Clock, EyeOff} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';

import {Checkbox} from 'features/Common/Checkbox';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {toggleDurationIgnore} from 'features/canvasItemsSlice';

function isDurationIgnoredItem(item: CanvasItem): item is CanvasItem & PlayableItem {
  return 'isDurationIgnored' in item;
}

export const DurationIgnore = ({projectId}: {projectId: string}) => {
  const dispatch = useDispatch();
  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);

  if (!selected || !selected.item) return null;
  if (!isDurationIgnoredItem(selected.item)) return null;

  const handleClick = () => {
    if (!isDurationIgnoredItem(selected.item)) return;

    dispatch(
      toggleDurationIgnore(projectId, selected.id, !selected.item.isDurationIgnored),
    );
  };

  return (
    <Checkbox
      label={selected.item.isDurationIgnored ? 'Include duration' : 'Exclude duration'}
      checked={selected.item.isDurationIgnored === false}
      onChange={handleClick}
      CheckedIcon={Clock}
      UncheckedIcon={EyeOff}
    />
  );
};
