import {DashParams, routePaths} from 'routes/routesHelper';
import {Link, useParams} from 'react-router-dom';
import {
  isImageMedia,
  isVideoMedia,
} from 'features/Dashboard/DashboardUploadDetails/utils';
import {useCallback, useLayoutEffect, useMemo, useRef, useState} from 'react';

import {ArrowLeft} from 'react-feather';
import {Button} from 'ui/Button';
import {FileNav} from './FileNav';
import LoadingDashboard from 'pages/Dashboard/LoadingDashboard';
import UploadIcon from 'features/Dashboard/shared/UploadIcon';
import {authUserSelector} from 'features/selectors/authSelectors';
import {formatDistance} from 'date-fns';
import {useFileNavActiveUpload} from './FileNavActiveUploadProvider';
import {useSelector} from 'react-redux';
import {useUserUploadFoldersQuery} from 'services/userUploadFolderAPI';

export function UploadsFolderListContainer() {
  const user = useSelector(authUserSelector);

  const {folderId} = useParams() as Record<string, string>;
  // only show dialog to clip upload if audio/video, doesn't make sense for images/other

  const {state} = useFileNavActiveUpload();
  const activeFileNavUploadId = state.userUploadId;
  const {
    data: foldersData,
    isLoading,
    isFetching,
    isError,
  } = useUserUploadFoldersQuery({userId: user?.id ? user.id : ''});

  const currentFolder = foldersData?.folders.find(folder => folder.id === folderId);

  if (isLoading) {
    return <LoadingDashboard />;
  }

  return (
    <div className="h-full">
      <div className="px-4 py-8 sm:px-6 lg:px-8">
        <div className="space-x-4 sm:flex sm:items-center">
          <Link to={`/${routePaths.dashboard}/${DashParams.UploadsParam}`}>
            <Button variant="tertiary" iconOnly leftIcon={ArrowLeft} />
          </Link>
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Folder: {currentFolder?.name} ({currentFolder?.file_count})
            </h1>
            {/* <p className="mt-2 text-sm text-gray-700">
              Contents of 
            </p> */}
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4  sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Updated
                      </th>

                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {currentFolder?.items.map(userUpload => (
                      <tr
                        style={{
                          zIndex: activeFileNavUploadId === userUpload.id ? 100 : 0,
                        }}
                        className="relative"
                        key={userUpload.id}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <Link
                            className="table-cell whitespace-nowrap py-0 pl-4 pr-3 text-sm sm:pl-6"
                            to={`/${routePaths.dashboard}/${DashParams.UploadsParam}/${userUpload.id}`}
                          >
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                {isVideoMedia(userUpload.file_type) && (
                                  <img
                                    className="h-10 w-10"
                                    src={`https://image.mux.com/${userUpload.mux_playback_id}/thumbnail.png?width=100&height=100&fit_mode=smartcrop`}
                                    alt=""
                                  />
                                )}
                                {isImageMedia(userUpload.file_type) && (
                                  <div className="flex h-10 w-10 items-center justify-center">
                                    <img src={userUpload.upload_url} />
                                  </div>
                                )}
                                {!isVideoMedia(userUpload.file_type) &&
                                  !isImageMedia(userUpload.file_type) && (
                                    <div className="flex h-10 w-10 items-center justify-center">
                                      <UploadIcon fileType={userUpload.file_type} />
                                    </div>
                                  )}
                              </div>
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">
                                  {userUpload.file_name}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex items-center text-gray-900">
                            {formatDistance(new Date(userUpload.updated_at), new Date(), {
                              addSuffix: true,
                            })}
                          </div>
                        </td>
                        <td
                          onClick={evt => {
                            if (activeFileNavUploadId) {
                              // console.log('activeFileNavUploadId', activeFileNavUploadId);
                              evt.preventDefault();
                              evt.stopPropagation();
                            }
                          }}
                          className="relative z-30 whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                        >
                          <FileNav userUploadId={userUpload.id} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
