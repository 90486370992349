import {CheckIcon} from '@heroicons/react/solid';
import {announceUserToApi} from 'features/authSlice';
import {PropsWithChildren} from 'react';
import {ArrowRight} from 'react-feather';
import {Form} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {useSubmitAnonymousUserEmailMutation} from 'services/authAPI';
import {getAuthToken} from 'services/utils';
import {Button} from 'ui/Button';
import {FormTextInput} from 'ui/TextInput';
import {Modal} from 'ui/Modal';
import {string} from 'zod';

export const EnterEmailModal = ({
  title,
  open,
  onSubmit,
  children,
  submitButton = 'Next',
  optional = true,
}: PropsWithChildren<{
  title: string;
  open: boolean;
  onSubmit: () => void;
  submitButton?: string;
  optional?: boolean;
}>) => {
  const [submitAnonymousUserEmail] = useSubmitAnonymousUserEmailMutation();
  const dispatch = useDispatch();

  return (
    <Modal title="" open={open} onClose={() => {}} userCanClose={false}>
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <div className="flex flex-col space-y-4 text-center">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
        <div className="text-sm">{children}</div>
        <div className="text-sm">
          <span className="font-medium">Enter your email address</span>, to be notified on
          completion.
        </div>
      </div>
      <Form
        onSubmit={async values => {
          if (values.email) {
            await submitAnonymousUserEmail({email: values.email});

            const token = await getAuthToken();
            await new Promise<void>((resolve, reject) => {
              dispatch(
                announceUserToApi(token, {}, error => {
                  if (error) reject(error);
                  resolve();
                }),
              );
            });
          }

          onSubmit();
        }}
      >
        {({handleSubmit, submitting, values, valid}) => (
          <form onSubmit={handleSubmit} className="space-y-4">
            <FormTextInput
              type="email"
              placeholder="Your email address"
              name="email"
              autoFocus
              validate={value => {
                if (optional && !value?.length) return;

                if (!string().email().safeParse(value).success) {
                  return 'Invalid email address';
                }
              }}
            />
            {!optional || (values.email && valid) ? (
              <Button
                variant="primary"
                type="submit"
                loading={submitting}
                fullWidth
                rightIcon={ArrowRight}
                disabled={!values.email || !valid}
              >
                {submitButton}
              </Button>
            ) : (
              <Button variant="secondary" type="submit" loading={submitting} fullWidth>
                Skip email notification
              </Button>
            )}
          </form>
        )}
      </Form>
    </Modal>
  );
};
