import pick from 'lodash.pick';
import {ForwardedRef, useEffect, useRef} from 'react';
import Moveable from 'react-moveable';
import {MoveableManagerProps} from '../MoveableManager';

/**
 * Returns a "hash" of all property values of the selected items.
 *
 * Used to determine if any relevant properties have changed.
 */
const getItemsHash = ({
  selectedItems,
  items,
}: Pick<MoveableManagerProps, 'selectedItems' | 'items'>) => {
  let itemsHash = '';

  const selectedItemIds = selectedItems.map(item => item.id).sort();
  const selectedItemsObj = Object.values(pick(items, selectedItemIds));
  for (const item of selectedItemsObj) {
    if (item.position) itemsHash += Object.values(item.position);
    if (item.dimension) itemsHash += Object.values(item.dimension);
    if (item.rotation) itemsHash += item.rotation.toString();
  }

  return itemsHash;
};

/**
 * Tells the Moveable to update its bounding box
 * when any of the selected items' relevant properties change.
 *
 * It doesn't do this when the Moveable itself is triggering the updates,
 * since it does that itself.
 */
export const useUpdateMoveable = ({
  selectedItems,
  items,
  moveableRef,
}: Pick<MoveableManagerProps, 'selectedItems' | 'items'> & {
  moveableRef: ForwardedRef<Moveable>;
}) => {
  const hash = useRef('');

  useEffect(() => {
    if (!moveableRef || !('current' in moveableRef)) return;
    if (moveableRef.current?.isDragging()) return;
    if (selectedItems.length === 0) return;

    const newHash = getItemsHash({selectedItems, items});
    if (newHash === hash.current) return;

    moveableRef.current?.updateRect();
    hash.current = newHash;
  }, [selectedItems, items, moveableRef]);
};
