import {UploadMediaClipType, uploadMediaClipAPI} from 'services/uploadMediaClipAPI';
import {
  isAudioMedia,
  isImageMedia,
  isVideoMedia,
} from 'features/Dashboard/DashboardUploadDetails/utils';
import {useEffect, useMemo, useState} from 'react';

import {UserUploadsType} from 'features/types/userLibrarySlice';
import {useInfiniteQuery} from 'services/useInfiniteQuery';
import {useSelector} from 'react-redux';
import {useSidebarMenu} from 'features/EditorCanvas/Sidebar/SidebarMenuContext';
import {userLibrarySelector} from 'features/selectors/userLibrarySelectors';
import {userUploadAPI} from 'services/userUploadAPI';

enum AssetTypes {
  Clip = 'clip',
  UserUpload = 'userUpload',
}

type AssetItemType = {
  item: UploadMediaClipType | UserUploadsType;
  type: AssetTypes;
};

type AssetsListParams = {
  searchTerm: string;
};

/**
 * Returns the list of assets for the given sidebar choice "Clips" or "Images"
 */
const useAssetsList = ({searchTerm}: AssetsListParams): AssetItemType[] => {
  const {
    hasNextPage: hasNextClipsPage,
    fetchNextPage: fetchNextClipsPage,
    resultPages: userClipsPages,
    data: clipsData,
  } = useInfiniteQuery(uploadMediaClipAPI.endpoints.listUploadMediaClips, {
    getNextPageParam: lastPage => lastPage.next ?? undefined,
  });

  const {
    pages: clipsPages,
    page: clipsPage,
    count: clipsCount,
  } = clipsData || {
    pages: 0,
    page: 0,
    count: 0,
  };

  useEffect(() => {
    if (clipsPages > clipsPage && hasNextClipsPage) {
      fetchNextClipsPage();
    }
  }, [hasNextClipsPage, clipsPages, clipsPage, clipsCount]);

  const userClips = useMemo(() => {
    if (!userClipsPages) {
      return [];
    }
    return userClipsPages.map(userClipsPage => userClipsPage.data).flat();
  }, [userClipsPages]);

  const {
    hasNextPage: hasNextUploadsPage,
    fetchNextPage: fetchNextUploadsPage,
    resultPages: userUploadsPages,
    data: userUploadsData,
  } = useInfiniteQuery(userUploadAPI.endpoints.listUserUploads, {
    getNextPageParam: lastPage => lastPage.next ?? undefined,
  });

  const {
    pages: uploadsPages,
    page: uploadsPage,
    count: uploadsCount,
  } = userUploadsData || {
    pages: 0,
    page: 0,
    count: 0,
  };

  useEffect(() => {
    if (uploadsPages > uploadsPage && hasNextUploadsPage) {
      fetchNextUploadsPage();
    }
  }, [hasNextUploadsPage, uploadsPages, uploadsPage, uploadsCount]);

  const userUploads = useMemo(() => {
    if (!userUploadsPages) {
      return [];
    }
    return userUploadsPages.map(userUploadsPage => userUploadsPage.data).flat();
  }, [userUploadsPages]);

  const {
    state: {activeUploadMenu},
  } = useSidebarMenu();

  const assetFilters = [activeUploadMenu];

  const filteredUserUploads = userUploads.filter(upload => {
    if (!assetFilters.length) {
      return true;
    } else {
      const fileType = upload.file_type;
      if (assetFilters.includes('Videos')) {
        return isVideoMedia(fileType);
      }
      if (assetFilters.includes('Audio')) {
        return isAudioMedia(fileType);
      }
      if (assetFilters.includes('Images')) {
        return isImageMedia(fileType);
      }
    }

    return false;
  });

  let assetsList = [
    // show user clips if on ClipAssets sidebar menu
    ...((assetFilters.length > 0 && assetFilters.includes('Videos')) ||
    !assetFilters.length
      ? userClips.map(item => ({item, type: AssetTypes.Clip}))
      : []),
    ...filteredUserUploads.map(item => ({item, type: AssetTypes.UserUpload})),
  ];
  const ids = assetsList.map(o => o.item.id);
  const uniqueAssetsList = assetsList.filter(
    ({item: {id}}, index) => !ids.includes(id, index + 1),
  );
  assetsList = uniqueAssetsList
    .sort((a: AssetItemType, b: AssetItemType) => {
      return b.item.created_at - a.item.created_at;
    })
    .filter(({item}) => {
      if (item) {
        return (
          // @ts-ignore
          (item.file_name && item.file_name.includes(searchTerm)) ||
          // @ts-ignore
          (item.clip_name && item.clip_name.includes(searchTerm)) ||
          // @ts-ignore
          (item.text_snippet && item.text_snippet.includes(searchTerm))
        );
      } else {
        return false;
      }
    });

  return assetsList;
};

export default useAssetsList;
