import {UploadMediaClipType} from 'services/uploadMediaClipAPI';

export function removeListItem<T>(arr: Array<T>, value: T): Array<T> {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export const sortedArrById = (arr: UploadMediaClipType[]) =>
  arr.slice().sort((a: UploadMediaClipType, b: UploadMediaClipType) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });

export function filterByCount(array: UploadMediaClipType[], count: number) {
  return array.filter(
    (a, index) =>
      array.indexOf(a) === index &&
      array.reduce((acc, b) => +(a === b) + acc, 0) === count,
  );
}
