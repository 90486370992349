import {ChevronDown, Plus} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';
import {useLandingPageQuery, useUpdateLandingPageMutation} from 'services/landingPageAPI';

import {BASE_SHARE_URL} from 'constants/environment';
import {Button} from 'ui/Button';
import {ButtonGroup} from 'ui/ButtonGroup';
import ClipPickerModal from './ClipPickerModal';
import {DashboardContentSkeleton} from 'features/Dashboard/common/DashboardContentSkeleton';
import {DashboardContentWrap} from 'features/Dashboard/common/DashboardContentWrap';
import {DashboardHead} from 'features/Dashboard/common/DashboardHead';
import {DraggableChapterList} from './LandingPageCreate/DraggableChapterList';
import {DraggableItemsList} from './LandingPageCreate/DraggableItemsList';
import {DropdownMenu} from 'ui/DropdownMenu';
import {IconButton} from 'ui/IconButton';
import {LandingPagePreview} from './LandingPagePreview';
import cn from 'classnames';
import {fetchUserClips} from 'features/userLibrarySlice';
import {useAuthAlt} from 'features/Auth/useAuthAlt';
import {useParams} from 'react-router-dom';
import {userLibrarySelector} from 'features/selectors/userLibrarySelectors';

type DashboardLandingPageDetailPageProps = {
  isShowing: boolean;
};

export const CreateLandingPage = ({isShowing}: DashboardLandingPageDetailPageProps) => {
  const {landingPageId} = useParams() as Record<string, string>;

  const {data: landingPageData, isLoading} = useLandingPageQuery({id: landingPageId});
  const [updateLandingPage, {isLoading: isUpdating}] = useUpdateLandingPageMutation();

  const handleShare = () => {
    window.analytics.track('Pg-Edit -- LdPgAdm -- Evt-Clk -- Share');
  };

  const useLoadUserClips = () => {
    const {userClips} = useSelector(userLibrarySelector);
    const dispatch = useDispatch();
    const {getAccessTokenSilently} = useAuthAlt();

    useEffect(() => {
      window.analytics.track('Starting fetch of user upload and clips from clips page', {
        onload_clip_count: userClips.length,
      });

      (async () => {
        try {
          const token = await getAccessTokenSilently();

          if (!userClips.length) {
            // dispatch(fetchUserUploads(token, true));
            dispatch(fetchUserClips(token, true));
          } else {
            // dispatch(fetchUserUploads(token, true));
            dispatch(fetchUserClips(token, false));
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }, [getAccessTokenSilently, dispatch, userClips.length]);
  };

  const [addClipModalOpen, setAddClipModalOpen] = useState(false);

  const handleClickAddClip = () => {
    setAddClipModalOpen(true);
  };

  const handleCreateEmbedLink = () => {
    window.analytics.track('Pg-Edit -- LdPgAdm -- Act-Crt -- Embed Link');
    updateLandingPage({
      id: landingPageId,
      landing_page_components_attributes: [
        {
          element_type: 'embed',
          component_type: 'LandingPageEmbed',
          title: 'Untitled Embed Link',
          component_attributes: {
            url: 'https://example.com',
          },
          order: landingPageData?.landing_page_components.length,
        },
      ],
    });
  };

  const handleCreateSection = () => {
    window.analytics.track('Pg-Edit -- LdPgAdm -- Act-Crt -- Section');
    updateLandingPage({
      id: landingPageId,
      landing_page_components_attributes: [
        {
          element_type: 'section',
          title: 'Untitled Section',
          order: landingPageData?.landing_page_components.length,
        },
      ],
    });
  };

  useEffect(() => {
    window.analytics.track('Pg-Edit -- LdPgAdm -- Evt-Vwd -- Edit Page');
  }, []);

  useLoadUserClips();

  const itemsContainer = useRef<HTMLDivElement>(null);

  if (!landingPageData || isLoading) {
    return null;
  }

  return (
    <DashboardContentSkeleton isShowing={isShowing} isLoading={false}>
      <DashboardHead title={'Landing Pages'}>
        <a href={`${BASE_SHARE_URL}/p/${landingPageId}`} target="_blank" rel="noreferrer">
          <Button variant="primary" onClick={handleShare}>
            Share
          </Button>
        </a>
      </DashboardHead>
      <ClipPickerModal
        landingPage={landingPageData}
        open={addClipModalOpen}
        onClose={() => setAddClipModalOpen(false)}
      />
      <DashboardContentWrap
        className={cn(
          'flex h-full w-full min-w-full flex-grow items-start justify-center align-middle',
        )}
      >
        <div className="grid w-full grid-cols-7 gap-4 px-4">
          <div className="col-span-1">
            <div className="sticky top-0 z-10 space-y-4 py-6">
              <div className="space-y-2">
                <ButtonGroup variant="primary">
                  <Button variant="primary" onClick={() => setAddClipModalOpen(true)}>
                    Add a clip
                  </Button>
                  <DropdownMenu
                    anchor="left"
                    button={
                      <IconButton
                        icon={ChevronDown}
                        label="Menu"
                        smallIcon
                        variant="primary"
                      />
                    }
                    items={[
                      {
                        label: 'Add an embed link',
                        icon: Plus,
                        onClick: () => handleCreateEmbedLink(),
                      },
                      {
                        label: 'Add a section',
                        icon: Plus,
                        onClick: () => handleCreateSection(),
                      },
                    ]}
                  />
                </ButtonGroup>
              </div>
              <div className="h-px bg-gray-200" />
              <DraggableChapterList
                landingPageId={landingPageId}
                itemsList={landingPageData.landing_page_components}
                scrollToIndex={index => {
                  const item = itemsContainer.current?.querySelector(
                    `[data-index="${index}"]`,
                  );
                  item?.scrollIntoView({behavior: 'smooth'});
                }}
              />
            </div>
          </div>
          <div className="col-span-4">
            <DraggableItemsList
              landingPageId={landingPageId}
              itemsList={landingPageData.landing_page_components}
              openModalHandler={handleClickAddClip}
              ref={itemsContainer}
            />
          </div>
          <div className="col-span-2 py-6">
            <div className="overflow-hidden rounded-t-md">
              <span className="flex space-x-1 bg-gray-600 p-1 px-2">
                <span className="h-2 w-2 rounded-full bg-red-400" />
                <span className="h-2 w-2 rounded-full bg-yellow-400" />
                <span className="h-2 w-2 rounded-full bg-green-400" />
              </span>
            </div>

            <LandingPagePreview landingPageId={landingPageId} />
          </div>
        </div>
      </DashboardContentWrap>
    </DashboardContentSkeleton>
  );
};
