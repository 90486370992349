import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useSelectedTranscript, useSelection } from 'features/TranscriptEditor/selection';

import ActiveSelectionWordFlags from './ActiveSelectionWordFlags';
import { EventInfo } from './constants';
import { Scissors } from 'react-feather';
import { TranscriptToolbarButton } from 'features/TranscriptEditor/TranscriptToolbar';
import classNames from 'classnames';
import { useCreateClip } from 'features/TranscriptEditor/transcriptUtils';
import { useDocumentMouseUp } from 'features/Common/utils';
import { useDuration } from 'features/EditorCanvas/components/CanvasTime/useDuration';
import { useLanguage } from '../LanguageState';
import { useParams } from 'react-router-dom';

interface TimelineActiveSelectionProps {
  width: number;
  thumbnailContainerHeight: number;
  getEventInfo: (event: MouseEvent) => EventInfo;
  onDrag: (position: 'start' | 'end', info: EventInfo) => void;
}

export const timelineDraggingState = atom({
  key: 'timelineDragging',
  default: false,
});

export default function TimelineActiveSelection({
  width,
  thumbnailContainerHeight,
  getEventInfo,
  onDrag,
}: TimelineActiveSelectionProps) {
  const dragging = useRecoilValue(timelineDraggingState);
  const duration = useDuration();

  const { selection, isTemporary } = useSelection();
  const { userUploadId } = useParams<{ userUploadId: string }>();
  const { language } = useLanguage();
  const selectionWords = useSelectedTranscript(userUploadId!, language);
  const createClip = useCreateClip(userUploadId!);


  if (!selection) return null;

  const { start, end } = selection;

  const activeSelectionTranslateX = !isNaN(width * (start / duration))
    ? width * (start / duration)
    : 0;

  const activeSelectionWidth = width * ((end - start) / duration);

  return (
    <div
      className={classNames(
        'group rounded-md border-2 bg-blue-400 bg-opacity-50 transition hover:border-white',
        dragging ? 'border-white' : 'border-transparent',
        isTemporary && '!transition-all',
      )}
      style={{
        zIndex: 80,
        height: `${thumbnailContainerHeight}px`,
        display: 'block',
        position: 'absolute',
        left: '0px',
        top: '44px',
        transform: `translateX(${activeSelectionTranslateX}px)`,
        width: `${activeSelectionWidth}px`,
      }}
    >
      {selectionWords && !isTemporary && (
        <ActiveSelectionWordFlags
          width={width}
          start={start}
          end={end}
          activeSelectionTranslateX={activeSelectionTranslateX}
          activeSelectionWidth={activeSelectionWidth}
          startWord={selectionWords.start}
          endWord={selectionWords.end}
        />
      )}
      <DragHandle
        position="start"
        onDrag={event => onDrag('start', getEventInfo(event))}
      />
      <DragHandle position="end" onDrag={event => onDrag('end', getEventInfo(event))} />
    </div>
  );
}

const DragHandle = ({
  position,
  onDrag,
}: {
  position: 'start' | 'end';
  onDrag: (event: MouseEvent) => void;
}) => {
  const dragActiveRef = useRef(false);
  const [dragging, setDragging] = useRecoilState(timelineDraggingState);

  const onDragRef = useRef(onDrag);
  useLayoutEffect(() => {
    onDragRef.current = onDrag;
  }, [onDrag]);

  useEffect(() => {
    const onMouseMove = (event: MouseEvent) => {
      if (!dragActiveRef.current) return;
      event.stopPropagation();
      onDragRef.current(event);
    };

    document.addEventListener('mousemove', onMouseMove);
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  const onMouseDown = useDocumentMouseUp(() => {
    if (!dragActiveRef.current) return;
    dragActiveRef.current = false;
    setDragging(false);
  });

  return (
    <div
      className={classNames(
        'absolute top-1/2 -mt-4 h-8 w-2 cursor-[ew-resize] rounded-md bg-white transition-all group-hover:opacity-100',
        position === 'start' ? '-left-px -ml-1' : '-right-px -mr-1',
        dragging ? 'opacity-100' : 'opacity-0',
      )}
      onMouseDown={event => {
        onMouseDown();

        event.stopPropagation();
        dragActiveRef.current = true;
        setDragging(true);
      }}
    >
      <div className="absolute left-1/2 top-1 bottom-1 -ml-px w-0.5 rounded-full bg-blue-400" />
    </div>
  );
};
