import {DesktopComputerIcon, DeviceMobileIcon} from '@heroicons/react/outline';
import {useMemo, useState} from 'react';

import {ApiRecordingSession} from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import {Button} from 'ui/Button';
import ReactTooltip from 'react-tooltip';
import {RecordingLanding} from 'pages/UserRecordingForms/InteractionForm/RecordingLanding';
import {RecordingSessionCheck} from 'features/RecordingForms/recording/contexts/RecordingSessionCheck';
import {Share} from 'react-feather';
import classNames from 'classnames';
import {recordingFormUrl} from './utils';
import useDimensions from 'react-use-dimensions';
import {useParams} from 'react-router-dom';
import {useWindowHeight} from '@react-hook/window-size';

const HEIGHT_VALUE = 800;
const MOBILE_DEMO_WIDTH = 520;
const DESKTOP_DEMO_WIDTH = 1024;

export const RecordingFormPreview = () => {
  const {recordingFormId} = useParams() as Record<string, string>;

  const previewUrl = useMemo(
    () => `${recordingFormUrl(recordingFormId)}?isPreview=true`,
    [recordingFormUrl, recordingFormId],
  );

  const [demoWidth, setDemoWidth] = useState(1024);
  const windowHeight = useWindowHeight();
  const [ref, {height, width}] = useDimensions();

  const isMobileDemo = demoWidth === MOBILE_DEMO_WIDTH;
  const isDesktopDemo = demoWidth === DESKTOP_DEMO_WIDTH;
  const scaleValue = (width - 100) / 1024;
  const scaledHeightValue = (HEIGHT_VALUE + 200) * scaleValue;

  return (
    <div
      className="hidden w-full flex-col items-center bg-white p-4 lg:flex"
      style={{height: windowHeight}}
    >
      <p className="mb-8 hidden text-center text-xs text-gray-500 md:flex">
        👇 Scaled down preview, no data will be recorded
      </p>
      <div
        ref={ref}
        className="mx-auto flex h-full w-full max-w-sm flex-col items-center justify-start p-12 "
      >
        <div className="flex flex-col items-center justify-center">
          <div
            className="w-full "
            style={{
              height: scaledHeightValue,
            }}
          ></div>
          <div
            style={{
              transform: `scale(${scaleValue})`,
            }}
            className="absolute transform overflow-hidden rounded-md bg-white shadow-lg"
          >
            <div
              style={{
                height: isMobileDemo ? 60 : 35,
                width: '100%',
              }}
              className={classNames(
                'flex flex-row items-center justify-start space-x-3 bg-gray-200 px-4 transition',
              )}
            >
              <div
                className={classNames('h-3 w-3 rounded-full bg-red-500', {
                  'opacity-0': isMobileDemo,
                })}
              />
              <div
                className={classNames('h-3 w-3 rounded-full bg-orange-500', {
                  'opacity-0': isMobileDemo,
                })}
              />
              <div
                className={classNames('h-3 w-3 rounded-full bg-green-500', {
                  'opacity-0': isMobileDemo,
                })}
              />
            </div>
            <div style={{width: demoWidth, height: HEIGHT_VALUE}}>
              <ApiRecordingSession.Provider>
                <RecordingSessionCheck>
                  <RecordingLanding formOverrideId={recordingFormId} />
                </RecordingSessionCheck>
              </ApiRecordingSession.Provider>
            </div>
            {/* <iframe width={demoWidth} height={HEIGHT_VALUE} src={previewUrl} /> */}
            <div
              style={{
                width: '100%',
              }}
              className={classNames(
                'flex h-16 flex-row items-center justify-center space-x-3 bg-gray-200 px-4 transition',
                {
                  'opacity-0': !isMobileDemo,
                },
              )}
            >
              <div
                className={classNames('h-12 w-12 rounded-full bg-gray-300', {
                  'opacity-0': !isMobileDemo,
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-center space-x-3">
        <span data-tip="Desktop shows browser at 1024 pixels">
          <ReactTooltip className="bg-gray-600 font-semibold" effect="solid" />
          <Button
            _className={classNames({
              'opacity-50': !isDesktopDemo,
            })}
            variant={'tertiary'}
            onClick={() => setDemoWidth(DESKTOP_DEMO_WIDTH)}
            iconOnly
            leftIcon={DesktopComputerIcon}
          />
        </span>
        <span data-tip="Mobile">
          <ReactTooltip className="bg-gray-600 font-semibold" effect="solid" />
          <Button
            _className={classNames({
              'opacity-50': !isMobileDemo,
            })}
            variant={'tertiary'}
            onClick={() => setDemoWidth(MOBILE_DEMO_WIDTH)}
            iconOnly
            leftIcon={DeviceMobileIcon}
          />
        </span>
        <span data-tip="Open live page">
          <ReactTooltip className="bg-gray-600 font-semibold" effect="solid" />
          <a href={previewUrl} target="_blank" rel="noopener noreferrer">
            <Button
              _className={classNames({
                'opacity-50': true,
              })}
              variant={'tertiary'}
              iconOnly
              leftIcon={Share}
            />
          </a>
        </span>
      </div>
    </div>
  );
};
