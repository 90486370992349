import {Check} from 'react-feather';
import {Fragment} from 'react';
import classNames from 'classnames';

const steps = ['Start', 'Upload', 'Customize', 'Clip', 'Download'];

export const FocusedFlowStepper = ({
  activeStep,
  onClick,
  canGoBack,
}: {
  activeStep: number;
  onClick: (index: number) => void;
  canGoBack: boolean;
}) => {
  return (
    <div className="mx-auto hidden w-full items-center overflow-x-scroll rounded-lg sm:flex lg:px-4">
      {steps.map((step, index) => {
        const isCurrent = index === activeStep;
        const isCompleted = index < activeStep;
        const isFuture = index > activeStep;
        const isFirst = index === 0;
        const isLast = index === steps.length - 1;

        return (
          <Fragment key={step}>
            <div
              className={classNames(
                'flex flex-shrink-0 items-center space-x-4 !bg-opacity-10 p-2 px-3 transition',
                isCompleted && 'opacity-80',
                (isCurrent || isCompleted) && 'text-indigo-600',
                isFuture && 'text-gray-400',
                isFirst && '!justify-start',
                isLast && '!justify-end',
                isCompleted &&
                  canGoBack &&
                  'cursor-pointer rounded-lg hover:bg-indigo-500',
              )}
              onClick={() => {
                if (isCompleted && canGoBack) {
                  onClick(index);
                }
              }}
            >
              <div
                className={classNames(
                  'box-content flex h-6 w-6 items-center justify-center rounded-full border-2 border-gray-400 text-base font-medium transition duration-500 md:h-8 md:w-8 md:text-xl',
                  isCompleted && '!border-indigo-600 bg-indigo-600 text-white',
                  isCurrent && '!border-indigo-600 text-indigo-600',
                )}
              >
                {isCompleted ? (
                  <Check size={20} className="relative top-px" />
                ) : (
                  <div>{index + 1}</div>
                )}
              </div>
              <div className="whitespace-nowrap font-semibold">{step}</div>
            </div>
            {index < steps.length - 1 && (
              <div
                className={classNames(
                  'relative mx-2 h-[6px] flex-1 overflow-hidden rounded-full bg-gray-200',
                  isCompleted && 'opacity-80',
                )}
              >
                <div
                  className={classNames(
                    'absolute inset-y-0 left-0 w-full origin-left scale-x-0 bg-indigo-600 transition duration-500',
                    isCompleted && '!scale-x-100',
                  )}
                />
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
