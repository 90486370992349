import {Button} from 'ui/Button';
import {Clock} from 'react-feather';
import {SparklesIcon} from '@heroicons/react/outline';
import {StepHeader} from './utils';
import {WizardStepFC} from './NewProjectWizard';
import {asyncTrack} from 'features/Common/utils';

export const Intro: WizardStepFC = ({state, actions, createProject}) => {
  return (
    <div className="flex h-full flex-col items-center justify-center space-y-4 text-center">
      <div className="-mt-4 flex items-center space-x-1.5 text-sm font-medium text-gray-600">
        <Clock size={16} />
        <div>Takes 2 min</div>
      </div>
      <StepHeader heading="Create a video" state={state} actions={actions}>
        Choose a template, answer some questions, and{' '}
        <strong>have a professional looking video ready to share in minutes.</strong>
      </StepHeader>
      <div className="mx-auto flex w-full max-w-[260px] flex-col items-center space-y-2">
        <Button
          variant="primary"
          leftIcon={SparklesIcon}
          fullWidth
          onClick={() => {
            window.analytics.track('TmpWzd -- Lc-Fin -- Template wizard intro');
            actions.nextStep();
          }}
        >
          Choose a template
        </Button>
        <Button
          variant="inline"
          size="small"
          onClick={async () => {
            await asyncTrack('TmpWzd -- Evt-Clk -- "create custom"');
            await createProject();
          }}
        >
          or, create a custom design
        </Button>
      </div>
    </div>
  );
};
