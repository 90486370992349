import {BaseItem, CaptionClipItem, CaptionItem} from 'features/types/canvasItemsSlice';
import {
  UPLOAD_TRANSCRIPT_CONTEXT_DEFAULT,
  UploadTranscriptProvider,
} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/UploadTranscriptContext';

import {ArrowLeftCircle} from 'react-feather';
import {CanvasButton} from '../../components/CanvasButton';
import {CloseSidebar} from '../CloseSidebar';
import {SelectionProvider} from 'features/TranscriptEditor/selection';
import {TranscriptEditor} from 'features/TranscriptEditor/TranscriptEditor';
import {ViewTypes} from '../../constants/ViewConstants';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {transcriptRangeForItem} from 'features/TranscriptEditor/transcriptUtils';
import {usePlayback} from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import {useProjectId} from '../../useProjectId';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';
import {useSelector} from 'react-redux';
import {useSidebarMenu} from '../SidebarMenuContext';
import {useTranscriptIdAndLanguage} from 'features/Captions/Transcripts';

const TranscriptWrapper = ({
  item,
  itemId,
  projectId,
}: {
  item: BaseItem & (CaptionClipItem | CaptionItem);
  itemId: string;
  projectId: string;
}) => {
  const {transcriptId, language} = useTranscriptIdAndLanguage({item, itemId, projectId});

  const {seekTo} = usePlayback();
  const {scenes} = useScenes();
  const scene = scenes.find(scene => scene.sceneId === item.sceneId);
  const sceneOffset = scene?.startTimeMs ?? 0;

  const timeOffsetMs = item.timeOffsetSeconds * 1000 - sceneOffset;

  if (!transcriptId) return null;

  return (
    <UploadTranscriptProvider
      initialState={{
        ...UPLOAD_TRANSCRIPT_CONTEXT_DEFAULT,
        initialAutoScroll: false,
        initialEditMode: false,
      }}
    >
      <TranscriptEditor
        userUploadId={transcriptId}
        language={language}
        range={transcriptRangeForItem({item})}
        timeOffsetMs={timeOffsetMs}
        seekTo={seekTo}
        toolbarOptions={{
          allowClipping: false,
          showShortcuts: false,
        }}
      />
    </UploadTranscriptProvider>
  );
};

export const Transcript = () => {
  const {dispatch} = useSidebarMenu();
  const setActiveView = (newActiveView: string) => {
    dispatch({
      type: 'update active sidebar menu',
      activeMenu: newActiveView,
    });
  };

  const projectId = useProjectId();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);

  if (!selected) {
    return <CloseSidebar />;
  }

  if (
    selected.item.viewType !== ViewTypes.CaptionClip &&
    selected.item.viewType !== ViewTypes.Caption
  ) {
    return <CloseSidebar />;
  }

  return (
    <SelectionProvider>
      <div className="pb-16">
        <div className="flex items-center space-x-2 border-b p-3">
          <CanvasButton onClick={() => setActiveView('Layer')}>
            <ArrowLeftCircle size={16} />
          </CanvasButton>
          <div className="font-semibold">Correct transcript</div>
        </div>
        <div className="p-3">
          <TranscriptWrapper
            item={selected.item}
            itemId={selected.id}
            projectId={projectId}
          />
        </div>
      </div>
    </SelectionProvider>
  );
};
