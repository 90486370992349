import {HStack} from 'features/Common/Stack';
import {CanvasItem} from 'features/types/canvasItemsSlice';
import {FC, ReactNode} from 'react';
import {useRecoilValue} from 'recoil';
import {zoomState} from '../AppCanvas/ZoomToolbar';

export const Toolbar = ({left, right}: {left?: ReactNode; right?: ReactNode}) => {
  const zoom = useRecoilValue(zoomState);

  return (
    <HStack className="flex-row-reverse justify-between" spacing={2}>
      <div className="pointer-events-auto flex-shrink-0">{right}</div>
      <div className="pointer-events-auto flex-shrink-0">{left}</div>
    </HStack>
  );
};

export const ToolbarContainer: FC<{
  position: CanvasItem['position'];
  dimension: CanvasItem['dimension'];
}> = ({position, dimension, children}) => {
  const zoom = useRecoilValue(zoomState);

  return (
    <div
      className="pointer-events-none absolute origin-bottom-left pb-2"
      style={{
        top: position.top - 50,
        left: position.left,
        transform: ` translate(0, -100%) scale(${1 / zoom})`,
        width: dimension.width * zoom,
        zIndex: 9999,
      }}
      data-moveable-toolbar
    >
      {children}
    </div>
  );
};
