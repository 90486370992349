import {ActiveState} from 'features/EditorCanvas/Sidebar/views/ColorPicker/ColorPicker';
import {useOpenCanvasColorPicker} from 'features/EditorCanvas/Sidebar/views/ColorPicker/colorPickerState';
import {FC} from 'react';

import {ColorSquare} from '../../ui/ColorSquare';

export const ColorInput: FC<{label?: string} & ActiveState> = ({
  color,
  children,
  label,
  ...pickerParams
}) => {
  const openColorPicker = useOpenCanvasColorPicker();

  const open = () => {
    openColorPicker({...pickerParams, color});
  };

  return (
    <div data-colorinput>
      {label && (
        <label className="mb-2 block text-sm font-medium leading-5 text-gray-700">
          {label}
        </label>
      )}
      {children ? (
        <div onClick={open}>{children}</div>
      ) : (
        <ColorSquare color={color} onClick={open} />
      )}
    </div>
  );
};
