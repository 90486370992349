import {RecordingSubmission} from 'features/RecordingForms/recording/contexts/ApiRecordingSessionProvider';
import {baseApi} from './baseAPI';
import {subscribeToCable} from './utils';

export enum RecordingSubmissionCableActions {
  SubmissionUpdated = 'submission_updated',
}

export const recordingSubmissionsAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['RecordingFormSubmission']})
  .injectEndpoints({
    endpoints: builder => ({
      recordingFormSubmissions: builder.query<RecordingSubmission[], void>({
        query: () => ({
          url: `user_recording_interaction_submissions`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading recording form submission data',
        }),
        providesTags: ['RecordingFormSubmission'],
        // THIS DOES NOT WORK YET
        async onCacheEntryAdded(_, api) {
          return subscribeToCable(
            {api, action: RecordingSubmissionCableActions.SubmissionUpdated},
            payload => {
              // console.log('payload ---', payload);
              api.dispatch(
                recordingSubmissionsAPI.util.invalidateTags(['RecordingFormSubmission']),
              );
            },
          );
        },
      }),
    }),
  });

export const {useRecordingFormSubmissionsQuery} = recordingSubmissionsAPI;
