import ClipListResultActions, {
  ClipListResultActionsProps,
} from '../../ContentClips/ClipListResultActions';
import {Pause, Play} from 'react-feather';

import {MilkIcon} from 'features/Common/MilkIcon';
import {UploadClipListItem} from '../SearchResults';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import cn from 'classnames';
import {secondToPlayerTime} from '../utils';
import {useSearchResultItem} from './useSearchResultItem';
import {useSearchResults} from '../useSearchResults';
import {useState} from 'react';

type ResultUploadClipsProps = {
  uploadClip: UploadClipListItem;
  children: (props: ClipListResultActionsProps) => JSX.Element;
};

export const ResultUploadClips = ({uploadClip, children}: ResultUploadClipsProps) => {
  const uploadClipToDisplay = 3;

  const {
    state: {shouldShowAll, videoRef, hoveredClipId},
    dispatch: searchResultItemDispatch,
  } = useSearchResultItem();

  const [isMenuOpen, setIsMenuOpen] = useState<null | string>(null);

  const {
    state: {clipIdPlaying},
    dispatch: searchResultsDispatch,
  } = useSearchResults();

  const uploadClipList =
    !shouldShowAll && uploadClip.uploadSecondaryItems.length > uploadClipToDisplay
      ? uploadClip.uploadSecondaryItems.slice(0, uploadClipToDisplay)
      : uploadClip.uploadSecondaryItems;

  const setHoveringClip = (newState: string | null) => {
    searchResultItemDispatch({
      type: 'updateHoveredClipId',
      payload: {
        clipId: newState,
      },
    });
  };

  const setShouldShowAll = (newState: boolean) => {
    searchResultItemDispatch({
      type: 'updateShouldShowAllItems',
      payload: {
        shouldShowAll: newState,
      },
    });
  };

  const targetClipHovered = (clipId: string) => {
    setHoveringClip(clipId);
  };

  const cancelHover = () => {
    setHoveringClip(null);
  };

  const setIsPlaying = (newState: boolean) => {
    searchResultItemDispatch({
      type: 'updateIsPlaying',
      payload: {
        isPlaying: newState,
      },
    });
  };

  const setClipIdPlaying = (clipId: null | string) => {
    searchResultsDispatch({
      type: 'updateClipIdPlaying',
      payload: {
        clipId,
      },
    });
  };

  const updateActiveClipAndPlay = (clip: UploadMediaClipType) => {
    // console.log('videoRef', videoRef);
    if (videoRef?.current) {
      setClipIdPlaying(clip.id);
      videoRef.current.seekTo(Number(clip.start_time), 'seconds');
      window.setTimeout(() => {
        setIsPlaying(true);
        if (videoRef?.current?.player?.player?.play) {
          videoRef.current.player.player.play();
        }
      }, 1);
    }
  };

  const stopAndClearActiveClip = () => {
    searchResultsDispatch({
      type: 'updateClipIdPlaying',
      payload: {
        clipId: null,
      },
    });
    setIsPlaying(false);
    if (videoRef?.current?.player?.player?.pause) {
      videoRef.current.player.player.pause();
    }
  };

  if (typeof children !== 'function') return null;

  return (
    <ul className="flex flex-col divide-y divide-gray-200">
      {uploadClipList.map(clip => {
        const shouldShowHour = !!(
          uploadClip.upload.duration && uploadClip.upload.duration > 60
        );
        return (
          <li
            onMouseEnter={() => {
              targetClipHovered(clip.id);
            }}
            onMouseLeave={() => {
              cancelHover();
            }}
            key={clip.id}
            className={cn('w-full py-2 px-2', {
              'relative z-10': isMenuOpen === clip.id,
            })}
          >
            <div className="relative w-full">
              <div className="flex items-center justify-between space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    // console.log('clip', clip);
                    if (clipIdPlaying === clip.id) {
                      stopAndClearActiveClip();
                    } else {
                      stopAndClearActiveClip();
                      updateActiveClipAndPlay(clip);
                    }
                  }}
                  className={cn(
                    'relative z-10 flex flex-shrink-0 items-center justify-center rounded-full border border-transparent p-1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                    {
                      'bg-gray-50': hoveredClipId === clip.id,
                    },
                  )}
                >
                  {hoveredClipId === clip.id || clipIdPlaying === clip.id ? (
                    clipIdPlaying === clip.id ? (
                      <Pause className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <Play className="h-5 w-5" aria-hidden="true" />
                    )
                  ) : (
                    <MilkIcon
                      width={20}
                      height={20}
                      viewBox="0 0 16 16"
                      className="h-5 w-5"
                      icon="Clip"
                    />
                  )}
                </button>
                <div className="min-w-0 flex-1">
                  <div className="block focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="truncate text-sm text-gray-500">
                      {Number(clip.start_time) !== 0 && '...'}
                      {clip.text_snippet}
                    </p>
                  </div>
                </div>
                <span className="flex-shrink-0 whitespace-nowrap text-sm text-gray-300">
                  {secondToPlayerTime(Number(clip.start_time), shouldShowHour)}
                </span>
                {/* Ask Jacques about children and render props */}
                {children({
                  userUploadId: uploadClip.upload.id,
                  userClipId: clip.id,
                  setIsMenuOpen: setIsMenuOpen,
                })}
              </div>
            </div>
          </li>
        );
      })}
      {!shouldShowAll && uploadClip.uploadSecondaryItems.length > uploadClipToDisplay && (
        <li className="w-full bg-white py-2 px-2">
          <div className="relative w-full">
            <button
              onClick={() => {
                setShouldShowAll(true);
              }}
              type="button"
              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Show all
            </button>
          </div>
        </li>
      )}
    </ul>
  );
};
