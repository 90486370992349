import {LandingPage} from 'services/landingPageAPI';
import LandingPageCard from './LandingPageCard';

interface LandingPageResultsProps {
  landingPages: LandingPage[];
}
export default function LandingPageResults({landingPages}: LandingPageResultsProps) {
  if (!landingPages) {
    return null;
  }
  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 pt-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      {landingPages.map(landingPage => {
        return <LandingPageCard landingPage={landingPage} key={landingPage.id} />;
      })}
    </ul>
  );
}
