import {createContainer} from 'unstated-next';
import {useState} from 'react';

type ActiveModalTypes = 'export' | 'share';

function useEditorCanvasModals() {
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState<ActiveModalTypes>('export');

  const updateExportModal = (updatedModalState: boolean) =>
    setIsExportModalOpen(updatedModalState);

  const updateActiveModal = (updatedActiveModal: ActiveModalTypes) =>
    setActiveModal(updatedActiveModal);

  return {
    activeModal,
    isExportModalOpen,
    updateExportModal,
    updateActiveModal,
  };
}

const EditorCanvasModals = createContainer(useEditorCanvasModals);

export {EditorCanvasModals};
