import {DashParams, routePaths} from 'routes/routesHelper';

import LoadingPage from 'features/EditorCanvas/components/shared/LoadingPage';
import {useCreateSampleUploadMutation} from 'services/userUploadAPI';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

export const CreateSampleUpload = () => {
  const [createSampleUpload] = useCreateSampleUploadMutation();
  const navigate = useNavigate();

  useEffect(() => {
    createSampleUpload().then(result => {
      if ('data' in result) {
        navigate(
          `/${routePaths.dashboard}/${DashParams.UploadsParam}/media/${result.data.bucket_key}`,
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="flex h-screen min-h-screen w-screen flex-col items-center justify-center bg-gray-100"
      style={{zIndex: 10001}}
    >
      <LoadingPage title="Loading..." explainer="Please wait" />
    </div>
  );
};
