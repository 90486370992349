import { canvasStateSelector, selectCanvasItemLoadingState } from 'features/selectors/canvasStateSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { CanvasItem } from 'features/types/canvasItemsSlice';
import { EXTERNAL_FONTS_WITH_PREVIEW } from 'features/Fonts/constants/fonts';
import { TimeMilliSeconds } from 'features/types/userLibrarySlice';
import { TranscriptParagraph } from './Transcripts';
import { accountFontsSelector } from 'features/selectors/fontDataSelectors';
import { getOpenfontData } from 'api/openfontAPI';
import { setDependencyReady } from 'features/canvasStateSlice';

export const useFontData = (fontFamily: string, projectId: string) => {
  const [font, setFont] = useState<{ family: string; data: opentype.Font }>();
  const loadedFontFamily = font?.family;
  const accountFonts = useSelector(accountFontsSelector());
  const isProjectLoading = useSelector(canvasStateSelector).isLoading;

  useEffect(() => {
    if (loadedFontFamily === fontFamily) {
      return;
    }

    let variantUrl: string | undefined;

    const externalFont = EXTERNAL_FONTS_WITH_PREVIEW.find(
      font => font.family === fontFamily,
    );

    if (externalFont) {
      variantUrl = externalFont.url;
    } else {
      if (isProjectLoading) return;

      const userFont = accountFonts.find(
        (font: Partial<{ font_fullname: string }>) => font.font_fullname === fontFamily,
      );
      if (userFont) {
        variantUrl = userFont.upload_url;
      }
    }

    if (!variantUrl) {
      console.error(`Could not find fontFamily "${fontFamily}"`);
      return;
    }

    requestFont(variantUrl)
      .then(data => {
        setFont({ family: fontFamily, data });
      })
      .catch(error => {
        console.error(`Could not find fontFamily "${fontFamily}": ${error.message}`);
        console.error(error);
      });
  }, [fontFamily, loadedFontFamily, accountFonts, isProjectLoading]);

  return font;
};

const pendingFonts: Record<string, Promise<opentype.Font>> = {};

const requestFont = async (variantUrlInput: string) => {
  const variantUrl = variantUrlInput.replace('http://', 'https://');

  window.openFontData = window.openFontData || {};

  const font = window.openFontData[variantUrl];
  if (font) return font;

  if (pendingFonts[variantUrl] !== undefined) {
    return pendingFonts[variantUrl];
  }

  pendingFonts[variantUrl] = getOpenfontData(variantUrl);

  const newFont = await pendingFonts[variantUrl];
  window.openFontData[variantUrl] = newFont;
  return newFont;
};

export type TranscriptWordsWithDimensionType = {
  fontWidth: number;
  fontHeight: number;
  content: string;
  start: TimeMilliSeconds;
  end: TimeMilliSeconds;
};

export const getWordsWithDimensions = ({
  paragraph = [],
  font,
  fontSizePx,
  lineHeight,
}: {
  paragraph: TranscriptParagraph;
  font: opentype.Font;
  fontSizePx: number;
  lineHeight: number;
}): TranscriptWordsWithDimensionType[] => {
  return paragraph.map(word => {
    const scale = (1 / font.unitsPerEm) * fontSizePx;

    const lineHeightFontUnits = font.unitsPerEm * lineHeight;
    const fontHeight = lineHeightFontUnits * scale;

    const fontWidth = font.getAdvanceWidth(`${word.text} `, fontSizePx);

    return {
      fontWidth,
      fontHeight,
      content: word.text,
      start: word.start,
      end: word.end,
    };
  });
};

export type WordWithDimensions = ReturnType<typeof getWordsWithDimensions>[number];

export const useSetFontReady = ({
  item,
  projectId,
  itemId,
}: {
  item: CanvasItem;
  projectId: string;
  itemId: string;
}) => {
  const dispatch = useDispatch();

  const { loading: itemLoading } = useSelector(state =>
    selectCanvasItemLoadingState(state, itemId))

  const { fontFamily, fontSize } = item.style;

  useEffect(() => {
    if (!itemLoading.includes('font')) return;
    if (!fontFamily || !fontSize) return;

    if (fontFamily) {
      const fontLoaded = document.fonts.check(`${fontSize}px ${fontFamily}`);
      if (fontLoaded) {
        dispatch(setDependencyReady({ itemId, dependency: 'font' }));
      } else {
        document.fonts.ready.then(() => {
          dispatch(setDependencyReady({ itemId, dependency: 'font' }));
        });
      }
    }
  }, [dispatch, fontFamily, fontSize, itemId, itemLoading, projectId]);
};
