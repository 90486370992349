import classNames from 'classnames';
import {TranscriptJSON} from 'features/Captions/Transcripts';
import {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {useSelection} from './selection';
import {getWordAtCoordinates} from './slateUtils';
import {usePopper} from 'react-popper';

export type DragHandleType = 'start' | 'end';

export const DragHandle = ({
  onMouseDown,
  type,
  show,
}: {
  onMouseDown: () => void;
  type: DragHandleType;
  show: boolean;
}) => {
  return (
    <div
      className={classNames(
        'absolute -m-2 flex cursor-[col-resize] flex-col items-center p-2 opacity-0 transition-opacity group-hover:opacity-100',
        type === 'start' ? '-top-3 -left-1.5' : '-bottom-3 -right-1.5 rotate-180',
        show && '!opacity-100',
      )}
      onMouseDown={onMouseDown}
    >
      <div className="h-3 w-3 rounded-full bg-indigo-500" />
      <div className="h-6 w-0.5 bg-indigo-500" />
    </div>
  );
};

type LeafElement = {
  text: string;
  handles?: DragHandleType[];
  highlighted?: boolean;
  hovering?: boolean;
  searchResult?: boolean;
  activeSearchResult?: boolean;
};
type LeafChildType = string | LeafElement;

export const getLeafChildren = ({
  text,
  firstSelected,
  lastSelected,
  highlightWord,
  hoverWord,
  searchResults,
  activeSearchResult,
}: {
  text: string;
  firstSelected?: boolean;
  lastSelected?: boolean;
  highlightWord: number | false;
  hoverWord: number | false;
  searchResults: number[];
  activeSearchResult?: number;
}) => {
  const children: LeafChildType[] = text.trim().split(' ');
  if (children.length === 0) return null;

  const setChild = (index: number, options: Partial<LeafElement>) => {
    const word = children[index];
    if (!word) return;

    if (typeof word === 'string') {
      children.splice(index, 1, {text: word, ...options});
    } else {
      const currentHandles = word.handles ?? [];
      const newHandles = options.handles ?? [];

      children.splice(index, 1, {
        ...word,
        ...options,
        handles: [...currentHandles, ...newHandles],
      });
    }
  };

  if (firstSelected) {
    setChild(0, {handles: ['start']});
  }

  if (lastSelected) {
    setChild(children.length - 1, {handles: ['end']});
  }

  if (highlightWord !== false) {
    setChild(highlightWord, {highlighted: true});
  }

  if (hoverWord !== false) {
    setChild(hoverWord, {hovering: true});
  }

  for (const searchResult of searchResults) {
    setChild(searchResult, {searchResult: true});
  }

  if (activeSearchResult) {
    setChild(activeSearchResult, {activeSearchResult: true});
  }

  const flattenedChildren: LeafChildType[] = [];
  for (const child of children) {
    const lastChild = flattenedChildren[flattenedChildren.length - 1];

    if (typeof child === 'string' && typeof lastChild === 'string') {
      flattenedChildren[flattenedChildren.length - 1] = `${lastChild} ${child}`;
    } else {
      flattenedChildren.push(child);
    }
  }

  return flattenedChildren;
};

const highlightStyle = 'px-1 -mx-1 py-1.5 -my-1.5 rounded-md';

export const LeafChild = ({
  child,
  onDragHandleStart,
  showDragHandles,
  hoverStyle,
  toolbar,
}: {
  child: LeafElement;
  onDragHandleStart: (type: DragHandleType) => void;
  showDragHandles: boolean;
  hoverStyle: string;
  toolbar: ReactNode;
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    modifiers: [{name: 'offset', options: {offset: [0, 20]}}],
  });

  return (
    <span
      className={classNames(
        'relative',
        child.hovering && `${hoverStyle} ${highlightStyle}`,
        child.searchResult && `bg-yellow-100 ${highlightStyle}`,
        child.activeSearchResult && `bg-yellow-200 ${highlightStyle}`,
        child.highlighted && `bg-indigo-200 ${highlightStyle}`,
      )}
      data-type="leaf"
      data-highlighted="true"
      ref={setReferenceElement}
    >
      {child.text}
      {child.handles?.includes('start') && (
        <DragHandle
          onMouseDown={() => onDragHandleStart('start')}
          type="start"
          show={showDragHandles}
        />
      )}
      {child.handles?.includes('end') && (
        <DragHandle
          onMouseDown={() => onDragHandleStart('end')}
          type="end"
          show={showDragHandles}
        />
      )}
      {toolbar && (
        <div
          data-ignore="true"
          className="z-30 select-none whitespace-nowrap rounded-lg bg-black p-1 text-white"
          onMouseUp={e => e.stopPropagation()}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {toolbar}
        </div>
      )}
    </span>
  );
};

export const useHandleDrag = ({
  dragging,
  transcript,
}: {
  dragging: DragHandleType | null;
  transcript: TranscriptJSON;
}) => {
  const {selection, setSelection} = useSelection();

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!dragging) return;
      if (!selection) return;

      const word = getWordAtCoordinates(transcript, event);
      if (!word) return;

      if (dragging === 'start') {
        setSelection([word.start, selection.end]);
      } else {
        setSelection([selection.start, word.end]);
      }
    },
    [dragging, selection, setSelection, transcript],
  );

  const handleMouseMoveRef = useRef(handleMouseMove);
  handleMouseMoveRef.current = handleMouseMove;

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      handleMouseMoveRef.current(event);
    };

    document.addEventListener('mousemove', handler);
    return () => document.removeEventListener('mousemove', handler);
  }, []);
};
