import {FontSizeInputAndSelect} from 'features/EditorCanvas/Sidebar/views/LayerSettings/SidebarModules/FontSizeInputAndSelect';

const DEFAULT_FONT_SIZES = [9, 10, 11, 12, 13, 14, 18, 24, 36, 48, 64, 72, 96, 144, 288];

function FontSizePicker({
  updateFontSize,
  activeFontSize,
}: {
  updateFontSize: (fontSize: number) => void;
  activeFontSize: number;
}) {
  return (
    <div className="w-20">
      <FontSizeInputAndSelect
        value={activeFontSize}
        onChange={updateFontSize}
        options={DEFAULT_FONT_SIZES}
      />
    </div>
  );
}

export default FontSizePicker;
