import {ReactNotificationOptions, store} from 'react-notifications-component';
import {atom, useRecoilValue, useSetRecoilState} from 'recoil';
import {useEffect, useRef, useState} from 'react';
import {
  useSlateEvent,
  useSlateEventHandlers,
} from 'features/TranscriptEditor/SlateEvents';

import {LanguageId} from '../LanguageState';
import {NOTIFICATION_BASE} from 'features/Notifications/constants';
import NotificationError from 'features/Notifications/NotificationError';
import {TranscriptJSON} from 'features/Captions/Transcripts';
import {Z_INDEX_MODAL} from 'features/Common/constants';
import {replaceSelectedWords} from 'features/TranscriptEditor/transcriptUtils';
import {useSelectedTranscript} from 'features/TranscriptEditor/selection';

type TranscriptCorrectionModalProps = {
  userUploadId: string;
  language: LanguageId;
  transcript: TranscriptJSON;
  onChangeTranscript: (transcript: TranscriptJSON, editedText: string) => void;
};

export const TranscriptCorrectionModal = ({
  userUploadId,
  language,
  transcript,
  onChangeTranscript,
}: TranscriptCorrectionModalProps) => {
  const setOpen = useSetRecoilState(openState);
  const close = () => setOpen(false);

  const selectedTranscript = useSelectedTranscript(userUploadId, language);
  const [value, setValue] = useState(() => selectedTranscript?.text || '');

  const save = () => {
    if (!selectedTranscript) return;

    onChangeTranscript(
      replaceSelectedWords({
        fullTranscript: transcript,
        selectedTranscript,
        newText: value,
      }),
      value,
    );

    close();
  };

  const handlers = useSlateEventHandlers();

  useSlateEvent('keyup', event => {
    if (event.key === 'Escape') {
      close();
    } else if (event.key === 'Enter') {
      save();
    }
  });

  const input = useRef<HTMLInputElement>(null);
  useEffect(() => {
    input.current?.focus();
  }, []);

  return (
    <div
      className="fixed inset-0 overflow-y-auto bg-gray-500 bg-opacity-75"
      style={{zIndex: Z_INDEX_MODAL}}
    >
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        />
        <div
          className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          style={{zIndex: Z_INDEX_MODAL + 1}}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="mt-1 text-center">
              <div className="mt-2 text-left">
                <div>
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Correct transcript
                    </h3>
                    <p className="mt-4 text-sm text-gray-500">
                      For best results, minimize the number of words selected during
                      corrections.
                    </p>
                  </div>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <div className="mt-1 flex">
                        <input
                          ref={input}
                          className="block w-full rounded-md border border-indigo-500 px-4 pb-4 pt-4 shadow-sm ring-indigo-500 sm:text-sm"
                          {...handlers}
                          value={value}
                          onChange={event => {
                            setValue(event.currentTarget.value);
                          }}
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500">Change the text above.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              onClick={save}
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              Update text
            </button>
            <button
              onClick={close}
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const openState = atom({
  key: 'transcriptCorrectionModalOpen',
  default: false,
});

export const useCorrectionModalOpen = () => useRecoilValue(openState);

export const useOpenCorrectionModal = (userUploadId: string, language: LanguageId) => {
  const selectedTranscript = useSelectedTranscript(userUploadId, language);
  const setOpen = useSetRecoilState(openState);

  return () => {
    if (!selectedTranscript) return;

    const wordCount = selectedTranscript.transcript.flat(1).length;
    if (wordCount > 5) {
      announceDisabled({
        title: 'Too many words',
        message: 'Select 5 words or less to edit.',
      });
      return;
    }

    if (selectedTranscript.transcript.length > 1) {
      announceDisabled({
        title: 'Multiple paragraphs selected',
        message: 'Select words within the same paragraph to edit.',
      });
      return;
    }

    setOpen(true);
  };
};

const announceDisabled = ({title, message}: {title: string; message: string}) => {
  const notificationOptions: ReactNotificationOptions = {
    ...NOTIFICATION_BASE,
    content: <NotificationError title={title} message={message} />,
    type: 'danger',
    dismiss: {
      click: true,
      duration: 4000,
      onScreen: false,
    },
  };
  store.addNotification(notificationOptions);
};
