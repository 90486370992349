import {ReactNode, createContext, useEffect, useState} from 'react';

export const WindowContext =
  createContext<{
    windowIsActive: boolean;
  } | null>(null);

export const WindowContextProvider = ({children}: {children?: ReactNode}) => {
  const [windowIsActive, setWindowIsActive] = useState(true);

  const handleActivity = (forcedFlag: boolean) => {
    if (typeof forcedFlag === 'boolean') {
      return forcedFlag ? setWindowIsActive(true) : setWindowIsActive(false);
    }

    return document.hidden ? setWindowIsActive(false) : setWindowIsActive(true);
  };

  useEffect(() => {
    const handleActivityFalse = () => handleActivity(false);
    const handleActivityTrue = () => handleActivity(true);

    document.addEventListener('visibilitychange', handleActivityFalse);
    document.addEventListener('blur', handleActivityFalse);
    window.addEventListener('blur', handleActivityFalse);
    window.addEventListener('focus', handleActivityTrue);
    document.addEventListener('focus', handleActivityTrue);

    return () => {
      window.removeEventListener('blur', handleActivityFalse);
      document.removeEventListener('blur', handleActivityFalse);
      window.removeEventListener('focus', handleActivityFalse);
      document.removeEventListener('focus', handleActivityTrue);
      document.removeEventListener('visibilitychange', handleActivityTrue);
    };
  }, []);

  return (
    <WindowContext.Provider value={{windowIsActive}}>{children}</WindowContext.Provider>
  );
};
