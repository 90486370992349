import {JsonParam, NumberParam, StringParam, useQueryParam} from 'use-query-params';
import {StandaloneCanvas, StandaloneCanvasContainer} from './StandaloneCanvas';
import {createContext, useCallback, useLayoutEffect, useState} from 'react';

import {Time} from 'features/Common/Time';
import {ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {objMap} from 'features/Common/utils';
import {useDuration} from 'features/EditorCanvas/components/CanvasTime/useDuration';
import {useMemoOne} from 'use-memo-one';
import {useParams} from 'react-router-dom';
import {usePlayback} from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import {useScenes} from 'features/EditorCanvas/components/CanvasTime/useScenes';

type FrameContextType = {
  frame: number;
  fps: number;
  markItemLoaded: (forFrame: number) => void;
};

export const FrameContext = createContext<FrameContextType | null>(null);

export const FrameCanvasContainer = ({
  fps,
  initialFrame,
}: {
  fps: number;
  initialFrame: number;
}) => {
  const [requestedFrame, setRequestedFrame] = useState<number | null>(initialFrame);
  useLayoutEffect(() => {
    window.setFrame = (frame: number) => {
      setRequestedFrame(frame);
    };
  }, []);

  const [showingFrame, setShowingFrame] = useState<number | null>(null);
  useLayoutEffect(() => {
    window.showingFrame = showingFrame;
  }, [showingFrame]);

  const duration = useDuration();
  const requestedTime = new Time((requestedFrame ?? 0) / fps, 's');
  const requestedTimeMs = Math.min(requestedTime.ms, duration);

  const {seekTo, getCurrentTime} = usePlayback();
  useLayoutEffect(() => {
    seekTo({timeMs: requestedTimeMs});
  }, [requestedTimeMs, seekTo]);

  const {activeScene} = useScenes();

  const [loadedItemCounts, setLoadedItemCounts] = useState<Record<number, number>>({});

  /**
   * This does the dependency check for loading elements (ie. fonts, images, video, etc)
   *
   * Prevents race condition around frames being set to ready, then frame changing, and
   * frame capture taking place after frame changed before everything is actually ready.
   */
  const markItemLoaded = useCallback((forFrame: number) => {
    setLoadedItemCounts(counts => {
      const frameCount = counts[forFrame] ?? 0;
      return {...counts, [forFrame]: frameCount + 1};
    });
  }, []);

  const overrideDurations = useOverrideDurations();

  let isReady = false;

  const isSeekedToRequestedFrame = getCurrentTime() === requestedTimeMs;

  if (isSeekedToRequestedFrame && activeScene) {
    const itemsToLoad = Object.entries(activeScene.items).filter(([id, item]) => {
      if (overrideDurations[id] == null) return false;
      if (overrideDurations[id]?.frames === 0) return false;

      return item.viewType === ViewTypes.Video || item.viewType === ViewTypes.VideoClip;
    });

    if (itemsToLoad.length === 0) {
      isReady = true;
    } else if (requestedFrame != null && loadedItemCounts[requestedFrame] != null) {
      isReady = loadedItemCounts[requestedFrame] >= itemsToLoad.length;
    }
  }

  // console.log('isReady', isReady);

  useLayoutEffect(() => {
    if (isReady) {
      setShowingFrame(requestedFrame);
    } else {
      setShowingFrame(null);
    }
  }, [isReady, requestedFrame]);

  const [debug] = useQueryParam('debug', StringParam);

  if (requestedFrame === null) return null;

  return (
    <FrameContext.Provider value={{frame: requestedFrame, fps, markItemLoaded}}>
      <StandaloneCanvas />
      {debug === 'true' && (
        <div className="fixed inset-x-0 top-0 border-b-2 border-red-500 bg-white p-2 opacity-70">
          <span>requestedFrame: {requestedFrame}</span>,&nbsp;
          <span>requestedTime: {requestedTimeMs}</span>,&nbsp;
          <span>showingFrame: {showingFrame}</span>,&nbsp;
        </div>
      )}
    </FrameContext.Provider>
  );
};

export function FramePage() {
  const {projectId} = useParams<{projectId: string}>();
  const [fps] = useQueryParam('fps', NumberParam);
  if (fps == null) throw new Error('Query param missing: fps');

  const [initialFrame = null] = useQueryParam('initialFrame', NumberParam);

  return (
    <StandaloneCanvasContainer projectId={projectId!}>
      <FrameCanvasContainer fps={fps!} initialFrame={initialFrame!} />
    </StandaloneCanvasContainer>
  );
}

const EMPTY_OBJECT = {};

export type ItemFrameCounts = Record<string, number | null>;

export type OverrideDurations = Record<string, {frames: number; duration: Time} | null>;

export const useOverrideDurations = (): OverrideDurations => {
  const [fps] = useQueryParam('fps', NumberParam);
  const [overrideDurations] = useQueryParam<ItemFrameCounts>(
    'itemFrameCounts',
    JsonParam,
  );

  return useMemoOne(() => {
    if (fps == null || overrideDurations == null) {
      return EMPTY_OBJECT;
    }

    return objMap(overrideDurations, frames => {
      if (frames == null) return null;
      return {frames, duration: new Time(frames / fps, 's')};
    });
  }, [fps, overrideDurations]);
};
