import {Editable, Slate, withReact} from 'slate-react';
import {Node, createEditor} from 'slate';
import {useEffect, useRef} from 'react';
import {DimensionType} from 'features/types/canvasItemsSlice';
import {ItemLayerTextContainerProps} from './ItemLayerTextContainer';
import {LayerText} from '../../shared/layers';
import {useMemoOne} from 'use-memo-one';
import {useOnResize} from 'features/Common/useElementSize';

const deserialise = (text: string) => {
  return text != null
    ? text.split('\n').map(line => ({type: 'paragraph', children: [{text: line}]}))
    : [];
};

const serialise = (nodes: Node[]) => {
  return nodes.map(n => Node.string(n)).join('\n');
};

export type ItemLayerTextProps = {
  initialText: string;
  onChangeText: (text: string) => void;
  onChangeSize: (size: DimensionType) => void;
  editModeText?: boolean;
  isPreview?: boolean;
} & Pick<ItemLayerTextContainerProps, 'style'>;

export default function ItemLayerText({
  initialText,
  onChangeText,
  onChangeSize,
  style,
  editModeText = false,
  isPreview,
}: ItemLayerTextProps) {
  const editor = useMemoOne(() => withReact(createEditor()), []);

  const value = deserialise(initialText);

  const handleChange = (nodes: Node[]) => {
    onChangeText(serialise(nodes));
  };

  const textRef = useRef<HTMLDivElement>(null);
  useOnResize(textRef, size => {
    const height = Math.round(size.height);
    let width = Math.round(size.width);
    if (width === 0) width = height;

    onChangeSize({width: Math.ceil(width), height: Math.ceil(height)});
  });

  const userSelect = editModeText ? 'text' : 'none';
  useEffect(() => {
    if (isPreview) return;

    if (editModeText === false) {
      window.getSelection()?.removeAllRanges();
    }
  }, [editModeText, isPreview]);

  return (
    <LayerText ref={textRef} style={{...style, userSelect}}>
      <div style={{padding: '0 8px'}}>
        <Slate editor={editor} value={value} onChange={handleChange}>
          <Editable />
        </Slate>
      </div>
    </LayerText>
  );
}
