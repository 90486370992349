import {ReactNode} from 'react';

export default function CanvasWrap({children}: {children: ReactNode}) {
  return (
    <div className="file-drop flex h-screen w-screen flex-row overflow-hidden bg-gray-50">
      <div className="flex min-w-0 flex-grow  bg-gray-50">
        <div className="webkit-fill-max-height flex h-screen w-full flex-col">
          <div className="flex h-full flex-1 flex-col overflow-hidden bg-gray-50">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
