import UploadMediaClipPreview from './PreviewContent/UploadMediaClipPreview';
import UserProjectDownloadPreview from './PreviewContent/UserProjectDownloadPreview';
import UserUploadPreview from './PreviewContent/UserUploadPreview';
import {convertToMs} from 'features/EditorCanvas/utils';
import humanizeDuration from 'humanize-duration';

const getDestinationDimensions = (destination: string) => {
  if (destination === 'twitter') {
    return {
      width: 1200,
      height: 630,
    };
  }
  if (destination === 'facebook') {
    return {
      width: 1200,
      height: 630,
    };
  }

  return {
    width: 1200,
    height: 630,
  };
};

type UserUploadAssetObj = {
  file_name: string;
  file_type: string;
  file_size: string;
  source: string;
  upload_url: string;
  last_modified: null | string;
  upload_id: string;
  bucket_key: string;
  created_at: number;
  updated_at: number;
  owner_id: string;
  discarded_at: null | string;
  bucket_file_name: string;
  extension: string;
  account_id: string;
  has_transcript: boolean;
  has_audio_analysis: boolean;
  id: string;
  ahoy_visit_id: number;
  max_width: number;
  max_height: number;
  duration: number;
};

type UploadMediaClipAssetObj = {
  media_type: string;
  user_upload_id: string;
  start_time: string;
  end_time: string;
  owner_id: string;
  created_at: number;
  updated_at: number;
  discarded_at: null;
  text_snippet: string;
  tag_list: any[];
};

type AssetClipRenderStatus = {
  render_status: string;
  error: null;
  success: boolean;
};

export type UploadMediaClipAsset = {
  media_type: string;
  user_upload_id: string;
  start_time: string;
  end_time: string;
  owner_id: string;
  created_at: number;
  updated_at: number;
  text_snippet: string;
  thumbnail_url: string;
  account_id: string;
  id: string;
  clip_name: string;
  discarded_at: null;
  ahoy_visit_id: string | null;
  tag_list: any[];
};

export type UserUploadAsset = {
  file_name: string;
  file_type: string;
  file_size: string;
  source: string;
  upload_url: string;
  last_modified: null | string;
  upload_id: string;
  bucket_key: string;
  created_at: number;
  updated_at: number;
  owner_id: string;
  discarded_at: null | string;
  bucket_file_name: string;
  extension: string;
  account_id: string;
  has_transcript: boolean;
  id: string;
  ahoy_visit_id: number;
  max_width: number;
  max_height: number;
  duration: number;
};

export type UserProjectDownloadAsset = {
  is_loading: boolean;
  is_error: boolean;
  created_at: number;
  duration_ms: number;
  id: string;
  mux_playback_id: string | null;
  mux_status: string | null;
  project_slug: string;
  status: string;
  name: string;
  updated_at: number;
  url: string;
  user_project_sync_version_id: string;
};

export type AssetTypeTypes = 'UserUpload' | 'UploadMediaClip' | 'UserProjectDownload';

export type AssetClipResponse = {
  start_time: string;
  end_time: string;
  text_snippet: string;
  thumbnail_url: string;
  asset_created_at: string;
  asset_updated_at: string;
  user_name: string;
  user_avatar: string;
  asset_type: AssetTypeTypes;
  asset_obj: UploadMediaClipAssetObj | UserUploadAssetObj | UserProjectDownloadAsset;
  asset: UploadMediaClipAsset | UserUploadAsset | UserProjectDownloadAsset;
  embed_url: string;
  account_name: string;
  mux_playback_id: string;
  mux_url: string;
  render_status: AssetClipRenderStatus;
  mux_thumbnail: string;
  mux_source: string;
};

export default function SocialCardPreview({
  contentId,
  responseData,
  destination = 'facebook',
}: {
  contentId: string;
  responseData: AssetClipResponse;
  destination: string;
}) {
  const {
    asset_created_at: assetCreatedAt,
    user_name: userName,
    user_avatar: userAvatar,
    account_name: accountName,
    text_snippet: textSnippet,
    asset_type: assetType,
    start_time: startTime,
    end_time: endTime,
    asset,
    mux_playback_id: muxPlaybackId,
  } = responseData;
  const durationSeconds = Number(endTime) - Number(startTime);
  const middleClipTimeInSeconds = Number(startTime) + durationSeconds / 2;
  const humanizedDuration = humanizeDuration(convertToMs(durationSeconds), {
    round: true,
  });

  const dimension = getDestinationDimensions(destination);
  const clipLengthWidth = 100;
  const clipOffsetTranslate = 'translateX(400px)';
  const viewCount = '100';

  const accountUrl = 'milkvideo.com';

  return (
    <div
      id="preview-render-font-card"
      className="flex w-full  flex-col justify-between overflow-hidden bg-white pt-4"
      style={{
        width: `${dimension.width}px`,
        height: `${dimension.height}px`,
      }}
    >
      {asset && assetType === 'UserUpload' && (
        <UserUploadPreview
          accountName={accountName}
          muxPlaybackId={muxPlaybackId}
          assetType={assetType}
          asset={asset as UserUploadAsset}
          humanizedDuration={humanizedDuration}
          textSnippet={textSnippet}
          userAvatar={userAvatar}
          userName={userName}
          assetCreatedAt={assetCreatedAt}
        />
      )}
      {asset && assetType === 'UserProjectDownload' && (
        <UserProjectDownloadPreview
          accountName={accountName}
          muxPlaybackId={muxPlaybackId}
          assetType={assetType}
          asset={asset as UserProjectDownloadAsset}
          humanizedDuration={humanizedDuration}
          textSnippet={textSnippet}
          userAvatar={userAvatar}
          userName={userName}
          assetCreatedAt={assetCreatedAt}
        />
      )}
      {asset && assetType === 'UploadMediaClip' && (
        <UploadMediaClipPreview
          accountName={accountName}
          muxPlaybackId={muxPlaybackId}
          assetType={assetType}
          asset={asset as UploadMediaClipAsset}
          humanizedDuration={humanizedDuration}
          textSnippet={textSnippet}
          userAvatar={userAvatar}
          userName={userName}
          assetCreatedAt={assetCreatedAt}
        />
      )}
    </div>
  );
}
