import {Dispatch, SetStateAction} from 'react';
import {Modal, ModalButtons, ModalDescription} from 'ui/Modal';

import {UserUploadsType} from 'features/types/userLibrarySlice';
import {Video} from 'react-feather';

export const UploadsListModals = ({
  showStartClippingUploadModal,
  openUpload,
  modalFile,
  setModalFile,
}: {
  showStartClippingUploadModal: boolean;
  openUpload: (userUpload: UserUploadsType) => void;
  modalFile: UserUploadsType | null;
  setModalFile: Dispatch<SetStateAction<UserUploadsType | null>>;
}) => {
  return (
    <>
      <Modal
        title="Upload successful"
        open={showStartClippingUploadModal}
        onClose={() => setModalFile(null)}
        size="small"
      >
        <ModalDescription>
          Would you like to open the clip&lsquo;s transcript to start creating clips?
        </ModalDescription>
        <div className="flex items-center space-x-3 break-all rounded-lg bg-gray-100 py-3 px-4 text-gray-700">
          <Video />
          <div>{modalFile?.file_name}</div>
        </div>
        <ModalButtons
          confirm={{
            label: 'Yes, start clipping',
            onClick: () => {
              if (!modalFile) return;
              openUpload(modalFile);
            },
          }}
          cancel={{label: 'No, close'}}
        />
      </Modal>
    </>
  );
};
