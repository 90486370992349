import {DashParams, routePaths} from 'routes/routesHelper';
import {FC, useState} from 'react';
import {
  Facebook,
  Instagram,
  Linkedin,
  Tiktok,
  Twitter,
} from '@icons-pack/react-simple-icons';
import {resizeCanvas, resizeProject} from 'features/canvasItemsSlice';
import {useDispatch, useSelector} from 'react-redux';

import {Button} from 'ui/Button';
import {DimensionType} from 'features/types/canvasItemsSlice';
import {GetRootState} from 'configureStore';
import {TextInput} from 'ui/TextInput';
import {accountDetailsSelector} from 'features/selectors/authSelectors';
import {getAuthToken} from 'services/utils';
import {postClientStatus} from 'api/projectClientSyncAPI';
import {submitDuplicateProjectSuccess} from 'features/userLibrarySlice';
import {submitProjectDuplicate} from 'api/projectsAPI';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

const sizes = [
  {
    label: 'Landscape',
    dimensions: {width: 1920, height: 1080},
    idealFor: [
      {icon: Facebook, label: 'Facebook post'},
      {icon: Linkedin, label: 'Linkedin post'},
    ],
  },
  {
    label: 'Portrait',
    dimensions: {width: 1080, height: 1920},
    idealFor: [
      {icon: Instagram, label: 'Instagram story'},
      {icon: Tiktok, label: 'TikTok'},
    ],
  },
  {
    label: 'Square',
    dimensions: {width: 1080, height: 1080},
    idealFor: [
      {icon: Instagram, label: 'Instagram post'},
      {icon: Twitter, label: 'Twitter post'},
    ],
  },
];

export function CanvasResizeMenu() {
  const projectId = useProjectId();
  const dispatch = useDispatch();
  const {user} = useSelector(accountDetailsSelector);

  const duplicateAndResize = async (dimensions: DimensionType) => {
    const token = await getAuthToken();
    const {userProject, latestProjectSync} = await submitProjectDuplicate(
      token,
      projectId,
    );

    dispatch(submitDuplicateProjectSuccess({userProject}));

    const {items} = resizeCanvas({
      dimensions,
      currentDimensions: latestProjectSync.client_state.sceneFrame.canvasDimensions,
      items: latestProjectSync.client_state.items,
    });

    const clientState = {
      items,
      sceneFrame: {
        ...latestProjectSync.client_state.sceneFrame,
        canvasDimensions: dimensions,
      },
    };

    const ownerId = user?.id ? user.id : 'anonoymous';

    await postClientStatus(
      token,
      userProject.slug,
      ownerId,
      clientState,
      latestProjectSync.client_sync_version,
    );

    window.open(
      `/${routePaths.create}/${DashParams.CanvasParam}/${userProject.slug}`,
      '_blank',
    );
  };

  const projectDimensions = useSelector((state: GetRootState) => {
    return state.sceneFrame.projects[projectId].canvasDimensions;
  });

  const projectAspectRatio = projectDimensions.width / projectDimensions.height;

  const [customDimensions, setCustomDimensions] = useState(projectDimensions);
  const customDimensionsValid = customDimensions.width > 0 && customDimensions.height > 0;

  return (
    <div>
      <div className="border-b p-5">
        <div className="text-lg font-medium">Resize</div>
        <div className="text-sm text-gray-700">Choose a size for your project</div>
      </div>
      <div className="divide-y">
        {sizes.map(size => {
          const aspectRatio = size.dimensions.width / size.dimensions.height;
          if (aspectRatio === projectAspectRatio) return null;

          return (
            <Size label={size.label} dimensions={size.dimensions} key={size.label}>
              <div className="space-y-1.5 text-gray-700">
                <div className="text-sm font-medium">Ideal for:</div>
                {size.idealFor.map(idealFor => (
                  <div
                    className="flex items-center space-x-2 text-sm"
                    key={idealFor.label}
                  >
                    <idealFor.icon width={16} height={16} />
                    <div>{idealFor.label}</div>
                  </div>
                ))}
              </div>
              <Button
                variant="secondary"
                fullWidth
                size="small"
                onClick={() => duplicateAndResize(size.dimensions)}
              >
                Copy & resize
              </Button>
            </Size>
          );
        })}
        <Size label="Custom">
          <div className="flex items-baseline">
            <NumberInput
              name="width"
              value={customDimensions.width}
              onChange={width => {
                setCustomDimensions({...customDimensions, width});
              }}
            />
            <div className="mx-3">x</div>
            <NumberInput
              name="height"
              value={customDimensions.height}
              onChange={height => {
                setCustomDimensions({...customDimensions, height});
              }}
            />
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="tertiary"
              size="small"
              onClick={() =>
                dispatch(resizeProject({projectId, dimensions: customDimensions}))
              }
              _className="flex-1"
              disabled={!customDimensionsValid}
            >
              Resize
            </Button>
            <Button
              variant="secondary"
              size="small"
              onClick={() => duplicateAndResize(customDimensions)}
              disabled={!customDimensionsValid}
            >
              Copy & resize
            </Button>
          </div>
        </Size>
      </div>
    </div>
  );
}

const NumberInput = ({
  name,
  value,
  onChange,
}: {
  name: string;
  value: number;
  onChange: (value: number) => void;
}) => {
  return (
    <TextInput
      name={name}
      value={value === 0 ? '' : value.toString()}
      onChange={value => {
        if (!value) {
          onChange(0);
          return;
        }

        const valueInt = parseInt(value, 10);
        if (!isNaN(valueInt)) onChange(valueInt);
      }}
    />
  );
};

const Size: FC<{label: string; dimensions?: DimensionType}> = ({
  label,
  dimensions,
  children,
}) => {
  return (
    <div key={label} className="space-y-4 p-5">
      <div className="-mb-1 flex items-baseline justify-between">
        <div className="font-medium">{label}</div>
        {dimensions && (
          <div className="text-xs text-gray-500">
            {dimensions.width}x{dimensions.height}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

// const getBoundingBox = (items: Record<string, CanvasItem>) => {
//   const itemTops = Object.values(items).map(item => item.position.top);
//   const itemLefts = Object.values(items).map(item => item.position.left);
//   const itemBottoms = Object.values(items).map(
//     item => item.position.top + item.dimension.height,
//   );
//   const itemRights = Object.values(items).map(
//     item => item.position.left + item.dimension.width,
//   );

//   return {
//     dimension: {
//       width: Math.max(...itemRights),
//       height: Math.max(...itemBottoms),
//     },
//     position: {
//       top: Math.min(...itemTops),
//       left: Math.min(...itemLefts),
//     },
//   };
// };
