import LoadingMilk from './LoadingMilk';
import cn from 'classnames';
import {routeHome} from 'routes/routesHelper';
import {useState} from 'react';

export default function LoadingPage({
  title = 'Launching video canvas',
  explainer = 'Please wait while the canvas loads.',
}: {
  title?: string;
  explainer?: string;
}) {
  const [isReady, setIsReady] = useState(false);
  return (
    <div className="flex min-h-screen flex-col items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
      <div className="align-center flex flex-col items-center justify-center space-y-5 text-center">
        <div
          className={cn({
            hidden: !isReady,
          })}
        >
          <div>
            <LoadingMilk imageCallback={setIsReady} />
          </div>
          <h1 className="mt-4 text-3xl font-bold text-gray-800">{title}</h1>
          <p className="mt-4 max-w-md text-gray-600">
            {explainer} <br />
            Not working? Click{' '}
            <a href={`${window.location.origin}${routeHome}`}>
              <span className="font-semibold text-indigo-500">here</span>
            </a>{' '}
            to go back home.
          </p>
        </div>
      </div>
    </div>
  );
}
