import {AccountFontUploadsType} from 'features/types/userLibrarySlice';
import {extensionMap} from 'features/FontPreviews/FontPreviewer';
import {Helmet} from 'react-helmet';

export function FontFamily({
  accountFontUpload,
}: {
  accountFontUpload: AccountFontUploadsType;
}) {
  const {upload_url: uploadUrl, font_fullname: fontFamily} = accountFontUpload;
  const urlList = uploadUrl.split('.');
  const extension = urlList[urlList.length - 1];
  const secureUploadUrl = uploadUrl.replace('http:', 'https:');
  const cssString = `
    @font-face {
      font-family: '${fontFamily}';
      src: url('${secureUploadUrl}') format('${extensionMap[extension]}');
    }
  `;

  return (
    <Helmet>
      <link href={`${secureUploadUrl}`} rel="stylesheet" />
      <style>{cssString}</style>
    </Helmet>
  );
}
