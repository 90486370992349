import {DashParams, routeHome, routePaths} from 'routes/routesHelper';
import {Navigate, useParams} from 'react-router-dom';
import {isImageMedia, isPlayableMedia} from './utils';

import DetailTypeImage from './DetailTypeImage';
import LoadingDashboard from 'pages/Dashboard/LoadingDashboard';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import {useUserUploadQuery} from 'services/userUploadAPI';

type ParamsType = {
  userUploadId: string;
};

export default function DashboardUploadDetails() {
  const {userUploadId} = useParams<ParamsType>();

  const {
    data: userUpload,
    isLoading,
    isFetching,
    // @ts-ignore
  } = useUserUploadQuery({id: userUploadId}!);

  if (isLoading) {
    return (
      <div className="mx-auto flex w-full items-center justify-center text-gray-200">
        <LoadingDashboard />
      </div>
    );
  }
  const fileType = userUpload && userUpload.file_type;

  if (!fileType) {
    return <Navigate to={routeHome} />;
  }

  if (isPlayableMedia(fileType)) {
    return (
      <Navigate
        to={`/${routePaths.dashboard}/${DashParams.UploadsParam}/${DashParams.UploadsMediaParam}/${userUpload.id}`}
      />
    );
  }
  if (isImageMedia(fileType)) {
    return <DetailTypeImage userUpload={userUpload} />;
  }
  return <Navigate to={routeHome} />;
}
