// Section First Campaign

import { Button, ButtonVariant } from 'ui/Button';
import { DashParams, routePaths } from 'routes/routesHelper';
import { Link, useNavigate } from 'react-router-dom';

import { CheckCircle } from 'react-feather';

export const SectionFirstCampaignHeader = () => {
  return (
    <>
      <div className="pb-2 md:flex md:items-center md:justify-between ">
        <div className="min-w-0 flex-1">
          <h2 className="text-normal font-semibold leading-7 text-gray-700 sm:truncate sm:text-2xl">
            {"Choose how you'd like to start"}
          </h2>
        </div>
      </div>
    </>
  );
};

export type SectionCampaignCardContent = {
  imageUrl: string;
  title: string;
  description: string;
  cta: string;
  ctaVariant: ButtonVariant;
  action?: (evt: any) => void;
};

export const SectionCampaignCard = ({ card }: { card: SectionCampaignCardContent }) => {
  return (
    <div className="col-span-1 flex flex-col overflow-hidden rounded-md bg-white">
      <div className="flex-shrink-0">
        <div className="aspect-w-4 aspect-h-3 overflow-hidden  rounded-md">
          <img className="object-cover object-center" src={card.imageUrl} />
        </div>
      </div>
      <div className="space-y-3 py-4 px-5 pb-6 text-center">
        <h3 className="text-lg text-gray-700">{card.title}</h3>
        <p className="text-sm text-gray-600">{card.description}</p>
        <Button
          variant={card.ctaVariant}
          onClick={evt => {
            if (card?.action) {
              card.action(evt);
            }
          }}
          size="small"
        >
          {card.cta}
        </Button>
      </div>
    </div>
  );
};

export const SectionFirstCampaign = () => {

  const navigate = useNavigate();
  return (
    <div className="space-y-3">
      <SectionFirstCampaignHeader />
      <div className="grid grid-cols-1 gap-x-3 gap-y-4 sm:grid-cols-2 md:grid-cols-3">
        <SectionCampaignCard
          card={{
            imageUrl: 'https://assets-milk.s3.amazonaws.com/share/video-testimonial.jpg',
            title: 'Collect your first testimonial',
            description:
              'Capture testimonials, leadership updates, or employee feedback with a single link.',
            cta: 'Collect video',
            ctaVariant: 'primary',
            action: (evt: any) => {
              evt.preventDefault();
              navigate(`/${routePaths.dashboard}/${DashParams.RecordingFormParams}`);
              // navigate(`/${routePaths.dashboard}/${DashParams.CreateCampaign}`);
              window.analytics.track('Dashboard click - testimonial request');
            },
          }}
        />
        <SectionCampaignCard
          card={{
            imageUrl: 'https://assets-milk.s3.amazonaws.com/share/video-maker.jpg',
            title: 'Edit existing videos',
            description:
              'Repurpose your quality webinar or event recording into a video ad.',
            cta: 'Edit video',
            ctaVariant: 'primary',
            action: (evt: any) => {
              evt.preventDefault();
              navigate(`/${routePaths.dashboard}/${DashParams.UploadsParam}`);
              // navigate(`/${routePaths.dashboard}/${DashParams.CreateCampaign}`);
              window.analytics.track('Dashboard click - video ad');
            },
          }}
        />

        <SectionCampaignCard
          card={{
            imageUrl: 'https://assets-milk.s3.amazonaws.com/share/video-page.jpg',
            title: 'Create a video landing page',
            description:
              'Put your best videos on a landing page to drive sign ups and meeting bookings.',
            cta: 'Request access',
            ctaVariant: 'tertiary',
            action: (evt: any) => {
              evt.preventDefault();
              window.analytics.track('Dashboard click - video landing page');
              !!window.Intercom &&
                window.Intercom(
                  'showNewMessage',
                  'I would like video landing page access',
                );
            },
          }}
        />
      </div>
      {/* <div className="flex items-center py-6">
        <p className="w-full text-center text-xs">
          Have something else in mind?{' '}
          <Link
            className="text-indigo-600 underline"
            to={`/${routePaths.dashboard}/${DashParams.CreateCampaign}`}
          >
            Here are some other things you can create
          </Link>
        </p>
      </div> */}
    </div>
  );
};
