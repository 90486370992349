import axios from 'axios';
import {BASE_URL} from 'constants/environment';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import FontPreviewer from 'features/FontPreviews/FontPreviewer';
import {ExternalFontUploadsType} from 'features/types/userLibrarySlice';
import {useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';

export function ExternalFontNamePreviewPage({displayText}: {displayText?: string}) {
  const {externalFontId} = useParams() as Record<string, string>;
  const [font, setFont] = useState<null | ExternalFontUploadsType>(null);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${BASE_URL}/api/v1/external_fonts/${externalFontId}`,
      );
      // console.log('response', response);
      setFont(response.data);
    })();
  }, []);

  // console.log('font', font);

  if (!font) {
    return <LoadingSpinner />;
  } else {
    const uploadUrl = `${font.url}`.replace('http:', 'https:');

    return (
      <>
        <FontPreviewer
          uploadUrl={uploadUrl}
          fontFamily={font.family}
          displayText={displayText ? displayText : `${font.family}`}
        />
      </>
    );
  }
}
