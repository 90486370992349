import {StytchClient, initStytch} from '@stytch/stytch-react';

import {BaseEditor} from 'slate';
import {Font} from 'opentype.js';
import {HistoryEditor} from 'slate-history';
import {ReactEditor} from 'slate-react';

// @ts-ignore
window.global = window;

// Fix for packages that assume existence of process.env 🙄
// @ts-ignore
window.process = {
  env: {},
};
declare global {
  interface Window {
    openFontData: Record<string, Font>;
    Intercom: any;
    triggerRenderer: boolean;
    Cypress: any;
    callObject: any;
    todesktop: any;
    ahoy: any;
    showingFrame?: number | null;
    setFrame?: (frame: number) => void;
    readyForScreenshot?: boolean;
    stytch: StytchClient;
  }
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
  }
}

export {};
