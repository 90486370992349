import {
  CanvasItem,
  ClipPath,
  StoredCrop,
  DimensionType,
  PercentClipPath,
} from 'features/types/canvasItemsSlice';
import {
  getCroppedBox,
  getDefaultCrop,
} from './components/AppCanvas/Canvas/utils/useEditCrop';

import {UnreachableCaseError} from 'features/Common/UnreachableCaseError';
import produce from 'immer';

const percentToFloat = (percent: string) => {
  return parseFloat(percent) / 100;
};

export const migrateItem = (unmigratedItem: CanvasItem) => {
  const items = migrateClipPaths({testItem: unmigratedItem});
  return items.testItem;
};

function isPercentClipPath(clipPath: ClipPath): clipPath is PercentClipPath {
  if (clipPath.type === 'rectangle') {
    return typeof clipPath.top === 'string';
  } else if (clipPath.type === 'circle') {
    return typeof clipPath.radius === 'string';
  } else {
    throw new UnreachableCaseError(clipPath);
  }
}

const toPercentClipPath = (
  clipPath: ClipPath,
  dimension: DimensionType,
): PercentClipPath => {
  if (isPercentClipPath(clipPath)) return clipPath;

  if (clipPath.type === 'rectangle') {
    return {
      type: 'rectangle',
      top: `${(clipPath.top / dimension.height) * 100}%`,
      bottom: `${(clipPath.bottom / dimension.height) * 100}%`,
      left: `${(clipPath.left / dimension.width) * 100}%`,
      right: `${(clipPath.right / dimension.width) * 100}%`,
    };
  } else if (clipPath.type === 'circle') {
    const avgDimension =
      Math.sqrt(dimension.width ** 2 + dimension.height ** 2) / Math.sqrt(2);

    return {
      type: 'circle',
      centerX: `${(clipPath.centerX / dimension.width) * 100}%`,
      centerY: `${(clipPath.centerY / dimension.height) * 100}%`,
      radius: `${(clipPath.radius / avgDimension) * 100}%`,
    };
  } else {
    throw new UnreachableCaseError(clipPath);
  }
};

const clipPathToCrop = (clipPath: ClipPath, dimension: DimensionType) => {
  let crop: StoredCrop;

  const percentClipPath = toPercentClipPath(clipPath, dimension);

  if (percentClipPath.type === 'rectangle') {
    crop = {
      type: 'rectangle',
      top: percentToFloat(percentClipPath.top),
      left: percentToFloat(percentClipPath.left),
      right: percentToFloat(percentClipPath.right),
      bottom: percentToFloat(percentClipPath.bottom),
    };
  } else if (percentClipPath.type === 'circle') {
    crop = {
      type: 'circle',
      radius: percentToFloat(percentClipPath.radius),
      centerX: percentToFloat(percentClipPath.centerX),
      centerY: percentToFloat(percentClipPath.centerY),
      originalAspectRatio: dimension.width / dimension.height,
    };
  } else {
    throw new UnreachableCaseError(percentClipPath);
  }

  return crop;
};

export const migrateClipPaths = (unmigratedItems: Record<string, CanvasItem>) => {
  return produce(unmigratedItems, draftItems => {
    for (const item of Object.values(draftItems)) {
      if (item.crop) continue;

      if (item.clipPath) {
        const crop = clipPathToCrop(item.clipPath, item.dimension);

        const {position, dimension} = getCroppedBox({
          currentCrop: {type: 'rectangle', top: 0, left: 0, right: 0, bottom: 0},
          croppedDimension: item.dimension,
          croppedPosition: item.position,
          newCrop: crop,
        });

        item.crop = crop;
        item.dimension = dimension;
        item.position = position;
        item.clipPath = undefined;
      } else {
        item.crop = getDefaultCrop('rectangle', item.dimension);
      }
    }
  });
};
