import {useEffect, useRef, useState} from 'react';

import LoadingSpinner from '../../shared/LoadingSpinner';
import cn from 'classnames';

export default function ItemDetailVideo({item}) {
  const playbackId = item.mux_playback_id;

  const height = 140;
  const width = 300;

  return (
    <div className="relative flex items-center justify-center px-2 py-1">
      {!playbackId && (
        <div className="absolute">
          <LoadingSpinner />
        </div>
      )}
      {!playbackId && (
        <span className="absolute bottom-2 right-2 inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-800">
          {'Loading'}
        </span>
      )}
      <div
        className={cn('relative', {
          'opacity-0': !playbackId,
        })}
        style={{width: `${width}px`, height: `${height}px`}}
      >
        <img
          src={`https://image.mux.com/${playbackId}/thumbnail.png?width=${width}&height=${height}&smart_crop=true`}
        />
        <span className="absolute bottom-2 right-2 inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-800">
          {'Video'}
        </span>
      </div>
    </div>
  );
}
