import {
  AlignDirection,
  AlignType,
} from './Sidebar/views/LayerSettings/SidebarModules/AlignPicker';
import {
  BASE_LAYER_ITEM_STYLE,
  LayerItemStyleType,
  SAMPLE_CLIP,
  SAMPLE_CLIP_CAPTION_ITEM,
} from './constants/ItemConstants';
import {CanvasItem, DimensionType} from 'features/types/canvasItemsSlice';
import {CanvasLayerDepedency, setDependencyReady} from 'features/canvasStateSlice';
import {ItemLayerSources, ViewTypes} from 'features/EditorCanvas/constants/ViewConstants';
import {
  TimeMilliSeconds,
  TimeSeconds,
  UserUploadsType,
} from 'features/types/userLibrarySlice';

import {RecentUsage} from 'services/recentUsageAPI';
import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import {objForEach} from 'features/Common/utils';
import produce from 'immer';
import {useDispatch} from 'react-redux';
import {useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';

export type ItemLoading = {
  itemId: string;
  viewType: ViewTypes;
  loading?: CanvasLayerDepedency[];
};

export type ItemsLoading = Record<string, ItemLoading>;

export const isItemLoading = (itemLoading: ItemLoading) => {
  if (!itemLoading?.loading) return false;
  return itemLoading.loading.length > 0;
};

export const getPendingItems = (items: ItemsLoading) => {
  return Object.values(items).filter(itemLoading => isItemLoading(itemLoading));
};

export const allItemsLoaded = (items: ItemsLoading) => {
  return !Object.values(items).some(itemLoading => isItemLoading(itemLoading));
};

export const convertToMs = (duration: TimeSeconds): TimeMilliSeconds => {
  return (duration * 1000) as TimeMilliSeconds;
};

export const convertToSec = (
  durationMs: TimeMilliSeconds,
  decimalPlaces?: number,
): TimeSeconds => {
  return (durationMs / 1000) as TimeSeconds;
};

export const convertToSecRounded = (
  durationMs: TimeMilliSeconds,
  decimalPlaces?: number,
): string => {
  if (decimalPlaces === 2) {
    return `${Math.round((durationMs / 1000 + Number.EPSILON) * 100) / 100}`;
  } else if (decimalPlaces === 1) {
    return Number(
      `${Math.round((durationMs / 1000 + Number.EPSILON) * 10) / 10}`,
    ).toFixed(1);
  } else {
    return `${durationMs / 1000}`;
  }
};

export const getVideoDimensions = ({
  currentDimensions,
  userUpload,
  fitWithin,
}: {
  currentDimensions: DimensionType;
  userUpload: UserUploadsType;
  fitWithin?: DimensionType;
}) => {
  if (userUpload.max_width == null || userUpload.max_height == null) {
    return currentDimensions;
  }

  if (fitWithin) {
    const maxDimension = {width: userUpload.max_width, height: userUpload.max_height};
    return getFitSize(maxDimension, fitWithin);
  } else {
    return {width: userUpload.max_width, height: userUpload.max_height};
  }
};

export const getFitSize = (maxSize: DimensionType, canvasDimensions: DimensionType) => {
  const aspectRatio = maxSize.width / maxSize.height;

  let width = maxSize.width;
  let height = maxSize.height;

  if (width > canvasDimensions.width) {
    width = canvasDimensions.width;
    height = width / aspectRatio;
  }

  if (height > canvasDimensions.height) {
    height = canvasDimensions.height;
    width = height * aspectRatio;
  }

  return {width, height};
};

export function getItemsForLayout({
  activeClip,
  userUpload,
  sceneId,
}: {
  activeClip: UploadMediaClipType;
  userUpload: UserUploadsType;
  sceneId: string;
}): Record<string, CanvasItem> {
  const videoLayerId = uuidv4();
  const captionLayerId = uuidv4();
  const playLength = parseFloat(activeClip.end_time) - parseFloat(activeClip.start_time);
  const newItems = {} as Record<string, CanvasItem>;

  newItems[videoLayerId] = {
    ...SAMPLE_CLIP,
    sceneId,
    layerName: 'Video layer 1',
    playLengthSeconds: Number(playLength),
    timeOffsetSeconds: parseFloat(activeClip.start_time),
    playbackId: userUpload.mux_playback_id,
    itemSourceId: activeClip.id,
    itemSource: ItemLayerSources.Clips,
  } as CanvasItem;

  newItems[videoLayerId].dimension = getVideoDimensions({
    userUpload,
    currentDimensions: newItems[videoLayerId].dimension,
  });

  newItems[captionLayerId] = {
    ...SAMPLE_CLIP_CAPTION_ITEM,
    sceneId,
    layerName: 'Caption layer 2',
    timeOffsetSeconds: parseFloat(activeClip.start_time),
    playLengthSeconds: playLength,
    itemSourceId: activeClip.id,
    itemSource: ItemLayerSources.Clips,
  } as CanvasItem;

  return newItems;
}

export type Rect = {top: number; left: number; width: number; height: number};

export const getRectForItem = (item: CanvasItem): Rect => {
  return {
    top: item.position.top,
    left: item.position.left,
    width: item.dimension.width,
    height: item.dimension.height,
  };
};

export const getDirectionPosition = ({
  type,
  direction,
  rect,
}: {
  type: AlignType;
  direction: AlignDirection;
  rect: Rect;
}) => {
  let size;
  let start;

  if (type === 'horizontal') {
    size = rect.height;
    start = rect.top;
  } else {
    size = rect.width;
    start = rect.left;
  }

  if (direction === 'start') {
    return start;
  } else if (direction === 'center') {
    return start + size / 2;
  } else {
    return start + size;
  }
};

export const getBoundingRect = (itemsObj: Record<string, CanvasItem>) => {
  const items = Object.values(itemsObj);
  if (items.length === 0) throw new Error('No items passed to getBoundingRect');

  const boundingRect = getRectForItem(items[0]);

  for (const item of items) {
    const rect = getRectForItem(item);

    if (rect.left < boundingRect.left) boundingRect.left = rect.left;
    if (rect.top < boundingRect.top) boundingRect.top = rect.top;
  }

  for (const item of items) {
    const rect = getRectForItem(item);

    const endTop = rect.top - boundingRect.top + rect.height;
    if (endTop > boundingRect.height) boundingRect.height = endTop;

    const endLeft = rect.left - boundingRect.left + rect.width;
    if (endLeft > boundingRect.width) boundingRect.width = endLeft;
  }

  return boundingRect;
};

export function getItemByType<T extends {viewType: ViewTypes}, V extends ViewTypes>(
  items: Record<string, T | undefined>,
  types: V | V[],
): {id: string; item: T & {viewType: V}} | undefined {
  const typesArr = Array.isArray(types) ? types : [types];

  for (const [id, item] of Object.entries(items)) {
    if (!item) continue;

    if (typesArr.includes(item.viewType as any)) {
      // @ts-ignore
      return {id, item};
    }
  }
}

export const useSetDependencyReady = ({
  projectId,
  itemId,
  dependency,
  ready,
}: {
  projectId: string;
  itemId: string;
  dependency: CanvasLayerDepedency;
  ready: boolean;
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDependencyReady({itemId, dependency, ready}));
  }, [itemId, dependency, ready, dispatch]);
};

/**
 * Support old canvasItems in case they are missing style property
 *
 * @param item
 * @returns
 */
export const reconfirmItemHasStyle = (item: CanvasItem): CanvasItem => {
  if (!item.style) {
    return {
      ...item,
      style: {
        ...BASE_LAYER_ITEM_STYLE,
      } as LayerItemStyleType,
    } as CanvasItem;
  }

  return item as CanvasItem;
};

/**
 * Check if items are missing scenes
 * @param items
 * @returns
 */
export const checkToMigrationCanvasItemsToScenes = (
  items: Record<string, CanvasItem>,
) => {
  const itemValues = Object.values(items);
  const itemWithoutScenes = itemValues.filter(
    item => !item.sceneId || item.sceneId === '',
  );
  return itemWithoutScenes.length > 0;
};

export const applyStyleToItem = (item: CanvasItem, style: RecentUsage) => {
  return produce(item, draftItem => {
    if (
      draftItem.viewType === ViewTypes.CaptionClip ||
      draftItem.viewType === ViewTypes.Caption
    ) {
      draftItem.style.color = style.captions_active_color;
      draftItem.style.colorCurrent = style.captions_active_color;
      draftItem.style.colorInactive = style.captions_inactive_color;
      draftItem.style.fontFamily = style.captions_font;
    }

    if (draftItem.viewType === ViewTypes.Text) {
      draftItem.style.color = style.text_color;
      draftItem.style.fontFamily = style.text_font;
    }

    if (draftItem.viewType === ViewTypes.Square) {
      draftItem.style.background = style.shape_background;
    }
  });
};

export const applyStyleToItems = (
  items: Record<string, CanvasItem>,
  style: RecentUsage,
) => {
  return produce(items, draftItems => {
    objForEach(draftItems, (item, id) => {
      draftItems[id] = applyStyleToItem(item, style);
    });
  });
};
