import {AudioWaveformOptions} from './AudioWaveforms/constants';
import {DynamicAudioWaveformAmplitudeContainer} from './DynamicAudioWaveformAmplitudeContainer';

import {CSSProperties} from 'react';
import {DimensionType, SmartComponentItem} from 'features/types/canvasItemsSlice';
import {DynamicCountDown} from './DynamicCountDown';
import {ProgressAnimationComponent} from './ProgressAnimation';
import {SmartComponents} from 'features/EditorCanvas/constants/ItemToolbarMapping';

export function ItemLayerSmartComponent({
  smartComponent,
  options,
  style,
  dimension,
  itemId,
}: {
  smartComponent: SmartComponentItem['smartComponent'];
  style: CSSProperties;
  dimension: DimensionType;
  options?: Record<string, any>;
  itemId: string;
}) {
  switch (smartComponent.id) {
    case SmartComponents.CountdownTimer:
      return <DynamicCountDown itemId={itemId} style={style} options={options} />;

    case SmartComponents.AudioWaveform:
      if (!options?.waveformStyle || !options?.targetPlayableMediaId) {
        return null;
      }
      return (
        <DynamicAudioWaveformAmplitudeContainer
          dimensions={dimension}
          style={style}
          options={
            {
              media: null,
              ...options,
              originLocation: options.originLocation || 'store',
              targetPlayableMediaId: options.targetPlayableMediaId,
              waveformStyle: options.waveformStyle,
            } as AudioWaveformOptions
          }
          smartComponent={smartComponent}
        />
      );

    case SmartComponents.ProgressAnimation:
      return (
        <ProgressAnimationComponent
          itemId={itemId}
          style={style}
          options={{
            dimension,
            background: options?.background,
            passiveColor: options?.passiveColor,
          }}
        />
      );

    default:
      return <div>SO SMART</div>;
  }
}
