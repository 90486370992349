import {CableMessagePayload} from './types';
import {LanguageId} from 'features/Dashboard/DashboardUploadDetails/PlayableMedia/LanguageState';
import {fetchFileUploadTranscript} from 'features/userLibrarySlice';

export enum UserUploadsCableActions {
  UpdateUserUpload = 'update_user_upload',
  TranscriptSaved = 'transcript_saved',
  Created = 'created',
  UpdatedPlaybackId = 'updated_playback_id',
}

const userUploadsCable = (
  dispatch: any,
  getAccessTokenSilently: any,
  message: CableMessagePayload,
  user: any,
) => {
  if (message.body.action === UserUploadsCableActions.TranscriptSaved) {
    const {user_upload_id: userUploadId, language = 'en'} = message.body.payload as {
      user_upload_id: string;
      language: LanguageId;
    };
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(fetchFileUploadTranscript(token, userUploadId, language));
      } catch (e) {
        console.error(e);
      }
    })();
  }
};

export default userUploadsCable;
