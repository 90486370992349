import {DetailTypePlayableMediaContainer} from './PlayableMedia/DetailTypePlayableMediaContainer';
import {LanguageProvider} from './PlayableMedia/LanguageState';
import {LoadingDashboardVideo} from 'features/EditorCanvas/components/shared/LoadingDashboardVideo';
import {Navigate} from 'react-router-dom';
import {UploadTranscriptProvider} from './PlayableMedia/UploadTranscriptContext';
import {routeHome} from 'routes/routesHelper';
import {useUserUploadQuery} from 'services/userUploadAPI';

interface DashboardVideoProps {
  userUploadId: string;
}

export function DashboardMediaAsset({userUploadId}: DashboardVideoProps) {
  const {
    data: userUpload,
    isLoading,
    isFetching,
    // @ts-ignore
  } = useUserUploadQuery({id: userUploadId}!);

  const fileType = userUpload && userUpload.file_type;

  if (isLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <LoadingDashboardVideo />
      </div>
    );
  }

  if (!fileType && !userUpload) {
    return <Navigate to={routeHome} />;
  }

  return (
    <LanguageProvider>
      <UploadTranscriptProvider>
        <DetailTypePlayableMediaContainer userUploadId={userUpload.id} />
      </UploadTranscriptProvider>
    </LanguageProvider>
  );
}
