import {ReactNode, createContext, useContext, useReducer} from 'react';

export enum SearchPages {
  Recent = 'recent results',
  SearchResults = 'search results',
}

type Action =
  | {
      type: 'searchQueryStart';
      payload: {
        query: string;
      };
    }
  | {
      type: 'searchQuerySuccess';
      payload: {
        query: string;
      };
    }
  | {
      type: 'searchQueryFailed';
    }
  | {
      type: 'updateSearchQuery';
      payload: {
        query: string;
      };
    }
  | {
      type: 'updateActivePage';
      payload: {
        newPage: SearchPages;
      };
    }
  | {
      type: 'updateClipIdPlaying';
      payload: {
        clipId: string | null;
      };
    }
  | {
      type: 'addNewVideoPlayerReference';
      payload: {
        videoRef: any;
      };
    }
  | {
      type: 'removeVideoPlayerReference';
      payload: {
        videoRef: any;
      };
    };
type Dispatch = (action: Action) => void;
type SearchResultsProviderProps = {children: ReactNode};

const SearchResultsStateContext =
  createContext<{state: State; dispatch: Dispatch} | undefined>(undefined);

type State = {
  activePage: SearchPages.Recent | SearchPages.SearchResults;
  searchQuery: string;
  clipIdPlaying: string | null;
  videoRefs: any[];
  isSearching: boolean;
};
const initialState = {
  searchQuery: '',
  activePage: SearchPages.Recent,
  clipIdPlaying: null,
  videoRefs: [],
  isSearching: false,
} as State;

function searchResultsReducer(state: State, action: Action) {
  switch (action.type) {
    case 'searchQueryStart': {
      window.analytics.track('User search query request started');
      return {...state, isSearching: true, searchQuery: action.payload.query};
    }
    case 'searchQuerySuccess': {
      window.analytics.track('User search query request success');
      return {...state, isSearching: false, searchQuery: action.payload.query};
    }
    case 'searchQueryFailed': {
      window.analytics.track('User search query request failed');
      return {...state, isSearching: false};
    }
    case 'updateSearchQuery': {
      return {...state, searchQuery: action.payload.query};
    }
    case 'updateActivePage': {
      return {...state, activePage: action.payload.newPage};
    }
    case 'addNewVideoPlayerReference': {
      const videoRefs = [...state.videoRefs, action.payload.videoRef];
      return {...state, videoRefs};
    }
    case 'removeVideoPlayerReference': {
      // const videoRefs = [...state.videoRefs, action.payload.videoRef];
      return {...state};
    }
    case 'updateClipIdPlaying': {
      state.videoRefs.forEach(videoRef => {
        // console.log('videoRef', videoRef);
        if (videoRef?.player?.player?.pause) {
          videoRef.player.player.pause();
        }
      });
      // console.log('action.payload.clipId', action.payload.clipId);
      return {...state, clipIdPlaying: action.payload.clipId};
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function SearchResultsProvider({children}: SearchResultsProviderProps) {
  const [state, dispatch] = useReducer(searchResultsReducer, initialState);
  const value = {state, dispatch};
  return (
    <SearchResultsStateContext.Provider value={value}>
      {children}
    </SearchResultsStateContext.Provider>
  );
}

function useSearchResults() {
  const context = useContext(SearchResultsStateContext);
  if (context === undefined) {
    throw new Error('useSearchResults must be used within a SearchResultsProvider');
  }
  return context;
}

export {SearchResultsProvider, useSearchResults};
