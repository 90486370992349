import { useState } from 'react';
import { ReactComponent as IconCameraOff } from 'vendor/dailyco/shared/icons/camera-off-md.svg';
import { ReactComponent as IconCameraOn } from 'vendor/dailyco/shared/icons/camera-on-md.svg';
import { ReactComponent as IconMicOff } from 'vendor/dailyco/shared/icons/mic-off-md.svg';
import { ReactComponent as IconMicOn } from 'vendor/dailyco/shared/icons/mic-on-md.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallState } from '../../contexts/CallProvider';
import { Button } from 'ui/Button'
import { useLocalParticipant } from '@daily-co/daily-react-hooks';

import { AudioIndicator } from '../HairCheck/AudioIndicator';
export const MuteButton = ({
  isMuted,
  mic = false,
  className,
  disabled = false,
  ...props
}) => {
  const { callObject } = useCallState();
  const [muted, setMuted] = useState(isMuted);
  const localParticipant = useLocalParticipant();

  const toggleDevice = (newState) => {
    if (mic) {
      callObject.setLocalAudio(newState);
    } else {
      callObject.setLocalVideo(newState);
    }

    setMuted(newState);
  };

  const Icon = mic
    ? [<IconMicOff key="mic-off" />, <IconMicOn key="mic-on" />]
    : [<IconCameraOff key="cam-off" />, <IconCameraOn key="cam-on" />];

  if (!callObject) return null;

  const cx = classNames(className, { muted: disabled || !muted });



  return (
    <Button
      variant='tertiary'
      size="large"
      className={cx}
      disabled={disabled}
      {...props}
      onClick={() => toggleDevice(!muted)}
    >
      {mic ? (
        localParticipant?.session_id && (
          <AudioIndicator sessionId={localParticipant.session_id} />
        )
      ) : (
        <div className="flex items-center justify-center" style={{ width: 24, height: 24 }}>
          {disabled ? Icon[0] : Icon[+muted]}
        </div>
      )}
    </Button>
  );
};

MuteButton.propTypes = {
  isMuted: PropTypes.bool,
  mic: PropTypes.bool,
  className: PropTypes.string,
};

export default MuteButton;
