export enum FontSource {
  GoogleFonts = 'Google Fonts',
  AccountUpload = 'AccountUpload',
}

const GF_URL = 'https://fonts.gstatic.com/s/';
const GF_SOURCE = 'Google Fonts';

export const EXTERNAL_FONTS_WITH_PREVIEW = [
  {
    id: '3728674d-a4e7-42ae-87c3-37cc19878662',
    origin: GF_SOURCE,
    url: GF_URL + 'orelegaone/v1/3qTpojOggD2XtAdFb-QXZGt61EcYaQ7F.ttf',
    variant: 'regular',
    family: 'Orelega One',
    category: 'display',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '02aae93e-e0b9-4b8f-aeb2-5f84a6099c9d',
    origin: GF_SOURCE,
    url: GF_URL + 'zendots/v1/XRXX3ICfm00IGoesQeaETM_FcCIG.ttf',
    variant: 'regular',
    family: 'Zen Dots',
    category: 'display',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '2c534031-add0-4577-913c-d04f0783d43c',
    origin: GF_SOURCE,
    url: GF_URL + 'karantina/v1/buExpo24ccnh31GVMABxTC8f-P5Oaiw4cw.ttf',
    variant: '700',
    family: 'Karantina',
    category: 'display',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '1506b765-62ac-4ea3-9788-34e5df4ba157',
    origin: GF_SOURCE,
    url: GF_URL + 'karantina/v1/buE0po24ccnh31GVMABJ8AA78NVSYw.ttf',
    variant: 'regular',
    family: 'Karantina',
    category: 'display',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: 'c525914e-d85c-465f-8a0c-36d15919f1cf',
    origin: GF_SOURCE,
    url: GF_URL + 'karantina/v1/buExpo24ccnh31GVMABxXCgf-P5Oaiw4cw.ttf',
    variant: '300',
    family: 'Karantina',
    category: 'display',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '3b9c339d-ed45-4de4-a94a-ddac9c09371b',
    origin: GF_SOURCE,
    url: GF_URL + 'antonio/v1/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVyW9htIY2DwSXlM.ttf',
    variant: '700',
    family: 'Antonio',
    category: 'sans-serif',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '7fa58bd4-fa35-47fa-817b-9a95f9562294',
    origin: GF_SOURCE,
    url: GF_URL + 'antonio/v1/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVyv9htIY2DwSXlM.ttf',
    variant: '600',
    family: 'Antonio',
    category: 'sans-serif',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '2c141874-bce6-4a10-ae73-07ead5810de9',
    origin: GF_SOURCE,
    url: GF_URL + 'antonio/v1/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxD8RtIY2DwSXlM.ttf',
    variant: '500',
    family: 'Antonio',
    category: 'sans-serif',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '8d0a3968-1af0-44e3-954b-4fdfc37295ba',
    origin: GF_SOURCE,
    url: GF_URL + 'antonio/v1/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxx8RtIY2DwSXlM.ttf',
    variant: 'regular',
    family: 'Antonio',
    category: 'sans-serif',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: 'c3e30e79-ea3d-4064-84dd-bb19a5dbeed5',
    origin: GF_SOURCE,
    url: GF_URL + 'antonio/v1/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVwv8RtIY2DwSXlM.ttf',
    variant: '300',
    family: 'Antonio',
    category: 'sans-serif',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '9b425618-691e-4461-964a-abdf7984000d',
    origin: GF_SOURCE,
    url: GF_URL + 'antonio/v1/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVzx8RtIY2DwSXlM.ttf',
    variant: '200',
    family: 'Antonio',
    category: 'sans-serif',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: 'fb4c9d02-2d37-4029-a0a3-a118d6acc53f',
    origin: GF_SOURCE,
    url: GF_URL + 'antonio/v1/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxx8BtIY2DwSXlM.ttf',
    variant: '100',
    family: 'Antonio',
    category: 'sans-serif',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: 'b532c2ec-0a06-4373-89c0-6042115eb758',
    origin: GF_SOURCE,
    url: GF_URL + 'synetactile/v5/11hGGpna2UTQKjMCVzjAPMKh3ysdjvKU8Q.ttf',
    variant: 'regular',
    family: 'Syne Tactile',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '46eb47dc-efaf-4a1c-a207-8ad3e08154f8',
    origin: GF_SOURCE,
    url: GF_URL + 'ballet/v6/QGYyz_MYZA-HM4NjuGOVnUEXme1I4Xi3C4G-EiAou6Y.ttf',
    variant: 'regular',
    family: 'Ballet',
    category: 'handwriting',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '2401456a-0505-467e-9fbc-b70697af11d9',
    origin: GF_SOURCE,
    url: GF_URL + 'benne/v10/L0xzDFAhn18E6Vjxlt6qTDBN.ttf',
    variant: 'regular',
    family: 'Benne',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '020169e6-f330-47ea-9e36-7761c4c31275',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'truculenta/v6/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMvklswcFHnJMMhg.ttf',
    variant: '900',
    family: 'Truculenta',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'df9128a9-4484-4aed-9451-4896ef23bb61',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'truculenta/v6/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMtAlswcFHnJMMhg.ttf',
    variant: '800',
    family: 'Truculenta',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '1c64e8fd-a6ed-4ac6-93fa-cfa99e569f69',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'truculenta/v6/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMrclswcFHnJMMhg.ttf',
    variant: '700',
    family: 'Truculenta',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '5e795761-747d-443b-9212-2e246aacf052',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'truculenta/v6/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMo4lswcFHnJMMhg.ttf',
    variant: '600',
    family: 'Truculenta',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '9946087f-57ce-4d40-b09a-9f328c6eb1b6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'truculenta/v6/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMmIiswcFHnJMMhg.ttf',
    variant: '500',
    family: 'Truculenta',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c5e11396-20ad-497b-a26f-bbe2fb90be5d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'truculenta/v6/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMlAiswcFHnJMMhg.ttf',
    variant: 'regular',
    family: 'Truculenta',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '12fc8527-cc82-4784-ab5b-4c22a9304dd4',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'truculenta/v6/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMg4iswcFHnJMMhg.ttf',
    variant: '300',
    family: 'Truculenta',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '7b471495-661b-44b0-814b-6e3a7aef524d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'truculenta/v6/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMtAiswcFHnJMMhg.ttf',
    variant: '200',
    family: 'Truculenta',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6a185884-f9d7-45cb-95d5-ae08f090052d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'truculenta/v6/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMlAjswcFHnJMMhg.ttf',
    variant: '100',
    family: 'Truculenta',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6dd12dab-df54-4135-97f6-3ebba5d63001',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'trispace/v6/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbP9soQl0zHugpt0.ttf',
    variant: '800',
    family: 'Trispace',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'fde13f6e-3da5-498b-8068-b459c79d96e1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'trispace/v6/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbJhsoQl0zHugpt0.ttf',
    variant: '700',
    family: 'Trispace',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'e72ad091-e74f-485f-ae51-5965c474aae3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'trispace/v6/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbKFsoQl0zHugpt0.ttf',
    variant: '600',
    family: 'Trispace',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '5bc074b7-4b13-467b-a37c-1c7e72ce1aac',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'trispace/v6/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbE1roQl0zHugpt0.ttf',
    variant: '500',
    family: 'Trispace',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8c748d4c-b013-4fb9-9251-5d695e5e012b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'trispace/v6/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbH9roQl0zHugpt0.ttf',
    variant: 'regular',
    family: 'Trispace',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'e4bde64e-0463-41e0-9eb0-6c5f1c559bfb',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'trispace/v6/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbCFroQl0zHugpt0.ttf',
    variant: '300',
    family: 'Trispace',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ffe69abc-bd5c-4471-a46e-53a792642288',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'trispace/v6/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbP9roQl0zHugpt0.ttf',
    variant: '200',
    family: 'Trispace',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'de75e1d1-9abb-4ecc-80a7-35bd76936ce5',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'trispace/v6/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbH9qoQl0zHugpt0.ttf',
    variant: '100',
    family: 'Trispace',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6a3f197b-e95b-41ce-87ee-f10d5d4c7c18',
    origin: GF_SOURCE,
    url: GF_URL + 'xanhmono/v7/R70ejykVmvKCep-vWhSomgqUfTfxTo24.ttf',
    variant: 'italic',
    family: 'Xanh Mono',
    category: 'monospace',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'b95e4a9b-0aa5-4e93-927a-74553aa1e2f7',
    origin: GF_SOURCE,
    url: GF_URL + 'xanhmono/v7/R70YjykVmvKCep-vWhSYmACQXzLhTg.ttf',
    variant: 'regular',
    family: 'Xanh Mono',
    category: 'monospace',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '8d9be204-3c43-4eed-891a-2eecc509f7f9',
    origin: GF_SOURCE,
    url: GF_URL + 'viaodalibre/v5/vEFW2_lWCgoR6OKuRz9kcRVJb2IY2tOHXg.ttf',
    variant: 'regular',
    family: 'Viaoda Libre',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '22812906-4045-4d87-8dce-f7a1c57087b1',
    origin: GF_SOURCE,
    url: GF_URL + 'oi/v4/w8gXH2EuRqtaut6yjBOG.ttf',
    variant: 'regular',
    family: 'Oi',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '93d31a38-15a9-4b87-aebc-676084275a3a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstencildisplay/v5/6aeX4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPyvAU9VcB5FTrrKG-.ttf',
    variant: '900',
    family: 'Big Shoulders Stencil Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a1337696-09fb-485b-ab67-8e5bd7a19eff',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstencildisplay/v5/6aeX4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPyvAw9FcB5FTrrKG-.ttf',
    variant: '800',
    family: 'Big Shoulders Stencil Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '9e2a39fe-d681-42ed-b17d-81d74af06f83',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstencildisplay/v5/6aeX4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPyvAs91cB5FTrrKG-.ttf',
    variant: '700',
    family: 'Big Shoulders Stencil Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'fff67c4b-c478-4eba-ba06-047a63de1c2c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstencildisplay/v5/6aeX4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPyvBI9lcB5FTrrKG-.ttf',
    variant: '600',
    family: 'Big Shoulders Stencil Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '53fc4255-d481-4ae7-9ac7-8d614775b739',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstencildisplay/v5/6aeX4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPyvBk8VcB5FTrrKG-.ttf',
    variant: '500',
    family: 'Big Shoulders Stencil Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3c224097-169e-4b24-8acb-56cfdcff6ba0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstencildisplay/v5/6aeq4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPysiQ2HMJz0ji.ttf',
    variant: 'regular',
    family: 'Big Shoulders Stencil Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '17037717-999b-498a-bf38-e299b0c44d4a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstencildisplay/v5/6aeX4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPyvA88FcB5FTrrKG-.ttf',
    variant: '300',
    family: 'Big Shoulders Stencil Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'dbe7c517-267f-4d91-9acb-86e4ed83d1c7',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstencildisplay/v5/6aeU4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPyvD00ncryljitbg.ttf',
    variant: '100',
    family: 'Big Shoulders Stencil Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3ff1f29a-3a68-4b42-87c8-87e1c4141d62',
    origin: GF_SOURCE,
    url: GF_URL + 'warnes/v12/pONn1hc0GsW6sW5OpiC2o6Lkqg.ttf',
    variant: 'regular',
    family: 'Warnes',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2012d6c2-51ed-4557-8b2c-499c3b78b3e0',
    origin: GF_SOURCE,
    url: GF_URL + 'lexendpeta/v10/BXRvvFPGjeLPh0kCfI4OkE_1c8Tf1IW3.ttf',
    variant: 'regular',
    family: 'Lexend Peta',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '435a2fd0-b42f-439a-af4f-726ca50ea267',
    origin: GF_SOURCE,
    url: GF_URL + 'singleday/v5/LYjHdGDjlEgoAcF95EI5jVoFUNfeQJU.ttf',
    variant: 'regular',
    family: 'Single Day',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'eb831b6c-556e-446b-8141-0bb18027e4f4',
    origin: GF_SOURCE,
    url: GF_URL + 'geostar/v13/sykz-yx4n701VLOftSq9-trEvlQ.ttf',
    variant: 'regular',
    family: 'Geostar',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'a596623c-04db-439e-a2b7-6bf79c395cf8',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinedisplay/v5/_LOkmyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85COlyKrAJGHowbP0g.ttf',
    variant: '900',
    family: 'Big Shoulders Inline Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'de18d861-d6c8-4972-8227-1bc1e72720a1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinedisplay/v5/_LOkmyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85COniLrAJGHowbP0g.ttf',
    variant: '800',
    family: 'Big Shoulders Inline Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '155421f5-3c65-4926-aca5-73baecf0c623',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinedisplay/v5/_LOkmyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85COmSIrAJGHowbP0g.ttf',
    variant: '700',
    family: 'Big Shoulders Inline Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '8857e02c-5b5d-48ad-bfe5-7c8328d725ee',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinedisplay/v5/_LOkmyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85COgCJrAJGHowbP0g.ttf',
    variant: '600',
    family: 'Big Shoulders Inline Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '02241550-5e2e-4054-a048-f175084670c0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinedisplay/v5/_LOkmyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85COiyOrAJGHowbP0g.ttf',
    variant: '500',
    family: 'Big Shoulders Inline Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'af6e3162-0baa-4e3b-ad37-647d6d162a47',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinedisplay/v5/_LObmyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CAtiniAptAoU.ttf',
    variant: 'regular',
    family: 'Big Shoulders Inline Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '5bc0b90c-2a83-4213-bc8c-7159c2c623be',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinedisplay/v5/_LOkmyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85COnSPrAJGHowbP0g.ttf',
    variant: '300',
    family: 'Big Shoulders Inline Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'd0d34e93-828b-4d3a-a553-97629124e5b8',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinedisplay/v5/_LOlmyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85COrytjChoEoUCJg.ttf',
    variant: '100',
    family: 'Big Shoulders Inline Display',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '41e0db31-a049-49df-a8c9-defdd8ccdcbf',
    origin: GF_SOURCE,
    url: GF_URL + 'kenia/v14/jizURE5PuHQH9qCONUGswfGM.ttf',
    variant: 'regular',
    family: 'Kenia',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'ff10439e-d99d-4fef-9fa5-74a0658fe543',
    origin: GF_SOURCE,
    url: GF_URL + 'dhurjati/v10/_6_8ED3gSeatXfFiFX3ySKQtuTA2.ttf',
    variant: 'regular',
    family: 'Dhurjati',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '765cd9e4-b908-4e08-a426-957b1285b7d7',
    origin: GF_SOURCE,
    url: GF_URL + 'biorhymeexpanded/v8/i7dVIE1zZzytGswgU577CDY9Ljbffxw0aVSHSdTXrb_z.ttf',
    variant: '800',
    family: 'BioRhyme Expanded',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '704bd0ff-7eb0-423a-95b2-b6c5b200f589',
    origin: GF_SOURCE,
    url: GF_URL + 'biorhymeexpanded/v8/i7dVIE1zZzytGswgU577CDY9LjbffxwoalSHSdTXrb_z.ttf',
    variant: '700',
    family: 'BioRhyme Expanded',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '88bc6661-c925-4ebc-8df5-5b039e54a2da',
    origin: GF_SOURCE,
    url: GF_URL + 'biorhymeexpanded/v8/i7dQIE1zZzytGswgU577CDY9LjbffySURXCPYsje.ttf',
    variant: 'regular',
    family: 'BioRhyme Expanded',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b2028505-2f6e-4bd1-b613-9055deed3c84',
    origin: GF_SOURCE,
    url: GF_URL + 'biorhymeexpanded/v8/i7dVIE1zZzytGswgU577CDY9Ljbffxw4bVSHSdTXrb_z.ttf',
    variant: '300',
    family: 'BioRhyme Expanded',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '0b8ecf22-5d42-4e15-8407-e770e5e7d861',
    origin: GF_SOURCE,
    url: GF_URL + 'biorhymeexpanded/v8/i7dVIE1zZzytGswgU577CDY9LjbffxxcblSHSdTXrb_z.ttf',
    variant: '200',
    family: 'BioRhyme Expanded',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ad468936-d00c-46aa-8b66-4eab4448d5fa',
    origin: GF_SOURCE,
    url: GF_URL + 'fruktur/v15/SZc53FHsOru5QYsMfz3GkUrS8DI.ttf',
    variant: 'regular',
    family: 'Fruktur',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'd7532726-4406-41e6-98df-06b2e6e9bb19',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinetext/v5/vm8hdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NEa7XO1oeH9xI2gc.ttf',
    variant: '900',
    family: 'Big Shoulders Inline Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f4250a9e-aadd-433c-b32f-ba546e1d59b4',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinetext/v5/vm8hdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NEYrWO1oeH9xI2gc.ttf',
    variant: '800',
    family: 'Big Shoulders Inline Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '9f0c83f8-f420-46b9-ac32-9910e0294be1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinetext/v5/vm8hdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NEZbVO1oeH9xI2gc.ttf',
    variant: '700',
    family: 'Big Shoulders Inline Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a372808c-2215-4ad2-bb5b-5107a6f48580',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinetext/v5/vm8hdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NEfLUO1oeH9xI2gc.ttf',
    variant: '600',
    family: 'Big Shoulders Inline Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '942c2f2f-7764-46bc-a09f-ec686b03162c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinetext/v5/vm8hdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NEd7TO1oeH9xI2gc.ttf',
    variant: '500',
    family: 'Big Shoulders Inline Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'eb496c6f-3b86-46ad-96b7-84b70a6ed00f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinetext/v5/vm8kdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NKSr6H1I1A9U.ttf',
    variant: 'regular',
    family: 'Big Shoulders Inline Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '66cc607e-632a-403e-89b0-472750ce0c4c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinetext/v5/vm8hdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NEYbSO1oeH9xI2gc.ttf',
    variant: '300',
    family: 'Big Shoulders Inline Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'db03ae0a-95b5-4655-a410-b43aa167f23d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersinlinetext/v5/vm8idQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NEU7wG3AwE9VRww.ttf',
    variant: '100',
    family: 'Big Shoulders Inline Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '56283767-4816-441f-b3a6-9665d549ab85',
    origin: GF_SOURCE,
    url: GF_URL + 'hanalei/v13/E21n_dD8iufIjBRHXzgmVydREus.ttf',
    variant: 'regular',
    family: 'Hanalei',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '884038a7-d44a-422e-950b-1a4e1e7409e8',
    origin: GF_SOURCE,
    url: GF_URL + 'lexendtera/v10/RrQUbo98_jt_IXnBPwCWtZhARYMgGtWA.ttf',
    variant: 'regular',
    family: 'Lexend Tera',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b86091d5-6466-4284-91f0-61d1514f403d',
    origin: GF_SOURCE,
    url: GF_URL + 'vibes/v4/QdVYSTsmIB6tmbd3HpbsuBlh.ttf',
    variant: 'regular',
    family: 'Vibes',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '7b87cb29-a866-4022-b896-501e30abccb0',
    origin: GF_SOURCE,
    url: GF_URL + 'passeroone/v14/JTUTjIko8DOq5FeaeEAjgE5B5Arr-s50.ttf',
    variant: 'regular',
    family: 'Passero One',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '89abc4c1-c48d-4395-9139-2c39b885ade1',
    origin: GF_SOURCE,
    url: GF_URL + 'akayatelivigala/v9/lJwc-oo_iG9wXqU3rCTD395tp0uifdLdsIH0YH8.ttf',
    variant: 'regular',
    family: 'Akaya Telivigala',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'f678c9f9-baff-4c42-a94f-b9fb1d609f68',
    origin: GF_SOURCE,
    url: GF_URL + 'suravaram/v11/_gP61R_usiY7SCym4xIAi261Qv9roQ.ttf',
    variant: 'regular',
    family: 'Suravaram',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'fb4dd70d-e197-4584-9ce7-b95c033f0223',
    origin: GF_SOURCE,
    url: GF_URL + 'geostarfill/v13/AMOWz4SWuWiXFfjEohxQ9os0U1K2w9lb4g.ttf',
    variant: 'regular',
    family: 'Geostar Fill',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '0170bb75-843b-4048-8149-5e5d4f27b619',
    origin: GF_SOURCE,
    url: GF_URL + 'kdamthmor/v11/MwQzbhjs3veF6QwJVf0JkGMViblPtXs.ttf',
    variant: 'regular',
    family: 'Kdam Thmor',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a1917e8f-33b2-4103-b718-619ce1b8f2ad',
    origin: GF_SOURCE,
    url: GF_URL + 'moulpali/v15/H4ckBXKMl9HagUWymyY6wr-wg763.ttf',
    variant: 'regular',
    family: 'Moulpali',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'adc21ef4-0cd0-4e49-bc61-74aa608912c9',
    origin: GF_SOURCE,
    url: GF_URL + 'gidugu/v11/L0x8DFMkk1Uf6w3RvPCmRSlUig.ttf',
    variant: 'regular',
    family: 'Gidugu',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7ae2bea2-bc46-4e4f-8d25-a1b529804bbf',
    origin: GF_SOURCE,
    url: GF_URL + 'butcherman/v14/2EbiL-thF0loflXUBOdb1zWzq_5uT84.ttf',
    variant: 'regular',
    family: 'Butcherman',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '1047bf37-e00b-4a92-afad-4c6d3536eea5',
    origin: GF_SOURCE,
    url: GF_URL + 'novaoval/v14/jAnEgHdmANHvPenMaswCMY-h3cWkWg.ttf',
    variant: 'regular',
    family: 'Nova Oval',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '95148148-3916-47d1-9cc5-167bb2effa9a',
    origin: GF_SOURCE,
    url: GF_URL + 'lacquer/v5/EYqzma1QwqpG4_BBB7-AXhttQ5I.ttf',
    variant: 'regular',
    family: 'Lacquer',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '79db4a2a-db07-4899-aa25-fa5e7912d108',
    origin: GF_SOURCE,
    url: GF_URL + 'fasthand/v13/0yb9GDohyKTYn_ZEESkuYkw2rQg1.ttf',
    variant: 'regular',
    family: 'Fasthand',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'c26def15-40e5-498c-83cf-898dc55d5203',
    origin: GF_SOURCE,
    url: GF_URL + 'sevillana/v11/KFOlCnWFscmDt1Bfiy1vAx05IsDqlA.ttf',
    variant: 'regular',
    family: 'Sevillana',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a3470242-3fe4-4c6d-af3e-14065b66832e',
    origin: GF_SOURCE,
    url: GF_URL + 'astloch/v14/TuGUUVJ8QI5GSeUjk2A-6MNPA10xLMQ.ttf',
    variant: '700',
    family: 'Astloch',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'cbc8f60e-e5eb-4c32-84a1-d7851551eeec',
    origin: GF_SOURCE,
    url: GF_URL + 'astloch/v14/TuGRUVJ8QI5GSeUjq9wRzMtkH1Q.ttf',
    variant: 'regular',
    family: 'Astloch',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '8fe287bb-e6e7-428f-a36f-1c3469d6d972',
    origin: GF_SOURCE,
    url: GF_URL + 'sofadione/v11/JIA2UVBxdnVBuElZaMFGcDOIETkmYDU.ttf',
    variant: 'regular',
    family: 'Sofadi One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '6c94ab5d-8f71-4673-bc5f-a2e3f5a2510f',
    origin: GF_SOURCE,
    url: GF_URL + 'preahvihear/v14/6NUS8F-dNQeEYhzj7uluxswE49FJf8Wv.ttf',
    variant: 'regular',
    family: 'Preahvihear',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'dc012054-6661-454f-a1aa-c1bea0af6fce',
    origin: GF_SOURCE,
    url: GF_URL + 'langar/v15/kJEyBukW7AIlgjGVrTVZ99sqrQ.ttf',
    variant: 'regular',
    family: 'Langar',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'fc05b899-073a-4f1b-86c9-12fac3f48f70',
    origin: GF_SOURCE,
    url: GF_URL + 'aubrey/v15/q5uGsou7NPBw-p7vugNsCxVEgA.ttf',
    variant: 'regular',
    family: 'Aubrey',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '51c512a9-974c-41b1-a3a4-aeaa6995e042',
    origin: GF_SOURCE,
    url: GF_URL + 'bahianita/v5/yYLr0hTb3vuqqsBUgxWtxTvV2NJPcA.ttf',
    variant: 'regular',
    family: 'Bahianita',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '4ab6146a-92ac-4b15-a95d-6bff5dd6a212',
    origin: GF_SOURCE,
    url: GF_URL + 'fascinate/v11/z7NWdRrufC8XJK0IIEli1LbQRPyNrw.ttf',
    variant: 'regular',
    family: 'Fascinate',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '73078dc7-8b80-483e-810f-f07c9d3175da',
    origin: GF_SOURCE,
    url: GF_URL + 'miltoniantattoo/v18/EvOUzBRL0o0kCxF-lcMCQxlpVsA_FwP8MDBku-s.ttf',
    variant: 'regular',
    family: 'Miltonian Tattoo',
    category: 'display',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '7905cf46-224b-485f-a5ff-43abb895101b',
    origin: GF_SOURCE,
    url: GF_URL + 'bonbon/v14/0FlVVPeVlFec4ee_cDEAbQY5-A.ttf',
    variant: 'regular',
    family: 'Bonbon',
    category: 'handwriting',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'f900ad72-a162-4a22-999f-f903dd13ba66',
    origin: GF_SOURCE,
    url: GF_URL + 'novacut/v14/KFOkCnSYu8mL-39LkWxPKTM1K9nz.ttf',
    variant: 'regular',
    family: 'Nova Cut',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'f29bcd96-dd2f-4376-b113-a336944b57b0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'blackandwhitepicture/v10/TwMe-JAERlQd3ooUHBUXGmrmioKjjnRSFO-NqI5HbcMi-yWY.ttf',
    variant: 'regular',
    family: 'Black And White Picture',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '9ce8a3cd-88df-4cc0-afe7-ea1ae775c0b3',
    origin: GF_SOURCE,
    url: GF_URL + 'andikanewbasic/v5/taiUGn9tCp-44eleq5Q-mszJivxSeK2mqG-iHxVJA85Okw.ttf',
    variant: '700italic',
    family: 'Andika New Basic',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '18a46c35-b1ec-46f5-afc5-5e2b71b8313f',
    origin: GF_SOURCE,
    url: GF_URL + 'andikanewbasic/v5/taiWGn9tCp-44eleq5Q-mszJivxScBO7NGqoGzdME84.ttf',
    variant: '700',
    family: 'Andika New Basic',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'c55b16ed-1bb3-43fd-8175-ddee11c410bd',
    origin: GF_SOURCE,
    url: GF_URL + 'andikanewbasic/v5/taiXGn9tCp-44eleq5Q-mszJivxSeK2eFECGFz5VCg.ttf',
    variant: 'italic',
    family: 'Andika New Basic',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '7200ad1f-1e40-4af6-ad8f-a95575db5973',
    origin: GF_SOURCE,
    url: GF_URL + 'andikanewbasic/v5/taiRGn9tCp-44eleq5Q-mszJivxSSK-UEGKDBz4.ttf',
    variant: 'regular',
    family: 'Andika New Basic',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '325695f6-26f0-4aa1-90c1-e9a03bc59999',
    origin: GF_SOURCE,
    url: GF_URL + 'novascript/v15/7Au7p_IpkSWSTWaFWkumvmQNEl0O0kEx.ttf',
    variant: 'regular',
    family: 'Nova Script',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '5612cfb1-4797-4f08-a0a1-711046110683',
    origin: GF_SOURCE,
    url: GF_URL + 'inriasans/v4/ptRRTiqXYfZMCOiVj9kQ1OzAkQ5PrcQybX4pQA.ttf',
    variant: '700italic',
    family: 'Inria Sans',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '086519f2-7a6f-40c7-a3a7-1f3be0bed38f',
    origin: GF_SOURCE,
    url: GF_URL + 'inriasans/v4/ptRPTiqXYfZMCOiVj9kQ3FLdDQtFqeY3fX4.ttf',
    variant: '700',
    family: 'Inria Sans',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'e38cee6d-bc99-464f-a4c1-04aa1ab000ed',
    origin: GF_SOURCE,
    url: GF_URL + 'inriasans/v4/ptROTiqXYfZMCOiVj9kQ1Oz4LSFrpe8uZA.ttf',
    variant: 'italic',
    family: 'Inria Sans',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '7e94878a-0c65-4c2a-b174-e9ddd2b4999b',
    origin: GF_SOURCE,
    url: GF_URL + 'inriasans/v4/ptRMTiqXYfZMCOiVj9kQ5O7yKQNute8.ttf',
    variant: 'regular',
    family: 'Inria Sans',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c8df2082-d715-463d-8489-5542ca690371',
    origin: GF_SOURCE,
    url: GF_URL + 'inriasans/v4/ptRRTiqXYfZMCOiVj9kQ1OzAgQlPrcQybX4pQA.ttf',
    variant: '300italic',
    family: 'Inria Sans',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '95f4045c-777c-44ac-8e6a-37955c224091',
    origin: GF_SOURCE,
    url: GF_URL + 'inriasans/v4/ptRPTiqXYfZMCOiVj9kQ3ELaDQtFqeY3fX4.ttf',
    variant: '300',
    family: 'Inria Sans',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '6708ffa3-8b25-469f-9ee0-6175415ab482',
    origin: GF_SOURCE,
    url: GF_URL + 'combo/v11/BXRlvF3Jh_fIhg0iBu9y8Hf0.ttf',
    variant: 'regular',
    family: 'Combo',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'd893b854-3ae3-4f2b-bd75-88fc53f93dfa',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imbue/v9/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP2GQfOsNNK-Q4xY.ttf',
    variant: '900',
    family: 'Imbue',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '9e2f28df-8f43-42e5-aea4-4f5e90c7d785',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imbue/v9/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP0iQfOsNNK-Q4xY.ttf',
    variant: '800',
    family: 'Imbue',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '706388bc-aca8-4f0a-a7ff-ff5c9979163f',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imbue/v9/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoPy-QfOsNNK-Q4xY.ttf',
    variant: '700',
    family: 'Imbue',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '0962e0a7-a432-4b39-8132-5c4dedbcd64b',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imbue/v9/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoPxaQfOsNNK-Q4xY.ttf',
    variant: '600',
    family: 'Imbue',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '7a633690-a608-4f1e-ba17-03f547f36787',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imbue/v9/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP_qXfOsNNK-Q4xY.ttf',
    variant: '500',
    family: 'Imbue',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '64ca7ff0-1330-489b-9249-5b03ef38cc40',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imbue/v9/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP8iXfOsNNK-Q4xY.ttf',
    variant: 'regular',
    family: 'Imbue',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '29818103-a3a0-47ce-ac88-3b6dbf2b2242',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imbue/v9/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP5aXfOsNNK-Q4xY.ttf',
    variant: '300',
    family: 'Imbue',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '414b33c7-fe09-4fff-86be-22c90741cb6e',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imbue/v9/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP0iXfOsNNK-Q4xY.ttf',
    variant: '200',
    family: 'Imbue',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '4a9ce67c-80db-49c9-8f5a-733a7dcb8ffe',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imbue/v9/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP8iWfOsNNK-Q4xY.ttf',
    variant: '100',
    family: 'Imbue',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '43c0cd48-2635-4b65-8d33-b6f015848bc1',
    origin: GF_SOURCE,
    url: GF_URL + 'federant/v15/2sDdZGNfip_eirT0_U0jRUG0AqUc.ttf',
    variant: 'regular',
    family: 'Federant',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '01b5fc40-c37b-4c28-b03a-bec2f9b43493',
    origin: GF_SOURCE,
    url: GF_URL + 'kiranghaerang/v10/E21-_dn_gvvIjhYON1lpIU4-bcqvWPaJq4no.ttf',
    variant: 'regular',
    family: 'Kirang Haerang',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'c380af1f-c127-41e2-bdfb-4eae4249cd1a',
    origin: GF_SOURCE,
    url: GF_URL + 'ericaone/v13/WBLnrEXccV9VGrOKmGD1W0_MJMGxiQ.ttf',
    variant: 'regular',
    family: 'Erica One',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'c958b9f0-d344-49e1-b8c0-ecaa64a4947b',
    origin: GF_SOURCE,
    url: GF_URL + 'bungeehairline/v8/snfys0G548t04270a_ljTLUVrv-7YB2dQ5ZPqQ.ttf',
    variant: 'regular',
    family: 'Bungee Hairline',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'cd39375f-2656-44df-8db2-aa4e70371600',
    origin: GF_SOURCE,
    url: GF_URL + 'supermercadoone/v12/OpNXnpQWg8jc_xps_Gi14kVVEXOn60b3MClBRTs.ttf',
    variant: 'regular',
    family: 'Supermercado One',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '7d7fe12f-2009-4ca4-8b2a-4a1651919a51',
    origin: GF_SOURCE,
    url: GF_URL + 'akayakanadaka/v4/N0bM2S5CPO5oOQqvazoRRb-8-PfRS5VBBSSF.ttf',
    variant: 'regular',
    family: 'Akaya Kanadaka',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '47129f20-84ba-43db-9105-da0a007d28ae',
    origin: GF_SOURCE,
    url: GF_URL + 'nerkoone/v5/m8JQjfZSc7OXlB3ZMOjzcJ5BZmqa3A.ttf',
    variant: 'regular',
    family: 'Nerko One',
    category: 'handwriting',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f6147c97-a702-4df0-a5e6-3dd7f95028c3',
    origin: GF_SOURCE,
    url: GF_URL + 'missfajardose/v12/E21-_dn5gvrawDdPFVl-N0Ajb8qvWPaJq4no.ttf',
    variant: 'regular',
    family: 'Miss Fajardose',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '978e6c94-5131-4f01-9255-e56660f521d1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librebarcodeean13text/v9/wlpigxXFDU1_oCu9nfZytgIqSG0XRcJm_OQiB96PAGEki52WfA.ttf',
    variant: 'regular',
    family: 'Libre Barcode EAN13 Text',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '6acae12f-fe34-44ec-8a90-3fc0dfe120d3',
    origin: GF_SOURCE,
    url: GF_URL + 'stalinistone/v28/MQpS-WezM9W4Dd7D3B7I-UT7eZ-UPyacPbo.ttf',
    variant: 'regular',
    family: 'Stalinist One',
    category: 'display',
    version: 'v28',
    subset: 'latin',
  },
  {
    id: 'ec0a1e69-5a6d-47f4-ad6b-933541ea482b',
    origin: GF_SOURCE,
    url: GF_URL + 'londrinasketch/v11/c4m41npxGMTnomOHtRU68eIJn8qfWWn5Pos6CA.ttf',
    variant: 'regular',
    family: 'Londrina Sketch',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'fc832298-a1ce-4331-a9af-64eb1626d228',
    origin: GF_SOURCE,
    url: GF_URL + 'rugeboogie/v13/JIA3UVFwbHRF_GIWSMhKNROiPzUveSxy.ttf',
    variant: 'regular',
    family: 'Ruge Boogie',
    category: 'handwriting',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '5fda188f-09cd-41f5-925c-8268e80b08ac',
    origin: GF_SOURCE,
    url: GF_URL + 'smokum/v13/TK3iWkUbAhopmrdGHjUHte5fKg.ttf',
    variant: 'regular',
    family: 'Smokum',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'a6bab76b-04ac-4632-9b56-473764db1962',
    origin: GF_SOURCE,
    url: GF_URL + 'unlock/v12/7Au-p_8ykD-cDl7GKAjSwkUVOQ.ttf',
    variant: 'regular',
    family: 'Unlock',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '9fc59707-0a54-4d8f-94be-638d4cd97030',
    origin: GF_SOURCE,
    url: GF_URL + 'irishgrover/v12/buExpoi6YtLz2QW7LA4flVgf-P5Oaiw4cw.ttf',
    variant: 'regular',
    family: 'Irish Grover',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'e1fd9334-cf3e-4ab8-8578-711900138f9f',
    origin: GF_SOURCE,
    url: GF_URL + 'sunshiney/v13/LDIwapGTLBwsS-wT4vcgE8moUePWkg.ttf',
    variant: 'regular',
    family: 'Sunshiney',
    category: 'handwriting',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '454bf1cf-1149-4482-b830-b280a689e86e',
    origin: GF_SOURCE,
    url: GF_URL + 'butterflykids/v11/ll8lK2CWTjuqAsXDqlnIbMNs5S4arxFrAX1D.ttf',
    variant: 'regular',
    family: 'Butterfly Kids',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'd9b098e5-8b26-4ed2-9f21-06bdf06f859c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librebarcode39extended/v15/8At7Gt6_O5yNS0-K4Nf5U922qSzhJ3dUdfJpwNUgfNRCOZ1GOBw.ttf',
    variant: 'regular',
    family: 'Libre Barcode 39 Extended',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'b3386e32-693c-4174-a5bb-012a2912e71a',
    origin: GF_SOURCE,
    url: GF_URL + 'macondo/v11/RrQQboN9-iB1IXmOS2XO0LBBd4Y.ttf',
    variant: 'regular',
    family: 'Macondo',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8c5c3f83-d6bf-48a6-b30a-3bab97546474',
    origin: GF_SOURCE,
    url: GF_URL + 'liujianmaocao/v7/845DNN84HJrccNonurqXILGpvCOoferVKGWsUo8.ttf',
    variant: 'regular',
    family: 'Liu Jian Mao Cao',
    category: 'handwriting',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '7853a862-dd6d-4d9c-809a-e2981c1821f1',
    origin: GF_SOURCE,
    url: GF_URL + 'trochut/v10/CHymV-fDDlP9bDIw3sinWVokMnIllmA.ttf',
    variant: '700',
    family: 'Trochut',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '916fc34e-b633-4579-af66-c568791c0165',
    origin: GF_SOURCE,
    url: GF_URL + 'trochut/v10/CHyhV-fDDlP9bDIw1naCeXAKPns8jw.ttf',
    variant: 'italic',
    family: 'Trochut',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '7f818b86-fc23-4d20-8800-048797f64959',
    origin: GF_SOURCE,
    url: GF_URL + 'trochut/v10/CHyjV-fDDlP9bDIw5nSIfVIPLns.ttf',
    variant: 'regular',
    family: 'Trochut',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '88a41ecc-541b-420a-b29e-6156d0c712ad',
    origin: GF_SOURCE,
    url: GF_URL + 'suwannaphum/v16/jAnCgHV7GtDvc8jbe8hXXIWl_8C0Wg2V.ttf',
    variant: 'regular',
    family: 'Suwannaphum',
    category: 'display',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '0c938882-a46f-4ed9-987b-134bf2d25454',
    origin: GF_SOURCE,
    url: GF_URL + 'lexendgiga/v10/PlI5Fl67Mah5Y8yMHE7lkVxEt8CwfGaD.ttf',
    variant: 'regular',
    family: 'Lexend Giga',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '9ea5c161-680b-420d-8375-e4e3b4b9f6b3',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZWFGb7hR12BxqH_VqnlS0SvWWUy1uW.ttf',
    variant: '900italic',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '3b3d5ce5-1d80-4402-a1e6-c2804626910f',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZQFGb7hR12BxqPe0UDkicWn2CEyw.ttf',
    variant: '900',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '921bb8ba-cb74-456f-9c44-13c0cc798ddb',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZWFGb7hR12BxqH_VqDlC0SvWWUy1uW.ttf',
    variant: '800italic',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '434a0c27-c493-456e-b1c4-3918ff69e24c',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZQFGb7hR12BxqPX0QDkicWn2CEyw.ttf',
    variant: '800',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '835b3ab7-f2ed-4ffa-80f2-ca4e898f17cb',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZWFGb7hR12BxqH_Vqfly0SvWWUy1uW.ttf',
    variant: '700italic',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '5c49dc1e-d32b-452b-9745-4d30312db021',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZQFGb7hR12BxqPQ0cDkicWn2CEyw.ttf',
    variant: '700',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'f523e833-2e06-4ecf-b6bf-7975a81524fa',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZWFGb7hR12BxqH_Vr7li0SvWWUy1uW.ttf',
    variant: '600italic',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '197ea4c5-277a-40e1-a61b-5121635ef51c',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZQFGb7hR12BxqPJ0YDkicWn2CEyw.ttf',
    variant: '600',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '6051f075-c02f-450d-995f-ad20f570a0a4',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZWFGb7hR12BxqH_VrXkS0SvWWUy1uW.ttf',
    variant: '500italic',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '3066345a-79d8-496d-837b-b9f4de0d8b4b',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZQFGb7hR12BxqPC0EDkicWn2CEyw.ttf',
    variant: '500',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'cf0cace8-6aa0-45d2-8a24-62677ef4f183',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZRFGb7hR12BxqH_WIjuAkalnmd.ttf',
    variant: 'italic',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '1c002d87-2cb3-4a0a-bea7-1ffc8bc6a957',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZTFGb7hR12Bxq3_2gnmgwKlg.ttf',
    variant: 'regular',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'f1ee4073-4ae5-4182-8066-d6b7417004e7',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZWFGb7hR12BxqH_VqPkC0SvWWUy1uW.ttf',
    variant: '300italic',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '844339d5-96bd-4cb3-94f7-02bb2ac93c11',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZQFGb7hR12BxqPU0ADkicWn2CEyw.ttf',
    variant: '300',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '46b8d548-7ddf-4f45-bbe5-64ec1513c694',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZWFGb7hR12BxqH_Vrrky0SvWWUy1uW.ttf',
    variant: '200italic',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'e574672a-1cdd-48f9-812d-cb999ed4e887',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZQFGb7hR12BxqPN0MDkicWn2CEyw.ttf',
    variant: '200',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '8516e4f3-48f1-4106-a2e3-29a9fa3b294f',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZXFGb7hR12BxqH_VpHsg04k2md0kI.ttf',
    variant: '100italic',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ae215803-d556-4516-b044-4e0c8320f84a',
    origin: GF_SOURCE,
    url: GF_URL + 'grenze/v4/O4ZRFGb7hR12BxqPm2IjuAkalnmd.ttf',
    variant: '100',
    family: 'Grenze',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd1d32f09-cc86-4914-8078-c2508faefc6f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'imfelldoublepicasc/v12/neIazDmuiMkFo6zj_sHpQ8teNbWlwBB_hXjJ4Y0Eeru2dGg.ttf',
    variant: 'regular',
    family: 'IM Fell Double Pica SC',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'bac8fcc7-987d-44a4-ae78-fd46f70905f2',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'brygada1918/v3/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfc9hscv7GykboaLg.ttf',
    variant: '700italic',
    family: 'Brygada 1918',
    category: 'serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '9f44ab18-0710-496e-8329-252fca780e08',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'brygada1918/v3/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfczxscv7GykboaLg.ttf',
    variant: '600italic',
    family: 'Brygada 1918',
    category: 'serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '52f2c51e-e0ce-45eb-a040-f43a0e40e341',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'brygada1918/v3/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfcIxwcv7GykboaLg.ttf',
    variant: '500italic',
    family: 'Brygada 1918',
    category: 'serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '30baa483-6c24-4acb-a39c-53a4e74d283b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'brygada1918/v3/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfcERwcv7GykboaLg.ttf',
    variant: 'italic',
    family: 'Brygada 1918',
    category: 'serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'acf4ebce-b226-4e92-8158-29482071e116',
    origin: GF_SOURCE,
    url:
      GF_URL + 'brygada1918/v3/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y4iY-V8Wu5O3gbo.ttf',
    variant: '700',
    family: 'Brygada 1918',
    category: 'serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '3b9aeda1-a7a3-4c52-a275-7d348a7eb8e5',
    origin: GF_SOURCE,
    url:
      GF_URL + 'brygada1918/v3/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y7GY-V8Wu5O3gbo.ttf',
    variant: '600',
    family: 'Brygada 1918',
    category: 'serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'c14935de-1889-4e08-8773-915ac96a523e',
    origin: GF_SOURCE,
    url:
      GF_URL + 'brygada1918/v3/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y12f-V8Wu5O3gbo.ttf',
    variant: '500',
    family: 'Brygada 1918',
    category: 'serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '66644c60-0495-447a-bb18-c5e00f0ed824',
    origin: GF_SOURCE,
    url:
      GF_URL + 'brygada1918/v3/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y2-f-V8Wu5O3gbo.ttf',
    variant: 'regular',
    family: 'Brygada 1918',
    category: 'serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '49092f9b-1294-4ac3-aafb-cf8800b2d4ba',
    origin: GF_SOURCE,
    url: GF_URL + 'mrbedfort/v11/MQpR-WCtNZSWAdTMwBicliq0XZe_Iy8.ttf',
    variant: 'regular',
    family: 'Mr Bedfort',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f1a148b5-271c-48ed-a1f8-117323bc9db7',
    origin: GF_SOURCE,
    url:
      GF_URL + 'sedgwickavedisplay/v8/xfuu0XPgU3jZPUoUo3ScvmPi-NapQ8OxM2czd-YnOzUD.ttf',
    variant: 'regular',
    family: 'Sedgwick Ave Display',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e13f8193-0741-4421-8ab3-494d3cf7ae37',
    origin: GF_SOURCE,
    url: GF_URL + 'zhimangxing/v7/f0Xw0ey79sErYFtWQ9a2rq-g0actfektIJ0.ttf',
    variant: 'regular',
    family: 'Zhi Mang Xing',
    category: 'handwriting',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'b2dc98a0-409c-465f-abd5-222c17bfb1e5',
    origin: GF_SOURCE,
    url: GF_URL + 'poorstory/v10/jizfREFUsnUct9P6cDfd4OmnLD0Z4zM.ttf',
    variant: 'regular',
    family: 'Poor Story',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8ae15f1d-580b-4321-8525-8bcbdd4a282c',
    origin: GF_SOURCE,
    url: GF_URL + 'taprom/v14/UcCn3F82JHycULbFQyk3-0kvHg.ttf',
    variant: 'regular',
    family: 'Taprom',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'e0200da5-295a-4ca8-8d1d-51b5cb78143c',
    origin: GF_SOURCE,
    url: GF_URL + 'galindo/v10/HI_KiYMeLqVKqwyuQ5HiRp-dhpQ.ttf',
    variant: 'regular',
    family: 'Galindo',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '58d77832-5d14-4de7-9e9b-fd4d9fa9b5f0',
    origin: GF_SOURCE,
    url: GF_URL + 'seymourone/v10/4iCp6Khla9xbjQpoWGGd0myIPYBvgpUI.ttf',
    variant: 'regular',
    family: 'Seymour One',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '00e28f82-84ca-4c17-8de5-fd1461b0a75e',
    origin: GF_SOURCE,
    url: GF_URL + 'kumaroneoutline/v8/Noao6VH62pyLP0fsrZ-v18wlUEcX9zDwRQu8EGKF.ttf',
    variant: 'regular',
    family: 'Kumar One Outline',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '407aca64-ff4a-4164-aaf4-236482dbb54f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1jiSv7Wp05GNi3k.ttf',
    variant: '900italic',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'fa8b48e7-2008-4321-8595-2db5212315aa',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1jLSv7Wp05GNi3k.ttf',
    variant: '800italic',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8ce43aaa-f365-44dd-900b-de7d409e3741',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1isSv7Wp05GNi3k.ttf',
    variant: '700italic',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '661eab55-62d5-4f78-8f2d-c90099aac771',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1iVSv7Wp05GNi3k.ttf',
    variant: '600italic',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '681a7571-bfce-46dd-96b3-8270260f8538',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1h5Tf7Wp05GNi3k.ttf',
    variant: '500italic',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '75b038c2-a3ec-4757-afc4-817e39aa5099',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1hLTf7Wp05GNi3k.ttf',
    variant: 'italic',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '3fcdee6e-5c77-4964-aab2-4e644034d34a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1gVTf7Wp05GNi3k.ttf',
    variant: '300italic',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '02d80236-2fa1-49a9-b76e-e54a5af5e9a0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1jLTf7Wp05GNi3k.ttf',
    variant: '200italic',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'c86e0c2a-b22e-4a71-9942-3e721049f2cf',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1hLTP7Wp05GNi3k.ttf',
    variant: '100italic',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '7e587c27-c6f4-4775-bd09-89cb70705469',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcHhyjDvTShUtWNg.ttf',
    variant: '900',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'd794a9a0-4fb1-4750-a539-4eccd0f36956',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcNxyjDvTShUtWNg.ttf',
    variant: '800',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'e35625ec-f2fa-4aad-b6c4-b81e760612c0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcUByjDvTShUtWNg.ttf',
    variant: '700',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '411b5afb-d0ba-4689-bd02-a02cf1515a7e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcaRyjDvTShUtWNg.ttf',
    variant: '600',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'da9128af-a066-43b5-8bd2-34831a2467ed',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIchRujDvTShUtWNg.ttf',
    variant: '500',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'f54a5702-74b6-4ba8-95ec-659ca07334c6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxujDvTShUtWNg.ttf',
    variant: 'regular',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '5d70f46c-70e9-4150-87b4-c036c2428bf6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIc6RujDvTShUtWNg.ttf',
    variant: '300',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ac2fd724-e696-436f-9b0c-136413144705',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcNxujDvTShUtWNg.ttf',
    variant: '200',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4938622c-4c6a-412f-9aea-b5df3b326f75',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'fraunces/v10/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxqjDvTShUtWNg.ttf',
    variant: '100',
    family: 'Fraunces',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'aa24b2c1-e2a8-4a9c-8257-2cd95e3bea57',
    origin: GF_SOURCE,
    url:
      GF_URL + 'shipporiminchob1/v4/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKLoxP3nih5H4Uug.ttf',
    variant: '800',
    family: 'Shippori Mincho B1',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'b5b68723-3b03-4907-9ff2-53b113dc02c8',
    origin: GF_SOURCE,
    url:
      GF_URL + 'shipporiminchob1/v4/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKMo9P3nih5H4Uug.ttf',
    variant: '700',
    family: 'Shippori Mincho B1',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'cdc2568c-f153-4d27-8a34-7ad2053905b2',
    origin: GF_SOURCE,
    url:
      GF_URL + 'shipporiminchob1/v4/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKVo5P3nih5H4Uug.ttf',
    variant: '600',
    family: 'Shippori Mincho B1',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'cc65d3c1-9e6c-41cb-832a-0e2a6ccfffea',
    origin: GF_SOURCE,
    url:
      GF_URL + 'shipporiminchob1/v4/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKeolP3nih5H4Uug.ttf',
    variant: '500',
    family: 'Shippori Mincho B1',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '546caae2-1da1-4f4a-b7da-16bc484dc238',
    origin: GF_SOURCE,
    url: GF_URL + 'shipporiminchob1/v4/wXK2E2wCr44tulPdnn-xbIpJ9RgT9-nyjqBr1lO97Q.ttf',
    variant: 'regular',
    family: 'Shippori Mincho B1',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '0494069b-1d3e-41ac-bc56-b0072474f47d',
    origin: GF_SOURCE,
    url: GF_URL + 'miltonian/v16/zOL-4pbPn6Ne9JqTg9mr6e5As-FeiQ.ttf',
    variant: 'regular',
    family: 'Miltonian',
    category: 'display',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '57be0373-10c2-4684-b0d3-0f93f4d35397',
    origin: GF_SOURCE,
    url: GF_URL + 'chenla/v15/SZc43FDpIKu8WZ9eXxfonUPL6Q.ttf',
    variant: 'regular',
    family: 'Chenla',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '9f17c44e-ceca-4087-819f-f616e04b6b5d',
    origin: GF_SOURCE,
    url: GF_URL + 'almendradisplay/v13/0FlPVOGWl1Sb4O3tETtADHRRlZhzXS_eTyer338.ttf',
    variant: 'regular',
    family: 'Almendra Display',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '14ecb203-27a9-4524-a392-4c44b992f7db',
    origin: GF_SOURCE,
    url: GF_URL + 'raviprakash/v9/gokpH6fsDkVrF9Bv9X8SOAKHmNZEq6TTFw.ttf',
    variant: 'regular',
    family: 'Ravi Prakash',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'ee5eedac-05f3-472b-84da-1f412e4de41b',
    origin: GF_SOURCE,
    url: GF_URL + 'fascinateinline/v12/jVyR7mzzB3zc-jp6QCAu60poNqIy1g3CfRXxWZQ.ttf',
    variant: 'regular',
    family: 'Fascinate Inline',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '57a57086-d698-4a16-bdeb-0b27cbe89e31',
    origin: GF_SOURCE,
    url: GF_URL + 'novaslim/v14/Z9XUDmZNQAuem8jyZcn-yMOInrib9Q.ttf',
    variant: 'regular',
    family: 'Nova Slim',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'cf71763f-82c5-48a2-ae3c-ab2e1f08cdcf',
    origin: GF_SOURCE,
    url: GF_URL + 'goblinone/v12/CSR64z1ZnOqZRjRCBVY_TOcATNt_pOU.ttf',
    variant: 'regular',
    family: 'Goblin One',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '1d4e5266-707f-49b1-9d31-cb0220f28412',
    origin: GF_SOURCE,
    url: GF_URL + 'almendrasc/v13/Iure6Yx284eebowr7hbyTZZJprVA4XQ0.ttf',
    variant: 'regular',
    family: 'Almendra SC',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'bce3c355-adac-4f4e-b069-f02aa971e8d7',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMyoSOZAHDWwgECi.ttf',
    variant: '800italic',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '7a7176a1-1f47-493e-be04-b6e4a1a6815f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMzPSOZAHDWwgECi.ttf',
    variant: '700italic',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'dca6ba2a-46ea-43b3-8bee-133b4a1d1a3d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMz2SOZAHDWwgECi.ttf',
    variant: '600italic',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '71e0836e-c6b5-4994-9fae-6154cf7e01f5',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMwaT-ZAHDWwgECi.ttf',
    variant: '500italic',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '27b2c942-7742-4f77-af84-af9cdcfaa628',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMwoT-ZAHDWwgECi.ttf',
    variant: 'italic',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '26653a66-83f5-45bf-8857-7d0f58d5b3bc',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMx2T-ZAHDWwgECi.ttf',
    variant: '300italic',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '081c0ac0-85c9-4b1a-804b-ee9b4f8de7e8',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMyoT-ZAHDWwgECi.ttf',
    variant: '200italic',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '5ebe8a67-aba4-4fc6-94ba-644870d45275',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438w-IjADOxEPjCggA.ttf',
    variant: '800',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'a1f14a1c-d1ef-4760-bcce-aea08a3fb445',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wn4jADOxEPjCggA.ttf',
    variant: '700',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'e0ffafec-2c38-4422-95f4-f779f5c44e6d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wpojADOxEPjCggA.ttf',
    variant: '600',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '89ea962f-f717-41fa-9d98-4547c97b3851',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wSo_ADOxEPjCggA.ttf',
    variant: '500',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '98c7cd71-89db-4736-9da1-b23ddfdf6e9d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438weI_ADOxEPjCggA.ttf',
    variant: 'regular',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'fb996969-a8b1-4e0f-ba52-5eb88501d6b2',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wJo_ADOxEPjCggA.ttf',
    variant: '300',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '4ea8679a-0087-486a-b913-4693d55c6150',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'newsreader/v7/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438w-I_ADOxEPjCggA.ttf',
    variant: '200',
    family: 'Newsreader',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '3c4ba773-7801-4411-bdce-82380110601f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'imfellgreatprimersc/v12/ga6daxBOxyt6sCqz3fjZCTFCTUDMHagsQKdDTLf9BXz0s8FG.ttf',
    variant: 'regular',
    family: 'IM Fell Great Primer SC',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'ebb99c38-13b6-4e9b-9e70-2a30ad794627',
    origin: GF_SOURCE,
    url: GF_URL + 'purplepurse/v11/qWctB66gv53iAp-Vfs4My6qyeBb_ujA4ug.ttf',
    variant: 'regular',
    family: 'Purple Purse',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0e511dd6-ae42-4ee3-9360-7f56d1450216',
    origin: GF_SOURCE,
    url: GF_URL + 'bokor/v15/m8JcjfpeeaqTiR2WdInbcaxE.ttf',
    variant: 'regular',
    family: 'Bokor',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '47ed92cc-b145-4500-96dd-2f5e741121ca',
    origin: GF_SOURCE,
    url: GF_URL + 'gfsneohellenic/v15/8QIWdiDOrfiq0b7R8O1Iw9WLcY5jL5r37oQbeMFe985V.ttf',
    variant: '700italic',
    family: 'GFS Neohellenic',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '1a82660d-f95c-4be4-9800-3e920a1322bb',
    origin: GF_SOURCE,
    url: GF_URL + 'gfsneohellenic/v15/8QIUdiDOrfiq0b7R8O1Iw9WLcY5rkYdr644fWsRO9w.ttf',
    variant: '700',
    family: 'GFS Neohellenic',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'c50c8c05-8e66-4fbb-b82b-77861f191144',
    origin: GF_SOURCE,
    url: GF_URL + 'gfsneohellenic/v15/8QITdiDOrfiq0b7R8O1Iw9WLcY5jL6JLwaATU91X.ttf',
    variant: 'italic',
    family: 'GFS Neohellenic',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '0a8a0795-44ab-4eca-8973-60f743c95e1b',
    origin: GF_SOURCE,
    url: GF_URL + 'gfsneohellenic/v15/8QIRdiDOrfiq0b7R8O1Iw9WLcY5TLahP46UDUw.ttf',
    variant: 'regular',
    family: 'GFS Neohellenic',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'a4dc1a7c-909a-4a76-ba85-ec1c77e1d9de',
    origin: GF_SOURCE,
    url: GF_URL + 'zcoolkuaile/v7/tssqApdaRQokwFjFJjvM6h2WpozzoXhC2g.ttf',
    variant: 'regular',
    family: 'ZCOOL KuaiLe',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '9231c0c2-63ac-4595-9ebd-ff07e89faebc',
    origin: GF_SOURCE,
    url: GF_URL + 'keaniaone/v10/zOL54pXJk65E8pXardnuycRuv-hHkOs.ttf',
    variant: 'regular',
    family: 'Keania One',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '91584085-aea7-4362-b447-cc04523a86a2',
    origin: GF_SOURCE,
    url: GF_URL + 'snowburstone/v10/MQpS-WezKdujBsXY3B7I-UT7eZ-UPyacPbo.ttf',
    variant: 'regular',
    family: 'Snowburst One',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'bd46b45a-c66a-469c-b5e8-1999e5f9685d',
    origin: GF_SOURCE,
    url: GF_URL + 'londrinashadow/v12/oPWX_kB4kOQoWNJmjxLV5JuoCUlXRlaSxkrMCQ.ttf',
    variant: 'regular',
    family: 'Londrina Shadow',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '47e99b96-ae95-433f-a2e9-3058ffbd1a26',
    origin: GF_SOURCE,
    url: GF_URL + 'plaster/v14/DdTm79QatW80eRh4Ei5JOtLOeLI.ttf',
    variant: 'regular',
    family: 'Plaster',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '7a4e247f-ee3a-42b7-a2b1-da583f06f0d7',
    origin: GF_SOURCE,
    url: GF_URL + 'varta/v5/Qw3AZQpJHj_6LzHUngWbrFkDH1x9UzkEirE-9PGLfQ.ttf',
    variant: '700',
    family: 'Varta',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '96b92deb-b825-4eba-9abe-439ce8ff6de8',
    origin: GF_SOURCE,
    url: GF_URL + 'varta/v5/Qw3AZQpJHj_6LzHUngWbrFkDH1x9ajkEirE-9PGLfQ.ttf',
    variant: '600',
    family: 'Varta',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '34b37392-0377-4b3b-bb63-888d7cb60d50',
    origin: GF_SOURCE,
    url: GF_URL + 'varta/v5/Qw3AZQpJHj_6LzHUngWbrFkDH1x9hj4EirE-9PGLfQ.ttf',
    variant: '500',
    family: 'Varta',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '13292b26-70e4-48c5-b7ec-8d252f431782',
    origin: GF_SOURCE,
    url: GF_URL + 'varta/v5/Qw3AZQpJHj_6LzHUngWbrFkDH1x9tD4EirE-9PGLfQ.ttf',
    variant: 'regular',
    family: 'Varta',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '9576b017-2b9c-4472-bcfa-16fb270b4ee1',
    origin: GF_SOURCE,
    url: GF_URL + 'varta/v5/Qw3AZQpJHj_6LzHUngWbrFkDH1x96j4EirE-9PGLfQ.ttf',
    variant: '300',
    family: 'Varta',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '53faa414-fc47-4b10-b42f-b8dcb76fe25a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWbRk0Z7AXkODN94.ttf',
    variant: '900italic',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '56dc16df-280c-4b59-99ef-89aa827fd523',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWZ1k0Z7AXkODN94.ttf',
    variant: '800italic',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'bd926bb2-b833-4f32-b9ff-f6a429414845',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWfpk0Z7AXkODN94.ttf',
    variant: '700italic',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'd875a1a7-8cb0-45f6-9f6b-e21d55fc34c6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWcNk0Z7AXkODN94.ttf',
    variant: '600italic',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '40eb08d9-9f96-4095-aed2-f5f27dcb53fc',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWS9j0Z7AXkODN94.ttf',
    variant: '500italic',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '35b80f23-8928-4a86-b00a-f581527b4b10',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWR1j0Z7AXkODN94.ttf',
    variant: 'italic',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '6ab6ca29-8748-4704-88ba-6d8fb6ef50cb',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWUNj0Z7AXkODN94.ttf',
    variant: '300italic',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '37e79448-4b27-48d6-bf48-0bbdca425435',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWZ1j0Z7AXkODN94.ttf',
    variant: '200italic',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '162a9d51-823b-4c3e-9ed3-2f87506af436',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWR1i0Z7AXkODN94.ttf',
    variant: '100italic',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '2da03a24-1b68-4e8e-a332-bcedb1a77c8a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2cxHfUg25riW1OD.ttf',
    variant: '900',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '8e46d724-b527-4a9d-8a0f-6e6022330583',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2cYHfUg25riW1OD.ttf',
    variant: '800',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '5c838b0d-5107-460b-a214-d35bc4b5f6bb',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2d_HfUg25riW1OD.ttf',
    variant: '700',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '6da4b0b6-5590-49f0-ac80-cc6d8ed2fc02',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2dGHfUg25riW1OD.ttf',
    variant: '600',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '039bd9c8-8247-4ec6-9c14-2b29cdfb5f60',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eqGvUg25riW1OD.ttf',
    variant: '500',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'b1c3eeac-4155-4c32-bdd9-2b6eb8010f31',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eYGvUg25riW1OD.ttf',
    variant: 'regular',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'f2d7a612-009c-41a9-9432-534fcf4e0103',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2fGGvUg25riW1OD.ttf',
    variant: '300',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'e90de401-5c2b-440b-9b91-1e0344f02aa1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2cYGvUg25riW1OD.ttf',
    variant: '200',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '34920702-0044-4d69-9005-53ad65325266',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'texturina/v9/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eYG_Ug25riW1OD.ttf',
    variant: '100',
    family: 'Texturina',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '7ef24ae9-da17-4a11-9b56-fdba8470da78',
    origin: GF_SOURCE,
    url: GF_URL + 'drsugiyama/v12/HTxoL2k4N3O9n5I1boGI7abRM4-t-g7y.ttf',
    variant: 'regular',
    family: 'Dr Sugiyama',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'cfb86e76-88cf-4c81-acbc-5ea5ea7c4c46',
    origin: GF_SOURCE,
    url: GF_URL + 'peddana/v10/aFTU7PBhaX89UcKWhh2aBYyMcKw.ttf',
    variant: 'regular',
    family: 'Peddana',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8d038b8f-2afe-4fb2-9c67-ad9e1823bf43',
    origin: GF_SOURCE,
    url: GF_URL + 'snippet/v12/bWt47f7XfQH9Gupu2v_Afcp9QWc.ttf',
    variant: 'regular',
    family: 'Snippet',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '68b26e5b-6fd1-4e83-a9fd-8387e5dee819',
    origin: GF_SOURCE,
    url: GF_URL + 'devonshire/v11/46kqlbDwWirWr4gtBD2BX0Vq01lYAZM.ttf',
    variant: 'regular',
    family: 'Devonshire',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'dac4ad5e-c9a8-44ae-a82a-d18204c66716',
    origin: GF_SOURCE,
    url: GF_URL + 'revalia/v9/WwkexPimBE2-4ZPEeVruNIgJSNM.ttf',
    variant: 'regular',
    family: 'Revalia',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'ddc670b7-dce4-437d-b3a4-9d80fe7e1e2d',
    origin: GF_SOURCE,
    url: GF_URL + 'jacquesfrancois/v10/ZXu9e04ZvKeOOHIe1TMahbcIU2cgmcPqoeRWfbs.ttf',
    variant: 'regular',
    family: 'Jacques Francois',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '679eb5d2-a1b9-4eed-9737-b6dbf3fe96a7',
    origin: GF_SOURCE,
    url: GF_URL + 'smythe/v13/MwQ3bhT01--coT1BOLh_uGInjA.ttf',
    variant: 'regular',
    family: 'Smythe',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '86aa4487-0c25-4f11-8090-23f50bb73c1a',
    origin: GF_SOURCE,
    url: GF_URL + 'underdog/v11/CHygV-jCElj7diMroVSiU14GN2Il.ttf',
    variant: 'regular',
    family: 'Underdog',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '4203b0c9-d120-45e3-a692-2f2739add158',
    origin: GF_SOURCE,
    url: GF_URL + 'medievalsharp/v14/EvOJzAlL3oU5AQl2mP5KdgptAq96MwvXLDk.ttf',
    variant: 'regular',
    family: 'MedievalSharp',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'c6122ca2-c709-4e70-8905-f9422cdf5bd9',
    origin: GF_SOURCE,
    url: GF_URL + 'lexendmega/v10/qFdA35aBi5JtHD41zSTFEv7K6BsAikI7.ttf',
    variant: 'regular',
    family: 'Lexend Mega',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '10809c1c-d56d-4292-8043-20e450d99a21',
    origin: GF_SOURCE,
    url: GF_URL + 'freehand/v14/cIf-Ma5eqk01VjKTgAmBTmUOmZJk.ttf',
    variant: 'regular',
    family: 'Freehand',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'ac8c8946-73ff-4ac0-8f1f-1c5088e2aa00',
    origin: GF_SOURCE,
    url: GF_URL + 'girassol/v6/JTUUjIo_-DK48laaNC9Nz2pJzxbi.ttf',
    variant: 'regular',
    family: 'Girassol',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '9b5473da-c687-4ad5-9dbf-96b751467e4e',
    origin: GF_SOURCE,
    url: GF_URL + 'miniver/v11/eLGcP-PxIg-5H0vC770Cy8r8fWA.ttf',
    variant: 'regular',
    family: 'Miniver',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a50ef7fd-58c6-4e46-b80c-081a66ffba0c',
    origin: GF_SOURCE,
    url: GF_URL + 'odormeanchey/v14/raxkHiKDttkTe1aOGcJMR1A_4mrY2zqUKafv.ttf',
    variant: 'regular',
    family: 'Odor Mean Chey',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '4c05eb3b-906d-4716-8bba-18e3358b3b1a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sansitaswashed/v5/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSWx1ubToVehmEa4Q.ttf',
    variant: '900',
    family: 'Sansita Swashed',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '35e0734b-6497-4d7b-9cd9-afc2cb7f86f8',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sansitaswashed/v5/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSWzRubToVehmEa4Q.ttf',
    variant: '800',
    family: 'Sansita Swashed',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'def769fe-817f-4d64-81d6-4a6f1702ed56',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sansitaswashed/v5/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW1NubToVehmEa4Q.ttf',
    variant: '700',
    family: 'Sansita Swashed',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '34f5f0b2-5143-451c-a9b2-387e6b1f6aee',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sansitaswashed/v5/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW2pubToVehmEa4Q.ttf',
    variant: '600',
    family: 'Sansita Swashed',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '5068e4b6-8203-43e4-8750-beeef0604309',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sansitaswashed/v5/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW4ZpbToVehmEa4Q.ttf',
    variant: '500',
    family: 'Sansita Swashed',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'd94186d9-7ad0-4bb3-9040-d56fdb13c693',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sansitaswashed/v5/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW7RpbToVehmEa4Q.ttf',
    variant: 'regular',
    family: 'Sansita Swashed',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '58a943db-88af-4d37-b3d2-dd91c371a071',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sansitaswashed/v5/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW-ppbToVehmEa4Q.ttf',
    variant: '300',
    family: 'Sansita Swashed',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '80f331a9-44e7-4379-bb1d-18ed5b23d760',
    origin: GF_SOURCE,
    url: GF_URL + 'felipa/v9/FwZa7-owz1Eu4F_wSNSEwM2zpA.ttf',
    variant: 'regular',
    family: 'Felipa',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '8e63fa1a-b5a9-4301-a4c5-680402c7b06a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librebarcode128text/v16/fdNv9tubt3ZEnz1Gu3I4-zppwZ9CWZ16Z0w5cV3Y6M90w4k.ttf',
    variant: 'regular',
    family: 'Libre Barcode 128 Text',
    category: 'display',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '897c5e17-7305-430e-8424-ac607bbd6b1a',
    origin: GF_SOURCE,
    url: GF_URL + 'emblemaone/v11/nKKT-GQ0F5dSY8vzG0rOEIRBHl57G_f_.ttf',
    variant: 'regular',
    family: 'Emblema One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '420b5694-feda-4e73-bb77-ca9a1f3c0023',
    origin: GF_SOURCE,
    url: GF_URL + 'jimnightshade/v10/PlIkFlu9Pb08Q8HLM1PxmB0g-OS4V3qKaMxD.ttf',
    variant: 'regular',
    family: 'Jim Nightshade',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'cb3a5400-229d-43a5-843d-7aa9a33ef68b',
    origin: GF_SOURCE,
    url: GF_URL + 'risque/v10/VdGfAZUfHosahXxoCUYVBJ-T5g.ttf',
    variant: 'regular',
    family: 'Risque',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ac4ce560-2537-4f37-84c1-4857eacb0a65',
    origin: GF_SOURCE,
    url: GF_URL + 'oldenburg/v10/fC1jPY5JYWzbywv7c4V6UU6oXyndrw.ttf',
    variant: 'regular',
    family: 'Oldenburg',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '92b6acff-e9ed-408f-b6b5-afdd16e91044',
    origin: GF_SOURCE,
    url: GF_URL + 'bahiana/v7/uU9PCBUV4YenPWJU7xPb3vyHmlI.ttf',
    variant: 'regular',
    family: 'Bahiana',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '84282be3-6701-4787-a3d3-f8a5db0c62b9',
    origin: GF_SOURCE,
    url: GF_URL + 'gupter/v4/2-cl9JNmxJqPO1Qs3bmbUsT5rZhaZg.ttf',
    variant: '700',
    family: 'Gupter',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c2b12c71-b761-43e6-aa0b-34e7b4fd6253',
    origin: GF_SOURCE,
    url: GF_URL + 'gupter/v4/2-cl9JNmxJqPO1Qslb-bUsT5rZhaZg.ttf',
    variant: '500',
    family: 'Gupter',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '7ee82fd6-bd79-4334-af60-c9b3db78db36',
    origin: GF_SOURCE,
    url: GF_URL + 'gupter/v4/2-cm9JNmxJqPO1QUYZa_Wu_lpA.ttf',
    variant: 'regular',
    family: 'Gupter',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '32e9e585-931b-4132-8b13-ced3c5b84825',
    origin: GF_SOURCE,
    url: GF_URL + 'ewert/v10/va9I4kzO2tFODYBvS-J3kbDP.ttf',
    variant: 'regular',
    family: 'Ewert',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'adfabd6b-0e9d-42ed-af34-a9e71a34d282',
    origin: GF_SOURCE,
    url: GF_URL + 'stick/v4/Qw3TZQpMCyTtJSvfvPVDMPoF.ttf',
    variant: 'regular',
    family: 'Stick',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '4b45b127-2fab-4993-9e50-9d4224d67074',
    origin: GF_SOURCE,
    url: GF_URL + 'princesssofia/v11/qWczB6yguIb8DZ_GXZst16n7GRz7mDUoupoI.ttf',
    variant: 'regular',
    family: 'Princess Sofia',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b3e8437c-afa5-42ed-b858-0c44e5693734',
    origin: GF_SOURCE,
    url: GF_URL + 'bungeeoutline/v8/_6_mEDvmVP24UvU2MyiGDslL3Qg3YhJqPXxo.ttf',
    variant: 'regular',
    family: 'Bungee Outline',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e56c2eeb-9ade-4d85-8918-cfed15133579',
    origin: GF_SOURCE,
    url: GF_URL + 'longcang/v7/LYjAdGP8kkgoTec8zkRgrXArXN7HWQ.ttf',
    variant: 'regular',
    family: 'Long Cang',
    category: 'handwriting',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '08bf8204-c018-4819-b00f-3e13b395b722',
    origin: GF_SOURCE,
    url: GF_URL + 'gayathri/v6/MCoXzAb429DbBilWLLiE37v4LfQJwHbn.ttf',
    variant: '700',
    family: 'Gayathri',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '02b6ba81-036f-466c-a663-1bfa2ccb66c6',
    origin: GF_SOURCE,
    url: GF_URL + 'gayathri/v6/MCoQzAb429DbBilWLIA48J_wBugA.ttf',
    variant: 'regular',
    family: 'Gayathri',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'fdbfed05-ffd8-45c1-b748-e7f4296cc3ea',
    origin: GF_SOURCE,
    url: GF_URL + 'gayathri/v6/MCoWzAb429DbBilWLLhc-pvSA_gA2W8.ttf',
    variant: '100',
    family: 'Gayathri',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'fdcb671c-14b2-4c25-8116-e586effa60eb',
    origin: GF_SOURCE,
    url: GF_URL + 'sirinstencil/v11/mem4YaWwznmLx-lzGfN7MdRydchGBq6al6o.ttf',
    variant: 'regular',
    family: 'Sirin Stencil',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '566715df-c97f-4fa8-bb45-a056cbe71825',
    origin: GF_SOURCE,
    url: GF_URL + 'diplomata/v14/Cn-0JtiMXwhNwp-wKxyfYGxYrdM9Sg.ttf',
    variant: 'regular',
    family: 'Diplomata',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '66f05ff3-1dd9-4db2-aea4-4da6f9776857',
    origin: GF_SOURCE,
    url: GF_URL + 'bethellen/v5/WwkbxPW2BE-3rb_JNT-qEIAiVNo5xNY.ttf',
    variant: 'regular',
    family: 'Beth Ellen',
    category: 'handwriting',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a5a8efc3-9e9e-41ec-8c22-69e503d768d4',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jacquesfrancoisshadow/v11/KR1FBtOz8PKTMk-kqdkLVrvR0ECFrB6Pin-2_q8VsHuV5ULS.ttf',
    variant: 'regular',
    family: 'Jacques Francois Shadow',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '3b5de4dc-d4e7-4003-9147-609c7769f551',
    origin: GF_SOURCE,
    url: GF_URL + 'caesardressing/v11/yYLx0hLa3vawqtwdswbotmK4vrR3cbb6LZttyg.ttf',
    variant: 'regular',
    family: 'Caesar Dressing',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0984e6ca-19f9-49b0-93b3-50eb0f1df404',
    origin: GF_SOURCE,
    url: GF_URL + 'jollylodger/v10/BXRsvFTAh_bGkA1uQ48dlB3VWerT3ZyuqA.ttf',
    variant: 'regular',
    family: 'Jolly Lodger',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'cf8c6155-eca7-4742-92fc-36be6faa9694',
    origin: GF_SOURCE,
    url: GF_URL + 'librebarcode128/v16/cIfnMbdUsUoiW3O_hVviCwVjuLtXeJ_A_gMk0izH.ttf',
    variant: 'regular',
    family: 'Libre Barcode 128',
    category: 'display',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '07a90457-5053-459e-9f3d-d65e1620ffd8',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstenciltext/v5/5aUj9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcYR5R0LbmvRrwPmQ.ttf',
    variant: '900',
    family: 'Big Shoulders Stencil Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ba96c3bd-0b96-488b-89ae-e717d2afe815',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstenciltext/v5/5aUj9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcYRdRkLbmvRrwPmQ.ttf',
    variant: '800',
    family: 'Big Shoulders Stencil Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '21861128-dfea-4380-a7b3-1c2c8ae3ce32',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstenciltext/v5/5aUj9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcYRBRULbmvRrwPmQ.ttf',
    variant: '700',
    family: 'Big Shoulders Stencil Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '0c1ad293-d6e8-4db8-a435-302b45f3ff60',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstenciltext/v5/5aUj9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcYQlRELbmvRrwPmQ.ttf',
    variant: '600',
    family: 'Big Shoulders Stencil Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6c48f503-8561-4780-91c7-4bf76d094c4d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstenciltext/v5/5aUj9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcYQJQ0LbmvRrwPmQ.ttf',
    variant: '500',
    family: 'Big Shoulders Stencil Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'bbb3e974-2499-4b48-816e-5310b55ea465',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstenciltext/v5/5aUg9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcbz9ambTsehi.ttf',
    variant: 'regular',
    family: 'Big Shoulders Stencil Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '830f6e4e-e2be-4f8a-94ac-9de79ed4b0a1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstenciltext/v5/5aUj9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcYRRQkLbmvRrwPmQ.ttf',
    variant: '300',
    family: 'Big Shoulders Stencil Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e9e1df8b-f3be-4ed4-a565-91f657e22f0d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersstenciltext/v5/5aUi9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcYSZYGLxtPhi2eA.ttf',
    variant: '100',
    family: 'Big Shoulders Stencil Text',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '9b5225f0-e5f2-48fb-ba64-a7dec0dd7ca7',
    origin: GF_SOURCE,
    url: GF_URL + 'flavors/v12/FBV2dDrhxqmveJTpbkzlNqkG9UY.ttf',
    variant: 'regular',
    family: 'Flavors',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '92e8697b-b434-4e1e-b919-c58c66a6458d',
    origin: GF_SOURCE,
    url: GF_URL + 'dotgothic16/v4/v6-QGYjBJFKgyw5nSoDAGE7L435YPFrT.ttf',
    variant: 'regular',
    family: 'DotGothic16',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '96bd1502-bb15-4d30-8293-6a2ad03efd23',
    origin: GF_SOURCE,
    url: GF_URL + 'asset/v12/SLXGc1na-mM4cWImRJqExst1.ttf',
    variant: 'regular',
    family: 'Asset',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '5a3c422e-34b1-4a25-bea0-2233c8c4db2b',
    origin: GF_SOURCE,
    url: GF_URL + 'kavivanar/v8/o-0IIpQgyXYSwhxP7_Jb4j5Ba_2c7A.ttf',
    variant: 'regular',
    family: 'Kavivanar',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'fffab421-d509-43ff-a4a3-bde6e9bbcabf',
    origin: GF_SOURCE,
    url: GF_URL + 'bigelowrules/v11/RrQWboly8iR_I3KWSzeRuN0zT4cCH8WAJVk.ttf',
    variant: 'regular',
    family: 'Bigelow Rules',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b2ce25e1-8e0c-40b6-a44c-db7c06ed7499',
    origin: GF_SOURCE,
    url: GF_URL + 'mrssheppards/v11/PN_2Rfm9snC0XUGoEZhb91ig3vjxynMix4Y.ttf',
    variant: 'regular',
    family: 'Mrs Sheppards',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b61919cb-1fa9-4a17-8f7a-d77acf4b6c72',
    origin: GF_SOURCE,
    url: GF_URL + 'siemreap/v15/Gg82N5oFbgLvHAfNl2YbnA8DLXpe.ttf',
    variant: 'regular',
    family: 'Siemreap',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '65ccd502-2a56-4900-a524-ebe349848b4c',
    origin: GF_SOURCE,
    url: GF_URL + 'molle/v11/E21n_dL5hOXFhWEsXzgmVydREus.ttf',
    variant: 'italic',
    family: 'Molle',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '47473c8a-cc33-4f23-89dc-f6109af18aac',
    origin: GF_SOURCE,
    url: GF_URL + 'atomicage/v15/f0Xz0eug6sdmRFkYZZGL58Ht9a8GYeA.ttf',
    variant: 'regular',
    family: 'Atomic Age',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'e64ede1d-6978-47d9-a971-2fa7f0f4958f',
    origin: GF_SOURCE,
    url: GF_URL + 'hachimarupop/v6/HI_TiYoRLqpLrEiMAuO9Ysfz7rW1EM_btd8u.ttf',
    variant: 'regular',
    family: 'Hachi Maru Pop',
    category: 'handwriting',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '261db74c-14e5-4a02-b23c-fafcad299b8b',
    origin: GF_SOURCE,
    url: GF_URL + 'glassantiqua/v10/xfu30Wr0Wn3NOQM2piC0uXOjnL_wN6fRUkY.ttf',
    variant: 'regular',
    family: 'Glass Antiqua',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8812b830-fe95-4b0c-98cb-861af1697c3d',
    origin: GF_SOURCE,
    url: GF_URL + 'sulphurpoint/v4/RLpkK5vv8KaycDcazWFPBj2afUU9n6kFUHPIFaU.ttf',
    variant: '700',
    family: 'Sulphur Point',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '29708d92-8a9e-41da-a392-7ad414baf734',
    origin: GF_SOURCE,
    url: GF_URL + 'sulphurpoint/v4/RLp5K5vv8KaycDcazWFPBj2aRfkSu6EuTHo.ttf',
    variant: 'regular',
    family: 'Sulphur Point',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '86c2d4bb-01b8-416d-94eb-608e72e17af9',
    origin: GF_SOURCE,
    url: GF_URL + 'sulphurpoint/v4/RLpkK5vv8KaycDcazWFPBj2afVU6n6kFUHPIFaU.ttf',
    variant: '300',
    family: 'Sulphur Point',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'e99e215a-4ce9-43d1-bc57-d2ba7931f433',
    origin: GF_SOURCE,
    url: GF_URL + 'monofett/v12/mFTyWbofw6zc9NtnW43SuRwr0VJ7.ttf',
    variant: 'regular',
    family: 'Monofett',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '87da9aa2-2256-4a59-854c-a827bc89176c',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c84cY7pG7w_q6APDMZN6kY3hbiXup67QqNPPcgYp0i.ttf',
    variant: '900italic',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '01b0e994-67ef-45fe-818d-f9112a25ed36',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c84cY7pG7w_q6APDMZN6kY3hbiXupT7QqNPPcgYp0i.ttf',
    variant: '800italic',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '753300e5-b103-4ce1-bab5-b412e893d1c3',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c84cY7pG7w_q6APDMZN6kY3hbiXuo07QqNPPcgYp0i.ttf',
    variant: '700italic',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd78b28b3-28bf-4775-95de-be5a219571d3',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c84cY7pG7w_q6APDMZN6kY3hbiXuoN7QqNPPcgYp0i.ttf',
    variant: '600italic',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '7ebd600b-fa38-4c62-8139-d779974440e8',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c84cY7pG7w_q6APDMZN6kY3hbiXurh6gqNPPcgYp0i.ttf',
    variant: '500italic',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'baab12cc-49a7-4164-a38f-4482919f0c8c',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c84cY7pG7w_q6APDMZN6kY3hbiXurT6gqNPPcgYp0i.ttf',
    variant: 'italic',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '1098f847-384e-495b-872e-a62d26becedb',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c-4cY7pG7w_oSJDszBXsKCcBH3P647qQCJHvIwYg.ttf',
    variant: '900',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '68973c49-e831-42cd-94df-1bd338db9d16',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c-4cY7pG7w_oSJDszBXsKCcBH3Fq47qQCJHvIwYg.ttf',
    variant: '800',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6b633187-7bb4-459c-b9bb-73a0df845ed1',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c-4cY7pG7w_oSJDszBXsKCcBH3ca47qQCJHvIwYg.ttf',
    variant: '700',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd600906d-3d28-4dd5-b130-ae359750e622',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c-4cY7pG7w_oSJDszBXsKCcBH3SK47qQCJHvIwYg.ttf',
    variant: '600',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c68d9185-6647-4672-9be3-12218386d221',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c-4cY7pG7w_oSJDszBXsKCcBH3pKk7qQCJHvIwYg.ttf',
    variant: '500',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '0bcf6006-67c2-43ff-967d-a55b3429031d',
    origin: GF_SOURCE,
    url: GF_URL + 'kufam/v6/C8c-4cY7pG7w_oSJDszBXsKCcBH3lqk7qQCJHvIwYg.ttf',
    variant: 'regular',
    family: 'Kufam',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6c08a760-106d-4e3a-9a65-847b4c86828a',
    origin: GF_SOURCE,
    url: GF_URL + 'ribeyemarrow/v12/GFDsWApshnqMRO2JdtRZ2d0vEAwTVWgKdtw.ttf',
    variant: 'regular',
    family: 'Ribeye Marrow',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '17cb1027-d5a6-4091-89d4-a701dca8fec8',
    origin: GF_SOURCE,
    url: GF_URL + 'odibeesans/v4/neIPzCSooYAho6WvjeToRYkyepH9qGsf.ttf',
    variant: 'regular',
    family: 'Odibee Sans',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'f55b82ea-adcf-44e7-9343-ee01ddc9ca0a',
    origin: GF_SOURCE,
    url: GF_URL + 'diplomatasc/v11/buExpoi3ecvs3kidKgBJo2kf-P5Oaiw4cw.ttf',
    variant: 'regular',
    family: 'Diplomata SC',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7c56c1db-507a-4a6d-8f63-af95790e430f',
    origin: GF_SOURCE,
    url: GF_URL + 'content/v15/zrfg0HLayePhU_AwaRzdBirfWCHvkAI.ttf',
    variant: '700',
    family: 'Content',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '2777c200-9db7-41d6-a562-03453b94d9e9',
    origin: GF_SOURCE,
    url: GF_URL + 'content/v15/zrfl0HLayePhU_AwUaDyIiL0RCg.ttf',
    variant: 'regular',
    family: 'Content',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '34be89f7-2c17-4f6a-985a-65bec9509020',
    origin: GF_SOURCE,
    url: GF_URL + 'srisakdi/v6/yMJWMIlvdpDbkB0A-gIAUghxoNFxW0Hz.ttf',
    variant: '700',
    family: 'Srisakdi',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6471495f-6d25-4ba4-8702-d487335a434a',
    origin: GF_SOURCE,
    url: GF_URL + 'srisakdi/v6/yMJRMIlvdpDbkB0A-jq8fSx5i814.ttf',
    variant: 'regular',
    family: 'Srisakdi',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '4bb62ef4-b19f-4309-b0ec-be8c1b8db784',
    origin: GF_SOURCE,
    url: GF_URL + 'lancelot/v12/J7acnppxBGtQEulG4JY4xJ9CGyAa.ttf',
    variant: 'regular',
    family: 'Lancelot',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '13d4204b-fdde-4b14-a8c6-5f1eb39f099d',
    origin: GF_SOURCE,
    url: GF_URL + 'chelaone/v11/6ae-4KC7Uqgdz_JZdPIy31vWNTMwoQ.ttf',
    variant: 'regular',
    family: 'Chela One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '795ea1a8-afc8-4f59-9b24-fe1f3330aa32',
    origin: GF_SOURCE,
    url: GF_URL + 'metalmania/v12/RWmMoKWb4e8kqMfBUdPFJeXCg6UKDXlq.ttf',
    variant: 'regular',
    family: 'Metal Mania',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'b6943936-a52c-4aaa-a89f-a471ac9f6b87',
    origin: GF_SOURCE,
    url: GF_URL + 'kantumruy/v11/syk0-yJ0m7wyVb-f4FOPQtflpn-UJ1H6Uw.ttf',
    variant: '700',
    family: 'Kantumruy',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '416a7cc2-303f-44b3-bf9b-cf07068e682b',
    origin: GF_SOURCE,
    url: GF_URL + 'kantumruy/v11/sykx-yJ0m7wyVb-f4FO3_vjBrlSILg.ttf',
    variant: 'regular',
    family: 'Kantumruy',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '6eed16f5-9178-4708-8753-a258c0e139ff',
    origin: GF_SOURCE,
    url: GF_URL + 'kantumruy/v11/syk0-yJ0m7wyVb-f4FOPUtDlpn-UJ1H6Uw.ttf',
    variant: '300',
    family: 'Kantumruy',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '70165c14-6bdc-4628-93c6-e39b1e024ee0',
    origin: GF_SOURCE,
    url: GF_URL + 'sahitya/v7/6qLFKZkOuhnuqlJAUZsqGyQvEnvSexI.ttf',
    variant: '700',
    family: 'Sahitya',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'b81f6a96-59fe-4aee-9774-312b3cc0eeba',
    origin: GF_SOURCE,
    url: GF_URL + 'sahitya/v7/6qLAKZkOuhnuqlJAaScFPywEDnI.ttf',
    variant: 'regular',
    family: 'Sahitya',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '6467d606-d1f7-497d-8b9a-3cb7d45e118d',
    origin: GF_SOURCE,
    url: GF_URL + 'arbutus/v12/NaPYcZ7dG_5J3poob9JtryO8fMU.ttf',
    variant: 'regular',
    family: 'Arbutus',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '3f1c38de-8f72-4500-8444-faa5e7895619',
    origin: GF_SOURCE,
    url: GF_URL + 'rhodiumlibre/v7/1q2AY5adA0tn_ukeHcQHqpx6pETLeo2gm2U.ttf',
    variant: 'regular',
    family: 'Rhodium Libre',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'c062c4ca-c9bd-49ec-988d-c1386f05622a',
    origin: GF_SOURCE,
    url: GF_URL + 'trykker/v11/KtktALyWZJXudUPzhNnoOd2j22U.ttf',
    variant: 'regular',
    family: 'Trykker',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '114925a0-dfd7-405b-bef3-4fef14673409',
    origin: GF_SOURCE,
    url: GF_URL + 'barriecito/v5/WWXXlj-CbBOSLY2QTuY_KdUiYwTO0MU.ttf',
    variant: 'regular',
    family: 'Barriecito',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b80b35fc-d0ab-416e-9bd3-735d28b39ecf',
    origin: GF_SOURCE,
    url: GF_URL + 'romanesco/v11/w8gYH2ozQOY7_r_J7mSn3HwLqOqSBg.ttf',
    variant: 'regular',
    family: 'Romanesco',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '1719d848-ea50-467c-86a4-246615c66c3f',
    origin: GF_SOURCE,
    url: GF_URL + 'inika/v11/rnCr-x5X3QP-pix7auM-mHnOSOuk.ttf',
    variant: '700',
    family: 'Inika',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7e80936d-1295-4820-ad60-86625dc2767c',
    origin: GF_SOURCE,
    url: GF_URL + 'inika/v11/rnCm-x5X3QP-phTHRcc2s2XH.ttf',
    variant: 'regular',
    family: 'Inika',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '039a7f92-bd06-43e8-b651-70636f688ef6',
    origin: GF_SOURCE,
    url: GF_URL + 'synemono/v5/K2FzfZNHj_FHBmRbFvHzIqCkDyvqZA.ttf',
    variant: 'regular',
    family: 'Syne Mono',
    category: 'monospace',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '85dfff90-93c3-41a9-9f0c-b1e14a3146e7',
    origin: GF_SOURCE,
    url: GF_URL + 'modernantiqua/v12/NGStv5TIAUg6Iq_RLNo_2dp1sI1Ea2u0c3Gi.ttf',
    variant: 'regular',
    family: 'Modern Antiqua',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c23a175d-d25a-4fda-be69-0a2300461123',
    origin: GF_SOURCE,
    url: GF_URL + 'spirax/v11/buE3poKgYNLy0F3cXktt-Csn-Q.ttf',
    variant: 'regular',
    family: 'Spirax',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'd2af174a-e86e-459f-8da6-743708d412b0',
    origin: GF_SOURCE,
    url: GF_URL + 'ruthie/v13/gokvH63sGkdqXuU9lD53Q2u_mQ.ttf',
    variant: 'regular',
    family: 'Ruthie',
    category: 'handwriting',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '0165cea2-a2d4-406b-86c8-20fe14f7635a',
    origin: GF_SOURCE,
    url: GF_URL + 'jomolhari/v4/EvONzA1M1Iw_CBd2hsQCF1IZKq5INg.ttf',
    variant: 'regular',
    family: 'Jomolhari',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '58e05354-6f09-4f73-a960-c18805e2acbe',
    origin: GF_SOURCE,
    url: GF_URL + 'meiescript/v10/_LOImzDK7erRjhunIspaMjxn5IXg0WDz.ttf',
    variant: 'regular',
    family: 'Meie Script',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6298ed3a-1662-4127-bd28-0192cc21ed21',
    origin: GF_SOURCE,
    url: GF_URL + 'maidenorange/v13/kJE1BuIX7AUmhi2V4m08kb1XjOZdCZS8FY8.ttf',
    variant: 'regular',
    family: 'Maiden Orange',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '5b8b04a7-1f17-4cf1-868b-bae359c34e64',
    origin: GF_SOURCE,
    url: GF_URL + 'bigshotone/v13/u-470qukhRkkO6BD_7cM_gxuUQJBXv_-.ttf',
    variant: 'regular',
    family: 'Bigshot One',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '2a1e1207-d7ed-44a4-946b-b7b21504b6ae',
    origin: GF_SOURCE,
    url: GF_URL + 'griffy/v11/FwZa7-ox2FQh9kfwSNSEwM2zpA.ttf',
    variant: 'regular',
    family: 'Griffy',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7a137ecb-eb5f-443c-be13-cda9d5b22ac5',
    origin: GF_SOURCE,
    url: GF_URL + 'castoro/v8/1q2EY5yMCld3-O4cLYpOyMQbjEX5fw.ttf',
    variant: 'italic',
    family: 'Castoro',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '136d6f04-eeee-45f4-b6af-0caa2fb12908',
    origin: GF_SOURCE,
    url: GF_URL + 'castoro/v8/1q2GY5yMCld3-O4cHYhEzOYenEU.ttf',
    variant: 'regular',
    family: 'Castoro',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '6c5b6db5-54bb-445f-9230-f44d569ec5fa',
    origin: GF_SOURCE,
    url: GF_URL + 'gotu/v4/o-0FIpksx3QOlH0Lioh6-hU.ttf',
    variant: 'regular',
    family: 'Gotu',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd9d631b8-f65d-4b64-9fae-274699e530d5',
    origin: GF_SOURCE,
    url: GF_URL + 'galdeano/v12/uU9MCBoQ4YOqOW1boDPx8PCOg0uX.ttf',
    variant: 'regular',
    family: 'Galdeano',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '5a712885-22e1-4d93-8535-22d8c3a33ad6',
    origin: GF_SOURCE,
    url: GF_URL + 'imfelldwpicasc/v12/0ybjGCAu5PfqkvtGVU15aBhXz3EUrnTW-BiKEUiBGA.ttf',
    variant: 'regular',
    family: 'IM Fell DW Pica SC',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c6256267-a3a9-41d2-90e8-eac82dc87295',
    origin: GF_SOURCE,
    url: GF_URL + 'iceberg/v10/8QIJdijAiM7o-qnZuIgOq7jkAOw.ttf',
    variant: 'regular',
    family: 'Iceberg',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'bedb6fc0-bd1f-4e77-ab23-febc1de1738a',
    origin: GF_SOURCE,
    url:
      GF_URL + 'sreekrushnadevaraya/v10/R70FjzQeifmPepmyQQjQ9kvwMkWYPfTA_EWb2FhQuXir.ttf',
    variant: 'regular',
    family: 'Sree Krushnadevaraya',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6c9e932f-0107-4437-9144-2a9027c8a163',
    origin: GF_SOURCE,
    url: GF_URL + 'cutefont/v10/Noaw6Uny2oWPbSHMrY6vmJNVNC9hkw.ttf',
    variant: 'regular',
    family: 'Cute Font',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '0b5b58ad-04b8-4f65-ae44-5b5e764e949d',
    origin: GF_SOURCE,
    url: GF_URL + 'lindenhill/v12/-F63fjxoKSg9Yc3hZgO8yjFK5igg1l9kn-s.ttf',
    variant: 'italic',
    family: 'Linden Hill',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '36b8f057-9d40-4194-be4b-39abc24068e7',
    origin: GF_SOURCE,
    url: GF_URL + 'lindenhill/v12/-F61fjxoKSg9Yc3hZgO8ygFI7CwC009k.ttf',
    variant: 'regular',
    family: 'Linden Hill',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '5a0a1ff0-c71d-4153-a353-c6a6439d92fa',
    origin: GF_SOURCE,
    url: GF_URL + 'wellfleet/v10/nuF7D_LfQJb3VYgX6eyT42aLDhO2HA.ttf',
    variant: 'regular',
    family: 'Wellfleet',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '867d1a7a-f149-4c91-a65d-61771d347cc8',
    origin: GF_SOURCE,
    url: GF_URL + 'piedra/v11/ke8kOg8aN0Bn7hTunEyHN_M3gA.ttf',
    variant: 'regular',
    family: 'Piedra',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '00ab1bcc-dc15-43c8-8566-b1f35200cac5',
    origin: GF_SOURCE,
    url: GF_URL + 'kavoon/v11/pxiFyp4_scRYhlU4NLr6f1pdEQ.ttf',
    variant: 'regular',
    family: 'Kavoon',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '14480f79-1f52-40d5-8cb3-4877779d664f',
    origin: GF_SOURCE,
    url: GF_URL + 'markoone/v12/9Btq3DFG0cnVM5lw1haaKpUfrHPzUw.ttf',
    variant: 'regular',
    family: 'Marko One',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '877eabc5-4430-4991-b314-3e91f136c2e1',
    origin: GF_SOURCE,
    url: GF_URL + 'stylish/v10/m8JSjfhPYriQkk7-fo35dLxEdmo.ttf',
    variant: 'regular',
    family: 'Stylish',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '7643d55d-a441-4518-85f7-010fb3fdd428',
    origin: GF_SOURCE,
    url: GF_URL + 'bayon/v16/9XUrlJNmn0LPFl-pOhYEd2NJ.ttf',
    variant: 'regular',
    family: 'Bayon',
    category: 'display',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '9e4d47e2-6f89-48f1-b9d6-2e6c64029aea',
    origin: GF_SOURCE,
    url: GF_URL + 'jotione/v11/Z9XVDmdJQAmWm9TwaYTe4u2El6GC.ttf',
    variant: 'regular',
    family: 'Joti One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'edb2000a-34df-414b-ae53-e55d7d86272d',
    origin: GF_SOURCE,
    url: GF_URL + 'junge/v10/gokgH670Gl1lUqAdvhB7SnKm.ttf',
    variant: 'regular',
    family: 'Junge',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'bcb49e21-334c-4cfb-ab7e-fb7e3c57f43f',
    origin: GF_SOURCE,
    url: GF_URL + 'gorditas/v10/ll84K2aTVD26DsPEtThUIooIvAoShA1i.ttf',
    variant: '700',
    family: 'Gorditas',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '57611226-9f09-48a5-acea-30a9b86bdc3c',
    origin: GF_SOURCE,
    url: GF_URL + 'gorditas/v10/ll8_K2aTVD26DsPEtQDoDa4AlxYb.ttf',
    variant: 'regular',
    family: 'Gorditas',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8c65e293-8328-424e-993e-a18d21a3bfae',
    origin: GF_SOURCE,
    url: GF_URL + 'lexendexa/v10/UMBXrPdOoHOnxExyjdBeWirXArM58BY.ttf',
    variant: 'regular',
    family: 'Lexend Exa',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ddf87418-6f2b-4edd-8574-c8a22d47575b',
    origin: GF_SOURCE,
    url: GF_URL + 'croissantone/v10/3y9n6bU9bTPg4m8NDy3Kq24UM3pqn5cdJ-4.ttf',
    variant: 'regular',
    family: 'Croissant One',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a982682e-7be6-482f-9145-4f7119f4526d',
    origin: GF_SOURCE,
    url: GF_URL + 'yeonsung/v10/QldMNTpbohAGtsJvUn6xSVNazqx2xg.ttf',
    variant: 'regular',
    family: 'Yeon Sung',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4021d7a4-19a7-4328-8b4c-3509fa4a35b8',
    origin: GF_SOURCE,
    url: GF_URL + 'eaglelake/v10/ptRMTiqbbuNJDOiKj9wG5O7yKQNute8.ttf',
    variant: 'regular',
    family: 'Eagle Lake',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6a121bfb-1884-44c7-945e-fa8fc4170afc',
    origin: GF_SOURCE,
    url: GF_URL + 'offside/v10/HI_KiYMWKa9QrAykQ5HiRp-dhpQ.ttf',
    variant: 'regular',
    family: 'Offside',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'e1e448ba-02e8-4b0f-a6c6-b8f907125e22',
    origin: GF_SOURCE,
    url: GF_URL + 'asar/v10/sZlLdRyI6TBIXkYQDLlTW6E.ttf',
    variant: 'regular',
    family: 'Asar',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '91070d5e-6c16-48ef-888e-7baf37fec58a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibarrarealnova/v8/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKop8-pXztxXZvSkTo.ttf',
    variant: '700italic',
    family: 'Ibarra Real Nova',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f101cfd6-bf7a-4d1e-9ca7-1fdfe0bb0036',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibarrarealnova/v8/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKop_apXztxXZvSkTo.ttf',
    variant: '600italic',
    family: 'Ibarra Real Nova',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '8845db10-9069-4d39-bbd7-499cd8e1dfc0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibarrarealnova/v8/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKopxquXztxXZvSkTo.ttf',
    variant: '500italic',
    family: 'Ibarra Real Nova',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ee8db9ca-ada7-4d01-afc6-1d3441bfda60',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibarrarealnova/v8/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKopyiuXztxXZvSkTo.ttf',
    variant: 'italic',
    family: 'Ibarra Real Nova',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '6e150ccb-6e84-4b2b-a1d8-ddc4ae19ec5f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibarrarealnova/v8/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXeH48DtVT9TWIvS.ttf',
    variant: '700',
    family: 'Ibarra Real Nova',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd1bd5ede-f490-4375-b656-dcc97cb163f7',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibarrarealnova/v8/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXe-48DtVT9TWIvS.ttf',
    variant: '600',
    family: 'Ibarra Real Nova',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'abb54283-431b-4628-a785-ff7e7f00201a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibarrarealnova/v8/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXdS5MDtVT9TWIvS.ttf',
    variant: '500',
    family: 'Ibarra Real Nova',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '10630dc4-4fc0-402f-8ea7-56c47be8b8d0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibarrarealnova/v8/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXdg5MDtVT9TWIvS.ttf',
    variant: 'regular',
    family: 'Ibarra Real Nova',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '06678158-186c-4602-a003-cb7a057bbd06',
    origin: GF_SOURCE,
    url: GF_URL + 'dorsa/v13/yYLn0hjd0OGwqo493XCFxAnQ.ttf',
    variant: 'regular',
    family: 'Dorsa',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'd101e3b5-748f-4857-9f8b-6f33d60d44b7',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVUHC5Tlhjxdw4.ttf',
    variant: '700italic',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '530c97c9-c006-4b3e-8b04-68d7ecf46121',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noa26Uj3zpmBOgbNpOIznZlRFipxkwjx.ttf',
    variant: '700',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '1982d9a8-c26e-430c-941a-2a3bbb6ef18d',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgGFVHC5Tlhjxdw4.ttf',
    variant: '600italic',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8eeba469-7734-4ae2-9785-d835b0d31ecb',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noa26Uj3zpmBOgbNpOJXnJlRFipxkwjx.ttf',
    variant: '600',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '4253ba35-748b-40bb-a2cc-dd8985bd2d2d',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgE1SHC5Tlhjxdw4.ttf',
    variant: '500italic',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bd58bcdd-8468-49dd-84d3-a679a2b7dadb',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noa26Uj3zpmBOgbNpOJ7m5lRFipxkwjx.ttf',
    variant: '500',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '307db255-a86a-4c1b-bb60-b7a53c9e17b3',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noa36Uj3zpmBOgbNpOqNuLl7OCZ4ihE.ttf',
    variant: 'italic',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '75c8c675-f9e4-4e61-87d8-fc5b628c041c',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noax6Uj3zpmBOgbNpNqPsr1ZPTZ4.ttf',
    variant: 'regular',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ae5e7738-8356-42bd-8de5-7810c6b14850',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgBVTHC5Tlhjxdw4.ttf',
    variant: '300italic',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8a8d2a30-2918-4cb9-b7b2-117b9a67618e',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noa26Uj3zpmBOgbNpOIjmplRFipxkwjx.ttf',
    variant: '300',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '52bff633-048d-4c41-9bce-1e0399c1cac3',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgHFQHC5Tlhjxdw4.ttf',
    variant: '200italic',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6177a565-3ad8-440a-a95d-6474724ea90f',
    origin: GF_SOURCE,
    url: GF_URL + 'fahkwang/v6/Noa26Uj3zpmBOgbNpOJHmZlRFipxkwjx.ttf',
    variant: '200',
    family: 'Fahkwang',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '18d8606b-b5c7-4466-a045-199a2f2cd976',
    origin: GF_SOURCE,
    url: GF_URL + 'lexend/v2/wlpzgwvFAVdoq2_nXIIx2UxLYClOCg.ttf',
    variant: '800',
    family: 'Lexend',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '0d8792a2-414c-4daa-aa55-76ccfc5d20d2',
    origin: GF_SOURCE,
    url: GF_URL + 'lexend/v2/wlpzgwvFAVdoq2_nQIEx2UxLYClOCg.ttf',
    variant: '700',
    family: 'Lexend',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'cf1fe578-1f5c-4b0b-a39d-caea7f8405ec',
    origin: GF_SOURCE,
    url: GF_URL + 'lexend/v2/wlpzgwvFAVdoq2_nJIAx2UxLYClOCg.ttf',
    variant: '600',
    family: 'Lexend',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'ecb314df-effb-4c64-8c2f-64cc1d766292',
    origin: GF_SOURCE,
    url: GF_URL + 'lexend/v2/wlpzgwvFAVdoq2_nCIcx2UxLYClOCg.ttf',
    variant: '500',
    family: 'Lexend',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '91a3c76f-6d9c-4386-af59-9b491dbf6a85',
    origin: GF_SOURCE,
    url: GF_URL + 'lexend/v2/wlpwgwvFAVdoq2_f_K4V0WdXaQ.ttf',
    variant: 'regular',
    family: 'Lexend',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '080c9bcd-a94b-4f03-87e4-4059e10dba85',
    origin: GF_SOURCE,
    url: GF_URL + 'lexend/v2/wlpzgwvFAVdoq2_nUIYx2UxLYClOCg.ttf',
    variant: '300',
    family: 'Lexend',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '094eb961-42d2-4180-8473-a67623a9ab7f',
    origin: GF_SOURCE,
    url: GF_URL + 'lexend/v2/wlpygwvFAVdoq2_nmKQR82JHaTBX.ttf',
    variant: '100',
    family: 'Lexend',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '68423ffa-efb4-463d-8bd7-49e07a2f7f33',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librebarcode39extendedtext/v15/eLG1P_rwIgOiDA7yrs9LoKaYRVLQ1YldrrOnnL7xPO4jNP68fLIiPopNNA.ttf',
    variant: 'regular',
    family: 'Libre Barcode 39 Extended Text',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'f003324d-d2e4-4770-a460-bcf941b0415c',
    origin: GF_SOURCE,
    url: GF_URL + 'barrio/v7/wEO8EBXBk8hBIDiEdQYhWdsX1Q.ttf',
    variant: 'regular',
    family: 'Barrio',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd7dda7bc-84f3-487c-a28e-53e23818935a',
    origin: GF_SOURCE,
    url: GF_URL + 'shipporimincho/v4/VdGDAZweH5EbgHY6YExcZfDoj0B4e9Om5JEO5--2zg.ttf',
    variant: '800',
    family: 'Shippori Mincho',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '31d52361-d531-457d-b1d6-c5f47cc3afca',
    origin: GF_SOURCE,
    url: GF_URL + 'shipporimincho/v4/VdGDAZweH5EbgHY6YExcZfDoj0B4Z9Cm5JEO5--2zg.ttf',
    variant: '700',
    family: 'Shippori Mincho',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '262ec7fc-c5cc-4872-804d-86b764079b37',
    origin: GF_SOURCE,
    url: GF_URL + 'shipporimincho/v4/VdGDAZweH5EbgHY6YExcZfDoj0B4A9Gm5JEO5--2zg.ttf',
    variant: '600',
    family: 'Shippori Mincho',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '6cb4daf5-aca9-4b23-83a3-73e30b6740e0',
    origin: GF_SOURCE,
    url: GF_URL + 'shipporimincho/v4/VdGDAZweH5EbgHY6YExcZfDoj0B4L9am5JEO5--2zg.ttf',
    variant: '500',
    family: 'Shippori Mincho',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a6f77537-46a4-483e-80ca-3c591cad1ab9',
    origin: GF_SOURCE,
    url: GF_URL + 'shipporimincho/v4/VdGGAZweH5EbgHY6YExcZfDoj0BA2_-C7LoS7g.ttf',
    variant: 'regular',
    family: 'Shippori Mincho',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '6e58459c-948e-4374-b38f-7965f796bc23',
    origin: GF_SOURCE,
    url: GF_URL + 'condiment/v10/pONk1hggFNmwvXALyH6Sq4n4o1vyCQ.ttf',
    variant: 'regular',
    family: 'Condiment',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '7015371a-0dcc-41d9-b865-2e8e47a7b93a',
    origin: GF_SOURCE,
    url: GF_URL + 'redressed/v13/x3dickHUbrmJ7wMy9MsBfPACvy_1BA.ttf',
    variant: 'regular',
    family: 'Redressed',
    category: 'handwriting',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'd7a18388-1027-4e0f-86ad-719c4516657f',
    origin: GF_SOURCE,
    url: GF_URL + 'rumraisin/v10/nwpRtKu3Ih8D5avB4h2uJ3-IywA7eMM.ttf',
    variant: 'regular',
    family: 'Rum Raisin',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'd30c013c-2e0f-432c-aec8-121e3da72490',
    origin: GF_SOURCE,
    url: GF_URL + 'akronim/v12/fdN-9sqWtWZZlHRp-gBxkFYN-a8.ttf',
    variant: 'regular',
    family: 'Akronim',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'd9781aa5-b5ef-47e3-99f6-682394dba876',
    origin: GF_SOURCE,
    url: GF_URL + 'novaflat/v14/QdVUSTc-JgqpytEbVebEuStkm20oJA.ttf',
    variant: 'regular',
    family: 'Nova Flat',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '2c126066-bcc0-4715-afb4-1fc549617a7a',
    origin: GF_SOURCE,
    url: GF_URL + 'margarine/v11/qkBXXvoE6trLT9Y7YLye5JRLkAXbMQ.ttf',
    variant: 'regular',
    family: 'Margarine',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '113a76bb-8b0a-4fef-8e83-71bf795aa350',
    origin: GF_SOURCE,
    url: GF_URL + 'londrinaoutline/v13/C8c44dM8vmb14dfsZxhetg3pDH-SfuoxrSKMDvI.ttf',
    variant: 'regular',
    family: 'Londrina Outline',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'ef79d489-32ad-4e52-96b2-981c2487385a',
    origin: GF_SOURCE,
    url: GF_URL + 'buda/v13/GFDqWAN8mnyIJSSrG7UBr7pZKA0.ttf',
    variant: '300',
    family: 'Buda',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '25638ff2-5e52-4f45-8b80-8f6f9b964f72',
    origin: GF_SOURCE,
    url: GF_URL + 'solway/v5/AMOTz46Cs2uTAOCuIlwZms0QW3mqyg.ttf',
    variant: '800',
    family: 'Solway',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '68706205-e3d3-46ee-b315-9bac8fd9e5f1',
    origin: GF_SOURCE,
    url: GF_URL + 'solway/v5/AMOTz46Cs2uTAOCuPl8Zms0QW3mqyg.ttf',
    variant: '700',
    family: 'Solway',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '8abeff18-4dac-4957-b8e6-ce9a1f0b8838',
    origin: GF_SOURCE,
    url: GF_URL + 'solway/v5/AMOTz46Cs2uTAOCudlkZms0QW3mqyg.ttf',
    variant: '500',
    family: 'Solway',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '16b96e1c-1a44-4ee9-9f08-413ea85f2fef',
    origin: GF_SOURCE,
    url: GF_URL + 'solway/v5/AMOQz46Cs2uTAOCWgnA9kuYMUg.ttf',
    variant: 'regular',
    family: 'Solway',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '14ee2347-49fd-4ed4-9f0b-682f98d9fe20',
    origin: GF_SOURCE,
    url: GF_URL + 'solway/v5/AMOTz46Cs2uTAOCuLlgZms0QW3mqyg.ttf',
    variant: '300',
    family: 'Solway',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2e9cca00-6456-4306-a265-2e3fa1b108d3',
    origin: GF_SOURCE,
    url: GF_URL + 'tulpenone/v12/dFa6ZfeC474skLgesc0CWj0w_HyIRlE.ttf',
    variant: 'regular',
    family: 'Tulpen One',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '05d644dc-ffa8-4579-808d-b954a6c81402',
    origin: GF_SOURCE,
    url: GF_URL + 'kulimpark/v4/fdNm9secq3hflz1Uu3IwhFwUXatQYZcqCjVVUA.ttf',
    variant: '700italic',
    family: 'Kulim Park',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'b0af4891-43a5-4c8b-a648-a73911470303',
    origin: GF_SOURCE,
    url: GF_URL + 'kulimpark/v4/fdN49secq3hflz1Uu3IwjOIJwa5aZbUvGjU.ttf',
    variant: '700',
    family: 'Kulim Park',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '83a1377e-f310-427a-b23b-985b9746aeb1',
    origin: GF_SOURCE,
    url: GF_URL + 'kulimpark/v4/fdNm9secq3hflz1Uu3IwhFwUOapQYZcqCjVVUA.ttf',
    variant: '600italic',
    family: 'Kulim Park',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'b1fced7e-5eb3-4bb1-a2b1-ef55b1ec6f1b',
    origin: GF_SOURCE,
    url: GF_URL + 'kulimpark/v4/fdN49secq3hflz1Uu3IwjIYIwa5aZbUvGjU.ttf',
    variant: '600',
    family: 'Kulim Park',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '777cacca-ffcc-4107-9d81-2f0f5de3b707',
    origin: GF_SOURCE,
    url: GF_URL + 'kulimpark/v4/fdN59secq3hflz1Uu3IwhFws4YR0abw2Aw.ttf',
    variant: 'italic',
    family: 'Kulim Park',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '53874eb8-c741-41ff-9251-9ad03eb04919',
    origin: GF_SOURCE,
    url: GF_URL + 'kulimpark/v4/fdN79secq3hflz1Uu3IwtF4m5aZxebw.ttf',
    variant: 'regular',
    family: 'Kulim Park',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '4627495a-1d2a-4543-a4c5-773a43c851ac',
    origin: GF_SOURCE,
    url: GF_URL + 'kulimpark/v4/fdNm9secq3hflz1Uu3IwhFwUTaxQYZcqCjVVUA.ttf',
    variant: '300italic',
    family: 'Kulim Park',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ce3f6a81-e4a6-4ce2-8a56-51957c921892',
    origin: GF_SOURCE,
    url: GF_URL + 'kulimpark/v4/fdN49secq3hflz1Uu3IwjPIOwa5aZbUvGjU.ttf',
    variant: '300',
    family: 'Kulim Park',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd8719bbf-5014-42ef-b878-e89c16739d13',
    origin: GF_SOURCE,
    url: GF_URL + 'kulimpark/v4/fdNm9secq3hflz1Uu3IwhFwUKa9QYZcqCjVVUA.ttf',
    variant: '200italic',
    family: 'Kulim Park',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a0aa2955-9d13-431d-a795-acc601ff1db2',
    origin: GF_SOURCE,
    url: GF_URL + 'kulimpark/v4/fdN49secq3hflz1Uu3IwjJYNwa5aZbUvGjU.ttf',
    variant: '200',
    family: 'Kulim Park',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '8b367e69-f4d9-4cd6-9c9d-dc0ada9887a9',
    origin: GF_SOURCE,
    url: GF_URL + 'dangrek/v14/LYjCdG30nEgoH8E2gCNqqVIuTN4.ttf',
    variant: 'regular',
    family: 'Dangrek',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'd600ccc6-da2f-480f-8c34-e86a6d5d0a50',
    origin: GF_SOURCE,
    url: GF_URL + 'nosifer/v11/ZGjXol5JTp0g5bxZaC1RVDNdGDs.ttf',
    variant: 'regular',
    family: 'Nosifer',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '327032a5-2d97-471d-a812-7ebbf0bed876',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'stintultracondensed/v11/-W_gXIrsVjjeyEnPC45qD2NoFPtBE0xCh2A-qhUO2cNvdg.ttf',
    variant: 'regular',
    family: 'Stint Ultra Condensed',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '4f1fdd8a-04e8-4207-9e1d-76416d215e7b',
    origin: GF_SOURCE,
    url: GF_URL + 'ranga/v8/C8cg4cYisGb28qY-AxgR6X2NZAn2.ttf',
    variant: '700',
    family: 'Ranga',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '2730f176-6cea-4307-b6f4-5f34f642b5c7',
    origin: GF_SOURCE,
    url: GF_URL + 'ranga/v8/C8ct4cYisGb28p6CLDwZwmGE.ttf',
    variant: 'regular',
    family: 'Ranga',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ed79d84b-82b7-492e-b108-4bfa0d3e9aab',
    origin: GF_SOURCE,
    url: GF_URL + 'donegalone/v10/m8JWjfRYea-ZnFz6fsK9FZRFRG-K3Mud.ttf',
    variant: 'regular',
    family: 'Donegal One',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '518cafdf-e3a4-45f4-b5ac-182e62cfc1d5',
    origin: GF_SOURCE,
    url: GF_URL + 'reggaeone/v3/7r3DqX5msMIkeuwJwOJt_a5L5uH-mts.ttf',
    variant: 'regular',
    family: 'Reggae One',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'd5f01baa-06e1-4b85-85d4-df75b5b1fcc7',
    origin: GF_SOURCE,
    url: GF_URL + 'pottaone/v6/FeVSS05Bp6cy7xI-YfxQ3Z5nm29Gww.ttf',
    variant: 'regular',
    family: 'Potta One',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a405dc6b-a180-4c80-b177-a3dcc4fe3a5c',
    origin: GF_SOURCE,
    url: GF_URL + 'chathura/v10/_gP81R7-rzUuVjim42eAjWSxYPp7oSNy.ttf',
    variant: '800',
    family: 'Chathura',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'd717f725-7cf5-46f4-9030-741dd42be462',
    origin: GF_SOURCE,
    url: GF_URL + 'chathura/v10/_gP81R7-rzUuVjim42ecjmSxYPp7oSNy.ttf',
    variant: '700',
    family: 'Chathura',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '58ccdac5-41da-4ddc-bf83-f48695c0f99c',
    origin: GF_SOURCE,
    url: GF_URL + 'chathura/v10/_gP71R7-rzUuVjim418goUC5S-Zy.ttf',
    variant: 'regular',
    family: 'Chathura',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '98f7c92f-90f7-44a2-8045-f9a7f96cd112',
    origin: GF_SOURCE,
    url: GF_URL + 'chathura/v10/_gP81R7-rzUuVjim42eMiWSxYPp7oSNy.ttf',
    variant: '300',
    family: 'Chathura',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '3b794681-e3bc-4798-9019-e2f6ac97e4ad',
    origin: GF_SOURCE,
    url: GF_URL + 'chathura/v10/_gP91R7-rzUuVjim42dEq0SbTvZyuDo.ttf',
    variant: '100',
    family: 'Chathura',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '503cbb24-35fc-4dee-bd1d-bb42f675bc07',
    origin: GF_SOURCE,
    url: GF_URL + 'sonsieone/v11/PbymFmP_EAnPqbKaoc18YVu80lbp8JM.ttf',
    variant: 'regular',
    family: 'Sonsie One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b348e493-e49e-4ea0-a826-30fd035a2fc3',
    origin: GF_SOURCE,
    url: GF_URL + 'angkor/v15/H4cmBXyAlsPdnlb-8iw-4Lqggw.ttf',
    variant: 'regular',
    family: 'Angkor',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'edc22785-9a94-49ee-9ff5-4b5773d8f883',
    origin: GF_SOURCE,
    url: GF_URL + 'metal/v15/lW-wwjUJIXTo7i3nnoQAUdN2.ttf',
    variant: 'regular',
    family: 'Metal',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '3aaa4412-f0d9-4b02-9a63-ce4e457f3d47',
    origin: GF_SOURCE,
    url:
      GF_URL + 'librecaslondisplay/v4/TuGOUUFxWphYQ6YI6q9Xp61FQzxDRKmzr2lRdRhtCC4d.ttf',
    variant: 'regular',
    family: 'Libre Caslon Display',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'f18d6f59-bb69-4060-a094-5d73930cc0e1',
    origin: GF_SOURCE,
    url: GF_URL + 'autourone/v12/UqyVK80cP25l3fJgbdfbk5lWVscxdKE.ttf',
    variant: 'regular',
    family: 'Autour One',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '53584d2a-4014-4ad9-be4f-0c03fd08fc75',
    origin: GF_SOURCE,
    url: GF_URL + 'originalsurfer/v11/RWmQoKGZ9vIirYntXJ3_MbekzNMiDEtvAlaMKw.ttf',
    variant: 'regular',
    family: 'Original Surfer',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '4853c8d4-2eaf-4b89-97a0-444b0de3adf9',
    origin: GF_SOURCE,
    url: GF_URL + 'trainone/v1/gyB-hwkiNtc6KnxUVjWHOqbZRY7JVQ.ttf',
    variant: 'regular',
    family: 'Train One',
    category: 'display',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '5b19ca42-c8a9-47f1-9402-c07d235213db',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'imfellgreatprimer/v12/bx6UNwSJtayYxOkbYFsT6hMsLzX7u85rJrrVtj4VTBY1N6U.ttf',
    variant: 'italic',
    family: 'IM Fell Great Primer',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'a2f29100-ade1-4d4e-9c40-a5f2f0d38f0f',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imfellgreatprimer/v12/bx6aNwSJtayYxOkbYFsT6hMsLzX7u85rJorXvDo3SQY1.ttf',
    variant: 'regular',
    family: 'IM Fell Great Primer',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '6db12021-e2de-4c6a-b0fc-148fa4867536',
    origin: GF_SOURCE,
    url: GF_URL + 'fenix/v10/XoHo2YL_S7-g5ostKzAFvs8o.ttf',
    variant: 'regular',
    family: 'Fenix',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '15ece0f9-d703-4021-8021-3e16628ced89',
    origin: GF_SOURCE,
    url: GF_URL + 'ruluko/v10/xMQVuFNZVaODtm0pC6WzKX_QmA.ttf',
    variant: 'regular',
    family: 'Ruluko',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '95b64fa4-437e-4fca-99f4-cb52fa0e0cc1',
    origin: GF_SOURCE,
    url: GF_URL + 'averiagruesalibre/v11/NGSov4nEGEktOaDRKsY-1dhh8eEtIx3ZUmmJw0SLRA8.ttf',
    variant: 'regular',
    family: 'Averia Gruesa Libre',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b1c451d4-04da-4317-9c4c-031a2b82cd85',
    origin: GF_SOURCE,
    url: GF_URL + 'dekko/v9/46khlb_wWjfSrttFR0vsfl1B.ttf',
    variant: 'regular',
    family: 'Dekko',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '41c0c50d-612a-4ee2-b90d-fd5fcd2e910f',
    origin: GF_SOURCE,
    url: GF_URL + 'inriaserif/v4/fC16PYxPY3rXxEndZJAzN3SuT5TAjliPbmxN0_E.ttf',
    variant: '700italic',
    family: 'Inria Serif',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '66d80bdb-f028-40f2-922e-813f2afe7b3e',
    origin: GF_SOURCE,
    url: GF_URL + 'inriaserif/v4/fC14PYxPY3rXxEndZJAzN3wQUgjFhFyta3xN.ttf',
    variant: '700',
    family: 'Inria Serif',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c2f314e5-10e7-458b-aca8-0df998c6e5f4',
    origin: GF_SOURCE,
    url: GF_URL + 'inriaserif/v4/fC1nPYxPY3rXxEndZJAzN3SudyjvqlCkcmU.ttf',
    variant: 'italic',
    family: 'Inria Serif',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '106b8553-6976-4f11-813e-3046c5418543',
    origin: GF_SOURCE,
    url: GF_URL + 'inriaserif/v4/fC1lPYxPY3rXxEndZJAzN0SsfSzNr0Ck.ttf',
    variant: 'regular',
    family: 'Inria Serif',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ec2c246e-f6fb-4e76-bc28-3831e7fa741e',
    origin: GF_SOURCE,
    url: GF_URL + 'inriaserif/v4/fC16PYxPY3rXxEndZJAzN3SuT4THjliPbmxN0_E.ttf',
    variant: '300italic',
    family: 'Inria Serif',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '75bd966e-c5d3-4676-b291-6f9e8534f309',
    origin: GF_SOURCE,
    url: GF_URL + 'inriaserif/v4/fC14PYxPY3rXxEndZJAzN3wAVQjFhFyta3xN.ttf',
    variant: '300',
    family: 'Inria Serif',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'fe52c2c5-eae3-4d66-8738-33da16471289',
    origin: GF_SOURCE,
    url: GF_URL + 'bellota/v4/MwQxbhXl3_qEpiwAKJBjDGYfjZtKpXulTQ.ttf',
    variant: '700italic',
    family: 'Bellota',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c6977f04-b96c-4ccc-8204-90d8bc5c14b3',
    origin: GF_SOURCE,
    url: GF_URL + 'bellota/v4/MwQzbhXl3_qEpiwAIC5-kGMViblPtXs.ttf',
    variant: '700',
    family: 'Bellota',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'cff3ebed-5211-4689-ab05-be1e71d44fba',
    origin: GF_SOURCE,
    url: GF_URL + 'bellota/v4/MwQ0bhXl3_qEpiwAKJBbsEk7hbBWrA.ttf',
    variant: 'italic',
    family: 'Bellota',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '36fdc01c-52cf-4ba2-a684-96d879ffcad4',
    origin: GF_SOURCE,
    url: GF_URL + 'bellota/v4/MwQ2bhXl3_qEpiwAGJJRtGs-lbA.ttf',
    variant: 'regular',
    family: 'Bellota',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'e8502caa-77d8-4649-8cfd-c8a76f3259b0',
    origin: GF_SOURCE,
    url: GF_URL + 'bellota/v4/MwQxbhXl3_qEpiwAKJBjHGEfjZtKpXulTQ.ttf',
    variant: '300italic',
    family: 'Bellota',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a0341cd9-bf11-4865-8145-9f4b32a2a312',
    origin: GF_SOURCE,
    url: GF_URL + 'bellota/v4/MwQzbhXl3_qEpiwAID55kGMViblPtXs.ttf',
    variant: '300',
    family: 'Bellota',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '0bbfa36f-f2f2-47ee-a869-1b6c8767ca03',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhTO23gX9BRy5m5M.ttf',
    variant: '900italic',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'bbf6b47b-55a9-4236-989b-ef52f2822732',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhRq23gX9BRy5m5M.ttf',
    variant: '800italic',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8a0205f0-1d13-4f6e-95a4-4d9df27bbdc1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhX223gX9BRy5m5M.ttf',
    variant: '700italic',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '1504bc5a-41ae-4613-82d9-effb12dad4c8',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhUS23gX9BRy5m5M.ttf',
    variant: '600italic',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8074037a-82db-4bc8-8dbf-8976ae800b48',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhaix3gX9BRy5m5M.ttf',
    variant: '500italic',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '1106ee16-2536-4ae1-9022-45d119b2cb7d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhZqx3gX9BRy5m5M.ttf',
    variant: 'italic',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'ccb0750a-771a-417c-bb50-0edbb63e0e00',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhcSx3gX9BRy5m5M.ttf',
    variant: '300italic',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '9a01ae8d-8788-4cc1-b4fc-7f8adf1c9753',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhRqx3gX9BRy5m5M.ttf',
    variant: '200italic',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '98e6c5b2-22b5-434f-83de-3b4e0f340b0a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhZqw3gX9BRy5m5M.ttf',
    variant: '100italic',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'be9f37c4-63eb-4b99-ae1b-67115f38936f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7JxwXLy1AHfAAy5.ttf',
    variant: '900',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '166dd6d4-4ed2-4371-b591-aa7ff7153b79',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7JYwXLy1AHfAAy5.ttf',
    variant: '800',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'bef0cb7a-e6da-47cd-a04b-8a4b978dea5c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7I_wXLy1AHfAAy5.ttf',
    variant: '700',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'bec23c31-ad8e-4633-8f27-58777389a353',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7IGwXLy1AHfAAy5.ttf',
    variant: '600',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8e8e6dd1-655a-4cc4-a253-f0316eb8adf4',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LqxnLy1AHfAAy5.ttf',
    variant: '500',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'db68da62-043e-4e10-a74b-e5715fc31aa2',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LYxnLy1AHfAAy5.ttf',
    variant: 'regular',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '285c73b8-2a5c-4309-aa10-49994ff37548',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7KGxnLy1AHfAAy5.ttf',
    variant: '300',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'ee4fe1a4-5dc4-4cc2-8e3b-687db643e451',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7JYxnLy1AHfAAy5.ttf',
    variant: '200',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'cc6084cb-f476-4119-80f8-de05e6ca1aaa',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'piazzolla/v11/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LYx3Ly1AHfAAy5.ttf',
    variant: '100',
    family: 'Piazzolla',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8e563b28-9d44-4b62-91b3-0816dd31b539',
    origin: GF_SOURCE,
    url: GF_URL + 'chango/v11/2V0cKI0OB5U7WaJyz324TFUaAw.ttf',
    variant: 'regular',
    family: 'Chango',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '356ac826-a413-450b-a047-6e8bac7af284',
    origin: GF_SOURCE,
    url: GF_URL + 'stoke/v12/z7NadRb7aTMfKONpfihK1YTV.ttf',
    variant: 'regular',
    family: 'Stoke',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '265b7b10-fe91-4255-8e8a-72c8217f9a80',
    origin: GF_SOURCE,
    url: GF_URL + 'stoke/v12/z7NXdRb7aTMfKNvFVgxC_pjcTeWU.ttf',
    variant: '300',
    family: 'Stoke',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '7aefae56-8609-4b08-bdb3-0f595d5fa0c6',
    origin: GF_SOURCE,
    url: GF_URL + 'sancreek/v13/pxiHypAnsdxUm159X7D-XV9NEe-K.ttf',
    variant: 'regular',
    family: 'Sancreek',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '0e65ec3f-33e8-43f0-bf2f-103f16a6838f',
    origin: GF_SOURCE,
    url: GF_URL + 'kumarone/v7/bMr1mS-P958wYi6YaGeGNO6WU3oT0g.ttf',
    variant: 'regular',
    family: 'Kumar One',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '0fc9cc12-c6e6-4bc6-ae9a-697d7888a2ce',
    origin: GF_SOURCE,
    url:
      GF_URL + 'stintultraexpanded/v10/CSRg4yNNh-GbW3o3JkwoDcdvMKMf0oBAd0qoATQkWwam.ttf',
    variant: 'regular',
    family: 'Stint Ultra Expanded',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6bc0f11c-c3c1-41aa-8cf3-88ff930619b9',
    origin: GF_SOURCE,
    url: GF_URL + 'khmer/v15/MjQImit_vPPwpF-BpN2EeYmD.ttf',
    variant: 'regular',
    family: 'Khmer',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '6dbd1939-838e-4ec8-8e7f-271977fdcc25',
    origin: GF_SOURCE,
    url: GF_URL + 'simonetta/v13/x3d5ckHVYrCU5BU15c4xfsKCsA7tLwc7Gn88.ttf',
    variant: '900italic',
    family: 'Simonetta',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '7a3c9711-5d2f-4ed9-8b35-bf3cba82d6d4',
    origin: GF_SOURCE,
    url: GF_URL + 'simonetta/v13/x3dnckHVYrCU5BU15c45-N0mtwTpDQIrGg.ttf',
    variant: '900',
    family: 'Simonetta',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '02dc7fe2-0322-41c3-be36-3bbb1e41b30d',
    origin: GF_SOURCE,
    url: GF_URL + 'simonetta/v13/x3dkckHVYrCU5BU15c4xfvoGnSrlBBsy.ttf',
    variant: 'italic',
    family: 'Simonetta',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '33d8410f-1a7c-418a-b2a7-4fd432c5563a',
    origin: GF_SOURCE,
    url: GF_URL + 'simonetta/v13/x3dickHVYrCU5BU15c4BfPACvy_1BA.ttf',
    variant: 'regular',
    family: 'Simonetta',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'f394aec1-06e5-4a16-8be5-e0324f7c2789',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librebarcode39text/v16/sJoa3KhViNKANw_E3LwoDXvs5Un0HQ1vT-031RRL-9rYaw.ttf',
    variant: 'regular',
    family: 'Libre Barcode 39 Text',
    category: 'display',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '4215e787-e521-41e8-bfc9-765567defc69',
    origin: GF_SOURCE,
    url: GF_URL + 'farsan/v8/VEMwRoJ0vY_zsyz62q-pxDX9rQ.ttf',
    variant: 'regular',
    family: 'Farsan',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ed46b67e-cccc-41bf-ace0-55b26bd9a86c',
    origin: GF_SOURCE,
    url: GF_URL + 'portlligatsans/v11/kmKmZrYrGBbdN1aV7Vokow6Lw4s4l7N0Tx4xEcQ.ttf',
    variant: 'regular',
    family: 'Port Lligat Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '628db269-fa99-40ea-95e5-7091142bc29d',
    origin: GF_SOURCE,
    url: GF_URL + 'crushed/v13/U9Mc6dym6WXImTlFT1kfuIqyLzA.ttf',
    variant: 'regular',
    family: 'Crushed',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'ce82047b-53a4-41ca-8324-7cc2217c2786',
    origin: GF_SOURCE,
    url: GF_URL + 'bubblerone/v11/f0Xy0eqj68ppQV9KBLmAouHH26MPePkt.ttf',
    variant: 'regular',
    family: 'Bubbler One',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '9aa33917-5968-43ec-be03-ab9fdb2f5b27',
    origin: GF_SOURCE,
    url: GF_URL + 'eater/v11/mtG04_FCK7bOvpu2u3FwsXsR.ttf',
    variant: 'regular',
    family: 'Eater',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '35d731ae-4efe-4207-b71a-ac46139cdea7',
    origin: GF_SOURCE,
    url: GF_URL + 'chicle/v11/lJwG-pw9i2dqU-BDyWKuobYSxw.ttf',
    variant: 'regular',
    family: 'Chicle',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f282f927-4fb9-402f-b829-e98c73b5efa1',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2FzfZ5fmddNNissxJvzIqCkDyvqZA.ttf',
    variant: '700italic',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '679d547f-d6ad-4f6e-a074-bee1cf3618db',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2FxfZ5fmddNPpUxWJ75JoKhHys.ttf',
    variant: '700',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ff43ead2-e135-48eb-9bda-8851b1667f6c',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2FzfZ5fmddNNissoJrzIqCkDyvqZA.ttf',
    variant: '600italic',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a7918842-9c23-4bee-b6e5-97cac7a9089e',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2FxfZ5fmddNPvEwWJ75JoKhHys.ttf',
    variant: '600',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '469ddf9a-75b1-437f-b203-9198228df8ac',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2FzfZ5fmddNNissjJ3zIqCkDyvqZA.ttf',
    variant: '500italic',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '57a92b22-ca93-47ab-9a74-6a775fccac93',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2FxfZ5fmddNPt03WJ75JoKhHys.ttf',
    variant: '500',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'cc96ac58-bc42-479a-939b-b8843ca5fa30',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2FwfZ5fmddNNisUeLTXKou4Bg.ttf',
    variant: 'italic',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'e2515cd8-5fa4-4e6b-a666-5d25caae8145',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2F-fZ5fmddNBikefJbSOos.ttf',
    variant: 'regular',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'b0ab43d9-e5d1-4e28-bfdd-278380876e23',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2FzfZ5fmddNNiss1JzzIqCkDyvqZA.ttf',
    variant: '300italic',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '102d2a3d-7565-4e11-a357-beff153b7ff9',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2FxfZ5fmddNPoU2WJ75JoKhHys.ttf',
    variant: '300',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'aabbf5f5-4f31-4b3b-96bf-0023ff1eef2b',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2FzfZ5fmddNNisssJ_zIqCkDyvqZA.ttf',
    variant: '200italic',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '2ad54f2b-450c-4c11-ac41-6ffce1ca7ad6',
    origin: GF_SOURCE,
    url: GF_URL + 'koho/v6/K2FxfZ5fmddNPuE1WJ75JoKhHys.ttf',
    variant: '200',
    family: 'KoHo',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '74c1e94a-f848-4411-8eed-b16d44d6785e',
    origin: GF_SOURCE,
    url: GF_URL + 'swankyandmoomoo/v12/flUlRrKz24IuWVI_WJYTYcqbEsMUZ3kUtbPkR64SYQ.ttf',
    variant: 'regular',
    family: 'Swanky and Moo Moo',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2a2e56da-4dfa-4cff-a861-a2ceb5b799c8',
    origin: GF_SOURCE,
    url: GF_URL + 'rationale/v14/9XUnlJ92n0_JFxHIfHcsdlFMzLC2Zw.ttf',
    variant: 'regular',
    family: 'Rationale',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'a41e8fad-619f-494b-939c-45338ebb5901',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8kUFFYpUN-dsIWs.ttf',
    variant: '900italic',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '32a156f3-a056-4826-b2b9-2550ec3aca88',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8mwFFYpUN-dsIWs.ttf',
    variant: '800italic',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '80a41ce9-c5db-4370-a24b-83a08b4309dc',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8gsFFYpUN-dsIWs.ttf',
    variant: '700italic',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '5bd58704-8483-4c2c-8d42-cda9d15cfa0b',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8jIFFYpUN-dsIWs.ttf',
    variant: '600italic',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '160a1953-598e-42da-af91-dd5ebe480e54',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8t4CFYpUN-dsIWs.ttf',
    variant: '500italic',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'dffc31ee-13f5-4b9d-903c-71f30bcdbf4d',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8uwCFYpUN-dsIWs.ttf',
    variant: 'italic',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '2ad10aa0-29ba-4a92-8aba-bf96c3ff9a4d',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8rICFYpUN-dsIWs.ttf',
    variant: '300italic',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '29fd5203-9e84-4c7d-a061-6ba6d9f7093a',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8mwCFYpUN-dsIWs.ttf',
    variant: '200italic',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '19cefae6-e779-47f2-9fdf-86fe7909c5c4',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8uwDFYpUN-dsIWs.ttf',
    variant: '100italic',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '132a84bd-4444-4ac1-8051-e6189dcaa8c7',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGl4_NXL7bZo9XXq35wRLONYyOjFk4ktgRBH452Mvds.ttf',
    variant: '900',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '5922d9eb-0b3b-45ad-af11-ec2797a7c0ea',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGl4_NXL7bZo9XXq35wRLONYyOjFk4NtgRBH452Mvds.ttf',
    variant: '800',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'e87b6586-02ab-461f-a401-a821fc5a428e',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGl4_NXL7bZo9XXq35wRLONYyOjFk5qtgRBH452Mvds.ttf',
    variant: '700',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'ae937b6b-e68b-4ed5-94b5-425abb5c2437',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGl4_NXL7bZo9XXq35wRLONYyOjFk5TtgRBH452Mvds.ttf',
    variant: '600',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'd7caed18-cb67-4f13-832d-487becccfbc2',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6_sQRBH452Mvds.ttf',
    variant: '500',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '5a0fc7e8-56a9-44d9-8e3f-47f0fa2a1227',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6NsQRBH452Mvds.ttf',
    variant: 'regular',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'ecd92be1-608f-4c22-a3fc-abf3e72ddd04',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGl4_NXL7bZo9XXq35wRLONYyOjFk7TsQRBH452Mvds.ttf',
    variant: '300',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '290e1686-c4a0-44e2-8ca5-0464670f550c',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGl4_NXL7bZo9XXq35wRLONYyOjFk4NsQRBH452Mvds.ttf',
    variant: '200',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'bb584a9a-b4dc-4436-a544-e74e18b5ce60',
    origin: GF_SOURCE,
    url: GF_URL + 'petrona/v16/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6NsARBH452Mvds.ttf',
    variant: '100',
    family: 'Petrona',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'a8eb09a3-ee0e-45b2-b6ba-a2b3a79aef3d',
    origin: GF_SOURCE,
    url: GF_URL + 'cagliostro/v11/ZgNWjP5HM73BV5amnX-TjGXEM4COoE4.ttf',
    variant: 'regular',
    family: 'Cagliostro',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c3767afb-5df8-4d20-97db-c40b56b91145',
    origin: GF_SOURCE,
    url: GF_URL + 'elsieswashcaps/v11/845ENN8xGZyVX5MVo_upKf7KnjK0RW74DG2HToawrdU.ttf',
    variant: '900',
    family: 'Elsie Swash Caps',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0984c251-2805-419c-a84a-f20ae593a1e8',
    origin: GF_SOURCE,
    url: GF_URL + 'elsieswashcaps/v11/845DNN8xGZyVX5MVo_upKf7KnjK0ferVKGWsUo8.ttf',
    variant: 'regular',
    family: 'Elsie Swash Caps',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '01ffbb64-0bca-4adb-a294-67c8a90ada8d',
    origin: GF_SOURCE,
    url: GF_URL + 'overlocksc/v11/1cX3aUHKGZrstGAY8nwVzHGAq8Sk1PoH.ttf',
    variant: 'regular',
    family: 'Overlock SC',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '3592cf54-4ca5-44e1-a158-43adac0fc003',
    origin: GF_SOURCE,
    url: GF_URL + 'englebert/v10/xn7iYH8w2XGrC8AR4HSxT_fYdN-WZw.ttf',
    variant: 'regular',
    family: 'Englebert',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ce6290db-eb61-43b1-8c02-3982f06701df',
    origin: GF_SOURCE,
    url: GF_URL + 'lakkireddy/v9/S6u5w49MUSzD9jlCPmvLZQfox9k97-xZ.ttf',
    variant: 'regular',
    family: 'Lakki Reddy',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'b6de0f80-e2d8-4081-9e80-63743d586bc8',
    origin: GF_SOURCE,
    url: GF_URL + 'baloobhai2/v4/sZlcdRSL-z1VEWZ4YNA7Y5IP0fjCgFZ8HNV3Nw.ttf',
    variant: '800',
    family: 'Baloo Bhai 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c53c163b-47ce-44d4-ae91-71e93e20b341',
    origin: GF_SOURCE,
    url: GF_URL + 'baloobhai2/v4/sZlcdRSL-z1VEWZ4YNA7Y5IPzfvCgFZ8HNV3Nw.ttf',
    variant: '700',
    family: 'Baloo Bhai 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c6de515e-eb5c-4af6-81ce-f8a765ffbfb5',
    origin: GF_SOURCE,
    url: GF_URL + 'baloobhai2/v4/sZlcdRSL-z1VEWZ4YNA7Y5IPqfrCgFZ8HNV3Nw.ttf',
    variant: '600',
    family: 'Baloo Bhai 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ca82ae9b-4439-468b-acf4-755a2bda8450',
    origin: GF_SOURCE,
    url: GF_URL + 'baloobhai2/v4/sZlcdRSL-z1VEWZ4YNA7Y5IPhf3CgFZ8HNV3Nw.ttf',
    variant: '500',
    family: 'Baloo Bhai 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '0576a1c3-f6fa-4802-afcf-a08954f3af07',
    origin: GF_SOURCE,
    url: GF_URL + 'baloobhai2/v4/sZlDdRSL-z1VEWZ4YNA7Y5I3cdTmiH1gFQ.ttf',
    variant: 'regular',
    family: 'Baloo Bhai 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'df6d4c85-5b49-47e0-9b10-e1ff9af4f587',
    origin: GF_SOURCE,
    url: GF_URL + 'koulen/v16/AMOQz46as3KIBPeWgnA9kuYMUg.ttf',
    variant: 'regular',
    family: 'Koulen',
    category: 'display',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '0cb7c1d0-3c5f-4993-91d9-fb053ff6295e',
    origin: GF_SOURCE,
    url: GF_URL + 'mysteryquest/v10/-nF6OG414u0E6k0wynSGlujRHwElD_9Qz9E.ttf',
    variant: 'regular',
    family: 'Mystery Quest',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '2d63d412-12c8-4d62-823d-7cb75564cc74',
    origin: GF_SOURCE,
    url: GF_URL + 'mogra/v9/f0X40eSs8c95TBo4DvLmxtnG.ttf',
    variant: 'regular',
    family: 'Mogra',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '87698d9c-8bc6-439b-a908-bde007e42326',
    origin: GF_SOURCE,
    url: GF_URL + 'prociono/v12/r05YGLlR-KxAf9GGO8upyDYtStiJ.ttf',
    variant: 'regular',
    family: 'Prociono',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '735418ca-b672-43f1-8da1-2fd7f4252e05',
    origin: GF_SOURCE,
    url: GF_URL + 'eastseadokdo/v10/xfuo0Wn2V2_KanASqXSZp22m05_aGavYS18y.ttf',
    variant: 'regular',
    family: 'East Sea Dokdo',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ddff1883-4a9b-40a8-9965-579c6dd6e5de',
    origin: GF_SOURCE,
    url: GF_URL + 'modak/v8/EJRYQgs1XtIEsnMH8BVZ76KU.ttf',
    variant: 'regular',
    family: 'Modak',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e3235b0e-8af8-43b5-9540-17f1c08e8132',
    origin: GF_SOURCE,
    url: GF_URL + 'stalemate/v10/taiIGmZ_EJq97-UfkZRpuqSs8ZQpaQ.ttf',
    variant: 'regular',
    family: 'Stalemate',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'd4a8b557-cd1c-42f4-8940-ea6fcc304901',
    origin: GF_SOURCE,
    url: GF_URL + 'gamjaflower/v10/6NUR8FiKJg-Pa0rM6uN40Z4kyf9Fdty2ew.ttf',
    variant: 'regular',
    family: 'Gamja Flower',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '68543c78-565e-40c7-89d7-374524da8031',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'imfellfrenchcanonsc/v12/FBVmdCru5-ifcor2bgq9V89khWcmQghEURY7H3c0UBCVIVqH.ttf',
    variant: 'regular',
    family: 'IM Fell French Canon SC',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c685dea2-53aa-4b3a-9285-b48a8e576e7b',
    origin: GF_SOURCE,
    url: GF_URL + 'quintessential/v10/fdNn9sOGq31Yjnh3qWU14DdtjY5wS7kmAyxM.ttf',
    variant: 'regular',
    family: 'Quintessential',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '776d5e68-d2d1-45f6-a20a-4bf41f7faf2e',
    origin: GF_SOURCE,
    url: GF_URL + 'songmyung/v10/1cX2aUDWAJH5-EIC7DIhr1GqhcitzeM.ttf',
    variant: 'regular',
    family: 'Song Myung',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '58f1e375-bee3-464a-a74f-b070c7b5f88f',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cXqaUPOAJv9sG4I-DJWjUk8hOCs_-YOoIgN.ttf',
    variant: '700italic',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '709aa0e2-b47d-41f3-93b6-3c374fa940a2',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cX0aUPOAJv9sG4I-DJeM1Sggeqo3eMeoA.ttf',
    variant: '700',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '846bacaf-ccae-4457-be0b-45b4a36411c7',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cXqaUPOAJv9sG4I-DJWjUlYheCs_-YOoIgN.ttf',
    variant: '600italic',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '2ae83c8c-0e0f-4f59-9a69-84a73f01d990',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cX0aUPOAJv9sG4I-DJeV1Wggeqo3eMeoA.ttf',
    variant: '600',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '81271711-224f-42a2-ae72-a46a73029404',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cXqaUPOAJv9sG4I-DJWjUl0guCs_-YOoIgN.ttf',
    variant: '500italic',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'cd7bddc3-0fa9-46b8-8ecf-df7d119514c7',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cX0aUPOAJv9sG4I-DJee1Kggeqo3eMeoA.ttf',
    variant: '500',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '11f68e90-6638-4370-9f89-494a248e8ce7',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cX3aUPOAJv9sG4I-DJWjXGAq8Sk1PoH.ttf',
    variant: 'italic',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '44eae4f8-ea5a-47f4-b276-0ff7eed21ff2',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cXxaUPOAJv9sG4I-DJmj3uEicG01A.ttf',
    variant: 'regular',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c2f81748-61ef-4ddf-94c3-6f0caeef8aab',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cXqaUPOAJv9sG4I-DJWjUksg-Cs_-YOoIgN.ttf',
    variant: '300italic',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd79f2b1d-8f7a-452a-a026-939202e9bc49',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cX0aUPOAJv9sG4I-DJeI1Oggeqo3eMeoA.ttf',
    variant: '300',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f113794d-b92e-4942-a768-f0a08d5669a3',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cXqaUPOAJv9sG4I-DJWjUlIgOCs_-YOoIgN.ttf',
    variant: '200italic',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '29bf2f53-601c-432c-a76c-d6a84166f7f1',
    origin: GF_SOURCE,
    url: GF_URL + 'kodchasan/v6/1cX0aUPOAJv9sG4I-DJeR1Cggeqo3eMeoA.ttf',
    variant: '200',
    family: 'Kodchasan',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '70ba8872-c7e2-450a-9055-de7be38725f5',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLjrETNbFtZCeGqgRXXQ-HPDMCDjEd4yVY.ttf',
    variant: '900italic',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '4b92bab6-8c80-4a3e-aa59-7a8557397252',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLhrETNbFtZCeGqgR1RXEXIBsShiVd4.ttf',
    variant: '900',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'fc0c5477-d1a1-43f9-940b-8b921ba7e301',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLjrETNbFtZCeGqgRXXQ8XODMCDjEd4yVY.ttf',
    variant: '800italic',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b46dc548-591c-4757-9d4b-bd6623adfda2',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLhrETNbFtZCeGqgR11XUXIBsShiVd4.ttf',
    variant: '800',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6393955a-da3f-464c-b9d5-897c0191236a',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLjrETNbFtZCeGqgRXXQ9nNDMCDjEd4yVY.ttf',
    variant: '700italic',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '507c83a4-11c0-4e62-9feb-c16d01b660f0',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLhrETNbFtZCeGqgR1pXkXIBsShiVd4.ttf',
    variant: '700',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '70e82641-fefd-4452-b4da-6154cced2b65',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLjrETNbFtZCeGqgRXXQ73MDMCDjEd4yVY.ttf',
    variant: '600italic',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'c05c7382-3b22-4f91-a25c-e803aaa37eea',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLhrETNbFtZCeGqgR0NX0XIBsShiVd4.ttf',
    variant: '600',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e3e0c0da-f61e-4ba1-87d3-9781eb7897bc',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLjrETNbFtZCeGqgRXXQ5HLDMCDjEd4yVY.ttf',
    variant: '500italic',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1738ec82-bab3-4c3c-9b08-52696480189d',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLhrETNbFtZCeGqgR0hWEXIBsShiVd4.ttf',
    variant: '500',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'c021c87a-1d95-42c5-8731-73e5466dc6f8',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLgrETNbFtZCeGqgRXXe2XiKMiokE4.ttf',
    variant: 'italic',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '88b0ea74-9c3e-454c-8a1b-2f9a6592820e',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLmrETNbFtZCeGqgSXVcWHALdio.ttf',
    variant: 'regular',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '83db9579-43c1-498d-89cf-f459d59d876d',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLjrETNbFtZCeGqgRXXQ8nKDMCDjEd4yVY.ttf',
    variant: '300italic',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '7f1327d4-c942-4af9-b3b9-fd9f022d3e79',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLhrETNbFtZCeGqgR15WUXIBsShiVd4.ttf',
    variant: '300',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2a9179da-e805-4472-9967-e645835f6f4e',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLjrETNbFtZCeGqgRXXQ63JDMCDjEd4yVY.ttf',
    variant: '200italic',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '8e111011-c774-4ec2-9c82-3e3e68e516bf',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLhrETNbFtZCeGqgR0dWkXIBsShiVd4.ttf',
    variant: '200',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2e9d0742-06bb-4bf2-bf5f-9b37fd0fefc7',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLirETNbFtZCeGqgRXXQwHoLOqtgE5h0A.ttf',
    variant: '100italic',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '417d0b05-4ae5-4e32-adcf-6655128ed82f',
    origin: GF_SOURCE,
    url: GF_URL + 'tomorrow/v5/WBLgrETNbFtZCeGqgR2xe2XiKMiokE4.ttf',
    variant: '100',
    family: 'Tomorrow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'dd2bdb07-bf11-4e59-a746-be08b82bf5f3',
    origin: GF_SOURCE,
    url: GF_URL + 'textmeone/v10/i7dOIFdlayuLUvgoFvHQFWZcalayGhyV.ttf',
    variant: 'regular',
    family: 'Text Me One',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b13dc784-f8d6-4536-bc59-237af2f118b1',
    origin: GF_SOURCE,
    url: GF_URL + 'rosarivo/v10/PlI4Fl2lO6N9f8HaNDeA0Hxumcy5ZX8.ttf',
    variant: 'italic',
    family: 'Rosarivo',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'e579fcad-89c1-4ed2-bdb0-5d75c127dc79',
    origin: GF_SOURCE,
    url: GF_URL + 'rosarivo/v10/PlI-Fl2lO6N9f8HaNAeC2nhMnNy5.ttf',
    variant: 'regular',
    family: 'Rosarivo',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '80cc6e6c-cec1-4070-b4d6-3c2499c35d23',
    origin: GF_SOURCE,
    url: GF_URL + 'matesc/v11/-nF8OGQ1-uoVr2wKyiXZ95OkJwA.ttf',
    variant: 'regular',
    family: 'Mate SC',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'bac066b0-5724-493d-98d6-62f7a4e5d237',
    origin: GF_SOURCE,
    url: GF_URL + 'uncialantiqua/v10/N0bM2S5WOex4OUbESzoESK-i-PfRS5VBBSSF.ttf',
    variant: 'regular',
    family: 'Uncial Antiqua',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4af4cde9-ffdd-4670-8d5f-941f3c4e17d3',
    origin: GF_SOURCE,
    url: GF_URL + 'fontdinerswanky/v11/ijwOs4XgRNsiaI5-hcVb4hQgMvCD4uEfKiGvxts.ttf',
    variant: 'regular',
    family: 'Fontdiner Swanky',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '9c8d9782-4147-4ed5-a01e-a9315d3ac3eb',
    origin: GF_SOURCE,
    url: GF_URL + 'kiwimaru/v1/R70djykGkuuDep-hRg6gbCm0Vxn9R5ShnA.ttf',
    variant: '500',
    family: 'Kiwi Maru',
    category: 'serif',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '184f58aa-8b80-4e69-ba11-ab9582937e7a',
    origin: GF_SOURCE,
    url: GF_URL + 'kiwimaru/v1/R70YjykGkuuDep-hRg6YmACQXzLhTg.ttf',
    variant: 'regular',
    family: 'Kiwi Maru',
    category: 'serif',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: 'a83e939d-b0cf-441a-a6ea-ca297f274b47',
    origin: GF_SOURCE,
    url: GF_URL + 'kiwimaru/v1/R70djykGkuuDep-hRg6gNCi0Vxn9R5ShnA.ttf',
    variant: '300',
    family: 'Kiwi Maru',
    category: 'serif',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: 'c162abce-c570-4434-99a2-2044cd7153eb',
    origin: GF_SOURCE,
    url: GF_URL + 'sarina/v11/-F6wfjF3ITQwasLhLkDUriBQxw.ttf',
    variant: 'regular',
    family: 'Sarina',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7acddc92-c0bb-4aae-9646-fbab19e47b48',
    origin: GF_SOURCE,
    url: GF_URL + 'baloopaaji2/v4/i7dRIFFzbz-QHZUdV9_UGWZuWPK8OwS-a3yGe9E.ttf',
    variant: '800',
    family: 'Baloo Paaji 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '17e0a7cd-7831-4f91-9694-1b962624f874',
    origin: GF_SOURCE,
    url: GF_URL + 'baloopaaji2/v4/i7dRIFFzbz-QHZUdV9_UGWZuWO6_OwS-a3yGe9E.ttf',
    variant: '700',
    family: 'Baloo Paaji 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '7ccd8b9c-a4c2-4315-a4df-47d7ff3aaa35',
    origin: GF_SOURCE,
    url: GF_URL + 'baloopaaji2/v4/i7dRIFFzbz-QHZUdV9_UGWZuWIq-OwS-a3yGe9E.ttf',
    variant: '600',
    family: 'Baloo Paaji 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ff6adb5c-6ce5-4faa-a64d-47b6af2336c7',
    origin: GF_SOURCE,
    url: GF_URL + 'baloopaaji2/v4/i7dRIFFzbz-QHZUdV9_UGWZuWKa5OwS-a3yGe9E.ttf',
    variant: '500',
    family: 'Baloo Paaji 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'cbfb8016-71cd-453d-a364-90643b290560',
    origin: GF_SOURCE,
    url: GF_URL + 'baloopaaji2/v4/i7dMIFFzbz-QHZUdV9_UGWZuYFKQHwyVd3U.ttf',
    variant: 'regular',
    family: 'Baloo Paaji 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ec40b5d8-2d27-48c6-b4a2-a85bdf18c593',
    origin: GF_SOURCE,
    url: GF_URL + 'julee/v12/TuGfUVB3RpZPQ6ZLodgzydtk.ttf',
    variant: 'regular',
    family: 'Julee',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '8d3286d5-b416-42c8-86d0-1d033fd0169c',
    origin: GF_SOURCE,
    url: GF_URL + 'paprika/v10/8QIJdijZitv49rDfuIgOq7jkAOw.ttf',
    variant: 'regular',
    family: 'Paprika',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'e53bcb04-0770-45e7-a45a-f6347ff62032',
    origin: GF_SOURCE,
    url: GF_URL + 'chilanka/v8/WWXRlj2DZQiMJYaYRrJQI9EAZhTO.ttf',
    variant: 'regular',
    family: 'Chilanka',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '09ef1f92-043f-4d44-84b1-b948569a68fc',
    origin: GF_SOURCE,
    url:
      GF_URL + 'museomoderno/v7/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMv9DuewajeKlCdo.ttf',
    variant: '900',
    family: 'MuseoModerno',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '579dbbce-cab9-416c-bffd-a371a9312a13',
    origin: GF_SOURCE,
    url:
      GF_URL + 'museomoderno/v7/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMtZDuewajeKlCdo.ttf',
    variant: '800',
    family: 'MuseoModerno',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '13d6d6c9-ac5e-4e28-bb8b-3d81fd1a11ce',
    origin: GF_SOURCE,
    url:
      GF_URL + 'museomoderno/v7/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMrFDuewajeKlCdo.ttf',
    variant: '700',
    family: 'MuseoModerno',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '4527be4e-7807-470c-be98-662a1378f6f9',
    origin: GF_SOURCE,
    url:
      GF_URL + 'museomoderno/v7/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMohDuewajeKlCdo.ttf',
    variant: '600',
    family: 'MuseoModerno',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'f2d58f6f-dc41-4ad9-9960-bb33a5c12ac6',
    origin: GF_SOURCE,
    url:
      GF_URL + 'museomoderno/v7/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMmREuewajeKlCdo.ttf',
    variant: '500',
    family: 'MuseoModerno',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'adc39acb-01ee-4765-b444-ee8b38fd19ab',
    origin: GF_SOURCE,
    url:
      GF_URL + 'museomoderno/v7/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMlZEuewajeKlCdo.ttf',
    variant: 'regular',
    family: 'MuseoModerno',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '7df71b44-cff4-40db-9312-7c25a7143794',
    origin: GF_SOURCE,
    url:
      GF_URL + 'museomoderno/v7/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMghEuewajeKlCdo.ttf',
    variant: '300',
    family: 'MuseoModerno',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '6ac89d09-c16d-438d-a3b6-da52b115b688',
    origin: GF_SOURCE,
    url:
      GF_URL + 'museomoderno/v7/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMtZEuewajeKlCdo.ttf',
    variant: '200',
    family: 'MuseoModerno',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'e3da765d-e695-4793-8ceb-c24c153fd423',
    origin: GF_SOURCE,
    url:
      GF_URL + 'museomoderno/v7/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMlZFuewajeKlCdo.ttf',
    variant: '100',
    family: 'MuseoModerno',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'ab69c748-77d3-40b5-b05c-2034992fee61',
    origin: GF_SOURCE,
    url: GF_URL + 'kottaone/v10/S6u_w41LXzPc_jlfNWqPHA3s5dwt7w.ttf',
    variant: 'regular',
    family: 'Kotta One',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '14a85f1e-39f2-4efd-913b-5d52f05d98c9',
    origin: GF_SOURCE,
    url: GF_URL + 'shanti/v14/t5thIREMM4uSDgzgU0ezpKfwzA.ttf',
    variant: 'regular',
    family: 'Shanti',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '7ee39e7a-528c-4af5-9ce4-6d89127bc881',
    origin: GF_SOURCE,
    url: GF_URL + 'charmonman/v8/MjQAmiR3vP_nuxDv47jiYC2HmL9K9OhmGnY.ttf',
    variant: '700',
    family: 'Charmonman',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '0efcef40-266f-4e74-b6dd-68d687e1b781',
    origin: GF_SOURCE,
    url: GF_URL + 'charmonman/v8/MjQDmiR3vP_nuxDv47jiWJGovLdh6OE.ttf',
    variant: 'regular',
    family: 'Charmonman',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '1f5ec47b-d271-4cdf-aa8a-21b031ff68a1',
    origin: GF_SOURCE,
    url: GF_URL + 'moul/v14/nuF2D__FSo_3E-RYiJCy-00.ttf',
    variant: 'regular',
    family: 'Moul',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '255749aa-01b1-4cb7-9660-a067a78e16b5',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'imfellfrenchcanon/v12/-F6gfiNtDWYfYc-tDiyiw08rrghJszkK6foXNNlKy5PzzrU.ttf',
    variant: 'italic',
    family: 'IM Fell French Canon',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '68650b61-71a0-4316-9f25-2cc166c57b9f',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imfellfrenchcanon/v12/-F6ufiNtDWYfYc-tDiyiw08rrghJszkK6coVPt1ozoPz.ttf',
    variant: 'regular',
    family: 'IM Fell French Canon',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'd39c59bc-36aa-46bb-90f0-3708ff0e3351',
    origin: GF_SOURCE,
    url: GF_URL + 'habibi/v11/CSR-4zFWkuqcTTNCShJeZOYySQ.ttf',
    variant: 'regular',
    family: 'Habibi',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '25926fcd-22ef-4a2e-9f75-e690aebef850',
    origin: GF_SOURCE,
    url: GF_URL + 'portlligatslab/v11/LDIpaoiQNgArA8kR7ulhZ8P_NYOss7ob9yGLmfI.ttf',
    variant: 'regular',
    family: 'Port Lligat Slab',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a592e8d6-ef19-426a-b721-df2b1e913d48',
    origin: GF_SOURCE,
    url: GF_URL + 'rowdies/v5/ptRMTieMYPNBAK219gtm5O7yKQNute8.ttf',
    variant: '700',
    family: 'Rowdies',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3bb7475c-3cc1-4d77-9410-4282aa2d7654',
    origin: GF_SOURCE,
    url: GF_URL + 'rowdies/v5/ptRJTieMYPNBAK21zrdJwObZNQo.ttf',
    variant: 'regular',
    family: 'Rowdies',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '393b9de5-889c-4896-8edb-e55b97b5c972',
    origin: GF_SOURCE,
    url: GF_URL + 'rowdies/v5/ptRMTieMYPNBAK219hth5O7yKQNute8.ttf',
    variant: '300',
    family: 'Rowdies',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '54a744ea-df83-4772-a88e-49f82da167af',
    origin: GF_SOURCE,
    url: GF_URL + 'baloobhaina2/v4/qWcwB6yyq4P9Adr3RtoX1q6ySiRbtBEgkYYBX_3F.ttf',
    variant: '800',
    family: 'Baloo Bhaina 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'bf52f404-71ec-48aa-8d9d-24024fa940ca',
    origin: GF_SOURCE,
    url: GF_URL + 'baloobhaina2/v4/qWcwB6yyq4P9Adr3RtoX1q6ySiRHtxEgkYYBX_3F.ttf',
    variant: '700',
    family: 'Baloo Bhaina 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'f6dd043c-49f7-43da-87a9-45345b8336cc',
    origin: GF_SOURCE,
    url: GF_URL + 'baloobhaina2/v4/qWcwB6yyq4P9Adr3RtoX1q6ySiQjthEgkYYBX_3F.ttf',
    variant: '600',
    family: 'Baloo Bhaina 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '8fbb0390-3bae-46c8-889c-7736140b98a7',
    origin: GF_SOURCE,
    url: GF_URL + 'baloobhaina2/v4/qWcwB6yyq4P9Adr3RtoX1q6ySiQPsREgkYYBX_3F.ttf',
    variant: '500',
    family: 'Baloo Bhaina 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '989400e0-f933-489c-bacd-d1216c627ace',
    origin: GF_SOURCE,
    url: GF_URL + 'baloobhaina2/v4/qWczB6yyq4P9Adr3RtoX1q6yShz7mDUoupoI.ttf',
    variant: 'regular',
    family: 'Baloo Bhaina 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'e0192e9e-8fe9-4c69-ae40-1ad30ee30bee',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO8LeVOQk6OThxPA.ttf',
    variant: '800italic',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '68b1a8ee-bf25-419a-9c1e-f5c33d2e5c52',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO9seVOQk6OThxPA.ttf',
    variant: '700italic',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8a125c99-4c87-4957-9b00-5eb90df81157',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO9VeVOQk6OThxPA.ttf',
    variant: '600italic',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '9a7c1c8d-65ca-4ff5-be5c-ac181f51c7c1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO-5flOQk6OThxPA.ttf',
    variant: '500italic',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd89e58cf-eb78-4b64-9ad6-99d7a28e8e50',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO-LflOQk6OThxPA.ttf',
    variant: 'italic',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '03e6ba36-172e-4558-86ab-a7d314419665',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO_VflOQk6OThxPA.ttf',
    variant: '300italic',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '7a747abf-1fab-4994-bfc5-4639bc04f9b3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO8LflOQk6OThxPA.ttf',
    variant: '200italic',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '2b9bae5d-4bc1-4837-a717-7af3d8567736',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO-Lf1OQk6OThxPA.ttf',
    variant: '100italic',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'dce6a5da-1930-42c7-88bd-fd793cfb48ef',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8SKtjPVmUsaaDhw.ttf',
    variant: '800',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '9d958438-279f-4a5f-bdd7-54f9ee1e44f1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8L6tjPVmUsaaDhw.ttf',
    variant: '700',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ceee592e-2c53-4450-b1bd-0228334fd041',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8FqtjPVmUsaaDhw.ttf',
    variant: '600',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '72bc3e63-3875-40ab-a16e-7c3aff443b82',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8-qxjPVmUsaaDhw.ttf',
    variant: '500',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '62d26c94-d413-446a-8fa1-e5c5f33aea51',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yKxjPVmUsaaDhw.ttf',
    variant: 'regular',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '98fd67fb-50fd-4224-9436-624e8338dbb3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8lqxjPVmUsaaDhw.ttf',
    variant: '300',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '4613d057-1352-4dfd-8cb1-5b0c9d858283',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8SKxjPVmUsaaDhw.ttf',
    variant: '200',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'cd6d7f16-245a-4514-9c26-d2843664462e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'jetbrainsmono/v6/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yK1jPVmUsaaDhw.ttf',
    variant: '100',
    family: 'JetBrains Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '765758a5-1652-4653-8ee3-008b80bb312f',
    origin: GF_SOURCE,
    url: GF_URL + 'leaguescript/v14/CSR54zpSlumSWj9CGVsoBZdeaNNUuOwkC2s.ttf',
    variant: 'regular',
    family: 'League Script',
    category: 'handwriting',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '25a94654-ed5a-4d33-aba0-d85780417e28',
    origin: GF_SOURCE,
    url: GF_URL + 'loversquarrel/v10/Yq6N-LSKXTL-5bCy8ksBzpQ_-zAsY7pO6siz.ttf',
    variant: 'regular',
    family: 'Lovers Quarrel',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'facbbee7-cfab-4022-b4ec-7cb01abdf957',
    origin: GF_SOURCE,
    url: GF_URL + 'fjordone/v11/zOL-4pbEnKBY_9S1jNKr6e5As-FeiQ.ttf',
    variant: 'regular',
    family: 'Fjord One',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '067acc35-da90-4e5c-9d3a-1a0fdf1dc600',
    origin: GF_SOURCE,
    url: GF_URL + 'pirataone/v11/I_urMpiDvgLdLh0fAtoftiiEr5_BdZ8.ttf',
    variant: 'regular',
    family: 'Pirata One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'ee2d4d8c-40f8-476e-85b1-55bdbcc8726c',
    origin: GF_SOURCE,
    url: GF_URL + 'spicyrice/v11/uK_24rSEd-Uqwk4jY1RyGv-2WkowRcc.ttf',
    variant: 'regular',
    family: 'Spicy Rice',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '88e3b954-1517-4de0-a0df-3cbf7ff6b795',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantunicase/v13/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9Nvsecv7Gy0DRzS.ttf',
    variant: '700',
    family: 'Cormorant Unicase',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '04043b75-0d83-4773-84d9-c7499fe78b58',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantunicase/v13/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9MLsOcv7Gy0DRzS.ttf',
    variant: '600',
    family: 'Cormorant Unicase',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '34f9172c-15dc-4126-b96a-801b17959e5d',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantunicase/v13/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9Mnt-cv7Gy0DRzS.ttf',
    variant: '500',
    family: 'Cormorant Unicase',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '8e30656e-d3df-41eb-ab8b-30b3f0ceaee9',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantunicase/v13/HI_QiZUaILtOqhqgDeXoF_n1_fTGX-vTnsMnx3C9.ttf',
    variant: 'regular',
    family: 'Cormorant Unicase',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '06ee5cd9-ad94-4328-85c6-488e6dfca27b',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantunicase/v13/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9N_tucv7Gy0DRzS.ttf',
    variant: '300',
    family: 'Cormorant Unicase',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'e98c5324-eca3-4d01-85e7-14d8686a5f1f',
    origin: GF_SOURCE,
    url: GF_URL + 'bilbo/v12/o-0EIpgpwWwZ210hpIRz4wxE.ttf',
    variant: 'regular',
    family: 'Bilbo',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c05a1667-b63d-4910-9834-dd1ba0a8de90',
    origin: GF_SOURCE,
    url: GF_URL + 'wireone/v13/qFdH35Wah5htUhV75WGiWdrCwwcJ.ttf',
    variant: 'regular',
    family: 'Wire One',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '4de22bd4-8561-4b4a-902b-00429903dded',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZgcR4sXrJcwHqoQ.ttf',
    variant: '900italic',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '7b8cf797-6e2a-4f26-81e0-a7826de786ef',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZqMR4sXrJcwHqoQ.ttf',
    variant: '800italic',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'aeb5db1a-a870-4cea-9b0c-db59c8c06e0a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZz8R4sXrJcwHqoQ.ttf',
    variant: '700italic',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '5627d0ff-f0af-4ff3-9cd3-265e51358108',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZ9sR4sXrJcwHqoQ.ttf',
    variant: '600italic',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd14f985c-b00b-42f5-afcb-d09f981c36db',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZGsN4sXrJcwHqoQ.ttf',
    variant: '500italic',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '3402f091-03a7-4363-8d70-204e91c3714a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZKMN4sXrJcwHqoQ.ttf',
    variant: 'italic',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd5705006-6724-40f6-afd8-0a814c806229',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oefdwIBytVjMYwE.ttf',
    variant: '900',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '6ae772a2-2989-4b1f-b4d6-80f18cd5d137',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oc7dwIBytVjMYwE.ttf',
    variant: '800',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '8082370e-8f47-4217-bfbd-7ea8bbd134a9',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oandwIBytVjMYwE.ttf',
    variant: '700',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '0c5a136e-81b2-4132-89b1-f5ace742857d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oZDdwIBytVjMYwE.ttf',
    variant: '600',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '5e10c4a8-147a-4d05-8e6c-37d8df8677fa',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oXzawIBytVjMYwE.ttf',
    variant: '500',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'fe5c156f-3618-4a62-ac95-4d6b4f345336',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bodonimoda/v7/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oU7awIBytVjMYwE.ttf',
    variant: 'regular',
    family: 'Bodoni Moda',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '8bf09012-68c2-4565-8220-3af6b99c7d70',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'justmeagaindownhere/v14/MwQmbgXtz-Wc6RUEGNMc0QpRrfUh2hSdBBMoAuwHvqDwc_fg.ttf',
    variant: 'regular',
    family: 'Just Me Again Down Here',
    category: 'handwriting',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'a38a16e5-7f15-425d-b760-1163b868c019',
    origin: GF_SOURCE,
    url: GF_URL + 'peralta/v10/hYkJPu0-RP_9d3kRGxAhrv956B8.ttf',
    variant: 'regular',
    family: 'Peralta',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'c80db8ef-579e-43e2-abf4-1ff5cddf560f',
    origin: GF_SOURCE,
    url: GF_URL + 'fresca/v11/6ae94K--SKgCzbM2Gr0W13DKPA.ttf',
    variant: 'regular',
    family: 'Fresca',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '29a040fb-4cc4-44bd-9423-475e630be973',
    origin: GF_SOURCE,
    url:
      GF_URL + 'bigshoulderstext/v6/55xyezRtP9G3CGPIf49hxc8P0eytUxBUGKFfSOkOfG0Y3A.ttf',
    variant: '900',
    family: 'Big Shoulders Text',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6886b443-2ac6-4469-9a2d-3007e15cf9e4',
    origin: GF_SOURCE,
    url:
      GF_URL + 'bigshoulderstext/v6/55xyezRtP9G3CGPIf49hxc8P0eytUxBUPKBfSOkOfG0Y3A.ttf',
    variant: '800',
    family: 'Big Shoulders Text',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '35807f51-b774-4653-99a5-c6eedf374fcc',
    origin: GF_SOURCE,
    url:
      GF_URL + 'bigshoulderstext/v6/55xyezRtP9G3CGPIf49hxc8P0eytUxBUIKNfSOkOfG0Y3A.ttf',
    variant: '700',
    family: 'Big Shoulders Text',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '763701ae-7a17-4a07-8e3e-47a5ce0ca3c0',
    origin: GF_SOURCE,
    url:
      GF_URL + 'bigshoulderstext/v6/55xyezRtP9G3CGPIf49hxc8P0eytUxBURKJfSOkOfG0Y3A.ttf',
    variant: '600',
    family: 'Big Shoulders Text',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bd64a0d2-b874-48d6-9627-4f8c5f75eb7c',
    origin: GF_SOURCE,
    url:
      GF_URL + 'bigshoulderstext/v6/55xyezRtP9G3CGPIf49hxc8P0eytUxBUaKVfSOkOfG0Y3A.ttf',
    variant: '500',
    family: 'Big Shoulders Text',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6c53f916-e221-4240-9e67-e20128af51ee',
    origin: GF_SOURCE,
    url: GF_URL + 'bigshoulderstext/v6/55xxezRtP9G3CGPIf49hxc8P0eytUxBsnIx7QMISdQ.ttf',
    variant: 'regular',
    family: 'Big Shoulders Text',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8e7fa22a-7ba6-47bf-8cda-c618707e0b03',
    origin: GF_SOURCE,
    url:
      GF_URL + 'bigshoulderstext/v6/55xyezRtP9G3CGPIf49hxc8P0eytUxBUMKRfSOkOfG0Y3A.ttf',
    variant: '300',
    family: 'Big Shoulders Text',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '69b27754-6a01-4c49-9ea0-f48563c7e323',
    origin: GF_SOURCE,
    url: GF_URL + 'bigshoulderstext/v6/55xzezRtP9G3CGPIf49hxc8P0eytUxBU-IZ_YscCdXQB.ttf',
    variant: '100',
    family: 'Big Shoulders Text',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'aac93ce4-156d-4d81-badc-ce8f49ae258d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemiexpanded/v8/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMw4SCyDLJX6XCWU.ttf',
    variant: '900',
    family: 'Encode Sans Semi Expanded',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '7189734a-b00e-4d0b-9720-64a14f5d01ed',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemiexpanded/v8/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMyoTCyDLJX6XCWU.ttf',
    variant: '800',
    family: 'Encode Sans Semi Expanded',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'bac4ba70-9927-46df-b25f-6917b9e678ef',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemiexpanded/v8/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMzYQCyDLJX6XCWU.ttf',
    variant: '700',
    family: 'Encode Sans Semi Expanded',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'de2109f3-d993-4248-a80a-58b9f0273c1d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemiexpanded/v8/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM1IRCyDLJX6XCWU.ttf',
    variant: '600',
    family: 'Encode Sans Semi Expanded',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '080b3548-704c-4f02-89e3-03321b1c7313',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemiexpanded/v8/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM34WCyDLJX6XCWU.ttf',
    variant: '500',
    family: 'Encode Sans Semi Expanded',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '7968966d-ec8a-4480-96fd-fee5fe606b45',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemiexpanded/v8/ke83OhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TC4o_LyjgOXc.ttf',
    variant: 'regular',
    family: 'Encode Sans Semi Expanded',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '9d8f20df-fe53-4dc3-9043-dd23f5e3a314',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemiexpanded/v8/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMyYXCyDLJX6XCWU.ttf',
    variant: '300',
    family: 'Encode Sans Semi Expanded',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '182d6a9b-a452-4002-94f5-4cbe3ac5dfa3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemiexpanded/v8/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM0IUCyDLJX6XCWU.ttf',
    variant: '200',
    family: 'Encode Sans Semi Expanded',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'dd2fdfc4-070c-4506-a4c3-d47fbb626472',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemiexpanded/v8/ke8xOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM-41KwrlKXeOEA.ttf',
    variant: '100',
    family: 'Encode Sans Semi Expanded',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '1ea9c5ce-205b-4315-a964-05b859eda90a',
    origin: GF_SOURCE,
    url: GF_URL + 'ramaraja/v7/SlGTmQearpYAYG1CABIkqnB6aSQU.ttf',
    variant: 'regular',
    family: 'Ramaraja',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '784fd564-f8f2-4282-8f82-96bb91d3b1b8',
    origin: GF_SOURCE,
    url: GF_URL + 'farro/v4/i7dJIFl3byGNHa3xM56-WkJUQUq7.ttf',
    variant: '700',
    family: 'Farro',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a443deb4-8dec-42d7-8075-4dca80883c42',
    origin: GF_SOURCE,
    url: GF_URL + 'farro/v4/i7dJIFl3byGNHa25NZ6-WkJUQUq7.ttf',
    variant: '500',
    family: 'Farro',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '9811108c-55f5-4c9c-8e8a-6c6b56b4593e',
    origin: GF_SOURCE,
    url: GF_URL + 'farro/v4/i7dEIFl3byGNHZVNHLq2cV5d.ttf',
    variant: 'regular',
    family: 'Farro',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'eb6ea57a-0d2f-412d-90ed-adc2278b40af',
    origin: GF_SOURCE,
    url: GF_URL + 'farro/v4/i7dJIFl3byGNHa3hNJ6-WkJUQUq7.ttf',
    variant: '300',
    family: 'Farro',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '54a8e8ff-99ec-4cb1-a7e4-57f082341cf1',
    origin: GF_SOURCE,
    url: GF_URL + 'oxanium/v6/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G83JeXiMBXQ7d67x.ttf',
    variant: '800',
    family: 'Oxanium',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6c4b8afd-eeee-49b7-81ce-a469fb072bdb',
    origin: GF_SOURCE,
    url: GF_URL + 'oxanium/v6/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G82ueXiMBXQ7d67x.ttf',
    variant: '700',
    family: 'Oxanium',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '075b5b49-a99c-44c5-aead-3b2bead8a7f4',
    origin: GF_SOURCE,
    url: GF_URL + 'oxanium/v6/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G82XeXiMBXQ7d67x.ttf',
    variant: '600',
    family: 'Oxanium',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '042094cf-0f40-4d64-b2e2-3d079f0255d2',
    origin: GF_SOURCE,
    url: GF_URL + 'oxanium/v6/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G817fniMBXQ7d67x.ttf',
    variant: '500',
    family: 'Oxanium',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f5114fb2-9081-4523-bdf4-dae20fae9709',
    origin: GF_SOURCE,
    url: GF_URL + 'oxanium/v6/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G81JfniMBXQ7d67x.ttf',
    variant: 'regular',
    family: 'Oxanium',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '5e3773c6-bb69-4686-bc1c-1e8b7409f50a',
    origin: GF_SOURCE,
    url: GF_URL + 'oxanium/v6/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G80XfniMBXQ7d67x.ttf',
    variant: '300',
    family: 'Oxanium',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8d3fc27b-e36b-4ff4-8d06-7399b07af70e',
    origin: GF_SOURCE,
    url: GF_URL + 'oxanium/v6/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G83JfniMBXQ7d67x.ttf',
    variant: '200',
    family: 'Oxanium',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '2bbc725d-1116-49fc-b8cd-d8de75703c6d',
    origin: GF_SOURCE,
    url:
      GF_URL + 'spacegrotesk/v5/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj4PVksjNsFjTDJK.ttf',
    variant: '700',
    family: 'Space Grotesk',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '368a6f7c-f015-42d2-a663-1eb04e1b8ec1',
    origin: GF_SOURCE,
    url:
      GF_URL + 'spacegrotesk/v5/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj42VksjNsFjTDJK.ttf',
    variant: '600',
    family: 'Space Grotesk',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'db39ddb5-2459-4dad-843e-ea3633864f53',
    origin: GF_SOURCE,
    url:
      GF_URL + 'spacegrotesk/v5/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7aUUsjNsFjTDJK.ttf',
    variant: '500',
    family: 'Space Grotesk',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '33bdca73-196f-4e10-9d5f-ee2618ecd127',
    origin: GF_SOURCE,
    url:
      GF_URL + 'spacegrotesk/v5/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7oUUsjNsFjTDJK.ttf',
    variant: 'regular',
    family: 'Space Grotesk',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'c60be52a-b227-4c77-8a2c-2e00c5a6f22c',
    origin: GF_SOURCE,
    url:
      GF_URL + 'spacegrotesk/v5/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj62UUsjNsFjTDJK.ttf',
    variant: '300',
    family: 'Space Grotesk',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '663bf8cd-1eb2-4a8b-b257-80eda4f49493',
    origin: GF_SOURCE,
    url: GF_URL + 'germaniaone/v10/Fh4yPjrqIyv2ucM2qzBjeS3ezAJONau6ew.ttf',
    variant: 'regular',
    family: 'Germania One',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '970b64b4-f4d7-47ee-8dc0-2ccc5acbf9b2',
    origin: GF_SOURCE,
    url: GF_URL + 'voces/v12/-F6_fjJyLyU8d4PBBG7YpzlJ.ttf',
    variant: 'regular',
    family: 'Voces',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '5ac8ca4a-a5c1-43f4-897d-4715b846a082',
    origin: GF_SOURCE,
    url: GF_URL + 'sura/v7/SZc53FL5PbyzLUJ7fz3GkUrS8DI.ttf',
    variant: '700',
    family: 'Sura',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'decec612-45f4-425b-9b80-8e81e7a5e686',
    origin: GF_SOURCE,
    url: GF_URL + 'sura/v7/SZc23FL5PbyzFf5UWzXtjUM.ttf',
    variant: 'regular',
    family: 'Sura',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '27a97580-c6f7-41df-93e7-a96cd8b144e4',
    origin: GF_SOURCE,
    url: GF_URL + 'slackey/v13/N0bV2SdQO-5yM0-dKlRaJdbWgdY.ttf',
    variant: 'regular',
    family: 'Slackey',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '90574374-77ce-49b7-9777-0dd14918fbb6',
    origin: GF_SOURCE,
    url: GF_URL + 'ruslandisplay/v13/Gw6jwczl81XcIZuckK_e3UpfdzxrldyFvm1n.ttf',
    variant: 'regular',
    family: 'Ruslan Display',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '4412c49c-22ac-4807-bda4-5d19ce5267c3',
    origin: GF_SOURCE,
    url: GF_URL + 'goldman/v5/pe0rMIWbN4JFplR2FI5XIteQB9Zra1U.ttf',
    variant: '700',
    family: 'Goldman',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '97672792-ed86-4c04-b4d4-e56acce838d5',
    origin: GF_SOURCE,
    url: GF_URL + 'goldman/v5/pe0uMIWbN4JFplR2LDJ4Bt-7G98.ttf',
    variant: 'regular',
    family: 'Goldman',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'edf6181c-8110-40c1-8d86-07b67ce025b5',
    origin: GF_SOURCE,
    url: GF_URL + 'dynalight/v11/1Ptsg8LOU_aOmQvTsF4ISotrDfGGxA.ttf',
    variant: 'regular',
    family: 'Dynalight',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '4eb5adf1-5cdd-4a1d-9075-829a3fb21cbd',
    origin: GF_SOURCE,
    url: GF_URL + 'newrocker/v11/MwQzbhjp3-HImzcCU_cJkGMViblPtXs.ttf',
    variant: 'regular',
    family: 'New Rocker',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '6ae7e76f-0597-46fd-b821-e25992f3252b',
    origin: GF_SOURCE,
    url: GF_URL + 'manuale/v10/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOLNH3zRdIWHYr8M.ttf',
    variant: '700italic',
    family: 'Manuale',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '31fee22b-466c-40a8-89d1-f64e12a7eb51',
    origin: GF_SOURCE,
    url: GF_URL + 'manuale/v10/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOIpH3zRdIWHYr8M.ttf',
    variant: '600italic',
    family: 'Manuale',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4c6690b0-a989-4b98-9870-320036694da4',
    origin: GF_SOURCE,
    url: GF_URL + 'manuale/v10/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOGZA3zRdIWHYr8M.ttf',
    variant: '500italic',
    family: 'Manuale',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'f5775234-890b-444f-941d-29d2913e189b',
    origin: GF_SOURCE,
    url: GF_URL + 'manuale/v10/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOFRA3zRdIWHYr8M.ttf',
    variant: 'italic',
    family: 'Manuale',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'f1a79c1a-53a8-4f58-a3fa-9def6c0e9085',
    origin: GF_SOURCE,
    url: GF_URL + 'manuale/v10/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeEDfLwD1TB_JHHY.ttf',
    variant: '700',
    family: 'Manuale',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '071294e7-ec76-468b-aac7-c1a428cca7cc',
    origin: GF_SOURCE,
    url: GF_URL + 'manuale/v10/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeE6fLwD1TB_JHHY.ttf',
    variant: '600',
    family: 'Manuale',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '2ab8e14a-3d64-44b9-84d4-8846b1f93c69',
    origin: GF_SOURCE,
    url: GF_URL + 'manuale/v10/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHWe7wD1TB_JHHY.ttf',
    variant: '500',
    family: 'Manuale',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ca774614-293f-44a2-b2b6-43bf3fdc3782',
    origin: GF_SOURCE,
    url: GF_URL + 'manuale/v10/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHke7wD1TB_JHHY.ttf',
    variant: 'regular',
    family: 'Manuale',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'fd20dad6-7678-4b58-97a1-1d92ef0faa21',
    origin: GF_SOURCE,
    url: GF_URL + 'kranky/v13/hESw6XVgJzlPsFnMpheEZo_H_w.ttf',
    variant: 'regular',
    family: 'Kranky',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'ad24c3ad-d305-4c8f-b8cf-9623a3a93905',
    origin: GF_SOURCE,
    url: GF_URL + 'ribeye/v11/L0x8DFMxk1MP9R3RvPCmRSlUig.ttf',
    variant: 'regular',
    family: 'Ribeye',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '46618235-10a8-473b-ae59-1a3998c93edf',
    origin: GF_SOURCE,
    url: GF_URL + 'cantoraone/v12/gyB4hws1JdgnKy56GB_JX6zdZ4vZVbgZ.ttf',
    variant: 'regular',
    family: 'Cantora One',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '6f2f3148-e75a-443f-9a25-c3c2e7385890',
    origin: GF_SOURCE,
    url: GF_URL + 'jomhuria/v12/Dxxp8j-TMXf-llKur2b1MOGbC3Dh.ttf',
    variant: 'regular',
    family: 'Jomhuria',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '705c3039-1b62-471a-80fa-7dd598c73c17',
    origin: GF_SOURCE,
    url: GF_URL + 'hennypenny/v10/wXKvE3UZookzsxz_kjGSfMQqt3M7tMDT.ttf',
    variant: 'regular',
    family: 'Henny Penny',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b0e5f3b0-6859-4eb7-8110-72ceaac621e9',
    origin: GF_SOURCE,
    url: GF_URL + 'puritan/v14/845fNMgkAJ2VTtIoxJjC_dup_2jDVevnLQ.ttf',
    variant: '700italic',
    family: 'Puritan',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '955f0220-57ce-4c6a-8be2-9cfb8b099dc4',
    origin: GF_SOURCE,
    url: GF_URL + 'puritan/v14/845dNMgkAJ2VTtIozCbfYd6j-0rGRes.ttf',
    variant: '700',
    family: 'Puritan',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '21dca6f2-640d-4e2d-aa0e-55141dd17e97',
    origin: GF_SOURCE,
    url: GF_URL + 'puritan/v14/845aNMgkAJ2VTtIoxJj6QfSN90PfXA.ttf',
    variant: 'italic',
    family: 'Puritan',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '7a6014a9-aa57-4def-8634-9a1f10ea1c32',
    origin: GF_SOURCE,
    url: GF_URL + 'puritan/v14/845YNMgkAJ2VTtIo9JrwRdaI50M.ttf',
    variant: 'regular',
    family: 'Puritan',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '24465a3b-8a93-4111-8f65-2ca1750118d3',
    origin: GF_SOURCE,
    url: GF_URL + 'shojumaru/v10/rax_HiWfutkLLnaKCtlMBBJek0vA8A.ttf',
    variant: 'regular',
    family: 'Shojumaru',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '782686a3-a0ca-4dad-b876-95a99ee31a46',
    origin: GF_SOURCE,
    url: GF_URL + 'bellotatext/v4/0FlOVP2VnlWS4f3-UE9hHXMx--G2eA_0YSuixmYK.ttf',
    variant: '700italic',
    family: 'Bellota Text',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '6bcec2bb-9d77-4fb7-bfd6-9a49a2933f13',
    origin: GF_SOURCE,
    url: GF_URL + 'bellotatext/v4/0FlMVP2VnlWS4f3-UE9hHXM5RfwqfQXwQy6yxg.ttf',
    variant: '700',
    family: 'Bellota Text',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '495d412a-7f57-44fc-bb97-c57f29d83c57',
    origin: GF_SOURCE,
    url: GF_URL + 'bellotatext/v4/0FlNVP2VnlWS4f3-UE9hHXMx-9kKVyv8Sjer.ttf',
    variant: 'italic',
    family: 'Bellota Text',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '6530b24d-8ff5-4aaf-9707-bdd7b46ec779',
    origin: GF_SOURCE,
    url: GF_URL + 'bellotatext/v4/0FlTVP2VnlWS4f3-UE9hHXMB-dMOdS7sSg.ttf',
    variant: 'regular',
    family: 'Bellota Text',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'b2bfafb7-fdc7-4aea-84ac-e044fa82af2f',
    origin: GF_SOURCE,
    url: GF_URL + 'bellotatext/v4/0FlOVP2VnlWS4f3-UE9hHXMx--Gmfw_0YSuixmYK.ttf',
    variant: '300italic',
    family: 'Bellota Text',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'af4cb399-dd84-483b-85da-8a5e824bd062',
    origin: GF_SOURCE,
    url: GF_URL + 'bellotatext/v4/0FlMVP2VnlWS4f3-UE9hHXM5VfsqfQXwQy6yxg.ttf',
    variant: '300',
    family: 'Bellota Text',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'bea9df3f-034f-4729-8a60-5901dcdeded1',
    origin: GF_SOURCE,
    url: GF_URL + 'geo/v14/CSRx4zRZluflLXpiYDxSbf8r.ttf',
    variant: 'italic',
    family: 'Geo',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '1da2fc62-9cf7-43d8-8b51-22f03f6a012b',
    origin: GF_SOURCE,
    url: GF_URL + 'geo/v14/CSRz4zRZlufVL3BmQjlCbQ.ttf',
    variant: 'regular',
    family: 'Geo',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '57c70c4b-86f0-4872-9502-20b78180826c',
    origin: GF_SOURCE,
    url: GF_URL + 'unifrakturcook/v14/IurA6Yli8YOdcoky-0PTTdkm56n05Uw13ILXs-h6.ttf',
    variant: '700',
    family: 'UnifrakturCook',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'c718549a-0e23-4a17-a2fa-3f1878247f0a',
    origin: GF_SOURCE,
    url: GF_URL + 'medulaone/v12/YA9Wr0qb5kjJM6l2V0yukiEqs7GtlvY.ttf',
    variant: 'regular',
    family: 'Medula One',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'b650e28c-d46e-4ce6-9df5-cdd13debf05b',
    origin: GF_SOURCE,
    url: GF_URL + 'newtegomin/v1/SLXMc1fV7Gd9USdBAfPlqfN0Q3ptkDMN.ttf',
    variant: 'regular',
    family: 'New Tegomin',
    category: 'serif',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '8321b927-d1bb-485e-9665-68dabeada5ac',
    origin: GF_SOURCE,
    url: GF_URL + 'coiny/v8/gyByhwU1K989PXwbElSvO5Tc.ttf',
    variant: 'regular',
    family: 'Coiny',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '62333089-e729-4f78-8978-04549b044d12',
    origin: GF_SOURCE,
    url: GF_URL + 'engagement/v12/x3dlckLDZbqa7RUs9MFVXNossybsHQI.ttf',
    variant: 'regular',
    family: 'Engagement',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'ac06281e-5c00-4681-bec4-a36c2abae64b',
    origin: GF_SOURCE,
    url: GF_URL + 'nokora/v16/hYkLPuwgTubzaWxohxUrqt18-B9Uuw.ttf',
    variant: '700',
    family: 'Nokora',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '635847b0-be53-4ad3-9864-7a4bb79a8189',
    origin: GF_SOURCE,
    url: GF_URL + 'nokora/v16/hYkIPuwgTubzaWxQOzoPovZg8Q.ttf',
    variant: 'regular',
    family: 'Nokora',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '03245d86-d7dd-47e1-82bb-4018a0e86f69',
    origin: GF_SOURCE,
    url: GF_URL + 'vibur/v13/DPEiYwmEzw0QRjTpLjoJd-Xa.ttf',
    variant: 'regular',
    family: 'Vibur',
    category: 'handwriting',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'e577757b-1fe1-4b76-bffa-9efd6b6edc08',
    origin: GF_SOURCE,
    url: GF_URL + 'tienne/v15/AYCJpX7pe9YCRP0zFG7jQHhuzvef5Q.ttf',
    variant: '900',
    family: 'Tienne',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '3f300e51-2c32-48d0-b153-e78ce6be59f9',
    origin: GF_SOURCE,
    url: GF_URL + 'tienne/v15/AYCJpX7pe9YCRP0zLGzjQHhuzvef5Q.ttf',
    variant: '700',
    family: 'Tienne',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'badc1a67-cea1-43b0-b371-62c2f025a584',
    origin: GF_SOURCE,
    url: GF_URL + 'tienne/v15/AYCKpX7pe9YCRP0LkEPHSFNyxw.ttf',
    variant: 'regular',
    family: 'Tienne',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '1c808dfd-99d8-4d58-afd7-9e2f2a453dfb',
    origin: GF_SOURCE,
    url: GF_URL + 'amarante/v10/xMQXuF1KTa6EvGx9bq-3C3rAmD-b.ttf',
    variant: 'regular',
    family: 'Amarante',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '99e09116-a62a-4b67-ac53-3ca222e075a7',
    origin: GF_SOURCE,
    url: GF_URL + 'balootammudu2/v4/1Ptzg8TIS_SAmkLguUdFP8UaJcKGZV0y4YqLFrUnJA.ttf',
    variant: '800',
    family: 'Baloo Tammudu 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '8fb1bf4f-a387-4e20-afe3-b1b5f13cb4ef',
    origin: GF_SOURCE,
    url: GF_URL + 'balootammudu2/v4/1Ptzg8TIS_SAmkLguUdFP8UaJcKGeV4y4YqLFrUnJA.ttf',
    variant: '700',
    family: 'Baloo Tammudu 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '8a9bdc47-9b15-4e7d-8c18-ce6bcc6b9ab7',
    origin: GF_SOURCE,
    url: GF_URL + 'balootammudu2/v4/1Ptzg8TIS_SAmkLguUdFP8UaJcKGHV8y4YqLFrUnJA.ttf',
    variant: '600',
    family: 'Baloo Tammudu 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '06704b36-9c86-4b75-bafe-388487487a27',
    origin: GF_SOURCE,
    url: GF_URL + 'balootammudu2/v4/1Ptzg8TIS_SAmkLguUdFP8UaJcKGMVgy4YqLFrUnJA.ttf',
    variant: '500',
    family: 'Baloo Tammudu 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '8bf16650-c9d5-4e47-9c05-8be438a3b9fe',
    origin: GF_SOURCE,
    url: GF_URL + 'balootammudu2/v4/1Pt2g8TIS_SAmkLguUdFP8UaJcK-xXEW6aGXHw.ttf',
    variant: 'regular',
    family: 'Baloo Tammudu 2',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '80e15387-6df4-436d-92a2-913b16949c58',
    origin: GF_SOURCE,
    url: GF_URL + 'novaround/v14/flU9Rqquw5UhEnlwTJYTYYfeeetYEBc.ttf',
    variant: 'regular',
    family: 'Nova Round',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'fd3ef35a-45f5-43b9-b523-f6fa8e1c3fd3',
    origin: GF_SOURCE,
    url: GF_URL + 'headlandone/v10/yYLu0hHR2vKnp89Tk1TCq3Tx0PlTeZ3mJA.ttf',
    variant: 'regular',
    family: 'Headland One',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4bdde997-3283-40e6-8f63-7d8cc3bf3710',
    origin: GF_SOURCE,
    url: GF_URL + 'italiana/v11/QldNNTtLsx4E__B0XTmRY31Wx7Vv.ttf',
    variant: 'regular',
    family: 'Italiana',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '6a364fe6-272a-4b20-bc2a-2cf46cc0ff02',
    origin: GF_SOURCE,
    url: GF_URL + 'arya/v9/ga6NawNG-HJdzfra3b-BaFg3dRE.ttf',
    variant: '700',
    family: 'Arya',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '53d2c575-a4cf-4813-95fc-dbda7840a24c',
    origin: GF_SOURCE,
    url: GF_URL + 'arya/v9/ga6CawNG-HJd9Ub1-beqdFE.ttf',
    variant: 'regular',
    family: 'Arya',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '78820501-235f-4ff0-9653-aa110b152d8d',
    origin: GF_SOURCE,
    url: GF_URL + 'almendra/v15/H4chBXKAlMnTn0CskxY48Ae9oqacbzhqDtg.ttf',
    variant: '700italic',
    family: 'Almendra',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '96d65695-ac09-4753-9c8a-5d9cf50716a5',
    origin: GF_SOURCE,
    url: GF_URL + 'almendra/v15/H4cjBXKAlMnTn0Cskx6G7Zu4qKK-aihq.ttf',
    variant: '700',
    family: 'Almendra',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'b4eb7f7f-36b1-4a46-ae95-0b4fc2422b19',
    origin: GF_SOURCE,
    url: GF_URL + 'almendra/v15/H4ciBXKAlMnTn0CskxY4yLuShq63czE.ttf',
    variant: 'italic',
    family: 'Almendra',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'a1cc9f1b-61e9-4952-930a-3d2e65838826',
    origin: GF_SOURCE,
    url: GF_URL + 'almendra/v15/H4ckBXKAlMnTn0CskyY6wr-wg763.ttf',
    variant: 'regular',
    family: 'Almendra',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '8fec6116-c95f-48f1-8b07-41c075e0f5cf',
    origin: GF_SOURCE,
    url: GF_URL + 'carroisgothicsc/v10/ZgNJjOVHM6jfUZCmyUqT2A2HVKjc-28nNHabY4dN.ttf',
    variant: 'regular',
    family: 'Carrois Gothic SC',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '53f5d30a-3816-498f-af37-abe584ff90d7',
    origin: GF_SOURCE,
    url: GF_URL + 'cherrycreamsoda/v13/UMBIrOxBrW6w2FFyi9paG0fdVdRciTd6Cd47DJ7G.ttf',
    variant: 'regular',
    family: 'Cherry Cream Soda',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'a8d9e83b-46d8-4f5e-8e02-9ac504e9b39b',
    origin: GF_SOURCE,
    url: GF_URL + 'mina/v6/-nF8OGc18vARl4NMyiXZ95OkJwA.ttf',
    variant: '700',
    family: 'Mina',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '319dec5e-98a2-492f-b23b-8c92bf446fd6',
    origin: GF_SOURCE,
    url: GF_URL + 'mina/v6/-nFzOGc18vARrz9j7i3y65o.ttf',
    variant: 'regular',
    family: 'Mina',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '1c33858a-5172-49c7-b87a-3a55e77c7525',
    origin: GF_SOURCE,
    url: GF_URL + 'bilboswashcaps/v15/zrf-0GXbz-H3Wb4XBsGrTgq2PVmdqAPopiRfKp8.ttf',
    variant: 'regular',
    family: 'Bilbo Swash Caps',
    category: 'handwriting',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '09e610b1-cbda-4334-a219-5cc22910545e',
    origin: GF_SOURCE,
    url: GF_URL + 'deliusunicase/v16/845CNMEwEIOVT8BmgfSzIr_6mlp7WMr_BmmlS5aw.ttf',
    variant: '700',
    family: 'Delius Unicase',
    category: 'handwriting',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'a5e888f4-c29c-402c-bdb6-e380bffae793',
    origin: GF_SOURCE,
    url: GF_URL + 'deliusunicase/v16/845BNMEwEIOVT8BmgfSzIr_6mmLHd-73LXWs.ttf',
    variant: 'regular',
    family: 'Delius Unicase',
    category: 'handwriting',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'ba09e084-a1ee-4e87-a9cb-5dd2e09f2bdb',
    origin: GF_SOURCE,
    url: GF_URL + 'elsie/v11/BCaqqZABrez54x6q2-1IU6QeXSBk.ttf',
    variant: '900',
    family: 'Elsie',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0c53836b-21e1-44a6-9f14-b7ef8d908559',
    origin: GF_SOURCE,
    url: GF_URL + 'elsie/v11/BCanqZABrez54yYu9slAeLgX.ttf',
    variant: 'regular',
    family: 'Elsie',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '657888af-4764-49e8-b14f-f2e37d41ec1d',
    origin: GF_SOURCE,
    url: GF_URL + 'thegirlnextdoor/v13/pe0zMJCIMIsBjFxqYBIcZ6_OI5oFHCYIV7t7w6bE2A.ttf',
    variant: 'regular',
    family: 'The Girl Next Door',
    category: 'handwriting',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'ffb680d1-8539-4e77-9ba4-8667c1232606',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantupright/v11/VuJudM3I2Y35poFONtLdafkUCHw1y1NpoRDsU9X6RPzQ.ttf',
    variant: '700',
    family: 'Cormorant Upright',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f3203c7c-297c-4801-b952-19b0b8a126e8',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantupright/v11/VuJudM3I2Y35poFONtLdafkUCHw1y1MNoBDsU9X6RPzQ.ttf',
    variant: '600',
    family: 'Cormorant Upright',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c3235a7c-2d29-4a0e-88b5-a2e168655784',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantupright/v11/VuJudM3I2Y35poFONtLdafkUCHw1y1MhpxDsU9X6RPzQ.ttf',
    variant: '500',
    family: 'Cormorant Upright',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'fd4d2c9e-7e51-4cdd-a082-3dea8aa1f831',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantupright/v11/VuJrdM3I2Y35poFONtLdafkUCHw1y2vVjjTkeMnz.ttf',
    variant: 'regular',
    family: 'Cormorant Upright',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '35370ad4-1757-49ed-a002-79e93f3d2f96',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantupright/v11/VuJudM3I2Y35poFONtLdafkUCHw1y1N5phDsU9X6RPzQ.ttf',
    variant: '300',
    family: 'Cormorant Upright',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '411d1efb-8a83-4897-af57-6f01cc56c336',
    origin: GF_SOURCE,
    url: GF_URL + 'flamenco/v13/neIIzCehqYguo67ssaWGHK06UY30.ttf',
    variant: 'regular',
    family: 'Flamenco',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '6d3f74be-5077-49ae-9573-7f26af257e30',
    origin: GF_SOURCE,
    url: GF_URL + 'flamenco/v13/neIPzCehqYguo67ssZ0qNIkyepH9qGsf.ttf',
    variant: '300',
    family: 'Flamenco',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '039a3d63-f30c-4390-9379-f8139dca70d2',
    origin: GF_SOURCE,
    url: GF_URL + 'scopeone/v9/WBLnrEXKYFlGHrOKmGD1W0_MJMGxiQ.ttf',
    variant: 'regular',
    family: 'Scope One',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '51c3b6d6-1a44-45da-a967-471692922813',
    origin: GF_SOURCE,
    url: GF_URL + 'milonga/v10/SZc53FHnIaK9W5kffz3GkUrS8DI.ttf',
    variant: 'regular',
    family: 'Milonga',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'dba85b96-d3ef-403f-8b56-38d394b66811',
    origin: GF_SOURCE,
    url: GF_URL + 'cherryswash/v11/i7dSIFByZjaNAMxtZcnfAy5E_FeaGy6QZ3WfYg.ttf',
    variant: '700',
    family: 'Cherry Swash',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a15b1a73-de46-46a8-96d5-d5ade509d458',
    origin: GF_SOURCE,
    url: GF_URL + 'cherryswash/v11/i7dNIFByZjaNAMxtZcnfAy58QHi-EwWMbg.ttf',
    variant: 'regular',
    family: 'Cherry Swash',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '6c6c5dc3-8eac-4750-b945-942cbdedb86e',
    origin: GF_SOURCE,
    url: GF_URL + 'lovedbytheking/v12/Gw6gwdP76VDVJNXerebZxUMeRXUF2PiNlXFu2R64.ttf',
    variant: 'regular',
    family: 'Loved by the King',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2120829c-0e60-4523-88e2-8d3845d9e692',
    origin: GF_SOURCE,
    url: GF_URL + 'asul/v12/VuJxdNjKxYr40U8qeKbXOIFneRo.ttf',
    variant: '700',
    family: 'Asul',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'cdc8abb2-c2f2-4bdb-bcad-bacc1e5f954f',
    origin: GF_SOURCE,
    url: GF_URL + 'asul/v12/VuJ-dNjKxYr46fMFXK78JIg.ttf',
    variant: 'regular',
    family: 'Asul',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2fded9e7-ee37-44be-a1c0-970ea9b9038c',
    origin: GF_SOURCE,
    url: GF_URL + 'tradewinds/v12/AYCPpXPpYNIIT7h8-QenM3Jq7PKP5Z_G.ttf',
    variant: 'regular',
    family: 'Trade Winds',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'd2d4d1df-f5b9-44cb-bb82-c70a7e4524d3',
    origin: GF_SOURCE,
    url: GF_URL + 'macondoswashcaps/v10/6NUL8EaAJgGKZA7lpt941Z9s6ZYgDq6Oekoa_mm5bA.ttf',
    variant: 'regular',
    family: 'Macondo Swash Caps',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '89bbea41-7d07-4ca9-9970-ba67b2fa2aca',
    origin: GF_SOURCE,
    url: GF_URL + 'lilyscriptone/v10/LhW9MV7ZMfIPdMxeBjBvFN8SXLS4gsSjQNsRMg.ttf',
    variant: 'regular',
    family: 'Lily Script One',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '9c7634c6-c409-4a7a-83ec-7ea4546028a1',
    origin: GF_SOURCE,
    url: GF_URL + 'sairastencilone/v4/SLXSc03I6HkvZGJ1GvvipLoYSTEL9AsMawif2YQ2.ttf',
    variant: 'regular',
    family: 'Saira Stencil One',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ae4fe861-ffed-4d87-b67e-c5ab10c5edbb',
    origin: GF_SOURCE,
    url: GF_URL + 'prostoone/v11/OpNJno4VhNfK-RgpwWWxpipfWhXD00c.ttf',
    variant: 'regular',
    family: 'Prosto One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '53bbba64-ff62-447d-9c44-5d413021528d',
    origin: GF_SOURCE,
    url: GF_URL + 'calistoga/v5/6NUU8F2OJg6MeR7l4e0vtMYAwdRZfw.ttf',
    variant: 'regular',
    family: 'Calistoga',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '0667111f-e435-4397-880a-adf9b4d86a1a',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCs-x1S2hzjrlfXbdu6tV3GY_etWWIJ.ttf',
    variant: '900italic',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '65b47118-586c-478a-9c0e-bab7d17788ab',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCq-x1S2hzjrlff68QeslfCQfK9WQ.ttf',
    variant: '900',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '5e5106d6-2cfc-4e13-8b24-473acdbedb9e',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCs-x1S2hzjrlfXbduCt13GY_etWWIJ.ttf',
    variant: '700italic',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ac2d18b4-8e3b-42ec-a4b7-a4f02a79c80f',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCq-x1S2hzjrlff08YeslfCQfK9WQ.ttf',
    variant: '700',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f1a128a0-2003-4e6a-859e-35b917281374',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCs-x1S2hzjrlfXbdvmtl3GY_etWWIJ.ttf',
    variant: '600italic',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '616a1e77-b62c-4bed-a195-5c621f2b8cdc',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCq-x1S2hzjrlfft8ceslfCQfK9WQ.ttf',
    variant: '600',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '3926b8f8-5ed7-425a-a0fd-ae058e7f24a6',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCs-x1S2hzjrlfXbdvKsV3GY_etWWIJ.ttf',
    variant: '500italic',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e5703844-c537-4593-b627-2592fd35c94b',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCq-x1S2hzjrlffm8AeslfCQfK9WQ.ttf',
    variant: '500',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c2a4fd91-ab57-40e3-afc3-9726732cd597',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCr-x1S2hzjrlfXbeM-mHnOSOuk.ttf',
    variant: 'italic',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '30680bd1-33af-4024-9a7a-0eb87a6f058c',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCp-x1S2hzjrlfnb-k6unzeSA.ttf',
    variant: 'regular',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'cf6642f9-68ab-46a5-be4d-38c03f95db18',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCs-x1S2hzjrlfXbduSsF3GY_etWWIJ.ttf',
    variant: '300italic',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '7acb6d88-3d2a-4b29-8685-e8b11c101dea',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCq-x1S2hzjrlffw8EeslfCQfK9WQ.ttf',
    variant: '300',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '39025deb-dc80-4fe0-a856-06a7df0e2508',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCs-x1S2hzjrlfXbdv2s13GY_etWWIJ.ttf',
    variant: '200italic',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b3c915b0-593e-4ca6-bfc1-c7519dc0f496',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCq-x1S2hzjrlffp8IeslfCQfK9WQ.ttf',
    variant: '200',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '3f9aaa35-925a-482f-b4cf-f059e609b317',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCt-x1S2hzjrlfXbdtakn3sTfukQHs.ttf',
    variant: '100italic',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '53cd13b2-c10c-43b7-a665-d7e59cef2cc7',
    origin: GF_SOURCE,
    url: GF_URL + 'livvic/v8/rnCr-x1S2hzjrlffC-M-mHnOSOuk.ttf',
    variant: '100',
    family: 'Livvic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'a60158b6-9a09-4d55-b432-310ab9458033',
    origin: GF_SOURCE,
    url: GF_URL + 'lexendzetta/v10/ll87K2KYXje7CdOFnEWcU8soliQejRR7AQ.ttf',
    variant: 'regular',
    family: 'Lexend Zetta',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '89c5b89a-454f-462e-842a-ea8c107658c6',
    origin: GF_SOURCE,
    url: GF_URL + 'imprima/v11/VEMxRoN7sY3yuy-7-oWHyDzktPo.ttf',
    variant: 'regular',
    family: 'Imprima',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '63173cde-cfe0-4e47-94bb-c8be4b26d65f',
    origin: GF_SOURCE,
    url: GF_URL + 'vampiroone/v13/gokqH6DoDl5yXvJytFsdLkqnsvhIor3K.ttf',
    variant: 'regular',
    family: 'Vampiro One',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'e06e22fe-fe82-4cd2-9885-4abc184bdd22',
    origin: GF_SOURCE,
    url: GF_URL + 'yatraone/v9/C8ch4copsHzj8p7NaF0xw1OBbRDvXw.ttf',
    variant: 'regular',
    family: 'Yatra One',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'f0df7c7c-c503-473c-bbd5-01349bca0ef3',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamahee/v10/XRXN3IOIi0hcP8iVU67hA9NtdxoHJndqZCsW.ttf',
    variant: '800',
    family: 'Mukta Mahee',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '72d90a8d-4d36-4068-a25d-2a7de7c22c2d',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamahee/v10/XRXN3IOIi0hcP8iVU67hA9NxdBoHJndqZCsW.ttf',
    variant: '700',
    family: 'Mukta Mahee',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '60f0ce3b-1c64-4fab-8ba4-201e7878138b',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamahee/v10/XRXN3IOIi0hcP8iVU67hA9MVdRoHJndqZCsW.ttf',
    variant: '600',
    family: 'Mukta Mahee',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '06228783-9542-463c-93b7-ec714c5207fb',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamahee/v10/XRXN3IOIi0hcP8iVU67hA9M5choHJndqZCsW.ttf',
    variant: '500',
    family: 'Mukta Mahee',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'd1bcd357-04dc-4b6b-9037-1d532127e019',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamahee/v10/XRXQ3IOIi0hcP8iVU67hA-vNWz4PDWtj.ttf',
    variant: 'regular',
    family: 'Mukta Mahee',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'e4c6473b-a191-429a-9d50-8d3416409766',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamahee/v10/XRXN3IOIi0hcP8iVU67hA9NhcxoHJndqZCsW.ttf',
    variant: '300',
    family: 'Mukta Mahee',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'e8957493-5b35-47d7-8a0e-7f0cabe59f8b',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamahee/v10/XRXN3IOIi0hcP8iVU67hA9MFcBoHJndqZCsW.ttf',
    variant: '200',
    family: 'Mukta Mahee',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a54e6468-326c-4a20-af9e-073e9881b31e',
    origin: GF_SOURCE,
    url: GF_URL + 'sumana/v5/4UaArE5TqRBjGj--TDfG54fN6ppsKg.ttf',
    variant: '700',
    family: 'Sumana',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1b75ee3d-6636-48ce-9483-a7913e3cff39',
    origin: GF_SOURCE,
    url: GF_URL + 'sumana/v5/4UaDrE5TqRBjGj-G8Bji76zR4w.ttf',
    variant: 'regular',
    family: 'Sumana',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '51989d72-1fe8-4484-833b-36117def670b',
    origin: GF_SOURCE,
    url: GF_URL + 'kiteone/v10/70lQu7shLnA_E02vyq1b6HnGO4uA.ttf',
    variant: 'regular',
    family: 'Kite One',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a9b3590c-094b-4f8c-a6c7-73e5072066a6',
    origin: GF_SOURCE,
    url:
      GF_URL + 'averiasanslibre/v11/ga6caxZG_G5OvCf_rt7FH3B6BHLMEdVLKjsVL5fXK3D9qtg.ttf',
    variant: '700italic',
    family: 'Averia Sans Libre',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '2b872cda-a991-4935-9c51-05714a1c5247',
    origin: GF_SOURCE,
    url: GF_URL + 'averiasanslibre/v11/ga6SaxZG_G5OvCf_rt7FH3B6BHLMEd31N6cQJZP1LmD9.ttf',
    variant: '700',
    family: 'Averia Sans Libre',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a8b631e4-ec22-427e-aae5-8af5d62b2ac9',
    origin: GF_SOURCE,
    url: GF_URL + 'averiasanslibre/v11/ga6RaxZG_G5OvCf_rt7FH3B6BHLMEdVLEoc6C5_8N3k.ttf',
    variant: 'italic',
    family: 'Averia Sans Libre',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '16278c6c-4820-4df2-a73b-9a32e419d431',
    origin: GF_SOURCE,
    url: GF_URL + 'averiasanslibre/v11/ga6XaxZG_G5OvCf_rt7FH3B6BHLMEeVJGIMYDo_8.ttf',
    variant: 'regular',
    family: 'Averia Sans Libre',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c83ef5d7-4079-4d74-8a62-3fb011c2d125',
    origin: GF_SOURCE,
    url:
      GF_URL + 'averiasanslibre/v11/ga6caxZG_G5OvCf_rt7FH3B6BHLMEdVLKisSL5fXK3D9qtg.ttf',
    variant: '300italic',
    family: 'Averia Sans Libre',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '60e858a6-5ccb-4bf5-9976-e6568525591a',
    origin: GF_SOURCE,
    url: GF_URL + 'averiasanslibre/v11/ga6SaxZG_G5OvCf_rt7FH3B6BHLMEd3lMKcQJZP1LmD9.ttf',
    variant: '300',
    family: 'Averia Sans Libre',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'e270b2b2-1cc4-451d-90e2-aea752ddb60e',
    origin: GF_SOURCE,
    url: GF_URL + 'arefruqaa/v15/WwkYxPW1E165rajQKDulKDwNcNIS2N_7Bdk.ttf',
    variant: '700',
    family: 'Aref Ruqaa',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'fa67fde4-d115-4905-a34c-e52fd1c6c5c7',
    origin: GF_SOURCE,
    url: GF_URL + 'arefruqaa/v15/WwkbxPW1E165rajQKDulEIAiVNo5xNY.ttf',
    variant: 'regular',
    family: 'Aref Ruqaa',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'ae53630e-1766-450d-80d8-3580ff9992c6',
    origin: GF_SOURCE,
    url: GF_URL + 'tillana/v6/VuJ0dNvf35P4qJ1OQAb7HIlMZRNcp0o.ttf',
    variant: '800',
    family: 'Tillana',
    category: 'handwriting',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ee5b11bb-6bc0-4277-9bf4-f76689e1ccbf',
    origin: GF_SOURCE,
    url: GF_URL + 'tillana/v6/VuJ0dNvf35P4qJ1OQBr4HIlMZRNcp0o.ttf',
    variant: '700',
    family: 'Tillana',
    category: 'handwriting',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bfc4c690-24e0-4843-af30-6f0dd3be7be5',
    origin: GF_SOURCE,
    url: GF_URL + 'tillana/v6/VuJ0dNvf35P4qJ1OQH75HIlMZRNcp0o.ttf',
    variant: '600',
    family: 'Tillana',
    category: 'handwriting',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '04811795-acf0-4c48-af85-aeb33be40c99',
    origin: GF_SOURCE,
    url: GF_URL + 'tillana/v6/VuJ0dNvf35P4qJ1OQFL-HIlMZRNcp0o.ttf',
    variant: '500',
    family: 'Tillana',
    category: 'handwriting',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '584839fe-4b9e-4805-b122-1ff76e166f99',
    origin: GF_SOURCE,
    url: GF_URL + 'tillana/v6/VuJxdNvf35P4qJ1OeKbXOIFneRo.ttf',
    variant: 'regular',
    family: 'Tillana',
    category: 'handwriting',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a62be834-a1c0-44d5-bf48-7e9ac73a9858',
    origin: GF_SOURCE,
    url: GF_URL + 'mako/v14/H4coBX6Mmc_Z0ST09g478Lo.ttf',
    variant: 'regular',
    family: 'Mako',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '023ef69b-4757-4a0f-84bd-d9d1494011e3',
    origin: GF_SOURCE,
    url: GF_URL + 'pavanam/v5/BXRrvF_aiezLh0xPDOtQ9Wf0QcE.ttf',
    variant: 'regular',
    family: 'Pavanam',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '58677b6a-a301-4027-b7a4-ddf4a52b6acb',
    origin: GF_SOURCE,
    url: GF_URL + 'meerainimai/v7/845fNMM5EIqOW5MPuvO3ILep_2jDVevnLQ.ttf',
    variant: 'regular',
    family: 'Meera Inimai',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'a86063e2-2c9b-4385-b04b-6f1e1d4b02ad',
    origin: GF_SOURCE,
    url: GF_URL + 'denkone/v10/dg4m_pzhrqcFb2IzROtHpbglShon.ttf',
    variant: 'regular',
    family: 'Denk One',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '5df9135b-871a-40f4-a6a5-aaa213161917',
    origin: GF_SOURCE,
    url: GF_URL + 'overtherainbow/v11/11haGoXG1k_HKhMLUWz7Mc7vvW5upvOm9NA2XG0.ttf',
    variant: 'regular',
    family: 'Over the Rainbow',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c7ecea35-a3e9-4d72-8908-513234e737d1',
    origin: GF_SOURCE,
    url: GF_URL + 'novamono/v13/Cn-0JtiGWQ5Ajb--MRKfYGxYrdM9Sg.ttf',
    variant: 'regular',
    family: 'Nova Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '78dd9ecd-fc81-43f4-8300-2a406b368474',
    origin: GF_SOURCE,
    url: GF_URL + 'novasquare/v15/RrQUbo9-9DV7b06QHgSWsZhARYMgGtWA.ttf',
    variant: 'regular',
    family: 'Nova Square',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'e817aac9-2c0a-46c2-a42a-76daf4eced53',
    origin: GF_SOURCE,
    url: GF_URL + 'iceland/v11/rax9HiuFsdMNOnWPWKxGADBbg0s.ttf',
    variant: 'regular',
    family: 'Iceland',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b61c0a26-098b-4a08-ab12-81116ebe1161',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratsubrayada/v12/U9MM6c-o9H7PgjlTHThBnNHGVUORwteQQHe3TcMWg3j36Ebz.ttf',
    variant: '700',
    family: 'Montserrat Subrayada',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'f3fd6a85-703d-4a72-a98f-9e280d335a60',
    origin: GF_SOURCE,
    url:
      GF_URL + 'montserratsubrayada/v12/U9MD6c-o9H7PgjlTHThBnNHGVUORwteQQE8LYuceqGT-.ttf',
    variant: 'regular',
    family: 'Montserrat Subrayada',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '7098875b-37a4-4ca3-b5c1-159c1f19e417',
    origin: GF_SOURCE,
    url: GF_URL + 'deliusswashcaps/v14/oY1E8fPLr7v4JWCExZpWebxVKORpXXedKmeBvEYs.ttf',
    variant: 'regular',
    family: 'Delius Swash Caps',
    category: 'handwriting',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '57e56274-b2d7-4a82-855c-af35b755315e',
    origin: GF_SOURCE,
    url: GF_URL + 'metamorphous/v13/Wnz8HA03aAXcC39ZEX5y1330PCCthTsmaQ.ttf',
    variant: 'regular',
    family: 'Metamorphous',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'ea9727a4-eb48-4116-8baa-d72ba90fe9fe',
    origin: GF_SOURCE,
    url: GF_URL + 'gafata/v11/XRXV3I6Cn0VJKon4MuyAbsrVcA.ttf',
    variant: 'regular',
    family: 'Gafata',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'e52e85b6-df8c-46a9-bf31-82388f9d7a4e',
    origin: GF_SOURCE,
    url: GF_URL + 'strait/v8/DtViJxy6WaEr1LZzeDhtkl0U7w.ttf',
    variant: 'regular',
    family: 'Strait',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '75d2b290-fbdc-466b-b811-ab40bd3f41af',
    origin: GF_SOURCE,
    url: GF_URL + 'tauri/v11/TwMA-IISS0AM3IpVWHU_TBqO.ttf',
    variant: 'regular',
    family: 'Tauri',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7a861bb7-911f-494f-8c99-ee1ac0332938',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'zcoolqingkehuangyou/v5/2Eb5L_R5IXJEWhD3AOhSvFC554MOOahI4mRIi_28c8bHWA.ttf',
    variant: 'regular',
    family: 'ZCOOL QingKe HuangYou',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '57e86f28-7ca3-4d62-8dc8-3093db5fa9b2',
    origin: GF_SOURCE,
    url: GF_URL + 'artifika/v13/VEMyRoxzronptCuxu6Wt5jDtreOL.ttf',
    variant: 'regular',
    family: 'Artifika',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '8a26953f-14b3-42e3-a752-245805fa9bdb',
    origin: GF_SOURCE,
    url: GF_URL + 'sharetech/v12/7cHtv4Uyi5K0OeZ7bohUwHoDmTcibrA.ttf',
    variant: 'regular',
    family: 'Share Tech',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '076cbe3b-fd51-44b2-b757-6fd75d6d3362',
    origin: GF_SOURCE,
    url: GF_URL + 'shortstack/v10/bMrzmS2X6p0jZC6EcmPFX-SScX8D0nq6.ttf',
    variant: 'regular',
    family: 'Short Stack',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '46b054aa-6b0b-4c71-8d9b-23b2eaaeecd2',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesansexpanded/v5/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKmDiNIXIwSP0XD.ttf',
    variant: '900',
    family: 'Encode Sans Expanded',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '11e15808-7a1b-4f9a-a74b-f366fb692da1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesansexpanded/v5/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKCDyNIXIwSP0XD.ttf',
    variant: '800',
    family: 'Encode Sans Expanded',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '7a308207-5c4e-441e-bb1b-881ce408629b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesansexpanded/v5/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKeDCNIXIwSP0XD.ttf',
    variant: '700',
    family: 'Encode Sans Expanded',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'cbc442c0-9f4c-4e29-ae46-e18b62a1cd65',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesansexpanded/v5/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpL6DSNIXIwSP0XD.ttf',
    variant: '600',
    family: 'Encode Sans Expanded',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '67e151e1-32b2-4408-aa66-a0438a77825c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesansexpanded/v5/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpLWCiNIXIwSP0XD.ttf',
    variant: '500',
    family: 'Encode Sans Expanded',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6806fc60-635a-488b-bd3b-033a9dbec190',
    origin: GF_SOURCE,
    url:
      GF_URL + 'encodesansexpanded/v5/c4m_1mF4GcnstG_Jh1QH6ac4hNLeNyeYUqoiIwdAd5Ab.ttf',
    variant: 'regular',
    family: 'Encode Sans Expanded',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '11e80d7c-510e-465d-9468-74c6e95b1c82',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesansexpanded/v5/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKOCyNIXIwSP0XD.ttf',
    variant: '300',
    family: 'Encode Sans Expanded',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'aec5222e-2a45-4f7c-8459-ac870dd602ae',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesansexpanded/v5/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpLqCCNIXIwSP0XD.ttf',
    variant: '200',
    family: 'Encode Sans Expanded',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'd9965c23-99a9-4bd6-b8fa-bd603b472f65',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesansexpanded/v5/c4mx1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpJGKQNicoAbJlw.ttf',
    variant: '100',
    family: 'Encode Sans Expanded',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e90dde00-45f0-47c6-b725-28a4f26aa75a',
    origin: GF_SOURCE,
    url: GF_URL + 'fasterone/v12/H4ciBXCHmdfClFb-vWhfyLuShq63czE.ttf',
    variant: 'regular',
    family: 'Faster One',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'a46834d1-74cd-4c70-a76a-ac2f3a5626b6',
    origin: GF_SOURCE,
    url: GF_URL + 'mclaren/v8/2EbnL-ZuAXFqZFXISYYf8z2Yt_c.ttf',
    variant: 'regular',
    family: 'McLaren',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'a4c59c7b-c472-4d6b-9852-418ffcddf014',
    origin: GF_SOURCE,
    url: GF_URL + 'himelody/v8/46ktlbP8Vnz0pJcqCTbEf29E31BBGA.ttf',
    variant: 'regular',
    family: 'Hi Melody',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f2837138-8913-4749-9d77-9ba9472ed2c8',
    origin: GF_SOURCE,
    url: GF_URL + 'mashanzheng/v5/NaPecZTRCLxvwo41b4gvzkXaRMTsDIRSfr0.ttf',
    variant: 'regular',
    family: 'Ma Shan Zheng',
    category: 'handwriting',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '811da7fe-ced9-4a93-b62f-f97de0f8ad12',
    origin: GF_SOURCE,
    url: GF_URL + 'ledger/v11/j8_q6-HK1L3if_sxm8DwHTBhHw.ttf',
    variant: 'regular',
    family: 'Ledger',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '9719fe90-2d00-4c41-b5b2-b05b4db4ed1a',
    origin: GF_SOURCE,
    url: GF_URL + 'katibeh/v12/ZGjXol5MQJog4bxDaC1RVDNdGDs.ttf',
    variant: 'regular',
    family: 'Katibeh',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '5feb0ca0-de44-47af-a9d6-f78526ae6d77',
    origin: GF_SOURCE,
    url: GF_URL + 'lifesavers/v13/ZXu_e1UftKKabUQMgxAal8HLOi5Tk8fIpPRW.ttf',
    variant: '800',
    family: 'Life Savers',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '12760eb8-9974-483b-8e7e-bbd0b7ed763e',
    origin: GF_SOURCE,
    url: GF_URL + 'lifesavers/v13/ZXu_e1UftKKabUQMgxAal8HXOS5Tk8fIpPRW.ttf',
    variant: '700',
    family: 'Life Savers',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '4bc05505-3f00-4327-84d3-30fb379c9031',
    origin: GF_SOURCE,
    url: GF_URL + 'lifesavers/v13/ZXuie1UftKKabUQMgxAal_lrFgpbuNvB.ttf',
    variant: 'regular',
    family: 'Life Savers',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '051878e6-0718-4650-89a9-eec3b0827630',
    origin: GF_SOURCE,
    url: GF_URL + 'orienta/v8/PlI9FlK4Jrl5Y9zNeyeo9HRFhcU.ttf',
    variant: 'regular',
    family: 'Orienta',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '12eef036-7343-4705-bc25-5a874aa5790d',
    origin: GF_SOURCE,
    url: GF_URL + 'walterturncoat/v13/snfys0Gs98ln43n0d-14ULoToe67YB2dQ5ZPqQ.ttf',
    variant: 'regular',
    family: 'Walter Turncoat',
    category: 'handwriting',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'fcca4a59-5571-4c70-8730-9f89b543dc6e',
    origin: GF_SOURCE,
    url: GF_URL + 'salsa/v12/gNMKW3FiRpKj-imY8ncKEZez.ttf',
    variant: 'regular',
    family: 'Salsa',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'fad1676a-99df-435a-bf69-93b3138a30b2',
    origin: GF_SOURCE,
    url: GF_URL + 'rakkas/v12/Qw3cZQlNHiblL3j_lttPOeMcCw.ttf',
    variant: 'regular',
    family: 'Rakkas',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '83da7545-3a1e-4791-b4a7-b3dccadff792',
    origin: GF_SOURCE,
    url: GF_URL + 'inknutantiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU2Hx7j5bBoIYJNf.ttf',
    variant: '900',
    family: 'Inknut Antiqua',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '869c6cc4-6d73-4201-b7ab-74963712bfa9',
    origin: GF_SOURCE,
    url: GF_URL + 'inknutantiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU2jxrj5bBoIYJNf.ttf',
    variant: '800',
    family: 'Inknut Antiqua',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '66007ff0-f7b0-4cb8-a895-4979750e556c',
    origin: GF_SOURCE,
    url: GF_URL + 'inknutantiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU2_xbj5bBoIYJNf.ttf',
    variant: '700',
    family: 'Inknut Antiqua',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'cce01049-0926-4794-94eb-a0fbb24a4563',
    origin: GF_SOURCE,
    url: GF_URL + 'inknutantiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU3bxLj5bBoIYJNf.ttf',
    variant: '600',
    family: 'Inknut Antiqua',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'dbb6738e-253b-48ac-aa47-e94803cdd70e',
    origin: GF_SOURCE,
    url: GF_URL + 'inknutantiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU33w7j5bBoIYJNf.ttf',
    variant: '500',
    family: 'Inknut Antiqua',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'c10b5be1-4698-4dff-9881-77ff5a7d4f75',
    origin: GF_SOURCE,
    url: GF_URL + 'inknutantiqua/v9/Y4GSYax7VC4ot_qNB4nYpBdaKXUD6pzxRwYB.ttf',
    variant: 'regular',
    family: 'Inknut Antiqua',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '8fe21513-f386-4f49-a137-fb0cccc8af01',
    origin: GF_SOURCE,
    url: GF_URL + 'inknutantiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU2vwrj5bBoIYJNf.ttf',
    variant: '300',
    family: 'Inknut Antiqua',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '17227027-4f03-4879-bd1d-24e8d644c999',
    origin: GF_SOURCE,
    url: GF_URL + 'euphoriascript/v11/mFTpWb0X2bLb_cx6To2B8GpKoD5ak_ZT1D8x7Q.ttf',
    variant: 'regular',
    family: 'Euphoria Script',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '946547df-558b-4146-8fd7-d4afadc24bb1',
    origin: GF_SOURCE,
    url: GF_URL + 'atma/v8/uK_z4rqWc-Eoo9J0Kjc9PvedRkM.ttf',
    variant: '700',
    family: 'Atma',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ee01e2a5-d746-4f26-bfff-d2b5b20d7801',
    origin: GF_SOURCE,
    url: GF_URL + 'atma/v8/uK_z4rqWc-Eoo7Z1Kjc9PvedRkM.ttf',
    variant: '600',
    family: 'Atma',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '0875700f-e56d-43e4-9cc2-9829b9fdd7b4',
    origin: GF_SOURCE,
    url: GF_URL + 'atma/v8/uK_z4rqWc-Eoo5pyKjc9PvedRkM.ttf',
    variant: '500',
    family: 'Atma',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'bee0bef3-8653-4fc5-a405-719237b1c856',
    origin: GF_SOURCE,
    url: GF_URL + 'atma/v8/uK_84rqWc-Eom25bDj8WIv4.ttf',
    variant: 'regular',
    family: 'Atma',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f41a05e2-b39d-4350-b86f-55edd2546772',
    origin: GF_SOURCE,
    url: GF_URL + 'atma/v8/uK_z4rqWc-Eoo8JzKjc9PvedRkM.ttf',
    variant: '300',
    family: 'Atma',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'a9eee3bc-f549-410f-b843-f8ebf9c32f63',
    origin: GF_SOURCE,
    url: GF_URL + 'dellarespira/v10/RLp5K5v44KaueWI6iEJQBiGPRfkSu6EuTHo.ttf',
    variant: 'regular',
    family: 'Della Respira',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8e715c56-dfd8-43d3-9900-5b5c77fe9136',
    origin: GF_SOURCE,
    url: GF_URL + 'cambo/v9/IFSqHeNEk8FJk416ok7xkPm8.ttf',
    variant: 'regular',
    family: 'Cambo',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'a024436e-65ae-4a52-993a-faeb1b762df4',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkornsc/v6/j8_y6-zQ3rXpceZj9cqnVin9GVuqYbPN5Yjn.ttf',
    variant: '900',
    family: 'Vollkorn SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '1e0ea145-30e3-4831-a795-d1b6305ef6e4',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkornsc/v6/j8_y6-zQ3rXpceZj9cqnVinFG1uqYbPN5Yjn.ttf',
    variant: '700',
    family: 'Vollkorn SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6fe95e82-adef-4eea-9300-af0e7b3f3e87',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkornsc/v6/j8_y6-zQ3rXpceZj9cqnVimhGluqYbPN5Yjn.ttf',
    variant: '600',
    family: 'Vollkorn SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '081170f6-430b-49e1-91ee-9265348cdd80',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkornsc/v6/j8_v6-zQ3rXpceZj9cqnVhF5NH-iSq_E.ttf',
    variant: 'regular',
    family: 'Vollkorn SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '5dc33ea7-adb2-46c5-9f8f-9c9b8bbc975d',
    origin: GF_SOURCE,
    url: GF_URL + 'biorhyme/v5/1cX3aULHBpDMsHYW_ETmiXGAq8Sk1PoH.ttf',
    variant: '800',
    family: 'BioRhyme',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2b3b33f9-cdbb-468e-9687-c14e9f23d7d1',
    origin: GF_SOURCE,
    url: GF_URL + 'biorhyme/v5/1cX3aULHBpDMsHYW_ET6inGAq8Sk1PoH.ttf',
    variant: '700',
    family: 'BioRhyme',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '61d5190d-3f14-4bf4-96e6-59059569a836',
    origin: GF_SOURCE,
    url: GF_URL + 'biorhyme/v5/1cXwaULHBpDMsHYW_HxGpVWIgNit.ttf',
    variant: 'regular',
    family: 'BioRhyme',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a3e90749-8af1-4d38-ab23-992fdeedb34c',
    origin: GF_SOURCE,
    url: GF_URL + 'biorhyme/v5/1cX3aULHBpDMsHYW_ETqjXGAq8Sk1PoH.ttf',
    variant: '300',
    family: 'BioRhyme',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a84b628c-ac66-4108-a8c0-5325efe58caf',
    origin: GF_SOURCE,
    url: GF_URL + 'biorhyme/v5/1cX3aULHBpDMsHYW_ESOjnGAq8Sk1PoH.ttf',
    variant: '200',
    family: 'BioRhyme',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '15b5b51e-ccc5-4082-ad70-99359d944099',
    origin: GF_SOURCE,
    url: GF_URL + 'mousememoirs/v8/t5tmIRoSNJ-PH0WNNgDYxdSb7TnFrpOHYh4.ttf',
    variant: 'regular',
    family: 'Mouse Memoirs',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '65a64110-2715-4481-bb48-22c1535a1652',
    origin: GF_SOURCE,
    url: GF_URL + 'yuseimagic/v5/yYLt0hbAyuCmoo5wlhPkpjHR-tdfcIT_.ttf',
    variant: 'regular',
    family: 'Yusei Magic',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '93ccaab5-ffaf-4cc0-a0c6-207ff325e66a',
    origin: GF_SOURCE,
    url: GF_URL + 'numans/v10/SlGRmQmGupYAfH8IYRggiHVqaQ.ttf',
    variant: 'regular',
    family: 'Numans',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'abe74b43-02e3-4cfe-b009-586ad0942450',
    origin: GF_SOURCE,
    url: GF_URL + 'unkempt/v12/2EbiL-Z2DFZue0DScTow1zWzq_5uT84.ttf',
    variant: '700',
    family: 'Unkempt',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '20cdcf0b-724b-4a00-836d-885bd6a24c49',
    origin: GF_SOURCE,
    url: GF_URL + 'unkempt/v12/2EbnL-Z2DFZue0DSSYYf8z2Yt_c.ttf',
    variant: 'regular',
    family: 'Unkempt',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '4c753096-6ab3-4833-a5b8-df2912d8d6bc',
    origin: GF_SOURCE,
    url: GF_URL + 'ranchers/v8/zrfm0H3Lx-P2Xvs2AoDYDC79XTHv.ttf',
    variant: 'regular',
    family: 'Ranchers',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '4f35a25f-181b-4ba9-89d2-1304047a9f97',
    origin: GF_SOURCE,
    url: GF_URL + 'imfellenglishsc/v12/a8IENpD3CDX-4zrWfr1VY879qFF05pZLO4gOg0shzA.ttf',
    variant: 'regular',
    family: 'IM Fell English SC',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'b8259741-242b-4968-81b9-52787e4fb0fd',
    origin: GF_SOURCE,
    url: GF_URL + 'timmana/v7/6xKvdShfL9yK-rvpCmvbKHwJUOM.ttf',
    variant: 'regular',
    family: 'Timmana',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'e4237939-ad51-4193-8bba-21fc55809c01',
    origin: GF_SOURCE,
    url: GF_URL + 'hanaleifill/v11/fC1mPYtObGbfyQznIaQzPQiMVwLBplm9aw.ttf',
    variant: 'regular',
    family: 'Hanalei Fill',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '553f7718-071d-4ee3-9f0b-c3d3a4b22297',
    origin: GF_SOURCE,
    url: GF_URL + 'notable/v9/gNMEW3N_SIqx-WX9-HMoFIez5MI.ttf',
    variant: 'regular',
    family: 'Notable',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'e72c4bab-df54-4cf0-8101-178679b19347',
    origin: GF_SOURCE,
    url: GF_URL + 'aguafinascript/v9/If2QXTv_ZzSxGIO30LemWEOmt1bHqs4pgicOrg.ttf',
    variant: 'regular',
    family: 'Aguafina Script',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '29aa362d-68fb-495d-8aa3-899fac6805a3',
    origin: GF_SOURCE,
    url: GF_URL + 'zeyada/v10/11hAGpPTxVPUbgZDNGatWKaZ3g.ttf',
    variant: 'regular',
    family: 'Zeyada',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '0bdaf20b-8195-4d3a-b0f1-f130dc66de79',
    origin: GF_SOURCE,
    url: GF_URL + 'craftygirls/v10/va9B4kXI39VaDdlPJo8N_NvuQR37fF3Wlg.ttf',
    variant: 'regular',
    family: 'Crafty Girls',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '1a164936-6356-4336-814e-3cadb84262c9',
    origin: GF_SOURCE,
    url: GF_URL + 'dokdo/v10/esDf315XNuCBLxLo4NaMlKcH.ttf',
    variant: 'regular',
    family: 'Dokdo',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a10e6c85-e4c7-4c07-a69b-d88d900687a3',
    origin: GF_SOURCE,
    url: GF_URL + 'creepster/v9/AlZy_zVUqJz4yMrniH4hdXf4XB0Tow.ttf',
    variant: 'regular',
    family: 'Creepster',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '47c78e37-d4ae-4d9b-b839-9143acc495d8',
    origin: GF_SOURCE,
    url: GF_URL + 'majormonodisplay/v7/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRhLCn2QIndPww.ttf',
    variant: 'regular',
    family: 'Major Mono Display',
    category: 'monospace',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '588ce5ed-991e-4310-a0ea-f590c1bf9947',
    origin: GF_SOURCE,
    url: GF_URL + 'codacaption/v14/ieVm2YRII2GMY7SyXSoDRiQGqcx6x_-fACIgaw.ttf',
    variant: '800',
    family: 'Coda Caption',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '2a1b4333-0df6-44cd-9067-f9751edb81b1',
    origin: GF_SOURCE,
    url: GF_URL + 'vesperlibre/v14/bx6dNxyWnf-uxPdXDHUD_RdAi2Kp0okKXKvPlw.ttf',
    variant: '900',
    family: 'Vesper Libre',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '1c282bc0-0d7a-49cc-9f48-662f417752e5',
    origin: GF_SOURCE,
    url: GF_URL + 'vesperlibre/v14/bx6dNxyWnf-uxPdXDHUD_RdAs2Cp0okKXKvPlw.ttf',
    variant: '700',
    family: 'Vesper Libre',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'deee7260-3447-488c-bb8d-6767c6db29c2',
    origin: GF_SOURCE,
    url: GF_URL + 'vesperlibre/v14/bx6dNxyWnf-uxPdXDHUD_RdA-2ap0okKXKvPlw.ttf',
    variant: '500',
    family: 'Vesper Libre',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '7591080e-fd46-4841-86df-fe2be136f41a',
    origin: GF_SOURCE,
    url: GF_URL + 'vesperlibre/v14/bx6CNxyWnf-uxPdXDHUD_Rd4D0-N2qIWVQ.ttf',
    variant: 'regular',
    family: 'Vesper Libre',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '899bae4b-9e02-4e64-a2d0-650f4590801e',
    origin: GF_SOURCE,
    url: GF_URL + 'ntr/v10/RLpzK5Xy0ZjiGGhs5TA4bg.ttf',
    variant: 'regular',
    family: 'NTR',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '3de0e276-b34b-4207-a8ab-3037e38ab75b',
    origin: GF_SOURCE,
    url: GF_URL + 'giveyouglory/v10/8QIQdiHOgt3vv4LR7ahjw9-XYc1zB4ZD6rwa.ttf',
    variant: 'regular',
    family: 'Give You Glory',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8186df27-c5f3-4e8d-8e89-2a02888c325f',
    origin: GF_SOURCE,
    url: GF_URL + 'dmmono/v5/aFTT7PB1QTsUX8KYth-o9YetaIf4VllXuA.ttf',
    variant: '500italic',
    family: 'DM Mono',
    category: 'monospace',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '5f9b6538-b89d-48da-9cca-1eadac2bac9d',
    origin: GF_SOURCE,
    url: GF_URL + 'dmmono/v5/aFTR7PB1QTsUX8KYvumzIYSnbKX9Rlk.ttf',
    variant: '500',
    family: 'DM Mono',
    category: 'monospace',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '38ba0e3f-9e8e-4042-ada4-94298cc05718',
    origin: GF_SOURCE,
    url: GF_URL + 'dmmono/v5/aFTW7PB1QTsUX8KYth-QAa6JYKzkXw.ttf',
    variant: 'italic',
    family: 'DM Mono',
    category: 'monospace',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '246deb2e-ac32-4901-b484-f0da08689a91',
    origin: GF_SOURCE,
    url: GF_URL + 'dmmono/v5/aFTU7PB1QTsUX8KYhh2aBYyMcKw.ttf',
    variant: 'regular',
    family: 'DM Mono',
    category: 'monospace',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '403ccbd9-e61c-4284-8bbf-37fede8a17ab',
    origin: GF_SOURCE,
    url: GF_URL + 'dmmono/v5/aFTT7PB1QTsUX8KYth-orYataIf4VllXuA.ttf',
    variant: '300italic',
    family: 'DM Mono',
    category: 'monospace',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3edc4f35-287a-4b45-a887-4ab708dc4137',
    origin: GF_SOURCE,
    url: GF_URL + 'dmmono/v5/aFTR7PB1QTsUX8KYvrGyIYSnbKX9Rlk.ttf',
    variant: '300',
    family: 'DM Mono',
    category: 'monospace',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e9ac6489-5268-4a14-9edd-0a188567df07',
    origin: GF_SOURCE,
    url: GF_URL + 'federo/v14/iJWFBX-cbD_ETsbmjVOe2WTG7Q.ttf',
    variant: 'regular',
    family: 'Federo',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'c8dcf506-65d4-42b5-b0cb-ed1488ecd29d',
    origin: GF_SOURCE,
    url: GF_URL + 'gaegu/v10/TuGSUVB6Up9NU573jvw74sdtBk0x.ttf',
    variant: '700',
    family: 'Gaegu',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '988700e0-a7a4-4e2f-848b-93333da7d26e',
    origin: GF_SOURCE,
    url: GF_URL + 'gaegu/v10/TuGfUVB6Up9NU6ZLodgzydtk.ttf',
    variant: 'regular',
    family: 'Gaegu',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '80c300a9-2f98-4963-bea5-ba9fde31a580',
    origin: GF_SOURCE,
    url: GF_URL + 'gaegu/v10/TuGSUVB6Up9NU57nifw74sdtBk0x.ttf',
    variant: '300',
    family: 'Gaegu',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '532ee685-3fb6-4fe8-9b8f-4045389866b6',
    origin: GF_SOURCE,
    url: GF_URL + 'mansalva/v4/aWB4m0aacbtDfvq5NJllI47vdyBg.ttf',
    variant: 'regular',
    family: 'Mansalva',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '2a27823e-23dd-491a-bc42-01a09846c969',
    origin: GF_SOURCE,
    url: GF_URL + 'sail/v11/DPEjYwiBxwYJFBTDADYAbvw.ttf',
    variant: 'regular',
    family: 'Sail',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'da4b5f6e-7280-4435-a9fc-ba15009be1a3',
    origin: GF_SOURCE,
    url: GF_URL + 'inder/v9/w8gUH2YoQe8_4vq6pw-P3U4O.ttf',
    variant: 'regular',
    family: 'Inder',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'cedaecfe-99a7-461e-8df0-accacd615abf',
    origin: GF_SOURCE,
    url: GF_URL + 'galada/v8/H4cmBXyGmcjXlUX-8iw-4Lqggw.ttf',
    variant: 'regular',
    family: 'Galada',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '8a058f34-0e32-40f9-898a-c2b62b2a9105',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemicondensed/v5/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1QV0-FHbdTgTFmr.ttf',
    variant: '900',
    family: 'Encode Sans Semi Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1adaac50-64a2-4c24-8a63-0e860377db34',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemicondensed/v5/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Qx0uFHbdTgTFmr.ttf',
    variant: '800',
    family: 'Encode Sans Semi Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '8616591a-e117-4486-b7e9-8bc0c880a49c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemicondensed/v5/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Qt0eFHbdTgTFmr.ttf',
    variant: '700',
    family: 'Encode Sans Semi Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6c082f38-75d9-4d19-ade7-1a975104f012',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemicondensed/v5/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1RJ0OFHbdTgTFmr.ttf',
    variant: '600',
    family: 'Encode Sans Semi Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '5c60ecdd-9c33-43e3-997b-8d884c186bdf',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemicondensed/v5/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Rl1-FHbdTgTFmr.ttf',
    variant: '500',
    family: 'Encode Sans Semi Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6e80453f-62a4-4f19-a9ed-c43c736790e2',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemicondensed/v5/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG2yR_sVPRsjp.ttf',
    variant: 'regular',
    family: 'Encode Sans Semi Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f772b383-55ea-4de7-ae45-33be82756324',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemicondensed/v5/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Q91uFHbdTgTFmr.ttf',
    variant: '300',
    family: 'Encode Sans Semi Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'd66f5196-5c15-49f3-8c31-cc249a8d8f3d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemicondensed/v5/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1RZ1eFHbdTgTFmr.ttf',
    variant: '200',
    family: 'Encode Sans Semi Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '5cb6cd4d-c1ab-4c47-b7f4-ff4623decbb6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanssemicondensed/v5/3qT6oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1T19MFtQ9jpVUA.ttf',
    variant: '100',
    family: 'Encode Sans Semi Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '804d7526-e49d-4f24-ba2b-06afe4918afa',
    origin: GF_SOURCE,
    url: GF_URL + 'imfelldwpica/v12/2sDEZGRQotv9nbn2qSl0TxXVYNwNZgnQnCosMXm0.ttf',
    variant: 'italic',
    family: 'IM Fell DW Pica',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'dac37dd5-81d3-47c1-bb9e-03d0359211e0',
    origin: GF_SOURCE,
    url: GF_URL + 'imfelldwpica/v12/2sDGZGRQotv9nbn2qSl0TxXVYNw9ZAPUvi88MQ.ttf',
    variant: 'regular',
    family: 'IM Fell DW Pica',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'e7db71bb-e160-42dd-9333-217a23a635ce',
    origin: GF_SOURCE,
    url: GF_URL + 'redrose/v8/QdVISTYiLBjouPgEUajvsfWwDtc3MH8ySvwDcjSsYUVUjg.ttf',
    variant: '700',
    family: 'Red Rose',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'bb82face-4809-4fe7-8203-78d80b35896d',
    origin: GF_SOURCE,
    url: GF_URL + 'redrose/v8/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yc_wDcjSsYUVUjg.ttf',
    variant: '600',
    family: 'Red Rose',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '9aff1d8b-adc8-4350-9254-f1caa82d5e65',
    origin: GF_SOURCE,
    url: GF_URL + 'redrose/v8/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yn_sDcjSsYUVUjg.ttf',
    variant: '500',
    family: 'Red Rose',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '8277e3b1-25a8-4a1f-a818-cb3b8e53aaaf',
    origin: GF_SOURCE,
    url: GF_URL + 'redrose/v8/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yrfsDcjSsYUVUjg.ttf',
    variant: 'regular',
    family: 'Red Rose',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '4e224fd6-ea6c-43af-b681-bfd24d2a4d90',
    origin: GF_SOURCE,
    url: GF_URL + 'redrose/v8/QdVISTYiLBjouPgEUajvsfWwDtc3MH8y8_sDcjSsYUVUjg.ttf',
    variant: '300',
    family: 'Red Rose',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '1db764a3-b357-40bb-bcd1-4c6f52ca7be8',
    origin: GF_SOURCE,
    url: GF_URL + 'brawler/v11/xn7gYHE3xXewAscGsgC7S9XdZN8.ttf',
    variant: 'regular',
    family: 'Brawler',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8c4e6f39-26ab-4dac-b49a-19c70483993e',
    origin: GF_SOURCE,
    url: GF_URL + 'caladea/v2/kJE0BugZ7AAjhybUvR1FQ98SrMxzBZ2lDA.ttf',
    variant: '700italic',
    family: 'Caladea',
    category: 'serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '991a50be-ecff-40c4-a50a-aebb2cccacbd',
    origin: GF_SOURCE,
    url: GF_URL + 'caladea/v2/kJE2BugZ7AAjhybUtaNY39oYqO52FZ0.ttf',
    variant: '700',
    family: 'Caladea',
    category: 'serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'e1ecda84-5112-495a-ab2c-5803ffb2b1b6',
    origin: GF_SOURCE,
    url: GF_URL + 'caladea/v2/kJExBugZ7AAjhybUvR19__A2pOdvDA.ttf',
    variant: 'italic',
    family: 'Caladea',
    category: 'serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '01c75848-6830-45fe-bb49-eae0c3aaf460',
    origin: GF_SOURCE,
    url: GF_URL + 'caladea/v2/kJEzBugZ7AAjhybUjR93-9IztOc.ttf',
    variant: 'regular',
    family: 'Caladea',
    category: 'serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '49c324eb-325c-4048-83bc-6fb189e5cc1b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'mountainsofchristmas/v13/3y9z6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7eBGqJFPtCOp6IaEA.ttf',
    variant: '700',
    family: 'Mountains of Christmas',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '41891b49-4eaa-45ab-9e6d-08306794184b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'mountainsofchristmas/v13/3y9w6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7ePNamMPNpJpc.ttf',
    variant: 'regular',
    family: 'Mountains of Christmas',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'dc123802-3b68-4a9d-9f43-135451696269',
    origin: GF_SOURCE,
    url: GF_URL + 'b612mono/v5/kmKkZq85QVWbN1eW6lJV1TZkp8VLnbdWSg4x.ttf',
    variant: '700italic',
    family: 'B612 Mono',
    category: 'monospace',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '58418cb2-a260-44d4-8927-fc1d9324d8a6',
    origin: GF_SOURCE,
    url: GF_URL + 'b612mono/v5/kmK6Zq85QVWbN1eW6lJdayv4os9Pv7JGSg.ttf',
    variant: '700',
    family: 'B612 Mono',
    category: 'monospace',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f894dacd-9cd0-447b-ab1b-166adcb2fe28',
    origin: GF_SOURCE,
    url: GF_URL + 'b612mono/v5/kmK5Zq85QVWbN1eW6lJV1Q7YiOFDtqtf.ttf',
    variant: 'italic',
    family: 'B612 Mono',
    category: 'monospace',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'c75da40e-113c-4463-bdb1-f44176fdcc43',
    origin: GF_SOURCE,
    url: GF_URL + 'b612mono/v5/kmK_Zq85QVWbN1eW6lJl1wTcquRTtg.ttf',
    variant: 'regular',
    family: 'B612 Mono',
    category: 'monospace',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e1e8b84e-0bd7-498b-a878-9a15f5d1d06a',
    origin: GF_SOURCE,
    url: GF_URL + 'patrickhandsc/v8/0nkwC9f7MfsBiWcLtY65AWDK873ViSi6JQc7Vg.ttf',
    variant: 'regular',
    family: 'Patrick Hand SC',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '759b67f2-f6ce-4015-82b8-ad1a930099d7',
    origin: GF_SOURCE,
    url: GF_URL + 'fingerpaint/v10/0QInMXVJ-o-oRn_7dron8YWO85bS8ANesw.ttf',
    variant: 'regular',
    family: 'Finger Paint',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'cd3e30b5-74f3-4440-a1f4-ae67b3f76fd9',
    origin: GF_SOURCE,
    url: GF_URL + 'anaheim/v8/8vII7w042Wp87g4G0UTUEE5eK_w.ttf',
    variant: 'regular',
    family: 'Anaheim',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '6c349b69-9c78-44c1-b78c-8e9a7a1e3e41',
    origin: GF_SOURCE,
    url: GF_URL + 'alikeangular/v13/3qTrojWunjGQtEBlIcwMbSoI3kM6bB7FKjE.ttf',
    variant: 'regular',
    family: 'Alike Angular',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '3fc9de6a-3b1f-48f3-b2a2-7983604ae476',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVudDflz-iPfoPuIC2iIqMfTUFFI0wvNzdwXQ.ttf',
    variant: '800italic',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '1289605b-4f2f-408e-b217-7bbe64c11477',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVwdDflz-iPfoPuIC2iKgEBzUdPJ24qJzc.ttf',
    variant: '800',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'cd4f3e0a-125b-4690-8820-579f12f37189',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVudDflz-iPfoPuIC2iIqMfUUJFI0wvNzdwXQ.ttf',
    variant: '700italic',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '23d50eaf-f6f3-4a73-bf0f-290f8ad613fd',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVwdDflz-iPfoPuIC2iKh0CzUdPJ24qJzc.ttf',
    variant: '700',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '0e8d9652-ed6d-4143-be82-aea19c0a22e0',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVudDflz-iPfoPuIC2iIqMfNUNFI0wvNzdwXQ.ttf',
    variant: '600italic',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'b6fc91ff-73d3-49ec-87fb-e366bd1ed3cc',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVwdDflz-iPfoPuIC2iKnkDzUdPJ24qJzc.ttf',
    variant: '600',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '5634e990-2503-4862-a195-b3abcf1efe57',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVudDflz-iPfoPuIC2iIqMfGURFI0wvNzdwXQ.ttf',
    variant: '500italic',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '67b467ea-078e-450b-a34c-5695d94e5e64',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVwdDflz-iPfoPuIC2iKlUEzUdPJ24qJzc.ttf',
    variant: '500',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'c7399dbc-8ad9-4227-8681-262e8cbe63e9',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVxdDflz-iPfoPuIC2iIqMn7W1hK2czPg.ttf',
    variant: 'italic',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'f19def57-f539-4fa1-bd71-1abde3e7d9a5',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVzdDflz-iPfoPuIC2iEqEt6U9kO2c.ttf',
    variant: 'regular',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'cd25c7c8-4b8f-4620-94db-f52300b4c682',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVudDflz-iPfoPuIC2iIqMfQUVFI0wvNzdwXQ.ttf',
    variant: '300italic',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '60de9df6-4972-4ab8-8d9d-3bba5c54afbe',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVwdDflz-iPfoPuIC2iKg0FzUdPJ24qJzc.ttf',
    variant: '300',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '242bb076-39ee-4a3b-bbf8-94c325adb323',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVvdDflz-iPfoPuIC2iIqMfiWdlCWIjPi5p.ttf',
    variant: '100italic',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '10c9b29b-f8b8-42f0-81b7-808a43e82296',
    origin: GF_SOURCE,
    url: GF_URL + 'bevietnam/v2/FBVxdDflz-iPfoPuIC2iKsUn7W1hK2czPg.ttf',
    variant: '100',
    family: 'Be Vietnam',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '59162cb8-37d1-4ae1-a23b-26937946dccb',
    origin: GF_SOURCE,
    url: GF_URL + 'bowlbyone/v12/taiPGmVuC4y96PFeqp8smo6C_Z0wcK4.ttf',
    variant: 'regular',
    family: 'Bowlby One',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '831c9001-176e-44f5-810c-4a1a15b30798',
    origin: GF_SOURCE,
    url: GF_URL + 'andika/v12/mem_Ya6iyW-LwqgAbbwRWrwGVA.ttf',
    variant: 'regular',
    family: 'Andika',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '0accf271-571b-42a6-a17a-9d1427aa803d',
    origin: GF_SOURCE,
    url: GF_URL + 'montez/v11/845ZNMk5GoGIX8lm1LDeSd-R_g.ttf',
    variant: 'regular',
    family: 'Montez',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7a99c1f1-e8ef-4af2-9d9c-c34921577683',
    origin: GF_SOURCE,
    url: GF_URL + 'quando/v9/xMQVuFNaVa6YuW0pC6WzKX_QmA.ttf',
    variant: 'regular',
    family: 'Quando',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'c99821d6-366a-457d-aaca-506632f7c695',
    origin: GF_SOURCE,
    url: GF_URL + 'belgrano/v11/55xvey5tM9rwKWrJZcMFirl08KDJ.ttf',
    variant: 'regular',
    family: 'Belgrano',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'cd3c985d-85af-4aae-9251-2a1b08600da0',
    origin: GF_SOURCE,
    url: GF_URL + 'rasa/v6/xn7gYHIn1mWmdh5xsgC7S9XdZN8.ttf',
    variant: '700',
    family: 'Rasa',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ab1e5d36-79e6-4efb-abb5-b61b74bf497a',
    origin: GF_SOURCE,
    url: GF_URL + 'rasa/v6/xn7gYHIn1mWmdnpwsgC7S9XdZN8.ttf',
    variant: '600',
    family: 'Rasa',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ccdbb1a0-a326-4403-be82-069b81042359',
    origin: GF_SOURCE,
    url: GF_URL + 'rasa/v6/xn7gYHIn1mWmdlZ3sgC7S9XdZN8.ttf',
    variant: '500',
    family: 'Rasa',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '63621076-fadb-41d4-9392-1b89b99e2362',
    origin: GF_SOURCE,
    url: GF_URL + 'rasa/v6/xn7vYHIn1mWmTqJelgiQV9w.ttf',
    variant: 'regular',
    family: 'Rasa',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '70661547-751f-470f-a57b-a206aa28d820',
    origin: GF_SOURCE,
    url: GF_URL + 'rasa/v6/xn7gYHIn1mWmdg52sgC7S9XdZN8.ttf',
    variant: '300',
    family: 'Rasa',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'eac5ce75-6882-4a4c-8c4f-b40fb6ba6d7e',
    origin: GF_SOURCE,
    url: GF_URL + 'doppioone/v8/Gg8wN5gSaBfyBw2MqCh-lgshKGpe5Fg.ttf',
    variant: 'regular',
    family: 'Doppio One',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '798c9407-d304-46fc-8c3e-9343377d5e29',
    origin: GF_SOURCE,
    url: GF_URL + 'clickerscript/v8/raxkHiKPvt8CMH6ZWP8PdlEq72rY2zqUKafv.ttf',
    variant: 'regular',
    family: 'Clicker Script',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b58e7d19-37b8-42d2-aa92-1c7bbf305890',
    origin: GF_SOURCE,
    url: GF_URL + 'skranji/v8/OZpGg_dtriVFNerMW4eBtlzNwED-b4g.ttf',
    variant: '700',
    family: 'Skranji',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c24ef787-d9d3-4783-8339-b54e4cea0211',
    origin: GF_SOURCE,
    url: GF_URL + 'skranji/v8/OZpDg_dtriVFNerMYzuuklTm3Ek.ttf',
    variant: 'regular',
    family: 'Skranji',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e80e5b0c-9be6-4fe8-999a-65bebb0501ee',
    origin: GF_SOURCE,
    url: GF_URL + 'b612/v5/3Jn_SDDxiSz36juKoDWBSVcBXuFb0Q.ttf',
    variant: '700italic',
    family: 'B612',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e29c8829-d473-4f9a-a6c5-7b4b47e32183',
    origin: GF_SOURCE,
    url: GF_URL + 'b612/v5/3Jn9SDDxiSz34oWXPDCLTXUETuE.ttf',
    variant: '700',
    family: 'B612',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '289d293e-5e9b-4abb-8ef1-dafe31723f33',
    origin: GF_SOURCE,
    url: GF_URL + 'b612/v5/3Jn8SDDxiSz36juyHBqlQXwdVw.ttf',
    variant: 'italic',
    family: 'B612',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '9736628f-9035-452d-ae41-adad90b2d8bb',
    origin: GF_SOURCE,
    url: GF_URL + 'b612/v5/3JnySDDxiSz32jm4GDigUXw.ttf',
    variant: 'regular',
    family: 'B612',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '0b61ceb4-b362-433b-bab6-1283a098bdfd',
    origin: GF_SOURCE,
    url: GF_URL + 'sarpanch/v6/hES16Xt4NCpRuk6PziUEaa7f1HEuRHkM.ttf',
    variant: '900',
    family: 'Sarpanch',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f46eb859-7025-4100-a244-40550d118afd',
    origin: GF_SOURCE,
    url: GF_URL + 'sarpanch/v6/hES16Xt4NCpRuk6PziUgaK7f1HEuRHkM.ttf',
    variant: '800',
    family: 'Sarpanch',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bf08554b-e634-4e3b-b7c4-381572f232c9',
    origin: GF_SOURCE,
    url: GF_URL + 'sarpanch/v6/hES16Xt4NCpRuk6PziU8a67f1HEuRHkM.ttf',
    variant: '700',
    family: 'Sarpanch',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'b119600b-fa17-4167-8964-6831e4b649db',
    origin: GF_SOURCE,
    url: GF_URL + 'sarpanch/v6/hES16Xt4NCpRuk6PziVYaq7f1HEuRHkM.ttf',
    variant: '600',
    family: 'Sarpanch',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '18c8cee6-1c99-474b-a4a6-05d69cff3fc3',
    origin: GF_SOURCE,
    url: GF_URL + 'sarpanch/v6/hES16Xt4NCpRuk6PziV0ba7f1HEuRHkM.ttf',
    variant: '500',
    family: 'Sarpanch',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd3c419f8-a75c-425e-8072-b204fe59aaf6',
    origin: GF_SOURCE,
    url: GF_URL + 'sarpanch/v6/hESy6Xt4NCpRuk6Pzh2ARIrX_20n.ttf',
    variant: 'regular',
    family: 'Sarpanch',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a0145533-d646-4a8f-a958-f22c4a4e651b',
    origin: GF_SOURCE,
    url: GF_URL + 'dawningofanewday/v11/t5t_IQMbOp2SEwuncwLRjMfIg1yYit_nAz8bhWJGNoBE.ttf',
    variant: 'regular',
    family: 'Dawning of a New Day',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '664ebdd8-9d67-4e18-b7a4-f7680f7a2c55',
    origin: GF_SOURCE,
    url: GF_URL + 'firacode/v10/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_NprFVfxN87gsj0.ttf',
    variant: '700',
    family: 'Fira Code',
    category: 'monospace',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'bfcc9053-6135-494d-8733-f173c9bcccac',
    origin: GF_SOURCE,
    url: GF_URL + 'firacode/v10/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_ONrFVfxN87gsj0.ttf',
    variant: '600',
    family: 'Fira Code',
    category: 'monospace',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'f2ae7d1f-c1dd-4565-babd-d47f199a1de2',
    origin: GF_SOURCE,
    url: GF_URL + 'firacode/v10/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_A9sFVfxN87gsj0.ttf',
    variant: '500',
    family: 'Fira Code',
    category: 'monospace',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '40e69f96-5706-49fc-858b-6f6a867a5b0c',
    origin: GF_SOURCE,
    url: GF_URL + 'firacode/v10/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_D1sFVfxN87gsj0.ttf',
    variant: 'regular',
    family: 'Fira Code',
    category: 'monospace',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'fc420c69-9aec-47c7-8fc6-06e52a8d81ea',
    origin: GF_SOURCE,
    url: GF_URL + 'firacode/v10/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_GNsFVfxN87gsj0.ttf',
    variant: '300',
    family: 'Fira Code',
    category: 'monospace',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ff2e9f64-e412-40e4-bfae-b025f4b587f4',
    origin: GF_SOURCE,
    url: GF_URL + 'megrim/v11/46kulbz5WjvLqJZlbWXgd0RY1g.ttf',
    variant: 'regular',
    family: 'Megrim',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '51c4847d-e461-42c0-9608-c007a225dbad',
    origin: GF_SOURCE,
    url: GF_URL + 'prozalibre/v5/LYjZdGHgj0k1DIQRyUEyyEotTH_ZeJSY8z6Np1k.ttf',
    variant: '800italic',
    family: 'Proza Libre',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '8d318927-a32b-40fc-8d88-90fe966f40a9',
    origin: GF_SOURCE,
    url: GF_URL + 'prozalibre/v5/LYjbdGHgj0k1DIQRyUEyyEKPUv_fcpC69i6N.ttf',
    variant: '800',
    family: 'Proza Libre',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '80f8f6b8-34ef-4b2d-a381-97606becb0e0',
    origin: GF_SOURCE,
    url: GF_URL + 'prozalibre/v5/LYjZdGHgj0k1DIQRyUEyyEotTGPaeJSY8z6Np1k.ttf',
    variant: '700italic',
    family: 'Proza Libre',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '07f3767f-e59f-4ba6-b9ad-3f1e451ed15f',
    origin: GF_SOURCE,
    url: GF_URL + 'prozalibre/v5/LYjbdGHgj0k1DIQRyUEyyEKTUf_fcpC69i6N.ttf',
    variant: '700',
    family: 'Proza Libre',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '88d225d3-7b57-46fe-83a1-520d1bd9795e',
    origin: GF_SOURCE,
    url: GF_URL + 'prozalibre/v5/LYjZdGHgj0k1DIQRyUEyyEotTAfbeJSY8z6Np1k.ttf',
    variant: '600italic',
    family: 'Proza Libre',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '83072631-7da0-40c0-a611-2d6528e5de3e',
    origin: GF_SOURCE,
    url: GF_URL + 'prozalibre/v5/LYjbdGHgj0k1DIQRyUEyyEL3UP_fcpC69i6N.ttf',
    variant: '600',
    family: 'Proza Libre',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '4b5dd2d5-7673-4cd5-873a-16c4917c7cfd',
    origin: GF_SOURCE,
    url: GF_URL + 'prozalibre/v5/LYjZdGHgj0k1DIQRyUEyyEotTCvceJSY8z6Np1k.ttf',
    variant: '500italic',
    family: 'Proza Libre',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '223fced8-e336-4726-9546-415f59690dab',
    origin: GF_SOURCE,
    url: GF_URL + 'prozalibre/v5/LYjbdGHgj0k1DIQRyUEyyELbV__fcpC69i6N.ttf',
    variant: '500',
    family: 'Proza Libre',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '4bec477b-f957-4111-a2dc-bbab858068c4',
    origin: GF_SOURCE,
    url: GF_URL + 'prozalibre/v5/LYjEdGHgj0k1DIQRyUEyyEotdN_1XJyz7zc.ttf',
    variant: 'italic',
    family: 'Proza Libre',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '4aee3a36-406b-49fc-9705-8f7924b03ad6',
    origin: GF_SOURCE,
    url: GF_URL + 'prozalibre/v5/LYjGdGHgj0k1DIQRyUEyyHovftvXWYyz.ttf',
    variant: 'regular',
    family: 'Proza Libre',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2cc3ead5-221d-4f6b-878d-481a4155f0d4',
    origin: GF_SOURCE,
    url: GF_URL + 'heptaslab/v9/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvm84DfbY5B0NBkz.ttf',
    variant: '900',
    family: 'Hepta Slab',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '55809cc6-b89e-4c2a-8a8c-3c14dabb3d45',
    origin: GF_SOURCE,
    url: GF_URL + 'heptaslab/v9/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvmV4DfbY5B0NBkz.ttf',
    variant: '800',
    family: 'Hepta Slab',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '40f1131f-7740-4663-a3aa-25b0486cab61',
    origin: GF_SOURCE,
    url: GF_URL + 'heptaslab/v9/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvny4DfbY5B0NBkz.ttf',
    variant: '700',
    family: 'Hepta Slab',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'd0ca710e-781f-4383-b229-d9fa5c6fc484',
    origin: GF_SOURCE,
    url: GF_URL + 'heptaslab/v9/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvnL4DfbY5B0NBkz.ttf',
    variant: '600',
    family: 'Hepta Slab',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '577613d6-d04a-4839-99d8-caee7a39f912',
    origin: GF_SOURCE,
    url: GF_URL + 'heptaslab/v9/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvkn5zfbY5B0NBkz.ttf',
    variant: '500',
    family: 'Hepta Slab',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '3bd4d43a-d6e5-4b81-9929-88d584393028',
    origin: GF_SOURCE,
    url: GF_URL + 'heptaslab/v9/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5zfbY5B0NBkz.ttf',
    variant: 'regular',
    family: 'Hepta Slab',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '687c43b9-746a-4236-a144-9b7e34f52cce',
    origin: GF_SOURCE,
    url: GF_URL + 'heptaslab/v9/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvlL5zfbY5B0NBkz.ttf',
    variant: '300',
    family: 'Hepta Slab',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'cde2fd69-721d-4163-93d4-ab4c9b71b47a',
    origin: GF_SOURCE,
    url: GF_URL + 'heptaslab/v9/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvmV5zfbY5B0NBkz.ttf',
    variant: '200',
    family: 'Hepta Slab',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '39c1843d-0bc7-4c7f-84b9-66bb5501e4c6',
    origin: GF_SOURCE,
    url: GF_URL + 'heptaslab/v9/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5jfbY5B0NBkz.ttf',
    variant: '100',
    family: 'Hepta Slab',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'd2708bca-2fd8-484c-b1fa-2985052c35a3',
    origin: GF_SOURCE,
    url: GF_URL + 'happymonkey/v9/K2F2fZZcl-9SXwl5F_C4R_OABwD2bWqVjw.ttf',
    variant: 'regular',
    family: 'Happy Monkey',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'a7650df2-4416-46e3-be2e-ade2614b524d',
    origin: GF_SOURCE,
    url: GF_URL + 'ralewaydots/v8/6NUR8FifJg6AfQvzpshgwJ8kyf9Fdty2ew.ttf',
    variant: 'regular',
    family: 'Raleway Dots',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b1c17bb6-3590-4465-99ff-9883d6634531',
    origin: GF_SOURCE,
    url: GF_URL + 'fondamento/v11/4UaFrEJGsxNmFTPDnkaJ96_p4rFwIwJePw.ttf',
    variant: 'italic',
    family: 'Fondamento',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'bc43dbba-f66e-4ca7-a5da-b535cd4318f3',
    origin: GF_SOURCE,
    url: GF_URL + 'fondamento/v11/4UaHrEJGsxNmFTPDnkaJx63j5pN1MwI.ttf',
    variant: 'regular',
    family: 'Fondamento',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '02786240-1eae-4731-84e9-d41a30e8522e',
    origin: GF_SOURCE,
    url: GF_URL + 'stardosstencil/v11/X7n44bcuGPC8hrvEOHXOgaKCc2TpU3tTvg-t29HSHw.ttf',
    variant: '700',
    family: 'Stardos Stencil',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'd4422c5c-d575-4ef1-87cf-f6fdf428fd93',
    origin: GF_SOURCE,
    url: GF_URL + 'stardosstencil/v11/X7n94bcuGPC8hrvEOHXOgaKCc2TR71R3tiSx0g.ttf',
    variant: 'regular',
    family: 'Stardos Stencil',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '350da1da-b101-4eac-b90d-6d9b292c5658',
    origin: GF_SOURCE,
    url: GF_URL + 'meddon/v15/kmK8ZqA2EgDNeHTZhBdB3y_Aow.ttf',
    variant: 'regular',
    family: 'Meddon',
    category: 'handwriting',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'f8975a4d-bb1d-46fa-b4f1-d0575f21e092',
    origin: GF_SOURCE,
    url: GF_URL + 'oregano/v8/If2KXTPxciS3H4S2oZXVOxvLzyP_qw.ttf',
    variant: 'italic',
    family: 'Oregano',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f6d2dc6d-7b06-441e-b934-efabfbd382e1',
    origin: GF_SOURCE,
    url: GF_URL + 'oregano/v8/If2IXTPxciS3H4S2kZffPznO3yM.ttf',
    variant: 'regular',
    family: 'Oregano',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '7a9a8e35-526c-445e-8c9e-64efa9003a03',
    origin: GF_SOURCE,
    url: GF_URL + 'expletussans/v14/RLpiK5v5_bqufTYdnhFzDj2ddfsgA6wPVFHNBaVImA.ttf',
    variant: '700italic',
    family: 'Expletus Sans',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '8ed65736-3ab5-4e1c-b196-ab4211721a09',
    origin: GF_SOURCE,
    url: GF_URL + 'expletussans/v14/RLpkK5v5_bqufTYdnhFzDj2dfUU9n6kFUHPIFaU.ttf',
    variant: '700',
    family: 'Expletus Sans',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'e8b07ed9-2c46-4120-a6a2-c3ae77938861',
    origin: GF_SOURCE,
    url: GF_URL + 'expletussans/v14/RLpiK5v5_bqufTYdnhFzDj2ddfsgZ60PVFHNBaVImA.ttf',
    variant: '600italic',
    family: 'Expletus Sans',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'adcf342b-7d46-4039-9b4a-8dd6216071be',
    origin: GF_SOURCE,
    url: GF_URL + 'expletussans/v14/RLpkK5v5_bqufTYdnhFzDj2dfSE8n6kFUHPIFaU.ttf',
    variant: '600',
    family: 'Expletus Sans',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '300e59a0-ef6d-4a11-925a-59578e3ab43f',
    origin: GF_SOURCE,
    url: GF_URL + 'expletussans/v14/RLpiK5v5_bqufTYdnhFzDj2ddfsgS6oPVFHNBaVImA.ttf',
    variant: '500italic',
    family: 'Expletus Sans',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'b1f01d1c-1e23-443c-8df8-c2a8bc493c33',
    origin: GF_SOURCE,
    url: GF_URL + 'expletussans/v14/RLpkK5v5_bqufTYdnhFzDj2dfQ07n6kFUHPIFaU.ttf',
    variant: '500',
    family: 'Expletus Sans',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '9017e601-aca5-4ed0-925e-b55f5dba7941',
    origin: GF_SOURCE,
    url: GF_URL + 'expletussans/v14/RLpnK5v5_bqufTYdnhFzDj2ddfsYv4MrXHrRDA.ttf',
    variant: 'italic',
    family: 'Expletus Sans',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '4e9cf36b-4121-44de-873b-29fcf586642a',
    origin: GF_SOURCE,
    url: GF_URL + 'expletussans/v14/RLp5K5v5_bqufTYdnhFzDj2dRfkSu6EuTHo.ttf',
    variant: 'regular',
    family: 'Expletus Sans',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '71a7db76-1be0-47f0-9919-ec7ebb8f6f14',
    origin: GF_SOURCE,
    url: GF_URL + 'gugi/v8/A2BVn5dXywshVA6A9DEfgqM.ttf',
    variant: 'regular',
    family: 'Gugi',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd323301f-dba3-4f92-b2f2-24224a052be3',
    origin: GF_SOURCE,
    url: GF_URL + 'codystar/v10/FwZY7-Q1xVk-40qxOt6A4sijpFu_.ttf',
    variant: 'regular',
    family: 'Codystar',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '57d241bb-e964-4832-acb6-24c945ee7a26',
    origin: GF_SOURCE,
    url: GF_URL + 'codystar/v10/FwZf7-Q1xVk-40qxOuYsyuyrj0e29bfC.ttf',
    variant: '300',
    family: 'Codystar',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a1efe976-a904-4584-82da-c0adfe951816',
    origin: GF_SOURCE,
    url: GF_URL + 'baumans/v10/-W_-XJj9QyTd3QfpR_oyaksqY5Q.ttf',
    variant: 'regular',
    family: 'Baumans',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ecc2ad85-1941-4333-a6fa-3ae10d4af485',
    origin: GF_SOURCE,
    url: GF_URL + 'qwigley/v10/1cXzaU3UGJb5tGoCuVxsi1mBmcE.ttf',
    variant: 'regular',
    family: 'Qwigley',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '68c08071-a41d-43be-adc4-43b46884f504',
    origin: GF_SOURCE,
    url: GF_URL + 'freckleface/v9/AMOWz4SXrmKHCvXTohxY-YI0U1K2w9lb4g.ttf',
    variant: 'regular',
    family: 'Freckle Face',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '225b94d7-774e-44d2-aba0-d5a1604ad506',
    origin: GF_SOURCE,
    url: GF_URL + 'lemon/v9/HI_EiYEVKqRMq0jBSZXAQ4-d.ttf',
    variant: 'regular',
    family: 'Lemon',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '81d3c1af-3ec8-4053-9f97-b1a56a437953',
    origin: GF_SOURCE,
    url: GF_URL + 'gurajada/v10/FwZY7-Qx308m-l-0Kd6A4sijpFu_.ttf',
    variant: 'regular',
    family: 'Gurajada',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '586cbb8e-9d4a-4cf1-8b24-31cad11820fe',
    origin: GF_SOURCE,
    url: GF_URL + 'pollerone/v12/ahccv82n0TN3gia5E4Bud-lbgUS5u0s.ttf',
    variant: 'regular',
    family: 'Poller One',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'd74edf46-673b-4e48-8b2b-8ad10630edc4',
    origin: GF_SOURCE,
    url: GF_URL + 'librebarcode39/v14/-nFnOHM08vwC6h8Li1eQnP_AHzI2K_d709jy92k.ttf',
    variant: 'regular',
    family: 'Libre Barcode 39',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'e9415f36-9095-4dda-a46b-32614288dbb5',
    origin: GF_SOURCE,
    url: GF_URL + 'tenaliramakrishna/v7/raxgHj6Yt9gAN3LLKs0BZVMo8jmwn1-8KJXqUFFvtA.ttf',
    variant: 'regular',
    family: 'Tenali Ramakrishna',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '68912073-6c92-4364-8666-39b4d7506fd7',
    origin: GF_SOURCE,
    url: GF_URL + 'bungeeshade/v6/DtVkJxarWL0t2KdzK3oI_jks7iLSrwFUlw.ttf',
    variant: 'regular',
    family: 'Bungee Shade',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c1df4304-a5bf-473a-a8b7-a0a417feaa49',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grenzegotisch/v3/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5hjhUcICdYPSd_w.ttf',
    variant: '900',
    family: 'Grenze Gotisch',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '577e4a83-cca2-4cd3-93e9-76693c1f5b9e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grenzegotisch/v3/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5rzhUcICdYPSd_w.ttf',
    variant: '800',
    family: 'Grenze Gotisch',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '1a3e76a0-8153-4a83-875b-5008fb84632e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grenzegotisch/v3/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5yDhUcICdYPSd_w.ttf',
    variant: '700',
    family: 'Grenze Gotisch',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '9b27d40c-193c-4942-bf38-fb2c223f559b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grenzegotisch/v3/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i58ThUcICdYPSd_w.ttf',
    variant: '600',
    family: 'Grenze Gotisch',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '74d8b20d-6493-4d37-8b66-b2a9801ba572',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grenzegotisch/v3/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5HT9UcICdYPSd_w.ttf',
    variant: '500',
    family: 'Grenze Gotisch',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '2251b1ee-612e-410c-ac1b-716d4f559077',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grenzegotisch/v3/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz9UcICdYPSd_w.ttf',
    variant: 'regular',
    family: 'Grenze Gotisch',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'fbb7cf30-b2c2-4d8a-99db-da7c91a3a2b4',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grenzegotisch/v3/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5cT9UcICdYPSd_w.ttf',
    variant: '300',
    family: 'Grenze Gotisch',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'de0ba3c4-a2a1-402b-bd0f-3056130bd123',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grenzegotisch/v3/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5rz9UcICdYPSd_w.ttf',
    variant: '200',
    family: 'Grenze Gotisch',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'fdaf1301-edb3-4f2a-a21d-5976e5775ecb',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grenzegotisch/v3/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz5UcICdYPSd_w.ttf',
    variant: '100',
    family: 'Grenze Gotisch',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '6047c8f3-b922-468a-8aeb-1dd616d1d606',
    origin: GF_SOURCE,
    url: GF_URL + 'sora/v3/xMQOuFFYT72X5wkB_18qmnndmSfSmX-KIwNhBti0.ttf',
    variant: '800',
    family: 'Sora',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'ab57a96c-d7b3-4eac-b0c9-36f2689bd053',
    origin: GF_SOURCE,
    url: GF_URL + 'sora/v3/xMQOuFFYT72X5wkB_18qmnndmSe1mX-KIwNhBti0.ttf',
    variant: '700',
    family: 'Sora',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'b859f168-9ac8-4956-ae47-653569cd9cd2',
    origin: GF_SOURCE,
    url: GF_URL + 'sora/v3/xMQOuFFYT72X5wkB_18qmnndmSeMmX-KIwNhBti0.ttf',
    variant: '600',
    family: 'Sora',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'b62ae719-0170-4e78-a002-9cc044dff5de',
    origin: GF_SOURCE,
    url: GF_URL + 'sora/v3/xMQOuFFYT72X5wkB_18qmnndmSdgnn-KIwNhBti0.ttf',
    variant: '500',
    family: 'Sora',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '5f9008dd-d698-4b90-bd80-6e3ef7a6ddc0',
    origin: GF_SOURCE,
    url: GF_URL + 'sora/v3/xMQOuFFYT72X5wkB_18qmnndmSdSnn-KIwNhBti0.ttf',
    variant: 'regular',
    family: 'Sora',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '49cf285f-1396-4f38-9186-d8225e2b949c',
    origin: GF_SOURCE,
    url: GF_URL + 'sora/v3/xMQOuFFYT72X5wkB_18qmnndmScMnn-KIwNhBti0.ttf',
    variant: '300',
    family: 'Sora',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'b7beda4f-3f9d-481a-a314-bac27b231d75',
    origin: GF_SOURCE,
    url: GF_URL + 'sora/v3/xMQOuFFYT72X5wkB_18qmnndmSfSnn-KIwNhBti0.ttf',
    variant: '200',
    family: 'Sora',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '7b241c38-3cd4-424d-8813-9263be6c896f',
    origin: GF_SOURCE,
    url: GF_URL + 'sora/v3/xMQOuFFYT72X5wkB_18qmnndmSdSn3-KIwNhBti0.ttf',
    variant: '100',
    family: 'Sora',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '2217ad7f-7770-47fc-93c7-4600d7709633',
    origin: GF_SOURCE,
    url: GF_URL + 'kurale/v6/4iCs6KV9e9dXjho6eAT3v02QFg.ttf',
    variant: 'regular',
    family: 'Kurale',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '76e98daf-429f-4872-8809-129c4e96c534',
    origin: GF_SOURCE,
    url: GF_URL + 'pompiere/v10/VEMyRoxis5Dwuyeov6Wt5jDtreOL.ttf',
    variant: 'regular',
    family: 'Pompiere',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '434f1e25-cb85-4c7c-a948-9289e297d607',
    origin: GF_SOURCE,
    url: GF_URL + 'vastshadow/v10/pe0qMImKOZ1V62ZwbVY9dfe6Kdpickwp.ttf',
    variant: 'regular',
    family: 'Vast Shadow',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '93c41003-bdff-4888-9488-5461f2a7c0e4',
    origin: GF_SOURCE,
    url: GF_URL + 'sedgwickave/v6/uK_04rKEYuguzAcSYRdWTJq8Xmg1Vcf5JA.ttf',
    variant: 'regular',
    family: 'Sedgwick Ave',
    category: 'handwriting',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '9df93a27-b195-4235-9276-429e550aaac0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'waitingforthesunrise/v11/WBL1rFvOYl9CEv2i1mO6KUW8RKWJ2zoXoz5JsYZQ9h_ZYk5J.ttf',
    variant: 'regular',
    family: 'Waiting for the Sunrise',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'd2b3cc2e-23b5-4b16-9b9a-dec2714a0a38',
    origin: GF_SOURCE,
    url: GF_URL + 'chauphilomeneone/v10/55xzezRsPtfie1vPY49qzdgSlJiHRQFcnoZ_YscCdXQB.ttf',
    variant: 'italic',
    family: 'Chau Philomene One',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '2b61c4d1-3933-4d08-b902-a19cd9346bae',
    origin: GF_SOURCE,
    url: GF_URL + 'chauphilomeneone/v10/55xxezRsPtfie1vPY49qzdgSlJiHRQFsnIx7QMISdQ.ttf',
    variant: 'regular',
    family: 'Chau Philomene One',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b6e8a782-675f-4b1e-8949-f8ea1ebe5c62',
    origin: GF_SOURCE,
    url: GF_URL + 'mirza/v10/co3FmWlikiN5EtJhB-O4mafBomDi.ttf',
    variant: '700',
    family: 'Mirza',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '274405ee-9ba3-4679-8050-535f4fb5f21d',
    origin: GF_SOURCE,
    url: GF_URL + 'mirza/v10/co3FmWlikiN5EtIFBuO4mafBomDi.ttf',
    variant: '600',
    family: 'Mirza',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '67d1204f-2e85-4116-8336-c247e61b372b',
    origin: GF_SOURCE,
    url: GF_URL + 'mirza/v10/co3FmWlikiN5EtIpAeO4mafBomDi.ttf',
    variant: '500',
    family: 'Mirza',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8eda7962-bb1b-410d-8769-fb6bcac48df9',
    origin: GF_SOURCE,
    url: GF_URL + 'mirza/v10/co3ImWlikiN5EurdKMewsrvI.ttf',
    variant: 'regular',
    family: 'Mirza',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '458f1ad6-d61d-4846-bbb1-10ef5d3106b9',
    origin: GF_SOURCE,
    url: GF_URL + 'battambang/v14/uk-lEGe7raEw-HjkzZabNsmMxyRa8oZK9I0.ttf',
    variant: '700',
    family: 'Battambang',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'e42031b9-839f-49b9-afa4-bc48186eecda',
    origin: GF_SOURCE,
    url: GF_URL + 'battambang/v14/uk-mEGe7raEw-HjkzZabDnWj4yxx7o8.ttf',
    variant: 'regular',
    family: 'Battambang',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '62df7413-2e42-4226-8a62-d8361b63e6a2',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'oleoscriptswashcaps/v8/Noag6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HCcaBbYUsn9T5dt0.ttf',
    variant: '700',
    family: 'Oleo Script Swash Caps',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'eb79b8ce-d6a1-4ee5-ba30-70b5a00fcac5',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'oleoscriptswashcaps/v8/Noaj6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HMXquSY0Hg90.ttf',
    variant: 'regular',
    family: 'Oleo Script Swash Caps',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e0f78099-226c-49c6-9815-7ce4613b8dab',
    origin: GF_SOURCE,
    url: GF_URL + 'fanwoodtext/v10/3XFzErwl05Ad_vSCF6Fq7xX2R9zc9vhCblye.ttf',
    variant: 'italic',
    family: 'Fanwood Text',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'f513e4c1-b935-4c0c-9ae1-3bc5f5aee7e8',
    origin: GF_SOURCE,
    url: GF_URL + 'fanwoodtext/v10/3XFtErwl05Ad_vSCF6Fq7xXGRdbY1P1Sbg.ttf',
    variant: 'regular',
    family: 'Fanwood Text',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '638eb370-d609-4435-a747-d6674643bae1',
    origin: GF_SOURCE,
    url: GF_URL + 'chonburi/v5/8AtqGs-wOpGRTBq66IWaFr3biAfZ.ttf',
    variant: 'regular',
    family: 'Chonburi',
    category: 'display',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f8bbd06c-f048-4966-a88d-98b16351b0b2',
    origin: GF_SOURCE,
    url: GF_URL + 'cevicheone/v11/gyB4hws1IcA6JzR-GB_JX6zdZ4vZVbgZ.ttf',
    variant: 'regular',
    family: 'Ceviche One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '4285149f-158f-4ca7-9a5b-d6f8e586e8bf',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantsc/v11/0ybmGD4kxqXBmOVLG30OGwsmEBUU_R3y8DOWGA.ttf',
    variant: '700',
    family: 'Cormorant SC',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '94e12c76-b6a9-4b78-948f-327c24be629d',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantsc/v11/0ybmGD4kxqXBmOVLG30OGwsmdBQU_R3y8DOWGA.ttf',
    variant: '600',
    family: 'Cormorant SC',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c5776f31-ce33-42e7-91e4-1eb9a5ffa847',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantsc/v11/0ybmGD4kxqXBmOVLG30OGwsmWBMU_R3y8DOWGA.ttf',
    variant: '500',
    family: 'Cormorant SC',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'bf3ae06b-639b-4ba1-ab46-bd0c197e5822',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantsc/v11/0yb5GD4kxqXBmOVLG30OGwserDow9Tbu-Q.ttf',
    variant: 'regular',
    family: 'Cormorant SC',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '2ca6b7d9-99be-4f30-8adb-dc92446090c7',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantsc/v11/0ybmGD4kxqXBmOVLG30OGwsmABIU_R3y8DOWGA.ttf',
    variant: '300',
    family: 'Cormorant SC',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '4ffa86d6-5b8d-413c-b866-c88b2561d1a4',
    origin: GF_SOURCE,
    url: GF_URL + 'rocknrollone/v4/kmK7ZqspGAfCeUiW6FFlmEC9guVhs7tfUxc.ttf',
    variant: 'regular',
    family: 'RocknRoll One',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '7a4770ff-228a-4a67-a686-713133d78688',
    origin: GF_SOURCE,
    url: GF_URL + 'convergence/v9/rax5HiePvdgXPmmMHcIPYRhasU7Q8Cad.ttf',
    variant: 'regular',
    family: 'Convergence',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '1fa0bfc9-e6e3-4ca5-b8b5-eb759d7465fc',
    origin: GF_SOURCE,
    url: GF_URL + 'anticdidone/v9/RWmPoKKX6u8sp8fIWdnDKqDiqYsGBGBzCw.ttf',
    variant: 'regular',
    family: 'Antic Didone',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'dba1117a-c7e4-48bb-868a-4521c3d9ea5d',
    origin: GF_SOURCE,
    url: GF_URL + 'rammettoone/v9/LhWiMV3HOfMbMetJG3lQDpp9Mvuciu-_SQ.ttf',
    variant: 'regular',
    family: 'Rammetto One',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'a8aaedf1-4ee5-4ea1-8e5f-9dc3fe87feeb',
    origin: GF_SOURCE,
    url: GF_URL + 'homenaje/v10/FwZY7-Q-xVAi_l-6Ld6A4sijpFu_.ttf',
    variant: 'regular',
    family: 'Homenaje',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '61bba6ff-cc24-41f9-94a2-67c41f25dffb',
    origin: GF_SOURCE,
    url: GF_URL + 'blinker/v4/cIf4MaFatEE-VTaP_K2GRGEsnIJkWL4.ttf',
    variant: '900',
    family: 'Blinker',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '37a439b7-476a-4f6e-80cb-83e8d1d349ec',
    origin: GF_SOURCE,
    url: GF_URL + 'blinker/v4/cIf4MaFatEE-VTaP_ImHRGEsnIJkWL4.ttf',
    variant: '800',
    family: 'Blinker',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '3a470633-f94d-4e49-aabc-a1d0164e4a5b',
    origin: GF_SOURCE,
    url: GF_URL + 'blinker/v4/cIf4MaFatEE-VTaP_JWERGEsnIJkWL4.ttf',
    variant: '700',
    family: 'Blinker',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '225b8b8f-7445-4e96-9776-19ec65154c1c',
    origin: GF_SOURCE,
    url: GF_URL + 'blinker/v4/cIf4MaFatEE-VTaP_PGFRGEsnIJkWL4.ttf',
    variant: '600',
    family: 'Blinker',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'fdb14408-06b9-454b-bfc4-9e0e1b34ff43',
    origin: GF_SOURCE,
    url: GF_URL + 'blinker/v4/cIf9MaFatEE-VTaPxCmrYGkHgIs.ttf',
    variant: 'regular',
    family: 'Blinker',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '4d6c76fe-4fdd-467d-8260-c140527c21ab',
    origin: GF_SOURCE,
    url: GF_URL + 'blinker/v4/cIf4MaFatEE-VTaP_IWDRGEsnIJkWL4.ttf',
    variant: '300',
    family: 'Blinker',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '116ba8ff-9969-4216-bd01-2b33c7168e06',
    origin: GF_SOURCE,
    url: GF_URL + 'blinker/v4/cIf4MaFatEE-VTaP_OGARGEsnIJkWL4.ttf',
    variant: '200',
    family: 'Blinker',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'b9f8d4da-6f78-4f02-8430-ac0ba50bc590',
    origin: GF_SOURCE,
    url: GF_URL + 'blinker/v4/cIf_MaFatEE-VTaP_E2hZEsCkIt9QQ.ttf',
    variant: '100',
    family: 'Blinker',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'cbde9108-4de8-48fc-8387-da759774166d',
    origin: GF_SOURCE,
    url: GF_URL + 'baloothambi2/v2/cY9ffjeOW0NHpmOQXranrbDyu7DnKLe5Th_gRA7L.ttf',
    variant: '800',
    family: 'Baloo Thambi 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '334ad7f0-c9b4-44d3-a044-1e66a048df41',
    origin: GF_SOURCE,
    url: GF_URL + 'baloothambi2/v2/cY9ffjeOW0NHpmOQXranrbDyu7D7K7e5Th_gRA7L.ttf',
    variant: '700',
    family: 'Baloo Thambi 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '924f9654-35fe-45c6-94ff-ba8cfc37cef6',
    origin: GF_SOURCE,
    url: GF_URL + 'baloothambi2/v2/cY9ffjeOW0NHpmOQXranrbDyu7CfKre5Th_gRA7L.ttf',
    variant: '600',
    family: 'Baloo Thambi 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'fecba3cf-6d85-4f10-ae3a-c4d42132535c',
    origin: GF_SOURCE,
    url: GF_URL + 'baloothambi2/v2/cY9ffjeOW0NHpmOQXranrbDyu7CzLbe5Th_gRA7L.ttf',
    variant: '500',
    family: 'Baloo Thambi 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '0eaff40e-c3d8-488d-b5db-b9251e2809e9',
    origin: GF_SOURCE,
    url: GF_URL + 'baloothambi2/v2/cY9cfjeOW0NHpmOQXranrbDyu4hHBJOxZQPp.ttf',
    variant: 'regular',
    family: 'Baloo Thambi 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '8987ec0a-6a12-4b31-9c59-6195a3eb6ee8',
    origin: GF_SOURCE,
    url: GF_URL + 'delagothicone/v1/hESp6XxvMDRA-2eD0lXpDa6QkBAGRUsJQAlbUA.ttf',
    variant: 'regular',
    family: 'Dela Gothic One',
    category: 'display',
    version: 'v1',
    subset: 'latin',
  },
  {
    id: '799f0be0-48bb-4693-9ee2-4c7831fda524',
    origin: GF_SOURCE,
    url: GF_URL + 'bellefair/v6/kJExBuYY6AAuhiXUxG19__A2pOdvDA.ttf',
    variant: 'regular',
    family: 'Bellefair',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '3874bef9-5742-4387-8410-3df8664df6f8',
    origin: GF_SOURCE,
    url: GF_URL + 'mate/v9/m8JTjftRd7WZ6z-2XqfXeLVdbw.ttf',
    variant: 'italic',
    family: 'Mate',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'd55667c4-0017-4ab4-9598-4d89ba026410',
    origin: GF_SOURCE,
    url: GF_URL + 'mate/v9/m8JdjftRd7WZ2z28WoXSaLU.ttf',
    variant: 'regular',
    family: 'Mate',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'e37ef440-0da6-4e09-8572-aed7672898bd',
    origin: GF_SOURCE,
    url: GF_URL + 'montaga/v8/H4cnBX2Ml8rCkEO_0gYQ7LO5mqc.ttf',
    variant: 'regular',
    family: 'Montaga',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '11b414f3-11ac-4085-aec2-a39ea0049293',
    origin: GF_SOURCE,
    url: GF_URL + 'sueellenfrancisco/v11/wXK3E20CsoJ9j1DDkjHcQ5ZL8xRaxru9ropF2lqk9H4.ttf',
    variant: 'regular',
    family: 'Sue Ellen Francisco',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '32b7bf3a-6421-4ac9-9cbf-2d9e8fa6b678',
    origin: GF_SOURCE,
    url: GF_URL + 'gravitasone/v10/5h1diZ4hJ3cblKy3LWakKQmaDWRNr3DzbQ.ttf',
    variant: 'regular',
    family: 'Gravitas One',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '78366929-07e7-464a-8dcf-8e7febedde0c',
    origin: GF_SOURCE,
    url: GF_URL + 'sofia/v9/8QIHdirahM3j_vu-sowsrqjk.ttf',
    variant: 'regular',
    family: 'Sofia',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '300fb615-d213-4df2-a5a6-9fad5b8bc369',
    origin: GF_SOURCE,
    url: GF_URL + 'chelseamarket/v8/BCawqZsHqfr89WNP_IApC8tzKBhlLA4uKkWk.ttf',
    variant: 'regular',
    family: 'Chelsea Market',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd850a90f-f090-4020-aa07-e0a37a8f8f8e',
    origin: GF_SOURCE,
    url: GF_URL + 'labelleaurore/v11/RrQIbot8-mNYKnGNDkWlocovHeIIG-eFNVmULg.ttf',
    variant: 'regular',
    family: 'La Belle Aurore',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '85ff4ca9-bf10-46e2-a2f3-f9bf85de29b5',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'goudybookletter1911/v10/sykt-z54laciWfKv-kX8krex0jDiD2HbY6I5tRbXZ4IXAA.ttf',
    variant: 'regular',
    family: 'Goudy Bookletter 1911',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'dc10dc40-0df8-47eb-b509-4e1fb850a410',
    origin: GF_SOURCE,
    url: GF_URL + 'mandali/v9/LhWlMVbYOfASNfNUVFk1ZPdcKtA.ttf',
    variant: 'regular',
    family: 'Mandali',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'ae85f17f-8036-42cc-918c-f2c5197b85da',
    origin: GF_SOURCE,
    url: GF_URL + 'imfellenglish/v10/Ktk3ALSLW8zDe0rthJysWrnLsAzHFaOdd4pI59zg.ttf',
    variant: 'italic',
    family: 'IM Fell English',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '0a803bac-8c25-40d3-913f-07c242ac7578',
    origin: GF_SOURCE,
    url: GF_URL + 'imfellenglish/v10/Ktk1ALSLW8zDe0rthJysWrnLsAz3F6mZVY9Y5w.ttf',
    variant: 'regular',
    family: 'IM Fell English',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4febacf6-57d5-4da0-90b7-b8292195ff8f',
    origin: GF_SOURCE,
    url: GF_URL + 'lekton/v11/SZc73FDmLaWmWpBm4zjMlWjX4DJXgQ.ttf',
    variant: '700',
    family: 'Lekton',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '6f7acec2-c588-4deb-af01-b9f4f0d23761',
    origin: GF_SOURCE,
    url: GF_URL + 'lekton/v11/SZc63FDmLaWmWpBuXR3sv0bb6StO.ttf',
    variant: 'italic',
    family: 'Lekton',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'd099ce2f-f12a-4e27-a8df-88f376c1ce39',
    origin: GF_SOURCE,
    url: GF_URL + 'lekton/v11/SZc43FDmLaWmWpBeXxfonUPL6Q.ttf',
    variant: 'regular',
    family: 'Lekton',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '07eeef8e-732e-4460-be61-482742798159',
    origin: GF_SOURCE,
    url: GF_URL + 'syne/v3/8vIS7w4qzmVxsWxjBZRjr0FKM_24vj6kR47NCV5Z.ttf',
    variant: '800',
    family: 'Syne',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'a6c88639-b38f-4626-93f5-424afa0ba428',
    origin: GF_SOURCE,
    url: GF_URL + 'syne/v3/8vIS7w4qzmVxsWxjBZRjr0FKM_3fvj6kR47NCV5Z.ttf',
    variant: '700',
    family: 'Syne',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'e4d30607-1a8e-4d29-940b-ec0f910a5975',
    origin: GF_SOURCE,
    url: GF_URL + 'syne/v3/8vIS7w4qzmVxsWxjBZRjr0FKM_3mvj6kR47NCV5Z.ttf',
    variant: '600',
    family: 'Syne',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'ad27671b-20a4-44b3-aa83-59d4eb63e2af',
    origin: GF_SOURCE,
    url: GF_URL + 'syne/v3/8vIS7w4qzmVxsWxjBZRjr0FKM_0KuT6kR47NCV5Z.ttf',
    variant: '500',
    family: 'Syne',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '913dca47-fe55-411a-8c76-133ca11bf494',
    origin: GF_SOURCE,
    url: GF_URL + 'syne/v3/8vIS7w4qzmVxsWxjBZRjr0FKM_04uT6kR47NCV5Z.ttf',
    variant: 'regular',
    family: 'Syne',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '5e4df86c-73a6-4be9-9c95-3f7ad4657790',
    origin: GF_SOURCE,
    url: GF_URL + 'davidlibre/v5/snfzs0W_99N64iuYSvp4W8HAxbqbSjORSo9W.ttf',
    variant: '700',
    family: 'David Libre',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'c78d8e23-2170-43b3-baab-3a9927fc400c',
    origin: GF_SOURCE,
    url: GF_URL + 'davidlibre/v5/snfzs0W_99N64iuYSvp4W8GIw7qbSjORSo9W.ttf',
    variant: '500',
    family: 'David Libre',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f4fab2e7-fa00-40ad-9ccb-7ae8465fb4fd',
    origin: GF_SOURCE,
    url: GF_URL + 'davidlibre/v5/snfus0W_99N64iuYSvp4W_l86p6TYS-Y.ttf',
    variant: 'regular',
    family: 'David Libre',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '58174673-1dd0-4fb4-9176-28ffe8663ea4',
    origin: GF_SOURCE,
    url: GF_URL + 'manjari/v4/k3kVo8UPMOBO2w1UdWLNC0HrLaqM6Q4.ttf',
    variant: '700',
    family: 'Manjari',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '826055bf-1da9-487a-b3e6-8b0c16f0772f',
    origin: GF_SOURCE,
    url: GF_URL + 'manjari/v4/k3kQo8UPMOBO2w1UTd7iL0nAMaM.ttf',
    variant: 'regular',
    family: 'Manjari',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '863773a3-beed-4f34-be55-17332b438c78',
    origin: GF_SOURCE,
    url: GF_URL + 'manjari/v4/k3kSo8UPMOBO2w1UdbroK2vFIaOV8A.ttf',
    variant: '100',
    family: 'Manjari',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '20b7bbfc-cb69-4d10-96b5-04283c64c313',
    origin: GF_SOURCE,
    url: GF_URL + 'kellyslab/v11/-W_7XJX0Rz3cxUnJC5t6TkMBf50kbiM.ttf',
    variant: 'regular',
    family: 'Kelly Slab',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '9c768b03-2b16-47eb-8dd1-383a185ef5d0',
    origin: GF_SOURCE,
    url: GF_URL + 'mrssaintdelafield/v8/v6-IGZDIOVXH9xtmTZfRagunqBw5WC62cK4tLsubB2w.ttf',
    variant: 'regular',
    family: 'Mrs Saint Delafield',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '0b735c98-b412-4d35-acf1-2279a17b5ee8',
    origin: GF_SOURCE,
    url: GF_URL + 'buenard/v12/OD5GuM6Cyma8FnnsB4vSjGCWALepwss.ttf',
    variant: '700',
    family: 'Buenard',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '18bd955f-bcb4-4cb7-860d-b8c1a55b5fe2',
    origin: GF_SOURCE,
    url: GF_URL + 'buenard/v12/OD5DuM6Cyma8FnnsPzf9qGi9HL4.ttf',
    variant: 'regular',
    family: 'Buenard',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '621a7f95-0033-4f08-a72a-4989ad667c32',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grandstander/v4/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9TjzcsvNDiQlBYQ.ttf',
    variant: '900italic',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a4dff8c2-70ae-4f68-882a-cfa8f3de79d1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grandstander/v4/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9ZzzcsvNDiQlBYQ.ttf',
    variant: '800italic',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '89fa577e-85ab-4310-a165-2df3c07d9f98',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grandstander/v4/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9ADzcsvNDiQlBYQ.ttf',
    variant: '700italic',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'fc59ff62-c0e1-4f5b-a74d-086ba89290f4',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grandstander/v4/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9OTzcsvNDiQlBYQ.ttf',
    variant: '600italic',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd9f10b92-692c-404d-a200-2c41d4409131',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grandstander/v4/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf91TvcsvNDiQlBYQ.ttf',
    variant: '500italic',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'f746a0d8-1e2c-404f-b237-24041471297d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grandstander/v4/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf95zvcsvNDiQlBYQ.ttf',
    variant: 'italic',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '211fd97a-deee-43de-a4ca-9ecd11967e4f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grandstander/v4/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9uTvcsvNDiQlBYQ.ttf',
    variant: '300italic',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '9cc543bd-6c01-46b1-8b4e-0538d39c570f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grandstander/v4/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9ZzvcsvNDiQlBYQ.ttf',
    variant: '200italic',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'af6131c9-afc2-4540-b9bb-1e6ed45b4414',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'grandstander/v4/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf95zrcsvNDiQlBYQ.ttf',
    variant: '100italic',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '113fb041-d402-469c-afa8-6ea4250841e3',
    origin: GF_SOURCE,
    url:
      GF_URL + 'grandstander/v4/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD_a5D3jWttFGmQk.ttf',
    variant: '900',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '82981aae-9c89-4d33-90b5-c4c133cd52f3',
    origin: GF_SOURCE,
    url:
      GF_URL + 'grandstander/v4/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD9-5D3jWttFGmQk.ttf',
    variant: '800',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '1efa253a-080e-4dae-8416-33204fa6f108',
    origin: GF_SOURCE,
    url:
      GF_URL + 'grandstander/v4/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD7i5D3jWttFGmQk.ttf',
    variant: '700',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ac0761d4-644a-4e66-a46f-afce88ea2d90',
    origin: GF_SOURCE,
    url:
      GF_URL + 'grandstander/v4/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD4G5D3jWttFGmQk.ttf',
    variant: '600',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '26532afd-aac1-4e20-83c9-cd3040d947c2',
    origin: GF_SOURCE,
    url:
      GF_URL + 'grandstander/v4/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD22-D3jWttFGmQk.ttf',
    variant: '500',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '6777cd06-6c2b-48f5-bf6b-6979f5288c5a',
    origin: GF_SOURCE,
    url:
      GF_URL + 'grandstander/v4/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD1--D3jWttFGmQk.ttf',
    variant: 'regular',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'fad8cae1-21ec-4dce-b1db-50c1d6ad5bec',
    origin: GF_SOURCE,
    url:
      GF_URL + 'grandstander/v4/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQDwG-D3jWttFGmQk.ttf',
    variant: '300',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd7f59535-3652-4ecd-9e60-fc73f9af390f',
    origin: GF_SOURCE,
    url:
      GF_URL + 'grandstander/v4/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD9--D3jWttFGmQk.ttf',
    variant: '200',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '5ff79ca0-afa8-423a-9b45-de5885134ba0',
    origin: GF_SOURCE,
    url:
      GF_URL + 'grandstander/v4/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD1-_D3jWttFGmQk.ttf',
    variant: '100',
    family: 'Grandstander',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '92834d27-9be1-44db-94fa-5ae6329cd8b3',
    origin: GF_SOURCE,
    url: GF_URL + 'titanone/v8/mFTzWbsGxbbS_J5cQcjykzIn2Etikg.ttf',
    variant: 'regular',
    family: 'Titan One',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c4b6707d-55e1-4f30-9d7a-4d7d8d3e5eb1',
    origin: GF_SOURCE,
    url: GF_URL + 'balthazar/v10/d6lKkaajS8Gm4CVQjFEvyRTo39l8hw.ttf',
    variant: 'regular',
    family: 'Balthazar',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '61ab05ca-df3c-44d0-93db-1188880ecf76',
    origin: GF_SOURCE,
    url: GF_URL + 'emilyscandy/v8/2EbgL-1mD1Rnb0OGKudbk0y5r9xrX84JjA.ttf',
    variant: 'regular',
    family: 'Emilys Candy',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '09104c67-fbe5-4882-b20a-118abddefc3f',
    origin: GF_SOURCE,
    url: GF_URL + 'esteban/v9/r05bGLZE-bdGdN-GdOuD5jokU8E.ttf',
    variant: 'regular',
    family: 'Esteban',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '42599093-e2b4-42aa-a796-7bbb9a1a31be',
    origin: GF_SOURCE,
    url: GF_URL + 'carme/v11/ptRHTiWdbvZIDOjGxLNrxfbZ.ttf',
    variant: 'regular',
    family: 'Carme',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '1010f721-575e-47dd-aa43-5aadd8c98c59',
    origin: GF_SOURCE,
    url: GF_URL + 'alike/v13/HI_EiYEYI6BIoEjBSZXAQ4-d.ttf',
    variant: 'regular',
    family: 'Alike',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '95d89792-84ea-4fc1-9acd-66da8b6a505d',
    origin: GF_SOURCE,
    url: GF_URL + 'gabriela/v9/qkBWXvsO6sreR8E-b_m-zrpHmRzC.ttf',
    variant: 'regular',
    family: 'Gabriela',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '394da17d-751e-4cfd-ba27-2e8f10897275',
    origin: GF_SOURCE,
    url: GF_URL + 'cutive/v12/NaPZcZ_fHOhV3Ip7T_hDoyqlZQ.ttf',
    variant: 'regular',
    family: 'Cutive',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'f492b82c-ec0f-4791-815e-93aef6fe8c53',
    origin: GF_SOURCE,
    url: GF_URL + 'rougescript/v9/LYjFdGbiklMoCIQOw1Ep3S4PVPXbUJWq9g.ttf',
    variant: 'regular',
    family: 'Rouge Script',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '455709fe-e0bf-4f5f-b2c1-b6a99cb11313',
    origin: GF_SOURCE,
    url: GF_URL + 'wendyone/v9/2sDcZGJOipXfgfXV5wgDb2-4C7wFZQ.ttf',
    variant: 'regular',
    family: 'Wendy One',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '8fd747a1-eec0-4189-a34d-f7d48c767678',
    origin: GF_SOURCE,
    url: GF_URL + 'frijole/v9/uU9PCBUR8oakM2BQ7xPb3vyHmlI.ttf',
    variant: 'regular',
    family: 'Frijole',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '0d34f989-7595-493c-8148-56ad03c66e0f',
    origin: GF_SOURCE,
    url: GF_URL + 'turretroad/v2/pxidypMgpcBFjE84Zv-fE0PlFteLYk1Mq3ap.ttf',
    variant: '800',
    family: 'Turret Road',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'c81718ba-4e07-4879-9ef9-5cca9f42f197',
    origin: GF_SOURCE,
    url: GF_URL + 'turretroad/v2/pxidypMgpcBFjE84Zv-fE0P5FdeLYk1Mq3ap.ttf',
    variant: '700',
    family: 'Turret Road',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'e2ae4eab-6bb8-4e1b-ba20-23ae966daffa',
    origin: GF_SOURCE,
    url: GF_URL + 'turretroad/v2/pxidypMgpcBFjE84Zv-fE0OxE9eLYk1Mq3ap.ttf',
    variant: '500',
    family: 'Turret Road',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'dde2b1b9-f39a-4fa6-98f4-4f3406c407b9',
    origin: GF_SOURCE,
    url: GF_URL + 'turretroad/v2/pxiAypMgpcBFjE84Zv-fE3tFOvODSVFF.ttf',
    variant: 'regular',
    family: 'Turret Road',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '546de489-ec2a-4176-ae98-b4f6f8ac5360',
    origin: GF_SOURCE,
    url: GF_URL + 'turretroad/v2/pxidypMgpcBFjE84Zv-fE0PpEteLYk1Mq3ap.ttf',
    variant: '300',
    family: 'Turret Road',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '102e036b-d1eb-4321-b86a-7e18807f76f7',
    origin: GF_SOURCE,
    url: GF_URL + 'turretroad/v2/pxidypMgpcBFjE84Zv-fE0ONEdeLYk1Mq3ap.ttf',
    variant: '200',
    family: 'Turret Road',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'd09a07d7-2909-4578-a901-d0036599b6fb',
    origin: GF_SOURCE,
    url: GF_URL + 'maitree/v5/MjQDmil5tffhpBrklmWJWJGovLdh6OE.ttf',
    variant: '700',
    family: 'Maitree',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ffcfe0fb-0752-4771-a89e-7ddfd885cdf3',
    origin: GF_SOURCE,
    url: GF_URL + 'maitree/v5/MjQDmil5tffhpBrklgGIWJGovLdh6OE.ttf',
    variant: '600',
    family: 'Maitree',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e636df51-2946-482f-aa5a-ca46cea23451',
    origin: GF_SOURCE,
    url: GF_URL + 'maitree/v5/MjQDmil5tffhpBrkli2PWJGovLdh6OE.ttf',
    variant: '500',
    family: 'Maitree',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b9fe20c5-b4b0-42ae-a212-2b29ff79922c',
    origin: GF_SOURCE,
    url: GF_URL + 'maitree/v5/MjQGmil5tffhpBrkrtmmfJmDoL4.ttf',
    variant: 'regular',
    family: 'Maitree',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '0270b1d7-28e1-40df-ac8c-771f13d38252',
    origin: GF_SOURCE,
    url: GF_URL + 'maitree/v5/MjQDmil5tffhpBrklnWOWJGovLdh6OE.ttf',
    variant: '300',
    family: 'Maitree',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e80943db-71e0-4a96-95fd-f8785c063edb',
    origin: GF_SOURCE,
    url: GF_URL + 'maitree/v5/MjQDmil5tffhpBrklhGNWJGovLdh6OE.ttf',
    variant: '200',
    family: 'Maitree',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'dce42a1e-7059-4c67-991d-1eedb5866545',
    origin: GF_SOURCE,
    url:
      GF_URL + 'cormorantinfant/v11/HhyKU44g9vKiM1sORYSiWeAsLN997_ItYDYhRoUYNrn_Ig.ttf',
    variant: '700italic',
    family: 'Cormorant Infant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '50b04da4-e336-4836-994b-6476a36814b8',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantinfant/v11/HhyIU44g9vKiM1sORYSiWeAsLN9950ww_DMrQqcdJrk.ttf',
    variant: '700',
    family: 'Cormorant Infant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'e45b393b-68a0-438e-b07d-1d590cabe6c8',
    origin: GF_SOURCE,
    url:
      GF_URL + 'cormorantinfant/v11/HhyKU44g9vKiM1sORYSiWeAsLN997_ItBDchRoUYNrn_Ig.ttf',
    variant: '600italic',
    family: 'Cormorant Infant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8e7b13ce-a29a-4805-8264-1287cec29ec5',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantinfant/v11/HhyIU44g9vKiM1sORYSiWeAsLN995ygx_DMrQqcdJrk.ttf',
    variant: '600',
    family: 'Cormorant Infant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '36a9a891-28e4-4333-8600-35b3c9aa79ed',
    origin: GF_SOURCE,
    url:
      GF_URL + 'cormorantinfant/v11/HhyKU44g9vKiM1sORYSiWeAsLN997_ItKDAhRoUYNrn_Ig.ttf',
    variant: '500italic',
    family: 'Cormorant Infant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8b1e81c7-8ea1-4ef4-bc37-d0ef4b726c4a',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantinfant/v11/HhyIU44g9vKiM1sORYSiWeAsLN995wQ2_DMrQqcdJrk.ttf',
    variant: '500',
    family: 'Cormorant Infant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'dd7f0c52-e308-4847-813c-f7b9350f07fd',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantinfant/v11/HhyJU44g9vKiM1sORYSiWeAsLN997_IV3BkFTq4EPw.ttf',
    variant: 'italic',
    family: 'Cormorant Infant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a7db5c04-fb41-4bea-a343-9c33570c9613',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantinfant/v11/HhyPU44g9vKiM1sORYSiWeAsLN993_Af2DsAXq4.ttf',
    variant: 'regular',
    family: 'Cormorant Infant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '2934cd5a-80e7-48e8-bc2c-769e53b5a5bd',
    origin: GF_SOURCE,
    url:
      GF_URL + 'cormorantinfant/v11/HhyKU44g9vKiM1sORYSiWeAsLN997_ItcDEhRoUYNrn_Ig.ttf',
    variant: '300italic',
    family: 'Cormorant Infant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '35174e74-8b47-4f03-a826-479e1c0d2560',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantinfant/v11/HhyIU44g9vKiM1sORYSiWeAsLN9951w3_DMrQqcdJrk.ttf',
    variant: '300',
    family: 'Cormorant Infant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0dbd1ecf-0a2c-4076-ba3d-d08d71a7ae93',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7acnpF2V0EjdZ2JkpY4xJ9CGyAa.ttf',
    variant: '800italic',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '15df8ba7-cf08-4ab8-a31b-e92475fba38d',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7aenpF2V0Er14MJlJw85ppSGw.ttf',
    variant: '800',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ab9ffbe8-20a0-4ef3-927c-fd27885c7d0e',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7acnpF2V0EjdZ2VkZY4xJ9CGyAa.ttf',
    variant: '700italic',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '116d9d4f-daa2-4702-be0a-414f01941e3b',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7aenpF2V0Ery4AJlJw85ppSGw.ttf',
    variant: '700',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '03ee35b0-5707-4848-bd9e-2185fee5b7d4',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7acnpF2V0EjdZ3xkJY4xJ9CGyAa.ttf',
    variant: '600italic',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '67ed54bf-04fe-4dd5-b7fa-30af0003cfae',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7aenpF2V0Err4EJlJw85ppSGw.ttf',
    variant: '600',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '0274857e-52d7-46be-9bdd-af2593b7ac9c',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7acnpF2V0EjdZ3dl5Y4xJ9CGyAa.ttf',
    variant: '500italic',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '0dcb1c4f-51f3-4e05-83bc-3777a66701f8',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7aenpF2V0Erg4YJlJw85ppSGw.ttf',
    variant: '500',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '24b41723-18c3-41c1-951b-c7690d9e9aac',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7aRnpF2V0EjdaUpvrIw74NL.ttf',
    variant: 'italic',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '4975bb4f-68ec-4fd8-b47a-2f4b4978d404',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7aTnpF2V0ETd68tnLcg7w.ttf',
    variant: 'regular',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'fca6c7ce-85af-49be-9823-48dec1a03bd8',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7acnpF2V0EjdZ2FlpY4xJ9CGyAa.ttf',
    variant: '300italic',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '12f4de7c-4957-4aad-8ce6-b0a3c92822c1',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7aenpF2V0Er24cJlJw85ppSGw.ttf',
    variant: '300',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '926f526a-bad0-4e65-9823-0eb99012768d',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7acnpF2V0EjdZ3hlZY4xJ9CGyAa.ttf',
    variant: '200italic',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '6cbaeb47-e018-4957-af11-2e2a6a1901b0',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7aenpF2V0Erv4QJlJw85ppSGw.ttf',
    variant: '200',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '9de355bb-49c3-47ea-9127-27ceed8a2679',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7afnpF2V0EjdZ1NtLYS6pNLAjk.ttf',
    variant: '100italic',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd31101cf-746b-428f-9c73-7207bc0a28d2',
    origin: GF_SOURCE,
    url: GF_URL + 'k2d/v4/J7aRnpF2V0ErE6UpvrIw74NL.ttf',
    variant: '100',
    family: 'K2D',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '015e5e94-6306-4359-a293-df3c2614de29',
    origin: GF_SOURCE,
    url: GF_URL + 'radley/v15/LYjBdGzinEIjCN1NogNAh14nVcfe.ttf',
    variant: 'italic',
    family: 'Radley',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '13a261e1-99ec-4f62-ab0d-43e80af1f421',
    origin: GF_SOURCE,
    url: GF_URL + 'radley/v15/LYjDdGzinEIjCN19oAlEpVs3VQ.ttf',
    variant: 'regular',
    family: 'Radley',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '123afefa-6ccf-4373-8056-3828578903cf',
    origin: GF_SOURCE,
    url: GF_URL + 'harmattan/v11/gokpH6L2DkFvVvRp9Xpr92-HmNZEq6TTFw.ttf',
    variant: '700',
    family: 'Harmattan',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '5164c91c-3065-4567-8cc7-6ba6068722a3',
    origin: GF_SOURCE,
    url: GF_URL + 'harmattan/v11/goksH6L2DkFvVvRp9XpTS0CjkP1Yog.ttf',
    variant: 'regular',
    family: 'Harmattan',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f90c8493-a2a1-4463-ba50-5d66cbc7558e',
    origin: GF_SOURCE,
    url: GF_URL + 'hanuman/v14/VuJ0dNvD15HhpJJBQBr4HIlMZRNcp0o.ttf',
    variant: '700',
    family: 'Hanuman',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '13bea7fa-4267-4728-bb05-34e0cc5d0dc2',
    origin: GF_SOURCE,
    url: GF_URL + 'hanuman/v14/VuJxdNvD15HhpJJBeKbXOIFneRo.ttf',
    variant: 'regular',
    family: 'Hanuman',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '758aacf7-0fe9-4c6b-a472-6aef54effece',
    origin: GF_SOURCE,
    url: GF_URL + 'unifrakturmaguntia/v11/WWXPlieVYwiGNomYU-ciRLRvEmK7oaVun2xNNgNa1A.ttf',
    variant: 'regular',
    family: 'UnifrakturMaguntia',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '2d06b986-5e8d-4f06-ba2f-9273af67a9ac',
    origin: GF_SOURCE,
    url: GF_URL + 'kosugi/v6/pxiFyp4_v8FCjlI4NLr6f1pdEQ.ttf',
    variant: 'regular',
    family: 'Kosugi',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'aa799063-49fc-4697-82fc-c62de03fe243',
    origin: GF_SOURCE,
    url: GF_URL + 'copse/v10/11hPGpDKz1rGb0djHkihUb-A.ttf',
    variant: 'regular',
    family: 'Copse',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '627ad34f-921a-442c-b704-114ade8233a5',
    origin: GF_SOURCE,
    url: GF_URL + 'podkova/v18/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWvFysU4EoporSHH.ttf',
    variant: '800',
    family: 'Podkova',
    category: 'serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '9aadd558-dbc8-42d0-85c4-5717e58a3df7',
    origin: GF_SOURCE,
    url: GF_URL + 'podkova/v18/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWuiysU4EoporSHH.ttf',
    variant: '700',
    family: 'Podkova',
    category: 'serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '757448d0-8bfe-43d9-93a1-fa3b1a43968d',
    origin: GF_SOURCE,
    url: GF_URL + 'podkova/v18/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWubysU4EoporSHH.ttf',
    variant: '600',
    family: 'Podkova',
    category: 'serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '9bf3b154-753f-41bd-b77a-f638c76c0af9',
    origin: GF_SOURCE,
    url: GF_URL + 'podkova/v18/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWt3zcU4EoporSHH.ttf',
    variant: '500',
    family: 'Podkova',
    category: 'serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '3732209a-e726-4e37-9e38-0edbb7ab0b4f',
    origin: GF_SOURCE,
    url: GF_URL + 'podkova/v18/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWtFzcU4EoporSHH.ttf',
    variant: 'regular',
    family: 'Podkova',
    category: 'serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '8fb36213-e0b5-489f-ada6-4a685f2d5e84',
    origin: GF_SOURCE,
    url: GF_URL + 'metrophobic/v14/sJoA3LZUhMSAPV_u0qwiAT-J737FPEEL.ttf',
    variant: 'regular',
    family: 'Metrophobic',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '9b64fc8f-e238-497b-af84-0629fa26572f',
    origin: GF_SOURCE,
    url: GF_URL + 'durusans/v14/xn7iYH8xwmSyTvEV_HOxT_fYdN-WZw.ttf',
    variant: 'regular',
    family: 'Duru Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '41f17ad4-e7a2-42a4-9b8d-8dac5e6d06a4',
    origin: GF_SOURCE,
    url: GF_URL + 'mrdehaviland/v9/OpNVnooIhJj96FdB73296ksbOj3C4ULVNTlB.ttf',
    variant: 'regular',
    family: 'Mr De Haviland',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '90ea579f-f377-4556-a159-f92940ee2c33',
    origin: GF_SOURCE,
    url:
      GF_URL + 'loveyalikeasister/v11/R70EjzUBlOqPeouhFDfR80-0FhOqJubN-Be78nZcsGGycA.ttf',
    variant: 'regular',
    family: 'Love Ya Like A Sister',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '97342d31-e851-41ab-8050-cde363fc24ce',
    origin: GF_SOURCE,
    url: GF_URL + 'slabo13px/v8/11hEGp_azEvXZUdSBzzRcKer2wkYnvI.ttf',
    variant: 'regular',
    family: 'Slabo 13px',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '6fc5440f-67e0-4ade-bf6a-27418ad5cea2',
    origin: GF_SOURCE,
    url: GF_URL + 'oxygenmono/v8/h0GsssGg9FxgDgCjLeAd7ijfze-PPlUu.ttf',
    variant: 'regular',
    family: 'Oxygen Mono',
    category: 'monospace',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e736f2e7-0471-4263-a9cf-7b4bbe9b8493',
    origin: GF_SOURCE,
    url: GF_URL + 'comicneue/v2/4UaarEJDsxBrF37olUeD96_RXp5UKylCNlcw_Q.ttf',
    variant: '700italic',
    family: 'Comic Neue',
    category: 'handwriting',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'c88b547e-6d3d-4163-816d-e0c9d3e6f7d3',
    origin: GF_SOURCE,
    url: GF_URL + 'comicneue/v2/4UaErEJDsxBrF37olUeD_xHMwpteLwtHJlc.ttf',
    variant: '700',
    family: 'Comic Neue',
    category: 'handwriting',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '567b5f15-c404-4894-bbb2-6923233e002a',
    origin: GF_SOURCE,
    url: GF_URL + 'comicneue/v2/4UaFrEJDsxBrF37olUeD96_p4rFwIwJePw.ttf',
    variant: 'italic',
    family: 'Comic Neue',
    category: 'handwriting',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'db7a6728-4976-4d7c-b00b-d34eecb542ab',
    origin: GF_SOURCE,
    url: GF_URL + 'comicneue/v2/4UaHrEJDsxBrF37olUeDx63j5pN1MwI.ttf',
    variant: 'regular',
    family: 'Comic Neue',
    category: 'handwriting',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '9981c015-f0f0-4ab3-9ef9-5aa0672844f3',
    origin: GF_SOURCE,
    url: GF_URL + 'comicneue/v2/4UaarEJDsxBrF37olUeD96_RTplUKylCNlcw_Q.ttf',
    variant: '300italic',
    family: 'Comic Neue',
    category: 'handwriting',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '351a309b-dc12-435e-b9b1-a4f6bc33889d',
    origin: GF_SOURCE,
    url: GF_URL + 'comicneue/v2/4UaErEJDsxBrF37olUeD_wHLwpteLwtHJlc.ttf',
    variant: '300',
    family: 'Comic Neue',
    category: 'handwriting',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '9e5aa817-e1ff-48e0-9a62-ffbc8d682025',
    origin: GF_SOURCE,
    url: GF_URL + 'alatsi/v2/TK3iWkUJAxQ2nLNGHjUHte5fKg.ttf',
    variant: 'regular',
    family: 'Alatsi',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'cc918e95-3eca-4527-a299-0dc3d4a77b94',
    origin: GF_SOURCE,
    url: GF_URL + 'kumbhsans/v5/c4mm1n92AsfhuCq6tVsagjt43KKVXUv8Los.ttf',
    variant: '700',
    family: 'Kumbh Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '834d6dd0-0adb-42f1-86b0-6e548a5938d9',
    origin: GF_SOURCE,
    url: GF_URL + 'kumbhsans/v5/c4ml1n92AsfhuCq6tVsauodX-Kq-QUI.ttf',
    variant: 'regular',
    family: 'Kumbh Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '160a1780-aee4-4c20-a725-e2f332db06ce',
    origin: GF_SOURCE,
    url: GF_URL + 'kumbhsans/v5/c4mm1n92AsfhuCq6tVsagit_3KKVXUv8Los.ttf',
    variant: '300',
    family: 'Kumbh Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '51f79426-62eb-4ce4-b1c1-c4c13171ded4',
    origin: GF_SOURCE,
    url: GF_URL + 'bubblegumsans/v9/AYCSpXb_Z9EORv1M5QTjEzMEtdaHzoPPb7R4.ttf',
    variant: 'regular',
    family: 'Bubblegum Sans',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '1184150f-7c9e-4494-aa8d-a48f796fd887',
    origin: GF_SOURCE,
    url: GF_URL + 'cedarvillecursive/v12/yYL00g_a2veiudhUmxjo5VKkoqA-B_neJbBxw8BeTg.ttf',
    variant: 'regular',
    family: 'Cedarville Cursive',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '8ad7cd25-d118-4195-9443-d7151153216e',
    origin: GF_SOURCE,
    url: GF_URL + 'wallpoet/v12/f0X10em2_8RnXVVdUNbu7cXP8L8G.ttf',
    variant: 'regular',
    family: 'Wallpoet',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'e78b31e5-4f84-4cea-9bbd-eccd4b16648a',
    origin: GF_SOURCE,
    url: GF_URL + 'athiti/v5/pe0sMISdLIZIv1wAsDdyAv2-C99ycg.ttf',
    variant: '700',
    family: 'Athiti',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '4d967335-be6c-43b0-b04c-f908c43476bc',
    origin: GF_SOURCE,
    url: GF_URL + 'athiti/v5/pe0sMISdLIZIv1wA1DZyAv2-C99ycg.ttf',
    variant: '600',
    family: 'Athiti',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '300a098c-e13c-4657-afb4-eefcd0f56933',
    origin: GF_SOURCE,
    url: GF_URL + 'athiti/v5/pe0sMISdLIZIv1wA-DFyAv2-C99ycg.ttf',
    variant: '500',
    family: 'Athiti',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'cf5eac92-b682-4b52-84c6-668f52953b04',
    origin: GF_SOURCE,
    url: GF_URL + 'athiti/v5/pe0vMISdLIZIv1w4DBhWCtaiAg.ttf',
    variant: 'regular',
    family: 'Athiti',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '97b55d24-04ab-4a10-a256-d209ef7bc55c',
    origin: GF_SOURCE,
    url: GF_URL + 'athiti/v5/pe0sMISdLIZIv1wAoDByAv2-C99ycg.ttf',
    variant: '300',
    family: 'Athiti',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'cda78fb4-598d-4375-88f0-908e876d4a27',
    origin: GF_SOURCE,
    url: GF_URL + 'athiti/v5/pe0sMISdLIZIv1wAxDNyAv2-C99ycg.ttf',
    variant: '200',
    family: 'Athiti',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '9cdb04f2-48ba-463d-a6e1-cb569935f1df',
    origin: GF_SOURCE,
    url: GF_URL + 'poly/v11/MQpV-W6wKNitdLKKr0DsviuGWA.ttf',
    variant: 'italic',
    family: 'Poly',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f6982bb0-381b-44cb-a26e-11e73afb788c',
    origin: GF_SOURCE,
    url: GF_URL + 'poly/v11/MQpb-W6wKNitRLCAq2Lpris.ttf',
    variant: 'regular',
    family: 'Poly',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '9b395843-517c-4302-aa2c-6d8ad22c8e95',
    origin: GF_SOURCE,
    url: GF_URL + 'petitformalscript/v8/B50TF6xQr2TXJBnGOFME6u5OR83oRP5qoHnqP4gZSiE.ttf',
    variant: 'regular',
    family: 'Petit Formal Script',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e9d6fc7b-ed8c-4c83-b7ab-7817f766f6f2',
    origin: GF_SOURCE,
    url: GF_URL + 'grandhotel/v8/7Au7p_IgjDKdCRWuR1azpmQNEl0O0kEx.ttf',
    variant: 'regular',
    family: 'Grand Hotel',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '17492151-f5da-4bdd-8330-7eff816e2856',
    origin: GF_SOURCE,
    url: GF_URL + 'limelight/v11/XLYkIZL7aopJVbZJHDuYPeNGrnY2TA.ttf',
    variant: 'regular',
    family: 'Limelight',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '4a6fd02f-8a46-415f-8803-9835cc34ebf9',
    origin: GF_SOURCE,
    url: GF_URL + 'cambay/v7/SLXMc1rY6H0_ZDs2bIYwwvN0Q3ptkDMN.ttf',
    variant: '700italic',
    family: 'Cambay',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '25134635-8335-41be-8f71-3646e83d770b',
    origin: GF_SOURCE,
    url: GF_URL + 'cambay/v7/SLXKc1rY6H0_ZDs-0pusx_lwYX99kA.ttf',
    variant: '700',
    family: 'Cambay',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd0f5376c-8a8d-4d22-87c1-ad6bf4983f9e',
    origin: GF_SOURCE,
    url: GF_URL + 'cambay/v7/SLXLc1rY6H0_ZDs2bL6M7dd8aGZk.ttf',
    variant: 'italic',
    family: 'Cambay',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '6904ef94-ef67-4f22-8a66-5df8e3de81b3',
    origin: GF_SOURCE,
    url: GF_URL + 'cambay/v7/SLXJc1rY6H0_ZDsGbrSIz9JsaA.ttf',
    variant: 'regular',
    family: 'Cambay',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd6e4fbde-1e4b-456d-894c-890cd3ee0afc',
    origin: GF_SOURCE,
    url:
      GF_URL + 'monsieurladoulaise/v9/_Xmz-GY4rjmCbQfc-aPRaa4pqV340p7EZl5ewkEU4HTy.ttf',
    variant: 'regular',
    family: 'Monsieur La Doulaise',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'd70ef727-c075-4c84-999f-b5da9d4bedf1',
    origin: GF_SOURCE,
    url: GF_URL + 'sniglet/v12/cIf4MaFLtkE3UjaJ_ImHRGEsnIJkWL4.ttf',
    variant: '800',
    family: 'Sniglet',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '4608d2c1-c470-40cc-9656-a8232d269c8d',
    origin: GF_SOURCE,
    url: GF_URL + 'sniglet/v12/cIf9MaFLtkE3UjaJxCmrYGkHgIs.ttf',
    variant: 'regular',
    family: 'Sniglet',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'da2be12c-dc76-4f1e-adeb-1d3080289f0b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersdisplay/v4/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1UT_fLJrgA00kAdA.ttf',
    variant: '900',
    family: 'Big Shoulders Display',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '836b015c-80e4-437f-a5d3-4d68da15565c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersdisplay/v4/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1Ua_bLJrgA00kAdA.ttf',
    variant: '800',
    family: 'Big Shoulders Display',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '00af851c-576d-4e1d-a4c8-45fc012f878b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersdisplay/v4/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1Ud_XLJrgA00kAdA.ttf',
    variant: '700',
    family: 'Big Shoulders Display',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '14f0c421-8e0a-42e2-a802-8f6c96cfecc9',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersdisplay/v4/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1UE_TLJrgA00kAdA.ttf',
    variant: '600',
    family: 'Big Shoulders Display',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '3f79cd62-e527-41e9-9353-84fdcb011e00',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersdisplay/v4/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1UP_PLJrgA00kAdA.ttf',
    variant: '500',
    family: 'Big Shoulders Display',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'db4ce03c-f3a8-43e9-aaff-0ca190e5da73',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersdisplay/v4/fC1_PZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1sy9rvLpMc2g.ttf',
    variant: 'regular',
    family: 'Big Shoulders Display',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '6cce9ce2-5f70-4154-af60-fc8ccd689558',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersdisplay/v4/fC1yPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1UZ_LLJrgA00kAdA.ttf',
    variant: '300',
    family: 'Big Shoulders Display',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '904b1c5b-86d5-40c9-907f-f0e748c069b7',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'bigshouldersdisplay/v4/fC1xPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx1Ur9DrDJYM2lAZ.ttf',
    variant: '100',
    family: 'Big Shoulders Display',
    category: 'display',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ebe96770-aa27-4d39-b576-fd0f63bf09b6',
    origin: GF_SOURCE,
    url: GF_URL + 'thasadith/v4/mtGj4_1TIqPYrd_f5R1osnus3QS2PEpN8zxA.ttf',
    variant: '700italic',
    family: 'Thasadith',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '72a5ea18-edee-4fa5-bb83-fefcfbd5b443',
    origin: GF_SOURCE,
    url: GF_URL + 'thasadith/v4/mtG94_1TIqPYrd_f5R1gDGYw2A6yHk9d8w.ttf',
    variant: '700',
    family: 'Thasadith',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '0cf6cdf1-8557-48ff-8f0d-6c70f61d8123',
    origin: GF_SOURCE,
    url: GF_URL + 'thasadith/v4/mtG-4_1TIqPYrd_f5R1oskMQ8iC-F1ZE.ttf',
    variant: 'italic',
    family: 'Thasadith',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '8c640097-65a4-4979-99ee-cf36e295a3b9',
    origin: GF_SOURCE,
    url: GF_URL + 'thasadith/v4/mtG44_1TIqPYrd_f5R1YsEkU0CWuFw.ttf',
    variant: 'regular',
    family: 'Thasadith',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c75131b7-d765-4ede-8a97-9cc829e0f5f2',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HCjSJ_RqATfVHNU.ttf',
    variant: '900italic',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '76116789-a81b-48a9-8b71-a8d5dfccde43',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HCKSJ_RqATfVHNU.ttf',
    variant: '800italic',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c75db1de-c87a-43a8-8cfb-43bd957421e0',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HDtSJ_RqATfVHNU.ttf',
    variant: '700italic',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c56eca73-525d-49c7-9a5f-047890b6060b',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HDUSJ_RqATfVHNU.ttf',
    variant: '600italic',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '69f6f5fc-05cc-4304-aaff-9078dd166ee5',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HA4T5_RqATfVHNU.ttf',
    variant: '500italic',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a664f73d-265f-469b-a4d5-d05762353427',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HAKT5_RqATfVHNU.ttf',
    variant: 'italic',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '43547903-0ae1-489b-a57a-adb6265dd92a',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HBUT5_RqATfVHNU.ttf',
    variant: '300italic',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'f970bc94-6bc1-4563-bea9-3afa7654b769',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HCKT5_RqATfVHNU.ttf',
    variant: '200italic',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '2f1f7764-147f-4875-8df3-7ef097dc9776',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HAKTp_RqATfVHNU.ttf',
    variant: '100italic',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd6da9abf-992b-4c51-9169-d347a6422492',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXmTTiDJXVigHPVA.ttf',
    variant: '900',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '8a221836-73dd-41ac-a7ce-9582adbe82fa',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXsDTiDJXVigHPVA.ttf',
    variant: '800',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '28562b7f-c3b4-4137-93be-8faf7f8d2a6f',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZMFGj5hxF0EhjimngomvnCCtqb30OX1zTiDJXVigHPVA.ttf',
    variant: '700',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd13cd8c9-22e6-4c99-b8bf-05020b7236f1',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZMFGj5hxF0EhjimngomvnCCtqb30OX7jTiDJXVigHPVA.ttf',
    variant: '600',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd97f334a-2104-4fbf-8961-2b052d447307',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXAjPiDJXVigHPVA.ttf',
    variant: '500',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '44048794-091d-4906-9534-2694c9a65ad8',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXMDPiDJXVigHPVA.ttf',
    variant: 'regular',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'f9c190c5-f23c-45df-b46e-43f74aabb739',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXbjPiDJXVigHPVA.ttf',
    variant: '300',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '84aad496-bbc9-4c71-a08e-6e574f56ced0',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXsDPiDJXVigHPVA.ttf',
    variant: '200',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '10e7ae36-2101-4634-b24d-d04a56a2f916',
    origin: GF_SOURCE,
    url: GF_URL + 'epilogue/v4/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXMDLiDJXVigHPVA.ttf',
    variant: '100',
    family: 'Epilogue',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '85679636-6a54-429e-9e75-501ba5921d20',
    origin: GF_SOURCE,
    url: GF_URL + 'baskervville/v5/YA9Kr0yU4l_XOrogbkun3kQQtZmspPPZ9Mlt.ttf',
    variant: 'italic',
    family: 'Baskervville',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'be6a6d09-364c-43f7-9078-a44d94f01e56',
    origin: GF_SOURCE,
    url: GF_URL + 'baskervville/v5/YA9Ur0yU4l_XOrogbkun3kQgt5OohvbJ9A.ttf',
    variant: 'regular',
    family: 'Baskervville',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '202174ab-961c-4473-9e4f-0415b0c999bc',
    origin: GF_SOURCE,
    url: GF_URL + 'amethysta/v9/rP2Fp2K15kgb_F3ibfWIGDWCBl0O8Q.ttf',
    variant: 'regular',
    family: 'Amethysta',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '8236e691-76a3-4351-8db7-f28d1b0b5013',
    origin: GF_SOURCE,
    url: GF_URL + 'aladin/v9/ZgNSjPJFPrvJV5f16Sf4pGT2Ng.ttf',
    variant: 'regular',
    family: 'Aladin',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '4c904a01-a058-42da-bce9-d03a98eed288',
    origin: GF_SOURCE,
    url: GF_URL + 'pangolin/v6/cY9GfjGcW0FPpi-tWPfK5d3aiLBG.ttf',
    variant: 'regular',
    family: 'Pangolin',
    category: 'handwriting',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '7c67262e-32ee-4af5-80d7-123cc3fe3ca3',
    origin: GF_SOURCE,
    url: GF_URL + 'faunaone/v8/wlpzgwTPBVpjpCuwkuEx2UxLYClOCg.ttf',
    variant: 'regular',
    family: 'Fauna One',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '19736013-8f8a-46ad-bcc0-f4b14a000f33',
    origin: GF_SOURCE,
    url: GF_URL + 'librecaslontext/v2/DdT578IGsGw1aF1JU10PUbTvNNaDMfID8sdjNR-8ssPt.ttf',
    variant: '700',
    family: 'Libre Caslon Text',
    category: 'serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'fa6a4c27-b7b1-4bb3-97a7-b32215360b02',
    origin: GF_SOURCE,
    url: GF_URL + 'librecaslontext/v2/DdT678IGsGw1aF1JU10PUbTvNNaDMfq91-dJGxO1q9o.ttf',
    variant: 'italic',
    family: 'Libre Caslon Text',
    category: 'serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '7b834226-bfb4-40e7-9098-b9222019dffc',
    origin: GF_SOURCE,
    url: GF_URL + 'librecaslontext/v2/DdT878IGsGw1aF1JU10PUbTvNNaDMcq_3eNrHgO1.ttf',
    variant: 'regular',
    family: 'Libre Caslon Text',
    category: 'serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'acb5e372-ac3d-41de-8cd8-a81b21f3b08a',
    origin: GF_SOURCE,
    url: GF_URL + 'calligraffitti/v12/46k2lbT3XjDVqJw3DCmCFjE0vnFZM5ZBpYN-.ttf',
    variant: 'regular',
    family: 'Calligraffitti',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'e4e25c1f-1692-408f-8d7d-30403d22f169',
    origin: GF_SOURCE,
    url: GF_URL + 'nanumbrushscript/v17/wXK2E2wfpokopxzthSqPbcR5_gVaxazyjqBr1lO97Q.ttf',
    variant: 'regular',
    family: 'Nanum Brush Script',
    category: 'handwriting',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '351b9f83-cfef-4cf3-bae6-8e3d0d2070d9',
    origin: GF_SOURCE,
    url: GF_URL + 'rosario/v19/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSmh3feIFPiUDn08.ttf',
    variant: '700italic',
    family: 'Rosario',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '5bdf9615-429b-4796-8345-60db910eb357',
    origin: GF_SOURCE,
    url: GF_URL + 'rosario/v19/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSlF3feIFPiUDn08.ttf',
    variant: '600italic',
    family: 'Rosario',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'db82f7ac-739f-4810-9b39-5279ec798414',
    origin: GF_SOURCE,
    url: GF_URL + 'rosario/v19/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSr1wfeIFPiUDn08.ttf',
    variant: '500italic',
    family: 'Rosario',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'e33c78af-15f0-4816-bf3e-93447b5bbde3',
    origin: GF_SOURCE,
    url: GF_URL + 'rosario/v19/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSo9wfeIFPiUDn08.ttf',
    variant: 'italic',
    family: 'Rosario',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'c20fbd18-db5a-4fa2-822c-0c4fc2176e3f',
    origin: GF_SOURCE,
    url: GF_URL + 'rosario/v19/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQStFwfeIFPiUDn08.ttf',
    variant: '300italic',
    family: 'Rosario',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '72655d23-c3b0-429e-92f9-6824efb3aca1',
    origin: GF_SOURCE,
    url: GF_URL + 'rosario/v19/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM6__Dmczd-YnOzUD.ttf',
    variant: '700',
    family: 'Rosario',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'c9485768-c2eb-4f57-97d4-b4de643d97be',
    origin: GF_SOURCE,
    url: GF_URL + 'rosario/v19/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM6_GDmczd-YnOzUD.ttf',
    variant: '600',
    family: 'Rosario',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '1dee0d05-f35c-426a-b31a-10f7323c009b',
    origin: GF_SOURCE,
    url: GF_URL + 'rosario/v19/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68qCWczd-YnOzUD.ttf',
    variant: '500',
    family: 'Rosario',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'ac9e6b94-53b6-4237-91ba-047a4eb813ac',
    origin: GF_SOURCE,
    url: GF_URL + 'rosario/v19/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68YCWczd-YnOzUD.ttf',
    variant: 'regular',
    family: 'Rosario',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '866d801f-3901-4234-8878-3b34d0a44969',
    origin: GF_SOURCE,
    url: GF_URL + 'rosario/v19/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM69GCWczd-YnOzUD.ttf',
    variant: '300',
    family: 'Rosario',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '90315dde-2751-41da-af88-606eaace1658',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'averiaseriflibre/v10/neIbzD2ms4wxr6GvjeD0X88SHPyX2xYOpzM2xK0uVLe_bXHq.ttf',
    variant: '700italic',
    family: 'Averia Serif Libre',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '87f4cb98-a023-4327-8367-6b92eb129b43',
    origin: GF_SOURCE,
    url:
      GF_URL + 'averiaseriflibre/v10/neIVzD2ms4wxr6GvjeD0X88SHPyX2xYGGS6qwacqdrKvbQ.ttf',
    variant: '700',
    family: 'Averia Serif Libre',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '88b9f79f-9ec4-4d69-9042-6f1c370eae71',
    origin: GF_SOURCE,
    url: GF_URL + 'averiaseriflibre/v10/neIUzD2ms4wxr6GvjeD0X88SHPyX2xYOpwuK64kmf6u2.ttf',
    variant: 'italic',
    family: 'Averia Serif Libre',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '9bfcbd28-6dce-4f62-aace-1d4f683c368a',
    origin: GF_SOURCE,
    url: GF_URL + 'averiaseriflibre/v10/neIWzD2ms4wxr6GvjeD0X88SHPyX2xY-pQGOyYw2fw.ttf',
    variant: 'regular',
    family: 'Averia Serif Libre',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '25d2263d-491c-4561-88c2-5762cdc255e6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'averiaseriflibre/v10/neIbzD2ms4wxr6GvjeD0X88SHPyX2xYOpzMmw60uVLe_bXHq.ttf',
    variant: '300italic',
    family: 'Averia Serif Libre',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'f1564b54-262c-484e-928b-d40b2d97ed02',
    origin: GF_SOURCE,
    url:
      GF_URL + 'averiaseriflibre/v10/neIVzD2ms4wxr6GvjeD0X88SHPyX2xYGCSmqwacqdrKvbQ.ttf',
    variant: '300',
    family: 'Averia Serif Libre',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '97035ab3-f77d-417d-92cc-4c8b26a8e78e',
    origin: GF_SOURCE,
    url: GF_URL + 'jua/v8/co3KmW9ljjAjc-DZCsKgsg.ttf',
    variant: 'regular',
    family: 'Jua',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '6dcd8e3b-c43b-4172-b850-d5a14ab91b22',
    origin: GF_SOURCE,
    url: GF_URL + 'zcoolxiaowei/v5/i7dMIFFrTRywPpUVX9_RJyM1YFKQHwyVd3U.ttf',
    variant: 'regular',
    family: 'ZCOOL XiaoWei',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '7a0bc955-fdbd-446f-86c2-34dea5557e21',
    origin: GF_SOURCE,
    url: GF_URL + 'rye/v8/r05XGLJT86YDFpTsXOqx4w.ttf',
    variant: 'regular',
    family: 'Rye',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '81624335-c09b-4205-bb7a-2fb6676c4282',
    origin: GF_SOURCE,
    url: GF_URL + 'kronaone/v9/jAnEgHdjHcjgfIb1ZcUCMY-h3cWkWg.ttf',
    variant: 'regular',
    family: 'Krona One',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '0e666831-fd0d-4a20-82db-c30051e5bf13',
    origin: GF_SOURCE,
    url: GF_URL + 'jockeyone/v10/HTxpL2g2KjCFj4x8WI6ArIb7HYOk4xc.ttf',
    variant: 'regular',
    family: 'Jockey One',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '84286302-5ec4-4b0e-9723-14371696a3a0',
    origin: GF_SOURCE,
    url: GF_URL + 'averialibre/v9/2V0HKIcMGZEnV6xygz7eNjESAJFxakTp2JEwT4Sk.ttf',
    variant: '700italic',
    family: 'Averia Libre',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '3a1ff466-69ed-4f3a-8677-50d59a0fbd7f',
    origin: GF_SOURCE,
    url: GF_URL + 'averialibre/v9/2V0FKIcMGZEnV6xygz7eNjEavoztb07t-pQgTw.ttf',
    variant: '700',
    family: 'Averia Libre',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '1a17049b-f789-4ccb-b86c-0cb8fefe2804',
    origin: GF_SOURCE,
    url: GF_URL + 'averialibre/v9/2V0EKIcMGZEnV6xygz7eNjESAKnNRWDh8405.ttf',
    variant: 'italic',
    family: 'Averia Libre',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '9490ed76-8d59-4048-8174-be561b03ca53',
    origin: GF_SOURCE,
    url: GF_URL + 'averialibre/v9/2V0aKIcMGZEnV6xygz7eNjEiAqPJZ2Xx8w.ttf',
    variant: 'regular',
    family: 'Averia Libre',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'e7316322-d68d-405d-9e27-1915dabc97c3',
    origin: GF_SOURCE,
    url: GF_URL + 'averialibre/v9/2V0HKIcMGZEnV6xygz7eNjESAJFhbUTp2JEwT4Sk.ttf',
    variant: '300italic',
    family: 'Averia Libre',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'a3b76944-b876-4984-a900-f70acb4f3781',
    origin: GF_SOURCE,
    url: GF_URL + 'averialibre/v9/2V0FKIcMGZEnV6xygz7eNjEarovtb07t-pQgTw.ttf',
    variant: '300',
    family: 'Averia Libre',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '67ccb59f-708f-4408-995d-05f2a86856a7',
    origin: GF_SOURCE,
    url: GF_URL + 'judson/v13/FeVSS0Fbvbc14Vxps5xQ3Z5nm29Gww.ttf',
    variant: '700',
    family: 'Judson',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '71bf9577-e2fc-42a0-b1c5-3a9626a5f71f',
    origin: GF_SOURCE,
    url: GF_URL + 'judson/v13/FeVTS0Fbvbc14VxhDblw97BrknZf.ttf',
    variant: 'italic',
    family: 'Judson',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '93daa46d-9913-43f8-8352-c53f380527dc',
    origin: GF_SOURCE,
    url: GF_URL + 'judson/v13/FeVRS0Fbvbc14VxRD7N01bV7kg.ttf',
    variant: 'regular',
    family: 'Judson',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'ec046652-3bda-491b-84c6-4dbee8c958f3',
    origin: GF_SOURCE,
    url: GF_URL + 'balooda2/v2/2-ch9J9j0IaUMQZwAJyJSk77hZloY23zejE.ttf',
    variant: '800',
    family: 'Baloo Da 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '7953fee8-cd50-41b8-a41d-5c98ddfc8cf1',
    origin: GF_SOURCE,
    url: GF_URL + 'balooda2/v2/2-ch9J9j0IaUMQZwAJyJSlL4hZloY23zejE.ttf',
    variant: '700',
    family: 'Baloo Da 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '5ebd827a-b2f6-419d-b8c0-898908b3c3d9',
    origin: GF_SOURCE,
    url: GF_URL + 'balooda2/v2/2-ch9J9j0IaUMQZwAJyJSjb5hZloY23zejE.ttf',
    variant: '600',
    family: 'Baloo Da 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'f72e2ddb-6eb3-4b9f-8819-108003cb2033',
    origin: GF_SOURCE,
    url: GF_URL + 'balooda2/v2/2-ch9J9j0IaUMQZwAJyJShr-hZloY23zejE.ttf',
    variant: '500',
    family: 'Baloo Da 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'a22488c1-bf27-44c3-92e0-1f8fe3f644b1',
    origin: GF_SOURCE,
    url: GF_URL + 'balooda2/v2/2-ci9J9j0IaUMQZwAJyJcu7XoZFDf2Q.ttf',
    variant: 'regular',
    family: 'Baloo Da 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '046d3ab2-bb1e-4aec-a003-17c7030bbed1',
    origin: GF_SOURCE,
    url: GF_URL + 'corben/v14/LYjAdGzzklQtCMpFHCZgrXArXN7HWQ.ttf',
    variant: '700',
    family: 'Corben',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'f78068dd-bd7b-479c-986f-cc9d84d779e2',
    origin: GF_SOURCE,
    url: GF_URL + 'corben/v14/LYjDdGzzklQtCMp9oAlEpVs3VQ.ttf',
    variant: 'regular',
    family: 'Corben',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '3dc6f141-8d36-4368-b9fe-e191021de91e',
    origin: GF_SOURCE,
    url: GF_URL + 'gfsdidot/v10/Jqzh5TybZ9vZMWFssvwiF-fGFSCGAA.ttf',
    variant: 'regular',
    family: 'GFS Didot',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a3f10dd2-a59d-4af4-b348-9ce5f6829ade',
    origin: GF_SOURCE,
    url: GF_URL + 'marmelad/v10/Qw3eZQdSHj_jK2e-8tFLG-YMC0R8.ttf',
    variant: 'regular',
    family: 'Marmelad',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '30abc189-d9fc-4eab-971a-448ff9d25cd3',
    origin: GF_SOURCE,
    url: GF_URL + 'sunflower/v9/RWmPoKeF8fUjqIj7Vc-0-MDiqYsGBGBzCw.ttf',
    variant: '700',
    family: 'Sunflower',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '7e9acc82-bbb0-474b-b6df-ae760390b78e',
    origin: GF_SOURCE,
    url: GF_URL + 'sunflower/v9/RWmPoKeF8fUjqIj7Vc-0sMbiqYsGBGBzCw.ttf',
    variant: '500',
    family: 'Sunflower',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '18a27f54-b776-4b54-aeda-72aed441d58b',
    origin: GF_SOURCE,
    url: GF_URL + 'sunflower/v9/RWmPoKeF8fUjqIj7Vc-06MfiqYsGBGBzCw.ttf',
    variant: '300',
    family: 'Sunflower',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '4a31c81d-00c8-40d5-bdc4-72d14b933915',
    origin: GF_SOURCE,
    url: GF_URL + 'ovo/v12/yYLl0h7Wyfzjy4Q5_3WVxA.ttf',
    variant: 'regular',
    family: 'Ovo',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '54b88fee-7aaa-4a30-bf1c-57b7b9c4d54d',
    origin: GF_SOURCE,
    url:
      GF_URL + 'herrvonmuellerhoff/v10/WBL6rFjRZkREW8WqmCWYLgCkQKXb4CAft3c6_qJY3QPQ.ttf',
    variant: 'regular',
    family: 'Herr Von Muellerhoff',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '7668f1d0-b1ff-494f-90ec-ad983cd52b18',
    origin: GF_SOURCE,
    url: GF_URL + 'amiko/v5/WwkdxPq1DFK04uIZXHrEGoQAUco5.ttf',
    variant: '700',
    family: 'Amiko',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a59d47df-93c2-4582-b1de-f3d69d638ae4',
    origin: GF_SOURCE,
    url: GF_URL + 'amiko/v5/WwkdxPq1DFK04uJ9XXrEGoQAUco5.ttf',
    variant: '600',
    family: 'Amiko',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '173a9d1a-e331-4af8-b23c-80cecec7eb29',
    origin: GF_SOURCE,
    url: GF_URL + 'amiko/v5/WwkQxPq1DFK04tqlc17MMZgJ.ttf',
    variant: 'regular',
    family: 'Amiko',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6d1257eb-cf3f-4550-bca7-1a029dd556b1',
    origin: GF_SOURCE,
    url: GF_URL + 'trocchi/v9/qWcqB6WkuIDxDZLcDrtUvMeTYD0.ttf',
    variant: 'regular',
    family: 'Trocchi',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'a42db01e-47b5-4cae-9f65-ccce2c722552',
    origin: GF_SOURCE,
    url: GF_URL + 'carroisgothic/v11/Z9XPDmFATg-N1PLtLOOxvIHl9ZmD3i7ajcJ-.ttf',
    variant: 'regular',
    family: 'Carrois Gothic',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '3e2a165c-ae41-4646-beb4-e4a96315b2a4',
    origin: GF_SOURCE,
    url: GF_URL + 'delius/v10/PN_xRfK0pW_9e1rtYcI-jT3L_w.ttf',
    variant: 'regular',
    family: 'Delius',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b6e2dc8f-34f7-474b-9536-68a0b2a9b7f2',
    origin: GF_SOURCE,
    url: GF_URL + 'kristi/v12/uK_y4ricdeU6zwdRCh0TMv6EXw.ttf',
    variant: 'regular',
    family: 'Kristi',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c6aa54aa-ac9c-4beb-9c1b-2f9ab92417f5',
    origin: GF_SOURCE,
    url: GF_URL + 'darkergrotesque/v2/U9MA6cuh-mLQlC4BKCtayOfARkSVozLzAW8hTOsXsX0.ttf',
    variant: '900',
    family: 'Darker Grotesque',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '5982a2e6-d964-43ee-80d9-b852f2dfd1a2',
    origin: GF_SOURCE,
    url: GF_URL + 'darkergrotesque/v2/U9MA6cuh-mLQlC4BKCtayOfARkSVoxbyAW8hTOsXsX0.ttf',
    variant: '800',
    family: 'Darker Grotesque',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '87879398-0cff-4296-a2bc-004d02533ec5',
    origin: GF_SOURCE,
    url: GF_URL + 'darkergrotesque/v2/U9MA6cuh-mLQlC4BKCtayOfARkSVowrxAW8hTOsXsX0.ttf',
    variant: '700',
    family: 'Darker Grotesque',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'ea077710-2ba2-47e0-abaa-1ac971b20930',
    origin: GF_SOURCE,
    url: GF_URL + 'darkergrotesque/v2/U9MA6cuh-mLQlC4BKCtayOfARkSVo27wAW8hTOsXsX0.ttf',
    variant: '600',
    family: 'Darker Grotesque',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'e7b8db9d-dc12-49e1-b80c-8df2b11ee3e4',
    origin: GF_SOURCE,
    url: GF_URL + 'darkergrotesque/v2/U9MA6cuh-mLQlC4BKCtayOfARkSVo0L3AW8hTOsXsX0.ttf',
    variant: '500',
    family: 'Darker Grotesque',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '03afa0f1-0a5e-4739-b1a9-a7205a8d9e77',
    origin: GF_SOURCE,
    url: GF_URL + 'darkergrotesque/v2/U9MH6cuh-mLQlC4BKCtayOfARkSVm7beJWcKUOI.ttf',
    variant: 'regular',
    family: 'Darker Grotesque',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'afb18676-bebc-4ef7-b7fe-121b5098160d',
    origin: GF_SOURCE,
    url: GF_URL + 'darkergrotesque/v2/U9MA6cuh-mLQlC4BKCtayOfARkSVoxr2AW8hTOsXsX0.ttf',
    variant: '300',
    family: 'Darker Grotesque',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '06575306-a41a-4987-8a92-4a29f44a460e',
    origin: GF_SOURCE,
    url: GF_URL + 'andada/v12/uK_y4riWaego3w9RCh0TMv6EXw.ttf',
    variant: 'regular',
    family: 'Andada',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '99078290-ab59-45cf-929d-9d2f1f69fae7',
    origin: GF_SOURCE,
    url: GF_URL + 'halant/v8/u-490qaujRI2PbsvY_1CmwZqcwdRXg.ttf',
    variant: '700',
    family: 'Halant',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '74e444d0-e48a-4118-887a-42a8c655645d',
    origin: GF_SOURCE,
    url: GF_URL + 'halant/v8/u-490qaujRI2PbsvB_xCmwZqcwdRXg.ttf',
    variant: '600',
    family: 'Halant',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '067a61e2-3cb1-422e-912d-5691aa982fcf',
    origin: GF_SOURCE,
    url: GF_URL + 'halant/v8/u-490qaujRI2PbsvK_tCmwZqcwdRXg.ttf',
    variant: '500',
    family: 'Halant',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ecb8c628-e233-4a5a-ad5c-f9129f4889dd',
    origin: GF_SOURCE,
    url: GF_URL + 'halant/v8/u-4-0qaujRI2PbsX39Jmky12eg.ttf',
    variant: 'regular',
    family: 'Halant',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b9530ef6-0c6a-41f8-b966-74c2df65e7eb',
    origin: GF_SOURCE,
    url: GF_URL + 'halant/v8/u-490qaujRI2Pbsvc_pCmwZqcwdRXg.ttf',
    variant: '300',
    family: 'Halant',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '42ce6048-6bf1-48d4-bb81-4042bba4edcf',
    origin: GF_SOURCE,
    url: GF_URL + 'magra/v9/uK_w4ruaZus72nbNDxcXEPuUX1ow.ttf',
    variant: '700',
    family: 'Magra',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '3ad4ee58-03ff-4c96-bf92-380e66cee557',
    origin: GF_SOURCE,
    url: GF_URL + 'magra/v9/uK_94ruaZus72k5xIDMfO-ed.ttf',
    variant: 'regular',
    family: 'Magra',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'ffb154df-1dc8-4f76-9adf-bc838441dd8a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8BfupYMnEhq5H1w.ttf',
    variant: '700italic',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '83f7dc87-c2bd-4f9c-a7c2-7bba699b7512',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY4S7Yvrr4cFFwq5.ttf',
    variant: '700',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '22497ad2-2363-4a1b-bc22-bc054581db61',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8HPvpYMnEhq5H1w.ttf',
    variant: '600italic',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '5289fdb8-5714-4d3e-8207-b41e5c8c6579',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY527Ivrr4cFFwq5.ttf',
    variant: '600',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '5f411563-6b5b-44d2-b3ce-fce9079e8119',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8F_opYMnEhq5H1w.ttf',
    variant: '500italic',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '3ed00df0-03e5-4425-885b-772e1cdd3cc7',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY5a64vrr4cFFwq5.ttf',
    variant: '500',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'bcdd74a8-1888-4273-93e0-1c6e6bb1a18a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8nN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYasyKvBgYsMDhM.ttf',
    variant: 'italic',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'e1cad183-a551-4e5c-b347-cf3697ccf96b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8lN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHbauwq_jhJsM.ttf',
    variant: 'regular',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '8022969d-383f-47ca-be4b-c0deddb18f17',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8AfppYMnEhq5H1w.ttf',
    variant: '300italic',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '7fca4940-9b41-4fed-a133-1320bd3bce9b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY4C6ovrr4cFFwq5.ttf',
    variant: '300',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '40a365c8-aac9-4070-b878-f5699ba892a0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8GPqpYMnEhq5H1w.ttf',
    variant: '200italic',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '76a05097-7d2e-4d84-9d1b-68eaa2c6a30d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY5m6Yvrr4cFFwq5.ttf',
    variant: '200',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'ec2657aa-c2be-4e2f-a0c6-3fc417676acf',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8hN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8M_LhakJHhOgBg.ttf',
    variant: '100italic',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '6baa4bb0-3fd8-4f12-b2a1-f3796d1fa003',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'ibmplexsanscondensed/v7/Gg8nN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY7KyKvBgYsMDhM.ttf',
    variant: '100',
    family: 'IBM Plex Sans Condensed',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '09f23536-1b62-4988-bc29-98bca61148b4',
    origin: GF_SOURCE,
    url: GF_URL + 'norican/v9/MwQ2bhXp1eSBqjkPGJJRtGs-lbA.ttf',
    variant: 'regular',
    family: 'Norican',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '100d52c6-73b8-44b8-bc21-26daecb20933',
    origin: GF_SOURCE,
    url: GF_URL + 'pattaya/v6/ea8ZadcqV_zkHY-XNdCn92ZEmVs.ttf',
    variant: 'regular',
    family: 'Pattaya',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f1d05c47-5020-42ed-80b7-70a415ef322f',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk2ALCRZonmalTgyPmRfsWg28TRN4O3WYZB_sU.ttf',
    variant: '800italic',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '94c5e3cd-c988-4dd2-9bc4-c85143401600',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk0ALCRZonmalTgyPmRfs0CxUTXPYeVXJZB.ttf',
    variant: '800',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '4fcb568c-c9ec-412b-9345-76d78490ccff',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk2ALCRZonmalTgyPmRfsWg29jSN4O3WYZB_sU.ttf',
    variant: '700italic',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '72a47bb7-1efd-4509-a2a8-ce308034a784',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk0ALCRZonmalTgyPmRfs0exkTXPYeVXJZB.ttf',
    variant: '700',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8bae144b-e9dc-474e-b4ce-bea806875c7b',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk2ALCRZonmalTgyPmRfsWg27zTN4O3WYZB_sU.ttf',
    variant: '600italic',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '105ad105-9ead-44c6-ab9e-94e91f61b5be',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk0ALCRZonmalTgyPmRfs16x0TXPYeVXJZB.ttf',
    variant: '600',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '830f4538-0173-4244-84b5-016c98e825e7',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk2ALCRZonmalTgyPmRfsWg25DUN4O3WYZB_sU.ttf',
    variant: '500italic',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '21437c44-622f-494b-a0c8-2f7fcbaf491f',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk0ALCRZonmalTgyPmRfs1WwETXPYeVXJZB.ttf',
    variant: '500',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8dbe03f1-2ed7-478e-9cd9-f73c738a636b',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/KtkrALCRZonmalTgyPmRfsWg42T9E4ucRY8.ttf',
    variant: 'italic',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '18a9bc4f-c523-4bfb-94c8-8225536fbe61',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/KtkpALCRZonmalTgyPmRfvWi6WDfFpuc.ttf',
    variant: 'regular',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c68432c0-ebdc-4034-a204-a33834510912',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk2ALCRZonmalTgyPmRfsWg28jVN4O3WYZB_sU.ttf',
    variant: '300italic',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '774ff7fb-cfc9-4ae5-a33c-8c4983c733a4',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk0ALCRZonmalTgyPmRfs0OwUTXPYeVXJZB.ttf',
    variant: '300',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f2a563f5-22f6-4cad-b54a-f915bba5bd05',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk2ALCRZonmalTgyPmRfsWg26zWN4O3WYZB_sU.ttf',
    variant: '200italic',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '4bb24e23-1976-44dc-8ee9-cbf4de35f433',
    origin: GF_SOURCE,
    url: GF_URL + 'spectralsc/v6/Ktk0ALCRZonmalTgyPmRfs1qwkTXPYeVXJZB.ttf',
    variant: '200',
    family: 'Spectral SC',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bc02927b-186e-47fa-927a-54e74783616f',
    origin: GF_SOURCE,
    url: GF_URL + 'telex/v9/ieVw2Y1fKWmIO9fTB1piKFIf.ttf',
    variant: 'regular',
    family: 'Telex',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '051066c2-f81f-4b82-a855-81dbb3f3ae1f',
    origin: GF_SOURCE,
    url: GF_URL + 'belleza/v9/0nkoC9_pNeMfhX4BtcbyawzruP8.ttf',
    variant: 'regular',
    family: 'Belleza',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '4ff565fa-cec3-4992-9501-a8b7e17de9e2',
    origin: GF_SOURCE,
    url: GF_URL + 'marcellussc/v8/ke8iOgUHP1dg-Rmi6RWjbLEPgdydGKikhA.ttf',
    variant: 'regular',
    family: 'Marcellus SC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'cdc4c87d-bdd6-48db-8183-d69b8a02e7eb',
    origin: GF_SOURCE,
    url: GF_URL + 'allertastencil/v11/HTx0L209KT-LmIE9N7OR6eiycOeF-zz313DuvQ.ttf',
    variant: 'regular',
    family: 'Allerta Stencil',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'ecfa834d-eff7-453c-8398-de8ba5991a70',
    origin: GF_SOURCE,
    url:
      GF_URL + 'imfelldoublepica/v10/3XF0EqMq_94s9PeKF7Fg4gOKINyMtZ8rf0a_VJxF2p2G8g.ttf',
    variant: 'italic',
    family: 'IM Fell Double Pica',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4d6ca4c9-6b2d-4119-8624-c48025a7be09',
    origin: GF_SOURCE,
    url: GF_URL + 'imfelldoublepica/v10/3XF2EqMq_94s9PeKF7Fg4gOKINyMtZ8rT0S1UL5Ayp0.ttf',
    variant: 'regular',
    family: 'IM Fell Double Pica',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8f821caf-c18c-4a4b-8bce-b4898f3de078',
    origin: GF_SOURCE,
    url: GF_URL + 'coustard/v11/3XFuErgg3YsZ5fqUU-2LkEHmb_jU3eRL.ttf',
    variant: '900',
    family: 'Coustard',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0425a1e2-30a4-4fc1-b790-9d2fa8dcea3f',
    origin: GF_SOURCE,
    url: GF_URL + 'coustard/v11/3XFpErgg3YsZ5fqUU9UPvWXuROTd.ttf',
    variant: 'regular',
    family: 'Coustard',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'ec5ec500-4e09-4f3c-900f-5156f9be1b05',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_upMpWdvgLdNxVLXbZiKi2Oq73EZZ_f6w.ttf',
    variant: '700italic',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '88354c35-4e09-4fdb-92ff-5fded2c0f2c4',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_urMpWdvgLdNxVLVQh_tiiEr5_BdZ8.ttf',
    variant: '700',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '8eeb1ba8-7c62-4e45-b684-3385a95621ed',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_upMpWdvgLdNxVLXbZiTiyOq73EZZ_f6w.ttf',
    variant: '600italic',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '8abe6439-932c-4d2a-b630-2626f8986455',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_urMpWdvgLdNxVLVWx-tiiEr5_BdZ8.ttf',
    variant: '600',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2e0b77bb-50aa-4b72-9660-c015c83a483b',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_upMpWdvgLdNxVLXbZiYiuOq73EZZ_f6w.ttf',
    variant: '500italic',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '010064e0-18ce-4ec2-81fa-6292a36af58c',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_urMpWdvgLdNxVLVUB5tiiEr5_BdZ8.ttf',
    variant: '500',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b3a86633-39de-48c7-8e67-a6b33a3ed5ff',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_usMpWdvgLdNxVLXbZalgKqo5bYbA.ttf',
    variant: 'italic',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '7195b89f-260f-40b4-9613-ba26e5c42bec',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_uuMpWdvgLdNxVLbbRQkiCvs5Y.ttf',
    variant: 'regular',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1dba7dff-3c9d-4d96-bd16-2378471c3ab8',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_upMpWdvgLdNxVLXbZiOiqOq73EZZ_f6w.ttf',
    variant: '300italic',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2d763392-6091-4188-83a7-9b96d498a286',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_urMpWdvgLdNxVLVRh4tiiEr5_BdZ8.ttf',
    variant: '300',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '19186867-5caa-4c87-a317-7a0f7e18ec6f',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_upMpWdvgLdNxVLXbZiXimOq73EZZ_f6w.ttf',
    variant: '200italic',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1446d270-7095-48ad-8b7b-3c45f770ac34',
    origin: GF_SOURCE,
    url: GF_URL + 'niramit/v5/I_urMpWdvgLdNxVLVXx7tiiEr5_BdZ8.ttf',
    variant: '200',
    family: 'Niramit',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e342574e-afa0-491e-a2a2-6f7f5127e062',
    origin: GF_SOURCE,
    url: GF_URL + 'meriendaone/v11/H4cgBXaMndbflEq6kyZ1ht6YgoyyYzFzFw.ttf',
    variant: 'regular',
    family: 'Merienda One',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f7398fa2-e44e-4ef2-8e0e-9294eae521ec',
    origin: GF_SOURCE,
    url: GF_URL + 'lustria/v8/9oRONYodvDEyjuhOrCg5MtPyAcg.ttf',
    variant: 'regular',
    family: 'Lustria',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'bedbc29c-7124-4d5c-baf5-882759849ece',
    origin: GF_SOURCE,
    url: GF_URL + 'share/v11/i7dPIFliZjKNF6VPLgK7UEZ2RFq7AwU.ttf',
    variant: '700italic',
    family: 'Share',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '65acda79-e619-49b4-a08b-97748e4e358c',
    origin: GF_SOURCE,
    url: GF_URL + 'share/v11/i7dJIFliZjKNF63xM56-WkJUQUq7.ttf',
    variant: '700',
    family: 'Share',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7836bfd5-4bad-44f2-b5bb-34565df7f7d3',
    origin: GF_SOURCE,
    url: GF_URL + 'share/v11/i7dKIFliZjKNF6VPFr6UdE5dWFM.ttf',
    variant: 'italic',
    family: 'Share',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'aa1a4b2d-dc3e-4902-bbb1-cf7a71055786',
    origin: GF_SOURCE,
    url: GF_URL + 'share/v11/i7dEIFliZjKNF5VNHLq2cV5d.ttf',
    variant: 'regular',
    family: 'Share',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '66f69c90-dc4b-450c-823b-a5a9077b0ad6',
    origin: GF_SOURCE,
    url: GF_URL + 'contrailone/v10/eLGbP-j_JA-kG0_Zo51noafdZUvt_c092w.ttf',
    variant: 'regular',
    family: 'Contrail One',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4f6deda7-bdce-4d8e-af3a-98575226a3d1',
    origin: GF_SOURCE,
    url: GF_URL + 'graduate/v8/C8cg4cs3o2n15t_2YxgR6X2NZAn2.ttf',
    variant: 'regular',
    family: 'Graduate',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '39f3abd9-678b-4ae5-81b7-320ccbb4e9c4',
    origin: GF_SOURCE,
    url: GF_URL + 'marvel/v10/nwpQtKeoNgBV0qa4k2x8Al-i5QwyYdrc.ttf',
    variant: '700italic',
    family: 'Marvel',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8b7e1972-9717-4489-b01e-17e8d758b00d',
    origin: GF_SOURCE,
    url: GF_URL + 'marvel/v10/nwpWtKeoNgBV0qawLXHgB1WmxwkiYQ.ttf',
    variant: '700',
    family: 'Marvel',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b0b15e73-3492-4a2b-9037-2bf91fefc4d6',
    origin: GF_SOURCE,
    url: GF_URL + 'marvel/v10/nwpXtKeoNgBV0qa4k1TALXuqzhA7.ttf',
    variant: 'italic',
    family: 'Marvel',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '094cc37a-35d7-4d7d-af5e-28ac52b1a25f',
    origin: GF_SOURCE,
    url: GF_URL + 'marvel/v10/nwpVtKeoNgBV0qaIkV7ED366zg.ttf',
    variant: 'regular',
    family: 'Marvel',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '60e7b629-9613-4f40-8a13-2577a03b4baf',
    origin: GF_SOURCE,
    url: GF_URL + 'comingsoon/v12/qWcuB6mzpYL7AJ2VfdQR1u-SUjjzsykh.ttf',
    variant: 'regular',
    family: 'Coming Soon',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '0eb2960e-7c9e-40f9-a36d-f0a3fb739fd0',
    origin: GF_SOURCE,
    url:
      GF_URL + 'commissioner/v5/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5HG4IO9pOXuRoaY.ttf',
    variant: '900',
    family: 'Commissioner',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '0ccae1c2-b9cc-4509-b2e8-4c184217f18d',
    origin: GF_SOURCE,
    url:
      GF_URL + 'commissioner/v5/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Fi4IO9pOXuRoaY.ttf',
    variant: '800',
    family: 'Commissioner',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '0bc44d2e-995b-4adc-9cbf-cc9d31ac65b1',
    origin: GF_SOURCE,
    url:
      GF_URL + 'commissioner/v5/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5D-4IO9pOXuRoaY.ttf',
    variant: '700',
    family: 'Commissioner',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '9402c447-5668-4cc7-b9ad-0a5ab06ced13',
    origin: GF_SOURCE,
    url:
      GF_URL + 'commissioner/v5/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Aa4IO9pOXuRoaY.ttf',
    variant: '600',
    family: 'Commissioner',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b8a7b840-a827-4be0-84af-c2403def6f67',
    origin: GF_SOURCE,
    url:
      GF_URL + 'commissioner/v5/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Oq_IO9pOXuRoaY.ttf',
    variant: '500',
    family: 'Commissioner',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f9b3b310-ad07-4765-8ecb-d2058578dd74',
    origin: GF_SOURCE,
    url:
      GF_URL + 'commissioner/v5/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Ni_IO9pOXuRoaY.ttf',
    variant: 'regular',
    family: 'Commissioner',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '8f922eff-35a5-4035-a889-8c1c760edad0',
    origin: GF_SOURCE,
    url:
      GF_URL + 'commissioner/v5/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Ia_IO9pOXuRoaY.ttf',
    variant: '300',
    family: 'Commissioner',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '18082655-1b40-4187-86de-4486ede4c6b7',
    origin: GF_SOURCE,
    url:
      GF_URL + 'commissioner/v5/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Fi_IO9pOXuRoaY.ttf',
    variant: '200',
    family: 'Commissioner',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '0f3c24d0-0ca7-4ffb-aff9-9becfc36b165',
    origin: GF_SOURCE,
    url:
      GF_URL + 'commissioner/v5/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Ni-IO9pOXuRoaY.ttf',
    variant: '100',
    family: 'Commissioner',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3aa1eb18-164b-4ad6-b6a3-2b9056dab9f5',
    origin: GF_SOURCE,
    url: GF_URL + 'amita/v9/HhyXU5si9Om7PTHTLtCCOopCTKkI.ttf',
    variant: '700',
    family: 'Amita',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '077aba67-33fa-484a-87a8-08fe9efa65dc',
    origin: GF_SOURCE,
    url: GF_URL + 'amita/v9/HhyaU5si9Om7PQlvAfSKEZZL.ttf',
    variant: 'regular',
    family: 'Amita',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '05139a24-8b4a-4759-ac1f-0d10c2234f4a',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasc/v15/taiRGmRtCJ62-O0HhNEa-Z6q4RE2SK-UEGKDBz4.ttf',
    variant: '900italic',
    family: 'Alegreya SC',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '47d96c54-c089-48ef-a600-e01d7c94a853',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasc/v15/taiTGmRtCJ62-O0HhNEa-ZYs_rUxQqu2FXKD.ttf',
    variant: '900',
    family: 'Alegreya SC',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'ef2b83ee-4844-4b00-af14-aada6c8a99be',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasc/v15/taiRGmRtCJ62-O0HhNEa-Z6q4TU3SK-UEGKDBz4.ttf',
    variant: '800italic',
    family: 'Alegreya SC',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'fa22b1cf-822e-4e52-9b1f-92059ba2b7bf',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasc/v15/taiTGmRtCJ62-O0HhNEa-ZYI_7UxQqu2FXKD.ttf',
    variant: '800',
    family: 'Alegreya SC',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '94081e16-6686-4e4a-8560-3fbdf5438c6d',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasc/v15/taiRGmRtCJ62-O0HhNEa-Z6q4Sk0SK-UEGKDBz4.ttf',
    variant: '700italic',
    family: 'Alegreya SC',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '421b8a57-42bf-435f-97fd-f7ff8227ab9c',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasc/v15/taiTGmRtCJ62-O0HhNEa-ZYU_LUxQqu2FXKD.ttf',
    variant: '700',
    family: 'Alegreya SC',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '0cc0fcd9-38d3-4029-8478-a33032b2097f',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasc/v15/taiRGmRtCJ62-O0HhNEa-Z6q4WEySK-UEGKDBz4.ttf',
    variant: '500italic',
    family: 'Alegreya SC',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '83ae8a67-c3dc-46b8-8a11-dee43b82f20e',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasc/v15/taiTGmRtCJ62-O0HhNEa-ZZc-rUxQqu2FXKD.ttf',
    variant: '500',
    family: 'Alegreya SC',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '69234ef0-defb-48c2-bdb1-57fe2329a856',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasc/v15/taiMGmRtCJ62-O0HhNEa-Z6q2ZUbbKe_DGs.ttf',
    variant: 'italic',
    family: 'Alegreya SC',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '681ef8b5-505a-43b7-a254-5480d4033c40',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasc/v15/taiOGmRtCJ62-O0HhNEa-a6o05E5abe_.ttf',
    variant: 'regular',
    family: 'Alegreya SC',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '001578b9-6045-49ca-b285-22152f928b01',
    origin: GF_SOURCE,
    url: GF_URL + 'biryani/v6/hv-TlzNxIFoO84Yddcg0GTBSU-J-RxQ.ttf',
    variant: '900',
    family: 'Biryani',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '5a0d5890-2221-49ae-8510-ef37d14a92ef',
    origin: GF_SOURCE,
    url: GF_URL + 'biryani/v6/hv-TlzNxIFoO84Yddew1GTBSU-J-RxQ.ttf',
    variant: '800',
    family: 'Biryani',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '190bff9f-9f5b-4275-ac82-5549dbdcb919',
    origin: GF_SOURCE,
    url: GF_URL + 'biryani/v6/hv-TlzNxIFoO84YddfA2GTBSU-J-RxQ.ttf',
    variant: '700',
    family: 'Biryani',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'e74175dc-7b12-45bf-aa92-887ccd16686a',
    origin: GF_SOURCE,
    url: GF_URL + 'biryani/v6/hv-TlzNxIFoO84YddZQ3GTBSU-J-RxQ.ttf',
    variant: '600',
    family: 'Biryani',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'e45a3126-4636-44ec-9adf-9f053472a33c',
    origin: GF_SOURCE,
    url: GF_URL + 'biryani/v6/hv-WlzNxIFoO84YdTUwZPTh5T-s.ttf',
    variant: 'regular',
    family: 'Biryani',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '20c557ab-7b89-469d-9625-a3012265eaa2',
    origin: GF_SOURCE,
    url: GF_URL + 'biryani/v6/hv-TlzNxIFoO84YddeAxGTBSU-J-RxQ.ttf',
    variant: '300',
    family: 'Biryani',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '733db2c0-0bb5-43e2-9ba7-a2d7eacc1730',
    origin: GF_SOURCE,
    url: GF_URL + 'biryani/v6/hv-TlzNxIFoO84YddYQyGTBSU-J-RxQ.ttf',
    variant: '200',
    family: 'Biryani',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f5991bb1-e17f-4c35-8c82-ab448befb1c4',
    origin: GF_SOURCE,
    url: GF_URL + 'palanquindark/v7/xn76YHgl1nqmANMB-26xC7yuF8YyY241fcvN2KT4.ttf',
    variant: '700',
    family: 'Palanquin Dark',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '247cf5e1-ddd8-427d-9e32-8966cab67c72',
    origin: GF_SOURCE,
    url: GF_URL + 'palanquindark/v7/xn76YHgl1nqmANMB-26xC7yuF8ZWYm41fcvN2KT4.ttf',
    variant: '600',
    family: 'Palanquin Dark',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '60db1df0-c5a7-487a-8821-c80ee183ed64',
    origin: GF_SOURCE,
    url: GF_URL + 'palanquindark/v7/xn76YHgl1nqmANMB-26xC7yuF8Z6ZW41fcvN2KT4.ttf',
    variant: '500',
    family: 'Palanquin Dark',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'df774353-c8ef-4144-ba33-d93ef9f5fd62',
    origin: GF_SOURCE,
    url: GF_URL + 'palanquindark/v7/xn75YHgl1nqmANMB-26xC7yuF_6OTEo9VtfE.ttf',
    variant: 'regular',
    family: 'Palanquin Dark',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'bfcb09ef-d48b-44a0-9538-4457393d68f9',
    origin: GF_SOURCE,
    url: GF_URL + 'scada/v9/RLp6K5Pv5qumeVJq9Y0lT1PEYfE5p6g.ttf',
    variant: '700italic',
    family: 'Scada',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'ca33cc4a-e10a-49b5-b153-8f7af437c2ab',
    origin: GF_SOURCE,
    url: GF_URL + 'scada/v9/RLp8K5Pv5qumeVrU6BEgRVfmZOE5.ttf',
    variant: '700',
    family: 'Scada',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'f7598099-3e36-4095-86a7-087e9400229a',
    origin: GF_SOURCE,
    url: GF_URL + 'scada/v9/RLp_K5Pv5qumeVJqzTEKa1vvffg.ttf',
    variant: 'italic',
    family: 'Scada',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '4a2975f0-3c5a-411f-9af5-0d827e56c8da',
    origin: GF_SOURCE,
    url: GF_URL + 'scada/v9/RLpxK5Pv5qumeWJoxzUobkvv.ttf',
    variant: 'regular',
    family: 'Scada',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '9f409d8c-75e3-40cf-8429-c386fc6c42c3',
    origin: GF_SOURCE,
    url: GF_URL + 'balootamma2/v2/vEFK2_hCAgcR46PaajtrYlBbd-ga8tK1W77HtMo.ttf',
    variant: '800',
    family: 'Baloo Tamma 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'cba04255-daea-437f-b8df-91d22db56065',
    origin: GF_SOURCE,
    url: GF_URL + 'balootamma2/v2/vEFK2_hCAgcR46PaajtrYlBbd_QZ8tK1W77HtMo.ttf',
    variant: '700',
    family: 'Baloo Tamma 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '3c9dfa13-1b20-4882-a63b-328cb4ca5f59',
    origin: GF_SOURCE,
    url: GF_URL + 'balootamma2/v2/vEFK2_hCAgcR46PaajtrYlBbd5AY8tK1W77HtMo.ttf',
    variant: '600',
    family: 'Baloo Tamma 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '1cb68267-345f-436f-bf86-195f2feca3d3',
    origin: GF_SOURCE,
    url: GF_URL + 'balootamma2/v2/vEFK2_hCAgcR46PaajtrYlBbd7wf8tK1W77HtMo.ttf',
    variant: '500',
    family: 'Baloo Tamma 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '5c3a8ada-02c2-4094-ae0a-f585bde25da6',
    origin: GF_SOURCE,
    url: GF_URL + 'balootamma2/v2/vEFX2_hCAgcR46PaajtrYlBbT0g21tqeR7c.ttf',
    variant: 'regular',
    family: 'Baloo Tamma 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '5a95820b-4472-4bd0-a1ad-bd404b14bbf4',
    origin: GF_SOURCE,
    url: GF_URL + 'rochester/v11/6ae-4KCqVa4Zy6Fif-Uy31vWNTMwoQ.ttf',
    variant: 'regular',
    family: 'Rochester',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f103500c-c4a0-4739-a37f-cff6eac2b18c',
    origin: GF_SOURCE,
    url: GF_URL + 'oranienbaum/v9/OZpHg_txtzZKMuXLIVrx-3zn7kz3dpHc.ttf',
    variant: 'regular',
    family: 'Oranienbaum',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'b9dad9cb-a469-40ea-871a-81adc60ce73a',
    origin: GF_SOURCE,
    url: GF_URL + 'suranna/v8/gokuH6ztGkFjWe58tBRZT2KmgP0.ttf',
    variant: 'regular',
    family: 'Suranna',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '4f68a9b9-2a0e-465d-bacc-c94c41ac0ff7',
    origin: GF_SOURCE,
    url: GF_URL + 'bungeeinline/v6/Gg8zN58UcgnlCweMrih332VuDGJ1-FEglsc.ttf',
    variant: 'regular',
    family: 'Bungee Inline',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'af3a73f3-b64c-4eeb-833e-f41c83117676',
    origin: GF_SOURCE,
    url: GF_URL + 'mallanna/v8/hv-Vlzx-KEQb84YaDGwzEzRwVvJ-.ttf',
    variant: 'regular',
    family: 'Mallanna',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e6946f46-b21d-482c-9c27-69c8b95f38dc',
    origin: GF_SOURCE,
    url: GF_URL + 'justanotherhand/v12/845CNN4-AJyIGvIou-6yJKyptyOpOcr_BmmlS5aw.ttf',
    variant: 'regular',
    family: 'Just Another Hand',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'e51a5f6d-8510-4be5-9027-9bb7e9b712ab',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8VrLWSUKTt8iVow.ttf',
    variant: '900italic',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '083824d4-1484-4488-9d4b-03fdd3412638',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8f7LWSUKTt8iVow.ttf',
    variant: '800italic',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '1b5e406d-777a-4e35-bb29-41d4d5efcc67',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8GLLWSUKTt8iVow.ttf',
    variant: '700italic',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '8fa85b9f-f3ae-48b0-9ac6-bf5da20aab40',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8IbLWSUKTt8iVow.ttf',
    variant: '600italic',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '2f588bbf-7e4a-4fd4-b799-2b1fda8739e3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8zbXWSUKTt8iVow.ttf',
    variant: '500italic',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '009581d8-e49c-44b8-b712-ae8da83da44b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8_7XWSUKTt8iVow.ttf',
    variant: 'italic',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '53144fc7-57fa-44d7-95e5-0c72b197e878',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8obXWSUKTt8iVow.ttf',
    variant: '300italic',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '1d8599ac-74ae-42a1-9938-5bef99bd74ef',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8f7XWSUKTt8iVow.ttf',
    variant: '200italic',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '3bbf64d4-2c2a-4e5d-84b8-e08a744cdf7a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbLi4F_bcTWCWp8g.ttf',
    variant: '900',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: 'de88de90-cfce-41cd-9acb-188718f0e2c0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbJG4F_bcTWCWp8g.ttf',
    variant: '800',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '81bb1cc0-e580-494a-a272-ee41093f66da',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbPa4F_bcTWCWp8g.ttf',
    variant: '700',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: 'dfe2efd7-b788-49b1-885c-a35c5237eff2',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbM-4F_bcTWCWp8g.ttf',
    variant: '600',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '2be529e1-7115-4353-93f4-b926a11bc67e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbCO_F_bcTWCWp8g.ttf',
    variant: '500',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: 'b8f2e0f4-9612-4df1-8f20-1649300ddfea',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbBG_F_bcTWCWp8g.ttf',
    variant: 'regular',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '3aec8d57-eb87-4e7d-9e54-a224f34b9cce',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbE-_F_bcTWCWp8g.ttf',
    variant: '300',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '9b247e46-1b07-4783-b4ee-e6b1cacdd690',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'literata/v23/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbJG_F_bcTWCWp8g.ttf',
    variant: '200',
    family: 'Literata',
    category: 'serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '2c1c2494-c38f-4037-a968-f801928693c0',
    origin: GF_SOURCE,
    url: GF_URL + 'arizonia/v11/neIIzCemt4A5qa7mv6WGHK06UY30.ttf',
    variant: 'regular',
    family: 'Arizonia',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '030359f2-c403-4d72-89f9-358bbad146aa',
    origin: GF_SOURCE,
    url: GF_URL + 'racingsansone/v8/sykr-yRtm7EvTrXNxkv5jfKKyDCwL3rmWpIBtA.ttf',
    variant: 'regular',
    family: 'Racing Sans One',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '3dca6de5-7db6-463c-830a-0061fcb6044a',
    origin: GF_SOURCE,
    url: GF_URL + 'averagesans/v9/1Ptpg8fLXP2dlAXR-HlJJNJPBdqazVoK4A.ttf',
    variant: 'regular',
    family: 'Average Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '2046ac63-e613-449e-8f86-31ec978c0c27',
    origin: GF_SOURCE,
    url: GF_URL + 'nobile/v12/m8JWjflSeaOVl1iGXJVvdZRFRG-K3Mud.ttf',
    variant: '700italic',
    family: 'Nobile',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'd28a9ff0-9e32-4290-b2cb-7518f9e24766',
    origin: GF_SOURCE,
    url: GF_URL + 'nobile/v12/m8JQjflSeaOVl1iO4ojzcJ5BZmqa3A.ttf',
    variant: '700',
    family: 'Nobile',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c1bc7d2b-23a5-409d-83b3-96d87bb2ae3e',
    origin: GF_SOURCE,
    url: GF_URL + 'nobile/v12/m8JWjflSeaOVl1iGXJUnc5RFRG-K3Mud.ttf',
    variant: '500italic',
    family: 'Nobile',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '43974474-eccc-42c1-aeb9-260793f5c149',
    origin: GF_SOURCE,
    url: GF_URL + 'nobile/v12/m8JQjflSeaOVl1iOqo7zcJ5BZmqa3A.ttf',
    variant: '500',
    family: 'Nobile',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '873b3494-f224-42f9-9499-1ef93088bd6d',
    origin: GF_SOURCE,
    url: GF_URL + 'nobile/v12/m8JRjflSeaOVl1iGXK3TWrBNb3OD.ttf',
    variant: 'italic',
    family: 'Nobile',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'ee70a6b6-ac69-4c62-95f8-c3687cbc7f9c',
    origin: GF_SOURCE,
    url: GF_URL + 'nobile/v12/m8JTjflSeaOVl1i2XqfXeLVdbw.ttf',
    variant: 'regular',
    family: 'Nobile',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '9e7cbf45-6ee2-42b9-b617-58fa3d57dac8',
    origin: GF_SOURCE,
    url: GF_URL + 'holtwoodonesc/v11/yYLx0hLR0P-3vMFSk1TCq3Txg5B3cbb6LZttyg.ttf',
    variant: 'regular',
    family: 'Holtwood One SC',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '39774831-cd2b-4d5b-8bb4-3c3c2c6d4b07',
    origin: GF_SOURCE,
    url: GF_URL + 'miriamlibre/v7/DdT-798HsHwubBAqfkcBTL_X3LbbRcC7_-Z7Hg.ttf',
    variant: '700',
    family: 'Miriam Libre',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'c6d34c2b-0fdc-4bf7-9162-4e2d42367b91',
    origin: GF_SOURCE,
    url: GF_URL + 'miriamlibre/v7/DdTh798HsHwubBAqfkcBTL_vYJn_Teun9g.ttf',
    variant: 'regular',
    family: 'Miriam Libre',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '5a793ed3-309f-4bfb-b1f2-ee40bbd89da3',
    origin: GF_SOURCE,
    url: GF_URL + 'caudex/v10/esDV311QOP6BJUr4yMo4kJ8GOJSuGdLB.ttf',
    variant: '700italic',
    family: 'Caudex',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '62d642c2-b396-4ed9-8a91-df0c6d80d5f6',
    origin: GF_SOURCE,
    url: GF_URL + 'caudex/v10/esDT311QOP6BJUrwdteklZUCGpG-GQ.ttf',
    variant: '700',
    family: 'Caudex',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '99333464-4102-4de2-ae85-82ea8409b3ab',
    origin: GF_SOURCE,
    url: GF_URL + 'caudex/v10/esDS311QOP6BJUr4yPKEv7sOE4in.ttf',
    variant: 'italic',
    family: 'Caudex',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '58a89c04-7592-46a1-9854-1bfcc9c63ef8',
    origin: GF_SOURCE,
    url: GF_URL + 'caudex/v10/esDQ311QOP6BJUrIyviAnb4eEw.ttf',
    variant: 'regular',
    family: 'Caudex',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '12e22391-0769-4524-8f7c-3d77894331df',
    origin: GF_SOURCE,
    url: GF_URL + 'cinzeldecorative/v9/daaHSScvJGqLYhG8nNt8KPPswUAPniZQa-lDQzCLlQXE.ttf',
    variant: '900',
    family: 'Cinzel Decorative',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '051e1f4b-c24a-4329-aa88-d3a4c06db0dc',
    origin: GF_SOURCE,
    url: GF_URL + 'cinzeldecorative/v9/daaHSScvJGqLYhG8nNt8KPPswUAPniZoaelDQzCLlQXE.ttf',
    variant: '700',
    family: 'Cinzel Decorative',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '52d22445-1aa2-4c97-93d5-28e043ec35fe',
    origin: GF_SOURCE,
    url: GF_URL + 'cinzeldecorative/v9/daaCSScvJGqLYhG8nNt8KPPswUAPnh7URs1LaCyC.ttf',
    variant: 'regular',
    family: 'Cinzel Decorative',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '30297c75-8c8a-47fe-b759-ea0853013567',
    origin: GF_SOURCE,
    url: GF_URL + 'sen/v2/6xKudSxYI9__O9OoKkH1JHUQSQ.ttf',
    variant: '800',
    family: 'Sen',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'fadb1cc9-1405-4da3-bf8c-c5e677793fb8',
    origin: GF_SOURCE,
    url: GF_URL + 'sen/v2/6xKudSxYI9__J9CoKkH1JHUQSQ.ttf',
    variant: '700',
    family: 'Sen',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'fb57bdd4-ecd2-45f3-a891-5f2e6af8c1e1',
    origin: GF_SOURCE,
    url: GF_URL + 'sen/v2/6xKjdSxYI9_Hm_-MImrpLQ.ttf',
    variant: 'regular',
    family: 'Sen',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '485d4b65-9173-4914-a245-adb0da7388d5',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamalar/v7/MCoKzAXyz8LOE2FpJMxZqINYAdAB62ruoAZW.ttf',
    variant: '800',
    family: 'Mukta Malar',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '65562e57-ed05-454b-be3b-625b8613c295',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamalar/v7/MCoKzAXyz8LOE2FpJMxZqINEAtAB62ruoAZW.ttf',
    variant: '700',
    family: 'Mukta Malar',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '2b8b9698-5048-4913-8edc-c584ce4daaf0',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamalar/v7/MCoKzAXyz8LOE2FpJMxZqIMgA9AB62ruoAZW.ttf',
    variant: '600',
    family: 'Mukta Malar',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '6e3720a4-30ee-4084-aa8a-c150508a9a05',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamalar/v7/MCoKzAXyz8LOE2FpJMxZqIMMBNAB62ruoAZW.ttf',
    variant: '500',
    family: 'Mukta Malar',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'af3b9aa6-26b0-4b2e-b721-da70ac1a70d4',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamalar/v7/MCoXzAXyz8LOE2FpJMxZqLv4LfQJwHbn.ttf',
    variant: 'regular',
    family: 'Mukta Malar',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '2e45800b-1a91-4d15-a8fd-eb5ae70bf233',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamalar/v7/MCoKzAXyz8LOE2FpJMxZqINUBdAB62ruoAZW.ttf',
    variant: '300',
    family: 'Mukta Malar',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '7e75c3e9-e705-4143-bd1d-79882ddb1f9c',
    origin: GF_SOURCE,
    url: GF_URL + 'muktamalar/v7/MCoKzAXyz8LOE2FpJMxZqIMwBtAB62ruoAZW.ttf',
    variant: '200',
    family: 'Mukta Malar',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '63c92891-95dc-4a04-a7f1-347707ccca66',
    origin: GF_SOURCE,
    url: GF_URL + 'yesteryear/v9/dg4g_p78rroaKl8kRKo1r7wHTwonmyw.ttf',
    variant: 'regular',
    family: 'Yesteryear',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '5b2c5c55-4c54-4aa9-a8a8-e1add97ca541',
    origin: GF_SOURCE,
    url: GF_URL + 'arbutusslab/v9/oY1Z8e7OuLXkJGbXtr5ba7ZVa68dJlaFAQ.ttf',
    variant: 'regular',
    family: 'Arbutus Slab',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'be832bb2-d2f2-4d88-969e-283847bbe510',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'zillaslabhighlight/v10/gNMUW2BrTpK8-inLtBJgMMfbm6uNVDvRxiP0TET4YmVF0Mb6.ttf',
    variant: '700',
    family: 'Zilla Slab Highlight',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'c53f5e16-81eb-48b1-8e34-5229a8dd8c4b',
    origin: GF_SOURCE,
    url:
      GF_URL + 'zillaslabhighlight/v10/gNMbW2BrTpK8-inLtBJgMMfbm6uNVDvRxhtIY2DwSXlM.ttf',
    variant: 'regular',
    family: 'Zilla Slab Highlight',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a19a61df-ce43-4252-ba49-3b671eefaf39',
    origin: GF_SOURCE,
    url: GF_URL + 'leckerlione/v11/V8mCoQH8VCsNttEnxnGQ-1itLZxcBtItFw.ttf',
    variant: 'regular',
    family: 'Leckerli One',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '636163fb-f7d5-4578-af03-faa0fe25bace',
    origin: GF_SOURCE,
    url: GF_URL + 'bowlbyonesc/v12/DtVlJxerQqQm37tzN3wMug9Pzgj8owhNjuE.ttf',
    variant: 'regular',
    family: 'Bowlby One SC',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'e0d14e72-2ae8-48a1-8a7f-9c8918220950',
    origin: GF_SOURCE,
    url: GF_URL + 'nixieone/v11/lW-8wjkKLXjg5y2o2uUoUOFzpS-yLw.ttf',
    variant: 'regular',
    family: 'Nixie One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '45495717-6c3b-4b62-afb3-d8883e22183c',
    origin: GF_SOURCE,
    url: GF_URL + 'schoolbell/v11/92zQtBZWOrcgoe-fgnJIVxIQ6mRqfiQ.ttf',
    variant: 'regular',
    family: 'Schoolbell',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '550d9f8b-55c5-48ae-b89b-28a478c61a40',
    origin: GF_SOURCE,
    url: GF_URL + 'changaone/v13/xfu20W3wXn3QLUJXhzq42ATivJXeO67ISw.ttf',
    variant: 'italic',
    family: 'Changa One',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'd8a53823-ee05-488e-9ad1-29ab5745c85d',
    origin: GF_SOURCE,
    url: GF_URL + 'changaone/v13/xfu00W3wXn3QLUJXhzq46AbouLfbK64.ttf',
    variant: 'regular',
    family: 'Changa One',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'e2b5f10f-9269-4f26-8e60-1ce120e8ca97',
    origin: GF_SOURCE,
    url: GF_URL + 'londrinasolid/v10/flUiRq6sw40kQEJxWNgkLuudGfvdDzY0n53oTrcL.ttf',
    variant: '900',
    family: 'Londrina Solid',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8a1e3100-9d3f-4530-a96c-0aef50e96562',
    origin: GF_SOURCE,
    url: GF_URL + 'londrinasolid/v10/flUhRq6sw40kQEJxWNgkLuudGcNZIhI8tIHh.ttf',
    variant: 'regular',
    family: 'Londrina Solid',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8551a6ca-aca4-4129-863f-848f50def78f',
    origin: GF_SOURCE,
    url: GF_URL + 'londrinasolid/v10/flUiRq6sw40kQEJxWNgkLuudGfv1CjY0n53oTrcL.ttf',
    variant: '300',
    family: 'Londrina Solid',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '468ebfa8-2807-447b-b90e-16a407ab2c4f',
    origin: GF_SOURCE,
    url: GF_URL + 'londrinasolid/v10/flUjRq6sw40kQEJxWNgkLuudGfs9KBYesZHhV64.ttf',
    variant: '100',
    family: 'Londrina Solid',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6fbdcdb5-36e9-4b37-a7cd-bca0f2304da4',
    origin: GF_SOURCE,
    url: GF_URL + 'aldrich/v11/MCoTzAn-1s3IGyJMZaAS3pP5H_E.ttf',
    variant: 'regular',
    family: 'Aldrich',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'dcd51d54-caae-49d7-8b27-319938036ddd',
    origin: GF_SOURCE,
    url: GF_URL + 'anonymouspro/v14/rP2ap2a15UIB7Un-bOeISG3pHl4OTCzc6IG30KqB9Q.ttf',
    variant: '700italic',
    family: 'Anonymous Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '7459d4c7-02b3-4918-a7d2-5dba0109dc05',
    origin: GF_SOURCE,
    url: GF_URL + 'anonymouspro/v14/rP2cp2a15UIB7Un-bOeISG3pFuAT0CnW7KOywKo.ttf',
    variant: '700',
    family: 'Anonymous Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'f57b5ba7-efc2-4e63-82c4-f9de019e655b',
    origin: GF_SOURCE,
    url: GF_URL + 'anonymouspro/v14/rP2fp2a15UIB7Un-bOeISG3pHl428AP44Kqr2Q.ttf',
    variant: 'italic',
    family: 'Anonymous Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '3e80b3bc-6cdb-49ef-a6df-6c90c7daf665',
    origin: GF_SOURCE,
    url: GF_URL + 'anonymouspro/v14/rP2Bp2a15UIB7Un-bOeISG3pLlw89CH98Ko.ttf',
    variant: 'regular',
    family: 'Anonymous Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'ee6df81b-31bd-4668-838c-55cbc55d14ce',
    origin: GF_SOURCE,
    url: GF_URL + 'dohyeon/v11/TwMN-I8CRRU2zM86HFE3ZwaH__-C.ttf',
    variant: 'regular',
    family: 'Do Hyeon',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '5f8e7f65-5c9f-4ab5-a061-a76a4735283e',
    origin: GF_SOURCE,
    url: GF_URL + 'vt323/v12/pxiKyp0ihIEF2hsYHpT2dkNE.ttf',
    variant: 'regular',
    family: 'VT323',
    category: 'monospace',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '20012da0-9395-42d8-9b89-650d868c77f6',
    origin: GF_SOURCE,
    url: GF_URL + 'cabinsketch/v14/QGY2z_kZZAGCONcK2A4bGOj0I_1o4dLyI4CMFw.ttf',
    variant: '700',
    family: 'Cabin Sketch',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'c3132f11-2e3d-482a-9cf2-fa0414fbc200',
    origin: GF_SOURCE,
    url: GF_URL + 'cabinsketch/v14/QGYpz_kZZAGCONcK2A4bGOjMn9JM6fnuKg.ttf',
    variant: 'regular',
    family: 'Cabin Sketch',
    category: 'display',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '819c4054-51a4-43fb-b4fa-66de43f38d0a',
    origin: GF_SOURCE,
    url: GF_URL + 'daysone/v10/mem9YaCnxnKRiYZOCLYVeLkWVNBt.ttf',
    variant: 'regular',
    family: 'Days One',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '21bd0d7e-0321-41ec-ba37-056fabcf1539',
    origin: GF_SOURCE,
    url: GF_URL + 'redhattext/v3/RrQKbohi_ic6B3yVSzGBrMxQbZ-UHu2BF1yELmgy.ttf',
    variant: '700italic',
    family: 'Red Hat Text',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '642b719d-2f6e-4040-b30e-b641f4654e2a',
    origin: GF_SOURCE,
    url: GF_URL + 'redhattext/v3/RrQIbohi_ic6B3yVSzGBrMxY04IIG-eFNVmULg.ttf',
    variant: '700',
    family: 'Red Hat Text',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '952d768e-c816-441f-8b26-882a0f408638',
    origin: GF_SOURCE,
    url: GF_URL + 'redhattext/v3/RrQKbohi_ic6B3yVSzGBrMxQbZ_cGO2BF1yELmgy.ttf',
    variant: '500italic',
    family: 'Red Hat Text',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'b41dc900-86ea-4983-b3d5-1f4309a50305',
    origin: GF_SOURCE,
    url: GF_URL + 'redhattext/v3/RrQIbohi_ic6B3yVSzGBrMxYm4QIG-eFNVmULg.ttf',
    variant: '500',
    family: 'Red Hat Text',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'a6deeea9-818e-45b1-903f-972de247cab2',
    origin: GF_SOURCE,
    url: GF_URL + 'redhattext/v3/RrQJbohi_ic6B3yVSzGBrMxQbacoMcmJPECN.ttf',
    variant: 'italic',
    family: 'Red Hat Text',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '67a9e602-d1c1-4979-9c2d-e8db059c4cf9',
    origin: GF_SOURCE,
    url: GF_URL + 'redhattext/v3/RrQXbohi_ic6B3yVSzGBrMxgb60sE8yZPA.ttf',
    variant: 'regular',
    family: 'Red Hat Text',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '5f5b6429-fe19-4348-9942-da6e10a49cd8',
    origin: GF_SOURCE,
    url: GF_URL + 'eczar/v9/BXRovF3Pi-DLmzWCKst622v9WNjW.ttf',
    variant: '800',
    family: 'Eczar',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'a50506fe-e5a9-4163-be5a-32eb85ae0cfd',
    origin: GF_SOURCE,
    url: GF_URL + 'eczar/v9/BXRovF3Pi-DLmzWeKct622v9WNjW.ttf',
    variant: '700',
    family: 'Eczar',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'e3261c8f-aa64-452c-958f-1b4bd3c155ed',
    origin: GF_SOURCE,
    url: GF_URL + 'eczar/v9/BXRovF3Pi-DLmzX6KMt622v9WNjW.ttf',
    variant: '600',
    family: 'Eczar',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '99ad90dc-acb0-4769-912c-1bd290167440',
    origin: GF_SOURCE,
    url: GF_URL + 'eczar/v9/BXRovF3Pi-DLmzXWL8t622v9WNjW.ttf',
    variant: '500',
    family: 'Eczar',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '92afdb9f-b9cc-4d20-ad45-bee5f2bcb390',
    origin: GF_SOURCE,
    url: GF_URL + 'eczar/v9/BXRlvF3Pi-DLmw0iBu9y8Hf0.ttf',
    variant: 'regular',
    family: 'Eczar',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'b43120a9-5bd8-4612-bbd9-32e146f7bd58',
    origin: GF_SOURCE,
    url: GF_URL + 'lexenddeca/v7/K2F1fZFYk-dHSE0UPPuwQ6qgLS76ZHOM.ttf',
    variant: 'regular',
    family: 'Lexend Deca',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '4d1bbc26-a6a2-4bd9-a403-2e10359389ea',
    origin: GF_SOURCE,
    url: GF_URL + 'seaweedscript/v8/bx6cNx6Tne2pxOATYE8C_Rsoe0WJ-KcGVbLW.ttf',
    variant: 'regular',
    family: 'Seaweed Script',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ef534b64-5f9a-4a35-ac69-6ce8124c0b00',
    origin: GF_SOURCE,
    url: GF_URL + 'reeniebeanie/v11/z7NSdR76eDkaJKZJFkkjuvWxbP2_qoOgf_w.ttf',
    variant: 'regular',
    family: 'Reenie Beanie',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'e3cdf168-fd58-445b-8996-15ed1abe1281',
    origin: GF_SOURCE,
    url: GF_URL + 'molengo/v11/I_uuMpWeuBzZNBtQbbRQkiCvs5Y.ttf',
    variant: 'regular',
    family: 'Molengo',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'ab323705-9dd6-4c0f-8753-7cfaa0f68d9f',
    origin: GF_SOURCE,
    url: GF_URL + 'knewave/v9/sykz-yx0lLcxQaSItSq9-trEvlQ.ttf',
    variant: 'regular',
    family: 'Knewave',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '59211017-6d61-4f10-a8a5-e42000f7c172',
    origin: GF_SOURCE,
    url: GF_URL + 'scheherazade/v20/YA9Lr0yF4ETZN60keViq1kQYC7yMjt3V_dB0Yw.ttf',
    variant: '700',
    family: 'Scheherazade',
    category: 'serif',
    version: 'v20',
    subset: 'latin',
  },
  {
    id: '05c93b56-5f7a-46b1-9ff4-db058488649f',
    origin: GF_SOURCE,
    url: GF_URL + 'scheherazade/v20/YA9Ur0yF4ETZN60keViq1kQgt5OohvbJ9A.ttf',
    variant: 'regular',
    family: 'Scheherazade',
    category: 'serif',
    version: 'v20',
    subset: 'latin',
  },
  {
    id: '67806676-91e6-4582-a722-60f38e475f9b',
    origin: GF_SOURCE,
    url: GF_URL + 'caveatbrush/v6/EYq0maZfwr9S9-ETZc3fKXtMW7mT03pdQw.ttf',
    variant: 'regular',
    family: 'Caveat Brush',
    category: 'handwriting',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'e13c7749-2ed1-4bb2-bc88-2591d8f59035',
    origin: GF_SOURCE,
    url: GF_URL + 'charm/v5/7cHrv4oii5K0Md6TDss8yn4hnCci.ttf',
    variant: '700',
    family: 'Charm',
    category: 'handwriting',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1f392141-0d9e-4d8a-b53d-f9f168b3d720',
    origin: GF_SOURCE,
    url: GF_URL + 'charm/v5/7cHmv4oii5K0MeYvIe804WIo.ttf',
    variant: 'regular',
    family: 'Charm',
    category: 'handwriting',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b05587fc-d6ed-49e5-aeec-62f40461739f',
    origin: GF_SOURCE,
    url: GF_URL + 'rambla/v8/snfus0ip98hx6mrEIYC2O_l86p6TYS-Y.ttf',
    variant: '700italic',
    family: 'Rambla',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '1c4995a1-169e-41c5-a56e-297bf72efd75',
    origin: GF_SOURCE,
    url: GF_URL + 'rambla/v8/snfos0ip98hx6mrMn50qPvN4yJuDYQ.ttf',
    variant: '700',
    family: 'Rambla',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '61482d2d-a428-40c6-b00c-52e14ba1e7b6',
    origin: GF_SOURCE,
    url: GF_URL + 'rambla/v8/snfps0ip98hx6mrEIbgKFN10wYKa.ttf',
    variant: 'italic',
    family: 'Rambla',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '63a20032-0e59-48d2-8c1a-61d11ebb046c',
    origin: GF_SOURCE,
    url: GF_URL + 'rambla/v8/snfrs0ip98hx6mr0I7IONthkwQ.ttf',
    variant: 'regular',
    family: 'Rambla',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'a7a252b2-1e7c-49cf-8d3b-4f09e9520065',
    origin: GF_SOURCE,
    url: GF_URL + 'bevan/v12/4iCj6KZ0a9NXjF8aUir7tlSJ.ttf',
    variant: 'regular',
    family: 'Bevan',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'd47d61a3-c517-4700-8f03-6beff7b0246b',
    origin: GF_SOURCE,
    url: GF_URL + 'sansita/v5/QldJNTRRphEb_-V7LBuJzX5-xodqz_joDQ.ttf',
    variant: '900italic',
    family: 'Sansita',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'c8856414-a889-45d2-8478-d09299899e23',
    origin: GF_SOURCE,
    url: GF_URL + 'sansita/v5/QldLNTRRphEb_-V7JJ2WaXl0wqVv3_g.ttf',
    variant: '900',
    family: 'Sansita',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2cdc4285-082d-4d6e-8e2d-d16ffeb87aa2',
    origin: GF_SOURCE,
    url: GF_URL + 'sansita/v5/QldJNTRRphEb_-V7LBuJ6X9-xodqz_joDQ.ttf',
    variant: '800italic',
    family: 'Sansita',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '74ea004c-bf2d-4a05-b8c4-efd4362a345e',
    origin: GF_SOURCE,
    url: GF_URL + 'sansita/v5/QldLNTRRphEb_-V7JLmXaXl0wqVv3_g.ttf',
    variant: '800',
    family: 'Sansita',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '58e3f5f9-febb-439e-948a-8d994b03ae2c',
    origin: GF_SOURCE,
    url: GF_URL + 'sansita/v5/QldJNTRRphEb_-V7LBuJ9Xx-xodqz_joDQ.ttf',
    variant: '700italic',
    family: 'Sansita',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'cda0eb3e-3048-4dd3-a7de-a60fc3da62ba',
    origin: GF_SOURCE,
    url: GF_URL + 'sansita/v5/QldLNTRRphEb_-V7JKWUaXl0wqVv3_g.ttf',
    variant: '700',
    family: 'Sansita',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ba360ded-b72c-4fe3-8d15-405b14edbb3a',
    origin: GF_SOURCE,
    url: GF_URL + 'sansita/v5/QldMNTRRphEb_-V7LBuxSVNazqx2xg.ttf',
    variant: 'italic',
    family: 'Sansita',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'aac35cba-8a53-4c6f-b560-4b49afd3a4ec',
    origin: GF_SOURCE,
    url: GF_URL + 'sansita/v5/QldONTRRphEb_-V7HBm7TXFf3qw.ttf',
    variant: 'regular',
    family: 'Sansita',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '36d917f7-79f8-488f-8ec4-6bd018ad8aec',
    origin: GF_SOURCE,
    url: GF_URL + 'lateef/v18/hESw6XVnNCxEvkbMpheEZo_H_w.ttf',
    variant: 'regular',
    family: 'Lateef',
    category: 'handwriting',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '3f216ee0-0cd6-4a5f-8ef2-09a627df9c75',
    origin: GF_SOURCE,
    url: GF_URL + 'kameron/v11/vm8zdR7vXErQxuzniAIfC-3jfHb--NY.ttf',
    variant: '700',
    family: 'Kameron',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'd0d55fba-ff30-4520-9c6b-d8bbe6ad227f',
    origin: GF_SOURCE,
    url: GF_URL + 'kameron/v11/vm82dR7vXErQxuznsL4wL-XIYH8.ttf',
    variant: 'regular',
    family: 'Kameron',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a9924628-c8c1-4dfa-961c-6e1511133bf7',
    origin: GF_SOURCE,
    url: GF_URL + 'shrikhand/v6/a8IbNovtLWfR7T7bMJwbBIiQ0zhMtA.ttf',
    variant: 'regular',
    family: 'Shrikhand',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'dd37a952-637b-4e86-ad1c-4f91204d7579',
    origin: GF_SOURCE,
    url: GF_URL + 'italianno/v10/dg4n_p3sv6gCJkwzT6Rnj5YpQwM-gg.ttf',
    variant: 'regular',
    family: 'Italianno',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b03c5c1a-ba94-4c72-b285-8e4fa3475ee3',
    origin: GF_SOURCE,
    url: GF_URL + 'muktavaani/v8/3JnkSD_-ynaxmxnEfVHPIGWlUMBD-u97MW1a.ttf',
    variant: '800',
    family: 'Mukta Vaani',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '23645963-2125-4b92-a5de-3c854b9e6f76',
    origin: GF_SOURCE,
    url: GF_URL + 'muktavaani/v8/3JnkSD_-ynaxmxnEfVHPIGW5U8BD-u97MW1a.ttf',
    variant: '700',
    family: 'Mukta Vaani',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '7c69834b-9e60-40e0-b5d8-ba1b99a133d0',
    origin: GF_SOURCE,
    url: GF_URL + 'muktavaani/v8/3JnkSD_-ynaxmxnEfVHPIGXdUsBD-u97MW1a.ttf',
    variant: '600',
    family: 'Mukta Vaani',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '59982e69-b695-444b-9064-cce0f7ea2dff',
    origin: GF_SOURCE,
    url: GF_URL + 'muktavaani/v8/3JnkSD_-ynaxmxnEfVHPIGXxVcBD-u97MW1a.ttf',
    variant: '500',
    family: 'Mukta Vaani',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '1410d146-030c-41da-a9cb-3cbb95924d6a',
    origin: GF_SOURCE,
    url: GF_URL + 'muktavaani/v8/3Jn5SD_-ynaxmxnEfVHPIF0FfORL0fNy.ttf',
    variant: 'regular',
    family: 'Mukta Vaani',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '5f462e4a-3035-4cc4-a698-368a47c841dc',
    origin: GF_SOURCE,
    url: GF_URL + 'muktavaani/v8/3JnkSD_-ynaxmxnEfVHPIGWpVMBD-u97MW1a.ttf',
    variant: '300',
    family: 'Mukta Vaani',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '4d5b4f1a-4dda-4c3b-af8c-e6b09e85d6c9',
    origin: GF_SOURCE,
    url: GF_URL + 'muktavaani/v8/3JnkSD_-ynaxmxnEfVHPIGXNV8BD-u97MW1a.ttf',
    variant: '200',
    family: 'Mukta Vaani',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '520f9fbc-5a6a-4857-8ccf-39a6b7e0079c',
    origin: GF_SOURCE,
    url: GF_URL + 'rubikmonoone/v9/UqyJK8kPP3hjw6ANTdfRk9YSN-8wRqQrc_j9.ttf',
    variant: 'regular',
    family: 'Rubik Mono One',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'f0d134db-6c95-451b-89ae-7a1427c92e5c',
    origin: GF_SOURCE,
    url: GF_URL + 'bentham/v11/VdGeAZQPEpYfmHglKWw7CJaK_y4.ttf',
    variant: 'regular',
    family: 'Bentham',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'abf9a93b-d808-4a8e-8ed5-bf7847d23a63',
    origin: GF_SOURCE,
    url: GF_URL + 'mada/v11/7Au_p_0qnzeSdbHhCCL8zkwMIFg.ttf',
    variant: '900',
    family: 'Mada',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b72a1a27-bfe1-4dc9-a455-8027d3f97007',
    origin: GF_SOURCE,
    url: GF_URL + 'mada/v11/7Au_p_0qnzeSdYnjCCL8zkwMIFg.ttf',
    variant: '700',
    family: 'Mada',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '2feec110-5ff0-4339-9f43-bb206478c2de',
    origin: GF_SOURCE,
    url: GF_URL + 'mada/v11/7Au_p_0qnzeSde3iCCL8zkwMIFg.ttf',
    variant: '600',
    family: 'Mada',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'ff8ba7ca-d2bd-42d8-96ef-a64cc22a006b',
    origin: GF_SOURCE,
    url: GF_URL + 'mada/v11/7Au_p_0qnzeSdcHlCCL8zkwMIFg.ttf',
    variant: '500',
    family: 'Mada',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '3bd03332-37fa-4ef6-b821-7606389a3659',
    origin: GF_SOURCE,
    url: GF_URL + 'mada/v11/7Auwp_0qnzeSTTXMLCrX0kU.ttf',
    variant: 'regular',
    family: 'Mada',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'e8440300-f117-4097-82fa-d053fe905bbc',
    origin: GF_SOURCE,
    url: GF_URL + 'mada/v11/7Au_p_0qnzeSdZnkCCL8zkwMIFg.ttf',
    variant: '300',
    family: 'Mada',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'af284e8a-4df0-4946-82eb-4ac0218b2a34',
    origin: GF_SOURCE,
    url: GF_URL + 'mada/v11/7Au_p_0qnzeSdf3nCCL8zkwMIFg.ttf',
    variant: '200',
    family: 'Mada',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'd1ae4efd-83af-4c0d-8b54-43e5cf1a1962',
    origin: GF_SOURCE,
    url: GF_URL + 'gildadisplay/v8/t5tmIRoYMoaYG0WEOh7HwMeR7TnFrpOHYh4.ttf',
    variant: 'regular',
    family: 'Gilda Display',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '3caeafe9-cb32-42fa-9d2d-5af90f925cf9',
    origin: GF_SOURCE,
    url: GF_URL + 'kadwa/v5/rnCr-x5V0g7ipix7auM-mHnOSOuk.ttf',
    variant: '700',
    family: 'Kadwa',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '21e859d7-e6c9-4025-a924-0de2e93fcad6',
    origin: GF_SOURCE,
    url: GF_URL + 'kadwa/v5/rnCm-x5V0g7iphTHRcc2s2XH.ttf',
    variant: 'regular',
    family: 'Kadwa',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '007f610a-6284-4673-b4f2-33f543d2fc78',
    origin: GF_SOURCE,
    url: GF_URL + 'berkshireswash/v9/ptRRTi-cavZOGqCvnNJDl5m5XmNPrcQybX4pQA.ttf',
    variant: 'regular',
    family: 'Berkshire Swash',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '6117c2e3-2eb5-49e4-9250-cc664606d852',
    origin: GF_SOURCE,
    url: GF_URL + 'firamono/v9/N0bS2SlFPv1weGeLZDtondv3mf3VaZBRBQ.ttf',
    variant: '700',
    family: 'Fira Mono',
    category: 'monospace',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '6ee74b44-824b-46f6-afb8-e601a3ee6b21',
    origin: GF_SOURCE,
    url: GF_URL + 'firamono/v9/N0bS2SlFPv1weGeLZDto1d33mf3VaZBRBQ.ttf',
    variant: '500',
    family: 'Fira Mono',
    category: 'monospace',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'e3b756d9-1c32-457c-829f-b485907a5cc6',
    origin: GF_SOURCE,
    url: GF_URL + 'firamono/v9/N0bX2SlFPv1weGeLZDtQIfTTkdbJYA.ttf',
    variant: 'regular',
    family: 'Fira Mono',
    category: 'monospace',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'e1bd4f3a-a9e7-49bd-b9ac-941bc5e4b3bd',
    origin: GF_SOURCE,
    url: GF_URL + 'allerta/v11/TwMO-IAHRlkbx940UnEdSQqO5uY.ttf',
    variant: 'regular',
    family: 'Allerta',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'fddd3a2f-7e9d-432f-8f6f-bb08c2666f64',
    origin: GF_SOURCE,
    url: GF_URL + 'lalezar/v9/zrfl0HLVx-HwTP82UaDyIiL0RCg.ttf',
    variant: 'regular',
    family: 'Lalezar',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '4925299e-27d0-4862-93d5-5346ab1f4fa1',
    origin: GF_SOURCE,
    url: GF_URL + 'capriola/v8/wXKoE3YSppcvo1PDln_8L-AinG8y.ttf',
    variant: 'regular',
    family: 'Capriola',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '18847f94-b5b3-449d-9a83-f4abeceb02d8',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'coveredbyyourgrace/v10/QGYwz-AZahWOJJI9kykWW9mD6opopoqXSOS0FgItq6bFIg.ttf',
    variant: 'regular',
    family: 'Covered By Your Grace',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '9d7c4ec1-6945-4734-89c5-2381f11da67c',
    origin: GF_SOURCE,
    url: GF_URL + 'boogaloo/v12/kmK-Zq45GAvOdnaW6x1F_SrQo_1K.ttf',
    variant: 'regular',
    family: 'Boogaloo',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '42e13a45-01bc-41c9-8e2a-1adbe043b296',
    origin: GF_SOURCE,
    url: GF_URL + 'yrsa/v6/wlpxgwnQFlxs3bf63IQ73W5OcCk.ttf',
    variant: '700',
    family: 'Yrsa',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c3dcb23a-cf00-49eb-854d-d13b2316211a',
    origin: GF_SOURCE,
    url: GF_URL + 'yrsa/v6/wlpxgwnQFlxs3dP73IQ73W5OcCk.ttf',
    variant: '600',
    family: 'Yrsa',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'b972f39c-8199-48e6-be56-f764d1e268b3',
    origin: GF_SOURCE,
    url: GF_URL + 'yrsa/v6/wlpxgwnQFlxs3f_83IQ73W5OcCk.ttf',
    variant: '500',
    family: 'Yrsa',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '031ad5d6-de2e-4a3d-a580-6f20f25470d0',
    origin: GF_SOURCE,
    url: GF_URL + 'yrsa/v6/wlp-gwnQFlxs5QvV-IwQwWc.ttf',
    variant: 'regular',
    family: 'Yrsa',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '17c79acf-b98c-45bc-8d89-e1304b6b5d01',
    origin: GF_SOURCE,
    url: GF_URL + 'yrsa/v6/wlpxgwnQFlxs3af93IQ73W5OcCk.ttf',
    variant: '300',
    family: 'Yrsa',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a640ce20-9486-4496-9dee-5dd243d260b0',
    origin: GF_SOURCE,
    url: GF_URL + 'martelsans/v7/h0GxssGi7VdzDgKjM-4d8hBb4MuHFUknqMxQ.ttf',
    variant: '900',
    family: 'Martel Sans',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'f1160d2d-4ad8-4723-bf7b-b8c2358c7a80',
    origin: GF_SOURCE,
    url: GF_URL + 'martelsans/v7/h0GxssGi7VdzDgKjM-4d8hB_4cuHFUknqMxQ.ttf',
    variant: '800',
    family: 'Martel Sans',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '7195bc29-0ca4-4652-be22-01193e41b08b',
    origin: GF_SOURCE,
    url: GF_URL + 'martelsans/v7/h0GxssGi7VdzDgKjM-4d8hBj4suHFUknqMxQ.ttf',
    variant: '700',
    family: 'Martel Sans',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'c5bc2092-f17e-4f89-9260-5c8004da20e8',
    origin: GF_SOURCE,
    url: GF_URL + 'martelsans/v7/h0GxssGi7VdzDgKjM-4d8hAH48uHFUknqMxQ.ttf',
    variant: '600',
    family: 'Martel Sans',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '4813e39d-5759-4f13-90cf-b6dd38e6be30',
    origin: GF_SOURCE,
    url: GF_URL + 'martelsans/v7/h0GsssGi7VdzDgKjM-4d8ijfze-PPlUu.ttf',
    variant: 'regular',
    family: 'Martel Sans',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '5d81c324-cad8-40f0-819b-5c4d49b96ad9',
    origin: GF_SOURCE,
    url: GF_URL + 'martelsans/v7/h0GxssGi7VdzDgKjM-4d8hBz5cuHFUknqMxQ.ttf',
    variant: '300',
    family: 'Martel Sans',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '88dedffe-b7ed-4801-9a4e-d93c1fb55499',
    origin: GF_SOURCE,
    url: GF_URL + 'martelsans/v7/h0GxssGi7VdzDgKjM-4d8hAX5suHFUknqMxQ.ttf',
    variant: '200',
    family: 'Martel Sans',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '52ebb69b-98e7-4e85-a53d-74d8638686ab',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTra2_R3ABgum-uoQ.ttf',
    variant: '900',
    family: 'Saira Extra Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '1e14f808-d3cc-4060-9d95-93dfb7d3df89',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrT27R3ABgum-uoQ.ttf',
    variant: '800',
    family: 'Saira Extra Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'da671594-1e5a-4369-8a99-dab04871beaf',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrU23R3ABgum-uoQ.ttf',
    variant: '700',
    family: 'Saira Extra Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '511bd5db-b01a-48f6-b1f7-8559a59e0a78',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrN2zR3ABgum-uoQ.ttf',
    variant: '600',
    family: 'Saira Extra Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c9402947-9430-4670-9eca-02f884440353',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrG2vR3ABgum-uoQ.ttf',
    variant: '500',
    family: 'Saira Extra Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'fba4fbd3-a47c-466c-8f2d-b25c703818a4',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairaextracondensed/v6/-nFiOHYr-vcC7h8MklGBkrvmUG9rbpkisrTT70L11Ct8sw.ttf',
    variant: 'regular',
    family: 'Saira Extra Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd88fbe2e-32ec-428e-90d1-4aa4ee3dad8a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrQ2rR3ABgum-uoQ.ttf',
    variant: '300',
    family: 'Saira Extra Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd0c4f9d1-a436-493e-a349-fe131e783e13',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrJ2nR3ABgum-uoQ.ttf',
    variant: '200',
    family: 'Saira Extra Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'b7ef761d-ef40-4633-9c3b-ac66b479dee5',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairaextracondensed/v6/-nFsOHYr-vcC7h8MklGBkrvmUG9rbpkisrTri0jx9i5ss3a3.ttf',
    variant: '100',
    family: 'Saira Extra Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '14b0a5eb-2ffe-4b64-b140-1b885f612851',
    origin: GF_SOURCE,
    url: GF_URL + 'spinnaker/v12/w8gYH2oyX-I0_rvR6Hmn3HwLqOqSBg.ttf',
    variant: 'regular',
    family: 'Spinnaker',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '23695716-87d4-4748-952d-22b273ff918b',
    origin: GF_SOURCE,
    url: GF_URL + 'sixcaps/v11/6ae_4KGrU7VR7bNmabcS9XXaPCop.ttf',
    variant: 'regular',
    family: 'Six Caps',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '5ba8d419-e2b0-4589-b1ab-c7c974be9a06',
    origin: GF_SOURCE,
    url: GF_URL + 'glegoo/v10/_Xmu-HQyrTKWaw2xN4a9CKRpzimMsg.ttf',
    variant: '700',
    family: 'Glegoo',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '487da265-d271-4258-9584-f540f1039267',
    origin: GF_SOURCE,
    url: GF_URL + 'glegoo/v10/_Xmt-HQyrTKWaw2Ji6mZAI91xw.ttf',
    variant: 'regular',
    family: 'Glegoo',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '7e77cb9f-cae7-4b77-98e7-d3ea3194f816',
    origin: GF_SOURCE,
    url: GF_URL + 'sharetechmono/v10/J7aHnp1uDWRBEqV98dVQztYldFc7pAsEIc3Xew.ttf',
    variant: 'regular',
    family: 'Share Tech Mono',
    category: 'monospace',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a9838dd1-c643-4064-9b34-a3de12155e68',
    origin: GF_SOURCE,
    url: GF_URL + 'enriqueta/v10/gokpH6L7AUFrRvV44HVr92-HmNZEq6TTFw.ttf',
    variant: '700',
    family: 'Enriqueta',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '595b97d2-5c23-49e7-8761-fda2b04d4f64',
    origin: GF_SOURCE,
    url: GF_URL + 'enriqueta/v10/gokpH6L7AUFrRvV44HVrk26HmNZEq6TTFw.ttf',
    variant: '600',
    family: 'Enriqueta',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '55a27504-5015-4ffb-a560-764b97a1b194',
    origin: GF_SOURCE,
    url: GF_URL + 'enriqueta/v10/gokpH6L7AUFrRvV44HVrv2mHmNZEq6TTFw.ttf',
    variant: '500',
    family: 'Enriqueta',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ee74926b-106b-485a-bfb8-b9909e63bcd4',
    origin: GF_SOURCE,
    url: GF_URL + 'enriqueta/v10/goksH6L7AUFrRvV44HVTS0CjkP1Yog.ttf',
    variant: 'regular',
    family: 'Enriqueta',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '03250ab2-ec78-4f4b-877b-c1dd9b6fa326',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxddiNhNaB6O-51OA.ttf',
    variant: '900italic',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '1b9c2584-0d90-4c54-9cbe-d77fd930af88',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DspC0iRrMYJ_K-4.ttf',
    variant: '900',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '396662dd-e101-4f28-a700-6e5794c0f928',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdUiJhNaB6O-51OA.ttf',
    variant: '800italic',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '3e33021a-a6f3-498c-99ba-2df17376793d',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1Du5D0iRrMYJ_K-4.ttf',
    variant: '800',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '9dc4a417-1dd3-46d9-b005-910cab2c43a1',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdTiFhNaB6O-51OA.ttf',
    variant: '700italic',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'deddff80-2a43-4e38-b20f-086360f3864c',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA0iRrMYJ_K-4.ttf',
    variant: '700',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '0ced6bdb-0f9c-4fc2-96f7-e0882975ccf7',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdBidhNaB6O-51OA.ttf',
    variant: '500italic',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'fd43ec99-09f3-4ed5-ada1-9a7ce7f26d9a',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG0iRrMYJ_K-4.ttf',
    variant: '500',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '9e985ff2-0088-4073-8ee4-17a2039abbd9',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1Bkxl8g5FPYtmMg.ttf',
    variant: 'italic',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '4bb57a82-7424-4736-b02c-fe552d48e588',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Nk5v9ixALYs.ttf',
    variant: 'regular',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'ca199999-0a9c-44e4-abdb-532764fa9b8c',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdXiZhNaB6O-51OA.ttf',
    variant: '300italic',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'c789eacf-725b-41fe-b0f4-e3804d2cd7ef',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DuJH0iRrMYJ_K-4.ttf',
    variant: '300',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'fa9f336f-2841-4818-9de0-ff1145203cfa',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGl4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdlgRBH452Mvds.ttf',
    variant: '100italic',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '2786731a-2788-4fd6-afcb-aaa33b52b950',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasanssc/v13/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1Dipl8g5FPYtmMg.ttf',
    variant: '100',
    family: 'Alegreya Sans SC',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '2099526d-32ed-45e0-884b-ebc13b010e89',
    origin: GF_SOURCE,
    url: GF_URL + 'michroma/v11/PN_zRfy9qWD8fEagAMg6rzjb_-Da.ttf',
    variant: 'regular',
    family: 'Michroma',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '5f19b5c9-1b78-4ac9-9f07-c3146183b17d',
    origin: GF_SOURCE,
    url: GF_URL + 'courierprime/v2/u-4i0q2lgwslOqpF_6gQ8kELawRR4-LfrtPtNXyeAg.ttf',
    variant: '700italic',
    family: 'Courier Prime',
    category: 'monospace',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '2598802d-5222-4fcc-bc3d-b4abcba6a1f9',
    origin: GF_SOURCE,
    url: GF_URL + 'courierprime/v2/u-4k0q2lgwslOqpF_6gQ8kELY7pMf-fVqvHoJXw.ttf',
    variant: '700',
    family: 'Courier Prime',
    category: 'monospace',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '21f1e553-bb28-4667-8a45-903a71110fb0',
    origin: GF_SOURCE,
    url: GF_URL + 'courierprime/v2/u-4n0q2lgwslOqpF_6gQ8kELawRpX837pvjxPA.ttf',
    variant: 'italic',
    family: 'Courier Prime',
    category: 'monospace',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '8d80155d-1442-4250-856c-cb17ae1139bc',
    origin: GF_SOURCE,
    url: GF_URL + 'courierprime/v2/u-450q2lgwslOqpF_6gQ8kELWwZjW-_-tvg.ttf',
    variant: 'regular',
    family: 'Courier Prime',
    category: 'monospace',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '039e687c-2829-4f26-81d0-cf7b6065f163',
    origin: GF_SOURCE,
    url: GF_URL + 'cousine/v17/d6lPkaiiRdih4SpP_SEXdTvM1_JgjmRpOA.ttf',
    variant: '700italic',
    family: 'Cousine',
    category: 'monospace',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'e024a821-e06d-48f7-a15a-e189a0309e06',
    origin: GF_SOURCE,
    url: GF_URL + 'cousine/v17/d6lNkaiiRdih4SpP9Z8K6T7G09BlnmQ.ttf',
    variant: '700',
    family: 'Cousine',
    category: 'monospace',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '23a51248-bced-4501-ac15-27f1fc309edf',
    origin: GF_SOURCE,
    url: GF_URL + 'cousine/v17/d6lKkaiiRdih4SpP_SEvyRTo39l8hw.ttf',
    variant: 'italic',
    family: 'Cousine',
    category: 'monospace',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '8f993be1-04a7-48aa-856f-b9bd67473a5f',
    origin: GF_SOURCE,
    url: GF_URL + 'cousine/v17/d6lIkaiiRdih4SpPzSMlzTbtz9k.ttf',
    variant: 'regular',
    family: 'Cousine',
    category: 'monospace',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'bf0ae552-8a00-4ff5-8fd1-f1a1c29a5377',
    origin: GF_SOURCE,
    url: GF_URL + 'lemonada/v12/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGnex2mfWc3Z2pTg.ttf',
    variant: '700',
    family: 'Lemonada',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '485f826d-bdfd-4b1d-84da-ce33f941bea9',
    origin: GF_SOURCE,
    url: GF_URL + 'lemonada/v12/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGpOx2mfWc3Z2pTg.ttf',
    variant: '600',
    family: 'Lemonada',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '3c6560ee-bee5-42bb-90cb-59a29af25b96',
    origin: GF_SOURCE,
    url: GF_URL + 'lemonada/v12/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGSOt2mfWc3Z2pTg.ttf',
    variant: '500',
    family: 'Lemonada',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'a670b731-db18-478f-a9d7-0714dc76eaa9',
    origin: GF_SOURCE,
    url: GF_URL + 'lemonada/v12/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGeut2mfWc3Z2pTg.ttf',
    variant: 'regular',
    family: 'Lemonada',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '3e27d2f2-f557-4161-8dc0-572dc030631c',
    origin: GF_SOURCE,
    url: GF_URL + 'lemonada/v12/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGJOt2mfWc3Z2pTg.ttf',
    variant: '300',
    family: 'Lemonada',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '73b89e64-be47-487c-8bee-4df4a5ac5b0a',
    origin: GF_SOURCE,
    url: GF_URL + 'kreon/v24/t5t9IRIUKY-TFF_LW5lnMR3v2Dnvhd-mejUfp2dWNg.ttf',
    variant: '700',
    family: 'Kreon',
    category: 'serif',
    version: 'v24',
    subset: 'latin',
  },
  {
    id: 'c7426849-24d6-4328-a0c8-eb62644f4262',
    origin: GF_SOURCE,
    url: GF_URL + 'kreon/v24/t5t9IRIUKY-TFF_LW5lnMR3v2DnvvN-mejUfp2dWNg.ttf',
    variant: '600',
    family: 'Kreon',
    category: 'serif',
    version: 'v24',
    subset: 'latin',
  },
  {
    id: '75139176-6b95-4f6e-b3f7-50588c3714e2',
    origin: GF_SOURCE,
    url: GF_URL + 'kreon/v24/t5t9IRIUKY-TFF_LW5lnMR3v2DnvUNimejUfp2dWNg.ttf',
    variant: '500',
    family: 'Kreon',
    category: 'serif',
    version: 'v24',
    subset: 'latin',
  },
  {
    id: '96e312ae-8fe3-425a-ae2d-bdb68f840656',
    origin: GF_SOURCE,
    url: GF_URL + 'kreon/v24/t5t9IRIUKY-TFF_LW5lnMR3v2DnvYtimejUfp2dWNg.ttf',
    variant: 'regular',
    family: 'Kreon',
    category: 'serif',
    version: 'v24',
    subset: 'latin',
  },
  {
    id: 'fde517f0-c9da-417b-b94d-980efc5f97ee',
    origin: GF_SOURCE,
    url: GF_URL + 'kreon/v24/t5t9IRIUKY-TFF_LW5lnMR3v2DnvPNimejUfp2dWNg.ttf',
    variant: '300',
    family: 'Kreon',
    category: 'serif',
    version: 'v24',
    subset: 'latin',
  },
  {
    id: '83c12a34-4218-4eff-b941-6a10adfa7979',
    origin: GF_SOURCE,
    url:
      GF_URL + 'frederickathegreat/v10/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV-9Skz7Ylch2L.ttf',
    variant: 'regular',
    family: 'Fredericka the Great',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '77490e52-2cb2-4c49-b78e-1b7a1ff47b78',
    origin: GF_SOURCE,
    url: GF_URL + 'candal/v10/XoHn2YH6T7-t_8cNAR4Jt9Yxlw.ttf',
    variant: 'regular',
    family: 'Candal',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b76b71a8-8144-407d-ac92-1d6bb7538e9e',
    origin: GF_SOURCE,
    url: GF_URL + 'forum/v11/6aey4Ky-Vb8Ew_IWMJMa3mnT.ttf',
    variant: 'regular',
    family: 'Forum',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'd2bc6106-cc47-456b-a40a-e5430996924f',
    origin: GF_SOURCE,
    url: GF_URL + 'pinyonscript/v11/6xKpdSJbL9-e9LuoeQiDRQR8aOLQO4bhiDY.ttf',
    variant: 'regular',
    family: 'Pinyon Script',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '613b3cc8-fcab-4c3c-9274-fdd4ca968953',
    origin: GF_SOURCE,
    url: GF_URL + 'laila/v8/LYjBdG_8nE8jDLzhpQNAh14nVcfe.ttf',
    variant: '700',
    family: 'Laila',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'cae25ae1-064e-4a56-82cb-ad046095a524',
    origin: GF_SOURCE,
    url: GF_URL + 'laila/v8/LYjBdG_8nE8jDLyFpANAh14nVcfe.ttf',
    variant: '600',
    family: 'Laila',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b47686c8-8539-438b-a8f9-0fca98808984',
    origin: GF_SOURCE,
    url: GF_URL + 'laila/v8/LYjBdG_8nE8jDLypowNAh14nVcfe.ttf',
    variant: '500',
    family: 'Laila',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '09bccaa5-7868-4260-81ec-97042f6f91df',
    origin: GF_SOURCE,
    url: GF_URL + 'laila/v8/LYjMdG_8nE8jDIRdiidIrEIu.ttf',
    variant: 'regular',
    family: 'Laila',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'db28eb94-ee6c-4e8b-8e51-e3d2e2995736',
    origin: GF_SOURCE,
    url: GF_URL + 'laila/v8/LYjBdG_8nE8jDLzxogNAh14nVcfe.ttf',
    variant: '300',
    family: 'Laila',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '32e5d0f3-bd3e-47a8-8352-cdfbd0c1237e',
    origin: GF_SOURCE,
    url: GF_URL + 'chewy/v12/uK_94ruUb-k-wk5xIDMfO-ed.ttf',
    variant: 'regular',
    family: 'Chewy',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '683698c5-be87-4342-986e-243d01f26979',
    origin: GF_SOURCE,
    url: GF_URL + 'basic/v10/xfu_0WLxV2_XKQN34lDVyR7D.ttf',
    variant: 'regular',
    family: 'Basic',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'fa47cc36-827a-45ea-ab82-1393e1f07ef9',
    origin: GF_SOURCE,
    url: GF_URL + 'dmseriftext/v5/rnCw-xZa_krGokauCeNq1wWyWfGFWFAMArZKqQ.ttf',
    variant: 'italic',
    family: 'DM Serif Text',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b3091c8a-7c75-4cf8-b733-8f480b8a9380',
    origin: GF_SOURCE,
    url: GF_URL + 'dmseriftext/v5/rnCu-xZa_krGokauCeNq1wWyafOPXHIJErY.ttf',
    variant: 'regular',
    family: 'DM Serif Text',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '15f20ffd-806c-4bbb-84cc-a6ed67ddcc66',
    origin: GF_SOURCE,
    url: GF_URL + 'sintony/v8/XoHj2YDqR7-98cVUGYgIn9cDkjLp6C8.ttf',
    variant: '700',
    family: 'Sintony',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'a445b2ab-68d6-4597-9be0-b3e681923036',
    origin: GF_SOURCE,
    url: GF_URL + 'sintony/v8/XoHm2YDqR7-98cVUITQnu98ojjs.ttf',
    variant: 'regular',
    family: 'Sintony',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'dc9897f5-7c87-422a-bacf-dedf9c22d303',
    origin: GF_SOURCE,
    url: GF_URL + 'pridi/v6/2sDdZG5JnZLfkc0mjE0jRUG0AqUc.ttf',
    variant: '700',
    family: 'Pridi',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '30604d7e-d3a6-4a6b-8f90-125b1fb41d67',
    origin: GF_SOURCE,
    url: GF_URL + 'pridi/v6/2sDdZG5JnZLfkc1CjU0jRUG0AqUc.ttf',
    variant: '600',
    family: 'Pridi',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '64b4a582-ab45-40d4-be0e-ee8bab04ec2e',
    origin: GF_SOURCE,
    url: GF_URL + 'pridi/v6/2sDdZG5JnZLfkc1uik0jRUG0AqUc.ttf',
    variant: '500',
    family: 'Pridi',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'b86ebc65-d3de-45e2-8e36-e72240ab3dca',
    origin: GF_SOURCE,
    url: GF_URL + 'pridi/v6/2sDQZG5JnZLfkfWao2krbl29.ttf',
    variant: 'regular',
    family: 'Pridi',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd20164d9-e424-46ae-8893-2ec21791b220',
    origin: GF_SOURCE,
    url: GF_URL + 'pridi/v6/2sDdZG5JnZLfkc02i00jRUG0AqUc.ttf',
    variant: '300',
    family: 'Pridi',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bfabf523-dd11-41c9-9e69-237ee2da14c2',
    origin: GF_SOURCE,
    url: GF_URL + 'pridi/v6/2sDdZG5JnZLfkc1SiE0jRUG0AqUc.ttf',
    variant: '200',
    family: 'Pridi',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '065a911e-c45b-4966-b2d3-70af5d3a1b8e',
    origin: GF_SOURCE,
    url: GF_URL + 'oleoscript/v9/raxkHieDvtMOe0iICsUccCDmnmrY2zqUKafv.ttf',
    variant: '700',
    family: 'Oleo Script',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '9320f770-1a02-4bee-81c6-1521f48c11c0',
    origin: GF_SOURCE,
    url: GF_URL + 'oleoscript/v9/rax5HieDvtMOe0iICsUccBhasU7Q8Cad.ttf',
    variant: 'regular',
    family: 'Oleo Script',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '5a6d3644-2d14-4ca9-9706-9d2991fb6dc0',
    origin: GF_SOURCE,
    url: GF_URL + 'reemkufi/v10/2sDcZGJLip7W2J7v7wQDb2-4C7wFZQ.ttf',
    variant: 'regular',
    family: 'Reem Kufi',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'aeb88844-5b74-454e-822c-66a76b14f413',
    origin: GF_SOURCE,
    url: GF_URL + 'rancho/v11/46kulbzmXjLaqZRlbWXgd0RY1g.ttf',
    variant: 'regular',
    family: 'Rancho',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'fc13761c-903a-4793-bfdc-74847eb0cdf6',
    origin: GF_SOURCE,
    url: GF_URL + 'blackhansans/v8/ea8Aad44WunzF9a-dL6toA8r8nqVIXSkH-Hc.ttf',
    variant: 'regular',
    family: 'Black Han Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'abf9bce6-98af-499f-8e87-55322579667e',
    origin: GF_SOURCE,
    url: GF_URL + 'arsenal/v5/wXKsE3kQtZQ4pF3D513kueEKnV03vdnKjw.ttf',
    variant: '700italic',
    family: 'Arsenal',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3b62b322-dbd0-49cd-a8aa-79c91e42d829',
    origin: GF_SOURCE,
    url: GF_URL + 'arsenal/v5/wXKuE3kQtZQ4pF3D7-P5JeQAmX8yrdk.ttf',
    variant: '700',
    family: 'Arsenal',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ec6a1850-5622-43d9-8e02-eb739ff3b6c3',
    origin: GF_SOURCE,
    url: GF_URL + 'arsenal/v5/wXKpE3kQtZQ4pF3D513cBc4ulXYrtA.ttf',
    variant: 'italic',
    family: 'Arsenal',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b6f32359-789a-4f13-9a82-2eb73ca3532b',
    origin: GF_SOURCE,
    url: GF_URL + 'arsenal/v5/wXKrE3kQtZQ4pF3D11_WAewrhXY.ttf',
    variant: 'regular',
    family: 'Arsenal',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ec8764c8-ded9-4e48-a5fc-68f40659a08a',
    origin: GF_SOURCE,
    url: GF_URL + 'overpassmono/v6/_Xm3-H86tzKDdAPa-KPQZ-AC3pSRk_edB3Zf8EQ.ttf',
    variant: '700',
    family: 'Overpass Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a257c87e-b6b9-42cc-a35c-44911c60531a',
    origin: GF_SOURCE,
    url: GF_URL + 'overpassmono/v6/_Xm3-H86tzKDdAPa-KPQZ-AC3vCQk_edB3Zf8EQ.ttf',
    variant: '600',
    family: 'Overpass Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ccecd49e-2968-489d-a6ce-7aca3f1624f0',
    origin: GF_SOURCE,
    url: GF_URL + 'overpassmono/v6/_Xmq-H86tzKDdAPa-KPQZ-AC5ii-t_-2G38.ttf',
    variant: 'regular',
    family: 'Overpass Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'df5c2db6-040d-4d65-aa4e-f89496373349',
    origin: GF_SOURCE,
    url: GF_URL + 'overpassmono/v6/_Xm3-H86tzKDdAPa-KPQZ-AC3oSWk_edB3Zf8EQ.ttf',
    variant: '300',
    family: 'Overpass Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '33ee46b4-d495-4314-9074-46ceffccae39',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifkr/v7/3JnmSDn90Gmq2mr3blnHaTZXTmRE8O1ZNH1ahck.otf',
    variant: '900',
    family: 'Noto Serif KR',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'a7633a54-1568-429b-8c5f-5c1f4439a120',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifkr/v7/3JnmSDn90Gmq2mr3blnHaTZXTlxG8O1ZNH1ahck.otf',
    variant: '700',
    family: 'Noto Serif KR',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'c550155a-e027-4579-943a-895984b292f5',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifkr/v7/3JnmSDn90Gmq2mr3blnHaTZXTjhH8O1ZNH1ahck.otf',
    variant: '600',
    family: 'Noto Serif KR',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '863abe49-8374-4696-bced-6da7fafd0cad',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifkr/v7/3JnmSDn90Gmq2mr3blnHaTZXThRA8O1ZNH1ahck.otf',
    variant: '500',
    family: 'Noto Serif KR',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'c144c91f-7169-4bef-8adc-8c7a1f53f923',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifkr/v7/3Jn7SDn90Gmq2mr3blnHaTZXduZp1ONyKHQ.otf',
    variant: 'regular',
    family: 'Noto Serif KR',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '6806f40a-9db7-4f2d-ae38-6550864b7bb8',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifkr/v7/3JnmSDn90Gmq2mr3blnHaTZXTkxB8O1ZNH1ahck.otf',
    variant: '300',
    family: 'Noto Serif KR',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '9be54b8a-82b7-4e4e-a76e-2770f8775999',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifkr/v7/3JnmSDn90Gmq2mr3blnHaTZXTihC8O1ZNH1ahck.otf',
    variant: '200',
    family: 'Noto Serif KR',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'c5159689-5d3f-45f3-a17f-6d9aefc9d207',
    origin: GF_SOURCE,
    url: GF_URL + 'baloochettan2/v2/vm8rdRbmXEva26PK-NtuX4ynWEznQNFfMr0fn5bhCA.ttf',
    variant: '800',
    family: 'Baloo Chettan 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'af85ce70-85db-4d31-bcf2-c767f5939da0',
    origin: GF_SOURCE,
    url: GF_URL + 'baloochettan2/v2/vm8rdRbmXEva26PK-NtuX4ynWEznXNJfMr0fn5bhCA.ttf',
    variant: '700',
    family: 'Baloo Chettan 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '8cc947af-5ee7-4c9e-871f-af9fa7a39e29',
    origin: GF_SOURCE,
    url: GF_URL + 'baloochettan2/v2/vm8rdRbmXEva26PK-NtuX4ynWEznONNfMr0fn5bhCA.ttf',
    variant: '600',
    family: 'Baloo Chettan 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'e82db43b-9e55-4310-b7c1-f371b6c5b8d9',
    origin: GF_SOURCE,
    url: GF_URL + 'baloochettan2/v2/vm8rdRbmXEva26PK-NtuX4ynWEznFNRfMr0fn5bhCA.ttf',
    variant: '500',
    family: 'Baloo Chettan 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '8a0ea098-487a-4753-a19a-cb59d58ff53e',
    origin: GF_SOURCE,
    url: GF_URL + 'baloochettan2/v2/vm8udRbmXEva26PK-NtuX4ynWEzf4P17OpYDlg.ttf',
    variant: 'regular',
    family: 'Baloo Chettan 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'b29a4477-6da7-43a0-8537-0c81ad4bb01a',
    origin: GF_SOURCE,
    url: GF_URL + 'lilitaone/v8/i7dPIFZ9Zz-WBtRtedDbUEZ2RFq7AwU.ttf',
    variant: 'regular',
    family: 'Lilita One',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'cc6c5eef-49ba-4f27-bb6d-59b8d3ebfab4',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3BqXNgp8wxdOdOn44QWalB4sP7itsB5g.ttf',
    variant: '900italic',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '164dc82e-2acf-418c-babb-46d29f7ebc67',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3DqXNgp8wxdOdOlwgP_a5L5uH-mts.ttf',
    variant: '900',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd2fc4a08-3888-4d6b-995c-e32c4d3c99fb',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3BqXNgp8wxdOdOn44QfahB4sP7itsB5g.ttf',
    variant: '800italic',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '116e7d15-327e-4efd-8276-4b2da9a1140e',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3DqXNgp8wxdOdOlywO_a5L5uH-mts.ttf',
    variant: '800',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6e15f096-780c-4d8f-8428-b3fe7afb55d7',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3BqXNgp8wxdOdOn44QYatB4sP7itsB5g.ttf',
    variant: '700italic',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bcdd4eca-9d1c-4d55-a1fa-794abc2683cf',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3DqXNgp8wxdOdOlzAN_a5L5uH-mts.ttf',
    variant: '700',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'da4613f0-eb67-4554-82a6-192aa43d4416',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3BqXNgp8wxdOdOn44QBapB4sP7itsB5g.ttf',
    variant: '600italic',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '63921454-56a8-429e-8fb2-bf8728bde81d',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3DqXNgp8wxdOdOl1QM_a5L5uH-mts.ttf',
    variant: '600',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '9971e030-e54d-4fad-8e45-affed9b130ec',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3BqXNgp8wxdOdOn44QKa1B4sP7itsB5g.ttf',
    variant: '500italic',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '5671cec6-5139-4f4d-8b07-4716452d494f',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3DqXNgp8wxdOdOl3gL_a5L5uH-mts.ttf',
    variant: '500',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8cd38275-f9d5-4166-9625-aafc48b08b49',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3EqXNgp8wxdOdOn44o3YRl6ujngw.ttf',
    variant: 'italic',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '62ee9aea-f56a-4483-a10e-4d1f46901194',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3GqXNgp8wxdOdOr4wi2aZg-ug.ttf',
    variant: 'regular',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'de5d67cc-b4ad-4be3-a45f-1a38ca9f6d8e',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3BqXNgp8wxdOdOn44QcaxB4sP7itsB5g.ttf',
    variant: '300italic',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a6ed3d40-21f7-49ea-926d-fb8af2496811',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3DqXNgp8wxdOdOlyAK_a5L5uH-mts.ttf',
    variant: '300',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '2bf64580-7ca7-43de-8084-34c36aed11e6',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3BqXNgp8wxdOdOn44QFa9B4sP7itsB5g.ttf',
    variant: '200italic',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '3dfa1cf0-d7b0-4c19-a693-3e7afe99f3b1',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3DqXNgp8wxdOdOl0QJ_a5L5uH-mts.ttf',
    variant: '200',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ebcdff77-dea8-48f1-b88a-b7078f399931',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3CqXNgp8wxdOdOn44QuY5hyO33g8IY.ttf',
    variant: '100italic',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '4fb2eb50-5e51-4403-9dab-f1836f0f0bdf',
    origin: GF_SOURCE,
    url: GF_URL + 'trirong/v6/7r3EqXNgp8wxdOdOl-go3YRl6ujngw.ttf',
    variant: '100',
    family: 'Trirong',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bec7e369-b2c0-4025-be62-5bdf937c34b6',
    origin: GF_SOURCE,
    url: GF_URL + 'gelasio/v4/cIf6MaFfvUQxTTqS9CuZ2GQmmKBhSL7Y1Q.ttf',
    variant: '700italic',
    family: 'Gelasio',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '2720bd23-7ca6-4eb9-978a-fb5ef70f4ce0',
    origin: GF_SOURCE,
    url: GF_URL + 'gelasio/v4/cIf4MaFfvUQxTTqS_JWERGEsnIJkWL4.ttf',
    variant: '700',
    family: 'Gelasio',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a6510417-00ba-49d6-ac02-8c0b324beedc',
    origin: GF_SOURCE,
    url: GF_URL + 'gelasio/v4/cIf6MaFfvUQxTTqS9CuZvGUmmKBhSL7Y1Q.ttf',
    variant: '600italic',
    family: 'Gelasio',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '8dc1875a-9c94-401e-95fc-22603d58ae23',
    origin: GF_SOURCE,
    url: GF_URL + 'gelasio/v4/cIf4MaFfvUQxTTqS_PGFRGEsnIJkWL4.ttf',
    variant: '600',
    family: 'Gelasio',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '109605c5-8201-45e7-8173-538ce0b63cbb',
    origin: GF_SOURCE,
    url: GF_URL + 'gelasio/v4/cIf6MaFfvUQxTTqS9CuZkGImmKBhSL7Y1Q.ttf',
    variant: '500italic',
    family: 'Gelasio',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '71e5a060-13aa-484d-bd2c-2cfc6ca72855',
    origin: GF_SOURCE,
    url: GF_URL + 'gelasio/v4/cIf4MaFfvUQxTTqS_N2CRGEsnIJkWL4.ttf',
    variant: '500',
    family: 'Gelasio',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'f6e2e21b-e04d-4210-9084-9a4eafe68765',
    origin: GF_SOURCE,
    url: GF_URL + 'gelasio/v4/cIf_MaFfvUQxTTqS9CuhZEsCkIt9QQ.ttf',
    variant: 'italic',
    family: 'Gelasio',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '09aea29d-f350-4059-82c5-f6b1db4f2427',
    origin: GF_SOURCE,
    url: GF_URL + 'gelasio/v4/cIf9MaFfvUQxTTqSxCmrYGkHgIs.ttf',
    variant: 'regular',
    family: 'Gelasio',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'b6895a3e-0fb3-43ce-900a-d56fb8cb7d16',
    origin: GF_SOURCE,
    url: GF_URL + 'blackopsone/v12/qWcsB6-ypo7xBdr6Xshe96H3WDzRtjkho4M.ttf',
    variant: 'regular',
    family: 'Black Ops One',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2ba43829-a87c-47b0-8ba8-a71191450da4',
    origin: GF_SOURCE,
    url: GF_URL + 'overlock/v10/Z9XQDmdMWRiN1_T9Z7Tc0G2Ltrmp8CLTlNs.ttf',
    variant: '900italic',
    family: 'Overlock',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '12183b7b-99c6-4341-b62c-a9a647ebbd7d',
    origin: GF_SOURCE,
    url: GF_URL + 'overlock/v10/Z9XSDmdMWRiN1_T9Z7xaz8mMvL2L9TLT.ttf',
    variant: '900',
    family: 'Overlock',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '83ec9df4-76bc-4eb0-bd76-d1084c59b592',
    origin: GF_SOURCE,
    url: GF_URL + 'overlock/v10/Z9XQDmdMWRiN1_T9Z7Tc0FWJtrmp8CLTlNs.ttf',
    variant: '700italic',
    family: 'Overlock',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '81d2f250-95e2-4603-b9ed-5a7ad4cc59e5',
    origin: GF_SOURCE,
    url: GF_URL + 'overlock/v10/Z9XSDmdMWRiN1_T9Z7xizcmMvL2L9TLT.ttf',
    variant: '700',
    family: 'Overlock',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b9f682da-16f9-4cfc-9a8a-cc18b7233d73',
    origin: GF_SOURCE,
    url: GF_URL + 'overlock/v10/Z9XTDmdMWRiN1_T9Z7Tc6OmmkrGC7Cs.ttf',
    variant: 'italic',
    family: 'Overlock',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '88d49037-856f-4e41-b458-c8bacbd84725',
    origin: GF_SOURCE,
    url: GF_URL + 'overlock/v10/Z9XVDmdMWRiN1_T9Z4Te4u2El6GC.ttf',
    variant: 'regular',
    family: 'Overlock',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8f76b16b-46ba-43a7-b270-4c185b185eee',
    origin: GF_SOURCE,
    url: GF_URL + 'kosugimaru/v6/0nksC9PgP_wGh21A2KeqGiTqivr9iBq_.ttf',
    variant: 'regular',
    family: 'Kosugi Maru',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '5633d482-6821-4a2e-9ced-73f26be777c9',
    origin: GF_SOURCE,
    url: GF_URL + 'average/v9/fC1hPYBHe23MxA7rIeJwVWytTyk.ttf',
    variant: 'regular',
    family: 'Average',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'd980c056-1e8b-4c38-89c2-bd4dcb7cc1e5',
    origin: GF_SOURCE,
    url: GF_URL + 'arimamadurai/v7/t5t7IRoeKYORG0WNMgnC3seB1b3oipusfhcat2c.ttf',
    variant: '900',
    family: 'Arima Madurai',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd006d16d-4b59-40a3-88cd-57460f59cf2a',
    origin: GF_SOURCE,
    url: GF_URL + 'arimamadurai/v7/t5t7IRoeKYORG0WNMgnC3seB1Znpipusfhcat2c.ttf',
    variant: '800',
    family: 'Arima Madurai',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd03bd336-6b79-4d22-8bae-e7eb11f61341',
    origin: GF_SOURCE,
    url: GF_URL + 'arimamadurai/v7/t5t7IRoeKYORG0WNMgnC3seB1YXqipusfhcat2c.ttf',
    variant: '700',
    family: 'Arima Madurai',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '693392ce-79ac-4a53-b781-b1fe6cb5159f',
    origin: GF_SOURCE,
    url: GF_URL + 'arimamadurai/v7/t5t7IRoeKYORG0WNMgnC3seB1c3sipusfhcat2c.ttf',
    variant: '500',
    family: 'Arima Madurai',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '6abc4e3b-9139-4a36-a799-250d71139c0d',
    origin: GF_SOURCE,
    url: GF_URL + 'arimamadurai/v7/t5tmIRoeKYORG0WNMgnC3seB7TnFrpOHYh4.ttf',
    variant: 'regular',
    family: 'Arima Madurai',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'e5aebc53-1e9e-4beb-b951-9ec80fe310e9',
    origin: GF_SOURCE,
    url: GF_URL + 'arimamadurai/v7/t5t7IRoeKYORG0WNMgnC3seB1ZXtipusfhcat2c.ttf',
    variant: '300',
    family: 'Arima Madurai',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd97dc662-323f-4997-88d0-a1fed8a65700',
    origin: GF_SOURCE,
    url: GF_URL + 'arimamadurai/v7/t5t7IRoeKYORG0WNMgnC3seB1fHuipusfhcat2c.ttf',
    variant: '200',
    family: 'Arima Madurai',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'e10ee726-93b9-45bd-bbaf-a499a6398cfd',
    origin: GF_SOURCE,
    url: GF_URL + 'arimamadurai/v7/t5t4IRoeKYORG0WNMgnC3seB1V3PqrGCch4Drg.ttf',
    variant: '100',
    family: 'Arima Madurai',
    category: 'display',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'ffaba285-1170-4d89-80d8-cc85e2dcf453',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'annieuseyourtelescope/v11/daaLSS4tI2qYYl3Jq9s_Hu74xwktnlKxH6osGVGjlDfB3UUVZA.ttf',
    variant: 'regular',
    family: 'Annie Use Your Telescope',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c0790667-e5a8-4f9a-8168-74c5fb3efe78',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanscondensed/v5/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-BYipByQJKnuIFA.ttf',
    variant: '900',
    family: 'Encode Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6493bf34-1ed7-49af-9894-a1eccda7c6da',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanscondensed/v5/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-IYmpByQJKnuIFA.ttf',
    variant: '800',
    family: 'Encode Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '4ab9d144-a220-456f-b51e-4b51cb8d7be4',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanscondensed/v5/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-PYqpByQJKnuIFA.ttf',
    variant: '700',
    family: 'Encode Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f09a45d3-2221-4c6e-a01f-bdce5f1f476c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanscondensed/v5/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-WYupByQJKnuIFA.ttf',
    variant: '600',
    family: 'Encode Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ea69d151-2130-452a-9b83-cd30b49603b9',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanscondensed/v5/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-dYypByQJKnuIFA.ttf',
    variant: '500',
    family: 'Encode Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '768dff14-b527-4f95-a9aa-fd093ddd2669',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanscondensed/v5/j8_16_LD37rqfuwxyIuaZhE6cRXOLtm2gfTGgaWNDw8VIw.ttf',
    variant: 'regular',
    family: 'Encode Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '116fa15e-913d-467a-b47c-2b703d37fabf',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanscondensed/v5/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-LY2pByQJKnuIFA.ttf',
    variant: '300',
    family: 'Encode Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '099d2eb3-bf28-4b84-b86d-e3e61addc327',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanscondensed/v5/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-SY6pByQJKnuIFA.ttf',
    variant: '200',
    family: 'Encode Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b37fed29-3b08-45db-a53a-89b53e2c2b3d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesanscondensed/v5/j8_76_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-5a-JLQoFI2KR.ttf',
    variant: '100',
    family: 'Encode Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ec817254-7aa8-4453-b104-236b79994f4c',
    origin: GF_SOURCE,
    url: GF_URL + 'signikanegative/v11/E217_cfngu7HiRpPX3ZpNE4kY5zKak6EipHD6z_iXAs.ttf',
    variant: '700',
    family: 'Signika Negative',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c3b02f5d-76c9-407b-b935-471adbc0a5a4',
    origin: GF_SOURCE,
    url: GF_URL + 'signikanegative/v11/E217_cfngu7HiRpPX3ZpNE4kY5zKaiqFipHD6z_iXAs.ttf',
    variant: '600',
    family: 'Signika Negative',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'ca76ac7d-9058-4481-acb4-2422f1cca57a',
    origin: GF_SOURCE,
    url: GF_URL + 'signikanegative/v11/E218_cfngu7HiRpPX3ZpNE4kY5zKUvKrrpno9zY.ttf',
    variant: 'regular',
    family: 'Signika Negative',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b04fd6d1-36f3-40c2-beb4-29cfee5cef33',
    origin: GF_SOURCE,
    url: GF_URL + 'signikanegative/v11/E217_cfngu7HiRpPX3ZpNE4kY5zKal6DipHD6z_iXAs.ttf',
    variant: '300',
    family: 'Signika Negative',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '46b23074-abb3-42c8-8694-4127255a786f',
    origin: GF_SOURCE,
    url: GF_URL + 'niconne/v10/w8gaH2QvRug1_rTfrQut2F4OuOo.ttf',
    variant: 'regular',
    family: 'Niconne',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '67ddf8a1-01a0-480b-b2a2-1f2422f9d278',
    origin: GF_SOURCE,
    url: GF_URL + 'gochihand/v11/hES06XlsOjtJsgCkx1PkTo71-n0nXWA.ttf',
    variant: 'regular',
    family: 'Gochi Hand',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b22e6a95-1a70-4338-a3c3-fb4d306e309f',
    origin: GF_SOURCE,
    url: GF_URL + 'gruppo/v11/WwkfxPmzE06v_ZWFWXDAOIEQUQ.ttf',
    variant: 'regular',
    family: 'Gruppo',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '365edf53-9cec-4ae9-b1e9-3e37cd3e0cbc',
    origin: GF_SOURCE,
    url: GF_URL + 'coda/v16/SLXIc1jY5nQ8HeIgTp6mw9t1cX8.ttf',
    variant: '800',
    family: 'Coda',
    category: 'display',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'd2adff55-60cc-49e9-be6d-e1a68dde206c',
    origin: GF_SOURCE,
    url: GF_URL + 'coda/v16/SLXHc1jY5nQ8JUIMapaN39I.ttf',
    variant: 'regular',
    family: 'Coda',
    category: 'display',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '458dc8b0-7eef-42fe-90bd-d9c71bfa83a3',
    origin: GF_SOURCE,
    url: GF_URL + 'syncopate/v12/pe0pMIuPIYBCpEV5eFdKvtKaA_Rue1UwVg.ttf',
    variant: '700',
    family: 'Syncopate',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'e3d2ea92-69c9-49e9-85b3-1655c63cb22f',
    origin: GF_SOURCE,
    url: GF_URL + 'syncopate/v12/pe0sMIuPIYBCpEV5eFdyAv2-C99ycg.ttf',
    variant: 'regular',
    family: 'Syncopate',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '9034e3f9-ecfa-478b-b7d3-e673a76afa7f',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlGdRyC6CRYbkQitLRyQ4oTef_6gQ.ttf',
    variant: '700italic',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'fb70f6d2-dc87-437c-81e7-bece0093dc82',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlEdRyC6CRYZvo_KLF4R6gWaf8.ttf',
    variant: '700',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'b60fd4e8-6686-4179-9595-f2e82d4ae04f',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlGdRyC6CRYbkQi0LVyQ4oTef_6gQ.ttf',
    variant: '600italic',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '5d59ed9e-53e6-4318-8bee-64408f6b81f4',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlEdRyC6CRYZp4-KLF4R6gWaf8.ttf',
    variant: '600',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a3eb1537-9ba0-4113-9e5e-a9e85eebc5e9',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlGdRyC6CRYbkQi_LJyQ4oTef_6gQ.ttf',
    variant: '500italic',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a764bf8c-35d9-4fc6-b2bd-1f1abd9e4b07',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlEdRyC6CRYZrI5KLF4R6gWaf8.ttf',
    variant: '500',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '19b84512-f3bf-4d8f-8d2c-cab3c8819dfe',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlFdRyC6CRYbkQaCJtWS6EPcA.ttf',
    variant: 'italic',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'cc272809-29ea-4552-a54a-22f4016cf401',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlLdRyC6CRYXkYQDLlTW6E.ttf',
    variant: 'regular',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ead432bb-6bec-469d-8ccb-68706cd19103',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlGdRyC6CRYbkQipLNyQ4oTef_6gQ.ttf',
    variant: '300italic',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '6cdd7e6d-9293-4f52-b08e-62d5f45ea7fd',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlEdRyC6CRYZuo4KLF4R6gWaf8.ttf',
    variant: '300',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a3a49fdd-fbdc-48ec-bf2f-4ebc1d66661c',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlGdRyC6CRYbkQiwLByQ4oTef_6gQ.ttf',
    variant: '200italic',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '682da378-acf6-4a77-9b28-497b7c29a301',
    origin: GF_SOURCE,
    url: GF_URL + 'krub/v4/sZlEdRyC6CRYZo47KLF4R6gWaf8.ttf',
    variant: '200',
    family: 'Krub',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '2b4f9bbc-53ee-4203-9849-db6606d64e99',
    origin: GF_SOURCE,
    url: GF_URL + 'voltaire/v10/1Pttg8PcRfSblAvGvQooYKVnBOif.ttf',
    variant: 'regular',
    family: 'Voltaire',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'eb6e315c-e87b-4a62-b1f6-e65e14e80b2c',
    origin: GF_SOURCE,
    url: GF_URL + 'ptserifcaption/v12/ieVj2ZhbGCW-JoW6S34pSDpqYKU019e7CAk8YvJEeg.ttf',
    variant: 'italic',
    family: 'PT Serif Caption',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '4965c721-e806-4cbf-a3db-01c4f90c1da0',
    origin: GF_SOURCE,
    url: GF_URL + 'ptserifcaption/v12/ieVl2ZhbGCW-JoW6S34pSDpqYKU059WxDCs5cvI.ttf',
    variant: 'regular',
    family: 'PT Serif Caption',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '504b3dc9-64f7-4bce-921d-493ac1248cc4',
    origin: GF_SOURCE,
    url: GF_URL + 'rozhaone/v8/AlZy_zVFtYP12Zncg2khdXf4XB0Tow.ttf',
    variant: 'regular',
    family: 'Rozha One',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '564b53fe-2010-482a-9a52-e3bd94a81fbd',
    origin: GF_SOURCE,
    url: GF_URL + 'cantataone/v10/PlI5Fl60Nb5obNzNe2jslVxEt8CwfGaD.ttf',
    variant: 'regular',
    family: 'Cantata One',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '1edc44b4-5692-4ca8-996c-284447182674',
    origin: GF_SOURCE,
    url: GF_URL + 'mrdafoe/v9/lJwE-pIzkS5NXuMMrGiqg7MCxz_C.ttf',
    variant: 'regular',
    family: 'Mr Dafoe',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '3a7d163b-d2e0-44ac-92e1-84bbb4e75347',
    origin: GF_SOURCE,
    url: GF_URL + 'suezone/v5/taiJGmd_EZ6rqscQgNFJkIqg-I0w.ttf',
    variant: 'regular',
    family: 'Suez One',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'bf851c12-c4c4-4d90-90fc-9727ec4bd236',
    origin: GF_SOURCE,
    url: GF_URL + 'antic/v12/TuGfUVB8XY5DRaZLodgzydtk.ttf',
    variant: 'regular',
    family: 'Antic',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '75bf8564-4ce1-45ea-8e77-2c7d1401a2fb',
    origin: GF_SOURCE,
    url: GF_URL + 'sortsmillgoudy/v10/Qw3AZR9MED_6PSuS_50nEaVrfzgEbH8EirE-9PGLfQ.ttf',
    variant: 'italic',
    family: 'Sorts Mill Goudy',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '525cebd4-fa1c-44a2-85cc-09139e7b6d54',
    origin: GF_SOURCE,
    url: GF_URL + 'sortsmillgoudy/v10/Qw3GZR9MED_6PSuS_50nEaVrfzgEXH0OjpM75PE.ttf',
    variant: 'regular',
    family: 'Sorts Mill Goudy',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '57911e6e-9b8e-43f3-8018-5d0a2efffe28',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'shadowsintolighttwo/v8/4iC86LVlZsRSjQhpWGedwyOoW-0A6_kpsyNmlAvNGLNnIF0.ttf',
    variant: 'regular',
    family: 'Shadows Into Light Two',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '8615356f-1183-4eec-a613-efdc3a238384',
    origin: GF_SOURCE,
    url: GF_URL + 'alexbrush/v12/SZc83FzrJKuqFbwMKk6EtUL57DtOmCc.ttf',
    variant: 'regular',
    family: 'Alex Brush',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'd58e54b0-d3b3-4e95-91d5-04d353032fc4',
    origin: GF_SOURCE,
    url: GF_URL + 'sriracha/v5/0nkrC9D4IuYBgWcI9ObYRQDioeb0.ttf',
    variant: 'regular',
    family: 'Sriracha',
    category: 'handwriting',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e8f20ef3-2c28-4153-b4dd-f1f106a1e657',
    origin: GF_SOURCE,
    url: GF_URL + 'cutivemono/v9/m8JWjfRfY7WVjVi2E-K9H5RFRG-K3Mud.ttf',
    variant: 'regular',
    family: 'Cutive Mono',
    category: 'monospace',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '9179a66f-a032-4616-82a2-86e4c1e538ab',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'nanumgothiccoding/v14/8QIYdjzHisX_8vv59_xMxtPFW4IXROws8xgecsV88t5V9r4.ttf',
    variant: '700',
    family: 'Nanum Gothic Coding',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'e0385541-6e3f-41df-8386-1efacfacae57',
    origin: GF_SOURCE,
    url: GF_URL + 'nanumgothiccoding/v14/8QIVdjzHisX_8vv59_xMxtPFW4IXROwsy6QxVs1X7tc.ttf',
    variant: 'regular',
    family: 'Nanum Gothic Coding',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '8f3654fa-a690-463e-8857-f50c01b72d8c',
    origin: GF_SOURCE,
    url: GF_URL + 'abhayalibre/v6/e3t5euGtX-Co5MNzeAOqinEY22_yqtxI6oYtBA.ttf',
    variant: '800',
    family: 'Abhaya Libre',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '378c1fa4-2df5-4a2b-af42-84828e9126f1',
    origin: GF_SOURCE,
    url: GF_URL + 'abhayalibre/v6/e3t5euGtX-Co5MNzeAOqinEYx2zyqtxI6oYtBA.ttf',
    variant: '700',
    family: 'Abhaya Libre',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '5c60d0ee-9da3-4381-bd2e-5600334807cb',
    origin: GF_SOURCE,
    url: GF_URL + 'abhayalibre/v6/e3t5euGtX-Co5MNzeAOqinEYo23yqtxI6oYtBA.ttf',
    variant: '600',
    family: 'Abhaya Libre',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ba4f546d-8ec1-4026-8ac1-5ee1e3a60bdd',
    origin: GF_SOURCE,
    url: GF_URL + 'abhayalibre/v6/e3t5euGtX-Co5MNzeAOqinEYj2ryqtxI6oYtBA.ttf',
    variant: '500',
    family: 'Abhaya Libre',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c9a95a81-da01-4de5-8416-6fcef9415a22',
    origin: GF_SOURCE,
    url: GF_URL + 'abhayalibre/v6/e3tmeuGtX-Co5MNzeAOqinEge0PWovdU4w.ttf',
    variant: 'regular',
    family: 'Abhaya Libre',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'e55dd406-de49-4b0d-bb47-1d6737ea0720',
    origin: GF_SOURCE,
    url: GF_URL + 'aclonica/v11/K2FyfZJVlfNNSEBXGb7TCI6oBjLz.ttf',
    variant: 'regular',
    family: 'Aclonica',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'dce50ecc-8e56-42f2-8d06-5712c8a21c17',
    origin: GF_SOURCE,
    url: GF_URL + 'marcellus/v8/wEO_EBrOk8hQLDvIAF8FUfAL3EsHiA.ttf',
    variant: 'regular',
    family: 'Marcellus',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b1a16748-8fb0-4bb4-94e0-98e78644c9a2',
    origin: GF_SOURCE,
    url: GF_URL + 'viga/v9/xMQbuFFdSaiX_QIjD4e2OX8.ttf',
    variant: 'regular',
    family: 'Viga',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'b5767acf-52e8-4156-a204-2ee899e41d5b',
    origin: GF_SOURCE,
    url: GF_URL + 'rufina/v8/Yq6W-LyURyLy-aKKHztAvMxenxE0SA.ttf',
    variant: '700',
    family: 'Rufina',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd05d22e0-100d-4278-a744-1097689d1947',
    origin: GF_SOURCE,
    url: GF_URL + 'rufina/v8/Yq6V-LyURyLy-aKyoxRktOdClg.ttf',
    variant: 'regular',
    family: 'Rufina',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e8e55467-ca2e-422d-8ed9-089b5576c6b8',
    origin: GF_SOURCE,
    url: GF_URL + 'itim/v5/0nknC9ziJOYewARKkc7ZdwU.ttf',
    variant: 'regular',
    family: 'Itim',
    category: 'handwriting',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'efbd9e13-0033-4aa3-abab-11fd6688326b',
    origin: GF_SOURCE,
    url: GF_URL + 'allan/v13/ea8aadU7WuTxEu5KEPCN2WpNgEKU.ttf',
    variant: '700',
    family: 'Allan',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '04948f4e-ecb3-4f89-88e1-f2e9d6c9abc3',
    origin: GF_SOURCE,
    url: GF_URL + 'allan/v13/ea8XadU7WuTxEtb2P9SF8nZE.ttf',
    variant: 'regular',
    family: 'Allan',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'c5d54689-6918-41ae-9693-fcdf09c1204c',
    origin: GF_SOURCE,
    url: GF_URL + 'allura/v9/9oRPNYsQpS4zjuAPjAIXPtrrGA.ttf',
    variant: 'regular',
    family: 'Allura',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '6eb79dbd-9e34-43f7-a22b-501a973aeec3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairasemicondensed/v6/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXePT8MWg3j36Ebz.ttf',
    variant: '900',
    family: 'Saira Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c7e09225-9d9f-480e-a9a5-602ec5a969c0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairasemicondensed/v6/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXerTsMWg3j36Ebz.ttf',
    variant: '800',
    family: 'Saira Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '84b83e25-d177-4fbd-b67d-c7d74427fcce',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairasemicondensed/v6/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXe3TcMWg3j36Ebz.ttf',
    variant: '700',
    family: 'Saira Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '40d18c2e-75a2-4abd-a200-60f4585aa436',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairasemicondensed/v6/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfTTMMWg3j36Ebz.ttf',
    variant: '600',
    family: 'Saira Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '66ef8dd9-f8b3-4c24-adb3-e946071f16ef',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairasemicondensed/v6/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXf_S8MWg3j36Ebz.ttf',
    variant: '500',
    family: 'Saira Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '673b402c-86f8-406a-b586-c8adaca90763',
    origin: GF_SOURCE,
    url:
      GF_URL + 'sairasemicondensed/v6/U9MD6c-2-nnJkHxyCjRcnMHcWVWV1cWRRU8LYuceqGT-.ttf',
    variant: 'regular',
    family: 'Saira Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '3b242c9f-7e1e-4f02-a780-fae88d2e8ee2',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairasemicondensed/v6/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXenSsMWg3j36Ebz.ttf',
    variant: '300',
    family: 'Saira Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '255b189b-46f0-46f6-828b-43014e6548b6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairasemicondensed/v6/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfDScMWg3j36Ebz.ttf',
    variant: '200',
    family: 'Saira Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd7e0156f-6ec6-472c-bf65-37fb591bb6f1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'sairasemicondensed/v6/U9MN6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXdvaOM8rXT-8V8.ttf',
    variant: '100',
    family: 'Saira Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd6c700fb-7deb-4886-9871-140ac40504c3',
    origin: GF_SOURCE,
    url:
      GF_URL + 'markazitext/v13/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtROXT4MlBekmJLo.ttf',
    variant: '700',
    family: 'Markazi Text',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '4c332f0b-18c3-4d8c-9d96-4dac9c20bb6d',
    origin: GF_SOURCE,
    url:
      GF_URL + 'markazitext/v13/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtSqXT4MlBekmJLo.ttf',
    variant: '600',
    family: 'Markazi Text',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'b6af4590-5b3c-4305-be4f-b1ae8b9fe070',
    origin: GF_SOURCE,
    url:
      GF_URL + 'markazitext/v13/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtcaQT4MlBekmJLo.ttf',
    variant: '500',
    family: 'Markazi Text',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'b80e83b5-724c-49a9-bdf2-e2b6ed5f2a7b',
    origin: GF_SOURCE,
    url:
      GF_URL + 'markazitext/v13/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtfSQT4MlBekmJLo.ttf',
    variant: 'regular',
    family: 'Markazi Text',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'dc874d9d-e5dd-495c-a99b-d91b59a2a967',
    origin: GF_SOURCE,
    url: GF_URL + 'damion/v10/hv-XlzJ3KEUe_YZUbWY3MTFgVg.ttf',
    variant: 'regular',
    family: 'Damion',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'c391ba1d-9634-46a1-b5d4-3ffc0e967d6f',
    origin: GF_SOURCE,
    url: GF_URL + 'varela/v11/DPEtYwqExx0AWHXJBBQFfvzDsQ.ttf',
    variant: 'regular',
    family: 'Varela',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c807aa2d-f432-4a00-beb8-168a52ccdbea',
    origin: GF_SOURCE,
    url: GF_URL + 'armata/v12/gokvH63_HV5jQ-E9lD53Q2u_mQ.ttf',
    variant: 'regular',
    family: 'Armata',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '6e0df78d-0c11-4e29-85cc-7a5cb1eee6a4',
    origin: GF_SOURCE,
    url: GF_URL + 'bungee/v6/N0bU2SZBIuF2PU_ECn50Kd_PmA.ttf',
    variant: 'regular',
    family: 'Bungee',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '3487e385-e659-40d7-9c43-becea279cd7f',
    origin: GF_SOURCE,
    url: GF_URL + 'tenorsans/v12/bx6ANxqUneKx06UkIXISr3JyC22IyqI.ttf',
    variant: 'regular',
    family: 'Tenor Sans',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '69d610ba-5ea8-48ba-a1fb-aaaa944b93fe',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0bX2SRONuN4SCj8tlFQIfTTkdbJYA.ttf',
    variant: '700italic',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '62d58343-a08a-4003-a4fd-62d3f18c8cc5',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0bV2SRONuN4QJbhKlRaJdbWgdY.ttf',
    variant: '700',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '4fb466b5-42f4-40eb-be66-7073a4862172',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0bX2SRONuN4SCj80lBQIfTTkdbJYA.ttf',
    variant: '600italic',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '44a37e1a-94fa-43a0-a6c6-bbcc58e99964',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0bV2SRONuN4QPLgKlRaJdbWgdY.ttf',
    variant: '600',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'e1febf2f-5215-43ae-b5c8-2bd8d54fe930',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0bX2SRONuN4SCj8_ldQIfTTkdbJYA.ttf',
    variant: '500italic',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '6871f529-8d32-4051-9ab7-d073260456dc',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0bV2SRONuN4QN7nKlRaJdbWgdY.ttf',
    variant: '500',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '77bbb642-9f9e-4e45-88b0-3022c6fd439e',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0bU2SRONuN4SCjECn50Kd_PmA.ttf',
    variant: 'italic',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c41fcaa4-3f9a-42f1-bdab-3255c41be55f',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0ba2SRONuN4eCrODlxxOd8.ttf',
    variant: 'regular',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '97eaf765-f5cb-497b-927d-f629ac8cc895',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0bX2SRONuN4SCj8plZQIfTTkdbJYA.ttf',
    variant: '300italic',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '010aa5d2-6e03-484f-86a2-5f03f3efaccc',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0bV2SRONuN4QIbmKlRaJdbWgdY.ttf',
    variant: '300',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a6b128d1-c448-40d3-be98-13ca216292f7',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0bX2SRONuN4SCj8wlVQIfTTkdbJYA.ttf',
    variant: '200italic',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '3ce5d00f-59d7-4c2e-9bd2-8aed9a9e9666',
    origin: GF_SOURCE,
    url: GF_URL + 'mali/v4/N0bV2SRONuN4QOLlKlRaJdbWgdY.ttf',
    variant: '200',
    family: 'Mali',
    category: 'handwriting',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'b31660f5-83a2-4020-85ff-602004d39604',
    origin: GF_SOURCE,
    url: GF_URL + 'yesevaone/v15/OpNJno4ck8vc-xYpwWWxpipfWhXD00c.ttf',
    variant: 'regular',
    family: 'Yeseva One',
    category: 'display',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '0efbb964-8d03-420d-8b9a-c9694012d0c3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'recursive/v23/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadArlE18vwxjDJCL.ttf',
    variant: '900',
    family: 'Recursive',
    category: 'sans-serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '7d2721bf-af0b-4a30-8409-aec93dc0660d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'recursive/v23/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadAClE18vwxjDJCL.ttf',
    variant: '800',
    family: 'Recursive',
    category: 'sans-serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '1fe6a2f1-499c-47ee-b8b8-157c7db5dc37',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'recursive/v23/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadBllE18vwxjDJCL.ttf',
    variant: '700',
    family: 'Recursive',
    category: 'sans-serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '7520579b-da1b-48a1-bd61-3f9350cfadf9',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'recursive/v23/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadBclE18vwxjDJCL.ttf',
    variant: '600',
    family: 'Recursive',
    category: 'sans-serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '68437327-939b-4763-b02b-2a985218c7f7',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'recursive/v23/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadCwk018vwxjDJCL.ttf',
    variant: '500',
    family: 'Recursive',
    category: 'sans-serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: 'e90a9028-75a2-4d7f-9592-c0743f80c397',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'recursive/v23/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadCCk018vwxjDJCL.ttf',
    variant: 'regular',
    family: 'Recursive',
    category: 'sans-serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '9d9fe06d-4d90-4b9f-8485-f40753da8deb',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'recursive/v23/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadDck018vwxjDJCL.ttf',
    variant: '300',
    family: 'Recursive',
    category: 'sans-serif',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: 'e43392c9-004c-4e8c-b07c-4ff8ed9389fe',
    origin: GF_SOURCE,
    url: GF_URL + 'lusitana/v8/CSR74z9ShvucWzsMKyDmaccqYtd2vfwk.ttf',
    variant: '700',
    family: 'Lusitana',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '3814632b-6f52-448d-a139-0a9099fb142f',
    origin: GF_SOURCE,
    url: GF_URL + 'lusitana/v8/CSR84z9ShvucWzsMKxhaRuMiSct_.ttf',
    variant: 'regular',
    family: 'Lusitana',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '1610fd86-d409-4c13-b886-b8876dcc1f85',
    origin: GF_SOURCE,
    url: GF_URL + 'benchnine/v9/ahcev8612zF4jxrwMosT6-xRhWa8q0v8ag.ttf',
    variant: '700',
    family: 'BenchNine',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '74ef00da-93eb-4793-acfe-fc546eef2d14',
    origin: GF_SOURCE,
    url: GF_URL + 'benchnine/v9/ahcbv8612zF4jxrwMosrV8N1jU2gog.ttf',
    variant: 'regular',
    family: 'BenchNine',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'e604a0c6-06eb-4ed3-99fc-3d0fae7c0a9c',
    origin: GF_SOURCE,
    url: GF_URL + 'benchnine/v9/ahcev8612zF4jxrwMosT--tRhWa8q0v8ag.ttf',
    variant: '300',
    family: 'BenchNine',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '7883d7fd-8b81-4cda-9f38-67d99820ffae',
    origin: GF_SOURCE,
    url:
      GF_URL + 'nothingyoucoulddo/v10/oY1B8fbBpaP5OX3DtrRYf_Q2BPB1SnfZb0OJl1ol2Ymo.ttf',
    variant: 'regular',
    family: 'Nothing You Could Do',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '5d815803-5f5c-4326-8ae3-a0dec6af0b96',
    origin: GF_SOURCE,
    url: GF_URL + 'fugazone/v10/rax_HiWKp9EAITukFslMBBJek0vA8A.ttf',
    variant: 'regular',
    family: 'Fugaz One',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '7b331f1c-467a-4580-bf66-7de25660f3c2',
    origin: GF_SOURCE,
    url: GF_URL + 'nanumpenscript/v15/daaDSSYiLGqEal3MvdA_FOL_3FkN2z7-aMFCcTU.ttf',
    variant: 'regular',
    family: 'Nanum Pen Script',
    category: 'handwriting',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'bb59b9af-1e33-4caa-88eb-5b6783dcb083',
    origin: GF_SOURCE,
    url: GF_URL + 'amaranth/v11/KtkrALODe433f0j1zMnAJWmn42T9E4ucRY8.ttf',
    variant: '700italic',
    family: 'Amaranth',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '50433a96-1a70-4789-8167-91f86e94724a',
    origin: GF_SOURCE,
    url: GF_URL + 'amaranth/v11/KtkpALODe433f0j1zMF-OPWi6WDfFpuc.ttf',
    variant: '700',
    family: 'Amaranth',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '20fdf9ad-912a-4718-a910-ab3f0cd9218a',
    origin: GF_SOURCE,
    url: GF_URL + 'amaranth/v11/KtkoALODe433f0j1zMnAHdWIx2zWD4I.ttf',
    variant: 'italic',
    family: 'Amaranth',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '841ea2d3-1246-4576-9f67-f5a4c7574d2d',
    origin: GF_SOURCE,
    url: GF_URL + 'amaranth/v11/KtkuALODe433f0j1zPnCF9GqwnzW.ttf',
    variant: 'regular',
    family: 'Amaranth',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'dc7ca077-b98f-4b02-9514-c8b0988e6a72',
    origin: GF_SOURCE,
    url: GF_URL + 'mitr/v6/pxiEypw5ucZF8YcdFJDUc1NECPY.ttf',
    variant: '700',
    family: 'Mitr',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bc354917-ac52-4c17-8976-c9bf4ed9fc3c',
    origin: GF_SOURCE,
    url: GF_URL + 'mitr/v6/pxiEypw5ucZF8eMcFJDUc1NECPY.ttf',
    variant: '600',
    family: 'Mitr',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '80b1c0d6-b8bb-4b88-9a27-fe723b7a1bc7',
    origin: GF_SOURCE,
    url: GF_URL + 'mitr/v6/pxiEypw5ucZF8c8bFJDUc1NECPY.ttf',
    variant: '500',
    family: 'Mitr',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a961da68-1c82-4b38-9f97-35abe728e683',
    origin: GF_SOURCE,
    url: GF_URL + 'mitr/v6/pxiLypw5ucZFyTsyMJj_b1o.ttf',
    variant: 'regular',
    family: 'Mitr',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'eb3d7c71-60fd-4ad4-8366-38f4b2219fcc',
    origin: GF_SOURCE,
    url: GF_URL + 'mitr/v6/pxiEypw5ucZF8ZcaFJDUc1NECPY.ttf',
    variant: '300',
    family: 'Mitr',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'b664b779-f3ea-4ad3-9b8b-b8ca55d628cf',
    origin: GF_SOURCE,
    url: GF_URL + 'mitr/v6/pxiEypw5ucZF8fMZFJDUc1NECPY.ttf',
    variant: '200',
    family: 'Mitr',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ea98b6bb-1c6f-4f43-bcad-a4f8c3175055',
    origin: GF_SOURCE,
    url: GF_URL + 'adamina/v14/j8_r6-DH1bjoc-dwu-reETl4Bno.ttf',
    variant: 'regular',
    family: 'Adamina',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '89064619-79e6-4275-a447-e29feb43183a',
    origin: GF_SOURCE,
    url: GF_URL + 'rocksalt/v11/MwQ0bhv11fWD6QsAVOZbsEk7hbBWrA.ttf',
    variant: 'regular',
    family: 'Rock Salt',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f015e023-bfa0-425b-ac60-f8dfc4463b3b',
    origin: GF_SOURCE,
    url: GF_URL + 'electrolize/v9/cIf5Ma1dtE0zSiGSiED7AUEGso5tQafB.ttf',
    variant: 'regular',
    family: 'Electrolize',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '63d559e1-10a9-4104-bb13-edf87eb3ce6d',
    origin: GF_SOURCE,
    url: GF_URL + 'juliussansone/v9/1Pt2g8TAX_SGgBGUi0tGOYEga5W-xXEW6aGXHw.ttf',
    variant: 'regular',
    family: 'Julius Sans One',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'b548bd18-df0c-482b-a025-a35e7655f2ff',
    origin: GF_SOURCE,
    url: GF_URL + 'alef/v12/FeVQS0NQpLYglo50L5la2bxii28.ttf',
    variant: '700',
    family: 'Alef',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '9d195104-2b31-44e0-8ba4-f22eec75ff09',
    origin: GF_SOURCE,
    url: GF_URL + 'alef/v12/FeVfS0NQpLYgrjJbC5FxxbU.ttf',
    variant: 'regular',
    family: 'Alef',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '985e29fd-baf5-4ae8-a2b2-6e727fe565ca',
    origin: GF_SOURCE,
    url: GF_URL + 'aleo/v4/c4mi1nF8G8_swAjxaDBJmq159KOnWA.ttf',
    variant: '700italic',
    family: 'Aleo',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '1e5bc32b-b5f9-41ea-99f6-a5aa6247ebcd',
    origin: GF_SOURCE,
    url: GF_URL + 'aleo/v4/c4mg1nF8G8_syLbs9DVDno985KM.ttf',
    variant: '700',
    family: 'Aleo',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '1c74b8b7-39bf-4e2d-bb3a-91d5d2b10bdb',
    origin: GF_SOURCE,
    url: GF_URL + 'aleo/v4/c4mh1nF8G8_swAjJ1B9tkoZl_Q.ttf',
    variant: 'italic',
    family: 'Aleo',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '9bd48d89-15ed-4dad-86b5-d1a61a241220',
    origin: GF_SOURCE,
    url: GF_URL + 'aleo/v4/c4mv1nF8G8_s8ArD0D1ogoY.ttf',
    variant: 'regular',
    family: 'Aleo',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ee81f20a-36ce-4857-b549-7fd639015b7d',
    origin: GF_SOURCE,
    url: GF_URL + 'aleo/v4/c4mi1nF8G8_swAjxeDdJmq159KOnWA.ttf',
    variant: '300italic',
    family: 'Aleo',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '1ff2677d-8d45-45f3-8c88-3020a4bc4b03',
    origin: GF_SOURCE,
    url: GF_URL + 'aleo/v4/c4mg1nF8G8_syKbr9DVDno985KM.ttf',
    variant: '300',
    family: 'Aleo',
    category: 'serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd2c61f5f-d6b3-45e5-a0f6-c69a1644f758',
    origin: GF_SOURCE,
    url: GF_URL + 'marckscript/v11/nwpTtK2oNgBA3Or78gapdwuCzyI-aMPF7Q.ttf',
    variant: 'regular',
    family: 'Marck Script',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b6feda5d-a92d-4ecf-8b5b-6803c8b5dda5',
    origin: GF_SOURCE,
    url: GF_URL + 'monoton/v10/5h1aiZUrOngCibe4fkbBQ2S7FU8.ttf',
    variant: 'regular',
    family: 'Monoton',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '9059408a-eb04-4489-b85f-df588d7e7d6c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'gentiumbookbasic/v11/pe0-MJCbPYBVokB1LHA9bbyaQb8ZGjc4VYnDzofc81s0voO3.ttf',
    variant: '700italic',
    family: 'Gentium Book Basic',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '16a91167-10ea-4381-b59f-8b740299f52c',
    origin: GF_SOURCE,
    url:
      GF_URL + 'gentiumbookbasic/v11/pe0wMJCbPYBVokB1LHA9bbyaQb8ZGjcw65Rfy43Y0V4kvg.ttf',
    variant: '700',
    family: 'Gentium Book Basic',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c0dba240-1e14-4ba3-99d1-34667a0da703',
    origin: GF_SOURCE,
    url: GF_URL + 'gentiumbookbasic/v11/pe0xMJCbPYBVokB1LHA9bbyaQb8ZGjc4VbF_4aPU2Ec9.ttf',
    variant: 'italic',
    family: 'Gentium Book Basic',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0c5f20c4-aea9-449e-a6b4-d340f067bfae',
    origin: GF_SOURCE,
    url: GF_URL + 'gentiumbookbasic/v11/pe0zMJCbPYBVokB1LHA9bbyaQb8ZGjcIV7t7w6bE2A.ttf',
    variant: 'regular',
    family: 'Gentium Book Basic',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '2c37cfa9-b667-4c9b-a1fa-50f674ca4188',
    origin: GF_SOURCE,
    url: GF_URL + 'badscript/v9/6NUT8F6PJgbFWQn47_x7lOwuzd1AZtw.ttf',
    variant: 'regular',
    family: 'Bad Script',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '104052b3-2cad-4a2a-ad75-d5d0b5c1633d',
    origin: GF_SOURCE,
    url: GF_URL + 'playball/v10/TK3gWksYAxQ7jbsKcj8Dl-tPKo2t.ttf',
    variant: 'regular',
    family: 'Playball',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '1b856db8-9f1f-45fc-baf1-a59c424e9482',
    origin: GF_SOURCE,
    url: GF_URL + 'unna/v16/AYCJpXzofN0NOpozLGzjQHhuzvef5Q.ttf',
    variant: '700italic',
    family: 'Unna',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'a61ad024-2048-4aa0-a140-30b42e6d1948',
    origin: GF_SOURCE,
    url: GF_URL + 'unna/v16/AYCLpXzofN0NMiQusGnpRFpr3vc.ttf',
    variant: '700',
    family: 'Unna',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '032d1e3b-6309-47bd-b857-7688493f4006',
    origin: GF_SOURCE,
    url: GF_URL + 'unna/v16/AYCKpXzofN0NOpoLkEPHSFNyxw.ttf',
    variant: 'italic',
    family: 'Unna',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'd6c88d6c-007b-4bc7-9751-d1d56b6963da',
    origin: GF_SOURCE,
    url: GF_URL + 'unna/v16/AYCEpXzofN0NCpgBlGHCWFM.ttf',
    variant: 'regular',
    family: 'Unna',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'fc05b30d-ebf5-4baa-8fe5-8de9df10704e',
    origin: GF_SOURCE,
    url: GF_URL + 'quantico/v10/rax7HiSdp9cPL3KIF7xuHIRfu0ry9TadML4.ttf',
    variant: '700italic',
    family: 'Quantico',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '1892a6a0-c85d-479f-9cf3-c3d105fb5e2c',
    origin: GF_SOURCE,
    url: GF_URL + 'quantico/v10/rax5HiSdp9cPL3KIF7TQARhasU7Q8Cad.ttf',
    variant: '700',
    family: 'Quantico',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'e38dcaa9-b662-40c1-aca6-ef4fa1b11333',
    origin: GF_SOURCE,
    url: GF_URL + 'quantico/v10/rax4HiSdp9cPL3KIF7xuJDhwn0LZ6T8.ttf',
    variant: 'italic',
    family: 'Quantico',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'd2adde08-7376-4b8f-a4b1-1c504e91957b',
    origin: GF_SOURCE,
    url: GF_URL + 'quantico/v10/rax-HiSdp9cPL3KIF4xsLjxSmlLZ.ttf',
    variant: 'regular',
    family: 'Quantico',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '9223bc1b-4a02-47c6-ad78-c3d4373ced7d',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifsc/v8/H4c8BXePl9DZ0Xe7gG9cyOj7miKxSzZBEtERe7U.otf',
    variant: '900',
    family: 'Noto Serif SC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'a1f9a22a-4d44-4a72-bde7-ca6900f82293',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifsc/v8/H4c8BXePl9DZ0Xe7gG9cyOj7mhqzSzZBEtERe7U.otf',
    variant: '700',
    family: 'Noto Serif SC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b9130241-2a2a-4e9a-b19d-a584b545256c',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifsc/v8/H4c8BXePl9DZ0Xe7gG9cyOj7mn6ySzZBEtERe7U.otf',
    variant: '600',
    family: 'Noto Serif SC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '97b41a09-34d8-47f6-bd14-51776cd4ccac',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifsc/v8/H4c8BXePl9DZ0Xe7gG9cyOj7mlK1SzZBEtERe7U.otf',
    variant: '500',
    family: 'Noto Serif SC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '85c853ec-d85d-411c-ad88-f9463b48713c',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifsc/v8/H4chBXePl9DZ0Xe7gG9cyOj7oqCcbzhqDtg.otf',
    variant: 'regular',
    family: 'Noto Serif SC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '8c432215-1673-498b-9a2f-23c9971faf87',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifsc/v8/H4c8BXePl9DZ0Xe7gG9cyOj7mgq0SzZBEtERe7U.otf',
    variant: '300',
    family: 'Noto Serif SC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '43bfbd3f-9dc4-402c-b876-654dd3ef453d',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifsc/v8/H4c8BXePl9DZ0Xe7gG9cyOj7mm63SzZBEtERe7U.otf',
    variant: '200',
    family: 'Noto Serif SC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '5958f8c1-0fd7-4ae3-a150-3cf2155421d3',
    origin: GF_SOURCE,
    url: GF_URL + 'carterone/v12/q5uCsoe5IOB2-pXv9UcNIxR2hYxREMs.ttf',
    variant: 'regular',
    family: 'Carter One',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '0bb51633-6e9b-4bd4-b4b2-6a3b3cd66c34',
    origin: GF_SOURCE,
    url: GF_URL + 'tangerine/v12/Iurd6Y5j_oScZZow4VO5srNpjJtM6G0t9w.ttf',
    variant: '700',
    family: 'Tangerine',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '56d26285-44ae-4141-8074-6a5e4fb1bdaf',
    origin: GF_SOURCE,
    url: GF_URL + 'tangerine/v12/IurY6Y5j_oScZZow4VOBDpxNhLBQ4Q.ttf',
    variant: 'regular',
    family: 'Tangerine',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '21a89b94-244d-424c-a855-49ef8d1a89f5',
    origin: GF_SOURCE,
    url: GF_URL + 'paytoneone/v13/0nksC9P7MfYHj2oFtYm2CiTqivr9iBq_.ttf',
    variant: 'regular',
    family: 'Paytone One',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '32b57329-baaf-4c50-8591-8c1759e0df6d',
    origin: GF_SOURCE,
    url: GF_URL + 'elmessiri/v9/K2F3fZBRmr9vQ1pHEey6OjalJyrYYWOMluQ.ttf',
    variant: '700',
    family: 'El Messiri',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '37421584-0c3b-47c5-b44f-a1d359bb19f9',
    origin: GF_SOURCE,
    url: GF_URL + 'elmessiri/v9/K2F3fZBRmr9vQ1pHEey6OlKkJyrYYWOMluQ.ttf',
    variant: '600',
    family: 'El Messiri',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '88819707-b137-424b-b5b5-72859bce3e0f',
    origin: GF_SOURCE,
    url: GF_URL + 'elmessiri/v9/K2F3fZBRmr9vQ1pHEey6On6jJyrYYWOMluQ.ttf',
    variant: '500',
    family: 'El Messiri',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '720d6099-526d-442e-8c80-ebec836491bb',
    origin: GF_SOURCE,
    url: GF_URL + 'elmessiri/v9/K2F0fZBRmr9vQ1pHEey6AoqKAyLzfWo.ttf',
    variant: 'regular',
    family: 'El Messiri',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '8bfc2bb1-b6e0-4416-887b-f6f68d497fe6',
    origin: GF_SOURCE,
    url: GF_URL + 'pontanosans/v8/qFdD35GdgYR8EzR6oBLDHa3qwjUMg1siNQ.ttf',
    variant: 'regular',
    family: 'Pontano Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '6ac01cef-0f67-4b78-9813-f2eb0a169ed7',
    origin: GF_SOURCE,
    url: GF_URL + 'ptmono/v8/9oRONYoBnWILk-9ArCg5MtPyAcg.ttf',
    variant: 'regular',
    family: 'PT Mono',
    category: 'monospace',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'cc44a6e3-9d91-4b4e-83c0-7b0d84e41494',
    origin: GF_SOURCE,
    url: GF_URL + 'khula/v7/OpNPnoEOns3V7G-piCvUrC59XwXD.ttf',
    variant: '800',
    family: 'Khula',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'aea6b40a-984f-4942-9910-41719ca9946c',
    origin: GF_SOURCE,
    url: GF_URL + 'khula/v7/OpNPnoEOns3V7G-1iyvUrC59XwXD.ttf',
    variant: '700',
    family: 'Khula',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'b0bba4b5-d05f-4125-92eb-134c8841d068',
    origin: GF_SOURCE,
    url: GF_URL + 'khula/v7/OpNPnoEOns3V7G_RiivUrC59XwXD.ttf',
    variant: '600',
    family: 'Khula',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '81abcb29-8b0b-4b5b-9d3c-82cb3b87527b',
    origin: GF_SOURCE,
    url: GF_URL + 'khula/v7/OpNCnoEOns3V7FcJpA_chzJ0.ttf',
    variant: 'regular',
    family: 'Khula',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '219a5f79-215b-435c-bb8f-ac3df929126a',
    origin: GF_SOURCE,
    url: GF_URL + 'khula/v7/OpNPnoEOns3V7G-ljCvUrC59XwXD.ttf',
    variant: '300',
    family: 'Khula',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd49bf7c8-c933-453e-805d-f33303409a00',
    origin: GF_SOURCE,
    url: GF_URL + 'alata/v2/PbytFmztEwbIofe6xKcRQEOX.ttf',
    variant: 'regular',
    family: 'Alata',
    category: 'sans-serif',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '29fa4b5a-7481-4267-b757-04c3408be19d',
    origin: GF_SOURCE,
    url: GF_URL + 'sawarabigothic/v8/x3d4ckfVaqqa-BEj-I9mE65u3k3NBSk3E2YljQ.ttf',
    variant: 'regular',
    family: 'Sawarabi Gothic',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '014ccff1-8cdf-4833-85d3-7667bb65e63e',
    origin: GF_SOURCE,
    url: GF_URL + 'cabincondensed/v14/nwpJtK6mNhBK2err_hqkYhHRqmwi3Mf97F15-K1oqQ.ttf',
    variant: '700',
    family: 'Cabin Condensed',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '0da9cd2e-6324-40d8-b73c-e45050349133',
    origin: GF_SOURCE,
    url: GF_URL + 'cabincondensed/v14/nwpJtK6mNhBK2err_hqkYhHRqmwiuMb97F15-K1oqQ.ttf',
    variant: '600',
    family: 'Cabin Condensed',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '6e1ebd63-9cc5-44f6-b2b8-6618add0a922',
    origin: GF_SOURCE,
    url: GF_URL + 'cabincondensed/v14/nwpJtK6mNhBK2err_hqkYhHRqmwilMH97F15-K1oqQ.ttf',
    variant: '500',
    family: 'Cabin Condensed',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '5f94fc35-6b71-4f63-b7be-40653ea171a9',
    origin: GF_SOURCE,
    url: GF_URL + 'cabincondensed/v14/nwpMtK6mNhBK2err_hqkYhHRqmwaYOjZ5HZl8Q.ttf',
    variant: 'regular',
    family: 'Cabin Condensed',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'd526289d-3fd1-4f2d-9710-2012dee7d977',
    origin: GF_SOURCE,
    url: GF_URL + 'gentiumbasic/v12/WnzmHAw9aB_JD2VGQVR80We3LAixMT8eaKiNbBVWkw.ttf',
    variant: '700italic',
    family: 'Gentium Basic',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'f0859625-60d6-4c34-942b-981dac8b780d',
    origin: GF_SOURCE,
    url: GF_URL + 'gentiumbasic/v12/WnzgHAw9aB_JD2VGQVR80We3JLasrToUbIqIfBU.ttf',
    variant: '700',
    family: 'Gentium Basic',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'e80a5715-dbf5-446c-8cce-912074b50ecc',
    origin: GF_SOURCE,
    url: GF_URL + 'gentiumbasic/v12/WnzjHAw9aB_JD2VGQVR80We3LAiJjRA6YIORZQ.ttf',
    variant: 'italic',
    family: 'Gentium Basic',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'bc3c19be-ac01-4a5c-b08f-a6f9609b7e2c',
    origin: GF_SOURCE,
    url: GF_URL + 'gentiumbasic/v12/Wnz9HAw9aB_JD2VGQVR80We3HAqDiTI_cIM.ttf',
    variant: 'regular',
    family: 'Gentium Basic',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c749b8e2-edc6-41e8-8f2f-559e914b511d',
    origin: GF_SOURCE,
    url: GF_URL + 'homemadeapple/v11/Qw3EZQFXECDrI2q789EKQZJob3x9Vnksi4M7.ttf',
    variant: 'regular',
    family: 'Homemade Apple',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'ae7acfc5-9b19-4100-ac89-415bb075ce43',
    origin: GF_SOURCE,
    url: GF_URL + 'alice/v12/OpNCnoEEmtHa6FcJpA_chzJ0.ttf',
    variant: 'regular',
    family: 'Alice',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'aaa828bd-c0dc-4970-9197-083d676903e0',
    origin: GF_SOURCE,
    url: GF_URL + 'spacemono/v6/i7dSIFZifjKcF5UAWdDRYERE_FeaGy6QZ3WfYg.ttf',
    variant: '700italic',
    family: 'Space Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8f91900d-ec98-4191-ac27-e285891c4914',
    origin: GF_SOURCE,
    url: GF_URL + 'spacemono/v6/i7dMIFZifjKcF5UAWdDRaPpZYFKQHwyVd3U.ttf',
    variant: '700',
    family: 'Space Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '800cb483-2a84-465b-a03d-ff6afe586655',
    origin: GF_SOURCE,
    url: GF_URL + 'spacemono/v6/i7dNIFZifjKcF5UAWdDRYER8QHi-EwWMbg.ttf',
    variant: 'italic',
    family: 'Space Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c5c0deae-6fba-4c37-bac1-29ed21d979c5',
    origin: GF_SOURCE,
    url: GF_URL + 'spacemono/v6/i7dPIFZifjKcF5UAWdDRUEZ2RFq7AwU.ttf',
    variant: 'regular',
    family: 'Space Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '695f3777-ab64-49a8-abfe-5d3ba7860d52',
    origin: GF_SOURCE,
    url: GF_URL + 'ruda/v14/k3kKo8YQJOpFgHQ1mQ5VkEbUKaLstS_-2KiSGg-H.ttf',
    variant: '900',
    family: 'Ruda',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '511901f0-353b-438f-86e3-4e694aa3faf8',
    origin: GF_SOURCE,
    url: GF_URL + 'ruda/v14/k3kKo8YQJOpFgHQ1mQ5VkEbUKaLFtS_-2KiSGg-H.ttf',
    variant: '800',
    family: 'Ruda',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'd426aa3b-f7e7-4dee-97e7-aea1d1b03a87',
    origin: GF_SOURCE,
    url: GF_URL + 'ruda/v14/k3kKo8YQJOpFgHQ1mQ5VkEbUKaKitS_-2KiSGg-H.ttf',
    variant: '700',
    family: 'Ruda',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'ac4afe0b-0b01-4800-8f41-5faaeb730081',
    origin: GF_SOURCE,
    url: GF_URL + 'ruda/v14/k3kKo8YQJOpFgHQ1mQ5VkEbUKaKbtS_-2KiSGg-H.ttf',
    variant: '600',
    family: 'Ruda',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '974220ae-fc5d-404c-9d15-cc9a387a56a1',
    origin: GF_SOURCE,
    url: GF_URL + 'ruda/v14/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJ3si_-2KiSGg-H.ttf',
    variant: '500',
    family: 'Ruda',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'af0473f4-b965-4c05-9c7d-71b325a45166',
    origin: GF_SOURCE,
    url: GF_URL + 'ruda/v14/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJFsi_-2KiSGg-H.ttf',
    variant: 'regular',
    family: 'Ruda',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'c0412eb4-9078-4da9-b28b-caca2b904499',
    origin: GF_SOURCE,
    url: GF_URL + 'gudea/v10/neIIzCqgsI0mp9gz26WGHK06UY30.ttf',
    variant: '700',
    family: 'Gudea',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '1457126e-fd0b-49ed-a9a9-1adf87f68c31',
    origin: GF_SOURCE,
    url: GF_URL + 'gudea/v10/neILzCqgsI0mp9CN_oWsMqEzSJQ.ttf',
    variant: 'italic',
    family: 'Gudea',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8cabf767-f2b1-4198-be7e-56a929f5a479',
    origin: GF_SOURCE,
    url: GF_URL + 'gudea/v10/neIFzCqgsI0mp-CP9IGON7Ez.ttf',
    variant: 'regular',
    family: 'Gudea',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6c90ebed-b923-4c80-9d65-3b4e7c3bc11f',
    origin: GF_SOURCE,
    url: GF_URL + 'economica/v8/Qw3EZQZaHCLgIWa29ZBbM_q4D3x9Vnksi4M7.ttf',
    variant: '700italic',
    family: 'Economica',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b6f16dc8-b2a1-4c59-831a-728dc6141176',
    origin: GF_SOURCE,
    url: GF_URL + 'economica/v8/Qw3aZQZaHCLgIWa29ZBTjeckCnZ5dHw8iw.ttf',
    variant: '700',
    family: 'Economica',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f3b479b0-2cdd-47fe-825a-691798a7973f',
    origin: GF_SOURCE,
    url: GF_URL + 'economica/v8/Qw3ZZQZaHCLgIWa29ZBbM8IEIFh1fWUl.ttf',
    variant: 'italic',
    family: 'Economica',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c464c9f8-e152-40db-9568-1550be81e090',
    origin: GF_SOURCE,
    url: GF_URL + 'economica/v8/Qw3fZQZaHCLgIWa29ZBrMcgAAl1lfQ.ttf',
    variant: 'regular',
    family: 'Economica',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '20fd4563-5f26-4b6c-91ce-a245e8816228',
    origin: GF_SOURCE,
    url: GF_URL + 'actor/v10/wEOzEBbCkc5cO3ekXygtUMIO.ttf',
    variant: 'regular',
    family: 'Actor',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '5828400e-5952-422f-895d-4f442d1d8e6c',
    origin: GF_SOURCE,
    url: GF_URL + 'jura/v16/z7NOdRfiaC4Vd8hhoPzfb5vBTP266quhTfmrH_rt.ttf',
    variant: '700',
    family: 'Jura',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '690e4ba5-31f8-4fc1-9ca2-2d1b7b381060',
    origin: GF_SOURCE,
    url: GF_URL + 'jura/v16/z7NOdRfiaC4Vd8hhoPzfb5vBTP2D6quhTfmrH_rt.ttf',
    variant: '600',
    family: 'Jura',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '3606977f-c747-4f9e-80ae-3ea7eee4f324',
    origin: GF_SOURCE,
    url: GF_URL + 'jura/v16/z7NOdRfiaC4Vd8hhoPzfb5vBTP1v7auhTfmrH_rt.ttf',
    variant: '500',
    family: 'Jura',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '3fbfdabf-0f98-4eeb-b747-91563838b8da',
    origin: GF_SOURCE,
    url: GF_URL + 'jura/v16/z7NOdRfiaC4Vd8hhoPzfb5vBTP1d7auhTfmrH_rt.ttf',
    variant: 'regular',
    family: 'Jura',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '810d6ba3-e679-48ef-8dce-81003e545f91',
    origin: GF_SOURCE,
    url: GF_URL + 'jura/v16/z7NOdRfiaC4Vd8hhoPzfb5vBTP0D7auhTfmrH_rt.ttf',
    variant: '300',
    family: 'Jura',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '888f429d-150c-46d8-b5f3-8eefbf585e04',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDIoapSCOBt_aeQQ7ftydoa8W_pylpox2S2CgOva.ttf',
    variant: '700italic',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'fbc7cf6e-8d1a-44ef-a505-b20d542f77f8',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDIqapSCOBt_aeQQ7ftydoa05efuk5A1-yiSgA.ttf',
    variant: '700',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '0313f83e-7915-452c-919c-59bd189330bb',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDIoapSCOBt_aeQQ7ftydoa8W_oWl5ox2S2CgOva.ttf',
    variant: '600italic',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '789da059-3502-4b01-921f-c70bd4d0ebc4',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDIqapSCOBt_aeQQ7ftydoa0gebuk5A1-yiSgA.ttf',
    variant: '600',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd9e64f1a-0c69-495e-8f78-3c56f123adef',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDIoapSCOBt_aeQQ7ftydoa8W_o6kJox2S2CgOva.ttf',
    variant: '500italic',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '55c6d60e-5d03-49ac-9019-16393cfe436f',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDIqapSCOBt_aeQQ7ftydoa0reHuk5A1-yiSgA.ttf',
    variant: '500',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '7d7f09a3-4d48-4116-b70f-eaba655c6942',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDIrapSCOBt_aeQQ7ftydoa8W8LOub458jGL.ttf',
    variant: 'italic',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '75ad710c-140f-4f92-9423-e5bf53ca5ed5',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDI1apSCOBt_aeQQ7ftydoaMWcjKm7sp8g.ttf',
    variant: 'regular',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '85971e6e-0104-46df-93df-359980f2036f',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDIoapSCOBt_aeQQ7ftydoa8W_pikZox2S2CgOva.ttf',
    variant: '300italic',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ef3d8d3f-18d6-4fa4-9991-4f91e199b70b',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDIqapSCOBt_aeQQ7ftydoa09eDuk5A1-yiSgA.ttf',
    variant: '300',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'ffea943b-69e8-4901-876e-7ccd947d6922',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDIoapSCOBt_aeQQ7ftydoa8W_oGkpox2S2CgOva.ttf',
    variant: '200italic',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'b299be15-9203-4aa8-b8db-5639cd4f185e',
    origin: GF_SOURCE,
    url: GF_URL + 'baijamjuree/v4/LDIqapSCOBt_aeQQ7ftydoa0kePuk5A1-yiSgA.ttf',
    variant: '200',
    family: 'Bai Jamjuree',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'f52628b5-455e-4e42-ada9-7b318abc57b3',
    origin: GF_SOURCE,
    url: GF_URL + 'hindvadodara/v7/neIQzCKvrIcn5pbuuuriV9tTSCnwiXM0oSOL2Yw.ttf',
    variant: '700',
    family: 'Hind Vadodara',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'f50ad19f-a456-4770-bdab-0fee6a11ee90',
    origin: GF_SOURCE,
    url: GF_URL + 'hindvadodara/v7/neIQzCKvrIcn5pbuuuriV9tTSE3xiXM0oSOL2Yw.ttf',
    variant: '600',
    family: 'Hind Vadodara',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '3eb1e7c6-8968-43bf-964a-00f2c9f21b37',
    origin: GF_SOURCE,
    url: GF_URL + 'hindvadodara/v7/neIQzCKvrIcn5pbuuuriV9tTSGH2iXM0oSOL2Yw.ttf',
    variant: '500',
    family: 'Hind Vadodara',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '6ff93bf4-99fd-4d11-9fb1-59aa428f9ec3',
    origin: GF_SOURCE,
    url: GF_URL + 'hindvadodara/v7/neINzCKvrIcn5pbuuuriV9tTcJXfrXsfvSo.ttf',
    variant: 'regular',
    family: 'Hind Vadodara',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'bb56b46d-abe3-4a78-84a4-8c1b30ba4eb6',
    origin: GF_SOURCE,
    url: GF_URL + 'hindvadodara/v7/neIQzCKvrIcn5pbuuuriV9tTSDn3iXM0oSOL2Yw.ttf',
    variant: '300',
    family: 'Hind Vadodara',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'c25d2242-0dfd-40b6-871a-0b813bc17196',
    origin: GF_SOURCE,
    url: GF_URL + 'neucha/v12/q5uGsou0JOdh94bvugNsCxVEgA.ttf',
    variant: 'regular',
    family: 'Neucha',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c8d19129-5a38-4156-9f17-d578faf1a025',
    origin: GF_SOURCE,
    url: GF_URL + 'audiowide/v9/l7gdbjpo0cum0ckerWCtkQXPExpQBw.ttf',
    variant: 'regular',
    family: 'Audiowide',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '62a2adc2-9617-4268-94a7-1133cbbd3973',
    origin: GF_SOURCE,
    url: GF_URL + 'karma/v11/va9F4kzAzMZRGLjTZMZ_uqzGQC_-.ttf',
    variant: '700',
    family: 'Karma',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '181ca4b7-28ab-4ddf-904f-73c1e285da5c',
    origin: GF_SOURCE,
    url: GF_URL + 'karma/v11/va9F4kzAzMZRGLi3ZcZ_uqzGQC_-.ttf',
    variant: '600',
    family: 'Karma',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '09297a59-5f07-4121-881f-d3d3da0c1443',
    origin: GF_SOURCE,
    url: GF_URL + 'karma/v11/va9F4kzAzMZRGLibYsZ_uqzGQC_-.ttf',
    variant: '500',
    family: 'Karma',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '425b760a-2901-43e0-a61f-33ccdd8b634f',
    origin: GF_SOURCE,
    url: GF_URL + 'karma/v11/va9I4kzAzMZRGIBvS-J3kbDP.ttf',
    variant: 'regular',
    family: 'Karma',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '4b6f6d6c-1ba5-4b7f-9134-da1f829829fa',
    origin: GF_SOURCE,
    url: GF_URL + 'karma/v11/va9F4kzAzMZRGLjDY8Z_uqzGQC_-.ttf',
    variant: '300',
    family: 'Karma',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c8aa5c4b-0f43-421e-aea2-44b73f665909',
    origin: GF_SOURCE,
    url: GF_URL + 'unicaone/v8/DPEuYwWHyAYGVTSmalshdtffuEY7FA.ttf',
    variant: 'regular',
    family: 'Unica One',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '2eb8f24a-d44a-456c-9499-70d59ffe1e35',
    origin: GF_SOURCE,
    url: GF_URL + 'luckiestguy/v11/_gP_1RrxsjcxVyin9l9n_j2RStR3qDpraA.ttf',
    variant: 'regular',
    family: 'Luckiest Guy',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a6a14b0a-0e55-4482-8184-de00184b441e',
    origin: GF_SOURCE,
    url: GF_URL + 'notoseriftc/v8/XLY9IZb5bJNDGYxLBibeHZ0BvvMpbX9GTsoOAX4.otf',
    variant: '900',
    family: 'Noto Serif TC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e1a715e1-04bb-4278-a80b-2149200dc95b',
    origin: GF_SOURCE,
    url: GF_URL + 'notoseriftc/v8/XLY9IZb5bJNDGYxLBibeHZ0BvssrbX9GTsoOAX4.otf',
    variant: '700',
    family: 'Noto Serif TC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '3e336ae0-7d37-4986-a94e-c6210c204bba',
    origin: GF_SOURCE,
    url: GF_URL + 'notoseriftc/v8/XLY9IZb5bJNDGYxLBibeHZ0Bvq8qbX9GTsoOAX4.otf',
    variant: '600',
    family: 'Noto Serif TC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '9071bca6-ea2a-45cd-aa99-febe6226c816',
    origin: GF_SOURCE,
    url: GF_URL + 'notoseriftc/v8/XLY9IZb5bJNDGYxLBibeHZ0BvoMtbX9GTsoOAX4.otf',
    variant: '500',
    family: 'Noto Serif TC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '7657f425-cead-4b6c-abf4-a56fdccd2bb1',
    origin: GF_SOURCE,
    url: GF_URL + 'notoseriftc/v8/XLYgIZb5bJNDGYxLBibeHZ0BhnEESXFtUsM.otf',
    variant: 'regular',
    family: 'Noto Serif TC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '2f3d415c-cf8d-4101-9a0b-885d6ca9cde0',
    origin: GF_SOURCE,
    url: GF_URL + 'notoseriftc/v8/XLY9IZb5bJNDGYxLBibeHZ0BvtssbX9GTsoOAX4.otf',
    variant: '300',
    family: 'Noto Serif TC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c6518240-6a53-42e4-8329-a036910d92a0',
    origin: GF_SOURCE,
    url: GF_URL + 'notoseriftc/v8/XLY9IZb5bJNDGYxLBibeHZ0Bvr8vbX9GTsoOAX4.otf',
    variant: '200',
    family: 'Noto Serif TC',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '9628087e-f7a3-42d8-ae1d-2b799246e52a',
    origin: GF_SOURCE,
    url: GF_URL + 'jaldi/v7/or3hQ67z0_CI33voSbT3LLQ1niPn.ttf',
    variant: '700',
    family: 'Jaldi',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'a38903bf-5b90-4263-8497-a3e964511427',
    origin: GF_SOURCE,
    url: GF_URL + 'jaldi/v7/or3sQ67z0_CI30NUZpD_B6g8.ttf',
    variant: 'regular',
    family: 'Jaldi',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '92ca9abe-574f-401e-b34f-af66e1972416',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahcev8Cj3ylylTXzTOwT0-5RhWa8q0v8ag.ttf',
    variant: '900italic',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '0bad65f9-aacf-4e48-aff1-266cc6ea590b',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahccv8Cj3ylylTXzRGoMd-lbgUS5u0s.ttf',
    variant: '900',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bc83ee8c-2733-4f0b-b430-0898d950cac2',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahcev8Cj3ylylTXzTOwT9-9RhWa8q0v8ag.ttf',
    variant: '800italic',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '793d3eda-5a92-47d3-a673-70ad50c54a21',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahccv8Cj3ylylTXzRE4Nd-lbgUS5u0s.ttf',
    variant: '800',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '19e64911-4b35-46ae-bd85-71bb40bc0503',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahcev8Cj3ylylTXzTOwT6-xRhWa8q0v8ag.ttf',
    variant: '700italic',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '0f9abf94-0add-4e1b-979e-424ad232f9ab',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahccv8Cj3ylylTXzRFIOd-lbgUS5u0s.ttf',
    variant: '700',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c24482fe-c5b0-4ff2-b757-58490062095f',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahcev8Cj3ylylTXzTOwTj-1RhWa8q0v8ag.ttf',
    variant: '600italic',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'e1ef4328-59db-4125-982d-2e42f91d13ca',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahccv8Cj3ylylTXzRDYPd-lbgUS5u0s.ttf',
    variant: '600',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8e202ba4-452b-44c7-a305-b0d03dce70e5',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahcev8Cj3ylylTXzTOwTo-pRhWa8q0v8ag.ttf',
    variant: '500italic',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '08d4c1cf-dbc8-42e9-9d2c-6731d86213f6',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahccv8Cj3ylylTXzRBoId-lbgUS5u0s.ttf',
    variant: '500',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'cacf1337-2cf5-45c5-ac7c-5ab0f7f579ba',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahcbv8Cj3ylylTXzTOwrV8N1jU2gog.ttf',
    variant: 'italic',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '314dfb4d-9a06-4853-9bad-e512a72a7f9d',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahcZv8Cj3ylylTXzfO4hU-FwnU0.ttf',
    variant: 'regular',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '63185bf3-4ade-4c8e-acd1-04a90e1b0202',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahcev8Cj3ylylTXzTOwT--tRhWa8q0v8ag.ttf',
    variant: '300italic',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'b26af209-9b1e-4bd3-92f8-7e93a5a296a3',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahccv8Cj3ylylTXzREIJd-lbgUS5u0s.ttf',
    variant: '300',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '43ace694-e480-4526-9227-6df853305b9d',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahcev8Cj3ylylTXzTOwTn-hRhWa8q0v8ag.ttf',
    variant: '200italic',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '32bf5629-f906-40d1-888a-2f97028114c4',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahccv8Cj3ylylTXzRCYKd-lbgUS5u0s.ttf',
    variant: '200',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a002ee12-7efe-4d3a-8967-49ec0bf44168',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahcdv8Cj3ylylTXzTOwTM8lxr0iwolLl.ttf',
    variant: '100italic',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '77ac218d-9e33-4a35-9ffe-15bfd17879c6',
    origin: GF_SOURCE,
    url: GF_URL + 'taviraj/v6/ahcbv8Cj3ylylTXzRIorV8N1jU2gog.ttf',
    variant: '100',
    family: 'Taviraj',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '358c45e8-ccd5-45cc-ab19-a6d2b22a05ce',
    origin: GF_SOURCE,
    url: GF_URL + 'pragatinarrow/v8/vm8sdRf0T0bS1ffgsPB7WZ-mD2ZD5fd_GJMTlo_4.ttf',
    variant: '700',
    family: 'Pragati Narrow',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ef32dc73-67da-4202-8f3c-d654384e623c',
    origin: GF_SOURCE,
    url: GF_URL + 'pragatinarrow/v8/vm8vdRf0T0bS1ffgsPB7WZ-mD17_ytN3M48a.ttf',
    variant: 'regular',
    family: 'Pragati Narrow',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '60db5748-a47e-44f7-b8ab-25edb66749fb',
    origin: GF_SOURCE,
    url:
      GF_URL + 'crimsonpro/v14/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi49fJs7dtC4yZNE.ttf',
    variant: '900italic',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '9621566c-4401-473a-aefd-bbde6342e5ce',
    origin: GF_SOURCE,
    url:
      GF_URL + 'crimsonpro/v14/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi4UfJs7dtC4yZNE.ttf',
    variant: '800italic',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '7e32f4ac-240a-46bb-9908-407f86e89e3b',
    origin: GF_SOURCE,
    url:
      GF_URL + 'crimsonpro/v14/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi5zfJs7dtC4yZNE.ttf',
    variant: '700italic',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'ad4cb951-9eef-4113-bd4a-4405990d699c',
    origin: GF_SOURCE,
    url:
      GF_URL + 'crimsonpro/v14/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi5KfJs7dtC4yZNE.ttf',
    variant: '600italic',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '159c4b5c-90cc-4fc1-ad25-95c7637ce5df',
    origin: GF_SOURCE,
    url:
      GF_URL + 'crimsonpro/v14/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi6me5s7dtC4yZNE.ttf',
    variant: '500italic',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '25ce1e5e-05a8-49f4-9ac7-48fefd25ed29',
    origin: GF_SOURCE,
    url:
      GF_URL + 'crimsonpro/v14/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi6Ue5s7dtC4yZNE.ttf',
    variant: 'italic',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '94dd8585-01a7-4f19-bbbe-a4e776ec7905',
    origin: GF_SOURCE,
    url:
      GF_URL + 'crimsonpro/v14/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi7Ke5s7dtC4yZNE.ttf',
    variant: '300italic',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '109ebbf3-8782-4cee-baf1-a8d5f310f952',
    origin: GF_SOURCE,
    url:
      GF_URL + 'crimsonpro/v14/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi4Ue5s7dtC4yZNE.ttf',
    variant: '200italic',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '1da78c9a-d11b-42c2-8551-b9e517dcbc55',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsonpro/v14/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZZ2p8OJE_VNWoyQ.ttf',
    variant: '900',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '26dae9d2-33d3-46f4-98aa-143fdc11ff8b',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsonpro/v14/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZTmp8OJE_VNWoyQ.ttf',
    variant: '800',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '7583b9a1-a290-4ba6-a93d-b0ac5b85e4ae',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsonpro/v14/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZKWp8OJE_VNWoyQ.ttf',
    variant: '700',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '668d64c7-3685-4433-b80b-cfa6c0bbf086',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsonpro/v14/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZEGp8OJE_VNWoyQ.ttf',
    variant: '600',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '2219c12b-03af-46a6-8b05-f8431ce8cc6c',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsonpro/v14/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZ_G18OJE_VNWoyQ.ttf',
    variant: '500',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '31187b10-e762-45f2-91fe-7a08699b405c',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsonpro/v14/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZzm18OJE_VNWoyQ.ttf',
    variant: 'regular',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '07ba0e2c-9e69-4d56-a4da-296909b64647',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsonpro/v14/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZkG18OJE_VNWoyQ.ttf',
    variant: '300',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '113102bb-394b-4e6c-930a-f15b32164e39',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsonpro/v14/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZTm18OJE_VNWoyQ.ttf',
    variant: '200',
    family: 'Crimson Pro',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '4407db0d-243d-45c1-9a9b-e1826189364b',
    origin: GF_SOURCE,
    url: GF_URL + 'khand/v9/TwMN-IINQlQQ0bLpd1E3ZwaH__-C.ttf',
    variant: '700',
    family: 'Khand',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '32ab2f74-3aca-4897-9271-e56ae933ffc1',
    origin: GF_SOURCE,
    url: GF_URL + 'khand/v9/TwMN-IINQlQQ0bKNdlE3ZwaH__-C.ttf',
    variant: '600',
    family: 'Khand',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '8d452d98-1ed1-48a0-ac7d-70e522f93899',
    origin: GF_SOURCE,
    url: GF_URL + 'khand/v9/TwMN-IINQlQQ0bKhcVE3ZwaH__-C.ttf',
    variant: '500',
    family: 'Khand',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '3810e372-04fd-45ec-8f44-cc3325199c23',
    origin: GF_SOURCE,
    url: GF_URL + 'khand/v9/TwMA-IINQlQQ0YpVWHU_TBqO.ttf',
    variant: 'regular',
    family: 'Khand',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'eaa5da7c-a0e5-40d3-b33d-be87eddf7ed8',
    origin: GF_SOURCE,
    url: GF_URL + 'khand/v9/TwMN-IINQlQQ0bL5cFE3ZwaH__-C.ttf',
    variant: '300',
    family: 'Khand',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '850f8390-b000-4f7a-b1dc-0e57c4d3299a',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntumono/v10/KFO8CneDtsqEr0keqCMhbCc_Mn33tYhkf3O1GVg.ttf',
    variant: '700italic',
    family: 'Ubuntu Mono',
    category: 'monospace',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '211f12a8-2e01-4a8e-a62f-27d531b97c30',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntumono/v10/KFO-CneDtsqEr0keqCMhbC-BL-Hyv4xGemO1.ttf',
    variant: '700',
    family: 'Ubuntu Mono',
    category: 'monospace',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '56d5448a-851f-4654-823b-5eaa56383210',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntumono/v10/KFOhCneDtsqEr0keqCMhbCc_CsHYkYBPY3o.ttf',
    variant: 'italic',
    family: 'Ubuntu Mono',
    category: 'monospace',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '52c4a2b4-54f4-462b-8795-9cae58694a1d',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntumono/v10/KFOjCneDtsqEr0keqCMhbBc9AMX6lJBP.ttf',
    variant: 'regular',
    family: 'Ubuntu Mono',
    category: 'monospace',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '7ca6d7b7-5a10-4e08-af1d-40ce14a569d6',
    origin: GF_SOURCE,
    url: GF_URL + 'faustina/v8/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsIgXV-SWc5LEnoF.ttf',
    variant: '700italic',
    family: 'Faustina',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '0812b3fb-ad4d-497a-b34d-b75f9adf8db7',
    origin: GF_SOURCE,
    url: GF_URL + 'faustina/v8/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsIZXV-SWc5LEnoF.ttf',
    variant: '600italic',
    family: 'Faustina',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '6d3564cf-4c15-456d-8d37-98646a813918',
    origin: GF_SOURCE,
    url: GF_URL + 'faustina/v8/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsL1Wl-SWc5LEnoF.ttf',
    variant: '500italic',
    family: 'Faustina',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c68846d4-2b1f-4bdc-b162-1b7e8fdc6643',
    origin: GF_SOURCE,
    url: GF_URL + 'faustina/v8/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsLHWl-SWc5LEnoF.ttf',
    variant: 'italic',
    family: 'Faustina',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '682bc6be-1a93-4429-81d0-d38403385e18',
    origin: GF_SOURCE,
    url: GF_URL + 'faustina/v8/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsZYYvGVWWe8tbEg.ttf',
    variant: '700',
    family: 'Faustina',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c578e4bd-cba9-4632-991b-edf56dffcd57',
    origin: GF_SOURCE,
    url: GF_URL + 'faustina/v8/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsXIYvGVWWe8tbEg.ttf',
    variant: '600',
    family: 'Faustina',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f1fba5d4-4e37-4e10-ad15-96992ec7b0c8',
    origin: GF_SOURCE,
    url: GF_URL + 'faustina/v8/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlssIEvGVWWe8tbEg.ttf',
    variant: '500',
    family: 'Faustina',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '4d7070b7-0250-44c9-9cc8-04d56d7d4abc',
    origin: GF_SOURCE,
    url: GF_URL + 'faustina/v8/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsgoEvGVWWe8tbEg.ttf',
    variant: 'regular',
    family: 'Faustina',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'bbccfde4-264d-47cf-8781-96530211461f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'saira/v7/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKB44wxkyQjQVYmxA.ttf',
    variant: '900italic',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'fe75567f-e5e8-4736-be97-0c12540e2854',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'saira/v7/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKByowxkyQjQVYmxA.ttf',
    variant: '800italic',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '0dd870e4-813a-4bd9-8c9b-de5cbfe7720d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'saira/v7/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBrYwxkyQjQVYmxA.ttf',
    variant: '700italic',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'cbba48af-8af2-4fdd-8fd3-149f2b29420f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'saira/v7/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBlIwxkyQjQVYmxA.ttf',
    variant: '600italic',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'a26c00cc-25a4-4dd5-990d-115e6a6db1c1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'saira/v7/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBeIsxkyQjQVYmxA.ttf',
    variant: '500italic',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '4de32119-28c9-428e-a10b-6dee97cd81f4',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'saira/v7/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBSosxkyQjQVYmxA.ttf',
    variant: 'italic',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'de27b55f-c71e-4145-b3e1-bae3644332ea',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'saira/v7/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBFIsxkyQjQVYmxA.ttf',
    variant: '300italic',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'a0e18341-2200-4e06-ad8b-13e508c2b5c0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'saira/v7/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKByosxkyQjQVYmxA.ttf',
    variant: '200italic',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '58c02bb6-30ac-4575-a23f-67a90a6c0930',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'saira/v7/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBSooxkyQjQVYmxA.ttf',
    variant: '100italic',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'cb0b8572-4117-4d9c-8f40-c2183f4db8d4',
    origin: GF_SOURCE,
    url:
      GF_URL + 'saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA7_PFosg7lwYmUVY.ttf',
    variant: '900',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '975bbed6-b6a7-4558-8e17-7d0f1479c462',
    origin: GF_SOURCE,
    url:
      GF_URL + 'saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA79rFosg7lwYmUVY.ttf',
    variant: '800',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'e1d7f62e-0a96-47a4-8de7-a3626eb891b8',
    origin: GF_SOURCE,
    url:
      GF_URL + 'saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA773Fosg7lwYmUVY.ttf',
    variant: '700',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'fa1f94f1-a609-48b5-923e-25d2aad3cc88',
    origin: GF_SOURCE,
    url:
      GF_URL + 'saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA74TFosg7lwYmUVY.ttf',
    variant: '600',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '8603a778-a2e2-4f19-b745-e0ec21ffe7f9',
    origin: GF_SOURCE,
    url:
      GF_URL + 'saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA72jCosg7lwYmUVY.ttf',
    variant: '500',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd0539973-ed38-49f0-b3de-449499e97ee1',
    origin: GF_SOURCE,
    url:
      GF_URL + 'saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rCosg7lwYmUVY.ttf',
    variant: 'regular',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '41ba8ae2-526d-4678-a40d-c4e55d47fd19',
    origin: GF_SOURCE,
    url:
      GF_URL + 'saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA7wTCosg7lwYmUVY.ttf',
    variant: '300',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'f7b6f4d0-a68f-46dc-ae4b-14eb931d6e34',
    origin: GF_SOURCE,
    url:
      GF_URL + 'saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA79rCosg7lwYmUVY.ttf',
    variant: '200',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'ae1ee7ee-e452-4956-a710-24819f005f71',
    origin: GF_SOURCE,
    url:
      GF_URL + 'saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rDosg7lwYmUVY.ttf',
    variant: '100',
    family: 'Saira',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '290f1ab0-d430-49d5-8be5-a2918332e9f9',
    origin: GF_SOURCE,
    url: GF_URL + 'handlee/v9/-F6xfjBsISg9aMakDmr6oilJ3ik.ttf',
    variant: 'regular',
    family: 'Handlee',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '1185be18-49a6-4d64-84af-19b627b5b9f6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWO3i-pGR7e2SvJQ.ttf',
    variant: '900italic',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a4efe384-6ce3-4821-afa3-3f5b37dac70e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3XyJn3-0oEZ-a2Q.ttf',
    variant: '900',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '071a5e64-39ae-4469-956c-b4b539d0de7f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWH3m-pGR7e2SvJQ.ttf',
    variant: '800italic',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '03633914-7e24-4c73-9809-625eced70ba6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3ViIn3-0oEZ-a2Q.ttf',
    variant: '800',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2e012656-b8a5-4011-8019-61503e658a77',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWA3q-pGR7e2SvJQ.ttf',
    variant: '700italic',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ea9b855f-d1cb-4cf8-b271-6df9edf9af60',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3USLn3-0oEZ-a2Q.ttf',
    variant: '700',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2365fcda-40e3-41d8-aba6-c2197a4eb807',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWZ3u-pGR7e2SvJQ.ttf',
    variant: '600italic',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '9bb32fb2-0054-4915-b339-461f702086e4',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3SCKn3-0oEZ-a2Q.ttf',
    variant: '600',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ed069dc2-bb0c-4a6b-b7a3-be53b787175d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWS3y-pGR7e2SvJQ.ttf',
    variant: '500italic',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '18829d50-3b01-445b-a0c8-c6ac966ac152',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3QyNn3-0oEZ-a2Q.ttf',
    variant: '500',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '72c6e552-e0a8-48ed-8de2-ccd904afcdfe',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fquv1WarE9ncg.ttf',
    variant: 'italic',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '054091c7-275d-4cf1-9d34-c261f91c6f40',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPKcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda5fiku3efvE8.ttf',
    variant: 'regular',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '49aae92f-60af-401b-af3e-9ce6a4bff96c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWE32-pGR7e2SvJQ.ttf',
    variant: '300italic',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '243222d3-4618-4812-968e-8a1fba79d448',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3VSMn3-0oEZ-a2Q.ttf',
    variant: '300',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a913a4bf-4ddd-461c-b200-2f6fbbb8ddf7',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWd36-pGR7e2SvJQ.ttf',
    variant: '200italic',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'beac62e1-9b87-4c9c-abc4-7acd5a2f4234',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3TCPn3-0oEZ-a2Q.ttf',
    variant: '200',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e609bc0a-17d4-4975-ab25-25eb6017417c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPOcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqW21-ejkp3cn22.ttf',
    variant: '100italic',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f92d7428-56c4-4f9d-8638-df5a7c62b5a6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasansextracondensed/v5/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3Zyuv1WarE9ncg.ttf',
    variant: '100',
    family: 'Fira Sans Extra Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '678221cc-222d-4614-be97-bc912b73ac33',
    origin: GF_SOURCE,
    url: GF_URL + 'neuton/v13/UMBQrPtMoH62xUZKaK4fegD5Drog6Q.ttf',
    variant: '800',
    family: 'Neuton',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'bd36722b-bb42-4818-b80e-fa688cf407c3',
    origin: GF_SOURCE,
    url: GF_URL + 'neuton/v13/UMBQrPtMoH62xUZKdK0fegD5Drog6Q.ttf',
    variant: '700',
    family: 'Neuton',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'c32861ff-11dc-485b-92dd-f16b38d322cf',
    origin: GF_SOURCE,
    url: GF_URL + 'neuton/v13/UMBRrPtMoH62xUZCyog_UC71B6M5.ttf',
    variant: 'italic',
    family: 'Neuton',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '7766c531-b6c8-4def-bb28-8593b43cbfa1',
    origin: GF_SOURCE,
    url: GF_URL + 'neuton/v13/UMBTrPtMoH62xUZyyII7civlBw.ttf',
    variant: 'regular',
    family: 'Neuton',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '86a2d213-7392-44fd-849d-6e7e80b529cb',
    origin: GF_SOURCE,
    url: GF_URL + 'neuton/v13/UMBQrPtMoH62xUZKZKofegD5Drog6Q.ttf',
    variant: '300',
    family: 'Neuton',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '7618b269-9473-4a70-b5b4-f19533b7297e',
    origin: GF_SOURCE,
    url: GF_URL + 'neuton/v13/UMBQrPtMoH62xUZKAKkfegD5Drog6Q.ttf',
    variant: '200',
    family: 'Neuton',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '19aaef8a-1ee3-4c6e-88ef-2a7e43eedd0e',
    origin: GF_SOURCE,
    url: GF_URL + 'padauk/v8/RrQSboJg-id7Onb512DE1JJEZ4YwGg.ttf',
    variant: '700',
    family: 'Padauk',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e8193214-2770-410d-a9cc-c522a33c3ced',
    origin: GF_SOURCE,
    url: GF_URL + 'padauk/v8/RrQRboJg-id7OnbBa0_g3LlYbg.ttf',
    variant: 'regular',
    family: 'Padauk',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '8f91ff25-a40c-4e49-8eff-550862792df0',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCu-xNNww_2s0amA9M8qt3AafOPXHIJErY.ttf',
    variant: '800italic',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'b53df270-0b07-43fb-8887-e3a9cb709572',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCs-xNNww_2s0amA9uetF3GY_etWWIJ.ttf',
    variant: '800',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'c90291b2-6fe2-4146-86dd-06a0f78c3239',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCu-xNNww_2s0amA9M8qsHDafOPXHIJErY.ttf',
    variant: '700italic',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '8b5bf92d-e491-4569-9642-ccd7df02a3ee',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCs-xNNww_2s0amA9uCt13GY_etWWIJ.ttf',
    variant: '700',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '1e8a7a56-1515-40d3-97c4-7b5f54521803',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCu-xNNww_2s0amA9M8qqXCafOPXHIJErY.ttf',
    variant: '600italic',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '3d2f25a3-747d-440b-8c2e-5a5252f56d4b',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCs-xNNww_2s0amA9vmtl3GY_etWWIJ.ttf',
    variant: '600',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '490e966d-d123-4643-b88e-3db33a7af164',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCu-xNNww_2s0amA9M8qonFafOPXHIJErY.ttf',
    variant: '500italic',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '02cbc72b-9302-460a-9147-541b3fc10c85',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCs-xNNww_2s0amA9vKsV3GY_etWWIJ.ttf',
    variant: '500',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '8431ce36-6a61-459b-84a5-623436b3ffe1',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCt-xNNww_2s0amA9M8kn3sTfukQHs.ttf',
    variant: 'italic',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'f354ef3c-5e97-47ba-9b4a-26f93497b7d1',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCr-xNNww_2s0amA-M-mHnOSOuk.ttf',
    variant: 'regular',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '3202ac88-b9c8-43c3-b3c5-3f2d0d24c479',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCu-xNNww_2s0amA9M8qtHEafOPXHIJErY.ttf',
    variant: '300italic',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd36ad505-89e1-459f-a3a1-5c7953ccfe8d',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCs-xNNww_2s0amA9uSsF3GY_etWWIJ.ttf',
    variant: '300',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'ef4e1d31-0c21-44cd-acad-1595f325a2c7',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCu-xNNww_2s0amA9M8qrXHafOPXHIJErY.ttf',
    variant: '200italic',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '7eea7c9a-5449-4d58-ac58-0870fd429eaf',
    origin: GF_SOURCE,
    url: GF_URL + 'spectral/v7/rnCs-xNNww_2s0amA9v2s13GY_etWWIJ.ttf',
    variant: '200',
    family: 'Spectral',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'b35ba3bd-b83d-4b67-acc9-a2f6477264bd',
    origin: GF_SOURCE,
    url: GF_URL + 'adventpro/v11/V8mDoQfxVT4Dvddr_yOwjYGIDbZyCts0DqQ.ttf',
    variant: '700',
    family: 'Advent Pro',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c837742d-b665-4197-b30c-b6cd69299eff',
    origin: GF_SOURCE,
    url: GF_URL + 'adventpro/v11/V8mDoQfxVT4Dvddr_yOwjeWJDbZyCts0DqQ.ttf',
    variant: '600',
    family: 'Advent Pro',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'd9ea62ab-3d6c-4501-9feb-e625f347e5f3',
    origin: GF_SOURCE,
    url: GF_URL + 'adventpro/v11/V8mDoQfxVT4Dvddr_yOwjcmODbZyCts0DqQ.ttf',
    variant: '500',
    family: 'Advent Pro',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '89545544-2844-4055-b867-dd188e0243d9',
    origin: GF_SOURCE,
    url: GF_URL + 'adventpro/v11/V8mAoQfxVT4Dvddr_yOwtT2nKb5ZFtI.ttf',
    variant: 'regular',
    family: 'Advent Pro',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8bb3024a-4ed5-4db9-965d-11021b6d5e91',
    origin: GF_SOURCE,
    url: GF_URL + 'adventpro/v11/V8mDoQfxVT4Dvddr_yOwjZGPDbZyCts0DqQ.ttf',
    variant: '300',
    family: 'Advent Pro',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b8a73d4a-fd08-4758-acb1-84b1ca36990f',
    origin: GF_SOURCE,
    url: GF_URL + 'adventpro/v11/V8mDoQfxVT4Dvddr_yOwjfWMDbZyCts0DqQ.ttf',
    variant: '200',
    family: 'Advent Pro',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '4d0fdbfd-56e3-4ead-a8b1-6e3f7bc952df',
    origin: GF_SOURCE,
    url: GF_URL + 'adventpro/v11/V8mCoQfxVT4Dvddr_yOwjVmtLZxcBtItFw.ttf',
    variant: '100',
    family: 'Advent Pro',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '48d45691-00b6-4fce-b1cd-70a20856d92d',
    origin: GF_SOURCE,
    url: GF_URL + 'dmserifdisplay/v5/-nFhOHM81r4j6k0gjAW3mujVU2B2G_Vx1_r352np3Q.ttf',
    variant: 'italic',
    family: 'DM Serif Display',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '32ed2271-a90c-47fe-a41a-f2135b3f8868',
    origin: GF_SOURCE,
    url: GF_URL + 'dmserifdisplay/v5/-nFnOHM81r4j6k0gjAW3mujVU2B2K_d709jy92k.ttf',
    variant: 'regular',
    family: 'DM Serif Display',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6ff88b90-68be-4d26-8135-60b9a9b8e666',
    origin: GF_SOURCE,
    url: GF_URL + 'philosopher/v14/vEFK2_5QCwIS4_Dhez5jcWBrd_QZ8tK1W77HtMo.ttf',
    variant: '700italic',
    family: 'Philosopher',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'b9f0872e-95cf-46df-a150-731a2b6a9a0c',
    origin: GF_SOURCE,
    url: GF_URL + 'philosopher/v14/vEFI2_5QCwIS4_Dhez5jcWjVamgc-NaXXq7H.ttf',
    variant: '700',
    family: 'Philosopher',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'bedc9e52-3cde-4685-8b05-cc46d37d91b5',
    origin: GF_SOURCE,
    url: GF_URL + 'philosopher/v14/vEFX2_5QCwIS4_Dhez5jcWBrT0g21tqeR7c.ttf',
    variant: 'italic',
    family: 'Philosopher',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '2c74aee7-703c-4e91-9983-edaac9888156',
    origin: GF_SOURCE,
    url: GF_URL + 'philosopher/v14/vEFV2_5QCwIS4_Dhez5jcVBpRUwU08qe.ttf',
    variant: 'regular',
    family: 'Philosopher',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '7b1a442b-0e72-4c6a-b781-001da206f0e4',
    origin: GF_SOURCE,
    url: GF_URL + 'sigmarone/v11/co3DmWZ8kjZuErj9Ta3dk6Pjp3Di8U0.ttf',
    variant: 'regular',
    family: 'Sigmar One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '2663937d-0aa0-4665-b4c3-cee3d78d7e79',
    origin: GF_SOURCE,
    url: GF_URL + 'sanchez/v8/Ycm0sZJORluHnXbIfmxR-D4Bxl3gkw.ttf',
    variant: 'italic',
    family: 'Sanchez',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'dbae2c71-1846-4a09-82e6-5ed663062c63',
    origin: GF_SOURCE,
    url: GF_URL + 'sanchez/v8/Ycm2sZJORluHnXbITm5b_BwE1l0.ttf',
    variant: 'regular',
    family: 'Sanchez',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c9d2374e-56ae-4012-9132-78fd53a23c5e',
    origin: GF_SOURCE,
    url: GF_URL + 'istokweb/v15/3qT0ojGmgSyUukBzKslpA1PG-2MQQhLMMygN.ttf',
    variant: '700italic',
    family: 'Istok Web',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'b262a037-bb95-4de0-a6d2-8eb9431a9968',
    origin: GF_SOURCE,
    url: GF_URL + 'istokweb/v15/3qTqojGmgSyUukBzKslhvU5a_mkUYBfcMw.ttf',
    variant: '700',
    family: 'Istok Web',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'd7d2715d-b394-41b0-bd77-a1ba9006deeb',
    origin: GF_SOURCE,
    url: GF_URL + 'istokweb/v15/3qTpojGmgSyUukBzKslpA2t61EcYaQ7F.ttf',
    variant: 'italic',
    family: 'Istok Web',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '2facc51d-89ea-4bd0-bad2-4817cd6570da',
    origin: GF_SOURCE,
    url: GF_URL + 'istokweb/v15/3qTvojGmgSyUukBzKslZAWF-9kIIaQ.ttf',
    variant: 'regular',
    family: 'Istok Web',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'e458bfc3-3b8d-4dbc-8a20-f4150fd68581',
    origin: GF_SOURCE,
    url: GF_URL + 'vidaloka/v13/7cHrv4c3ipenMKlEass8yn4hnCci.ttf',
    variant: 'regular',
    family: 'Vidaloka',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'faa7fbde-520a-48bc-9c23-cee3cc58bf60',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorant/v11/H4c-BXOCl9bbnla_nHIq6qMEh4a2QTRjF8ER.ttf',
    variant: '700italic',
    family: 'Cormorant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c8b63bc9-ff96-4b76-b5eb-68a31beb709f',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorant/v11/H4cgBXOCl9bbnla_nHIiVL6YgoyyYzFzFw.ttf',
    variant: '700',
    family: 'Cormorant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'e370dd4e-2efb-4aea-9b34-9cb0b264a484',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorant/v11/H4c-BXOCl9bbnla_nHIq6qNghoa2QTRjF8ER.ttf',
    variant: '600italic',
    family: 'Cormorant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0c30b946-dd5f-4b51-9793-cc3b9a0a06ab',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorant/v11/H4cgBXOCl9bbnla_nHIiML-YgoyyYzFzFw.ttf',
    variant: '600',
    family: 'Cormorant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '6ce6d760-40de-4602-9c6b-dfe7c37e94ab',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorant/v11/H4c-BXOCl9bbnla_nHIq6qNMgYa2QTRjF8ER.ttf',
    variant: '500italic',
    family: 'Cormorant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8d167660-613d-494f-b61e-0b6ad382ed10',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorant/v11/H4cgBXOCl9bbnla_nHIiHLiYgoyyYzFzFw.ttf',
    variant: '500',
    family: 'Cormorant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '1edc096f-cf06-45ec-9de2-d29dacab670d',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorant/v11/H4cjBXOCl9bbnla_nHIq6pu4qKK-aihq.ttf',
    variant: 'italic',
    family: 'Cormorant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0b2697c7-d795-4f82-9538-2d51c1be648f',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorant/v11/H4clBXOCl9bbnla_nHIa6JG8iqeuag.ttf',
    variant: 'regular',
    family: 'Cormorant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7673442d-44d2-4e97-aeb8-b96c367aeb16',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorant/v11/H4c-BXOCl9bbnla_nHIq6qMUgIa2QTRjF8ER.ttf',
    variant: '300italic',
    family: 'Cormorant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'caafc295-3507-4889-a5dd-bfa109d0d959',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorant/v11/H4cgBXOCl9bbnla_nHIiRLmYgoyyYzFzFw.ttf',
    variant: '300',
    family: 'Cormorant',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a6ca15a7-a386-4d32-9ff0-a5cf66e0b709',
    origin: GF_SOURCE,
    url: GF_URL + 'specialelite/v11/XLYgIZbkc4JPUL5CVArUVL0nhncESXFtUsM.ttf',
    variant: 'regular',
    family: 'Special Elite',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f7f8da31-9f2b-4295-a8c6-43030c406c9e',
    origin: GF_SOURCE,
    url: GF_URL + 'volkhov/v12/SlGXmQieoJcKemNecTA8PHFSaBYRagrQrA.ttf',
    variant: '700italic',
    family: 'Volkhov',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '50d8ecce-3d3a-416d-952c-26a7d78da319',
    origin: GF_SOURCE,
    url: GF_URL + 'volkhov/v12/SlGVmQieoJcKemNeeY4hoHRYbDQUego.ttf',
    variant: '700',
    family: 'Volkhov',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '29f1fbb6-8009-4bc4-90ad-f0f1c6c46426',
    origin: GF_SOURCE,
    url: GF_URL + 'volkhov/v12/SlGSmQieoJcKemNecTAEgF52YD0NYw.ttf',
    variant: 'italic',
    family: 'Volkhov',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'acd83799-3232-4425-bff5-0b4e2c383dbb',
    origin: GF_SOURCE,
    url: GF_URL + 'volkhov/v12/SlGQmQieoJcKemNeQTIOhHxzcD0.ttf',
    variant: 'regular',
    family: 'Volkhov',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c60efcf2-8f60-4fa2-a78a-397707328176',
    origin: GF_SOURCE,
    url: GF_URL + 'sawarabimincho/v10/8QIRdiDaitzr7brc8ahpxt6GcIJTLahP46UDUw.ttf',
    variant: 'regular',
    family: 'Sawarabi Mincho',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '1c94fe7b-ec3a-4e2e-b507-a9e808968188',
    origin: GF_SOURCE,
    url: GF_URL + 'quattrocento/v12/OZpbg_xvsDZQL_LKIF7q4jP_eE3fd6PZsXcM9w.ttf',
    variant: '700',
    family: 'Quattrocento',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '9871ec46-7a59-407c-884b-fb6014ca9532',
    origin: GF_SOURCE,
    url: GF_URL + 'quattrocento/v12/OZpEg_xvsDZQL_LKIF7q4jPHxGL7f4jFuA.ttf',
    variant: 'regular',
    family: 'Quattrocento',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '55ac844b-c5b4-4c58-a391-69d21e79e40e',
    origin: GF_SOURCE,
    url: GF_URL + 'poiretone/v9/UqyVK80NJXN4zfRgbdfbk5lWVscxdKE.ttf',
    variant: 'regular',
    family: 'Poiret One',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '3f975a73-a22d-4b3e-b63b-c2649914ba5f',
    origin: GF_SOURCE,
    url: GF_URL + 'cookie/v12/syky-y18lb0tSbfNlQCT9tPdpw.ttf',
    variant: 'regular',
    family: 'Cookie',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'e2300be0-1236-4d32-ad54-35575b8a21ec',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinslab/v13/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHveD09L4KZAyK43w.ttf',
    variant: '700italic',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '2cc5ed8f-6020-4260-9317-27b90fd4a532',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinslab/v13/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvQT09L4KZAyK43w.ttf',
    variant: '600italic',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '556fac68-5b16-40f3-8e70-393b2e9ceba3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinslab/v13/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvrTo9L4KZAyK43w.ttf',
    variant: '500italic',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '501ccdab-167c-4b55-bbb4-e460e2f69c5a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinslab/v13/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvnzo9L4KZAyK43w.ttf',
    variant: 'italic',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'dde2ecce-c270-45e8-9a74-7081e27fed5a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinslab/v13/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvwTo9L4KZAyK43w.ttf',
    variant: '300italic',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '3bcdc2f6-4a70-4b30-b455-eb00ef876f76',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinslab/v13/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvHzo9L4KZAyK43w.ttf',
    variant: '200italic',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '782e95cd-33a4-4cc9-87cf-b7b216ba6959',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinslab/v13/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvnzs9L4KZAyK43w.ttf',
    variant: '100italic',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '59e5976f-77ae-4f67-9574-4863ee6350fc',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinslab/v13/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W776rd3k3K6CcEyI.ttf',
    variant: '700',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'a075866d-1a90-4e62-8bfa-32aeeb9a8304',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinslab/v13/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W74erd3k3K6CcEyI.ttf',
    variant: '600',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '54c31ffe-b8e5-4c83-add0-77ca6a100a46',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinslab/v13/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W72usd3k3K6CcEyI.ttf',
    variant: '500',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'defa5fb0-ad62-4c8c-a62e-07a7e9d514da',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinslab/v13/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71msd3k3K6CcEyI.ttf',
    variant: 'regular',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '36dfcded-a97f-4bf2-b499-1af243952da3',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinslab/v13/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W7wesd3k3K6CcEyI.ttf',
    variant: '300',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'ccd96823-0c5c-4549-b49c-ad4043a06207',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinslab/v13/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W79msd3k3K6CcEyI.ttf',
    variant: '200',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '6081e95f-6e99-47cc-98d4-b4588cb2fdce',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinslab/v13/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71mtd3k3K6CcEyI.ttf',
    variant: '100',
    family: 'Josefin Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '3922dc1b-d4d8-462e-bdc1-f6c5fa2f8be6',
    origin: GF_SOURCE,
    url: GF_URL + 'ropasans/v10/EYq3maNOzLlWtsZSScy6WDNscZef2mNE.ttf',
    variant: 'italic',
    family: 'Ropa Sans',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '42c1ba9e-22c1-4810-a9bf-8cd128b55396',
    origin: GF_SOURCE,
    url: GF_URL + 'ropasans/v10/EYqxmaNOzLlWtsZSScyKWjloU5KP2g.ttf',
    variant: 'regular',
    family: 'Ropa Sans',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'c329c687-d5a0-4867-b412-3437d94d22fa',
    origin: GF_SOURCE,
    url: GF_URL + 'parisienne/v8/E21i_d3kivvAkxhLEVZpcy96DuKuavM.ttf',
    variant: 'regular',
    family: 'Parisienne',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '9d499954-58e0-4cd2-bbde-6c2b20b98b22',
    origin: GF_SOURCE,
    url: GF_URL + 'arapey/v9/-W_9XJn-UDDA2RCKZdoYREcjeo0k.ttf',
    variant: 'italic',
    family: 'Arapey',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '80e6e676-7437-4ccc-a4d0-8ee5d61799ea',
    origin: GF_SOURCE,
    url: GF_URL + 'arapey/v9/-W__XJn-UDDA2RC6Z9AcZkIzeg.ttf',
    variant: 'regular',
    family: 'Arapey',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '827dded3-4d4e-4ee1-97c5-4bfba7f720b8',
    origin: GF_SOURCE,
    url:
      GF_URL + 'publicsans/v5/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tr4hwctfVotfj7j.ttf',
    variant: '900italic',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'cc01e0c3-d355-49a1-ac19-b1c798f77639',
    origin: GF_SOURCE,
    url:
      GF_URL + 'publicsans/v5/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673trRhwctfVotfj7j.ttf',
    variant: '800italic',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ab5334a3-f2f4-47c7-b3d6-e2d4670957d0',
    origin: GF_SOURCE,
    url:
      GF_URL + 'publicsans/v5/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tq2hwctfVotfj7j.ttf',
    variant: '700italic',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '84d652e3-bf1e-4124-8dc1-355b8c79f51d',
    origin: GF_SOURCE,
    url:
      GF_URL + 'publicsans/v5/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tqPhwctfVotfj7j.ttf',
    variant: '600italic',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '5756170d-0ab3-428d-98ac-5dbf2691ab65',
    origin: GF_SOURCE,
    url:
      GF_URL + 'publicsans/v5/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpjgActfVotfj7j.ttf',
    variant: '500italic',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '99360da7-3ab3-4d2c-9cef-8f47cd486a18',
    origin: GF_SOURCE,
    url:
      GF_URL + 'publicsans/v5/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgActfVotfj7j.ttf',
    variant: 'italic',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6a6cdf51-ba6e-4b64-8f00-324cc53e0ccf',
    origin: GF_SOURCE,
    url:
      GF_URL + 'publicsans/v5/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673toPgActfVotfj7j.ttf',
    variant: '300italic',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b398746b-6dc9-4037-80f1-3fd129815cbe',
    origin: GF_SOURCE,
    url:
      GF_URL + 'publicsans/v5/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673trRgActfVotfj7j.ttf',
    variant: '200italic',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '72725e79-385e-485c-bfc9-16de7f7cb862',
    origin: GF_SOURCE,
    url:
      GF_URL + 'publicsans/v5/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgQctfVotfj7j.ttf',
    variant: '100italic',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6b453ab2-24d5-48b2-b309-955b60a53010',
    origin: GF_SOURCE,
    url: GF_URL + 'publicsans/v5/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuv565ww0pX189fg.ttf',
    variant: '900',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '0e331675-cc7b-4cae-acf9-e891078df505',
    origin: GF_SOURCE,
    url: GF_URL + 'publicsans/v5/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymulp65ww0pX189fg.ttf',
    variant: '800',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '84901c0c-c2e9-4c7a-9430-e7684b740e78',
    origin: GF_SOURCE,
    url: GF_URL + 'publicsans/v5/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymu8Z65ww0pX189fg.ttf',
    variant: '700',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ed9f33c2-93ca-43fe-8457-6cd782cd86da',
    origin: GF_SOURCE,
    url: GF_URL + 'publicsans/v5/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuyJ65ww0pX189fg.ttf',
    variant: '600',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '67e7ae8b-83c7-480c-ac47-c6c3c1a63352',
    origin: GF_SOURCE,
    url: GF_URL + 'publicsans/v5/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuJJm5ww0pX189fg.ttf',
    variant: '500',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '689ee312-b016-40a5-bdfa-34690997a82d',
    origin: GF_SOURCE,
    url: GF_URL + 'publicsans/v5/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpm5ww0pX189fg.ttf',
    variant: 'regular',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3ae4a845-7ff9-4694-ae26-9b26cc098e60',
    origin: GF_SOURCE,
    url: GF_URL + 'publicsans/v5/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuSJm5ww0pX189fg.ttf',
    variant: '300',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '28166fa9-7316-41bd-9e04-366ca8462b3b',
    origin: GF_SOURCE,
    url: GF_URL + 'publicsans/v5/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymulpm5ww0pX189fg.ttf',
    variant: '200',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e1f9b937-fa65-4551-908a-550ad0916b3b',
    origin: GF_SOURCE,
    url: GF_URL + 'publicsans/v5/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpi5ww0pX189fg.ttf',
    variant: '100',
    family: 'Public Sans',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'c6545207-97aa-4389-8e9e-84b5aa0e956e',
    origin: GF_SOURCE,
    url: GF_URL + 'chakrapetch/v4/cIfnMapbsEk7TDLdtEz1BwkWmpLZRZ_A_gMk0izH.ttf',
    variant: '700italic',
    family: 'Chakra Petch',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'adcd1cf6-9384-4fa6-ba22-dbe0f4fb7d5e',
    origin: GF_SOURCE,
    url: GF_URL + 'chakrapetch/v4/cIflMapbsEk7TDLdtEz1BwkeJI9FQJXE3AY00g.ttf',
    variant: '700',
    family: 'Chakra Petch',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '7a118073-2185-4a8b-b98a-14759057cd61',
    origin: GF_SOURCE,
    url: GF_URL + 'chakrapetch/v4/cIfnMapbsEk7TDLdtEz1BwkWmpK9RJ_A_gMk0izH.ttf',
    variant: '600italic',
    family: 'Chakra Petch',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'f734bd5e-3fa4-4ec4-b133-12a0066b6365',
    origin: GF_SOURCE,
    url: GF_URL + 'chakrapetch/v4/cIflMapbsEk7TDLdtEz1BwkeQI5FQJXE3AY00g.ttf',
    variant: '600',
    family: 'Chakra Petch',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd20b108b-fe0a-4007-998f-cb16012d6e0b',
    origin: GF_SOURCE,
    url: GF_URL + 'chakrapetch/v4/cIfnMapbsEk7TDLdtEz1BwkWmpKRQ5_A_gMk0izH.ttf',
    variant: '500italic',
    family: 'Chakra Petch',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '922f4adf-9a39-4637-a298-e029aa9c6a92',
    origin: GF_SOURCE,
    url: GF_URL + 'chakrapetch/v4/cIflMapbsEk7TDLdtEz1BwkebIlFQJXE3AY00g.ttf',
    variant: '500',
    family: 'Chakra Petch',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '7e55b4e4-5a84-45ee-b869-11ee141ff3ad',
    origin: GF_SOURCE,
    url: GF_URL + 'chakrapetch/v4/cIfkMapbsEk7TDLdtEz1BwkWmqplarvI1R8t.ttf',
    variant: 'italic',
    family: 'Chakra Petch',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '10068d93-23c2-4761-b439-78d7677e4dad',
    origin: GF_SOURCE,
    url: GF_URL + 'chakrapetch/v4/cIf6MapbsEk7TDLdtEz1BwkmmKBhSL7Y1Q.ttf',
    variant: 'regular',
    family: 'Chakra Petch',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '472a1061-4e2c-418e-b696-e2daa06efdf0',
    origin: GF_SOURCE,
    url: GF_URL + 'chakrapetch/v4/cIfnMapbsEk7TDLdtEz1BwkWmpLJQp_A_gMk0izH.ttf',
    variant: '300italic',
    family: 'Chakra Petch',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '64440c4c-4bac-430f-9d1d-6f0f1dcf5063',
    origin: GF_SOURCE,
    url: GF_URL + 'chakrapetch/v4/cIflMapbsEk7TDLdtEz1BwkeNIhFQJXE3AY00g.ttf',
    variant: '300',
    family: 'Chakra Petch',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'e8194303-4362-4bdd-819f-116073f0c4d4',
    origin: GF_SOURCE,
    url: GF_URL + 'ultra/v13/zOLy4prXmrtY-tT6yLOD6NxF.ttf',
    variant: 'regular',
    family: 'Ultra',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '5089000f-e995-4879-a00e-560ba34b7718',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplaysc/v10/ke82OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbw0kcwXK6ARIyH5IA.ttf',
    variant: '900italic',
    family: 'Playfair Display SC',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '21969e0f-9491-45a7-983d-a12b84c80fa2',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplaysc/v10/ke80OhoaMkR6-hSn7kbHVoFf7ZfgMPr_nTorNcsdL4IUMyE.ttf',
    variant: '900',
    family: 'Playfair Display SC',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'de8cf4fd-c4cf-4467-a8e6-386c26e1b079',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplaysc/v10/ke82OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbw0qc4XK6ARIyH5IA.ttf',
    variant: '700italic',
    family: 'Playfair Display SC',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b8e3b4ff-97f6-4991-a1bc-94e52450270f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplaysc/v10/ke80OhoaMkR6-hSn7kbHVoFf7ZfgMPr_nQIpNcsdL4IUMyE.ttf',
    variant: '700',
    family: 'Playfair Display SC',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'cfb60b38-a0cd-4b53-bcb4-c6436426408f',
    origin: GF_SOURCE,
    url:
      GF_URL + 'playfairdisplaysc/v10/ke87OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbwMFeEzI4sNKg.ttf',
    variant: 'italic',
    family: 'Playfair Display SC',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6c8cb972-3f48-4ceb-9e09-50449c2ed97a',
    origin: GF_SOURCE,
    url: GF_URL + 'playfairdisplaysc/v10/ke85OhoaMkR6-hSn7kbHVoFf7ZfgMPr_pb4GEcM2M4s.ttf',
    variant: 'regular',
    family: 'Playfair Display SC',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '2a39f122-0e7d-4f8c-aab2-4083b5134426',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zJtBhPNqw73oHH7BbQp4-B6XlrZkQCNI0un_HLMEo.ttf',
    variant: '900italic',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '13811cd7-8121-48a9-b703-a0c39cadb7bb',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zJtBhPNqw73oHH7BbQp4-B6XlrZm0CNI0un_HLMEo.ttf',
    variant: '800italic',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'dc9aee10-8223-4ec4-a0c4-d9218abe5895',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zJtBhPNqw73oHH7BbQp4-B6XlrZgoCNI0un_HLMEo.ttf',
    variant: '700italic',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '2c9b59c0-8d15-4e62-b3f5-856b23a18929',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zJtBhPNqw73oHH7BbQp4-B6XlrZjMCNI0un_HLMEo.ttf',
    variant: '600italic',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c1d38be0-ecf6-48ca-bf4f-a0f035a03701',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zJtBhPNqw73oHH7BbQp4-B6XlrZt8FNI0un_HLMEo.ttf',
    variant: '500italic',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '90223a84-351d-47fa-8ae3-e3c59590e747',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zJtBhPNqw73oHH7BbQp4-B6XlrZu0FNI0un_HLMEo.ttf',
    variant: 'italic',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '56e69d6e-dade-4c36-94a5-af8db3cb710a',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zJtBhPNqw73oHH7BbQp4-B6XlrZrMFNI0un_HLMEo.ttf',
    variant: '300italic',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd7e2294e-59c5-4740-b9ab-a7ed83e72abe',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zJtBhPNqw73oHH7BbQp4-B6XlrZm0FNI0un_HLMEo.ttf',
    variant: '200italic',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '7c1b615e-f42d-4566-b5fb-6dc885df2198',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zJtBhPNqw73oHH7BbQp4-B6XlrZu0ENI0un_HLMEo.ttf',
    variant: '100italic',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a334123d-9bca-4a22-9956-aecbe4b414d1',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zPtBhPNqw79Ij1E865zBUv7mwKIgVGPokMmuHL.ttf',
    variant: '900',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '9604dd7d-6e40-426d-9c7a-5127491f4413',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zPtBhPNqw79Ij1E865zBUv7mwjIgVGPokMmuHL.ttf',
    variant: '800',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '51cca455-69bf-46f6-b651-1c9d4bec49ca',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zPtBhPNqw79Ij1E865zBUv7mxEIgVGPokMmuHL.ttf',
    variant: '700',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '88205af3-7647-40b9-ada7-c47cfd0f3876',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zPtBhPNqw79Ij1E865zBUv7mx9IgVGPokMmuHL.ttf',
    variant: '600',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c0edc37e-9fd2-419f-862c-45bc8c5d8a61',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zPtBhPNqw79Ij1E865zBUv7myRJQVGPokMmuHL.ttf',
    variant: '500',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '0fbfde43-fcc3-4c11-8fde-863593986e88',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zPtBhPNqw79Ij1E865zBUv7myjJQVGPokMmuHL.ttf',
    variant: 'regular',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '2224e11d-d779-476b-bc5a-331a813d0a01',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zPtBhPNqw79Ij1E865zBUv7mz9JQVGPokMmuHL.ttf',
    variant: '300',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '53ef8158-8dde-4d8d-88a0-4fae9601c7cc',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zPtBhPNqw79Ij1E865zBUv7mwjJQVGPokMmuHL.ttf',
    variant: '200',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd7eda95e-ec28-430b-a663-72d430e026ec',
    origin: GF_SOURCE,
    url: GF_URL + 'jost/v6/92zPtBhPNqw79Ij1E865zBUv7myjJAVGPokMmuHL.ttf',
    variant: '100',
    family: 'Jost',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '23daa06b-47da-405d-8e97-bbcc4470ba14',
    origin: GF_SOURCE,
    url: GF_URL + 'sarala/v5/uK_x4riEZv4o1w9ptjI3OtWYVkMpXA.ttf',
    variant: '700',
    family: 'Sarala',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ff4edf61-ba58-4f21-9537-9a796b6d104a',
    origin: GF_SOURCE,
    url: GF_URL + 'sarala/v5/uK_y4riEZv4o1w9RCh0TMv6EXw.ttf',
    variant: 'regular',
    family: 'Sarala',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '9ac81442-2312-4cb4-b6bb-aaef7cb2f129',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p9RB7xD-GVxk3Nd.ttf',
    variant: '900italic',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'dacd6562-c081-442b-ab16-621ea6332e83',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xaID1ALZH2mBhkw.ttf',
    variant: '900',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '9e5e62e6-13cc-4b62-8ef7-3e09daa9bc8c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p91BrxD-GVxk3Nd.ttf',
    variant: '800italic',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '8df42e7c-166b-4d8d-9aaf-c578cd395c2b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xTIH1ALZH2mBhkw.ttf',
    variant: '800',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'ead7a525-72e8-44eb-8347-9dcb5691b804',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p9pBbxD-GVxk3Nd.ttf',
    variant: '700italic',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2037a08c-2599-4594-be23-6f60198d73d3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xUIL1ALZH2mBhkw.ttf',
    variant: '700',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'b523282f-b880-4a50-a09e-d03390a3aa97',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8NBLxD-GVxk3Nd.ttf',
    variant: '600italic',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'ac3a0bf7-a478-4079-8515-ee0f2f4f6a7f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xNIP1ALZH2mBhkw.ttf',
    variant: '600',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '6beb5a55-3cf7-4661-9c48-9bb81f8a8b0e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8hA7xD-GVxk3Nd.ttf',
    variant: '500italic',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'a32e983a-6d40-43ee-8e8b-183454b4c980',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xGIT1ALZH2mBhkw.ttf',
    variant: '500',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'eaca73ad-e384-4edc-931b-60a3899ad020',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFThWacfw6zH4dthXcyms1lPpC8I_b0juU057qfVKphL03l4.ttf',
    variant: 'italic',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '3231ed6a-3a46-40e8-a1c4-64a074c12e39',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU0J7K3RCJ1b0w.ttf',
    variant: 'regular',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c5ec7d90-dd67-458d-9294-cb70dab02e74',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p95ArxD-GVxk3Nd.ttf',
    variant: '300italic',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '771324f2-b590-49cc-a248-0c504cdb8b40',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xQIX1ALZH2mBhkw.ttf',
    variant: '300',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '9394f814-bbc5-4921-96c2-622a72529e1d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8dAbxD-GVxk3Nd.ttf',
    variant: '200italic',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '6a2c1249-6648-4ae1-944b-dba158ad3f5e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xJIb1ALZH2mBhkw.ttf',
    variant: '200',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '505c2ae5-64e8-4778-b0c8-ca086b20e8f3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFTjWacfw6zH4dthXcyms1lPpC8I_b0juU057p-xIJxp1ml4imo.ttf',
    variant: '100italic',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '77056f3b-2266-4926-a84e-2743a960d319',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'montserratalternates/v12/mFThWacfw6zH4dthXcyms1lPpC8I_b0juU0xiKfVKphL03l4.ttf',
    variant: '100',
    family: 'Montserrat Alternates',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '662c74aa-1be6-4778-bd54-d9672e354a06',
    origin: GF_SOURCE,
    url: GF_URL + 'yellowtail/v11/OZpGg_pnoDtINPfRIlLotlzNwED-b4g.ttf',
    variant: 'regular',
    family: 'Yellowtail',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'ba3b73a9-ced5-42c8-a380-6155841066a6',
    origin: GF_SOURCE,
    url: GF_URL + 'hammersmithone/v12/qWcyB624q4L_C4jGQ9IK0O_dFlnbshsks4MRXw.ttf',
    variant: 'regular',
    family: 'Hammersmith One',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '16ab559b-482c-4360-a592-c4d8d709960a',
    origin: GF_SOURCE,
    url: GF_URL + 'newscycle/v17/CSR54z1Qlv-GDxkbKVQ_dFsvaNNUuOwkC2s.ttf',
    variant: '700',
    family: 'News Cycle',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'f8d0d3b0-e56e-45d1-b98d-98105c7a0629',
    origin: GF_SOURCE,
    url: GF_URL + 'newscycle/v17/CSR64z1Qlv-GDxkbKVQ_TOcATNt_pOU.ttf',
    variant: 'regular',
    family: 'News Cycle',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'e06fe882-f57a-4f49-a52c-5a2d0ab2debd',
    origin: GF_SOURCE,
    url: GF_URL + 'staatliches/v6/HI_OiY8KO6hCsQSoAPmtMbectJG9O9PS.ttf',
    variant: 'regular',
    family: 'Staatliches',
    category: 'display',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '38c5eaeb-9b25-4651-87b2-3b65e4c3943e',
    origin: GF_SOURCE,
    url: GF_URL + 'palanquin/v6/9XUilJ90n1fBFg7ceXwUyn5oxJuqbi3ezg.ttf',
    variant: '700',
    family: 'Palanquin',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8f593e3b-2638-4ab5-8dfc-16bf91114d25',
    origin: GF_SOURCE,
    url: GF_URL + 'palanquin/v6/9XUilJ90n1fBFg7ceXwUrn9oxJuqbi3ezg.ttf',
    variant: '600',
    family: 'Palanquin',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '06b61876-8190-43f8-a66e-1430574ceede',
    origin: GF_SOURCE,
    url: GF_URL + 'palanquin/v6/9XUilJ90n1fBFg7ceXwUgnhoxJuqbi3ezg.ttf',
    variant: '500',
    family: 'Palanquin',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'e7e1033b-461c-428d-83ef-fdc46b8d4edf',
    origin: GF_SOURCE,
    url: GF_URL + 'palanquin/v6/9XUnlJ90n1fBFg7ceXwsdlFMzLC2Zw.ttf',
    variant: 'regular',
    family: 'Palanquin',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '7c53cf28-de22-44df-af67-2efe0619f1e2',
    origin: GF_SOURCE,
    url: GF_URL + 'palanquin/v6/9XUilJ90n1fBFg7ceXwU2nloxJuqbi3ezg.ttf',
    variant: '300',
    family: 'Palanquin',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'fc1063d1-7f69-4e71-a5bf-9ed310f13f45',
    origin: GF_SOURCE,
    url: GF_URL + 'palanquin/v6/9XUilJ90n1fBFg7ceXwUvnpoxJuqbi3ezg.ttf',
    variant: '200',
    family: 'Palanquin',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '5e900f67-42f7-4247-8abd-8704c55c1734',
    origin: GF_SOURCE,
    url: GF_URL + 'palanquin/v6/9XUhlJ90n1fBFg7ceXwUEltI7rWmZzTH.ttf',
    variant: '100',
    family: 'Palanquin',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f7224708-cfce-4003-a57d-55bbc4ecaa61',
    origin: GF_SOURCE,
    url: GF_URL + 'merienda/v9/gNMAW3x8Qoy5_mf8uWu-Fa-y1sfpPES4.ttf',
    variant: '700',
    family: 'Merienda',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'bcb86104-a548-4a02-a2b8-edd0d3d59f78',
    origin: GF_SOURCE,
    url: GF_URL + 'merienda/v9/gNMHW3x8Qoy5_mf8uVMCOou6_dvg.ttf',
    variant: 'regular',
    family: 'Merienda',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'f6b8c5ab-a8a1-4c56-8614-dc29cba4ddb0',
    origin: GF_SOURCE,
    url: GF_URL + 'hindguntur/v7/wXKyE3UZrok56nvamSuJd_yWmFczn9zaj5Ju.ttf',
    variant: '700',
    family: 'Hind Guntur',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '47d176ef-9389-4675-ba1d-841120d0f6a9',
    origin: GF_SOURCE,
    url: GF_URL + 'hindguntur/v7/wXKyE3UZrok56nvamSuJd_zymVczn9zaj5Ju.ttf',
    variant: '600',
    family: 'Hind Guntur',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '25224765-09d2-4cd8-bd49-55c309044bb5',
    origin: GF_SOURCE,
    url: GF_URL + 'hindguntur/v7/wXKyE3UZrok56nvamSuJd_zenlczn9zaj5Ju.ttf',
    variant: '500',
    family: 'Hind Guntur',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '957c5c3f-ebd3-4237-898f-16ce35a03230',
    origin: GF_SOURCE,
    url: GF_URL + 'hindguntur/v7/wXKvE3UZrok56nvamSuJd8Qqt3M7tMDT.ttf',
    variant: 'regular',
    family: 'Hind Guntur',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'fccc31cf-9747-4026-9c10-028cd5844b52',
    origin: GF_SOURCE,
    url: GF_URL + 'hindguntur/v7/wXKyE3UZrok56nvamSuJd_yGn1czn9zaj5Ju.ttf',
    variant: '300',
    family: 'Hind Guntur',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'efb9be99-43e8-416d-a4e3-ef3c68c3231b',
    origin: GF_SOURCE,
    url: GF_URL + 'asapcondensed/v8/pxiYypY1o9NHyXh3WvSbGSggdOeJUPVBim6CovpOkXA.ttf',
    variant: '700italic',
    family: 'Asap Condensed',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '009a3fe5-e454-4788-91e6-6628bb7823e3',
    origin: GF_SOURCE,
    url: GF_URL + 'asapcondensed/v8/pxieypY1o9NHyXh3WvSbGSggdO83TWlEgGqgp-pO.ttf',
    variant: '700',
    family: 'Asap Condensed',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '44625e85-2efc-4d9f-bd5a-66e7e4a7fd49',
    origin: GF_SOURCE,
    url: GF_URL + 'asapcondensed/v8/pxiYypY1o9NHyXh3WvSbGSggdOeJUJFAim6CovpOkXA.ttf',
    variant: '600italic',
    family: 'Asap Condensed',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '34ce4232-9827-472e-99ec-7147c67a4798',
    origin: GF_SOURCE,
    url: GF_URL + 'asapcondensed/v8/pxieypY1o9NHyXh3WvSbGSggdO9TTGlEgGqgp-pO.ttf',
    variant: '600',
    family: 'Asap Condensed',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '270ea018-a83e-40c2-91e6-e14324d34036',
    origin: GF_SOURCE,
    url: GF_URL + 'asapcondensed/v8/pxiYypY1o9NHyXh3WvSbGSggdOeJUL1Him6CovpOkXA.ttf',
    variant: '500italic',
    family: 'Asap Condensed',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '864586a5-83eb-47b4-b595-2e74d808f3a0',
    origin: GF_SOURCE,
    url: GF_URL + 'asapcondensed/v8/pxieypY1o9NHyXh3WvSbGSggdO9_S2lEgGqgp-pO.ttf',
    variant: '500',
    family: 'Asap Condensed',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'cfe1494e-251b-4495-9712-30ada08912cd',
    origin: GF_SOURCE,
    url: GF_URL + 'asapcondensed/v8/pxifypY1o9NHyXh3WvSbGSggdOeJaElurmapvvM.ttf',
    variant: 'italic',
    family: 'Asap Condensed',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '8db4416e-1f5a-4371-a604-7b6df3a00e84',
    origin: GF_SOURCE,
    url: GF_URL + 'asapcondensed/v8/pxidypY1o9NHyXh3WvSbGSggdNeLYk1Mq3ap.ttf',
    variant: 'regular',
    family: 'Asap Condensed',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '39d03a89-32b6-4691-a11a-2cc953423055',
    origin: GF_SOURCE,
    url: GF_URL + 'passionone/v11/Pby6FmL8HhTPqbjUzux3JEMS0X7owpYcuH8y.ttf',
    variant: '900',
    family: 'Passion One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7ddd5cb1-3f42-41b4-b452-737fe09c0884',
    origin: GF_SOURCE,
    url: GF_URL + 'passionone/v11/Pby6FmL8HhTPqbjUzux3JEMq037owpYcuH8y.ttf',
    variant: '700',
    family: 'Passion One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0dc5ecfc-e11c-4259-9784-2e25f644586e',
    origin: GF_SOURCE,
    url: GF_URL + 'passionone/v11/PbynFmL8HhTPqbjUzux3JHuW_Frg6YoV.ttf',
    variant: 'regular',
    family: 'Passion One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '99311410-2c22-4955-acca-d07944afb4cc',
    origin: GF_SOURCE,
    url: GF_URL + 'changa/v11/2-c79JNi2YuVOUcOarRPgnNGooxCZy22QjDp9htf1ZM.ttf',
    variant: '800',
    family: 'Changa',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '1e7ff62d-3e34-4487-9d0c-30ee55a3e39a',
    origin: GF_SOURCE,
    url: GF_URL + 'changa/v11/2-c79JNi2YuVOUcOarRPgnNGooxCZ0q2QjDp9htf1ZM.ttf',
    variant: '700',
    family: 'Changa',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '3708f90c-f152-4ffe-a572-5f1c0a4ae640',
    origin: GF_SOURCE,
    url: GF_URL + 'changa/v11/2-c79JNi2YuVOUcOarRPgnNGooxCZ3O2QjDp9htf1ZM.ttf',
    variant: '600',
    family: 'Changa',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f4e8eea1-3a5e-4aa5-b2b3-21a0402b2fa3',
    origin: GF_SOURCE,
    url: GF_URL + 'changa/v11/2-c79JNi2YuVOUcOarRPgnNGooxCZ5-xQjDp9htf1ZM.ttf',
    variant: '500',
    family: 'Changa',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '98d11094-6d6a-4797-a122-b2bb30bb1459',
    origin: GF_SOURCE,
    url: GF_URL + 'changa/v11/2-c79JNi2YuVOUcOarRPgnNGooxCZ62xQjDp9htf1ZM.ttf',
    variant: 'regular',
    family: 'Changa',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '634fd17a-c676-4693-b443-4aa884ddcc18',
    origin: GF_SOURCE,
    url: GF_URL + 'changa/v11/2-c79JNi2YuVOUcOarRPgnNGooxCZ_OxQjDp9htf1ZM.ttf',
    variant: '300',
    family: 'Changa',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'c0661f62-f1e7-4248-b83d-a8a11f46ce36',
    origin: GF_SOURCE,
    url: GF_URL + 'changa/v11/2-c79JNi2YuVOUcOarRPgnNGooxCZy2xQjDp9htf1ZM.ttf',
    variant: '200',
    family: 'Changa',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '36a5ca52-5e6d-4ea6-970d-cd64796e3641',
    origin: GF_SOURCE,
    url: GF_URL + 'pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK0nSgPJE4580.ttf',
    variant: 'regular',
    family: 'Press Start 2P',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '9afa441f-0e96-4575-81ca-7fa0be7873c8',
    origin: GF_SOURCE,
    url: GF_URL + 'rokkitt/v20/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pbcJDLE76HvN6n.ttf',
    variant: '900',
    family: 'Rokkitt',
    category: 'serif',
    version: 'v20',
    subset: 'latin',
  },
  {
    id: 'cefc35a4-e8ce-4374-86a3-c4a9139eb082',
    origin: GF_SOURCE,
    url: GF_URL + 'rokkitt/v20/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pycJDLE76HvN6n.ttf',
    variant: '800',
    family: 'Rokkitt',
    category: 'serif',
    version: 'v20',
    subset: 'latin',
  },
  {
    id: '325c7e95-0548-4faf-b542-34ab8e465f96',
    origin: GF_SOURCE,
    url: GF_URL + 'rokkitt/v20/qFdb35qfgYFjGy5hukqqhw5XeRgdi1oVcJDLE76HvN6n.ttf',
    variant: '700',
    family: 'Rokkitt',
    category: 'serif',
    version: 'v20',
    subset: 'latin',
  },
  {
    id: 'a27187e5-6f4c-48f6-a1de-03c22c8a1211',
    origin: GF_SOURCE,
    url: GF_URL + 'rokkitt/v20/qFdb35qfgYFjGy5hukqqhw5XeRgdi1oscJDLE76HvN6n.ttf',
    variant: '600',
    family: 'Rokkitt',
    category: 'serif',
    version: 'v20',
    subset: 'latin',
  },
  {
    id: '54260c54-2bae-40ff-ad0f-7a621242059b',
    origin: GF_SOURCE,
    url: GF_URL + 'rokkitt/v20/qFdb35qfgYFjGy5hukqqhw5XeRgdi1rAd5DLE76HvN6n.ttf',
    variant: '500',
    family: 'Rokkitt',
    category: 'serif',
    version: 'v20',
    subset: 'latin',
  },
  {
    id: '1ead087d-a0a5-418b-844d-d0b7cd1568bd',
    origin: GF_SOURCE,
    url: GF_URL + 'rokkitt/v20/qFdb35qfgYFjGy5hukqqhw5XeRgdi1ryd5DLE76HvN6n.ttf',
    variant: 'regular',
    family: 'Rokkitt',
    category: 'serif',
    version: 'v20',
    subset: 'latin',
  },
  {
    id: '97ece5f4-cb35-4130-8641-0eb0d856f42b',
    origin: GF_SOURCE,
    url: GF_URL + 'rokkitt/v20/qFdb35qfgYFjGy5hukqqhw5XeRgdi1qsd5DLE76HvN6n.ttf',
    variant: '300',
    family: 'Rokkitt',
    category: 'serif',
    version: 'v20',
    subset: 'latin',
  },
  {
    id: '578d73af-cd5d-42c3-a389-ac28083653bb',
    origin: GF_SOURCE,
    url: GF_URL + 'rokkitt/v20/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pyd5DLE76HvN6n.ttf',
    variant: '200',
    family: 'Rokkitt',
    category: 'serif',
    version: 'v20',
    subset: 'latin',
  },
  {
    id: '340a40c2-5873-4465-ae19-c19ade1b947c',
    origin: GF_SOURCE,
    url: GF_URL + 'rokkitt/v20/qFdb35qfgYFjGy5hukqqhw5XeRgdi1rydpDLE76HvN6n.ttf',
    variant: '100',
    family: 'Rokkitt',
    category: 'serif',
    version: 'v20',
    subset: 'latin',
  },
  {
    id: '7e65c105-b181-44d3-9d96-727a4358cb66',
    origin: GF_SOURCE,
    url:
      GF_URL + 'quattrocentosans/v13/va9X4lja2NVIDdIAAoMR5MfuElaRB0zMj_bTPXnijLsJV7E.ttf',
    variant: '700italic',
    family: 'Quattrocento Sans',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'e531df27-1599-4e6c-92b6-934424c4a2bc',
    origin: GF_SOURCE,
    url: GF_URL + 'quattrocentosans/v13/va9Z4lja2NVIDdIAAoMR5MfuElaRB0RykmrWN33AiasJ.ttf',
    variant: '700',
    family: 'Quattrocento Sans',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '7e272607-f5a8-4b8e-8f6d-9f736392f182',
    origin: GF_SOURCE,
    url: GF_URL + 'quattrocentosans/v13/va9a4lja2NVIDdIAAoMR5MfuElaRB0zMt0r8GXHJkLI.ttf',
    variant: 'italic',
    family: 'Quattrocento Sans',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'fcb01dd6-7b4f-4130-8ecd-f3a056e5df40',
    origin: GF_SOURCE,
    url: GF_URL + 'quattrocentosans/v13/va9c4lja2NVIDdIAAoMR5MfuElaRB3zOvU7eHGHJ.ttf',
    variant: 'regular',
    family: 'Quattrocento Sans',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'f0bd1d7a-3f26-473f-828f-d71804ce5683',
    origin: GF_SOURCE,
    url: GF_URL + 'noticiatext/v10/VuJrdNDF2Yv9qppOePKYRP12YwPhumvVjjTkeMnz.ttf',
    variant: '700italic',
    family: 'Noticia Text',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '2a89720b-db84-4be3-9d19-bc979d9d2cea',
    origin: GF_SOURCE,
    url: GF_URL + 'noticiatext/v10/VuJpdNDF2Yv9qppOePKYRP1-3R59v2HRrDH0eA.ttf',
    variant: '700',
    family: 'Noticia Text',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '45f5540e-f6ad-4160-bebf-4580a12b71c2',
    origin: GF_SOURCE,
    url: GF_URL + 'noticiatext/v10/VuJodNDF2Yv9qppOePKYRP12YztdlU_dpSjt.ttf',
    variant: 'italic',
    family: 'Noticia Text',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b49767be-3435-4b65-b4aa-f46f62ae440c',
    origin: GF_SOURCE,
    url: GF_URL + 'noticiatext/v10/VuJ2dNDF2Yv9qppOePKYRP1GYTFZt0rNpQ.ttf',
    variant: 'regular',
    family: 'Noticia Text',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '31070583-9642-4c58-a2a3-95e5f8210f0a',
    origin: GF_SOURCE,
    url: GF_URL + 'orbitron/v17/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimxpmIyXjU1pg.ttf',
    variant: '900',
    family: 'Orbitron',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '97ded689-02d9-4c90-a9b8-d1a2ea33766a',
    origin: GF_SOURCE,
    url: GF_URL + 'orbitron/v17/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nymymxpmIyXjU1pg.ttf',
    variant: '800',
    family: 'Orbitron',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '3887fc1b-464b-4426-8109-56626722c2e6',
    origin: GF_SOURCE,
    url: GF_URL + 'orbitron/v17/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1ny_CmxpmIyXjU1pg.ttf',
    variant: '700',
    family: 'Orbitron',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '2aa02215-dd69-475c-bca9-ec1d33ac1185',
    origin: GF_SOURCE,
    url: GF_URL + 'orbitron/v17/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyxSmxpmIyXjU1pg.ttf',
    variant: '600',
    family: 'Orbitron',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'a3ab29ce-373b-47bc-9e69-71cc5dbcaa4d',
    origin: GF_SOURCE,
    url: GF_URL + 'orbitron/v17/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyKS6xpmIyXjU1pg.ttf',
    variant: '500',
    family: 'Orbitron',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '3e4ccdc7-e9e0-46c4-b0f3-6f1c2b615ff2',
    origin: GF_SOURCE,
    url: GF_URL + 'orbitron/v17/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyGy6xpmIyXjU1pg.ttf',
    variant: 'regular',
    family: 'Orbitron',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '4ef3906a-4986-4886-b1fa-a96251103d33',
    origin: GF_SOURCE,
    url: GF_URL + 'gothica1/v8/CSR44z5ZnPydRjlCCwlC6OAKSPl6tOU9Eg.ttf',
    variant: '900',
    family: 'Gothic A1',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '23eb9c5f-d2b2-4dda-a15f-f75436fd362e',
    origin: GF_SOURCE,
    url: GF_URL + 'gothica1/v8/CSR44z5ZnPydRjlCCwlCzOEKSPl6tOU9Eg.ttf',
    variant: '800',
    family: 'Gothic A1',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f3473ad3-d023-46f7-810d-03c810a842a8',
    origin: GF_SOURCE,
    url: GF_URL + 'gothica1/v8/CSR44z5ZnPydRjlCCwlC0OIKSPl6tOU9Eg.ttf',
    variant: '700',
    family: 'Gothic A1',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '85980a2f-bac1-405b-9fc5-061382eb80d7',
    origin: GF_SOURCE,
    url: GF_URL + 'gothica1/v8/CSR44z5ZnPydRjlCCwlCtOMKSPl6tOU9Eg.ttf',
    variant: '600',
    family: 'Gothic A1',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '6825cfa9-39b9-4b83-aa12-18efb9571041',
    origin: GF_SOURCE,
    url: GF_URL + 'gothica1/v8/CSR44z5ZnPydRjlCCwlCmOQKSPl6tOU9Eg.ttf',
    variant: '500',
    family: 'Gothic A1',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'fb6abc90-2a42-4149-9485-408fdcad381c',
    origin: GF_SOURCE,
    url: GF_URL + 'gothica1/v8/CSR94z5ZnPydRjlCCwl6bM0uQNJmvQ.ttf',
    variant: 'regular',
    family: 'Gothic A1',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '7a3b7c82-ecf9-4006-9817-b2c90aea2237',
    origin: GF_SOURCE,
    url: GF_URL + 'gothica1/v8/CSR44z5ZnPydRjlCCwlCwOUKSPl6tOU9Eg.ttf',
    variant: '300',
    family: 'Gothic A1',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c22715a9-90b9-4f6d-854e-2732086a5f01',
    origin: GF_SOURCE,
    url: GF_URL + 'gothica1/v8/CSR44z5ZnPydRjlCCwlCpOYKSPl6tOU9Eg.ttf',
    variant: '200',
    family: 'Gothic A1',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '6f4a98c3-b789-4361-b3da-8369b136f984',
    origin: GF_SOURCE,
    url: GF_URL + 'gothica1/v8/CSR74z5ZnPydRjlCCwlCCMcqYtd2vfwk.ttf',
    variant: '100',
    family: 'Gothic A1',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '7622e6eb-4b2d-449a-a19e-8b1c5b25d28c',
    origin: GF_SOURCE,
    url: GF_URL + 'baloo2/v2/wXKuE3kTposypRyd7__6JeQAmX8yrdk.ttf',
    variant: '800',
    family: 'Baloo 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'a8e9e15b-e00c-4171-8830-9748364de98f',
    origin: GF_SOURCE,
    url: GF_URL + 'baloo2/v2/wXKuE3kTposypRyd7-P5JeQAmX8yrdk.ttf',
    variant: '700',
    family: 'Baloo 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'af8d26a5-2c38-4b14-bfd9-6a65f737645f',
    origin: GF_SOURCE,
    url: GF_URL + 'baloo2/v2/wXKuE3kTposypRyd74f4JeQAmX8yrdk.ttf',
    variant: '600',
    family: 'Baloo 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: 'f59cd51a-dafe-478a-84a5-007c68d4b8ab',
    origin: GF_SOURCE,
    url: GF_URL + 'baloo2/v2/wXKuE3kTposypRyd76v_JeQAmX8yrdk.ttf',
    variant: '500',
    family: 'Baloo 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '33a282e1-2dd1-4422-8dd8-56af0209c8c3',
    origin: GF_SOURCE,
    url: GF_URL + 'baloo2/v2/wXKrE3kTposypRyd11_WAewrhXY.ttf',
    variant: 'regular',
    family: 'Baloo 2',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '7aeb6922-9d50-4f75-bec9-daed3884dee5',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6sfjptAgt5VM-kVkqdyU8n1ioSblJFh8ARHNh4zg.ttf',
    variant: '700italic',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '120058ce-c9d1-424c-8f38-bbe126a6fa21',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6qfjptAgt5VM-kVkqdyU8n3pQP8ldPg-IUDNg.ttf',
    variant: '700',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '1100d032-6347-4c58-90c5-8bcd7646855f',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6sfjptAgt5VM-kVkqdyU8n1ioSClNFh8ARHNh4zg.ttf',
    variant: '600italic',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'faf6abf8-a9a7-4cbc-9fd4-ff04d73f4549',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6qfjptAgt5VM-kVkqdyU8n3vAO8ldPg-IUDNg.ttf',
    variant: '600',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f130d2dc-8120-48cb-8f63-b3c11444d8f2',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6sfjptAgt5VM-kVkqdyU8n1ioSJlRFh8ARHNh4zg.ttf',
    variant: '500italic',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'e3c2c252-c25c-49f8-9165-4e1c0e36a8d0',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6qfjptAgt5VM-kVkqdyU8n3twJ8ldPg-IUDNg.ttf',
    variant: '500',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '645c70f5-a34d-42a1-a09a-e26155846d4a',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6pfjptAgt5VM-kVkqdyU8n1ioq0n1hj-sNFQ.ttf',
    variant: 'italic',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '988e7903-0a5c-495c-b59d-d8a6c0089af8',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F63fjptAgt5VM-kVkqdyU8n5igg1l9kn-s.ttf',
    variant: 'regular',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '3176c86d-53be-4c26-b2fb-cde8b301e9d0',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6sfjptAgt5VM-kVkqdyU8n1ioSflVFh8ARHNh4zg.ttf',
    variant: '300italic',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '4ca23ee2-75da-4882-b748-7acb2e7fd91d',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6qfjptAgt5VM-kVkqdyU8n3oQI8ldPg-IUDNg.ttf',
    variant: '300',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6aeef3a2-98b7-4ca0-9d5f-498ab6c54e87',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6sfjptAgt5VM-kVkqdyU8n1ioSGlZFh8ARHNh4zg.ttf',
    variant: '200italic',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '71126dbc-c8fb-41c7-8c1b-42e8d957e5db',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6qfjptAgt5VM-kVkqdyU8n3uAL8ldPg-IUDNg.ttf',
    variant: '200',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '4f261889-0309-440c-9a0c-1184e57bd632',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6rfjptAgt5VM-kVkqdyU8n1ioStndlre4dFcFh.ttf',
    variant: '100italic',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '3c96f4ab-fbfe-401c-88a7-446159a77303',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexmono/v6/-F6pfjptAgt5VM-kVkqdyU8n3kwq0n1hj-sNFQ.ttf',
    variant: '100',
    family: 'IBM Plex Mono',
    category: 'monospace',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '82886900-d826-46a9-b76a-7600090996ec',
    origin: GF_SOURCE,
    url: GF_URL + 'ramabhadra/v10/EYq2maBOwqRW9P1SQ83LehNGX5uWw3o.ttf',
    variant: 'regular',
    family: 'Ramabhadra',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '54cbea17-c581-4386-a1f1-629e13cf42c2',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesans/v8/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGMjkZtWP7FJCt2c.ttf',
    variant: '900',
    family: 'Encode Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '981dc13e-a354-482b-9032-0289cc714b4e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesans/v8/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGOHkZtWP7FJCt2c.ttf',
    variant: '800',
    family: 'Encode Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b628d59d-0f9e-4390-9f0b-5b250851d4de',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesans/v8/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGIbkZtWP7FJCt2c.ttf',
    variant: '700',
    family: 'Encode Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f5c7ba2b-02d6-4093-84b9-e13fac64d3ce',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesans/v8/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGL_kZtWP7FJCt2c.ttf',
    variant: '600',
    family: 'Encode Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '2d0752ba-82ec-4fb6-9bb2-cbe0b2f5c098',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesans/v8/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGFPjZtWP7FJCt2c.ttf',
    variant: '500',
    family: 'Encode Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '5128b6f3-f657-4228-b000-38ce7366c9c4',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesans/v8/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGGHjZtWP7FJCt2c.ttf',
    variant: 'regular',
    family: 'Encode Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ae613b5e-5c87-4a3e-9bc6-ca7243864de9',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesans/v8/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGD_jZtWP7FJCt2c.ttf',
    variant: '300',
    family: 'Encode Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd0c14457-c6e4-4820-bdbc-9cba025d9a65',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesans/v8/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGOHjZtWP7FJCt2c.ttf',
    variant: '200',
    family: 'Encode Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd9cf82b5-3df0-407f-af92-24964192c839',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'encodesans/v8/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGGHiZtWP7FJCt2c.ttf',
    variant: '100',
    family: 'Encode Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '2ba82d5c-c6b6-48f2-ba8c-b60400d59711',
    origin: GF_SOURCE,
    url: GF_URL + 'cantarell/v10/B50WF7ZDq37KMUvlO015iZrSEY6aB4oWgWHB.ttf',
    variant: '700italic',
    family: 'Cantarell',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '5ae742e4-30b0-4a98-876f-148b5044b6f8',
    origin: GF_SOURCE,
    url: GF_URL + 'cantarell/v10/B50IF7ZDq37KMUvlO01xN4dOFISeJY8GgQ.ttf',
    variant: '700',
    family: 'Cantarell',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'd005e100-6648-4068-a9fc-ce87d170b1ce',
    origin: GF_SOURCE,
    url: GF_URL + 'cantarell/v10/B50LF7ZDq37KMUvlO015iaJuPqqSLJYf.ttf',
    variant: 'italic',
    family: 'Cantarell',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'd8fdfa0c-9526-40ae-b057-dac2b1ddf2c7',
    origin: GF_SOURCE,
    url: GF_URL + 'cantarell/v10/B50NF7ZDq37KMUvlO01Ji6hqHK-CLA.ttf',
    variant: 'regular',
    family: 'Cantarell',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4a1ab1b4-9293-4370-ab32-f2693bf8fee9',
    origin: GF_SOURCE,
    url: GF_URL + 'chivo/v12/va9D4kzIxd1KFrBteWJ4sKjkRT_-bF0.ttf',
    variant: '900italic',
    family: 'Chivo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'd0bc5ef1-08c8-409e-a509-58f8c8f32e95',
    origin: GF_SOURCE,
    url: GF_URL + 'chivo/v12/va9F4kzIxd1KFrjrZsZ_uqzGQC_-.ttf',
    variant: '900',
    family: 'Chivo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '5cc2735a-8065-421f-a9ae-3f1b9c8203bc',
    origin: GF_SOURCE,
    url: GF_URL + 'chivo/v12/va9D4kzIxd1KFrBteVp6sKjkRT_-bF0.ttf',
    variant: '700italic',
    family: 'Chivo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2c680f38-b33f-44d7-9cb4-c8f2dd57d1c8',
    origin: GF_SOURCE,
    url: GF_URL + 'chivo/v12/va9F4kzIxd1KFrjTZMZ_uqzGQC_-.ttf',
    variant: '700',
    family: 'Chivo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '362628df-5502-4f04-bcb6-6b77f3292aae',
    origin: GF_SOURCE,
    url: GF_URL + 'chivo/v12/va9G4kzIxd1KFrBtQeZVlKDPWTY.ttf',
    variant: 'italic',
    family: 'Chivo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'bd6afdaa-fc55-4841-aca8-eb7a6293b823',
    origin: GF_SOURCE,
    url: GF_URL + 'chivo/v12/va9I4kzIxd1KFoBvS-J3kbDP.ttf',
    variant: 'regular',
    family: 'Chivo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '6e9c7cc0-1a51-4064-a583-dceca5f6d5c8',
    origin: GF_SOURCE,
    url: GF_URL + 'chivo/v12/va9D4kzIxd1KFrBteUp9sKjkRT_-bF0.ttf',
    variant: '300italic',
    family: 'Chivo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '392d8ad0-435c-4fb2-b50d-de8a9a19ae1c',
    origin: GF_SOURCE,
    url: GF_URL + 'chivo/v12/va9F4kzIxd1KFrjDY8Z_uqzGQC_-.ttf',
    variant: '300',
    family: 'Chivo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '8142a404-646a-4757-970f-bd6448a4dd56',
    origin: GF_SOURCE,
    url: GF_URL + 'oldstandardtt/v13/MwQrbh3o1vLImiwAVvYawgcf2eVWEX-dTFxeb80flQ.ttf',
    variant: '700',
    family: 'Old Standard TT',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'b8e9a3d5-7dd7-4e48-90fd-821087188f40',
    origin: GF_SOURCE,
    url: GF_URL + 'oldstandardtt/v13/MwQsbh3o1vLImiwAVvYawgcf2eVer1q9ZnJSZtQG.ttf',
    variant: 'italic',
    family: 'Old Standard TT',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '81d2e283-4986-446b-9c31-995f43950628',
    origin: GF_SOURCE,
    url: GF_URL + 'oldstandardtt/v13/MwQubh3o1vLImiwAVvYawgcf2eVurVC5RHdCZg.ttf',
    variant: 'regular',
    family: 'Old Standard TT',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '56cbf712-b0f4-41d6-8b7b-8991f166c30c',
    origin: GF_SOURCE,
    url: GF_URL + 'prata/v13/6xKhdSpbNNCT-vWIAG_5LWwJ.ttf',
    variant: 'regular',
    family: 'Prata',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '7f6c4550-d1c4-42a7-8bc4-8613a556ea01',
    origin: GF_SOURCE,
    url: GF_URL + 'concertone/v11/VEM1Ro9xs5PjtzCu-srDqRTlhv-CuVAQ.ttf',
    variant: 'regular',
    family: 'Concert One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'de031daa-f80f-421c-8224-4f563454bc4a',
    origin: GF_SOURCE,
    url: GF_URL + 'didactgothic/v14/ahcfv8qz1zt6hCC5G4F_P4ASpUySp0LlcyQ.ttf',
    variant: 'regular',
    family: 'Didact Gothic',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'c9018f79-ed1f-4003-8bcf-df0a6d2a6591',
    origin: GF_SOURCE,
    url: GF_URL + 'francoisone/v15/_Xmr-H4zszafZw3A-KPSZutNxgKQu_avAg.ttf',
    variant: 'regular',
    family: 'Francois One',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'e89fb27c-4390-442f-b17c-847b95306d70',
    origin: GF_SOURCE,
    url: GF_URL + 'secularone/v5/8QINdiTajsj_87rMuMdKypDlMul7LJpK.ttf',
    variant: 'regular',
    family: 'Secular One',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b6514597-1706-425e-bb77-70dc6ebeba4c',
    origin: GF_SOURCE,
    url: GF_URL + 'kalam/v11/YA9Qr0Wd4kDdMtDqHQLLmCUItqGt.ttf',
    variant: '700',
    family: 'Kalam',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '4ea1b0a0-60c5-40d5-b9a3-979e7bafb156',
    origin: GF_SOURCE,
    url: GF_URL + 'kalam/v11/YA9dr0Wd4kDdMuhWMibDszkB.ttf',
    variant: 'regular',
    family: 'Kalam',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b7cf76e7-5e68-447b-9bae-3d7ea76e9e1f',
    origin: GF_SOURCE,
    url: GF_URL + 'kalam/v11/YA9Qr0Wd4kDdMtD6GgLLmCUItqGt.ttf',
    variant: '300',
    family: 'Kalam',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'e65d99f2-0d7e-4101-adf9-3c5ac7e3f18f',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVkJx26TKEr37c9aBBxOlos7iLSrwFUlw.ttf',
    variant: '800italic',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '7fbeac66-fb67-4558-9dba-2f9f8df0f28c',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVmJx26TKEr37c9YLJvulwm6gDXvwE.ttf',
    variant: '800',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '160bac6b-3d01-4564-b898-9b61c7dd9814',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVkJx26TKEr37c9aBBxJlks7iLSrwFUlw.ttf',
    variant: '700italic',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '2be10464-72f3-4862-905b-17ace7d35ad3',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVmJx26TKEr37c9YK5sulwm6gDXvwE.ttf',
    variant: '700',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '98189bd3-de1e-4065-ae81-223310a76447',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVkJx26TKEr37c9aBBxQlgs7iLSrwFUlw.ttf',
    variant: '600italic',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c667060c-504d-433d-ab1a-e8942d99d864',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVmJx26TKEr37c9YMptulwm6gDXvwE.ttf',
    variant: '600',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ad289316-8e6b-443e-803b-36463c1bc32f',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVkJx26TKEr37c9aBBxbl8s7iLSrwFUlw.ttf',
    variant: '500italic',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd61cfef5-0d3c-4358-af52-be324f08f9f0',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVmJx26TKEr37c9YOZqulwm6gDXvwE.ttf',
    variant: '500',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'a0953140-b841-455a-b1d5-0a244fd99b74',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVhJx26TKEr37c9aBBJmnYI5gnOpg.ttf',
    variant: 'italic',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd301d24f-af30-478f-9a31-d5048ff78311',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVjJx26TKEr37c9WBJDnlQN9gk.ttf',
    variant: 'regular',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '00919037-9433-4c12-a712-575810d98fab',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVkJx26TKEr37c9aBBxNl4s7iLSrwFUlw.ttf',
    variant: '300italic',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f1b960f9-6534-43be-ad4f-4709d043b973',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVmJx26TKEr37c9YL5rulwm6gDXvwE.ttf',
    variant: '300',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'ed18030c-a852-4934-b7e7-21c9614a8baa',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVkJx26TKEr37c9aBBxUl0s7iLSrwFUlw.ttf',
    variant: '200italic',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f11fae14-becd-4ca3-b8ed-5233a668b792',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVmJx26TKEr37c9YNpoulwm6gDXvwE.ttf',
    variant: '200',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '1c237172-433b-4e05-a13a-319d7e533ee9',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVnJx26TKEr37c9aBBx_nwMxAzephhN.ttf',
    variant: '100italic',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e367967b-ba5b-4ecd-8991-238582edfab6',
    origin: GF_SOURCE,
    url: GF_URL + 'sarabun/v8/DtVhJx26TKEr37c9YHZJmnYI5gnOpg.ttf',
    variant: '100',
    family: 'Sarabun',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'cba71a32-188f-4c84-bdf4-26142b38ff88',
    origin: GF_SOURCE,
    url: GF_URL + 'pathwaygothicone/v9/MwQrbgD32-KAvjkYGNUUxAtW7pEBwx-dTFxeb80flQ.ttf',
    variant: 'regular',
    family: 'Pathway Gothic One',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '823f1dec-be06-4309-9ffd-25e54055bd54',
    origin: GF_SOURCE,
    url: GF_URL + 'cuprum/v14/dg47_pLmvrkcOkBNI_FMh0j91rkhli25aXjYIhYmknUPEA.ttf',
    variant: '700italic',
    family: 'Cuprum',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'cd0154ed-dd22-41f4-926a-e879496660c6',
    origin: GF_SOURCE,
    url: GF_URL + 'cuprum/v14/dg47_pLmvrkcOkBNI_FMh0j91rkhli25UHjYIhYmknUPEA.ttf',
    variant: '600italic',
    family: 'Cuprum',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '9bc4f6d7-c9bb-4204-86e0-ef75784c5410',
    origin: GF_SOURCE,
    url: GF_URL + 'cuprum/v14/dg47_pLmvrkcOkBNI_FMh0j91rkhli25vH_YIhYmknUPEA.ttf',
    variant: '500italic',
    family: 'Cuprum',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'b0e18840-f7b6-44fe-82f3-ca28daa18adc',
    origin: GF_SOURCE,
    url: GF_URL + 'cuprum/v14/dg47_pLmvrkcOkBNI_FMh0j91rkhli25jn_YIhYmknUPEA.ttf',
    variant: 'italic',
    family: 'Cuprum',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '7822ea9a-a4cc-4423-8068-86f69dd5263b',
    origin: GF_SOURCE,
    url: GF_URL + 'cuprum/v14/dg45_pLmvrkcOkBnKsOzXyGWTBcmgwL9ZjzSJjQjgnU.ttf',
    variant: '700',
    family: 'Cuprum',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'e04c1d15-3d42-4ce4-a541-e23a6c2b5d96',
    origin: GF_SOURCE,
    url: GF_URL + 'cuprum/v14/dg45_pLmvrkcOkBnKsOzXyGWTBcmgzv9ZjzSJjQjgnU.ttf',
    variant: '600',
    family: 'Cuprum',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '473b7302-0559-48cc-bbd6-01be42f3f4ba',
    origin: GF_SOURCE,
    url: GF_URL + 'cuprum/v14/dg45_pLmvrkcOkBnKsOzXyGWTBcmg9f6ZjzSJjQjgnU.ttf',
    variant: '500',
    family: 'Cuprum',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'a52453a9-d602-4430-9619-a458464658df',
    origin: GF_SOURCE,
    url: GF_URL + 'cuprum/v14/dg45_pLmvrkcOkBnKsOzXyGWTBcmg-X6ZjzSJjQjgnU.ttf',
    variant: 'regular',
    family: 'Cuprum',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '1d2884f8-ae1f-436c-b0be-616c0d7fb9b2',
    origin: GF_SOURCE,
    url: GF_URL + 'squadaone/v9/BCasqZ8XsOrx4mcOk6MtWaA8WDBkHgs.ttf',
    variant: 'regular',
    family: 'Squada One',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '1efab3f8-2c88-4848-80b0-9c68e7e67258',
    origin: GF_SOURCE,
    url: GF_URL + 'sacramento/v8/buEzpo6gcdjy0EiZMBUG0CoV_NxLeiw.ttf',
    variant: 'regular',
    family: 'Sacramento',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '0119e887-2d6f-4aaf-9114-014cb6875cc7',
    origin: GF_SOURCE,
    url: GF_URL + 'patrickhand/v14/LDI1apSQOAYtSuYWp8ZhfYeMWcjKm7sp8g.ttf',
    variant: 'regular',
    family: 'Patrick Hand',
    category: 'handwriting',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '8592c369-9220-4eb5-90e7-17f4f688a622',
    origin: GF_SOURCE,
    url: GF_URL + 'archivoblack/v10/HTxqL289NzCGg4MzN6KJ7eW6OYuP_x7yx3A.ttf',
    variant: 'regular',
    family: 'Archivo Black',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'e64b6b4c-f251-41e7-b8e1-5f381d48565d',
    origin: GF_SOURCE,
    url: GF_URL + 'russoone/v9/Z9XUDmZRWg6M1LvRYsH-yMOInrib9Q.ttf',
    variant: 'regular',
    family: 'Russo One',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '157ade28-4fae-4530-ac63-e337560d0ec2',
    origin: GF_SOURCE,
    url: GF_URL + 'spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrEsvm6OChXtf4qS.ttf',
    variant: '900',
    family: 'Spartan',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '140a9a10-2f1d-4d4c-9e8c-bb31a430f163',
    origin: GF_SOURCE,
    url: GF_URL + 'spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrEFvm6OChXtf4qS.ttf',
    variant: '800',
    family: 'Spartan',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '2126069d-2728-4e22-b17c-f54e2b65680a',
    origin: GF_SOURCE,
    url: GF_URL + 'spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrFivm6OChXtf4qS.ttf',
    variant: '700',
    family: 'Spartan',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'e33c4c36-be8c-4840-91a9-d4258fa84336',
    origin: GF_SOURCE,
    url: GF_URL + 'spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrFbvm6OChXtf4qS.ttf',
    variant: '600',
    family: 'Spartan',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'bffc119b-eb9c-44d3-a31f-120ec82c1cac',
    origin: GF_SOURCE,
    url: GF_URL + 'spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrG3uW6OChXtf4qS.ttf',
    variant: '500',
    family: 'Spartan',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '8a894fcc-d550-49e6-b83d-8e71bb1123bb',
    origin: GF_SOURCE,
    url: GF_URL + 'spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrGFuW6OChXtf4qS.ttf',
    variant: 'regular',
    family: 'Spartan',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'c50f0e21-9e8e-474e-9f14-f5cf2a9192e8',
    origin: GF_SOURCE,
    url: GF_URL + 'spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrHbuW6OChXtf4qS.ttf',
    variant: '300',
    family: 'Spartan',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '5eb88b30-4a46-4966-9bc2-ad6b1c58c6e3',
    origin: GF_SOURCE,
    url: GF_URL + 'spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrEFuW6OChXtf4qS.ttf',
    variant: '200',
    family: 'Spartan',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '1c6926e6-6334-48b6-8a73-803d69d76a38',
    origin: GF_SOURCE,
    url: GF_URL + 'spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrGFuG6OChXtf4qS.ttf',
    variant: '100',
    family: 'Spartan',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'd8c17ff0-f7ce-43ca-9130-0bce726f626c',
    origin: GF_SOURCE,
    url: GF_URL + 'kaushanscript/v9/vm8vdRfvXFLG3OLnsO15WYS5DF7_ytN3M48a.ttf',
    variant: 'regular',
    family: 'Kaushan Script',
    category: 'handwriting',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '9e9f51c9-ff53-46e6-a6d0-0623132a7881',
    origin: GF_SOURCE,
    url: GF_URL + 'cardo/v14/wlpygwjKBV1pqhND-aQR82JHaTBX.ttf',
    variant: '700',
    family: 'Cardo',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'cb13d1dc-e951-493d-8bd8-6ac016ee1995',
    origin: GF_SOURCE,
    url: GF_URL + 'cardo/v14/wlpxgwjKBV1pqhv93IQ73W5OcCk.ttf',
    variant: 'italic',
    family: 'Cardo',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'a302a903-3f8f-435e-b7e3-222e180b1e14',
    origin: GF_SOURCE,
    url: GF_URL + 'cardo/v14/wlp_gwjKBV1pqiv_1oAZ2H5O.ttf',
    variant: 'regular',
    family: 'Cardo',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '3f9316cd-f112-4236-a37e-f99f390b30c3',
    origin: GF_SOURCE,
    url: GF_URL + 'yantramanav/v6/flUhRqu5zY00QEpyWJYWN59wesNZIhI8tIHh.ttf',
    variant: '900',
    family: 'Yantramanav',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '1915c9e7-05b8-47fc-a1a3-07fcb26c4ba2',
    origin: GF_SOURCE,
    url: GF_URL + 'yantramanav/v6/flUhRqu5zY00QEpyWJYWN59IeMNZIhI8tIHh.ttf',
    variant: '700',
    family: 'Yantramanav',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '291cc0bb-7780-4913-9f08-03b848958d42',
    origin: GF_SOURCE,
    url: GF_URL + 'yantramanav/v6/flUhRqu5zY00QEpyWJYWN58AfsNZIhI8tIHh.ttf',
    variant: '500',
    family: 'Yantramanav',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ecf05ebc-6f77-45bc-b7d8-581e277071f8',
    origin: GF_SOURCE,
    url: GF_URL + 'yantramanav/v6/flU8Rqu5zY00QEpyWJYWN6f0V-dRCQ41.ttf',
    variant: 'regular',
    family: 'Yantramanav',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '0aa246ab-7598-4caa-a972-2ba1010b4b82',
    origin: GF_SOURCE,
    url: GF_URL + 'yantramanav/v6/flUhRqu5zY00QEpyWJYWN59Yf8NZIhI8tIHh.ttf',
    variant: '300',
    family: 'Yantramanav',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '58beee56-a981-4140-b0c9-1b5f2cbe130e',
    origin: GF_SOURCE,
    url: GF_URL + 'yantramanav/v6/flU-Rqu5zY00QEpyWJYWN5-QXeNzDB41rZg.ttf',
    variant: '100',
    family: 'Yantramanav',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bee37e24-55e3-4175-baf5-13541feb7b2d',
    origin: GF_SOURCE,
    url: GF_URL + 'almarai/v5/tssoApxBaigK_hnnS_qjhnicoq72sXg.ttf',
    variant: '800',
    family: 'Almarai',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b8bc1514-da48-4a1e-a3c7-44b03f36eaed',
    origin: GF_SOURCE,
    url: GF_URL + 'almarai/v5/tssoApxBaigK_hnnS-aghnicoq72sXg.ttf',
    variant: '700',
    family: 'Almarai',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '4f4c7c16-8fd6-4596-b935-d842171a0414',
    origin: GF_SOURCE,
    url: GF_URL + 'almarai/v5/tsstApxBaigK_hnnc1qPonC3vqc.ttf',
    variant: 'regular',
    family: 'Almarai',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '7634df8c-3914-41c7-bc74-25b1acdd3caf',
    origin: GF_SOURCE,
    url: GF_URL + 'almarai/v5/tssoApxBaigK_hnnS_anhnicoq72sXg.ttf',
    variant: '300',
    family: 'Almarai',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '8965231b-7fc0-41e1-b34b-53940c7911aa',
    origin: GF_SOURCE,
    url: GF_URL + 'greatvibes/v8/RWmMoKWR9v4ksMfaWd_JN-XCg6UKDXlq.ttf',
    variant: 'regular',
    family: 'Great Vibes',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b0aa5987-d056-42a2-93ae-7d74072b49bc',
    origin: GF_SOURCE,
    url: GF_URL + 'redhatdisplay/v4/8vIX7wUr0m80wwYf0QCXZzYzUoTg-AYgaqrFIkJQb7zU.ttf',
    variant: '900italic',
    family: 'Red Hat Display',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c6f782d5-28c4-4916-84c1-e12ab6b8bbf6',
    origin: GF_SOURCE,
    url: GF_URL + 'redhatdisplay/v4/8vIV7wUr0m80wwYf0QCXZzYzUoTofhmEbaDBAEdAbw.ttf',
    variant: '900',
    family: 'Red Hat Display',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '01ca90b6-a7f7-415e-89d1-febab4eee6fb',
    origin: GF_SOURCE,
    url: GF_URL + 'redhatdisplay/v4/8vIX7wUr0m80wwYf0QCXZzYzUoTg-AYYaKrFIkJQb7zU.ttf',
    variant: '700italic',
    family: 'Red Hat Display',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'a3e32bfc-fa65-4601-9b06-667c401215eb',
    origin: GF_SOURCE,
    url: GF_URL + 'redhatdisplay/v4/8vIV7wUr0m80wwYf0QCXZzYzUoToRhuEbaDBAEdAbw.ttf',
    variant: '700',
    family: 'Red Hat Display',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '732f7fc8-30a2-455e-a9d8-0bcc8681896a',
    origin: GF_SOURCE,
    url: GF_URL + 'redhatdisplay/v4/8vIX7wUr0m80wwYf0QCXZzYzUoTg-AZQbqrFIkJQb7zU.ttf',
    variant: '500italic',
    family: 'Red Hat Display',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '23e30c25-c5cf-4315-9a07-51fa813475b3',
    origin: GF_SOURCE,
    url: GF_URL + 'redhatdisplay/v4/8vIV7wUr0m80wwYf0QCXZzYzUoToDh2EbaDBAEdAbw.ttf',
    variant: '500',
    family: 'Red Hat Display',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '176c130e-8989-411a-836a-190a598390b8',
    origin: GF_SOURCE,
    url: GF_URL + 'redhatdisplay/v4/8vIS7wUr0m80wwYf0QCXZzYzUoTg-D6kR47NCV5Z.ttf',
    variant: 'italic',
    family: 'Red Hat Display',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd7cadb4d-bfdc-4a32-91cd-b4b324f5e990',
    origin: GF_SOURCE,
    url: GF_URL + 'redhatdisplay/v4/8vIQ7wUr0m80wwYf0QCXZzYzUoTQ-jSgZYvdCQ.ttf',
    variant: 'regular',
    family: 'Red Hat Display',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'fde65414-67fd-40a6-b91f-c28ebf84339a',
    origin: GF_SOURCE,
    url: GF_URL + 'tinos/v16/buEzpoGnedXvwjX-Rt1s0CoV_NxLeiw.ttf',
    variant: '700italic',
    family: 'Tinos',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '39a504a3-8f3d-4704-ac20-31c3f7fd0c45',
    origin: GF_SOURCE,
    url: GF_URL + 'tinos/v16/buE1poGnedXvwj1AW0Fp2i43-cxL.ttf',
    variant: '700',
    family: 'Tinos',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'e4fcdb17-9f07-47eb-85f1-2a26fc7f0572',
    origin: GF_SOURCE,
    url: GF_URL + 'tinos/v16/buE2poGnedXvwjX-fmFD9CI-4NU.ttf',
    variant: 'italic',
    family: 'Tinos',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '19180592-5d94-43a4-9ec8-bce292fc98cd',
    origin: GF_SOURCE,
    url: GF_URL + 'tinos/v16/buE4poGnedXvwgX8dGVh8TI-.ttf',
    variant: 'regular',
    family: 'Tinos',
    category: 'serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'a0b32a89-897b-4f49-93ec-fce3f70823a3',
    origin: GF_SOURCE,
    url: GF_URL + 'lobstertwo/v13/BngTUXZGTXPUvIoyV6yN5-fI3hyEwRiof_DpXMY.ttf',
    variant: '700italic',
    family: 'Lobster Two',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '6f5425a8-a467-4536-a903-0390b3088651',
    origin: GF_SOURCE,
    url: GF_URL + 'lobstertwo/v13/BngRUXZGTXPUvIoyV6yN5-92w4CByxyKeuDp.ttf',
    variant: '700',
    family: 'Lobster Two',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '1524b652-4f49-46ed-a0fe-929773cbc711',
    origin: GF_SOURCE,
    url: GF_URL + 'lobstertwo/v13/BngOUXZGTXPUvIoyV6yN5-fI5qCr5RCDY_k.ttf',
    variant: 'italic',
    family: 'Lobster Two',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '773de779-473d-45c9-955c-b2001608021a',
    origin: GF_SOURCE,
    url: GF_URL + 'lobstertwo/v13/BngMUXZGTXPUvIoyV6yN59fK7KSJ4ACD.ttf',
    variant: 'regular',
    family: 'Lobster Two',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'd5918ce1-37b9-4e48-a9cc-d20f6ae4d52b',
    origin: GF_SOURCE,
    url: GF_URL + 'mplus1p/v19/e3tmeuShHdiFyPFzBRrQfBMge0PWovdU4w.ttf',
    variant: '900',
    family: 'M PLUS 1p',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '72c94d2e-fec8-4169-9ef9-95bf54275b69',
    origin: GF_SOURCE,
    url: GF_URL + 'mplus1p/v19/e3tmeuShHdiFyPFzBRrQWBIge0PWovdU4w.ttf',
    variant: '800',
    family: 'M PLUS 1p',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'a9402bb4-abc8-4e03-b045-973c3f703f2a',
    origin: GF_SOURCE,
    url: GF_URL + 'mplus1p/v19/e3tmeuShHdiFyPFzBRrQRBEge0PWovdU4w.ttf',
    variant: '700',
    family: 'M PLUS 1p',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '885cb5b7-12ad-4906-8373-be577dcea9e9',
    origin: GF_SOURCE,
    url: GF_URL + 'mplus1p/v19/e3tmeuShHdiFyPFzBRrQDBcge0PWovdU4w.ttf',
    variant: '500',
    family: 'M PLUS 1p',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'b32267a2-cb57-40a9-8866-7ffa441e4b8c',
    origin: GF_SOURCE,
    url: GF_URL + 'mplus1p/v19/e3tjeuShHdiFyPFzBRro-D4Ec2jKqw.ttf',
    variant: 'regular',
    family: 'M PLUS 1p',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '0ecc4feb-f93b-4a32-b05e-5a0044aa5866',
    origin: GF_SOURCE,
    url: GF_URL + 'mplus1p/v19/e3tmeuShHdiFyPFzBRrQVBYge0PWovdU4w.ttf',
    variant: '300',
    family: 'M PLUS 1p',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'b0cbfa36-2ca2-4ab1-96c4-72dae8cf0c4f',
    origin: GF_SOURCE,
    url: GF_URL + 'mplus1p/v19/e3tleuShHdiFyPFzBRrQnDQAUW3aq-5N.ttf',
    variant: '100',
    family: 'M PLUS 1p',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '04679695-e7b8-45ff-878a-6d10e65d7d87',
    origin: GF_SOURCE,
    url: GF_URL + 'bangers/v13/FeVQS0BTqb0h60ACL5la2bxii28.ttf',
    variant: 'regular',
    family: 'Bangers',
    category: 'display',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '8e0bd519-0451-4db1-abae-9e539c57d47e',
    origin: GF_SOURCE,
    url: GF_URL + 'courgette/v8/wEO_EBrAnc9BLjLQAUkFUfAL3EsHiA.ttf',
    variant: 'regular',
    family: 'Courgette',
    category: 'handwriting',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '0ab3d029-5381-4cdd-ba36-8a451963c36a',
    origin: GF_SOURCE,
    url: GF_URL + 'abeezee/v14/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf',
    variant: 'italic',
    family: 'ABeeZee',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '0fd6a69c-1fb3-4466-af62-8c68a4a43a96',
    origin: GF_SOURCE,
    url: GF_URL + 'abeezee/v14/esDR31xSG-6AGleN6tKukbcHCpE.ttf',
    variant: 'regular',
    family: 'ABeeZee',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'ecff54f6-ea79-4f17-b5c7-2e6594b324a5',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUo9_-1phKLFgshYDvh6Vwt7V9VPEp2jkVHuxKiBA.ttf',
    variant: '900italic',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'fc531c7c-bdda-4f68-895e-630c8f3550bf',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUu9_-1phKLFgshYDvh6Vwt5dlKmE18imdCqxI.ttf',
    variant: '900',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '17e52692-3486-48b1-94f2-431dd22892d8',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUo9_-1phKLFgshYDvh6Vwt7V9VGEt2jkVHuxKiBA.ttf',
    variant: '800italic',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'ec39f9c1-785b-4328-972d-25acd1d3c266',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUu9_-1phKLFgshYDvh6Vwt5f1LmE18imdCqxI.ttf',
    variant: '800',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'e4fe0b07-d856-49d1-a72c-380d3736f4bf',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUo9_-1phKLFgshYDvh6Vwt7V9VBEh2jkVHuxKiBA.ttf',
    variant: '700italic',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '21d158ac-7e97-4df3-9b3c-80cc900d8b1e',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUu9_-1phKLFgshYDvh6Vwt5eFImE18imdCqxI.ttf',
    variant: '700',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'fc797f7e-45b8-4744-b304-4eb2187823de',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUo9_-1phKLFgshYDvh6Vwt7V9VTE52jkVHuxKiBA.ttf',
    variant: '500italic',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '16217481-f8a4-40dc-879e-005871a766dd',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUu9_-1phKLFgshYDvh6Vwt5alOmE18imdCqxI.ttf',
    variant: '500',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '409a8e67-ef64-4b78-8f96-e731bf827c1f',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUt9_-1phKLFgshYDvh6Vwt7V9tuGdShm5bsg.ttf',
    variant: 'italic',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '4027be86-9cdf-4264-b11b-a31274788505',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUz9_-1phKLFgshYDvh6Vwt3V1nvEVXlm4.ttf',
    variant: 'regular',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '3504358e-697a-4537-bf33-b32c99b650ab',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUo9_-1phKLFgshYDvh6Vwt7V9VFE92jkVHuxKiBA.ttf',
    variant: '300italic',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'edb94455-73a6-4018-a1b6-35da63f79fe3',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUu9_-1phKLFgshYDvh6Vwt5fFPmE18imdCqxI.ttf',
    variant: '300',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '42b07ef4-2698-4d79-beaf-9162e52e9aca',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUv9_-1phKLFgshYDvh6Vwt7V9V3G1WpGtLsgu7.ttf',
    variant: '100italic',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '50eda923-0469-442f-9e30-68ea379d0268',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreyasans/v14/5aUt9_-1phKLFgshYDvh6Vwt5TltuGdShm5bsg.ttf',
    variant: '100',
    family: 'Alegreya Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '362483fa-292d-4a74-9acd-f7cdc52ed58b',
    origin: GF_SOURCE,
    url: GF_URL + 'frankruhllibre/v6/j8_36_fAw7jrcalD7oKYNX0QfAnPUzPCxJDMhYeIHw8.ttf',
    variant: '900',
    family: 'Frank Ruhl Libre',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f17f2f83-c0ca-40e4-a006-825779d47fee',
    origin: GF_SOURCE,
    url: GF_URL + 'frankruhllibre/v6/j8_36_fAw7jrcalD7oKYNX0QfAnPUwvAxJDMhYeIHw8.ttf',
    variant: '700',
    family: 'Frank Ruhl Libre',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '1141f0af-59b8-4cad-a459-81dcfca3cc9c',
    origin: GF_SOURCE,
    url: GF_URL + 'frankruhllibre/v6/j8_36_fAw7jrcalD7oKYNX0QfAnPU0PGxJDMhYeIHw8.ttf',
    variant: '500',
    family: 'Frank Ruhl Libre',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '86cb3f57-e72e-492b-a996-002d86a85531',
    origin: GF_SOURCE,
    url: GF_URL + 'frankruhllibre/v6/j8_w6_fAw7jrcalD7oKYNX0QfAnPa7fv4JjnmY4.ttf',
    variant: 'regular',
    family: 'Frank Ruhl Libre',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '96e9fc24-f9a7-4635-a016-45080c086759',
    origin: GF_SOURCE,
    url: GF_URL + 'frankruhllibre/v6/j8_36_fAw7jrcalD7oKYNX0QfAnPUxvHxJDMhYeIHw8.ttf',
    variant: '300',
    family: 'Frank Ruhl Libre',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '7f04594c-d487-4f8e-a2c8-d702661edb8e',
    origin: GF_SOURCE,
    url: GF_URL + 'cinzel/v11/8vIU7ww63mVu7gtR-kwKxNvkNOjw-n_gTYrvDE5ZdqU.ttf',
    variant: '900',
    family: 'Cinzel',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'cc008ebc-aee4-435f-a7a1-eb7a9a02d901',
    origin: GF_SOURCE,
    url: GF_URL + 'cinzel/v11/8vIU7ww63mVu7gtR-kwKxNvkNOjw-lbgTYrvDE5ZdqU.ttf',
    variant: '800',
    family: 'Cinzel',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0b664b84-d1d8-43f8-8106-b09eee013bf0',
    origin: GF_SOURCE,
    url: GF_URL + 'cinzel/v11/8vIU7ww63mVu7gtR-kwKxNvkNOjw-jHgTYrvDE5ZdqU.ttf',
    variant: '700',
    family: 'Cinzel',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8dd388b1-ce45-40cf-8add-256531492a4d',
    origin: GF_SOURCE,
    url: GF_URL + 'cinzel/v11/8vIU7ww63mVu7gtR-kwKxNvkNOjw-gjgTYrvDE5ZdqU.ttf',
    variant: '600',
    family: 'Cinzel',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '192d6b94-471e-48de-b77a-2eba4608c20b',
    origin: GF_SOURCE,
    url: GF_URL + 'cinzel/v11/8vIU7ww63mVu7gtR-kwKxNvkNOjw-uTnTYrvDE5ZdqU.ttf',
    variant: '500',
    family: 'Cinzel',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a4a6960f-4391-4f08-ba7b-05c99507734f',
    origin: GF_SOURCE,
    url: GF_URL + 'cinzel/v11/8vIU7ww63mVu7gtR-kwKxNvkNOjw-tbnTYrvDE5ZdqU.ttf',
    variant: 'regular',
    family: 'Cinzel',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7f36ea1d-7db1-4b10-9dd3-6deaaa741c73',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlZJuKqmkySFr9V9.ttf',
    variant: '900italic',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '3ddc427b-7ab2-442d-9323-f6ae3cc74b4b',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlZguKqmkySFr9V9.ttf',
    variant: '800italic',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '0ec3ceca-10c8-433d-9c5c-12128024efee',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlYHuKqmkySFr9V9.ttf',
    variant: '700italic',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '6139c20b-a7ae-47b1-8281-a2f7c7b36fce',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlY-uKqmkySFr9V9.ttf',
    variant: '600italic',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '2e10bc36-2c2b-4781-95f9-84e0f17f1980',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlbSv6qmkySFr9V9.ttf',
    variant: '500italic',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '583516f2-b4f2-47a6-bf43-514816a437b7',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlbgv6qmkySFr9V9.ttf',
    variant: 'italic',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'c4d4a325-549c-4d23-8102-3920674dda4c',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGXxII_KCisSGVrw.ttf',
    variant: '900',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '8caaf46b-2966-468b-8a1f-59d90d24256c',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGdhII_KCisSGVrw.ttf',
    variant: '800',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '3eeabbd0-20cb-45ef-96f0-c4167b571535',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGERII_KCisSGVrw.ttf',
    variant: '700',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'c08a5edc-db23-4769-88cb-3c669973befa',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGKBII_KCisSGVrw.ttf',
    variant: '600',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'c6c79337-dd94-476a-b889-a98852e43f74',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGxBUI_KCisSGVrw.ttf',
    variant: '500',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '9dc3beea-aa22-40ac-a638-0147d2973509',
    origin: GF_SOURCE,
    url: GF_URL + 'alegreya/v19/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNG9hUI_KCisSGVrw.ttf',
    variant: 'regular',
    family: 'Alegreya',
    category: 'serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'f6b9dc50-e562-44d4-9426-e6f8af543c96',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIrB2sgqZiGfHK5.ttf',
    variant: '900italic',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a9b36972-4cd0-47ae-95e8-8cd7588af538',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf-62PAGEki52WfA.ttf',
    variant: '900',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c73a8647-60ae-4333-a76d-2b2aba26fb17',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIPBmsgqZiGfHK5.ttf',
    variant: '800italic',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bfd4fe35-2ee8-4be2-bda1-27a8e82dde59',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf36yPAGEki52WfA.ttf',
    variant: '800',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '499d8b17-8485-4b4e-8baf-5ccefff7d16d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbITBWsgqZiGfHK5.ttf',
    variant: '700italic',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '3a50d803-b037-42a3-a48c-adc328d14653',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfw6-PAGEki52WfA.ttf',
    variant: '700',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bef78b3d-2883-4939-9423-5bb0a8bbf054',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJ3BGsgqZiGfHK5.ttf',
    variant: '600italic',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6c6bd671-83f9-4d34-8c28-a274751506f3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66PAGEki52WfA.ttf',
    variant: '600',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8b030062-4229-4f3a-9010-39eb2340468b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJbA2sgqZiGfHK5.ttf',
    variant: '500italic',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '3e4831c2-51e9-494d-bb74-03e506936b62',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfi6mPAGEki52WfA.ttf',
    variant: '500',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '71acf38f-b1fd-42a6-8de7-89d81b5100d6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfYqvKk8ogoSP.ttf',
    variant: 'italic',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '2be239e7-81ce-4ed9-b1e0-9930fd7eeb28',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRnf4CrCEo4gg.ttf',
    variant: 'regular',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '9a9dfeab-0fce-4cc4-a5d2-de37fd59a498',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIDAmsgqZiGfHK5.ttf',
    variant: '300italic',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ff34d564-d5e9-4e4a-9e2c-830b65ef3f96',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf06iPAGEki52WfA.ttf',
    variant: '300',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'bdc67ba8-ca2d-459d-8424-129dba8bbd9a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJnAWsgqZiGfHK5.ttf',
    variant: '200italic',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a639a974-bf8c-47b4-8dd3-6eb3b3d49ca0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRft6uPAGEki52WfA.ttf',
    variant: '200',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '3b2e4813-ba46-4e64-845a-6ab25980a2ed',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlpjgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbLLIEsKh5SPZWs.ttf',
    variant: '100italic',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'b6cc6d12-c7be-432f-a4d5-261ba82d9db8',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'barlowsemicondensed/v6/wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfG4qvKk8ogoSP.ttf',
    variant: '100',
    family: 'Barlow Semi Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'fbcee4f2-7ef4-49e1-bbc2-1bd2a8013db9',
    origin: GF_SOURCE,
    url: GF_URL + 'patuaone/v11/ZXuke1cDvLCKLDcimxBI5PNvNA9LuA.ttf',
    variant: 'regular',
    family: 'Patua One',
    category: 'display',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '931bac84-ff9c-4bc9-816a-a35d8771a102',
    origin: GF_SOURCE,
    url: GF_URL + 'monda/v11/TK3gWkYFABsmjsLaGz8Dl-tPKo2t.ttf',
    variant: '700',
    family: 'Monda',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '4a23f900-28da-4bd0-b3eb-b89b06b22a6e',
    origin: GF_SOURCE,
    url: GF_URL + 'monda/v11/TK3tWkYFABsmjvpmNBsLvPdG.ttf',
    variant: 'regular',
    family: 'Monda',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '1cdba2ff-cd31-4d7b-9bb6-2c45895fd248',
    origin: GF_SOURCE,
    url: GF_URL + 'sairacondensed/v6/EJRLQgErUN8XuHNEtX81i9TmEkrnIcxg8Keepi2lHw.ttf',
    variant: '900',
    family: 'Saira Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f54c6e73-629f-4c57-948c-6429393826c2',
    origin: GF_SOURCE,
    url: GF_URL + 'sairacondensed/v6/EJRLQgErUN8XuHNEtX81i9TmEkrnBc1g8Keepi2lHw.ttf',
    variant: '800',
    family: 'Saira Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '1dedf3e1-9f29-4210-9db3-b200835265c2',
    origin: GF_SOURCE,
    url: GF_URL + 'sairacondensed/v6/EJRLQgErUN8XuHNEtX81i9TmEkrnGc5g8Keepi2lHw.ttf',
    variant: '700',
    family: 'Saira Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '03040b25-fd68-4b3c-af40-0d34b4762e5e',
    origin: GF_SOURCE,
    url: GF_URL + 'sairacondensed/v6/EJRLQgErUN8XuHNEtX81i9TmEkrnfc9g8Keepi2lHw.ttf',
    variant: '600',
    family: 'Saira Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '86578b51-afd8-4c22-b86c-8882fd57e2a2',
    origin: GF_SOURCE,
    url: GF_URL + 'sairacondensed/v6/EJRLQgErUN8XuHNEtX81i9TmEkrnUchg8Keepi2lHw.ttf',
    variant: '500',
    family: 'Saira Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd7f7a92c-b13e-4af2-8f02-1e9365371965',
    origin: GF_SOURCE,
    url: GF_URL + 'sairacondensed/v6/EJROQgErUN8XuHNEtX81i9TmEkrfpeFE-IyCrw.ttf',
    variant: 'regular',
    family: 'Saira Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f450b36c-acee-4053-9ea0-78594de680fb',
    origin: GF_SOURCE,
    url: GF_URL + 'sairacondensed/v6/EJRLQgErUN8XuHNEtX81i9TmEkrnCclg8Keepi2lHw.ttf',
    variant: '300',
    family: 'Saira Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '7f468afd-4afb-4175-a57c-4e5dc6757c61',
    origin: GF_SOURCE,
    url: GF_URL + 'sairacondensed/v6/EJRLQgErUN8XuHNEtX81i9TmEkrnbcpg8Keepi2lHw.ttf',
    variant: '200',
    family: 'Saira Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c4f67eec-0665-46ce-8881-60acb7d9f88d',
    origin: GF_SOURCE,
    url: GF_URL + 'sairacondensed/v6/EJRMQgErUN8XuHNEtX81i9TmEkrnwetA2omSrzS8.ttf',
    variant: '100',
    family: 'Saira Condensed',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ff7e2f49-836f-44a3-a551-d3196b4bf073',
    origin: GF_SOURCE,
    url: GF_URL + 'mplusrounded1c/v10/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0C45sKxeqmzgRK.ttf',
    variant: '900',
    family: 'M PLUS Rounded 1c',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8790bc85-6375-442b-b15e-47d4370dcefd',
    origin: GF_SOURCE,
    url: GF_URL + 'mplusrounded1c/v10/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0m4psKxeqmzgRK.ttf',
    variant: '800',
    family: 'M PLUS Rounded 1c',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8d08a439-7bf6-4ea2-8de2-8fb5dbd4dda2',
    origin: GF_SOURCE,
    url: GF_URL + 'mplusrounded1c/v10/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM064ZsKxeqmzgRK.ttf',
    variant: '700',
    family: 'M PLUS Rounded 1c',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8961f62e-f0bb-432e-be96-dcab004f7bb7',
    origin: GF_SOURCE,
    url: GF_URL + 'mplusrounded1c/v10/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM1y55sKxeqmzgRK.ttf',
    variant: '500',
    family: 'M PLUS Rounded 1c',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '3ec9b8c9-6ef1-4970-b371-76a1eac88d88',
    origin: GF_SOURCE,
    url: GF_URL + 'mplusrounded1c/v10/VdGEAYIAV6gnpUpoWwNkYvrugw9RuPWGzr8C7vav.ttf',
    variant: 'regular',
    family: 'M PLUS Rounded 1c',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '1498c040-3bd1-4f81-885e-34f6c80efe07',
    origin: GF_SOURCE,
    url: GF_URL + 'mplusrounded1c/v10/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0q5psKxeqmzgRK.ttf',
    variant: '300',
    family: 'M PLUS Rounded 1c',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '299c680c-6e17-49a6-aa80-cf5c54f5d947',
    origin: GF_SOURCE,
    url: GF_URL + 'mplusrounded1c/v10/VdGCAYIAV6gnpUpoWwNkYvrugw9RuM3ixLsg6-av1x0.ttf',
    variant: '100',
    family: 'M PLUS Rounded 1c',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'dfdf7301-c562-4b7e-a74e-f170b1429b51',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntucondensed/v11/u-4k0rCzjgs5J7oXnJcM_0kACGMtf-fVqvHoJXw.ttf',
    variant: 'regular',
    family: 'Ubuntu Condensed',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'a02016ef-d83e-4dd0-9ac2-e496c990e4e9',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DLvXWmZM7Xq34g9.ttf',
    variant: '900italic',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'f2db7c2f-c7e7-430f-b970-53a8b0a76f28',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DLGXWmZM7Xq34g9.ttf',
    variant: '800italic',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'f6cd2cf9-a04e-4f37-86f5-b060d55179a8',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DKhXWmZM7Xq34g9.ttf',
    variant: '700italic',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '2f3bfb0b-512e-4957-926c-c3babfd9dd8e',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DKYXWmZM7Xq34g9.ttf',
    variant: '600italic',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '37b8338c-7e0c-4c9e-a4e6-7eed98355bec',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DJ0WmmZM7Xq34g9.ttf',
    variant: '500italic',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '18b35f0b-123f-4eba-a130-9cdad07212a5',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DJGWmmZM7Xq34g9.ttf',
    variant: 'italic',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '939aba61-04cb-49cf-bbc6-179915d76adb',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2mXauGWOdEbD63w.ttf',
    variant: '900',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'd66e7313-ded2-4f52-815a-2da77484a9f9',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2sHauGWOdEbD63w.ttf',
    variant: '800',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '8067d779-71eb-4ded-ba19-34350ce988b0',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df213auGWOdEbD63w.ttf',
    variant: '700',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '006010ea-c8aa-462e-8843-eac6e7a8f589',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df27nauGWOdEbD63w.ttf',
    variant: '600',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '3a410ef7-ab67-46e0-9d7d-96a1c7a0eeca',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2AnGuGWOdEbD63w.ttf',
    variant: '500',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '5dfa8b81-c6ed-49a2-b9a4-6e1b06cdf0ae',
    origin: GF_SOURCE,
    url: GF_URL + 'vollkorn/v13/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2MHGuGWOdEbD63w.ttf',
    variant: 'regular',
    family: 'Vollkorn',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'c1b97734-9c86-4663-9c70-4370a8e5ef72',
    origin: GF_SOURCE,
    url: GF_URL + 'amiri/v17/J7aanpd8CGxBHpUrjAo9zptgHjAavCA.ttf',
    variant: '700italic',
    family: 'Amiri',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '2af890a7-9ed7-4a54-a8cd-01c1cac9e01f',
    origin: GF_SOURCE,
    url: GF_URL + 'amiri/v17/J7acnpd8CGxBHp2VkZY4xJ9CGyAa.ttf',
    variant: '700',
    family: 'Amiri',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'f55f5fff-5258-4056-a620-9e9c16be0a30',
    origin: GF_SOURCE,
    url: GF_URL + 'amiri/v17/J7afnpd8CGxBHpUrtLYS6pNLAjk.ttf',
    variant: 'italic',
    family: 'Amiri',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '909970a1-5972-4c8d-91bb-7b4d373a23b8',
    origin: GF_SOURCE,
    url: GF_URL + 'amiri/v17/J7aRnpd8CGxBHqUpvrIw74NL.ttf',
    variant: 'regular',
    family: 'Amiri',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '2dd57bb8-0a7e-410b-a1ec-5aa520637769',
    origin: GF_SOURCE,
    url: GF_URL + 'ptsanscaption/v13/0FlJVP6Hrxmt7-fsUFhlFXNIlpcSwSrUSwWuz38Tgg.ttf',
    variant: '700',
    family: 'PT Sans Caption',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '42d9c71e-3beb-40bd-bb93-0eff156b6c05',
    origin: GF_SOURCE,
    url: GF_URL + 'ptsanscaption/v13/0FlMVP6Hrxmt7-fsUFhlFXNIlpcqfQXwQy6yxg.ttf',
    variant: 'regular',
    family: 'PT Sans Caption',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'e2d56001-d877-45a7-972d-d8d9b42378ea',
    origin: GF_SOURCE,
    url: GF_URL + 'creteround/v9/55xqey1sJNPjPiv1ZZZrxK1-0bjiL2xNhKc.ttf',
    variant: 'italic',
    family: 'Crete Round',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '5e64aaa7-99a4-4f80-8144-a6b08355ad10',
    origin: GF_SOURCE,
    url: GF_URL + 'creteround/v9/55xoey1sJNPjPiv1ZZZrxJ1827zAKnxN.ttf',
    variant: 'regular',
    family: 'Crete Round',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '38635969-008d-468b-9d23-ef8745088dad',
    origin: GF_SOURCE,
    url: GF_URL + 'satisfy/v11/rP2Hp2yn6lkG50LoOZSCHBeHFl0.ttf',
    variant: 'regular',
    family: 'Satisfy',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '416d98e9-c718-4ad8-93b6-d0428842efd6',
    origin: GF_SOURCE,
    url: GF_URL + 'manrope/v4/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F87jxeN7B.ttf',
    variant: '800',
    family: 'Manrope',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c5d007ea-ee33-4738-880a-67cf6c0cf421',
    origin: GF_SOURCE,
    url: GF_URL + 'manrope/v4/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE-_F87jxeN7B.ttf',
    variant: '700',
    family: 'Manrope',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '85c8cc72-9646-4e57-b582-07d8215dee30',
    origin: GF_SOURCE,
    url: GF_URL + 'manrope/v4/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F87jxeN7B.ttf',
    variant: '600',
    family: 'Manrope',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '21d1c6c2-09a9-4b2e-9f96-9e1223c8bb65',
    origin: GF_SOURCE,
    url: GF_URL + 'manrope/v4/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_F87jxeN7B.ttf',
    variant: '500',
    family: 'Manrope',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '92b40fe1-3599-460e-ac51-708d88c4b6d8',
    origin: GF_SOURCE,
    url: GF_URL + 'manrope/v4/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F87jxeN7B.ttf',
    variant: 'regular',
    family: 'Manrope',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'b33da6b3-927c-47af-b8f9-912c2f9133f4',
    origin: GF_SOURCE,
    url: GF_URL + 'manrope/v4/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFO_F87jxeN7B.ttf',
    variant: '300',
    family: 'Manrope',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '94db16fd-eb47-4a4e-850a-0c6da9aa50e4',
    origin: GF_SOURCE,
    url: GF_URL + 'manrope/v4/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FO_F87jxeN7B.ttf',
    variant: '200',
    family: 'Manrope',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '8d2530d6-9d4c-44f5-9133-e0e976411315',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HAotBdsBU7iVdxQ.ttf',
    variant: '900italic',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '584ea52b-f6ee-41e3-be6c-1bb77cff7494',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HABtBdsBU7iVdxQ.ttf',
    variant: '800italic',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '3909354e-fd9c-4044-b56f-e628fa59a051',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HBmtBdsBU7iVdxQ.ttf',
    variant: '700italic',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '676d8010-cc95-4a2d-91a3-de6ba70467ff',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HBftBdsBU7iVdxQ.ttf',
    variant: '600italic',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '13478b96-55a0-441a-a3a7-6df0ce492fa0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HCzsxdsBU7iVdxQ.ttf',
    variant: '500italic',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '46a563dc-c7b4-4031-9efd-3dedcd842ad7',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HCBsxdsBU7iVdxQ.ttf',
    variant: 'italic',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b3f838f9-2476-4ed2-a2db-22e330b2ab6b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HDfsxdsBU7iVdxQ.ttf',
    variant: '300italic',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '4a11f453-ebb5-4393-8e06-8a94f7054920',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HABsxdsBU7iVdxQ.ttf',
    variant: '200italic',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '12359b26-6ac1-473a-82ca-152195eef0fc',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HCBshdsBU7iVdxQ.ttf',
    variant: '100italic',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'a4cdf535-5c51-403a-ac07-e932bdc9cf26',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTnTRp8B1oJ0vyVQ.ttf',
    variant: '900',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'bd0def1c-8c96-4a0a-b35c-05a2bafbac09',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTtDRp8B1oJ0vyVQ.ttf',
    variant: '800',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '9f7f8a3c-47a7-41b8-b36a-ddcc5f01f1b0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTT0zRp8B1oJ0vyVQ.ttf',
    variant: '700',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'a16e4fd1-49aa-4a46-a005-0de7dc723346',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTT6jRp8B1oJ0vyVQ.ttf',
    variant: '600',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e3682962-9677-4178-bf6c-527f77894553',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTBjNp8B1oJ0vyVQ.ttf',
    variant: '500',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '9f1636a3-37f2-4df3-a8db-bfbfcc95de70',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDNp8B1oJ0vyVQ.ttf',
    variant: 'regular',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '9cc136e3-0d30-4d12-86fe-5a2d7939d782',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTajNp8B1oJ0vyVQ.ttf',
    variant: '300',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e12f6772-f79e-4450-9ef1-e787ef396b13',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTtDNp8B1oJ0vyVQ.ttf',
    variant: '200',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '244a05d8-a39c-4a49-8a5f-def1c39a6ddb',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'archivo/v8/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDJp8B1oJ0vyVQ.ttf',
    variant: '100',
    family: 'Archivo',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'fd778c72-a976-4923-8a70-87b7b75232c0',
    origin: GF_SOURCE,
    url: GF_URL + 'balsamiqsans/v3/P5sfzZiAbNrN8SB3lQQX7PncwdsvmYpsBxDAVQI4aA.ttf',
    variant: '700italic',
    family: 'Balsamiq Sans',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'c95d5461-e682-44a6-a659-25d20c5c77a2',
    origin: GF_SOURCE,
    url: GF_URL + 'balsamiqsans/v3/P5sZzZiAbNrN8SB3lQQX7PncyWUyBY9mAzLFRQI.ttf',
    variant: '700',
    family: 'Balsamiq Sans',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'f7e31618-7052-4ea8-9c13-5c6857820935',
    origin: GF_SOURCE,
    url: GF_URL + 'balsamiqsans/v3/P5sazZiAbNrN8SB3lQQX7PncwdsXJaVIDzvcXA.ttf',
    variant: 'italic',
    family: 'Balsamiq Sans',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '0bfdbac8-dc34-46ed-bd70-028760b3dbea',
    origin: GF_SOURCE,
    url: GF_URL + 'balsamiqsans/v3/P5sEzZiAbNrN8SB3lQQX7Pnc8dkdIYdNHzs.ttf',
    variant: 'regular',
    family: 'Balsamiq Sans',
    category: 'display',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'd8a3eed5-221b-4675-b640-7d0d3b173c2d',
    origin: GF_SOURCE,
    url: GF_URL + 'righteous/v9/1cXxaUPXBpj2rGoU7C9mj3uEicG01A.ttf',
    variant: 'regular',
    family: 'Righteous',
    category: 'display',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '200b950d-aeed-4ef2-9e28-eba9d3df309b',
    origin: GF_SOURCE,
    url: GF_URL + 'alfaslabone/v10/6NUQ8FmMKwSEKjnm5-4v-4Jh6dVretWvYmE.ttf',
    variant: 'regular',
    family: 'Alfa Slab One',
    category: 'display',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '46f0cdc2-7b1a-41be-a726-d2840ded5db3',
    origin: GF_SOURCE,
    url: GF_URL + 'signika/v12/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tKwG5bGhs_cfKe1.ttf',
    variant: '700',
    family: 'Signika',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'dbafd1e9-bdf1-469e-b359-29b6a3dad557',
    origin: GF_SOURCE,
    url: GF_URL + 'signika/v12/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tKJG5bGhs_cfKe1.ttf',
    variant: '600',
    family: 'Signika',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'af357956-8a8e-4058-bcdf-066617163a42',
    origin: GF_SOURCE,
    url: GF_URL + 'signika/v12/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tJlHJbGhs_cfKe1.ttf',
    variant: '500',
    family: 'Signika',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '14d37848-a336-42d1-988b-66fa1920d0de',
    origin: GF_SOURCE,
    url: GF_URL + 'signika/v12/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tJXHJbGhs_cfKe1.ttf',
    variant: 'regular',
    family: 'Signika',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '3b0080f1-8c5b-4578-854d-e4d5da41e1dd',
    origin: GF_SOURCE,
    url: GF_URL + 'signika/v12/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tIJHJbGhs_cfKe1.ttf',
    variant: '300',
    family: 'Signika',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '51709ba3-193e-4160-a8e1-119203c3f6ac',
    origin: GF_SOURCE,
    url: GF_URL + 'gloriahallelujah/v12/LYjYdHv3kUk9BMV96EIswT9DIbW-MLSy3TKEvkCF.ttf',
    variant: 'regular',
    family: 'Gloria Hallelujah',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'e0b60ce0-f3b0-4945-b706-d1efc94ca314',
    origin: GF_SOURCE,
    url: GF_URL + 'nanummyeongjo/v15/9Bty3DZF0dXLMZlywRbVRNhxy2pLVFA0pfCs5Kos.ttf',
    variant: '800',
    family: 'Nanum Myeongjo',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '8c5b8c90-f540-47cf-81f1-8f8d94d71c0e',
    origin: GF_SOURCE,
    url: GF_URL + 'nanummyeongjo/v15/9Bty3DZF0dXLMZlywRbVRNhxy2pXV1A0pfCs5Kos.ttf',
    variant: '700',
    family: 'Nanum Myeongjo',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '37062d60-d258-47ee-b047-b7aeee2e77c8',
    origin: GF_SOURCE,
    url: GF_URL + 'nanummyeongjo/v15/9Btx3DZF0dXLMZlywRbVRNhxy1LreHQ8juyl.ttf',
    variant: 'regular',
    family: 'Nanum Myeongjo',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'd4690433-d726-42ef-a729-9ee9b6e4df3a',
    origin: GF_SOURCE,
    url: GF_URL + 'amaticsc/v16/TUZ3zwprpvBS1izr_vOMscG6eb8D3WTy-A.ttf',
    variant: '700',
    family: 'Amatic SC',
    category: 'handwriting',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '92de8262-55e3-4416-a61b-967d65c42724',
    origin: GF_SOURCE,
    url: GF_URL + 'amaticsc/v16/TUZyzwprpvBS1izr_vO0De6ecZQf1A.ttf',
    variant: 'regular',
    family: 'Amatic SC',
    category: 'handwriting',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '37988231-c1d6-41a6-abfe-8c1ba4d9dddb',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizGREVNn1dOx-zrZ2X3pZvkTiUa4442q17jjNOg_oc.ttf',
    variant: '700italic',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '9dbbddff-980d-4f37-97d0-73b3bda5c314',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizAREVNn1dOx-zrZ2X3pZvkTi2k_hIzoVrBicOg.ttf',
    variant: '700',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'a3f89e88-9e86-41f1-af97-1e7969edc877',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizGREVNn1dOx-zrZ2X3pZvkTiUa4-o3q17jjNOg_oc.ttf',
    variant: '600italic',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '66d70c53-558e-4d8e-8751-0e6c280a4409',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizAREVNn1dOx-zrZ2X3pZvkTi3A_xIzoVrBicOg.ttf',
    variant: '600',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'aca68858-bf63-44fb-8af9-68ce2ab17b94',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizGREVNn1dOx-zrZ2X3pZvkTiUa48Ywq17jjNOg_oc.ttf',
    variant: '500italic',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '1eeb5acd-e57b-44ba-9397-ad4698f9e12d',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizAREVNn1dOx-zrZ2X3pZvkTi3s-BIzoVrBicOg.ttf',
    variant: '500',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'c0828dc3-1adf-491b-9fb6-085d2030f3e5',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizBREVNn1dOx-zrZ2X3pZvkTiUa2zIZj1bIkNo.ttf',
    variant: 'italic',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'cabf455d-947c-4aff-84b5-fde35bdf85ca',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizDREVNn1dOx-zrZ2X3pZvkThUY0TY7ikbI.ttf',
    variant: 'regular',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'c0318459-49f3-45c3-9ffb-7e9fbe396e0d',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizGREVNn1dOx-zrZ2X3pZvkTiUa454xq17jjNOg_oc.ttf',
    variant: '300italic',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '544a2b75-6685-4da7-b50d-4184b791f392',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizAREVNn1dOx-zrZ2X3pZvkTi20-RIzoVrBicOg.ttf',
    variant: '300',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '388760d0-0e4e-4769-a079-6b74d10b3569',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizGREVNn1dOx-zrZ2X3pZvkTiUa4_oyq17jjNOg_oc.ttf',
    variant: '200italic',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '9176cfcb-69b0-401c-87b4-6b9d33d1305b',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizAREVNn1dOx-zrZ2X3pZvkTi3Q-hIzoVrBicOg.ttf',
    variant: '200',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'b1f85224-4158-4454-b521-7f13ed30d4fd',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizHREVNn1dOx-zrZ2X3pZvkTiUa41YTi3TNgNq55w.ttf',
    variant: '100italic',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'bb2a5a87-db4f-4cda-85da-786805821017',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexserif/v9/jizBREVNn1dOx-zrZ2X3pZvkTi182zIZj1bIkNo.ttf',
    variant: '100',
    family: 'IBM Plex Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '810f9c2d-24ad-4cd3-ae42-93d1d2881733',
    origin: GF_SOURCE,
    url: GF_URL + 'breeserif/v10/4UaHrEJCrhhnVA3DgluAx63j5pN1MwI.ttf',
    variant: 'regular',
    family: 'Bree Serif',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'dcfcf732-02bc-4dfc-8379-1af0294d4b74',
    origin: GF_SOURCE,
    url: GF_URL + 'permanentmarker/v10/Fh4uPib9Iyv2ucM6pGQMWimMp004HaqIfrT5nlk.ttf',
    variant: 'regular',
    family: 'Permanent Marker',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a6a6123a-6090-429d-8f0f-1ab01ec3f089',
    origin: GF_SOURCE,
    url: GF_URL + 'tajawal/v4/Iurf6YBj_oCad4k1l7KmLrZjiLlJ-G0.ttf',
    variant: '900',
    family: 'Tajawal',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '939d40e9-08b9-498c-b776-39db04a851bf',
    origin: GF_SOURCE,
    url: GF_URL + 'tajawal/v4/Iurf6YBj_oCad4k1l5anLrZjiLlJ-G0.ttf',
    variant: '800',
    family: 'Tajawal',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'c5660340-fb82-4a35-82ce-40c544a5d6b9',
    origin: GF_SOURCE,
    url: GF_URL + 'tajawal/v4/Iurf6YBj_oCad4k1l4qkLrZjiLlJ-G0.ttf',
    variant: '700',
    family: 'Tajawal',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '2e455f77-12c7-40db-8e36-5d6b139d49cf',
    origin: GF_SOURCE,
    url: GF_URL + 'tajawal/v4/Iurf6YBj_oCad4k1l8KiLrZjiLlJ-G0.ttf',
    variant: '500',
    family: 'Tajawal',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '5be4c099-9de3-4bc8-851c-1dfd9eec367d',
    origin: GF_SOURCE,
    url: GF_URL + 'tajawal/v4/Iura6YBj_oCad4k1rzaLCr5IlLA.ttf',
    variant: 'regular',
    family: 'Tajawal',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: 'd40a5d52-c353-4864-955e-08ea87e1c44c',
    origin: GF_SOURCE,
    url: GF_URL + 'tajawal/v4/Iurf6YBj_oCad4k1l5qjLrZjiLlJ-G0.ttf',
    variant: '300',
    family: 'Tajawal',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '9470a3ac-458e-4a10-b4dc-883ea518997c',
    origin: GF_SOURCE,
    url: GF_URL + 'tajawal/v4/Iurf6YBj_oCad4k1l_6gLrZjiLlJ-G0.ttf',
    variant: '200',
    family: 'Tajawal',
    category: 'sans-serif',
    version: 'v4',
    subset: 'latin',
  },
  {
    id: '394d99ee-02d8-4184-b03a-5938b4e087de',
    origin: GF_SOURCE,
    url: GF_URL + 'caveat/v10/WnznHAc5bAfYB2QRah7pcpNvOx-pjRV6SIKjYBxPigs.ttf',
    variant: '700',
    family: 'Caveat',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a5a9f8ac-213e-4cdc-b6ae-a8738531c3f9',
    origin: GF_SOURCE,
    url: GF_URL + 'caveat/v10/WnznHAc5bAfYB2QRah7pcpNvOx-pjSx6SIKjYBxPigs.ttf',
    variant: '600',
    family: 'Caveat',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '1db87489-f01f-4652-9487-62a756c42eb5',
    origin: GF_SOURCE,
    url: GF_URL + 'caveat/v10/WnznHAc5bAfYB2QRah7pcpNvOx-pjcB9SIKjYBxPigs.ttf',
    variant: '500',
    family: 'Caveat',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '858de5c0-10f4-4fd3-8d69-fa8ac23ff3b6',
    origin: GF_SOURCE,
    url: GF_URL + 'caveat/v10/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9SIKjYBxPigs.ttf',
    variant: 'regular',
    family: 'Caveat',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '604e5218-699d-4f08-8ee1-4e2e2ab7201d',
    origin: GF_SOURCE,
    url: GF_URL + 'fredokaone/v8/k3kUo8kEI-tA1RRcTZGmTmHBA6aF8Bf_.ttf',
    variant: 'regular',
    family: 'Fredoka One',
    category: 'display',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '626f37ab-f7ea-4bdb-b6fc-0a8104fb360f',
    origin: GF_SOURCE,
    url: GF_URL + 'catamaran/v8/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPNHa1anXuluiLyw.ttf',
    variant: '900',
    family: 'Catamaran',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '4cb9c819-e2d7-4524-a199-a6761fe893fe',
    origin: GF_SOURCE,
    url: GF_URL + 'catamaran/v8/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPPja1anXuluiLyw.ttf',
    variant: '800',
    family: 'Catamaran',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b0717c7d-1aa9-4be9-83ca-f5c57990bd99',
    origin: GF_SOURCE,
    url: GF_URL + 'catamaran/v8/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPJ_a1anXuluiLyw.ttf',
    variant: '700',
    family: 'Catamaran',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '5576ffc5-a452-4441-8b59-71994df047db',
    origin: GF_SOURCE,
    url: GF_URL + 'catamaran/v8/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPKba1anXuluiLyw.ttf',
    variant: '600',
    family: 'Catamaran',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '5da3b409-9b89-41b2-a607-24da7e8206bd',
    origin: GF_SOURCE,
    url: GF_URL + 'catamaran/v8/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPErd1anXuluiLyw.ttf',
    variant: '500',
    family: 'Catamaran',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'b06e55b2-9d9a-4f5c-8504-4901d5f88d4d',
    origin: GF_SOURCE,
    url: GF_URL + 'catamaran/v8/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjd1anXuluiLyw.ttf',
    variant: 'regular',
    family: 'Catamaran',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd3b3625b-976d-4579-8015-ad49b95b631e',
    origin: GF_SOURCE,
    url: GF_URL + 'catamaran/v8/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPCbd1anXuluiLyw.ttf',
    variant: '300',
    family: 'Catamaran',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '7b8bca21-56fb-4afe-b970-55dd9c2f597b',
    origin: GF_SOURCE,
    url: GF_URL + 'catamaran/v8/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPPjd1anXuluiLyw.ttf',
    variant: '200',
    family: 'Catamaran',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c63ca816-266f-4abf-9d33-1d4eb6d53ba2',
    origin: GF_SOURCE,
    url: GF_URL + 'catamaran/v8/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjc1anXuluiLyw.ttf',
    variant: '100',
    family: 'Catamaran',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd6987f30-8e03-4827-bc82-7682aa59a53c',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UafrEtFpBISdmSt-MY2ehbO95t0SkdmPnws9Iu-uA.ttf',
    variant: '900italic',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '22621fcb-bf54-4ebc-9ce9-fba44e84b119',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UafrEtFpBISdmSt-MY2ehbO95t0Y0dmPnws9Iu-uA.ttf',
    variant: '800italic',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '40ef0f49-4446-4066-91e9-2edb5a0aea97',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UafrEtFpBISdmSt-MY2ehbO95t0BEdmPnws9Iu-uA.ttf',
    variant: '700italic',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'fbfb939e-e124-4e15-8bc6-fe34301bc86a',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UafrEtFpBISdmSt-MY2ehbO95t0PUdmPnws9Iu-uA.ttf',
    variant: '600italic',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'e4aa9ff7-a289-4177-a436-5516e0116437',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UafrEtFpBISdmSt-MY2ehbO95t00UBmPnws9Iu-uA.ttf',
    variant: '500italic',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '88cef889-24de-4cd8-bcba-4143e1916127',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UafrEtFpBISdmSt-MY2ehbO95t040BmPnws9Iu-uA.ttf',
    variant: 'italic',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c01b6b81-5ba3-462a-b868-577af2610d9a',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UafrEtFpBISdmSt-MY2ehbO95t0vUBmPnws9Iu-uA.ttf',
    variant: '300italic',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'be124ad4-1929-4b14-b386-6e3ec75a2102',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UafrEtFpBISdmSt-MY2ehbO95t0Y0BmPnws9Iu-uA.ttf',
    variant: '200italic',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2e3bce46-8d07-4c8e-a5e3-135639ff17eb',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UafrEtFpBISdmSt-MY2ehbO95t040FmPnws9Iu-uA.ttf',
    variant: '100italic',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'f6806007-da0d-4a93-94fa-81a6cc7ba5e7',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UaZrEtFpBI4f1ZSIK9d4LjJ4vowCwNsOl4p5Is.ttf',
    variant: '900',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '501e9a0a-14b6-4097-8ac9-d47830282a31',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UaZrEtFpBI4f1ZSIK9d4LjJ4tMwCwNsOl4p5Is.ttf',
    variant: '800',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '9ca2804c-c60b-4c27-b6ff-9ed2d8fb2ded',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UaZrEtFpBI4f1ZSIK9d4LjJ4rQwCwNsOl4p5Is.ttf',
    variant: '700',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2739583b-b39f-41b7-9c7e-f130f1e66a63',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UaZrEtFpBI4f1ZSIK9d4LjJ4o0wCwNsOl4p5Is.ttf',
    variant: '600',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'f21916b3-7917-4b61-8c05-4f27d0a3e752',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UaZrEtFpBI4f1ZSIK9d4LjJ4mE3CwNsOl4p5Is.ttf',
    variant: '500',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2889cac4-6ca7-4fc3-a030-883e3d3fca1b',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3CwNsOl4p5Is.ttf',
    variant: 'regular',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '5c817113-fa1e-43bf-8390-498c6eb4125e',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UaZrEtFpBI4f1ZSIK9d4LjJ4g03CwNsOl4p5Is.ttf',
    variant: '300',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '1b5489c3-b2d1-4b0c-a88f-a2873b675be8',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UaZrEtFpBI4f1ZSIK9d4LjJ4tM3CwNsOl4p5Is.ttf',
    variant: '200',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'cc7b34d0-480e-406b-9963-f23e3629c0ff',
    origin: GF_SOURCE,
    url: GF_URL + 'exo/v12/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM2CwNsOl4p5Is.ttf',
    variant: '100',
    family: 'Exo',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'd58d3b9f-e223-4673-b1c9-ff3f6ac856df',
    origin: GF_SOURCE,
    url: GF_URL + 'domine/v11/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X5XHI10VErGuW8Q.ttf',
    variant: '700',
    family: 'Domine',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '09fc582f-a897-4225-aaf7-609461e1ba03',
    origin: GF_SOURCE,
    url: GF_URL + 'domine/v11/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X6zHI10VErGuW8Q.ttf',
    variant: '600',
    family: 'Domine',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'e906ee83-ccbf-49bb-a88c-fb2dba670743',
    origin: GF_SOURCE,
    url: GF_URL + 'domine/v11/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X0DAI10VErGuW8Q.ttf',
    variant: '500',
    family: 'Domine',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '85534c34-7fb0-4428-b72d-c22134211a24',
    origin: GF_SOURCE,
    url: GF_URL + 'domine/v11/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X3LAI10VErGuW8Q.ttf',
    variant: 'regular',
    family: 'Domine',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '3d0189b0-2f43-476a-afe9-2498b5557d30',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cormorantgaramond/v10/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPzvA-NxBKL_y94.ttf',
    variant: '700italic',
    family: 'Cormorant Garamond',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8db5cebe-4304-4d3e-99d1-c0a829493172',
    origin: GF_SOURCE,
    url:
      GF_URL + 'cormorantgaramond/v10/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQEl5vuQWJ5heb_w.ttf',
    variant: '700',
    family: 'Cormorant Garamond',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '840e77c6-c716-4ff2-b31d-6e74053f4e29',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cormorantgaramond/v10/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEOXvQ-NxBKL_y94.ttf',
    variant: '600italic',
    family: 'Cormorant Garamond',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4120bdeb-138c-4e1b-9ad6-4e729c720186',
    origin: GF_SOURCE,
    url:
      GF_URL + 'cormorantgaramond/v10/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQdl9vuQWJ5heb_w.ttf',
    variant: '600',
    family: 'Cormorant Garamond',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8dbc4667-e816-4c1d-b563-057226eb2fe1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cormorantgaramond/v10/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEO7ug-NxBKL_y94.ttf',
    variant: '500italic',
    family: 'Cormorant Garamond',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a81d4c6f-d03c-4e7d-966e-ab320a3e78c5',
    origin: GF_SOURCE,
    url:
      GF_URL + 'cormorantgaramond/v10/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQWlhvuQWJ5heb_w.ttf',
    variant: '500',
    family: 'Cormorant Garamond',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8ad7b3c1-6eec-4f5a-81a2-1ce76eafed52',
    origin: GF_SOURCE,
    url:
      GF_URL + 'cormorantgaramond/v10/co3ZmX5slCNuHLi8bLeY9MK7whWMhyjYrHtPkyuF7w6C.ttf',
    variant: 'italic',
    family: 'Cormorant Garamond',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'dd83375c-49a5-41bd-8971-8eefb73d95da',
    origin: GF_SOURCE,
    url: GF_URL + 'cormorantgaramond/v10/co3bmX5slCNuHLi8bLeY9MK7whWMhyjornFLsS6V7w.ttf',
    variant: 'regular',
    family: 'Cormorant Garamond',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6201697d-a69d-4300-9f5e-942f7dcefa0c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cormorantgaramond/v10/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPjuw-NxBKL_y94.ttf',
    variant: '300italic',
    family: 'Cormorant Garamond',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'c27e97a2-1afc-4844-8f18-fa8a92fc10b1',
    origin: GF_SOURCE,
    url:
      GF_URL + 'cormorantgaramond/v10/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQAllvuQWJ5heb_w.ttf',
    variant: '300',
    family: 'Cormorant Garamond',
    category: 'serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ab5e7ce6-0a5b-4908-8a33-1494a2d9cabb',
    origin: GF_SOURCE,
    url: GF_URL + 'martel/v5/PN_yRfK9oXHga0XV5e8ahRbX9vnDzw.ttf',
    variant: '900',
    family: 'Martel',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1bff54ec-dc7f-46f8-bfae-3a85f45d854b',
    origin: GF_SOURCE,
    url: GF_URL + 'martel/v5/PN_yRfK9oXHga0XVwe4ahRbX9vnDzw.ttf',
    variant: '800',
    family: 'Martel',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '69fbe67b-bb9a-4543-a615-275810d44584',
    origin: GF_SOURCE,
    url: GF_URL + 'martel/v5/PN_yRfK9oXHga0XV3e0ahRbX9vnDzw.ttf',
    variant: '700',
    family: 'Martel',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '40105bd0-df0f-4ed5-8005-4a9acfa6c3bf',
    origin: GF_SOURCE,
    url: GF_URL + 'martel/v5/PN_yRfK9oXHga0XVuewahRbX9vnDzw.ttf',
    variant: '600',
    family: 'Martel',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'de14eb6f-a38b-40b6-8910-1cd4fb490c85',
    origin: GF_SOURCE,
    url: GF_URL + 'martel/v5/PN_xRfK9oXHga0XtYcI-jT3L_w.ttf',
    variant: 'regular',
    family: 'Martel',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '4fb25243-7233-498e-ba9e-98fcaf651366',
    origin: GF_SOURCE,
    url: GF_URL + 'martel/v5/PN_yRfK9oXHga0XVzeoahRbX9vnDzw.ttf',
    variant: '300',
    family: 'Martel',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '22c31037-858e-4f3e-85f3-3928fd38359f',
    origin: GF_SOURCE,
    url: GF_URL + 'martel/v5/PN_yRfK9oXHga0XVqekahRbX9vnDzw.ttf',
    variant: '200',
    family: 'Martel',
    category: 'serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '69f7a6a9-9fe9-4c78-b681-65b5798cf0fd',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifjp/v8/xn77YHs72GKoTvER4Gn3b5eMZFqJRkgfU8fEwb0.otf',
    variant: '900',
    family: 'Noto Serif JP',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'e0284252-68af-40d4-b2db-6d5b0a5c3328',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifjp/v8/xn77YHs72GKoTvER4Gn3b5eMZGKLRkgfU8fEwb0.otf',
    variant: '700',
    family: 'Noto Serif JP',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd4dfd824-e5d3-479d-9c72-76fb8aa6f360',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifjp/v8/xn77YHs72GKoTvER4Gn3b5eMZAaKRkgfU8fEwb0.otf',
    variant: '600',
    family: 'Noto Serif JP',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '3aebd620-3074-4872-b707-1f7d25431d6e',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifjp/v8/xn77YHs72GKoTvER4Gn3b5eMZCqNRkgfU8fEwb0.otf',
    variant: '500',
    family: 'Noto Serif JP',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd87dff3e-4506-4055-89a5-543e6ac782ae',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifjp/v8/xn7mYHs72GKoTvER4Gn3b5eMXNikYkY0T84.otf',
    variant: 'regular',
    family: 'Noto Serif JP',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd0d9be22-2657-4cf3-abd7-7fd74d79814d',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifjp/v8/xn77YHs72GKoTvER4Gn3b5eMZHKMRkgfU8fEwb0.otf',
    variant: '300',
    family: 'Noto Serif JP',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '212b10b4-4579-42bf-b4e4-58c336d6ddc0',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserifjp/v8/xn77YHs72GKoTvER4Gn3b5eMZBaPRkgfU8fEwb0.otf',
    variant: '200',
    family: 'Noto Serif JP',
    category: 'serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd4bb1c54-9691-42b7-b3c1-25a74ae1c560',
    origin: GF_SOURCE,
    url: GF_URL + 'mavenpro/v22/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8Yzx5nCpozp5GvU.ttf',
    variant: '900',
    family: 'Maven Pro',
    category: 'sans-serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: 'c948f454-e41d-451a-8ae3-cd3f07587516',
    origin: GF_SOURCE,
    url: GF_URL + 'mavenpro/v22/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8aXx5nCpozp5GvU.ttf',
    variant: '800',
    family: 'Maven Pro',
    category: 'sans-serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '16f5ba27-017b-44fd-a1a0-5370a81ee260',
    origin: GF_SOURCE,
    url: GF_URL + 'mavenpro/v22/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8cLx5nCpozp5GvU.ttf',
    variant: '700',
    family: 'Maven Pro',
    category: 'sans-serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: 'ce98a810-cc9a-4dc4-9797-4b6ac542ca13',
    origin: GF_SOURCE,
    url: GF_URL + 'mavenpro/v22/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8fvx5nCpozp5GvU.ttf',
    variant: '600',
    family: 'Maven Pro',
    category: 'sans-serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '79c50f0c-0dd0-466a-89b9-5c4d5446e0fe',
    origin: GF_SOURCE,
    url: GF_URL + 'mavenpro/v22/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8Rf25nCpozp5GvU.ttf',
    variant: '500',
    family: 'Maven Pro',
    category: 'sans-serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '10db8423-7d5c-4ee3-9fef-7c931a4b5917',
    origin: GF_SOURCE,
    url: GF_URL + 'mavenpro/v22/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8SX25nCpozp5GvU.ttf',
    variant: 'regular',
    family: 'Maven Pro',
    category: 'sans-serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '6c7bc8e7-ef6b-45fd-bdc3-f29c82ca9199',
    origin: GF_SOURCE,
    url: GF_URL + 'play/v12/6ae84K2oVqwItm4TOpc423nTJTM.ttf',
    variant: '700',
    family: 'Play',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'fdc6ed02-0a24-4e34-a0b4-682904cff1c0',
    origin: GF_SOURCE,
    url: GF_URL + 'play/v12/6aez4K2oVqwIjtI8Hp8Tx3A.ttf',
    variant: 'regular',
    family: 'Play',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '48f93fa9-78f2-4fce-88b9-83cd8ce23039',
    origin: GF_SOURCE,
    url:
      GF_URL + 'architectsdaughter/v11/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvfY5q4szgE-Q.ttf',
    variant: 'regular',
    family: 'Architects Daughter',
    category: 'handwriting',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '89480780-f433-4607-a15a-333d1ae6e6d5',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasanscondensed/v5/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVLMR0dzRehY43EA.ttf',
    variant: '900italic',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1bad3b7e-0ad6-4bdb-a853-74360ad2f4a1',
    origin: GF_SOURCE,
    url:
      GF_URL + 'firasanscondensed/v5/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWXXKiMN-cxZblY4.ttf',
    variant: '900',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'cf8f5b44-ac89-4e50-82aa-8ebce34ee47a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasanscondensed/v5/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVCMV0dzRehY43EA.ttf',
    variant: '800italic',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a7b1a218-a870-4ad6-86a4-fb3c6361544f',
    origin: GF_SOURCE,
    url:
      GF_URL + 'firasanscondensed/v5/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWVHLiMN-cxZblY4.ttf',
    variant: '800',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'efd7eaa8-900b-4f86-99a1-d55ae318c848',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasanscondensed/v5/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVFMZ0dzRehY43EA.ttf',
    variant: '700italic',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a7b0b37d-0812-473b-94a1-178917dac541',
    origin: GF_SOURCE,
    url:
      GF_URL + 'firasanscondensed/v5/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWU3IiMN-cxZblY4.ttf',
    variant: '700',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b0aeef99-84b2-40dd-949e-62824d498876',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasanscondensed/v5/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVcMd0dzRehY43EA.ttf',
    variant: '600italic',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ad769f56-2e42-4742-be54-220391f19280',
    origin: GF_SOURCE,
    url:
      GF_URL + 'firasanscondensed/v5/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWSnJiMN-cxZblY4.ttf',
    variant: '600',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '377f9bea-5147-4163-934a-4e432fc6f73b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasanscondensed/v5/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVXMB0dzRehY43EA.ttf',
    variant: '500italic',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'fe940197-a4d4-460e-a9b9-6ebfa18e0017',
    origin: GF_SOURCE,
    url:
      GF_URL + 'firasanscondensed/v5/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWQXOiMN-cxZblY4.ttf',
    variant: '500',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6b3486a9-31cc-4476-a992-560002cb440e',
    origin: GF_SOURCE,
    url:
      GF_URL + 'firasanscondensed/v5/wEOjEADFm8hSaQTFG18FErVhsC9x-tarUfPtqOlQfx9CjA.ttf',
    variant: 'italic',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '52ba0030-0922-4028-aea5-0623b6c339a1',
    origin: GF_SOURCE,
    url: GF_URL + 'firasanscondensed/v5/wEOhEADFm8hSaQTFG18FErVhsC9x-tarYfHnrMtVbx8.ttf',
    variant: 'regular',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e3fc34fc-530a-43fd-af39-83d183d7a572',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasanscondensed/v5/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVBMF0dzRehY43EA.ttf',
    variant: '300italic',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '9a831035-2bf1-4bb8-b341-883876bacb02',
    origin: GF_SOURCE,
    url:
      GF_URL + 'firasanscondensed/v5/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWV3PiMN-cxZblY4.ttf',
    variant: '300',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3d92a61c-4ace-4600-8db3-a6a3db98adc0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasanscondensed/v5/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVYMJ0dzRehY43EA.ttf',
    variant: '200italic',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a7286dca-bc9c-4097-8102-7dc4b547a4a0',
    origin: GF_SOURCE,
    url:
      GF_URL + 'firasanscondensed/v5/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWTnMiMN-cxZblY4.ttf',
    variant: '200',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e8580ed8-b795-45fd-91dc-75a0ec49a8b6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'firasanscondensed/v5/wEOtEADFm8hSaQTFG18FErVhsC9x-tarUfPVzONUXRpSjJcu.ttf',
    variant: '100italic',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '4c77439b-0a3c-40ae-b277-1d16fc0a043b',
    origin: GF_SOURCE,
    url:
      GF_URL + 'firasanscondensed/v5/wEOjEADFm8hSaQTFG18FErVhsC9x-tarWZXtqOlQfx9CjA.ttf',
    variant: '100',
    family: 'Fira Sans Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b24a86e0-a91f-41aa-a749-f558eda2f419',
    origin: GF_SOURCE,
    url: GF_URL + 'hindmadurai/v6/f0Xu0e2p98ZvDXdZQIOcpqjfTaInecsoMJ0b_g.ttf',
    variant: '700',
    family: 'Hind Madurai',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'fb561906-9318-49ed-b595-c013e11f9458',
    origin: GF_SOURCE,
    url: GF_URL + 'hindmadurai/v6/f0Xu0e2p98ZvDXdZQIOcpqjfKaMnecsoMJ0b_g.ttf',
    variant: '600',
    family: 'Hind Madurai',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '7cbed38c-59bf-4efb-a8ff-0b2c93a9ae87',
    origin: GF_SOURCE,
    url: GF_URL + 'hindmadurai/v6/f0Xu0e2p98ZvDXdZQIOcpqjfBaQnecsoMJ0b_g.ttf',
    variant: '500',
    family: 'Hind Madurai',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '50d53e55-4e1c-4305-a22d-b185542b4a13',
    origin: GF_SOURCE,
    url: GF_URL + 'hindmadurai/v6/f0Xx0e2p98ZvDXdZQIOcpqjn8Y0DceA0OQ.ttf',
    variant: 'regular',
    family: 'Hind Madurai',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '42e4cf14-89a8-4bd1-a1b2-d95b82f593f7',
    origin: GF_SOURCE,
    url: GF_URL + 'hindmadurai/v6/f0Xu0e2p98ZvDXdZQIOcpqjfXaUnecsoMJ0b_g.ttf',
    variant: '300',
    family: 'Hind Madurai',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '32e9a0fa-b393-438c-8591-520ebe667fed',
    origin: GF_SOURCE,
    url: GF_URL + 'acme/v11/RrQfboBx-C5_bx3Lb23lzLk.ttf',
    variant: 'regular',
    family: 'Acme',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '09e32ed7-dc42-4f89-b715-7bd5851e1196',
    origin: GF_SOURCE,
    url: GF_URL + 'abrilfatface/v12/zOL64pLDlL1D99S8g8PtiKchm-BsjOLhZBY.ttf',
    variant: 'regular',
    family: 'Abril Fatface',
    category: 'display',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '681d8222-dcbd-492f-811c-24caf2caab6e',
    origin: GF_SOURCE,
    url: GF_URL + 'assistant/v7/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtmZgEGGf3qGuvM4.ttf',
    variant: '800',
    family: 'Assistant',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd11bc739-9901-4c7a-a29a-b645207b31fb',
    origin: GF_SOURCE,
    url: GF_URL + 'assistant/v7/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtgFgEGGf3qGuvM4.ttf',
    variant: '700',
    family: 'Assistant',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '1fe01ceb-ad22-4274-b4f9-017d777c7599',
    origin: GF_SOURCE,
    url: GF_URL + 'assistant/v7/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtjhgEGGf3qGuvM4.ttf',
    variant: '600',
    family: 'Assistant',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '68ca442b-647c-40e0-a7c3-a1d03c6abd1c',
    origin: GF_SOURCE,
    url: GF_URL + 'assistant/v7/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQttRnEGGf3qGuvM4.ttf',
    variant: '500',
    family: 'Assistant',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '868f1353-9504-4963-b4d0-cba25aa742f3',
    origin: GF_SOURCE,
    url: GF_URL + 'assistant/v7/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnEGGf3qGuvM4.ttf',
    variant: 'regular',
    family: 'Assistant',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '667ae96f-791a-4ca1-ab00-205d541c29ce',
    origin: GF_SOURCE,
    url: GF_URL + 'assistant/v7/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtrhnEGGf3qGuvM4.ttf',
    variant: '300',
    family: 'Assistant',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '31677567-0329-4213-a070-d4f44beaaa99',
    origin: GF_SOURCE,
    url: GF_URL + 'assistant/v7/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtmZnEGGf3qGuvM4.ttf',
    variant: '200',
    family: 'Assistant',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '3e15af42-d761-4243-b1a8-034a2a2b705e',
    origin: GF_SOURCE,
    url: GF_URL + 'archivonarrow/v12/tssxApVBdCYD5Q7hcxTE1ArZ0bb1scVf-9cCOxBu_BM.ttf',
    variant: '700italic',
    family: 'Archivo Narrow',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2a14d5c4-b4e0-4341-add3-afaebe70b882',
    origin: GF_SOURCE,
    url: GF_URL + 'archivonarrow/v12/tss3ApVBdCYD5Q7hcxTE1ArZ0b5LrFla8dMgPgBu.ttf',
    variant: '700',
    family: 'Archivo Narrow',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '1e3035ba-65ce-4637-88ac-35d441371d8a',
    origin: GF_SOURCE,
    url: GF_URL + 'archivonarrow/v12/tssxApVBdCYD5Q7hcxTE1ArZ0bb1saFe-9cCOxBu_BM.ttf',
    variant: '600italic',
    family: 'Archivo Narrow',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '2e21ffed-66ac-421f-84b6-82a0dca77a36',
    origin: GF_SOURCE,
    url: GF_URL + 'archivonarrow/v12/tss3ApVBdCYD5Q7hcxTE1ArZ0b4vrVla8dMgPgBu.ttf',
    variant: '600',
    family: 'Archivo Narrow',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c068f1ef-6fcf-405e-91a2-f7cc94e5a39b',
    origin: GF_SOURCE,
    url: GF_URL + 'archivonarrow/v12/tssxApVBdCYD5Q7hcxTE1ArZ0bb1sY1Z-9cCOxBu_BM.ttf',
    variant: '500italic',
    family: 'Archivo Narrow',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '42a8b772-0119-4b6b-940d-5502235c52f1',
    origin: GF_SOURCE,
    url: GF_URL + 'archivonarrow/v12/tss3ApVBdCYD5Q7hcxTE1ArZ0b4Dqlla8dMgPgBu.ttf',
    variant: '500',
    family: 'Archivo Narrow',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '79dd26e0-5eea-4705-a932-76c6d6fd58d9',
    origin: GF_SOURCE,
    url: GF_URL + 'archivonarrow/v12/tss2ApVBdCYD5Q7hcxTE1ArZ0bb1iXlw398pJxk.ttf',
    variant: 'italic',
    family: 'Archivo Narrow',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '9ec204d3-c455-4092-9f4b-038a20478e70',
    origin: GF_SOURCE,
    url: GF_URL + 'archivonarrow/v12/tss0ApVBdCYD5Q7hcxTE1ArZ0Yb3g31S2s8p.ttf',
    variant: 'regular',
    family: 'Archivo Narrow',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '52fb0982-3787-4920-b05c-a09ced8dc721',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdC35WCmI96Ajtm81Gga1rN4h8ij1I7LLE.ttf',
    variant: '900italic',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '73d6e2b8-6c6a-4779-aff1-1d5fdf1af75e',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdA35WCmI96Ajtm81kmdP7K6BsAikI7.ttf',
    variant: '900',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f7c0b44a-47b3-47e1-ad8a-852ad64708cc',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdC35WCmI96Ajtm81Gga37M4h8ij1I7LLE.ttf',
    variant: '800italic',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'c5ac293c-b14f-4d1e-88a6-ff069dffe3eb',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdA35WCmI96Ajtm81kCdf7K6BsAikI7.ttf',
    variant: '800',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '10f7483c-add7-41a2-b69e-03dbbf580cde',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdC35WCmI96Ajtm81Gga2LP4h8ij1I7LLE.ttf',
    variant: '700italic',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'dbca5643-1957-4a4f-80a1-fdb709dfeb3d',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdA35WCmI96Ajtm81kedv7K6BsAikI7.ttf',
    variant: '700',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f333220e-5952-4587-a593-b5a5efba2ef7',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdC35WCmI96Ajtm81GgawbO4h8ij1I7LLE.ttf',
    variant: '600italic',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3d5dffb1-292b-4291-acce-04d478c5e09a',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdA35WCmI96Ajtm81l6d_7K6BsAikI7.ttf',
    variant: '600',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '054e4a59-e010-46fe-aac9-d8c0e4f945d8',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdB35WCmI96Ajtm81GgU97gxhcJk1s.ttf',
    variant: 'italic',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '66c94042-ccf2-470e-a7ba-d37f46a0459f',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdH35WCmI96Ajtm82GiWdrCwwcJ.ttf',
    variant: 'regular',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '817cee86-a6f7-4811-b694-0ad5612b6613',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdC35WCmI96Ajtm81Gga3LI4h8ij1I7LLE.ttf',
    variant: '300italic',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '6b37c662-f11f-48a9-b3b9-991f9665d431',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdA35WCmI96Ajtm81kOcf7K6BsAikI7.ttf',
    variant: '300',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '97374e45-1be6-47e5-bdf0-f44dedfeac63',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdC35WCmI96Ajtm81GgaxbL4h8ij1I7LLE.ttf',
    variant: '200italic',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '982073db-7a4f-4065-91e5-dd4992ac6bf9',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdA35WCmI96Ajtm81lqcv7K6BsAikI7.ttf',
    variant: '200',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'e8fbdabd-a282-4f08-922a-f293c3cba438',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdD35WCmI96Ajtm81Gga7rqwjUMg1siNQ.ttf',
    variant: '100italic',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f7668a86-6a56-44c5-92ec-5b45d69fcc96',
    origin: GF_SOURCE,
    url: GF_URL + 'overpass/v5/qFdB35WCmI96Ajtm81nGU97gxhcJk1s.ttf',
    variant: '100',
    family: 'Overpass',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '5a68754c-8d30-4f38-afd6-8151184c00cb',
    origin: GF_SOURCE,
    url: GF_URL + 'rajdhani/v10/LDI2apCSOBg7S-QT7pa8FsOsc-bGkqIw.ttf',
    variant: '700',
    family: 'Rajdhani',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'cd1c8589-b8fa-4dbc-8d5b-7ebc30c8bdfd',
    origin: GF_SOURCE,
    url: GF_URL + 'rajdhani/v10/LDI2apCSOBg7S-QT7pbYF8Osc-bGkqIw.ttf',
    variant: '600',
    family: 'Rajdhani',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6f3c19ce-b42c-4d31-ac5e-7fbd876f03bc',
    origin: GF_SOURCE,
    url: GF_URL + 'rajdhani/v10/LDI2apCSOBg7S-QT7pb0EMOsc-bGkqIw.ttf',
    variant: '500',
    family: 'Rajdhani',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'c31d5868-9800-494f-90f3-9989ccfd1616',
    origin: GF_SOURCE,
    url: GF_URL + 'rajdhani/v10/LDIxapCSOBg7S-QT7q4AOeekWPrP.ttf',
    variant: 'regular',
    family: 'Rajdhani',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '737ab915-80ff-4a94-af33-8e51e30a5989',
    origin: GF_SOURCE,
    url: GF_URL + 'rajdhani/v10/LDI2apCSOBg7S-QT7pasEcOsc-bGkqIw.ttf',
    variant: '300',
    family: 'Rajdhani',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ad96bb66-a9e8-44fa-911c-03d6d56a9807',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq1NyusCzRRXnaur.ttf',
    variant: '800italic',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '21151571-c477-4fb1-b265-660a827e09bf',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq0qyusCzRRXnaur.ttf',
    variant: '700italic',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'fcdc0c51-ffe1-4426-ae55-c90a216fc3fb',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq0TyusCzRRXnaur.ttf',
    variant: '600italic',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '30e7af48-36aa-4975-bdf0-0293bc930669',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq3_zesCzRRXnaur.ttf',
    variant: '500italic',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '35c106e1-27fd-4cd1-86c7-07cefb0af8d1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq3NzesCzRRXnaur.ttf',
    variant: 'italic',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '034c966d-f5a6-4331-94e6-6e48a66d4520',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq2TzesCzRRXnaur.ttf',
    variant: '300italic',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '228d1162-aa19-4e5d-82d2-a32539dd29af',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZIukljuEG7xFHnQ.ttf',
    variant: '800',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'bd9f952c-dfd8-4729-9030-3701013307cd',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZRekljuEG7xFHnQ.ttf',
    variant: '700',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'cdb087a0-a9b5-421f-b553-d5daabd74e31',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZfOkljuEG7xFHnQ.ttf',
    variant: '600',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '3c5fd8b6-9cc3-4942-ada4-66f3461943b2',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZkO4ljuEG7xFHnQ.ttf',
    variant: '500',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '0d9c9aa0-a050-47c4-9f36-dc7767fac723',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZou4ljuEG7xFHnQ.ttf',
    variant: 'regular',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '9293f55f-1a5d-425d-a39f-4286fceec72f',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'merriweathersans/v14/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZ_O4ljuEG7xFHnQ.ttf',
    variant: '300',
    family: 'Merriweather Sans',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '4c88fba3-0f87-4f84-a024-bbbfa9e26f25',
    origin: GF_SOURCE,
    url: GF_URL + 'slabo27px/v7/mFT0WbgBwKPR_Z4hGN2qsxgJ1EJ7i90.ttf',
    variant: 'regular',
    family: 'Slabo 27px',
    category: 'serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'f249980e-5a0d-488d-a8f5-fa38ba90c0ca',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKS-Go6G5tXcraQI4GnVaRrMFJyAu4.ttf',
    variant: '900italic',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'b88e9173-fd26-4941-852e-1fc3816832bf',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKU-Go6G5tXcr4WPCWgX6BJNUJy.ttf',
    variant: '900',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '3ebd9c31-542d-419e-8ed5-ad401e44c51d',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKS-Go6G5tXcraQI6WmVaRrMFJyAu4.ttf',
    variant: '800italic',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '1b586c5e-2bc1-4934-a141-6e4928aabf6e',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKU-Go6G5tXcr4yPSWgX6BJNUJy.ttf',
    variant: '800',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '773507fb-1f9c-4e6b-9ec3-3dd83286fdbd',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKS-Go6G5tXcraQI7mlVaRrMFJyAu4.ttf',
    variant: '700italic',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'c6dbbd51-f8b4-4fa2-ad07-da5747869231',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKU-Go6G5tXcr4uPiWgX6BJNUJy.ttf',
    variant: '700',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '034c318c-7edb-4aa6-815b-f5d6fa5e1411',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKS-Go6G5tXcraQI92kVaRrMFJyAu4.ttf',
    variant: '600italic',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '4f550d7f-42e4-4bb4-b5c0-a6acb04757e6',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKU-Go6G5tXcr5KPyWgX6BJNUJy.ttf',
    variant: '600',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '1b761d54-3892-480b-ad20-efc258f0e5a3',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKS-Go6G5tXcraQI_GjVaRrMFJyAu4.ttf',
    variant: '500italic',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'a6c8f9bc-631d-49f1-8b6d-f41e68bf3374',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKU-Go6G5tXcr5mOCWgX6BJNUJy.ttf',
    variant: '500',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'c24b772c-37b3-4262-b2d5-5e1e802983a8',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKX-Go6G5tXcraQGwWKcaxALFs.ttf',
    variant: 'italic',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'afaee9e7-2ba1-4a6d-b4a0-46a4dc25ef02',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKZ-Go6G5tXcoaSEQGodLxA.ttf',
    variant: 'regular',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'f0f77230-8810-4f0d-b26a-0bc34ae9a79a',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKS-Go6G5tXcraQI6miVaRrMFJyAu4.ttf',
    variant: '300italic',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'b6343789-6ad1-4026-9e0a-8f788c790ae3',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKU-Go6G5tXcr4-OSWgX6BJNUJy.ttf',
    variant: '300',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '692fd41d-1be2-47b4-b876-ebd83c092b5d',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKS-Go6G5tXcraQI82hVaRrMFJyAu4.ttf',
    variant: '200italic',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '33b9aa44-9c9a-460d-a3f8-26d688668c95',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKU-Go6G5tXcr5aOiWgX6BJNUJy.ttf',
    variant: '200',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '0db23eb1-bba0-472b-a1ba-88e23fb068bd',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKV-Go6G5tXcraQI2GAdY5FPFtrGw.ttf',
    variant: '100italic',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '91166dfd-0435-461e-8fb7-0eb86259e089',
    origin: GF_SOURCE,
    url: GF_URL + 'kanit/v7/nKKX-Go6G5tXcr72GwWKcaxALFs.ttf',
    variant: '100',
    family: 'Kanit',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '69de6937-c5e5-4579-8646-790c09d99f71',
    origin: GF_SOURCE,
    url: GF_URL + 'anticslab/v9/bWt97fPFfRzkCa9Jlp6IWcJWXW5p5Qo.ttf',
    variant: 'regular',
    family: 'Antic Slab',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '7970b09c-5361-43b3-b7b2-7256760c36db',
    origin: GF_SOURCE,
    url: GF_URL + 'asap/v15/KFO7CniXp96ayz4E7kS706qGLdTylacKW3ueBVEeezU.ttf',
    variant: '700italic',
    family: 'Asap',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'fa7a97a5-4ce4-42f5-b8f2-cd3c6931d370',
    origin: GF_SOURCE,
    url: GF_URL + 'asap/v15/KFO7CniXp96ayz4E7kS706qGLdTylZ4KW3ueBVEeezU.ttf',
    variant: '600italic',
    family: 'Asap',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '3dc56170-4671-4de2-9050-be050ff285f5',
    origin: GF_SOURCE,
    url: GF_URL + 'asap/v15/KFO7CniXp96ayz4E7kS706qGLdTylXINW3ueBVEeezU.ttf',
    variant: '500italic',
    family: 'Asap',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '5db06c7f-ac7f-478a-8cce-c396d5c85f95',
    origin: GF_SOURCE,
    url: GF_URL + 'asap/v15/KFO7CniXp96ayz4E7kS706qGLdTylUANW3ueBVEeezU.ttf',
    variant: 'italic',
    family: 'Asap',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'f8705f11-ae0a-4839-b3c4-c08b3ab3ccbf',
    origin: GF_SOURCE,
    url: GF_URL + 'asap/v15/KFO9CniXp96a4Tc2EZzSuDAoKsHd0ahOUX-8AEEe.ttf',
    variant: '700',
    family: 'Asap',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '44301d1c-9b84-4c77-91b9-bd6aa8646fd7',
    origin: GF_SOURCE,
    url: GF_URL + 'asap/v15/KFO9CniXp96a4Tc2EZzSuDAoKsHk0ahOUX-8AEEe.ttf',
    variant: '600',
    family: 'Asap',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '4db45215-7793-487c-977c-fcaf3c12b333',
    origin: GF_SOURCE,
    url: GF_URL + 'asap/v15/KFO9CniXp96a4Tc2EZzSuDAoKsEI1qhOUX-8AEEe.ttf',
    variant: '500',
    family: 'Asap',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'a0ec87e7-86d0-452b-8e5c-cf05a7f812c6',
    origin: GF_SOURCE,
    url: GF_URL + 'asap/v15/KFO9CniXp96a4Tc2EZzSuDAoKsE61qhOUX-8AEEe.ttf',
    variant: 'regular',
    family: 'Asap',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '597b4d9d-39bd-4b7c-a266-bb1226e159cf',
    origin: GF_SOURCE,
    url: GF_URL + 'questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    variant: 'regular',
    family: 'Questrial',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '1e393a4a-d23e-450c-a056-727305c0364c',
    origin: GF_SOURCE,
    url: GF_URL + 'shadowsintolight/v10/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQDcsr4xzSMYA.ttf',
    variant: 'regular',
    family: 'Shadows Into Light',
    category: 'handwriting',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'f2235032-b9ad-4ca5-933f-502cc5b69f5f',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSgOSvHp47LTZFwA.ttf',
    variant: '900italic',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '20924f6b-7823-4486-848a-f10b3e455d90',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSqeSvHp47LTZFwA.ttf',
    variant: '800italic',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '8f8b48cd-eef2-499b-848c-5a78d0760418',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSzuSvHp47LTZFwA.ttf',
    variant: '700italic',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '8813c708-cf24-4e05-a1b2-c77f2383ea7f',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsS9-SvHp47LTZFwA.ttf',
    variant: '600italic',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '5b681ad9-94b9-42a0-85dd-0c32edb6b596',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSG-OvHp47LTZFwA.ttf',
    variant: '500italic',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '1f004187-fc92-4be8-b359-e8c73226cfcd',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSKeOvHp47LTZFwA.ttf',
    variant: 'italic',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '060830aa-e56a-4a1a-b47c-2fa06ea23afc',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSd-OvHp47LTZFwA.ttf',
    variant: '300italic',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '67191813-ee0b-4a59-9b92-d0006d19658a',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSqeOvHp47LTZFwA.ttf',
    variant: '200italic',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '4bf84695-6e05-4797-a956-28c4a7079806',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptyg83HX_SGhgqO0yLcmjzUAuWexTpWwaClGrw-PTY.ttf',
    variant: '900',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'b567d0fe-afd0-4e34-ae49-bc741072ff68',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptyg83HX_SGhgqO0yLcmjzUAuWexRNWwaClGrw-PTY.ttf',
    variant: '800',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '128d246e-cf32-445e-abab-7f3aded9c3fd',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptyg83HX_SGhgqO0yLcmjzUAuWexXRWwaClGrw-PTY.ttf',
    variant: '700',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '17112ca7-9821-4a18-9bd3-dcde7991e1ef',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptyg83HX_SGhgqO0yLcmjzUAuWexU1WwaClGrw-PTY.ttf',
    variant: '600',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'eed3ad52-0671-489c-a505-e63db8969ad1',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptyg83HX_SGhgqO0yLcmjzUAuWexaFRwaClGrw-PTY.ttf',
    variant: '500',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '39a3655c-9e91-437b-88ee-30c95d91ba26',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNRwaClGrw-PTY.ttf',
    variant: 'regular',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '4da13ae0-fb51-43ef-92c0-5bd121d18511',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptyg83HX_SGhgqO0yLcmjzUAuWexc1RwaClGrw-PTY.ttf',
    variant: '300',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '64c1d69f-6375-450e-8036-ce0b5e8a6442',
    origin: GF_SOURCE,
    url: GF_URL + 'mulish/v3/1Ptyg83HX_SGhgqO0yLcmjzUAuWexRNRwaClGrw-PTY.ttf',
    variant: '200',
    family: 'Mulish',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'e3ea5087-7ab9-436d-ad06-a3cbd4d3c2c2',
    origin: GF_SOURCE,
    url: GF_URL + 'dmsans/v6/rP2Ap2ywxg089UriCZawpBqmDnYS-Cjk6Q.ttf',
    variant: '700italic',
    family: 'DM Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6a257d0a-3f6e-4b52-a1ca-88939a1985e8',
    origin: GF_SOURCE,
    url: GF_URL + 'dmsans/v6/rP2Cp2ywxg089UriASitOB-sClQX6Cg.ttf',
    variant: '700',
    family: 'DM Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '592736ce-4fc3-4493-805b-09c5ee22abe1',
    origin: GF_SOURCE,
    url: GF_URL + 'dmsans/v6/rP2Ap2ywxg089UriCZaw7BymDnYS-Cjk6Q.ttf',
    variant: '500italic',
    family: 'DM Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ff587cfa-7399-44a5-acea-28de575068d0',
    origin: GF_SOURCE,
    url: GF_URL + 'dmsans/v6/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf',
    variant: '500',
    family: 'DM Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '54827f8a-faf9-4ec2-9439-20b0a7aeb406',
    origin: GF_SOURCE,
    url: GF_URL + 'dmsans/v6/rP2Fp2ywxg089UriCZaIGDWCBl0O8Q.ttf',
    variant: 'italic',
    family: 'DM Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '28024f68-1fe9-405a-99da-6d5d4153b9a5',
    origin: GF_SOURCE,
    url: GF_URL + 'dmsans/v6/rP2Hp2ywxg089UriOZSCHBeHFl0.ttf',
    variant: 'regular',
    family: 'DM Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'ac65ab4b-0cc5-4c44-9991-7999f78afb5c',
    origin: GF_SOURCE,
    url: GF_URL + 'indieflower/v12/m8JVjfNVeKWVnh3QMuKkFcZlbkGG1dKEDw.ttf',
    variant: 'regular',
    family: 'Indie Flower',
    category: 'handwriting',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '4722704a-c630-41f8-9109-cce76a325ad3',
    origin: GF_SOURCE,
    url:
      GF_URL + 'ebgaramond/v15/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7c8R496WamXgXFI.ttf',
    variant: '800italic',
    family: 'EB Garamond',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '2a1feb89-2089-4cb8-9aa6-ffbd094965a9',
    origin: GF_SOURCE,
    url:
      GF_URL + 'ebgaramond/v15/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7dbR496WamXgXFI.ttf',
    variant: '700italic',
    family: 'EB Garamond',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '225dded1-6fae-44f0-acb8-e40c8432301f',
    origin: GF_SOURCE,
    url:
      GF_URL + 'ebgaramond/v15/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7diR496WamXgXFI.ttf',
    variant: '600italic',
    family: 'EB Garamond',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'f70eab56-bfdb-480d-ad82-c4c1ddeed0b7',
    origin: GF_SOURCE,
    url:
      GF_URL + 'ebgaramond/v15/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7eOQI96WamXgXFI.ttf',
    variant: '500italic',
    family: 'EB Garamond',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '48c461c8-0b78-4240-bc47-7aecdb4f26f6',
    origin: GF_SOURCE,
    url:
      GF_URL + 'ebgaramond/v15/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7e8QI96WamXgXFI.ttf',
    variant: 'italic',
    family: 'EB Garamond',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '2b196c04-e9b9-4e6b-8412-6c15fa0e2586',
    origin: GF_SOURCE,
    url: GF_URL + 'ebgaramond/v15/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-a_NUA4V-e6yHgQ.ttf',
    variant: '800',
    family: 'EB Garamond',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '7b1cd565-df1b-4183-ad44-dc6bc17750e0',
    origin: GF_SOURCE,
    url: GF_URL + 'ebgaramond/v15/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-DPNUA4V-e6yHgQ.ttf',
    variant: '700',
    family: 'EB Garamond',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'bbb51a04-c494-433d-b5af-b2ca0ca1a51f',
    origin: GF_SOURCE,
    url: GF_URL + 'ebgaramond/v15/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-NfNUA4V-e6yHgQ.ttf',
    variant: '600',
    family: 'EB Garamond',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '81aed1c9-193e-44d1-968a-2bf026bf3339',
    origin: GF_SOURCE,
    url: GF_URL + 'ebgaramond/v15/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-2fRUA4V-e6yHgQ.ttf',
    variant: '500',
    family: 'EB Garamond',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'af24034b-76aa-4aba-9d5e-a3f377b1bb61',
    origin: GF_SOURCE,
    url: GF_URL + 'ebgaramond/v15/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RUA4V-e6yHgQ.ttf',
    variant: 'regular',
    family: 'EB Garamond',
    category: 'serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '7ca67ba3-c66a-48b9-968d-617e27e82413',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_6XJnvUD7dzB2KZeKcaWMrUZEtdzow.ttf',
    variant: '900italic',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '78686915-59d3-48d5-9b13-bbf15c681abc',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_8XJnvUD7dzB2C4_04bmkvc5Q9dw.ttf',
    variant: '900',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '32eee5d6-9b87-494f-99c4-6b724fa923ef',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_6XJnvUD7dzB2KZeK4aGMrUZEtdzow.ttf',
    variant: '800italic',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '7756d653-8fea-4d7d-8774-73b5fdc6c160',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_8XJnvUD7dzB2Cx_w4bmkvc5Q9dw.ttf',
    variant: '800',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ffead620-4146-408b-8754-bbccde400b07',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_6XJnvUD7dzB2KZeKka2MrUZEtdzow.ttf',
    variant: '700italic',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b0564b0a-865b-42d0-b251-9f49560f91ca',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_8XJnvUD7dzB2C2_84bmkvc5Q9dw.ttf',
    variant: '700',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '18d8ce08-8842-42d1-8930-581f50e8204c',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_6XJnvUD7dzB2KZeLAamMrUZEtdzow.ttf',
    variant: '600italic',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2231fced-cb29-4e71-b4fa-978c1ede43fb',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_8XJnvUD7dzB2Cv_44bmkvc5Q9dw.ttf',
    variant: '600',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '442e1556-cb53-4db8-a731-e9af1f94d561',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_6XJnvUD7dzB2KZeLsbWMrUZEtdzow.ttf',
    variant: '500italic',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'd68ebf4f-471f-4491-8040-3464c00f3e52',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_8XJnvUD7dzB2Ck_k4bmkvc5Q9dw.ttf',
    variant: '500',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'b7a15132-ed5f-4c99-8873-8b5f8c1b7006',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_9XJnvUD7dzB2KZdoYREcjeo0k.ttf',
    variant: 'italic',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '83e40c0a-b36e-4d5c-82b5-276451cdf671',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W__XJnvUD7dzB26Z9AcZkIzeg.ttf',
    variant: 'regular',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3edd22ee-ddb6-4c9c-85f9-f780c926cbee',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_6XJnvUD7dzB2KZeK0bGMrUZEtdzow.ttf',
    variant: '300italic',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '21a2be16-7c10-4ab7-9905-c8d50bd7cf32',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_8XJnvUD7dzB2Cy_g4bmkvc5Q9dw.ttf',
    variant: '300',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '841c5d6c-41b5-4e56-b1f6-c362ad042ab1',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_6XJnvUD7dzB2KZeLQb2MrUZEtdzow.ttf',
    variant: '200italic',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'c443ae81-6a8f-42fa-adfa-9d1cae85c869',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_8XJnvUD7dzB2Cr_s4bmkvc5Q9dw.ttf',
    variant: '200',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'd91c116b-1dc1-4a87-a777-dc6b4f2133ef',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_7XJnvUD7dzB2KZeJ8TkMBf50kbiM.ttf',
    variant: '100italic',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1fb3af4a-9499-4359-9dca-87afa5f8fa12',
    origin: GF_SOURCE,
    url: GF_URL + 'prompt/v5/-W_9XJnvUD7dzB2CA9oYREcjeo0k.ttf',
    variant: '100',
    family: 'Prompt',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '5a57d929-f17e-4be1-9b15-55241a0e3816',
    origin: GF_SOURCE,
    url: GF_URL + 'teko/v10/LYjCdG7kmE0gdRhYgCNqqVIuTN4.ttf',
    variant: '700',
    family: 'Teko',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8bf17663-15e5-4358-b1b9-4a6c0cdc37d1',
    origin: GF_SOURCE,
    url: GF_URL + 'teko/v10/LYjCdG7kmE0gdXxZgCNqqVIuTN4.ttf',
    variant: '600',
    family: 'Teko',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'dd5f627b-7c49-48fa-8dcb-696638c832b5',
    origin: GF_SOURCE,
    url: GF_URL + 'teko/v10/LYjCdG7kmE0gdVBegCNqqVIuTN4.ttf',
    variant: '500',
    family: 'Teko',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6a24db69-fb7b-4fb2-92bf-bdf47bee3688',
    origin: GF_SOURCE,
    url: GF_URL + 'teko/v10/LYjNdG7kmE0gTaR3pCtBtVs.ttf',
    variant: 'regular',
    family: 'Teko',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '8b288e5f-9101-4a6e-a76c-24d7bbecb740',
    origin: GF_SOURCE,
    url: GF_URL + 'teko/v10/LYjCdG7kmE0gdQhfgCNqqVIuTN4.ttf',
    variant: '300',
    family: 'Teko',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'a37fdc52-ed4a-4d77-8ae6-bab8a73a145a',
    origin: GF_SOURCE,
    url: GF_URL + 'pacifico/v17/FwZY7-Qmy14u9lezJ96A4sijpFu_.ttf',
    variant: 'regular',
    family: 'Pacifico',
    category: 'handwriting',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '956c7fbd-6f35-4f8d-a122-364ba136e9d9',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanshk/v6/nKKP-GM_FYFRJvXzVXaAPe9ZsF1THMX6MKliqQ.otf',
    variant: '900',
    family: 'Noto Sans HK',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6463b3b5-16c0-4fed-bc39-a0df9c1fd357',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanshk/v6/nKKP-GM_FYFRJvXzVXaAPe9ZiF9THMX6MKliqQ.otf',
    variant: '700',
    family: 'Noto Sans HK',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'd2a6d45a-64a8-434a-bac0-29f83ae59441',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanshk/v6/nKKP-GM_FYFRJvXzVXaAPe9ZwFlTHMX6MKliqQ.otf',
    variant: '500',
    family: 'Noto Sans HK',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '0be19779-b43b-48d0-9084-93a31be748ea',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanshk/v6/nKKQ-GM_FYFRJvXzVXaAPe9hMnB3Eu7mOQ.otf',
    variant: 'regular',
    family: 'Noto Sans HK',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '402c6417-5a29-4fa4-94ce-dc967a36d143',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanshk/v6/nKKP-GM_FYFRJvXzVXaAPe9ZmFhTHMX6MKliqQ.otf',
    variant: '300',
    family: 'Noto Sans HK',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '4702e233-9d36-45ad-a26c-bb3d62f9b129',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanshk/v6/nKKO-GM_FYFRJvXzVXaAPe9ZUHp1MOv2ObB7.otf',
    variant: '100',
    family: 'Noto Sans HK',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '86213b43-24d9-4437-b937-8c8c5c651502',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drHdetC6jJ7bpQBL.ttf',
    variant: '900italic',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '9488938f-d5c5-4eb2-a346-f029284218fe',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drH0etC6jJ7bpQBL.ttf',
    variant: '800italic',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '458dfc87-278c-4e3f-9c5a-8a94239af9dd',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drGTetC6jJ7bpQBL.ttf',
    variant: '700italic',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '65a5e047-0165-4ad7-be61-954eab7b8a21',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drGqetC6jJ7bpQBL.ttf',
    variant: '600italic',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '29786b74-c830-4d54-b0f5-bc6f20a2efbe',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drFGfdC6jJ7bpQBL.ttf',
    variant: '500italic',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'efef1823-6749-4944-a577-44c0fef12c64',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drF0fdC6jJ7bpQBL.ttf',
    variant: 'italic',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6d98852e-f5a2-4492-830f-05decd544e60',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drEqfdC6jJ7bpQBL.ttf',
    variant: '300italic',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '0fe2e42b-8091-4086-bf22-742c37a5ca01',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drH0fdC6jJ7bpQBL.ttf',
    variant: '200italic',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'f0d5c42c-e281-491e-95a1-b86ceb69a711',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drF0fNC6jJ7bpQBL.ttf',
    variant: '100italic',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '7db98c9b-1de6-4f02-abec-4834ab50cab7',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH1v4okm5zmbvwkAx_sfcEuiD8jF_WcPtq-rpvLpQ.ttf',
    variant: '900',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'fd62ed13-28b4-4314-bfcc-662c6217b607',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH1v4okm5zmbvwkAx_sfcEuiD8jPvWcPtq-rpvLpQ.ttf',
    variant: '800',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '01f7ea28-3388-4034-b8cc-6d0b1612beaf',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH1v4okm5zmbvwkAx_sfcEuiD8jWfWcPtq-rpvLpQ.ttf',
    variant: '700',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '01ba5c8d-644f-48ac-ba87-b6b5ecee6c95',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH1v4okm5zmbvwkAx_sfcEuiD8jYPWcPtq-rpvLpQ.ttf',
    variant: '600',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'ced05672-a0c4-4cf8-96e1-f1c5daa0eff9',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH1v4okm5zmbvwkAx_sfcEuiD8jjPKcPtq-rpvLpQ.ttf',
    variant: '500',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4fdf8cdf-b5bd-433f-92b8-2e9c32102ad3',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKcPtq-rpvLpQ.ttf',
    variant: 'regular',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '9a47b80f-1c6c-4b17-a962-2cb388254911',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH1v4okm5zmbvwkAx_sfcEuiD8j4PKcPtq-rpvLpQ.ttf',
    variant: '300',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'c0d1d162-7985-4fba-a775-ce6f4463c45d',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH1v4okm5zmbvwkAx_sfcEuiD8jPvKcPtq-rpvLpQ.ttf',
    variant: '200',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '2236d587-4122-472c-8854-4103b2c93251',
    origin: GF_SOURCE,
    url: GF_URL + 'exo2/v10/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvOcPtq-rpvLpQ.ttf',
    variant: '100',
    family: 'Exo 2',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'c41e59bd-94f8-4f5b-a7b9-4b0d7f89fdc4',
    origin: GF_SOURCE,
    url: GF_URL + 'comfortaa/v30/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4Y_LPrQVIT9c2c8.ttf',
    variant: '700',
    family: 'Comfortaa',
    category: 'display',
    version: 'v30',
    subset: 'latin',
  },
  {
    id: 'b7eaf2c2-cc88-4258-8776-202abe94e157',
    origin: GF_SOURCE,
    url: GF_URL + 'comfortaa/v30/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4bbLPrQVIT9c2c8.ttf',
    variant: '600',
    family: 'Comfortaa',
    category: 'display',
    version: 'v30',
    subset: 'latin',
  },
  {
    id: '9ffb99ea-0584-4c84-af8d-4eee3592a6df',
    origin: GF_SOURCE,
    url: GF_URL + 'comfortaa/v30/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4VrMPrQVIT9c2c8.ttf',
    variant: '500',
    family: 'Comfortaa',
    category: 'display',
    version: 'v30',
    subset: 'latin',
  },
  {
    id: '31b51d66-0906-4777-b008-3ba47a4e3a5e',
    origin: GF_SOURCE,
    url: GF_URL + 'comfortaa/v30/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf',
    variant: 'regular',
    family: 'Comfortaa',
    category: 'display',
    version: 'v30',
    subset: 'latin',
  },
  {
    id: 'cf85d47c-1555-47a7-a0b0-f4d218f38594',
    origin: GF_SOURCE,
    url: GF_URL + 'comfortaa/v30/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4TbMPrQVIT9c2c8.ttf',
    variant: '300',
    family: 'Comfortaa',
    category: 'display',
    version: 'v30',
    subset: 'latin',
  },
  {
    id: 'c30a1257-b6e1-4eb2-a7ff-2d874b44f63f',
    origin: GF_SOURCE,
    url: GF_URL + 'arvo/v14/tDbO2oWUg0MKqSIoVLHK9tD-hAHkGg.ttf',
    variant: '700italic',
    family: 'Arvo',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'e20d53bf-da2a-4ade-9a9c-e5632db87b2d',
    origin: GF_SOURCE,
    url: GF_URL + 'arvo/v14/tDbM2oWUg0MKoZw1yLTA8vL7lAE.ttf',
    variant: '700',
    family: 'Arvo',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'bf14a2b3-7a19-4adf-875e-e2c75f8e717e',
    origin: GF_SOURCE,
    url: GF_URL + 'arvo/v14/tDbN2oWUg0MKqSIQ6J7u_vvijQ.ttf',
    variant: 'italic',
    family: 'Arvo',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'f403b181-2cf1-4e0d-bb5a-99b3f06e9af5',
    origin: GF_SOURCE,
    url: GF_URL + 'arvo/v14/tDbD2oWUg0MKmSAa7Lzr7vs.ttf',
    variant: 'regular',
    family: 'Arvo',
    category: 'serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'f7968156-5559-4527-9896-05973a644914',
    origin: GF_SOURCE,
    url: GF_URL + 'zillaslab/v6/dFanZfeM_74wlPZtksIFaj8CRHGpXnp2fazkfg.ttf',
    variant: '700italic',
    family: 'Zilla Slab',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '79bfdf65-e669-468f-bc55-bea79cf6733d',
    origin: GF_SOURCE,
    url: GF_URL + 'zillaslab/v6/dFa5ZfeM_74wlPZtksIFYoEf2HSjWlhzbaw.ttf',
    variant: '700',
    family: 'Zilla Slab',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'e644e834-722b-42ba-8add-e015488ca16d',
    origin: GF_SOURCE,
    url: GF_URL + 'zillaslab/v6/dFanZfeM_74wlPZtksIFaj8CIHCpXnp2fazkfg.ttf',
    variant: '600italic',
    family: 'Zilla Slab',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '35c2f7a8-1ca8-496a-8986-5234aa79621f',
    origin: GF_SOURCE,
    url: GF_URL + 'zillaslab/v6/dFa5ZfeM_74wlPZtksIFYuUe2HSjWlhzbaw.ttf',
    variant: '600',
    family: 'Zilla Slab',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '8b750b30-7109-474a-9402-0cdf9e6369ca',
    origin: GF_SOURCE,
    url: GF_URL + 'zillaslab/v6/dFanZfeM_74wlPZtksIFaj8CDHepXnp2fazkfg.ttf',
    variant: '500italic',
    family: 'Zilla Slab',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'a7e01a9d-b8ba-4a12-b2b1-0aaa22fc5486',
    origin: GF_SOURCE,
    url: GF_URL + 'zillaslab/v6/dFa5ZfeM_74wlPZtksIFYskZ2HSjWlhzbaw.ttf',
    variant: '500',
    family: 'Zilla Slab',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '2b6b1463-debf-4238-84f4-a145b4a0fbb2',
    origin: GF_SOURCE,
    url: GF_URL + 'zillaslab/v6/dFa4ZfeM_74wlPZtksIFaj86-F6NVlFqdA.ttf',
    variant: 'italic',
    family: 'Zilla Slab',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '267acd39-52f5-4293-8627-0347bb538dcc',
    origin: GF_SOURCE,
    url: GF_URL + 'zillaslab/v6/dFa6ZfeM_74wlPZtksIFWj0w_HyIRlE.ttf',
    variant: 'regular',
    family: 'Zilla Slab',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'b197ac48-753b-41e6-9e9f-18b5e69b20d5',
    origin: GF_SOURCE,
    url: GF_URL + 'zillaslab/v6/dFanZfeM_74wlPZtksIFaj8CVHapXnp2fazkfg.ttf',
    variant: '300italic',
    family: 'Zilla Slab',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c6aed5bd-fe5e-40b3-bded-7bd358cfed1b',
    origin: GF_SOURCE,
    url: GF_URL + 'zillaslab/v6/dFa5ZfeM_74wlPZtksIFYpEY2HSjWlhzbaw.ttf',
    variant: '300',
    family: 'Zilla Slab',
    category: 'serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '56963e42-5b62-49ed-8bb7-98b0c77ab53b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'yanonekaffeesatz/v16/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoEdKpcGuLCnXkVA.ttf',
    variant: '700',
    family: 'Yanone Kaffeesatz',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'db3e7672-de5e-4889-afb7-4719adc5b09b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'yanonekaffeesatz/v16/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoKNKpcGuLCnXkVA.ttf',
    variant: '600',
    family: 'Yanone Kaffeesatz',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'e71f3a42-0ff2-40da-951a-2ee92c0b3af7',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'yanonekaffeesatz/v16/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoxNWpcGuLCnXkVA.ttf',
    variant: '500',
    family: 'Yanone Kaffeesatz',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '72107de7-3ff3-4177-a75e-f67c9708c5f6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'yanonekaffeesatz/v16/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWpcGuLCnXkVA.ttf',
    variant: 'regular',
    family: 'Yanone Kaffeesatz',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'ea184b63-871b-42d6-b27b-35ab1fcc479c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'yanonekaffeesatz/v16/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoqNWpcGuLCnXkVA.ttf',
    variant: '300',
    family: 'Yanone Kaffeesatz',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '3843252f-6945-4469-a473-0c1c3e3d9a9c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'yanonekaffeesatz/v16/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftodtWpcGuLCnXkVA.ttf',
    variant: '200',
    family: 'Yanone Kaffeesatz',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'd3521e53-560a-474d-9fc9-561aea0afc6e',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrW3bWvIMHYrtUxg.ttf',
    variant: '900italic',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '7509f0d7-b751-4692-8f9a-92585983b4cb',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxwL3I-JCGChYJ8VI-L6OO_au7B45L0_3HcuKECcrs.ttf',
    variant: '900',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '47d238b1-615f-4165-bd74-ff4d69381a25',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrf3fWvIMHYrtUxg.ttf',
    variant: '800italic',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f6700f27-a33a-402b-a164-ac3b18269c23',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxwL3I-JCGChYJ8VI-L6OO_au7B47b1_3HcuKECcrs.ttf',
    variant: '800',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'd91e810f-cde0-4dd4-a8f1-860facbd94d1',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrY3TWvIMHYrtUxg.ttf',
    variant: '700italic',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '4d407c07-1402-4722-af1c-8decb25cf0c6',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxwL3I-JCGChYJ8VI-L6OO_au7B46r2_3HcuKECcrs.ttf',
    variant: '700',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '49bb6555-703f-4a79-bf46-2b927d2b8fdb',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrB3XWvIMHYrtUxg.ttf',
    variant: '600italic',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '8bd1ebfd-26f3-4982-b0e3-a2999cd3e91b',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxwL3I-JCGChYJ8VI-L6OO_au7B4873_3HcuKECcrs.ttf',
    variant: '600',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '5f19cfc5-41b5-4998-8213-016d34a3d69e',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrK3LWvIMHYrtUxg.ttf',
    variant: '500italic',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '5cf50991-85eb-49e9-b572-b7c3de723ed7',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxwL3I-JCGChYJ8VI-L6OO_au7B4-Lw_3HcuKECcrs.ttf',
    variant: '500',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1082fa2e-78d8-41d1-98b0-3421cc88099d',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxxL3I-JCGChYJ8VI-L6OO_au7B6xTT31vytKgbaw.ttf',
    variant: 'italic',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '97f86ccd-488d-49ee-922c-980a595fe09b',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTx3L3I-JCGChYJ8VI-L6OO_au7B2xbZ23n3pKg.ttf',
    variant: 'regular',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'f8960dde-190c-4876-a6ff-c9edd1364d41',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrc3PWvIMHYrtUxg.ttf',
    variant: '300italic',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'db834d95-62e7-41be-bbf5-4a9f4f616058',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxwL3I-JCGChYJ8VI-L6OO_au7B47rx_3HcuKECcrs.ttf',
    variant: '300',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1ada680a-7048-47f9-bfd9-9fa79714f6d1',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrF3DWvIMHYrtUxg.ttf',
    variant: '200italic',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '80e287ec-10f2-4c22-9277-89e5158b60be',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxwL3I-JCGChYJ8VI-L6OO_au7B497y_3HcuKECcrs.ttf',
    variant: '200',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1f70bde2-ac5a-4cc9-8b96-c8e7fbf83106',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxzL3I-JCGChYJ8VI-L6OO_au7B6xTru1H2lq0La6JN.ttf',
    variant: '100italic',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1bac8fb1-b6ac-473c-9353-e52830aaa109',
    origin: GF_SOURCE,
    url: GF_URL + 'barlowcondensed/v5/HTxxL3I-JCGChYJ8VI-L6OO_au7B43LT31vytKgbaw.ttf',
    variant: '100',
    family: 'Barlow Condensed',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '30ded3c7-9b02-47bc-b576-a54bc813e328',
    origin: GF_SOURCE,
    url: GF_URL + 'bebasneue/v2/JTUSjIg69CK48gW7PXooxW5rygbi49c.ttf',
    variant: 'regular',
    family: 'Bebas Neue',
    category: 'display',
    version: 'v2',
    subset: 'latin',
  },
  {
    id: '19d5c05b-bec1-4b09-94ac-431caaf3db3a',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIVzD-0qpwxpaWvjeD0X88SAOeauXEGISyqwacqdrKvbQ.ttf',
    variant: '900italic',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'cfb8c0c3-1250-4c9c-a69a-d80be02dbd0f',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasfcZhSugxYUvZrI.ttf',
    variant: '900',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8e945916-7a33-49e6-a88a-7cc5efea6b28',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIVzD-0qpwxpaWvjeD0X88SAOeauXEGGS6qwacqdrKvbQ.ttf',
    variant: '700italic',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '817478e9-f2fb-40ff-9ba0-160bb514e22d',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasc8bhSugxYUvZrI.ttf',
    variant: '700',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'be5b9236-0f01-4545-b56e-bca9efd28826',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIVzD-0qpwxpaWvjeD0X88SAOeauXEGfS-qwacqdrKvbQ.ttf',
    variant: '600italic',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '9a365305-c2e1-40e6-b5f9-c63179f1b4f6',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasasahSugxYUvZrI.ttf',
    variant: '600',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '2ecd1965-531e-4d53-b3a8-19d264c56f9f',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIWzD-0qpwxpaWvjeD0X88SAOeauXE-pQGOyYw2fw.ttf',
    variant: 'italic',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '2cf51a4e-577b-44b8-b5b1-296a2a9cf2df',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIQzD-0qpwxpaWvjeD0X88SAOeaiXM0oSOL2Yw.ttf',
    variant: 'regular',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '38bb80bd-3664-40e6-9f2e-4a94d0bb9be0',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIVzD-0qpwxpaWvjeD0X88SAOeauXEGCSmqwacqdrKvbQ.ttf',
    variant: '300italic',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'fc2bb1de-878a-4382-83e5-3583c74e0b49',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasd8chSugxYUvZrI.ttf',
    variant: '300',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'd04fc9ec-362a-4f9c-be96-90e3327602af',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIVzD-0qpwxpaWvjeD0X88SAOeauXEGbSqqwacqdrKvbQ.ttf',
    variant: '200italic',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b8ab011e-66e6-458d-864f-83d58f171f88',
    origin: GF_SOURCE,
    url: GF_URL + 'sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasbsfhSugxYUvZrI.ttf',
    variant: '200',
    family: 'Source Serif Pro',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '1f1eb828-b9d8-4261-86e5-aad855f83585',
    origin: GF_SOURCE,
    url: GF_URL + 'varelaround/v13/w8gdH283Tvk__Lua32TysjIvoMGOD9gxZw.ttf',
    variant: 'regular',
    family: 'Varela Round',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '2131eb15-94c1-48fe-b98d-4b27210e9905',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX7KVElMYYaJe8bpLHnCwDKhdTmrINscf3vBmpl8A.ttf',
    variant: '700italic',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c17ef39b-dec1-47c0-b610-8ecc35b6ebe6',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX9KVElMYYaJe8bpLHnCwDKjWr7MIZmdd_qFmo.ttf',
    variant: '700',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '74004a4c-71f5-4b54-ac4e-7e9f497f4fa7',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJscf3vBmpl8A.ttf',
    variant: '600italic',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '64276459-dcfb-42cd-bbb3-0908b92c5544',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX9KVElMYYaJe8bpLHnCwDKjQ76MIZmdd_qFmo.ttf',
    variant: '600',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '0036b6a7-5733-4cd0-b78e-01d09b1ec776',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX7KVElMYYaJe8bpLHnCwDKhdTm5IVscf3vBmpl8A.ttf',
    variant: '500italic',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f94f3d72-1c3a-4e67-9f9a-109eda6cf728',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX9KVElMYYaJe8bpLHnCwDKjSL9MIZmdd_qFmo.ttf',
    variant: '500',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'c02b470c-ed84-43f7-9333-7a0980fa12bc',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX-KVElMYYaJe8bpLHnCwDKhdTeEKxIedbzDw.ttf',
    variant: 'italic',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'a1fad2a5-8249-4575-bf9d-06ee6d2902f2',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYXgKVElMYYaJe8bpLHnCwDKtdbUFI5NadY.ttf',
    variant: 'regular',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '87951914-6854-4002-8b8e-bbb35e1d7eff',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRscf3vBmpl8A.ttf',
    variant: '300italic',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '597c08ba-01d4-4a68-b5a7-12050f6d553f',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX9KVElMYYaJe8bpLHnCwDKjXr8MIZmdd_qFmo.ttf',
    variant: '300',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '234fb1ba-4982-4b62-99a3-ad8f65785304',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX7KVElMYYaJe8bpLHnCwDKhdTm2Idscf3vBmpl8A.ttf',
    variant: '200italic',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'd2e6340c-96aa-42a0-afa6-34edcf3da8b0',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX9KVElMYYaJe8bpLHnCwDKjR7_MIZmdd_qFmo.ttf',
    variant: '200',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'f7986d68-5b67-4908-b8ca-455da0d90283',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX8KVElMYYaJe8bpLHnCwDKhdTmdKZMW9PjD3N8.ttf',
    variant: '100italic',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'cf2d5f77-ee35-4238-abc0-45b6ea729727',
    origin: GF_SOURCE,
    url: GF_URL + 'ibmplexsans/v8/zYX-KVElMYYaJe8bpLHnCwDKjbLeEKxIedbzDw.ttf',
    variant: '100',
    family: 'IBM Plex Sans',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '2ef52d5f-f527-4c6b-acb5-4788d941cfb6',
    origin: GF_SOURCE,
    url: GF_URL + 'cairo/v10/SLXLc1nY6Hkvalqiab6M7dd8aGZk.ttf',
    variant: '900',
    family: 'Cairo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '7d44eb8f-02db-4345-8ecb-c050e59917dd',
    origin: GF_SOURCE,
    url: GF_URL + 'cairo/v10/SLXLc1nY6Hkvalqaa76M7dd8aGZk.ttf',
    variant: '700',
    family: 'Cairo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4d343a86-bccc-41aa-890c-078e3a3c68ab',
    origin: GF_SOURCE,
    url: GF_URL + 'cairo/v10/SLXLc1nY6Hkvalr-ar6M7dd8aGZk.ttf',
    variant: '600',
    family: 'Cairo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '0a777c8d-48a0-412b-b563-6a2a734616e3',
    origin: GF_SOURCE,
    url: GF_URL + 'cairo/v10/SLXGc1nY6HkvamImRJqExst1.ttf',
    variant: 'regular',
    family: 'Cairo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '29135512-99f4-4e74-abf6-4e5c161ae262',
    origin: GF_SOURCE,
    url: GF_URL + 'cairo/v10/SLXLc1nY6HkvalqKbL6M7dd8aGZk.ttf',
    variant: '300',
    family: 'Cairo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '6298cbf1-2f78-458f-a526-28c966895d4c',
    origin: GF_SOURCE,
    url: GF_URL + 'cairo/v10/SLXLc1nY6Hkvalrub76M7dd8aGZk.ttf',
    variant: '200',
    family: 'Cairo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '1d42f4aa-63c5-441f-82ea-adfbbab75385',
    origin: GF_SOURCE,
    url: GF_URL + 'abel/v12/MwQ5bhbm2POE6VhLPJp6qGI.ttf',
    variant: 'regular',
    family: 'Abel',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '527aad36-2b27-48f1-9d55-ab64213ebfd5',
    origin: GF_SOURCE,
    url: GF_URL + 'lobster/v23/neILzCirqoswsqX9_oWsMqEzSJQ.ttf',
    variant: 'regular',
    family: 'Lobster',
    category: 'display',
    version: 'v23',
    subset: 'latin',
  },
  {
    id: '90dd259f-14d8-44ef-8ac5-330b7e8968cf',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B1i0HTeB9ptDqpw.ttf',
    variant: '700',
    family: 'Dancing Script',
    category: 'handwriting',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'db420685-192a-4611-b48b-0b6235a8e7ee',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B7y0HTeB9ptDqpw.ttf',
    variant: '600',
    family: 'Dancing Script',
    category: 'handwriting',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '3a0f5aba-3194-47f8-b047-e04ad9a61153',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BAyoHTeB9ptDqpw.ttf',
    variant: '500',
    family: 'Dancing Script',
    category: 'handwriting',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '3430f6ac-5d5a-4adb-8fd4-955d307f0615',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTeB9ptDqpw.ttf',
    variant: 'regular',
    family: 'Dancing Script',
    category: 'handwriting',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'd6d30708-8751-4975-ad51-48a8f92ddb8f',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONNXs-cv7Gy0DRzS.ttf',
    variant: '900italic',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '54af70df-33fb-41f9-a3c8-22486ac8536e',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_XiYsKILxRpg3hIP6sJ7fM7PqtvszztO0rzmmkDQ.ttf',
    variant: '900',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '5a88f6ce-a3ad-4cc9-8411-56e3e568d4a7',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONNvsecv7Gy0DRzS.ttf',
    variant: '700italic',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '9d88fc11-93ad-43e3-8c1f-71cfc42f3ca7',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_XiYsKILxRpg3hIP6sJ7fM7Pqths7ztO0rzmmkDQ.ttf',
    variant: '700',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '585d3dfc-e2e9-4901-a3c8-c0553e9957c5',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONMLsOcv7Gy0DRzS.ttf',
    variant: '600italic',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '4369b6fa-39f1-42c0-a09f-afc6a86ec194',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_XiYsKILxRpg3hIP6sJ7fM7Pqt4s_ztO0rzmmkDQ.ttf',
    variant: '600',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '2944a949-6ddf-41b3-92bc-c7572fc09d82',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONMnt-cv7Gy0DRzS.ttf',
    variant: '500italic',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '9e46cf11-628f-4686-8a97-47f808475b72',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_XiYsKILxRpg3hIP6sJ7fM7PqtzsjztO0rzmmkDQ.ttf',
    variant: '500',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'a4b07e2b-419f-43bb-998c-72bf8625e7a0',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_QiYsKILxRpg3hIP6sJ7fM7PqlOOvTnsMnx3C9.ttf',
    variant: 'italic',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '5bb14e7b-a2a2-409b-b5ac-96bd42db3a04',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_SiYsKILxRpg3hIP6sJ7fM7PqVOuHXvMY3xw.ttf',
    variant: 'regular',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'abc77017-cf4a-4660-ae9e-e71a900efe1c',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONN_tucv7Gy0DRzS.ttf',
    variant: '300italic',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '28b70ada-c189-4c98-a4f4-e77518bc7a06',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_XiYsKILxRpg3hIP6sJ7fM7PqtlsnztO0rzmmkDQ.ttf',
    variant: '300',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '31d81e67-3bad-44c7-8bfb-7940007c1d52',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_ViYsKILxRpg3hIP6sJ7fM7PqlONMbtecv7Gy0DRzS.ttf',
    variant: '200italic',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '7ab23ea6-ac23-4564-8970-e7be6428a41c',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcecodepro/v14/HI_XiYsKILxRpg3hIP6sJ7fM7Pqt8srztO0rzmmkDQ.ttf',
    variant: '200',
    family: 'Source Code Pro',
    category: 'monospace',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'a1b5be24-fc80-4ca7-827e-2a01a2c18945',
    origin: GF_SOURCE,
    url: GF_URL + 'fjallaone/v8/Yq6R-LCAWCX3-6Ky7FAFnOZwkxgtUb8.ttf',
    variant: 'regular',
    family: 'Fjalla One',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '6f8ea897-4a62-49cc-868f-a26a9e743466',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsontext/v11/wlprgwHKFkZgtmSR3NB0oRJfajDqDtNV9rRPfrKu.ttf',
    variant: '700italic',
    family: 'Crimson Text',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '24d0af2e-4a0f-4155-a255-e3dde21e5d91',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsontext/v11/wlppgwHKFkZgtmSR3NB0oRJX1C12C9lR1LFffg.ttf',
    variant: '700',
    family: 'Crimson Text',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '5673afec-fc38-4255-935c-d8c2755fbf5f',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsontext/v11/wlprgwHKFkZgtmSR3NB0oRJfajCOD9NV9rRPfrKu.ttf',
    variant: '600italic',
    family: 'Crimson Text',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '1e6c95b8-d21e-4b8e-87eb-4b13363aa70c',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsontext/v11/wlppgwHKFkZgtmSR3NB0oRJXsCx2C9lR1LFffg.ttf',
    variant: '600',
    family: 'Crimson Text',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0fc03a63-94e5-4f8f-b324-b64305c0dadb',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsontext/v11/wlpogwHKFkZgtmSR3NB0oRJfaghWIfdd3ahG.ttf',
    variant: 'italic',
    family: 'Crimson Text',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '7304d3f4-0dc4-4a2a-bafb-3ba93569f319',
    origin: GF_SOURCE,
    url: GF_URL + 'crimsontext/v11/wlp2gwHKFkZgtmSR3NB0oRJvaAJSA_JN3Q.ttf',
    variant: 'regular',
    family: 'Crimson Text',
    category: 'serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '485fc7f7-cf05-42c7-8c77-db3f48c34389',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cSfvOWHpzveWxBw.ttf',
    variant: '900italic',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '95fe5a9a-fdaa-47e4-9eb0-cd93110e50dd',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cYPvOWHpzveWxBw.ttf',
    variant: '800italic',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '655f6b63-1f3f-4976-9842-b4a7afe26245',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cB_vOWHpzveWxBw.ttf',
    variant: '700italic',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'a3ae3d9f-ff7b-4bac-af67-f87ea6d8fe64',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cPvvOWHpzveWxBw.ttf',
    variant: '600italic',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '3057fd41-d06a-489e-953c-90a02d3ccc56',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6c0vzOWHpzveWxBw.ttf',
    variant: '500italic',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '3dada4f3-0bcc-4f11-a45d-267767997396',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6c4PzOWHpzveWxBw.ttf',
    variant: 'italic',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '2aaa0162-5762-4a13-be47-7fe0c428cf41',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cvvzOWHpzveWxBw.ttf',
    variant: '300italic',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '8ff2974d-fc18-4d7d-8d8a-1b9a3be43ea5',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cYPzOWHpzveWxBw.ttf',
    variant: '200italic',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '6bf94f48-1712-4725-98d5-b4ca766cc48f',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6c4P3OWHpzveWxBw.ttf',
    variant: '100italic',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'd13b4324-2ab5-4796-9894-6d68d914172e',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8V_YCL_EXFh2reU.ttf',
    variant: '900',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '881b8edb-1efb-4b8f-a8e3-2baaa44088da',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8XbYCL_EXFh2reU.ttf',
    variant: '800',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '1e206ab9-db93-4382-8028-3f1595d22112',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8RHYCL_EXFh2reU.ttf',
    variant: '700',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '8f52525b-a897-4127-9514-18234cecc1cd',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8SjYCL_EXFh2reU.ttf',
    variant: '600',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '3caf017a-60da-4435-bf6a-25e287973dad',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8cTfCL_EXFh2reU.ttf',
    variant: '500',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'fd66d771-a766-4a4f-adad-69abc4bab701',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbfCL_EXFh2reU.ttf',
    variant: 'regular',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '20200d90-841a-4eb6-8217-fe691d4764bc',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8ajfCL_EXFh2reU.ttf',
    variant: '300',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'ed40eb0a-51b4-4d15-9e4d-ffdfd1228b77',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8XbfCL_EXFh2reU.ttf',
    variant: '200',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '83dc24a3-aafc-46a8-9995-e0fb3ba37ab3',
    origin: GF_SOURCE,
    url: GF_URL + 'bitter/v17/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbeCL_EXFh2reU.ttf',
    variant: '100',
    family: 'Bitter',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'fce7f31f-5903-4367-94b9-2847244efcad',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanssc/v12/k3kIo84MPvpLmixcA63oeALZraWt9yX6-q2CGg.otf',
    variant: '900',
    family: 'Noto Sans SC',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '0cd1e7f0-d52c-4421-b896-3938281869a7',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanssc/v12/k3kIo84MPvpLmixcA63oeALZlaet9yX6-q2CGg.otf',
    variant: '700',
    family: 'Noto Sans SC',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '20f7bee5-f401-4181-b735-9ee8a45983f4',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanssc/v12/k3kIo84MPvpLmixcA63oeALZ3aGt9yX6-q2CGg.otf',
    variant: '500',
    family: 'Noto Sans SC',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '1dffbff8-29b9-4406-ac7f-c58a1768095e',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanssc/v12/k3kXo84MPvpLmixcA63oeALhL4iJ-Q7m8w.otf',
    variant: 'regular',
    family: 'Noto Sans SC',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '22191db6-e76a-483d-9ab8-cbefc1f6885d',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanssc/v12/k3kIo84MPvpLmixcA63oeALZhaCt9yX6-q2CGg.otf',
    variant: '300',
    family: 'Noto Sans SC',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '61bdcd90-128c-41fa-8870-8e40d3e4fd17',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanssc/v12/k3kJo84MPvpLmixcA63oeALZTYKL2wv287Sb.otf',
    variant: '100',
    family: 'Noto Sans SC',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'd935a3be-087a-4342-bbcf-b7f8614c6259',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHIPWlwkzuA_u1Bg.ttf',
    variant: '700italic',
    family: 'Cabin',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: 'bf2ed290-d05a-42cf-b0c1-960f9cd946e9',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHGfWlwkzuA_u1Bg.ttf',
    variant: '600italic',
    family: 'Cabin',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: 'da7b3c61-699e-48ce-95a3-c134b867d7e6',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXH9fKlwkzuA_u1Bg.ttf',
    variant: '500italic',
    family: 'Cabin',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: 'ddb41ccf-eb6f-4202-ab63-aceaa9a69788',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHx_KlwkzuA_u1Bg.ttf',
    variant: 'italic',
    family: 'Cabin',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '5d075457-5a87-4df6-bc94-d6d85939facf',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkbqDL7Gvxm7rE_s.ttf',
    variant: '700',
    family: 'Cabin',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: 'b1b323ef-9735-4cf0-a2e5-eeb15fe7afae',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkYODL7Gvxm7rE_s.ttf',
    variant: '600',
    family: 'Cabin',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '2eff7138-bdc3-4e8d-80aa-010198c3ed24',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkW-EL7Gvxm7rE_s.ttf',
    variant: '500',
    family: 'Cabin',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: 'b2677b9c-01f4-4383-a60f-c7a24f88857c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EL7Gvxm7rE_s.ttf',
    variant: 'regular',
    family: 'Cabin',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '1f97d3c4-8a14-4eb6-9249-96d216e1a2e1',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lppyw7aRr8lleY2co.ttf',
    variant: '900',
    family: 'Inconsolata',
    category: 'monospace',
    version: 'v21',
    subset: 'latin',
  },
  {
    id: '8b87ed5c-2ca7-48c9-aced-622808c91594',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7LppwU7aRr8lleY2co.ttf',
    variant: '800',
    family: 'Inconsolata',
    category: 'monospace',
    version: 'v21',
    subset: 'latin',
  },
  {
    id: 'ac0ad3e5-be94-4810-8de8-9dd6639e9732',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp2I7aRr8lleY2co.ttf',
    variant: '700',
    family: 'Inconsolata',
    category: 'monospace',
    version: 'v21',
    subset: 'latin',
  },
  {
    id: '80991108-4900-47c3-afa0-b4eb31b1352e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp1s7aRr8lleY2co.ttf',
    variant: '600',
    family: 'Inconsolata',
    category: 'monospace',
    version: 'v21',
    subset: 'latin',
  },
  {
    id: '9273d8aa-c18f-466b-a291-5aa0f5912ffe',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp7c8aRr8lleY2co.ttf',
    variant: '500',
    family: 'Inconsolata',
    category: 'monospace',
    version: 'v21',
    subset: 'latin',
  },
  {
    id: 'ec29f333-7217-4916-8fe2-d98c9cbf5922',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp4U8aRr8lleY2co.ttf',
    variant: 'regular',
    family: 'Inconsolata',
    category: 'monospace',
    version: 'v21',
    subset: 'latin',
  },
  {
    id: '5c28d6ae-4c12-4ae7-b4fd-5a0fc80bcc49',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp9s8aRr8lleY2co.ttf',
    variant: '300',
    family: 'Inconsolata',
    category: 'monospace',
    version: 'v21',
    subset: 'latin',
  },
  {
    id: '69ca32dd-541b-44f8-9db8-fe6e69b3098e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7LppwU8aRr8lleY2co.ttf',
    variant: '200',
    family: 'Inconsolata',
    category: 'monospace',
    version: 'v21',
    subset: 'latin',
  },
  {
    id: '008105a0-8c03-4d32-ae20-635874012bfd',
    origin: GF_SOURCE,
    url: GF_URL + 'librebaskerville/v9/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTjYwI8Gcw6Oi.ttf',
    variant: '700',
    family: 'Libre Baskerville',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'f3c172bb-1dd4-4370-be8f-128a56f11953',
    origin: GF_SOURCE,
    url: GF_URL + 'librebaskerville/v9/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcaxYaDc2V2ro.ttf',
    variant: 'italic',
    family: 'Libre Baskerville',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '8d0b407c-2b5e-46c6-b8df-55cfdb26ae2b',
    origin: GF_SOURCE,
    url: GF_URL + 'librebaskerville/v9/kmKnZrc3Hgbbcjq75U4uslyuy4kn0pNeYRI4CN2V.ttf',
    variant: 'regular',
    family: 'Libre Baskerville',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '33f76c7e-054e-4d61-a3c1-1d8256f929c1',
    origin: GF_SOURCE,
    url: GF_URL + 'hind/v11/5aU19_a8oxmIfNJdIRuYjDpf5Vw.ttf',
    variant: '700',
    family: 'Hind',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '5e50b48b-a69a-45e3-a66e-86de4ef922b6',
    origin: GF_SOURCE,
    url: GF_URL + 'hind/v11/5aU19_a8oxmIfLZcIRuYjDpf5Vw.ttf',
    variant: '600',
    family: 'Hind',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '3c237ff1-6248-4646-b357-3c06d5e3827f',
    origin: GF_SOURCE,
    url: GF_URL + 'hind/v11/5aU19_a8oxmIfJpbIRuYjDpf5Vw.ttf',
    variant: '500',
    family: 'Hind',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '9c434a04-4694-4a9c-b358-bebcdbcc75c5',
    origin: GF_SOURCE,
    url: GF_URL + 'hind/v11/5aU69_a8oxmIRG5yBROzkDM.ttf',
    variant: 'regular',
    family: 'Hind',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '915fad26-594c-429a-a57d-c04cde8b81a2',
    origin: GF_SOURCE,
    url: GF_URL + 'hind/v11/5aU19_a8oxmIfMJaIRuYjDpf5Vw.ttf',
    variant: '300',
    family: 'Hind',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '1a906db2-1681-4500-a215-30f86588f393',
    origin: GF_SOURCE,
    url: GF_URL + 'dosis/v19/HhyJU5sn9vOmLxNkIwRSjTVNWLEJt7QV3BkFTq4EPw.ttf',
    variant: '800',
    family: 'Dosis',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'b0983ffe-4c85-4be7-8fb5-8819b50d9f6f',
    origin: GF_SOURCE,
    url: GF_URL + 'dosis/v19/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ0LQV3BkFTq4EPw.ttf',
    variant: '700',
    family: 'Dosis',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'cac044f2-1a75-4210-9c18-a36a8bf5a3eb',
    origin: GF_SOURCE,
    url: GF_URL + 'dosis/v19/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ6bQV3BkFTq4EPw.ttf',
    variant: '600',
    family: 'Dosis',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '22379622-d196-446e-87a0-9e18d3fbc803',
    origin: GF_SOURCE,
    url: GF_URL + 'dosis/v19/HhyJU5sn9vOmLxNkIwRSjTVNWLEJBbMV3BkFTq4EPw.ttf',
    variant: '500',
    family: 'Dosis',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '0044d9c8-e4c6-4b5f-abb1-d5313c781f32',
    origin: GF_SOURCE,
    url: GF_URL + 'dosis/v19/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7MV3BkFTq4EPw.ttf',
    variant: 'regular',
    family: 'Dosis',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'e5a950f7-1d67-4a4c-994c-0f00205dadd6',
    origin: GF_SOURCE,
    url: GF_URL + 'dosis/v19/HhyJU5sn9vOmLxNkIwRSjTVNWLEJabMV3BkFTq4EPw.ttf',
    variant: '300',
    family: 'Dosis',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'f03ae5bb-1115-4109-b55c-70fcef5b8867',
    origin: GF_SOURCE,
    url: GF_URL + 'dosis/v19/HhyJU5sn9vOmLxNkIwRSjTVNWLEJt7MV3BkFTq4EPw.ttf',
    variant: '200',
    family: 'Dosis',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'bd059a9f-95c9-4fb7-bca5-33bc14fd0830',
    origin: GF_SOURCE,
    url: GF_URL + 'arimo/v17/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9jQxrEdwcoaKww.ttf',
    variant: '700italic',
    family: 'Arimo',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '45509831-af5f-4e12-a2d7-50098f840994',
    origin: GF_SOURCE,
    url: GF_URL + 'arimo/v17/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9aQxrEdwcoaKww.ttf',
    variant: '600italic',
    family: 'Arimo',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '3b41303e-a0a6-42d7-aac7-8fd5e668cde6',
    origin: GF_SOURCE,
    url: GF_URL + 'arimo/v17/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-2RBrEdwcoaKww.ttf',
    variant: '500italic',
    family: 'Arimo',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '380b8b9b-9284-4146-847c-106ab06922d1',
    origin: GF_SOURCE,
    url: GF_URL + 'arimo/v17/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-ERBrEdwcoaKww.ttf',
    variant: 'italic',
    family: 'Arimo',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'f9950d61-bf4d-48a6-ade6-cb11de288600',
    origin: GF_SOURCE,
    url: GF_URL + 'arimo/v17/P5sfzZCDf9_T_3cV7NCUECyoxNk3CstsBxDAVQI4aA.ttf',
    variant: '700',
    family: 'Arimo',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '66bb7222-887f-4d1d-8090-7d817555249b',
    origin: GF_SOURCE,
    url: GF_URL + 'arimo/v17/P5sfzZCDf9_T_3cV7NCUECyoxNk3M8tsBxDAVQI4aA.ttf',
    variant: '600',
    family: 'Arimo',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'b2088dce-edf5-46ad-b9fa-d0997a7c0302',
    origin: GF_SOURCE,
    url: GF_URL + 'arimo/v17/P5sfzZCDf9_T_3cV7NCUECyoxNk338xsBxDAVQI4aA.ttf',
    variant: '500',
    family: 'Arimo',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'c503c2ff-b092-4b8e-a6a0-d4d819b00203',
    origin: GF_SOURCE,
    url: GF_URL + 'arimo/v17/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxsBxDAVQI4aA.ttf',
    variant: 'regular',
    family: 'Arimo',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'be5158b5-aa5c-4466-a45a-de80d5852d6e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05obVQ9DMTedX1sGE.ttf',
    variant: '900italic',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'c4823d7a-bd6a-4f34-9062-b75081e7712d',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05ob8Q9DMTedX1sGE.ttf',
    variant: '800italic',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '21aa78e1-3714-41ce-9beb-463d007cc9c3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oabQ9DMTedX1sGE.ttf',
    variant: '700italic',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '8cfd1718-3d38-46c5-945f-bb85f32255ef',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oaiQ9DMTedX1sGE.ttf',
    variant: '600italic',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '765a49d5-f09b-464a-a246-82589bb63560',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oZORNDMTedX1sGE.ttf',
    variant: '500italic',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'e0ffc75c-2154-40f8-aba4-68c4aa383729',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oZ8RNDMTedX1sGE.ttf',
    variant: 'italic',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'f7b9d012-a1e7-43a7-8f2f-a2086b6cca06',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oYiRNDMTedX1sGE.ttf',
    variant: '300italic',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '3d21c1d5-3fa3-459d-90d6-798d3bfd0ecc',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05ob8RNDMTedX1sGE.ttf',
    variant: '200italic',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '52e7ec62-ffb7-4c52-b515-6aeabf4a098c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oZ8RdDMTedX1sGE.ttf',
    variant: '100italic',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '7971a0d9-48e2-49e4-82d7-58b9431af01a',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhh8KUB9rIb-JH1g.ttf',
    variant: '900',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'fffcfd6e-b7c6-4eb9-a3e0-e811f98ac580',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhrsKUB9rIb-JH1g.ttf',
    variant: '800',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '7d75168a-9d95-474d-93a4-93227bf870c0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhycKUB9rIb-JH1g.ttf',
    variant: '700',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '6ae91394-f89f-47e8-8ff1-44c47507931c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduh8MKUB9rIb-JH1g.ttf',
    variant: '600',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'e9160e87-7fcb-49ef-98cd-15a3ffa14d62',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhHMWUB9rIb-JH1g.ttf',
    variant: '500',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'a1f7e5ae-c660-4c83-b5e2-e4ebef189f06',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsWUB9rIb-JH1g.ttf',
    variant: 'regular',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd8cd0e7a-7049-4566-9756-810122763043',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhcMWUB9rIb-JH1g.ttf',
    variant: '300',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '6ba38ae4-e50b-4197-9232-5a01ec9030e7',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhrsWUB9rIb-JH1g.ttf',
    variant: '200',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '2b2ea59b-79c1-4277-a4e9-697ecc52a210',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'librefranklin/v7/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsSUB9rIb-JH1g.ttf',
    variant: '100',
    family: 'Libre Franklin',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'd076b2bf-7502-454e-af9b-277e1de7f7f0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinsans/v17/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTU4VhKibpUV3MEQ.ttf',
    variant: '700italic',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'b3317fc5-35cf-40f0-bd01-4b52e06ef5c0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinsans/v17/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTaoVhKibpUV3MEQ.ttf',
    variant: '600italic',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '64cb93f3-58ea-4b2a-95fa-00f4cc018684',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinsans/v17/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCThoJhKibpUV3MEQ.ttf',
    variant: '500italic',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'eb507179-100b-464a-8834-767272f2d7ff',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinsans/v17/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTtIJhKibpUV3MEQ.ttf',
    variant: 'italic',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '6c2fc03f-0faa-4ed0-a8e3-733458c4aa3c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinsans/v17/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCT6oJhKibpUV3MEQ.ttf',
    variant: '300italic',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '45c625fb-f34a-44f7-b7f8-459e7906623c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinsans/v17/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTNIJhKibpUV3MEQ.ttf',
    variant: '200italic',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '3e9ae569-23ce-44d8-b804-8fc2f2ee7c66',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'josefinsans/v17/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTtINhKibpUV3MEQ.ttf',
    variant: '100italic',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'bb1869f0-1830-47bc-8b7b-7858a0d6ba79',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinsans/v17/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_N_XXMFrLgTsQV0.ttf',
    variant: '700',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '99e8cbd1-57a6-4ed9-ad67-bb8ba1d07482',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinsans/v17/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ObXXMFrLgTsQV0.ttf',
    variant: '600',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'cf1cd64a-7065-4685-b3f9-b4ac4fb6fc9d',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinsans/v17/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQXMFrLgTsQV0.ttf',
    variant: '500',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '69c574f7-3330-44d0-abb2-f3df6201da4b',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinsans/v17/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQXMFrLgTsQV0.ttf',
    variant: 'regular',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'a42560b3-cbe1-4589-8543-ea2d1c62f6e9',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinsans/v17/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_GbQXMFrLgTsQV0.ttf',
    variant: '300',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '6ad4e50e-23d9-4861-aec9-82abc75a5309',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinsans/v17/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_LjQXMFrLgTsQV0.ttf',
    variant: '200',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '4221ff96-b17f-4ff6-8e5d-fb69733edbf8',
    origin: GF_SOURCE,
    url:
      GF_URL + 'josefinsans/v17/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjRXMFrLgTsQV0.ttf',
    variant: '100',
    family: 'Josefin Sans',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '5ab6e9c3-aad8-4dda-aafb-0e4b0f12df41',
    origin: GF_SOURCE,
    url: GF_URL + 'ptsansnarrow/v12/BngSUXNadjH0qYEzV7ab-oWlsbg95DiCUfzgRd-3.ttf',
    variant: '700',
    family: 'PT Sans Narrow',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '3c2ea906-1bee-4060-a786-4ea6201c9e53',
    origin: GF_SOURCE,
    url: GF_URL + 'ptsansnarrow/v12/BngRUXNadjH0qYEzV7ab-oWlsYCByxyKeuDp.ttf',
    variant: 'regular',
    family: 'PT Sans Narrow',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '9b783b85-f8c8-4fc4-b5cc-273102b2dd2c',
    origin: GF_SOURCE,
    url: GF_URL + 'anton/v15/1Ptgg87LROyAm0K08i4gS7lu.ttf',
    variant: 'regular',
    family: 'Anton',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'ecb3de55-95d4-4b16-b6b8-8e0cedb29b2a',
    origin: GF_SOURCE,
    url: GF_URL + 'heebo/v10/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EICucckOnz02SXQ.ttf',
    variant: '900',
    family: 'Heebo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '39477665-1779-4730-9ff1-3c0070b043da',
    origin: GF_SOURCE,
    url: GF_URL + 'heebo/v10/NGSpv5_NC0k9P_v6ZUCbLRAHxK1ECSucckOnz02SXQ.ttf',
    variant: '800',
    family: 'Heebo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '648bafc3-c21a-412a-9c65-e42f8e6be207',
    origin: GF_SOURCE,
    url: GF_URL + 'heebo/v10/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EbiucckOnz02SXQ.ttf',
    variant: '700',
    family: 'Heebo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b88d196a-5364-4e78-9b7b-c354c6bc4817',
    origin: GF_SOURCE,
    url: GF_URL + 'heebo/v10/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EVyucckOnz02SXQ.ttf',
    variant: '600',
    family: 'Heebo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '1f1f9ca3-2093-44e1-a78e-1544db3c89e8',
    origin: GF_SOURCE,
    url: GF_URL + 'heebo/v10/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EuyycckOnz02SXQ.ttf',
    variant: '500',
    family: 'Heebo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'c75af790-358b-482e-878b-1f90a878276f',
    origin: GF_SOURCE,
    url: GF_URL + 'heebo/v10/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSycckOnz02SXQ.ttf',
    variant: 'regular',
    family: 'Heebo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b00055b8-5900-477e-ba6c-11cbdac793ab',
    origin: GF_SOURCE,
    url: GF_URL + 'heebo/v10/NGSpv5_NC0k9P_v6ZUCbLRAHxK1E1yycckOnz02SXQ.ttf',
    variant: '300',
    family: 'Heebo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '10f19b4b-03d5-468d-8387-bfe4ff678aeb',
    origin: GF_SOURCE,
    url: GF_URL + 'heebo/v10/NGSpv5_NC0k9P_v6ZUCbLRAHxK1ECSycckOnz02SXQ.ttf',
    variant: '200',
    family: 'Heebo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '4e4db226-f729-484c-a77e-f9c0a25e84e2',
    origin: GF_SOURCE,
    url: GF_URL + 'heebo/v10/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiS2cckOnz02SXQ.ttf',
    variant: '100',
    family: 'Heebo',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '7352236c-71c8-4b7b-b3c5-03097373a8ec',
    origin: GF_SOURCE,
    url: GF_URL + 'oxygen/v10/2sDcZG1Wl4LcnbuCNWgDb2-4C7wFZQ.ttf',
    variant: '700',
    family: 'Oxygen',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b3269d50-5e95-4c22-a686-c627e8516995',
    origin: GF_SOURCE,
    url: GF_URL + 'oxygen/v10/2sDfZG1Wl4Lcnbu6iUcnZ0SkAg.ttf',
    variant: 'regular',
    family: 'Oxygen',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: '9fda6b7d-4113-465d-89db-d879ba287ab9',
    origin: GF_SOURCE,
    url: GF_URL + 'oxygen/v10/2sDcZG1Wl4LcnbuCJW8Db2-4C7wFZQ.ttf',
    variant: '300',
    family: 'Oxygen',
    category: 'sans-serif',
    version: 'v10',
    subset: 'latin',
  },
  {
    id: 'b76285f0-be02-45f1-b8a4-ff0221f14df1',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHsv4kjgoGqM7E_CfO451optzsrd6m9.ttf',
    variant: '900italic',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '61b02833-ff91-4201-85e1-b9cb7ff3912a',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHqv4kjgoGqM7E3j-wc4FAtlT47dw.ttf',
    variant: '900',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'c7c7f8a3-3545-4ec1-abac-e84abeb4fba9',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHsv4kjgoGqM7E_CfOc5loptzsrd6m9.ttf',
    variant: '800italic',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '572141d6-395e-4f68-8cae-cfe255f14d09',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHqv4kjgoGqM7E3q-0c4FAtlT47dw.ttf',
    variant: '800',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ccdd9b43-2222-41be-8dc9-020dac77c83f',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHsv4kjgoGqM7E_CfOA5Voptzsrd6m9.ttf',
    variant: '700italic',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '1869dd3f-7ff8-40f1-995c-7f46e7aec9f1',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHqv4kjgoGqM7E3t-4c4FAtlT47dw.ttf',
    variant: '700',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '735182db-2b68-4561-b1f7-7499f8855914',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHsv4kjgoGqM7E_CfPk5Foptzsrd6m9.ttf',
    variant: '600italic',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '5dce8851-5333-48fd-a415-1b29ab0637c9',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHqv4kjgoGqM7E30-8c4FAtlT47dw.ttf',
    variant: '600',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3d67d5e9-be4c-4c57-9d76-d677169927b0',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHsv4kjgoGqM7E_CfPI41optzsrd6m9.ttf',
    variant: '500italic',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '382a3f13-568c-48d5-87ab-b985dba96a40',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHqv4kjgoGqM7E3_-gc4FAtlT47dw.ttf',
    variant: '500',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '47f34941-e25f-42e5-9cd4-1a5940b3b738',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHrv4kjgoGqM7E_Ccs8yn4hnCci.ttf',
    variant: 'italic',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '2337f61d-fbd1-4938-ae9e-55b1dc440029',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHpv4kjgoGqM7EPC8E46HsxnA.ttf',
    variant: 'regular',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '8eadf73d-aeac-42c1-a646-7f9f30e631fa',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHsv4kjgoGqM7E_CfOQ4loptzsrd6m9.ttf',
    variant: '300italic',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: '3d9f243c-7719-40a8-8dad-6a92eba98b65',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHqv4kjgoGqM7E3p-kc4FAtlT47dw.ttf',
    variant: '300',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'cd86874c-0f87-46d1-9821-a9c3627d49a4',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHsv4kjgoGqM7E_CfP04Voptzsrd6m9.ttf',
    variant: '200italic',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'ecf3bf29-0214-4635-b640-3823a9009bca',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHqv4kjgoGqM7E3w-oc4FAtlT47dw.ttf',
    variant: '200',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'cb74ee9a-52dc-484b-ae61-e9a4379da982',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHtv4kjgoGqM7E_CfNYwHoDmTcibrA.ttf',
    variant: '100italic',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'a9a5558a-df56-458b-8a3e-004a3871c906',
    origin: GF_SOURCE,
    url: GF_URL + 'barlow/v5/7cHrv4kjgoGqM7E3b8s8yn4hnCci.ttf',
    variant: '100',
    family: 'Barlow',
    category: 'sans-serif',
    version: 'v5',
    subset: 'latin',
  },
  {
    id: 'd7e0c525-f501-45c9-9c53-c11a15ab9a00',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNnCUElPZbLXGxGR.ttf',
    variant: '800italic',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'dfa25153-0ae5-4021-90e5-2820e06a39da',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNmlUElPZbLXGxGR.ttf',
    variant: '700italic',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '95e15017-1b55-4f95-9e0a-8d7e9765b834',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNmcUElPZbLXGxGR.ttf',
    variant: '600italic',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '4f487857-719d-496f-912b-0a1876737f27',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNlwV0lPZbLXGxGR.ttf',
    variant: '500italic',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '7e772835-e503-4c9e-a3be-2de46c44b1d0',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNlCV0lPZbLXGxGR.ttf',
    variant: 'italic',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'c89ba736-bd95-4ef6-a9f7-f60f9d810585',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNkcV0lPZbLXGxGR.ttf',
    variant: '300italic',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'ceb280ea-e937-4400-95f5-07145a1c82c3',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNnCV0lPZbLXGxGR.ttf',
    variant: '200italic',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'fd8f1868-c157-438c-b560-370ee0d0a0fa',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJ2qFENLR7fHGw.ttf',
    variant: '800',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '78f770dd-8d46-4a5e-a718-f4571248a364',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTDH52qFENLR7fHGw.ttf',
    variant: '700',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '6a249bf1-e47e-4a16-a98c-9b64812c22aa',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTDJp2qFENLR7fHGw.ttf',
    variant: '600',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '2ea5b430-a8a7-488a-9171-57222bcc47b7',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTDypqqFENLR7fHGw.ttf',
    variant: '500',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'f65f1a07-4a51-473a-a7eb-3fed510e4ea9',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqqFENLR7fHGw.ttf',
    variant: 'regular',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '02bfdc8e-1edb-4498-a818-b8d685f978b5',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTDppqqFENLR7fHGw.ttf',
    variant: '300',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '26180b79-e509-4c28-b854-d2c47fb15392',
    origin: GF_SOURCE,
    url: GF_URL + 'karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqqFENLR7fHGw.ttf',
    variant: '200',
    family: 'Karla',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'a955fc4a-695c-4aaa-8550-b5d4597661b4',
    origin: GF_SOURCE,
    url: GF_URL + 'hindsiliguri/v7/ijwOs5juQtsyLLR5jN4cxBEoRCf_4uEfKiGvxts.ttf',
    variant: '700',
    family: 'Hind Siliguri',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '585e7dbd-0c32-46bf-855a-0345d94de6a5',
    origin: GF_SOURCE,
    url: GF_URL + 'hindsiliguri/v7/ijwOs5juQtsyLLR5jN4cxBEoREP-4uEfKiGvxts.ttf',
    variant: '600',
    family: 'Hind Siliguri',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'b31cba73-a26c-4faf-be41-6fdefdb3a1f3',
    origin: GF_SOURCE,
    url: GF_URL + 'hindsiliguri/v7/ijwOs5juQtsyLLR5jN4cxBEoRG_54uEfKiGvxts.ttf',
    variant: '500',
    family: 'Hind Siliguri',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '31ed0fb9-958d-413b-9696-8183735aab4f',
    origin: GF_SOURCE,
    url: GF_URL + 'hindsiliguri/v7/ijwTs5juQtsyLLR5jN4cxBEofJvQxuk0Nig.ttf',
    variant: 'regular',
    family: 'Hind Siliguri',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: 'bf887de7-3174-459c-95d3-cbd6b8ebf9e5',
    origin: GF_SOURCE,
    url: GF_URL + 'hindsiliguri/v7/ijwOs5juQtsyLLR5jN4cxBEoRDf44uEfKiGvxts.ttf',
    variant: '300',
    family: 'Hind Siliguri',
    category: 'sans-serif',
    version: 'v7',
    subset: 'latin',
  },
  {
    id: '9cfebdd5-935b-43db-9c3f-d781031ffba8',
    origin: GF_SOURCE,
    url:
      GF_URL + 'inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
    variant: '900',
    family: 'Inter',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '313bed60-1ab6-46ea-85ea-ddd68cc30497',
    origin: GF_SOURCE,
    url:
      GF_URL + 'inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
    variant: '800',
    family: 'Inter',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '99d221a2-a62d-49c3-9b5c-deb2251f7b04',
    origin: GF_SOURCE,
    url:
      GF_URL + 'inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
    variant: '700',
    family: 'Inter',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'fda97c87-acb9-4894-b724-ef35b20165bc',
    origin: GF_SOURCE,
    url:
      GF_URL + 'inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
    variant: '600',
    family: 'Inter',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '213f2ad9-b050-4fed-8178-a5f7f36c1cb8',
    origin: GF_SOURCE,
    url:
      GF_URL + 'inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
    variant: '500',
    family: 'Inter',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '7034455b-deac-4935-9dce-c484031e4ec8',
    origin: GF_SOURCE,
    url:
      GF_URL + 'inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
    variant: 'regular',
    family: 'Inter',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '6f446ba6-ae85-4308-ba5b-5fbc1aa00aa3',
    origin: GF_SOURCE,
    url:
      GF_URL + 'inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
    variant: '300',
    family: 'Inter',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'ab6e65a4-ae19-4e16-aca8-653032bca793',
    origin: GF_SOURCE,
    url:
      GF_URL + 'inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
    variant: '200',
    family: 'Inter',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: '57023cbf-d4f5-457f-b562-9165f6683e39',
    origin: GF_SOURCE,
    url:
      GF_URL + 'inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
    variant: '100',
    family: 'Inter',
    category: 'sans-serif',
    version: 'v3',
    subset: 'latin',
  },
  {
    id: 'd1ef2ad1-dc23-4189-857e-a5f9d0d8d165',
    origin: GF_SOURCE,
    url: GF_URL + 'titilliumweb/v9/NaPDcZTIAOhVxoMyOr9n_E7ffEDBKIx5YrSYqWM.ttf',
    variant: '900',
    family: 'Titillium Web',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'c8f1fda3-5dd5-4a82-a9fc-c3fb11715ed5',
    origin: GF_SOURCE,
    url: GF_URL + 'titilliumweb/v9/NaPFcZTIAOhVxoMyOr9n_E7fdMbetIlzZpaduWMmxA.ttf',
    variant: '700italic',
    family: 'Titillium Web',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'de215170-0441-42fd-bad9-19e82afa0e8f',
    origin: GF_SOURCE,
    url: GF_URL + 'titilliumweb/v9/NaPDcZTIAOhVxoMyOr9n_E7ffHjDKIx5YrSYqWM.ttf',
    variant: '700',
    family: 'Titillium Web',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '2d34bf7c-de17-41ce-803c-ff18bd587ce2',
    origin: GF_SOURCE,
    url: GF_URL + 'titilliumweb/v9/NaPFcZTIAOhVxoMyOr9n_E7fdMbe0IhzZpaduWMmxA.ttf',
    variant: '600italic',
    family: 'Titillium Web',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '27050545-1a40-4230-903c-cbeb5535fcfb',
    origin: GF_SOURCE,
    url: GF_URL + 'titilliumweb/v9/NaPDcZTIAOhVxoMyOr9n_E7ffBzCKIx5YrSYqWM.ttf',
    variant: '600',
    family: 'Titillium Web',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'bf7f82ad-400a-4a0a-bc39-e8ce3632e909',
    origin: GF_SOURCE,
    url: GF_URL + 'titilliumweb/v9/NaPAcZTIAOhVxoMyOr9n_E7fdMbmCKZXbr2BsA.ttf',
    variant: 'italic',
    family: 'Titillium Web',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'f751d583-9d10-403f-bb67-81352f36e24d',
    origin: GF_SOURCE,
    url: GF_URL + 'titilliumweb/v9/NaPecZTIAOhVxoMyOr9n_E7fRMTsDIRSfr0.ttf',
    variant: 'regular',
    family: 'Titillium Web',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '2c71485c-f65b-413b-8bae-aa1a3ad927ee',
    origin: GF_SOURCE,
    url: GF_URL + 'titilliumweb/v9/NaPFcZTIAOhVxoMyOr9n_E7fdMbepI5zZpaduWMmxA.ttf',
    variant: '300italic',
    family: 'Titillium Web',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'ebb47617-2c31-4a3e-b62c-d49bc8e7cc14',
    origin: GF_SOURCE,
    url: GF_URL + 'titilliumweb/v9/NaPDcZTIAOhVxoMyOr9n_E7ffGjEKIx5YrSYqWM.ttf',
    variant: '300',
    family: 'Titillium Web',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '758d6f97-4d78-41d0-a057-b20efd221625',
    origin: GF_SOURCE,
    url: GF_URL + 'titilliumweb/v9/NaPFcZTIAOhVxoMyOr9n_E7fdMbewI1zZpaduWMmxA.ttf',
    variant: '200italic',
    family: 'Titillium Web',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '0481b4fb-0812-4da0-a6ec-25be1840eff2',
    origin: GF_SOURCE,
    url: GF_URL + 'titilliumweb/v9/NaPDcZTIAOhVxoMyOr9n_E7ffAzHKIx5YrSYqWM.ttf',
    variant: '200',
    family: 'Titillium Web',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '5e320deb-0379-441b-be03-1a3f94ec3a13',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanstc/v11/-nFkOG829Oofr2wohFbTp9i9uwYvDd1V39Hr7g.otf',
    variant: '900',
    family: 'Noto Sans TC',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'bf3f2a24-17c8-4c02-91f8-c6b7ab8b6e9c',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanstc/v11/-nFkOG829Oofr2wohFbTp9i9gwQvDd1V39Hr7g.otf',
    variant: '700',
    family: 'Noto Sans TC',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'dfcd15ce-f505-477e-b4e0-597bad3f33df',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanstc/v11/-nFkOG829Oofr2wohFbTp9i9ywIvDd1V39Hr7g.otf',
    variant: '500',
    family: 'Noto Sans TC',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '0996a9cf-8b65-42dc-8561-e5ecb11eac53',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanstc/v11/-nF7OG829Oofr2wohFbTp9iFOSsLA_ZJ1g.otf',
    variant: 'regular',
    family: 'Noto Sans TC',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '6e98ea6f-1fc1-44f3-a329-40c3a662bb76',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanstc/v11/-nFkOG829Oofr2wohFbTp9i9kwMvDd1V39Hr7g.otf',
    variant: '300',
    family: 'Noto Sans TC',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '38f29615-1977-4072-9051-bdce2559d5b7',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanstc/v11/-nFlOG829Oofr2wohFbTp9i9WyEJIfNZ1sjy.otf',
    variant: '100',
    family: 'Noto Sans TC',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '8c014c9e-c76e-46bc-b6a4-306281257fba',
    origin: GF_SOURCE,
    url: GF_URL + 'quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18G0wx40QDw.ttf',
    variant: '700',
    family: 'Quicksand',
    category: 'sans-serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: 'f477d4a5-02fc-42fc-86bd-fbef1ce5e724',
    origin: GF_SOURCE,
    url: GF_URL + 'quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkCEv18G0wx40QDw.ttf',
    variant: '600',
    family: 'Quicksand',
    category: 'sans-serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '248a4689-4204-4450-b82c-659d7f79648b',
    origin: GF_SOURCE,
    url: GF_URL + 'quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkM0o18G0wx40QDw.ttf',
    variant: '500',
    family: 'Quicksand',
    category: 'sans-serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '2f1bf88f-8a59-4221-a569-2ba1fb77f5a6',
    origin: GF_SOURCE,
    url: GF_URL + 'quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18G0wx40QDw.ttf',
    variant: 'regular',
    family: 'Quicksand',
    category: 'sans-serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '7a68885e-e794-4ad5-bae1-c98c666dcf5d',
    origin: GF_SOURCE,
    url: GF_URL + 'quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkKEo18G0wx40QDw.ttf',
    variant: '300',
    family: 'Quicksand',
    category: 'sans-serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '50bcfd66-41ac-463b-81c8-8f52fe82cd43',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserif/v9/ga6Vaw1J5X9T9RW6j9bNfFIu0RWedO9NOoYIDg.ttf',
    variant: '700italic',
    family: 'Noto Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '57b57790-93bc-4bb6-ba48-584da2ef7654',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserif/v9/ga6Law1J5X9T9RW6j9bNdOwzTRCUcM1IKoY.ttf',
    variant: '700',
    family: 'Noto Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '46af4f72-5a1b-4609-9014-d88e0c753ae2',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserif/v9/ga6Kaw1J5X9T9RW6j9bNfFIWbTq6fMRRMw.ttf',
    variant: 'italic',
    family: 'Noto Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '30837963-d000-430d-8932-90d83711d844',
    origin: GF_SOURCE,
    url: GF_URL + 'notoserif/v9/ga6Iaw1J5X9T9RW6j9bNTFAcaRi_bMQ.ttf',
    variant: 'regular',
    family: 'Noto Serif',
    category: 'serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '8d87c5ac-fb9a-4086-9894-a40a5984f279',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9f4kDNxMZdWfMOD5VvkrBKRhf_XljGllLX.ttf',
    variant: '900italic',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '3cbac89a-c06a-41d0-af6f-e77e6c6a5988',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9B4kDNxMZdWfMOD5VnFK_uQR37fF3Wlg.ttf',
    variant: '900',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '2f0f9e74-2c65-424d-afe0-027137563b94',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9f4kDNxMZdWfMOD5VvkrBuRxf_XljGllLX.ttf',
    variant: '800italic',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '463104f7-de56-46ed-b784-7d610423d556',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9B4kDNxMZdWfMOD5VnMK7uQR37fF3Wlg.ttf',
    variant: '800',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'cf1f09c2-1827-4ce5-9fe0-0c11291ed4aa',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9f4kDNxMZdWfMOD5VvkrByRBf_XljGllLX.ttf',
    variant: '700italic',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'fb8362e8-0e13-429f-96bf-c4312bc2e10a',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9B4kDNxMZdWfMOD5VnLK3uQR37fF3Wlg.ttf',
    variant: '700',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'fe1c1761-45a4-4fc6-9b94-f5e7e6f250ef',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9f4kDNxMZdWfMOD5VvkrAWRRf_XljGllLX.ttf',
    variant: '600italic',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f126d2ab-b4e7-478c-9c79-5baec313033c',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9B4kDNxMZdWfMOD5VnSKzuQR37fF3Wlg.ttf',
    variant: '600',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '2500539b-a1c4-4c29-9dbb-0b68dfb666ec',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9f4kDNxMZdWfMOD5VvkrA6Qhf_XljGllLX.ttf',
    variant: '500italic',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'b7f7d345-886e-43a2-aa23-50b2e4c7c544',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9B4kDNxMZdWfMOD5VnZKvuQR37fF3Wlg.ttf',
    variant: '500',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '5addd63b-d861-4546-bc1f-79d0ececacd1',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9C4kDNxMZdWfMOD5VvkojOazP3dUTP.ttf',
    variant: 'italic',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '66a76844-5fd4-4a1e-a1b3-96c07992897d',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9E4kDNxMZdWfMOD5VfkILKSTbndQ.ttf',
    variant: 'regular',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'bb16467f-fb5f-4690-b653-5b73f1553132',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9f4kDNxMZdWfMOD5VvkrBiQxf_XljGllLX.ttf',
    variant: '300italic',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '87f15d28-c224-4b04-915b-ef941f837ed4',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9B4kDNxMZdWfMOD5VnPKruQR37fF3Wlg.ttf',
    variant: '300',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '885c7e79-2ed5-46d1-adc0-68e710a15c3b',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9f4kDNxMZdWfMOD5VvkrAGQBf_XljGllLX.ttf',
    variant: '200italic',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'f650fd2c-689c-4b34-b8e4-e94c0368e08c',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9B4kDNxMZdWfMOD5VnWKnuQR37fF3Wlg.ttf',
    variant: '200',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: 'e02144cf-059b-4c45-9c80-a11f341d7884',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9A4kDNxMZdWfMOD5VvkrCqYTfVcFTPj0s.ttf',
    variant: '100italic',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '84673194-64d3-47f5-9c4b-7839dddc09e8',
    origin: GF_SOURCE,
    url: GF_URL + 'firasans/v11/va9C4kDNxMZdWfMOD5Vn9IjOazP3dUTP.ttf',
    variant: '100',
    family: 'Fira Sans',
    category: 'sans-serif',
    version: 'v11',
    subset: 'latin',
  },
  {
    id: '77996e10-e536-4ca2-95de-ac74067a80b1',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe01MImSLYBIv1o4X1M8cce4G1ptU1QCU5l-06Y.ttf',
    variant: '900italic',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '677e23ae-b934-45da-b817-000496a31c29',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe03MImSLYBIv1o4X1M8cc8-BP5qWVAgVol-.ttf',
    variant: '900',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'b5cd1277-b926-48c6-ab75-fe46c6fb8df7',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe01MImSLYBIv1o4X1M8cce4G35sU1QCU5l-06Y.ttf',
    variant: '800italic',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '6cdb0a7c-fc57-4f92-ba1f-74fbabed0510',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe03MImSLYBIv1o4X1M8cc8aBf5qWVAgVol-.ttf',
    variant: '800',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '04aa158c-6f3b-41d4-aad9-ec84e6b48db5',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe01MImSLYBIv1o4X1M8cce4G2JvU1QCU5l-06Y.ttf',
    variant: '700italic',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f8d483d3-9c65-4473-8eed-e209fe2369a6',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe03MImSLYBIv1o4X1M8cc8GBv5qWVAgVol-.ttf',
    variant: '700',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '541d8fec-32f9-4969-ac43-3e575d5e1233',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe01MImSLYBIv1o4X1M8cce4GwZuU1QCU5l-06Y.ttf',
    variant: '600italic',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '295afcf3-8b66-4659-bc6c-3c4a8e899793',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe03MImSLYBIv1o4X1M8cc9iB_5qWVAgVol-.ttf',
    variant: '600',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '73ce8910-c0bc-4fc8-9088-bb2fc52da101',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe0oMImSLYBIv1o4X1M8cce4I95Ad1wpT5A.ttf',
    variant: 'italic',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '4f7288f9-5170-4389-bf62-0ea7b12a8a50',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe0qMImSLYBIv1o4X1M8cfe6Kdpickwp.ttf',
    variant: 'regular',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '2cb29f3e-0dfe-4653-9bdf-3ecc92f139ed',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe01MImSLYBIv1o4X1M8cce4G3JoU1QCU5l-06Y.ttf',
    variant: '300italic',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: '4ce44e19-fe97-4eb0-a856-9a2f36faec60',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe03MImSLYBIv1o4X1M8cc8WAf5qWVAgVol-.ttf',
    variant: '300',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c3752344-d18b-490e-8985-786c321b7aae',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe01MImSLYBIv1o4X1M8cce4GxZrU1QCU5l-06Y.ttf',
    variant: '200italic',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'c1e4ff37-1f75-415f-9ceb-8254db83ae58',
    origin: GF_SOURCE,
    url: GF_URL + 'nunitosans/v6/pe03MImSLYBIv1o4X1M8cc9yAv5qWVAgVol-.ttf',
    variant: '200',
    family: 'Nunito Sans',
    category: 'sans-serif',
    version: 'v6',
    subset: 'latin',
  },
  {
    id: 'f47c934d-deb2-46ac-a17b-d9e8c810b480',
    origin: GF_SOURCE,
    url: GF_URL + 'mukta/v8/iJWHBXyXfDDVXbFmi1ma-2HW7ZB_.ttf',
    variant: '800',
    family: 'Mukta',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '939d57c1-54d5-4ad2-8412-22026fcd33b4',
    origin: GF_SOURCE,
    url: GF_URL + 'mukta/v8/iJWHBXyXfDDVXbF6iFma-2HW7ZB_.ttf',
    variant: '700',
    family: 'Mukta',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: 'eda8d274-67d9-4ff8-9738-034398b893d7',
    origin: GF_SOURCE,
    url: GF_URL + 'mukta/v8/iJWHBXyXfDDVXbEeiVma-2HW7ZB_.ttf',
    variant: '600',
    family: 'Mukta',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '13b35401-3b35-4b26-98a2-513f44cf339c',
    origin: GF_SOURCE,
    url: GF_URL + 'mukta/v8/iJWHBXyXfDDVXbEyjlma-2HW7ZB_.ttf',
    variant: '500',
    family: 'Mukta',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '237d3a3e-6fe9-4bc8-820d-38cfc2dec941',
    origin: GF_SOURCE,
    url: GF_URL + 'mukta/v8/iJWKBXyXfDDVXYnGp32S0H3f.ttf',
    variant: 'regular',
    family: 'Mukta',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '1ae88a1e-c0bd-49d7-a8ca-21652c2aa921',
    origin: GF_SOURCE,
    url: GF_URL + 'mukta/v8/iJWHBXyXfDDVXbFqj1ma-2HW7ZB_.ttf',
    variant: '300',
    family: 'Mukta',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '74b64aa6-2e58-47e4-bcf3-efc4407363c3',
    origin: GF_SOURCE,
    url: GF_URL + 'mukta/v8/iJWHBXyXfDDVXbEOjFma-2HW7ZB_.ttf',
    variant: '200',
    family: 'Mukta',
    category: 'sans-serif',
    version: 'v8',
    subset: 'latin',
  },
  {
    id: '1b896d79-fbdc-4b39-a1f7-b3ac7d8c75c3',
    origin: GF_SOURCE,
    url: GF_URL + 'ptserif/v12/EJRQQgYoZZY2vCFuvAFT9gaQVy7VocNB6Iw.ttf',
    variant: '700italic',
    family: 'PT Serif',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '4c87bba3-b4d8-4adb-a0f6-9fb17398108d',
    origin: GF_SOURCE,
    url: GF_URL + 'ptserif/v12/EJRSQgYoZZY2vCFuvAnt65qVXSr3pNNB.ttf',
    variant: '700',
    family: 'PT Serif',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'db6581f1-893a-43d4-8b7c-ee34defdfba8',
    origin: GF_SOURCE,
    url: GF_URL + 'ptserif/v12/EJRTQgYoZZY2vCFuvAFTzrq_cyb-vco.ttf',
    variant: 'italic',
    family: 'PT Serif',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '7e5604cd-96b3-404f-b533-50361266ce0c',
    origin: GF_SOURCE,
    url: GF_URL + 'ptserif/v12/EJRVQgYoZZY2vCFuvDFRxL6ddjb-.ttf',
    variant: 'regular',
    family: 'PT Serif',
    category: 'serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '5e0048da-573d-4056-8204-6ecd67a90b8d',
    origin: GF_SOURCE,
    url: GF_URL + 'nanumgothic/v17/PN_oRfi-oW3hYwmKDpxS7F_LXv77zlEn14YEUQ.ttf',
    variant: '800',
    family: 'Nanum Gothic',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'e76746c9-c61e-4c39-aaff-ab0776646e88',
    origin: GF_SOURCE,
    url: GF_URL + 'nanumgothic/v17/PN_oRfi-oW3hYwmKDpxS7F_LQv37zlEn14YEUQ.ttf',
    variant: '700',
    family: 'Nanum Gothic',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '46e25cff-9ec2-4515-949d-a65f399fdc20',
    origin: GF_SOURCE,
    url: GF_URL + 'nanumgothic/v17/PN_3Rfi-oW3hYwmKDpxS7F_z_tLfxno73g.ttf',
    variant: 'regular',
    family: 'Nanum Gothic',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '106c350c-3456-4a79-97e1-08fce6905522',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUd2wJo43ZKyDSQQ.ttf',
    variant: '900italic',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '02a30c5e-c22f-408e-9136-4d181957299c',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmwJo43ZKyDSQQ.ttf',
    variant: '800italic',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '35780f04-4335-4c1c-905a-e3c8df4c1cc9',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUOWwJo43ZKyDSQQ.ttf',
    variant: '700italic',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '8f6d70e6-6215-4556-a1fa-47c35bf4068c',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUAGwJo43ZKyDSQQ.ttf',
    variant: '600italic',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '4fcfc3be-fa60-4dd6-ab0e-580a06c1de52',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU7GsJo43ZKyDSQQ.ttf',
    variant: '500italic',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '619a6d74-3aca-49e4-ab79-6a20efb414ad',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3msJo43ZKyDSQQ.ttf',
    variant: 'italic',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '82afa228-3196-4d46-acb2-08a2232805c3',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUgGsJo43ZKyDSQQ.ttf',
    variant: '300italic',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'c1abd3b4-fce2-4798-996a-1e7bfb483afb',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmsJo43ZKyDSQQ.ttf',
    variant: '200italic',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'e391b503-ee6a-4637-9a25-e46a0ea20256',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3moJo43ZKyDSQQ.ttf',
    variant: '100italic',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '6f2de453-cd2d-4e2e-b2f8-235a2d4047f1',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K-DQNigDp6_cOyA.ttf',
    variant: '900',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'c33e746d-a891-4426-b217-b5f04bbee9e2',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nQNigDp6_cOyA.ttf',
    variant: '800',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'b41fc230-bc5d-4bf7-bea7-3de05331be81',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QNigDp6_cOyA.ttf',
    variant: '700',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'f8787cd2-61c3-4dfb-bf22-5e71059ca485',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNigDp6_cOyA.ttf',
    variant: '600',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'ced2f668-27aa-4875-ac96-b2f1df9df593',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNigDp6_cOyA.ttf',
    variant: '500',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '614ef894-fe49-4d17-b5dd-641badc1fd56',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNigDp6_cOyA.ttf',
    variant: 'regular',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '30135ad0-fdba-480b-8fce-69e2c6804290',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXNigDp6_cOyA.ttf',
    variant: '300',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: 'eb1dc9f3-60c7-41a4-b2c7-de97351545c3',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nXNigDp6_cOyA.ttf',
    variant: '200',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '6b81e273-a941-4ea5-9f01-46016263bf12',
    origin: GF_SOURCE,
    url: GF_URL + 'worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWNigDp6_cOyA.ttf',
    variant: '100',
    family: 'Work Sans',
    category: 'sans-serif',
    version: 'v9',
    subset: 'latin',
  },
  {
    id: '492c60ba-aa64-4325-9ed3-1016ea901e0a',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanskr/v13/Pby7FmXiEBPT4ITbgNA5CgmOUlnI7rgQsWYrzw.otf',
    variant: '900',
    family: 'Noto Sans KR',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'd7862542-db55-48f0-98a5-70c398dbeee2',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanskr/v13/Pby7FmXiEBPT4ITbgNA5CgmOalvI7rgQsWYrzw.otf',
    variant: '700',
    family: 'Noto Sans KR',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '6f95e7f9-3af0-47b2-b341-b585784c3c50',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanskr/v13/Pby7FmXiEBPT4ITbgNA5CgmOIl3I7rgQsWYrzw.otf',
    variant: '500',
    family: 'Noto Sans KR',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '8486e4f8-7ed8-4f8d-b447-afa802ec30ee',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanskr/v13/PbykFmXiEBPT4ITbgNA5Cgm20HTs4JMMuA.otf',
    variant: 'regular',
    family: 'Noto Sans KR',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '48f15633-df63-4eab-bcc7-90cd78b9771a',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanskr/v13/Pby7FmXiEBPT4ITbgNA5CgmOelzI7rgQsWYrzw.otf',
    variant: '300',
    family: 'Noto Sans KR',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'e10d8e59-fb75-457a-b4de-e6f756ce9cac',
    origin: GF_SOURCE,
    url: GF_URL + 'notosanskr/v13/Pby6FmXiEBPT4ITbgNA5CgmOsn7uwpYcuH8y.otf',
    variant: '100',
    family: 'Noto Sans KR',
    category: 'sans-serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'ed450993-f478-49ca-8bd9-61749fb16838',
    origin: GF_SOURCE,
    url: GF_URL + 'lora/v17/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-C0Ckqh8ndeZzZ0.ttf',
    variant: '700italic',
    family: 'Lora',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'f6aa264c-3bed-4106-8d17-6efc4f1c970e',
    origin: GF_SOURCE,
    url: GF_URL + 'lora/v17/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-BQCkqh8ndeZzZ0.ttf',
    variant: '600italic',
    family: 'Lora',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '84ca59b5-0a7a-4bbc-9d77-fb2c3d446329',
    origin: GF_SOURCE,
    url: GF_URL + 'lora/v17/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-PgFkqh8ndeZzZ0.ttf',
    variant: '500italic',
    family: 'Lora',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '1b2e5a80-0b1f-493b-8bca-877d57ec4752',
    origin: GF_SOURCE,
    url: GF_URL + 'lora/v17/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-MoFkqh8ndeZzZ0.ttf',
    variant: 'italic',
    family: 'Lora',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '6559fefd-24d8-4ce9-996c-a5ccddb61e8d',
    origin: GF_SOURCE,
    url: GF_URL + 'lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787z5vCJGmKxemMeZ.ttf',
    variant: '700',
    family: 'Lora',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '130ccd7d-e222-44be-a965-7580737b0fdf',
    origin: GF_SOURCE,
    url: GF_URL + 'lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787zAvCJGmKxemMeZ.ttf',
    variant: '600',
    family: 'Lora',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '4d807961-b19e-443b-8892-96994e0fbe74',
    origin: GF_SOURCE,
    url: GF_URL + 'lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787wsuyJGmKxemMeZ.ttf',
    variant: '500',
    family: 'Lora',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '29f15d7d-2179-4dd1-b764-afebd8a36a9b',
    origin: GF_SOURCE,
    url: GF_URL + 'lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787weuyJGmKxemMeZ.ttf',
    variant: 'regular',
    family: 'Lora',
    category: 'serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'a558c5d9-fdb4-4fda-bee9-9b49ac11a627',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8v0FEUwdYPFkJ1O.ttf',
    variant: '900italic',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'bc6a11e7-bdcf-4aea-9ba1-8033e7ed11e1',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8vdFEUwdYPFkJ1O.ttf',
    variant: '800italic',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '497fe666-8da2-496c-b4c1-028f85aa5ee9',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUwdYPFkJ1O.ttf',
    variant: '700italic',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'cf378cff-1393-4d02-8fbf-de6d8dda6385',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFEUwdYPFkJ1O.ttf',
    variant: '600italic',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'da670671-32c2-4269-bee8-49207ee55937',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tvE0UwdYPFkJ1O.ttf',
    variant: '500italic',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '932cd644-bc67-4d01-be77-62f983962497',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0UwdYPFkJ1O.ttf',
    variant: 'italic',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'cf8b4708-ed7c-49f4-9a18-4ff07b675239',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8sDE0UwdYPFkJ1O.ttf',
    variant: '300italic',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'ddd6262c-b3da-4607-a129-5700bf686273',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-ro-1UE80V4bVkA.ttf',
    variant: '900',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '6a27a2b4-9df7-46e9-8450-24a952de3713',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-h4-1UE80V4bVkA.ttf',
    variant: '800',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'cb21d79c-4ce2-4938-b7eb-d2e676f76178',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UE80V4bVkA.ttf',
    variant: '700',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'cf5d7e50-2a25-49c5-948f-228bd61f6089',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-1UE80V4bVkA.ttf',
    variant: '600',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '71c12d25-df3c-4f17-bc7f-3757eddb3a8e',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1UE80V4bVkA.ttf',
    variant: '500',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '4e92ec03-4202-462f-9a1a-1914f9a9f3d2',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UE80V4bVkA.ttf',
    variant: 'regular',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'a16f747f-5185-4311-af42-2d7832fe35d6',
    origin: GF_SOURCE,
    url: GF_URL + 'rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1UE80V4bVkA.ttf',
    variant: '300',
    family: 'Rubik',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '4a06acd0-5538-46b3-bdc1-6abda9ab5f67',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmPuLGRpWRyAs.ttf',
    variant: '700',
    family: 'Open Sans Condensed',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '7f0d022d-8d97-4398-aec7-d2f5c116ff31',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'opensanscondensed/v15/z7NHdQDnbTkabZAIOl9il_O6KJj73e7Fd_-7suDMQreU2AsJSg.ttf',
    variant: '300italic',
    family: 'Open Sans Condensed',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'cfb09e83-e38a-4946-96d1-02edd0757096',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhPuLGRpWRyAs.ttf',
    variant: '300',
    family: 'Open Sans Condensed',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'a364f5e5-da4c-4964-a5e3-cee7f691aca8',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXQ3I6Li01BKofIMN4AYevNWz4PDWtj.ttf',
    variant: '900italic',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '28090106-ebf4-47fa-984e-32878eed4179',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXW3I6Li01BKofAtsGkZuHJeTsfDQ.ttf',
    variant: '900',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '40bf2f0d-c951-4573-8213-b5db6cc4aedc',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXQ3I6Li01BKofIMN4kYOvNWz4PDWtj.ttf',
    variant: '800italic',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '8d4f648e-737e-40ed-ab8c-697127a389e5',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXW3I6Li01BKofAksCkZuHJeTsfDQ.ttf',
    variant: '800',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'fcc12201-e0d3-40ef-9d5f-c6d0ed582397',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXQ3I6Li01BKofIMN44Y-vNWz4PDWtj.ttf',
    variant: '700italic',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'd7d5eed1-ab47-406f-86b5-2dd3754e137a',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXW3I6Li01BKofAjsOkZuHJeTsfDQ.ttf',
    variant: '700',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '6f220193-7c35-4fe3-a432-e59cd794d371',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXQ3I6Li01BKofIMN5cYuvNWz4PDWtj.ttf',
    variant: '600italic',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'b7cf4d28-731f-4db7-badf-26f7f7a38b95',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXW3I6Li01BKofA6sKkZuHJeTsfDQ.ttf',
    variant: '600',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'ea1e92ae-c883-4e3a-8887-9441c9661be2',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXX3I6Li01BKofIMOaETM_FcCIG.ttf',
    variant: 'italic',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '2c4dcad8-4155-439f-b380-4d781706b74b',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXV3I6Li01BKof4MuyAbsrVcA.ttf',
    variant: 'regular',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'a384e27e-c553-427b-a0cd-0a4ee02ece04',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXQ3I6Li01BKofIMN4oZOvNWz4PDWtj.ttf',
    variant: '300italic',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '8780d9a4-76c7-4d97-b9e9-83f6e11d05a1',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXW3I6Li01BKofAnsSkZuHJeTsfDQ.ttf',
    variant: '300',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'c7b3226e-6eb7-447a-8c41-1a3c6ca7bf1a',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXQ3I6Li01BKofIMN5MZ-vNWz4PDWtj.ttf',
    variant: '200italic',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: '803204ca-14c8-4698-9ba5-303e8d623aee',
    origin: GF_SOURCE,
    url: GF_URL + 'nunito/v16/XRXW3I6Li01BKofA-sekZuHJeTsfDQ.ttf',
    variant: '200',
    family: 'Nunito',
    category: 'sans-serif',
    version: 'v16',
    subset: 'latin',
  },
  {
    id: 'a8c857ed-22a8-4026-91fa-22d5c9159700',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_gGUbtbK-F2rA0s.ttf',
    variant: '900italic',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '015a99b0-eb55-4ce0-b42d-b9d5227c0ff0',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_iiUbtbK-F2rA0s.ttf',
    variant: '800italic',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '9dcd96d8-b85e-41e3-95c4-add97b9984f3',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_k-UbtbK-F2rA0s.ttf',
    variant: '700italic',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '5190e581-f91e-4b90-b406-e6f578af479b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_naUbtbK-F2rA0s.ttf',
    variant: '600italic',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '0bb721df-1fe9-4254-bb93-191e32916d3e',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_pqTbtbK-F2rA0s.ttf',
    variant: '500italic',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '57ee72f6-b9bc-4c31-b68f-9af67896e42b',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_qiTbtbK-F2rA0s.ttf',
    variant: 'italic',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '3f29c9cd-b383-48c6-8525-e3dcfb4b4008',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsukDQZNLo_U2r.ttf',
    variant: '900',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: 'b99e00d2-3429-4c11-877d-397d2d744bc5',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFukDQZNLo_U2r.ttf',
    variant: '800',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '9cb37884-94cc-417b-9e7d-9fc6a027795c',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiukDQZNLo_U2r.ttf',
    variant: '700',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '5b75e98b-a7a8-4221-a589-329885653627',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebukDQZNLo_U2r.ttf',
    variant: '600',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: 'a562abd5-3087-424f-b9f9-820ccb56c802',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vUDQZNLo_U2r.ttf',
    variant: '500',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: 'f5611e23-51e7-46d3-aa30-b5c6698ded67',
    origin: GF_SOURCE,
    url:
      GF_URL +
      'playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvUDQZNLo_U2r.ttf',
    variant: 'regular',
    family: 'Playfair Display',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '998db144-c62b-49e6-aa80-04790b35d975',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntu/v15/4iCp6KVjbNBYlgoKejZPsmyIPYBvgpUI.ttf',
    variant: '700italic',
    family: 'Ubuntu',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '03038159-94c6-4846-bb44-94f7840bf9a9',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntu/v15/4iCv6KVjbNBYlgoCxCvTt2aMH4V_gg.ttf',
    variant: '700',
    family: 'Ubuntu',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '1e0a85a1-1fba-4344-aa92-d85bcf59f11c',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntu/v15/4iCp6KVjbNBYlgoKejYHtGyIPYBvgpUI.ttf',
    variant: '500italic',
    family: 'Ubuntu',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '787e2fd8-1f9e-49d0-9a13-c86757c1750a',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntu/v15/4iCv6KVjbNBYlgoCjC3Tt2aMH4V_gg.ttf',
    variant: '500',
    family: 'Ubuntu',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '517eb809-7057-4c8d-a63e-765ceed045fd',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntu/v15/4iCu6KVjbNBYlgoKeg7znUiAFpxm.ttf',
    variant: 'italic',
    family: 'Ubuntu',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '6cb00cf7-6f2a-4c27-80b1-70e3fc3f8c1c',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntu/v15/4iCs6KVjbNBYlgo6eAT3v02QFg.ttf',
    variant: 'regular',
    family: 'Ubuntu',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '424dafdb-ec65-427a-b8f1-022e56c7c529',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntu/v15/4iCp6KVjbNBYlgoKejZftWyIPYBvgpUI.ttf',
    variant: '300italic',
    family: 'Ubuntu',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'dbbbb20d-af6f-4865-aa0d-262ce6f2c7f4',
    origin: GF_SOURCE,
    url: GF_URL + 'ubuntu/v15/4iCv6KVjbNBYlgoC1CzTt2aMH4V_gg.ttf',
    variant: '300',
    family: 'Ubuntu',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '0623621a-b3b8-46bc-b567-a7fef0b3e300',
    origin: GF_SOURCE,
    url: GF_URL + 'robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoJYOWaG5iddG-1A.ttf',
    variant: '900',
    family: 'Roboto Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'e7cb0f6d-75af-4cb8-ba63-0792b151e374',
    origin: GF_SOURCE,
    url: GF_URL + 'robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDIOWaG5iddG-1A.ttf',
    variant: '800',
    family: 'Roboto Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '4706f6a2-1c84-43bb-afb2-ce562cdc2b79',
    origin: GF_SOURCE,
    url: GF_URL + 'robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4OWaG5iddG-1A.ttf',
    variant: '700',
    family: 'Roboto Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '8b9499a5-569f-4ef4-9686-8e84caf7f8ef',
    origin: GF_SOURCE,
    url: GF_URL + 'robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoUoOWaG5iddG-1A.ttf',
    variant: '600',
    family: 'Roboto Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'c67a9ce8-e55c-4dbe-8335-df528194e16e',
    origin: GF_SOURCE,
    url: GF_URL + 'robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjovoSWaG5iddG-1A.ttf',
    variant: '500',
    family: 'Roboto Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '316f47a4-7198-4a67-b5f2-78002771111e',
    origin: GF_SOURCE,
    url: GF_URL + 'robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaG5iddG-1A.ttf',
    variant: 'regular',
    family: 'Roboto Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '89a842c6-f51a-4001-8649-6d6a1d578ab6',
    origin: GF_SOURCE,
    url: GF_URL + 'robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSWaG5iddG-1A.ttf',
    variant: '300',
    family: 'Roboto Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'f9d8e1b1-fe24-431f-bda3-c97c5d392ab4',
    origin: GF_SOURCE,
    url: GF_URL + 'robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDISWaG5iddG-1A.ttf',
    variant: '200',
    family: 'Roboto Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'd9bc6003-2b69-4472-9e7c-da89a515d870',
    origin: GF_SOURCE,
    url: GF_URL + 'robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojIWWaG5iddG-1A.ttf',
    variant: '100',
    family: 'Roboto Slab',
    category: 'serif',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '3a07669c-eca4-4491-bac1-aad0b7268a69',
    origin: GF_SOURCE,
    url: GF_URL + 'merriweather/v22/u-4l0qyriQwlOrhSvowK_l5-eR7NWMf_hP3hPGWH.ttf',
    variant: '900italic',
    family: 'Merriweather',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '043f108a-4fa4-45f2-a2dd-2ab7e03286ec',
    origin: GF_SOURCE,
    url: GF_URL + 'merriweather/v22/u-4n0qyriQwlOrhSvowK_l52_wFpX837pvjxPA.ttf',
    variant: '900',
    family: 'Merriweather',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: 'bbd2e634-141f-4901-b910-84da06dc0530',
    origin: GF_SOURCE,
    url: GF_URL + 'merriweather/v22/u-4l0qyriQwlOrhSvowK_l5-eR71Wsf_hP3hPGWH.ttf',
    variant: '700italic',
    family: 'Merriweather',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '14853f8b-072f-4c0d-9ac6-1ab097a0d366',
    origin: GF_SOURCE,
    url: GF_URL + 'merriweather/v22/u-4n0qyriQwlOrhSvowK_l52xwNpX837pvjxPA.ttf',
    variant: '700',
    family: 'Merriweather',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '089d83b7-35ef-45d5-b9f6-9d71c66b793d',
    origin: GF_SOURCE,
    url: GF_URL + 'merriweather/v22/u-4m0qyriQwlOrhSvowK_l5-eSZJdeP3r-Ho.ttf',
    variant: 'italic',
    family: 'Merriweather',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '17b5010e-477a-4c58-8439-884faf9780eb',
    origin: GF_SOURCE,
    url: GF_URL + 'merriweather/v22/u-440qyriQwlOrhSvowK_l5OeyxNV-bnrw.ttf',
    variant: 'regular',
    family: 'Merriweather',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: 'c6a208fc-3dba-4b46-b0f9-f40da870e4fc',
    origin: GF_SOURCE,
    url: GF_URL + 'merriweather/v22/u-4l0qyriQwlOrhSvowK_l5-eR7lXcf_hP3hPGWH.ttf',
    variant: '300italic',
    family: 'Merriweather',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: '45e260af-714e-4daf-b753-a66180f913c8',
    origin: GF_SOURCE,
    url: GF_URL + 'merriweather/v22/u-4n0qyriQwlOrhSvowK_l521wRpX837pvjxPA.ttf',
    variant: '300',
    family: 'Merriweather',
    category: 'serif',
    version: 'v22',
    subset: 'latin',
  },
  {
    id: 'b4e7269c-a515-47b1-8ccb-28c6989e1899',
    origin: GF_SOURCE,
    url: GF_URL + 'ptsans/v12/jizdRExUiTo99u79D0e8fOytKB8c8zMrig.ttf',
    variant: '700italic',
    family: 'PT Sans',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '00053c25-b15a-42f4-a1e6-23710aac9c12',
    origin: GF_SOURCE,
    url: GF_URL + 'ptsans/v12/jizfRExUiTo99u79B_mh4OmnLD0Z4zM.ttf',
    variant: '700',
    family: 'PT Sans',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'b571ccfb-97a5-4290-b42c-f05e0eabe5a9',
    origin: GF_SOURCE,
    url: GF_URL + 'ptsans/v12/jizYRExUiTo99u79D0eEwMOJIDQA-g.ttf',
    variant: 'italic',
    family: 'PT Sans',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c156c1cf-12ce-41b4-a961-b3349363f0a1',
    origin: GF_SOURCE,
    url: GF_URL + 'ptsans/v12/jizaRExUiTo99u79P0WOxOGMMDQ.ttf',
    variant: 'regular',
    family: 'PT Sans',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '403af268-b247-4b79-a5a0-9ff4a69c3816',
    origin: GF_SOURCE,
    url:
      GF_URL + 'robotomono/v13/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrmAB-W9AJi8SZwt.ttf',
    variant: '700italic',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '21f97953-cec0-45a0-ba26-8be9e4d774d5',
    origin: GF_SOURCE,
    url:
      GF_URL + 'robotomono/v13/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrm5B-W9AJi8SZwt.ttf',
    variant: '600italic',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'b12924e2-ecb2-417d-bab7-aeb999438923',
    origin: GF_SOURCE,
    url:
      GF_URL + 'robotomono/v13/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlVAOW9AJi8SZwt.ttf',
    variant: '500italic',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'fde88e18-a075-4530-8220-7125e88fea9d',
    origin: GF_SOURCE,
    url:
      GF_URL + 'robotomono/v13/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlnAOW9AJi8SZwt.ttf',
    variant: 'italic',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '9015f72c-da3c-481e-81aa-3b1e2114dc92',
    origin: GF_SOURCE,
    url:
      GF_URL + 'robotomono/v13/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrk5AOW9AJi8SZwt.ttf',
    variant: '300italic',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '8bfd4860-3442-4511-a3bb-02eb7eaeb40a',
    origin: GF_SOURCE,
    url:
      GF_URL + 'robotomono/v13/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrnnAOW9AJi8SZwt.ttf',
    variant: '200italic',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '7cc1fc5d-7e13-4470-8ad4-7ec7c9da83ba',
    origin: GF_SOURCE,
    url:
      GF_URL + 'robotomono/v13/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlnAeW9AJi8SZwt.ttf',
    variant: '100italic',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '07a43b21-4d3f-4e40-897e-18219a40da0d',
    origin: GF_SOURCE,
    url: GF_URL + 'robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_Of2PQ--5Ip2sSQ.ttf',
    variant: '700',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '31bf99dc-72b0-4230-bb17-1f83d41a2283',
    origin: GF_SOURCE,
    url: GF_URL + 'robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_AP2PQ--5Ip2sSQ.ttf',
    variant: '600',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '579eec6f-5a25-435c-901b-497e12e2fd59',
    origin: GF_SOURCE,
    url: GF_URL + 'robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_7PqPQ--5Ip2sSQ.ttf',
    variant: '500',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'd08f9f3f-8cf7-4ec3-841e-7bf86d9d3af3',
    origin: GF_SOURCE,
    url: GF_URL + 'robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQ--5Ip2sSQ.ttf',
    variant: 'regular',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'bace760b-23c5-4659-accd-a7c459041faf',
    origin: GF_SOURCE,
    url: GF_URL + 'robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_gPqPQ--5Ip2sSQ.ttf',
    variant: '300',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '3a482d00-44d4-4a22-a89d-8763bbcfd5e4',
    origin: GF_SOURCE,
    url: GF_URL + 'robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_XvqPQ--5Ip2sSQ.ttf',
    variant: '200',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: '932efc4c-ee60-4af3-b927-b9b33a00df08',
    origin: GF_SOURCE,
    url: GF_URL + 'robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vuPQ--5Ip2sSQ.ttf',
    variant: '100',
    family: 'Roboto Mono',
    category: 'monospace',
    version: 'v13',
    subset: 'latin',
  },
  {
    id: 'ab0d6f64-2075-40bf-bc31-de847a9b13eb',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4cHLPrQVIT9c2c8.ttf',
    variant: '900italic',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '03300d7b-0328-46b5-af8e-a5264d0fb1a1',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejLPrQVIT9c2c8.ttf',
    variant: '800italic',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'e51b2594-8dc1-4edf-ae80-de1726249e5f',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4Y_LPrQVIT9c2c8.ttf',
    variant: '700italic',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '1b1e29a2-15a0-40cb-86e3-ea80cfaa3387',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4bbLPrQVIT9c2c8.ttf',
    variant: '600italic',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'c19e46cb-683c-49ed-a16d-bdf11e0c8b02',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4VrMPrQVIT9c2c8.ttf',
    variant: '500italic',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '3e2ce6b6-705c-4fb9-b286-1c1d3b05b687',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf',
    variant: 'italic',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '98d9e42f-2353-46b8-bca2-ec65bb90dfa6',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4TbMPrQVIT9c2c8.ttf',
    variant: '300italic',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'b81c7aa8-0b06-407d-9323-26275810b357',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejMPrQVIT9c2c8.ttf',
    variant: '200italic',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '57d0c69c-1195-4d16-b0d5-b5081b220d8f',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjNPrQVIT9c2c8.ttf',
    variant: '100italic',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'ef3c7a40-8eef-4ff1-90b2-1d22c5af14e6',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtzpYCPNLA3JC9c.ttf',
    variant: '900',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'e6cbae64-0621-4e43-9332-29ee2d61b367',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCPNLA3JC9c.ttf',
    variant: '800',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'c5434e1e-88c2-4170-bc6e-ddd976a4eed9',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pYCPNLA3JC9c.ttf',
    variant: '700',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'c9c03fa6-f219-44a5-970b-4a10821ebba9',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCPNLA3JC9c.ttf',
    variant: '600',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '293ac2cd-fcc3-4324-a7c2-96af8a335a13',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoooCPNLA3JC9c.ttf',
    variant: '500',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '9198237c-b208-433d-a63e-b371579674ba',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCPNLA3JC9c.ttf',
    variant: 'regular',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'e59ecef5-d6f4-4403-ad22-1d1a0bbd33e7',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEooCPNLA3JC9c.ttf',
    variant: '300',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '9cf5b91e-8c80-4761-80d5-f40c1e75403e',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtaooCPNLA3JC9c.ttf',
    variant: '200',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'c6158a5e-296d-4009-b7b0-ec6eff5eaf7f',
    origin: GF_SOURCE,
    url: GF_URL + 'raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvao4CPNLA3JC9c.ttf',
    variant: '100',
    family: 'Raleway',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '583b5713-dd89-4dfc-8674-ba0d380a26dd',
    origin: GF_SOURCE,
    url: GF_URL + 'notosans/v12/o-0TIpQlx3QUlC5A4PNr4Az5ZtyEx2xqPaif.ttf',
    variant: '700italic',
    family: 'Noto Sans',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'a0df4b04-583a-4f40-a214-5314136f1ef1',
    origin: GF_SOURCE,
    url: GF_URL + 'notosans/v12/o-0NIpQlx3QUlC5A4PNjXhFlY9aA5Wl6PQ.ttf',
    variant: '700',
    family: 'Noto Sans',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c29ed1ef-a129-4ec0-8b04-bc147b3fa6da',
    origin: GF_SOURCE,
    url: GF_URL + 'notosans/v12/o-0OIpQlx3QUlC5A4PNr4DRFSfiM7HBj.ttf',
    variant: 'italic',
    family: 'Noto Sans',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: '93df9e87-54a2-4716-b9cb-ce4885312618',
    origin: GF_SOURCE,
    url: GF_URL + 'notosans/v12/o-0IIpQlx3QUlC5A4PNb4j5Ba_2c7A.ttf',
    variant: 'regular',
    family: 'Noto Sans',
    category: 'sans-serif',
    version: 'v12',
    subset: 'latin',
  },
  {
    id: 'c41afe27-0ba4-4692-afcc-c0a029783a05',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiDyp8kv8JHgFVrJJLm81xlEN2PQEhcqw.ttf',
    variant: '900italic',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '974b7214-4113-46e3-92af-f980206debbf',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf',
    variant: '900',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '8b9273c9-b0a6-4f44-adfd-e16101c8c5d1',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiDyp8kv8JHgFVrJJLm111lEN2PQEhcqw.ttf',
    variant: '800italic',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '2281b24c-e8b4-4934-bb6e-f14425d26d5a',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf',
    variant: '800',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '8f8cfcc9-3366-4659-81f5-3767803d0d91',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiDyp8kv8JHgFVrJJLmy15lEN2PQEhcqw.ttf',
    variant: '700italic',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '97866f11-d912-4deb-89eb-8783f95b91d1',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf',
    variant: '700',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '9cde51c1-300c-4bdb-91cc-e4282fb57aff',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiDyp8kv8JHgFVrJJLmr19lEN2PQEhcqw.ttf',
    variant: '600italic',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'f2686037-7f9c-422a-bfb7-e3153443002e',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf',
    variant: '600',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '5603930d-e923-4bf3-899a-f270d3f1a280',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiDyp8kv8JHgFVrJJLmg1hlEN2PQEhcqw.ttf',
    variant: '500italic',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'ef3966d3-802f-41f2-bdc4-2be92e1dfd5d',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf',
    variant: '500',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '2bf95a18-0c47-4f99-8928-563f49062f32',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiGyp8kv8JHgFVrJJLed3FBGPaTSQ.ttf',
    variant: 'italic',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '4a6faaec-d61f-4245-bd64-3ead658ce943',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf',
    variant: 'regular',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '643db3b1-ff05-438f-bd2f-8132664169f0',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf',
    variant: '300italic',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '68fadc58-6c73-416b-a479-9fef5c1c06e8',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf',
    variant: '300',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'f56a1690-6542-45fb-ab7d-db5354f9c296',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf',
    variant: '200italic',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '1822f3fb-2b3f-4acd-9fb2-0f299d4727e7',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf',
    variant: '200',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '70254b95-bd92-40c4-af94-3b276ab086b5',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiAyp8kv8JHgFVrJJLmE3tFOvODSVFF.ttf',
    variant: '100italic',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '1c959e2f-5194-4d76-8f1a-3879f292cc26',
    origin: GF_SOURCE,
    url: GF_URL + 'poppins/v15/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf',
    variant: '100',
    family: 'Poppins',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '2cf51a02-1398-4af8-9a33-53fa455539c0',
    origin: GF_SOURCE,
    url: GF_URL + 'oswald/v36/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZogUFoZAaRliE.ttf',
    variant: '700',
    family: 'Oswald',
    category: 'sans-serif',
    version: 'v36',
    subset: 'latin',
  },
  {
    id: '7c62d49b-21fe-49d4-a857-b7de726b02bc',
    origin: GF_SOURCE,
    url: GF_URL + 'oswald/v36/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9ogUFoZAaRliE.ttf',
    variant: '600',
    family: 'Oswald',
    category: 'sans-serif',
    version: 'v36',
    subset: 'latin',
  },
  {
    id: '13fdb276-45e7-4f37-a4b0-dd92076c853f',
    origin: GF_SOURCE,
    url: GF_URL + 'oswald/v36/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf',
    variant: '500',
    family: 'Oswald',
    category: 'sans-serif',
    version: 'v36',
    subset: 'latin',
  },
  {
    id: '8c3d14b8-4ab0-4ad8-b934-4f73fd5c5178',
    origin: GF_SOURCE,
    url: GF_URL + 'oswald/v36/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf',
    variant: 'regular',
    family: 'Oswald',
    category: 'sans-serif',
    version: 'v36',
    subset: 'latin',
  },
  {
    id: '6afc91c2-b093-4b52-8896-3d3ea4215bfb',
    origin: GF_SOURCE,
    url: GF_URL + 'oswald/v36/TK3_WkUHHAIjg75cFRf3bXL8LICs169vgUFoZAaRliE.ttf',
    variant: '300',
    family: 'Oswald',
    category: 'sans-serif',
    version: 'v36',
    subset: 'latin',
  },
  {
    id: '79911b73-e048-489e-8d4a-09a5aafba011',
    origin: GF_SOURCE,
    url: GF_URL + 'oswald/v36/TK3_WkUHHAIjg75cFRf3bXL8LICs13FvgUFoZAaRliE.ttf',
    variant: '200',
    family: 'Oswald',
    category: 'sans-serif',
    version: 'v36',
    subset: 'latin',
  },
  {
    id: '6843af92-42c4-4c0c-96e5-8d5f39f0eb81',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklxdr3cWWxg40.ttf',
    variant: '900italic',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '83ff3bd7-96be-40c5-a65e-457324d15a7c',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nAkB1v_8CGxg.ttf',
    variant: '900',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'b592e364-01bc-4b76-80d0-8f1038fdf70a',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclRdr3cWWxg40.ttf',
    variant: '700italic',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'da0b2cd8-6962-4fce-8ff5-f256f3b505d7',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkB1v_8CGxg.ttf',
    variant: '700',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '7d325ad8-502a-4991-ae66-142fca6d0f18',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lBdr3cWWxg40.ttf',
    variant: '600italic',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '68cd1dac-3ff4-4c67-8e7a-ca2e38edfa96',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkB1v_8CGxg.ttf',
    variant: '600',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '60f6b5b4-7efc-4a4e-a75f-73640f4e43cc',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPa7gujNj9tmf.ttf',
    variant: 'italic',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '13e2cda4-f441-460e-ba39-c360c77e4d9b',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3aP6TkmDZz9g.ttf',
    variant: 'regular',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '3e1cadba-9211-40bc-94e5-b65fbce06186',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkhdr3cWWxg40.ttf',
    variant: '300italic',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '74b4fb8c-4c7f-4564-b4d9-9f01ec2b4c61',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zAkB1v_8CGxg.ttf',
    variant: '300',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: 'bb7fb6d9-7231-4403-91d6-8d7ea2371a98',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokRdr3cWWxg40.ttf',
    variant: '200italic',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '95d79cf7-16a7-4a8a-855c-fc16ffeea47b',
    origin: GF_SOURCE,
    url: GF_URL + 'sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_AkB1v_8CGxg.ttf',
    variant: '200',
    family: 'Source Sans Pro',
    category: 'sans-serif',
    version: 'v14',
    subset: 'latin',
  },
  {
    id: '56a76e98-0233-4817-aa61-e0540fd4d978',
    origin: GF_SOURCE,
    url:
      GF_URL + 'robotocondensed/v19/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDtCYYatlYcyRi4A.ttf',
    variant: '700italic',
    family: 'Roboto Condensed',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '02308b96-9fb5-4d6d-9b7a-8210a28493cb',
    origin: GF_SOURCE,
    url: GF_URL + 'robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meKCMSbvtdYyQ.ttf',
    variant: '700',
    family: 'Roboto Condensed',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'dc500bf9-a053-4727-ab2b-a14d32c03a32',
    origin: GF_SOURCE,
    url: GF_URL + 'robotocondensed/v19/ieVj2ZhZI2eCN5jzbjEETS9weq8-19e7CAk8YvJEeg.ttf',
    variant: 'italic',
    family: 'Roboto Condensed',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'c412e5c7-294d-46a5-b1e9-d9763ec1d744',
    origin: GF_SOURCE,
    url: GF_URL + 'robotocondensed/v19/ieVl2ZhZI2eCN5jzbjEETS9weq8-59WxDCs5cvI.ttf',
    variant: 'regular',
    family: 'Roboto Condensed',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '6b7f0350-3ec3-4ab1-877a-c78c934288cc',
    origin: GF_SOURCE,
    url:
      GF_URL + 'robotocondensed/v19/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDpCEYatlYcyRi4A.ttf',
    variant: '300italic',
    family: 'Roboto Condensed',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: '4a7042b6-392b-4e9b-be7e-00a0bc59099d',
    origin: GF_SOURCE,
    url: GF_URL + 'robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZKCMSbvtdYyQ.ttf',
    variant: '300',
    family: 'Roboto Condensed',
    category: 'sans-serif',
    version: 'v19',
    subset: 'latin',
  },
  {
    id: 'f270ef46-2a99-4629-bf90-b59f54515130',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZSgnD-_xxrCq7qg.ttf',
    variant: '900italic',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '18c80488-9eaa-42a3-8589-0b6d25329b96',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTURjIg1_i6t8kCHKm45_epG7g7J_950vCo.ttf',
    variant: '900',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'd8e12b93-9377-4422-a91b-3e442ea954a3',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZbgjD-_xxrCq7qg.ttf',
    variant: '800italic',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'f47eafd0-7a42-44f9-a9bc-1f66160b1063',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTURjIg1_i6t8kCHKm45_c5H7g7J_950vCo.ttf',
    variant: '800',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'ccc34769-860c-4088-b97d-87c831e2fb0a',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZcgvD-_xxrCq7qg.ttf',
    variant: '700italic',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '8c532734-8ca6-45e5-80aa-aee738459239',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE7g7J_950vCo.ttf',
    variant: '700',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '93f8d79d-096d-4cc2-9bb5-d70ee416d893',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZFgrD-_xxrCq7qg.ttf',
    variant: '600italic',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'b66a73fb-342e-4457-92b0-60064a9211be',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF7g7J_950vCo.ttf',
    variant: '600',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'de035833-2876-4e73-b4d0-6df741204d59',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3D-_xxrCq7qg.ttf',
    variant: '500italic',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '2d003d82-2665-441c-bf66-7142b5052a2a',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC7g7J_950vCo.ttf',
    variant: '500',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '14f7440d-31f6-4ae2-a393-65c8a1eee01d',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTUQjIg1_i6t8kCHKm459WxhziTn89dtpQ.ttf',
    variant: 'italic',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'c73027a5-87ee-45b3-8c88-747e0edb5e7a',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTUSjIg1_i6t8kCHKm45xW5rygbi49c.ttf',
    variant: 'regular',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '33447af3-260d-489f-913d-ee145fd486ea',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzD-_xxrCq7qg.ttf',
    variant: '300italic',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '80024424-af4a-4438-871d-97444ca3cb26',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD7g7J_950vCo.ttf',
    variant: '300',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '721c80bb-4496-4b66-9d7e-e2d44150cb48',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_D-_xxrCq7qg.ttf',
    variant: '200italic',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '79ff044b-4e33-48b7-936e-f6d9452ae241',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA7g7J_950vCo.ttf',
    variant: '200',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: 'c4b0e4ca-8905-4fe8-ae1a-538bb863071d',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqi7j0dJ9pTOi.ttf',
    variant: '100italic',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '6f94b9cd-b2d4-4fe2-a38f-4f6be499df8e',
    origin: GF_SOURCE,
    url: GF_URL + 'montserrat/v15/JTUQjIg1_i6t8kCHKm45_QphziTn89dtpQ.ttf',
    variant: '100',
    family: 'Montserrat',
    category: 'sans-serif',
    version: 'v15',
    subset: 'latin',
  },
  {
    id: '1adfb21c-87bd-4b68-8eb5-cf10c9ecc208',
    origin: GF_SOURCE,
    url: GF_URL + 'lato/v17/S6u_w4BMUTPHjxsI3wiPHA3s5dwt7w.ttf',
    variant: '900italic',
    family: 'Lato',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '277d5b7d-3720-4623-b7a5-36b36b73d498',
    origin: GF_SOURCE,
    url: GF_URL + 'lato/v17/S6u9w4BMUTPHh50Xew-FGC_p9dw.ttf',
    variant: '900',
    family: 'Lato',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '51f77888-8bc6-4050-b3ea-7a110b1c6daf',
    origin: GF_SOURCE,
    url: GF_URL + 'lato/v17/S6u_w4BMUTPHjxsI5wqPHA3s5dwt7w.ttf',
    variant: '700italic',
    family: 'Lato',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '94a9ee3f-25ea-438e-a417-62ff8944aff6',
    origin: GF_SOURCE,
    url: GF_URL + 'lato/v17/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf',
    variant: '700',
    family: 'Lato',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '0ea5820d-1654-4971-b38e-955f2f476862',
    origin: GF_SOURCE,
    url: GF_URL + 'lato/v17/S6u8w4BMUTPHjxswWyWrFCbw7A.ttf',
    variant: 'italic',
    family: 'Lato',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '12a03727-ef49-4eaf-9529-29002b10c72d',
    origin: GF_SOURCE,
    url: GF_URL + 'lato/v17/S6uyw4BMUTPHvxk6XweuBCY.ttf',
    variant: 'regular',
    family: 'Lato',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'ec3de023-98c2-4146-873e-5f3d7c9e2403',
    origin: GF_SOURCE,
    url: GF_URL + 'lato/v17/S6u_w4BMUTPHjxsI9w2PHA3s5dwt7w.ttf',
    variant: '300italic',
    family: 'Lato',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '8153ecd1-cc4d-4473-8944-0bbd3faff8b3',
    origin: GF_SOURCE,
    url: GF_URL + 'lato/v17/S6u9w4BMUTPHh7USew-FGC_p9dw.ttf',
    variant: '300',
    family: 'Lato',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '7acf54f3-1bc4-4a5c-a63e-a99bd62318a8',
    origin: GF_SOURCE,
    url: GF_URL + 'lato/v17/S6u-w4BMUTPHjxsIPy-vNiPg7MU0.ttf',
    variant: '100italic',
    family: 'Lato',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: 'f40f71ea-3e4a-4539-a087-681564ff9916',
    origin: GF_SOURCE,
    url: GF_URL + 'lato/v17/S6u8w4BMUTPHh30wWyWrFCbw7A.ttf',
    variant: '100',
    family: 'Lato',
    category: 'sans-serif',
    version: 'v17',
    subset: 'latin',
  },
  {
    id: '16239f12-128f-42af-b058-22d3ba973f7a',
    origin: GF_SOURCE,
    url: GF_URL + 'notosansjp/v28/-F6pfjtqLzI2JPCgQBnw7HFQQi8q1H1hj-sNFQ.otf',
    variant: '900',
    family: 'Noto Sans JP',
    category: 'sans-serif',
    version: 'v28',
    subset: 'latin',
  },
  {
    id: 'ca0c01fa-401a-4372-8fba-e0afca573324',
    origin: GF_SOURCE,
    url: GF_URL + 'notosansjp/v28/-F6pfjtqLzI2JPCgQBnw7HFQei0q1H1hj-sNFQ.otf',
    variant: '700',
    family: 'Noto Sans JP',
    category: 'sans-serif',
    version: 'v28',
    subset: 'latin',
  },
  {
    id: 'b3c24c0a-fe4c-4f06-9cfb-b19edb4f23d9',
    origin: GF_SOURCE,
    url: GF_URL + 'notosansjp/v28/-F6pfjtqLzI2JPCgQBnw7HFQMisq1H1hj-sNFQ.otf',
    variant: '500',
    family: 'Noto Sans JP',
    category: 'sans-serif',
    version: 'v28',
    subset: 'latin',
  },
  {
    id: '5828db5a-ab26-4731-ad01-d802e55efcc9',
    origin: GF_SOURCE,
    url: GF_URL + 'notosansjp/v28/-F62fjtqLzI2JPCgQBnw7HFowAIO2lZ9hg.otf',
    variant: 'regular',
    family: 'Noto Sans JP',
    category: 'sans-serif',
    version: 'v28',
    subset: 'latin',
  },
  {
    id: '4b5da774-af7b-4061-8be8-5129229ab1a6',
    origin: GF_SOURCE,
    url: GF_URL + 'notosansjp/v28/-F6pfjtqLzI2JPCgQBnw7HFQaioq1H1hj-sNFQ.otf',
    variant: '300',
    family: 'Noto Sans JP',
    category: 'sans-serif',
    version: 'v28',
    subset: 'latin',
  },
  {
    id: '5cbea80d-f8cb-4252-bd21-e8fdcf7ac57c',
    origin: GF_SOURCE,
    url: GF_URL + 'notosansjp/v28/-F6ofjtqLzI2JPCgQBnw7HFQoggM-FNthvIU.otf',
    variant: '100',
    family: 'Noto Sans JP',
    category: 'sans-serif',
    version: 'v28',
    subset: 'latin',
  },
  {
    id: '5610d912-97a0-461f-9086-144fb0267581',
    origin: GF_SOURCE,
    url: GF_URL + 'opensans/v18/memnYaGs126MiZpBA-UFUKW-U-hsKKKTjrPW.ttf',
    variant: '800italic',
    family: 'Open Sans',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '57518d99-50d6-46c6-b35d-2070ec261f18',
    origin: GF_SOURCE,
    url: GF_URL + 'opensans/v18/mem5YaGs126MiZpBA-UN8rs-VeJoCqeDjg.ttf',
    variant: '800',
    family: 'Open Sans',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '2eb38617-aaa7-4602-968d-cf3b14b9c523',
    origin: GF_SOURCE,
    url: GF_URL + 'opensans/v18/memnYaGs126MiZpBA-UFUKWiUOhsKKKTjrPW.ttf',
    variant: '700italic',
    family: 'Open Sans',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: 'e009a7ca-329f-4cd2-9c9e-5bda18b80045',
    origin: GF_SOURCE,
    url: GF_URL + 'opensans/v18/mem5YaGs126MiZpBA-UN7rg-VeJoCqeDjg.ttf',
    variant: '700',
    family: 'Open Sans',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '1aaeb30b-6b53-4ca4-9261-1d9c0bff34d5',
    origin: GF_SOURCE,
    url: GF_URL + 'opensans/v18/memnYaGs126MiZpBA-UFUKXGUehsKKKTjrPW.ttf',
    variant: '600italic',
    family: 'Open Sans',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '0ef21f01-ea27-42a0-99f3-1401f294fe70',
    origin: GF_SOURCE,
    url: GF_URL + 'opensans/v18/mem5YaGs126MiZpBA-UNirk-VeJoCqeDjg.ttf',
    variant: '600',
    family: 'Open Sans',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: 'a2187bdd-4994-4d72-8071-4206f548056b',
    origin: GF_SOURCE,
    url: GF_URL + 'opensans/v18/mem6YaGs126MiZpBA-UFUJ0ef8xkA76a.ttf',
    variant: 'italic',
    family: 'Open Sans',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: 'ae1e3c84-0f70-4533-b574-e5a945d75283',
    origin: GF_SOURCE,
    url: GF_URL + 'opensans/v18/mem8YaGs126MiZpBA-U1UpcaXcl0Aw.ttf',
    variant: 'regular',
    family: 'Open Sans',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '613f479f-7500-4036-a552-6c6a2f069986',
    origin: GF_SOURCE,
    url: GF_URL + 'opensans/v18/memnYaGs126MiZpBA-UFUKWyV-hsKKKTjrPW.ttf',
    variant: '300italic',
    family: 'Open Sans',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '5e394311-a6b0-49d1-8b39-8352c1e59edb',
    origin: GF_SOURCE,
    url: GF_URL + 'opensans/v18/mem5YaGs126MiZpBA-UN_r8-VeJoCqeDjg.ttf',
    variant: '300',
    family: 'Open Sans',
    category: 'sans-serif',
    version: 'v18',
    subset: 'latin',
  },
  {
    id: '4d2c6023-5428-451f-9f4d-6bd2886743e6',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf',
    variant: '900italic',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
  {
    id: 'e8f01020-8b00-442a-9be6-048fabd3e11b',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf',
    variant: '900',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
  {
    id: 'ea2fd634-faa3-48b8-ac9a-470291876dcb',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf',
    variant: '700italic',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
  {
    id: 'd39cbe75-b3e5-4043-96b7-58675da1f3e5',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
    variant: '700',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
  {
    id: 'a93f4e33-bd1b-4fdd-ae80-89582d911eea',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf',
    variant: '500italic',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
  {
    id: '874794e3-521e-41e8-a225-f7b361a6bddf',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf',
    variant: '500',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
  {
    id: 'e13af025-193c-4938-abdf-007e3bf861de',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf',
    variant: 'italic',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
  {
    id: 'f521e556-f546-4f03-90c0-7208dbc3e80b',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf',
    variant: 'regular',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
  {
    id: 'f80d94a2-be52-49e0-8910-45a4c88182bb',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf',
    variant: '300italic',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
  {
    id: '14dea42f-851f-49d5-bcf8-95aa4c7973b0',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf',
    variant: '300',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
  {
    id: '6a0378ef-9355-439d-ab92-6915b7567263',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf',
    variant: '100italic',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
  {
    id: 'f7f3cee7-7cee-4620-abc9-f2dbe0c81cbd',
    origin: GF_SOURCE,
    url: GF_URL + 'roboto/v27/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf',
    variant: '100',
    family: 'Roboto',
    category: 'sans-serif',
    version: 'v27',
    subset: 'latin',
  },
];
