import * as Sentry from '@sentry/react';

import { FC, ReactNode, StrictMode, createContext, useMemo } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { StytchProvider, initStytch } from '@stytch/stytch-react';
import store, { history } from './configureStore';

import { ActionCableConsumer } from 'cableSubscriptions/ActionCableConsumer';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { LoadAccountFonts } from './features/Fonts/LoadAccountFonts';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { RecoilRoot } from 'recoil';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { STYTCH_PUBLIC_TOKEN } from 'features/Auth/useAuthAlt';
import { TrackingVisitProvider } from 'features/providers/TrackingVisitProvider';
import { WindowContextProvider } from 'features/providers/WindowContextProvider';
import classNames from 'classnames';
import { env } from 'constants/environment';
import { useAppWrapStyles } from 'AppWrap';

export const ActionCableContext = createContext({} as any);
/*
 This readys a consumer (think of this as a browser window) that will connect 
 against /cable on your backend server by default. In other words, the client 
 (your front-end) is connecting to the cable - through a route you have defined 
 on the backend.
*/
const faviconSrc = env === 'production' ? '/favicon.png?v=3' : '/favicon-dev.png?v=3';

window.stytch = initStytch(STYTCH_PUBLIC_TOKEN)!;

export const AppProviders: FC = ({ children }) => {
  const { appClass } = useAppWrapStyles();
  return (
    <div data-testid="app-wrapper" className={classNames(appClass, 'min-h-screen')}>
      <Helmet>
        <link rel="icon" type="image/png" href={faviconSrc} />
      </Helmet>

      <StrictMode>
        <RecoilRoot>
          <Provider store={store}>
            <StytchProvider stytch={window.stytch}>
              <WindowContextProvider>
                <LoadAccountFonts />
                <Router history={history}>
                  <TrackingVisitProvider>
                    <QueryParamProvider ReactRouterRoute={RouteAdapter}>
                      <CookiesProvider>
                        <Sentry.ErrorBoundary showDialog>
                          <ActionCableContext.Provider value={ActionCableConsumer}>
                            {children}
                          </ActionCableContext.Provider>
                        </Sentry.ErrorBoundary>
                      </CookiesProvider>
                    </QueryParamProvider>
                  </TrackingVisitProvider>
                </Router>
              </WindowContextProvider>
            </StytchProvider>
          </Provider>
        </RecoilRoot>
      </StrictMode>
    </div>
  );
};

/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */
const RouteAdapter: FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(location: Location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate],
  );
  // https://github.com/pbeshai/use-query-params/issues/196
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return children({ history: adaptedHistory, location });
};
