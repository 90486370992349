import {OnChange} from 'features/Common/utils';
import {createContext, FC, useContext, useState} from 'react';
import {useSlateEvent} from './SlateEvents';

const hotkeys = {
  capitalization: 'q',
  punctuation: 'w',
};

const hotkeyKeys = Object.values(hotkeys);

export type HotkeyLabel = keyof typeof hotkeys;

type Context = {
  activeHotkey: string | undefined;
  setActiveHotkey: OnChange<string | undefined>;
};

const HotkeyContext = createContext<Context | null>(null);

export const HotkeyProvider: FC = ({children}) => {
  const [activeHotkey, setActiveHotkey] = useState<string>();

  useSlateEvent('keydown', event => {
    if (!hotkeyKeys.includes(event.key)) return;
    if (event.key === activeHotkey) return;

    setActiveHotkey(event.key);
  });

  useSlateEvent('keyup', () => {
    setActiveHotkey(undefined);
  });

  return (
    <HotkeyContext.Provider value={{activeHotkey, setActiveHotkey}}>
      {children}
    </HotkeyContext.Provider>
  );
};

const getHotkeyLabel = (key: string): HotkeyLabel | undefined => {
  const entries = Object.entries(hotkeys);
  const foundHotkey = entries.find(([, k]) => key === k);
  return foundHotkey?.[0] as any;
};

export const useActiveHotkey = (): undefined | HotkeyLabel => {
  const hotkeyContext = useContext(HotkeyContext);
  const activeHotkey = hotkeyContext?.activeHotkey;

  if (!activeHotkey) return;
  return getHotkeyLabel(activeHotkey);
};
