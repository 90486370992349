import ErrorPage from 'features/EditorCanvas/components/shared/ErrorPage';
import LoadingPage from 'features/EditorCanvas/components/shared/LoadingPage';
import { MainDownloadDetails } from './MainDownloadDetails';
import MainDownloadPreview from 'features/Dashboard/DashboardPage/ContentDownloads/MainDownload/MainDownloadPreview';
import { MainDownloadTabPanels } from './MainDownloadTabPanels';
import { useProjectDownload } from 'features/FocusedFlow/utils';

// Ratio = width / height
function MainDownloadPreviewContainer({
  projectId,
  downloadId,
}: {
  projectId: string;
  downloadId: string;
}) {
  const projectDownload = useProjectDownload(downloadId);
  // console.log('projectDownload', projectDownload);
  const { download, isLoading, isError } = projectDownload;

  if (isError && !isLoading) {
    return <div className="w-full bg-white">
      <ErrorPage />
    </div>

  }

  if (!download || isLoading) {
    return (
      <div className="w-full bg-white">

        <LoadingPage
          title="Loading download"
          explainer="Please wait while video is prepared."
        />
      </div>
    );
  }

  return (
    <div className="w-full bg-white">
      <div className="mx-auto w-full py-6 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="w-full lg:grid lg:grid-cols-7 lg:grid-rows-1  lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          <div className="lg:col-span-4 lg:row-end-1">
            <MainDownloadPreview
              playbackId={download.mux_playback_id}
              isLoading={download.is_loading}
              isError={download.is_error}
              projectSlug={download.project_slug}
            />
          </div>

          <MainDownloadDetails
            userProjectDownload={download}
            projectId={projectId}
            downloadId={downloadId}
          />

          {/* This will be for comments and other stuff */}
          <MainDownloadTabPanels projectId={projectId} downloadId={downloadId} />
        </div>
      </div>
    </div>
  );
}

export default MainDownloadPreviewContainer;
