import {FeatureFlag} from 'features/featureFlagSlice';
import {
  featureFlagSelector,
  featureFlagsSelector,
} from 'features/selectors/featureFlagsSelectors';
import {useSelector} from 'react-redux';

/** Returns true if the flag is enabled. */
export const useFeatureFlag = (key: FeatureFlag) => {
  return useSelector(featureFlagSelector(key)) ?? false;
};

/** Returns true if all flags are enabled. */
export const useFeatureFlagsEvery = (keys: FeatureFlag[]) => {
  const allFlags = useSelector(featureFlagsSelector);
  if (keys.length === 0) return false;
  return keys.every(key => allFlags[key]);
};

/** Returns true if at least one flag is enabled. */
export const useFeatureFlagsSome = (keys: FeatureFlag[]) => {
  const allFlags = useSelector(featureFlagsSelector);
  if (keys.length === 0) return false;
  return keys.some(key => allFlags[key]);
};
