import {AudiowaveFfprobe, AudiowaveWaveformData} from 'api/userUploadsAPI';
import {
  YoutubeUserUploadExistsResponse,
  YoutubeUserUploadRequestedResponse,
} from 'api/userLibraryAPI';

import {UploadMediaClipType} from 'services/uploadMediaClipAPI';
import {UserUploadsCableActions} from 'cableSubscriptions/userUploadsCable';
import {UserUploadsTypes} from 'features/Dashboard/shared/UploadIcon';
import WaveformData from 'waveform-data';
import {baseApi} from './baseAPI';
import {recordingSubmissionsAPI} from './recordingSubmissionsAPI';
import {subscribeToCable} from './utils';

type AudioWaveformReturnType = {
  audio_data: {
    ffprobe: AudiowaveFfprobe;
    audiowave: AudiowaveWaveformData;
  };
  created_at: number;
  id: string;
  options: Record<string, any>;
  user_upload_id: string;
};

export type AudioAnalysisParams = {
  uploadId: string;
  rangeStart: string;
  rangeEnd: string;
};

export type AudioAnalysisFftFrame = number[];
export type AudioAnalysisFft = AudioAnalysisFftFrame[];

export type AudioAnalysisProperties = {
  fft_range_size: number;
  fps: number;
  time_range: [number, number];
};

type AudioAnalysisReturnType = {
  fft_analysis: AudioAnalysisFft;
  properties: AudioAnalysisProperties;
};

export type UploadOriginUI = {
  type: 'focused-flow';
  wizardStateId: string;
};

export type UserUpload = {
  parent_folder_id: null | string;
  account_id: string;
  bucket_file_name: string;
  bucket_key: string;
  created_at: number;
  extension: string;
  file_name: string;
  file_size: string;
  file_type: string | UserUploadsTypes;
  id: string;
  mux_playback_id: string;
  owner_id: string;
  source: string;
  status: string;
  updated_at: number;
  upload_id: string;
  upload_url: string;
  discarded_at: any;
  last_modified: any;
  has_transcript: boolean;
  has_audio_analysis: boolean;
  should_expect_transcript: boolean;
  max_width: number | null;
  max_height: number | null;
  duration: number | null;
  account_logo: boolean | null;
};

export type UserUploadInput = {
  parent_folder_id?: null | string;
  account_id?: string;
  bucket_file_name?: string;
  bucket_key?: string;
  created_at?: number;
  extension?: string;
  file_name?: string;
  file_size?: string;
  file_type?: string | UserUploadsTypes;
  id?: string;
  mux_playback_id?: string;
  owner_id?: string;
  source?: string;
  status?: string;
  updated_at?: number;
  upload_id?: string;
  upload_url?: string;
  discarded_at?: any;
  last_modified?: any;
  has_transcript?: boolean;
  has_audio_analysis?: boolean;
  should_expect_transcript?: boolean;
  max_width?: number | null;
  max_height?: number | null;
  duration?: number | null;
  account_logo?: boolean | null;
};

type UserUploadUpdateInput = UpdateId<UserUploadInput> & {};

type UpdateId<T extends {}> = T & {id: string};
type UpsertId<T extends {}> = T & {id?: string};

interface ListUserUploadResponse<T> {
  page: number;
  count: number;
  items: number;
  pages: number;
  last: number;
  from: number;
  to: number;
  prev: number;
  next: number;
  data: T[];
}

export const userUploadAPI = baseApi
  .enhanceEndpoints({addTagTypes: ['UserUpload', 'Transcript', 'UploadMediaClip']})
  .injectEndpoints({
    endpoints: builder => ({
      listUserUploads: builder.query<ListUserUploadResponse<UserUpload>, number | void>({
        query: (page = 1) => ({
          url: `user_uploads?page=${page}`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading user uploads data',
          refetchOnReconnect: false,
          refetchOnFocus: false,
        }),
        providesTags: (result, error, page) =>
          result
            ? [
                // Provides a tag for each post in the current page,
                // as well as the 'PARTIAL-LIST' tag.
                ...result.data.map(({id}) => ({type: 'UserUpload' as const, id})),
                {type: 'UserUpload', id: 'PARTIAL-LIST'},
              ]
            : [{type: 'UserUpload', id: 'PARTIAL-LIST'}],
      }),

      userUpload: builder.query<UserUpload, {id: string}>({
        query: ({id}) => ({
          url: `user_uploads/${id}`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading user upload data',
        }),
        providesTags: (result, error, {id}) => [
          {type: 'UserUpload', id: 'PARTIAL-LIST'},
          {type: 'UserUpload', id},
        ],
        async onCacheEntryAdded(_, api) {
          return subscribeToCable(
            {api, action: UserUploadsCableActions.UpdateUserUpload},
            payload => {
              // console.log('payload', payload);
              api.dispatch(
                userUploadAPI.util.invalidateTags([
                  {type: 'UserUpload', id: 'PARTIAL-LIST'},
                  {type: 'UserUpload', id: payload.user_upload_id},
                ]),
              );
              api.dispatch(
                recordingSubmissionsAPI.util.invalidateTags(['RecordingFormSubmission']),
              );
            },
          );
        },
      }),

      postYoutubeUserUpload: builder.mutation<
        YoutubeUserUploadExistsResponse | YoutubeUserUploadRequestedResponse,
        {youtubeVideoId: string; youtubeVideoName: string}
      >({
        query: ({youtubeVideoId, youtubeVideoName}) => ({
          url: `external_media/youtube_video_upload`,
          method: `POST`,
          v2: true,
          errorTitle: `Error creating user uploads page`,
          data: {
            youtube_video_params: {
              youtube_video_id: youtubeVideoId,
              youtube_video_name: youtubeVideoName,
            },
          },
        }),
        invalidatesTags: (result, error) => {
          if (result) {
            const {
              user_upload: {id},
            } = result;
            // console.log('result', result);
            return [
              {type: 'UserUpload', id: 'PARTIAL-LIST'},
              {type: 'UserUpload', id},
            ];
          }
        },
      }),
      createUserUpload: builder.mutation<UserUpload, UserUploadInput>({
        query: userUpload => ({
          url: `user_uploads`,
          method: `POST`,
          v2: true,
          errorTitle: `Error creating user uploads page`,
          data: {user_upload: userUpload},
        }),
        invalidatesTags: (result, error, {id}) => {
          return [
            {type: 'UserUpload', id: 'PARTIAL-LIST'},
            {type: 'UserUpload', id},
          ];
        },
      }),

      updateUserUpload: builder.mutation<UserUpload, UserUploadInput>({
        query: ({id, ...patch}) => ({
          url: `user_uploads/${id}`,
          method: `PATCH`,
          v2: true,
          errorTitle: `Error updating user upload`,
          data: {user_upload: patch},
        }),
        invalidatesTags: (result, error, {id}) => {
          // console.log('result', result);
          // console.log('id', id);
          // console.log('error', error);

          return [
            {type: 'UserUpload', id},
            {type: 'UserUpload', id: 'PARTIAL-LIST'},
          ];
        },
      }),

      deleteUserUpload: builder.mutation<{success: boolean; id: string}, string>({
        query: id => ({
          url: `user_uploads/${id}`,
          method: 'DELETE',
          v2: true,
          errorTitle: 'Error deleting user upload',
        }),
        invalidatesTags: (result, error, id) => [
          {type: 'UserUpload', id},
          {type: 'UserUpload', id: 'PARTIAL-LIST'},
        ],
      }),

      listUserUploadsUploadMediaClips: builder.query<
        ListUserUploadResponse<UserUpload>,
        {page: number | void; id: string}
      >({
        query: ({id, page = 1}) => ({
          url: `user_uploads/${id}/clips?page=${page}`,
          method: 'GET',
          v2: true,
          errorTitle: 'Error loading user uploads data',
          refetchOnReconnect: false,
          refetchOnFocus: false,
        }),
        providesTags: (result, error, page) =>
          result
            ? [
                // Provides a tag for each post in the current page,
                // as well as the 'PARTIAL-LIST' tag.
                ...result.data.map(({id}) => ({type: 'UploadMediaClip' as const, id})),
                {type: 'UploadMediaClip', id: 'PARTIAL-LIST'},
              ]
            : [{type: 'UploadMediaClip', id: 'PARTIAL-LIST'}],
      }),

      userUploadsUploadMediaClips: builder.query<UploadMediaClipType[], {id: string}>({
        query: ({id}) => ({
          url: `user_uploads/${id}/clips`,
          v2: true,
          method: 'GET',
          errorTitle: 'Error fetching clips',
        }),
        providesTags: ['UploadMediaClip'],
      }),
      createSampleUpload: builder.mutation<UserUpload, void>({
        query: () => ({
          url: 'user_uploads/create_sample',
          method: 'POST',
          errorTitle: 'Error creating sample upload',
        }),
        invalidatesTags: ['UserUpload'],
      }),

      audioWaveform: builder.query<WaveformData, {uploadId: string}>({
        query: ({uploadId}) => ({
          url: `user_uploads/${uploadId}/media_audio_waveforms`,
          method: 'GET',
          errorTitle: false,
          v2: true,
        }),
        transformResponse: ({audio_data}: AudioWaveformReturnType) => {
          return WaveformData.create(audio_data.audiowave);
        },
      }),
      audioAnalysis: builder.query<AudioAnalysisReturnType, AudioAnalysisParams>({
        query: ({uploadId, rangeStart, rangeEnd}) => ({
          url: `user_uploads/${uploadId}/audio_analysis?range_start=${rangeStart}&range_end=${rangeEnd}`,
          method: 'GET',
          errorTitle: false,
          v2: true,
        }),
      }),
    }),
  });

export const {
  useAudioAnalysisQuery,
  useAudioWaveformQuery,
  useCreateSampleUploadMutation,
  useCreateUserUploadMutation,
  useDeleteUserUploadMutation,
  useLazyListUserUploadsQuery,
  useLazyUserUploadQuery,
  useListUserUploadsQuery,
  useListUserUploadsUploadMediaClipsQuery,
  usePostYoutubeUserUploadMutation,
  useUpdateUserUploadMutation,
  useUserUploadQuery,
  useUserUploadsUploadMediaClipsQuery,
} = userUploadAPI;
