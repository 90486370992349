import cn from 'classnames';
import { useDuration } from 'features/EditorCanvas/components/CanvasTime/useDuration';

function msToTime(duration) {
  let minutes =
    Math.round((duration / (1000 * 60)) % 60) > 0
      ? Math.round((duration / (1000 * 60)) % 60)
      : 1;

  minutes = minutes < 10 ? '' + minutes : minutes;

  return 'About ' + minutes + (minutes === '1' ? ' minute' : ' minutes');
}

export function CanvasModalExport({
  startExportFn,
  isExporting,
  canvasDimensions,
  projectId,
}) {
  const { height, width } = canvasDimensions;

  const beginExport = () => {
    window.analytics.track('Begin export from project canvas');
    startExportFn(projectId);
  };

  const durationMs = useDuration();
  const durationInWords = msToTime(durationMs);

  return (
    <>
      <div className="mt-3 ml-2 text-center sm:mt-0 sm:text-left">
        <div className="mt-5 rounded-md border border-gray-200 px-4">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="select-none text-sm font-medium text-gray-500">Format</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                MP4 video (.mp4)
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="select-none text-sm font-medium text-gray-500">
                Dimensions
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {width} x {height}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="select-none text-sm font-medium text-gray-500">
                Video quality
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">High</dd>
            </div>
          </dl>
        </div>
        <div className="mt-2 px-2">
          <p className="text-sm text-gray-500">
            This export will be generated from optimized versions of your original media.
          </p>
        </div>

        <div className="mt-5 rounded-md border border-gray-200 px-4">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="select-none text-sm font-medium text-gray-500">
                Export time
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {durationInWords.toString()}
              </dd>
            </div>
          </dl>
        </div>

        <div className="mt-2 px-2">
          <p className="text-sm text-gray-500">
            Once you start the export, you will be sent an email with the download link.
          </p>
        </div>
      </div>
      <div className="mt-4 flex w-full flex-row-reverse border-t border-gray-200 pt-4">
        <button
          disabled={isExporting}
          onClick={beginExport}
          type="button"
          className={cn(
            {
              'bg-gray-600 opacity-75': isExporting,
              'bg-indigo-600 hover:bg-indigo-700': !isExporting,
            },
            ' focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent px-3 py-1 text-sm  font-medium text-white focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto',
          )}
        >
          {isExporting && (
            <svg
              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Start export
        </button>
      </div>
    </>
  );
}
