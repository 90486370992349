import { EventInfo, THUMBNAIL_CONTAINER_HEIGHT, THUMBNAIL_FRAME_HEIGHT } from './constants';
import { FC, PropsWithChildren, forwardRef, useEffect, useRef, useState } from 'react';
import {
  MuxPlaybackIdType,
  TimeMilliSeconds,
  UserUploadsType,
} from 'features/types/userLibrarySlice';
import TimelineActiveSelection, { timelineDraggingState } from './TimelineActiveSelection';

import { DimensionWidthType } from 'features/types/canvasItemsSlice';
import LoadingSpinner from 'features/EditorCanvas/components/shared/LoadingSpinner';
import { Scissors } from 'react-feather';
import TimelineCurrentTimeMarker from './TimelineCurrentTimeMarker';
import TimelineHoverTimeMarker from './TimelineHoverTimeMarker';
import { TimelineWaveform } from './TimelineWaveform';
import { TranscriptToolbarButton } from 'features/TranscriptEditor/TranscriptToolbar';
import { VisibleTimeIndicator } from './VisibleTranscript';
import classNames from 'classnames';
import { convertToMs } from 'features/EditorCanvas/utils';
import { formatTime } from 'features/EditorCanvas/components/CanvasTime/utils';
import { isVideoMedia } from '../../utils';
import { useCreateClip } from 'features/TranscriptEditor/transcriptUtils';
import { useDebounceCallback } from '@react-hook/debounce';
import { useDuration } from 'features/EditorCanvas/components/CanvasTime/useDuration';
import { useLoadStoryboardJson } from './hooks';
import { useParams } from 'react-router-dom';
import { usePlayback } from 'features/EditorCanvas/components/CanvasTime/usePlayback';
import { useSelection } from 'features/TranscriptEditor/selection';
import { useSetRecoilState } from 'recoil';

interface DetailVideoPlayerThumbnailsProps {
  uploadFile: UserUploadsType;
  playbackId: MuxPlaybackIdType;
  width: DimensionWidthType;
}

const paddingX = 32;

export const DetailVideoPlayerThumbnails: FC<DetailVideoPlayerThumbnailsProps> = ({
  uploadFile,
  playbackId,
  width,
}: DetailVideoPlayerThumbnailsProps) => {
  const isVideo = isVideoMedia(uploadFile.file_type);
  const { selection, dragSelection, setSelection } = useSelection();
  const { seekTo } = usePlayback();
  const debouncedSeekTo = useDebounceCallback(seekTo, 500, true);

  const { userUploadId } = useParams<{ userUploadId: string }>();
  const createClip = useCreateClip(userUploadId!);
  useEffect(() => {
    if (dragSelection?.start == null) return;
    debouncedSeekTo({ timeMs: dragSelection.start });
  }, [dragSelection?.start, debouncedSeekTo]);

  const innerWidth = width - paddingX;

  const [hoverPercentage, setHoverPercentage] = useState<number | null>(null);

  const duration = useDuration();
  const storyboardJson = useLoadStoryboardJson(playbackId, isVideo, duration);

  const container = useRef<HTMLDivElement>(null);
  const getEventInfo = (event: MouseEvent): EventInfo => {
    let layerX = (event as any).layerX;

    if (container.current) {
      const targetRect = container.current?.getBoundingClientRect();
      layerX = event.clientX - targetRect.left;
    }

    const percent = Math.min(0.999999, Math.max(0, layerX / innerWidth));

    return {
      percent,
      playTime: percent * (duration / 1000),
      x: layerX,
    };
  };

  const setDragging = useSetRecoilState(timelineDraggingState);
  if (storyboardJson.isLoading) {
    return (
      <div
        aria-label="loading-indicator"
        className="flex w-full justify-center bg-gray-200"
      >
        <LoadingSpinner />
      </div>
    );
  }

  if (!storyboardJson.data) {
    return (
      <div
        style={{ height: `${THUMBNAIL_CONTAINER_HEIGHT}px` }}
        className="flex scale-x-95 scale-y-75 transform items-center justify-center"
      >
        <div className="h-full w-full animate-pulse bg-gray-100"></div>
      </div>
    );
  }

  const handleDrag = (start: EventInfo, end: EventInfo) => {
    window.analytics.track('Is dragging video timeline');
    setSelection([convertToMs(start.playTime), convertToMs(end.playTime)]);
    setDragging(true);
  };

  const handleDragEnd = ({ playTime }: EventInfo, type: 'click' | 'drag') => {
    setDragging(false);

    if (type === 'click') {
      window.analytics.track('Click down video thumbnail');
      seekTo({ timeMs: playTime * 1000 });
      setSelection(null);
    }
  };

  const handleHover = (event: EventInfo | null) => {
    if (!event) {
      setHoverPercentage(null);
      return;
    }

    let percentage = event.x / innerWidth;
    if (percentage < 0) percentage = 0;
    if (percentage > 1) percentage = 1;

    setHoverPercentage(percentage);
  };

  const calculateNumberOfTicks = (durationMs: TimeMilliSeconds) => {
    const duration = durationMs / 1000;

    const timeDivider = {
      5: duration / 5, // 5 sec
      10: duration / 10, // 10 sec
      15: duration / 15, // 15 sec
      30: duration / 30, // 30 sec
      60: duration / 60, // 1 min
      120: duration / 120, // 2 min
      300: duration / 300, // 5 min
      600: duration / 600, // 10 min
      1200: duration / 1200, // 20 min
      1800: duration / 1800, // 30 min
      3600: duration / 3600, // 1 hour
      5400: duration / 5400, // 1 hour
      7200: duration / 7200, // 2 hours
    };
    const numberOfTicks =
      innerWidth > 1100 ? 15 : innerWidth > 800 ? 10 : innerWidth > 600 ? 6 : 3;

    const closestDividerPair = Object.entries(timeDivider).find(([key, value]) => {
      if (value <= numberOfTicks) {
        return true;
      } else {
        return false;
      }
    }) || [7200, duration / 7200];
    const durationIncrementer = duration / closestDividerPair[1];
    const timeStampList = [];
    // console.log('closestDividerPair', closestDividerPair);
    for (let i = 1; i * durationIncrementer < duration; i++) {
      // console.log('i * durationIncrementer', i * durationIncrementer);
      timeStampList.push((i * durationIncrementer) / duration);
    }

    return timeStampList;
  };

  // TODO(lenny) Make the displayed time an increment that considers the avaliable space
  // The increments should be 5 second, 10 seconds, 15 seconds, 30 seconds, 1 minute,
  // 2 minutes, 5 minutes, 10 minutes, 30 minutes, 60 minutes, 120 minutes
  const timeStampList = calculateNumberOfTicks(duration) || [];



  return (
    <div
      className="relative flex select-none overflow-hidden"
      style={{
        width: `100%`,
        paddingLeft: `${paddingX / 2}px`,
        paddingRight: `${paddingX / 2}px`,
      }}
    >
      <div className="relative z-0 w-full">
        <VisibleTimeIndicator width={innerWidth} />
        <div
          className="absolute inset-0 top-11 w-full"
          style={{ height: THUMBNAIL_FRAME_HEIGHT }}
        >
          <TimelineWaveform
            playbackId={playbackId}
            userUploadId={uploadFile.id}
            fileType={uploadFile.file_type}
          />
        </div>

        <div
          className={classNames(
            'absolute top-0 w-full select-none text-xs transition-opacity',
            hoverPercentage != null && 'opacity-0',
          )}
        >
          {timeStampList.map((timeStampMultiple, timeKey) => {
            const playTime = duration * timeStampMultiple;

            // TODO(lenny): Change this
            const ARBITRARY_NUMBER_THAT_SHOULD_BE_UPDATED_WHEN_THERE_IS_TIME = 2;

            const formattedTime = formatTime(playTime);

            return (
              <span
                key={timeKey}
                className="h-4 border-l border-gray-400 pl-1 text-xs text-gray-400"
                style={{
                  position: 'absolute',
                  transform: `translateX(${(playTime / duration) * innerWidth -
                    ARBITRARY_NUMBER_THAT_SHOULD_BE_UPDATED_WHEN_THERE_IS_TIME
                    }px)`,
                }}
              >
                {formattedTime}
              </span>
            );
          })}
        </div>
        <TimelineContainer
          onHover={handleHover}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          getEventInfo={getEventInfo}
          ref={container}
        >
          <TimelineCurrentTimeMarker />
          {hoverPercentage != null && (
            <TimelineHoverTimeMarker percentage={hoverPercentage} />
          )}
          <div
            style={{
              transform: `translateX(-${paddingX / 2}px)`,
              width: `${width + paddingX}px`,
            }}
            className="absolute top-0 left-0 z-40 h-full cursor-horizontal opacity-0"
          />
          {selection && (
            <TimelineActiveSelection
              thumbnailContainerHeight={THUMBNAIL_FRAME_HEIGHT}
              width={innerWidth}
              getEventInfo={getEventInfo}
              onDrag={(type, { playTime }) => {
                if (type === 'start') {
                  setSelection([convertToMs(playTime), selection.end]);
                } else {
                  setSelection([selection.start, convertToMs(playTime)]);
                }
              }}
            />
          )}
        </TimelineContainer>
        {selection && (
          <div className="bg-gray-800 left-0 top-1 absolute z-50 rounded-md inline-flex justify-center items-center">
            <TranscriptToolbarButton
              showShortcut={false}
              label="Clip"
              icon={Scissors}
              action={() => {
                // console.log('userUploadId', userUploadId);
                createClip();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

type TimelineContainerProps = PropsWithChildren<{
  onHover: (info: EventInfo | null) => void;
  onDrag: (start: EventInfo, end: EventInfo) => void;
  onDragEnd: (info: EventInfo, type: 'drag' | 'click') => void;
  getEventInfo: (e: MouseEvent) => EventInfo;
}>;

const TimelineContainer = forwardRef<HTMLDivElement, TimelineContainerProps>(
  ({ children, onHover, onDrag, onDragEnd, getEventInfo }, ref) => {
    const dragStart = useRef<EventInfo | null>(null);

    const getValidDrag = (start: EventInfo | null, currentEvent: MouseEvent) => {
      if (!start) return;

      const endInfo = getEventInfo(currentEvent);
      const pxMoved = Math.abs(endInfo.x - start.x);
      if (pxMoved < 5) return;

      if (endInfo.x > start.x) {
        return { start, end: endInfo };
      } else {
        return { start: endInfo, end: start };
      }
    };

    return (
      <div
        ref={ref}
        className="relative z-40 h-full w-full select-none"
        onMouseLeave={() => {
          onHover(null);
        }}
        onMouseEnter={({ nativeEvent }) => onHover(getEventInfo(nativeEvent))}
        onMouseMove={({ nativeEvent }) => {
          const dragInfo = getValidDrag(dragStart.current, nativeEvent);
          if (dragInfo) {
            onDrag(dragInfo.start, dragInfo.end);
            onHover(null);
          } else {
            onHover(getEventInfo(nativeEvent));
          }
        }}
        onMouseUp={({ nativeEvent }) => {
          if (dragStart.current) {
            const dragInfo = getValidDrag(dragStart.current, nativeEvent);
            if (dragInfo) {
              onDrag(dragInfo.start, dragInfo.end);
              onDragEnd(getEventInfo(nativeEvent), 'drag');
            } else {
              onDragEnd(getEventInfo(nativeEvent), 'click');
            }
          }

          dragStart.current = null;
        }}
        onMouseDown={({ nativeEvent }) => {
          dragStart.current = getEventInfo(nativeEvent);
        }}
      >
        {children}
      </div>
    );
  },
);
