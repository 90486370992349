import {useCallback, useEffect, useRef} from 'react';
import {useStytch, useStytchSession, useStytchUser} from '@stytch/stytch-react';

import {env} from 'constants/environment';
import {getAuthToken} from 'services/utils';
import {useNavigate} from 'react-router-dom';

const STYTCH_PUBLIC_TOKEN_PROD = 'public-token-live-c191549f-325e-46a7-b355-090f4f8a27da';
const STYTCH_PUBLIC_TOKEN_DEV = 'public-token-test-53434def-607d-44df-89b8-072cbfa42993';
export const STYTCH_PUBLIC_TOKEN =
  env === 'production' ? STYTCH_PUBLIC_TOKEN_PROD : STYTCH_PUBLIC_TOKEN_DEV;
interface CypressAuth0Type {
  token: string;
  user: {
    email: string;
    nickname: string;
    picture: string;
    sub: string;
  };
}

export const useAuthAlt = () => {
  const cypressAuth0 = useRef<CypressAuth0Type | undefined>();
  const stytchSession = useStytchSession();
  const stytchUser = useStytchUser();
  const navigate = useNavigate();

  const stytchClient = useStytch();

  const logout = useCallback(() => {
    stytchClient.session.revoke();
  }, [stytchClient]);

  // if (window.Cypress) {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   useEffect(() => {
  //     cypressAuth0.current = JSON.parse(localStorage.getItem('auth0Cypress')!);
  //   }, []);

  //   if (cypressAuth0.current !== undefined) {
  //     const cypressValues = cypressAuth0.current;
  //     authReturnValue = {
  //       ...authReturnValue,
  //       user: cypressValues.user,
  //       isAuthenticated: true,
  //       isLoading: false,
  //       getAccessTokenSilently: async (
  //         options?: GetTokenSilentlyOptions | undefined,
  //       ): Promise<string> => {
  //         return new Promise<string>(resolve => {
  //           return resolve(cypressValues.token);
  //         });
  //       },
  //     };
  //     console.log('Cypress active');
  //   }
  // }

  // created_at: string;
  // crypto_wallets: Array<{
  //     crypto_wallet_id: string;
  //     crypto_wallet_address: string;
  //     crypto_wallet_type: string;
  //     verified: boolean;
  // }>;
  // emails: Array<{
  //     email: string;
  //     email_id: string;
  //     verified: boolean;
  // }>;
  // name: {
  //     first_name: string;
  //     last_name: string;
  //     middle_name: string;
  // };
  // phone_numbers: Array<{
  //     phone_number: string;
  //     phone_id: string;
  //     verified: boolean;
  // }>;
  // providers: Array<{
  //     provider_subject: string;
  //     provider_type: string;
  // }>;
  // status: "active" | "pending";
  // totps: Array<{
  //     totp_id: string;
  //     verified: boolean;
  // }>;
  // user_id: string;
  // webauthn_registrations: Array<{
  //     domain: string;
  //     user_agent: string;
  //     verified: boolean;
  //     webauthn_registration_id: string;
  // }>;

  const user = stytchUser
    ? {
        ...stytchUser,
        email_verified: stytchUser.emails[0].verified,
        email: stytchUser.emails[0].email,
        nickname: `${stytchUser.name.first_name} ${stytchUser.name.last_name}`,
        picture: 'https://fakeimg.pl/100x100',
        sub: stytchUser.user_id,
      }
    : null;

  return {
    getAccessTokenSilently: getAuthToken,
    logout: ({returnTo}: {returnTo: string}) => {
      logout();
      navigate(returnTo);
    },
    user: user,
    isAuthenticated: !!stytchSession,
    isLoading: !stytchSession,
  };
};
