import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';

import { Icon } from 'react-feather';
import { LoadingSpinnerInline } from 'features/EditorCanvas/components/shared/LoadingSpinnerInline';
import Mousetrap from 'mousetrap';
import classNames from 'classnames';
import { fadeProps } from 'features/EditorCanvas/Sidebar/Sidebar';

export const TranscriptToolbar: FC = ({ children }) => {
  return (
    <div className="pointer-events-none sticky bottom-0 left-0 right-0 flex items-center">
      <AnimatePresence>
        {children && (
          <motion.div
            className="pointer-events-auto z-[1000] m-auto mt-3 inline-flex items-center justify-center space-x-4 rounded bg-gray-900 p-2 text-white"
            {...fadeProps}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

type ButtonProps = {
  label?: string;
  shortcut?: string;
  handleShortcut?: boolean;
  showShortcut?: boolean;
  icon: Icon;
  action: () => any;
};

export const TranscriptToolbarButton = ({
  label,
  shortcut,
  handleShortcut = true,
  showShortcut = true,
  icon: Icon,
  action,
}: ButtonProps) => {
  const [loading, setLoading] = useState(false);
  const runAction = async () => {
    setLoading(true);

    // console.log('action', action);

    try {
      await action();
      // eslint-disable-next-line no-empty
    } catch (e) { }

    setLoading(false);
  };

  const runActionRef = useRef(runAction);
  runActionRef.current = runAction;

  useEffect(() => {
    if (!shortcut) return;
    if (!handleShortcut) return;

    const instance = Mousetrap.bind(shortcut, () => {
      runActionRef.current();
    });

    return () => {
      instance.unbind(shortcut);
    };
  }, [shortcut, handleShortcut]);

  return (
    <button
      onClick={() => {
        // console.log('runAction', runAction);
        runAction();
      }}
      className={classNames(
        'relative rounded-md p-2 text-sm text-white transition duration-150 ease-in-out hover:bg-gray-400 hover:bg-opacity-20',
        loading && 'pointer-events-none',
      )}
    >
      <div className="flex items-center justify-center space-x-2.5">
        <Icon size={18} />
        {label && (
          <span>{label}</span>
        )}
        {shortcut && showShortcut && (
          <span className="flex h-[20px] items-center justify-center rounded-md bg-gray-600 px-1.5 text-sm font-bold text-gray-300">
            {shortcut.toUpperCase()}
          </span>
        )}
      </div>
      <div
        className={classNames(
          'pointer-events-none absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 transition-opacity',
          !loading && 'opacity-0',
        )}
      >
        <LoadingSpinnerInline isLight className="!ml-0 !mr-0" />
      </div>
    </button>
  );
};
