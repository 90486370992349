import {AccountsLatestClips} from './AccountsLatestClips';
import {RecentSearchResults} from './RecentSearchResults';

export const RecentSearch = () => {
  return (
    <div className="flex w-full flex-col space-y-8 pr-6 pb-6">
      <RecentSearchResults />
      <AccountsLatestClips />
    </div>
  );
};
