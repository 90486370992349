import {fontDataSelector} from 'features/selectors/fontDataSelectors';
import {useSelector} from 'react-redux';
import {FontFamily} from './FontFamily';

export function LoadAccountFonts() {
  const {accountFontUploads} = useSelector(fontDataSelector);

  return (
    <>
      {accountFontUploads.map(accountFontUpload => (
        <FontFamily key={accountFontUpload.id} accountFontUpload={accountFontUpload} />
      ))}
    </>
  );
}
