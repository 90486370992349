import opentype from 'opentype.js';

export async function getOpenfontData(fontUrl: string) {
  try {
    const font = await opentype.load(fontUrl);
    return font;
  } catch (err: any) {
    console.error('Could not load font at URL: ', fontUrl);
    throw err;
  }
}
