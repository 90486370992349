import {DashParams, routePaths} from 'routes/routesHelper';
import {Dialog, Transition} from '@headlessui/react';
// Refactor TODO (jacques): Use Feather icons. Render using object config.
import {DownloadIcon, UserAddIcon} from '@heroicons/react/outline';
import {Droplet, Edit} from 'react-feather';
import {Fragment, ReactElement, useMemo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {MenuIcon, XIcon} from '@heroicons/react/outline';

import AccountPickerNav from 'features/Dashboard/shared/AccountPickerNav';
import {Button} from 'ui/Button';
import {Divider} from 'features/EditorCanvas/Sidebar/SidebarMenu';
import {InviteTeamForm} from 'features/NewOnboarding/InviteTeamForm';
import {MilkIcon} from 'features/Common/MilkIcon';
import MilkLogo from './shared/MilkLogo';
import {Modal} from 'ui/Modal';
import {NewFolderModal} from './DashboardPage/ContentUploads/NewFolderModal';
import UserPickerNav from 'features/Dashboard/shared/UserPickerNav';
import {YoutubeUploadModal} from './DashboardPage/ContentUploads/YoutubeUpload/YoutubeUploadModal';
import classNames from 'classnames';
import {createContainer} from 'unstated-next';
import {formatDistanceToNow} from 'date-fns';
import {showSuccessNotification} from 'features/Common/utils';

const createNav = {
  name: 'Create',
  type: 'create-button',
  href: `/${routePaths.dashboard}/${DashParams.CreateCampaign}`,
  icon: Edit,
};

export const homeNav = {
  type: 'home-button',
  href: `/${routePaths.dashboard}`,
};

const navigation = [
  {
    header: true,
    id: 'content-base',
    name: 'Prepare',
    'section-target': 'content-base',
    href: `/${routePaths.dashboard}/${DashParams.UploadsParam}`,
    icon: () => (
      <MilkIcon
        className={classNames(itemIconClasses, 'mr-3')}
        width={16}
        height={16}
        viewBox="0 0 16 16"
        icon={'Upload'}
      />
    ),
  },
  {
    name: 'My Files',
    href: `/${routePaths.dashboard}/${DashParams.UploadsParam}`,

    link: true,
  },
  {
    name: 'Video recording',
    href: `/${routePaths.dashboard}/${DashParams.RecordingFormParams}`,

    link: true,
  },
  {
    name: 'Brand kit',
    href: `/${routePaths.dashboard}/${DashParams.BrandKitParam}`,
    icon: Droplet,
    link: true,
  },

  {
    header: true,
    id: 'manage-base',
    name: 'Manage',
    'section-target': 'manage-base',
    href: `/${routePaths.dashboard}/${DashParams.ProjectsParam}`,
    icon: () => (
      <MilkIcon
        className={classNames(itemIconClasses, 'mr-3')}
        width={16}
        height={16}
        icon={'Project'}
      />
    ),
  },

  {
    name: 'Projects',
    href: `/${routePaths.dashboard}/${DashParams.ProjectsParam}`,
    link: true,
  },
  {
    name: 'Templates',
    href: `/${routePaths.dashboard}/${DashParams.TemplatesPageParam}`,
    matchType: 'start',
    link: true,
  },
  {
    name: 'Clips',
    href: `/${routePaths.dashboard}/${DashParams.ClipsParam}`,
    link: true,
  },
  {
    header: true,
    id: 'share-base',
    name: 'Share',
    'section-target': 'share-base',
    href: `/${routePaths.dashboard}/${DashParams.DownloadsParam}`,
    icon: () => <DownloadIcon className={classNames(itemIconClasses, 'mr-3')} />,
  },
  {
    name: 'Downloads',
    href: `/${routePaths.dashboard}/${DashParams.DownloadsParam}`,
    link: true,
    section: 'downloads',
  },
  // {
  //   header: true,
  //   id: 'reordings',
  //   name: 'Capture',
  //   'section-target': 'recordings-base',
  //   href: `/${routePaths.dashboard}/${DashParams.RecordingFormParams}`,
  //   icon: () => (
  //     <MilkIcon
  //       className={classNames(itemIconClasses, 'mr-3')}
  //       width={16}
  //       height={16}
  //       viewBox="0 0 16 16"
  //       icon={'Recording'}
  //     />
  //   ),
  // },
  // {
  //   name: 'Recordings',
  //   href: `/${routePaths.dashboard}/${DashParams.RecordingFormParams}`,
  //   link: true,
  // },
];

interface DashboardSidebarProps {
  isMinimized?: boolean;
  children: ReactElement;
}

export const parseTrialEndDate = (trialEndsAt: string) => {
  try {
    return Date.parse(trialEndsAt);
  } catch (error) {
    return Date.now();
  }
};

export const getRemainingTrialTime = (trialEndsAt: string) => {
  const parsedDate = trialEndsAt ? parseTrialEndDate(trialEndsAt) : Date.now();
  return trialEndsAt && Date.now() < Date.parse(trialEndsAt)
    ? formatDistanceToNow(parsedDate, {
        includeSeconds: false,
      })
    : 0;
};

const grayIconColor = 'text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600';

const iconSizeClasses = 'h-6 w-6 transition ease-in-out duration-150';
const itemIconClasses = grayIconColor + ' ' + iconSizeClasses;

function useDashboardSidebar(initialState = 0) {
  const [dashboardSidebarHeight, setDashboardSidebarHeight] = useState(initialState);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  return {
    dashboardSidebarHeight,
    inviteModalOpen,
    setDashboardSidebarHeight,
    setInviteModalOpen,
    setSidebarOpen,
    sidebarOpen,
  };
}

export const DashboardSidebarContainer = createContainer(useDashboardSidebar);

export const DashboardWrapWithSidebar = ({
  isMinimized,
  children,
}: DashboardSidebarProps) => {
  return (
    <DashboardSidebarContainer.Provider>
      <DashboardSidebar isMinimized={isMinimized}>{children}</DashboardSidebar>
    </DashboardSidebarContainer.Provider>
  );
};

const isRoute = (route: string, pathname: string, matchType?: undefined | 'start') => {
  if (matchType === 'start') {
    return pathname.startsWith(route);
  }
  return route === pathname;
};

export const NavFooter = ({
  isMobile = false,
  setInviteModalOpen,
}: {
  isMobile?: boolean;
  setInviteModalOpen: (param: boolean) => void;
}) => {
  return (
    <div className="flex flex-shrink-0 flex-col space-y-1  p-4">
      <span className="flex h-[1px] w-full py-1">
        <span className="flex h-[1px] w-full bg-gray-300" />
      </span>
      <div className="flex flex-1 flex-col space-y-1">
        <Button
          variant="inline"
          onClick={() => {
            !!window.Intercom &&
              window.Intercom('showNewMessage', 'Hi! I have some feedback');
          }}
          _className={classNames('text-xs')}
          alignText="left"
          leftIcon={() => (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              className={classNames('mr-1 h-5 w-5 flex-shrink-0')}
              aria-hidden="true"
            >
              <path d="M12 15C11.7775 15 11.56 15.066 11.375 15.1896C11.19 15.3132 11.0458 15.4889 10.9606 15.6945C10.8755 15.9 10.8532 16.1262 10.8966 16.3445C10.94 16.5627 11.0472 16.7632 11.2045 16.9205C11.3618 17.0778 11.5623 17.185 11.7805 17.2284C11.9988 17.2718 12.225 17.2495 12.4305 17.1644C12.6361 17.0792 12.8118 16.935 12.9354 16.75C13.059 16.565 13.125 16.3475 13.125 16.125C13.125 15.8266 13.0065 15.5405 12.7955 15.3295C12.5845 15.1185 12.2984 15 12 15Z" />
              <path d="M11.25 6.75H12.75V13.5H11.25V6.75Z" />
              <path d="M19.5 21H4.5C4.1023 20.9996 3.721 20.8414 3.43978 20.5602C3.15856 20.279 3.0004 19.8977 3 19.5V4.5C3.0004 4.1023 3.15856 3.721 3.43978 3.43978C3.721 3.15856 4.1023 3.0004 4.5 3H19.5C19.8977 3.0004 20.279 3.15856 20.5602 3.43978C20.8414 3.721 20.9996 4.1023 21 4.5V19.5C20.9996 19.8977 20.8414 20.279 20.5602 20.5602C20.279 20.8414 19.8977 20.9996 19.5 21ZM4.5 4.5V19.5H19.5007L19.5 4.5H4.5Z" />
            </svg>
          )}
        >
          Share feedback
        </Button>
      </div>
      {!isMobile && (
        <>
          <span className="flex h-[1px] w-full py-1">
            <span className="flex h-[1px] w-full bg-gray-300" />
          </span>
          <div className="relative flex text-left">
            <div className="flex w-full justify-between">
              <AccountPickerNav loadFromBottom={true} />
              <UserPickerNav loadFromBottom={true} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function DashboardSidebar({isMinimized, children}: DashboardSidebarProps) {
  const {sidebarOpen, setSidebarOpen, inviteModalOpen, setInviteModalOpen} =
    DashboardSidebarContainer.useContainer();
  return (
    <>
      <Modal
        size="large"
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        title="Invite collaborators"
      >
        <InviteTeamForm
          onComplete={count => {
            setInviteModalOpen(false);

            if (count === 0) return;

            showSuccessNotification({
              title: 'Success',
              message: `Invitation emails sent to ${count} ${
                count === 1 ? 'user' : 'users'
              }`,
            });
          }}
        />
      </Modal>

      <NewFolderModal />
      <YoutubeUploadModal />
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <div className="w-full space-y-2">
                      <div className="relative flex text-left">
                        <div className="flex w-full justify-between">
                          {!isMinimized && <AccountPickerNav />}
                          <UserPickerNav />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* THIS IS WHERE THE MOBILE NAV IS SETUP */}
                  <MobileNav />
                </div>
                <NavFooter isMobile={true} setInviteModalOpen={setInviteModalOpen} />
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="z-10 hidden md:fixed md:inset-y-0 md:flex md:w-52 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-2">
                <div className="w-full space-y-2">
                  <div className="relative flex text-left">
                    <div className="flex w-full justify-between">
                      <Link to={homeNav.href}>
                        <MilkLogo />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <nav className="mt-3 flex-1 space-y-1 bg-white">
                {/* <SidebarHeaderItem item={createNav} />
                <span className="flex h-[1px] w-full"></span> */}
                {navigation.map(item => {
                  if (item?.header) {
                    return <SidebarHeaderItem key={item.id} item={item} />;
                  } else if (item?.divider) {
                    return <Divider key={item.id} />;
                  } else if (item?.link) {
                    return <SidebarLinkItem key={item.id} item={item} />;
                  }
                })}
              </nav>
            </div>
            <NavFooter setInviteModalOpen={setInviteModalOpen} />
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-52">
          <div className="fixed top-2 right-4 z-10 flex items-center justify-center rounded-full border-2 border-gray-400 bg-white md:hidden">
            <button
              type="button"
              className="inline-flex h-10 w-10 items-center justify-center rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">{children}</main>
        </div>
      </div>
    </>
  );
}

const MobileNav = () => {
  return (
    <nav className="mt-5 space-y-1 px-2">
      {/* <SidebarHeaderItem item={createNav} /> */}
      {navigation.map(item => {
        if (item.header) {
          return <SidebarHeaderItem item={item} />;
        } else if (item?.divider) {
          return <Divider key={item.id} />;
        } else if (item?.link) {
          return <SidebarLinkItem item={item} />;
        }
      })}
    </nav>
  );
};

const SidebarLinkItem = ({item}: {item: any}) => {
  const location = useLocation();
  return (
    <Link
      key={item.name}
      to={item.href}
      className={classNames(
        isRoute(item.href, location.pathname, item?.matchType)
          ? 'bg-gray-100 text-gray-900'
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
        'group flex items-center px-4 py-2 pl-14 text-xs font-medium',
      )}
    >
      {item.name}
    </Link>
  );
};

const SidebarHeaderItem = ({item}: {item: any}) => {
  if (item.type === 'create-button') {
    return (
      <Link
        to={createNav.href}
        className={classNames(
          'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
          'group flex items-center rounded-md py-2 text-sm font-medium',
        )}
      >
        <span className="mr-0 ml-3 flex h-8 w-8 flex-shrink-0 items-center  justify-center rounded-full bg-indigo-500 p-1">
          <Edit className={classNames('flex h-5 w-5 text-white')} aria-hidden="true" />
        </span>
        <span className=" px-2 py-2">{createNav.name}</span>
      </Link>
    );
  }
  return (
    <Link
      key={item.name}
      to={item.href}
      className={classNames(
        'text-gray-800 hover:bg-gray-50 hover:text-gray-900',
        'group flex items-center px-4 py-2 text-sm font-medium',
      )}
    >
      <item.icon
        className={classNames(
          'text-gray-400 group-hover:text-gray-500',
          'h-6 w-6 flex-shrink-0 pr-3',
        )}
        aria-hidden="true"
      />
      {item.name}
    </Link>
  );
};
