import { Navigate, useLocation } from 'react-router-dom';

import LoadingDashboard from '../../Dashboard/LoadingDashboard';
import { PaymentPlanPick } from './PaymentPlanPick';
import { ReactElement } from 'react';
import { authSettingsAndAccountSelector } from 'features/selectors/authSelectors';
import { localEnvAuthOverride } from 'constants/environment';
import { paymentRoutes } from 'routes/RoutesDashboard';
import { routePaths } from 'routes/routesHelper';
import { useSelector } from 'react-redux';

/**
 * AccountCheckWrap
 *
 * Make sure there is a current account and access.
 *
 * Key checks:
 * - Setup websocket connection based on accountIdentifyApiAccountWrap
 */

export const AccountCheckWrap = ({
  children,
  loadingComponent,
}: {
  children: ReactElement;
  loadingComponent?: ReactElement;
}) => {
  const { authenticatedWithApi, isFetchingInitialApiRequest, account, user, plan } =
    useSelector(authSettingsAndAccountSelector);

  const location = useLocation();
  // console.log('location', location.pathname);

  const isPaymentSuccess = location.pathname.includes(
    `${routePaths.payments}/${paymentRoutes.paymentSuccess}`,
  );

  /**
   * This check exists to allow local development to override the Account Subscription
   * check.
   *
   * This should be replaced with a rake task to create a account subscription locally.
   */
  const shouldLoadPaymentPlanPicker = localEnvAuthOverride
    ? false
    : !plan && !isPaymentSuccess;

  if (
    authenticatedWithApi &&
    (!account || account.metadata?.onboarding_pending) &&
    !window.location.pathname.startsWith('/join') &&
    !window.location.pathname.startsWith(`/${routePaths.user}/join`)
  ) {
    let url = `/${routePaths.user}/join`;
    if (account?.id) url += `?accountId=${account.id}`;

    return <Navigate replace to={url} />;
  }

  if (!authenticatedWithApi || isFetchingInitialApiRequest) {
    if (loadingComponent) {
      return loadingComponent;
    }

    return <LoadingDashboard />;
  }

  // if (shouldLoadPaymentPlanPicker) {
  //   return <PaymentPlanPick />;
  // }

  return children;
};
