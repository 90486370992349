import {AlignCenter, AlignJustify, AlignLeft, AlignRight} from 'react-feather';
import {ButtonGroup, CanvasButton} from 'features/EditorCanvas/components/CanvasButton';
import {useDispatch, useSelector} from 'react-redux';

import {TextAlign} from 'features/types/canvasItemsSlice';
import {canvasItemsSelectedItemSelector} from 'features/selectors/canvasStateSelectors';
import {updateItemAttribute} from 'features/canvasItemsSlice';

const alignOptions = [
  {
    id: 'left',
    icon: AlignLeft,
  },
  {
    id: 'center',
    icon: AlignCenter,
  },
  {
    id: 'right',
    icon: AlignRight,
  },
  {
    id: 'justify',
    icon: AlignJustify,
  },
] as const;

export const TextAlignPicker = ({projectId}: {projectId: string}) => {
  const dispatch = useDispatch();

  const selected = useSelector(canvasItemsSelectedItemSelector)(projectId);
  if (!selected) return null;

  const item = selected.item;

  // @ts-ignore
  const textAlign: TextAlign = item.style.textAlign ?? 'left';

  const setTextAlign = (align: TextAlign) => {
    dispatch(
      updateItemAttribute({
        projectId,
        id: selected.id,
        attribute: 'style',
        property: 'textAlign',
        newValue: align,
      }),
    );
  };

  return (
    <div className="flex">
      <div className="flex w-full flex-col text-sm leading-5 text-gray-700">
        <div className="flex flex-row">
          <ButtonGroup>
            {alignOptions.map(({id, icon: Icon}) => (
              <CanvasButton
                key={id}
                onClick={() => setTextAlign(id)}
                variant={id === textAlign ? 'active' : 'default'}
              >
                <Icon size={16} />
              </CanvasButton>
            ))}
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};
