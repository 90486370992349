import {CSSProperties, useRef} from 'react';
import {formatTime, useTimeCode} from '../../CanvasTime/utils';

import {updateCanvasItemDimensions} from 'features/canvasItemsSlice';
import {useDispatch} from 'react-redux';
import {useDuration} from '../../CanvasTime/useDuration';
import {useOnResize} from 'features/Common/useElementSize';
import {useProjectId} from 'features/EditorCanvas/useProjectId';

export function DynamicCountDown({
  itemId,
  style,
  options,
}: {
  itemId: string;
  style?: CSSProperties;
  options?: Record<string, any>;
}) {
  const elRef = useRef<any>(null);
  const dispatch = useDispatch();
  const projectId = useProjectId();
  const onChangeSize = (dimension: any) => {
    dispatch(updateCanvasItemDimensions({projectId, id: itemId, dimension}));
  };

  useOnResize(elRef, size => {
    const height = Math.round(size.height);
    let width = Math.round(size.width);
    if (width === 0) width = height;

    onChangeSize({width: Math.ceil(width), height: Math.ceil(height)});
  });

  const timeCode = useTimeCode(2);
  const durationMs = useDuration();
  const durationTimeStr = formatTime(durationMs);
  const [durationMin, durationSec] = durationTimeStr.split(':');
  const [minutes, seconds] = timeCode.split(':');

  const minRemaining = String(Number(durationMin) - Number(minutes)).padStart(2, '0');
  const secRemaining = String(Math.round(Number(durationSec) - Number(seconds))).padStart(
    2,
    '0',
  );

  return (
    <>
      <div ref={elRef} style={{...style}} className="text-2xl text-gray-800">
        <div>{timeCode}</div>
        <div>
          {minRemaining}:{secRemaining}
        </div>
      </div>
    </>
  );
}
