import {ReactNode, useState} from 'react';

import EditableTextField from 'features/EditorCanvas/components/shared/EditableTextField';
import {UserUploadsType} from 'features/types/userLibrarySlice';
import {useUpdateUserUploadMutation} from 'services/userUploadAPI';

export default function FileNameTextField({
  children,
  uploadFile,
}: {
  children?: ReactNode;
  uploadFile: UserUploadsType;
}) {
  const fileName = uploadFile.file_name;

  const [tempFieldValue, setTempFieldValue] = useState(fileName);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updateUserUpload, {}] = useUpdateUserUploadMutation();

  const submitFileName = () => {
    window.analytics.track('Submit new file name from workspace toolbar');
    updateUserUpload({
      id: uploadFile.id,
      file_name: tempFieldValue,
    });

    setIsEditing(false);
  };

  return (
    <EditableTextField
      tempFieldValue={tempFieldValue}
      setTempFieldValue={setTempFieldValue}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      isSubmitting={isSubmitting}
      setIsSubmitting={setIsSubmitting}
      startValue={fileName}
      onSubmit={submitFileName}
    >
      {children}
    </EditableTextField>
  );
}
